import type { Todo, Product } from '@lib/common/type';
import type { DetailItem, InputParams } from '@lib/stock_status/type';

// 在庫入力inputparamsのamountDetailが初期値かのチェック
export const isInitializeInputAmount = (detail: DetailItem) => {
  // const caseIdForCheck = detail?.caseId === 0 ? '' : detail?.caseId;
  if (
    detail?.amountDetailId == '' &&
    detail?.bara === '' &&
    detail?.cart === '' &&
    detail?.case === '' &&
    detail?.total === '' &&
    detail?.caseId === 0 &&
    detail?.piecesPerBox === 1 &&
    detail?.productionOrShippingDate === ''
  ) {
    return true;
  }
};

// inputparamsに初期データのみしかないかのチェック
export const isExistOnlyInitializeInputParams = (inputParams: InputParams) => {
  if (
    inputParams.amountDetail &&
    inputParams.amountDetail.length === 1 &&
    isInitializeInputAmount(inputParams.amountDetail[0])
  ) {
    return true;
  }
};

// 在庫数は必須入力チェック
export const totalCheck = (
  inputParams: InputParams,
  inputAmountParams: Todo
) => {
  if (
    inputParams.amountDetail &&
    inputParams.amountDetail.length === 1 &&
    isInitializeInputAmount(inputParams.amountDetail[0])
  ) {
    // inputParamsの初期値のみ設定されている場合
    if (inputAmountParams.some((ip: DetailItem) => ip.total === '')) {
      return true;
    }
  } else {
    if (
      inputAmountParams.some((ip: DetailItem) => ip.total === '') ||
      (inputParams.amountDetail &&
        inputParams.amountDetail.some((ip: DetailItem) => ip.total === ''))
    ) {
      return true;
    }
  }
};

// 日付重複チェック
export const duplicateDateCheck = (
  inputParams: InputParams,
  inputAmountParams: Todo
) => {
  const checkDates: Todo = [];
  if (
    inputParams.amountDetail &&
    isExistOnlyInitializeInputParams(inputParams)
  ) {
    // inputParamsの初期値のみ設定されている場合
    const params = inputAmountParams;
    params.map((p: Todo) => {
      checkDates.push(p.productionOrShippingDate);
    });
  } else {
    const params = inputParams.amountDetail.concat([...inputAmountParams]);
    params.map((p: Todo) => {
      checkDates.push(p.productionOrShippingDate);
    });
  }
  const result = existsSameValue(checkDates);
  return result;
};

//  配列内で値が重複してないか調べる
const existsSameValue = (a: Todo) => {
  const s = new Set(a);
  return s.size != a.length;
};

// 日付のみまたは日付とケースの重複チェック
export const duplicateCheck = (
  inputParams: InputParams,
  inputAmountParams: Todo
  // casesLength: number | undefined
) => {
  const checkDatesAndCases: Todo = [];
  if (isExistOnlyInitializeInputParams(inputParams)) {
    // inputParamsの初期値のみ設定されている場合
    const params = inputAmountParams;
    params.map((p: Todo) => {
      // SidebarInputからは日付がnull、InputComponentからは日付が''でくるため置き換え
      const checkDate =
        p.productionOrShippingDate === null ? '' : p.productionOrShippingDate;
      if (p.caseId) {
        checkDatesAndCases.push(checkDate + p.caseId.toString());
      } else {
        checkDatesAndCases.push(checkDate);
      }
    });
  } else {
    const params = inputParams.amountDetail
      ? inputParams.amountDetail.concat([...inputAmountParams])
      : inputAmountParams;
    params.map((p: Todo) => {
      const checkDate =
        p.productionOrShippingDate === null ? '' : p.productionOrShippingDate;
      if (p.caseId) {
        checkDatesAndCases.push(checkDate + p.caseId.toString());
      } else {
        checkDatesAndCases.push(checkDate);
      }
    });
  }
  const result = existsSameValue(checkDatesAndCases);
  return result;
};

// ケース・バラの共通の計算式
export const calcTotalFunc = (
  product: Product | undefined,
  newDetail: DetailItem,
  selectedCasePiecesPerCase: number
) => {
  // ここでbara,case,cartが空であればtotalへ０を設定してあげる。
  if (!newDetail.case && newDetail.bara === '0') {
    newDetail.total = 0;
  } else if (newDetail.bara || newDetail.case) {
    if (selectedCasePiecesPerCase) {
      newDetail.total =
        Number(newDetail.bara) +
        Number(newDetail.case) * selectedCasePiecesPerCase;
    } else {
      newDetail.total =
        Number(newDetail.bara) +
        Number(newDetail.case) * (selectedCasePiecesPerCase || 1);
    }
  } else {
    newDetail.total = '';
  }
  return newDetail;
};

// numpadを使用するかの判定
export const shouldDisplayCustomNumPad = (
  isIPadOrIPhone13: boolean,
  useScanning: boolean
) => {
  return isIPadOrIPhone13 && useScanning; // 開発環境で確認する際は'isIPadOrIPhone13 && 'を外す
};

// 個数inputの先頭文字が「0」の場合はいかなる場合も0を返す
export const normalizeZeros = (str: string) => {
  if (/^0/.test(str)) {
    return '0';
  }
  return str;
};
