import * as S from './StoreroomList.styles';
import type { Storeroom } from '@lib/common/type';
import { useHistory } from 'react-router-dom';
import { PickingState } from '@lib/mobile/picking_edit/type';
import { MaterialsSearchState } from '@lib/mobile/materials_for_stocks/type';

type Props = {
  storerooms: Storeroom[] | undefined;
  setStoreroomOpen: (storeroomOpen: boolean) => void;
  setSelectedStoreroomId: (selectedStoreroomId: number | '') => void;
  setSelectedStoreroomName: (selectedStoreName: string | '') => void;
  fromPicking?: string;
  pickingState?: PickingState;
  materialsSearchState?: MaterialsSearchState;
};

const StoreroomList = ({
  storerooms,
  setStoreroomOpen,
  setSelectedStoreroomId,
  setSelectedStoreroomName,
  fromPicking,
  pickingState,
  materialsSearchState,
}: Props) => {
  const history = useHistory();

  const handleClickAllStoreroom = () => {
    localStorage.setItem('pickingSelectedStoreroomId', '');
    localStorage.setItem('pickingSelectedStoreroomName', 'すべての在庫室');
    setSelectedStoreroomId('');
    setSelectedStoreroomName('すべての在庫室');
    setStoreroomOpen(false);
    if (fromPicking === 'picking') {
      history.push(`/mobile/picking`, pickingState);
    } else if (fromPicking === 'picking_by_shipping') {
      history.push(`/mobile/picking_by_shipping`, {
        ...pickingState,
        stateSelectedStoreroomId: '',
      });
    } else if (fromPicking === 'materials_for_stocks') {
      history.push(`/mobile/materials/for_stocks`, {
        ...materialsSearchState,
        stateSelectedStoreroomId: '',
      });
    }
  };

  const handleClickSelectStoreroom = (s: Storeroom) => {
    localStorage.setItem('pickingSelectedStoreroomId', String(s.id));
    localStorage.setItem('pickingSelectedStoreroomName', s.name);
    setSelectedStoreroomId(s.id);
    setSelectedStoreroomName(s.name);
    setStoreroomOpen(false);
    if (fromPicking === 'picking') {
      history.push(`/mobile/picking`, pickingState);
    } else if (fromPicking === 'picking_by_shipping') {
      history.push(`/mobile/picking_by_shipping`, {
        ...pickingState,
        stateSelectedStoreroomId: s.id,
      });
    } else if (fromPicking === 'materials_for_stocks') {
      history.push(`/mobile/materials/for_stocks`, {
        ...materialsSearchState,
        stateSelectedStoreroomId: s.id,
      });
    } else {
      history.push(`/mobile/products/for_stocks`);
    }
  };

  const allStoreRooms =
    fromPicking === 'picking' || fromPicking === 'picking_by_shipping';

  return (
    <S.TagList>
      {allStoreRooms && (
        <S.TagLabel>
          <S.StoreroomName onClick={() => handleClickAllStoreroom()}>
            すべての在庫室
          </S.StoreroomName>
        </S.TagLabel>
      )}
      {storerooms?.map((s) => (
        <S.TagItem key={s.id}>
          <S.TagLabel>
            <S.StoreroomName
              onClick={() => {
                handleClickSelectStoreroom(s);
              }}
            >
              {s.name}
            </S.StoreroomName>
          </S.TagLabel>
        </S.TagItem>
      ))}
    </S.TagList>
  );
};
export default StoreroomList;
