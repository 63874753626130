export const TABLE_HEADER = [
  { key: '編集 複製', width: '48px', colSpan: 1 },
  // { key: '複製', width: '48px', colSpan: 1 },
  { key: '製品名', align: 'left', width: '12.75rem' },
  { key: '数量', width: '19rem', colSpan: 4 },
  // { key: '数量', align: 'left', width: '5rem', colSpan: 4 },
  // { key: '完成品数量', align: 'left', width: '5rem', colSpan: 3 },
  {
    key: '半製品製造',
    width: '100px',
    // width: '15px',
    wordBreak: 'keep-all',
    colSpan: 1,
  },
  {
    key: '出荷予定',
    width: '100px',
    // width: '15px',
    wordBreak: 'keep-all',
    colSpan: 1,
  },
  {
    key: '賞味期限',
    width: '100px',
    // width: '15px',
    wordBreak: 'keep-all',
    colSpan: 1,
  },
  // { key: '', align: 'left', width: '1rem', colSpan: 2 },
  { key: '時刻', width: '6rem', colSpan: 1 },
  // { key: '写真', width: '48px', colSpan: 1 },
  { key: '備考', width: '100%', colSpan: 1 },
];
