import { axiosInstance } from '@lib/pc/common/api/axiosConfig';
import { useQuery } from 'react-query';
import humps from 'humps';
import moment from 'moment';
import type { Todo } from '@lib/common/type';

const useSemiProductProductionResults = (date: Date) => {
  const { data, refetch } = useQuery(['production_results', date], async () => {
    const data = await axiosInstance().get(
      `/api/v1/semi_product_production_results?date=${moment(date).format(
        'YYYYMMDD'
      )}`
    );

    return data;
  });

  const semiProductionResults = data && (humps.camelizeKeys(data.data) as Todo);

  return { semiProductionResults, refetch };
};

export default useSemiProductProductionResults;
