export type DetailItem = {
  bara: number;
  case: number;
  cart: number;
  total: number | string;
  caseId: number;
  productionOrShippingDate: string | Date;
  amountDetailId: number | null;
};

export type Factory = {
  id: number;
  name: string;
};

export type FactoryAll = {
  id: number;
  name: string;
  lines: number[];
};

export type Stocks = {
  amount?: number;
  amountDetail: DetailItem[];
  comment: string;
  date: string;
  safetyStocks?: number;
  actualProductStocksId?: number;
  actualSemiProductStocksId?: number;
};

export type Stock = {
  date: string;
  productKind: number;
  productName: string;
  expiryDays?: number;
  pieces?: number;
  piecesPerBox?: number;
  safetyStocks?: number;
  stocks?: Stocks[];
};

export type ProductionResultDetailItem = {
  piece: number;
  case: number;
  cart: number;
  amount: number;
  defectiveSemiProductAmount: number;
  shippingOrExpirationDate: string;
  productionDateOfSemiProduct: string;
  comment: string;
  factoryAndLineName: string;
};

export type ProductProductionResults = {
  totalAmount?: number;
  productionResultDetails: ProductionResultDetailItem[];
  date: string;
};

export type ProductProductionResultItems = {
  amount: number | null;
  bestBeforeDate: string | null;
  bestBeforeDateInfo: Todo[];
  cart: string | null;
  case: string;
  casesPerCart: string | number;
  caseId: number;
  checkTime: string;
  comment: string;
  defectiveProductAmount: string;
  detailId: null | number;
  employeeIds: EmployeeData[];
  lineIndex: number;
  lineName: string;
  piece: string;
  piecesPerBox: string | number;
  plannedAmount: number;
  plannedCase: string;
  plannedPiece: string;
  productCode: string;
  productId: number;
  productName: string;
  productProductionResultId: null | number;
  productionDateOfSemiProduct: string;
  productsProcessId: number;
  shippingDate: string;
  shippingOrExpirationDate: null | string;
  storeroomId: number;
  time: null | string;
};

export type Tag = {
  id: number;
  name: string;
};

export type Storeroom = {
  id: number;
  name: string;
};

export type FactoryLine = {
  id: number;
  name: string;
  factoryId: number;
  factoryName: string;
};

export type Product = {
  id: number;
  name: string;
  nameAbbreviation: string;
  productKind: number;
  productCode: string;
  stockLastInputAt?: string;
  pieces?: number;
  piecesPerBox?: number;
  casesPerCart?: number;
  expiryDays: number;
  janCode?: string;
  stockDateLabel?: string;
  labelOfAmount: string;
  stocks?: Stocks[];
  tagIds: number[];
  storeroomIds: number[];
  productProductionResults?: ProductProductionResults[];
};

export type Line = {
  factoryLineId: number;
  factoryLineName: string;
  packagingResult?: PackagingResultForLine[];
};

export type PackagingResultForLine = {
  order: number;
  packagingBestBeforeDate?: Date;
  packagingCompletedQuantity?: number;
  packagingId: number;
  packagingPlannedQuantity: number;
  productId: number;
  productName: string;
  productionResultId: number;
  productCasesPerCart: number;
  productPiecesPerBox: number;
  stackingResult?: StackingResult;
};

export type StackingResult = {
  id: number;
  packagingId: number;
  productId: number;
  startedTime?: string;
  completedTime?: string;
  bestBeforeDate?: Date;
  comment?: string;
  completedBara?: number;
  completedCart?: number;
  completedCase?: number;
  completedQuantity?: number;
  employeeIds?: number[];
  stackingResult?: { id: number };
  productName: string;
};

export type EmployeeData = {
  id: number;
  code: string;
  name: string;
};

export type SearchState = {
  stateSearchChar: string;
  stateSelectedTagIds: number[];
  stateSelectedStoreroom: string;
  stateSelectedStoreroomId: number;
  stateScrollPosition: number;
  stateSearchOpen: boolean;
  stateProductsData: Product[];
  stateStocksData: Product[];
};

export type ProductionSchedule = {
  actualAmount: number | null;
  estimatedAmount: number | null;
  productionSchedulesId: number | null;
  productionDate: Date | null;
  comment: string | null;
  checkEnabled: boolean;
};

export type Schedules = {
  date: string;
  productId: number;
  productName: string;
  productKind: number | null;
  productCode: string;
  submitDate?: string;
  tagIds: number[];
  productionSchedules: ProductionSchedule[];
};

export type BasisForSchedules = {
  id: number;
  name: string;
  nameAbbreviation?: string; // Add this if it's optional
  nameKana?: string; // Add this if it's optional
  productCode: string;
  safetyStockDays?: string; // Add this if it's optional
  tagIds: number[];
  numberOfDaysParamsMonth: number;
  productionSchedules: MonthlyData[];
  productProductionResults: MonthlyData[];
  demandForecasts: MonthlyData[];
  shippings: MonthlyData[];
  safetyStocks: MonthlyData[];
  estimatedProductStocks: MonthlyData[];
  actualProductStocks: MonthlyData[];
};

export type SalesInformation = {
  id: number;
  customer: { id: number; name: string };
  employee: { id: number; name: string };
  entryDate: string;
  title: string;
  comment: string;
  details: SalesInformationDetail[];
};

export type SalesInformationDetail = {
  id: number;
  product: { id: number; name: string };
  deliveryDate: string;
  salesStore: { id: number; name: string };
  shippingDate: string;
  primaryEstimatedAmount: number;
  secondaryEstimatedAmount: number;
  fixedAmount: number;
};

export type Customer = {
  id: number;
  name: string;
};

export type SalesStore = {
  id: number;
  name: string;
};

export type MonthlyData = {
  date: string;
  amount: number | null;
  id?: number | '';
  estimatedAmount?: number | null;
  actualAmount?: number | null;
  checkEnabled?: boolean;
  comment?: string;
};

export type FunctionType = {
  id: number;
  type: string;
};

/* eslint-disable-next-line @typescript-eslint/no-explicit-any */
export type Todo = any;

export type ProductsProcess = {
  id: number;
  name: string;
  productId: number;
  productCode: string;
  factoryLineId: number;
  factoryLineIds: number[];
  storerooms: Storeroom[];
};

export type SemiProductsProcess = {
  id: number;
  name: string;
  semiProductCode: string;
  semiProductId: number;
  factoryLineId: number;
  factoryLineIds: number[];
  storerooms: Storeroom[];
};

export type OptionType = {
  code: string;
  label: string;
  value: number;
};

// ラインで使用
export const PRODUCT_KIND = {
  PRODUCT_AND_SEMI_PRODUCT: 0, //完成品、半製品の全て
  PRODUCT: 1, //完成品
  SEMI_PRODUCT: 2, //半製品
} as const;

// タグと在庫室で使用
export const MATERIAL_PRODUCT_KIND = {
  ALL: 0, //完成品、半製品、資材・原材料全て
  PRODUCT: 1, //完成品
  SEMI_PRODUCT: 2, //半製品
  MATERIAL: 3, //資材・原材料
  PRODUCT_AND_SEMI_PRODUCT: 4, //完成品、半製品の全て
} as const;

export const ALLOCATION_TYPE = {
  SALES: 0, //店舗販売
  DISCARD: 1, //廃棄
  TRANSFER: 2, //移動
  CONSUMPTION: 3, //消費
} as const;

export const MATERIAL_TYPE = {
  ROW_MATERIAL: 0, //原材料
  MATERIAL: 1, //資材
  ALL_MATERIAL: 2, //資材・原材料
} as const;

export const MATERIAL_TYPE_LABEL = {
  ROW_MATERIAL: '原材料名',
  MATERIAL: '資材名',
} as const;

export const MATERIAL_ALLOCATION_TYPE = {
  DISCARD: 0,
  TRANSFER: 1,
} as const;

// 従業員の役割
export const ROLE_TYPE = {
  MATERIAL_SETTINGS: 'materialSettings', //資材・原材料管理
} as const;

export type Case = {
  productId: number;
  productName: string;
  caseId: number;
  caseName: string;
  piecesPerBox: number;
  productKind: typeof PRODUCT_KIND[keyof typeof PRODUCT_KIND];
};

export type DateType = {
  id: number;
  name: string;
};

export type AllProduct = {
  allProductId: number;
  expiryDays: number;
  id: number;
  janCode: string;
  name: string;
  nameAbbreviation: string;
  productCode: string;
  productKind: number;
  safetyStockDays: number;
  storeroomIds: number[];
};

export type AllocationType = {
  id: number;
  name: string;
};

export type productStockAllocationDetail = {
  allocationType: string;
  amount: number | null;
  bestBeforeDate: string | null;
  case: number | null;
  piece: number | null;
  caseId: number | null;
  comment: string;
  detailId: number | null;
  toStoreroomId: number | null;
  toStoreroomName: string | null;
  fromStoreroomName: string | null;
};

export type productStockAllocation = {
  date: Date;
  totalAmount: number | null;
  productStockAllocationDetails: productStockAllocationDetail[];
};

export type stockAllocationsAll = {
  id: number;
  name: string;
  nameAbbreviation: string;
  nameKana: string;
  numberOfDaysParamsMonth: number;
  productCode: string | number;
  productKind: string | number;
  storeroomsIds?: number[];
  tagIds?: number[];
  productStockAllocations: productStockAllocation[];
};

export type selectedItem = {
  id: number;
  name: string;
  type: '完' | '半';
  date: Date | string;
  data: productStockAllocationDetail[] | [];
  productStockAllocations: productStockAllocation[] | [];
  labelOfAmount: string;
  storeroomsIds: number[];
};

export type ShippingGroup = {
  id: number;
  name: string;
  code: string;
  shippingTime: string;
};

export type ShippingDestination = {
  value: number;
  label: string;
  code: string;
};

export type LoadOptionsResult = {
  options: ShippingDestination[];
  hasMore: boolean;
  additional: { page: number };
};

export type ShippingDestinationMeta = {
  totalPages: number;
  currentPage: number;
  totalCount: number;
};

export type Additional = { page: number };

export type Accumulator = {
  amounts: string[];
  shippingIds: number[];
  shippingNames: string[];
};

export type updateShipping = {
  id: number;
  amount: number;
};

export type ScannedProductInfo = {
  processId: number | null;
  productId: number | null;
  productName: string;
};

export type scannedProduct = {
  productId: number | null;
  productName: string;
  productCode: string;
};

export type Material = {
  id: number;
  name: string;
  nameAbbreviation: string | null;
  nameKana: string | null;
  orderType: number;
  materialType: number;
  labelOfAmount: string;
  labelOfUnit: string;
  materialCode: string;
  piecesPerBox: number;
  piecesPerUnit: number;
  pricePerUnit: number;
  stockLastInputAt: string;
  alertThreshold: number;
  defaultOrderAmount: number;
  tagIds: number[];
  storeroomIds: number[];
};

export const initializeMaterial = {
  id: 0,
  name: '',
  nameAbbreviation: null,
  nameKana: null,
  orderType: 0,
  materialType: 0,
  labelOfAmount: '',
  labelOfUnit: '',
  materialCode: '',
  piecesPerBox: 1,
  piecesPerUnit: 1,
  pricePerUnit: 0,
  stockLastInputAt: '',
  alertThreshold: 0,
  defaultOrderAmount: 0,
  tagIds: [],
  storeroomIds: [],
};

export type OrderStatus = {
  id: number;
  name: string;
};

export type scannedMaterial = {
  materialId: number | null;
  materialName: string;
};

export type Supplier = {
  id: number;
  name: string;
  code: string;
  description: string;
  honorific: string;
  nameKana: string;
  orderType: string;
};

export type ErrorResponse = {
  message: string;
};
