import { axiosInstance } from '@lib/pc/common/api/axiosConfig';
import { useQuery } from 'react-query';
import humps from 'humps';
import { Todo } from '@lib/common/type';

const useSemiProducts = () => {
  const { data, refetch } = useQuery(
    ['semi_products_for_settings'],
    async () => {
      const data = await axiosInstance().get(`/api/v1/settings/semi_products`);
      return data;
    }
  );
  const semiProducts = humps.camelizeKeys(data?.data.semi_products) as Todo;

  return { semiProducts, refetch };
};

export default useSemiProducts;
