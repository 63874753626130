import styled from 'styled-components';

export const ButtonContainer = styled.div<{
  padding?: string;
  width?: string;
  buttonType?: string;
}>`
  display: flex;
  align-items: center;
  justify-content: center;
  width: ${(props) => (props.width ? props.width : '100px')};
  height: 45px;
  padding: ${(props) => (props.padding ? props.padding : '')};
  border-radius: 5px;
  color: ${(props) =>
    props.buttonType === 'blue'
      ? props.theme.palette.white
      : props.buttonType === 'delete'
      ? props.theme.palette.error
      : props.theme.palette.primary.main};
  background: ${(props) =>
    props.buttonType === 'delete'
      ? props.theme.palette.white
      : props.buttonType === 'blue'
      ? props.theme.palette.primary.main
      : props.theme.palette.white};
  border: ${(props) =>
    `${
      props.buttonType === 'blue'
        ? props.theme.palette.primary.main
        : props.buttonType === 'delete'
        ? props.theme.palette.error
        : props.theme.palette.primary.main
    } 1px solid`};
  font-weight: bold;
  font-size: 16px;
  margin-left: ${(props) =>
    props.buttonType === 'delete' || props.buttonType === 'orderCompleted'
      ? 'auto'
      : ''};
  cursor: pointer;
`;

export const OperationPanel = styled.div`
  padding: 0.5rem 0.5rem 0 0.5rem;
`;

export const ButtonArea = styled.div`
  width: 100%;
  display: flex;
  /* justify-content: center; */
  gap: 1rem;
  padding: 1.5rem;
  background-color: white;
`;

export const KindLabel = styled.div`
  font-size: 0.75rem;
  padding: 2px 6px;
  border-radius: 2px;
  border: 1px solid ${(props) => props.theme.palette.primary.main};
  color: ${(props) => props.theme.palette.primary.main};
  white-space: nowrap;
  height: 18px;
  font-weight: bold;
`;

export const KindLabelFailed = styled(KindLabel)`
  border: 1px solid ${(props) => props.theme.palette.error};
  color: ${(props) => props.theme.palette.error};
`;

export const KindLabelFill = styled(KindLabel)`
  background: ${(props) => props.theme.palette.primary.main};
  color: ${(props) => props.theme.palette.white};
`;

export const TelWrapper = styled.div`
  text-align: end;
  padding-right: 1rem;
  background-color: ${(props) => props.theme.palette.white};
  color: ${(props) => props.theme.palette.white};
`;

export const OperationPanelUpper = styled.div`
  display: flex;
  justify-content: space-between;
  padding: 1rem 1.5rem;
  background-color: ${(props) => props.theme.palette.white};
`;
export const OperationPanelDestName = styled.div`
  font-size: 1.5rem;
  font-weight: bold;
`;
export const OrderAndFaxStatus = styled.div`
  display: flex;
  justify-content: end;
  width: 140px;
  gap: 15px;
`;
