import React, {
  FC,
  useCallback,
  useEffect,
  useState,
  useRef,
  useMemo,
  MutableRefObject,
} from 'react';
import {
  ListOnItemsRenderedProps,
  FixedSizeList as List,
  GridOnItemsRenderedProps,
  FixedSizeGrid as Grid,
} from 'react-window';
import moment from 'moment';
import humps from 'humps';
import InfiniteLoader from 'react-window-infinite-loader';
import useSWR, { useSWRConfig } from 'swr';
import * as S from './TableWithFixedSidebar.styles';
import Filter from '@assets/icons/filter_list';
import { ReactComponent as FilteredIcon } from '@assets/icons/filtered.svg';
import Overlay from '@components/elements/backdrops/Overlay/Overlay';
import TagMenu from '@components/modules/common/TagMenu/TagMenu';
import TagListModal from '@components/modules/common/TagListModal/TagListModal';
import DisplaySelectModal from '@components/modules/common/DisplaySelectModal/DisplaySelectModal';
import ErrorMsgPopUpForStock from '@components/modules/pc/stock_status/ErrorMsgPopUpForStock/ErrorMsgPopUpForStock';
import MemorizedListCell from '@components/modules/pc/production_schedule/MemorizedListCell/MemorizedListCell';
import ProductionScheduleEditable from '@components/modules/pc/production_schedule/ProductionScheduleEditable/ProductionScheduleEditable';
import ProductionScheduleMemorizedListCell from '@components/modules/pc/production_schedule/ProductionScheduleMemorizedListCell/ProductionScheduleMemorizedListCell';
import TableNameRow from '@components/modules/pc/production_schedule/TableNameRow/TableNameRow';
import TableTotalRow from '@components/modules/pc/production_schedule/TableTotalRow/TableTotalRow';
import LoadingOverlay from '@components/modules/common/LoadingOverlay/LoadingOverlay';
import {
  generateMonthlyDateArray,
  isIncludes,
} from '@lib/pc/production_schedule/functions';
import { containsNonNumeric } from '@lib/common/functions';
import { CircularProgress } from '@material-ui/core';
import type {
  Todo,
  //BasisForSchedules,
  MonthlyData,
  FunctionType,
} from '@lib/common/type';
import type { SelectedProduct } from '@lib/pc/production_schedule/type';
import { PRODUCT_KIND } from '@lib/common/type';
import { axiosInstance } from '@lib/pc/common/api/axiosConfig';
import { useUpdateProductionScheduleMutation } from '@lib/pc/production_schedule/hooks';

const BATCH_SIZE = 10;
const numColumns = 1;

type Props = {
  selectedDate: Todo;
  selected: SelectedProduct | boolean | null;
  selectIndex: number;
  setSelectIndex: (index: number) => void;
  handleSelectedProduct: (
    d: Todo,
    productName: string,
    indexId: string
  ) => void;
  handleOpenProductDetail: (
    id: number,
    name: string,
    safetyStockDays: string
  ) => void;
  directMode: boolean;
  updateCount: number;
  setUpdateCount: (updateCount: number) => void;
  selectedFactoryId: number;
  selectedFactoryLineId: number;
  directUpdating: boolean;
  setDirectUpdating: (directUpdating: boolean) => void;
};

const TableWithFixedSidebar: FC<Props> = ({
  selectedDate,
  selected,
  selectIndex,
  setSelectIndex,
  handleSelectedProduct,
  handleOpenProductDetail,
  directMode,
  updateCount,
  setUpdateCount,
  selectedFactoryId,
  selectedFactoryLineId,
  directUpdating,
  setDirectUpdating,
}: Props) => {
  const [searchWord, setSearchWord] = useState('');
  const [tagMenuActive, setTagMenuActive] = useState(false);
  const [tagListModalActive, setTagListModalActive] = useState(false);
  const [displaySelectModalActive, setDisplaySelectModalActive] =
    useState(false);
  const [selectedTags, setSelectedTags] = useState<Todo>([]);
  const selectedTagIds = selectedTags.map((item: Todo) => item['id']);
  const functionTypeObj = [
    { id: 0, type: '生産計画' },
    { id: 1, type: '生産実績' },
    { id: 2, type: '需要予測' },
    { id: 3, type: '出荷実績' },
    { id: 4, type: '理想在庫' },
    { id: 5, type: '予定在庫' },
    { id: 6, type: '実在庫' },
  ];
  const timerRef = useRef<NodeJS.Timeout | null>(null);
  const isInitialLoadComplete = useRef(false);
  const [selectedTypes, setSelectedTypes] =
    useState<FunctionType[]>(functionTypeObj);
  const selectedTypeIds = selectedTypes.map((item: FunctionType) => item['id']);

  const today = new Date();
  today.setHours(0, 0, 0, 0);

  const handleSearch = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSearchWord(e.target.value);
  };

  const [inputParams, setInputParams] = useState<Todo>({
    actualAmount: '',
    comment: '',
    checkEnabled: false,
  });

  const [errMsg, setErrMsg] = useState('');

  // セルで直接変更した時→true
  const [isChanged, setIsChanged] = useState(false);

  // 直接入力モードと表示間で受け渡しする現在のセルの位置管理
  const [currentRowIndex, setCurrentRowIndex] = useState(0);
  const [currentCellIndex, setCurrentCellIndex] = useState(0);

  const [products, setProducts] = useState<Todo[]>();
  const [hasNextPage, setHasNextPage] = useState(true); // To track if there are more items to load
  const [isNextPageLoading, setIsNextPageLoading] = useState(false); // Track if the next page is loading
  const [currentPage, setCurrentPage] = useState(0);
  const [metaCurrentPage, setMetaCurrentPage] = useState(0);
  const [metaTotalPages, setMetaTotalPages] = useState(0);

  const nameListRef = useRef<List | null>(
    null
  ) as MutableRefObject<List | null>;

  const gridRef = useRef<List | null>(null) as MutableRefObject<Grid | null>;
  const totalListRef = useRef<List | null>(
    null
  ) as MutableRefObject<List | null>;

  const onNameListScroll = ({ scrollOffset }: { scrollOffset: number }) => {
    if (gridRef.current) {
      gridRef.current.scrollTo({ scrollTop: scrollOffset });
    }
    if (totalListRef.current) {
      totalListRef.current.scrollTo(scrollOffset);
    }
  };

  const onGridScroll = ({ scrollTop }: { scrollTop: number }) => {
    if (nameListRef.current) {
      nameListRef.current.scrollTo(scrollTop);
    }
    if (totalListRef.current) {
      totalListRef.current.scrollTo(scrollTop);
    }
  };

  const onTotalListScroll = ({ scrollOffset }: { scrollOffset: number }) => {
    if (nameListRef.current) {
      nameListRef.current.scrollTo(scrollOffset);
    }
    if (gridRef.current) {
      gridRef.current.scrollTo({ scrollTop: scrollOffset });
    }
  };

  const handleSuccess = () => {
    setInputParams({
      ...inputParams,
      actualAmount: '',
      comment: '',
      checkEnabled: false,
    });
    setIsChanged(false);
    setDirectUpdating(false);
  };

  const updateProductionScheduleMutation = useUpdateProductionScheduleMutation(
    0,
    undefined,
    handleSuccess,
    setErrMsg
  );

  const handleUpdate = (inputParams: Todo) => {
    if (isChanged) {
      if (containsNonNumeric(inputParams.actualAmount)) {
        setErrMsg('半角数字のみ入力してください4');
      } else {
        setDirectUpdating(true);
        updateProductionScheduleMutation.mutate(inputParams);
      }
    }
  };

  const fetchData = useCallback(
    async (currentPage: number) => {
      try {
        const response = await axiosInstance().get(
          `/api/v1/production_schedules`,
          {
            params: {
              date: moment(selectedDate).format('YYYYMMDD'),
              factory_line_id: selectedFactoryLineId,
              factory_id: selectedFactoryId,
              page: currentPage + 1,
              search: searchWord,
              tag_ids: selectedTags
                ? selectedTags.map((item: Todo) => item['id'])
                : [],
              per_page: BATCH_SIZE,
            },
          }
        );
        const newItems = humps.camelizeKeys(
          response?.data?.basis_for_production_schedules
        );
        setMetaCurrentPage(response?.data?.meta.current_page);
        setMetaTotalPages(response?.data?.meta.total_pages);
        return newItems;
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    },
    [
      selectedDate,
      selectedFactoryLineId,
      selectedFactoryId,
      selectedTags,
      searchWord,
    ]
  );

  const loadMoreItems = useCallback(async () => {
    if (!isInitialLoadComplete.current) return;

    if (isNextPageLoading || !hasNextPage) {
      return;
    }

    setIsNextPageLoading(true);
    if (metaCurrentPage < metaTotalPages) {
      const newProducts: Todo[] = (await fetchData(currentPage)) || [];

      if (newProducts?.length === 0) {
        setHasNextPage(false); // No more items to load
      } else {
        setProducts((prev) => [...(prev || []), ...newProducts]);
        setCurrentPage((prev) => prev + 1); // Move to the next page
      }
    }

    setIsNextPageLoading(false);
  }, [currentPage, hasNextPage, isNextPageLoading, metaTotalPages, fetchData]);

  const loadNothing = useCallback(async () => {
    undefined;
  }, []);

  const { data: swr_schedule } = useSWR('schedule');
  const { data: swr_stock } = useSWR('stock');
  const { mutate } = useSWRConfig();

  const clearAll = () => {
    setHasNextPage(true);
    setIsNextPageLoading(false);
    setCurrentPage(0);
    setProducts([]);
    mutate('schedule', null, false);
    mutate('stock', null, false);
  };

  const fetchFilteredProducts = async () => {
    const filteredProducts = await fetchData(0);
    setProducts(filteredProducts || []);
    setCurrentPage(1);
  };

  const clearFetchedInfo = () => {
    setMetaCurrentPage(0);
    setMetaTotalPages(0);
  };

  // gridの横幅算出
  const calculatedColumnWidth =
    (products ? products[0]?.numberOfDaysParamsMonth + 1 : 31) * 91;

  useEffect(() => {
    fetchFilteredProducts();

    timerRef.current = setTimeout(() => {
      isInitialLoadComplete.current = true;
    }, 1500);

    return () => {
      if (timerRef.current) {
        clearTimeout(timerRef.current);
      }
    };
  }, []);

  useEffect(() => {
    if (!isInitialLoadComplete.current) return;

    clearAll();
    fetchFilteredProducts();
  }, [selectedDate, selectedFactoryLineId, selectedFactoryId, selectedTags]);

  useEffect(() => {
    if (!isInitialLoadComplete.current) return;

    clearAll();
    if (timerRef.current) {
      clearTimeout(timerRef.current);
    }

    timerRef.current = setTimeout(() => {
      fetchFilteredProducts();
    }, 2000);

    return () => {
      if (timerRef.current) {
        clearTimeout(timerRef.current);
      }
    };
  }, [searchWord]);

  const isItemLoaded = (index: number) => index < (products?.length || 0);

  useEffect(() => {
    setTagMenuActive(false);
  }, [selectedTagIds.length === 0]);

  // lastセルの判定に使用
  const lastOfProductProductionResults = selectedTypeIds.length === 2;
  const lastOfDemandForecasts =
    selectedTypeIds.length === 2 ||
    (selectedTypeIds.includes(1) && selectedTypeIds.length == 3);
  const lastOfShippings =
    selectedTypeIds.length === 2 ||
    (selectedTypeIds.every((v: number) => v <= 3) &&
      !selectedTypeIds.some((v: number) => v >= 4) &&
      selectedTypeIds.length == 3) ||
    !isIncludes([4, 5, 6], selectedTypeIds);
  const lastOfSafetyStocksComponents =
    selectedTypeIds.length === 2 || !isIncludes([5, 6], selectedTypeIds);
  const lastOfEstimatedProductStocks =
    selectedTypeIds.length === 2 || !selectedTypeIds.includes(6);

  const Cell = ({
    rowIndex,
    columnIndex,
    style,
  }: {
    rowIndex: number;
    columnIndex: number;
    style: React.CSSProperties;
  }) => {
    const index = rowIndex * numColumns + columnIndex;
    const product = useMemo(() => {
      if (!products || index >= products.length || !products[index]) {
        return null;
      }
      return products[index];
    }, [products, index]);

    if (!product) {
      return <div style={style}>Loading...</div>;
    }

    const productionScheduleEditableListCells = useMemo(() => {
      return product.productionSchedules
        .slice(0, -2)
        .map((schedule: MonthlyData, idx: number) => {
          const schedule_result = swr_schedule?.find(
            (item: Todo) =>
              item.product_id === product.id && item.date === schedule.date
          );
          schedule.actualAmount = schedule_result
            ? schedule_result.actual_amount
            : schedule.actualAmount;

          return (
            <ProductionScheduleEditable
              p={product}
              key={`production-schedule-editable-${product.productCode}-${product.name}-${index}-${idx}`}
              currentRowIndex={currentRowIndex}
              setCurrentRowIndex={setCurrentRowIndex}
              currentCellIndex={currentCellIndex}
              setCurrentCellIndex={setCurrentCellIndex}
              selectIndex={selectIndex}
              inputParams={schedule}
              isChanged={isChanged}
              setIsChanged={setIsChanged}
              handleUpdate={handleUpdate}
              setErrMsg={setErrMsg}
              updateCount={updateCount}
              setUpdateCount={setUpdateCount}
              rowIndex={index}
              cellIndex={idx}
              productLength={products ? products.length : 10}
              totalTitle={
                idx ===
                (products ? products[0]?.numberOfDaysParamsMonth : 0) + 1
                  ? '合計'
                  : idx ===
                    (products ? products[0]?.numberOfDaysParamsMonth : 0) + 2
                  ? '前年同月'
                  : ''
              }
            />
          );
        });
    }, []);

    const productionScheduleMemorizedListCells = useMemo(() => {
      return product.productionSchedules
        .slice(0, -2)
        .map((schedule: MonthlyData, idx: number) => {
          const schedule_result = swr_schedule?.find(
            (item: Todo) =>
              item.product_id === product.id && item.date === schedule.date
          );
          schedule.actualAmount = schedule_result?.actual_amount
            ? schedule_result?.actual_amount
            : schedule.actualAmount;
          return (
            <ProductionScheduleMemorizedListCell
              key={`${index}-${product.id}-${idx}`}
              selected={selected}
              rowIndex={index}
              p={product}
              paramsType={schedule}
              cellIndex={idx}
              selectIndex={selectIndex}
              monthlyDateNum={product?.numberOfDaysParamsMonth}
              handleSelectedProduct={handleSelectedProduct}
              currentRowIndex={currentRowIndex}
              setCurrentRowIndex={setCurrentRowIndex}
              currentCellIndex={currentCellIndex}
              setCurrentCellIndex={setCurrentCellIndex}
            />
          );
        });
    }, []);

    // 生産実績
    const productProductionResultsComponents = useMemo(() => {
      return product.productProductionResults
        .slice(0, -2)
        .map((productProductionResult: MonthlyData, idx: number) => {
          return (
            <MemorizedListCell
              key={`${index}-${product.id}-${idx}`}
              index={index}
              p={product}
              paramsType={productProductionResult}
              idx={idx}
              selectIndex={selectIndex}
              last={lastOfProductProductionResults}
              monthlyDateNum={product?.numberOfDaysParamsMonth}
              yellow={true}
            />
          );
        });
    }, []);

    // 需要予測
    const demandForecastsComponents = useMemo(() => {
      return product.demandForecasts
        .slice(0, -2)
        .map((demandForecast: MonthlyData, idx: number) => {
          return (
            <MemorizedListCell
              key={`${index}-${product.id}-${idx}`}
              index={index}
              p={product}
              paramsType={demandForecast}
              idx={idx}
              selectIndex={selectIndex}
              last={lastOfDemandForecasts}
              monthlyDateNum={product?.numberOfDaysParamsMonth}
            />
          );
        });
    }, []);

    // 出荷実績
    const shippingsComponents = useMemo(() => {
      return product.shippings
        .slice(0, -2)
        .map((shipping: MonthlyData, idx: number) => {
          return (
            <MemorizedListCell
              key={`${index}-${product.id}-${idx}`}
              index={index}
              p={product}
              paramsType={shipping}
              idx={idx}
              selectIndex={selectIndex}
              last={lastOfShippings}
              monthlyDateNum={product?.numberOfDaysParamsMonth}
              yellow={true}
            />
          );
        });
    }, []);

    // 理想在庫
    const safetyStocksComponents = useMemo(() => {
      return product.safetyStocks
        .slice(0, -2)
        .map((safetyStock: MonthlyData, idx: number) => {
          return (
            <MemorizedListCell
              key={`${index}-${product.id}-${idx}`}
              index={index}
              p={product}
              paramsType={safetyStock}
              idx={idx}
              selectIndex={selectIndex}
              last={lastOfSafetyStocksComponents}
              monthlyDateNum={product?.numberOfDaysParamsMonth}
            />
          );
        });
    }, []);

    // 予定予測
    const estimatedProductStocksComponents = useMemo(() => {
      return product.estimatedProductStocks
        .slice(0, -2)
        .map((estimatedProductStock: MonthlyData, idx: number) => {
          const result = swr_stock?.find(
            (item: Todo) =>
              item.product_id === product.id &&
              item.date === estimatedProductStock.date
          );
          estimatedProductStock.amount = result
            ? result.amount
            : estimatedProductStock.amount;
          return (
            <MemorizedListCell
              key={`${index}-${product.id}-${idx}`}
              index={index}
              p={product}
              paramsType={estimatedProductStock}
              idx={idx}
              selectIndex={selectIndex}
              last={lastOfEstimatedProductStocks}
              monthlyDateNum={product?.numberOfDaysParamsMonth}
              yellow={true}
            />
          );
        });
    }, []);

    const actualProductStocksComponents = useMemo(() => {
      return product.actualProductStocks
        .slice(0, -2)
        .map((actualProductStock: MonthlyData, idx: number) => {
          return (
            <MemorizedListCell
              key={`${index}-${product.id}-${idx}`}
              index={index}
              p={product}
              paramsType={actualProductStock}
              idx={idx}
              selectIndex={selectIndex}
              last={true}
              monthlyDateNum={product?.numberOfDaysParamsMonth}
            />
          );
        });
    }, []);

    const memoizedCell = useMemo(() => {
      return (
        <React.Fragment key={`${index}-${product.productCode}-product`}>
          <div style={style}>
            <S.Matrix>
              {directMode && !tagMenuActive ? (
                <S.ListRow key={`${index}-${product.name}`} yellow={false}>
                  {productionScheduleEditableListCells}
                </S.ListRow>
              ) : (
                <S.ListRow key={`${index}-${product.id}-tmp`} yellow={false}>
                  {productionScheduleMemorizedListCells}
                </S.ListRow>
              )}
            </S.Matrix>
            <S.ListRow
              notDisplay={!selectedTypeIds.includes(1) ? true : false}
              yellow={true}
              className="list-row"
            >
              {selectedTypeIds.includes(1) &&
                productProductionResultsComponents}
            </S.ListRow>
            <S.ListRow
              notDisplay={!selectedTypeIds.includes(2) ? true : false}
              yellow={false}
            >
              {selectedTypeIds.includes(2) && demandForecastsComponents}
            </S.ListRow>
            <S.ListRow
              notDisplay={!selectedTypeIds.includes(3) ? true : false}
              yellow={true}
            >
              {selectedTypeIds.includes(3) && shippingsComponents}
            </S.ListRow>
            <S.ListRow
              notDisplay={!selectedTypeIds.includes(4) ? true : false}
              yellow={false}
            >
              {selectedTypeIds.includes(4) && safetyStocksComponents}
            </S.ListRow>
            <S.ListRow
              notDisplay={!selectedTypeIds.includes(5) ? true : false}
              yellow={true}
            >
              {selectedTypeIds.includes(5) && estimatedProductStocksComponents}
            </S.ListRow>
            <S.ListRow
              notDisplay={!selectedTypeIds.includes(6) ? true : false}
              yellow={false}
            >
              {selectedTypeIds.includes(6) && actualProductStocksComponents}
            </S.ListRow>
          </div>
        </React.Fragment>
      );
    }, []);

    return memoizedCell;
  };

  // InfiniteLoaderのonItemsRenderedプロパティの型を定義
  type InfiniteLoaderOnItemsRenderedProps = {
    visibleStartIndex: number;
    visibleStopIndex: number;
  };

  // ラッパー関数を作成
  const onItemsRenderedWrapper =
    (onItemsRendered: (props: InfiniteLoaderOnItemsRenderedProps) => void) =>
    (gridProps: GridOnItemsRenderedProps) => {
      // GridのpropsをInfiniteLoaderの期待する形式に変換
      onItemsRendered({
        visibleStartIndex:
          gridProps.visibleRowStartIndex * numColumns +
          gridProps.visibleColumnStartIndex,
        visibleStopIndex:
          gridProps.visibleRowStopIndex * numColumns +
          gridProps.visibleColumnStopIndex,
      });
    };

  return (
    <>
      <div id="whole-wrapper" className="whole-wrapper">
        <S.ListWrapper className="list-wrapper">
          <S.ListSidebarFilter className="list-sidebar-label">
            <S.SearchArea>
              <S.ListLeftHeadCell
                onClick={() => setTagMenuActive(!tagMenuActive)}
                isBlue={searchWord !== '' || selectedTagIds.length > 0}
              >
                <span className="filter-product">
                  {searchWord !== '' || selectedTagIds.length > 0 ? (
                    <>
                      <FilteredIcon />
                      <div className="filter_text">商品絞込み中</div>
                    </>
                  ) : (
                    <>
                      <Filter />
                      <div className="filter_text">商品の絞込</div>
                    </>
                  )}
                </span>
              </S.ListLeftHeadCell>
              <S.DisplayButton
                onClick={() =>
                  setDisplaySelectModalActive(!displaySelectModalActive)
                }
                isBlue={selectedTypeIds.length !== 7}
              >
                <span className="filter-product">
                  {selectedTypeIds.length !== 7 ? (
                    <>
                      <FilteredIcon />
                      <div className="filter_text">選択中</div>
                    </>
                  ) : (
                    <>
                      <Filter />
                      <div className="filter_text">表示選択</div>
                    </>
                  )}
                </span>
              </S.DisplayButton>
            </S.SearchArea>
          </S.ListSidebarFilter>
          {/* <S.ListBody className={selected && !directMode ? 'move-to-left' : ''}> */}
          <S.ListBody>
            <S.HeaderListRow className="list-row">
              {products &&
                products.length > 0 &&
                generateMonthlyDateArray(
                  products[0]?.numberOfDaysParamsMonth,
                  selectedDate,
                  selectIndex
                ).map((p, i) => {
                  return (
                    // 月日、合計、前年同月
                    <S.ListHeadCell
                      key={`${i}-${p.productionDate}`}
                      index={i}
                      numOfIndex={products[0]?.numberOfDaysParamsMonth}
                      highLighten={p.isToday}
                      dayOfWeek={moment(p.productionDate).format('ddd')}
                      onClick={() => {
                        setSelectIndex(i);
                      }}
                    >
                      {i === 0
                        ? moment(p.productionDate).format('M/D(ddd)')
                        : i === products[0]?.numberOfDaysParamsMonth + 1
                        ? '合計'
                        : i === products[0]?.numberOfDaysParamsMonth + 2
                        ? '前年同月'
                        : moment(p.productionDate).format('DD(ddd)')}
                    </S.ListHeadCell>
                  );
                })}
            </S.HeaderListRow>
          </S.ListBody>
          <S.VirtualizedList
            productsExist={products && products.length > 0 ? true : false}
          >
            {products ? (
              <>
                <InfiniteLoader
                  isItemLoaded={isItemLoaded} // Checks if an item is loaded
                  itemCount={
                    hasNextPage ? products.length + 1 : products.length
                  }
                  loadMoreItems={loadNothing} // Function to fetch more data
                  threshold={3}
                >
                  {({
                    onItemsRendered,
                    ref,
                  }: {
                    onItemsRendered: (props: ListOnItemsRenderedProps) => void;
                    ref: React.Ref<List | null>;
                  }) => (
                    <S.FixedSizeListWrapper>
                      {products.length > 0 ? (
                        <S.StyledList
                          height={1300}
                          itemCount={products.length} // Number of items loaded so far
                          itemSize={selectedTypeIds.length * 30}
                          width={299}
                          onItemsRendered={onItemsRendered}
                          ref={(listInstance) => {
                            if (typeof ref === 'function') {
                              ref(listInstance);
                            }
                            nameListRef.current = listInstance;
                          }}
                          onScroll={onNameListScroll}
                          className="hidden-scrollbar"
                        >
                          {({ index, style }) => (
                            <TableNameRow
                              index={index}
                              style={style}
                              products={products}
                              handleOpenProductDetail={handleOpenProductDetail}
                              selectedTypeIds={selectedTypeIds}
                            />
                          )}
                        </S.StyledList>
                      ) : (
                        <S.NoProductsToShowNameArray />
                      )}
                    </S.FixedSizeListWrapper>
                  )}
                </InfiniteLoader>
                <InfiniteLoader
                  isItemLoaded={isItemLoaded} // Checks if an item is loaded
                  itemCount={
                    hasNextPage ? products.length + 1 : products.length
                  }
                  loadMoreItems={loadMoreItems} // Function to fetch more data
                  threshold={3}
                >
                  {({
                    onItemsRendered,
                    ref,
                  }: {
                    onItemsRendered: (
                      props: InfiniteLoaderOnItemsRenderedProps
                    ) => void;
                    ref: React.Ref<Grid | null>;
                  }) => (
                    <div>
                      {products.length > 0 ? (
                        <S.StyledGrid
                          className="grid-class"
                          columnCount={numColumns}
                          columnWidth={calculatedColumnWidth}
                          height={1300}
                          rowCount={Math.ceil(products.length / numColumns)}
                          rowHeight={selectedTypeIds.length * 30}
                          width={calculatedColumnWidth}
                          onItemsRendered={onItemsRenderedWrapper(
                            onItemsRendered
                          )}
                          ref={(gridInstance) => {
                            if (typeof ref === 'function') {
                              ref(gridInstance);
                            }
                            gridRef.current = gridInstance;
                          }}
                          onScroll={onGridScroll}
                        >
                          {Cell}
                        </S.StyledGrid>
                      ) : (searchWord !== '' || selectedTagIds.length > 0) &&
                        products.length === 0 ? (
                        <S.NoProductsToShow>
                          完成品が見つかりませんでした。
                          <div>条件を変更して再度検索してください。</div>
                        </S.NoProductsToShow>
                      ) : (
                        <S.CircularIconWrapper>
                          <CircularProgress style={{ color: '#64b2f9' }} />
                        </S.CircularIconWrapper>
                      )}
                    </div>
                  )}
                </InfiniteLoader>
                <InfiniteLoader
                  isItemLoaded={isItemLoaded} // Checks if an item is loaded
                  itemCount={
                    hasNextPage ? products.length + 1 : products.length
                  }
                  loadMoreItems={loadNothing} // Function to fetch more data
                  threshold={3}
                >
                  {({
                    onItemsRendered,
                    ref,
                  }: {
                    onItemsRendered: (props: ListOnItemsRenderedProps) => void;
                    ref: React.Ref<List | null>;
                  }) => (
                    <S.FixedSizeListTotalWrapper>
                      <List
                        height={1300}
                        itemCount={products.length} // Number of items loaded so far
                        itemSize={selectedTypeIds.length * 30}
                        width={182}
                        onItemsRendered={onItemsRendered}
                        ref={(listInstance) => {
                          if (typeof ref === 'function') {
                            ref(listInstance);
                          }
                          totalListRef.current = listInstance;
                        }}
                        onScroll={onTotalListScroll}
                      >
                        {({ index, style }) => (
                          <TableTotalRow
                            index={index}
                            style={style}
                            products={products}
                            selectedTypeIds={selectedTypeIds}
                            lastOfShippings={lastOfShippings}
                            lastOfProductProductionResults={
                              lastOfProductProductionResults
                            }
                            lastOfSafetyStocksComponents={
                              lastOfSafetyStocksComponents
                            }
                            lastOfDemandForecasts={lastOfDemandForecasts}
                            lastOfEstimatedProductStocks={
                              lastOfEstimatedProductStocks
                            }
                            selectIndex={selectIndex}
                          />
                        )}
                      </List>
                    </S.FixedSizeListTotalWrapper>
                  )}
                </InfiniteLoader>
              </>
            ) : (
              <S.CircularIconWrapper>
                <CircularProgress style={{ color: '#64b2f9' }} />
              </S.CircularIconWrapper>
            )}
          </S.VirtualizedList>
        </S.ListWrapper>
      </div>
      {tagMenuActive && (
        <TagMenu
          searchParam={searchWord}
          selectedTags={selectedTags}
          setSelectedTags={(tags) => setSelectedTags(tags)}
          setOpenTagModal={() => setTagListModalActive(true)}
          handleChange={handleSearch}
          deleteSearchParam={() => setSearchWord('')}
          clearFetchedInfo={clearFetchedInfo}
        />
      )}
      {tagListModalActive && (
        <TagListModal
          selectedTags={selectedTags}
          setSelectedTags={(tags) => setSelectedTags(tags)}
          tagListModalActive={tagListModalActive}
          handleClose={() => {
            setTagListModalActive(false);
            setTagMenuActive(false);
          }}
          productKind={PRODUCT_KIND.PRODUCT}
        />
      )}
      {displaySelectModalActive && (
        <DisplaySelectModal
          notSelectableObj={'生産計画'}
          selectedTypes={selectedTypes}
          functionTypeObj={functionTypeObj}
          setSelectedTypes={(type) => setSelectedTypes(type)}
          displaySelectModalActive={displaySelectModalActive}
          handleClose={() => setDisplaySelectModalActive(false)}
        />
      )}
      {tagMenuActive && (
        <Overlay
          zIndex={9997}
          handleClick={() => {
            setTagMenuActive(false);
          }}
        />
      )}
      {directUpdating && <LoadingOverlay zIndex={9997} dark />}
      <ErrorMsgPopUpForStock
        errMsg={errMsg}
        handleClose={() => setErrMsg('')}
        fromPc={true}
        width={'20rem'}
      />
    </>
  );
};

export default TableWithFixedSidebar;
