import styled from 'styled-components';

export const ClearButton = styled.button`
  padding: 4px;
  background: none;
  float: right;
`;

export const TagDeleteButton = styled.button`
  padding: 0;
  background: none;
`;

export const TagName = styled.span`
  margin-left: 0.625rem;
`;

export const AddTagLink = styled.div`
  display: inline-block;
`;

export const TagItem = styled.div`
  display: inline-block;
`;

export const ListItemInner = styled.div<{ isAddTag?: boolean }>`
  display: flex;
  align-items: center;
  margin-right: 0.5rem;
  margin-bottom: 0.5rem;
  padding: 0.5rem;
  border: 1px solid ${(props) => props.theme.palette.sand};
  border-radius: 0.25rem;
  font-weight: 500;
  background: ${(props) =>
    props.isAddTag ? props.theme.palette.white : '#f3f6f7'};
`;

export const PlusIcon = styled.div`
  svg {
    width: 1.25rem;
    height: 1.25rem;
  }
`;

export const TagIcon = styled.div`
  margin-left: 0.25rem;

  svg {
    width: 1.25rem;
    height: 1.25rem;
  }
`;
