import { axiosInstance } from '@lib/pc/common/api/axiosConfig';
import { useQuery } from 'react-query';
import humps from 'humps';
import type { FactoryLine } from '@lib/common/type';

const useFactoryLines = (productKind: string | number) => {
  return useQuery(['factory_lines', productKind], async () => {
    const { data } = await axiosInstance().get(
      `/api/v1/factory_lines?product_kind=${productKind}`
    );
    return humps.camelizeKeys(data.factory_lines) as FactoryLine[];
  });
};

export default useFactoryLines;
