import { axiosInstance } from '@lib/pc/common/api/axiosConfig';
import { useQuery } from 'react-query';
import humps from 'humps';
import type { Todo } from '@lib/common/type';

const useSuppliers = () => {
  return useQuery('suppliers', async () => {
    const { data } = await axiosInstance().get('/api/v1/suppliers');
    return humps.camelizeKeys(data.suppliers_response) as unknown as Todo;
  });
};

export default useSuppliers;
