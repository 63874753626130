import styled from 'styled-components';

export const Wrapper = styled.div<{
  bgColor?: string;
}>`
  width: 100vw;
  height: calc(100vh - 193px);
  padding: 16px 80px 150px;
  overflow: scroll;
  background-color: ${(props) => props.theme.palette.beige};
  .go-back {
    width: 160px;
    display: flex;
    padding-bottom: 32px;
    cursor: pointer;
    color: ${(props) => props.theme.palette.blue};
    > span {
      padding-top: 8px;
      padding-left: 10px;
    }
  }
`;

/**
 * 各商品の情報
 */
export const ProductMasterEditContents = styled.div`
  .product-info {
    display: flex;
    /* 商品画像 **********/
    .product-img {
      grid-area: product-img;
      width: 60px;
      height: 60px;
      background-color: #ececec;
      display: flex;
      justify-content: center;
      align-items: center;
      .img {
        img {
          width: 60px;
          height: 60px;
          object-fit: contain;
        }
      }
    }
    .name {
      display: flex;
      align-items: center;
      padding-left: 11px;
      font-size: 24px;
    }
  }

  /* 基本情報 **********/
  /* タグ **********/
  .tags-container {
    grid-area: tags-container;
    margin-top: 24px;
    /* リスト */
    .tags-list {
      /* ヘッダー */
      &__header {
        display: flex;
        padding: 24px 0;
        &--title {
          display: flex;
          justify-content: center;
          align-items: center;
          font-weight: bold;
          padding-right: 12px;
        }
        &--edit-btn {
          display: flex;
          align-items: center;
          margin-left: 8px;
          color: ${(props) => props.theme.palette.primary.main};
          cursor: pointer;
          .icon {
            margin-right: 8px;
          }
        }
        &--display-switching {
          margin-left: auto;
          display: flex;
          align-items: center;
        }
      }
      /* リスト */
      &__lists {
        display: flex;
        flex-wrap: wrap;
        .tag {
          margin-right: 16px;
          margin-bottom: 16px;
          padding: 12px 16px;
          border: solid 1px #7bb5df;
          color: #7bb5df;
          border-radius: 21px;
          cursor: pointer;
          /* 選択中 */
          &.selected {
            background-color: #7bb5df;
            color: ${(props) => props.theme.palette.white};
            font-weight: bold;
          }
        }
      }
    }
  }
`;

export const ButtonContainer = styled.div`
  position: fixed;
  width: 100vw;
  bottom: 0;
  padding: 22px 0;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #f8f7f4;
  & > button {
    width: 136px;
    &.disabled {
      background: #e0e0e0;
      border-color: #e0e0e0;
      color: #6b675c;
      cursor: initial;
    }
  }
`;

export const Checkbox = styled.input`
  opacity: 0;
  position: absolute;
  cursor: pointer;
`;

export const TagName = styled.span<{
  defaultChecked?: boolean;
}>`
  display: flex;
  align-items: center;
  font-size: 1rem;
  line-height: 1rem;
  color: ${(props) => props.theme.palette.baseBlack};
  ::before {
    display: inline-block;
    content: '';
    width: 1.125rem;
    height: 1.125rem;
    margin-right: 8px;
    background-image: ${(props) =>
      props.defaultChecked
        ? "url('data:image/svg+xml;charset=utf8,%3Csvg%20width%3D%2218%22%20height%3D%2218%22%20viewBox%3D%220%200%2018%2018%22%20fill%3D%22none%22%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%3E%3Cpath%20d%3D%22M16%200H2C0.89%200%200%200.9%200%202V16C0%2017.1%200.89%2018%202%2018H16C17.11%2018%2018%2017.1%2018%2016V2C18%200.9%2017.11%200%2016%200ZM7%2014L2%209L3.41%207.59L7%2011.17L14.59%203.58L16%205L7%2014Z%22%20fill%3D%22%232BA9D1%22%2F%3E%3C%2Fsvg%3E')"
        : "url('data:image/svg+xml;charset=utf8,%3Csvg%20width%3D%2218%22%20height%3D%2218%22%20viewBox%3D%220%200%2018%2018%22%20fill%3D%22none%22%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%3E%3Cpath%20d%3D%22M16%202V16H2V2H16ZM16%200H2C0.9%200%200%200.9%200%202V16C0%2017.1%200.9%2018%202%2018H16C17.1%2018%2018%2017.1%2018%2016V2C18%200.9%2017.1%200%2016%200Z%22%20fill%3D%22%23C0C0C0%22%2F%3E%3C%2Fsvg%3E')"};
    background-repeat: no-repeat;
    background-position: center;
  }
`;
