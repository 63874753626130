import { FC, useState, useEffect } from 'react';
import moment from 'moment';
import * as S from './RightSlideSidebar.styles';
import ScrollToTop from '@components/modules/common/ScrollToTop/ScrollToTop';
import ProductProductionResultForm from '@components/modules/pc/product_production_result/ProductProductionResultForm/ProductProductionResultForm';
import type { Product, Todo } from '@lib/common/type';
import { selectedItem } from '@lib/pc/stock_status/type';
import ConfirmPopUp from '@components/modules/common/ConfirmPopUp/ConfirmPopUp';

type Props = {
  productionResultsInProducts: Product[];
  rightSlideSidebarActive: boolean;
  selectedProduct: selectedItem | null;
  confirmMsg: string;
  edited: boolean;
  popUp: boolean;
  editedStates: Todo;
  refetch: () => void;
  setRightSlideSidebarActive: (rightSlideSidebarActive: boolean) => void;
  setConfirmMsg: (confirmMsg: string) => void;
  setEdited: (edited: boolean) => void;
  setPopUp: (popUp: boolean) => void;
  setEditedStates: (editedStates: Todo) => void;
};

const RightSlideSidebar: FC<Props> = ({
  productionResultsInProducts,
  rightSlideSidebarActive,
  selectedProduct,
  confirmMsg,
  edited,
  popUp,
  refetch,
  setRightSlideSidebarActive,
  setConfirmMsg,
  setEdited,
  setPopUp,
  setEditedStates,
}: Props) => {
  const day =
    selectedProduct && selectedProduct.date !== ''
      ? moment(selectedProduct.date)
      : moment();

  const formattedDate = moment(day).format('YYYY/MM/DD (ddd)');

  const selectedProductKind = selectedProduct?.type === '完' ? 1 : 2;
  const product = productionResultsInProducts?.find(
    (product) =>
      product?.id === Number(selectedProduct?.productId) &&
      product?.productKind === selectedProductKind
  );
  const productProductionResults = product?.productProductionResults;
  const productProductionResult = productProductionResults?.find(
    (productProductionResult) =>
      moment(day).isSame(productProductionResult.date, 'day')
  );
  const handleEditChange = (index: number, edited: boolean) => {
    setEditedStates((prev: Todo) => ({ ...prev, [index]: edited }));
  };

  const [apiParams, setApiParams] = useState<Todo>({
    productionResultDetail:
      productProductionResult?.productionResultDetails || '',
  });

  const handleOk = () => {
    setEdited(false);
    setConfirmMsg('');
    setRightSlideSidebarActive(false);
  };

  const handleCancel = () => {
    setConfirmMsg('');
  };

  useEffect(() => {
    if (selectedProduct) {
      setApiParams({
        productionResultDetail: selectedProduct?.data
          ? selectedProduct?.data
          : [],
      });
    }
  }, [selectedProduct]);

  if (!selectedProduct) return null;

  return (
    <>
      <S.Wrapper open={rightSlideSidebarActive}>
        <ScrollToTop />
        <S.ScrollableContainer>
          <S.HeadContainer>
            <S.HeadDate>{formattedDate}</S.HeadDate>
            <S.HeadProductName>
              <S.HeadProductType productKind={selectedProduct?.type}>
                {selectedProduct?.type}
              </S.HeadProductType>
              {selectedProduct?.productName}
            </S.HeadProductName>
          </S.HeadContainer>
          {apiParams.productionResultDetail.length !== 0 ? (
            <S.InputContainer>
              {apiParams.productionResultDetail?.map(
                (detail: Todo, i: number) => {
                  if (!product) return;
                  return (
                    <ProductProductionResultForm
                      key={i}
                      index={i}
                      date={
                        selectedProduct.date ? selectedProduct.date : new Date()
                      }
                      editData={detail}
                      edited={edited}
                      setEdited={setEdited}
                      setRightSlideSidebarActive={setRightSlideSidebarActive}
                      rightSlideSidebarActive={rightSlideSidebarActive}
                      refetch={refetch}
                      popUp={popUp}
                      setPopUp={setPopUp}
                      onEditChange={handleEditChange}
                      confirmMsg={confirmMsg}
                      labelOfAmount={product.labelOfAmount}
                    />
                  );
                }
              )}
            </S.InputContainer>
          ) : (
            <S.InputContainer>
              <S.MessageWrapper>
                <div>選択した日付の生産実績はありません。</div>
              </S.MessageWrapper>
            </S.InputContainer>
          )}
        </S.ScrollableContainer>
        <ConfirmPopUp
          fromPc={true}
          confirmMsg={confirmMsg}
          width="21rem"
          handleOk={handleOk}
          handleCancel={handleCancel}
          zIndex={9998}
        />
      </S.Wrapper>
    </>
  );
};

export default RightSlideSidebar;
