import styled from 'styled-components';

export const Wrapper = styled.div<{
  open: boolean;
}>`
  height: 100%;
  width: 390px;
  position: fixed;
  padding: 1.5rem 1rem;
  top: 0;
  z-index: 9999;
  right: 0;
  background-color: ${(props) => props.theme.palette.beige};
  box-shadow: 0px 24px 38px rgba(0, 0, 0, 0.07),
    0px 9px 46px rgba(0, 0, 0, 0.06), 0px 11px 15px rgba(0, 0, 0, 0.1);
  transform: ${(props) =>
    props.open ? 'translateX(0px)' : 'translateX(390px)'};
  transition: transform 390ms ease-in-out;
`;

export const ScrollableContainer = styled.div`
  overflow-y: auto;
  height: calc(100vh - 4.6rem);
  padding-bottom: 4.6rem;
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none;
  ::-webkit-scrollbar {
    display: none;
  }
`;

export const HeadContainer = styled.div`
  position: relative;
`;

export const HeadDate = styled.div`
  font-weight: 700;
  font-size: 14px;
  line-height: 20px;
`;

export const HeadProductName = styled.div`
  font-weight: 700;
  font-size: 24px;

  margin: 0.5rem 0 1rem 0;
  display: flex;
  align-items: center;
`;

export const HeadProductType = styled.div<{
  productKind: string | undefined;
}>`
  font-size: 0.75rem;
  padding: 0.5rem;
  margin-right: 0.5rem;
  border-radius: 2px;
  border: 1px solid#2BA9D1;
  background: ${(props) => (props.productKind === '完' ? '#2BA9D1' : '#fff')};
  color: ${(props) => (props.productKind === '完' ? '#fff' : '#2BA9D1')};
`;

export const HeadProductMeta = styled.div`
  font-size: 18px;
  line-height: 26px;
  margin-bottom: 0.5rem;
  font-weight: 700;
`;

export const Heading = styled.div`
  font-weight: 700;
  font-size: 18px;
  line-height: 26px;
  margin: 0.5rem 0;
`;

export const DContainer = styled.div`
  display: flex;
  gap: 0.5rem;
  justify-content: left;
  margin: 1.5rem 0;
`;

export const SidebarInputContainer = styled.div`
  display: flex;
  flex-direction: column-reverse;
  gap: 1.5rem;
`;

export const NoteAmountContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: left;
  font-weight: 700;
  padding-top: 1.5rem;
  gap: 1rem;
`;

export const NoteAmountLabel = styled.div`
  width: 3.5rem;
  font-weight: 700;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 5.5rem;
`;

export const NoteAmountInput = styled.textarea`
  background: #ffffff;
  border-radius: 8px;
  width: 100%;
  height: 5.5rem;
  text-align: left;
  padding: 1rem 0.75rem 1rem;

  resize: none;
`;

export const ButtonContainer = styled.div`
  position: fixed;
  bottom: 0;
  left: 0;
  padding: 1rem 0;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  background-color: #f3ede6;

  & > button {
    width: 136px;
  }
`;
