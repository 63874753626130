import styled from 'styled-components';

export const Wrapper = styled.div<{
  display?: boolean;
  width?: string;
}>`
  display: flex;
  position: relative;
  text-align: center;
  width: ${(props) => (props.width ? props.width : '')};
`;
