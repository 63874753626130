import { useEffect, useState } from 'react';
import * as S from './SalesStoreContents.styles';
import ChevronLeft from '@assets/icons/chevron_left';
import { ReactComponent as CircleCloseIcon } from '@assets/icons/circle_close.svg';
import { Button } from '@components/elements/buttons/Button';
import ErrorMsgPopUp from '@components/modules/common/ErrorMsgPopUp/ErrorMsgPopUp';
import Overlay from '@components/elements/backdrops/Overlay/Overlay';
import CustomerSelect from '@components/modules/pc/order_summary/CustomerSelect/CustomerSelect';
import ShippingDestinationSelect from '@components/modules/pc/shipping_status/ShippingDestinationSelect/ShippingDestinationSelect';
import { Customer, Todo } from '@lib/common/type';
import {
  SalesStoreEdit,
  ShippingDestinationInfo,
} from '@lib/pc/settings/sales_store_edit/type';
import useUpdateSalesStore from '@lib/pc/settings/sales_store_edit/useUpdateSalesStore';
import useCreateSalesStore from '@lib/pc/settings/sales_store_create/useCreateSalesStore';

type Props = {
  newData: SalesStoreEdit;
  setNewData: (newData: SalesStoreEdit) => void;
  updating: boolean;
  setUpdating: (updating: boolean) => void;
  enableToSave: boolean;
  setEnableToSave: (enableToSave: boolean) => void;
  setIsChanged: (isChanged: boolean) => void;
  onClickGoBack: () => void;
  refetch?: () => void;
  setUpdatePopUp: (updatePopUp: boolean) => void;
  createdData?: Todo;
  customers?: Customer[];
  shippingDestinationInfo?: ShippingDestinationInfo;
};

const SalesStoreContents = ({
  newData,
  setNewData,
  updating,
  setUpdating,
  enableToSave,
  setEnableToSave,
  setIsChanged,
  onClickGoBack,
  refetch,
  setUpdatePopUp,
  customers,
  shippingDestinationInfo,
}: Props) => {
  const [errMsg, setErrMsg] = useState('');

  // 登録ボタンか保存ボタンか判定
  const createButton = newData?.id === 0 ? true : false;

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const data = { ...newData, [e.target.name]: e.target.value };
    setNewData(data);
    setIsChanged(true);
    setEnableToSave(true);
  };

  const createdSalesStoreData = {
    id: 0,
    customerId: 0,
    customerName: '',
    code: '',
    name: '',
    shippingDestinationName: '',
    shippingDestinationId: 0,
  };

  type Customer = {
    id: number;
    name: string;
  };

  const [selectedShippingDestinationId, setSelectedShippingDestinationId] =
    useState(newData?.shippingDestinationId);
  const [selectedCustomerId, setSelectedCustomerId] = useState(0);
  const [createdData, setCreatedData] = useState(createdSalesStoreData);

  // 更新に使用
  const [newCustomersArray, setNewCustomersArray] = useState<Customer[]>([]);

  const handleSelectShippingDestination = (e: number) => {
    setSelectedShippingDestinationId(e);
    setNewData({
      ...newData,
      shippingDestinationId: e,
      shippingDestinationName: selectedName,
    });
  };

  const handleUpdateSuccess = () => {
    refetch && refetch();
    setUpdatePopUp(true);
    setIsChanged(false);
    setUpdating(false);
    setEnableToSave(false);
    setSelectedShippingDestinationId(0);
  };

  const [selectedName, setSelectedName] = useState('');

  const [isCustomerChanged, setIsCustomerChanged] = useState(false);

  const handleCreatedSalesStore = (
    salesStore: typeof createdSalesStoreData
  ) => {
    setCreatedData(salesStore);
  };

  // 販売店の出荷先更新
  const updateSalesStore = useUpdateSalesStore(
    newData?.id,
    handleUpdateSuccess,
    setErrMsg
  );

  // ***********************
  // [保存ボタン]押下、更新処理
  // ***********************
  const handleSubmit = () => {
    setUpdating(true);
    updateSalesStore.mutate(newData);
  };

  const createSalesStoreMutation = useCreateSalesStore(
    handleCreatedSalesStore,
    handleUpdateSuccess,
    setErrMsg
  );

  // ***********************
  // [登録ボタン]押下、作成処理
  // ***********************
  const handleSubmitCreate = () => {
    setUpdating(true);
    createSalesStoreMutation.mutate(newData);
  };

  const onClickAddButton = () => {
    const selectedCustomer = customers?.find(
      (c: Customer) => c.id === selectedCustomerId
    );

    if (selectedCustomer) {
      newCustomersArray.push({
        id: selectedCustomer.id,
        name: selectedCustomer.name,
      });
    }

    const selectedCustomersIds =
      newCustomersArray &&
      newCustomersArray?.map((item: Customer) => item['id']);

    setNewData({ ...newData, customerIds: selectedCustomersIds });

    setIsCustomerChanged(false);
    setSelectedCustomerId(0);
  };

  const deleteFunction = (i: number) => {
    setIsChanged(true);
    newCustomersArray.splice(i, 1);
    setNewCustomersArray([...newCustomersArray]);
  };

  const onChangeCustomerSelect = (value: number) => {
    setSelectedCustomerId(value);
    setIsCustomerChanged(true);
  };

  // TODO:フロントからcustomersNameを返す
  useEffect(() => {
    if (createdData?.id !== 0) {
      setNewData({
        ...newData,
        customersName: [],
        code: createdData?.code,
        name: createdData?.name,
        shippingDestinationName: createdData?.shippingDestinationName,
        shippingDestinationId: createdData?.shippingDestinationId,
      });
    }
    setCreatedData && setCreatedData(createdData);
  }, [createdData]);

  useEffect(() => {
    setSelectedShippingDestinationId(newData?.shippingDestinationId);
  }, [newData?.shippingDestinationId]);

  useEffect(() => {
    if (newData?.name && newData?.code) {
      setEnableToSave(true);
    }
  }, [newData?.name, newData?.code]);

  return (
    <>
      <S.Wrapper>
        <S.GoBackWrapper onClick={onClickGoBack}>
          <ChevronLeft isBlue={true} />
          <span>一覧画面へ戻る</span>
        </S.GoBackWrapper>

        <S.ShippingGroupAreaDl>
          <dt>販売店コード</dt>
          <dd>
            <S.Input
              type="text"
              inputMode="numeric"
              name="code"
              id="code"
              autoComplete="off"
              value={newData?.code ? newData.code : ''}
              onChange={handleChange}
            />
          </dd>
          <dt>販売店名</dt>
          <dd>
            <S.Input
              type="text"
              inputMode="numeric"
              name="name"
              id="name"
              autoComplete="off"
              value={newData?.name ? newData.name : ''}
              onChange={handleChange}
            />
          </dd>
          <dt>出荷先</dt>
          <dd>
            <S.ShippingDestinationWrapper>
              <S.ShippingDestinationSelectWrapper>
                <ShippingDestinationSelect
                  handleChangeShipping={handleSelectShippingDestination}
                  setPopUpShippingName={setSelectedName}
                  selectedId={
                    selectedShippingDestinationId
                      ? selectedShippingDestinationId
                      : createdData?.shippingDestinationId
                  }
                  shippingDestinationInfo={
                    shippingDestinationInfo ? shippingDestinationInfo : null
                  }
                  useCacheUniqs
                  height="41px"
                  menuTop="34px"
                  searchable={true}
                />
              </S.ShippingDestinationSelectWrapper>
            </S.ShippingDestinationWrapper>
          </dd>
          <dt>得意先名</dt>
          <dd>
            {customers && newData.id === 0 ? (
              <>
                <div
                  style={{ display: 'flex', justifyContent: 'space-between' }}
                >
                  <CustomerSelect
                    options={customers && customers}
                    value={selectedCustomerId}
                    onChange={(value) => {
                      onChangeCustomerSelect(value);
                    }}
                    searchable={true}
                    width={'1000px'}
                  />
                  <S.AddButton>
                    {isCustomerChanged &&
                    selectedShippingDestinationId !== 0 ? (
                      <Button
                        children={'追加'}
                        onClick={() => onClickAddButton()}
                      />
                    ) : (
                      <Button disabled children={'追加'} />
                    )}
                  </S.AddButton>
                </div>
                {newCustomersArray &&
                  newCustomersArray.map((customer, i) => {
                    return (
                      <S.CustomerNameWrapper key={i}>
                        {/* 削除ボタン */}
                        <S.CloseButton onClick={() => deleteFunction(i)}>
                          <CircleCloseIcon />
                        </S.CloseButton>

                        <S.CustomerName>{customer.name}</S.CustomerName>
                      </S.CustomerNameWrapper>
                    );
                  })}
              </>
            ) : (
              <>
                {newData?.customersName &&
                  newData?.customersName.map((customer, i) => {
                    return (
                      <S.CustomerNameWrapper key={i}>
                        <S.CustomerName>{customer}</S.CustomerName>
                      </S.CustomerNameWrapper>
                    );
                  })}
              </>
            )}
          </dd>
        </S.ShippingGroupAreaDl>
      </S.Wrapper>
      <S.ButtonContainer>
        {createButton ? (
          <Button
            children={updating ? '登録中...' : '登録'}
            disabled={updating || !enableToSave}
            onClick={handleSubmitCreate}
          />
        ) : (
          <Button
            children={updating ? '保存中...' : '保存'}
            disabled={updating || !enableToSave}
            onClick={handleSubmit}
          />
        )}
      </S.ButtonContainer>
      <ErrorMsgPopUp
        errMsg={errMsg}
        handleClose={() => {
          setErrMsg('');
          setUpdating(false);
        }}
        fromPc={true}
        zIndex={9999}
      />
      {errMsg && (
        <Overlay
          dark
          handleClick={() => {
            setErrMsg('');
            setUpdating(false);
          }}
        />
      )}
    </>
  );
};

export default SalesStoreContents;
