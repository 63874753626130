import styled, { css } from 'styled-components';

const lastCell = css`
  border-bottom: 2px solid ${(props) => props.theme.palette.sandGray};
`;

export const TotalWrapper = styled.div<{
  notDisplay?: boolean;
}>`
  display: ${(props) => (props.notDisplay ? 'none' : 'flex')};
`;

// 合計、前年同月セル
export const TotalCell = styled.div<{
  lastCell?: boolean;
}>`
  width: 91px;
  height: 30px;
  text-align: center;
  border-left: 2px double rgb(231, 226, 221);
  border-bottom: 1px solid #efefef;
  justify-content: center;
  align-items: center;
  position: relative;
  width: 91px;
  box-sizing: border-box;
  padding: 0.5rem 0;
  display: flex;
  font-weight: 700;

  :first-child {
    background-color: ${(props) => props.theme.palette.white};
  }

  ${(props) => (props.lastCell ? lastCell : '')};
`;
