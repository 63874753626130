import { useMutation } from 'react-query';
import { axiosInstance } from '@lib/pc/common/api/axiosConfig';

const useDeleteSemiProductProductionResultMutation = (
  id: number,
  handleDeleteSuccess: () => void
) => {
  return useMutation(
    async () => {
      return await axiosInstance().delete(
        `/api/v1/semi_product_production_results/${id}`
      );
    },
    {
      onSuccess: () => {
        handleDeleteSuccess();
      },
      onError: () => alert('エラーが発生しました'),
    }
  );
};

export default useDeleteSemiProductProductionResultMutation;
