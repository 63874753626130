import { useEffect } from 'react';
import { Heading } from '@components/elements/typographies/Heading';
import * as S from './WrappingHeaderControl.styles';
import type { factory } from '@lib/pc/stacker/useStackers';
import DateSelector from '@components/elements/inputs/DateSelector/DateSelector';
import { Todo } from '@lib/common/type';
import { getTodayForInput } from '@lib/common/functions';
import { Button } from '@components/elements/buttons/Button';

type Props = {
  wrappings: Todo;
  factories: factory[];
  selectedFactoryId: Todo;
  setSelectedFactoryId: (id: string) => void;
  setSelectedFactoryData: (selectedFactoryData: Todo) => void;
  setSelectedLineId: (selectedLineId: number) => void;
  setSelectedLine: (selectedLineIndex: number) => void;
  selectedDate: Date;
  getBeforeDate: (selectedDate: Date) => void;
  getNextDate: (selectedDate: Date) => void;
  setDate: (date: Date) => void;
  refetch: () => void;
};

const WrappingHeaderControl = ({
  wrappings,
  factories,
  selectedFactoryId,
  setSelectedFactoryId,
  setSelectedFactoryData,
  setSelectedLineId,
  setSelectedLine,
  selectedDate,
  getBeforeDate,
  getNextDate,
  setDate,
  refetch,
}: Props) => {
  const today = new Date();
  const handleClick = () => {
    getTodayForInput(selectedDate, today, setDate, refetch);
  };

  useEffect(() => {
    // 選択工場変更時
    if (selectedFactoryId === undefined) {
      setSelectedFactoryData(wrappings?.packagingResult[0]);
      setSelectedLineId(wrappings?.packagingResult[0].lines[0].factoryLineId);
    } else {
      const selectedData = wrappings?.packagingResult.filter(
        (pr: { factoryId: number }) => pr.factoryId == selectedFactoryId
      );
      if (selectedData?.[0]) {
        setSelectedFactoryData(selectedData[0]);
        setSelectedLineId(selectedData[0].lines[0].factoryLineId);
        setSelectedLine(0);
      }
    }
  }, [selectedFactoryId]);

  return (
    <S.Wrapper>
      <S.FlexBetweenCenter>
        <S.FlexBaseline>
          <S.FlexBaseline>
            <Heading text="包装実績" fs="32" fw="lg" />
            <Heading text="を入力" fs="24" fw="lg" />
          </S.FlexBaseline>
          <S.Select
            value={selectedFactoryId}
            onChange={(e) => setSelectedFactoryId(e.target.value)}
            name="factories"
            id="factories"
          >
            {factories
              ? factories.map((f) => (
                  <option key={f.factoryId + f.factoryName} value={f.factoryId}>
                    {f.factoryName}
                  </option>
                ))
              : ''}
          </S.Select>
        </S.FlexBaseline>
      </S.FlexBetweenCenter>
      <S.DateWrapper>
        <Button
          width="90px"
          margin={'0 12px 0 0'}
          outlined={true}
          borderWidth={1}
          onClick={handleClick}
        >
          今日
        </Button>
        <DateSelector
          date={selectedDate}
          getBeforeDate={getBeforeDate}
          getNextDate={getNextDate}
          setDate={setDate}
        />
      </S.DateWrapper>
    </S.Wrapper>
  );
};

export default WrappingHeaderControl;
