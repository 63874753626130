import styled from 'styled-components';

export const Table = styled.table<{
  paddingBottom?: string;
}>`
  width: 100%;
  border-collapse: collapse;
  border-spacing: 0 1em;
  font-size: 0.875rem;
  padding-bottom: ${(props) => (props.paddingBottom ? props.paddingBottom : 0)};
  font-feature-settings: 'palt';
`;

export const Tr = styled.tr`
  background-color: ${(props) => props.theme.palette.beige};
  display: flex;
  padding: 0 20px;
  height: 45px;
  width: 100vw;
  align-items: center;
  border-bottom: solid 1px #e5e1dd;
`;

// 絞り込み
export const ListLeftHeadCell = styled.div<{
  invalid?: boolean;
  isBlue?: boolean;
}>`
  pointer-events: ${(props) => (props.invalid ? 'none' : '')};
  cursor: pointer !important;
  position: -webkit-sticky; /* for Safari */
  display: flex;
  color: ${(props) => (props.isBlue ? props.theme.palette.blue : '#9ca3af')};
  align-items: center;

  > span {
    display: flex;
    font-size: 16px;
    vertical-align: middle;
    align-items: center;
    padding: 0 12px;
    height: 45px;
    > svg {
      display: inline;
      width: 24px;
      height: 24px;
    }
    .filter_text {
      padding-top: 5px;
      padding-left: 6px;
    }
  }

  :nth-child(2) {
    > div > input {
      cursor: pointer;
    }
  }
`;

export const TableContents = styled.div`
  overflow: scroll;
  width: 100vw;
  height: calc(100vh - 89px);
  padding-bottom: 131px;
`;

export const StyledTable = styled.table`
  width: 100%;
  font-feature-settings: 'palt';
`;

export const TRow = styled.tr`
  cursor: pointer;
  border-bottom: 1px solid #e5e1dd;
  display: flex;
  padding: 0 20px;
  width: 100vw;
  height: 45px;
  align-items: center;
  &:hover {
    box-shadow: 3px 3px 3px rgba(0, 0, 0, 0.1);
    border: solid 1px #e5e1dd;
  }
`;

export const TCell = styled.th`
  text-align: ${(props) => (props.align ? props.align : 'left')};
  font-size: 16px;
  font-weight: 500;
  &.sales-store-code {
    width: 130px;
  }
  &.sales-store-name {
    width: 370px;
  }
  &.destination-name {
    width: 370px;
  }
  &.customer-name {
    width: 300px;
    display: flex;
    position: relative;
    .cell-content {
      white-space: pre-wrap;
      display: -webkit-box;
      -webkit-box-orient: vertical;
      -webkit-line-clamp: 1;
      overflow: hidden;
      word-break: break-all;
    }
  }
`;

export const TCellCustomerName = styled.div`
  margin-right: 3px;
`;

export const HeaderName = styled.th<{ isBlue: boolean }>`
  color: ${(props) => (props.isBlue ? props.theme.palette.blue : '')};
  align-items: center;
  width: 370px;
  display: flex;
  cursor: pointer;
  font-size: 16px;
  font-weight: 500;
`;

/* 得意先 全表示 */
export const CommentBalloon = styled.tr`
  .comment-full__title {
    font-weight: bold;
  }
  .cell-content__balloon {
    white-space: pre-wrap;
    background-color: white;
    line-height: 1.8;
    padding: 8px 12px;
    display: none;
    &.active {
      display: table-cell;
      border-bottom: 1px solid #e6e1dd;
      word-break: break-all;
    }
  }
`;
