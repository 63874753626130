import React, { useState } from 'react';
import moment from 'moment';
import { Button } from '@components/elements/buttons/Button';
import { factoryLineColors } from '@lib/pc/common/type';
import * as S from './WrappingEditTimeModal.styles';
import { TABLE_HEADER } from '../WrappingMainTable/WrappingMainTable';

type Props = {
  lineIndex: number;
  timeData: { lineName: string; time: string };
  handleClose: () => void;
  handleSubmit: (time: string) => void;
};

const WrappingEditTimeModal = ({
  lineIndex,
  timeData,
  handleClose,
  handleSubmit,
}: Props) => {
  const [timeState, setTimeState] = useState(timeData);
  const valuesOfFactoryLineColors = Object.values(factoryLineColors);
  if (!timeData) return <>loading</>;
  return (
    <S.Wrapper>
      <S.Line bgColor={valuesOfFactoryLineColors[lineIndex]}>
        {timeData.lineName}
      </S.Line>
      <div>
        <S.Table>
          <tbody>
            <S.THead>
              {TABLE_HEADER.map((t, i) => (
                <S.THeadCell
                  key={t.key}
                  width={t.width}
                  wordBreak={t.wordBreak}
                  // fontSize={t.fontSize}
                >
                  {i === 3 && '終了時刻'}
                </S.THeadCell>
              ))}
            </S.THead>
            <S.TRow>
              <S.TCell width="3px" />
              <S.TCell width="3px" />
              <S.TCell width="12.75rem" />
              <S.TCell width="5.1rem">
                <S.TimeInput
                  type="time"
                  name="starting_Time"
                  id="starting_Time"
                  min="00:00"
                  max="27:00"
                  value={
                    timeState.time && timeState.time.length > 5
                      ? moment(timeState.time).format('LT')
                      : timeState.time
                  }
                  onChange={(event) =>
                    setTimeState({
                      ...timeState,
                      time: event.target.value,
                    })
                  }
                />
              </S.TCell>
              <S.TCell width="3.5rem" align="center" />
              <S.TCell width="6rem" />
              <S.TCell width="6rem" />
              <S.TCell align="right" width="5rem" />
              <S.TCell width="6rem" fontSize={'14px'} />
              <S.TCell width="5.8rem" />
              <S.TCell width="2.6rem" />
            </S.TRow>
          </tbody>
        </S.Table>
      </div>
      <S.ButtonWrapper>
        <S.ButtonContainer>
          <Button borderWidth={1} outlined onClick={handleClose}>
            キャンセル
          </Button>
          <Button onClick={() => handleSubmit(timeState.time)}>保存</Button>
        </S.ButtonContainer>
      </S.ButtonWrapper>
    </S.Wrapper>
  );
};

export default WrappingEditTimeModal;
