import React from 'react';
import * as S from './MaterialAllocationMainTable.styles';
import { ReactComponent as AddIcon } from '@assets/icons/add.svg';
import { ReactComponent as PencilIcon } from '@assets/icons/pencil.svg';
import moment from 'moment';
import { CircularProgress } from '@material-ui/core';
import { TABLE_HEADER } from './header';
import { seeFullComment } from '@lib/pc/common/functions';
import { Todo, MATERIAL_ALLOCATION_TYPE } from '@lib/common/type';
import {
  MaterialAllocationEditData,
  initEditData,
} from '@lib/pc/material_allocation/type';

type Props = {
  data: Todo;
  setEditData: (editData: MaterialAllocationEditData) => void;
  setPopUp: (popUp: boolean) => void;
  setEditModalOpen: (editModalOpen: boolean) => void;
  setScanning: (scanning: boolean) => void;
};

const MaterialAllocationMainTable = ({
  data,
  setEditData,
  setPopUp,
  setEditModalOpen,
  setScanning,
}: Props) => {
  const onClickPencil = (p: Todo) => {
    setEditData(p);
    setPopUp(false);
    setEditModalOpen(true);
  };

  const handleClickCell = (p: Todo, className?: string) => {
    if (className !== 'packaging-comment') {
      onClickPencil(p);
      setScanning(false);
    }
  };

  if (!data)
    return (
      <S.CircularIconWrapperOuter>
        <S.CircularIconWrapper>
          <CircularProgress style={{ color: '#64b2f9' }} />
        </S.CircularIconWrapper>
      </S.CircularIconWrapperOuter>
    );

  return (
    <S.Wrapper>
      <S.Table>
        <tbody>
          {/* テーブルヘッダー */}
          <S.THead>
            {TABLE_HEADER.map((t, index: number) => (
              <S.THeadCell
                key={t.key + index}
                width={t.width}
                wordBreak={t.wordBreak}
                colSpan={t.colSpan}
              >
                {t.key}
              </S.THeadCell>
            ))}
          </S.THead>
          {data &&
            data?.stockAllocations.map((p: Todo, index: number) => (
              <React.Fragment key={index + p.detailId + Math.random()}>
                <S.TRow className="hover-effect">
                  {/**** 上段 ****/}
                  {/* 編集 */}
                  <S.PencilIcon
                    onClick={() => {
                      onClickPencil(p);
                      setScanning(false);
                    }}
                  >
                    <PencilIcon />
                  </S.PencilIcon>
                  {/* 資材名 */}
                  <S.TCell
                    width="12.75rem"
                    marginRight={'12px'}
                    productKind={p.productKind}
                    onClick={() => handleClickCell(p)}
                  >
                    <div className="product-wrapper">
                      <div className="product-name-kind">
                        <div className="product-name">{p.materialName}</div>
                      </div>
                      <div className="product-code">
                        {p.materialCode && p.materialCode}
                      </div>
                    </div>
                  </S.TCell>
                  {/* 払出先 */}
                  <S.TCell
                    width="12.75rem"
                    marginRight={'12px'}
                    onClick={() => handleClickCell(p)}
                  >
                    {p.allocationType === MATERIAL_ALLOCATION_TYPE.DISCARD
                      ? '廃棄'
                      : p.transferStoreroomName && p.transferStoreroomName}
                  </S.TCell>

                  <S.TCell
                    className="display-quantity display-quantity__count--case"
                    onClick={() => handleClickCell(p)}
                  >
                    {/* 資材 ケース */}
                    <div className="display-quantity__inner-wrap">
                      <div className="cell-content">
                        <div className="quantity">
                          <span className="num">{p.case}</span>
                          <span className="unit">ケース</span>
                        </div>
                        <div className="explanation">
                          x{p?.piecesPerBox ? p?.piecesPerBox : 1}
                          <span className="unit">
                            {p.labelOfUnit !== 'なし'
                              ? p.labelOfUnit
                              : p.labelOfAmount
                              ? p.labelOfAmount
                              : '個'}
                          </span>
                        </div>
                      </div>
                      <div className="symbol">+</div>
                    </div>
                  </S.TCell>
                  {/* バラまとめ単位：本、袋等 */}
                  {p.labelOfUnit !== 'なし' ? (
                    <S.TCell
                      className="display-quantity display-quantity__count--case"
                      onClick={() => handleClickCell(p)}
                    >
                      <div className="display-quantity__inner-wrap">
                        <div className="cell-content">
                          <div className="quantity">
                            <span className="num">{p.unit}</span>
                            <span className="unit">
                              {p.labelOfUnit !== 'なし'
                                ? p.labelOfUnit
                                : p.labelOfAmount
                                ? p.labelOfAmount
                                : '個'}
                            </span>
                          </div>
                          <div className="explanation">
                            x{p?.piecesPerUnit ? p?.piecesPerUnit : 1}
                            <span className="unit">
                              {p.labelOfAmount ? p.labelOfAmount : '個'}
                            </span>
                          </div>
                        </div>
                        <div className="symbol">+</div>
                      </div>
                    </S.TCell>
                  ) : (
                    <S.TCell></S.TCell>
                  )}
                  <S.TCell
                    className="display-quantity display-quantity__count--quantity"
                    onClick={() => handleClickCell(p)}
                  >
                    {/* 資材 個 */}
                    <div className="display-quantity__inner-wrap">
                      <div className="cell-content">
                        <div className="quantity">
                          <span className="num">{p.piece}</span>
                          <span className="unit">
                            {p.labelOfAmount ? p.labelOfAmount : '個'}
                          </span>
                        </div>
                      </div>
                      <div className="symbol">=</div>
                    </div>
                  </S.TCell>
                  <S.TCell
                    className="display-quantity display-quantity__count--total"
                    onClick={() => handleClickCell(p)}
                  >
                    {/* 資材 合計 */}
                    <div className="display-quantity__inner-wrap">
                      <div className="with-label__inner-wrap">
                        <div className="cell-content">
                          <div className="quantity">
                            <span className="title">合計</span>
                            <span className="num">{p.amount}</span>
                            <span className="unit">
                              {p.labelOfAmount ? p.labelOfAmount : '個'}
                            </span>
                          </div>
                        </div>
                      </div>
                    </div>
                  </S.TCell>
                  {/* 使用期限日 */}
                  <S.TCell colSpan={1} onClick={() => handleClickCell(p)}>
                    {p.expirationDate &&
                      moment(p.expirationDate).format('YY.MM.DD')}
                  </S.TCell>
                  {/* 備考 */}
                  <S.TCell
                    colSpan={1}
                    className="packaging-comment"
                    onClick={() => {
                      seeFullComment(index);
                      handleClickCell(p, 'packaging-comment');
                    }}
                  >
                    <div className="cell-content">{p.comment}</div>
                  </S.TCell>
                </S.TRow>
                <S.CommentBalloon
                  onClick={() => {
                    seeFullComment(index);
                  }}
                >
                  <td
                    key={index + p.detailId + Math.random()}
                    id={`comment-full-${index}`}
                    colSpan={13}
                    className={'cell-content__balloon'}
                  >
                    <span className="comment-full__title">備考：</span>
                    <br />
                    {p.comment}
                  </td>
                </S.CommentBalloon>
              </React.Fragment>
            ))}
          <tr>
            <td colSpan={13}>
              <S.FlexTableDivRow
                onClick={() => {
                  setEditData({
                    ...initEditData,
                  });
                  setPopUp(false);
                  setEditModalOpen(true);
                  setScanning(false);
                }}
              >
                <AddIcon />
                追加
              </S.FlexTableDivRow>
            </td>
          </tr>
        </tbody>
      </S.Table>
    </S.Wrapper>
  );
};

export default MaterialAllocationMainTable;
