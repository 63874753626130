import React, { FC } from 'react';

type Props = {
  isActive?: boolean;
};

const ArrowLeft: FC<Props> = ({ isActive }) => {
  return (
    <svg
      width="36"
      height="36"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M14 7L9 12L14 17V7Z"
        fill={isActive ? 'rgba(43, 169, 209, 1)' : 'rgba(192, 192, 192, 1)'}
      />
    </svg>
  );
};

export default ArrowLeft;
