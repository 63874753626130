import * as S from './PickingSelectStoreroom.styles';
import { ReactComponent as ExpandMore } from '@assets/icons/expand_more.svg';
import { ReactComponent as StoreHouse } from '@assets/icons/mobile/storehouse.svg';

type Props = {
  setStoreroomOpen: (storeroomOpen: boolean) => void;
  selectedStoreroom: string;
  storeroomName: string;
};

const PickingSelectStoreroom = ({
  selectedStoreroom,
  setStoreroomOpen,
  storeroomName,
}: Props) => {
  return (
    <S.Wrapper>
      <S.Title>
        <S.StoreroomPanel onClick={() => setStoreroomOpen(true)}>
          <S.IconWrapper>
            <StoreHouse />
          </S.IconWrapper>

          {selectedStoreroom && selectedStoreroom !== 'すべての在庫室' ? (
            <S.StorageText>{selectedStoreroom}</S.StorageText>
          ) : storeroomName && selectedStoreroom !== 'すべての在庫室' ? (
            <S.StorageText>{storeroomName}</S.StorageText>
          ) : (
            <S.StorageText>在庫室を選択</S.StorageText>
          )}
          <S.ExpandMore>
            <ExpandMore />
          </S.ExpandMore>
        </S.StoreroomPanel>
      </S.Title>
    </S.Wrapper>
  );
};

export default PickingSelectStoreroom;
