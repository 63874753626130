import React, { FC, useMemo } from 'react';
import useSWR from 'swr';
import * as S from './TableTotalRow.styles';
import { addComma } from '@lib/pc/production_schedule/functions';
import type { Todo, MonthlyData } from '@lib/common/type';

type Props = {
  index: number;
  style: React.CSSProperties;
  products: Todo;
  selectedTypeIds: number[];
  lastOfShippings: boolean;
  lastOfProductProductionResults: boolean;
  lastOfSafetyStocksComponents: boolean;
  lastOfDemandForecasts: boolean;
  lastOfEstimatedProductStocks: boolean;
  selectIndex: number;
};

const TableTotalRow: FC<Props> = ({
  index,
  style,
  products,
  selectedTypeIds,
  lastOfShippings,
  lastOfProductProductionResults,
  lastOfSafetyStocksComponents,
  lastOfDemandForecasts,
  lastOfEstimatedProductStocks,
  selectIndex,
}: Props) => {
  const { data: swr_schedule } = useSWR('schedule');
  const { data: swr_stock } = useSWR('stock');

  const product = useMemo(() => {
    if (!products || index >= products.length || !products[index]) {
      return <div style={style}>Loading...</div>;
    }
    return products[index];
  }, [products, index]);

  const totalLength = product?.productionSchedules?.length;

  const productionScheduleTotalComponent = useMemo(() => {
    const schedule_result = swr_schedule?.find(
      (item: Todo) => item.product_id === product.id
    );
    if (schedule_result) {
      const totalValue = product?.productionSchedules
        ?.slice(0, -2)
        .reduce(
          (
            accumulator: number,
            current: { actualAmount: null; estimatedAmount: null }
          ) => {
            const valueToAdd =
              current.actualAmount !== null
                ? current.actualAmount
                : current.estimatedAmount !== null
                ? current.estimatedAmount
                : 0;
            return accumulator + Number(valueToAdd);
          },
          0
        );
      product.productionSchedules[totalLength - 2].actualAmount = totalValue;
    }
    return product?.productionSchedules
      ?.slice(-2)
      .map((schedule: MonthlyData, scheduleIndex: number) => {
        const uniqueKey = `${product.id}-${index}-${scheduleIndex}-${
          schedule.actualAmount || 'null' + 'actualAmount'
        }`;
        return (
          <S.TotalCell lastCell={false} key={uniqueKey}>
            {schedule?.actualAmount ? addComma(schedule?.actualAmount) : ''}
          </S.TotalCell>
        );
      });
  }, [product.productionSchedules]);

  const productionResultTotalComponent = useMemo(() => {
    return product?.productProductionResults
      ?.slice(-2)
      .map((productionResult: MonthlyData, scheduleIndex: number) => {
        const uniqueKey = `${product.id}-${index}-${scheduleIndex}-${
          productionResult.amount || 'null' + 'productionResult'
        }`;
        return (
          <S.TotalCell
            lastCell={lastOfProductProductionResults}
            key={uniqueKey}
          >
            {productionResult?.amount ? addComma(productionResult?.amount) : ''}
          </S.TotalCell>
        );
      });
  }, [product.productProductionResults]);

  const demandForecastComponent = useMemo(() => {
    return product?.demandForecasts
      ?.slice(-2)
      .map((demandForecast: MonthlyData, scheduleIndex: number) => {
        const uniqueKey = `${product.id}-${index}-${scheduleIndex}-${
          demandForecast.amount || 'null' + 'demandForecast'
        }`;
        return (
          <S.TotalCell lastCell={lastOfDemandForecasts} key={uniqueKey}>
            {demandForecast?.amount ? addComma(demandForecast?.amount) : ''}
          </S.TotalCell>
        );
      });
  }, [product.demandForecasts, selectIndex, selectedTypeIds]);

  const shippingTotalComponent = useMemo(() => {
    return product?.shippings
      ?.slice(-2)
      .map((shipping: MonthlyData, scheduleIndex: number) => {
        const uniqueKey = `${product.id}-${index}-${scheduleIndex}-${
          shipping.amount || 'null' + 'shipping'
        }`;
        return (
          <S.TotalCell lastCell={lastOfShippings} key={uniqueKey}>
            {shipping?.amount ? addComma(shipping?.amount) : ''}
          </S.TotalCell>
        );
      });
  }, [product.shippings]);

  const safetyStockTotalComponent = useMemo(() => {
    return product?.safetyStocks
      ?.slice(-2)
      .map((safetyStock: MonthlyData, scheduleIndex: number) => {
        const uniqueKey = `${product.id}-${index}-${scheduleIndex}-${
          safetyStock.amount || 'null' + 'safetyStock'
        }`;
        return (
          <S.TotalCell lastCell={lastOfSafetyStocksComponents} key={uniqueKey}>
            {safetyStock?.amount ? addComma(safetyStock?.amount) : ''}
          </S.TotalCell>
        );
      });
  }, [product.safetyStocks]);

  const estimatedProductStockTotalComponent = useMemo(() => {
    const stock_result = swr_stock?.find(
      (item: Todo) => item.product_id === product.id
    );
    if (stock_result) {
      product.estimatedProductStocks[totalLength - 2] =
        product.estimatedProductStocks[totalLength - 3];
    }
    return product?.estimatedProductStocks
      ?.slice(-2)
      .map((estimatedProductStock: MonthlyData, scheduleIndex: number) => {
        const uniqueKey = `${product.id}-${index}-${scheduleIndex}-${
          estimatedProductStock.amount || 'null' + 'estimatedProductStock'
        }`;
        return (
          <S.TotalCell lastCell={lastOfEstimatedProductStocks} key={uniqueKey}>
            {estimatedProductStock?.amount
              ? addComma(estimatedProductStock?.amount)
              : ''}
          </S.TotalCell>
        );
      });
  }, [product.estimatedProductStocks]);

  const actualProductStockTotalComponent = useMemo(() => {
    return product?.actualProductStocks
      ?.slice(-2)
      .map((actualProductStock: MonthlyData, scheduleIndex: number) => {
        const uniqueKey = `${product.id}-${index}-${scheduleIndex}-${
          actualProductStock.amount || 'null' + 'actualProductStock'
        }`;

        return (
          <S.TotalCell lastCell={true} key={uniqueKey}>
            {actualProductStock?.amount
              ? addComma(actualProductStock?.amount)
              : ''}
          </S.TotalCell>
        );
      });
  }, [product.actualProductStock]);

  const totalList = useMemo(() => {
    return (
      <React.Fragment key={`${index}-${product.productCode}-product`}>
        <div style={style}>
          <S.TotalWrapper>{productionScheduleTotalComponent}</S.TotalWrapper>
          <S.TotalWrapper
            notDisplay={!selectedTypeIds.includes(1) ? true : false}
          >
            {productionResultTotalComponent}
          </S.TotalWrapper>
          <S.TotalWrapper
            notDisplay={!selectedTypeIds.includes(2) ? true : false}
          >
            {demandForecastComponent}
          </S.TotalWrapper>
          <S.TotalWrapper
            notDisplay={!selectedTypeIds.includes(3) ? true : false}
          >
            {shippingTotalComponent}
          </S.TotalWrapper>
          <S.TotalWrapper
            notDisplay={!selectedTypeIds.includes(4) ? true : false}
          >
            {safetyStockTotalComponent}
          </S.TotalWrapper>
          <S.TotalWrapper
            notDisplay={!selectedTypeIds.includes(5) ? true : false}
          >
            {estimatedProductStockTotalComponent}
          </S.TotalWrapper>
          <S.TotalWrapper
            notDisplay={!selectedTypeIds.includes(6) ? true : false}
          >
            {actualProductStockTotalComponent}
          </S.TotalWrapper>
        </div>
      </React.Fragment>
    );
  }, []);

  return totalList;
};

export default TableTotalRow;
