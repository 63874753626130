import { useEffect, useState, useLayoutEffect } from 'react';
import { useHistory, useLocation } from 'react-router';
import { useWindowScroll } from 'react-use';
import moment from 'moment';
import * as S from './PickingEditWrapper.styles';
import { ReactComponent as ChevronLeftIcon } from '@assets/icons/mobile/chevron_left.svg';
import { ReactComponent as StoreHouse } from '@assets/icons/mobile/storehouse.svg';
import ConfirmPopUp from '@components/modules/common/ConfirmPopUp/ConfirmPopUp';
import PickingProductSearchForm from '@components/modules/mobile/picking_edit/PickingProductSearchForm/PickingProductSearchForm';
import PickingIndexByShipping from '@components/modules/mobile/picking_by_shipping_edit/PickingIndexByShipping/PickingIndexByShipping';
import { PickingState } from '@lib/mobile/picking_edit/type';
import { Case, Product, Storeroom } from '@lib/common/type';
import {
  DetailsByProduct,
  DetailsByShippingDestination,
  NotCheckedInfo,
  PICKING_STATUS,
} from '@lib/mobile/picking_by_shipping/type';
import { STATUS_VALUE } from '@lib/mobile/picking_by_shipping/type';

type Props = {
  scannedJanCode?: string;
  scannedProductName: string;
  products: Product[];
  storerooms: Storeroom[];
  picking: DetailsByShippingDestination;
  cases: Case[] | undefined;
  completedCheckState: boolean;
  setCompletedCheckState: (completedCheckState: boolean) => void;
  fromScannerResult: boolean;
  greenFixedAreaHeight: string;
  setUpdatePickingDetailId: (updatePickingDetailId: number) => void;
  handleUpdate: (
    checkState: boolean,
    amountDetail: DetailsByProduct[],
    allocateTrueStatus: boolean
  ) => void;
  handleClickAllocateComplete: () => void;
  confirmMsg: string;
  setConfirmMsg: (confirmMsg: string) => void;
  notCheckedInfo: NotCheckedInfo[];
  setNotCheckedInfo: (notCheckedInfo: NotCheckedInfo[]) => void;
  allocateCompleteBtn: boolean;
  setAllocateCompleteBtn: (allocateCompleteBtn: boolean) => void;
  refetch: () => void;
  setErrMsg: (errMsg: string) => void;
  updating: boolean;
  setUpdating: (updating: boolean) => void;
  searchProductChar: string;
  setSearchProductChar: (searchProductChar: string) => void;
};

const PickingEditWrapper = ({
  scannedJanCode,
  scannedProductName,
  storerooms,
  picking,
  cases,
  completedCheckState,
  setCompletedCheckState,
  fromScannerResult,
  greenFixedAreaHeight,
  setUpdatePickingDetailId,
  handleUpdate,
  handleClickAllocateComplete,
  confirmMsg,
  setConfirmMsg,
  notCheckedInfo,
  setNotCheckedInfo,
  allocateCompleteBtn,
  setAllocateCompleteBtn,
  refetch,
  setErrMsg,
  updating,
  setUpdating,
  searchProductChar,
  setSearchProductChar,
}: Props) => {
  const history = useHistory();
  const { state } = useLocation<PickingState>();
  // stateにはstateShippingDateが設定されないのでpicking.shippingDateを使用
  const shippingDateFromState = picking.shippingDate;
  // バックエンドからのpickingStatusで個別画面のallocateTrueStatusを判定
  const allocateTrueStatus =
    picking.pickingStatus === PICKING_STATUS.ALLOCATION_NOT_STARTED ||
    picking.pickingStatus === PICKING_STATUS.ALLOCATION_IN_PROGRESS
      ? true
      : false;

  const formattedDate = moment(shippingDateFromState).format('YYYY/MM/DD');
  const formattedDayOfWeek = moment(shippingDateFromState).format('(ddd)');
  const formattedTime =
    picking.shippingScheduledTime &&
    moment(picking.shippingScheduledTime).format('LT');

  // スクロール位置state
  const { y: currentScrollPosition } = useWindowScroll();
  // スクロール固定エリアの高さ
  const [fixedAreaHeight, setFixedAreaHeight] = useState('');
  const [storeroomName, setStoreroomName] = useState('');
  const [storeroomId, setStoreroomId] = useState(0);
  const [allCheckState, setAllCheckState] = useState<boolean[]>([]);

  // 個別画面から一覧画面へ戻る時
  const onClickGoBack = () => {
    // +ボタン押下時、または入力内容に変更を加えた時
    if (notCheckedInfo.length > 0) {
      setConfirmMsg(
        `以下の変更した内容をチェック(保存)せずに終了します。よろしいですか？`
      );
    } else {
      history.push(`/mobile/picking_by_shipping`, {
        ...state,
        stateCompletedCheckState: completedCheckState,
        stateFromEditComponent: true,
      });
    }
  };

  // 確認メッセージOK
  const handleOk = () => {
    setConfirmMsg('');
    setNotCheckedInfo([]);
    history.push(`/mobile/picking_by_shipping`, {
      ...state,
      stateFromEditComponent: true,
    });
  };
  // 確認メッセージキャンセル
  const handleCancel = () => {
    setConfirmMsg('');
  };

  // 現在のチェック状態に応じたピッキングステータス状況
  const [currentPickingStatus, setCurrentPickingStatus] = useState(0);

  // スキャナー結果があったら検索文字列へ設定する
  useEffect(() => {
    if (scannedProductName) {
      setSearchProductChar(scannedProductName);
    }
  }, [scannedProductName]);

  // ヘッダーの高さを取得して一覧表示のTOP位置を決める
  useEffect(() => {
    if (document) {
      const clientW = document?.getElementById('fixed-area')?.clientHeight;
      clientW && setFixedAreaHeight(clientW.toString());
    }
  }, [document]);

  useEffect(() => {
    const selectedStoreroom = storerooms.filter(
      (s: Storeroom) => s.id === Number(state.stateSelectedStoreroomId)
    );
    setStoreroomName(selectedStoreroom[0]?.name);
    setStoreroomId(selectedStoreroom[0]?.id);
  }, [state.stateSelectedStoreroomId]);

  // pickingStatusに振分待ち(3) or 振分中(4)を含むかの配列。false:含まない
  useEffect(() => {
    setAllCheckState(picking.allCheckState);
  }, [picking]);

  // 振分・ピッキングのステータス表示更新
  useLayoutEffect(() => {
    if (allCheckState.every((v) => v === true)) {
      if (allocateTrueStatus) {
        // 振分待ちの場合かつ全て振分済みの場合(振分完了)
        // ステータスは「振分中」とし、「振分完了ボタン」を表示する
        setCurrentPickingStatus(PICKING_STATUS.ALLOCATION_IN_PROGRESS);
        setAllocateCompleteBtn(true);
      } else {
        setCurrentPickingStatus(PICKING_STATUS.DONE);
        setAllocateCompleteBtn(false);
      }
    } else if (
      allCheckState.every((v) => v === false) ||
      allCheckState.length === 0
    ) {
      if (allocateTrueStatus) {
        // 振分待ちの場合かつ全て未振分の場合
        setCurrentPickingStatus(PICKING_STATUS.ALLOCATION_NOT_STARTED);
        setAllocateCompleteBtn(false);
      } else {
        // ピッキング未着手
        setCurrentPickingStatus(PICKING_STATUS.NOT_STARTED);
        setAllocateCompleteBtn(false);
      }
    } else {
      if (allocateTrueStatus) {
        // 振分待ちの場合かつ振分中の場合
        setCurrentPickingStatus(PICKING_STATUS.ALLOCATION_IN_PROGRESS);
        setAllocateCompleteBtn(false);
      } else {
        // ピッキング中
        setCurrentPickingStatus(PICKING_STATUS.IN_PROGRESS);
        setAllocateCompleteBtn(false);
      }
    }
  }, [state.stateClickedPickingStatus, allCheckState]);

  if (!picking) return null;

  return (
    <>
      <S.FixedArea
        id="fixed-area"
        className="fixed-area"
        scrollPosition={currentScrollPosition}
        fromScannerResult={fromScannerResult}
        greenFixedAreaHeight={greenFixedAreaHeight}
      >
        <S.Wrapper>
          <S.Title>
            <S.BackButton
              onClick={() => {
                onClickGoBack();
              }}
            >
              <S.IconWrapper></S.IconWrapper>
              <ChevronLeftIcon />
            </S.BackButton>
            <S.TitleContainer className="shipping-date">
              <S.PlanText>
                {formattedDate}
                <span>{formattedDayOfWeek}の</span>
                出荷予定{formattedTime}
                <span>（出荷先別）</span>
              </S.PlanText>
            </S.TitleContainer>
          </S.Title>
          <S.Title className="picking-group-and-allocate">
            <S.TitleContainer>
              <S.PlanText className="group-name">
                {picking.groupName}
              </S.PlanText>
            </S.TitleContainer>
            {/* 振分完了ボタン */}
            {allocateCompleteBtn && (
              <S.AllocateCompleteButton onClick={handleClickAllocateComplete}>
                振分完了
              </S.AllocateCompleteButton>
            )}
          </S.Title>
          <S.Title className="status">
            {/* ステータス */}
            <S.StatusIcon
              btnBgColor={STATUS_VALUE[currentPickingStatus]?.btnBgColor}
              borderColor={STATUS_VALUE[currentPickingStatus]?.borderColor}
              fontSize={STATUS_VALUE[currentPickingStatus]?.fontSize}
              fontColor={STATUS_VALUE[currentPickingStatus]?.fontColor}
              fontWeight={STATUS_VALUE[currentPickingStatus]?.fontWeight}
            >
              {STATUS_VALUE[currentPickingStatus]?.key}
            </S.StatusIcon>
            {/* <S.StatusWrapper status={pickingStatus}>
              <Status />
            </S.StatusWrapper> */}
            <S.ProductInfoWrapper>
              <S.ProductInfoText>
                {picking.numberOfProducts}
                <span>品目 合計</span>
                {picking.totalAmount}
                <span>個</span>
              </S.ProductInfoText>
            </S.ProductInfoWrapper>
          </S.Title>
        </S.Wrapper>
        {/* 在庫室 */}
        <S.Title className="storeroom">
          <S.IconWrapper>
            <StoreHouse />
          </S.IconWrapper>
          <S.StoreroomText>
            {storeroomName ? storeroomName : 'すべての在庫室'}
          </S.StoreroomText>
        </S.Title>
        {/* 商品検索、チェック済以外表示エリア */}
        <PickingProductSearchForm
          searchProductChar={searchProductChar}
          setSearchProductChar={setSearchProductChar}
          completedCheckState={completedCheckState}
          setCompletedCheckState={setCompletedCheckState}
          allocateTrueStatus={allocateTrueStatus ? true : false}
        />
        {/* 出荷先検索エリア */}
        {/* <PickingShippingDestinationSearchForm
          searchShippingChar={searchShippingChar}
          setSearchShippingChar={setSearchShippingChar}
        /> */}
      </S.FixedArea>
      {/* fixedAreaHeightこの行に必要？ */}
      <S.ScrollArea className="scroll-area" fixedAreaHeight={fixedAreaHeight}>
        {/* 出荷先別商品情報 */}
        <PickingIndexByShipping
          scannedJanCode={scannedJanCode}
          detailsByShippingDestination={picking.detailsByDestination}
          searchProductChar={searchProductChar}
          completedCheckState={completedCheckState}
          fixedAreaHeight={fixedAreaHeight}
          fromScannerResult={fromScannerResult}
          greenFixedAreaHeight={greenFixedAreaHeight}
          cases={cases}
          setUpdatePickingDetailId={setUpdatePickingDetailId}
          handleUpdate={handleUpdate}
          notCheckedInfo={notCheckedInfo}
          setNotCheckedInfo={setNotCheckedInfo}
          refetch={refetch}
          setErrMsg={setErrMsg}
          storeroomName={storeroomName}
          storeroomId={storeroomId}
          updating={updating}
          setUpdating={setUpdating}
          picking={picking}
        />
      </S.ScrollArea>
      <ConfirmPopUp
        fromPc={false}
        confirmMsg={confirmMsg}
        notCheckedInfo={notCheckedInfo}
        zIndex={1004}
        handleOk={handleOk}
        handleCancel={handleCancel}
      />
    </>
  );
};

export default PickingEditWrapper;
