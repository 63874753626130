import { useMutation } from 'react-query';
import humps from 'humps';
import { axiosInstance } from '@lib/pc/common/api/axiosConfig';
import { Todo } from '@lib/common/type';

// 生産実績・生産実績詳細テーブル更新
const useUpdateProductProductionResultMutation = (
  id: string | number,
  date: Date,
  factoryLineId: number,
  handleUpdateSuccess: () => void
) => {
  return useMutation(
    async (params: Todo) => {
      return await axiosInstance().patch(
        `/api/v1/product_production_results/${id}?date=${date}&factory_line_id=${factoryLineId}`,
        humps.decamelizeKeys(params)
      );
    },
    {
      onSuccess: () => {
        handleUpdateSuccess();
      },
      onError: () => alert('エラーが発生しました'),
    }
  );
};

export default useUpdateProductProductionResultMutation;
