import { FC, useEffect, useState } from 'react';
import * as S from './DeliveryStatus.styles';
import { Todo } from '@lib/common/type';
// import SubmitButton from '@components/modules/pc/purchase_orders/SubmitButton/SubmitButton';
import DeliverStatusInputWrapper from '../DeliverStatusInputWrapper/DeliverStatusInputWrapper';
import ErrorMsgPopUp from '@components/modules/common/ErrorMsgPopUp/ErrorMsgPopUp';

type Props = {
  supplierId: number | undefined;
  materialOrder: Todo;
  isChangedArray: boolean[];
  updating: boolean;
  setUpdating: (updating: boolean) => void;
  setMsgKind: (msgKind: string) => void;
  setTaskKind: (taskKind: string) => void;
  setPopUp: (popUp: boolean) => void;
  setDeleteId: (deleteId: number) => void;
  setConfirmMsg: (confirmMsg: string) => void;
  msgKind: string;
  refetch: () => void;
  materialOrdersRefetch: () => void;
  isLineChangedArray: boolean[];
  setIsLineChangedArray: (isLineChangedArray: boolean[]) => void;
  disableAnotherMaterial: boolean;
  setDisableAnotherMaterial: (disableAnotherMaterial: boolean) => void;
};

const DeliveryStatus: FC<Props> = ({
  supplierId,
  materialOrder,
  isChangedArray,
  updating,
  setUpdating,
  setMsgKind,
  setTaskKind,
  setPopUp,
  setDeleteId,
  setConfirmMsg,
  msgKind,
  refetch,
  materialOrdersRefetch,
  isLineChangedArray,
  setIsLineChangedArray,
  disableAnotherMaterial,
  setDisableAnotherMaterial,
}) => {
  const [errMsg, setErrMsg] = useState('');
  // 納品日、納品数入力中
  const [inputting, setInputting] = useState(false);
  // 下位のコンポーネントに渡しているのがネストの深いオブジェクトなので、強制再レンダリングする必要がある
  const [, setToggleValue] = useState(false);
  const reRender = () => setToggleValue((prev) => !prev);

  // const onClickDeliveryCompleted = () => {
  //   // TODO
  //   setUpdating(true);
  // };

  useEffect(() => {
    refetch();
    reRender();
  }, [materialOrder?.orderId]);

  return (
    <>
      <S.DeliveryStatusWrapper>
        <S.DeliveryWhiteArea>
          <S.DeliveryStatusTitle>納品状況</S.DeliveryStatusTitle>
          {materialOrder?.orderDetails &&
            materialOrder?.orderDetails.map((detail: Todo, index: number) => {
              return (
                <S.DeliveryMaterialWrapper key={index}>
                  <S.DeliveryMaterialName>
                    {detail.materialName}
                  </S.DeliveryMaterialName>
                  <DeliverStatusInputWrapper
                    index={index}
                    detail={detail}
                    setErrMsg={setErrMsg}
                    supplierId={supplierId}
                    isChangedArray={isChangedArray}
                    updating={updating}
                    setUpdating={setUpdating}
                    setMsgKind={setMsgKind}
                    setTaskKind={setTaskKind}
                    setPopUp={setPopUp}
                    setDeleteId={setDeleteId}
                    setConfirmMsg={setConfirmMsg}
                    msgKind={msgKind}
                    refetch={refetch}
                    materialOrdersRefetch={materialOrdersRefetch}
                    isLineChangedArray={isLineChangedArray}
                    setIsLineChangedArray={setIsLineChangedArray}
                    disableAnotherMaterial={disableAnotherMaterial}
                    setDisableAnotherMaterial={setDisableAnotherMaterial}
                    orderStatus={materialOrder?.orderStatus}
                    inputting={inputting}
                    setInputting={setInputting}
                  />
                </S.DeliveryMaterialWrapper>
              );
            })}
          {/* <S.ButtonContainer invalid={errMsg || confirmMsg ? true : false}> */}
          {/* TODO:完全納品ボタンのdisabled */}
          {/* <S.ButtonContainer>
            <SubmitButton
              onClick={onClickDeliveryCompleted}
              disabled={updating || materialOrder?.orderStatus !== 'completed'}
            >
              納品完了
            </SubmitButton>
          </S.ButtonContainer> */}
        </S.DeliveryWhiteArea>
      </S.DeliveryStatusWrapper>
      <ErrorMsgPopUp
        errMsg={errMsg}
        handleClose={() => {
          setErrMsg('');
          setUpdating(false);
        }}
        fromPc={true}
      />
    </>
  );
};

export default DeliveryStatus;
