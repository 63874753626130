export type MaterialAllocationEditData = {
  detailId: number;
  expirationDate: string;
  allocationType: string | number;
  storeroomId: number | null;
  case: number | null;
  unit: number | null;
  piece: number | null;
  amount: number | null;
  comment: string;
  materialId: number;
  transferStoreroomId: number | null;
  piecesPerUnit: number | null;
  piecesPerBox: number | null;
};

export const initEditData = {
  detailId: 0,
  expirationDate: '',
  allocationType: '',
  storeroomId: null,
  case: null,
  unit: null,
  piece: null,
  amount: null,
  comment: '',
  materialId: 0,
  transferStoreroomId: null,
  piecesPerUnit: null,
  piecesPerBox: null,
};
