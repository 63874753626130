import styled from 'styled-components';

export const Wrapper = styled.div<{
  bgColor: string;
}>`
  width: 100vw;
  height: calc(100vh - 193px);
  //ライン別背景
  background-color: ${(props) => props.bgColor};
`;

export const Header = styled.div<{
  bgColor: string;
}>`
  display: flex;
  justify-content: space-between;
  border-top: 1px solid #cdcdcd;
  background-color: ${(props) => props.bgColor};
`;

export const TabWrapper = styled.div`
  display: flex;
  width: 100vw;
  overflow-x: scroll;
`;

export const TabArea = styled.div`
  display: flex;
  background-color: #222e3e;
  cursor: pointer;
`;

export const Tab = styled.div<{
  active: boolean;
  tabCount: number;
  bgColor: string;
}>`
  display: grid;
  vertical-align: middle;
  background-color: ${(props) => (props.active ? props.bgColor : '#F8F7F4')};
  width: calc(100vw / ${(props) => props.tabCount});
  height: 64px;
  text-align: center;
  color: ${(props) => (props.active ? '#FFFFFF' : 'rgba(51, 51, 51, 0.5)')};
  font-weight: ${(props) => (props.active ? '900' : '400')};
  font-family: 'Noto Sans JP';
  font-style: normal;
  font-size: 21px;
  line-height: 24px;
  align-items: center;
  text-align: center;
  min-width: 200px;
`;
