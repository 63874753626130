import styled from 'styled-components';
import { Link } from 'react-router-dom';

export const Wrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100vw;
  height: 3.75rem;
  padding: 0 1.2rem;
  border-bottom: 1px solid ${(props) => props.theme.palette.sand};
  background: ${(props) => props.theme.palette.beige};
`;

export const NavWrapper = styled.div`
  display: flex;
  align-items: center;
  color: #191712;
`;

export const NavButton = styled.button`
  padding: 1rem 1rem 1rem 0;
  background: none;
  color: #191712;
`;

export const Title = styled.p`
  font-weight: bold;
  font-size: 1.25rem;
`;

export const UserInfo = styled.div`
  display: flex;
  align-items: center;
`;

export const UserName = styled.p`
  margin-left: 0.75rem;
  width: 117px;
  height: 20px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
`;

export const LogoutMenu = styled.div<{ logoutMenuActive?: boolean }>`
  position: absolute;
  top: 3.75rem;
  right: 0;
  z-index: 9999;
  display: ${(props) => (props.logoutMenuActive ? 'block' : 'none')};
`;

export const logoutDialog = styled.div<{ logoutDialogActive?: boolean }>`
  position: absolute;
  z-index: 9999;
  display: ${(props) => (props.logoutDialogActive ? 'block' : 'none')};
`;

export const Sidebar = styled.div`
  z-index: 9999;
  position: fixed;
  top: 0;
  left: 0;
  /* overlayのz-indexが9998なので */
  width: 75%;
  height: 100vh;
  background: ${(props) => props.theme.palette.white};
`;

export const MenuList = styled.ul``;

export const MenuItem = styled.li<{ selected?: boolean }>`
  background: ${(props) =>
    props.selected ? props.theme.palette.beige : props.theme.palette.white};
`;

export const MenuLink = styled(Link)`
  display: flex;
  align-items: center;
  height: 3.5rem;
  padding-left: 1.5rem;
`;

export const MenuText = styled.p`
  margin-left: 1rem;
  font-feature-settings: 'palt';
`;
