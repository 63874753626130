import styled from 'styled-components';

export const Table = styled.table<{
  paddingBottom?: string;
}>`
  width: 100%;
  border-collapse: collapse;
  border-spacing: 0 1em;
  font-size: 0.875rem;
  padding-bottom: ${(props) => (props.paddingBottom ? props.paddingBottom : 0)};
`;

export const TableContents = styled.div`
  overflow: scroll;
  width: 100vw;
  height: calc(100vh - 89px);
`;

export const TRowWrapper = styled.div`
  width: 100vw;
  overflow: scroll;
`;

export const TRow = styled.tr<{
  bgColor: string;
}>`
  cursor: pointer;
  border: 1px solid #e6e1dd;
  background-color: ${(props) => props.bgColor};
  overflow: scroll;
`;

export const THead = styled.tr`
  background: #f8f7f4;
`;

export const TCell = styled.td<{
  align?: string;
  width?: string;
  status?: boolean;
  isCursor?: boolean;
}>`
  padding: 1rem 0;
  width: ${(props) => props.width};
  padding: ${(props) =>
    props.align === 'right' ? '1rem 0.5rem 1rem 0' : '1rem 0 1rem 2rem'};

  text-align: ${(props) => (props.align ? props.align : 'left')};
  font-size: 16px;
  font-weight: 500;
  > svg {
    display: inline;
    width: 18px;
    height: 18px;
  }
  > span {
    font-size: 14px;
  }
  :first-child {
    padding-left: 2rem;
  }
  :last-child {
    padding-right: 2rem;
  }
  &.header {
    color: ${(props) => (props.status ? props.theme.palette.blue : '')};
    cursor: ${(props) => (props.isCursor ? 'pointer' : '')};
  }
  &.shippingDate {
    width: 7.25rem;
  }
  &.client {
    width: 15rem;
  }
  &.items {
    width: 6.25rem;
  }
  &.sum {
    width: 6.25rem;
  }
  &.status {
    width: 7.25rem;
  }
`;

export const TClientCell = styled.td`
  padding: 1rem 0 1rem 0.5rem;
  width: 15rem;
  font-size: 16px;
`;

export const StatusBar = styled.div<{
  bgColor: string;
  color: string;
  borderColor: string;
}>`
  border: ${(props) => `${props.borderColor} solid 2px`};
  color: ${(props) => props.color};
  background-color: ${(props) => props.bgColor};
  padding: 6px 10px;
  font-size: 16px;
  font-weight: 700;
  text-align: center;
`;
