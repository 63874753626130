import { FC } from 'react';
import moment from 'moment';
import {
  VictoryAxis,
  VictoryBar,
  VictoryChart,
  VictoryContainer,
  VictoryGroup,
} from 'victory';
import { ModalPaper } from '@components/modules/common/pc/ModalPaper';
import {
  convertEstimatedArrToBarData,
  convertProductionArrToBarData,
  convertEstimatedOrActualArrToBarData,
  addComma,
  changeWidth,
  generateDateArrayForModal,
} from '@lib/pc/production_schedule/functions';
import * as S from '../Graph.styles';
import { CircularProgress } from '@material-ui/core';
import type { ResponseProductionSchedule } from '@lib/pc/production_schedule/type';
import type { Todo } from '@lib/common/type';

type Props = {
  data: Todo;
  date: Todo;
  setDate: (date: Todo) => void;
};
/*  COMMENT(220314):生産計画と実績グラフ */
const ProductionGraph: FC<Props> = ({ data, date, setDate }: Props) => {
  const indexNum = Number(date.format('DD') - 1);
  return (
    <ModalPaper
      heading1="生産計画と実績"
      heading2="（生産計画数と生産実績）"
      date={date}
      setDate={setDate}
    >
      <S.GraphControlHead>
        <S.DescHeader>
          「計画通りに生産が行われているか」を確認することができます。
          <br />
          「生産計画（予定）」は、生産される予定の数量を表示しています。
          <br />
          「生産実績（実数）」は、実際に生産済みの数量を表示しています。
        </S.DescHeader>
      </S.GraphControlHead>
      {data ? (
        <S.GraphContainer>
          <S.LeftHeadContainer>
            <S.LeftLabelContainer>
              <S.LeftGraphLabelContainer>
                <S.GraphLeftLabelContainer>
                  <div>
                    <S.ColorBox bgColor={'#F6C1B1'} />
                  </div>
                  <S.LeftGraphTextContainer>
                    <div>生産計画（予定）</div>
                    <span>生産計画の数量</span>
                  </S.LeftGraphTextContainer>
                </S.GraphLeftLabelContainer>
                <S.GraphLeftLabelContainer>
                  <div>
                    <S.ColorBox bgColor={'#ABD5F3'} />
                  </div>
                  <S.LeftGraphTextContainer>
                    <div>生産実績（実数）</div>
                    <span>実際に生産された数量</span>
                  </S.LeftGraphTextContainer>
                </S.GraphLeftLabelContainer>
              </S.LeftGraphLabelContainer>
              <S.GraphLeftLabelYAxisContainer>
                <VictoryChart
                  domainPadding={{ x: 0 }}
                  width={120}
                  height={320}
                  padding={{ top: 5, bottom: 0, right: 0, left: 0 }}
                  containerComponent={<VictoryContainer responsive={false} />}
                >
                  <VictoryAxis
                    width={130}
                    height={320}
                    dependentAxis
                    offsetX={50}
                    standalone
                    style={{
                      axisLabel: {
                        fontSize: 20,
                        padding: 30,
                        zIndex: 100,
                      },
                    }}
                  />
                  <VictoryGroup
                    groupComponent={<g display="none" />}
                    offset={20}
                  >
                    {/* グラフ左のY軸の値 */}
                    <VictoryBar
                      barWidth={16}
                      data={convertEstimatedOrActualArrToBarData(
                        data.productionSchedules
                      )}
                      style={{ data: { display: 'none' } }}
                    />
                  </VictoryGroup>
                </VictoryChart>
              </S.GraphLeftLabelYAxisContainer>
            </S.LeftLabelContainer>
            <div>
              <S.LeftTableEmptyCell />
              <S.LeftTableHeadCell bg={'#F6C1B1'}>
                <span>生産計画（予定数）</span>
              </S.LeftTableHeadCell>
              <S.LeftTableHeadCell marginTop bg={'#ABD5F3'}>
                <span> 生産実績（実数）</span>
              </S.LeftTableHeadCell>
            </div>
          </S.LeftHeadContainer>
          <S.ScrollableContainer>
            {/* COMMENT:交互背景は時間かかりそう
      https://github.com/FormidableLabs/victory/issues/1601 */}
            <VictoryChart
              width={changeWidth(data.numberOfDaysParamsMonth)}
              height={320}
              padding={{ top: 0, bottom: 0, right: 0, left: 35 }}
              containerComponent={<VictoryContainer responsive={false} />}
            >
              <VictoryAxis
                //x
                style={{
                  axis: { display: 'none' },
                  tickLabels: {
                    display: 'none',
                  },
                }}
              />
              <VictoryAxis
                //y
                dependentAxis
                style={{
                  axis: { display: 'none' },
                  grid: { stroke: '#E7E2DD' },
                  tickLabels: {
                    display: 'none',
                  },
                }}
              />
              <VictoryGroup domainPadding={20} offset={20}>
                <VictoryBar
                  barWidth={16}
                  style={{
                    // estimated
                    data: {
                      fill: '#F6C1B1',
                      stroke: '#B68A7C',
                      strokeWidth: 1,
                    },
                  }}
                  data={convertEstimatedArrToBarData(data.productionSchedules)}
                />
                <VictoryBar
                  barWidth={16}
                  style={{
                    // actual
                    data: {
                      fill: '#ABD5F3',
                      stroke: '#67AB90',
                      strokeWidth: 1,
                    },
                  }}
                  data={convertProductionArrToBarData(data.productionSchedules)}
                />
              </VictoryGroup>
            </VictoryChart>
            <S.TableWrapper>
              <S.TableRow>
                {generateDateArrayForModal(
                  data.numberOfDaysParamsMonth,
                  date
                ).map((day: Todo, i: number) => {
                  return (
                    <S.TableHeadCell
                      key={i}
                      isToday={i === indexNum}
                      dayOfWeek={moment(day.productionDate).format('ddd')}
                    >
                      {i === 0 ? (
                        <div>
                          {moment(day.productionDate).format('MM/DD')}
                          <span>
                            {moment(day.productionDate).format('(ddd)')}
                          </span>
                        </div>
                      ) : (
                        <div>
                          {moment(day.productionDate).format('DD')}
                          <span>
                            {moment(day.productionDate).format('(ddd)')}
                          </span>
                        </div>
                      )}
                    </S.TableHeadCell>
                  );
                })}
              </S.TableRow>
              <S.TableRow>
                {data.productionSchedules.map(
                  (p: ResponseProductionSchedule, i: number) => {
                    return (
                      <S.TableCell
                        color={'#CE7D64'}
                        key={i}
                        isToday={i === indexNum}
                      >
                        {p.actualAmount
                          ? addComma(p.actualAmount)
                          : addComma(p.estimatedAmount) || '-'}
                      </S.TableCell>
                    );
                  }
                )}
              </S.TableRow>
              <S.TableRow>
                {data.productionSchedules.map(
                  (p: ResponseProductionSchedule, i: number) => {
                    return (
                      <S.TableCell
                        color={'#3387C5'}
                        key={i}
                        isToday={i === indexNum}
                        marginTop
                      >
                        {addComma(p.productionResultAmount) || '-'}
                      </S.TableCell>
                    );
                  }
                )}
              </S.TableRow>
            </S.TableWrapper>
          </S.ScrollableContainer>
        </S.GraphContainer>
      ) : (
        <S.GraphContainer>
          <S.CircularIconWrapper top={'133rem'}>
            <CircularProgress style={{ color: '#64b2f9' }} />
          </S.CircularIconWrapper>
        </S.GraphContainer>
      )}
    </ModalPaper>
  );
};

export default ProductionGraph;
