import { useParams } from 'react-router-dom';
import { useState } from 'react';
import { WithHeader } from '@components/templates/mobile/WithHeader';
import * as S from './MaterialsStocksEdit.styles';
import ScrollToTop from '@components/modules/common/ScrollToTop/ScrollToTop';
import MaterialFormWrapper from '@components/modules/mobile/materials_stocks_edit/MaterialFormWrapper/MaterialFormWrapper';
import useMaterialsStocksForOneProduct from '@lib/mobile/materials_stocks_edit/useMaterialsStocksForOneProduct';
import { Todo } from '@lib/common/type';

export const MaterialsStocksEditRoot = () => {
  const {
    id,
    stock_kind,
    storeroom_id,
    date: dateStr,
  } = useParams<{
    id: string;
    stock_kind: string;
    date: string;
    storeroom_id: string;
  }>();
  const [date] = useState(new Date(dateStr));
  const [inputAmountParams, setInputAmountParams] = useState<Todo>([]);

  const { stocksForOneProduct: materialStocks, refetch } =
    useMaterialsStocksForOneProduct(id, storeroom_id);

  const stocks = materialStocks?.stocks;

  if (!stocks) return null;

  return (
    <>
      <ScrollToTop />
      <WithHeader>
        <S.Wrapper>
          <MaterialFormWrapper
            id={id}
            date={date}
            inputAmountParams={inputAmountParams}
            setInputAmountParams={setInputAmountParams}
            stock_kind={stock_kind}
            storeroom_id={storeroom_id}
            material={materialStocks}
            stocks={materialStocks?.stocks}
            refetch={refetch}
          />
        </S.Wrapper>
      </WithHeader>
    </>
  );
};
