import styled from 'styled-components';

export const DWrapper = styled.div`
  padding: 0 0.875rem 1rem 0.5rem;
`;

export const FormWrapper = styled.div`
  font-size: 15px;
  margin-bottom: 1rem;
  padding-top: 0.75rem;
  display: flex;
  justify-content: space-between;

  div:first-child {
    white-space: nowrap;
    font-weight: 700;
    margin-right: 0.5rem;
  }
`;

export const DRightBottomWrapper = styled.div`
  display: flex;
  align-items: center;
  padding: 1rem 0;
`;

export const DInputContainer = styled.div<{
  center?: boolean;
}>`
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 6px;
  > label {
    font-weight: 700;
    font-size: 13px;
  }
  > input {
    height: 2.75rem;
    max-width: 6.75rem;
    width: 100%;
    border-radius: 8px;
    text-align: ${(props) => (props.center ? 'center' : 'right')};
    padding-right: 12px;
    -webkit-text-fill-color: ${(props) => props.theme.palette.baseBlack};
    opacity: 1;

    :focus {
      outline: none;
    }

    :disabled {
      border: 2px solid #ffffff;
      background-color: transparent;
    }
  }

  > span {
    position: absolute;
    right: 0.3rem;
    top: 80%;
    font-size: 12px;
    margin-left: 4px;
    color: #6b675c;
  }
`;

export const DSymbol = styled.div`
  margin-top: 1rem;
  padding: 0 5px;
  font-weight: 700;
  font-size: 13px;
`;

export const NoteAmountContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: left;
  font-weight: 700;
  padding-top: 0.75rem;
  gap: 1rem;
  padding-bottom: 1.5rem;
  border-bottom: 2px solid #d3d3d3;
`;

export const NoteAmountLabel = styled.div`
  width: 3.5rem;
  font-weight: 700;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 5.5rem;
`;

export const NoteAmountInput = styled.textarea`
  background: #f8f7f4;
  border-radius: 8px;
  border: 2px solid ${(props) => props.theme.palette.white};
  width: 100%;
  height: 5.5rem;
  text-align: left;
  padding: 0.5rem;
  -webkit-text-fill-color: ${(props) => props.theme.palette.baseBlack};
  opacity: 1;

  resize: none;
`;
