import * as S from './SalesInformation.styles';
import { Heading } from '@components/elements/typographies/Heading';
import { WithHeader } from '@components/templates/WithHeader';
import { Button } from '@components/elements/buttons/Button';
import { ReactComponent as Add } from '@assets/icons/add.svg';
import { useHistory, useLocation } from 'react-router-dom';
import { useSalesInformationList } from '@lib/pc/sales_information/hooks';
import SalesManagementTable from '@components/modules/pc/sales_management/SalesManagementTable/SalesManagementTable';
import { useState, useEffect } from 'react';
import UpdatePopUp from '@components/modules/common/UpdatePopUp/UpdatePopUp';

export const SalesInformationRoot = () => {
  const history = useHistory();
  const location = useLocation();
  const search = location.search;
  const query = new URLSearchParams(search);
  const [created, setCreated] = useState(query.get('created') === '1');
  if (query.get('created') === '1') {
    query.delete('created');
    history.replace({ search: query.toString() });
  }
  const { salesInformationList } = useSalesInformationList();

  useEffect(() => {
    localStorage.setItem('last_view_page', 'sales_information');
  }, []);

  return (
    <WithHeader>
      <S.Wrapper>
        <div>
          <S.HeadContainer>
            <S.FlexBaseline>
              <div>
                <Heading tag="span" text="営業情報を入力" fs="32" fw="lg" />
              </div>
            </S.FlexBaseline>
            <div>
              <S.ButtonContainer>
                <Button
                  onClick={() => history.push('sales_information/new')}
                  padding="lg"
                  icon={<Add />}
                >
                  新規登録
                </Button>
              </S.ButtonContainer>
            </div>
          </S.HeadContainer>
          {salesInformationList && (
            <SalesManagementTable salesInformationList={salesInformationList} />
          )}
        </div>
      </S.Wrapper>
      <UpdatePopUp
        taskKind="sales_information"
        messageKind="create"
        popUp={created}
        handleClose={() => {
          setCreated(false);
        }}
        fromPc
      />
    </WithHeader>
  );
};
