import * as S from './PickingSearchForm.styles';
import { ReactComponent as SearchIcon } from '@assets/icons/mobile/search.svg';
import { ReactComponent as CloseIcon } from '@assets/icons/close_24px.svg';
import { ReactComponent as FilterIcon } from '@assets/icons/filter_list.svg';
import { ReactComponent as FilteredIcon } from '@assets/icons/mobile/filtered.svg';

type Props = {
  searchChar: string;
  setSearchChar: (searchChar: string) => void;
  statePickingStatus: number[];
  statusActive: boolean;
  setStatusActive: (statusActive: boolean) => void;
};

const PickingSearchForm = ({
  searchChar,
  setSearchChar,
  statePickingStatus,
  statusActive,
  setStatusActive,
}: Props) => {
  return (
    <S.SearchWrapper>
      <S.SearchArea>
        <S.SearchConditionInput
          placeholder="出荷先グループで検索"
          onChange={(event) => setSearchChar(event.target.value)}
          value={searchChar}
        />
        {searchChar ? (
          <S.Icon onClick={() => setSearchChar('')}>
            <CloseIcon />
          </S.Icon>
        ) : (
          <S.Icon>
            <SearchIcon />
          </S.Icon>
        )}
      </S.SearchArea>

      <S.ListLeftHeadCell onClick={() => setStatusActive(!statusActive)}>
        {statePickingStatus.length ? (
          <S.FilterWrapper
            filtered
            onClick={() => {
              setStatusActive(!statusActive);
            }}
          >
            <FilteredIcon />
            <span>絞り込み中</span>
          </S.FilterWrapper>
        ) : (
          <S.FilterWrapper
            onClick={() => {
              setStatusActive(!statusActive);
            }}
          >
            <FilterIcon />
            <span>ステータス</span>
          </S.FilterWrapper>
        )}
      </S.ListLeftHeadCell>
    </S.SearchWrapper>
  );
};

export default PickingSearchForm;
