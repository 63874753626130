import { FC } from 'react';
import moment from 'moment';
import {
  VictoryAxis,
  VictoryBar,
  VictoryChart,
  VictoryContainer,
  VictoryGroup,
} from 'victory';
import { ModalPaper } from '@components/modules/common/pc/ModalPaper';
import {
  extractTotalAmountForBarDisplay,
  generateDateArrayForModal,
  isSameDayWithOutTime,
  numberWithDecimalPlaces,
  changeWidth,
  findMinAmountForMaterialAllocationGraph,
  findMaxAmountForMaterialAllocationGraph,
} from '@lib/pc/stock_status/functions';
import * as S from './MaterialAllocationGraphWithTable.styles';
import {
  AllocationType,
  Storeroom,
  productStockAllocation,
} from '@lib/common/type';
import { removeLastItem } from '@lib/common/functions';
import { MaterialStockAllocationDetails } from '@lib/pc/material_allocation_status/type';

type Props = {
  selectedDate: moment.Moment;
  data: MaterialStockAllocationDetails[];
  selectedAllocationTypeId: number;
  allocationTypeOptions: AllocationType[];
  numberOfDaysParamsMonth: number;
  selectedStoreroomId: number;
  storerooms: Storeroom[] | undefined;
  setSelectedAllocationTypeId: (id: number) => void;
  setSelectedStoreroomId: (id: number) => void;
};

const MaterialAllocationGraphWithTable: FC<Props> = ({
  selectedDate,
  data,
  selectedAllocationTypeId,
  allocationTypeOptions,
  numberOfDaysParamsMonth,
  selectedStoreroomId,
  storerooms,
  setSelectedAllocationTypeId,
  setSelectedStoreroomId,
}: Props) => {
  const today = new Date();
  const newAllocations = data && removeLastItem(data);

  const barColor =
    selectedAllocationTypeId === -1
      ? '#F6C1B1'
      : selectedAllocationTypeId === 0
      ? '#B0F1D7'
      : selectedAllocationTypeId === 1
      ? '#F3DC9F'
      : '#E7E2DD';

  const minYCoordinate = findMinAmountForMaterialAllocationGraph(data);
  const tmpMaxY = findMaxAmountForMaterialAllocationGraph(data);
  const maxYCoordinate = minYCoordinate == 0 && tmpMaxY == 0 ? 1 : tmpMaxY;

  return (
    <ModalPaper
      heading1=""
      heading2="資料・原材料の払出状況の分析"
      selectedAllocationTypeId={selectedAllocationTypeId}
      allocationTypeOptions={allocationTypeOptions}
      setSelectedAllocationTypeId={setSelectedAllocationTypeId}
      selectedStoreroomId={selectedStoreroomId}
      handleChangeTransfer={setSelectedStoreroomId}
      storerooms={storerooms}
    >
      <S.ModalHeader>
        <S.FlexContainer>
          <S.FlexContainer>
            {selectedAllocationTypeId === -1 ? (
              <>
                <S.ColorBox bgColor="#F6C1B1" />
                全ての払出タイプ
              </>
            ) : selectedAllocationTypeId === 0 ? (
              <>
                <S.ColorBox bgColor="#B0F1D7" />
                廃棄数
              </>
            ) : (
              <>
                <S.ColorBox bgColor="#F3DC9F" />
                在庫移動数
              </>
            )}
          </S.FlexContainer>
        </S.FlexContainer>
      </S.ModalHeader>
      <S.GraphContainer>
        <S.ScrollableContainer>
          <S.LeftLabelContainer>
            <S.LeftGraphLabelContainer>
              <div>
                <VictoryChart
                  domainPadding={{ x: 50 }}
                  width={120}
                  height={320}
                  minDomain={{ y: minYCoordinate }}
                  maxDomain={{ y: maxYCoordinate }}
                  padding={{ top: 30, bottom: 0, right: 0, left: 0 }}
                  containerComponent={<VictoryContainer responsive={false} />}
                >
                  <VictoryAxis
                    width={130}
                    height={400}
                    minDomain={{ y: minYCoordinate }}
                    maxDomain={{ y: maxYCoordinate }}
                    dependentAxis
                    offsetX={120}
                    standalone
                    style={{
                      axisLabel: {
                        fontSize: 16,
                        padding: 30,
                        zIndex: 100,
                      },
                    }}
                  />
                  <VictoryGroup
                    groupComponent={<g display="none" />}
                    offset={20}
                  >
                    {/* グラフ左のY軸の値 */}
                    <VictoryBar
                      barWidth={16}
                      data={extractTotalAmountForBarDisplay(newAllocations)}
                      style={{ data: { display: 'none' } }}
                    />
                  </VictoryGroup>
                </VictoryChart>
              </div>
            </S.LeftGraphLabelContainer>
          </S.LeftLabelContainer>
          <VictoryChart
            height={320}
            width={changeWidth(numberOfDaysParamsMonth)}
            domainPadding={{ x: 70 }}
            padding={{ top: 30, bottom: 0, right: -45, left: 55 }}
            containerComponent={<VictoryContainer responsive={false} />}
          >
            <VictoryAxis
              dependentAxis
              //x
            />
            <VictoryAxis
              dependentAxis
              //y
              style={{
                grid: { stroke: '#E7E2DD' },
              }}
            />
            <VictoryGroup>
              <VictoryBar
                barWidth={16}
                style={{ data: { fill: barColor } }}
                data={extractTotalAmountForBarDisplay(newAllocations)}
              />
            </VictoryGroup>
          </VictoryChart>
          <S.TableWrapper>
            <S.TableRow>
              <S.TableAccordionHeadCell bgColor="transparent" />
              {generateDateArrayForModal(
                numberOfDaysParamsMonth,
                selectedDate
              ).map(
                (
                  day: { productionDate: Date; isToday: boolean },
                  i: number
                ) => {
                  return (
                    <S.TableHeadCell
                      key={`日付${i}`}
                      isToday={isSameDayWithOutTime(day.productionDate, today)}
                      dayOfWeek={moment(day.productionDate).format('ddd')}
                    >
                      <div>
                        {i === 0
                          ? moment(day.productionDate).format('MM/DD')
                          : moment(day.productionDate).format('DD')}
                        <span>
                          {moment(day.productionDate).format('(ddd)')}
                        </span>
                      </div>
                    </S.TableHeadCell>
                  );
                }
              )}
            </S.TableRow>
            <S.TableRow>
              <S.TableAccordionHeadCell color="#106CAE" bgColor="#E7E2DD">
                在庫払出数
              </S.TableAccordionHeadCell>
              {newAllocations &&
                newAllocations.map((p: productStockAllocation, i: number) => {
                  return (
                    <S.TableCell
                      key={`在庫払出数${i}`}
                      isToday={isSameDayWithOutTime(p.date, today)}
                      lastCell={true}
                    >
                      {p.totalAmount
                        ? numberWithDecimalPlaces(p.totalAmount, 2)
                        : '-'}
                    </S.TableCell>
                  );
                })}
            </S.TableRow>
          </S.TableWrapper>
        </S.ScrollableContainer>
      </S.GraphContainer>
    </ModalPaper>
  );
};

export default MaterialAllocationGraphWithTable;
