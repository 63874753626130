import { useWindowScroll } from 'react-use';
import { useEffect, useMemo, useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import moment from 'moment';
import * as S from './MaterialsForStocks.styles';
import BarcodeReader from '../RealtimeScanner/BarcodeReader';
import { ReactComponent as FactoryIcon } from '@assets/icons/mobile/factory.svg';
import scanSound from '@assets/sounds/sound.mp3';
import { WithHeader } from '@components/templates/mobile/WithHeader';
import { ReactComponent as ExpandMore } from '@assets/icons/expand_more.svg';
import ErrorMsgPopUp from '@components/modules/common/ErrorMsgPopUp/ErrorMsgPopUp';
import MaterialList from '@components/modules/mobile/materials_for_stocks/MaterialList/MaterialList';
import SearchConditionList from '@components/modules/common/mobile/SearchConditionList/SearchConditionList';
import SelectedTagList from '@components/modules/common/mobile/SelectedTagList/SelectedTagList';
import TagsForStocksModal from '@components/modules/mobile/tags_for_stocks_modal/TagsForStocksModal/TagsForStocksModal';
import StoreroomsForStocksModal from '@components/modules/mobile/storerooms_for_stocks_modal/StoreroomsForStocksModal/StoreroomsForStocksModal';
// import FloatingCameraButton from '@components/modules/common/mobile/FloatingCameraButton/FloatingCameraButton';
import { tagSearch } from '@lib/common/functions';
import { MATERIAL_PRODUCT_KIND, type Product } from '@lib/common/type';
import useTags from '@lib/mobile/common/hooks/useTags';
import useStorerooms from '@lib/mobile/common/hooks/useStorerooms';
import { useMaterialStocks } from '@lib/mobile/materials_for_stocks/useMaterialStocks';
import useProducts from '@lib/mobile/common/hooks/useProducts';
import type { MaterialsSearchState } from '@lib/mobile/materials_for_stocks/type';
import { searchCharFunc } from '@lib/mobile/materials_for_stocks/functions';
import type { Storeroom, Todo } from '@lib/common/type';

export const MaterialsForStocksRoot = () => {
  const history = useHistory();
  const [selectedTagIds, setSelectedTagIds] = useState<number[]>([]);
  const [selectedStoreroomId, setSelectedStoreroomId] = useState<number | ''>(
    ''
  );
  const [scanning, setScanning] = useState(true);
  const [results, setResults] = useState<Todo>([]);
  const [inputText] = useState('');
  const [errMsg, setErrMsg] = useState('');
  const [audio] = useState(new Audio(scanSound));
  const [selectedStoreroomName, setSelectedStoreroomName] = useState<
    string | ``
  >('');
  // 在庫室検索文字列
  const [searchStoreroom, setSearchStoreroom] = useState('');
  const [filteredStoreroom, setFilteredStoreroom] = useState<Storeroom[]>([]);
  const { state } = useLocation<MaterialsSearchState>();
  const { data: tags } = useTags(MATERIAL_PRODUCT_KIND.MATERIAL);
  const { materialStocks } = useMaterialStocks();
  const { data: storerooms } = useStorerooms(MATERIAL_PRODUCT_KIND.MATERIAL);
  const { data: products } = useProducts();

  // タグmodal画面open用
  const [tagsOpen, setTagsOpen] = useState(false);
  // 在庫室modal画面open用
  const [storeroomOpen, setStoreroomOpen] = useState(false);

  // 検索文字state
  const [searchChar, setSearchChar] = useState('');
  // スクロール位置state
  const [scrollPosition, setScrollPosition] = useState(0);
  // スクロール位置
  const { y: currentScrollPosition } = useWindowScroll();
  // products一覧state
  const [stateProductsData, setStateProductsData] = useState<Product[]>([]);
  // 検索➜在庫入力➜検索結果状態を取得する
  useEffect(() => {
    if (state === undefined) {
      setSearchChar('');
      setSelectedTagIds([]);
      setSelectedStoreroomName('');
      setSelectedStoreroomId('');
      setStateProductsData([]);
      setScrollPosition(0);
    } else {
      const {
        stateSearchChar,
        stateSelectedTagIds,
        stateSelectedStoreroom,
        stateSelectedStoreroomId,
        stateScrollPosition,
        // stateProductsData,
      } = state;
      window.scrollTo(0, stateScrollPosition);
      setSearchChar(stateSearchChar);
      setSelectedTagIds(stateSelectedTagIds ? stateSelectedTagIds : []);
      setSelectedStoreroomName(stateSelectedStoreroom);
      setSelectedStoreroomId(stateSelectedStoreroomId);
      setStateProductsData(stateProductsData);
      setScrollPosition(stateScrollPosition);
    }
    products && setStateProductsData(products);
  }, [materialStocks?.length]);

  const searchState = {
    stateSearchChar: searchChar,
    stateSelectedTagIds: selectedTagIds,
    stateSelectedStoreroom: selectedStoreroomName,
    stateSelectedStoreroomId: selectedStoreroomId,
    stateScrollPosition: currentScrollPosition,
    stateProductsData: stateProductsData,
  };

  useEffect(() => {
    if (state === undefined) {
      window.scrollTo(0, 0);
    } else {
      window.scrollTo(0, state.stateScrollPosition);
      window.scrollTo(0, scrollPosition);
    }
  }, [scrollPosition]);

  useEffect(() => {
    let combinedDataFromStocks: Todo[] = [];
    if (selectedStoreroomId && materialStocks) {
      const filteredFromStocks = materialStocks.filter(
        (stock: { storeroomIds: number[] }) =>
          stock.storeroomIds.includes(selectedStoreroomId)
      );
      combinedDataFromStocks = [...filteredFromStocks];
    }
    if (results.length !== 0) {
      const getData = [
        ...searchState.stateProductsData,
        ...combinedDataFromStocks,
      ].filter(
        (pro: Todo) =>
          pro.materialCode === results[0]?.codeResult?.code &&
          pro.storeroomIds.includes(Number(selectedStoreroomId))
      );
      if (!!getData && getData.length > 0) {
        history.push(
          `/mobile/materials/${getData[0].id}/stocks/${moment().format(
            'YYYY-MM-DD'
          )}/${selectedStoreroomId}/edit`,
          searchState
        );
      } else {
        // 該当のjanCodeがなかった場合
        setErrMsg('該当の資材原材料が見つかりませんでした。');
      }
    }
  }, [results]);

  const selectedTags =
    tags?.filter((tag) => selectedTagIds.includes(tag.id)) || [];

  // 在庫室選択時
  const storeroomFiltered = useMemo(() => {
    if (!materialStocks) return;
    if (selectedStoreroomId !== '') {
      return materialStocks.filter((a: { storeroomIds: number[] }) =>
        a.storeroomIds.includes(selectedStoreroomId)
      );
    }
  }, [selectedStoreroomId, materialStocks]);

  const filtered = useMemo(() => {
    if (!materialStocks) return;
    if (!storeroomFiltered) return;
    if (selectedTagIds.length > 0) {
      return storeroomFiltered.filter((a: Product) =>
        tagSearch(a, selectedTagIds)
      );
    } else if (searchChar) {
      return searchCharFunc(searchChar, storeroomFiltered);
    }
    return storeroomFiltered;
  }, [selectedTagIds, searchChar, selectedStoreroomId, materialStocks]);

  // 場所検索機能
  useEffect(() => {
    if (!storerooms) return;
    if (searchStoreroom) {
      setFilteredStoreroom(
        storerooms.filter((sr) => sr.name.match(searchStoreroom))
      );
    } else {
      setFilteredStoreroom(storerooms);
    }
  }, [searchStoreroom, storerooms]);

  if (tagsOpen) {
    return (
      <TagsForStocksModal
        setTagsOpen={setTagsOpen}
        selectedTagIds={selectedTagIds}
        setSelectedTagIds={setSelectedTagIds}
        fromComponent="materialsForStocks"
      />
    );
  }

  if (storeroomOpen) {
    return (
      <StoreroomsForStocksModal
        searchStoreroom={searchStoreroom}
        setSearchStoreroom={setSearchStoreroom}
        storerooms={filteredStoreroom}
        setStoreroomOpen={setStoreroomOpen}
        setSelectedStoreroomId={setSelectedStoreroomId}
        setSelectedStoreroomName={setSelectedStoreroomName}
        materialsSearchState={state}
        fromPicking={'materials_for_stocks'}
      />
    );
  }

  return (
    <WithHeader>
      <S.Wrapper>
        <S.Title>
          <S.TitleText>資材・原材料</S.TitleText>
          <S.StoreroomPanel onClick={() => setStoreroomOpen(true)}>
            <FactoryIcon />
            {selectedStoreroomName ? (
              <S.StorageText>{selectedStoreroomName}</S.StorageText>
            ) : (
              <S.StorageText>場所を選択</S.StorageText>
            )}
            <S.ExpandMore>
              <ExpandMore />
            </S.ExpandMore>
          </S.StoreroomPanel>
        </S.Title>
        {selectedStoreroomId && (
          <S.SearchConditionList>
            {selectedTags.length === 0 ? (
              <SearchConditionList
                setTagsOpen={setTagsOpen}
                searchChar={searchChar}
                setSearchChar={setSearchChar}
              />
            ) : (
              <SelectedTagList
                tags={selectedTags}
                clearAll={() => setSelectedTagIds([])}
                clear={(tagId) =>
                  setSelectedTagIds(selectedTagIds.filter((id) => id !== tagId))
                }
                tagsOpen={tagsOpen}
                setTagsOpen={setTagsOpen}
                clearTagSearch={() => {
                  setSelectedTagIds([]);
                }}
              />
            )}
          </S.SearchConditionList>
        )}
        <S.ProductList id="product-list">
          {filtered === undefined ? (
            <S.SelectStoreroomText>
              場所を選択してください。
            </S.SelectStoreroomText>
          ) : filtered?.length === 0 && selectedStoreroomId ? (
            <S.SelectStoreroomText>
              該当の商品はありません。
            </S.SelectStoreroomText>
          ) : (
            <>
              <MaterialList
                materials={filtered}
                searchChar={searchChar}
                selectedTagIds={selectedTagIds}
                selectedStoreroom={selectedStoreroomName}
                selectedStoreroomId={selectedStoreroomId}
                scrollPosition={currentScrollPosition}
              />
              {scanning ? (
                <BarcodeReader
                  onDetected={(result: Todo) => {
                    setScanning(false);
                    audio.play();
                    const newResult = {
                      codeResult: { code: result.codeResult.code },
                      inputText,
                    };
                    setResults([...results, newResult]);
                  }}
                />
              ) : (
                <ErrorMsgPopUp
                  fromPc={true}
                  errMsg={errMsg}
                  handleClose={() => {
                    setResults([]);
                    setScanning(true);
                    setErrMsg('');
                  }}
                />
              )}
            </>
          )}
        </S.ProductList>
      </S.Wrapper>
      {/* カメラボタン、一旦非表示 */}
      {/* {selectedStoreroomId && (
        <FloatingCameraButton
          handleClick={() =>
            history.push(`/mobile/live/${selectedStoreroomId}`, searchState)
          }
        />
      )} */}
    </WithHeader>
  );
};
export default MaterialsForStocksRoot;
