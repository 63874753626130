import { useMutation } from 'react-query';
import humps from 'humps';
import { axiosInstance } from '@lib/pc/common/api/axiosConfig';
import { CaseNameInputParam } from '@lib/pc/settings/product_master_setting/type';

// タグ作成
const useCreateCaseMutation = (handleSuccess: () => void) => {
  return useMutation(
    async (params: CaseNameInputParam) => {
      return await axiosInstance().post(
        `/api/v1/settings/cases`,
        humps.decamelizeKeys(params)
      );
    },
    {
      onSuccess: () => {
        handleSuccess();
      },
      onError: () => alert('エラーが発生しました'),
    }
  );
};

export default useCreateCaseMutation;
