import * as S from './PhotoSwipeForIndexModal.styles';
import { BestBeforeDateByPic } from '@lib/pc/wrapping/types';
import { ReactComponent as OkIcon } from '@assets/icons/ok.svg';
import { ReactComponent as NgIcon } from '@assets/icons/ng.svg';
import { ReactComponent as CautionIcon } from '@assets/icons/caution.svg';
import { Navigation, Pagination, Scrollbar, A11y } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/swiper-bundle.css';

type Props = {
  bestBeforeDateState: string;
  bestBeforeDateCheckCount: number;
  recognizedCnt: number;
  bestBeforeDatesForModal: BestBeforeDateByPic[];
  closeModal: () => void;
};

const PhotoSwipeForIndexModal = ({
  bestBeforeDateState,
  bestBeforeDateCheckCount,
  recognizedCnt,
  bestBeforeDatesForModal,
  closeModal,
}: Props) => {
  return (
    <>
      <S.Wrapper>
        <S.HeadContainer>
          <S.HeadingWrapper>
            <S.ButtonContainer>
              <S.HeaderButton onClick={closeModal}>戻る</S.HeaderButton>
              <S.DateCheckIcon>
                {bestBeforeDateState === 'DATE_RECOGNIZED' ? (
                  <OkIcon />
                ) : bestBeforeDateState === 'PARTIAL_DATE_RECOGNIZED' ? (
                  <CautionIcon />
                ) : bestBeforeDateState === 'DATE_NOT_RECOGNIZED' ? (
                  <NgIcon />
                ) : (
                  ''
                )}
              </S.DateCheckIcon>
              <S.MainTitle>日付認識数</S.MainTitle>
              {/* 写真から取得でした日付数／チェックする賞味期限総数の表示 */}
              <S.DateCheckContainer>
                <S.DateCount>{recognizedCnt}</S.DateCount>
                <S.DateText>/</S.DateText>
                <S.DateTotalCount>{bestBeforeDateCheckCount}</S.DateTotalCount>
              </S.DateCheckContainer>
              <S.DummyHeaderButton></S.DummyHeaderButton>
            </S.ButtonContainer>
          </S.HeadingWrapper>
        </S.HeadContainer>
        {/* swiper画面の表示 */}
        {bestBeforeDatesForModal.length > 0 ? (
          <S.Swiper>
            <Swiper
              modules={[Navigation, Pagination, Scrollbar, A11y]}
              navigation={{
                prevEl: '.button_prev',
                nextEl: '.button_next',
              }}
              // 下記で、デフォルトの左右のページネーション表示
              // navigation
              pagination={{
                // パラメータを設定
                el: '#pagination',
                // type: 'fraction', // 枚数表示
              }}
            >
              {bestBeforeDatesForModal.map((pic, i) => (
                <SwiperSlide key={i}>
                  <img src={pic.image} />
                </SwiperSlide>
              ))}
            </Swiper>
            <div id="pagination" className="swiper-pagination"></div>
          </S.Swiper>
        ) : (
          <S.NoPictureText>写真はまだ撮影されていません。</S.NoPictureText>
        )}
      </S.Wrapper>
    </>
  );
};

export default PhotoSwipeForIndexModal;
