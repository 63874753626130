import { useState, useEffect } from 'react';
import moment from 'moment';
import * as S from './ProductProductionResultForm.styles';
import { Button } from '@components/elements/buttons/Button';
import type { Todo } from '@lib/common/type';
import useUpdateProductProductionResultMutation from '@lib/pc/product_production_result/useUpdateProductProductionResultMutation';
import { calcTotalFuncForIndex } from '@lib/pc/product_production_result/functions';
import Overlay from '@components/elements/backdrops/Overlay/Overlay';
import LABEL_OF_AMOUNT_MAP from '@components/modules/common/LabelOfAmountMap/LabelOfAmountMap';

type Props = {
  index: number;
  editData: Todo;
  date: Date;
  edited: boolean;
  rightSlideSidebarActive: boolean;
  popUp: boolean;
  confirmMsg: string;
  setEdited: (edited: boolean) => void;
  setRightSlideSidebarActive: (rightSlideSidebarActive: boolean) => void;
  refetch: () => void;
  setPopUp: (popUp: boolean) => void;
  onEditChange: (index: number, edited: boolean) => void;
  labelOfAmount: string;
};

const ProductProductionResultForm = ({
  index,
  editData,
  date,
  rightSlideSidebarActive,
  popUp,
  confirmMsg,
  setEdited,
  setRightSlideSidebarActive,
  refetch,
  setPopUp,
  onEditChange,
  labelOfAmount,
}: Props) => {
  const [newData, setNewData] = useState(editData);
  const [inputEdited, setInputEdited] = useState(false);

  const changeData = (name: string, value: string | number) => {
    setEdited(true);
    setInputEdited(true);
    setNewData({ ...newData, [name]: value });
  };

  const changeTotal = (name: string, value: string | number) => {
    setNewData((prevData: Todo) => {
      const oldData = prevData;
      const newData = { ...oldData, [name]: Number(value) };
      const calcValue = calcTotalFuncForIndex(newData, newData.piecesPerBox);
      prevData = { ...newData, amount: calcValue };
      return prevData;
    });
  };

  const changeAmount = (name: string, value: string | number) => {
    changeData(name, value);
    changeTotal(name, value);
  };

  const onChangeAmount =
    (index: number) => (e: React.ChangeEvent<HTMLInputElement>) => {
      changeAmount(e.target.name, e.target.value);
      onEditChange(index, true);
    };

  const changeDefectiveProductAmount =
    (index: number) => (e: React.ChangeEvent<HTMLInputElement>) => {
      changeData(e.target.name, e.target.value);
      onEditChange(index, true);
    };

  const handleComment =
    (index: number) => (e: React.ChangeEvent<HTMLTextAreaElement>) => {
      setNewData({ ...newData, comment: e.target.value });
      setEdited(true);
      setInputEdited(true);
      onEditChange(index, true);
    };

  const handleClickCancel = (index: number) => {
    setNewData(editData);
    setEdited(false);
    setInputEdited(false);
    onEditChange(index, false);
  };

  const handleUpdateSuccess = () => {
    setEdited(false);
    setInputEdited(false);
    setRightSlideSidebarActive(false);
    setPopUp(!popUp);
    refetch();
  };

  // 完成品生産実績データ更新処理
  const updateProductProductionResultMutation =
    useUpdateProductProductionResultMutation(
      editData?.detailId,
      date,
      editData?.factoryLineId,
      handleUpdateSuccess
    );

  const handleSubmit = (newData: Todo) => {
    updateProductProductionResultMutation.mutate(newData);
  };

  useEffect(() => {
    setInputEdited(false);
  }, [rightSlideSidebarActive]);

  useEffect(() => {
    setNewData(editData);
  }, [editData]);

  return (
    <S.DWrapper>
      <S.FormWrapper>
        <div>{newData?.factoryAndLineName}</div>
      </S.FormWrapper>
      <S.FormWrapper>
        <div>在庫室</div>
        <div>{newData?.storeroomName}</div>
      </S.FormWrapper>
      <S.FormWrapper>
        <div>製造工程</div>
        <div>{newData?.productsProcessName}</div>
      </S.FormWrapper>
      <S.FormWrapper>
        <div>半製品の製造日</div>
        <div>{newData?.productionDateOfSemiProduct}</div>
      </S.FormWrapper>
      {/* {stockDateLabel && ( */}
      <S.FormWrapper>
        <div>出荷予定日</div>
        <div>
          {newData?.shippingDate &&
            moment(newData?.shippingDate).format('YYYY/MM/DD (ddd)')}
        </div>
      </S.FormWrapper>
      {/* )} */}
      <S.FormWrapper>
        <div>賞味期限日</div>
        <div>
          {newData?.bestBeforeDate &&
            moment(newData?.bestBeforeDate).format('YYYY/MM/DD (ddd)')}
        </div>
      </S.FormWrapper>
      <S.DRightBottomWrapper>
        <div>予定 &ensp;</div>
        <S.DInputContainer center={true}>
          <label htmlFor={`production-result-planned-case-${index}`}>
            ケース
          </label>
          <input
            id={`production-result-planned-case-${index}`}
            value={newData?.plannedCase || ''}
            type="text"
            disabled
          />
          <span>
            ×{newData?.piecesPerBox}
            {labelOfAmount ? LABEL_OF_AMOUNT_MAP[labelOfAmount] : '個'}
          </span>
        </S.DInputContainer>
        <S.DSymbol>+</S.DSymbol>
        <S.DInputContainer>
          <label htmlFor={`production-result-planned-piece-${index}`}>
            {labelOfAmount ? LABEL_OF_AMOUNT_MAP[labelOfAmount] : '個'}
          </label>
          <input
            id={`production-result-planned-piece-${index}`}
            value={newData?.plannedPiece || ''}
            type="text"
            disabled
          />
        </S.DInputContainer>
        <S.DSymbol>=</S.DSymbol>
        <S.DInputContainer>
          <label htmlFor="production-result-planned-total">合計</label>
          <input
            id="production-result-planned-total"
            value={
              newData?.plannedAmount
                ? Number(newData?.plannedAmount).toLocaleString()
                : ''
            }
            disabled
          />
        </S.DInputContainer>
      </S.DRightBottomWrapper>
      <S.DRightBottomWrapper>
        <div>実績 &ensp;</div>
        <S.DInputContainer center={true}>
          <label htmlFor={`production-result-case-${index}`}>ケース</label>
          <input
            name="case"
            id={`production-result-case-${index}`}
            value={newData?.case || ''}
            type="number"
            inputMode="numeric"
            onChange={onChangeAmount(index)}
            disabled={newData?.piecesPerBox === 1}
          />
          <span>
            ×{newData?.piecesPerBox}
            {labelOfAmount ? LABEL_OF_AMOUNT_MAP[labelOfAmount] : '個'}
          </span>
        </S.DInputContainer>
        <S.DSymbol>+</S.DSymbol>
        <S.DInputContainer>
          <label htmlFor={`production-result-piece-${index}`}>
            {labelOfAmount ? LABEL_OF_AMOUNT_MAP[labelOfAmount] : '個'}
          </label>
          <input
            name="piece"
            id={`production-result-piece-${index}`}
            value={newData?.piece || ''}
            type="number"
            inputMode="numeric"
            onChange={onChangeAmount(index)}
          />
        </S.DInputContainer>
        <S.DSymbol>=</S.DSymbol>
        <S.DInputContainer>
          <label htmlFor="production-result-total">合計</label>
          <input
            id="production-result-total"
            value={
              newData?.amount ? Number(newData?.amount).toLocaleString() : ''
            }
            disabled
          />
        </S.DInputContainer>
      </S.DRightBottomWrapper>
      <S.FormWrapper marginTop="1rem">
        <div>不良品個数</div>
        <input
          id="defective-product-amount"
          name="defectiveProductAmount"
          value={newData?.defectiveProductAmount || ''}
          type="number"
          inputMode="numeric"
          onChange={changeDefectiveProductAmount(index)}
        />
      </S.FormWrapper>
      <S.NoteAmountContainer>
        <S.NoteAmountLabel>備考</S.NoteAmountLabel>
        <S.NoteAmountInput
          placeholder=""
          value={newData?.comment || ''}
          onChange={handleComment(index)}
        />
      </S.NoteAmountContainer>

      <S.ButtonContainer invalid={false}>
        <Button
          borderWidth={1}
          outlined={true}
          disabled={
            !inputEdited || updateProductProductionResultMutation.isLoading
          }
          onClick={() => handleClickCancel(index)}
        >
          キャンセル
        </Button>
        <Button
          borderWidth={1}
          disabled={
            !inputEdited || updateProductProductionResultMutation.isLoading
          }
          onClick={() => handleSubmit(newData)}
        >
          {updateProductProductionResultMutation.isLoading ? '更新中' : '保存'}
        </Button>
      </S.ButtonContainer>
      {confirmMsg && <Overlay zIndex={9997} />}
    </S.DWrapper>
  );
};
export default ProductProductionResultForm;
