import { FC } from 'react';
import * as S from './AddShippingGroupModal.styles';
import { Button } from '@components/elements/buttons/Button';
import { Heading } from '@components/elements/typographies/Heading/Heading';
import ShippingGroupSelect from '@components/modules/pc/picking_by_shipping/ShippingGroupSelect/ShippingGroupSelect';
import { ShippingGroup } from '@lib/common/type';

type Props = {
  date: moment.Moment;
  shippingGroups: ShippingGroup[] | undefined;
  selectedShippingGroupId: number;
  setSelectedShippingGroupId: (selectedShippingGroupId: number) => void;
  handleCreatePickingGroup: () => void;
  handleCancel: () => void;
  setSelectedShippingGroupName: (selectedShippingGroupName: string) => void;
  scheduledTime: string;
  setScheduledTime: (scheduledTime: string) => void;
};

const AddShippingGroupModal: FC<Props> = ({
  date,
  shippingGroups,
  selectedShippingGroupId,
  setSelectedShippingGroupId,
  handleCreatePickingGroup,
  handleCancel,
  setSelectedShippingGroupName,
}: // scheduledTime,
// setScheduledTime,
Props) => {
  const handleSelectShippingGroup = (e: number) => {
    setSelectedShippingGroupId(e);
  };

  return (
    <S.Wrapper className="add-picking-product-modal">
      <S.FlexBaseline>
        <Heading
          tag="span"
          text={date.format('YYYY/MM/DD(ddd)')}
          fs="32"
          fw="lg"
        />
        <Heading tag="span" text="への出荷先グループの追加" fs="24" fw="lg" />
      </S.FlexBaseline>
      <S.InnerWrapper>
        <S.ShippingDestination>
          <S.ShippingDestinationSpan>出荷先グループ</S.ShippingDestinationSpan>
          <ShippingGroupSelect
            data={shippingGroups}
            handleSelectShippingGroup={handleSelectShippingGroup}
            value={selectedShippingGroupId}
            setSelectedShippingGroupName={setSelectedShippingGroupName}
          />
        </S.ShippingDestination>
        {/* <S.TimeWrapper>
          <S.TimeSpan>出荷予定時刻</S.TimeSpan>
          <S.TimeInput
            type="time"
            name="scheduled_Time"
            id="scheduled_Time"
            min="00:00"
            max="27:00"
            value={scheduledTime}
            onChange={(event) => setScheduledTime(event.target.value)}
          />
        </S.TimeWrapper> */}
      </S.InnerWrapper>
      <S.ButtonContainer>
        <Button borderWidth={1} outlined={true} onClick={handleCancel}>
          キャンセル
        </Button>
        <Button borderWidth={1} onClick={handleCreatePickingGroup}>
          保存
        </Button>
      </S.ButtonContainer>
    </S.Wrapper>
  );
};

export default AddShippingGroupModal;
