import { FC } from 'react';
import * as S from './Heading.styles';

type Props = {
  tag?: 'h1' | 'h2' | 'h3' | 'h4' | 'h5' | 'h6' | 'span';
  color?: string;
  text: string;
  align?: 'center' | 'right' | 'left';
  fs?: string;
  fw?: 'sm' | 'md' | 'lg';
};

export const Heading: FC<Props> = ({
  tag,
  text,
  color,
  fw,
  fs,
  align,
}: Props) => {
  return (
    <S.Root as={tag ? tag : 'div'} fw={fw} fs={fs} color={color} align={align}>
      {text}
    </S.Root>
  );
};
