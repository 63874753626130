import { useQuery } from 'react-query';
import humps from 'humps';
import { axiosInstance } from '@lib/pc/common/api/axiosConfig';
import type { Customer } from '@lib/pc/settings/customer_setting/type';

const useCustomers = () => {
  const { data, refetch } = useQuery('customers_setting', async () => {
    const { data } = await axiosInstance().get(`/api/v1/customers`);
    return data;
  });
  const customers = humps.camelizeKeys(data?.customers) as Customer[];

  return { customers, refetch };
};

export default useCustomers;
