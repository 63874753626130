import { axiosInstance } from '@lib/pc/common/api/axiosConfig';
import { useQuery } from 'react-query';
import humps from 'humps';
import { MaterialOrder } from '@lib/pc/purchase_orders/type';

const useMaterialOrder = (id: number | string | undefined | null) => {
  const { data, refetch } = useQuery(
    ['material_order_detail', id],
    async () => {
      const data = await axiosInstance().get(`/api/v1/material_orders/${id}`);
      return data;
    }
  );
  const materialOrder =
    data && (humps.camelizeKeys(data.data) as unknown as MaterialOrder);
  return { materialOrder, refetch };
};

export default useMaterialOrder;
