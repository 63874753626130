import styles from 'styled-components';

export const Wrapper = styles.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

export const BackButton = styles.button`
  padding: 0.875rem 1rem;
  background: none;
`;

export const IconWrapper = styles.div`
  width: 4rem;
`;

export const TitleText = styles.p`
  font-weight: bold;
  font-size: 1.125rem;
`;

export const SubmitButtonWrapper = styles.div``;

export const SubmitButton = styles.div<{ active: boolean }>`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 4rem;
  height: 2.5rem;
  padding: 0;
  background: ${(props) =>
    props.active ? props.theme.palette.blue : '#C0C0C0'};
  border-radius: 0.5rem;
  color: ${(props) => props.theme.palette.white};
  font-weight: bold;
  text-decoration: none;

  &:hover {
    text-decoration: none;
  }
`;
