import { Link } from 'react-router-dom';
import styled from 'styled-components';

export const Product = styled(Link)<{ index: number }>`
  display: flex;
  justify-content: space-between;
  padding: 0.625rem 1rem;
  border-bottom: 2px solid #f2f2f2;
  background: ${(props) =>
    props.index % 2 == 0
      ? props.theme.palette.white
      : props.theme.palette.paleYellow};

  &:hover {
    text-decoration: none;
    opacity: 1;
  }
`;

export const ProductInfo = styled.div`
  color: ${(props) => props.theme.palette.blue};
`;

export const ProductName = styled.p`
  font-weight: bold;
`;

export const ProductCode = styled.div`
  margin-top: 0.5rem;
  font-size: 0.75rem;
`;

export const StockInfo = styled.div`
  color: ${(props) => props.theme.palette.khaki};
  font-size: 0.75rem;
`;

export const StockDateIcon = styled.div`
  text-align: -webkit-right;
`;

export const ProductIcon = styled.div`
  width: 24px;
  height: 16px;
  color: #ffffff;
  background: #2ba9d1;
  border-radius: 2px;
  padding: 2px 6px;
`;

export const SemiProductIcon = styled.div`
  width: 24px;
  height: 16px;
  color: #2ba9d1;
  background: #ffffff;
  border-radius: 2px;
  padding-right: 0.25rem;
  border: 0.1rem solid #2ba9d1;
`;

export const Icon = styled.p`
  float: right;
`;

export const UpdateDate = styled.span`
  margin-right: 8px;
  font-weight: 400;
`;

export const StockDate = styled.p`
  margin-top: 0.625rem;
  text-align: right;
  font-weight: bold;
`;
