import styled from 'styled-components';

export const Wrapper = styled.div``;

// export const Wrapper = styled.div<{
//   scrollInvalid: boolean;
// }>`
//   /* padding: 17px 24px; */
//   position: ${(props) => (props.scrollInvalid ? 'fixed' : '')};
// `;
