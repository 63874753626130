import styled from 'styled-components';

export const FloatingIconWrapper = styled.button<{
  bottom?: string;
  right?: string;
}>`
  position: fixed;
  bottom: ${(props) => (props.bottom ? props.bottom : '6.5rem')};
  right: ${(props) => (props.right ? props.right : '1rem')};
  padding: 1rem;
  background: #fff;
  border-radius: 50%;
  cursor: pointer;
  filter: drop-shadow(0px 6px 10px rgba(0, 0, 0, 0.14))
    drop-shadow(0px 1px 18px rgba(0, 0, 0, 0.12))
    drop-shadow(0px 3px 5px rgba(0, 0, 0, 0.2));
`;
