import { useMutation } from 'react-query';
import humps from 'humps';
import { axiosInstance } from '@lib/pc/common/api/axiosConfig';
import { Todo } from '@lib/common/type';
import { Email } from '@lib/pc/purchase_orders/type';

const useCreateMaterialOrderForEmailMutation = (
  materialOrderId: number | null,
  handleUpdateSuccess: () => void,
  setErrMsg: (errMsg: string) => void
) => {
  return useMutation(
    async (params: Email) => {
      return await axiosInstance().post(
        `/api/v1/send_purchase_order_email/${materialOrderId}`,
        humps.decamelizeKeys(params)
      );
    },
    {
      onSuccess: () => {
        handleUpdateSuccess?.();
      },
      onError: (error: Todo) => {
        setErrMsg(error.response.data.message || 'エラーが発生しました');
      },
    }
  );
};

export default useCreateMaterialOrderForEmailMutation;
