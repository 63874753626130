import { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { WithHeader } from '@components/templates/mobile/WithHeader';
import moment from 'moment';
import * as S from './PickingByShipping.styles';
import { ReactComponent as CardboardBox } from '@assets/icons/mobile/cardboard_box.svg';
import { ReactComponent as CautionIcon } from '@assets/icons/caution.svg';
import AlertPickingPastShipDateModal from '@components/modules/mobile/picking_by_shipping/AlertPickingPastShipDateModal/AlertPickingPastShipDateModal';
import RSingleDatePicker from '@components/elements/inputs/RSingleDatepicker/RSingleDatepicker';
import Overlay from '@components/elements/backdrops/Overlay/Overlay';
import PickingStatusMenu from '@components/modules/mobile/picking/PickingStatusMenu/PickingStatusMenu';
import PickingSearchForm from '@components/modules/mobile/picking/PickingSearchForm/PickingSearchForm';
import PickingList from '@components/modules/mobile/picking/PickingList/PickingList';
import PickingSelectStoreroom from '@components/modules/mobile/picking/PickingSelectStoreroom/PickingSelectStoreroom';
import StoreroomsForStocksModal from '@components/modules/mobile/storerooms_for_stocks_modal/StoreroomsForStocksModal/StoreroomsForStocksModal';
import useProducts from '@lib/mobile/common/hooks/useProducts';
import useStorerooms from '@lib/mobile/common/hooks/useStorerooms';
import usePickings from '@lib/mobile/picking_by_shipping/usePickings';
import { PickingState } from '@lib/mobile/picking_edit/type';
import { PRODUCT_KIND, Product, Storeroom, Todo } from '@lib/common/type';

export const PickingByShippingRoot = () => {
  const { state } = useLocation<PickingState>();

  const [date, setDate] = useState(moment());
  const [calendarActive, setCalenderActive] = useState(false);
  // 検索文字state
  const [stateSearchChar, setSearchChar] = useState('');
  const [stateShippingDate, setShippingDate] = useState('');
  const [statePickingStatus, setStatePickingStatus] = useState<number[]>([]);
  const [stateShippings, setShippings] = useState<Todo>([]);
  const [stateScrollPosition, setScrollPosition] = useState(0);
  const [stateProducts, setProducts] = useState<Product[]>();
  const [scannedProductName, setScannedProductName] = useState('');
  // 編集画面からの戻り値を判定
  const [stateFromEditComponent, setFromEditComponent] = useState(false);
  const [janCode, setJanCode] = useState('');
  // ステータス絞り込みメニュー表示
  const [statusActive, setStatusActive] = useState(false);
  // 絞り込みステータス
  const [selectedStatus, setSelectedStatus] = useState<number[]>([]);
  const { data: products } = useProducts();
  // 在庫室modal画面open用
  const [storeroomOpen, setStoreroomOpen] = useState(false);
  const [selectedStoreroom, setSelectedStoreroom] = useState(() => {
    const storedNameValue = localStorage.getItem(
      'pickingSelectedStoreroomName'
    );

    return storedNameValue ? storedNameValue : '';
  });
  // 在庫室検索文字列
  const [searchStoreroom, setSearchStoreroom] = useState('');
  const [filteredStoreroom, setFilteredStoreroom] = useState<Storeroom[]>([]);
  const [selectedStoreroomId, setSelectedStoreroomId] = useState<number | ''>(
    () => {
      const storedValue = localStorage.getItem('pickingSelectedStoreroomId');

      return storedValue ? Number(storedValue) : '';
    }
  );
  const [storeroomName, setStoreroomName] = useState('');
  // RSingleDatePickerで日付選択したかを判定→edit画面(個別画面)への遷移時にfalse設定する
  const [fromDatePicker, setFromDatePicker] = useState(false);
  // チェック済以外表示チェックボックス
  const [stateCompletedCheckState, setCompletedCheckState] = useState(true);
  // 一覧画面からクリックしたときのピッキングステータス
  const [stateClickedPickingStatus, setStateClickedPickingStatus] = useState(0);

  const { data: storerooms } = useStorerooms(PRODUCT_KIND.PRODUCT);
  // 画面表示時に、当日のdateではなく、state.stateShippingDateがある場合はその日付でデータを取得する。
  // またカレンダーでの日付選択時にはその日付を表示する。
  const targetDate = fromDatePicker
    ? date.toDate()
    : state?.stateFromEditComponent && state?.stateShippingDate
    ? moment(state?.stateShippingDate).toDate()
    : date.toDate();

  // 画面に表示する日付
  const formattedDate = moment(targetDate).format('MM/DD');
  const formattedDayOfWeek = moment(targetDate).format('(ddd)');
  const { pickings: shippings } = usePickings(targetDate, 5000);

  // 出荷予定日を過ぎたピッキングデータありのmodal画面表示
  const [alertPickingPastShipDate, setAlertPickingPastShipDate] =
    useState(false);

  useEffect(() => {
    if (state === undefined) {
      setSearchChar('');
      setShippingDate('');
      setShippings('');
      setScrollPosition(0);
      setJanCode('');
      setStatePickingStatus([]);
      setScannedProductName('');
      setSelectedStoreroomId(selectedStoreroomId);
      setCompletedCheckState(true);
    } else {
      const {
        stateSearchChar,
        stateShippingDate,
        stateShippings,
        stateScrollPosition,
        stateJanCode,
        statePickingStatus,
        stateScannedProductName,
        stateSelectedStoreroomId,
        stateFromEditComponent,
        stateCompletedCheckState,
      } = state;
      setSearchChar(stateSearchChar);
      setShippingDate(stateShippingDate);
      setShippings(stateShippings);
      window.scrollTo(0, stateScrollPosition);
      // setScrollPosition(stateScrollPosition);
      setJanCode(stateJanCode);
      setStatePickingStatus(statePickingStatus);
      setProducts(stateProducts);
      setScannedProductName(stateScannedProductName);
      setSelectedStoreroomId(stateSelectedStoreroomId);
      setFromEditComponent(stateFromEditComponent);
      setCompletedCheckState(stateCompletedCheckState);
      setStateClickedPickingStatus(shippings && shippings.pickingStatus);
    }
    products && setProducts(products);
  }, [state]);

  const pickingState = {
    stateSearchChar: stateSearchChar,
    stateShippingDate: stateShippingDate,
    stateShippings: stateShippings,
    stateScrollPosition: stateScrollPosition,
    stateJanCode: janCode,
    statePickingStatus: statePickingStatus,
    stateProducts: stateProducts,
    stateScannedProductName: scannedProductName,
    stateSelectedStoreroomId: selectedStoreroomId,
    stateFromEditComponent: stateFromEditComponent,
    stateCompletedCheckState: stateCompletedCheckState,
    stateClickedPickingStatus: stateClickedPickingStatus,
  };

  // 場所検索機能
  useEffect(() => {
    if (!storerooms) return;
    if (searchStoreroom) {
      setFilteredStoreroom(
        storerooms.filter((sr) => sr.name.match(searchStoreroom))
      );
    } else {
      setFilteredStoreroom(storerooms);
    }
  }, [searchStoreroom, storerooms]);

  useEffect(() => {
    if (state?.stateSelectedStoreroomId) {
      const selectedStoreroom = storerooms?.filter(
        (s: Storeroom) => s.id === state.stateSelectedStoreroomId
      );
      selectedStoreroom && setStoreroomName(selectedStoreroom[0]?.name);
    } else if (state?.stateSelectedStoreroomId === '') {
      setSelectedStoreroom('');
      setStoreroomName('');
    }
  }, [state?.stateSelectedStoreroomId, storerooms]);

  useEffect(() => {
    setStatePickingStatus(selectedStatus);
  }, [selectedStatus]);

  if (storeroomOpen && pickingState) {
    return (
      <StoreroomsForStocksModal
        searchStoreroom={searchStoreroom}
        setSearchStoreroom={setSearchStoreroom}
        storerooms={filteredStoreroom}
        setStoreroomOpen={setStoreroomOpen}
        setSelectedStoreroomId={setSelectedStoreroomId}
        setSelectedStoreroomName={setSelectedStoreroom}
        fromPicking={'picking_by_shipping'}
        pickingState={pickingState}
      />
    );
  }

  return (
    <WithHeader>
      <S.Wrapper>
        <S.Title>
          <S.TitleText>
            <S.TitleContainer>
              <S.IconContainer>
                <CardboardBox />
              </S.IconContainer>
              ピッキング（出荷先別）
            </S.TitleContainer>
          </S.TitleText>
        </S.Title>
        <S.Container>
          <S.StickyContainer>
            <S.StickyItem>
              <S.Title className="plannedTitle">
                <S.TitleContainer>
                  <S.PlanDate>{formattedDate}</S.PlanDate>
                  <S.PlanDayOfWeek>{formattedDayOfWeek}</S.PlanDayOfWeek>
                  <S.PlanText>の出荷予定</S.PlanText>
                </S.TitleContainer>
                <div style={{ display: 'flex' }}>
                  {shippings?.dataOfPastShipDate[0]?.totalAmount && (
                    <S.CautionIconWrapper>
                      <CautionIcon
                        onClick={() => setAlertPickingPastShipDate(true)}
                      />
                    </S.CautionIconWrapper>
                  )}
                  <RSingleDatePicker
                    date={date}
                    setDate={setDate}
                    invalid={calendarActive}
                    fromMobile={true}
                    calendarBlue={true}
                    setFromDatePicker={setFromDatePicker}
                  />
                </div>
              </S.Title>
              {/* 在庫室検索エリア */}
              {storerooms && (
                <PickingSelectStoreroom
                  setStoreroomOpen={setStoreroomOpen}
                  selectedStoreroom={selectedStoreroom}
                  storeroomName={storeroomName}
                  // storerooms={storerooms}
                  // storeroomId={state?.stateSelectedStoreroomId}
                />
              )}
              {/* 出荷先グループ、ステータス検索エリア */}
              <PickingSearchForm
                searchChar={stateSearchChar}
                setSearchChar={setSearchChar}
                statePickingStatus={statePickingStatus}
                statusActive={statusActive}
                setStatusActive={setStatusActive}
              />
            </S.StickyItem>
            <PickingList
              targetDate={targetDate}
              pickingState={pickingState}
              pickings={shippings}
              fromPickingByShippingDestination={true}
              setFromDatePicker={setFromDatePicker}
              selectedStoreroomId={
                state?.stateSelectedStoreroomId
                  ? state?.stateSelectedStoreroomId
                  : selectedStoreroomId
                  ? selectedStoreroomId
                  : 0
              }
            />
          </S.StickyContainer>
        </S.Container>
      </S.Wrapper>
      {calendarActive && (
        <Overlay dark handleClick={() => setCalenderActive(false)} />
      )}
      {statusActive && (
        <PickingStatusMenu
          selectedStatus={selectedStatus}
          setSelectedStatus={setSelectedStatus}
          statePickingStatus={statePickingStatus}
          setStatePickingStatus={setStatePickingStatus}
          useAllocation={shippings && shippings.useAllocation}
        />
      )}
      {statusActive && <Overlay handleClick={() => setStatusActive(false)} />}
      {alertPickingPastShipDate && (
        <AlertPickingPastShipDateModal
          setDate={setDate}
          setFromDatePicker={setFromDatePicker}
          setAlertPickingPastShipDate={setAlertPickingPastShipDate}
          dataOfPastShipDate={shippings?.dataOfPastShipDate}
          pickingState={pickingState}
        />
      )}
      {alertPickingPastShipDate && (
        <Overlay
          zIndex={2}
          handleClick={() => setAlertPickingPastShipDate(false)}
        />
      )}
    </WithHeader>
  );
};
