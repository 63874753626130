import styled from 'styled-components';

export const Wrapper = styled.div<{
  top?: string;
  right?: string;
  left?: string;
}>`
  width: 359px;
  height: auto;
  padding: 8px 8px 0px 8px;
  background: ${(props) => props.theme.palette.beige};
  box-shadow: 0px 12px 17px rgba(0, 0, 0, 0.07),
    0px 5px 22px rgba(0, 0, 0, 0.06), 0px 7px 8px rgba(0, 0, 0, 0.1);
  position: absolute;
  z-index: 9998;
  gap: 12px;
  border-radius: 4px;
  top: ${(props) => (props.top ? props.top : '130px')};
  right: ${(props) => (props.right ? props.right : '')};
  left: ${(props) => (props.left ? props.left : '')};
`;
export const Header = styled.div`
  height: 2.5rem;
  margin-bottom: 0.75rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

export const HeaderTitle = styled.div`
  display: flex;
`;

export const HeaderTitleText = styled.div`
  font-weight: 500;
  font-size: 1rem;
  line-height: 1.4375rem;
  margin-left: 0.5625rem;
  color: ${(props) => props.theme.palette.khaki};
`;

export const CloseIconWrapper = styled.div`
  padding: 9px;
  background: none;
  float: right;
  cursor: pointer;
`;

export const Main = styled.div``;

export const Input = styled.input<{
  tagSelected?: boolean;
}>`
  width: 100%;
  height: 2.5rem;
  color: ${(props) => props.theme.palette.black};
  font-size: 1rem;
  line-height: 1rem;
  padding: 0.625rem;
  padding-left: 3rem;
  background-color: ${(props) =>
    props.tagSelected ? props.theme.palette.beige : props.theme.palette.white};
  outline: ${(props) => (props.tagSelected ? '' : 'solid 1px #c0c0c0')};
  border-radius: 8px;
  cursor: pointer;
  font-feature-settings: 'palt';

  ::placeholder {
    color: ${(props) => props.theme.palette.khaki};
    font-weight: 400;
    font-size: 1rem;
  }
`;

export const Icon = styled.div`
  color: ${(props) => props.theme.palette.khaki};
  padding-left: 0.75rem;
  position: absolute;
  top: 0;
  bottom: 0;
  display: flex;
  align-items: center;
  svg {
    width: 1.5rem;
    height: 1.5rem;
  }
`;

export const InputWrapper = styled.div`
  position: relative;
  display: flex;
  margin-bottom: 0.75rem;
  width: 100%;
`;

export const DeleteIcon = styled.div`
  color: ${(props) => props.theme.palette.khaki};
  background-color: ${(props) => props.theme.palette.white};
  border-radius: 0 7.8px 7.8px 0;
  position: absolute;
  top: 8px;
  right: 5px;
  display: flex;
  align-items: center;
  cursor: pointer;
  svg {
    width: 1.5rem;
    height: 1.5rem;
  }
`;
