// 上段 ケース種類あり
export const TABLE_HEADER_WITH_CASE_KIND = [
  { key: '半製品', align: 'left', width: '160px', colSpan: 1 },
  {
    key: '払出先',
    align: 'left',
    wordBreak: 'keep-all',
    colSpan: 3,
  },
  {
    key: '製造日',
    align: 'left',
    wordBreak: 'keep-all',
    colSpan: 3,
  },
];

// 上段 ケース種類なし
export const TABLE_HEADER = [
  { key: '完成品', align: 'left', width: '160px', colSpan: 3 },
  {
    key: '払出先',
    align: 'left',
    wordBreak: 'keep-all',
    colSpan: 1,
  },
  {
    key: '賞味期限日',
    align: 'left',
    wordBreak: 'keep-all',
    colSpan: 3,
  },
];

// 下段 ケース種類あり
export const TABLE_HEADER_WITH_CASE_KIND_UNDER = [
  {
    key: 'ケースの種類',
    align: 'left',
    wordBreak: 'keep-all',
    colSpan: 1,
  },
  { key: '払出数', align: 'right', colSpan: 3 },
  { key: '備考', align: 'right', colSpan: 3 },
];

// 下段 ケース種類なし
export const TABLE_HEADER_UNDER = [
  { key: '払出数', align: 'right', colSpan: 3 },
  {
    key: '備考',
    align: 'left',
    wordBreak: 'keep-all',
    colSpan: 4,
  },
];
