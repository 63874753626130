import { ChangeEvent, FC, useState, useEffect, useRef } from 'react';
import * as S from './AutoComplete.styles';
import type { EmployeeData } from '@lib/common/type';

interface autoCompleteProps {
  employeeSuggestions: EmployeeData[];
  // employeeSuggestions: EmployeeData[];
  // ここでは一人の従業員だけ設定するから配列の必要はない
  singleEmployee: EmployeeData | null;
  editEmployees: EmployeeData[];
  setEditEmployees: (editEmployees: EmployeeData[]) => void;
  inputNo: number;
}

// TODO: suggestionsを渡す

export const AutoComplete: FC<autoCompleteProps> = ({
  // 従業員の選択肢
  employeeSuggestions,
  // 選択従業員一人の設定
  singleEmployee,
  editEmployees,
  setEditEmployees,
  inputNo,
}) => {
  const sortEmployeeSuggestions = employeeSuggestions.sort((a, b) => {
    const numA = Number(a.code.replace(/[^\d]/g, '')) || Infinity;
    const numB = Number(b.code.replace(/[^\d]/g, '')) || Infinity;
    return numA - numB;
  });
  const [search, setSearch] = useState<{
    text: string;
    suggestions: EmployeeData[];
  }>({
    text: '',
    suggestions: sortEmployeeSuggestions,
  });
  const [isComponentVisible, setIsComponentVisible] = useState(true);
  const [inputStates, setInputStates] = useState([
    { id: 'input0', isFocused: false },
    { id: 'input1', isFocused: false },
    { id: 'input2', isFocused: false },
  ]);
  const isMounted = useRef(true);
  const { suggestions } = search;

  const getInputFocused = (indexNo: number) => {
    const inputFocused = inputStates.find(
      (inputState) => inputState.id === `input${indexNo}`
    );
    return inputFocused ? inputFocused.isFocused : false;
  };

  const onTextChanged = (e: ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value;
    let suggestions: EmployeeData[] = [];
    if (value.length > 0) {
      suggestions = employeeSuggestions
        .sort()
        .filter(
          (v: EmployeeData) => v.code.includes(value) || v.name.includes(value)
        );
    }
    setIsComponentVisible(true);
    // 候補の配列を設定
    setSearch({ suggestions, text: value });
  };

  // 従業員を選択したとき**********************************
  const suggestionSelected = (value: EmployeeData) => {
    setIsComponentVisible(false);

    // 指定配列箇所へ、新しく選択された従業員を設定
    setEditEmployees([...editEmployees, value]);
  };

  // バツボタン押した時
  const handleClickClear = () => {
    editEmployees.splice(inputNo, 1);
    setEditEmployees([...editEmployees]);
  };

  const handleFocus = (id: string, isBlur: boolean) => {
    const updatedStates = inputStates.map((inputState) => {
      if (inputState.id === id) {
        if (isBlur) {
          setTimeout(() => {
            if (isMounted.current) {
              setInputStates((prevInputStates) =>
                prevInputStates.map((prevInputState) =>
                  prevInputState.id === id
                    ? { ...prevInputState, isFocused: false }
                    : prevInputState
                )
              );
            }
          }, 50);
        }
        return { ...inputState, isFocused: true };
      }
      return inputState;
    });

    setInputStates(updatedStates);
  };

  useEffect(() => {
    return () => {
      isMounted.current = false;
    };
  }, []);

  return (
    <S.Root>
      <S.ComponentVisible
        onClick={() => setIsComponentVisible(false)}
        isComponentVisible={isComponentVisible}
      />
      <S.InputContainer>
        <S.Input
          id={`input${inputNo}`}
          autoComplete="off"
          value={
            search.text
              ? search.text
              : singleEmployee
              ? `${singleEmployee.code}: ${singleEmployee.name}`
              : ''
          }
          onChange={onTextChanged}
          onFocus={() => handleFocus(`input${inputNo}`, false)}
          onBlur={() => handleFocus(`input${inputNo}`, true)}
          type="text"
          inputMode="numeric"
          required
        />
        {singleEmployee && (
          <S.ClearButton
            onClick={() => {
              handleClickClear();
            }}
          />
        )}
      </S.InputContainer>
      {suggestions.length > 0 &&
        isComponentVisible &&
        getInputFocused(inputNo) && (
          <S.AutoCompleteContainer>
            {suggestions.map((item: EmployeeData) => (
              <S.AutoCompleteItem key={item.id}>
                <S.AutoCompleteItemButton
                  key={item.id}
                  onClick={() => suggestionSelected(item)}
                >
                  {item.code}: {item.name}
                </S.AutoCompleteItemButton>
              </S.AutoCompleteItem>
            ))}
          </S.AutoCompleteContainer>
        )}
    </S.Root>
  );
};
