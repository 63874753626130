import { ReactComponent as TagIcon } from '@assets/icons/mobile/tag.svg';
import { ReactComponent as SearchIcon } from '@assets/icons/mobile/search.svg';
import * as S from './SearchConditionList.styles';

type Props = {
  setTagsOpen: (tagsOpen: boolean) => void;
  searchChar: string;
  setSearchChar: (searchChar: string) => void;
};

const SearchConditionList = ({
  setTagsOpen,
  searchChar,
  setSearchChar,
}: Props) => {
  return (
    <>
      <S.SearchConditionPanelList>
        <S.SearchConditionPanel onClick={() => setTagsOpen(true)}>
          <TagIcon />
          <S.SearchConditionText>全てのタグ</S.SearchConditionText>
        </S.SearchConditionPanel>
        <S.SearchConditionPanel>
          <SearchIcon />
          <S.SearchConditionInput
            placeholder="商品/コードで検索"
            onChange={(event) => setSearchChar(event.target.value)}
            value={searchChar}
          />
        </S.SearchConditionPanel>
      </S.SearchConditionPanelList>
    </>
  );
};
export default SearchConditionList;
