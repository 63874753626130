import styled from 'styled-components';

export const Wrapper = styled.div`
  background: ${(props) => props.theme.palette.white};
  position: relative;
  height: calc(100vh - 5rem);
`;

export const HeadContainer = styled.div`
  height: 88px;
  padding: 1.15rem 2.5rem;
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

export const FlexBaseline = styled.div`
  display: flex;
  align-items: baseline;
`;

export const ButtonContainer = styled.div`
  display: flex;
  align-items: center;
  & > button {
    width: 160px;
    height: 44px;
  }
`;

export const Shinb = styled.button`
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #f3ede6;
`;
