import { useEffect, useState } from 'react';
import * as S from './PickingDetailByDate.styles';
import { ReactComponent as AddIcon } from '@assets/icons/mobile/add.svg';
import { ReactComponent as ArrowUpOutlined } from '@assets/icons/arrow_up_outlined.svg';
import { ReactComponent as DeleteIcon } from '@assets/icons/delete.svg';
import { ReactComponent as ExpandMore } from '@assets/icons/expand_more.svg';
import DeleteModal from '@components/modules/mobile/picking_edit/DeleteConfirmModal/DeleteConfirmModal';
// import { PickingInputParams } from '@lib/mobile/picking_edit/type';
import { Todo } from '@lib/common/type';
import {
  DetailsByProduct,
  // DetailsByProduct,
  PickingBreakdownDetail,
} from '@lib/mobile/picking_edit/type';
import { calcTotal } from '@lib/mobile/picking_edit/functions';

type Props = {
  detailByProductId: number;
  casesPerCart: number;
  piecesPerBox: number;
  totalOfDetailsByProduct: number;
  shortageAmount: number;
  setShortageAmount: (shortageAmount: number) => void;
  pickingBreakdownDetail: PickingBreakdownDetail[];
  handleUpdateIndexNewData: (
    pickingBreakdownDetail: PickingBreakdownDetail[],
    detailByProductId: number,
    shortageAmount: number
  ) => void;
  newData: DetailsByProduct[];
  setNewData: (newData: DetailsByProduct[]) => void;
};

// ピッキング初期値
const initializeAmount = {
  bestBeforeDate: '',
  cart: 0,
  case: 0,
  piece: 0,
  amount: 0,
};

const PickingDetailByDate = ({
  detailByProductId,
  casesPerCart,
  piecesPerBox,
  totalOfDetailsByProduct,
  // shortageAmount,
  setShortageAmount,
  pickingBreakdownDetail,
  handleUpdateIndexNewData,
}: // newData,
// setNewData,
Props) => {
  const [newDetailData, setNewDetailData] = useState(pickingBreakdownDetail);
  const [detailActive, setDetailActive] = useState(false);
  const [shortage, setShortage] = useState(0);
  const [confirmModal, setConfirmModal] = useState(false);
  const [delIndex, setDelIndex] = useState(0);

  // カートの更新
  const handleChangeCart = (
    i: number,
    casesPerCart: number | '',
    piecesPerBox: number | '',
    value: string | '',
    caseNum: string | '',
    piece: string | ''
  ) => {
    let amount: number;
    if (value.match(/^[0-9]*$/)) {
      amount = calcTotal(piecesPerBox, Number(caseNum), Number(piece));
    }
    // カートと合計値を更新する
    setNewDetailData((prevState: PickingBreakdownDetail[]) =>
      prevState.map((obj: PickingBreakdownDetail, index: number) =>
        index === i
          ? { ...obj, cart: Number(value), amount: Number(amount) }
          : obj
      )
    );
  };

  // ケースの更新
  const handleChangeCase = (
    i: number,
    casesPerCart: number | '',
    piecesPerBox: number | '',
    cart: string | '',
    value: string | '',
    piece: string | ''
  ) => {
    let amount: number;
    if (value.match(/^[0-9]*$/)) {
      amount = calcTotal(piecesPerBox, Number(value), Number(piece));
    }
    // ケースと合計値を更新する
    setNewDetailData((prevState: PickingBreakdownDetail[]) =>
      prevState.map((obj: PickingBreakdownDetail, index: number) =>
        index === i
          ? { ...obj, case: Number(value), amount: Number(amount) }
          : obj
      )
    );
  };

  // バラの更新
  const handleChangePiece = (
    i: number,
    casesPerCart: number | '',
    piecesPerBox: number | '',
    cart: string | '',
    caseNum: string | '',
    value: string | ''
  ) => {
    let amount: number;
    if (value.match(/^[0-9]*$/)) {
      amount = calcTotal(piecesPerBox, Number(caseNum), Number(value));
    }
    // ケースと合計値を更新する
    setNewDetailData((prevState: PickingBreakdownDetail[]) =>
      prevState.map((obj: PickingBreakdownDetail, index: number) =>
        index === i
          ? { ...obj, piece: Number(value), amount: Number(amount) }
          : obj
      )
    );
  };

  // 賞味期限日選択時
  const handleChangeDate = (i: number, value: string) => {
    setNewDetailData((prevState: PickingBreakdownDetail[]) =>
      prevState.map((obj: PickingBreakdownDetail, index: number) =>
        index === i ? { ...obj, bestBeforeDate: value } : obj
      )
    );
  };

  const handleDeleteButton = (index: number) => {
    newDetailData.splice(index, 1);
  };

  // ピッキング入力追加ボタン押下時処理
  const AddPickingDetail = () => {
    // 今までの配列に空データをpushする
    newDetailData.push(initializeAmount);
    handleUpdateIndexNewData(newDetailData, detailByProductId, 0);
  };

  useEffect(() => {
    const total = newDetailData.reduce((sum, i) => sum + i.amount, 0);
    const calcShortage = totalOfDetailsByProduct - total;
    setShortage(calcShortage);
    setShortageAmount(calcShortage);
    // 全体のピッキングデータを更新する
    handleUpdateIndexNewData(newDetailData, detailByProductId, calcShortage);
  }, [newDetailData, newDetailData.length, shortage]);

  useEffect(() => {
    // 全体のピッキングデータを更新する
    handleUpdateIndexNewData(newDetailData, detailByProductId, shortage);
  }, [shortage]);

  return (
    <S.Wrapper>
      <S.TitleText onClick={() => setDetailActive(!detailActive)}>
        ピッキングした内訳を入力
        {detailActive ? <ArrowUpOutlined /> : <ExpandMore />}
      </S.TitleText>
      <S.DetailWrapper open={detailActive}>
        {newDetailData.map((p: Todo, index: number) => (
          <S.Detail key={index}>
            <S.ExpirationDate>
              <S.LabelWrapper>
                <S.ProductionOrShippingDateLabel
                  htmlFor={`production-date-${index}`}
                >
                  賞味期限
                </S.ProductionOrShippingDateLabel>
                <S.Input
                  type="date"
                  name="productionDate"
                  id={`production-date-${index}`}
                  value={p.bestBeforeDate ? p.bestBeforeDate : ''}
                  onChange={(event) =>
                    handleChangeDate?.(index, event.target.value)
                  }
                  pattern="\d{4}-\d{2}-\d{2}"
                  autoComplete="off"
                  max="9999-12-31"
                />
                <S.DeleteButtonWrapper>
                  <S.DeleteButton
                    onClick={() => {
                      setDelIndex(index);
                      setConfirmModal(true);
                    }}
                  >
                    <DeleteIcon />
                  </S.DeleteButton>
                </S.DeleteButtonWrapper>
              </S.LabelWrapper>
            </S.ExpirationDate>
            {/* 在庫室の選択 */}
            {/* ピッキング入力エリア */}
            <S.AmountWrapper>
              {casesPerCart && (
                <>
                  <S.DInputContainer center={true}>
                    <label htmlFor={`new-stock-cart-${index}`}>台車</label>
                    <input
                      id={`new-stock-cart-${index}`}
                      value={p.cart ? p.cart : ''}
                      onChange={(event) =>
                        handleChangeCart(
                          index,
                          casesPerCart,
                          piecesPerBox,
                          event.target.value,
                          p.case,
                          p.piece
                        )
                      }
                      type="text"
                      pattern="^[1-9][0-9]*$"
                      inputMode="numeric"
                      autoComplete="off"
                      max="9999-12-31"
                    />
                    <span>×{casesPerCart}ｹｰｽ</span>
                  </S.DInputContainer>
                  <S.DSymbol>+</S.DSymbol>
                </>
              )}
              <S.DInputContainer center={true}>
                <label htmlFor={`new-stock-case-${index}`}>ケース</label>
                <input
                  id={`new-stock-case-${index}`}
                  value={p.case ? p.case : ''}
                  onChange={(event) =>
                    handleChangeCase(
                      index,
                      casesPerCart,
                      piecesPerBox,
                      p.cart,
                      event.target.value,
                      p.piece
                    )
                  }
                  type="text"
                  pattern="^[1-9][0-9]*$"
                  inputMode="numeric"
                  autoComplete="off"
                  max="9999-12-31"
                />
                <span>×{piecesPerBox}個</span>
              </S.DInputContainer>
              <S.DSymbol>+</S.DSymbol>
              <S.DInputContainer>
                <label htmlFor={`new-stock-bara-${index}`}>個</label>
                <input
                  id={`new-stock-bara-${index}`}
                  value={p.piece ? p.piece : ''}
                  onChange={(event) =>
                    handleChangePiece(
                      index,
                      casesPerCart,
                      piecesPerBox,
                      p.cart,
                      p.case,
                      event.target.value
                    )
                  }
                  type="text"
                  pattern="^[1-9][0-9]*$"
                  inputMode="numeric"
                  autoComplete="off"
                />
              </S.DInputContainer>
              <S.DSymbol>=</S.DSymbol>
              <S.DInputContainer>
                <label htmlFor="new-stock-total">合計</label>
                <input
                  id="new-stock-total"
                  value={p.amount ? p.amount : ''}
                  disabled
                />
              </S.DInputContainer>
            </S.AmountWrapper>
          </S.Detail>
        ))}
        <S.AddIconWrapper onClick={() => AddPickingDetail()}>
          <AddIcon />
        </S.AddIconWrapper>
      </S.DetailWrapper>
      <DeleteModal
        confirmModal={confirmModal}
        setConfirmModal={setConfirmModal}
        delIndex={delIndex}
        handleDeleteButton={handleDeleteButton}
      />
    </S.Wrapper>
  );
};

export default PickingDetailByDate;
