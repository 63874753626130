import { format } from 'date-fns';
import * as S from './MaterialList.styles';
import moment from 'moment';
import { FC } from 'react';
import { MATERIAL_TYPE, Material } from '@lib/common/type';

type Props = {
  materials: Material[] | undefined;
  searchChar: string;
  selectedTagIds: number[];
  selectedStoreroom: string;
  selectedStoreroomId: number | '';
  scrollPosition: number;
};

const MaterialList: FC<Props> = ({
  materials,
  searchChar,
  selectedTagIds,
  selectedStoreroom,
  selectedStoreroomId,
  scrollPosition,
}) => {
  const searchState = {
    stateSearchChar: searchChar,
    stateSelectedTagIds: selectedTagIds,
    stateSelectedStoreroom: selectedStoreroom,
    stateSelectedStoreroomId: selectedStoreroomId,
    stateScrollPosition: scrollPosition,
  };

  return (
    <>
      {materials?.map((material, i) => (
        <S.Material
          to={{
            pathname: `/mobile/materials/${material.id}/stocks/${format(
              new Date(),
              'yyyy-MM-dd'
            )}/${selectedStoreroomId}/edit`,
            state: searchState,
          }}
          index={i}
          key={i}
        >
          <S.MaterialInfo>
            <S.MaterialName>{material.name}</S.MaterialName>
            <S.MaterialCode>{material.materialCode}</S.MaterialCode>
          </S.MaterialInfo>
          <S.StockInfo>
            <S.StockDateIcon>
              {material.materialType === MATERIAL_TYPE.MATERIAL ? (
                <S.SemiMaterialIcon>資</S.SemiMaterialIcon>
              ) : (
                <S.MaterialIcon>原</S.MaterialIcon>
              )}
            </S.StockDateIcon>
            <S.StockDate>
              <S.UpdateDate>
                {material.stockLastInputAt && '更新日'}
              </S.UpdateDate>
              {material.stockLastInputAt
                ? moment(material.stockLastInputAt).format('YYYY/MM/DD')
                : ''}
            </S.StockDate>
          </S.StockInfo>
        </S.Material>
      ))}
    </>
  );
};
export default MaterialList;
