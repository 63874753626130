import { FC } from 'react';
import * as S from './StoreroomsForStocksModal.styles';
import ScrollToTop from '@components/modules/common/ScrollToTop/ScrollToTop';
import { useHistory } from 'react-router-dom';
import { ReactComponent as ChevronLeftIcon } from '@assets/icons/mobile/chevron_left.svg';
import StorageSearchForm from '@components/modules/mobile/storerooms_for_stocks_modal/StoreroomSearchForm/StoreroomSearchForm';
import StoreroomList from '@components/modules/mobile/storerooms_for_stocks_modal/StoreroomList/StoreroomList';
import type { Storeroom } from '@lib/common/type';
import { PickingState } from '@lib/mobile/picking_edit/type';
import { MaterialsSearchState } from '@lib/mobile/materials_for_stocks/type';

type Props = {
  searchStoreroom: string;
  setSearchStoreroom: (searchStoreroom: string) => void;
  storerooms: Storeroom[] | undefined;
  setStoreroomOpen: (storeroomOpen: boolean) => void;
  setSelectedStoreroomId: (selectedStorageId: number | '') => void;
  setSelectedStoreroomName: (selectedStoreName: string | '') => void;
  fromPicking?: string;
  pickingState?: PickingState;
  materialsSearchState?: MaterialsSearchState;
};

const StoreroomsForStocksModal: FC<Props> = ({
  searchStoreroom,
  setSearchStoreroom,
  storerooms,
  setStoreroomOpen,
  setSelectedStoreroomId,
  setSelectedStoreroomName,
  fromPicking,
  pickingState,
  materialsSearchState,
}) => {
  const history = useHistory();

  const handleClickBackButton = () => {
    setStoreroomOpen(false);
    if (fromPicking === 'picking') {
      history.push(`/mobile/picking`, pickingState);
    } else if (fromPicking === 'picking_by_shipping') {
      history.push(`/mobile/picking_by_shipping`, pickingState);
    } else if (fromPicking === 'materials_for_stocks') {
      // TODO：資材・原材料用のstate作成
      // history.push(`/mobile/materials/for_stocks`);
      history.push(`/mobile/materials/for_stocks`, materialsSearchState);
    } else {
      history.push(`/mobile/products/for_stocks`);
    }
  };

  return (
    <>
      <ScrollToTop />
      <S.Wrapper>
        <S.Header>
          <S.TitleWrapper>
            <S.IconWrapper>
              <S.BackButton
                onClick={() => {
                  // setSelectedStoreroomId('');
                  handleClickBackButton();
                }}
              >
                <ChevronLeftIcon />
              </S.BackButton>
            </S.IconWrapper>
            <S.TitleText>
              {fromPicking === 'picking' ||
              fromPicking === 'picking_by_shipping'
                ? '在庫室'
                : '場所'}
              の選択
            </S.TitleText>
          </S.TitleWrapper>
          <StorageSearchForm
            searchStoreroom={searchStoreroom}
            setSearchStoreroom={setSearchStoreroom}
            fromPicking={
              fromPicking === 'picking' || fromPicking === 'picking_by_shipping'
            }
          />
        </S.Header>
        <StoreroomList
          storerooms={storerooms}
          setStoreroomOpen={setStoreroomOpen}
          setSelectedStoreroomId={setSelectedStoreroomId}
          setSelectedStoreroomName={setSelectedStoreroomName}
          fromPicking={fromPicking}
          pickingState={pickingState}
          materialsSearchState={materialsSearchState}
        />
      </S.Wrapper>
    </>
  );
};

export default StoreroomsForStocksModal;
