import { useEffect, useState } from 'react';
import { DndProvider } from 'react-dnd';
import { useHistory, useLocation, useParams } from 'react-router-dom';
import { HTML5Backend } from 'react-dnd-html5-backend';
import * as S from './MaterialMasterCreateWrapper.styles';
import MaterialInfo from '@components/modules/pc/settings/material_master_setting/MaterialMasterEdit/MaterialInfo/MaterialInfo';
import ConfirmPopUp from '@components/modules/common/ConfirmPopUp/ConfirmPopUp';
import ErrorMsgPopUp from '@components/modules/common/ErrorMsgPopUp/ErrorMsgPopUp';
import Overlay from '@components/elements/backdrops/Overlay/Overlay';
import UpdatePopUp from '@components/modules/pc/settings/common/UpdatePopUp/UpdatePopUp';
import { defaultNewMaterialData } from './default';
import { ProductMasterSettingState } from '@lib/pc/settings/product_master_setting/type';
import useMaterials from '@lib/pc/settings/material_master_setting/useMaterials';
import useMaterialBasicInfo from '@lib/pc/settings/material_master_create/userMaterialBasicInfo';

export type InputParams = {
  time: string;
};

const MaterialMasterCreateWrapper = () => {
  const { timestamp } = useParams<{ timestamp: string }>();
  const { state } = useLocation<ProductMasterSettingState>();
  const history = useHistory();
  const [popUp, setPopUp] = useState(false);
  const [updatePopUp, setUpdatePopUp] = useState(false);
  const [updating, setUpdating] = useState(false);
  const [newMaterialData, setNewMaterialData] = useState(
    state?.editData ? state.editData : defaultNewMaterialData
  );
  const [selectedTabIndex, setSelectedTabIndex] = useState(0);
  const [isChanged, setIsChanged] = useState(false);
  const [confirmMsg, setConfirmMsg] = useState('');
  const [selectedTabId, setSelectedTabId] = useState(0);

  // 確認メッセージhandleOk判定用:一覧画面へ移動か、別タブ表示か。
  const [goBack, setGoBack] = useState(false);
  const [errMsg, setErrMsg] = useState('');

  // MaterialInfoで作成した新しいProduct
  const [materialFromInfo, setMaterialFromInfo] = useState(
    defaultNewMaterialData
  );
  const { materials: allMaterials } = useMaterials();
  const { materialBasicInfo: materialBasicInfo } = useMaterialBasicInfo();

  const MaterialSettingTabs = ['資材・原材料', '仕入先', '在庫室', 'タグ'];

  const handleUpdateSuccess = () => {
    setUpdating(false);
    setPopUp(!popUp);
    setIsChanged(false);
    setUpdatePopUp(!updatePopUp);
  };

  // 一覧画面へ戻る
  const onClickGoBack = () => {
    if (isChanged) {
      setGoBack(true);
      setConfirmMsg('変更した内容を保存せずに終了します。よろしいですか？');
    } else {
      history.push({
        pathname: `/pc/settings/material_master_setting`,
        state,
      });
    }
  };

  const handleClickTab = (i: number) => {
    setGoBack(false);
    if (materialFromInfo.id === 0) {
      setErrMsg('はじめに資材・原材料を登録してください。');
    } else if (isChanged) {
      setSelectedTabId(i);
      setConfirmMsg('変更した内容を保存せずに終了します。よろしいですか？');
    } else {
      setSelectedTabIndex(i);
      history.push({
        pathname: `/pc/settings/material_master_setting_edit/${materialFromInfo.id}`,
        state: {
          ...state,
          stateSelectedTabId: i,
          stateSelectedProductId: materialFromInfo.id,
        },
      });
    }
  };

  // 確認メッセージOK
  const handleOk = () => {
    // ここで一覧画面へ戻るか別タブを表示するかの判断が必要
    if (goBack) {
      history.push({
        pathname: `/pc/settings/material_master_setting`,
        state,
      });
    } else {
      setSelectedTabIndex(selectedTabId);
    }
    setIsChanged(false);
    setConfirmMsg('');
  };
  // 確認メッセージキャンセル
  const handleCancel = () => {
    setConfirmMsg('');
  };

  useEffect(() => {
    setNewMaterialData(newMaterialData);
  }, []);

  return (
    <div>
      <S.TabWrapper>
        {MaterialSettingTabs.map((d: string, i: number) => (
          <div key={d}>
            <S.TabArea>
              <S.Tab
                key={d}
                tabCount={MaterialSettingTabs.length}
                active={i == selectedTabIndex}
                onClick={() => {
                  handleClickTab(i);
                }}
              >
                {d}
              </S.Tab>
            </S.TabArea>
          </div>
        ))}
      </S.TabWrapper>

      <DndProvider backend={HTML5Backend}>
        {/* 製品情報 */}
        {selectedTabIndex === 0 && (
          <MaterialInfo
            material={materialFromInfo}
            updating={updating}
            setUpdating={setUpdating}
            setPopUp={setPopUp}
            newMaterialData={newMaterialData}
            setNewMaterialData={setNewMaterialData}
            handleUpdateSuccess={handleUpdateSuccess}
            onClickGoBack={onClickGoBack}
            isChanged={isChanged}
            setIsChanged={setIsChanged}
            allMaterials={allMaterials}
            materialFromInfo={materialFromInfo}
            setMaterialFromInfo={setMaterialFromInfo}
            timestamp={timestamp}
            materialTypes={materialBasicInfo?.materialTypes}
            orderTypes={materialBasicInfo?.orderTypes}
            labelOfAmounts={materialBasicInfo?.labelOfAmounts}
            orderUnits={materialBasicInfo?.orderUnits}
            labelOfUnits={materialBasicInfo?.labelOfUnits}
          />
        )}
      </DndProvider>
      <ConfirmPopUp
        fromPc={true}
        confirmMsg={confirmMsg}
        zIndex={1004}
        handleOk={handleOk}
        handleCancel={handleCancel}
      />
      <UpdatePopUp
        popUp={updatePopUp}
        taskKind={'messageOnly'}
        messageKind={'create'}
        handleClose={() => setUpdatePopUp(false)}
      />
      <ErrorMsgPopUp
        errMsg={errMsg}
        handleClose={() => setErrMsg('')}
        fromPc={true}
      />
      {updatePopUp && <Overlay handleClick={() => setUpdatePopUp(false)} />}
    </div>
  );
};

export default MaterialMasterCreateWrapper;
