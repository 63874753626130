import styled from 'styled-components';
import close_24px from '../../../../assets/icons/close_24px.svg';

export const Wrapper = styled.div<{
  open?: boolean;
  openFromTop?: boolean;
}>`
  width: 100vw;
  height: 100%;

  top: ${(props) => (props.openFromTop ? '0%' : props.open ? '8%' : '100%')};
  position: fixed;
  transition: all 0.5s ease-out;
  z-index: 10000;
  overflow-x: hidden;
`;

export const HeadContainer = styled.div`
  height: 86px;
  // padding: 1.15rem 2.25rem;
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

export const FlexBaseline = styled.div`
  display: flex;
  align-items: baseline;
`;

export const HeadingWrapper = styled.div`
  float: left;
  padding: 31px 40px;
`;

export const TitleWrapper = styled.div`
  position: relative;
  right: 0;
  left: 0;
  background-color: #ffffff;
  width: 100%;
  height: 86px;
`;

export const ButtonContainer = styled.div`
  padding: 22px 24px;
  float: right;
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  & > button {
    width: 136px;
  }

  & > button:first-child {
    margin-right: 1rem;
  }
`;

export const EmployeeContents = styled.div<{
  openFromTop?: boolean;
}>`
  background-color: #f2f2f2;
  height: ${(props) => (props.openFromTop ? '100%' : '85%')};
  padding: 40px 54px;
`;

export const EmployeeIndividual = styled.div`
  // width: 8.87rem;
  // margin-bottom: 4rem;
  padding-bottom: 1rem;
  display: flex;
`;

export const EmployeeIndividualLabel = styled.div<{
  width?: string;
}>`
  width: ${(props) => (props.width ? props.width : '4.87rem')};
  height: 64px;
  display: flex;
  justify-content: center;
  flex-direction: column;
  vertical-align: middle;
  font-size: 16px;
  font-weight: 400;
`;

export const EmployeeIndividualInput = styled.input`
  float: right;
  display: flex;
  width: 220px;
  height: 64px;
  border-radius: 8px;
  font-size: 16px;
  font-weight: 400;
  padding: 22px 12px;
  border: 1px solid rgba(0, 0, 0, 0.08);
  :focus {
    border: 1px solid transparent;
  }
  -webkit-appearance: none; /* デフォルトのサーチキャンセルボタンを非表示にする場合 */

  ::-webkit-search-cancel-button {
    height: 20px; /* キャンセルボタンのサイズ */
    width: 20px; /*  キャンセルボタンのサイズ */
    top: 10px;
    left: 10px;
    cursor: pointer; /*  キャンセルボタンにポインターを付ける  */
    -webkit-appearance: none; /* デフォルトのサーチキャンセルボタンを非表示にする場合 */
    // background-image: url(${close_24px});
    background-repeat: no-repeat;
    background-size: cover;
    display: inline-block;
    background-display: block;
  }
`;

export const SuggestArea = styled.div`
  display: none;
`;
