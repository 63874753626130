import { FC } from 'react';
import * as S from './SearchMenu.styles';
import RDateRangePicker from '@components/elements/inputs/RSingleDatepicker/RDateRangePicker';
import { ReactComponent as FilterListIcon } from '@assets/icons/mobile/filter_list.svg';
import { ReactComponent as CloseIcon } from '@assets/icons/close.svg';
import { Button } from '@components/elements/buttons/Button';
import type { DateType } from '@lib/common/type';

type Props = {
  headerTitleText: string;
  selectedDateTypeId: string;
  dateRange: {
    startDate: moment.Moment | null;
    endDate: moment.Moment | null;
  };
  dateTypes: DateType[];
  setSelectedDateTypeId: (selectedDateTypeId: string) => void;
  setSearchMenuActive: (searchMenuActive: boolean) => void;
  setDateRange: React.Dispatch<
    React.SetStateAction<{
      startDate: moment.Moment | null;
      endDate: moment.Moment | null;
    }>
  >;
};

const SearchMenu: FC<Props> = ({
  headerTitleText,
  selectedDateTypeId,
  dateRange,
  dateTypes,
  setSearchMenuActive,
  setDateRange,
  setSelectedDateTypeId,
}: Props) => {
  const handleDateChange = ({
    startDate,
    endDate,
  }: {
    startDate: moment.Moment | null;
    endDate: moment.Moment | null;
  }) => {
    if (startDate && !endDate) {
      setDateRange({ startDate, endDate: startDate });
    } else {
      setDateRange({ startDate, endDate });
    }
  };
  const handleRemoveDateAndId = () => {
    setDateRange({ startDate: null, endDate: null });
    setSelectedDateTypeId('0');
  };

  return (
    <S.Wrapper>
      <S.Header>
        <S.HeaderTitle>
          <FilterListIcon />
          <S.HeaderTitleText>{headerTitleText}</S.HeaderTitleText>
        </S.HeaderTitle>
        <S.CloseIconWrapper>
          <CloseIcon onClick={() => setSearchMenuActive(false)} />
        </S.CloseIconWrapper>
      </S.Header>
      <S.Main>
        <S.InputWrapper>
          <S.DateTypeSelect
            value={selectedDateTypeId}
            onChange={(e) => {
              setSelectedDateTypeId(e.target.value);
            }}
          >
            {selectedDateTypeId === '0'
              ? dateTypes.map((p: { id: number; name: string }) => (
                  <option key={p.id} value={p.id}>
                    {p.name}
                  </option>
                ))
              : dateTypes.slice(1).map((p: { id: number; name: string }) => (
                  <option key={p.id} value={p.id}>
                    {p.name}
                  </option>
                ))}
          </S.DateTypeSelect>
        </S.InputWrapper>
        <S.InputWrapper>
          <RDateRangePicker
            isSelect={selectedDateTypeId === '0'}
            dateRange={dateRange}
            handleDateChange={handleDateChange}
          />
        </S.InputWrapper>
        {selectedDateTypeId !== '0' && (
          <S.InputWrapper>
            <Button
              borderWidth={1}
              width="300px"
              outlined={true}
              onClick={handleRemoveDateAndId}
            >
              絞込み条件の削除
            </Button>
          </S.InputWrapper>
        )}
      </S.Main>
    </S.Wrapper>
  );
};
export default SearchMenu;
