import React from 'react';
import { useParams } from 'react-router-dom';
import * as S from './SupplierMasterEdit.styles';
import { WithHeader } from '@components/templates/WithHeader';
import { Heading } from '@components/elements/typographies/Heading';
import SupplierEditWrapper from '@components/modules/pc/settings/supplier_setting/SupplierEdit/SupplierEditWrapper/SupplierEditWrapper';
import useSupplier from '@lib/pc/settings/supplier_edit/useSupplier';

export const SupplierMasterEditRoot: React.FC = () => {
  const { id } = useParams<{ id: string }>();
  const { supplier, refetch } = useSupplier(id);

  return (
    <WithHeader>
      <S.Wrapper>
        <div>
          <S.HeadContainer>
            <S.FlexBaseline>
              <div>
                <Heading tag="span" text="仕入先の編集" fs="32" fw="lg" />
              </div>
            </S.FlexBaseline>
          </S.HeadContainer>
          <SupplierEditWrapper supplier={supplier} refetch={refetch} />
        </div>
      </S.Wrapper>
    </WithHeader>
  );
};
