import { axiosInstance } from '@lib/pc/common/api/axiosConfig';
import { useQuery } from 'react-query';
import humps from 'humps';
import { Todo } from '@lib/common/type';

const useSemiProducts = () => {
  return useQuery(['semi_products'], async () => {
    const data = await axiosInstance().get(`/api/v1/semi_products`);
    return humps.camelizeKeys(data.data) as Todo;
  });
};

export default useSemiProducts;
