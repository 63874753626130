import styled from 'styled-components';

export const Wrapper = styled.div`
  display: block;
  padding: 30px 40px;
  background: ${(props) => props.theme.palette.white};
  position: fixed;
  box-shadow: 0px 6px 10px rgba(0, 0, 0, 0.07), 0px 1px 18px rgba(0, 0, 0, 0.06),
    0px 3px 5px rgba(0, 0, 0, 0.1);
  border-radius: 8px;
  width: 82%;
  left: 9%;
  top: 35%;
  color: ${(props) => props.theme.palette.black};
  z-index: 9;
  max-height: 50%;
  line-height: 1.5;
  overflow-y: scroll;
`;

export const TitleWrapper = styled.div`
  display: flex;
`;

export const IconWrapper = styled.div`
  height: 40px;
  width: 40px;
`;

export const DateAndTotalAmount = styled.div`
  display: flex;
  justify-content: space-between;
  margin-top: 16px;
`;

export const Date = styled.div`
  color: ${(props) => props.theme.palette.blue};
  border-bottom: 1px solid ${(props) => props.theme.palette.blue};
  cursor: pointer;
  font-weight: 700;
  font-size: 24px;
  > span {
    font-size: 18px;
  }
`;

export const TotalAmount = styled.div`
  color: ${(props) => props.theme.palette.black};
  font-weight: 700;
  font-size: 24px;
  > span {
    font-size: 18px;
  }
`;
