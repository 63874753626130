import { axiosInstance } from '@lib/pc/common/api/axiosConfig';
import { useQuery } from 'react-query';
import humps from 'humps';
import Axios from 'axios';
import { Todo } from '@lib/common/type';

const useProducts = () => {
  return useQuery(['products'], async () => {
    try {
      const data = await axiosInstance().get(`/api/v1/products`);
      return humps.camelizeKeys(data.data) as Todo;
    } catch (err) {
      if (Axios.isAxiosError(err) && err.response) {
        // ✅ log status code here
        console.log('---ここからaxiosInstanceのエラー---');
        console.log(err.response.status);
        console.log(err.message);
        console.log(err.response.headers); // 👉️ {... response headers here}
        console.log(err.response.data); // 👉️ {... response data here}
      }
    }
  });
};

export default useProducts;
