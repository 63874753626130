import styled from 'styled-components';

export const Wrapper = styled.div`
  position: relative;
  width: 100vw;
  border-bottom: 1px solid ${(props) => props.theme.palette.sand};
  background: ${(props) => props.theme.palette.beige};
`;

export const Navigation = styled.nav`
  position: relative;
  padding: 1rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

export const FlexCenter = styled.div`
  display: flex;
  align-items: center;
`;

export const Menu = styled(FlexCenter)`
  color: ${(props) => props.theme.palette.baseBlack};
  padding: 0.75rem;
  cursor: pointer;
`;

export const Title = styled.a`
  font-size: 1.25rem /* 20px */;
  font-weight: 700;
`;

interface IProps {
  active?: boolean;
  logoutMenuActive?: boolean;
  logoutDialogActive?: boolean;
}

export const Backdrop = styled.a`
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  opacity: 0.25;
  background: #1f2937;
  display: ${(props: IProps) => (props.active ? '' : 'none')};
`;

export const Sidebar = styled.div`
  position: fixed;
  z-index: 9999;
  transition-property: all;
  transition-duration: 700ms;
  height: 100vh;
  width: 33%;
  max-width: 280px;
  transform: ${(props: IProps) => (props.active ? '' : 'translateX(-100%)')};
`;

export const SidebarNavigation = styled.nav`
  height: calc(100vh - 79px);
  overflow-y: auto;

  display: flex;
  flex-direction: column;

  background: ${(props) => props.theme.palette.beige};
  border-top: 1px solid ${(props) => props.theme.palette.sand};
  border-right-width: 1px;

  box-shadow: 0px 6px 10px rgba(0, 0, 0, 0.07), 0px 1px 18px rgba(0, 0, 0, 0.06),
    0px 3px 5px rgba(0, 0, 0, 0.1);
`;

export const SidebarList = styled.li`
  color: ${(props) => props.theme.palette.primary.main};
  /**
   * 一番上のSidebarListHeaderにはmarginをつけない
   */
  &:nth-child(1) {
    div:nth-child(1) {
      margin-top: 0;
    }
  }
`;

export const SidebarListLink = styled.div`
  cursor: pointer;
  color: ${(props) => props.theme.palette.baseBlack};
  padding: 0.75rem;
  display: flex;
  align-items: center;
  font-feature-settings: 'palt';
  .arrow-right {
    position: relative;
    top: 1px;
  }
`;

export const SidebarListHeader = styled.div`
  color: ${(props) => props.theme.palette.baseBlack};
  padding: 0.5rem 0 0.5rem 0.8rem;
  font-weight: 700;
  font-size: 12px;
  background-color: #e9e9e9;
  margin-top: 4px;
  display: flex;
`;

export const SidebarListTitle = styled.div`
  padding: 5px;
`;

export const LogoutMenu = styled.div`
  position: absolute;
  right: 0;
  z-index: 10001;
  display: ${(props: IProps) => (props.logoutMenuActive ? 'block' : 'none')};
`;

export const logoutDialog = styled.div`
  position: absolute;
  z-index: 10001;
  display: ${(props: IProps) => (props.logoutDialogActive ? 'block' : 'none')};
`;
