import { axiosInstance } from '@lib/pc/common/api/axiosConfig';
import { useQuery } from 'react-query';
import humps from 'humps';
import type { Material } from '@lib/common/type';

const useMaterials = (materialType: number) => {
  return useQuery(`common_materials_${materialType}`, async () => {
    const { data } = await axiosInstance().get(
      `/api/v1/materials?material_kind=${materialType}`
    );
    return humps.camelizeKeys(data.materials) as Material[];
  });
};

export default useMaterials;
