import { useEffect, useState, useLayoutEffect } from 'react';
import * as S from './PickingDetailInput.styles';
import { ReactComponent as AddIcon } from '@assets/icons/mobile/add.svg';
// import { ReactComponent as ArrowUpOutlined } from '@assets/icons/arrow_up_outlined.svg';
import { ReactComponent as DeleteIcon } from '@assets/icons/delete.svg';
// import { ReactComponent as ExpandMore } from '@assets/icons/expand_more.svg';
import DeleteModal from '@components/modules/mobile/picking_edit/DeleteConfirmModal/DeleteConfirmModal';
import CaseSelect from '@components/modules/common/CaseSelect/CaseSelect';
import { Case, Todo } from '@lib/common/type';
import {
  NotCheckedInfo,
  PickingDetails,
} from '@lib/mobile/picking_by_shipping/type';
import { calcTotal } from '@lib/mobile/picking_edit/functions';

type Props = {
  idx: number;
  destinationName: string;
  productName: string;
  detailByProductId: number;
  piecesPerBox: number;
  totalOfDetailsByProduct: number;
  shortageAmount: number;
  setShortageAmount: (shortageAmount: number) => void;
  pickingBreakdownDetail: PickingDetails[];
  handleUpdateIndexNewData: (
    pickingBreakdownDetail: PickingDetails[],
    detailByProductId: number,
    shortageAmount: number
  ) => void;
  cases: Case[] | undefined;
  notCheckedInfo: NotCheckedInfo[];
  setNotCheckedInfo: (notCheckedInfo: NotCheckedInfo[]) => void;
  refetch: () => void;
  storeroomName: string | undefined;
  detailActive: boolean;
  displayMessage: boolean;
  // setDetailActive: (detailActive: boolean) => void;
};

// ピッキング初期値
const initializeAmount = {
  bestBeforeDate: '',
  case: 0,
  piece: 0,
  amount: 0,
  caseId: 0,
  piecesPerBox: 1,
};

const PickingDetailInput = ({
  idx,
  destinationName,
  productName,
  detailByProductId,
  totalOfDetailsByProduct,
  setShortageAmount,
  pickingBreakdownDetail,
  handleUpdateIndexNewData,
  cases,
  notCheckedInfo,
  setNotCheckedInfo,
  refetch,
  storeroomName,
  detailActive,
  displayMessage,
}: // setDetailActive,
Props) => {
  const [newDetailData, setNewDetailData] = useState(pickingBreakdownDetail);
  const [shortage, setShortage] = useState(0);
  const [confirmModal, setConfirmModal] = useState(false);
  const [delIndex, setDelIndex] = useState(0);
  const [caseSelectIndex, setCaseSelectIndex] = useState(0);
  // ケース種類
  const [selectedCaseId, setSelectedCaseId] = useState(0);
  // 同じケース種類選択時にもケースidを設定
  const [changeSelectedCase, setChangeSelectedCase] = useState(false);

  const handleChange = () => {
    const filteredInfo = notCheckedInfo.filter(
      (n: NotCheckedInfo) =>
        n.destinationName === destinationName && n.productName === productName
    );
    if (filteredInfo.length === 0) {
      notCheckedInfo.push({
        productName: productName,
        destinationName: destinationName,
      });
    }
    setNotCheckedInfo(notCheckedInfo);
  };

  // ケース数の更新
  const handleChangeCase = (
    i: number,
    piecesPerBox: number | '',
    value: string | '',
    piece: string | ''
  ) => {
    handleChange();
    let amount: number;
    if (value.match(/^[0-9]*$/)) {
      amount = calcTotal(piecesPerBox, Number(value), Number(piece));
    }
    // ケースと合計値を更新する
    setNewDetailData((prevState: PickingDetails[]) =>
      prevState.map((obj: PickingDetails, index: number) =>
        index === i
          ? { ...obj, case: Number(value), amount: Number(amount) }
          : obj
      )
    );
  };

  // バラの更新
  const handleChangePiece = (
    i: number,
    piecesPerBox: number | '',
    caseNum: string | '',
    value: string | ''
  ) => {
    handleChange();
    let amount: number;
    if (value.match(/^[0-9]*$/)) {
      amount = calcTotal(piecesPerBox, Number(caseNum), Number(value));
    }
    // ケースと合計値を更新する
    setNewDetailData((prevState: PickingDetails[]) =>
      prevState.map((obj: PickingDetails, index: number) =>
        index === i
          ? { ...obj, piece: Number(value), amount: Number(amount) }
          : obj
      )
    );
  };

  // 賞味期限日選択時
  const handleChangeDate = (i: number, value: string) => {
    handleChange();
    setNewDetailData((prevState: PickingDetails[]) =>
      prevState.map((obj: PickingDetails, index: number) =>
        index === i ? { ...obj, bestBeforeDate: value } : obj
      )
    );
  };

  const handleDeleteButton = (index: number) => {
    const total = newDetailData
      ? newDetailData.reduce((sum, i) => sum + i.amount, 0)
      : 0;
    const calcShortage = totalOfDetailsByProduct + total;
    setShortage(calcShortage);
    setShortageAmount(calcShortage);

    handleChange();
    newDetailData.splice(index, 1);
  };

  // ピッキング入力追加ボタン押下時処理
  const AddPickingDetail = () => {
    handleChange();
    // PCからのピッキング商品追加時、newDetailDataがnullのため初期値設定
    if (newDetailData === null) {
      setNewDetailData([
        {
          bestBeforeDate: '',
          case: 0,
          piece: 0,
          amount: 0,
          caseId: 0,
          piecesPerBox: 1,
        },
      ]);
    } else {
      // 今までの配列に空データをpushする
      newDetailData.push(initializeAmount);
    }
    handleUpdateIndexNewData(newDetailData, detailByProductId, 0);
  };

  useEffect(() => {
    const total = newDetailData
      ? newDetailData.reduce((sum, i) => sum + i.amount, 0)
      : 0;
    const calcShortage = totalOfDetailsByProduct - total;
    if (shortage !== calcShortage) {
      setShortage(calcShortage);
      setShortageAmount(calcShortage);
    }
    handleUpdateIndexNewData(newDetailData, detailByProductId, calcShortage);
  }, [newDetailData]);

  // チェック済み以外表示時、このコンポネントに渡ってくるデータを設定し直す
  useLayoutEffect(() => {
    setNewDetailData(pickingBreakdownDetail);
  }, [pickingBreakdownDetail]);

  useEffect(() => {
    const selectedCase = cases?.find(
      (c) => c.caseId === selectedCaseId && c.productKind === 1
    );
    if (selectedCase) {
      setNewDetailData((prevState: PickingDetails[]) =>
        prevState.map((obj: PickingDetails, index: number) =>
          index === caseSelectIndex
            ? {
                ...obj,
                caseId: selectedCase?.caseId,
                piecesPerBox: selectedCase?.piecesPerBox,
              }
            : obj
        )
      );
    }
  }, [selectedCaseId, changeSelectedCase]);

  useEffect(() => {
    refetch();
    setNewDetailData(pickingBreakdownDetail);
  }, [pickingBreakdownDetail?.length]);

  return (
    <S.Wrapper>
      {/* <S.TitleText onClick={() => setDetailActive(!detailActive)}> */}
      <S.DetailWrapper open={detailActive}>
        <S.TitleText>
          <S.TextWhite displayMessage={displayMessage}>
            {storeroomName === undefined
              ? 'ピッキングした内訳'
              : 'ピッキングした内訳を入力'}
          </S.TextWhite>
          {/* {detailActive ? <ArrowUpOutlined /> : <ExpandMore />} */}
        </S.TitleText>
        {newDetailData &&
          newDetailData.map((p: Todo, index: number) => (
            <S.Detail key={index}>
              <S.DetailWhite lastDetail={newDetailData.length === index + 1}>
                <S.ExpirationDate>
                  <S.LabelWrapper>
                    <S.ProductionOrShippingDateLabel
                      htmlFor={`production-date-${idx}-${index}`}
                    >
                      賞味期限
                    </S.ProductionOrShippingDateLabel>
                    <S.Input
                      type="date"
                      name="productionDate"
                      id={`production-date-${idx}-${index}`}
                      value={p.bestBeforeDate ? p.bestBeforeDate : ''}
                      onChange={(event) =>
                        handleChangeDate?.(index, event.target.value)
                      }
                      pattern="\d{4}-\d{2}-\d{2}"
                      autoComplete="off"
                      max="9999-12-31"
                      disabled={storeroomName === undefined}
                    />
                    <S.DeleteButtonWrapper>
                      <S.DeleteButton
                        onClick={() => {
                          setDelIndex(index);
                          setConfirmModal(true);
                        }}
                      >
                        <DeleteIcon />
                      </S.DeleteButton>
                    </S.DeleteButtonWrapper>
                  </S.LabelWrapper>
                  {/* ケースの選択エリア */}
                  <S.CaseWrapper>
                    {cases && (
                      <CaseSelect
                        cases={cases}
                        value={newDetailData[index].caseId}
                        setSelectedCaseId={setSelectedCaseId}
                        marginLeft={'0'}
                        disabled={
                          newDetailData[index].amount > 0 ||
                          storeroomName === undefined
                        }
                        onChange={() => {
                          setChangeSelectedCase(!changeSelectedCase);
                          setCaseSelectIndex(index);
                        }}
                        controlFontWeight={'700'}
                        labelFontWeight={'400'}
                      />
                    )}
                  </S.CaseWrapper>
                </S.ExpirationDate>
                {/* ピッキング入力エリア */}
                <S.AmountWrapper>
                  <S.DInputContainer center={true}>
                    <label htmlFor={`new-stock-case-${idx}-${index}`}>
                      ケース
                    </label>
                    <input
                      id={`new-stock-case-${idx}-${index}`}
                      value={p.case ? p.case : ''}
                      onChange={(event) =>
                        handleChangeCase(
                          index,
                          newDetailData[index].piecesPerBox
                            ? newDetailData[index].piecesPerBox
                            : 1,
                          event.target.value,
                          p.piece
                        )
                      }
                      type="text"
                      pattern="^[1-9][0-9]*$"
                      inputMode="numeric"
                      autoComplete="off"
                      disabled={
                        newDetailData[index].caseId === 0 ||
                        storeroomName === undefined
                      }
                    />
                    <span>
                      ×
                      {newDetailData[index].piecesPerBox
                        ? newDetailData[index].piecesPerBox
                        : 1}
                      個
                    </span>
                  </S.DInputContainer>
                  <S.DSymbol>+</S.DSymbol>
                  <S.DInputContainer>
                    <label htmlFor={`new-stock-bara-${idx}-${index}`}>個</label>
                    <input
                      id={`new-stock-bara-${idx}-${index}`}
                      value={p.piece ? p.piece : ''}
                      onChange={(event) =>
                        handleChangePiece(
                          index,
                          newDetailData[index].piecesPerBox
                            ? newDetailData[index].piecesPerBox
                            : 1,
                          p.case,
                          event.target.value
                        )
                      }
                      type="text"
                      pattern="^[1-9][0-9]*$"
                      inputMode="numeric"
                      autoComplete="off"
                      disabled={
                        newDetailData[index].caseId === 0 ||
                        storeroomName === undefined
                      }
                    />
                  </S.DInputContainer>
                  <S.DSymbol>=</S.DSymbol>
                  <S.DInputContainer>
                    <label htmlFor={`new-stock-total-${idx}-${index}`}>
                      合計
                    </label>
                    <input
                      id={`new-stock-total-${idx}-${index}`}
                      value={p.amount ? p.amount : ''}
                      disabled
                    />
                  </S.DInputContainer>
                </S.AmountWrapper>
              </S.DetailWhite>
            </S.Detail>
          ))}
        {/* ＋ボタン */}
        {storeroomName !== undefined && (
          <S.AddIconWrapper onClick={() => AddPickingDetail()}>
            <AddIcon />
          </S.AddIconWrapper>
        )}
      </S.DetailWrapper>
      <DeleteModal
        confirmModal={confirmModal}
        setConfirmModal={setConfirmModal}
        delIndex={delIndex}
        handleDeleteButton={handleDeleteButton}
      />
      {/* <div className="gap" /> */}
    </S.Wrapper>
  );
};

export default PickingDetailInput;
