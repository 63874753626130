import styled from 'styled-components';

export const TabWrapper = styled.div`
  display: flex;
  width: 100vw;
  overflow-x: scroll;
`;

export const TabArea = styled.div`
  display: flex;
  background-color: #222e3e;
  cursor: pointer;
  min-width: 200px;
`;

export const Tab = styled.div<{
  active: boolean;
  tabCount: number;
  bgColor: string;
}>`
  background-color: ${(props) => (props.active ? props.bgColor : '#F8F7F4')};
  padding-top: 16px;
  width: calc(100vw / ${(props) => props.tabCount});
  height: 64px;
  line-height: 30px;
  text-align: center;
  color: ${(props) => (props.active ? '#FFFFFF' : 'rgba(51, 51, 51, 0.5)')};
  font-weight: ${(props) => (props.active ? '900' : '400')};
  font-family: 'Noto Sans JP';
  font-style: normal;
  font-size: 32px;
  line-height: 32px;
  align-items: center;
  text-align: center;
  min-width: 200px;
`;

export const Content = styled.div`
  width: 100vw;
`;

export const Title = styled.div`
  font-weight: 700;
  font-size: 2rem;
  line-height: 2rem;
  padding: 20px 40px;
`;

export const TabContentArea = styled.div`
  font-size: 30px;
  text-align: center;
`;

export const TabContent = styled.div<{
  open: boolean;
}>`
  color: black;
  display: ${(props) => (props.open ? 'block' : 'none')};
`;

export const Table = styled.table`
  width: 100%;
  border-collapse: collapse;
  border-spacing: 0 1em;
  font-size: 0.875rem;
`;

export const TRow = styled.tr`
  border: 1px solid #e6e1dd;
  height: 4.5rem;
`;

export const DraggableTRow = styled.tr<{
  bgColor: string;
}>`
  border: 1px solid #e6e1dd;
  height: 4.5rem;
  background-color: ${(props) => props.bgColor};
`;

export const THead = styled.tr<{
  bgColor: string;
}>`
  background-color: ${(props) => props.bgColor};
  border-top: 1px solid ${(props) => props.theme.palette.sandGray};
  border-bottom: 1px solid ${(props) => props.theme.palette.sandGray};
  height: 2.6rem;
`;

export const TCellOrder = styled.td<{
  marginRight?: string;
}>`
  cursor: grabbing;
  text-align: left;
  padding: 1rem 0rem 1rem 0.625rem;
  width: 3px;
  margin-right: ${(props) => (props.marginRight ? props.marginRight : '')};
  vertical-align: middle;
`;

export const THeadCell = styled.td<{
  align?: string;
  width?: string;
  wordBreak?: string;
}>`
  font-family: 'Noto Sans JP';
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  vertical-align: middle;
  word-break: ${(props) => (props.wordBreak ? props.wordBreak : '')};
  padding: 1rem 0;
  min-width: ${(props) => (props.width ? props.width : '7.25rem')};
  padding: ${(props) =>
    props.align === 'right' ? '1rem 0.5rem 1rem 0' : '1rem 0 1rem 0.625rem'};
  text-align: ${(props) => (props.align ? props.align : 'left')};
  word-spacing: 100vw;
  > svg {
    display: inline;
    width: 18px;
    height: 18px;
  }
  :first-child {
    // padding-left: 2rem;
  }
  :last-child {
    padding-right: 2rem;
  }
  > span {
    font-size: 10px;
    margin-left: 4px;
  }
`;

export const TCell = styled.td<{
  align?: 'left' | 'right' | 'center';
  width?: string;
  marginRight?: string;
  background?: string;
  wordBreak?: string;
  fontSize?: string;
}>`
  font-size: ${(props) => (props.fontSize ? props.fontSize : '16px')};
  vertical-align: middle;
  word-break: ${(props) => (props.wordBreak ? props.wordBreak : '')};
  padding: 1rem 0;
  min-width: ${(props) => (props.width ? props.width : '7.25rem')};
  padding: ${(props) =>
    props.align === 'right' ? '1rem 0.5rem 1rem 0' : '1rem 0 1rem 0.625rem'};
  text-align: ${(props) => (props.align ? props.align : 'left')};
  margin-right: ${(props) => (props.marginRight ? props.marginRight : '')};
  background: ${(props) => (props.background ? props.background : '')};
  > svg {
    display: inline;
    width: 18px;
    height: 18px;
  }

  > span {
    font-size: 10px;
    margin-left: 4px;
  }
`;

export const ArrowDropDown = styled.td`
  vertical-align: middle;
`;

export const PencilIcon = styled.td`
  cursor: pointer;
  text-align: center;
  vertical-align: middle;
  padding-left: 14px;
`;

export const FlexTableDivRow = styled.div`
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #2ba9d1;
  font-weight: bold;
  height: 4.5rem;
  border-bottom: 1px solid #e6e1dd;
  gap: 2rem;
  svg path {
    fill: #2ba9d1;
  }
`;

export const CircularIconWrapperOuter = styled.div`
  width: 100vw;
  height: 100vh;
  position: relative;
`;

export const CircularIconWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  top: 40%;
  left: 48%;
`;
