import { useEffect, useState } from 'react';
import * as S from './PickingDetailByShipping.styles';
import {
  DetailsByProduct,
  NotCheckedInfo,
  PickingDetails,
} from '@lib/mobile/picking_by_shipping/type';
import { ReactComponent as CheckedIcon } from '@assets/icons/mobile/check.svg';
import { ReactComponent as CheckIcon } from '@assets/icons/mobile/check_circle_outline_24px.svg';
import PickingDetailInput from '@components/modules/mobile/picking_by_shipping_edit/PickingDetailInput/PickingDetailInput';
import PickingDetailWithChecked from '@components/modules/mobile/picking_by_shipping_edit/PickingDetailWithChecked/PickingDetailWithChecked';
import { Case, Todo } from '@lib/common/type';
import { filterPikingData } from '@lib/mobile/picking_by_shipping_edit/functions';

type Props = {
  open: boolean;
  idx: number;
  countTrue: number;
  dataTotalAmount: number;
  destinationName: string;
  detailsByProduct: DetailsByProduct[];
  searchProductChar: string;
  completedCheckState: boolean;
  // setPickingStatus: (pickingStatus: string) => void;
  fromScannerResult?: boolean;
  fixedAreaHeight: string;
  greenFixedAreaHeight: string;
  fixedAreaShippingTitleHeight: string;
  cases: Case[] | undefined;
  setUpdatePickingDetailId: (updatePickingDetailId: number) => void;
  handleUpdate: (
    checkState: boolean,
    amountDetail: DetailsByProduct[],
    allocateTrueStatus: boolean
  ) => void;
  notCheckedInfo: NotCheckedInfo[];
  setNotCheckedInfo: (notCheckedProducts: NotCheckedInfo[]) => void;
  refetch: () => void;
  setErrMsg: (errMsg: string) => void;
  storeroomName: string;
  storeroomId: number;
  updating: boolean;
  setUpdating: (updating: boolean) => void;
  allocateTrueStatus: boolean;
};

const PickingDetailByShipping = ({
  open,
  idx,
  countTrue,
  dataTotalAmount,
  destinationName,
  detailsByProduct,
  searchProductChar,
  completedCheckState,
  fromScannerResult,
  fixedAreaHeight,
  greenFixedAreaHeight,
  cases,
  setUpdatePickingDetailId,
  handleUpdate,
  notCheckedInfo,
  setNotCheckedInfo,
  refetch,
  setErrMsg,
  storeroomName,
  storeroomId,
  updating,
  setUpdating,
  allocateTrueStatus,
}: Props) => {
  // 更新する全体のデータ
  const [newData, setNewData] = useState<DetailsByProduct[]>(detailsByProduct);
  const [shortageAmount, setShortageAmount] = useState(0);
  const [detailOpenArray] = useState<boolean[]>([]);

  // 下位のコンポーネントに渡しているのがネストの深いオブジェクトなので、強制再レンダリングする必要がある
  const [, setToggleValue] = useState(false);
  const reRender = () => setToggleValue((prev) => !prev);

  // amountDetailチェック
  const validateAmountDetails = (amountDetails: Todo[]): string[] => {
    const errors: string[] = [];
    const bestBeforeDateCounts: { [key: string]: number } = {};

    for (const detail of amountDetails) {
      const { amount, bestBeforeDate } = detail;
      // amountが0の場合、エラーを追加
      if (amount === 0) {
        errors.push('ピッキング数を入力してください。');
        // setErrMsg('ピッキング数を入力してください。');
      }
      // 振分ステータスの場合、賞味期限日の未入力チェック
      if (!bestBeforeDate && allocateTrueStatus) {
        errors.push('賞味期限日を入力してください。');
      }
      // bestBeforeDateの出現回数をカウント
      bestBeforeDateCounts[bestBeforeDate] =
        (bestBeforeDateCounts[bestBeforeDate] || 0) + 1;
    }
    // bestBeforeDateが重複している場合、エラーを追加
    for (const [bestBeforeDate, count] of Object.entries(
      bestBeforeDateCounts
    )) {
      if (count > 1) {
        errors.push(`同じ賞味期限日${bestBeforeDate}が入力されています。`);
      }
    }
    return errors;
  };

  const handleOnClickCheck = async (p: DetailsByProduct) => {
    const errors: string[] = [];

    if (storeroomName === undefined) {
      errors.push('在庫室を選択してください。');
      // setErrMsg('在庫室を選択してください。');
    } else if (p.amountDetail === null) {
      errors.push('ピッキングした内訳を入力してください。');
      // setErrMsg('ピッキングした内訳を入力してください。');
    } else if (allocateTrueStatus) {
      // ピッキング入力チェック
      errors.push(...validateAmountDetails(p.amountDetail));
    }

    if (errors.length === 0) {
      await setUpdatePickingDetailId(p.pickingDetailId);
      allocateTrueStatus
        ? handleCheckState(
            !p.allocationCheckState,
            p.productId,
            p.pickingDetailId
          )
        : handleCheckState(!p.checkState, p.productId, p.pickingDetailId);
      refetch();
    } else {
      errors.forEach(setErrMsg);
    }
  };

  const handleClickOpen = (
    detailOpen: boolean,
    productId: number,
    index: number
  ) => {
    setNewData((prevState: DetailsByProduct[]) =>
      prevState.map((obj: DetailsByProduct) =>
        obj.productId === productId
          ? { ...obj, detailOpen: detailOpen === true ? false : true }
          : obj
      )
    );
    detailOpenArray[index] = detailOpenArray[index] ? false : true;
  };

  const handleCheckState = (
    checkState: boolean,
    productId: number,
    pickingDetailId: number
  ) => {
    refetch();
    // 更新前に出荷停止の確認
    const filteredDetailsByProduct = detailsByProduct.filter(
      (n) => n.pickingDetailId === pickingDetailId
    );
    if (filteredDetailsByProduct[0]?.stopShipping) {
      setErrMsg('この出荷先商品は出荷止です。');
    } else {
      setUpdating(true);
      allocateTrueStatus === true
        ? setNewData((prevState: DetailsByProduct[]) =>
            prevState.map((obj: DetailsByProduct) =>
              obj.productId === productId
                ? { ...obj, allocationCheckState: checkState }
                : obj
            )
          )
        : setNewData((prevState: DetailsByProduct[]) =>
            prevState.map((obj: DetailsByProduct) =>
              obj.productId === productId
                ? { ...obj, checkState: checkState }
                : obj
            )
          );
      // バックエンド更新-----------------------------------------------------
      const filteredNewData = newData.filter(
        (n) => n.pickingDetailId === pickingDetailId
      );
      handleUpdate(checkState, filteredNewData, allocateTrueStatus);
    }
  };

  // ピッキング入力フォーム追加時
  // 全体のnewDataを更新する処理
  const handleUpdateIndexNewData = (
    newDetailData: PickingDetails[],
    detailByProductId: number,
    shortageAmount: number
  ) => {
    setNewData((prevState: DetailsByProduct[]) =>
      prevState.map((obj: DetailsByProduct) =>
        obj.productId === detailByProductId
          ? {
              ...obj,
              amountDetail: newDetailData,
              shortageAmount: shortageAmount,
            }
          : obj
      )
    );
  };

  // PCからのピッキング商品追加情報を反映するためnewDataを設定
  // amountDetailをrefetchするためのuseEffect;
  useEffect(() => {
    setNewData(detailsByProduct);
    reRender();
  }, [detailsByProduct.length, dataTotalAmount, countTrue]);

  const filteredData = filterPikingData(
    newData,
    searchProductChar,
    completedCheckState,
    allocateTrueStatus,
    storeroomId
  );

  return (
    <S.Wrapper
      className="picking-detail-by-shipping-wrapper"
      open={open}
      fixedAreaHeight={fixedAreaHeight}
    >
      {newData &&
        filteredData.map((p: DetailsByProduct, index: number) => (
          <S.ProductFormSection className="section" key={index}>
            <S.StickyContainer className="sticky-container">
              {/* 商品名、商品コード、チェック済or未チェック */}
              <S.StickyProductTitle
                className="product-title"
                checked={
                  allocateTrueStatus ? p.allocationCheckState : p.checkState
                }
                fromScannerResult={fromScannerResult}
                greenFixedAreaHeight={greenFixedAreaHeight}
                fixedAreaHeight={fixedAreaHeight}
                deliveryResults={
                  allocateTrueStatus
                    ? p.allocationCheckState && p.deliveryResults === 'short'
                    : p.checkState && p.deliveryResults === 'short'
                }
              >
                <S.ClickExpandArea
                  className="click-expand-area"
                  onClick={() => {
                    handleClickOpen(p.detailOpen, p.productId, index);
                  }}
                  shortOrOver={
                    p.deliveryResults === 'short' ||
                    p.deliveryResults === 'over'
                  }
                >
                  {p.productImage ? (
                    <S.ProductImg src={p.productImage} loading="lazy" />
                  ) : (
                    <S.ProductImageContainer>
                      <div
                        style={{
                          width: '45px',
                          height: '45px',
                          background: '#ccc',
                        }}
                      />
                    </S.ProductImageContainer>
                  )}
                  <S.Name>
                    <p>{p.name}</p>
                    {p.code}
                  </S.Name>
                  <S.TotalAmount>
                    <p>合計</p>
                    {p.total}
                    <span>個</span>
                  </S.TotalAmount>
                </S.ClickExpandArea>

                {/* 商品別：チェック済みor未チェック */}
                <S.CheckArea
                  onClick={() => !updating && handleOnClickCheck(p)}
                  checked={
                    allocateTrueStatus ? p.allocationCheckState : p.checkState
                  }
                >
                  <S.CheckIconWrapper>
                    {allocateTrueStatus ? (
                      p.allocationCheckState ? (
                        <CheckedIcon />
                      ) : (
                        <CheckIcon />
                      )
                    ) : p.checkState ? (
                      <CheckedIcon />
                    ) : (
                      <CheckIcon />
                    )}
                  </S.CheckIconWrapper>
                  <S.StatusName
                    checked={
                      allocateTrueStatus ? p.allocationCheckState : p.checkState
                    }
                  >
                    {updating ? (
                      <span>保存中</span>
                    ) : allocateTrueStatus ? (
                      p.allocationCheckState ? (
                        <span>振分済み</span>
                      ) : (
                        <span>振分</span>
                      )
                    ) : p.checkState ? (
                      <span>チェック済</span>
                    ) : (
                      <span>チェック</span>
                    )}
                  </S.StatusName>
                </S.CheckArea>
              </S.StickyProductTitle>
              {p.shortageAmount < 0 && (
                <S.MessageText>
                  <S.MessageTextWhite>
                    【要確認】{-p.shortageAmount}個多いです
                  </S.MessageTextWhite>
                </S.MessageText>
              )}
              {p.shortageAmount > 0 && (
                <S.MessageText>
                  <S.MessageTextWhite>
                    【要確認】あと{p.shortageAmount}
                    個、{allocateTrueStatus ? '振分' : 'ピッキング'}
                    してください
                  </S.MessageTextWhite>
                </S.MessageText>
              )}
              {/* ピッキングした内訳 */}
              {(!allocateTrueStatus && p.checkState) ||
              (allocateTrueStatus && p.allocationCheckState) ? (
                <PickingDetailWithChecked
                  idx={idx}
                  pickingBreakdownDetail={p.amountDetail}
                  cases={cases?.filter(
                    (c) => c.productId === p.productId && c.productKind === 1
                  )}
                  detailActive={detailOpenArray[index]}
                />
              ) : (
                <PickingDetailInput
                  idx={idx}
                  destinationName={destinationName}
                  productName={p.name}
                  detailByProductId={p.productId}
                  piecesPerBox={p.piecesPerBox}
                  totalOfDetailsByProduct={p.total}
                  shortageAmount={shortageAmount}
                  setShortageAmount={setShortageAmount}
                  pickingBreakdownDetail={p.amountDetail}
                  handleUpdateIndexNewData={handleUpdateIndexNewData}
                  cases={cases?.filter(
                    (c) => c.productId === p.productId && c.productKind === 1
                  )}
                  notCheckedInfo={notCheckedInfo}
                  setNotCheckedInfo={setNotCheckedInfo}
                  refetch={refetch}
                  storeroomName={storeroomName}
                  detailActive={detailOpenArray[index]}
                  displayMessage={p.shortageAmount === 0 ? false : true}
                />
              )}
            </S.StickyContainer>
          </S.ProductFormSection>
        ))}
    </S.Wrapper>
  );
};

export default PickingDetailByShipping;
