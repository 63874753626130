import { useMutation } from 'react-query';
import humps from 'humps';
import { axiosInstance } from '@lib/pc/common/api/axiosConfig';
import { Todo } from '@lib/common/type';

const useUpdateMaterialDeliveriesMutation = (
  detailId: number,
  handleUpdateSuccess: () => void,
  setErrMsg: (errMsg: string) => void
) => {
  return useMutation(
    async (params: Todo) => {
      return await axiosInstance().patch(
        `/api/v1/material_deliveries/${detailId}`,
        humps.decamelizeKeys({
          material_delivery: {
            material_id: params.materialDelivery.materialId,
            material_order_detail_id:
              params.materialDelivery.materialOrderDetailId,
            amount: params.materialDelivery.amount,
          },
        })
      );
    },
    {
      onSuccess: () => {
        handleUpdateSuccess?.();
      },
      onError: (error: Todo) => {
        setErrMsg(error.response.data.message || 'エラーが発生しました');
      },
    }
  );
};

export default useUpdateMaterialDeliveriesMutation;
