import React, { FC } from 'react';
import * as S from './Overlay.styles';

type Props = {
  handleClick?: () => void;
  dark?: boolean;
  zIndex?: number;
};

const Overlay: FC<Props> = ({ handleClick, dark, zIndex }: Props) => {
  return <S.Overlay onClick={handleClick} dark={dark} zIndex={zIndex} />;
};

export default Overlay;
