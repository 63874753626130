import { axiosInstance } from '@lib/pc/common/api/axiosConfig';
import { useMutation } from 'react-query';

export const useDeleteMaterialOrdersMutation = (
  orderId: number | null,
  handleDeleteSuccess: () => void
) => {
  return useMutation(
    async () => {
      await axiosInstance().delete(`/api/v1/material_orders/${orderId}`);
    },
    {
      onSuccess: () => {
        handleDeleteSuccess();
      },
      onError: () => alert('エラーが発生しました'),
    }
  );
};

export default useDeleteMaterialOrdersMutation;
