import { FC, useState } from 'react';
import moment from 'moment';
import * as S from './AllStoreroomsRightSlideSidebar.styles';
import { Button } from '@components/elements/buttons/Button';
import ErrorMsgPopUpForStock from '@components/modules/pc/stock_status/ErrorMsgPopUpForStock/ErrorMsgPopUpForStock';
import ScrollToTop from '@components/modules/common/ScrollToTop/ScrollToTop';
import AllStoreroomStockForm from '@components/modules/pc/stock_status/AllStoreroomStockForm/AllStoreroomStockForm';
import { selectedItem } from '@lib/pc/stock_status/type';
import { useAllStoreroomsStock } from '@lib/pc/stock_status/hooks';

type Props = {
  rightSlideSidebarActive: boolean;
  selectedProduct: selectedItem;
  handleOkAllStoreRooms: () => void;
};

const AllStoreroomsRightSlideSidebar: FC<Props> = ({
  rightSlideSidebarActive,
  selectedProduct,
  handleOkAllStoreRooms,
}: Props) => {
  const day =
    selectedProduct && selectedProduct.date !== ''
      ? moment(selectedProduct.date)
      : moment();

  const formattedDate = moment(day).format('YYYY/MM/DD (ddd)');

  const [errMsg, setErrMsg] = useState('');

  const { data: stocks } = useAllStoreroomsStock(
    moment(selectedProduct.date).toDate(),
    selectedProduct.productId,
    selectedProduct.productKind
  );

  if (!selectedProduct) return null;

  return (
    <>
      <S.Wrapper open={rightSlideSidebarActive}>
        <ScrollToTop />
        <S.ScrollableContainer>
          <S.HeadContainer>
            <S.HeadDate>{formattedDate}</S.HeadDate>
            <S.HeadProductName>
              <S.HeadProductType productKind={selectedProduct?.type}>
                {selectedProduct?.type}
              </S.HeadProductType>
              {selectedProduct?.productName}
            </S.HeadProductName>
          </S.HeadContainer>

          <AllStoreroomStockForm data={stocks} product={selectedProduct} />
          <S.ButtonContainer>
            <Button
              borderWidth={1}
              outlined={true}
              onClick={() => {
                handleOkAllStoreRooms();
              }}
            >
              OK
            </Button>
          </S.ButtonContainer>
        </S.ScrollableContainer>
        <ErrorMsgPopUpForStock
          errMsg={errMsg}
          handleClose={() => setErrMsg('')}
          fromPc={true}
        />
      </S.Wrapper>
      {/* {rightSlideSidebarActive && (
        <Overlay handleClick={() => setRightSlideSidebarActive(false)} />
      )} */}
    </>
  );
};

export default AllStoreroomsRightSlideSidebar;
