import LABEL_OF_AMOUNT_MAP from '@components/modules/common/LabelOfAmountMap/LabelOfAmountMap';
import * as S from './stockAllocationDetailForm.styles';
import type { Todo } from '@lib/common/type';

type Props = {
  index: number;
  fromStoreroomName: string;
  toStoreroomName: string;
  allocationType: string;
  piece: string | number;
  total: string | number;
  caseValue: string | number;
  piecesPerBox: string;
  bestBeforeDate: Todo;
  comment: string;
  labelOfAmount: string;
};

const stockAllocationDetailForm = ({
  index,
  fromStoreroomName,
  toStoreroomName,
  allocationType,
  piece,
  total,
  caseValue,
  piecesPerBox,
  bestBeforeDate,
  comment,
  labelOfAmount,
}: Props) => {
  return (
    <S.DWrapper>
      <S.FormWrapper>
        <div>払出元</div>
        <div>{fromStoreroomName}</div>
      </S.FormWrapper>
      <S.FormWrapper>
        <div>払出先</div>
        <div>
          {allocationType === 'transfer'
            ? toStoreroomName
            : allocationType === 'sales'
            ? '店舗販売'
            : allocationType === 'discard'
            ? '廃棄'
            : allocationType === 'consumption'
            ? '消費'
            : ''}
        </div>
      </S.FormWrapper>
      <S.FormWrapper>
        <div>賞味期限</div>
        <div>{bestBeforeDate}</div>
      </S.FormWrapper>
      <S.DRightBottomWrapper>
        <S.DInputContainer center={true}>
          <label htmlFor={`stock-allocation-case-${index}`}>ケース</label>
          <input
            id={`stock-allocation-case-${index}`}
            value={caseValue}
            type="text"
            disabled
          />
          <span>
            ×{piecesPerBox}
            {labelOfAmount ? LABEL_OF_AMOUNT_MAP[labelOfAmount] : '個'}
          </span>
        </S.DInputContainer>
        <S.DSymbol>+</S.DSymbol>
        <S.DInputContainer>
          <label htmlFor={`stock-allocation-piece-${index}`}>
            {labelOfAmount ? LABEL_OF_AMOUNT_MAP[labelOfAmount] : '個'}
          </label>
          <input
            id={`stock-allocation-piece-${index}`}
            value={piece}
            type="text"
            disabled
          />
        </S.DInputContainer>
        <S.DSymbol>=</S.DSymbol>
        <S.DInputContainer>
          <label htmlFor="stock-allocation-total">合計</label>
          <input
            id="stock-allocation-total"
            value={Number(total).toLocaleString()}
            disabled
          />
        </S.DInputContainer>
      </S.DRightBottomWrapper>
      <S.NoteAmountContainer>
        <S.NoteAmountLabel>備考</S.NoteAmountLabel>
        <S.NoteAmountInput placeholder="" value={comment} disabled />
      </S.NoteAmountContainer>
    </S.DWrapper>
  );
};
export default stockAllocationDetailForm;
