import { useParams } from 'react-router-dom';
import Form from '@components/modules/pc/purchase_orders_edit/Form/Form';
import { WithHeader } from '@components/templates/WithHeader';
import useMaterialOrder from '@lib/pc/purchase_orders_edit/useMaterialOrder';

export const PurchaseOrdersEditRoot = () => {
  const { id } = useParams<{ id: string }>();
  const { materialOrder: materialOrder, refetch } = useMaterialOrder(id);

  return (
    <WithHeader>
      <Form materialOrder={materialOrder} refetch={refetch} />
    </WithHeader>
  );
};
