import { FC, useState } from 'react';
import { useHistory } from 'react-router';
// import { PCPath } from '@config/path';
import Filter from '@assets/icons/filter_list';
// import { pickingStatus } from '@lib/pc/picking/constants';
import { STATUS_VALUE } from '@lib/pc/picking/type';
import * as S from './TableWithFilter.styles';
import PickingStatusMenu from '@components/modules/pc/picking/PickingStatusMenu/PickingStatusMenu';
import SearchMenu from '@components/modules/pc/picking/SearchMenu/SearchMenu';
import Overlay from '@components/elements/backdrops/Overlay/Overlay';
import { TABLE_DATA_DUMMY, TABLE_HEADER } from './dummy';
import { Todo } from '@lib/common/type';

export type AlignType = 'left' | 'center' | 'right';

type Props = {
  setDatePickerInactive: (datePickerInactive: boolean) => void;
};

const TableWithFilter: FC<Props> = ({ setDatePickerInactive }: Props) => {
  const history = useHistory();
  const ALL_STATUS = [0, 1, 2];
  // const ALL_STATUS = ['waiting_picking', 'picking', 'waiting_shipping'];

  const [statusActive, setStatusActive] = useState(false);
  // 絞り込みステータス
  const [selectedStatus, setSelectedStatus] = useState<number[]>([]);
  const [statePickingStatus, setStatePickingStatus] = useState<number[]>([]);
  // 出荷先グループ名検索modal表示
  const [shippingGroupSearchModalActive, setShippingGroupSearchModalActive] =
    useState(false);
  // 出荷先グループ名検索文字列
  const [shippingGroupSearchWord, setShippingGroupSearchWord] = useState('');
  // 出荷時間検索modal表示
  const [shippingTimeSearchModalActive, setShippingTimeSearchModalActive] =
    useState(false);

  const onClickState = (key: string) => {
    if (key === 'ステータス') {
      setStatusActive(!statusActive);
      setShippingGroupSearchModalActive(false);
      setShippingTimeSearchModalActive(false);
    } else if (key === '出荷先グループ名') {
      setStatusActive(false);
      setShippingGroupSearchModalActive(!shippingGroupSearchModalActive);
      setShippingTimeSearchModalActive(false);
    } else if (key === '出荷時間') {
      setStatusActive(false);
      setShippingGroupSearchModalActive(false);
      setShippingTimeSearchModalActive(!shippingTimeSearchModalActive);
    }
  };

  // 検索出荷先グループ名
  const handleSetShippingGroupSearch = (e: Todo) => {
    setShippingGroupSearchWord(e.target.value);
  };

  return (
    <>
      <S.Table>
        <thead>
          <S.THead>
            {TABLE_HEADER.map((t) => (
              <S.TCell
                className="header"
                align={t.align}
                key={t.key}
                width={t.width}
                onClick={() => onClickState(t.key)}
                status={
                  (statePickingStatus?.length > 0 && t.key === 'ステータス') ||
                  (shippingGroupSearchWord?.length > 0 &&
                    t.key === '出荷先グループ名')
                }
                isCursor={
                  t.key === 'ステータス' || t.key === '出荷先グループ名'
                }
              >
                {t.key}
                {t.filter && (
                  <Filter
                    isBlue={
                      statePickingStatus?.length > 0 && t.key === 'ステータス'
                    }
                  />
                )}
                {t.key === '出荷先グループ名' && (
                  <Filter
                    isBlue={
                      shippingGroupSearchWord?.length > 0 &&
                      t.key === '出荷先グループ名'
                    }
                  />
                )}
              </S.TCell>
            ))}
          </S.THead>
        </thead>
      </S.Table>
      <S.TableContents>
        <S.Table paddingBottom={'50px'}>
          <tbody>
            {TABLE_DATA_DUMMY.filter((p: Todo) =>
              statePickingStatus.length > 0
                ? statePickingStatus.includes(p.status)
                : ALL_STATUS.includes(p.status)
            )
              .filter((p: Todo) =>
                p.shippingGroup.includes(shippingGroupSearchWord)
              )
              .map((t, i) => {
                return (
                  <S.TRow
                    bgColor={STATUS_VALUE[t.status].backgroundColor}
                    key={i}
                    onClick={() => {
                      history.push(`/pc/picking_detail/${t.id}`);
                      // history.push(
                      //   `/pc${PCPath.picking}/picking_detail/${t.id}`
                      // );
                      setDatePickerInactive(true);
                    }}
                  >
                    <S.TCell className="shippingDate">{t.date}</S.TCell>
                    <S.TCell className="client">{t.shippingGroup}</S.TCell>
                    <S.TCell className="items" align="right">
                      {t.items}
                      <span>品目</span>
                    </S.TCell>
                    <S.TCell className="sum" align="right">
                      {t.sum}
                      <span>個</span>
                    </S.TCell>
                    <S.TCell className="status">
                      <S.StatusBar
                        color={STATUS_VALUE[t.status].fontColor}
                        bgColor={STATUS_VALUE[t.status].borderColor}
                        borderColor={STATUS_VALUE[t.status].borderColor}
                      >
                        {STATUS_VALUE[t.status].key}
                      </S.StatusBar>
                    </S.TCell>
                  </S.TRow>
                );
              })}
          </tbody>
        </S.Table>
      </S.TableContents>
      {statusActive && (
        <PickingStatusMenu
          selectedStatus={selectedStatus}
          setSelectedStatus={setSelectedStatus}
          statePickingStatus={statePickingStatus}
          setStatePickingStatus={setStatePickingStatus}
          useAllocation={false} // とりあえずpropsのみ渡す
        />
      )}
      {shippingGroupSearchModalActive && (
        <SearchMenu
          title={'出荷先グループ名'}
          searchParam={shippingGroupSearchWord}
          handleClose={() => setShippingGroupSearchModalActive(false)}
          handleChange={handleSetShippingGroupSearch}
          left={'18%'}
          deleteSearchParam={setShippingGroupSearchWord}
        />
      )}
      {statusActive ||
        shippingGroupSearchModalActive ||
        (shippingTimeSearchModalActive && (
          <Overlay
            handleClick={() => {
              setStatusActive(false);
              setShippingGroupSearchModalActive(false);
              setShippingTimeSearchModalActive(false);
            }}
          />
        ))}
    </>
  );
};

export default TableWithFilter;
