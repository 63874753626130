import { useEffect, useState } from 'react';
import { Todo } from '@lib/common/type';
import * as S from './ProductionScheduleEditable.styles';
import { addComma, isSameIndex } from '@lib/pc/production_schedule/functions';
import { containsNonNumeric } from '@lib/common/functions';
import { useSWRConfig } from 'swr';

type Props = {
  p: Todo;
  currentRowIndex: number;
  setCurrentRowIndex: (currentRowIndex: number) => void;
  currentCellIndex: number;
  setCurrentCellIndex: (setCurrentCellIndex: number) => void;
  selectIndex: number;
  inputParams: Todo;
  isChanged: boolean;
  setIsChanged: (isChanged: boolean) => void;
  handleUpdate: (inputParams: Todo) => void;
  setErrMsg: (errMsg: string) => void;
  updateCount: number;
  setUpdateCount: (updateCount: number) => void;
  rowIndex: number;
  cellIndex: number;
  productLength: number;
  totalTitle: string;
};

const ProductionScheduleEditable = ({
  p,
  currentRowIndex,
  setCurrentRowIndex,
  currentCellIndex,
  setCurrentCellIndex,
  selectIndex,
  inputParams,
  isChanged,
  setIsChanged,
  handleUpdate,
  setErrMsg,
  updateCount,
  setUpdateCount,
  rowIndex,
  cellIndex,
  productLength,
  totalTitle,
}: Props) => {
  const today = new Date();
  today.setHours(0, 0, 0, 0);
  const { mutate } = useSWRConfig();

  const isToday = isSameIndex(
    cellIndex,
    selectIndex,
    p.numberOfDaysParamsMonth
  );
  const [actualAmount, setActualAmount] = useState(inputParams?.actualAmount);
  const [focusedRowIndex, setFocusedRowIndex] = useState(
    currentRowIndex ? currentRowIndex : 0
  );
  const [focusedCellIndex, setFocusedCellIndex] = useState(
    currentCellIndex ? currentCellIndex : 0
  );

  const handleAmount = (event: React.ChangeEvent<HTMLInputElement>) => {
    const value = event.target.value;
    if (value === '' || /^\d*$/i.test(value)) {
      setActualAmount(value);
      setIsChanged(true);
      setErrMsg(''); // エラーメッセージをクリア

      setTimeout(() => {
        mutate(
          'schedule',
          [
            {
              product_id: p.id,
              date: p.productionSchedules[cellIndex].date,
              actual_amount: value,
            },
          ],
          false
        );
      }, 0);
    } else {
      setErrMsg('半角数字のみ入力してください');
    }
  };

  const handleKeyDown = (e: React.KeyboardEvent) => {
    if (focusedRowIndex > 0 && e.key === 'ArrowUp') {
      setFocusedRowIndex((prevIndex) => prevIndex - 1);
    } else if (
      focusedRowIndex < productLength - 1 &&
      (e.key === 'ArrowDown' || e.key === 'Enter')
    ) {
      setFocusedRowIndex((prevIndex) => prevIndex + 1);
    } else if (focusedCellIndex > 0 && e.key === 'ArrowLeft') {
      setFocusedCellIndex((prevIndex) => prevIndex - 1);
    } else if (
      focusedCellIndex < p.numberOfDaysParamsMonth &&
      e.key === 'ArrowRight'
    ) {
      setFocusedCellIndex((prevIndex) => prevIndex + 1);
    } else if (
      (focusedRowIndex == 0 && e.key === 'ArrowUp') ||
      (focusedRowIndex == productLength - 1 &&
        (e.key === 'ArrowDown' || e.key === 'Enter')) ||
      (focusedCellIndex == 0 && e.key === 'ArrowLeft') ||
      (focusedCellIndex == p.numberOfDaysParamsMonth && e.key === 'ArrowRight')
    ) {
      onBlur();
    }
  };

  const onBlur = () => {
    if (isChanged) {
      if (containsNonNumeric(actualAmount)) {
        setErrMsg('半角数字のみ入力してください');
      } else {
        // 再計算ボタン有効化
        const day = new Date(p.productionSchedules[cellIndex].date);
        day >= today && setUpdateCount(updateCount + 1);
        handleUpdate({
          ...inputParams,
          productId: p.id,
          actualAmount: Number(actualAmount),
          comment: inputParams.comment,
          checkEnabled:
            inputParams.checkEnabled !== null
              ? inputParams.checkEnabled
              : false,
        });
      }
    }
  };

  const getValue = () => {
    if (rowIndex === focusedRowIndex && cellIndex === focusedCellIndex) {
      return actualAmount
        ? actualAmount
        : inputParams?.estimatedAmount
        ? inputParams?.estimatedAmount
        : '';
    } else {
      return actualAmount
        ? addComma(actualAmount)
        : inputParams?.estimatedAmount
        ? addComma(inputParams?.estimatedAmount)
        : '';
    }
  };

  useEffect(() => {
    const focusedCurrentId =
      focusedRowIndex.toString() + '-' + focusedCellIndex.toString();
    const focusedInput = document.getElementById(focusedCurrentId);
    focusedInput && focusedInput.focus();

    setCurrentRowIndex(focusedRowIndex);
    setCurrentCellIndex(focusedCellIndex);
  }, [focusedRowIndex, focusedCellIndex]);

  return (
    <>
      {totalTitle === '' ? (
        <S.Wrapper
          id={`${rowIndex}-${cellIndex}`}
          key={`${p.id}-${p.productCode}-${cellIndex}`}
          highLighten={isToday}
          onChange={handleAmount}
          value={getValue()}
          withCheckEnabled={p.productionSchedules[cellIndex]?.checkEnabled}
          type="text"
          pattern="^[1-9][0-9]*$"
          inputMode="numeric"
          autoComplete="off"
          onFocus={() => {
            setFocusedRowIndex(rowIndex);
            setFocusedCellIndex(cellIndex);
          }}
          onKeyDown={(e) => {
            handleKeyDown(e);
          }}
          onBlur={onBlur}
        />
      ) : (
        // 合計、前年同月
        <S.Wrapper
          id={`${rowIndex}-${cellIndex}`}
          key={`${p.id}-${p.productCode}-${cellIndex}`}
          highLighten={isToday}
          readOnly
          value={
            actualAmount === ''
              ? ''
              : actualAmount
              ? addComma(actualAmount)
              : inputParams?.actualAmount
              ? addComma(inputParams?.actualAmount)
              : ''
          }
        />
      )}
    </>
  );
};

export default ProductionScheduleEditable;
