import styled from 'styled-components';

export const Wrapper = styled.div<{
  open: boolean;
}>`
  height: 100%;
  width: 390px;
  position: fixed;
  padding: 1.5rem 1rem;
  top: 0;
  z-index: 9999;
  right: 0;
  background-color: ${(props) => props.theme.palette.beige};
  box-shadow: 0px 24px 38px rgba(0, 0, 0, 0.07),
    0px 9px 46px rgba(0, 0, 0, 0.06), 0px 11px 15px rgba(0, 0, 0, 0.1);
  transform: ${(props) =>
    props.open ? 'translateX(0px)' : 'translateX(390px)'};
  transition: transform 390ms ease-in-out;
`;

export const ScrollableContainer = styled.div`
  overflow-y: auto;
  height: calc(100vh - 3rem);
  padding-bottom: 1rem;
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none;
  ::-webkit-scrollbar {
    display: none;
  }
`;

export const HeadContainer = styled.div`
  position: relative;
`;

export const HeadDate = styled.div`
  font-weight: 700;
  font-size: 14px;
  line-height: 20px;
`;

export const HeadProductName = styled.div`
  font-weight: 700;
  font-size: 24px;

  margin: 0.5rem 0 1rem 0;
  display: flex;
  align-items: center;
`;

export const HeadProductType = styled.div<{
  productKind: string | undefined;
}>`
  font-size: 0.75rem;
  padding: 0.5rem;
  margin-right: 0.5rem;
  border-radius: 2px;
  border: 1px solid #2ba9d1;
  background: ${(props) => (props.productKind === '完' ? '#2BA9D1' : '#fff')};
  color: ${(props) => (props.productKind === '完' ? '#fff' : '#2BA9D1')};
`;

export const DContainer = styled.div`
  display: flex;
  gap: 0.5rem;
  justify-content: left;
  margin: 1.5rem 0;
`;

export const ButtonContainer = styled.div<{
  invalid: boolean;
}>`
  position: fixed;
  bottom: 0;
  left: 0;
  padding: 1rem 0;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  background-color: #f3ede6;
  pointer-events: ${(props) => (props.invalid ? 'none' : '')};

  & > button {
    width: 136px;
  }
`;

export const InputContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

export const MessageWrapper = styled.div`
  font-size: 15px;
  margin-bottom: 1rem;
  padding-top: 1rem;
  display: flex;
  justify-content: space-between;

  div:first-child {
    font-weight: 700;
  }
`;

// 以下はformのstyle

export const DWrapper = styled.div`
  padding: 0 0.875rem 1rem 0.5rem;
`;

export const NoteAmountContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: left;
  font-weight: 700;
  padding-top: 0.75rem;
  gap: 1rem;
`;

export const NoteAmountLabel = styled.div`
  width: 3.5rem;
  font-weight: 700;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 5.5rem;
`;

export const NoteAmountInput = styled.textarea`
  background: #f8f7f4;
  border-radius: 8px;
  border: 2px solid ${(props) => props.theme.palette.white};
  width: 100%;
  height: 5.5rem;
  text-align: left;
  padding: 0.5rem;
  -webkit-text-fill-color: ${(props) => props.theme.palette.baseBlack};
  opacity: 1;

  resize: none;
`;

export const FormWrapper = styled.div`
  font-size: 15px;
  margin-bottom: 1rem;
  padding-top: 0.5rem;
  display: flex;
  justify-content: space-between;

  div:first-child {
    white-space: nowrap;
    font-weight: 700;
    margin-right: 0.5rem;
  }
`;

export const DRightBottomWrapper = styled.div`
  display: flex;
  align-items: center;
  padding: 0.5rem 0;

  div:first-child {
    white-space: nowrap;
    font-weight: 700;
    margin-right: 0.5rem;
  }
`;

export const DInputContainer = styled.div<{
  center?: boolean;
}>`
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 6px;
  > label {
    font-weight: 700;
    font-size: 13px;
  }
  > input {
    height: 2.75rem;
    max-width: 6.75rem;
    width: 100%;
    border-radius: 8px;
    text-align: ${(props) => (props.center ? 'center' : 'right')};
    padding-right: 12px;
    -webkit-text-fill-color: ${(props) => props.theme.palette.baseBlack};
    ::-webkit-inner-spin-button {
      -webkit-appearance: none;
    }
    opacity: 1;

    :focus {
      outline: none;
    }

    :disabled {
      border: 2px solid #ffffff;
      background-color: transparent;
    }
  }

  > span {
    position: absolute;
    right: 0.3rem;
    top: 80%;
    font-size: 12px;
    margin-left: 4px;
    color: #6b675c;
  }
`;

export const DSymbol = styled.div`
  margin-top: 1rem;
  padding: 0 5px;
  font-weight: 700;
  font-size: 13px;
`;

export const Input = styled.input<{
  labelLength?: boolean;
}>`
  width: 52%;
  height: 3.25rem;
  vertical-align: middle;
  color: ${(props) => props.theme.palette.baseBlack};
  font-size: 1rem;
  line-height: 1.25rem;
  padding: 0.325rem;
  background: ${(props) => props.theme.palette.white};
  border: none;
  border-radius: 0.5rem;
  border: 1px solid rgba(0, 0, 0, 0.08);
  margin-left: ${(props) => (props.labelLength ? '1rem' : '')};
  -webkit-appearance: none;
  ::-webkit-date-and-time-value {
    text-align: left;
    padding-left: 8px;
  }
`;

export const DButtonContainer = styled.div<{
  invalid: boolean;
}>`
  padding: 1rem 0px;
  width: 100%;
  display: flex;
  -webkit-box-align: center;
  align-items: center;
  justify-content: space-evenly;
  border-bottom: 2px solid #d3d3d3;

  & > button {
    width: 136px;
  }

  & > button:first-child {
    margin-right: 1rem;
  }
`;
