import { useState, useEffect } from 'react';
import WrappingContents from '@components/modules/pc/wrapping_status/WrappingContents/WrappingContents';
import WrappingHeaderControl from '@components/modules/pc/wrapping_status/WrappingHeaderControl/WrappingHeaderControl';
import { WithHeader } from '@components/templates/WithHeader';
import useCases from '@lib/pc/common/hooks/useCases';
import useWrappings from '@lib/pc/wrapping/useWrappings';
import useProducts from '@lib/pc/wrapping/useProducts';
import UpdatePopUp from '@components/modules/common/UpdatePopUp/UpdatePopUp';
import { PcScrollStyle } from '@lib/styled-components/globalStyle';
import moment from 'moment';

export const WrappingStatusRoot = () => {
  const today = new Date();
  const [date, setDate] = useState(new Date());
  const [popUp, setPopUp] = useState(false);
  const [messageKind, setMessageKind] = useState('');
  const [editProductName, setEditProductName] = useState('');

  const { wrappings, refetch } = useWrappings(date);
  const { data: products } = useProducts();
  const { data: cases } = useCases();

  const [selectedFactoryId, setSelectedFactoryId] = useState(
    wrappings?.packagingResult[0].factoryId
  );
  const [factoriesData] = useState(wrappings?.packagingResult);
  const [selectedFactoryData, setSelectedFactoryData] = useState(
    wrappings?.packagingResult && wrappings.packagingResult[0]
  );
  const [selectedLineId, setSelectedLineId] = useState<number>(
    selectedFactoryData?.lines[0].factoryLineId
  );
  const [selectedLineIndex, setSelectedLine] = useState(0);
  // 商品選択時の1台車あたりのケース数
  const [casesPerCart, setCasesPerCart] = useState('');
  // 商品選択時の1ケースあたりの個数
  const [piecesPerBox, setPiecesPerBox] = useState('');

  // 1日戻る
  const getBeforeDate = (date: Date) => {
    setDate(moment(date).subtract(1, 'days').toDate());
    setPopUp(false);
  };

  // 1日進む
  const getNextDate = (date: Date) => {
    // 現在日付けが過去日のときのみ、日付を進められる。
    if (
      moment(date).format('YYYY/MM/DD') !== moment(today).format('YYYY/MM/DD')
    ) {
      setDate(moment(date).add(1, 'days').toDate());
      setPopUp(false);
    }
  };

  useEffect(() => {
    localStorage.setItem('last_view_page', 'wrapping_status');
  }, []);

  return (
    <WithHeader>
      <PcScrollStyle />
      <WrappingHeaderControl
        wrappings={wrappings}
        factories={factoriesData ? factoriesData : wrappings?.packagingResult}
        selectedFactoryId={
          selectedFactoryId
            ? selectedFactoryId
            : wrappings?.packagingResult[0].factoryId
        }
        setSelectedFactoryId={setSelectedFactoryId}
        setSelectedFactoryData={setSelectedFactoryData}
        setSelectedLineId={setSelectedLineId}
        setSelectedLine={setSelectedLine}
        selectedDate={date}
        getBeforeDate={getBeforeDate}
        getNextDate={getNextDate}
        setDate={setDate}
        refetch={refetch}
      />
      <WrappingContents
        wrappings={wrappings}
        selectedFactoryId={selectedFactoryId}
        setSelectedFactoryId={setSelectedFactoryId}
        selectedFactoryData={
          selectedFactoryData
            ? selectedFactoryData
            : wrappings?.packagingResult[0]
        }
        setSelectedFactoryData={setSelectedFactoryData}
        selectedLineId={
          selectedLineId
            ? selectedLineId
            : wrappings?.packagingResult[0].lines[0].factoryLineId
        }
        setSelectedLineId={setSelectedLineId}
        selectedLineIndex={selectedLineIndex}
        setSelectedLine={setSelectedLine}
        date={date}
        products={products}
        cases={cases}
        popUp={popUp}
        setPopUp={setPopUp}
        editProductName={editProductName}
        setEditProductName={setEditProductName}
        refetch={refetch}
        messageKind={messageKind}
        setMessageKind={setMessageKind}
        casesPerCart={casesPerCart}
        setCasesPerCart={setCasesPerCart}
        piecesPerBox={piecesPerBox}
        setPiecesPerBox={setPiecesPerBox}
      />
      <UpdatePopUp
        popUp={popUp}
        handleClose={() => setPopUp(false)}
        fromPc={true}
        taskKind={'wrapping'}
        messageKind={messageKind}
        productName={editProductName}
      />
    </WithHeader>
  );
};
