import React, { useEffect, useState } from 'react';
import * as S from './ProcessInfo.styles';
import ChevronLeft from '@assets/icons/chevron_left';
import ProductImg from '@assets/images/product-img.jpg';
import { ReactComponent as CircleCloseIcon } from '@assets/icons/circle_close.svg';
import { ReactComponent as PlusBlueIcon } from '@assets/icons/mobile/plus_blue.svg';
import { ReactComponent as PlusIcon } from '@assets/icons/plus.svg';
import { Button } from '@components/elements/buttons/Button';
import FactoryLineModal from '@components/modules/pc/settings/common/FactoryLineModal/FactoryLineModal';
import DeleteConfirmModal from '@components/modules/common/DeleteConfirmModal/DeleteConfirmModal';
import LABEL_OF_AMOUNT_MAP from '@components/modules/common/LabelOfAmountMap/LabelOfAmountMap';
import ProcessSelect from '@components/modules/pc/settings/common/ProcessSelect/ProcessSelect';
import ProcessesMaterialList from '@components/modules/pc/settings/product_master_setting/ProductMasterEdit/ProcessesMaterialList/ProcessesMaterialList';
import SemiProductSelect from '@components/modules/pc/settings/common/SemiProductSelect/SemiProductSelect';
import {
  Factory,
  FactoryLine,
  MATERIAL_TYPE_LABEL,
  Todo,
} from '@lib/common/type';
import {
  SemiProduct,
  Process,
  Material,
} from '@lib/pc/settings/product_master_setting/type';
import useCreateSemiProductsProcessMutation from '@lib/pc/settings/semi_product_master_setting/useCreateSemiProductsProcess';
import useUpdateSemiProductsProcessMutation from '@lib/pc/settings/semi_product_master_setting/useUpdateSemiProductsProcess';
import useProcessRelations from '@lib/pc/settings/semi_product_master_edit/useProcessRelations';
import ErrorMsgPopUp from '@components/modules/common/ErrorMsgPopUp/ErrorMsgPopUp';
import Overlay from '@components/elements/backdrops/Overlay/Overlay';

type Props = {
  semiProduct: Todo;
  updating: boolean;
  setUpdating: (updating: boolean) => void;
  onClickGoBack: () => void;
  setIsChanged: (isChanged: boolean) => void;
  handleProductUpdateSuccess: () => void;
  allFactories: Factory[] | undefined;
  enableToSave: boolean;
  setEnableToSave: (enableToSave: boolean) => void;
  selectableProcess: boolean;
  setSelectableProcess: (selectableProcess: boolean) => void;
};

const ProcessInfo = ({
  semiProduct,
  updating,
  setUpdating,
  onClickGoBack,
  setIsChanged,
  handleProductUpdateSuccess,
  allFactories,
  enableToSave,
  setEnableToSave,
  selectableProcess,
  setSelectableProcess,
}: Props) => {
  // 新規ボタンor編集ボタン
  const [newMode, setNewMode] = useState(true);
  const [errMsg, setErrMsg] = useState('');

  const initProcessData = {
    id: 0,
    name: '',
    semiProductId: semiProduct?.id || semiProduct?.semiProduct?.id,
  };

  // 工程更新内容
  const [newProcessData, setNewProcessData] = useState(initProcessData);

  const initProcess = {
    processId: 0,
    processName: '',
    factories: [],
    factoryLines: [],
    semiProducts: [],
  };

  // 工程id
  const [selectedProcessId, setSelectedProcessId] = useState(0);
  // 半製品id
  const [selectedSemiProductId, setSelectedSemiProductId] = useState(0);
  // 半製品名
  const [selectedSemiProductName, setSelectedSemiProductName] = useState('');
  // 半製品最小単位
  const [
    selectedSemiProductLabelOfAmount,
    setSelectedSemiProductLabelOfAmount,
  ] = useState('');
  // 資材・原材料id
  const [selectedMaterialId, setSelectedMaterialId] = useState(0);
  // 資材・原材料名
  const [selectedMaterialName, setSelectedMaterialName] = useState('');
  // 資材最小単位
  const [selectedMaterialLabelOfAmount, setSelectedMaterialLabelOfAmount] =
    useState('');
  // 原材料最小単位
  const [
    selectedRawMaterialLabelOfAmount,
    setSelectedRawMaterialLabelOfAmount,
  ] = useState('');

  // 工場ライン編集
  const [selectedLines, setSelectedLines] = useState<FactoryLine[]>([]);
  // 商品に紐づく工場
  const [factories, setFactories] = useState(semiProduct?.factories);
  const [factoryLineModalActive, setFactoryLineModalActive] = useState(false);

  // 変更前工場ライン
  const [beforeLineIds, setBeforeLineIds] = useState<number[]>([]);

  // 半製品AutoComplete用
  const [editItem, setEditItem] = useState<SemiProduct>({
    id: 0,
    name: '',
    pieces: 0,
    imageUrl: '',
    labelOfAmount: '',
  });
  // 半製品入力の数
  const [pieces, setPieces] = useState<Todo>(null);
  // 半製品配列➜表示に使用
  const [semiProductsArray, setSemiProductsArray] = useState<SemiProduct[]>([]);
  // 資材配列➜表示に使用
  const [materialsArray, setMaterialsArray] = useState<Material[]>([]);
  // 原材料配列➜表示に使用
  const [rawMaterialsArray, setRawMaterialsArray] = useState<Material[]>([]);
  // 資材・原材料AutoComplete用
  const [materialEditItem, setMaterialEditItem] = useState<Material>({
    id: 0,
    name: '',
    usageAmount: 0,
    imageUrl: '',
    labelOfAmount: '',
  });

  const {
    semiProducts: allSemiProducts,
    rawMaterials,
    supplyMaterials: materials,
  } = useProcessRelations(selectedProcessId);

  const semiProductsArrayIds = semiProductsArray.map((item) => item['id']);
  // すでに紐づいている半製品と編集中の半製品は表示しない
  const flitteredSemiProduct = allSemiProducts?.filter(
    (sp: Todo) => !semiProductsArrayIds.includes(sp.id)
  );

  // 削除確認メッセージ
  const [confirmMsg, setConfirmMsg] = useState('');
  // 削除No
  const [deleteNo, setDeleteNo] = useState(0);
  // 削除対象
  const [deleteItem, setDeleteItem] = useState('');

  // 工程名の設定
  const handleChangeProcessName = (e: React.ChangeEvent<HTMLInputElement>) => {
    setIsChanged(true);
    setEnableToSave(true);
    const data = { ...newProcessData, name: e.target.value };
    setNewProcessData(data);
  };

  // 半製品の数の設定
  const handleChangeSemiProductPieces = (
    e: React.ChangeEvent<HTMLInputElement>
  ) => {
    setIsChanged(true);
    setPieces(e.target.value);
  };

  // 半製品追加ボタン
  const onClickAddButton = (editItem: SemiProduct) => {
    if (pieces > 0) {
      setIsChanged(true);
      setEnableToSave(true);
      // 表示用
      semiProductsArray.push({
        id: editItem.id,
        name: editItem.name,
        imageUrl: editItem.imageUrl,
        pieces: pieces,
        labelOfAmount: editItem.labelOfAmount,
      });
      setPieces(null);
      setEditItem({
        id: 0,
        name: '',
        pieces: 0,
        imageUrl: '',
        labelOfAmount: '',
      });
      setSelectedSemiProductId(0);
      setSelectedSemiProductName('');
      setSelectedSemiProductLabelOfAmount('');
    } else {
      setErrMsg('0より大きい数を入力してください。');
    }
  };

  // 半製品削除ボタン
  const onClickDelButton = (no: number, name: string) => {
    setConfirmMsg(`${name}を本当に削除しますか？`);
    setDeleteItem('semiProduct');
    setDeleteNo(no);
  };

  const deleteFunction = () => {
    setIsChanged(true);
    setEnableToSave(true);
    if (deleteItem === 'semiProduct') {
      semiProductsArray.splice(deleteNo, 1);
      setSemiProductsArray([...semiProductsArray]);
    } else if (deleteItem === 'material') {
      materialsArray.splice(deleteNo, 1);
      setMaterialsArray([...materialsArray]);
    } else {
      rawMaterialsArray.splice(deleteNo, 1);
      setRawMaterialsArray([...rawMaterialsArray]);
    }
    setDeleteItem('');
    setConfirmMsg('');
  };

  const createSemiProductsProcessMutation =
    useCreateSemiProductsProcessMutation(handleProductUpdateSuccess);

  const updateSemiProductsProcessMutation =
    useUpdateSemiProductsProcessMutation(
      selectedProcessId,
      handleProductUpdateSuccess,
      setErrMsg
    );

  const handleClickNew = () => {
    setNewMode(true);
    setSelectedLines([]);
    setSemiProductsArray([]);
    setBeforeLineIds([]);
    setMaterialsArray([]);
    setSelectableProcess(false);
  };

  const handleClickEdit = async () => {
    setSelectedProcessId(0);
    setNewMode(false);
    setSelectableProcess(true);
  };

  // ***********************
  // [保存ボタン]押下、更新処理
  // ***********************
  const handleSubmit = async () => {
    setUpdating(true);
    const selectedLinesIdsForUpdate = selectedLines.map((item) => item['id']);
    const data = {
      ...newProcessData,
      productId: semiProduct.semiProduct.id,
      factoryLineIds: selectedLinesIdsForUpdate,
    };
    setNewProcessData(data);
    const semiProductsForUpdate = semiProductsArray.map(
      (item: SemiProduct) => ({
        id: item.id,
        pieces: item.pieces,
      })
    );
    // 資材と原材料追加データのまとめ
    const mergedArray = materialsArray.concat(rawMaterialsArray);
    const materialsForUpdate = mergedArray.map((item: Material) => ({
      id: item.id,
      usageAmount: item.usageAmount,
    }));

    if (newMode) {
      // 作成処理
      createSemiProductsProcessMutation.mutate({
        name: newProcessData.name,
        semiProductId: semiProduct?.semiProduct?.id,
        factoryLineIds: selectedLinesIdsForUpdate,
        semiProducts: semiProductsForUpdate,
        materials: materialsForUpdate,
      });
      setSelectedLines([]);
      setSemiProductsArray([]);
      setMaterialsArray([]);
      setBeforeLineIds([]);
      setNewProcessData(initProcessData);
    } else {
      // 更新処理
      updateSemiProductsProcessMutation.mutate({
        productsProcess: newProcessData,
        factoryLineIds: selectedLinesIdsForUpdate,
        semiProducts: semiProductsForUpdate,
        materials: materialsForUpdate,
      });
    }
  };

  // 選択された工程の内容を設定する
  const setSelectedProcessInfo = () => {
    const selectedProcess = semiProduct?.processes
      ? semiProduct?.processes.find(
          (p: Process) => selectedProcessId === p.processId
        )
      : initProcess;
    setSelectedLines(
      selectedProcess?.factoryLines ? selectedProcess?.factoryLines : []
    );
    setSemiProductsArray(
      selectedProcess?.semiProducts ? selectedProcess?.semiProducts : []
    );
    setMaterialsArray(
      selectedProcess?.materials ? selectedProcess?.materials : []
    );
    setRawMaterialsArray(
      selectedProcess?.rawMaterials ? selectedProcess?.rawMaterials : []
    );
    if (selectedProcess?.factoryLines) {
      const beforeIds = selectedProcess?.factoryLines.map(
        (item: FactoryLine) => item['id']
      );
      setBeforeLineIds(beforeIds);
    } else {
      setBeforeLineIds([]);
    }
  };

  useEffect(() => {
    const newIds = selectedLines.map((item: FactoryLine) => item['id']);
    if (newIds.toString() !== beforeLineIds.toString()) {
      setIsChanged(true);
      setEnableToSave(true);
    } else {
      setIsChanged(false);
    }
  }, [selectedLines]);

  useEffect(() => {
    setEditItem({
      id: selectedSemiProductId,
      name: selectedSemiProductName,
      pieces: 0,
      imageUrl: '',
      labelOfAmount: selectedSemiProductLabelOfAmount,
    });
  }, [selectedSemiProductId]);

  useEffect(() => {
    setMaterialEditItem({
      id: selectedMaterialId,
      name: selectedMaterialName,
      usageAmount: 0,
      imageUrl: '',
      labelOfAmount: '',
    });
  }, [selectedMaterialId, selectedMaterialName]);

  // 工程が変更されたら工場・ラインと半製品を該当工程のものへ設定する
  useEffect(() => {
    if (selectedProcessId !== initProcess.processId) {
      setNewMode(false);
    } else {
      setNewMode(true);
    }
    setSelectedProcessInfo();
  }, [selectedProcessId]);

  // 工程登録済みの場合、編集ボタンをactiveにする
  useEffect(() => {
    if (selectedProcessId === 0 && semiProduct?.processes?.length > 0) {
      setSelectableProcess(true);
      // 1つめの工程設定
      setSelectedProcessId(semiProduct.processes[0].processId);
    } else {
      setSelectedProcessInfo();
    }
  }, [semiProduct?.processes?.length]);

  const isAddButtonDisabled =
    (newMode &&
      (newProcessData?.name === undefined || newProcessData?.name === '')) ||
    (!newMode && newProcessData.semiProductId === 0);

  return (
    <>
      {semiProduct && (
        <S.Wrapper>
          <div className="go-back" onClick={onClickGoBack}>
            <ChevronLeft isBlue={true} />
            <span>一覧画面へ戻る</span>
          </div>

          <S.ProductMasterEditContents>
            <div className="product-info">
              <div className="product-img">
                <div className="img">
                  <img
                    src={
                      semiProduct?.imageUrl ||
                      semiProduct?.semiProduct?.imageUrl ||
                      ProductImg
                    }
                  />
                </div>
              </div>
              <div className="name">
                {semiProduct?.name || semiProduct?.semiProduct?.name}
              </div>
            </div>
            <div className="associated-number-of-pieces">
              {/* 工程名 */}
              <div className="process-breakdown-list-header">
                <dl className="process-breakdown-list-header__inputs">
                  <div className="process-name">
                    <dt>工程名</dt>
                    <dd>
                      {selectableProcess ? (
                        // 工程選択
                        <ProcessSelect
                          processes={semiProduct?.processes}
                          value={selectedProcessId}
                          setSelectedProcessId={setSelectedProcessId}
                        />
                      ) : (
                        <S.Input
                          type="text"
                          inputMode="numeric"
                          name="process"
                          id="process"
                          autoComplete="off"
                          align="left"
                          padding="0 5px"
                          value={newProcessData?.name}
                          onChange={handleChangeProcessName}
                        />
                      )}
                    </dd>
                  </div>
                </dl>
                <div className="new-btn">
                  <S.ModeButton
                    newMode={selectableProcess ? true : false}
                    onClick={() => {
                      handleClickNew();
                    }}
                  >
                    新規
                  </S.ModeButton>
                </div>
                <div className="edit-btn">
                  <S.ModeButton
                    newMode={selectableProcess ? false : true}
                    onClick={() => {
                      handleClickEdit();
                    }}
                  >
                    編集
                  </S.ModeButton>
                </div>
              </div>
              {/* 工場・ライン */}
              <div className="plant-line-container">
                <div className="plant-line-container__title">工場・ライン</div>
                <ul className="plant-line-list">
                  {/* 製造者の工場全てから検索する */}
                  {allFactories &&
                    allFactories.map((factory) => {
                      const selectedLinesForFactory = selectedLines?.filter(
                        (line) => line.factoryId === factory.id
                      );
                      const hasSelectedLines =
                        selectedLinesForFactory.length > 0;

                      return (
                        <React.Fragment key={factory.id}>
                          {hasSelectedLines && (
                            <S.FactoryList
                              className="factory-list"
                              factoryPresence={hasSelectedLines}
                            >
                              {hasSelectedLines && (
                                <div className="plant-name">{factory.name}</div>
                              )}
                              <div className="line-wrapper">
                                {selectedLinesForFactory.map((line) => (
                                  <div key={line.id}>
                                    <ul className="plant-line-list__line line-list">
                                      <li className="line-list__line">
                                        {line.name}
                                      </li>
                                    </ul>
                                  </div>
                                ))}
                              </div>
                            </S.FactoryList>
                          )}
                        </React.Fragment>
                      );
                    })}
                </ul>
                <div
                  className={
                    isAddButtonDisabled ? 'disabled-add-button' : 'add-button'
                  }
                  onClick={
                    !isAddButtonDisabled
                      ? () => setFactoryLineModalActive(true)
                      : undefined
                  }
                >
                  <S.PlusIcon>
                    {isAddButtonDisabled ? <PlusIcon /> : <PlusBlueIcon />}
                  </S.PlusIcon>
                  <span className="label">工場・ラインを選択</span>
                </div>
              </div>

              {/* 半製品個数追加箇所 */}
              <div className="piece-breakdown-list-header">
                <dl className="piece-breakdown-list-header__inputs">
                  <div className="close-btn" />
                  <div className="product-name">
                    <dt>半製品名</dt>
                    <dd>
                      <SemiProductSelect
                        semiProducts={flitteredSemiProduct}
                        value={selectedSemiProductId}
                        setSelectedSemiProductId={setSelectedSemiProductId}
                        setSelectedSemiProductName={setSelectedSemiProductName}
                        setSelectedSemiProductLabelOfAmount={
                          setSelectedSemiProductLabelOfAmount
                        }
                        disabled={
                          newProcessData?.name === '' && selectedProcessId === 0
                        }
                      />
                    </dd>
                  </div>
                  <div className="product-number-wrapper">
                    <div className="product-number">
                      <dt>数量</dt>
                      <dd>
                        <S.Input
                          type="number"
                          inputMode="numeric"
                          name="pieces"
                          id="pieces"
                          autoComplete="off"
                          align="left"
                          padding="0 5px"
                          value={pieces ? pieces : ''}
                          onChange={handleChangeSemiProductPieces}
                          disabled={
                            newProcessData?.name === '' &&
                            selectedProcessId === 0
                          }
                        />
                      </dd>
                    </div>
                  </div>
                  <div className="label-of-amount">
                    {selectedSemiProductLabelOfAmount
                      ? LABEL_OF_AMOUNT_MAP[selectedSemiProductLabelOfAmount]
                      : ''}
                  </div>
                  <div className="add-btn">
                    {editItem.name && pieces ? (
                      <Button
                        children={'追加'}
                        onClick={() => onClickAddButton(editItem)}
                      />
                    ) : (
                      <Button disabled children={'追加'} />
                    )}
                  </div>
                </dl>
              </div>
              {/* 半製品個数内訳リスト */}
              <div className="piece-breakdown-list-contents">
                <ul className="piece-breakdown-list-contents__pieces">
                  {semiProductsArray &&
                    semiProductsArray.map((sp: SemiProduct, index: number) => {
                      return (
                        <li
                          className="piece-breakdown-list-contents__piece"
                          key={sp?.id + index + Math.random()}
                        >
                          <div
                            className="close-btn"
                            onClick={() => onClickDelButton(index, sp?.name)}
                            key={sp?.name + index}
                          >
                            <CircleCloseIcon />
                          </div>
                          {/* {sp?.imageUrl ? (
                            <img src={sp.imageUrl} />
                          ) : (
                            <div className="product-img" key={Math.random()}>
                              img
                            </div>
                          )} */}
                          <div
                            className="product-name"
                            key={sp.name + Math.random()}
                          >
                            {sp?.name}
                          </div>
                          <div
                            className="product-pieces-num"
                            key={sp.name + sp.pieces + Math.random()}
                          >
                            {sp?.pieces}
                          </div>
                          <div className="label-of-amount">
                            {sp?.labelOfAmount
                              ? LABEL_OF_AMOUNT_MAP[sp?.labelOfAmount]
                              : '個'}
                          </div>
                        </li>
                      );
                    })}
                </ul>
              </div>
              {/* 資材追加箇所 */}
              <ProcessesMaterialList
                label={MATERIAL_TYPE_LABEL.MATERIAL}
                materials={materials}
                materialEditItem={materialEditItem}
                materialsArray={materialsArray}
                selectedMaterialId={selectedMaterialId}
                setSelectedMaterialId={setSelectedMaterialId}
                setSelectedMaterialName={setSelectedMaterialName}
                setIsChanged={setIsChanged}
                setEnableToSave={setEnableToSave}
                setErrMsg={setErrMsg}
                setConfirmMsg={setConfirmMsg}
                setDeleteItem={setDeleteItem}
                setDeleteNo={setDeleteNo}
                setMaterialEditItem={setMaterialEditItem}
                selectedMaterialLabelOfAmount={selectedMaterialLabelOfAmount}
                setSelectedMaterialLabelOfAmount={
                  setSelectedMaterialLabelOfAmount
                }
                disabled={
                  newProcessData?.name === '' && selectedProcessId === 0
                }
              />
              {/* 原材料追加箇所 */}
              <ProcessesMaterialList
                label={MATERIAL_TYPE_LABEL.ROW_MATERIAL}
                materials={rawMaterials}
                materialEditItem={materialEditItem}
                materialsArray={rawMaterialsArray}
                selectedMaterialId={selectedMaterialId}
                setSelectedMaterialId={setSelectedMaterialId}
                setSelectedMaterialName={setSelectedMaterialName}
                setIsChanged={setIsChanged}
                setEnableToSave={setEnableToSave}
                setErrMsg={setErrMsg}
                setConfirmMsg={setConfirmMsg}
                setDeleteItem={setDeleteItem}
                setDeleteNo={setDeleteNo}
                setMaterialEditItem={setMaterialEditItem}
                selectedMaterialLabelOfAmount={selectedRawMaterialLabelOfAmount}
                setSelectedMaterialLabelOfAmount={
                  setSelectedRawMaterialLabelOfAmount
                }
                disabled={
                  newProcessData?.name === '' && selectedProcessId === 0
                }
              />
            </div>
          </S.ProductMasterEditContents>
        </S.Wrapper>
      )}
      <S.ButtonContainer>
        {/* <Button
          children={'キャンセル'}
          borderWidth={1}
          outlined
          onClick={onClickCancel}
          disabled={updating}
        /> */}
        {enableToSave ? (
          <Button
            children={updating ? '保存中...' : '保存'}
            onClick={handleSubmit}
          />
        ) : (
          <Button disabled children={'保存'} />
        )}
      </S.ButtonContainer>
      {factoryLineModalActive && (
        <FactoryLineModal
          selectedLines={selectedLines}
          setSelectedLines={setSelectedLines}
          factories={factories}
          setFactories={setFactories}
          handleClose={() => setFactoryLineModalActive(false)}
        />
      )}
      {confirmMsg && (
        <DeleteConfirmModal
          confirmMsg={confirmMsg}
          onClickCancel={() => setConfirmMsg('')}
          onClickDelete={() => deleteFunction()}
        />
      )}
      <ErrorMsgPopUp
        errMsg={errMsg}
        handleClose={() => {
          setErrMsg('');
          setUpdating(false);
        }}
        fromPc={true}
        zIndex={9999}
      />
      {errMsg && (
        <Overlay
          dark
          handleClick={() => {
            setErrMsg('');
            setUpdating(false);
          }}
        />
      )}
    </>
  );
};

export default ProcessInfo;
