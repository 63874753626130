import styled from 'styled-components';

export const Wrapper = styled.div`
  width: 100%;
  height: 100vh;
  padding: 16px 80px;
  background-color: ${(props) => props.theme.palette.beige};
  overflow-y: scroll;
  font-feature-settings: 'palt';
`;

export const GoBackWrapper = styled.div`
  width: 160px;
  display: flex;
  padding-bottom: 32px;
  cursor: pointer;
  color: ${(props) => props.theme.palette.blue};
  > span {
    padding-top: 8px;
    padding-left: 10px;
  }
`;

export const GroupCodeWrapper = styled.div`
  display: flex;
  margin-bottom: 36px;
  > span {
    font-weight: 700;
    margin-right: 55px;
  }
`;

export const NameWrapper = styled.div`
  display: flex;
  margin-bottom: 36px;
  > span {
    font-weight: 700;
    margin-right: 72px;
  }
`;

export const ShippingDestinationTitle = styled.div`
  font-weight: 700;
  padding-bottom: 8px;
  text-align: left;
  width: 80%;
`;

export const ShippingDestinationSelectRow = styled.div`
  display: flex;
  /* gap: 24px; */
  justify-content: space-between;
`;

export const ShippingDestinationSelectWrapper = styled.div`
  width: 90%;
`;

export const Input = styled.input`
  width: 100%;
  height: 44px;
  color: ${(props) => props.theme.palette.baseBlack};
  font-size: 1rem;
  padding: 0.75rem;
  text-align: left;
  outline: none;
  border-radius: 0.5rem;
  border: 1px solid rgba(0, 0, 0, 0.08);
  background: ${(props) => props.theme.palette.white};
  ::-webkit-inner-spin-button {
    -webkit-appearance: none;
  }
`;

export const AddButton = styled.span`
  padding-top: 24px;
  justify-content: center;
  align-items: center;
  dd {
    text-align: -webkit-right;
  }
  button {
    width: 64px;
    &.disabled {
      background: #e0e0e0;
      border-color: #e0e0e0;
      color: #6b675c;
      cursor: initial;
    }
  }
`;

export const ShippingDestinationsWrapper = styled.div`
  margin-top: 24px;
  overflow: scroll;
  height: calc(100vh - 193px);
  padding-bottom: 500px;
  & > div:first-child {
    border-top-left-radius: 0.5rem;
    border-top-right-radius: 0.5rem;
  }
  & > div:last-child {
    border-bottom-left-radius: 0.5rem;
    border-bottom-right-radius: 0.5rem;
  }
`;

export const ShippingDestination = styled.div`
  cursor: pointer;
  width: 100%;
  display: flex;
  border-bottom: 1px solid #cdcdcd;
  align-items: center;
  background-color: ${(props) => props.theme.palette.white};
  border: 1px solid rgba(0, 0, 0, 0.08);
  &:hover {
    box-shadow: 3px 3px 3px rgba(0, 0, 0, 0.1);
    border: solid 1px #e5e1dd;
  }
`;

export const DeleteButton = styled.div`
  /* margin: 12px 0; */
`;

export const DepartmentNameWrapper = styled.div`
  display: flex;
  width: 100%;
  height: 48px;
  align-items: center;
`;

export const CloseButton = styled.div`
  width: 40px;
  height: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const ButtonContainer = styled.div`
  position: fixed;
  width: 100vw;
  bottom: 0;
  padding: 22px 0;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #f8f7f4;
  & > button {
    width: 136px;
    &.disabled {
      background: #e0e0e0;
      border-color: #e0e0e0;
      color: #6b675c;
      cursor: initial;
    }
  }
`;
