import { axiosInstance } from '@lib/pc/common/api/axiosConfig';
import { useQuery } from 'react-query';
import humps from 'humps';
import moment from 'moment';
import type { Todo } from '@lib/common/type';

const useOrderSummaryDetails = (
  date: Date,
  customerId: string | number,
  productId: string | number
) => {
  const { data } = useQuery(
    ['order_summary_details', date, customerId, productId],
    async () => {
      const data = await axiosInstance().get(
        `/api/v1/order_summary_detail?date=${moment(date).format(
          'YYYY-MM-DD'
        )}&customer_id=${customerId}&product_id=${productId}`
      );
      return data;
    }
  );
  const orderSummaryDetails = data && (humps.camelizeKeys(data.data) as Todo);

  return { orderSummaryDetails };
};

export default useOrderSummaryDetails;
