import styled from 'styled-components';

export const Wrapper = styled.div`
  background: ${(props) => props.theme.palette.beige};
  margin: 0 auto;
`;

export const Container = styled.div`
  margin: 0 auto;
  width: 100vw;
`;

export const StickyContainer = styled.div`
  height: auto;
  width: 100%;
`;

export const StickyItem = styled.div`
  background-color: ${(props) => props.theme.palette.beige};
  position: sticky;
  top: 0;
  width: 100%;
`;

export const Title = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: bold;
  font-size: 1.125rem;
  padding-top: 1rem;
  padding-right: 0.8rem;
  &.plannedTitle {
    border-bottom: 1px solid ${(props) => props.theme.palette.sand};
    justify-content: space-between;
    padding-left: 1.5rem;
    padding-top: 0;
  }
`;

export const TitleText = styled.div`
  padding-right: 0.7rem;
  padding-left: 1rem;
`;

export const TitleContainer = styled.div`
  display: flex;
  justify-content: space-between;
  font-feature-settings: 'palt';
`;

export const IconContainer = styled.div`
  margin-right: 9px;
`;

export const CautionIconWrapper = styled.div`
  margin-top: 15px;
  margin-right: 10px;
`;

export const PlanDate = styled.div`
  font-size: 24px;
  font-weight: 700;
`;

export const PlanDayOfWeek = styled.div`
  font-size: 18px;
  font-weight: 700;
  padding-top: 4px;
`;

export const PlanText = styled.div`
  font-size: 22px;
  font-weight: 700;
`;
