import React, { useState } from 'react';
import * as S from './FactoryLineModal.styles';
// import { ReactComponent as SearchIcon } from '@assets/icons/search.svg';
import { Button } from '@components/elements/buttons/Button';
import type { Factory, Todo } from '@lib/common/type';
import useFactories from '@lib/pc/settings/product_master_edit/useFactories';
import useFactoryLines from '@lib/pc/settings/product_master_edit/useFactoryLines';
import { FactoryLine } from '@lib/pc/settings/product_master_edit/type';

type Props = {
  selectedLines: Todo[];
  setSelectedLines: (selectedLineIds: FactoryLine[]) => void;
  factories: Factory[];
  setFactories: (factories: Factory[]) => void;
  handleClose: () => void;
};

const FactoryLineModal: React.FC<Props> = ({
  selectedLines,
  setSelectedLines,
  factories,
  setFactories,
  handleClose,
}: Props) => {
  const { data: allFactories } = useFactories();
  const { data: allFactoryLines } = useFactoryLines();
  const [selectedLinesState, setSelectedLinesState] =
    useState<FactoryLine[]>(selectedLines);
  const [selectedFactoriesState] = useState<Factory[]>(factories);

  return (
    <S.Wrapper>
      <S.Container>
        <S.Header>
          <S.Title>工場・ラインの選択</S.Title>
        </S.Header>
        {allFactories &&
          allFactories.map((factory: Factory, index: number) => {
            return (
              <React.Fragment key={index + factory.id}>
                <S.FactoryName key={index + factory.id + factory.name}>
                  {factory.name}
                </S.FactoryName>
                <S.ListUl key={index}>
                  {allFactoryLines &&
                    allFactoryLines.map((allLines: Todo) =>
                      allLines
                        .filter((al: Todo) => al.factoryId === factory.id)
                        .map((l: FactoryLine) => (
                          <S.ListLi key={l.id}>
                            <S.Label $checked={selectedLines?.includes(l.id)}>
                              <S.Checkbox
                                type="checkbox"
                                name="list"
                                value={l.id}
                                onChange={() => {
                                  selectedLinesState?.some(
                                    (t: Todo) => t.id === l.id
                                  )
                                    ? setSelectedLinesState(
                                        selectedLinesState.filter(
                                          (t: Todo) => t.id !== l.id
                                        )
                                      )
                                    : setSelectedLinesState([
                                        ...selectedLinesState,
                                        l,
                                      ]);
                                  allFactories.some(
                                    (f: Factory) =>
                                      f.id === l.factoryId &&
                                      !selectedFactoriesState?.some(
                                        (t: Factory) => t.id === f.id
                                      )
                                  )
                                    ? selectedFactoriesState?.push({
                                        id: l.factoryId,
                                        name: l.factoryName,
                                      })
                                    : '';
                                }}
                                defaultChecked={selectedLinesState?.some(
                                  (t: Todo) => t.id === l.id
                                )}
                              />
                              <S.TagName
                                defaultChecked={selectedLinesState?.some(
                                  (t) => t.id === l.id
                                )}
                              >
                                {l.name}
                              </S.TagName>
                            </S.Label>
                          </S.ListLi>
                        ))
                    )}
                </S.ListUl>
              </React.Fragment>
            );
          })}
      </S.Container>
      <S.ButtonWrapper>
        <S.ButtonContainer>
          <Button borderWidth={1} outlined={true} onClick={handleClose}>
            キャンセル
          </Button>
          <Button
            onClick={() => {
              setSelectedLines(selectedLinesState);
              setFactories(selectedFactoriesState);
              handleClose();
            }}
          >
            決定
          </Button>
        </S.ButtonContainer>
      </S.ButtonWrapper>
    </S.Wrapper>
  );
};
export default FactoryLineModal;
