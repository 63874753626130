import { FC, useState, useEffect } from 'react';
import moment from 'moment';
import * as S from './RightSlideSidebar.styles';
import { ReactComponent as UpdateIcon } from '@assets/icons/update.svg';
import { Button } from '@components/elements/buttons/Button';
import Overlay from '@components/elements/backdrops/Overlay/Overlay';
import ConfirmPopUp from '@components/modules/common/ConfirmPopUp/ConfirmPopUp';
import ErrorMsgPopUpForStock from '@components/modules/pc/stock_status/ErrorMsgPopUpForStock/ErrorMsgPopUpForStock';
import { addComma } from '@lib/pc/production_schedule/functions';
import type { SelectedProduct } from '@lib/pc/production_schedule/type';

type Props = {
  popUp: boolean;
  setPopUp: (popUp: boolean) => void;
  handleCloseSidebar: (date: Date) => void;
  selectedProduct: SelectedProduct | null;
  directMode: boolean;
  handleChangeButtonOnRightSlideSidebar: () => void;
  isChanged: boolean;
  setIsChanged: (isChanged: boolean) => void;
  inputParams: {
    actualAmount: number | string;
    comment: string;
    checkEnabled: boolean;
  };
  setInputParams: (params: {
    actualAmount: number | string;
    comment: string;
    checkEnabled: boolean;
  }) => void;
  updating: boolean;
  errMsg: string;
  setErrMsg: (errMsg: string) => void;
};

const RightSlideSidebar: FC<Props> = ({
  popUp,
  setPopUp,
  handleCloseSidebar,
  selectedProduct,
  directMode,
  handleChangeButtonOnRightSlideSidebar,
  isChanged,
  setIsChanged,
  inputParams,
  setInputParams,
  updating,
  errMsg,
  setErrMsg,
}: Props) => {
  const today = new Date();
  today.setHours(0, 0, 0, 0);
  const day = new Date(
    selectedProduct ? selectedProduct.selectedSchedule.date : null
  );
  const DD = moment(day).format('DD');
  const MM = moment(day).format('MM');
  const dd = moment(day).format('ddd');

  const [checked, setChecked] = useState(false);
  const [confirmMsg, setConfirmMsg] = useState('');

  useEffect(() => {
    if (selectedProduct) {
      setInputParams({
        actualAmount:
          selectedProduct.selectedSchedule.actualAmount === 0
            ? '0'
            : selectedProduct.selectedSchedule.actualAmount
            ? selectedProduct.selectedSchedule.actualAmount
            : selectedProduct.selectedSchedule.estimatedAmount === 0
            ? '0'
            : selectedProduct.selectedSchedule.estimatedAmount
            ? selectedProduct.selectedSchedule.estimatedAmount
            : '',
        comment: selectedProduct.selectedSchedule.comment,
        checkEnabled: selectedProduct.selectedSchedule.checkEnabled,
      });
      setChecked(selectedProduct.selectedSchedule.checkEnabled || false);
    }
  }, [selectedProduct]);

  const handleAmount = (event: React.ChangeEvent<HTMLInputElement>) => {
    const value = event.target.value;
    if (value) {
      setInputParams({
        ...inputParams,
        actualAmount: Number(value),
        checkEnabled:
          inputParams.checkEnabled === null ? false : inputParams.checkEnabled,
      });
      setIsChanged(true);
    } else {
      setInputParams({ ...inputParams, actualAmount: '' });
    }
  };

  const handleComment = (event: React.ChangeEvent<HTMLTextAreaElement>) => {
    const value = event.target.value;
    setInputParams({ ...inputParams, comment: value });
    setIsChanged(true);
  };

  const handleChecked = (event: React.ChangeEvent<HTMLInputElement>) => {
    const check = event.target.checked;
    setChecked(check);
    setInputParams({ ...inputParams, checkEnabled: check });
    setIsChanged(true);
  };

  const handleRecommendedValue = () => {
    if (
      selectedProduct?.selectedSchedule.actualAmount === 0 ||
      selectedProduct?.selectedSchedule.actualAmount
    ) {
      const estimatedAmount =
        selectedProduct.selectedSchedule.estimatedAmount === 0
          ? '0'
          : selectedProduct.selectedSchedule.estimatedAmount;
      const element = document.getElementById(
        'productAmountInput'
      ) as HTMLInputElement;
      element.value = estimatedAmount;
      element.dispatchEvent(new Event('change', { bubbles: true }));
      setInputParams({ ...inputParams, actualAmount: estimatedAmount });
      setIsChanged(true);
    }
  };

  const onClickCancel = () => {
    if (isChanged) {
      setPopUp(false);
      setConfirmMsg('保存せずに編集を終了します。よろしいですか。');
    } else {
      setPopUp(false);
    }
  };

  const handleOk = () => {
    handleCloseSidebar(day);
    setIsChanged(false);
    setConfirmMsg('');
  };

  const handleCancel = () => {
    setConfirmMsg('');
  };

  return (
    <>
      <S.Wrapper open={selectedProduct !== null && inputParams && !directMode}>
        <S.DateContainer>
          <S.MMTypography> {MM}</S.MMTypography>
          <S.SlashTypography>/</S.SlashTypography>
          <S.FlexAlignBaseline>
            <S.DDTypography>{DD}</S.DDTypography>
            <S.DdTypography>({dd})</S.DdTypography>
          </S.FlexAlignBaseline>
        </S.DateContainer>
        <S.ProductContainer>
          <S.ProductImage />
          <div>{selectedProduct && selectedProduct.productName}</div>
        </S.ProductContainer>
        <>
          <S.ProductAmountContainer>
            <S.ProductAmountLabel>
              <div>直接変更</div>
              {selectedProduct?.selectedSchedule.estimatedAmount !== null && (
                <S.FlexAlignCenter>
                  <S.UpdateIconWrapper onClick={handleRecommendedValue}>
                    <UpdateIcon />
                  </S.UpdateIconWrapper>
                  推奨値(
                  {addComma(
                    selectedProduct &&
                      selectedProduct.selectedSchedule.estimatedAmount
                  )}
                  )に戻す
                </S.FlexAlignCenter>
              )}
            </S.ProductAmountLabel>

            <S.ProductAmountInput
              onChange={handleAmount}
              value={
                inputParams.actualAmount === ''
                  ? ''
                  : inputParams.actualAmount === 0
                  ? '0'
                  : inputParams.actualAmount
                  ? inputParams.actualAmount
                  : selectedProduct?.selectedSchedule.actualAmount
                  ? selectedProduct?.selectedSchedule.actualAmount
                  : selectedProduct?.selectedSchedule.estimatedAmount
                  ? selectedProduct?.selectedSchedule.estimatedAmount
                  : ''
              }
              type="number"
              inputMode="numeric"
              id="productAmountInput"
            />
          </S.ProductAmountContainer>
          <S.NoteAmountContainer>
            <S.NoteAmountLabel>備考</S.NoteAmountLabel>
            <S.NoteAmountInput
              placeholder="メモを残せます"
              value={
                inputParams.comment === ''
                  ? ''
                  : inputParams.comment
                  ? inputParams.comment
                  : selectedProduct?.selectedSchedule.comment
                  ? selectedProduct?.selectedSchedule.comment
                  : ''
              }
              onChange={handleComment}
            />
          </S.NoteAmountContainer>
          <S.MessageContainer>
            <S.CheckBoxContainer>
              <label>
                <input
                  type="checkbox"
                  onChange={handleChecked}
                  checked={checked}
                ></input>
                <span></span>
                ｢変更があった｣ことが伝わるように
                <br />
                セルを目立たせる
              </label>
            </S.CheckBoxContainer>
            <S.ButtonContainer invalid={confirmMsg || errMsg ? true : false}>
              <Button
                borderWidth={1}
                outlined={true}
                onClick={() => {
                  isChanged ? onClickCancel() : handleCloseSidebar(day);
                }}
                disabled={updating}
              >
                キャンセル
              </Button>
              {selectedProduct && (
                <Button
                  borderWidth={1}
                  onClick={handleChangeButtonOnRightSlideSidebar}
                  disabled={updating}
                >
                  変更
                </Button>
              )}
            </S.ButtonContainer>
          </S.MessageContainer>
          <S.CheckBoxAndButtonContainer></S.CheckBoxAndButtonContainer>
          <ErrorMsgPopUpForStock
            errMsg={errMsg}
            handleClose={() => setErrMsg('')}
            fromPc={true}
            width={'20rem'}
          />
        </>
        <ConfirmPopUp
          fromPc={true}
          confirmMsg={confirmMsg}
          width={'20rem'}
          handleOk={handleOk}
          handleCancel={handleCancel}
        />
      </S.Wrapper>
      {popUp && (
        <Overlay
          handleClick={() => {
            setPopUp(false);
          }}
        />
      )}
    </>
  );
};

export default RightSlideSidebar;
