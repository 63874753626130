import moment from 'moment';
import * as S from './AlertPickingPastShipDateModal.styles';
import { ReactComponent as CautionIcon } from '@assets/icons/caution.svg';
import {
  formatDate,
  formatDayOfWeek,
} from '@lib/mobile/picking_by_shipping/functions';
import { PickingState } from '@lib/mobile/picking_edit/type';
import { useHistory } from 'react-router-dom';

type DataOfPastShipDate = {
  shippingDate: string;
  totalAmount: number;
};

type Props = {
  setDate: (d: moment.Moment) => void;
  setFromDatePicker: (fromDatePicker: boolean) => void;
  setAlertPickingPastShipDate: (alertPickingPastShipDate: boolean) => void;
  dataOfPastShipDate: DataOfPastShipDate[];
  pickingState: PickingState;
};

const AlertPickingPastShipDateModal = ({
  setDate,
  setFromDatePicker,
  setAlertPickingPastShipDate,
  dataOfPastShipDate,
  pickingState,
}: Props) => {
  const history = useHistory();

  // alert画面での日付押下時処理
  const handleClickAlertDate = (date: moment.Moment | null) => {
    setDate(date || moment());
    history.push(`/mobile/picking_by_shipping`, {
      ...pickingState,
      stateSearchChar: '',
      stateSelectedStoreroomId: '',
    });
    setFromDatePicker(true);
    setAlertPickingPastShipDate(false);
  };

  return (
    <S.Wrapper>
      <S.TitleWrapper>
        <S.IconWrapper>
          <CautionIcon />
        </S.IconWrapper>
        <div>下記日付に未出荷のピッキングデータがあります。</div>
      </S.TitleWrapper>
      {dataOfPastShipDate &&
        dataOfPastShipDate.map((data: DataOfPastShipDate, index: number) => (
          <S.DateAndTotalAmount key={index}>
            <S.Date
              onClick={() => {
                handleClickAlertDate(moment(data.shippingDate));
              }}
            >
              {formatDate(data.shippingDate)}
              <span>({formatDayOfWeek(data.shippingDate)})</span>
            </S.Date>
            <S.TotalAmount>
              {data.totalAmount}
              <span>個</span>
            </S.TotalAmount>
          </S.DateAndTotalAmount>
        ))}
    </S.Wrapper>
  );
};
export default AlertPickingPastShipDateModal;
