import styled from 'styled-components';
interface IProps {
  open?: boolean;
}

export const Wrapper = styled.div`
  background-color: ${(props) => props.theme.palette.sandGray};

  width: 100vw;
  height: 15%;

  padding: 1.5rem 1.5rem;

  display: ${(props: IProps) => (props.open ? 'block' : 'none')};
  top: ${(props: IProps) => (props.open ? '80px' : '100%')};
  position: fixed;
  transition: all 0.5s ease-out;
  z-index: 10000;
  overflow-x: hidden;
  width: 500px;
  padding: 8px 8px 0px 8px;
  background: ${(props) => props.theme.palette.beige};
  box-shadow: 0px 12px 17px rgba(0, 0, 0, 0.07),
    0px 5px 22px rgba(0, 0, 0, 0.06), 0px 7px 8px rgba(0, 0, 0, 0.1);
  position: absolute;
  z-index: 9998;
  gap: 12px;
  border-radius: 4px;
  top: 145px;
  right: 369px;
`;

export const SelectWrapper = styled.div`
  display: flex;
  justify-content: space-between;
`;

export const Header = styled.div`
  display: flex;
  justify-content: space-between;
  /* background-color: ${(props) => props.theme.palette.white}; */
  border-radius: 16px 16px 0 0;
  margin-bottom: 0.75rem;
`;

export const HeaderTitle = styled.div`
  display: flex;
`;

export const HeaderTitleText = styled.div`
  font-weight: 500;
  font-size: 1rem;
  line-height: 1.4375rem;
  margin-left: 0.5625rem;
  color: ${(props) => props.theme.palette.khaki};
`;
