import { useState, useRef, useEffect } from 'react';
import moment from 'moment';
import { ReactComponent as OkIcon } from '@assets/icons/ok.svg';
import { Button } from '@components/elements/buttons/Button';
import type { EmployeeData, Line, Todo } from '@lib/common/type';
import EmployeeModal from '@components/modules/common/EmployeeModal/EmployeeModal';
import Overlay from '@components/elements/backdrops/Overlay/Overlay';
import * as S from './StackerDetail.styles';

import { calcTotalFunc } from '@lib/pc/stacker/functions';

const TABLE_HEADER = [
  { key: '順', align: 'left', width: '3px' },
  {
    key: '製品名\n完成品 包装数量',
    align: 'left',
    width: '136px',
    wordBreak: 'keep-all',
    colSpan: 3,
    whiteSpace: true,
  },
  {
    key: 'ケースの種類',
    align: 'left',
    width: '100px',
    wordBreak: 'keep-all',
    colSpan: 1,
  },

  {
    key: `賞味期限\n印字確認`,
    align: 'left',
    width: '87px',
    whiteSpace: true,
  },
  { key: '時刻', align: 'left', width: '87px' },
  {
    key: '完成品\nスタッカー数量',
    align: 'left',
    width: '9.375rem',
    colSpan: 4,
    whiteSpace: true,
  },
  { key: '', align: 'left', width: '64px' },
] as const;

const PACKAGING_BG_COLOR = 'rgba(187, 187, 187, 0.1)';
const NUMBER_REGEX = /^[0-9\b]+$/;

type Props = {
  line: Line;
  editData: Todo;
  handleEditSubmit: (editData: Todo) => void;
  tabColor: number;
  date: Date;
  setPopUp: (popUp: boolean) => void;
};

const StackerDetail = ({
  editData,
  handleEditSubmit,
  date,
  setPopUp,
}: Props) => {
  const [newData, setNewData] = useState(editData);
  const [edited, setEdited] = useState(false);
  const [employeeModalActive, setEmployeeModalActive] = useState(false);
  // 担当者modalでuseEffect発火させるためのcount
  const [count, setCount] = useState(0);
  const [errModal, setErrModal] = useState(false);
  // 賞味期限日を変更有無
  const [changedBestBeforeDate, setChangedBestBeforeDate] = useState(false);
  // 入力完了判定
  const [completed, setCompleted] = useState(false);

  const startedTimeRef = useRef<HTMLInputElement>(null);
  const completedTimeRef = useRef<HTMLInputElement>(null);
  const bestBeforeDateRef = useRef<HTMLInputElement>(null);

  const changeData = (name: string, value: string | number) => {
    setEdited(true);
    setNewData({ ...newData, [name]: value });

    if (name === 'bestBeforeDate') setChangedBestBeforeDate(true);
  };

  const handleChange = (
    e:
      | React.ChangeEvent<HTMLInputElement>
      | React.ChangeEvent<HTMLTextAreaElement>
  ) => {
    changeData(e.target.name, e.target.value);
    setPopUp(false);
  };

  const handleClickCancel = () => {
    setNewData(editData);
    setEdited(false);
  };

  const handleClickEmployee = () => {
    setCount(count + 1);
    setEmployeeModalActive(true);
    setEdited(true);
    setPopUp(false);
  };

  const handleChangeEmployee = (employeesInfo: EmployeeData[]) => {
    setNewData({
      ...newData,
      employeeIds: employeesInfo.map((e) => e.id),
      stackingResult: {
        ...newData.stackingResult,
        employeesInfo,
      },
    });
  };

  // 完了数比較チェック
  const handleCheckCompletedAmount = (newData: Todo) => {
    //  スタッカー完了個数に変更があったときのみ設定される。(newData.completedQuantity)
    if (
      (newData.completedQuantity &&
        !NUMBER_REGEX.test(newData.completedQuantity)) ||
      (newData.completedQuantity &&
        newData.completedQuantity.toString().length > 10)
    ) {
      setErrModal(true);
    } else {
      handleEditSubmit(newData);
      setEdited(false);
    }
  };

  const changeTotal = (name: string, value: string | number) => {
    setNewData((prevData: Todo) => {
      const oldData = prevData;
      const newData = { ...oldData, [name]: Number(value) };
      const calcValue = calcTotalFunc(newData, newData.productPiecesPerBox);
      prevData = { ...newData, completedQuantity: calcValue };
      return prevData;
    });
  };

  const changeStackerCount = (name: string, value: string | number) => {
    changeData(name, value);
    changeTotal(name, value);
  };

  const onChangeStackerCount = (e: React.ChangeEvent<HTMLInputElement>) =>
    changeStackerCount(e.target.name, e.target.value);

  const onClickStackerCountChangeButton = (name: string, delta: number) => {
    let prevValue;
    if (newData[name] !== undefined) {
      prevValue = newData[name];
    } else if (newData.stackingResult?.[name] !== undefined) {
      prevValue = newData.stackingResult?.[name];
    } else {
      prevValue = 0;
    }
    const value = prevValue + delta;
    changeStackerCount(name, value);
    setPopUp(false);
  };

  // これがないとnewDataに設定されない。
  useEffect(() => {
    // 初期表示時のクリアボタンを有効にするための設定
    setTimeout(() => {
      if (startedTimeRef.current) startedTimeRef.current.defaultValue = '';
      if (completedTimeRef.current) completedTimeRef.current.defaultValue = '';
      if (bestBeforeDateRef.current)
        bestBeforeDateRef.current.defaultValue = '';
    }, 100);
  }, [editData]);

  const cloneDate = (dateObject: Date) => {
    return new Date(dateObject.valueOf());
  };

  // 賞味期限日の初期設定＆チェック完了表示のためのデータ設定
  useEffect(() => {
    // チェック完了表示のためのデータ設定
    if (!edited) {
      setNewData(editData);
    }
    const calcDate = cloneDate(date);
    if (editData?.productExpiryDays) {
      calcDate.setDate(
        calcDate.getDate() + Number(editData?.productExpiryDays)
      );
    }
    if (changedBestBeforeDate === false && editData?.stackingResult === null) {
      // stackingResultがない場合----------------------------
      setNewData({
        ...editData,
        bestBeforeDate: moment(calcDate).format('YYYY-MM-DD'),
      });
    } else if (
      (changedBestBeforeDate === false &&
        newData?.stackingResult &&
        newData?.stackingResult?.bestBeforeDate === undefined) ||
      (changedBestBeforeDate === false &&
        newData?.stackingResult &&
        newData?.stackingResult?.bestBeforeDate === null)
    ) {
      // stackingResultがある場合----------------------------
      setNewData({
        ...editData,
        bestBeforeDate: moment(calcDate).format('YYYY-MM-DD'),
      });
    }
  }, [editData?.stackingResult, editData?.productExpiryDays]);

  useEffect(() => {
    if (
      newData?.stackingResult?.bestBeforeDate &&
      newData?.stackingResult?.employeesInfo &&
      newData?.stackingResult?.startedTime &&
      newData?.stackingResult?.completedTime &&
      newData?.stackingResult?.completedQuantity
    ) {
      setCompleted(true);
    } else {
      setCompleted(false);
    }
  }, [newData]);

  if (!newData) return null;

  const SaveCancelButton = () => (
    <>
      <Button
        onClick={() => handleCheckCompletedAmount(newData)}
        width="100%"
        disabled={!edited}
      >
        保存
      </Button>
      <S.CancelButtonWrapper>
        <Button
          borderWidth={1}
          outlined
          onClick={handleClickCancel}
          width="100%"
          disabled={!edited}
        >
          ｷｬﾝｾﾙ
        </Button>
      </S.CancelButtonWrapper>
    </>
  );

  return (
    <>
      <>
        <tbody>
          {/***** 上段header *****/}
          {newData.order === 1 && (
            <S.THead>
              {TABLE_HEADER.map((t, i) => (
                <S.THeadCell {...t} key={i}>
                  {t.key}
                </S.THeadCell>
              ))}
            </S.THead>
          )}
          {/***** 上段 *****/}
          <S.TopRow>
            {/* 順 */}
            <S.TCellOrder marginRight={'28px'} rowSpan={4}>
              {newData.order}
            </S.TCellOrder>
            {/* 製品名 */}
            <S.TCell
              width={'68px'}
              height="100px"
              marginRight={'12px'}
              background={PACKAGING_BG_COLOR}
              colSpan={3}
            >
              {editData?.productCode}:
              {editData?.productNameAbbreviation
                ? editData?.productNameAbbreviation
                : editData?.productName}
            </S.TCell>
            {/* ケースの種類 */}
            <S.TCell
              width={'68px'}
              height="100px"
              marginRight={'12px'}
              background={PACKAGING_BG_COLOR}
              colSpan={1}
            >
              {editData?.caseName}
            </S.TCell>
            {/* 賞味期限（包装） */}
            <S.TCell width={'3.5rem'} background={PACKAGING_BG_COLOR}>
              <S.PackagingDateLabel>包装</S.PackagingDateLabel>
              <S.PackagingDate>
                {editData?.packagingBestBeforeDate &&
                  moment(editData?.packagingBestBeforeDate).format('YY.MM.DD')}
              </S.PackagingDate>
            </S.TCell>

            {/* ここからinput */}
            {/* 開始時刻（スタッカー） */}
            <S.TCell width={'87px'} background={'#F8F7F4'} fontSize={'14px'}>
              <S.FormLabel>開始</S.FormLabel>
              <S.TimeInput
                ref={startedTimeRef}
                type="time"
                name="startedTime"
                id="startedTime"
                width="5.5rem"
                max="28:59"
                value={
                  newData.startedTime
                    ? newData.startedTime
                    : newData.startedTime === ''
                    ? ''
                    : (newData.stackingResult?.startedTime &&
                        moment(newData.stackingResult?.startedTime).format(
                          'LT'
                        )) ||
                      ''
                }
                onChange={handleChange}
                autoComplete="off"
              />
            </S.TCell>
            {/* スタッカー担当者 */}
            <S.TCell
              width={'9.375rem'}
              background={'#F8F7F4'}
              fontSize={'14px'}
              spanRight={'0.5rem'}
              colSpan={4}
              onClick={handleClickEmployee}
            >
              <S.FormLabel>スタッカー担当者</S.FormLabel>
              <S.StackerEmployeeWrapper>
                {newData.stackingResult?.employeesInfo &&
                  newData.stackingResult.employeesInfo.map(
                    (e: { code: string; name: string }, i: number) => {
                      return (
                        <div key={i}>
                          {e.code}
                          {e.name && ':'}
                          {e.name}
                        </div>
                      );
                    }
                  )}
              </S.StackerEmployeeWrapper>
            </S.TCell>
            {/* 保存・キャンセル・入力完了 */}
            <S.TCell className="delete-btn" rowSpan={4}>
              {edited ? (
                <SaveCancelButton />
              ) : completed ? (
                <S.CompletedWrapper>
                  <OkIcon />
                </S.CompletedWrapper>
              ) : (
                <SaveCancelButton />
              )}
            </S.TCell>
          </S.TopRow>
          {/***** 二段目 *****/}
          <S.TRow>
            {/* 完成品 包装予定数量 台車 */}
            <S.TCell className="label" background={PACKAGING_BG_COLOR}>
              予<br />定
            </S.TCell>
            <S.TCell
              className="display-quantity display-quantity__count--case"
              background={PACKAGING_BG_COLOR}
            >
              {/* 完成品 包装予定数量 ケース */}
              <div className="display-quantity__inner-wrap">
                <div className="cell-content">
                  <div className="quantity">
                    <span className="num">
                      {editData?.packagingPlannedCase}
                    </span>
                    <span className="unit">ケース</span>
                  </div>
                  <div className="explanation">
                    x{editData?.productPiecesPerBox}
                    <span className="unit">個</span>
                  </div>
                </div>
                <div className="symbol">+</div>
              </div>
            </S.TCell>
            <S.TCell
              className="display-quantity display-quantity__count--quantity"
              background={PACKAGING_BG_COLOR}
            >
              {/* 完成品 包装予定数量  個*/}
              <div className="display-quantity__inner-wrap">
                <div className="cell-content">
                  <div className="quantity">
                    <span className="num">
                      {editData?.packagingPlannedBara}
                    </span>
                    <span className="unit">個</span>
                  </div>
                </div>
                <div className="symbol">=</div>
              </div>
            </S.TCell>
            <S.TCell
              className="display-quantity display-quantity__count--total"
              background={PACKAGING_BG_COLOR}
            >
              {/* 完成品 包装予定数量 合計 */}
              <div className="display-quantity__inner-wrap">
                <div className="with-label__inner-wrap">
                  <div className="cell-content">
                    <div className="quantity">
                      <span className="title">合計</span>
                      <span className="num">
                        {editData?.packagingPlannedQuantity}
                      </span>
                      <span className="unit">個</span>
                    </div>
                  </div>
                </div>
              </div>
            </S.TCell>
            {/* 賞味期限（スタッカー） */}
            <S.TCell width={'3.5rem'} fontSize={'14px'} rowSpan={2}>
              <S.FormLabel>スタッカー</S.FormLabel>
              <S.Input
                className="date"
                ref={bestBeforeDateRef}
                type="date"
                name="bestBeforeDate"
                id="bestBeforeDate"
                width="5.5rem"
                value={
                  newData.bestBeforeDate
                    ? newData.bestBeforeDate
                    : newData.bestBeforeDate === ''
                    ? ''
                    : newData.stackingResult === ''
                    ? ''
                    : newData.stackingResult?.bestBeforeDate || ''
                }
                onChange={handleChange}
                onClick={() => setPopUp(false)}
                pattern="\d{4}-\d{2}-\d{2}"
                autoComplete="off"
                max="9999-12-31"
              />
            </S.TCell>
            {/* 終了時刻（スタッカー） */}
            <S.TCell width={'87px'} background={'#F8F7F4'} rowSpan={2}>
              <S.FormLabelSmall>終了</S.FormLabelSmall>
              <S.TimeInput
                ref={completedTimeRef}
                type="time"
                name="completedTime"
                id="completedTime"
                max="28:59"
                value={
                  newData.completedTime
                    ? newData.completedTime
                    : newData.completedTime === ''
                    ? ''
                    : (newData.stackingResult?.completedTime &&
                        moment(newData.stackingResult?.completedTime).format(
                          'LT'
                        )) ||
                      ''
                }
                onChange={handleChange}
                autoComplete="off"
              />
            </S.TCell>
            <S.TCell width="6rem" rowSpan={2}>
              <S.FormLabelSmall>ケース</S.FormLabelSmall>
              <S.CompletedAmountInputWrapper>
                <S.AmountInputWrapper>
                  <S.CompletedAmountInput
                    type="number"
                    inputMode="numeric"
                    name="completedCase"
                    id="completedCase"
                    value={
                      (newData.completedCase ??
                        newData.stackingResult?.completedCase) ||
                      ''
                    }
                    onChange={onChangeStackerCount}
                    autoComplete="off"
                  />
                  <S.InputUnit>x{newData.productPiecesPerBox}個</S.InputUnit>
                </S.AmountInputWrapper>
                <S.ButtonGroup>
                  <S.Button
                    onClick={() =>
                      onClickStackerCountChangeButton('completedCase', 1)
                    }
                  >
                    ＋
                  </S.Button>
                  <S.Button
                    onClick={() =>
                      onClickStackerCountChangeButton('completedCase', -1)
                    }
                    disabled={
                      (newData?.completedCase === undefined &&
                        newData?.stackingResult === null) ||
                      newData?.completedCase === 0
                    }
                  >
                    ー
                  </S.Button>
                </S.ButtonGroup>
                <S.ArithmeticSymbol>+</S.ArithmeticSymbol>
              </S.CompletedAmountInputWrapper>
            </S.TCell>
            {/* 個 */}
            {/* スタッカー 個 入力 ---------------------------------------*/}
            <S.TCell
              rowSpan={2}
              width={'6rem'}
              background={'#F8F7F4'}
              spanRight={'0.5rem'}
            >
              <S.FormLabelSmall>個</S.FormLabelSmall>
              <S.CompletedAmountInputWrapper>
                <S.CompletedAmountInput
                  type="number"
                  inputMode="numeric"
                  name="completedBara"
                  id="completedBara"
                  value={
                    (newData.completedBara ??
                      newData.stackingResult?.completedBara) ||
                    ''
                  }
                  onChange={onChangeStackerCount}
                  autoComplete="off"
                />
                <S.ButtonGroup>
                  <S.Button
                    onClick={() =>
                      onClickStackerCountChangeButton('completedBara', 1)
                    }
                  >
                    ＋
                  </S.Button>
                  <S.Button
                    onClick={() =>
                      onClickStackerCountChangeButton('completedBara', -1)
                    }
                    disabled={
                      (newData?.completedBara === undefined &&
                        newData?.stackingResult === null) ||
                      newData?.completedBara === 0
                    }
                  >
                    ー
                  </S.Button>
                </S.ButtonGroup>
                <S.ArithmeticSymbol>=</S.ArithmeticSymbol>
              </S.CompletedAmountInputWrapper>
            </S.TCell>
            {/* スタッカー 合計 ---------------------------------------*/}
            <S.TCell
              className="display-quantity--total"
              rowSpan={2}
              width={'6rem'}
            >
              <S.FormLabelSmall>合計</S.FormLabelSmall>
              <div className="inner">
                {(newData.completedQuantity ??
                  newData.stackingResult?.completedQuantity) ||
                  ''}
                <span className="unit">個</span>
              </div>
            </S.TCell>
          </S.TRow>

          {/***** 三段目 *****/}
          <S.TRow>
            <S.TCell className="label" background={PACKAGING_BG_COLOR}>
              完<br />了
            </S.TCell>
            <S.TCell
              className="display-quantity display-quantity__count--case"
              background={PACKAGING_BG_COLOR}
            >
              {/* 完成品 包装完了 ケース */}
              <div className="display-quantity__inner-wrap">
                <div className="cell-content">
                  <div className="quantity">
                    <span className="num">
                      {editData?.packagingCompletedCase}
                    </span>
                    <span className="unit">ケース</span>
                  </div>
                  <div className="explanation">
                    x{editData?.productPiecesPerBox}
                    <span className="unit">個</span>
                  </div>
                </div>
                <div className="symbol">+</div>
              </div>
            </S.TCell>
            <S.TCell
              className="display-quantity display-quantity__count--quantity"
              background={PACKAGING_BG_COLOR}
            >
              {/* 完成品 包装完了 個 */}
              <div className="display-quantity__inner-wrap">
                <div className="cell-content">
                  <div className="quantity">
                    <span className="num">
                      {editData?.packagingCompletedBara}
                    </span>
                    <span className="unit">個</span>
                  </div>
                </div>
                <div className="symbol">=</div>
              </div>
            </S.TCell>
            <S.TCell
              className="display-quantity display-quantity__count--total"
              background={PACKAGING_BG_COLOR}
            >
              {/* 完成品 包装予定数量 */}
              <div className="display-quantity__inner-wrap">
                <div className="with-label__inner-wrap">
                  <div className="cell-content">
                    <div className="quantity">
                      <span className="title">合計</span>
                      <span className="num">
                        {editData?.packagingCompletedQuantity}
                      </span>
                      <span className="unit">個</span>
                    </div>
                  </div>
                </div>
              </div>
            </S.TCell>
          </S.TRow>
          {/* 備考 */}
          <S.TRow>
            <S.TCell className="label">
              備<br />考
            </S.TCell>
            <S.TCell className="note" colSpan={9}>
              <S.TextareaStackingComment
                name="comment"
                id="comment"
                value={
                  (newData.comment ?? newData.stackingResult?.comment) || ''
                }
                onChange={handleChange}
                autoComplete="off"
              />
            </S.TCell>
          </S.TRow>
        </tbody>
      </>
      {employeeModalActive && (
        <>
          <EmployeeModal
            employeeRole="stackings"
            employeesAtEdit={newData.stackingResult?.employeesInfo || []}
            setEmployeesAtEdit={handleChangeEmployee}
            EmployeeModalActive
            setEmployeeModalActive={setEmployeeModalActive}
            openFromTop={true}
          />
          <Overlay handleClick={() => setEmployeeModalActive(false)} />
        </>
      )}
      {errModal && (
        <>
          <S.ErrModalWrapper>
            <S.ErrModalTextContainer>
              <span>
                スタッカー完了数量は半角数字、10桁以内で入力してください。
              </span>
            </S.ErrModalTextContainer>
            <S.ErrModalButtonContainer>
              <Button padding="md" outlined onClick={() => setErrModal(false)}>
                OK
              </Button>
            </S.ErrModalButtonContainer>
          </S.ErrModalWrapper>
          <Overlay zIndex={10000} dark />
        </>
      )}
    </>
  );
};

export default StackerDetail;
