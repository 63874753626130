import styled from 'styled-components';

export const Wrapper = styled.div`
  width: 100vw;
  height: 100%;
  top: 0;
  position: fixed;
  background-color: #f8f7f4;
  overflow-x: hidden;
  z-index: 2;
`;

export const HeadContainer = styled.div`
  padding: 2rem 2.5rem;
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

export const ModalHeadCloseIcon = styled.div`
  cursor: pointer;
  padding: 4px;

  > svg {
    width: 24px;
    height: 24px;
  }
`;

export const Input = styled.input<{
  align?: string;
  padding?: string;
  height?: string;
}>`
  width: 320px;
  height: 44px;
  color: ${(props) => props.theme.palette.baseBlack};
  font-size: 1rem;
  padding: ${(props) => (props.padding ? props.padding : '0.325rem')};
  text-align: ${(props) => props.align};
  border: none;
  border-radius: 0.5rem;
  border: 1px solid rgba(0, 0, 0, 0.08);
  height: ${(props) => (props.height ? props.height : '')};
  background: ${(props) => props.theme.palette.white};
  cursor: pointer;
  ::-webkit-inner-spin-button {
    -webkit-appearance: none;
  }
  &.search-input {
    line-height: 1rem;
    padding: 0.625rem;
    padding-left: 3rem;
  }
  .name {
    padding: 6px 10px;
  }
`;

export const CaseAddWrapper = styled.div`
  display: flex;
  /* border-bottom: solid 1px #e5e1dd; */
  /* 各項目 */
  .add-case-name {
    display: flex;
    dt {
      margin-bottom: 8px;
      margin-right: 8px;
      width: 130px;
      display: flex;
      align-items: center;
    }
    dd {
      display: flex;
    }

    .product-info {
      margin-right: 32px;
      .finished-or-semifinished {
        display: flex;
        justify-content: center;
        align-items: center;
        height: 40px;
      }
    }
  }
`;

export const CaseAddAndSearchArea = styled.div`
  display: flex;
  padding: 0 40px;
`;

export const ButtonContainer = styled.div`
  & > button {
    width: 136px;
    margin-left: 1rem;
    &.disabled {
      background: #e0e0e0;
      border-color: #e0e0e0;
      color: #6b675c;
      cursor: initial;
    }
  }
`;

export const InputWrapper = styled.div`
  position: relative;
  display: flex;
  width: 100%;
`;

export const Icon = styled.div`
  color: ${(props) => props.theme.palette.khaki};
  padding-left: 0.6rem;
  position: absolute;
  top: 0.5rem;
  display: flex;
  align-items: center;
  svg {
    width: 1.5rem;
    height: 1.5rem;
  }
`;

/**
 * 各タグの情報
 */
export const CaseSettingsContents = styled.div``;

export const CaseContentsTitle = styled.div`
  padding: 22px 50px;
  border-bottom: solid 1px #e5e1dd;
`;

export const CaseContentsWrapper = styled.div`
  max-width: 100vw;
  max-height: 100vh;
  height: 100vh;
  overflow: scroll;
  position: relative;
  padding-bottom: 100px;
`;

export const CaseRow = styled.div`
  display: flex;
  position: relative;
  min-height: 54px;
  align-items: center;
  border-bottom: solid 1px #e5e1dd;
  background: ${(props) => props.theme.palette.white};
  &.header {
    background-color: #f8f7f4;
  }
  .edit {
    width: 52px;
    padding: 0 20px;
    min-height: 44px;
    display: flex;
    align-items: center;
    cursor: pointer;
  }
  .not-edit {
    width: 52px;
    padding: 0 20px;
    min-height: 44px;
    display: flex;
    align-items: center;
  }
  button {
    width: 136px;
  }
`;

export const DisableButton = styled.button`
  cursor: auto;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0.75rem 0;
  gap: 10px;
  background: #e0e0e0;
  color: #6b675c;
  border: 2px solid #e0e0e0;
  font-weight: bold;
  font-size: 16px;
  text-align: center;
  border-radius: 8px;
  border-width: 2px;
`;
