import { axiosInstance } from '@lib/pc/common/api/axiosConfig';
import { useQuery } from 'react-query';
import humps from 'humps';
import type { SalesStore } from '@lib/common/type';

const useSalesStores = () => {
  return useQuery('sales_stores', async () => {
    const { data } = await axiosInstance().get(`/api/v1/sales_stores`);
    return humps.camelizeKeys(data.sales_stores) as SalesStore[];
  });
};

export default useSalesStores;
