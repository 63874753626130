import { FC } from 'react';
import * as S from './CustomNumPad.styles';
import NumPad from 'react-numpad';
import type { Todo } from '@lib/common/type';

type CustomNumPadProps = {
  name: string;
  value: string;
  top?: string;
  right?: string;
  width?: string;
  height?: string;
  flexEnd?: boolean;
  onChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
};

const CustomNumPad: FC<CustomNumPadProps> = ({
  name,
  value,
  top,
  right,
  width,
  height,
  flexEnd,
  onChange,
}) => {
  const handleChange = (value: string) => {
    const event = {
      target: {
        name,
        value,
      },
    } as React.ChangeEvent<HTMLInputElement>;
    onChange(event);
  };
  const myTheme = {
    global: {
      fontFamily: 'Noto Sans JP',
    },
    header: {
      primaryColor: '#263238',
      secondaryColor: '#f9f9f9',
      highlightColor: '#FFC107',
      backgroundColor: '#2ba9d1',
    },
    body: {
      primaryColor: '#263238',
      secondaryColor: '#32a5f2',
      highlightColor: '#FFC107',
      backgroundColor: '#f9f9f9',
    },
    panel: {
      backgroundColor: '#CFD8DC',
    },
  };

  // https://github.com/gpietro/react-numpad/blob/9cb1f62378d865f8c856472bacfe1f07d324977b/lib/components/Number.js#L51
  const keyValid =
    (isValid: Todo) =>
    (value = '', key: Todo) => {
      if (key === '-') {
        return value.charAt(0) === '-' || isValid(key + value);
      }
      // 新しい入力が0文字の場合、trueを返す
      if (value + key === '0') {
        return true;
      }

      return isValid(key === '.' ? value + key + 1 : value + key);
    };

  return (
    <div>
      <NumPad.Number
        theme={myTheme}
        // label=''
        value={value}
        onChange={handleChange}
        decimal={false}
        negative={false}
        // [center, flex-start, flex-end, startBottomLeft, startBottomRight, startTopLeft, startTopRight]
        position={flexEnd ? 'flex-end' : 'startBottomLeft'}
        validation={keyValid}
        sync={true}
      >
        <S.Wrapper>
          <S.WrapperButton
            top={top}
            width={width}
            right={right}
            height={height}
          ></S.WrapperButton>
        </S.Wrapper>
      </NumPad.Number>
    </div>
  );
};

export default CustomNumPad;
