import { useMutation } from 'react-query';
import humps from 'humps';
import { axiosInstance } from '@lib/pc/common/api/axiosConfig';
import { AxiosError } from 'axios';
import { ErrorResponse, Todo } from '@lib/common/type';

const useUpdateSalesStore = (
  salesStoreId: number | null,
  handleSuccess: () => void,
  setErrMsg: (errMsg: string) => void
) => {
  return useMutation(
    async (params: Todo) => {
      console.log('---更新箇所 params 1109--', params);
      return await axiosInstance().patch(
        `/api/v1/settings/sales_stores/${salesStoreId}`,
        humps.decamelizeKeys(params)
      );
    },
    {
      onSuccess: () => {
        handleSuccess?.();
      },
      onError: (error: AxiosError<ErrorResponse>) => {
        const message = error.response?.data.message || 'エラーが発生しました';
        setErrMsg(message);
      },
    }
  );
};

export default useUpdateSalesStore;
