import { axiosInstance } from '@lib/pc/common/api/axiosConfig';
import { useQuery } from 'react-query';
import humps from 'humps';
import type { FactoryLine } from '@lib/common/type';

const useFactoryLines = () => {
  return useQuery('factory_lines', async () => {
    const { data } = await axiosInstance().get(
      '/api/v1/settings/factory_lines'
    );
    return humps.camelizeKeys(data.factory_lines) as FactoryLine[];
  });
};

export default useFactoryLines;
