import styled from 'styled-components';

export const Wrapper = styled.div`
  padding: 0 0.875rem 1.75rem 1rem;
`;

export const HeadContainer = styled.div`
  position: relative;
  margin-bottom: 8px;
`;

export const InputContainer = styled.div`
  display: flex;
  flex-direction: column-reverse;
`;

export const StoreroomWrapper = styled.div`
  display: flex;
  position: relative;
  text-align: center;
  justify-content: space-between;
  padding: 16px 0 8px 0;
  font-weight: 700;
`;

export const StoreroomTotalWrapper = styled.div`
  display: flex;
  position: relative;
  text-align: center;
  justify-content: space-between;
  padding: 16px 0;
  font-weight: 700;
`;

export const DateWrapper = styled.div<{
  isFirst: boolean;
}>`
  display: flex;
  position: relative;
  text-align: center;
  justify-content: space-between;
  padding: 16px 0 8px 0;
  border-top: ${(props) => (props.isFirst ? '1px solid #d3d3d3' : '')};
`;

export const ProductionOrShippingDateLabel = styled.label`
  display: block;
  font-size: 15px;
  display: flex;
  align-items: center;
  font-weight: 700;
`;

export const DRightBottomWrapper = styled.div`
  display: flex;
  align-items: center;
  padding-bottom: 0.5rem;
  padding: 16px 0;
  justify-content: space-between;
  border-bottom: 1px solid #d3d3d3;
`;

export const DInputContainer = styled.div<{
  center?: boolean;
}>`
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 6px;
  > label {
    font-weight: 700;
    font-size: 13px;
  }
  > input {
    height: 2.75rem;
    max-width: 6.75rem;
    width: 100%;
    border-radius: 8px;
    text-align: ${(props) => (props.center ? 'center' : 'right')};
    padding-right: 12px;
    -webkit-text-fill-color: ${(props) => props.theme.palette.baseBlack};
    opacity: 1;

    :focus {
      outline: none;
    }

    :disabled {
      border: 2px solid #ffffff;
      background-color: transparent;
    }
  }

  > span {
    position: absolute;
    right: 0.3rem;
    top: 75%;
    font-size: 12px;
    margin-left: 4px;
    color: #6b675c;
  }
`;

export const DSymbol = styled.div`
  margin-top: 1rem;
  padding: 0 5px;
  font-weight: 700;
  font-size: 13px;
`;
