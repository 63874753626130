import { useEffect, useState } from 'react';
import * as S from './ShippingDestinationContents.styles';
import ChevronLeft from '@assets/icons/chevron_left';
import { ReactComponent as CircleCloseIcon } from '@assets/icons/circle_close.svg';
import { ShippingGroup, Todo } from '@lib/common/type';
import { Button } from '@components/elements/buttons/Button';
import DeleteConfirmModal from '@components/modules/common/DeleteConfirmModal/DeleteConfirmModal';
import ErrorMsgPopUp from '@components/modules/common/ErrorMsgPopUp/ErrorMsgPopUp';
import Overlay from '@components/elements/backdrops/Overlay/Overlay';
import ShippingDestinationSelect from '@components/modules/pc/shipping_status/ShippingDestinationSelect/ShippingDestinationSelect';
import useUpdateShippingGroupAndShippingDestination from '@lib/pc/settings/shipping_group_master_edit/useUpdateShippingGroupAndShippingDestination';
import { useLocation } from 'react-router-dom';
import { ShippingDestination } from '@lib/pc/settings/shipping_group_master_edit/type';
import { ShippingGroupUpdateData } from '@lib/pc/settings/shipping_group_setting/type';

type Props = {
  newData: ShippingGroupUpdateData;
  shippingDestinations: ShippingDestination[];
  refetch: () => void;
  onClickGoBack: () => void;
  setIsChanged: (isChanged: boolean) => void;
  enableToSave: boolean;
  setEnableToSave: (enableToSave: boolean) => void;
  setUpdatePopUp: (updatePopUp: boolean) => void;
};

const ShippingDestinationContents = ({
  newData,
  shippingDestinations,
  refetch,
  onClickGoBack,
  setIsChanged,
  enableToSave,
  setEnableToSave,
  setUpdatePopUp,
}: Props) => {
  const { state } = useLocation<ShippingGroup>();

  const registeredIds =
    shippingDestinations &&
    shippingDestinations?.map((item: ShippingDestination) => item['id']);

  // 削除確認メッセージ
  const [confirmMsg, setConfirmMsg] = useState('');
  const [errMsg, setErrMsg] = useState('');
  // 削除No
  const [deleteNo, setDeleteNo] = useState(0);
  const [updating, setUpdating] = useState(false);

  // selectの作成
  const [selectedId, setSelectedId] = useState(0);
  const [selectedName, setSelectedName] = useState('');
  const [selectedCode, setSelectedCode] = useState('');

  const [newShippingDestinations, setNewShippingDestinations] =
    useState<ShippingDestination[]>(shippingDestinations);

  const [selectedIds, setSelectedIds] = useState<number[]>(registeredIds);

  // 追加ボタン
  const onClickAddButton = () => {
    newShippingDestinations.push({
      id: selectedId,
      name: selectedName,
      code: selectedCode,
    });
    setIsChanged(true);
    setSelectedId(0);
    setEnableToSave(true);
    const selectedShippingDestinationIds =
      newShippingDestinations &&
      newShippingDestinations?.map((item: ShippingDestination) => item['id']);
    setSelectedIds(selectedShippingDestinationIds);
  };

  const handleUpdateSuccess = () => {
    refetch();
    setUpdating(false);
    setUpdatePopUp(true);
    setIsChanged(false);
    setEnableToSave(false);
  };

  const updateShippingGroupAndShippingDestination =
    useUpdateShippingGroupAndShippingDestination(
      state.id,
      handleUpdateSuccess,
      setErrMsg
    );

  // ***********************
  // [保存ボタン]押下、更新処理
  // ***********************
  const handleSubmit = () => {
    setUpdating(true);
    const newShippingDestinationsIds = newShippingDestinations.map(
      (item: ShippingDestination) => item['id']
    );
    updateShippingGroupAndShippingDestination.mutate(
      newShippingDestinationsIds
    );
  };

  // 削除ボタン
  const onClickDelButton = (no: number, name: string) => {
    setConfirmMsg(`${name}を本当に削除しますか？`);
    setDeleteNo(no);
  };

  const deleteFunction = () => {
    setIsChanged(true);
    newShippingDestinations.splice(deleteNo, 1);
    setNewShippingDestinations([...newShippingDestinations]);
    setConfirmMsg('');
    setEnableToSave(true);
  };

  const handleSelectShippingDestination = (e: number) => {
    setSelectedId(e);
  };

  useEffect(() => {
    setNewShippingDestinations(shippingDestinations);
  }, [shippingDestinations?.length]);

  return (
    <>
      <S.Wrapper>
        <S.GoBackWrapper onClick={onClickGoBack}>
          <ChevronLeft isBlue={true} />
          <span>一覧画面へ戻る</span>
        </S.GoBackWrapper>
        <S.GroupCodeWrapper>
          <span>出荷先グループコード</span> {newData?.code}
        </S.GroupCodeWrapper>
        <S.NameWrapper>
          <span>出荷先グループ名</span> {newData?.name}
        </S.NameWrapper>
        <S.ShippingDestinationTitle>出荷先</S.ShippingDestinationTitle>
        <S.ShippingDestinationSelectRow>
          <S.ShippingDestinationSelectWrapper>
            <ShippingDestinationSelect
              handleChangeShipping={handleSelectShippingDestination}
              setPopUpShippingName={setSelectedName}
              setSelectedCode={setSelectedCode}
              selectedShippingDestinationIds={selectedIds}
              selectedId={selectedId}
              useCacheUniqs
              height="41px"
              menuTop="34px"
              searchable={true}
            />
          </S.ShippingDestinationSelectWrapper>
          <div>
            <S.AddButton>
              {selectedId ? (
                <Button children={'追加'} onClick={() => onClickAddButton()} />
              ) : (
                <Button disabled children={'追加'} />
              )}
            </S.AddButton>
          </div>
        </S.ShippingDestinationSelectRow>

        <S.ShippingDestinationsWrapper>
          {newShippingDestinations &&
            newShippingDestinations.map((destination: Todo, index: number) => {
              return (
                // 1行
                <S.ShippingDestination key={index}>
                  {/* 削除ボタン */}
                  <S.DeleteButton>
                    <S.CloseButton
                      onClick={() => onClickDelButton(index, destination.name)}
                    >
                      <CircleCloseIcon />
                    </S.CloseButton>
                  </S.DeleteButton>
                  <S.CodeNameWrapper>
                    <S.CodeWrapper>{destination.code}</S.CodeWrapper>
                    {destination.name}
                  </S.CodeNameWrapper>
                </S.ShippingDestination>
              );
            })}
        </S.ShippingDestinationsWrapper>
      </S.Wrapper>
      <S.ButtonContainer>
        {enableToSave ? (
          <Button
            children={updating ? '保存中...' : '保存'}
            onClick={handleSubmit}
          />
        ) : (
          <Button disabled children={'保存'} />
        )}
      </S.ButtonContainer>
      {confirmMsg && (
        <DeleteConfirmModal
          confirmMsg={confirmMsg}
          onClickCancel={() => setConfirmMsg('')}
          onClickDelete={() => deleteFunction()}
        />
      )}
      <ErrorMsgPopUp
        errMsg={errMsg}
        handleClose={() => {
          setErrMsg('');
          setUpdating(false);
        }}
        fromPc={true}
        zIndex={9999}
      />
      {errMsg && (
        <Overlay
          dark
          handleClick={() => {
            setErrMsg('');
            setUpdating(false);
          }}
        />
      )}
    </>
  );
};

export default ShippingDestinationContents;
