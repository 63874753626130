import { FC } from 'react';
import moment from 'moment';
import * as S from './CheckedRightSlideSidebar.styles';
import { Button } from '@components/elements/buttons/Button';
import type { Todo } from '@lib/common/type';
import { selectedItem } from '@lib/pc/picking_by_shipping/type';
import { pickingStatus } from '@lib/pc/picking/constants';

type Props = {
  onClickOK: () => void;
  selectedEditItem: selectedItem;
  data: Todo;
};

const CheckedRightSlideSidebar: FC<Props> = ({
  onClickOK,
  selectedEditItem,
  data,
}: Props) => {
  const today = new Date(data?.shippingDate);
  today.setHours(0, 0, 0, 0);
  const day = today;

  const formattedDate = moment(day).format('YYYY/MM/DD (ddd)');

  return (
    <>
      <S.Wrapper>
        <S.ScrollableContainer>
          <S.HeadContainer>
            <S.HeadDate>{formattedDate}</S.HeadDate>
            <S.StatusBar
              color={data && pickingStatus[data?.pickingStatus].statusBarColor}
              bgColor={
                data && pickingStatus[data?.pickingStatus].statusBarBgColor
              }
              borderColor={
                data && pickingStatus[data?.pickingStatus].statusBorderColor
              }
            >
              {data && pickingStatus[data?.pickingStatus].label}
            </S.StatusBar>
          </S.HeadContainer>

          <S.PickingInfoWrapper>
            <S.ProductContainer>
              <div className="title">商品</div>
              <div className="name">
                {selectedEditItem && selectedEditItem.productName}
              </div>
            </S.ProductContainer>
            <S.ProductContainer>
              <div className="title">出荷先</div>
              <div className="name">
                {selectedEditItem && selectedEditItem.shippingName}
              </div>
            </S.ProductContainer>
          </S.PickingInfoWrapper>
          <>
            <S.Container>
              <S.Label>この商品はすでにピッキング済みです。</S.Label>
              <div className="total-wrapper">
                <div>ピッキング予定数</div>
                <div className="amount">{selectedEditItem.total}</div>
              </div>
              <div className="total-wrapper">
                <span>実際のピッキング数</span>
                <div className="amount">{selectedEditItem.totalAmount}</div>
              </div>
            </S.Container>

            <S.ButtonContainer>
              <Button
                borderWidth={1}
                outlined={true}
                onClick={() => {
                  onClickOK();
                }}
              >
                OK
              </Button>
            </S.ButtonContainer>
          </>
        </S.ScrollableContainer>
      </S.Wrapper>
    </>
  );
};

export default CheckedRightSlideSidebar;
