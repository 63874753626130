import type { Product, Todo } from '@lib/common/type';

// 文字列検索
export const searchCharFunc = (stateSearchChar: string, stocks: Product[]) => {
  const newArr = stocks.filter((a) => {
    return (
      a.name.includes(stateSearchChar) ||
      (a.productCode && a.productCode.toString().includes(stateSearchChar))
    );
  });
  return newArr;
};

// スタッカー：台車、ケース、バラの計算
export const calcTotalFunc = (newData: Todo, piecesPerBox: number) => {
  let caseTotal = 0;
  let baraTotal = 0;
  if (newData.completedCase) {
    caseTotal = Number(newData.completedCase) * (piecesPerBox || 1);
  } else if (newData.stackingResult && newData.completedCase === undefined) {
    caseTotal =
      Number(newData.stackingResult.completedCase) * (piecesPerBox || 1);
  } else {
    caseTotal = 0;
  }
  if (newData.completedBara) {
    baraTotal = Number(newData.completedBara);
  } else if (
    newData.stackingResult &&
    newData.stackingResult.completedBara &&
    newData.completedBara === undefined
  ) {
    baraTotal = newData.stackingResult.completedBara;
  } else {
    baraTotal = 0;
  }

  return caseTotal + baraTotal;
};
