import * as S from './SendConfirmModal.styled';
import { Button } from '@components/elements/buttons/Button';

type Props = {
  sendConfirm: boolean;
  setSendConfirm: (sendConfirm: boolean) => void;
  send: () => void;
  sending: boolean;
  sent: boolean;
  goBack: () => void;
};

const SendConfirmModal = ({
  sendConfirm,
  setSendConfirm,
  send,
  sending,
  sent,
  goBack,
}: Props) => {
  return (
    <S.ModalWrapper open={sendConfirm}>
      <S.ModalTextContainer>
        <span>撮影した写真を保存しますか？</span>
      </S.ModalTextContainer>
      <S.ModalButtonContainer>
        <Button
          padding="sm"
          outlined
          onClick={() => {
            setSendConfirm(false);
          }}
        >
          キャンセル
        </Button>
        <Button
          padding="sm"
          outlined
          onClick={() => {
            send();
          }}
        >
          保存
        </Button>
      </S.ModalButtonContainer>
      <S.Sending sending={sending} />
      <S.ModalWrapper open={sent}>
        保存が完了しました。
        <div onClick={() => goBack()}>OK</div>
      </S.ModalWrapper>
    </S.ModalWrapper>
  );
};

export default SendConfirmModal;
