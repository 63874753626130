import { useEffect, useState } from 'react';
import * as S from './PhotoSwipeModal.styles';
import { ReactComponent as CameraAddIcon } from '@assets/icons/camera_add.svg';
import { ReactComponent as DeleteIcon } from '@assets/icons/delete.svg';
import type { Todo } from '@lib/common/type';
import { ReactComponent as OkIcon } from '@assets/icons/ok.svg';
import { ReactComponent as NgIcon } from '@assets/icons/ng.svg';
import { ReactComponent as CautionlIcon } from '@assets/icons/caution.svg';
import ConfirmPopUp from '@components/modules/common/ConfirmPopUp/ConfirmPopUp';
import Overlay from '@components/elements/backdrops/Overlay/Overlay';
import type { BestBeforeDateByPic } from '@lib/pc/wrapping/types';
import { Navigation, Pagination, Scrollbar, A11y } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/swiper-bundle.css';
import { useCounter } from '@lib/pc/wrapping/functions';
import { useDateCheckState } from '@lib/pc/wrapping/functions';

type Props = {
  newData: Todo;
  setNewData: (newData: Todo) => void;
  swipeModalActive: boolean;
  setSwipeModalActive: (swipeModalActive: boolean) => void;
  setPhotoGraphyModalActive: (photoGraphyModalActive: boolean) => void;
  bestBeforeDateByPic: BestBeforeDateByPic[];
  setPhotoViewerModalActive: (photoViewerModalActive: boolean) => void;
  bestBeforeDate?: string;
  bestBeforeDateCheckCount: number;
};

const PhotoSwipeModal = ({
  newData,
  setNewData,
  swipeModalActive,
  setSwipeModalActive,
  setPhotoGraphyModalActive,
  bestBeforeDateByPic,
  setPhotoViewerModalActive,
  bestBeforeDate,
  bestBeforeDateCheckCount,
}: Props) => {
  const [confirmMsg, setConfirmMsg] = useState('');
  const [currentIndex, setCurrentIndex] = useState(0);
  const { dateCheckState, ngState, okState, cautionState, resetState } =
    useDateCheckState(''); // 認識結果アイコン表示
  const { dateCount, increment, resetCount } = useCounter(0); // 日付認識数（分子）

  // 追加撮影ボタン実行イベント
  const addCameraEvent = () => {
    // PhotoViewer画面をClose
    setSwipeModalActive(false);
    setPhotoViewerModalActive(false);
    setPhotoGraphyModalActive(true);
    // 追加撮影用の順番に変更（昇順）
    bestBeforeDateByPic.reverse();
  };

  // 削除ボタン実行イベント
  const deleteEvent = () => {
    bestBeforeDateByPic.splice(currentIndex, 1);
    // 日付認識数の集計と日付認識State判定：分子変更がない場合にも再判定が必要
    datePicCheck();
    // 再表示用に順番に変更（降順）
    bestBeforeDateByPic.reverse();
    // 初期化
    setConfirmMsg('');
    setPhotoViewerModalActive(false);
  };

  const handleOk = () => {
    setPhotoViewerModalActive(false);
    setPhotoGraphyModalActive(false);
    setSwipeModalActive(false);
    // 登録用に並び順をもとに戻す（昇順）
    setNewData({
      ...newData,
      bestBeforeDateByPic: bestBeforeDateByPic.reverse(),
      bestBeforeDateState: dateCheckState,
    });
  };

  //日付認識数と日付認識State判定
  function datePicCheck() {
    // 比較対象の賞味期限日がない（基本的にはあり得ない）場合はskip
    if (bestBeforeDate === undefined) {
      return;
    }

    // 初期化
    resetState();
    resetCount();

    //比較対象の賞味期限
    const strEditDate = bestBeforeDate ? bestBeforeDate.replace(/-/g, '') : '';
    let dateError = false; //日付不一致判定
    for (let i = 0; i < bestBeforeDateByPic.length; i++) {
      for (let j = 0; j < bestBeforeDateByPic[i].expiryDates.length; j++) {
        let date = '';
        // 登録済み画像で日付認識できなかったものはNULL値になる：Error回避のためif条件追加
        if (bestBeforeDateByPic[i].expiryDates[j] === null) {
          date = '';
        } else {
          date = bestBeforeDateByPic[i].expiryDates[j].replace(/-/g, '');
        }
        if (date !== strEditDate) {
          dateError = true;
        }
        // s3保存済みはYYYY/MM/DD形式、今撮影したのはYYYYMMDD形式のため8桁以上で判定
        if (date.length >= 8) {
          // 何かしらの日付が取得できている
          increment();
        }
      }
    }
    if (dateError) {
      ngState();
    } else {
      // 写真から取得した日付数とチェックが必要な賞味期限数からStateを判定
      if (dateCount === bestBeforeDateCheckCount) {
        okState();
      } else {
        cautionState();
      }
    }
  }

  useEffect(() => {
    if (bestBeforeDateByPic.length === 0) {
      setSwipeModalActive(false);
    } else {
      // 表示用の順番に変更（降順）
      bestBeforeDateByPic.reverse();
      // 日付認識数の集計と日付認識State判定
      datePicCheck();
    }
  }, [bestBeforeDateByPic.length]);

  useEffect(() => {
    // 日付認識数の集計と日付認識State判定
    datePicCheck();
  }, [dateCount]);

  return (
    <>
      <S.Wrapper open={swipeModalActive}>
        <S.HeadContainer>
          <S.HeadingWrapper>
            <S.ButtonContainer>
              <S.DateCheckIcon>
                {dateCheckState === 'DATE_RECOGNIZED' ? (
                  <OkIcon />
                ) : dateCheckState === 'PARTIAL_DATE_RECOGNIZED' ? (
                  <CautionlIcon />
                ) : dateCheckState === 'DATE_NOT_RECOGNIZED' ? (
                  <NgIcon />
                ) : (
                  ''
                )}
              </S.DateCheckIcon>
              <S.MainTitle>日付認識数</S.MainTitle>
              {/* 写真から取得でした日付数／チェックする賞味期限総数の表示 */}
              <S.DateCheckContainer>
                <S.DateCount>{dateCount}</S.DateCount>
                <S.DateText>/</S.DateText>
                <S.DateTotalCount>{bestBeforeDateCheckCount}</S.DateTotalCount>
              </S.DateCheckContainer>
              <S.HeaderButton onClick={() => addCameraEvent()}>
                <CameraAddIcon />
              </S.HeaderButton>
            </S.ButtonContainer>
          </S.HeadingWrapper>
        </S.HeadContainer>
        {/* swiper画面の表示 */}
        <S.Swiper>
          <Swiper
            modules={[Navigation, Pagination, Scrollbar, A11y]}
            navigation={{
              prevEl: '.button_prev',
              nextEl: '.button_next',
            }}
            // 下記で、デフォルトの左右のページネーション表示
            // navigation
            pagination={{
              // パラメータを設定
              el: '#pagination',
              // type: 'fraction', // 枚数表示
            }}
            onSlideChange={(swiper) => {
              setCurrentIndex(swiper.activeIndex);
            }}
            // pagination={{ clickable: true }}
          >
            {bestBeforeDateByPic.map(
              (pic: BestBeforeDateByPic, indx: number) => (
                <SwiperSlide key={indx}>
                  <img src={pic.image} />
                </SwiperSlide>
              )
            )}
          </Swiper>
          {/* 要素を作成 */}
          <div id="pagination" className="swiper-pagination"></div>
        </S.Swiper>
        <S.FooterContainer>
          <S.FooterWrapper>
            <S.FooterButtonContainer>
              <S.DeleteButton
                onClick={() =>
                  setConfirmMsg('この画像を削除しても良いですか？')
                }
              >
                <DeleteIcon />
                削除
              </S.DeleteButton>
              <S.OkButton onClick={() => handleOk()}>OK</S.OkButton>
            </S.FooterButtonContainer>
          </S.FooterWrapper>
        </S.FooterContainer>
      </S.Wrapper>
      <ConfirmPopUp
        fromPc={true}
        confirmMsg={confirmMsg}
        zIndex={10002}
        handleOk={() => deleteEvent()}
        handleCancel={() => setConfirmMsg('')}
      />
      {confirmMsg && <Overlay zIndex={10001} dark />}
    </>
  );
};
export default PhotoSwipeModal;
