import { FC } from 'react';
import * as S from './MaterialAllocationDetailForm.styles.';
import type { Todo } from '@lib/common/type';

type Props = {
  index: number;
  fromStoreroomName: string;
  toStoreroomName: string;
  allocationType: string;
  caseValue: number;
  piece: number;
  unit: number;
  total: number;
  bestBeforeDate: Todo;
  piecesPerUnit: string;
  piecesPerBox: number;
  comment: string;
  labelOfAmount: string;
  labelOfUnit: string;
};

const MaterialAllocationDetailForm: FC<Props> = ({
  index,
  fromStoreroomName,
  toStoreroomName,
  allocationType,
  caseValue,
  piece,
  total,
  unit,
  bestBeforeDate,
  piecesPerUnit,
  piecesPerBox,
  comment,
  labelOfAmount,
  labelOfUnit,
}: Props) => {
  return (
    <S.DWrapper>
      <S.FormWrapper>
        <div>払出元</div>
        <div>{fromStoreroomName}</div>
      </S.FormWrapper>
      <S.FormWrapper>
        <div>払出先</div>
        <div>{allocationType === 'transfer' ? toStoreroomName : '廃棄'}</div>
      </S.FormWrapper>
      <S.FormWrapper>
        <div>使用期限</div>
        <div>{bestBeforeDate}</div>
      </S.FormWrapper>
      <S.DRightBottomWrapper>
        <S.DInputContainer>
          <label htmlFor={`stock-allocation-case-${index}`}>ケース</label>
          <input
            id={`stock-allocation-case-${index}`}
            value={caseValue ? caseValue : ''}
            type="text"
            disabled
          />
          <span>
            ×{piecesPerBox}
            {labelOfUnit !== 'なし'
              ? labelOfUnit
              : labelOfAmount
              ? labelOfAmount
              : '個'}
          </span>
        </S.DInputContainer>
        <S.DSymbol>+</S.DSymbol>
        {/* 単位個数：本、袋等 */}
        {labelOfUnit !== 'なし' && (
          <>
            <S.DInputContainer>
              <label htmlFor={`stock-allocation-unit-${index}`}>
                {labelOfUnit}
              </label>
              <input
                id={`stock-allocation-unit-${index}`}
                value={unit ? unit : ''}
                type="text"
                disabled
              />
              <span>
                ×{piecesPerUnit}
                {labelOfAmount ? labelOfAmount : '個'}
              </span>
            </S.DInputContainer>
            <S.DSymbol>+</S.DSymbol>
          </>
        )}
        <S.DInputContainer>
          <label htmlFor={`stock-allocation-piece-${index}`}>
            {labelOfAmount ? labelOfAmount : '個'}
          </label>
          <input
            id={`stock-allocation-piece-${index}`}
            value={piece ? piece : ''}
            type="text"
            disabled
          />
        </S.DInputContainer>
        <S.DSymbol>=</S.DSymbol>
        <S.DInputContainer>
          <label htmlFor="stock-allocation-total">合計</label>
          <input
            id="stock-allocation-total"
            value={Number(total).toLocaleString()}
            disabled
          />
        </S.DInputContainer>
      </S.DRightBottomWrapper>
      <S.NoteAmountContainer>
        <S.NoteAmountLabel>備考</S.NoteAmountLabel>
        <S.NoteAmountInput value={comment} disabled />
      </S.NoteAmountContainer>
    </S.DWrapper>
  );
};
export default MaterialAllocationDetailForm;
