import { FC } from 'react';
import ReactSelect from 'react-select';
import * as S from './ShippingGroupSelect.styles';
import type { ShippingGroup, Todo } from '@lib/common/type';
import { NO_OPTION_JAPANESE } from '@lib/pc/common/constants';

type Props = {
  data: ShippingGroup[] | undefined;
  handleSelectShippingGroup: (e: number) => void;
  value?: number | undefined;
  disabled?: boolean;
  setSelectedShippingGroupName: (selectedShippingGroupName: string) => void;
};

const ShippingGroupSelect: FC<Props> = ({
  data,
  handleSelectShippingGroup,
  value,
  disabled,
  setSelectedShippingGroupName,
}: Props) => {
  const toReactSelectOptions = (options: { id: number; name: string }[]) =>
    options.map(({ id, name }) => ({
      value: id,
      label: name,
    }));

  const reactSelectOptions = data && toReactSelectOptions(data);
  const currentValue = reactSelectOptions?.find((o) => o.value === value);

  const styles = {
    control: (baseStyles: Todo) => ({
      ...baseStyles,
      minHeight: '4rem',
      width: '728px',
      borderRadius: '0.5rem',
      fontWeight: 400,
      textAlign: 'left',
      borderStyle: 'hidden',
      border: '0.1rem solid #cdcdcdcd',
      // outline: '0.5px solid rgba(0,0,0,0.08)',
      cursor: 'pointer',
      backgroundColor: disabled ? '' : 'white',
      '@media (max-width: 599px)': {
        width: '16rem',
      },
    }),
    menu: (baseStyles: Todo) => ({
      ...baseStyles,
      top: '37px',
    }),
    option: (baseStyles: Todo) => ({
      ...baseStyles,
      textAlign: 'left',
      fontWeight: 400,
      fontSize: '15px',
    }),
  };

  return (
    <S.Wrapper>
      {/* 出荷先 */}
      <ReactSelect
        options={reactSelectOptions}
        onChange={(option) => {
          option && handleSelectShippingGroup(option.value);
          option && setSelectedShippingGroupName(option.label);
        }}
        value={currentValue ? currentValue : null}
        styles={styles}
        isDisabled={disabled}
        isSearchable={false}
        components={{
          IndicatorSeparator: () => null,
        }}
        noOptionsMessage={() => NO_OPTION_JAPANESE}
        placeholder="出荷先グループの選択"
      />
    </S.Wrapper>
  );
};

export default ShippingGroupSelect;
