import styled from 'styled-components';

export const Wrapper = styled.div`
  width: 100%;
`;

export const Label = styled.div`
  width: 100%;
  color: ${(props) => props.theme.palette.khaki};
  font-weight: bold;
  font-size: 16px;
  line-height: 23px;
  margin-bottom: 0.5rem;
`;

export const Input = styled.input`
  width: 100%;
  border-radius: 0.5rem;
  padding: 12px 0 12px 12px;
  background: ${(props) => props.theme.palette.white};
  border: none;
  color: ${(props) => props.theme.palette.black};
`;
