export const TABLE_HEADER = [
  { key: '', align: 'left', width: '3px' },
  { key: '製品名', align: 'left', width: '0px', colSpan: 1 },
  {
    key: '開始',
    align: 'left',
    width: '63px',
    wordBreak: 'keep-all',
    padding: '0 1.25rem',
    colSpan: 1,
  },
  // { key: '台車', align: 'right', width: '6rem' },
  { key: '', align: 'left', width: '3px' },
  { key: 'ケース', align: 'right', width: '6rem' },
  { key: '個', align: 'right', width: '6rem' },
  { key: '合計', align: 'right' },
  {
    key: '賞味期限',
    align: 'left',
    width: '50px',
    wordBreak: 'keep-all',
    colSpan: 2,
  },
  {
    key: '撮影',
    align: 'center',
    width: '4rem',
    right: '11px',
    // padding: '0 1.4rem',
  },

  // {
  //   key: 'シール性 チェック',
  //   align: 'center',
  //   width: '4rem',
  //   fontSize: '0.75rem',
  // },
];

export const TABLE_HEADER_UNDER = [
  { key: 'ケースの種類', align: 'left', width: '0px', colSpan: 1 },
  {
    key: '終了',
    align: 'left',
    width: '63px',
    wordBreak: 'keep-all',
    padding: '0 1.25rem',
    colSpan: 1,
  },
  // { key: '台車', align: 'right' },
  { key: '', align: 'left', width: '3px' },
  { key: 'ケース', align: 'right' },
  { key: '個', align: 'right' },
  { key: '合計', align: 'right' },
  {
    key: '包装担当者',
    align: 'left',
    width: '9.375rem',
    wordBreak: 'keep-all',
    padding: '0 0.25rem',
    colSpan: 3,
  },
];
