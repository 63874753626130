import React, { useRef, useState } from 'react';
import * as S from './CaptureForTraining.styles';
import { Camera, CameraType } from 'react-camera-pro';
import Resizer from 'react-image-file-resizer';
import axios from 'axios';
import scanSound from '@assets/sounds/sound.mp3';
import { Todo } from '@lib/common/type';

export const CaptureForTraining = () => {
  const camera = useRef<CameraType>(null);
  const [imgSrc, setImgSrc] = useState<Todo>([null, null, null, null]);
  // 640*640にresizeされたimage
  const [resizedImgSrc, setResizedImgSrc] = useState<Todo>([
    null,
    null,
    null,
    null,
  ]);
  const [imgIndex, setImgIndex] = useState<number>(0);
  const [previewMode, setPreviewMode] = useState<boolean>(false);
  const [closeMode, setCloseMode] = useState<boolean>(false);
  const [sendMode, setSendMode] = useState<boolean>(false);
  const [sendConfirm, setSendConfirm] = useState<boolean>(false);
  const [sending, setSending] = useState<boolean>(false);
  const [sent, setSent] = useState<boolean>(false);
  // const audio = new Audio('/camera.mp3');
  const [audio] = useState(new Audio(scanSound));

  const resizeFile = (file: Blob) =>
    new Promise((resolve) => {
      Resizer.imageFileResizer(
        file,
        640,
        640,
        'JPEG',
        100,
        0,
        (uri) => {
          resolve(uri);
        },
        'base64'
      );
    });

  const capture = React.useCallback(
    async (index) => {
      const tmpImgSrc = [...imgSrc];
      const tempResizedImgSrc = [...resizedImgSrc];
      let cameraImageSrc;
      if (camera.current) {
        cameraImageSrc = await camera.current.takePhoto();
      }

      // resizeFile------------
      if (cameraImageSrc) {
        const blob = await dataURItoBlob(cameraImageSrc);
        const resized = (await resizeFile(blob)) as string;
        tempResizedImgSrc[index] = resized;
        setResizedImgSrc(tempResizedImgSrc);
      }

      tmpImgSrc[index] = cameraImageSrc;
      // 画面上部4枚の画像へ設定
      setImgSrc(tmpImgSrc);
      audio.load();
      audio.play();

      if (index === 3) {
        // 4枚目の写真撮影時、1枚めが撮影されていなかったらindexを1枚目に設定
        setPreviewMode(imgSrc[0] !== null);
        setImgIndex(0);
      } else {
        setPreviewMode(imgSrc[index + 1] !== null);
        setImgIndex(index + 1);
      }
    },
    [setImgSrc, imgIndex, setImgIndex]
  );

  // どの角度のものを選択するか
  const select = React.useCallback(
    (index) => {
      if (index === 4) {
        setPreviewMode(imgSrc[0] !== null);
        setImgIndex(0);
      } else {
        setPreviewMode(imgSrc[index] !== null);
        setImgIndex(index);
      }
    },
    [imgIndex, setImgIndex, previewMode, setPreviewMode]
  );

  // 再撮影
  const clear = React.useCallback(
    (index) => {
      const tmpImgSrc = [...imgSrc];
      tmpImgSrc[index] = null;
      setImgSrc(tmpImgSrc);
      setPreviewMode(false);
    },
    [imgSrc, setImgSrc, previewMode, setPreviewMode]
  );

  // blobへの変換
  const dataURItoBlob = (dataURI: string) => {
    // convert base64 to raw binary data held in a string
    // doesn't handle URLEncoded DataURIs - see SO answer #6850276 for code that does this
    const byteString = atob(dataURI.split(',')[1]);

    // separate out the mime component
    const mimeString = dataURI.split(',')[0].split(':')[1].split(';')[0];

    // write the bytes of the string to an ArrayBuffer
    const ab = new ArrayBuffer(byteString.length);
    const ia = new Uint8Array(ab);
    for (let i = 0; i < byteString.length; i++) {
      ia[i] = byteString.charCodeAt(i);
    }
    return new Blob([ab], { type: mimeString });
  };

  const send = async () => {
    setSendConfirm(false);
    setSending(true);

    const url =
      'https://dr1wpthv7d.execute-api.ap-northeast-1.amazonaws.com/default/test-camera';

    const data = {
      product_id: 1,
      image1: resizedImgSrc[0]?.split(',')[1],
      image2: resizedImgSrc[1]?.split(',')[1],
      image3: resizedImgSrc[2]?.split(',')[1],
      image4: resizedImgSrc[3]?.split(',')[1],
    };
    const headers = { 'Content-Type': 'application/json' };

    axios
      .post(url, data, { headers })
      .then((response) => {
        console.log(response);
        setSending(false);
        setSent(true);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const Cam = () => (
    <Camera ref={camera} errorMessages={{}} facingMode="environment" />
  );

  return (
    <>
      <S.CloseModal closeMode={closeMode}>
        <S.CloseConfirm>写真撮影作業を終了しますか？</S.CloseConfirm>
        <S.CloseNo onClick={() => setCloseMode(false)}>いいえ</S.CloseNo>
        <S.CloseYes onClick={() => window.close()}>はい</S.CloseYes>
      </S.CloseModal>
      <S.SendModal sendMode={sendMode}>
        <S.SendConfirm sendConfirm={sendConfirm}>
          撮影した写真を送信しますか？
        </S.SendConfirm>
        <S.SendNo
          sendConfirm={sendConfirm}
          onClick={() => {
            setSendMode(false), setSendConfirm(false);
          }}
        >
          いいえ
        </S.SendNo>
        <S.SendYes sendConfirm={sendConfirm} onClick={() => send()}>
          はい
        </S.SendYes>
        <S.Sending sending={sending} />
        <S.SentSuccess sent={sent}>
          送信が完了しました。
          <br />
          ブラウザを閉じてください。
        </S.SentSuccess>
      </S.SendModal>
      <S.Filter closeMode={closeMode} sendMode={sendMode} />
      <S.OuterBody>
        <S.HeaderContainer>
          <S.CloseButton onClick={() => setCloseMode(true)}>
            閉じる
          </S.CloseButton>
          <S.ProductName>商品A</S.ProductName>
          <S.SendButton
            onClick={() => {
              setSendMode(true), setSendConfirm(true);
            }}
          >
            送信
          </S.SendButton>
        </S.HeaderContainer>
        <S.ThumbnailContainer>
          <S.PreviewThumbnail1
            id="image1"
            src={imgSrc[0] === null ? '/front.png' : imgSrc[0]}
            imgIndex={imgIndex}
            onClick={() => select(0)}
          />
          <S.PreviewThumbnail2
            id="image2"
            src={imgSrc[1] === null ? '/left.png' : imgSrc[1]}
            imgIndex={imgIndex}
            onClick={() => select(1)}
          />
          <S.PreviewThumbnail3
            id="image3"
            src={imgSrc[2] === null ? '/right.png' : imgSrc[2]}
            imgIndex={imgIndex}
            onClick={() => select(2)}
          />
          <S.PreviewThumbnail4
            id="image4"
            src={imgSrc[3] === null ? '/upper.png' : imgSrc[3]}
            imgIndex={imgIndex}
            onClick={() => select(3)}
          />
        </S.ThumbnailContainer>
        <S.Sentence1 previewMode={previewMode} imgIndex={imgIndex}>
          正面-高さ45°
        </S.Sentence1>
        <S.Sentence2 previewMode={previewMode} imgIndex={imgIndex}>
          左正面-高さ45°
        </S.Sentence2>
        <S.Sentence3 previewMode={previewMode} imgIndex={imgIndex}>
          右正面-高さ45°
        </S.Sentence3>
        <S.Sentence4 previewMode={previewMode} imgIndex={imgIndex}>
          真上
        </S.Sentence4>
        <S.CanvasContainer>
          <S.BlurWrapper previewMode={previewMode} className="blurCanvasRef">
            <Cam />
          </S.BlurWrapper>
          <S.CameraWrapper id="mainCanvas" previewMode={previewMode}>
            <Cam />
          </S.CameraWrapper>
          <S.ImageWrapper src={imgSrc[imgIndex]} previewMode={previewMode} />
        </S.CanvasContainer>
        <S.CircleButtonContainer>
          <S.Circle previewMode={previewMode} />
          <S.CircleButton
            id="circle-button"
            previewMode={previewMode}
            onClick={() => capture(imgIndex)}
          />
          <S.RecaptureButton
            onClick={() => clear(imgIndex)}
            previewMode={previewMode}
          >
            再撮影
          </S.RecaptureButton>
          <S.YesButton
            onClick={() => select(imgIndex + 1)}
            previewMode={previewMode}
          >
            OK
          </S.YesButton>
        </S.CircleButtonContainer>
      </S.OuterBody>
    </>
  );
};
