import { FC, useEffect, useState } from 'react';
import { useHistory, useLocation } from 'react-router';
import moment from 'moment';
import Filter from '@assets/icons/filter_list';
import * as S from './TableWithFilter.styles';
import PickingStatusMenu from '@components/modules/pc/picking/PickingStatusMenu/PickingStatusMenu';
import SearchMenu from '@components/modules/pc/picking/SearchMenu/SearchMenu';
import ShippingStopMenu from '@components/modules/pc/picking_by_shipping/picking_by_shipping_detail/ShippingStopMenu/ShippingStopMenu';
import ShortageMenu from '@components/modules/pc/picking_by_shipping/picking_by_shipping_detail/ShortagetMenu/ShortageMenu';
import Overlay from '@components/elements/backdrops/Overlay/Overlay';
import { TABLE_HEADER } from './dummy';
import { Todo } from '@lib/common/type';
import {
  PickingByShippingState,
  Pickings,
} from '@lib/pc/picking_by_shipping/type';
import { STATUS_VALUE } from '@lib/pc/picking/type';

export type AlignType = 'left' | 'center' | 'right';

type Props = {
  shippings: Pickings;
  setDatePickerInactive: (datePickerInactive: boolean) => void;
};

const TableWithFilter: FC<Props> = ({
  shippings,
  setDatePickerInactive,
}: Props) => {
  const { state } = useLocation<PickingByShippingState>();
  const history = useHistory();
  const ALL_STATUS = [0, 1, 2, 3, 4];
  // const ALL_STATUS = ['waiting_picking', 'picking', 'waiting_shipping'];

  const [statusActive, setStatusActive] = useState(false);
  // スクロール位置state
  // const { y: currentScrollPosition } = useWindowScroll();
  const [stateScrollPosition, setScrollPosition] = useState<number | null>(
    null
  );
  // 日付
  const [selectedDate, setSelectedDate] = useState(shippings?.shippingDate);
  // 絞り込みステータス
  const [selectedStatus, setSelectedStatus] = useState<number[]>([]);
  const [statePickingStatus, setStatePickingStatus] = useState<number[]>([]);
  // 出荷先グループ名検索modal表示
  const [shippingGroupSearchModalActive, setShippingGroupSearchModalActive] =
    useState(false);
  // 出荷先グループ名検索文字列
  const [shippingGroupSearchWord, setShippingGroupSearchWord] = useState('');
  // 出荷止めmodal表示
  const [shippingStopModalActive, setShippingStopModalActive] = useState(false);
  // 欠品ありmodal表示
  const [shortageModalActive, setShortageModalActive] = useState(false);
  // 出荷止めメニュー(true: 出荷止めのみ表示)
  const [selectedShippingStopStatus, setSelectedShippingStopStatus] =
    useState(false);
  // 欠品メニュー(true: 欠品ありのみ表示)
  const [selectedShortStatus, setSelectedShortStatus] = useState(false);

  const onClickState = (key: string) => {
    if (key === 'ステータス') {
      setStatusActive(!statusActive);
      setShippingGroupSearchModalActive(false);
      setShortageModalActive(false);
      setShippingStopModalActive(false);
    } else if (key === '出荷先グループ名') {
      setStatusActive(false);
      setShippingGroupSearchModalActive(!shippingGroupSearchModalActive);
      setShortageModalActive(false);
      setShippingStopModalActive(false);
    } else if (key === '欠品') {
      setStatusActive(false);
      setShippingGroupSearchModalActive(false);
      setShortageModalActive(!shortageModalActive);
      setShippingStopModalActive(false);
    } else if (key === '出荷止め') {
      setStatusActive(false);
      setShippingGroupSearchModalActive(false);
      setShortageModalActive(false);
      setShippingStopModalActive(!shippingStopModalActive);
    }
  };

  // 検索出荷先グループ名
  const handleSetShippingGroupSearch = (e: Todo) => {
    setShippingGroupSearchWord(e.target.value);
  };

  useEffect(() => {
    if (state === undefined) {
      setSelectedDate(shippings?.shippingDate);
      setScrollPosition(0);
      setShippingGroupSearchWord('');
      // setShippingStopMenuStatus(false);
      setSelectedShippingStopStatus(false);
      setStatePickingStatus([]);
    } else {
      const {
        stateSelectedDate,
        stateScrollPosition,
        stateSearchShippingGroupName,
        stateShippingStopMenuStatus,
        stateShortageStatus,
        stateSelectedMenuStatus,
      } = state;
      setSelectedDate(stateSelectedDate);
      document
        ?.getElementById('table-contents')
        ?.scrollTo(0, stateScrollPosition);
      setShippingGroupSearchWord(stateSearchShippingGroupName);
      setSelectedShippingStopStatus(stateShippingStopMenuStatus);
      setSelectedShortStatus(stateShortageStatus);
      setSelectedStatus(stateSelectedMenuStatus);
    }
  }, [state]);

  const pickingByShippingState = {
    stateSelectedDate: selectedDate,
    stateScrollPosition: stateScrollPosition,
    stateSearchShippingGroupName: shippingGroupSearchWord,
    stateShippingStopMenuStatus: selectedShippingStopStatus,
    stateShortageStatus: selectedShortStatus,
    stateSelectedMenuStatus: selectedStatus,
  };

  useEffect(() => {
    setStatePickingStatus(selectedStatus);
  }, [selectedStatus]);

  useEffect(() => {
    setSelectedDate(shippings?.shippingDate);
  }, [shippings?.shippingDate]);

  // スクロール位置保存
  document?.getElementById('table-contents')?.addEventListener('scroll', () => {
    const currentScrollPosition =
      document?.getElementById('table-contents')?.scrollTop;
    if (currentScrollPosition) {
      setScrollPosition(currentScrollPosition);
    }
  });

  return (
    <>
      <S.Table>
        <thead>
          <S.THead>
            {TABLE_HEADER.map((t) => (
              <S.TCell
                className="header"
                align={t.align}
                key={t.key}
                width={t.width}
                onClick={() => onClickState(t.key)}
                status={
                  (statePickingStatus?.length > 0 && t.key === 'ステータス') ||
                  (shippingGroupSearchWord?.length > 0 &&
                    t.key === '出荷先グループ名') ||
                  (selectedShortStatus && t.key === '欠品') ||
                  (selectedShippingStopStatus && t.key === '出荷止め')
                }
                isCursor={
                  t.key === 'ステータス' ||
                  t.key === '出荷先グループ名' ||
                  t.key === '欠品' ||
                  t.key === '出荷止め'
                }
              >
                {t.key}
                {t.filter && (
                  <Filter
                    isBlue={
                      selectedStatus?.length > 0 && t.key === 'ステータス'
                    }
                  />
                )}
                {t.key === '出荷先グループ名' && (
                  <Filter
                    isBlue={
                      shippingGroupSearchWord?.length > 0 &&
                      t.key === '出荷先グループ名'
                    }
                  />
                )}
                {t.key === '欠品' && (
                  <Filter isBlue={selectedShortStatus && t.key === '欠品'} />
                )}
                {t.key === '出荷止め' && (
                  <Filter
                    isBlue={selectedShippingStopStatus && t.key === '出荷止め'}
                  />
                )}
              </S.TCell>
            ))}
          </S.THead>
        </thead>
      </S.Table>
      <S.TableContents className="table-contents" id="table-contents">
        <S.Table paddingBottom={'50px'}>
          <tbody>
            {shippings &&
              shippings.pickings
                .filter((p: Todo) =>
                  statePickingStatus.length > 0
                    ? statePickingStatus.includes(p.pickingStatus)
                    : ALL_STATUS.includes(p.pickingStatus)
                )
                .filter((p: Todo) =>
                  p.groupName.includes(shippingGroupSearchWord)
                )
                .filter((p: Todo) =>
                  selectedShippingStopStatus
                    ? p.stopShipping === true
                    : p.stopShipping === true || p.stopShipping === false
                )
                .filter((p: Todo) =>
                  selectedShortStatus
                    ? p.deliveryResults === true
                    : p.deliveryResults === true || p.deliveryResults === false
                )
                .map((t, i) => {
                  return (
                    <S.TRow
                      bgColor={STATUS_VALUE[t.pickingStatus].backgroundColor}
                      key={i}
                      onClick={() => {
                        history.push(`/pc/picking_by_shipping_detail/${t.id}`, {
                          ...pickingByShippingState,
                          stateSelectedDate: selectedDate,
                          stateScrollPosition: stateScrollPosition,
                          stateSearchShippingGroupName: shippingGroupSearchWord,
                          stateShippingStopMenuStatus:
                            selectedShippingStopStatus,
                          stateSelectedMenuStatus: selectedStatus,
                        });
                        setDatePickerInactive(true);
                      }}
                    >
                      <S.TCell className="shipping-date">
                        {shippings.shippingDate &&
                          moment(shippings.shippingDate).format('YY.MM.DD')}
                      </S.TCell>
                      <S.TCell className="client">{t.groupName}</S.TCell>
                      <S.TCell className="shipping-time" align="right">
                        {t.shippingScheduledTime &&
                          moment(t.shippingScheduledTime).format('LT')}
                      </S.TCell>
                      <S.TCell className="finished-time" align="right">
                        {t.pickingFinishedAt &&
                          moment(t.pickingFinishedAt).format('LT')}
                      </S.TCell>
                      <S.TCell className="items" align="right">
                        {t.numberOfProducts || 0}
                        <span>品目</span>
                      </S.TCell>
                      <S.TCell className="sum" align="right">
                        {t.totalAmount || 0}
                        <span>個</span>
                      </S.TCell>
                      <S.TCell className="stop-shipping" align="right">
                        {t.stopShipping && 'あり'}
                      </S.TCell>
                      <S.TCell className="shortage" align="right">
                        {t.deliveryResults && 'あり'}
                      </S.TCell>
                      <S.TCell className="status">
                        <S.StatusBar
                          color={STATUS_VALUE[t.pickingStatus].fontColor}
                          bgColor={STATUS_VALUE[t.pickingStatus].borderColor}
                          borderColor={
                            STATUS_VALUE[t.pickingStatus].borderColor
                          }
                        >
                          {STATUS_VALUE[t.pickingStatus].key}
                        </S.StatusBar>
                      </S.TCell>
                    </S.TRow>
                  );
                })}
          </tbody>
        </S.Table>
      </S.TableContents>
      {statusActive && (
        <PickingStatusMenu
          selectedStatus={selectedStatus}
          setSelectedStatus={setSelectedStatus}
          statePickingStatus={statePickingStatus}
          setStatePickingStatus={setStatePickingStatus}
          useAllocation={shippings.useAllocation}
        />
      )}
      {shippingGroupSearchModalActive && (
        <SearchMenu
          title={'出荷先グループ名'}
          searchParam={shippingGroupSearchWord}
          handleClose={() => setShippingGroupSearchModalActive(false)}
          handleChange={handleSetShippingGroupSearch}
          left={'18%'}
          deleteSearchParam={setShippingGroupSearchWord}
        />
      )}
      {shippingStopModalActive && (
        <ShippingStopMenu
          selectedShippingStopStatus={selectedShippingStopStatus}
          setSelectedShippingStopStatus={setSelectedShippingStopStatus}
        />
      )}
      {shortageModalActive && (
        <ShortageMenu
          selectedShortStatus={selectedShortStatus}
          setSelectedShortStatus={setSelectedShortStatus}
        />
      )}
      {(statusActive ||
        shippingGroupSearchModalActive ||
        shortageModalActive ||
        shippingStopModalActive) && (
        <Overlay
          handleClick={() => {
            setStatusActive(false);
            setShippingGroupSearchModalActive(false);
            setShortageModalActive(false);
            setShippingStopModalActive(false);
          }}
        />
      )}
    </>
  );
};

export default TableWithFilter;
