import * as S from './LogoutDialog.styles';
import { Button } from '@components/elements/buttons/Button';

type Props = {
  logout: () => void;
  handleCloseDialog: () => void;
};

const LogoutDialog: React.FC<Props> = ({
  logout,
  handleCloseDialog,
}: Props) => {
  return (
    <S.Wrapper>
      <S.Header>
        <div>ログアウトしますか？</div>
      </S.Header>
      <S.ButtonWrapper>
        <S.ButtonContainer>
          <Button borderWidth={1} outlined onClick={handleCloseDialog}>
            キャンセル
          </Button>
          <Button onClick={() => logout()}>ログアウト</Button>
        </S.ButtonContainer>
      </S.ButtonWrapper>
      {/* <S.MainContainer>{dialogMessage}</S.MainContainer> */}
    </S.Wrapper>
  );
};

export default LogoutDialog;
