import { useEffect, useState } from 'react';
import { WithHeader } from '@components/templates/WithHeader';
import UpdatePopUp from '@components/modules/common/UpdatePopUp/UpdatePopUp';
import MaterialAllocationContents from '@components/modules/pc/material_allocation/MaterialAllocationContents/MaterialAllocationContents';
import MaterialAllocationHeaderControl from '@components/modules/pc/material_allocation/MaterialAllocationHeaderControl/MaterialAllocationHeaderControl';
import useCases from '@lib/pc/common/hooks/useCases';
import useStorerooms from '@lib/pc/common/hooks/useStorerooms';
import { PcScrollStyle } from '@lib/styled-components/globalStyle';
import { getTodayForInput } from '@lib/common/functions';
import moment from 'moment';
import {
  MATERIAL_TYPE,
  Material,
  MATERIAL_PRODUCT_KIND,
} from '@lib/common/type';
import useMaterialStockAllocations from '@lib/pc/material_allocation/useMaterialStockAllocations';
import useMaterials from '@lib/pc/common/hooks/useMaterials';

export const MaterialAllocationRoot = () => {
  const today = new Date();
  const [date, setDate] = useState(new Date());
  const [popUp, setPopUp] = useState(false);
  const [messageKind, setMessageKind] = useState('');
  const [editMaterialName, setEditMaterialName] = useState('');
  // 選択された払出元に存在する資材のみ
  const [selectedStoreroomsMaterials, setSelectedStoreroomsMaterials] =
    useState<Material[]>([]);

  const { data: storerooms } = useStorerooms(MATERIAL_PRODUCT_KIND.MATERIAL);
  const { data: materials } = useMaterials(MATERIAL_TYPE.MATERIAL);

  const { data: cases } = useCases();
  const [selectedStoreroomId, setSelectedStoreroomId] = useState(
    storerooms?.[0].id
  );
  const { materialStockAllocations, refetch } = useMaterialStockAllocations(
    date,
    MATERIAL_TYPE.MATERIAL,
    selectedStoreroomId ? selectedStoreroomId : storerooms?.[0].id
  );

  // 1日戻る
  const getBeforeDate = (date: Date) => {
    setDate(moment(date).subtract(1, 'days').toDate());
    setPopUp(false);
  };

  // 1日進む
  const getNextDate = (date: Date) => {
    // 現在日付けが過去日のときのみ、日付を進められる。
    if (
      moment(date).format('YYYY/MM/DD') !== moment(today).format('YYYY/MM/DD')
    ) {
      setDate(moment(date).add(1, 'days').toDate());
      setPopUp(false);
    }
  };

  const handleClick = () => {
    getTodayForInput(date, today, setDate, refetch);
  };

  useEffect(() => {
    setSelectedStoreroomId(storerooms?.[0].id);
  }, [storerooms]);

  // 払出元に存在する資材だけを設定する
  useEffect(() => {
    refetch();
    const filteredMaterials = materials?.filter(
      (m: Material) =>
        selectedStoreroomId && m.storeroomIds.includes(selectedStoreroomId)
    );
    filteredMaterials && setSelectedStoreroomsMaterials(filteredMaterials);
  }, [selectedStoreroomId, materials]);

  useEffect(() => {
    localStorage.setItem('last_view_page', 'stock_allocation_status');
  }, []);

  if (!cases || !storerooms) return null;

  return (
    <WithHeader>
      <PcScrollStyle />
      <MaterialAllocationHeaderControl
        storerooms={storerooms}
        selectedStoreroomId={selectedStoreroomId}
        setSelectedStoreroomId={setSelectedStoreroomId}
        selectedDate={date}
        getBeforeDate={getBeforeDate}
        getNextDate={getNextDate}
        setPopUp={setPopUp}
        handleClick={handleClick}
        setDate={setDate}
      />
      <MaterialAllocationContents
        stockAllocations={materialStockAllocations}
        selectedStoreroomId={selectedStoreroomId}
        date={date}
        selectedStoreroomsMaterials={selectedStoreroomsMaterials}
        storerooms={storerooms}
        setPopUp={setPopUp}
        setEditMaterialName={setEditMaterialName}
        refetch={refetch}
        setMessageKind={setMessageKind}
      />
      <UpdatePopUp
        popUp={popUp}
        handleClose={() => setPopUp(false)}
        fromPc={true}
        taskKind={'stockAllocation'}
        messageKind={messageKind}
        productName={editMaterialName}
      />
    </WithHeader>
  );
};
