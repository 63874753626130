export const TABLE_HEADER = [
  {
    key: '編集',
    width: '47px',
    colSpan: 1,
  },
  { key: '資材名', width: '230px' },
  {
    key: '払出先',
    width: '230px',
    wordBreak: 'keep-all',
    colSpan: 1,
  },
  {
    key: '数量',
    width: '200px',
    wordBreak: 'keep-all',
    colSpan: 4,
  },
  {
    key: '使用期限',
    width: '100px',
    colSpan: 1,
  },
  {
    key: '備考',
    width: '193px',
    wordBreak: 'keep-all',
    colSpan: 1,
  },
];
