import { useMutation } from 'react-query';
import humps from 'humps';
import { axiosInstance } from '@lib/mobile/common/api/axiosConfig';
import { Todo } from '@lib/common/type';

const useUpdatePickingMutation = (
  pickingDetailId: string | number,
  handleUpdateSuccess: () => void,
  setErrMsg: (errMsg: string) => void
) => {
  return useMutation(
    async (params: Todo) => {
      return await axiosInstance().patch(
        `/api/v1/pickings/${pickingDetailId}`,
        humps.decamelizeKeys(params)
      );
    },
    {
      onSuccess: () => {
        handleUpdateSuccess();
      },
      onError: (error: Todo) => {
        setErrMsg(error.response.data.message || 'エラーが発生しました');
      },
    }
  );
};

export default useUpdatePickingMutation;
