import styled from 'styled-components';

export const Wrapper = styled.div<{
  open?: boolean;
}>`
  width: 740px;
  height: 761px;
  top: ${(props) => (props.open ? '7%' : '100%')};
  left: 50%;
  transform: translate(-50%, 0%);
  bottom: 30px;
  position: fixed;
  transition: all 0.5s ease-out;
  z-index: 10001;
  overflow-x: hidden;
  background-color: white;
  display: flex;
  flex-direction: column;
`;

export const HeadContainer = styled.div`
  position: fixed; //要素の固定
  width: 740px;
  height: 84px;
  display: left;
  flex-direction: column;
  background-color: ${(props) => props.theme.palette.beige};
`;

export const HeadingWrapper = styled.div<{
  status?: string;
}>`
  float: left;
  height: 84px;
  font-weight: 700;
  background-color: white;
  width: 100%;
  text-align: center;
  padding-top: 10px;
  padding-bottom: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: ${(props) =>
    props.status === 'OK'
      ? props.theme.palette.tealGreen
      : props.status === 'NG'
      ? props.theme.palette.error
      : props.theme.palette.beige};
`;

export const Title = styled.div`
  font-size: 32px;
  margin-right: -725px;
  color: #fff;
  width: 100%;
`;

export const ButtonContainer = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  height: 84px;
  justify-content: space-evenly;
  & > button {
    width: 136px;
  }
  & > button:first-child {
    margin-right: 1rem;
  }
`;

export const HeaderButton = styled.div`
  cursor: pointer;
  width: 136px;
  height: 51px;
  margin-right: 5px;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 10px;
  background-color: ${(props) => props.theme.palette.white};
  color: ${(props) => props.theme.palette.primary.main};
  border: 2px solid ${(props) => props.theme.palette.primary.main};
  font-weight: bold;
  font-size: 16px;
  text-align: center;
  border-radius: 8px;
  padding: 0.75rem;
  border-width: 2px;
`;

export const CanvasBoard = styled.div<{
  status?: string;
}>`
  padding-top: 84px;
  height: 555px;
  width: 740px;
  flex-direction: column;
  display: inline-block;
  img {
    top: 74px !important;
    top: 150px;
    height: 555px;
    width: 740px;
    display: none; //非表示
  }
  canvas {
    top: 74px !important;
    top: 150px;
    height: 555px;
    width: 740px;
    display: inline-block;
    background-color: black;
    border: ${(props) =>
      props.status === 'OK'
        ? `4px solid ${props.theme.palette.tealGreen}`
        : props.status === 'NG'
        ? `4px solid ${props.theme.palette.error}`
        : `4px solid ${props.theme.palette.beige}`};
  }
`;

export const CircularIconWrapperOuter = styled.div`
  width: 50vw;
  height: 60vh;
  position: relative;
`;

export const CircularIconWrapper = styled.div`
  z-index: 10003;
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  top: 40%;
  left: 48%;
`;

export const FooterContainer = styled.div`
  padding-top: 84px;
  height: 122px;
  display: inline-block;
  flex-direction: column;
`;

export const FooterWrapper = styled.div`
  float: left;
  font-weight: 700;
  background-color: white;
  color: black;
  width: 100%;
  height: 122px;
  text-align: center;
  padding-top: 10px;
  padding-bottom: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: ${(props) => props.theme.palette.beige};
`;

export const FooterButtonContainer = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  justify-content: space-evenly;
  & > button {
    width: 136px;
  }
  & > button:first-child {
    margin-right: 1rem;
  }
`;

//キャンセルボタン
export const CancelButton = styled.div`
  cursor: pointer;
  width: 136px;
  height: 51px;
  margin-right: 5px;
  color: #3ac;
  background: #fff;
  padding: 0.75rem 0;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 10px;
  font-weight: bold;
  font-size: 16px;
  text-align: center;
  border: 2px solid #3ac;
  border-radius: 8px;
  border-width: 2px;
`;

//OKボタン
export const OkButton = styled.div`
  cursor: pointer;
  width: 136px;
  height: 51px;
  margin-right: 5px;
  color: #fff;
  background: #3ac;
  padding: 0.75rem 0;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 10px;
  font-weight: bold;
  font-size: 16px;
  text-align: center;
  border: 2px solid #3ac;
  border-radius: 8px;
  border-width: 2px;
`;
