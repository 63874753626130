import { useState } from 'react';
import * as S from './TagInfo.styles';
import ProductImg from '@assets/images/product-img.jpg';
import { ReactComponent as PencilIcon } from '@assets/icons/pencil.svg';
import ChevronLeft from '@assets/icons/chevron_left';
import { Button } from '@components/elements/buttons/Button';
import ErrorMsgPopUp from '@components/modules/common/ErrorMsgPopUp/ErrorMsgPopUp';
import Overlay from '@components/elements/backdrops/Overlay/Overlay';
import TagSettingsModal from '@components/modules/pc/settings/common/TagSettingsModal/TagSettingsModal';
import { Tag, Todo } from '@lib/common/type';
import type { TagNameInputParam } from '@lib/pc/settings/product_master/type';
import useUpdateProductAndTagsMutation from '@lib/pc/settings/product_master_edit/useUpdateProductsAndTags';
import useTagsForSetting from '@lib/pc/settings/product_master_setting/useTagsForSetting';
import { TagForSetting } from '@lib/pc/settings/product_master_setting/type';

type Props = {
  product: Todo;
  updating: boolean;
  setUpdating: (updating: boolean) => void;
  onClickGoBack: () => void;
  handleProductUpdateSuccess: () => void;
  isChanged: boolean;
  setIsChanged: (isChanged: boolean) => void;
};

const TagInfo = ({
  product,
  updating,
  setUpdating,
  onClickGoBack,
  handleProductUpdateSuccess,
  isChanged,
  setIsChanged,
}: Props) => {
  const [tagSettingsOpen, setTagSettingsOpen] = useState(false);
  // ついてるタグだけ表示
  const [onlySelectedTags, setOnlySelectedTags] = useState(false);
  // 商品に紐付けるタグ------------------------------------------------------
  const [newSelectedTagsForProduct, setNewSelectedTagsForProduct] = useState(
    product?.tags ? product?.tags : []
  );
  // タグ編集後のメッセージpopUp
  const [tagPopUpp, setTagPopUp] = useState(false);
  // タグ追加
  const [newTag, setNewTag] = useState<TagNameInputParam>({ name: '' });
  // タグ名編集
  const [editTag, setEditTag] = useState<TagNameInputParam>({ name: '' });
  // タグ名編集開始
  const [isTagChanged, setIsTagChanged] = useState(false);
  // タグ編集テキストエリアのopen
  const [editInputOpen, setEditInputOpen] = useState(false);
  // タグ削除確認modal画面の表示
  const [tagDelConfirmOpen, setTagDelConfirmOpen] = useState(false);
  // 紐付けられている商品があるが、タグ削除をするか確認modal画面の表示
  const [tagDelConfirmAgainOpen, setTagDelConfirmAgainOpen] = useState(false);
  // 削除するタグの情報
  const [deleteTag, setDeleteTag] = useState<Tag>({ id: 0, name: '' });
  // 更新、編集、削除後のpopUpメッセージ種別
  const [msgKind, setMsgKind] = useState('');
  const [errMsg, setErrMsg] = useState('');

  // 選択されているタグのid→Todo:新規作成時、未作成のためエラーになる
  const [, setSelectedTagsIds] = useState(
    product?.tags ? product?.tags.map((dt: Tag) => dt['id']) : []
  );

  const { tags: allTagsForSetting, refetch } = useTagsForSetting();

  const onChangeOnlySelected = (event: React.ChangeEvent<HTMLInputElement>) => {
    const check = event.target.checked;
    setOnlySelectedTags(check);
  };

  // 商品とタグテーブル更新
  const updateProductAndTagsMutation = useUpdateProductAndTagsMutation(
    product?.id || product?.product.id,
    handleProductUpdateSuccess,
    setErrMsg
  );

  // ***********************
  // [保存ボタン]押下、更新処理
  // ***********************
  const handleSubmit = () => {
    setUpdating(true);

    // 商品に紐付けるタグ----------------
    const tagIds = newSelectedTagsForProduct.map((item: Tag) => item['id']);
    updateProductAndTagsMutation.mutate({ tagIds: tagIds });
  };

  const onClickTagButton = (selected: boolean, tag: Tag) => {
    setIsChanged(true);
    // このindex全タグのindex
    if (selected) {
      // 選択済みタグの削除処理
      const dataTagsId =
        newSelectedTagsForProduct &&
        newSelectedTagsForProduct.map((dt: Tag) => dt['id']);
      const index = dataTagsId.indexOf(tag.id);
      newSelectedTagsForProduct.splice(index, 1);
    } else {
      // 選択タグの追加処理
      newSelectedTagsForProduct.push(tag);
    }
    setNewSelectedTagsForProduct(newSelectedTagsForProduct);
    setSelectedTagsIds(newSelectedTagsForProduct.map((dt: Tag) => dt['id']));
  };

  const handleSuccessTagSettings = () => {
    refetch();
    setTagPopUp(true);
    setNewTag({ name: '' });
    setEditTag({ name: '' });
    setIsTagChanged(false);
    setEditInputOpen(false);
    setTagDelConfirmOpen(false);
    setTagDelConfirmAgainOpen(false);
  };

  return (
    <>
      {product && (
        <>
          <S.Wrapper>
            <div className="go-back" onClick={onClickGoBack}>
              <ChevronLeft isBlue={true} />
              <span>一覧画面へ戻る</span>
            </div>

            <S.ProductMasterEditContents>
              <div className="product-info">
                <div className="product-img">
                  <div className="img">
                    <img
                      src={
                        product?.imageUrl ||
                        product?.product?.imageUrl ||
                        ProductImg
                      }
                    />
                  </div>
                </div>
                <div className="name">
                  {product?.name || product?.product?.name}
                </div>
              </div>
              {/* タグ */}
              <div className="tags-container">
                {/* タグ追加・編集 */}
                <div className="tags-list__header">
                  <div
                    className="tags-list__header--edit-btn"
                    onClick={() => {
                      refetch();
                      setTagSettingsOpen(true);
                    }}
                  >
                    <PencilIcon className="icon" />
                    新規タグの追加・既存タグの管理
                  </div>
                  <div className="tags-list__header--display-switching">
                    <S.Checkbox
                      type="checkbox"
                      name="onlySelectedTags"
                      onChange={onChangeOnlySelected}
                      checked={onlySelectedTags}
                    />
                    <S.TagName defaultChecked={onlySelectedTags}>
                      ついてるタグだけ表示
                    </S.TagName>
                  </div>
                </div>
                {/* タグ一覧 */}
                <ul className="tags-list__lists">
                  {onlySelectedTags
                    ? product.tags &&
                      product.tags.map((at: Tag) => {
                        return (
                          <li key={at.id} className="tag selected">
                            {at.name}
                          </li>
                        );
                      })
                    : allTagsForSetting &&
                      allTagsForSetting.map(
                        (at: TagForSetting) => {
                          const dataTagsId =
                            newSelectedTagsForProduct &&
                            newSelectedTagsForProduct.map(
                              (dt: TagForSetting) => dt['id']
                            );
                          const selected = dataTagsId?.includes(at.id)
                            ? true
                            : false;
                          return (
                            <li
                              key={at.id}
                              className={selected ? 'tag selected' : 'tag'}
                              onClick={() => onClickTagButton(selected, at)}
                            >
                              {at.name}
                            </li>
                          );
                        }
                        // );
                      )}
                </ul>
              </div>
            </S.ProductMasterEditContents>
          </S.Wrapper>
          {tagSettingsOpen && (
            <TagSettingsModal
              data={allTagsForSetting}
              tagSettingsOpen={tagSettingsOpen}
              handleClose={() => setTagSettingsOpen(false)}
              handleSuccessTagSettings={handleSuccessTagSettings}
              tagPopUp={tagPopUpp}
              setTagPopUp={setTagPopUp}
              newTag={newTag}
              setNewTag={setNewTag}
              editTag={editTag}
              setEditTag={setEditTag}
              isTagChanged={isTagChanged}
              setIsTagChanged={setIsTagChanged}
              editInputOpen={editInputOpen}
              setEditInputOpen={setEditInputOpen}
              tagDelConfirmOpen={tagDelConfirmOpen}
              setTagDelConfirmOpen={setTagDelConfirmOpen}
              deleteTag={deleteTag}
              setDeleteTag={setDeleteTag}
              tagDelConfirmAgainOpen={tagDelConfirmAgainOpen}
              setTagDelConfirmAgainOpen={setTagDelConfirmAgainOpen}
              msgKind={msgKind}
              setMsgKind={setMsgKind}
            />
          )}
        </>
      )}
      <S.ButtonContainer>
        {/* <Button
          children={'キャンセル'}
          borderWidth={1}
          outlined
          onClick={onClickCancel}
          disabled={updating}
        /> */}
        {isChanged ? (
          <Button
            children={updating ? '保存中...' : '保存'}
            onClick={handleSubmit}
          />
        ) : (
          <Button disabled children={'保存'} />
        )}
      </S.ButtonContainer>
      <ErrorMsgPopUp
        errMsg={errMsg}
        handleClose={() => {
          setErrMsg('');
          setUpdating(false);
        }}
        fromPc={true}
        zIndex={9999}
      />
      {errMsg && (
        <Overlay
          dark
          handleClick={() => {
            setErrMsg('');
            setUpdating(false);
          }}
        />
      )}
    </>
  );
};

export default TagInfo;
