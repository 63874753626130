import { axiosInstance } from '@lib/pc/common/api/axiosConfig';
import { useQuery } from 'react-query';
import humps from 'humps';
import { Todo } from '@lib/common/type';

const useAllLabelsOfAmount = () => {
  const { data } = useQuery(
    ['semi_products_all_labels_of_amount'],
    async () => {
      const data = await axiosInstance().get(
        `/api/v1/settings/semi_products/all_labels_of_amount`
      );
      return data;
    }
  );
  const allLabelsOfAmount = humps.camelizeKeys(data?.data) as Todo;

  return { allLabelsOfAmount };
};

export default useAllLabelsOfAmount;
