import { FC } from 'react';
import * as S from './OrderDetailsWrapper.styles';
import { ReactComponent as DeleteIcon } from '@assets/icons/delete.svg';
import ConsumptionTaxSelect from '@components/modules/common/ConsumptionTaxSelect/ConsumptionTaxSelect';
import MaterialSelect from '@components/modules/pc/material_allocation/MaterialSelect/MaterialSelect';
import { Material, Todo } from '@lib/common/type';
import { calculateTotals } from '@lib/pc/purchase_orders_edit/functions';

type Props = {
  index: number;
  newData: Todo;
  edit: boolean;
  materials: Material[];
  setNewData: (newData: Todo) => void;
  setIsDetailChanged: (isDetailChanged: boolean) => void;
  setNewTotalPrice: (newTotalPrice: number) => void;
  setNewTotalTax: (newTotalTax: number) => void;
  setNewTotalPriceWithTax: (newTotalPriceWithTax: number) => void;
};
const OrderDetailsWrapper: FC<Props> = ({
  index,
  newData,
  edit,
  materials,
  setNewData,
  setIsDetailChanged,
  setNewTotalPrice,
  setNewTotalTax,
  setNewTotalPriceWithTax,
}) => {
  const ConsumptionTax = [
    {
      value: 'ten_percent',
      name: '10%',
    },
    {
      value: 'eight_percent',
      name: '8%',
    },
  ];

  const deleteRow = (index: number, destroy: boolean) => {
    const newDetails = [...newData.orderDetails];
    const newDetail = {
      ...newDetails[index],
      destroy: !destroy,
    };
    newDetails[index] = newDetail;
    setNewData({ ...newData, orderDetails: newDetails });
  };

  const deleteRowForCreate = (index: number) => {
    const newDetails = [...newData.orderDetails];
    newDetails.splice(index, 1);
    setNewData({ ...newData, orderDetails: newDetails });
  };

  const handleChangeDetail = (
    e:
      | React.ChangeEvent<HTMLInputElement>
      | React.ChangeEvent<HTMLTextAreaElement>,
    index: number,
    decimalOk: boolean
  ) => {
    const isValid = decimalOk
      ? /^[0-9.]*$/.test(e.target.value)
      : /^[0-9]*$/.test(e.target.value);

    if (isValid) {
      const newDetails = [...newData.orderDetails];
      const newDetail = {
        ...newDetails[index],
        [e.target.name]: e.target.value,
      };
      newDetails[index] = newDetail;
      setNewData({ ...newData, orderDetails: newDetails });
      setIsDetailChanged(true);
    }
  };

  const handleChangeMaterial = (id: number) => {
    // materialsから商品単価を取得
    const targetMaterial = materials?.find((m) => m.id === id);
    const newDetails = [...newData.orderDetails];
    const newDetail = {
      ...newDetails[index],
      materialId: id,
      materialName: targetMaterial?.name,
      unit: targetMaterial?.labelOfUnit,
      unitPrice: targetMaterial?.pricePerUnit,
    };
    newDetails[index] = newDetail;
    setNewData({ ...newData, orderDetails: newDetails });
    setIsDetailChanged(true);
  };

  const handleChangeTax = (selectedTaxRate: string) => {
    const newDetails = [...newData.orderDetails];
    const newDetail = {
      ...newDetails[index],
      taxRate: selectedTaxRate,
    };
    newDetails[index] = newDetail;
    setNewData({ ...newData, orderDetails: newDetails });
    setIsDetailChanged(true);
    handleGetCalculation(newDetails);
  };

  const handleGetCalculation = (newDetails?: Todo[]) => {
    const filteredOrderDetails = newDetails
      ? newDetails
      : newData?.orderDetails
          .filter((d: Todo) => d.unitPrice && d.amount && d.taxRate)
          .map((d: Todo) => ({
            unitPrice: Number(d.unitPrice),
            amount: d.amount,
            taxRate: d.taxRate,
          }));

    const orderDetails = filteredOrderDetails.map((f: Todo) => {
      return {
        unitPrice: Number(f.unitPrice),
        amount: f.amount,
        taxRate: f.taxRate,
      };
    });

    const calcResult = calculateTotals(orderDetails, newData.roundingMethod);
    // PDFエリアのための設定
    setNewTotalPrice(calcResult.totalPrice);
    // calcResult.totalTaxに対して小数点処理をする
    setNewTotalTax(calcResult.roundedTotalTax);
    setNewTotalPriceWithTax(calcResult.totalPriceWithTax);
  };

  return (
    <S.ProductListRow key={index}>
      {/* 製品名 */}
      <S.ProductName>
        <S.MaterialSelectWrapper>
          {materials && (
            <MaterialSelect
              materials={materials}
              handleChangeMaterial={handleChangeMaterial}
              value={newData?.orderDetails[index]?.materialId}
              disabled={!newData.supplierId}
              height="40px"
              maxHeight="180px"
              marginTop="4px"
              notDisplayCode
            />
          )}
        </S.MaterialSelectWrapper>
      </S.ProductName>
      {/* 単価 */}
      <S.ProductUnitPrice>
        <S.Input
          type="text"
          name="unitPrice"
          id="unitPrice"
          autoComplete="new-password"
          align="left"
          padding="0 10px"
          value={newData?.orderDetails[index]?.unitPrice || ''}
          onChange={(event) => {
            handleChangeDetail(event, index, true);
          }}
          onBlur={() => handleGetCalculation()}
          disabled={!newData?.orderDetails[index]?.materialId}
        />
      </S.ProductUnitPrice>
      {/* 数量 */}
      <S.ProductUnitAmount>
        <S.Input
          type="text"
          name="amount"
          id="amount"
          autoComplete="new-password"
          align="left"
          padding="0 10px"
          value={newData?.orderDetails[index]?.amount || ''}
          onChange={(event) => {
            handleChangeDetail(event, index, false);
          }}
          onBlur={() => handleGetCalculation()}
          disabled={!newData?.orderDetails[index]?.materialId}
        />
      </S.ProductUnitAmount>
      {/* 税率 */}
      <S.ProductUnitTaxRate paddingTop={'9px'}>
        <ConsumptionTaxSelect
          data={ConsumptionTax}
          value={
            newData?.orderDetails[index]?.taxRate
              ? newData?.orderDetails[index]?.taxRate
              : ''
          }
          height="40px"
          handleChangeTax={handleChangeTax}
        />
      </S.ProductUnitTaxRate>
      {/* 削除 */}
      {edit ? (
        <S.ProductDelete>
          {newData?.orderDetails[index]?.destroy ? (
            <S.IconButton
              onClick={() =>
                deleteRow(index, newData?.orderDetails[index]?.destroy)
              }
            >
              <S.DeletedText>削除済</S.DeletedText>
            </S.IconButton>
          ) : (
            <S.IconButton
              onClick={() =>
                deleteRow(index, newData?.orderDetails[index]?.destroy)
              }
            >
              <DeleteIcon />
            </S.IconButton>
          )}
        </S.ProductDelete>
      ) : (
        // 作成時、削除
        <S.IconButton onClick={() => deleteRowForCreate(index)}>
          <DeleteIcon />
        </S.IconButton>
      )}
    </S.ProductListRow>
  );
};
export default OrderDetailsWrapper;
