import { useState, useEffect } from 'react';
import * as S from './EmployeeModal.styles';
import { Heading } from '@components/elements/typographies/Heading';
import { Button } from '@components/elements/buttons/Button';
import { AutoComplete } from '../../pc/employee_role/AutoComplete/AutoComplete';
import type { EmployeeData } from '@lib/common/type';
import useEmployeesAtLine from '@lib/pc/common/hooks/useEmployeesAtLine';

type Props = {
  width?: string;
  employeeRole: string;
  employeesAtEdit: EmployeeData[];
  setEmployeesAtEdit: (editEmployees: EmployeeData[]) => void;
  EmployeeModalActive: boolean;
  setEmployeeModalActive: (EmployeeModalActive: boolean) => void;
  openFromTop?: boolean;
  selectedLineId: number;
};

const EmployeeModal = ({
  width,
  employeeRole,
  employeesAtEdit,
  setEmployeesAtEdit,
  EmployeeModalActive,
  setEmployeeModalActive,
  openFromTop,
  selectedLineId,
}: Props) => {
  // 従業員modal画面で使用する従業員データ
  const [editEmployees, setEditEmployees] = useState<EmployeeData[]>([]);

  const { data } = useEmployeesAtLine(employeeRole, selectedLineId);

  //従業員候補取得
  const employeeSuggestions = data;

  // 編集画面の従業員を、従業員modal画面へ設定
  useEffect(() => {
    setEditEmployees(employeesAtEdit);
  }, [employeesAtEdit]);

  return (
    <S.Wrapper open={EmployeeModalActive} openFromTop={openFromTop}>
      <S.HeadContainer>
        <S.TitleWrapper>
          <S.HeadingWrapper>
            <Heading
              tag="span"
              text={
                employeeSuggestions?.length != 0
                  ? '担当者を入力してください'
                  : 'このラインに担当者は設定されていません'
              }
              fs="32"
              fw="lg"
            />
          </S.HeadingWrapper>

          <S.ButtonContainer>
            <Button
              children={'決定'}
              onClick={() => {
                setEmployeesAtEdit(editEmployees);
                setEmployeeModalActive(false);
              }}
            />
          </S.ButtonContainer>
        </S.TitleWrapper>
      </S.HeadContainer>

      <S.EmployeeContents openFromTop={openFromTop}>
        {employeeSuggestions?.length != 0 &&
          [...Array(3)].map((_, i) => {
            return (
              <S.EmployeeIndividual key={i}>
                <S.EmployeeIndividualLabel width={width}>
                  {i + 1}人目
                  <br />
                </S.EmployeeIndividualLabel>
                {employeeSuggestions && (
                  <AutoComplete
                    key={Math.random()}
                    employeeSuggestions={employeeSuggestions}
                    singleEmployee={editEmployees ? editEmployees[i] : null}
                    editEmployees={editEmployees ? editEmployees : []}
                    setEditEmployees={setEditEmployees}
                    inputNo={i}
                  />
                )}
              </S.EmployeeIndividual>
            );
          })}
      </S.EmployeeContents>
    </S.Wrapper>
  );
};

export default EmployeeModal;
