import styled from 'styled-components';

export const DeliveryStatusWrapper = styled.div`
  padding-top: 0.5rem;
`;

export const DeliveryWhiteArea = styled.div`
  background-color: ${(props) => props.theme.palette.white};
  padding: 0 0.5rem;
`;

export const DeliveryStatusTitle = styled.div`
  padding: 1rem 0.75rem 1rem 0;
  font-size: large;
  font-weight: bold;
`;

export const DeliveryMaterialWrapper = styled.div`
  padding-bottom: 1rem;
`;

export const DeliveryMaterialName = styled.div`
  font-size: medium;
  padding-bottom: 1rem;
`;

export const ButtonContainer = styled.div<{
  invalid?: boolean;
}>`
  left: 0;
  padding: 1rem 0;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  pointer-events: ${(props) => (props.invalid ? 'none' : '')};

  & > button {
    width: 136px;
  }
`;
