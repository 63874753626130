import { axiosInstance } from '@lib/mobile/common/api/axiosConfig';
import { useQuery } from 'react-query';
import humps from 'humps';
import type { Storeroom } from '@lib/common/type';

const useStorerooms = (productKind: number | string) => {
  return useQuery('storerooms', async () => {
    const { data } = await axiosInstance().get(
      `/api/v1/storerooms?product_kind=${productKind}`
    );
    return humps.camelizeKeys(data.storerooms) as Storeroom[];
  });
};

export default useStorerooms;
