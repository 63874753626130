import { FC, useEffect } from 'react';
import type { OptionType, Product, Todo } from '@lib/common/type';
import ReactSelect from 'react-select';
import {
  removeSpaces,
  useDeviceOrientationAndSize,
} from '@lib/common/functions';
import { NO_OPTION_JAPANESE } from '@lib/pc/common/constants';

type Props = {
  products: Product[] | undefined;
  handleChangeProduct: (e: number) => void;
  value?: number | undefined;
  disabled?: boolean;
  setPopUpProductName?: (popUpProductName: string) => void;
  setSelectedProductLabelOfAmount?: (
    selectedProductLabelOfAmount: string
  ) => void;
  maxHeight?: string | undefined;
  marginTop?: string | undefined;
};

const ProductSelect: FC<Props> = ({
  products,
  handleChangeProduct,
  value,
  disabled,
  setPopUpProductName,
  setSelectedProductLabelOfAmount,
  marginTop,
}: Props) => {
  const { deviceType } = useDeviceOrientationAndSize();
  const isDesktop = deviceType === 'desktop';

  const toReactSelectOptions = (
    options: {
      id: number;
      productCode: string;
      name: string;
      labelOfAmount: string;
    }[]
  ) =>
    options?.map(({ id, productCode, name, labelOfAmount }) => ({
      value: id,
      label: removeSpaces(name),
      code: productCode,
      labelOfAmount: labelOfAmount,
    }));

  const reactSelectOptions = products && toReactSelectOptions(products);
  const currentValue = reactSelectOptions?.find((o) => o.value === value);

  const filteredProduct = products?.find((p) => p.id === value);

  const filterOption = (
    option: { label: string; data: OptionType },
    inputValue: string
  ): boolean => {
    const { label, data } = option;
    const { code } = data;
    return (
      label.toLowerCase().includes(inputValue.toLowerCase()) ||
      code?.toLowerCase().includes(inputValue.toLowerCase())
    );
  };

  useEffect(() => {
    filteredProduct &&
      setPopUpProductName &&
      setPopUpProductName(filteredProduct.name);
    filteredProduct &&
      setSelectedProductLabelOfAmount &&
      setSelectedProductLabelOfAmount(filteredProduct.labelOfAmount);
  }, [filteredProduct]);

  const styles = {
    control: (baseStyles: Todo) => ({
      ...baseStyles,
      width: '100%',
      height: '40px',
      borderRadius: '0.5rem',
      fontWeight: 400,
      textAlign: 'left',
      borderStyle: 'hidden',
      outline: '0.5px solid rgba(0,0,0,0.08)',
      cursor: 'pointer',
      boxShadow: 'none',
      backgroundColor: disabled ? '' : 'white',
      '@media (max-width: 599px)': {
        width: '16rem',
      },
    }),
    menu: (baseStyles: Todo) => ({
      ...baseStyles,
      top: '37px',
      marginTop: marginTop ? marginTop : '',
    }),
    singleValue: (baseStyles: Todo) => ({
      ...baseStyles,
      overflow: 'initial',
    }),
    option: (baseStyles: Todo) => ({
      ...baseStyles,
      textAlign: 'left',
      fontWeight: 400,
      fontSize: '15px',
    }),
    menuList: (baseStyles: Todo) => ({
      ...baseStyles,
      maxHeight: '180px',
    }),
  };

  return (
    <ReactSelect
      menuShouldBlockScroll={true}
      menuShouldScrollIntoView={false}
      options={reactSelectOptions}
      onChange={(option) => {
        option && handleChangeProduct(option.value);
        option && setPopUpProductName && setPopUpProductName(option.label);
        option &&
          setSelectedProductLabelOfAmount &&
          setSelectedProductLabelOfAmount(option.labelOfAmount);
      }}
      value={currentValue ? currentValue : null}
      styles={styles}
      isDisabled={disabled}
      isSearchable={isDesktop}
      filterOption={filterOption}
      components={{
        IndicatorSeparator: () => null,
      }}
      noOptionsMessage={() => NO_OPTION_JAPANESE}
      placeholder="選択してください"
    />
  );
};

export default ProductSelect;
