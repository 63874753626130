import { useEffect, useState } from 'react';
import { useHistory, useLocation, useParams } from 'react-router';
import * as S from './PickingEditScannerResult.styles';
import Close from '@assets/icons/mobile/close';
import PickingInfoByProduct from '@components/modules/mobile/picking_edit/PickingInfoByProduct/PickingInfoByProduct';
import useProducts from '@lib/mobile/common/hooks/useProducts';
import {
  DetailsByProduct,
  IndividualShippings,
  PickingState,
} from '@lib/mobile/picking_edit/type';
import { PICKING_DETAIL_DATA } from './dummy';
import { Todo } from '@lib/common/type';
// import moment from 'moment';

export const PickingEditScannerResultRoot = () => {
  // idはshipping_id
  const { id } = useParams<{ id: string }>();
  const { state } = useLocation<PickingState>();
  // const [date] = useState(moment());
  const history = useHistory();
  const [stateSearchChar, setSearchChar] = useState('');
  const [stateShippingDate, setShippingDate] = useState('');
  const [stateShippings, setShippings] = useState<Todo>([]);
  const [statePickingStatus, setStatePickingStatus] = useState<number[]>([]);
  // const [shippingsFromIndex, setShippingsFromIndex] = useState<Todo>([]);
  const [stateScrollPosition, setScrollPosition] = useState(0);
  // const [stateScrollPositionForEdit, setScrollPositionForEdit] = useState(0);
  const [stateScannedProductName, setProductName] = useState('');
  const [stateSelectedStoreroomId, setSelectedStoreroomId] = useState<
    number | ''
  >('');
  // チェック済以外表示チェックボックス
  const [completedCheckState, setCompletedCheckState] = useState(true);

  const [janCode, setJanCode] = useState('');
  // const [allProduct, setAllProduct] = useState<Product[]>([]);
  const { data: products } = useProducts();

  // TODO: バックエンドから出荷データを取得する
  // GET: /api/v1/shippings/1→id
  // const { stocksForOneProduct: shippings, refetch } = useIndividualShipping(id);
  // const { stocksForOneProduct: stocks, refetch } = useStocksForOneProduct(id);
  const [individualShippings] =
    useState<IndividualShippings>(PICKING_DETAIL_DATA);
  // const dummyShippings = PICKING_DETAIL_DATA;

  // ステータス
  const [, setPickingStatus] = useState('pickingNotStarted');
  // // チェック済以外表示チェックボックス
  // const [completedCheckState] = useState(false);
  // // 商品検索エリア
  // const [searchProductChar, setSearchProductChar] = useState('');

  // console.log(
  //   '---スキャン結果画面 PickingEditScannerResultRoot 1308---',
  //   shippingsFromIndex
  // );
  // console.log('---編集画面 allProduct 1019---', allProduct);
  console.log(
    '---スキャン結果画面 PickingEditScannerResultRoot products 1715---',
    products
  );
  console.log('---PickingEditScannerResultRoot state 1046---', state);
  // ステータスmodal画面open
  // const [statusOpen, setStatusOpen] = useState(false);
  // スクロール固定エリアの高さ
  const [fixedAreaHeight, setFixedAreaHeight] = useState('');
  const [filteredIndividualShippings, setFilteredIndividualShippings] =
    useState<DetailsByProduct[]>([]);

  useEffect(() => {
    console.log('---useEffect PickingEditRoot---');
    if (state) {
      console.log('---設定される？1345 state---', state);
      const {
        stateSearchChar,
        stateShippingDate,
        stateShippings,
        stateScrollPosition,
        stateJanCode,
        statePickingStatus,
        stateScannedProductName,
        stateSelectedStoreroomId,
        stateCompletedCheckState,
      } = state;
      console.log(
        '---PickingEditRoot 設定される？ stateShippings 1400---',
        stateShippings
      );
      setSearchChar(stateSearchChar);
      setShippingDate(stateShippingDate);
      setShippings(stateShippings);
      setJanCode(stateJanCode);
      setStatePickingStatus(statePickingStatus);
      setScrollPosition(stateScrollPosition);
      setProductName(stateScannedProductName);
      // setScrollPositionForEdit(stateScrollPositionForEdit);
      setSelectedStoreroomId(stateSelectedStoreroomId);
      setCompletedCheckState(stateCompletedCheckState);
    } else {
      setSearchChar('');
      setShippings(stateShippings);
      // setShippingsFromIndex('');
      setScrollPosition(0);
      setStatePickingStatus([]);
      setProductName('');
      // setScrollPositionForEdit(0);
      setSelectedStoreroomId('');
      setCompletedCheckState(true);
    }
  }, [state]);
  // }, [id]);

  console.log('---id 1824---', id);
  console.log('---janCode 1824---', janCode);
  const pickingState = {
    stateSearchChar: stateSearchChar,
    stateShippingDate: stateShippingDate,
    stateShippings: stateShippings,
    stateScrollPosition: stateScrollPosition,
    stateJanCode: janCode,
    statePickingStatus: statePickingStatus,
    stateScannedProductName: stateScannedProductName,
    // stateScrollPositionForEdit: stateScrollPositionForEdit,
    stateSelectedStoreroomId: stateSelectedStoreroomId,
    stateCompletedCheckState: completedCheckState,
  };
  console.log('---PickingEditRoot pickingState 1638---', pickingState);
  // console.log('---stateScannedProductName 1625---', stateScannedProductName);

  useEffect(() => {
    if (document) {
      const clientW = document?.getElementById('fixed-area')?.clientHeight;
      clientW && setFixedAreaHeight(clientW.toString());
    }
  }, [document]);

  // 在庫室が選択されている場合、選択した在庫室idでfilter
  useEffect(() => {
    if (state.stateSelectedStoreroomId) {
      const filtered = individualShippings.detailsByProduct.filter(
        (p: DetailsByProduct) =>
          p.storeroomIds.includes(Number(state.stateSelectedStoreroomId))
      );
      setFilteredIndividualShippings(filtered);
    } else {
      setFilteredIndividualShippings(individualShippings.detailsByProduct);
    }
  }, [individualShippings]);

  return (
    <>
      <S.Wrapper>
        <S.Header id="fixed-area">
          <S.CameraMessage>
            <div
              onClick={() =>
                history.push(`/mobile/picking_detail_edit/${id}`, state)
              }
            >
              <Close isWhite={true} />
            </div>
            <div>商品コード読み取り</div>
            <div />
          </S.CameraMessage>
        </S.Header>
        {/* ピッキング予定商品別情報 */}
        {stateScannedProductName && (
          <PickingInfoByProduct
            searchProductChar={stateScannedProductName}
            completedCheckState={completedCheckState}
            detailsByProduct={filteredIndividualShippings}
            // detailsByProduct={individualShippings.detailsByProduct}
            setPickingStatus={setPickingStatus}
            fromScannerResult={true}
            // janCode={janCode}
            fixedAreaHeight={fixedAreaHeight}
          />
        )}
      </S.Wrapper>
    </>
  );
};
