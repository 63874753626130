import styled from 'styled-components';

export const Wrapper = styled.div<{
  open?: string;
}>`
  width: 100%;
  height: 18rem;
  top: 0;
  position: fixed;
  z-index: 9999;
  background: ${(props) => props.theme.palette.white};
  box-shadow: 0px 6px 10px rgba(0, 0, 0, 0.07), 0px 1px 18px rgba(0, 0, 0, 0.06),
    0px 3px 5px rgba(0, 0, 0, 0.1);
`;

export const InnerWrapper = styled.div`
  display: flex;
  padding: 20px 50px;
  justify-content: space-between;
`;

export const Product = styled.div`
  display: block;
  padding-top: 8px;
`;

export const ProductSpan = styled.span`
  padding-top: 8px;
  color: #6b675c;
  font-weight: 700;
`;

export const ShippingDestination = styled.div`
  display: block;
`;

export const ShippingDestinationSpan = styled.div`
  padding-top: 8px;
  color: #6b675c;
  font-weight: 700;
`;

export const Amount = styled.div`
  display: block;
`;

export const AmountSpan = styled.div`
  padding-top: 8px;
  color: #6b675c;
  font-weight: 700;
  padding-bottom: 5px;
`;

export const ChangeAmountInput = styled.input`
  background: #ffffff;
  border-radius: 8px;
  width: 22rem;
  height: 64px;
  text-align: right;
  padding: 1rem;
  /* border: 1px solid #dcdcdc; */
  outline: 0.5px solid rgba(0, 0, 0, 0.08);

  ::-webkit-inner-spin-button {
    -webkit-appearance: none;
  }
`;

export const ButtonContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 2rem;
  margin-top: 1rem;
  /* justify-content: space-evenly; */
  & > button {
    width: 136px;
  }

  & > button:first-child {
    margin-right: 1rem;
  }
`;
