import { ReactComponent as AddIcon } from '@assets/icons/mobile/plus_blue.svg';
import * as S from './SupplierEmailList.styles';
import { useState } from 'react';

type Props = {
  emailKind: string;
  emails: string[];
  setEmails: (emails: string[]) => void;
  setIsChanged: (isChanged: boolean) => void;
};

const SupplierEmailList = ({
  emailKind,
  emails,
  setEmails,
  setIsChanged,
}: Props) => {
  // 下位のコンポーネントに渡しているのがネストの深いオブジェクトなので、強制再レンダリングする必要がある
  const [, setToggleValue] = useState(false);
  const reRender = () => setToggleValue((prev) => !prev);

  const handleChangeEmail = (
    index: number,
    e: React.ChangeEvent<HTMLInputElement>
  ) => {
    setIsChanged(true);
    const inputData = e.target.value;
    emails[index] = inputData;
    setEmails(emails);
    reRender();
  };

  const addNewEmail = () => {
    setIsChanged(true);
    if (emails.length > 0) {
      emails.push('');
    } else {
      const emailArray: string[] = [];
      emailArray[0] = '';
      setEmails(emailArray);
    }
    reRender();
  };

  const emailTitle =
    emailKind === 'Email' ? 'TO：メールアドレス' : 'CC：メールアドレス';

  return (
    <S.Wrapper>
      <S.EmailTitle>{emailTitle}</S.EmailTitle>
      <S.ContentsWrapper>
        {emails?.map((email, index) => (
          <div key={index}>
            <S.Input
              type="email"
              value={email}
              onChange={(e) => handleChangeEmail(index, e)}
              placeholder="メールアドレスを入力"
            />
          </div>
        ))}
        <S.AddNewAddressButton onClick={addNewEmail}>
          <AddIcon /> {emailTitle}
        </S.AddNewAddressButton>
      </S.ContentsWrapper>
    </S.Wrapper>
  );
};

export default SupplierEmailList;
