import { axiosInstance as mobileAxiosInstance } from '@lib/mobile/common/api/axiosConfig';
import { axiosInstance as pcAxiosInstance } from '@lib/pc/common/api/axiosConfig';
import { useQuery } from 'react-query';
import humps from 'humps';
import moment from 'moment';
import { Todo } from '@lib/common/type';

// 予定在庫情報の取得
export const useDuplicateEstimatedStock = (
  materialId: number | string,
  storeroomId: number | string,
  date: Date,
  fromPc?: boolean
) => {
  const { data } = useQuery(
    ['duplicate_estimated_material_stocks', materialId, storeroomId, date],
    async () => {
      const axiosInstance = fromPc ? pcAxiosInstance : mobileAxiosInstance;
      const data = await axiosInstance().get(
        `/api/v1/material_stocks/${materialId}/storeroom/${storeroomId}/duplicate_latest_estimated?&date=${moment(
          date
        ).format('YYYY-MM-DD')}`
      );

      return data;
    }
  );
  const duplicateEstimatedStock = humps.camelizeKeys(
    data?.data?.estimated_materials
  ) as Todo[];

  return { duplicateEstimatedStock };
};

export default useDuplicateEstimatedStock;
