import styled from 'styled-components';

export const Wrapper = styled.div`
  display: flex;
  width: 100vw;
  overflow-x: scroll;
`;

export const TabArea = styled.div`
  background-color: #222e3e;
  cursor: pointer;
`;

export const Tab = styled.div<{
  active: boolean;
  tabCount: number;
  bgColor: string;
}>`
  background-color: ${(props) => (props.active ? props.bgColor : '#F8F7F4')};
  padding-top: 16px;
  width: calc(100vw / ${(props) => props.tabCount});
  height: 64px;
  line-height: 30px;
  text-align: center;
  color: ${(props) => (props.active ? '#FFFFFF' : 'rgba(51, 51, 51, 0.5)')};
  font-weight: ${(props) => (props.active ? '900' : '400')};
  font-family: 'Noto Sans JP';
  font-style: normal;
  font-size: 32px;
  line-height: 32px;
  align-items: center;
  text-align: center;
  user-select: none;
  min-width: 200px;
`;

export const CircularIconWrapperOuter = styled.div`
  width: 100vw;
  height: 100vh;
  position: relative;
`;

export const CircularIconWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  top: 40%;
  left: 48%;
`;
