import { useEffect, useState } from 'react';
import * as S from './PickingInfoByProduct.styles';
import PickingForm from '@components/modules/mobile/picking_edit/PickingForm/PickingForm';
import {
  DetailsByProduct,
  PickingBreakdownDetail,
} from '@lib/mobile/picking_edit/type';
import { ReactComponent as CheckedIcon } from '@assets/icons/mobile/check.svg';
import { ReactComponent as CheckIcon } from '@assets/icons/mobile/check_circle_outline_24px.svg';
import PickingDetailByDate from '@components/modules/mobile/picking_edit/PickingDetailByDate/PickingDetailByDate';
import PickingDetailByDateWithChecked from '@components/modules/mobile/picking_edit/PickingDetailByDateWithChecked/PickingDetailByDateWithChecked';
import PickingDetailByShipping from '@components/modules/mobile/picking_edit/PickingDetailByShipping/PickingDetailByShipping';
// import { useWindowScroll } from 'react-use';

type Props = {
  searchProductChar: string;
  completedCheckState: boolean;
  detailsByProduct: DetailsByProduct[];
  setPickingStatus: (pickingStatus: string) => void;
  // janCode: string;
  fromScannerResult?: boolean;
  fixedAreaHeight?: string;
};

const PickingInfoByProduct = ({
  searchProductChar,
  completedCheckState,
  detailsByProduct,
  setPickingStatus,
  // janCode,
  fromScannerResult,
  fixedAreaHeight,
}: Props) => {
  // 更新する全体のデータ
  const [newData, setNewData] = useState<DetailsByProduct[]>(detailsByProduct);
  const [shortageAmount, setShortageAmount] = useState(0);

  useEffect(() => {
    setNewData(detailsByProduct);
  }, [detailsByProduct]);

  // ページ全体のチェック状態によりstateを設定
  useEffect(() => {
    const checkStateGroup = newData?.map((obj) => obj.checkState);
    const isAllEqual = (array: boolean[]) =>
      array?.every((value: boolean) => value === array[0]);
    if (isAllEqual(checkStateGroup)) {
      // すべて同じ値の場合
      if (checkStateGroup[0]) {
        setPickingStatus('readyForShipment');
      } else {
        setPickingStatus('pickingNotStarted');
      }
    } else {
      // 混在している場合
      setPickingStatus('picking');
    }
  }, [newData]);

  // チェックボタン押下時
  const handleCheckState = (checkState: boolean, id: number) => {
    setNewData((prevState: DetailsByProduct[]) =>
      prevState.map((obj: DetailsByProduct) =>
        obj.id === id ? { ...obj, checkState: checkState } : obj
      )
    );
    // TODO:ここでバックエンドへ更新に行く
  };

  // ピッキング入力フォーム追加時
  // 全体のnewDataを更新する処理
  const handleUpdateIndexNewData = (
    newDetailData: PickingBreakdownDetail[],
    detailByProductId: number,
    shortageAmount: number
  ) => {
    setNewData((prevState: DetailsByProduct[]) =>
      prevState.map((obj: DetailsByProduct) =>
        obj.id === detailByProductId
          ? {
              ...obj,
              pickingBreakdownDetail: newDetailData,
              shortageAmount: shortageAmount,
            }
          : obj
      )
    );
  };

  return (
    <S.Wrapper>
      {newData &&
        newData
          // searchCharは出荷先グループ検索のための項目のため、filter不要
          .filter(
            (p: DetailsByProduct) => p?.name?.includes(searchProductChar)
            // p.name.includes(searchProductChar) || p.janCode === janCode
          )
          .filter((p: DetailsByProduct) =>
            completedCheckState
              ? p.checkState === false
              : p.checkState === true || p.checkState === false
          )
          .map((p: DetailsByProduct, index: number) => (
            <S.ProductFormSection key={index}>
              <S.StickyContainer
                // scrollPosition={currentScrollPosition}
                fromScannerResult={fromScannerResult}
              >
                <S.StickyProductTitle
                  className="product-title"
                  checked={p.checkState}
                  // scrollPosition={currentScrollPosition}
                  fromScannerResult={fromScannerResult}
                  fixedAreaHeight={fixedAreaHeight}
                >
                  <S.ProductImageContainer>
                    <div
                      style={{
                        width: '45px',
                        height: '45px',
                        background: '#ccc',
                      }}
                    />
                  </S.ProductImageContainer>
                  <S.Name>
                    <p>{p.name}</p>
                    {p.productCode}
                  </S.Name>
                  <S.CheckArea
                    onClick={() => {
                      handleCheckState(!p.checkState, p.id);
                    }}
                    checked={p.checkState}
                  >
                    <S.CheckIconWrapper>
                      {p.checkState === true ? <CheckedIcon /> : <CheckIcon />}
                    </S.CheckIconWrapper>
                    <S.StatusName checked={p.checkState}>
                      {p.checkState === true ? (
                        <span>チェック済</span>
                      ) : (
                        <span>チェック</span>
                      )}
                    </S.StatusName>
                  </S.CheckArea>
                </S.StickyProductTitle>
                {p.shortageAmount < 0 && (
                  <S.MessageText error={true}>
                    【要内訳確認】{-p.shortageAmount}個多いです
                  </S.MessageText>
                )}
                {p.shortageAmount > 0 && (
                  <S.MessageText>
                    あと{p.shortageAmount}個、ピッキングしてください
                  </S.MessageText>
                )}
                {/* 台車・ケース・バラ・合計 */}
                <S.Amount checked={p.checkState}>
                  <S.DRightBottomWrapper>
                    {p.cart && (
                      <>
                        <S.DInputContainer center={true} checked={p.checkState}>
                          <label htmlFor={`new-stock-cart-${index}`}>
                            台車
                          </label>
                          <input
                            id={`new-picking-case-${index}`}
                            value={p.cart}
                            disabled
                          />
                          <span>×{p.casesPerCart}ｹｰｽ</span>
                        </S.DInputContainer>
                        <S.DSymbol>+</S.DSymbol>
                      </>
                    )}
                    <S.DInputContainer center={true} checked={p.checkState}>
                      <label htmlFor={`new-stock-case-${index}`}>ケース</label>
                      <input
                        id={`new-picking-case-${index}`}
                        value={p.case}
                        disabled
                      />
                      <span>×{p.piecesPerBox}個</span>
                    </S.DInputContainer>
                    <S.DSymbol>+</S.DSymbol>
                    <S.DInputContainer checked={p.checkState}>
                      <label htmlFor={`new-stock-bara-${index}`}>個</label>
                      <input
                        id={`new-picking-case-${index}`}
                        value={p.piece}
                        disabled
                      />
                    </S.DInputContainer>
                    <S.DSymbol>=</S.DSymbol>
                    <S.DInputContainer checked={p.checkState}>
                      <label htmlFor="new-stock-total">合計</label>
                      <input
                        id={`new-picking-case-${index}`}
                        value={p.total}
                        disabled
                      />
                    </S.DInputContainer>
                  </S.DRightBottomWrapper>
                </S.Amount>
                {/* ピッキングした内訳 */}
                {p.checkState ? (
                  <PickingDetailByDateWithChecked
                    casesPerCart={p.casesPerCart}
                    piecesPerBox={p.piecesPerBox}
                    pickingBreakdownDetail={p.pickingBreakdownDetail}
                  />
                ) : (
                  <PickingDetailByDate
                    detailByProductId={p.id}
                    casesPerCart={p.casesPerCart}
                    piecesPerBox={p.piecesPerBox}
                    totalOfDetailsByProduct={p.total}
                    shortageAmount={shortageAmount}
                    setShortageAmount={setShortageAmount}
                    pickingBreakdownDetail={p.pickingBreakdownDetail}
                    handleUpdateIndexNewData={handleUpdateIndexNewData}
                    newData={newData}
                    setNewData={setNewData}
                  />
                )}
                {/* 出荷先内訳 */}
                <PickingDetailByShipping
                  destinationBreakdownDetail={p.destinationBreakdownDetail}
                />
                <PickingForm />
              </S.StickyContainer>
            </S.ProductFormSection>
          ))}
    </S.Wrapper>
  );
};

export default PickingInfoByProduct;
