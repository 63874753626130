import { axiosInstance } from '@lib/pc/common/api/axiosConfig';
import { useQuery } from 'react-query';
import humps from 'humps';
import type { Todo } from '@lib/common/type';

const useMenuFunctions = () => {
  return useQuery('menu_functions', async () => {
    const { data } = await axiosInstance().get(`/api/v1/user_and_roles`);
    return humps.camelizeKeys(data.functions) as Todo[];
  });
};

export default useMenuFunctions;
