import { axiosInstance } from '@lib/pc/common/api/axiosConfig';
import { useQuery } from 'react-query';
import humps from 'humps';
// import type { Todo } from '@lib/common/type';
import { MaterialOrders } from '@lib/pc/purchase_orders/type';

const useMaterialOrders = () => {
  const { data, refetch } = useQuery(['material_orders'], async () => {
    const data = await axiosInstance().get('/api/v1/material_orders');
    return data;
  });
  const materialOrders =
    data && (humps.camelizeKeys(data.data) as unknown as MaterialOrders);
  return { materialOrders, refetch };
};

export default useMaterialOrders;
