import styled from 'styled-components';

export const Wrapper = styled.div<{
  fixedAreaHeight: string;
}>`
  width: 100vw;
  background-color: #f9f5d2;
  box-sizing: border-box;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 0px;

  :last-child {
    margin-bottom: 100px;
  }
`;
