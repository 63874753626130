import styled from 'styled-components';

export const Wrapper = styled.div<{
  open?: boolean;
}>`
  width: 100vw;
  height: 100vh;
  top: 7%;
  position: fixed;
  transition: all 0.5s ease-out;
  z-index: 10001;
  overflow-x: hidden;
  background-color: white;
  display: flex;
  margin: 0 auto;
  &.video {
    width: 100%;
    height: 100vh;
    position: absolute;
  }
  &.canvas {
    position: absolute;
  }
`;

export const HeadContainer = styled.div`
  position: fixed;
  z-index: 11000;
  right: 0;
  left: 0;
  height: 64px;
  background-color: rgba(0, 0, 0, 0.7);
  color: #fff;
  display: left;
  flex-direction: row;
`;

/* 戻るボタン */
export const ReturnButton = styled.div`
  cursor: pointer;
  position: fixed;
  height: 64px;
  padding: 0.5rem;
  display: flex;
  align-items: center;
  font-size: 1rem;
  font-weight: bold;
  gap: 0.5rem;
  svg path {
    fill: #fff;
  }
`;

/* タイトル */
export const HeadingWrapper = styled.div`
  font-weight: 700;
  width: 100%;
  height: 64px;
  text-align: center;
  display: inline-block;
  justify-content: center;
  align-items: center;
  vertical-align: bottom;
  padding: 0.5rem;
`;

/* タイトル：賞味期限 */
export const MainTitle = styled.div`
  font-size: 32px;
  line-height: 46px;
  font-feature-settings: 'halt' on;
  display: inline-block;
`;

/* タイトル：を撮影してください */
export const SubTitle = styled.div`
  font-size: 18px;
  line-height: 26px;
  font-feature-settings: 'halt' on;
  display: inline-block;
`;

/* カメラボタン */
export const CameraButton = styled.div`
  cursor: pointer;
  position: absolute;
  width: 80px;
  height: 80px;
  top: 365px;
  right: 10px;
  border-radius: 50%;
  background-color: rgba(0, 0, 0, 0.7);
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  svg {
    width: 40px;
    height: 40px;
  }
  svg path {
    fill: #fff;
  }
`;

export const Canvas = styled.div`
  /* 非表示 */
  display: none;
  canvas {
    background-color: black;
  }
`;
