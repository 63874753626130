import { useQuery } from 'react-query';
import humps from 'humps';
import { axiosInstance } from '@lib/pc/common/api/axiosConfig';
import { CurrentUserEmployee } from '../supplier_edit/type';

const useEmployees = (roleName: string) => {
  const { data, refetch } = useQuery(['employees_for_create'], async () => {
    const data = await axiosInstance().get(
      `/api/v1/settings/employees?role_name=${roleName}`
    );
    return data;
  });
  const employees = humps.camelizeKeys(
    data?.data?.employees
  ) as CurrentUserEmployee[];

  return { employees, refetch };
};

export default useEmployees;
