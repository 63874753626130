import * as S from './StoreRoomAllocationTypeModal.styles';
import { ReactComponent as FilterListIcon } from '@assets/icons/mobile/filter_list.svg';
import StoreroomSelect from '@components/modules/pc/material_allocation_status/StoreroomSelect/StoreroomSelect';
import StockAllocationTransferSelect from '@components/modules/pc/material_allocation_status/StockAllocationTransferSelect/StockAllocationTransferSelect';
import type { AllocationType, Storeroom, Todo } from '@lib/common/type';

type Props = {
  allocationMenuActive: boolean;
  storerooms: Storeroom[] | undefined;
  stockAllocationsAll: Todo[];
  selectedAllocationTypeId: number;
  setSelectedAllocationTypeId: (selectedAllocationTypeId: number) => void;
  selectedStoreroomId: number;
  setSelectedStoreroomId: (selectedStoreroomId: number) => void;
  handleClose: () => void;
  allocationTypeOptions: AllocationType[];
};

const StoreRoomAllocationTypeModal: React.FC<Props> = ({
  allocationMenuActive,
  storerooms,
  selectedAllocationTypeId,
  setSelectedAllocationTypeId,
  selectedStoreroomId,
  setSelectedStoreroomId,
  allocationTypeOptions,
  handleClose,
}: Props) => {
  if (!storerooms) return null;

  return (
    <S.Wrapper open={allocationMenuActive}>
      <S.Header>
        <S.HeaderTitle>
          <FilterListIcon />
          <S.HeaderTitleText>払出先、払出元の絞り込み</S.HeaderTitleText>
        </S.HeaderTitle>
      </S.Header>

      <S.SelectWrapper>
        {/* 払出元の選択 */}
        <StoreroomSelect
          data={storerooms}
          value={selectedStoreroomId}
          width="235px"
          height="54px"
          setSelectedId={setSelectedStoreroomId}
        />
        {/* 払出先の選択 */}
        <StockAllocationTransferSelect
          value={selectedAllocationTypeId}
          handleChangeTransfer={setSelectedAllocationTypeId}
          handleClose={handleClose}
          allocationTypeOptions={allocationTypeOptions}
        />
      </S.SelectWrapper>
    </S.Wrapper>
  );
};
export default StoreRoomAllocationTypeModal;
