/* eslint-disable @typescript-eslint/no-explicit-any */
import { useCallback, useLayoutEffect } from 'react';
import PropTypes from 'prop-types';
import Quagga from '@ericblade/quagga2';

function getMedian(arr: any[]) {
  arr.sort((a: number, b: number) => a - b);
  const half = Math.floor(arr.length / 2);
  if (arr.length % 2 === 1) {
    return arr[half];
  }
  return (arr[half - 1] + arr[half]) / 2;
}

function getMedianOfCodeErrors(decodedCodes: any[]) {
  const errors = decodedCodes
    .filter((x: { error: undefined }) => x.error !== undefined)
    .map((x: { error: any }) => x.error);
  const medianOfErrors = getMedian(errors);
  return medianOfErrors;
}

const defaultConstraints = {
  width: window.innerWidth,
  height: window.innerHeight * 0.56,
};

const defaultLocatorSettings = {
  patchSize: 'large',
  halfSample: true,
};

const defaultDecoders = ['ean_reader'];

const Scanner = ({
  onDetected,
  scannerRef,
  onScannerReady,
  cameraId,
  facingMode,
  constraints = defaultConstraints,
  locator = defaultLocatorSettings,
  numOfWorkers = navigator.hardwareConcurrency || 0,
  decoders = defaultDecoders,
  locate = true,
}: any) => {
  const scanResults: any[] = [];

  const errorCheck = useCallback(
    (result) => {
      if (!onDetected) {
        return;
      }
      // １つでもエラー率0.19以上があれば除外
      const isErr = result.codeResult.decodedCodes.some(
        ({ error }: { error: any }) =>
          error != undefined && parseFloat(error) > 0.19
      );

      if (isErr) return;

      // エラー率の中央値が0.1以上なら除外
      const median = getMedianOfCodeErrors(result.codeResult.decodedCodes);
      if (median > 0.1) return;

      // 3回連続で同じ値だった場合のみ採用
      scanResults.push(result.codeResult.code);
      if (scanResults.length < 3) {
        return;
      }
      let is_same_all = false;
      if (scanResults.every((v) => v === scanResults[0])) {
        is_same_all = true;
      }
      if (!is_same_all) {
        scanResults.shift();
        return;
      }

      onDetected(result);
    },
    [onDetected]
  );

  const handleProcessed = (result: {
    boxes: any[];
    box: any;
    codeResult: { code: any };
    line: any;
  }) => {
    const drawingCtx = Quagga.canvas.ctx.overlay;
    // const drawingCanvas = Quagga.canvas.dom.overlay;
    drawingCtx.font = '24px Arial';
    drawingCtx.fillStyle = 'green';

    if (result) {
      // console.warn('* quagga onProcessed', result);
      // if (result.boxes) {
      //   drawingCtx.clearRect(
      //     0,
      //     0,
      //     Number(drawingCanvas.getAttribute('width')),
      //     Number(drawingCanvas.getAttribute('height'))
      //   );
      //   result.boxes
      //     .filter((box: any) => box !== result.box)
      //     .forEach((box: any) => {
      //       Quagga.ImageDebug.drawPath(box, { x: 0, y: 1 }, drawingCtx, {
      //         color: 'purple',
      //         lineWidth: 2,
      //       });
      //     });
      // }
      // if (result.box) {
      //   Quagga.ImageDebug.drawPath(result.box, { x: 0, y: 1 }, drawingCtx, {
      //     color: 'yellow',
      //     lineWidth: 2,
      //   });
      // }
      // // 下記、表示される緑色の数字。7/12
      // if (result.codeResult && result.codeResult.code) {
      //   // const validated = barcodeValidator(result.codeResult.code);
      //   // const validated = validateBarcode(result.codeResult.code);
      //   // Quagga.ImageDebug.drawPath(result.line, { x: 'x', y: 'y' }, drawingCtx, { color: validated ? 'green' : 'red', lineWidth: 3 });
      //   drawingCtx.font = '24px Arial';
      //   // drawingCtx.fillStyle = validated ? 'green' : 'red';
      //   // drawingCtx.fillText(`${result.codeResult.code} valid: ${validated}`, 10, 50);
      //   drawingCtx.fillText(result.codeResult.code, 10, 20);
      //   // if (validated) {
      //   //     onDetected(result);
      //   // }
      // }
      // // 下記、スキャナ時に表示される赤線箇所。7/12
      // if (result.codeResult && result.codeResult.code) {
      //   Quagga.ImageDebug.drawPath(
      //     result.line,
      //     { x: 'x', y: 'y' },
      //     drawingCtx,
      //     { color: 'red', lineWidth: 3 }
      //   );
      // }
    }
  };

  useLayoutEffect(() => {
    Quagga.init(
      {
        inputStream: {
          type: 'LiveStream',
          constraints: {
            ...constraints,
            ...(cameraId && { deviceId: cameraId }),
            ...(!cameraId && { facingMode }),
          },
          target: scannerRef.current,
        },
        locator,
        numOfWorkers,
        decoder: { readers: decoders },
        locate,
      },
      (err: any) => {
        Quagga.onProcessed(handleProcessed);

        if (err) {
          return console.log('Error starting Quagga:', err);
        }
        if (scannerRef && scannerRef.current) {
          Quagga.start();
          if (onScannerReady) {
            onScannerReady();
          }
        }
      }
    );
    Quagga.onDetected(errorCheck);
    return () => {
      Quagga.offDetected(errorCheck);
      Quagga.offProcessed(handleProcessed);
      Quagga.stop();
    };
  }, [
    cameraId,
    onDetected,
    onScannerReady,
    scannerRef,
    errorCheck,
    constraints,
    locator,
    decoders,
    locate,
  ]);
  return null;
};

Scanner.propTypes = {
  onDetected: PropTypes.func.isRequired,
  scannerRef: PropTypes.object.isRequired,
  onScannerReady: PropTypes.func,
  cameraId: PropTypes.string,
  facingMode: PropTypes.string,
  constraints: PropTypes.object,
  locator: PropTypes.object,
  numOfWorkers: PropTypes.number,
  decoders: PropTypes.array,
  locate: PropTypes.bool,
};

export default Scanner;
