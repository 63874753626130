import styled, { css } from 'styled-components';

export const Wrapper = styled.div`
  max-width: 100vw;
  max-height: 100vh;
  height: calc(100vh - 143px);
  overflow: scroll;
  position: relative;
  background: ${(props) => props.theme.palette.beige};
`;

export const ListWrapper = styled.div`
  max-width: 100vw;
  max-height: 100vh;
  height: calc(100vh - 165px);

  overflow: scroll;
  display: flex;
  position: relative;
  background: ${(props) => props.theme.palette.beige};
`;

/**
 *
 * リスト
 *
 */
export const ListHeader = styled.div`
  display: flex;
  align-items: center;

  box-shadow: 1px 3px 5px 0px rgba(0, 0, 0, 0.2);
`;

const highLightenHeadCell = css`
  color: ${(props) => props.theme.palette.white}!important;
  background: ${(props) => props.theme.palette.blue}!important;
  border-color: ${(props) => props.theme.palette.blue}!important;
`;

const highLightenCell = css`
  position: relative;
  border-right: 2px solid ${(props) => props.theme.palette.blue};
  border-left: 2px solid ${(props) => props.theme.palette.blue};
`;

const cornerArrow = css`
  ::before,
  ::after {
    content: '';
    position: absolute;
    top: 0;
    right: 0;
    border-color: transparent;
    border-style: solid;
  }
  ::before {
    border-width: 0.4rem;
  }
  ::after {
    border-width: 0.4rem;
    border-right-color: #c74141;
    border-top-color: #c74141;
  }
`;

export const ListHeadCell = styled.div<{
  highLighten: boolean;
  dayOfWeek?: string;
  index: number;
  numOfIndex: number;
}>`
  height: 50px;
  width: 91px;
  box-sizing: border-box;
  padding: 0.5rem 0;

  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  position: sticky;

  /* 前年同月 */
  :last-child {
    position: sticky;
    top: 0;
    right: 0;
    border-left: 2px double ${(props) => props.theme.palette.sandGray};
    font-weight: 700;
  }

  /* 合計 */
  :nth-last-child(2) {
    position: sticky;
    top: 0;
    right: 91px;
    border-left: 2px double ${(props) => props.theme.palette.sandGray};
    font-weight: 700;
  }

  :nth-child(odd) {
    background: ${(props) => props.theme.palette.beige};
  }

  :nth-child(even) {
    background: ${(props) => props.theme.palette.white};
  }

  ${({ highLighten }) => (highLighten ? highLightenHeadCell : null)}

  color: ${(props) =>
    props.dayOfWeek === '土' && props.index <= props.numOfIndex
      ? '#1046cf'
      : props.dayOfWeek === '日' && props.index <= props.numOfIndex
      ? '#c74141'
      : ''};
`;

export const ListCell = styled.div<{
  highLighten: boolean;
}>`
  cursor: pointer;

  height: 50px;
  width: 80px;
  box-sizing: border-box;
  padding: 0.5rem 0;
  border-spacing: 0;
  display: flex;
  justify-content: center;
  align-items: center;

  ${({ highLighten }) => (highLighten ? highLightenCell : null)}
`;

export const ListCellContainer = styled.div<{
  highLighten?: boolean;
  withCheckEnabled?: boolean;
  invalid?: boolean;
  isCursor?: boolean;
  isTodayOrEarlier?: boolean;
}>`
  position: relative;
  cursor: ${(props) => (props.isCursor ? 'pointer' : '')};
  height: 50px;
  width: 91px;
  box-sizing: border-box;
  padding: 0.5rem 0;
  display: flex;
  justify-content: center;
  align-items: center;
  border-bottom: 1px solid ${(props) => props.theme.palette.yellowGray};
  color: ${(props) => (props.isTodayOrEarlier ? '' : '#797979')};
  font-weight: ${(props) => (props.withCheckEnabled ? 'bold' : '')};
  background-color: ${(props) =>
    props.withCheckEnabled ? '#FDDEDE!important' : ''};
  ${({ withCheckEnabled }) => (withCheckEnabled ? cornerArrow : null)};

  ${({ highLighten }) => (highLighten ? highLightenCell : null)};
  pointer-events: ${(props) => (props.invalid ? 'none' : '')};

  /* 前年同月 */
  :last-child {
    position: sticky;
    top: 0;
    right: 0;
    border-left: 2px double ${(props) => props.theme.palette.sandGray};
    font-weight: 700;
    background: ${(props) => props.theme.palette.white};
    pointer-events: none;
  }

  /* 合計 */
  :nth-last-child(2) {
    position: sticky;
    top: 0;
    right: 91px;
    border-left: 2px double ${(props) => props.theme.palette.sandGray};
    font-weight: 700;
    background: ${(props) => props.theme.palette.beige};
    pointer-events: none;
  }

  &.select-cell {
    border: 2px solid ${(props) => props.theme.palette.primary.main};
  }
`;

export const ListBody = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  z-index: 2;
  position: relative;
  height: max-content;
  -webkit-transition: transform 320ms ease-in-out;
  -moz-transition: transform 320ms ease-in-out;
  -ms-transition: transform 320ms ease-in-out;
  -o-transition: transform 320ms ease-in-out;
  transition: transform 320ms ease-in-out;
  &.move-to-left {
    -webkit-transform: translateX(-390px);
    -moz-transform: translateX(-390px);
    -ms-transform: translateX(-390px);
    -o-transform: translateX(-390px);
    transform: translateX(-390px);
  }

  & > div:nth-child(odd) {
    background: ${(props) => props.theme.palette.paleYellow};
  }

  & > div:nth-child(even) {
    background: ${(props) => props.theme.palette.white};
  }
  & > div:not(:first-child):nth-child(even) > div:nth-child(odd) {
    background: ${(props) => props.theme.palette.beige};
  }
  & > div:not(:first-child):nth-child(odd) > div:nth-child(odd) {
    background: ${(props) => props.theme.palette.yellowGray};
  }
`;

export const ListRow = styled.div`
  display: flex;
  background: #fff;

  :first-child {
    position: -webkit-sticky; /* for Safari */
    position: sticky;
    top: -1px;
    z-index: 4;
    height: 50px;
    box-shadow: 1px 3px 5px 0px rgba(0, 0, 0, 0.2);
  }
`;

export const ListSidebar = styled.div`
  min-width: 270px;
  height: max-content;
  border-right: 1px solid ${(props) => props.theme.palette.yellowGray};
  background: #fff;
  background: ${(props) => props.theme.palette.beige};

  position: sticky;
  left: -1px;
  z-index: 5;

  & > div:first-child {
    background: ${(props) => props.theme.palette.paleYellow};

    position: -webkit-sticky; /* for Safari */
    position: sticky;
    top: -1px;
    /* padding: 1px; */
    background: ${(props) => props.theme.palette.yellowGray};
    z-index: 1000;
  }
`;

export const ListLeftHeadCell = styled.div<{
  isBlue?: boolean;
  isProductName?: boolean;
}>`
  cursor: ${(props) => (props.isProductName ? '' : 'pointer')};
  height: 50px;
  min-width: 270px;
  border-bottom: 1px solid ${(props) => props.theme.palette.yellowGray};
  box-shadow: 4px 3px 5px 0px rgba(0, 0, 0, 0.05);
  position: -webkit-sticky; /* for Safari */
  -webkit-transform: translateZ(0);
  display: flex;
  justify-content: space-between;
  padding: 8px 1rem;
  gap: 0.25rem;

  display: flex;
  flex-direction: column;

  > span {
    font-size: 0.75rem;
    &.filter-product {
      width: 90%;
      padding-left: 30px;
      height: 32px;
      line-height: 32px;
      position: absolute;
      background: ${(props) => props.theme.palette.white};
      color: ${(props) =>
        props.isBlue ? props.theme.palette.blue : '#9ca3af'};
      border-radius: 0.5rem;
      cursor: pointer;

      > svg {
        position: absolute;
        top: 12%;
        left: 5%;
      }

      > div {
        padding-left: 15px;
        font-size: 16px;
      }
    }
  }

  /* 商品の絞り込み */
  //COMMENT: OKであれば後で書き方変えたほうがいい
  :first-child {
    background-color: #f5f2e6 !important;
    position: -webkit-sticky; /* for Safari */
    position: sticky;
    > div > input {
      cursor: pointer;
      height: 27px;
      /* height: 32px; */
      padding: 0.5rem 40px 0.5rem;
    }
  }

  :nth-child(odd) {
    background: ${(props) => props.theme.palette.paleYellow};
  }
  :nth-child(even) {
    background: ${(props) => props.theme.palette.white};
  }
`;

export const ListLeftHeadName = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

export const ListLeftHeadMeta = styled.div`
  color: rgba(51, 51, 51, 0.5);
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-size: 0.75rem;
  > span {
    font-weight: 700;
  }
`;

export const CircularIconWrapper = styled.div`
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 10001;
  background-color: ${(props) => props.theme.palette.beige} !important;
`;
