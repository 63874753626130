import { useState } from 'react';
import * as S from './SupplierInfo.styles';
import { ReactComponent as CircleCloseIcon } from '@assets/icons/circle_close.svg';
import { ReactComponent as PencilIcon } from '@assets/icons/pencil.svg';
import ChevronLeft from '@assets/icons/chevron_left';
import ProductImg from '@assets/images/product-img.jpg';
import { Button } from '@components/elements/buttons/Button';
import DeleteConfirmModal from '@components/modules/common/DeleteConfirmModal/DeleteConfirmModal';
import ErrorMsgPopUp from '@components/modules/common/ErrorMsgPopUp/ErrorMsgPopUp';
import Overlay from '@components/elements/backdrops/Overlay/Overlay';
import SupplierSelect from '@components/modules/pc/settings/material_master_setting/MaterialMasterEdit/SupplierSelect/SupplierSelect';
import { Supplier, Todo } from '@lib/common/type';
import { MaterialsAndSupplier } from '@lib/pc/settings/material_master_edit/type';
import useUpdateMaterialAndSuppliersMutation from '@lib/pc/settings/material_master_edit/useUpdateMaterialAndSuppliersMutation';
import useSuppliersForMaterialSetting from '@lib/pc/settings/material_master_edit/useSuppliersForMaterialSetting';

type Props = {
  material: Todo;
  updating: boolean;
  setUpdating: (updating: boolean) => void;
  onClickGoBack: () => void;
  handleUpdateSuccess: () => void;
  setIsChanged: (isChanged: boolean) => void;
  productFromInfo: Todo;
  enableToSave: boolean;
  setEnableToSave: (enableToSave: boolean) => void;
  caseEditMode: boolean;
  setCaseEditMode: (caseEditMode: boolean) => void;
};

const SupplierInfo = ({
  material,
  updating,
  setUpdating,
  onClickGoBack,
  setIsChanged,
  handleUpdateSuccess,
  enableToSave,
  setEnableToSave,
  caseEditMode,
  setCaseEditMode,
}: Props) => {
  const [selectedSupplierId, setSelectedSupplierId] = useState(0);
  const [newLeadTime, setNewLeadTime] = useState<string | null>('' || null);
  const [newPricePerUnit, setNewPricePerUnit] = useState<string | null>(
    '' || null
  );
  const [newPriorityNumber, setNewPriorityNumber] = useState<string | null>(
    '' || null
  );

  // 下位のコンポーネントに渡しているのがネストの深いオブジェクトなので、強制再レンダリングする必要がある
  const [, setToggleValue] = useState(false);
  const reRender = () => setToggleValue((prev) => !prev);
  // 削除確認メッセージ
  const [confirmMsg, setConfirmMsg] = useState('');
  const [errMsg, setErrMsg] = useState('');
  // 削除No
  const [deleteNo, setDeleteNo] = useState(0);
  const [editOpen, setEditOpen] = useState(false);

  const { suppliers: allSuppliers } = useSuppliersForMaterialSetting();

  const newDataForUpdate = material?.materialsAndSuppliers
    ? material?.materialsAndSuppliers.map((value: MaterialsAndSupplier) => {
        return {
          materialId: material.material.id,
          name: value.name,
          supplierId: value.id,
          deliveryLeadTime: value.deliveryLeadTime,
          pricePerUnit: value.pricePerUnit,
          orderPriority: value.orderPriority,
          editMode: false,
        };
      })
    : [];

  // 更新に使用
  const [newMaterialAndSuppliersArray, setNewMaterialAndSuppliersArray] =
    useState<MaterialsAndSupplier[]>(newDataForUpdate);

  // supplierIdだけ取り出し
  const supplierIds = newMaterialAndSuppliersArray.map(
    (item: MaterialsAndSupplier) => item['supplierId']
  );

  // すでに登録済みの仕入先を除く
  const filteredSuppliers = allSuppliers?.filter((value: Supplier) => {
    if (!supplierIds.includes(value.id)) {
      return { id: value.id, name: value.name };
    }
  });

  // 資材・原材料と仕入先
  const updateMaterialAndSuppliersMutation =
    useUpdateMaterialAndSuppliersMutation(
      material?.id || material?.material.id,
      handleUpdateSuccess,
      setErrMsg
    );

  // ***********************
  // [保存ボタン]押下、更新処理
  // ***********************
  const handleSubmit = () => {
    setUpdating(true);
    updateMaterialAndSuppliersMutation.mutate(newMaterialAndSuppliersArray);
  };

  const handleChangeLeadTime = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.value.match(/^[0-9]*$/)) {
      setNewLeadTime(e.target.value);
    }
    setIsChanged(true);
  };

  // 単位あたり価格（小数点以下10桁まで入力可能）
  const handleChangePricePerUnit = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.value.match(/^\d*\.?\d{0,10}$/)) {
      setNewPricePerUnit(e.target.value);
    }
    setIsChanged(true);
  };

  const handleChangePriorityNumber = (
    e: React.ChangeEvent<HTMLInputElement>
  ) => {
    if (e.target.value.match(/^[0-9]*$/)) {
      setNewPriorityNumber(e.target.value);
    }
    setIsChanged(true);
  };

  // 追加ボタン
  const onClickAddButton = () => {
    const selectedSupplier = filteredSuppliers?.find(
      (s: Supplier) => s.id === selectedSupplierId
    );

    if (selectedSupplier) {
      newMaterialAndSuppliersArray.push({
        id: material.id || material.material.id,
        name: selectedSupplier.name || selectedSupplier.name,
        supplierId: selectedSupplier.id,
        deliveryLeadTime: newLeadTime ? Number(newLeadTime) : '',
        pricePerUnit: Number(newPricePerUnit),
        orderPriority: newPriorityNumber ? Number(newPriorityNumber) : '',
        editMode: false,
      });
    }
    setIsChanged(true);
    reRender();
    setSelectedSupplierId(0);
    setNewLeadTime(null);
    setNewPricePerUnit(null);
    setNewPriorityNumber(null);
    setEnableToSave(true);
  };

  // 削除ボタン
  const onClickDelButton = (no: number, name: string) => {
    setConfirmMsg(`${name}を本当に削除しますか？`);
    setDeleteNo(no);
  };

  const deleteFunction = () => {
    setIsChanged(true);
    newMaterialAndSuppliersArray.splice(deleteNo, 1);
    setNewMaterialAndSuppliersArray([...newMaterialAndSuppliersArray]);
    setConfirmMsg('');
    setEnableToSave(true);
  };

  const onClickEdit = (index: number) => {
    setEditOpen(!editOpen);
    setCaseEditMode(true);
    newMaterialAndSuppliersArray[index].editMode = true;
  };

  const [isEdited, setIsEdited] = useState(false);

  // 単位あたり価格更新
  const handleChangePricePerUnitUpdate = (
    e: React.ChangeEvent<HTMLInputElement>,
    index: number
  ) => {
    setEnableToSave(true);
    setIsEdited(!isEdited);
    setIsChanged(true);
    console.log(e, index);
    if (e.target.value.match(/^[0-9]*$/)) {
      newMaterialAndSuppliersArray[index].pricePerUnit = Number(e.target.value);
    }
  };

  // リードタイム
  const handleChangeDeliveryLeadTimeUpdate = (
    e: React.ChangeEvent<HTMLInputElement>,
    index: number
  ) => {
    setEnableToSave(true);
    setIsEdited(!isEdited);
    setIsChanged(true);
    if (e.target.value.match(/^[0-9]*$/)) {
      newMaterialAndSuppliersArray[index].deliveryLeadTime = Number(
        e.target.value
      );
    }
  };

  // 優先順位更新
  const handleChangeOrderPriorityUpdate = (
    e: React.ChangeEvent<HTMLInputElement>,
    index: number
  ) => {
    setEnableToSave(true);
    setIsEdited(!isEdited);
    setIsChanged(true);
    console.log(e, index);
    if (e.target.value.match(/^[0-9]*$/)) {
      newMaterialAndSuppliersArray[index].orderPriority = Number(
        e.target.value
      );
    }
  };

  return (
    <>
      {material && allSuppliers && (
        <>
          <S.Wrapper>
            <div className="go-back" onClick={onClickGoBack}>
              <ChevronLeft isBlue={true} />
              <span>一覧画面へ戻る</span>
            </div>

            <S.ProductMasterEditContents>
              <div className="product-info">
                <div className="product-img">
                  <div className="img">
                    <img
                      src={
                        material?.imageUrl ||
                        material?.material?.imageUrl ||
                        ProductImg
                      }
                    />
                  </div>
                </div>
                <div className="name">
                  {material?.name || material?.material?.name}
                </div>
              </div>
              {/* 仕入先追加・編集 */}
            </S.ProductMasterEditContents>
            <S.ProductMasterEditTable>
              <thead>
                <tr>
                  <th></th>
                  <S.TitleTh>仕入先</S.TitleTh>
                  <S.TitleTh>リードタイム日数</S.TitleTh>
                  <S.TitleTh>
                    単位あたり価格（
                    {material.material.orderUnitValue}）
                  </S.TitleTh>
                  <S.TitleTh>優先順位</S.TitleTh>
                  <th></th>
                </tr>
                <tr>
                  <th></th>
                  <th>
                    <SupplierSelect
                      suppliers={filteredSuppliers}
                      value={selectedSupplierId}
                      setSelectedSupplierId={setSelectedSupplierId}
                    />
                  </th>
                  <th>
                    {/* リードタイム日数 */}
                    <S.Input
                      type="text"
                      inputMode="numeric"
                      name="deliveryLeadTime"
                      id="deliveryLeadTime"
                      autoComplete="off"
                      value={newLeadTime ? newLeadTime : ''}
                      onChange={handleChangeLeadTime}
                    />
                  </th>
                  <th>
                    {/* 単位あたり価格 */}
                    <S.Input
                      type="text"
                      inputMode="numeric"
                      name="pricePerUnit"
                      id="pricePerUnit"
                      autoComplete="off"
                      value={newPricePerUnit ? newPricePerUnit : ''}
                      onChange={handleChangePricePerUnit}
                    />
                  </th>
                  <th>
                    {/* 優先順位 */}
                    <S.Input
                      type="text"
                      inputMode="numeric"
                      name="priorityNumber"
                      id="priorityNumber"
                      autoComplete="off"
                      value={newPriorityNumber ? newPriorityNumber : ''}
                      onChange={handleChangePriorityNumber}
                    />
                  </th>
                  <th>
                    <S.AddButton>
                      <dd>
                        {selectedSupplierId ? (
                          <Button
                            children={'追加'}
                            onClick={() => onClickAddButton()}
                          />
                        ) : (
                          <Button disabled children={'追加'} />
                        )}
                      </dd>
                    </S.AddButton>
                  </th>
                </tr>
              </thead>
              <tbody>
                {newMaterialAndSuppliersArray &&
                  newMaterialAndSuppliersArray.map(
                    (pc: MaterialsAndSupplier, index: number) => {
                      return (
                        <S.SelectedCaseWrapperTr key={index}>
                          {/* 削除ボタン */}
                          <S.DeleteButtonTd>
                            <S.CloseButton
                              onClick={() => onClickDelButton(index, pc.name)}
                            >
                              <CircleCloseIcon />
                            </S.CloseButton>
                          </S.DeleteButtonTd>
                          {/* 仕入先 */}
                          <S.CaseTypeTd>
                            <span className="product-icon"></span>
                            {pc.name}
                          </S.CaseTypeTd>
                          {/* リードタイム日数 */}
                          <S.PiecesPerBoxAndDisplayOrderTd
                            editMode={pc.editMode}
                          >
                            {pc.editMode && caseEditMode ? (
                              <S.PiecesPerBoxAndDisplayOrderInput
                                type="text"
                                inputMode="numeric"
                                className="deliveryLeadTime"
                                id="deliveryLeadTime"
                                autoComplete="off"
                                value={
                                  pc.deliveryLeadTime ? pc.deliveryLeadTime : ''
                                }
                                onChange={(e) =>
                                  handleChangeDeliveryLeadTimeUpdate(e, index)
                                }
                              />
                            ) : (
                              <S.PiecesPerBoxAndDisplayOrderDiv
                                editMode={pc.editMode}
                              >
                                {pc.deliveryLeadTime}
                              </S.PiecesPerBoxAndDisplayOrderDiv>
                            )}
                          </S.PiecesPerBoxAndDisplayOrderTd>
                          {/* 単位あたり価格 */}
                          <S.PiecesPerBoxAndDisplayOrderTd
                            editMode={pc.editMode}
                          >
                            {pc.editMode && caseEditMode ? (
                              <>
                                <S.PiecesPerBoxAndDisplayOrderInput
                                  type="text"
                                  inputMode="numeric"
                                  className="pricePerUnit"
                                  id="pricePerUnit"
                                  autoComplete="off"
                                  padding={'0.75rem 2rem 0.75rem 0'}
                                  value={pc.pricePerUnit ? pc.pricePerUnit : ''}
                                  onChange={(e) =>
                                    handleChangePricePerUnitUpdate(e, index)
                                  }
                                />
                                <span>{material.material.orderUnitValue}</span>
                              </>
                            ) : (
                              <S.PiecesPerBoxAndDisplayOrderDiv
                                editMode={pc.editMode}
                              >
                                {pc.pricePerUnit}
                              </S.PiecesPerBoxAndDisplayOrderDiv>
                            )}
                          </S.PiecesPerBoxAndDisplayOrderTd>
                          {/* 優先順位 */}
                          <S.PiecesPerBoxAndDisplayOrderTd
                            editMode={pc.editMode}
                          >
                            {pc.editMode && caseEditMode ? (
                              <S.PiecesPerBoxAndDisplayOrderInput
                                type="text"
                                inputMode="numeric"
                                className="orderPriority"
                                id="orderPriority"
                                autoComplete="off"
                                value={pc.orderPriority ? pc.orderPriority : ''}
                                onChange={(e) =>
                                  handleChangeOrderPriorityUpdate(e, index)
                                }
                              />
                            ) : (
                              <S.PiecesPerBoxAndDisplayOrderDiv
                                editMode={pc.editMode}
                              >
                                {pc.orderPriority}
                              </S.PiecesPerBoxAndDisplayOrderDiv>
                            )}
                          </S.PiecesPerBoxAndDisplayOrderTd>
                          <S.EditButtonTd>
                            <S.EditButtonWrapper>
                              <S.EditButton
                                className="edit-button"
                                onClick={() => {
                                  // 編集モード
                                  onClickEdit(index);
                                }}
                              >
                                <PencilIcon className="icon" />
                              </S.EditButton>
                            </S.EditButtonWrapper>
                          </S.EditButtonTd>
                        </S.SelectedCaseWrapperTr>
                      );
                    }
                  )}
              </tbody>
            </S.ProductMasterEditTable>
          </S.Wrapper>
        </>
      )}

      <S.ButtonContainer>
        {enableToSave ? (
          <Button
            children={updating ? '保存中...' : '保存'}
            onClick={handleSubmit}
          />
        ) : (
          <Button disabled children={'保存'} />
        )}
      </S.ButtonContainer>
      {confirmMsg && (
        <DeleteConfirmModal
          confirmMsg={confirmMsg}
          onClickCancel={() => setConfirmMsg('')}
          onClickDelete={() => deleteFunction()}
        />
      )}
      <ErrorMsgPopUp
        errMsg={errMsg}
        handleClose={() => {
          setErrMsg('');
          setUpdating(false);
        }}
        fromPc={true}
        zIndex={9999}
      />
      {errMsg && (
        <Overlay
          dark
          handleClick={() => {
            setErrMsg('');
            setUpdating(false);
          }}
        />
      )}
    </>
  );
};

export default SupplierInfo;
