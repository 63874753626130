import { useEffect, useState } from 'react';
import * as S from './PickingList.styles';
import { Todo } from '@lib/common/type';
import { useHistory, useLocation } from 'react-router-dom';
import { PickingState } from '@lib/mobile/picking_edit/type';
import { useWindowScroll } from 'react-use';
import PickingListTitle from '../PickingListTitle/PickingListTitle';

type Props = {
  targetDate?: Date;
  pickingState: PickingState;
  pickings?: Todo;
  fromPickingByShippingDestination?: boolean;
  setFromDatePicker?: (fromDatePicker: boolean) => void;
  selectedStoreroomId?: number | '';
};

const PickingList = ({
  targetDate,
  pickingState,
  pickings,
  fromPickingByShippingDestination,
  setFromDatePicker,
  selectedStoreroomId,
}: Props) => {
  const history = useHistory();
  const { state } = useLocation<PickingState>();
  // スクロール位置state
  const [stateScrollPosition, setScrollPosition] = useState(0);
  const [selectedStoreroomInfo, setSelectedStoreroomInfo] = useState(0);
  const { y: currentScrollPosition } = useWindowScroll();

  const handleClick = (p: Todo, storeroomSelectedPickingStatus: number) => {
    if (fromPickingByShippingDestination) {
      // 出荷先ピッキング個別画面へ遷移
      setFromDatePicker?.(false);
      if (state === undefined) {
        history.push(`/mobile/picking_by_shipping_edit/${p.id}`, {
          ...pickingState,
          stateShippingDate: targetDate,
          stateShippings: pickings?.pickings,
          stateScrollPosition: stateScrollPosition,
          stateSelectedStoreroomId: selectedStoreroomId,
          stateClickedPickingStatus: storeroomSelectedPickingStatus,
          // stateClickedPickingStatus: p.pickingStatus,
        });
      } else {
        history.push(`/mobile/picking_by_shipping_edit/${p.id}`, {
          ...state,
          stateShippingDate: targetDate,
          stateShippings: state.stateShippings,
          stateScrollPosition: stateScrollPosition,
          stateSelectedStoreroomId: state.stateSelectedStoreroomId,
          stateClickedPickingStatus: storeroomSelectedPickingStatus,
          // stateClickedPickingStatus: p.pickingStatus,
        });
      }
    } else {
      // 商品別ピッキング個別画面へ遷移
      history.push(`/mobile/picking_detail_edit/${p.id}`, pickingState);
    }
  };

  // スクロール位置保存
  useEffect(() => {
    setScrollPosition(currentScrollPosition);
  }, [currentScrollPosition]);

  return (
    <S.Table>
      {pickings &&
        pickings.pickings
          ?.filter((p: Todo) =>
            pickingState.stateSearchChar === ''
              ? p.groupName
              : p.groupName.includes(pickingState.stateSearchChar)
          )
          .filter((p: Todo) =>
            pickingState.stateSelectedStoreroomId
              ? p.storeroomIds.includes(pickingState.stateSelectedStoreroomId)
              : p
          )
          .map((p: Todo, i: number) => (
            <thead key={p.id}>
              <PickingListTitle
                selectedStoreroomId={selectedStoreroomId && selectedStoreroomId}
                p={p}
                i={i}
                handleClick={handleClick}
                fromPickingByShippingDestination={
                  fromPickingByShippingDestination
                }
                setFromDatePicker={setFromDatePicker}
                stateScrollPosition={stateScrollPosition}
                selectedStoreroomInfo={selectedStoreroomInfo}
                setSelectedStoreroomInfo={setSelectedStoreroomInfo}
                selectedPickingStatus={
                  pickingState?.statePickingStatus?.length > 0
                    ? pickingState.statePickingStatus
                    : null
                }
              />
            </thead>
          ))}
    </S.Table>
  );
};

export default PickingList;
