import { FC, useState, useEffect, useRef } from 'react';
import moment from 'moment';
import { Button } from '@components/elements/buttons/Button';
import * as S from './RightSlideSidebar.styles';
import ScrollToTop from '@components/modules/common/ScrollToTop/ScrollToTop';
import { Todo, Accumulator, updateShipping } from '@lib/common/type';
import useShipping from '@lib/pc/shipping_status/useShipping';
import useUpdateShippingsMutation from '@lib/pc/shipping_status/useUpdateShippings';
import ConfirmPopUp from '@components/modules/common/ConfirmPopUp/ConfirmPopUp';
import Overlay from '@components/elements/backdrops/Overlay/Overlay';
import UpdatePopUp from '@components/modules/common/UpdatePopUp/UpdatePopUp';

type Props = {
  rightSlideBarOpen: boolean;
  selectedCellDate: string;
  selectedProduct: Todo;
  selectedShippingDestinationId: number;
  edited: boolean;
  confirmMsg: string;
  popUp: boolean;
  handleCloseSidebar: () => void;
  setSelectedProduct: (selectedProduct: Todo) => void;
  setEdited: (edited: boolean) => void;
  setConfirmMsg: (confirmMsg: string) => void;
  setPopUp: (popUp: boolean) => void;
};

const RightSlideSidebar: FC<Props> = ({
  rightSlideBarOpen,
  selectedCellDate,
  selectedProduct,
  selectedShippingDestinationId,
  edited,
  confirmMsg,
  popUp,
  handleCloseSidebar,
  setSelectedProduct,
  setEdited,
  setConfirmMsg,
  setPopUp,
}: Props) => {
  const editedDate = moment(selectedCellDate);
  // バックエンドからのデータ取得
  const { shippingData, refetch } = useShipping(
    editedDate,
    selectedShippingDestinationId,
    selectedProduct.id
  );

  const prevShippingDataRef = useRef();
  const [amounts, setAmounts] = useState<(string | number)[]>([]);
  const [shippingId, setShippingId] = useState<number[]>([]);
  const [shippingNames, setShippingNames] = useState<string[]>([]);
  const [destinationName, setDestinationName] = useState('');
  const [editedIndex, setEditedIndex] = useState(0);
  const [params, setParams] = useState<{ shipping: updateShipping }>({
    shipping: {
      id: 0,
      amount: 0,
    },
  });

  const handleAmountChange = (index: number, newValue: string) => {
    setAmounts(
      amounts.map((amount: number | string, i: number) =>
        i === index ? Number(newValue) : amount
      )
    );
    setEdited(true);
    setEditedIndex(index);
    setDestinationName(shippingNames[index]);
    setParams({
      shipping: {
        id: shippingId[index],
        amount: Number(newValue),
      },
    });
  };

  const handleUpdateSuccess = () => {
    setEdited(false);
    setConfirmMsg('');
    setPopUp(true);
    refetch();
  };

  // 出荷実績データ更新処理
  const updateShippingsMutation =
    useUpdateShippingsMutation(handleUpdateSuccess);

  const handleSubmit = (params: updateShipping) => {
    updateShippingsMutation.mutate(params);
  };

  const handleOk = () => {
    setParams({
      shipping: {
        id: 0,
        amount: 0,
      },
    });
    setEdited(false);
    setEditedIndex(0);
    handleCloseSidebar();
    setSelectedProduct('');
    setConfirmMsg('');
  };

  const handleCancel = () => {
    setConfirmMsg('');
  };

  const isSameOrBefore = moment(selectedCellDate).isSameOrBefore(
    moment().startOf('day')
  );

  useEffect(() => {
    // shippingData が前回と異なる場合にのみ実行
    if (
      JSON.stringify(shippingData) !==
      JSON.stringify(prevShippingDataRef.current)
    ) {
      if (shippingData?.shippings) {
        const { amounts, shippingIds, shippingNames } =
          shippingData.shippings.reduce(
            (acc: Accumulator, d: Todo) => {
              acc.amounts.push(d.amount || '');
              acc.shippingIds.push(d.shippingId || '');
              acc.shippingNames.push(d.destinationName || '');
              return acc;
            },
            { amounts: [], shippingIds: [], shippingNames: [] }
          );

        setAmounts(amounts);
        setShippingId(shippingIds);
        setShippingNames(shippingNames);
      }
      // 現在の shippingData を保存
      prevShippingDataRef.current = shippingData;
    }
  }, [shippingData]);

  return (
    <>
      <S.Wrapper open={rightSlideBarOpen}>
        <ScrollToTop />
        <S.ScrollableContainer>
          <S.HeadContainer>
            <S.HeadDate>{editedDate.format('YYYY/MM/DD (ddd)')}</S.HeadDate>
            <S.HeadProductName>{selectedProduct?.name}</S.HeadProductName>
          </S.HeadContainer>
          <S.DataContainer>
            <S.Table>
              <tbody>
                <S.THead>
                  <S.THeadCell
                    width={'10'}
                    wordBreak={'keep-all'}
                    colSpan={1}
                    textAlign={'-webkit-left'}
                  >
                    出荷先
                  </S.THeadCell>
                  <S.THeadCell
                    width={'30'}
                    wordBreak={'keep-all'}
                    colSpan={1}
                    textAlign={'-webkit-right'}
                    paddingRight="1.2rem"
                  >
                    個
                  </S.THeadCell>
                </S.THead>
                {shippingData?.shippings &&
                  shippingData?.shippings?.map((d: Todo, index: number) => (
                    <S.TrCell
                      key={index}
                      wideBottom={
                        shippingData?.shippings?.length === index + 1
                          ? true
                          : false
                      }
                    >
                      <S.TdCell
                        textAlign={'-webkit-left'}
                        width={'200px'}
                        fontWeight={700}
                      >
                        {d.destinationName}
                      </S.TdCell>
                      <S.TdCell textAlign={'-webkit-right'} width={'70px'}>
                        <input
                          id={`amount-${index}`}
                          name="amount"
                          type="number"
                          inputMode="numeric"
                          value={(amounts && amounts[index]) || ''}
                          onChange={(e) =>
                            handleAmountChange(index, e.target.value)
                          }
                          disabled={
                            (edited &&
                              editedIndex !== null &&
                              editedIndex !== index) ||
                            updateShippingsMutation.isLoading ||
                            !isSameOrBefore
                          }
                          // 編集中input以外、更新中、今日以降はdisabled
                        />
                      </S.TdCell>
                    </S.TrCell>
                  ))}
                <tr>
                  <S.TdCell
                    textAlign={'-webkit-left'}
                    width={'200px'}
                    fontWeight={550}
                  >
                    {isSameOrBefore ? '合計（出荷数）' : '合計（予定数）'}
                  </S.TdCell>
                  <S.TdCell
                    textAlign={'-webkit-right'}
                    width={'60px'}
                    fontWeight={550}
                    paddingRight="1.2rem"
                  >
                    {shippingData?.totalAmount ? shippingData?.totalAmount : ''}
                  </S.TdCell>
                </tr>
              </tbody>
            </S.Table>
          </S.DataContainer>
          <S.ButtonContainer invalid={false}>
            {isSameOrBefore && (
              <Button
                borderWidth={1}
                outlined={true}
                onClick={() => {
                  setAmounts(
                    shippingData.shippings.map((d: Todo) => d.amount || '')
                  );
                  setEdited(false);
                  setEditedIndex(0);
                }}
                disabled={!edited || !!confirmMsg}
              >
                キャンセル
              </Button>
            )}
            {isSameOrBefore && (
              <Button
                borderWidth={1}
                onClick={() => {
                  handleSubmit(params.shipping);
                }}
                disabled={!edited || !!confirmMsg}
              >
                保存
              </Button>
            )}
            {!isSameOrBefore && (
              <Button
                borderWidth={1}
                onClick={() => {
                  handleOk();
                }}
              >
                OK
              </Button>
            )}
          </S.ButtonContainer>
        </S.ScrollableContainer>
        <ConfirmPopUp
          fromPc={true}
          confirmMsg={confirmMsg}
          width="22rem"
          handleOk={handleOk}
          handleCancel={handleCancel}
          zIndex={9998}
        />
        <UpdatePopUp
          popUp={popUp}
          handleClose={() => setPopUp(false)}
          fromPc={true}
          taskKind={'shipping'}
          messageKind={'update'}
          shippingName={destinationName}
        />
        {(confirmMsg || popUp) && (
          <Overlay zIndex={9997} handleClick={() => setPopUp(false)} />
        )}
      </S.Wrapper>
    </>
  );
};

export default RightSlideSidebar;
