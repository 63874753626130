import React from 'react';
import { WithHeader } from '@components/templates/WithHeader';
import { Heading } from '@components/elements/typographies/Heading';
import * as S from './ShippingGroupMasterCreate.styles';
import ShippingGroupCreateWrapper from '@components/modules/pc/settings/shipping_group_setting/ShippingGroupCreate/ShippingGroupCreateWrapper';

export const ShippingGroupMasterCreateRoot: React.FC = () => {
  return (
    <WithHeader>
      <S.Wrapper>
        <div>
          <S.HeadContainer>
            <S.FlexBaseline>
              <div>
                <Heading
                  tag="span"
                  text="出荷先グループの作成"
                  fs="32"
                  fw="lg"
                />
              </div>
            </S.FlexBaseline>
          </S.HeadContainer>
          <ShippingGroupCreateWrapper />
        </div>
      </S.Wrapper>
    </WithHeader>
  );
};
