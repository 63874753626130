import { FC } from 'react';

type Props = {
  color: string;
};

const Duplicate: FC<Props> = ({ color }) => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M15 1H3C1.9 1 1 1.9 1 3V17H3V3H15V1ZM20.6 5H7.4C6.08 5 5 5.9 5 7V21C5 22.1 6.08 23 7.4 23H20.6C21.92 23 23 22.1 23 21V7C23 5.9 21.92 5 20.6 5ZM21 21H7V7H21V21ZM19 15H15V19H13V15H9V13H13V9H15V13H19V15Z"
        fill={color}
      />
    </svg>
  );
};

export default Duplicate;
