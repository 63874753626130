import { FC, useEffect, useRef, useState } from 'react';
import * as S from './SidebarInput.styles';
import CaseSelect from '@components/modules/common/CaseSelect/CaseSelect';
import { ReactComponent as DeleteIcon } from '@assets/icons/delete.svg';
import { Button } from '@components/elements/buttons/Button';
import LABEL_OF_AMOUNT_MAP from '@components/modules/common/LabelOfAmountMap/LabelOfAmountMap';
import type { Todo, Case } from '@lib/common/type';
import type { DetailItem } from '@lib/stock_status/type';
import CustomNumPad from '@lib/pc/common/num_pad/customNumPad';
import { isIPad13 } from 'react-device-detect';
import {
  shouldDisplayCustomNumPad,
  normalizeZeros,
} from '@lib/stock_status/functions';
import { isIPhone } from '@lib/common/functions';

type Props = {
  index: number;
  type: 'packaged' | 'unpackaged';
  detail: DetailItem;
  detailLength: number;
  onChangeBara?: (bara: string) => void;
  onChangeCaseValue?: (caseValue: string) => void;
  onChangeDate?: (productionOrShippingDate: Todo) => void;
  onChangeCase?: (caseId: number) => void;
  changeCase: (index: number, value: number) => void;
  dateType: string;
  stockDateLabel?: string;
  labelOfAmount: string;
  handleDelete: () => void;
  deleteId: number | null;
  setDeleteId: (deleteId: number | null) => void;
  inputParams: Todo;
  handleRemoveField: (index: number, inputParams: Todo) => void;
  cases: Case[] | undefined;
  selectedCasePiecesPerCase: number;
  setSelectedCasePiecesPerCase: (selectedCasePiecesPerCase: number) => void;
  caseSelectWidth?: string;
  caseLabelWidth?: string;
  rightSlideSidebarActive?: boolean;
  fromPc?: boolean;
  needToSetInitCase?: boolean;
  useScanning: boolean;
};

const SidebarInput: FC<Props> = ({
  index,
  detail,
  type,
  deleteId,
  detailLength,
  stockDateLabel,
  labelOfAmount,
  onChangeBara,
  onChangeCaseValue,
  onChangeDate,
  onChangeCase,
  changeCase,
  handleDelete,
  setDeleteId,
  inputParams,
  handleRemoveField,
  cases,
  selectedCasePiecesPerCase,
  setSelectedCasePiecesPerCase,
  caseSelectWidth,
  caseLabelWidth,
  rightSlideSidebarActive,
  fromPc,
  needToSetInitCase,
  useScanning,
}: Props) => {
  const [confirmModal, setConfirmModal] = useState(false);
  const [selectedCaseId, setSelectedCaseId] = useState(0);
  const bestBeforeDateRef = useRef<HTMLInputElement>(null);

  const handleDeleteButton = () => {
    if (detail.amountDetailId === undefined || detail.amountDetailId === '') {
      setConfirmModal(true);
    } else {
      setDeleteId(detail.amountDetailId ? detail.amountDetailId : null);
    }
  };

  // ケース選択時。ここでは半製品、完成品判定済みのcasesのみ存在する。
  const setPiecesPerCase = (value: Todo) => {
    const filtered = cases && cases.find((c) => c.caseId === value);
    if (filtered) {
      setSelectedCasePiecesPerCase(filtered?.piecesPerBox);
    } else {
      setSelectedCasePiecesPerCase(1);
    }
  };

  const clearDate = () => {
    // iPadOSでクリアボタンを効かせるためのコード
    setTimeout(() => {
      if (bestBeforeDateRef.current)
        bestBeforeDateRef.current.defaultValue = '';
    }, 100);
  };

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    onChangeCaseValue?.(event.target.value);
  };

  const displayPiecesPerBox =
    detail?.piecesPerBox || selectedCasePiecesPerCase || 1;

  // numPadクリックでlocalStorageにpiecesPerBox設定
  const handleClick = (displayPiecesPerBox: number) => {
    localStorage.setItem(
      'selectedCasePiecesPerCase',
      displayPiecesPerBox.toString()
    );
  };

  // iPadOS、初期表示時クリアボタンを効かせるためのコード
  useEffect(() => {
    setTimeout(() => {
      if (bestBeforeDateRef.current)
        bestBeforeDateRef.current.defaultValue = '';
    }, 100);
  }, []);

  // ケース、バラ入力時、inputParamsにcaseIdを設定
  useEffect(() => {
    if (selectedCaseId !== 0 && selectedCaseId !== undefined) {
      changeCase(index, selectedCaseId);
    }
  }, [detail?.case, detail?.bara]);

  // mobileのためのケース、piecesPerBox初期値設定
  useEffect(() => {
    if (
      !fromPc &&
      index === 0 &&
      detailLength === 1 &&
      detail?.caseId?.toString() === ''
    ) {
      cases && setSelectedCasePiecesPerCase(cases[0]?.piecesPerBox);
      cases && setSelectedCaseId(cases[0]?.caseId);
    } else if (!fromPc && !!detail.piecesPerBox) {
      // データ存在する場合のlocalStorageへのpiecesPerBox初期値設定
      localStorage.setItem(
        'selectedCasePiecesPerCase',
        detail.piecesPerBox.toString()
      );
      setSelectedCasePiecesPerCase(detail.piecesPerBox);
    }
  }, [detail]);

  // pcのためのケース、piecesPerBox初期値設定
  useEffect(() => {
    if (fromPc && needToSetInitCase && cases) {
      cases && setSelectedCasePiecesPerCase(cases[0]?.piecesPerBox);
      cases && setSelectedCaseId(cases[0]?.caseId);
    }
  }, [rightSlideSidebarActive]);

  return (
    <S.DContainer>
      <S.FormWrapper setDeleteId={!!deleteId}>
        <S.DExpirationDate>
          <S.DateWrapper>
            <S.ProductionOrShippingDateLabel
              htmlFor={`production-date-${index}`}
            >
              {/* 完成品の場合：賞味期限、半製品の場合： 製造日時*/}
              {stockDateLabel
                ? stockDateLabel
                : type === 'packaged'
                ? '賞味期限'
                : '製造日時'}
            </S.ProductionOrShippingDateLabel>
            <S.Input
              ref={bestBeforeDateRef}
              type="date"
              name="date"
              id={`production-date-${index}`}
              value={
                detail?.productionOrShippingDate
                  ? detail?.productionOrShippingDate.toString()
                  : ''
              }
              onChange={(event) => {
                onChangeDate?.(event.target.value);
                clearDate();
              }}
              pattern="\d{4}-\d{2}-\d{2}"
              autoComplete="off"
              max="9999-12-31"
              labelLength={stockDateLabel ? stockDateLabel.length > 4 : false}
            />
            <S.DeleteButton
              onClick={() => {
                handleDeleteButton();
              }}
            >
              <DeleteIcon />
            </S.DeleteButton>
          </S.DateWrapper>
          {cases && cases?.length > 1 && (
            <CaseSelect
              cases={cases}
              value={detail?.caseId ? Number(detail?.caseId) : selectedCaseId}
              labelMarginRight="1rem"
              disabled={Number(detail?.case) > 0 || Number(detail?.bara) > 0}
              onChange={(value) => {
                onChangeCase?.(value);
                setPiecesPerCase(value);
                setSelectedCaseId(value);
              }}
              pcWidth={caseSelectWidth}
              caseLabelWidth={caseLabelWidth}
            />
          )}
        </S.DExpirationDate>

        <div>
          <S.DRightBottomWrapper>
            {/* ケース */}
            <S.DInputContainer center={true}>
              <label htmlFor={`stock-case-${index}`}>ケース</label>
              <input
                id={`stock-case-${index}`}
                name={`stock-cae-${index}`}
                value={detail?.case ? detail?.case : ''}
                onChange={(event) => onChangeCaseValue?.(event.target.value)}
                type="text"
                pattern="^[1-9][0-9]*$"
                inputMode="numeric"
                autoComplete="off"
                disabled={
                  detail?.piecesPerBox === 1 ||
                  displayPiecesPerBox === 1 ||
                  selectedCaseId === undefined
                }
              />
              <span>
                ×{displayPiecesPerBox}
                {labelOfAmount ? LABEL_OF_AMOUNT_MAP[labelOfAmount] : '個'}
              </span>
              {(!!selectedCaseId || !!detail) &&
                shouldDisplayCustomNumPad(
                  isIPad13 || isIPhone(),
                  useScanning
                ) && (
                  <div onClick={() => handleClick(displayPiecesPerBox)}>
                    <CustomNumPad
                      top="-3.1rem"
                      right="-3.4rem"
                      width="110px"
                      height="2.8rem"
                      name={`stock-case-${index}`}
                      value={detail?.case ? detail?.case : ''}
                      onChange={handleChange}
                      flexEnd={isIPhone()}
                    />
                  </div>
                )}
            </S.DInputContainer>
            <S.DSymbol>+</S.DSymbol>
            {/* バラ個数 */}
            <S.DInputContainer>
              <label htmlFor={`stock-bara-${index}`}>
                {labelOfAmount ? LABEL_OF_AMOUNT_MAP[labelOfAmount] : '個'}
              </label>
              <input
                id={`stock-bara-${index}`}
                name={`stock-bara-${index}`}
                value={detail?.bara ? normalizeZeros(detail?.bara) : ''}
                onChange={(event) => onChangeBara?.(event.target.value)}
                type="text"
                pattern="^[0-9]*$"
                inputMode="numeric"
                autoComplete="off"
                onFocus={() => handleClick(displayPiecesPerBox)}
              />
              {shouldDisplayCustomNumPad(
                isIPad13 || isIPhone(),
                useScanning
              ) && (
                <div onClick={() => handleClick(displayPiecesPerBox)}>
                  <CustomNumPad
                    top="-3.1rem"
                    right="-3.4rem"
                    width="110px"
                    height="2.8rem"
                    name={`stock-bara-${index}`}
                    value={detail?.bara ? normalizeZeros(detail?.bara) : ''}
                    onChange={(event) => onChangeBara?.(event.target.value)}
                    flexEnd={isIPhone()}
                  />
                </div>
              )}
            </S.DInputContainer>
            <S.DSymbol>=</S.DSymbol>
            <S.DInputContainer>
              <label htmlFor="stock-total">合計</label>
              <input
                id="stock-total"
                value={
                  (!detail?.case && !detail?.bara) || detail?.total === ''
                    ? ''
                    : Number(detail?.total).toLocaleString()
                }
                disabled
              />
            </S.DInputContainer>
          </S.DRightBottomWrapper>
        </div>
      </S.FormWrapper>
      {(deleteId || confirmModal) && (
        <S.DeleteModalWrapper>
          <S.DeleteModalTextContainer>
            <span>削除後は元に戻すことが出来ません。</span>
            <span>本当に削除しますか？</span>
          </S.DeleteModalTextContainer>
          <S.DeleteModalButtonContainer>
            <Button
              padding="sm"
              outlined
              onClick={() => {
                setDeleteId(null);
                setConfirmModal(false);
              }}
            >
              キャンセル
            </Button>
            <Button
              padding="sm"
              icon={<DeleteIcon />}
              outlined
              onClick={() => {
                if (detail?.amountDetailId) {
                  handleDelete();
                }
                handleRemoveField(index, inputParams);
                setConfirmModal(false);
              }}
            >
              削除
            </Button>
          </S.DeleteModalButtonContainer>
        </S.DeleteModalWrapper>
      )}
    </S.DContainer>
  );
};

export default SidebarInput;
