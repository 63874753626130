const isValidEmail = (email: string) => {
  // 正規表現パターン
  const pattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
  // パターンに一致するかどうかをテスト
  if (email) {
    return pattern.test(email);
  } else {
    return true;
  }
};

export const areAllValidEmails = (emails: string[]) => {
  // すべてのメールアドレスが有効であるかどうかを返す(false:有効ではないものが含まれる)
  return emails.every((email) => isValidEmail(email));
};
