import { axiosInstance } from '@lib/mobile/common/api/axiosConfig';
import { useQuery } from 'react-query';
import humps from 'humps';
import moment from 'moment';
// import type { Todo } from '@lib/common/type';
import { DetailsByShippingDestination } from '@lib/mobile/picking_by_shipping/type';

export const usePicking = (
  pickingId: number | string,
  storeroomId: number | string,
  refetchInterval?: number
) => {
  const { data, refetch } = useQuery(
    ['picking', pickingId, storeroomId],
    async () => {
      const data = await axiosInstance().get(
        `/api/v1/pickings/${pickingId}?date=${moment().format(
          'YYYYMMDD'
        )}&storeroom_id=${storeroomId}`
      );

      return data;
    },
    { refetchInterval }
  );
  const picking = humps.camelizeKeys(
    data?.data.picking_details
  ) as unknown as DetailsByShippingDestination;

  return { picking, refetch };
};

export default usePicking;
