import styled from 'styled-components';

export const WholeWrapper = styled.div<{
  fromScannerResult: boolean;
  fixedAreaHeight: string;
  greenFixedAreaHeight: string;
}>`
  :first-child {
    margin-top: ${(props) =>
      props.fromScannerResult === true
        ? props.greenFixedAreaHeight + 'px'
        : props.fromScannerResult === false
        ? props.fixedAreaHeight + 'px'
        : ''};
  }
`;

export const Wrapper = styled.div`
  width: 100vw;
  background-color: #f9f5d2;
  box-sizing: border-box;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 0px;
`;

// 出荷先別情報
export const DestinationInfo = styled.div<{
  checkState: boolean;
  short: boolean;
}>`
  width: 100vw;
  align-items: center;
  padding: 0.3rem 0.5rem;
  display: flex;
  font-size: 16px;
  font-weight: 700;
  line-height: 23px;
  letter-spacing: 0em;
  text-align: left;
  border: 1px solid rgb(230, 225, 221);
  background-color: ${(props) => (props.checkState ? '#dadada' : '')};
  color: ${(props) => (props.short ? props.theme.palette.error : '')};

  .destination-text-wrapper {
    padding: 0 17px;
    width: 80%;
    .info {
      display: flex;
      justify-content: space-between;
      > span {
        font-size: 12px;
      }
    }
  }
`;

export const CheckArea = styled.div<{
  checked: boolean;
}>`
  float: right;
  border: 1px solid
    ${(props) =>
      props.checked ? props.theme.palette.black : props.theme.palette.blue};
  width: 60px;
  height: 44px;
  border-radius: 8px;
  > span {
    font-weight: 700;
    font-size: 10px;
    text-align: center;
  }
`;

export const CheckIconWrapper = styled.div`
  align-items: center;
  padding: 0px 19px;
`;

export const StatusName = styled.div<{
  checked: boolean;
}>`
  display: flex;
  align-items: center;
  color: ${(props) =>
    props.checked ? props.theme.palette.khaki : props.theme.palette.blue};
  font-weight: bold;
  font-size: 13px;
  > span {
    width: 100vw;
    font-size: 10px;
    text-align: center;
    letter-spacing: -0.2em;
  }
`;
