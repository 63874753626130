import React from 'react';
import { WithHeader } from '@components/templates/WithHeader';
import { Heading } from '@components/elements/typographies/Heading';
import * as S from './SupplierMasterCreate.styles';
import SupplierCreateWrapper from '@components/modules/pc/settings/supplier_setting/SupplierCreate/SupplierCreateWrapper';
import useEmployees from '@lib/pc/settings/supplier_create/useEmployees';
import { ROLE_TYPE } from '@lib/common/type';

export const SupplierMasterCreateRoot: React.FC = () => {
  const { employees, refetch } = useEmployees(ROLE_TYPE.MATERIAL_SETTINGS);

  return (
    <WithHeader>
      <S.Wrapper>
        <div>
          <S.HeadContainer>
            <S.FlexBaseline>
              <div>
                <Heading tag="span" text="仕入先の作成" fs="32" fw="lg" />
              </div>
            </S.FlexBaseline>
          </S.HeadContainer>
          <SupplierCreateWrapper employees={employees} refetch={refetch} />
        </div>
      </S.Wrapper>
    </WithHeader>
  );
};
