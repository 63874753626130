import styled from 'styled-components';

export const Wrapper = styled.div`
  background-color: '#F8F7F4';
  border-bottom: 1px solid ${(props) => props.theme.palette.sand};
  padding: 0 0.8rem;
`;

export const Title = styled.div`
  align-items: center;
  justify-content: center;
  font-weight: bold;
  font-size: 1.125rem;
`;

export const StoreroomPanel = styled.div`
  display: flex;
  align-items: center;
  margin-top: 0.75rem;
  height: 2.5rem;
  border-radius: 0.5rem;
  font-size: 16px;
  color: ${(props) => props.theme.palette.khaki};
  background: ${(props) => props.theme.palette.white};

  &:nth-of-type(1) {
    margin-top: 0;
  }
`;

export const IconWrapper = styled.div`
  padding-left: 12px;
`;

export const StorageText = styled.p`
  margin-left: 0.75rem;
  font-weight: 400;
  font-size: 16px;
`;

export const ExpandMore = styled.p`
  margin: 0 0 0 auto;
`;
