import styled from 'styled-components';

export const Wrapper = styled.div<{
  open?: boolean;
}>`
  width: 100vw;
  height: 100vh;
  top: 0;
  position: fixed;
  background-color: #f8f7f4;
  z-index: 2;
`;

export const HeadContainer = styled.div`
  padding: 2rem 2.5rem;
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

export const ModalHeadCloseIcon = styled.div`
  cursor: pointer;
  padding: 4px;

  > svg {
    width: 24px;
    height: 24px;
  }
`;

export const ContentsWrapper = styled.div`
  height: 100vh;
  padding: 16px 80px;
  overflow-y: scroll;
`;

export const ContentItem = styled.div<{
  width?: string;
  marginTop?: string;
}>`
  width: ${(props) => (props.width ? props.width : '')};
  > dt {
    font-weight: 700;
    margin-top: ${(props) => (props.marginTop ? props.marginTop : '16px')};
  }
  > dd {
    margin-top: 8px;
  }
`;

export const Input = styled.input`
  width: 100%;
  height: 40px;
  color: ${(props) => props.theme.palette.baseBlack};
  font-size: 1rem;
  padding: 0 20px;
  text-align: left;
  border: none;
  outline: none;
  border-radius: 0.5rem;
  border: 1px solid rgba(0, 0, 0, 0.08);
  background: ${(props) => props.theme.palette.white};
  ::-webkit-inner-spin-button {
    -webkit-appearance: none;
  }
`;

export const MailBodyTextArea = styled.textarea`
  width: 100%;
  min-height: 6rem;
  color: ${(props) => props.theme.palette.baseBlack};
  font-size: 1rem;
  line-height: 1.25rem;
  padding: 0.325rem 20px;
  border: none;
  border-radius: 0.5rem;
  border: 1px solid rgba(0, 0, 0, 0.08);
  background: ${(props) => props.theme.palette.white};
  word-wrap: break-word;
  word-break: break-all;
  white-space: normal;
  margin-top: 8px;
`;

export const TelFaxWrapper = styled.div`
  display: flex;
  justify-content: space-between;
`;

export const ToAndCcMailAddressesWrapper = styled.div`
  display: flex;
  gap: 36px;
  margin-bottom: 100px;
  margin-top: 16px;
`;

export const ToAndCcMailAddressesItem = styled.div`
  width: 50%;
`;

export const ButtonWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
  bottom: 0;
  right: 0;
  left: 0;
  background-color: #f3ede6;
  width: 100%;
  height: 83px;
`;

export const ButtonContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  & > button {
    width: 136px;
  }
`;
