import { FC } from 'react';
import * as S from './StoreroomSelect.styles';
import type { Todo } from '@lib/common/type';
import ReactSelect from 'react-select';
import { NO_OPTION_JAPANESE } from '@lib/pc/common/constants';

type Props = {
  data: Todo[] | undefined;
  value: number | undefined;
  width: string;
  height: string;
  marginTop?: string;
  setSelectedId?: (selectedId: number) => void;
  onChange?: (selectedCaseId: number, index?: number) => void;
  disabled?: boolean;
  broaden?: boolean;
};

const StoreroomSelect: FC<Props> = ({
  data,
  value,
  width,
  height,
  marginTop,
  setSelectedId,
  onChange,
  disabled,
  broaden,
}: Props) => {
  const handleChangeCase = (e: number) => {
    if (setSelectedId) {
      setSelectedId(e);
    }
  };

  const toReactSelectOptions = (options: { id: number; name: string }[]) =>
    options.map(({ id, name }) => ({
      value: id,
      label: name,
    }));

  const allProductTransferOptions = [{ id: 0, name: '全ての払出元' }];
  const reactSelectOptions =
    data && toReactSelectOptions(allProductTransferOptions.concat(data));
  const currentValue = reactSelectOptions?.find((o) => o.value === value);

  const styles = {
    control: (baseStyles: Todo) => ({
      ...baseStyles,
      width: width ? width : '237px',
      height: height ? height : '56px',
      marginTop: marginTop ? marginTop : '',
      borderRadius: '0.5rem',
      fontWeight: 400,
      textAlign: 'left',
      borderStyle: 'hidden',
      outline: '0.5px solid rgba(0,0,0,0.08)',
      backgroundColor: disabled ? '' : 'white',
      cursor: 'pointer',
    }),
    menu: (baseStyles: Todo) => ({
      ...baseStyles,
      zIndex: 99999,
      marginTop: '3px',
    }),
    option: (baseStyles: Todo) => ({
      ...baseStyles,
      textAlign: 'left',
      fontWeight: 400,
      fontSize: '15px',
      height: broaden ? '50px' : '',
      lineHeight: broaden ? '30px' : '',
      zIndex: 99999,
    }),
    menuList: (baseStyles: Todo) => ({
      ...baseStyles,
      zIndex: 99999,
    }),
    menuPortal: (baseStyles: Todo) => ({
      ...baseStyles,
      zIndex: 999999,
    }),
  };

  return (
    <S.Wrapper>
      <ReactSelect
        menuPortalTarget={document.querySelector('body')}
        options={reactSelectOptions}
        value={currentValue ? currentValue : null}
        onChange={(option) => {
          option && handleChangeCase(option.value);
          option && onChange?.(option.value);
        }}
        styles={styles}
        isDisabled={disabled}
        isSearchable={false}
        components={{
          IndicatorSeparator: () => null,
        }}
        noOptionsMessage={() => NO_OPTION_JAPANESE}
        placeholder="全ての払出元"
      />
    </S.Wrapper>
  );
};

export default StoreroomSelect;
