import { useEffect, useState } from 'react';
import * as S from './ProductInfo.styles';
import { CircularProgress } from '@material-ui/core';
import ChevronLeft from '@assets/icons/chevron_left';
import { Button } from '@components/elements/buttons/Button';
import ErrorMsgPopUp from '@components/modules/common/ErrorMsgPopUp/ErrorMsgPopUp';
import Overlay from '@components/elements/backdrops/Overlay/Overlay';
import ProductContents from '@components/modules/pc/settings/product_master_setting/ProductMasterEdit/ProductContents/ProductContents';
import useUpdateProductMutation from '@lib/pc/settings/product_master_edit/useUpdateProducts';
import useCreateProduct from '@lib/pc/settings/product_master_create/useCreateProduct';
import { createdProductData } from '@components/modules/pc/settings/product_master_setting/ProductMasterCreate/ProductMasterCreateWrapper/default';
import { Product, Todo } from '@lib/common/type';

type Props = {
  product: Todo;
  updating: boolean;
  setUpdating: (updating: boolean) => void;
  setPopUp: (popUp: boolean) => void;
  newProductData: Todo;
  setNewProductData: (newProductData: Todo) => void;
  handleProductUpdateSuccess: () => void;
  onClickGoBack: () => void;
  isChanged: boolean;
  setIsChanged: (isChanged: boolean) => void;
  allProducts: Product[] | undefined;
  productFromInfo: Todo;
  setProductFromInfo: (productFromInfo: Todo) => void;
  timestamp: string;
  caseAlert?: boolean;
  storeRoomAlert?: boolean;
};

const ProductInfo = ({
  product,
  updating,
  setUpdating,
  setPopUp,
  newProductData,
  setNewProductData,
  handleProductUpdateSuccess,
  onClickGoBack,
  isChanged,
  setIsChanged,
  allProducts,
  productFromInfo,
  setProductFromInfo,
  timestamp,
  caseAlert,
  storeRoomAlert,
}: Props) => {
  const [errMsg, setErrMsg] = useState('');
  const handleChange = (
    e:
      | React.ChangeEvent<HTMLInputElement>
      | React.ChangeEvent<HTMLTextAreaElement>
  ) => {
    setIsChanged(true);
    setNewProductData({ ...newProductData, [e.target.name]: e.target.value });
  };

  const handleChangeImage = (imageDataUri: string) => {
    setIsChanged(true);
    setNewProductData({ ...newProductData, imageDataUri });
  };

  const handleChangeCode = (
    e:
      | React.ChangeEvent<HTMLInputElement>
      | React.ChangeEvent<HTMLTextAreaElement>
  ) => {
    setIsChanged(true);
    if (e.target.value.match(/^[a-zA-Z0-9-]*$/)) {
      setNewProductData({ ...newProductData, [e.target.name]: e.target.value });
    }
  };

  const handleChangeNumber = (
    e:
      | React.ChangeEvent<HTMLInputElement>
      | React.ChangeEvent<HTMLTextAreaElement>
  ) => {
    setIsChanged(true);
    if (e.target.value.match(/^[0-9]*$/)) {
      setNewProductData({ ...newProductData, [e.target.name]: e.target.value });
    }
  };

  const handleChangeCheckBox = (e: React.ChangeEvent<HTMLInputElement>) => {
    setIsChanged(true);
    setNewProductData({
      ...newProductData,
      [e.target.name]: e.target.checked,
    });
  };

  const [selectedChangeLabelOfAmountName, setSelectedChangeLabelOfAmountName] =
    useState('');

  // 作成したproduct→ProductMasterCreateWrapperへ移動
  const [createdProduct, setCreatedProduct] = useState(createdProductData);

  const handleCreatedProduct = (product: typeof createdProductData) => {
    setCreatedProduct(product);
  };
  const createProductMutation = useCreateProduct(
    handleCreatedProduct,
    handleProductUpdateSuccess,
    setErrMsg
  );

  const duplicateNameCheck = () => {
    // 製品名重複チェック
    const nameDuplicateProductExists =
      allProducts &&
      allProducts?.some(
        (ap) =>
          ap.name === newProductData.name &&
          ap.productKind === 1 &&
          ap.id !== newProductData.id
      );

    if (nameDuplicateProductExists) {
      setErrMsg('同じ名前の商品が登録済みです。');
    } else if (newProductData.name === '') {
      setErrMsg('商品名を入力してください。');
    } else {
      return true;
    }
  };

  // ***********************
  // [登録ボタン]押下、作成処理
  // ***********************
  const handleSubmitCreate = () => {
    const checkResult = duplicateNameCheck();
    if (checkResult) {
      setUpdating(true);
      // 商品マスタ----------------------
      createProductMutation.mutate(newProductData);
    }
  };

  // 商品マスタ更新
  const updateProductMutation = useUpdateProductMutation(
    newProductData?.id,
    handleProductUpdateSuccess,
    setErrMsg
  );

  // ***********************
  // [保存ボタン]押下、更新処理
  // ***********************
  const handleSubmit = () => {
    const checkResult = duplicateNameCheck();
    if (checkResult) {
      setUpdating(true);
      // 商品マスタ----------------------
      updateProductMutation.mutate(newProductData);
    }
  };

  useEffect(() => {
    if (createdProduct) {
      createdProduct && setProductFromInfo(createdProduct);
    }
  }, [createdProduct]);

  // useEffectをproductの変更のときに走らせると、画像ファイルを変更するたびにAPI結果が再ロードされてしまい入力値が消える
  // 対策として、productがundefinedからAPIで取得できたあとorページを開いた直後だけstateにデータを入れるようにする
  // !!productを見るだけだと、react queryのキャッシュのせいなのか、修正→一覧画面→修正と遷移したときに最新データがロードされない
  useEffect(() => {
    if (product?.id !== null && productFromInfo.id === 0) {
      setNewProductData(product?.product);
    }
  }, [!!product, timestamp]);

  return (
    <>
      {product ? (
        product && (
          <>
            <S.Wrapper>
              <div className="go-back" onClick={onClickGoBack}>
                <ChevronLeft isBlue={true} />
                <span>一覧画面へ戻る</span>
              </div>
              <S.ProductMasterEditContents>
                <ProductContents
                  newProductData={newProductData}
                  setNewProductData={setNewProductData}
                  updating={updating}
                  setUpdating={setUpdating}
                  handleChangeCode={handleChangeCode}
                  handleChange={handleChange}
                  handleChangeNumber={handleChangeNumber}
                  handleChangeImage={handleChangeImage}
                  selectedChangeLabelOfAmountName={
                    selectedChangeLabelOfAmountName
                  }
                  setSelectedChangeLabelOfAmountName={
                    setSelectedChangeLabelOfAmountName
                  }
                  handleChangeCheckBox={handleChangeCheckBox}
                  handleProductUpdateSuccess={handleProductUpdateSuccess}
                  setPopUp={setPopUp}
                  setIsChanged={setIsChanged}
                  caseAlert={caseAlert}
                  storeRoomAlert={storeRoomAlert}
                />
              </S.ProductMasterEditContents>
            </S.Wrapper>
            <S.ButtonContainer>
              {/* <Button
                children={'キャンセル'}
                borderWidth={1}
                outlined
                onClick={onClickCancel}
                disabled={updating}
              /> */}
              {isChanged &&
              product?.id === null &&
              allProducts &&
              newProductData.name !== '' ? (
                <Button
                  children={updating ? '登録中...' : '登録'}
                  disabled={updating}
                  onClick={handleSubmitCreate}
                />
              ) : !isChanged && product?.id === null ? (
                <Button children={updating ? '登録中...' : '登録'} disabled />
              ) : isChanged && product?.id !== null ? (
                <Button
                  children={updating ? '保存中...' : '保存'}
                  disabled={updating}
                  onClick={handleSubmit}
                />
              ) : (
                <Button children={updating ? '保存中...' : '保存'} disabled />
              )}
            </S.ButtonContainer>
          </>
        )
      ) : (
        <S.CircularIconWrapper>
          <CircularProgress style={{ color: '#64b2f9' }} />
        </S.CircularIconWrapper>
      )}
      <ErrorMsgPopUp
        errMsg={errMsg}
        handleClose={() => setErrMsg('')}
        fromPc={true}
      />
      {errMsg && (
        <Overlay
          handleClick={() => {
            setErrMsg('');
            setUpdating(false);
          }}
        />
      )}
    </>
  );
};

export default ProductInfo;
