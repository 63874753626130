import { useQuery } from 'react-query';
import humps from 'humps';
import { axiosInstance } from '@lib/pc/common/api/axiosConfig';
import { Todo } from '@lib/common/type';

const useSupplierDepartment = (supplierDepartmentId: number) => {
  const { data, refetch } = useQuery(
    ['supplier_department_edit', supplierDepartmentId],
    async () => {
      const data = await axiosInstance().get(
        `/api/v1/settings/supplier_department/${supplierDepartmentId}`
      );
      return data;
    }
  );
  const supplierDepartment = humps.camelizeKeys(data?.data) as Todo;

  return { supplierDepartment, refetch };
};

export default useSupplierDepartment;
