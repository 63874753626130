import { axiosInstance } from '@lib/pc/common/api/axiosConfig';
import { useQuery } from 'react-query';
import humps from 'humps';
import moment from 'moment';
import type { Todo } from '@lib/common/type';

const useShippings = (
  date: moment.Moment,
  selectedShippingDestinationId: number
) => {
  const { data, refetch } = useQuery(['shippings', date], async () => {
    const data = await axiosInstance().get(
      `/api/v1/shippings?date=${date.format(
        'YYYYMMDD'
      )}&shipping_destination_id=${selectedShippingDestinationId}`
    );

    return data;
  });

  const shippings = data && (humps.camelizeKeys(data.data.shippings) as Todo);

  return { shippings, refetch };
};

export default useShippings;
