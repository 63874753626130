import styled, { css } from 'styled-components';
import close_24px from '../../../../../assets/icons/close_24px.svg';

export const Root = styled.div`
  position: relative;
  width: 320px;
`;

export const ComponentVisible = styled.div<{
  isComponentVisible: boolean;
}>`
  display: ${(props) => (props.isComponentVisible ? 'block' : 'none')};
`;

export const baseButtonMixin = css`
  background: none;
  border: none;
  padding: 0;
`;

export const ValueWrapper = styled.input<{ fill?: string }>`
  width: 100%;
  padding-left: 8px;
  padding-right: 32px;
  height: 32px;
  box-sizing: border-box;
  border-radius: 1px;
  border: 1px solid #b6c1ce;
  line-height: 32px;
  background-color: #ffffff;
  input:placeholder-shown {
    background-color: #ffffff;
  }
`;

export const AutoCompleteIcon = styled.span<{
  isOpen: boolean;
  fill?: string;
}>`
  position: absolute;
  top: 0;
  right: 0;
  height: 32px;
  width: 32px;
  transition: all 150ms linear;
  transform: ${(props) => (props.isOpen ? 'rotate(0.5turn)' : 'none')};
  transform-origin: center;
  display: flex;

  svg {
    margin: auto;
  }

  ${ValueWrapper}:focus + & {
    color: ${(props) => props.color || 'rgba(243, 246, 247, 1)'};
    fill: ${(props) => props.fill || 'rgba(243, 246, 247, 1)'};
  }
`;

// 検索候補のBOX
export const AutoCompleteContainer = styled.ul`
  background: #fefefe;
  padding: 8px 0;
  list-style-type: none;
  position: absolute;
  top: 100%;
  left: 70%;
  // border: 1px solid #b6c1ce;
  border-radius: 8px;
  margin: 0;
  box-sizing: border-box;
  min-height: 64px;
  max-height: 280px;
  overflow-y: auto;
  z-index: 1;
  display: grid;
  place-items: center;

  // 高さ
  top: 0;
  border-radius: 8px;
  width: 243px;
  // position: inherit;
`;

export const AutoCompleteItem = styled.li`
  padding: 0 24px;
  width: 100%;
  box-sizing: border-box;
  &:hover {
    background-color: #ebf4ff;
  }
`;

// 選択候補の表示箇所;
export const AutoCompleteItemButton = styled.button`
  ${baseButtonMixin} width: 100%;
  line-height: 32px;
  text-align: left;
  // 選択候補の文字色
  color: black;
  &:active {
    outline: none;
    color: rgba(243, 246, 247, 1);
  }
`;

export const Input = styled(ValueWrapper)`
  input:placeholder-shown {
    background-color: #ffffff;
  }

  // float: right;
  width: 220px;
  height: 64px;
  border-radius: 8px;
  font-size: 16px;
  font-weight: 400;
  padding: 22px 10px;
  border: 1px solid rgba(0, 0, 0, 0.08);
  // :focus {
  //   border: 1px solid transparent;
  // }
  -webkit-appearance: none; /* デフォルトのサーチキャンセルボタンを非表示にする場合 */

  ::-webkit-search-cancel-button {
    -webkit-appearance: none; /* デフォルトのサーチキャンセルボタンを非表示にする場合 */
    background-image: url(${close_24px});
    background-repeat: no-repeat;
    background-size: cover;
    :placeholder-shown {
      display: none;
    }
  }

  // transition: border-color 150ms linear;

  &:focus {
    border-color: rgba(243, 246, 247, 1);
    outline: none;

    + ${AutoCompleteIcon} {
      color: ${(props) => props.color || 'rgba(243, 246, 247, 1)'};
      fill: ${(props) => props.fill || 'rgba(243, 246, 247, 1)'};
    }
  }
  input::placeholder {
    color: green;
  }
`;

export const ClearButton = styled.button`
  position: absolute;
  right: 85px;
  top: 18px;
  width: 20px;
  height: 20px;
  // padding: 0;
  // width: 40px;
  // height: 40px;
  // margin-bottom: 8px;
  // margin-left: 8px;
  border: 1px solid transparent;
  background-color: transparent;
  background-image: url(${close_24px});
  background-repeat: no-repeat;
  background-position: center center;
  vertical-align: middle;
  outline: 0;
  cursor: pointer;

  #input-text::placeholder-shown {
    display: none;
  }

  #input-text:placeholder-shown {
    display: none;
  }
`;

export const InputContainer = styled.div`
  position: relative;
`;
