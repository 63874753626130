import { useState } from 'react';
import { useLocation } from 'react-router';
import moment from 'moment';
import { Heading } from '@components/elements/typographies/Heading';
import * as S from './PickingByShippingRoot.styles';
import { WithHeader } from '@components/templates/WithHeader';
import AddShippingGroupModal from '@components/modules/pc/picking_by_shipping/AddShippingGroupModal/AddShippingGroupModal';
import ErrorMsgPopUp from '@components/modules/common/ErrorMsgPopUp/ErrorMsgPopUp';
import TableWithFilter from '@components/modules/pc/picking_by_shipping/TableWithFilter/TableWithFilter';
import RSingleDatePicker from '@components/elements/inputs/RSingleDatepicker/RSingleDatepicker';
import { PickingByShippingState } from '@lib/pc/picking_by_shipping/type';
import useCreatePickingMutation from '@lib/pc/picking_by_shipping/useCreatePickingMutation';
import usePickings from '@lib/pc/picking_by_shipping/usePickings';
import useShippingGroups from '@lib/pc/picking_by_shipping/useShippingGroups';
import UpdatePopUp from '@components/modules/common/UpdatePopUp/UpdatePopUp';
import Overlay from '@components/elements/backdrops/Overlay/Overlay';

export const PickingByShippingRoot = () => {
  const { state } = useLocation<PickingByShippingState>();
  const selectedDate = state?.stateSelectedDate ? state?.stateSelectedDate : '';
  const [date, setDate] = useState(
    selectedDate ? moment(selectedDate) : moment()
  );
  const [datePickerInactive, setDatePickerInactive] = useState(false);
  const [addShippingGroupModalOpen, setAddShippingGroupModalOpen] =
    useState(false);
  const [selectedShippingGroupId, setSelectedShippingGroupId] = useState(0);
  const [selectedShippingGroupName, setSelectedShippingGroupName] =
    useState('');
  const [errMsg, setErrMsg] = useState('');
  const [updatePopUp, setUpdatePopUp] = useState(false);
  const [scheduledTime, setScheduledTime] = useState('');
  const { pickings: shippings, refetch } = usePickings(date.toDate());
  const { shippingGroups: shippingGroups } = useShippingGroups();

  const handleCreateSuccess = () => {
    refetch();
    setAddShippingGroupModalOpen(false);
    setUpdatePopUp(!updatePopUp);
    setSelectedShippingGroupId(0);
    setScheduledTime('');
  };

  const handleCreatePickingGroup = () => {
    createPickingMutation.mutate({
      shippingGroupId: selectedShippingGroupId,
      shippingDate: date.format('YYYYMMDD'),
      shippingScheduledTime: scheduledTime,
    });
  };

  const createPickingMutation = useCreatePickingMutation(
    handleCreateSuccess,
    setErrMsg
  );

  const handleCancel = () => {
    setSelectedShippingGroupId(0);
    setScheduledTime('');
    setAddShippingGroupModalOpen(false);
  };

  return (
    <>
      <WithHeader>
        <S.Wrapper>
          <div>
            <S.HeadContainer>
              <div>
                <Heading
                  tag="span"
                  text="ピッキング(出荷先別)の進捗"
                  fs="32"
                  fw="lg"
                />
                <Heading tag="span" text="を確認" fs="24" fw="lg" />
              </div>
              {/* 新規登録ボタン */}
              <S.ButtonWrapper>
                <S.ButtonContainer
                  onClick={() => {
                    setAddShippingGroupModalOpen(!addShippingGroupModalOpen);
                    setUpdatePopUp(false);
                  }}
                >
                  ＋ 新規登録
                </S.ButtonContainer>
              </S.ButtonWrapper>
              <div>
                <RSingleDatePicker
                  date={date}
                  setDate={setDate}
                  invalid={datePickerInactive}
                />
              </div>
            </S.HeadContainer>
          </div>
          <div>
            <TableWithFilter
              shippings={shippings}
              setDatePickerInactive={setDatePickerInactive}
            />
          </div>
        </S.Wrapper>
      </WithHeader>
      {addShippingGroupModalOpen && (
        <AddShippingGroupModal
          date={date}
          shippingGroups={shippingGroups}
          selectedShippingGroupId={selectedShippingGroupId}
          setSelectedShippingGroupId={setSelectedShippingGroupId}
          handleCreatePickingGroup={() => handleCreatePickingGroup()}
          handleCancel={handleCancel}
          setSelectedShippingGroupName={setSelectedShippingGroupName}
          scheduledTime={scheduledTime}
          setScheduledTime={setScheduledTime}
        />
      )}
      {addShippingGroupModalOpen && !errMsg && (
        <Overlay dark handleClick={() => setAddShippingGroupModalOpen(false)} />
      )}

      <ErrorMsgPopUp
        errMsg={errMsg}
        fromPc={true}
        handleClose={() => setErrMsg('')}
        zIndex={1002}
      />
      <UpdatePopUp
        popUp={updatePopUp}
        fromPc={true}
        taskKind={'addShippingGroup'}
        shippingGroupName={selectedShippingGroupName}
        handleClose={() => {
          setUpdatePopUp(false);
        }}
      />
    </>
  );
};
