import { FC, useState } from 'react';
import moment from 'moment';
import { SingleDatePicker } from 'react-dates';
import 'moment/locale/ja'; // 日本語ローカライズ
import 'react-dates/initialize';
import 'react-dates/lib/css/_datepicker.css';
import styled from 'styled-components';
import { ReactComponent as Calendar } from '@assets/icons/calendar.svg';
import { ReactComponent as CalendarBlue } from '@assets/icons/mobile/calendar_blue.svg';
import 'react-dates/lib/defaultPhrases';
// import defaultPhrases from 'react-dates/lib/defaultPhrases';

type Props = {
  date: moment.Moment;
  setDate: (d: moment.Moment) => void;
  invalid: boolean;
  fromMobile?: boolean;
  calendarBlue?: boolean;
  setFromDatePicker?: (fromDatePicker: boolean) => void;
  width?: string;
};

//COMMENT: styled-componentに反映されるのを待つ(https://github.com/react-dates/react-dates/issues/1030)
const StyledWrapper = styled.div<{
  invalid?: boolean;
  fromMobile?: boolean;
  width?: string;
}>`
  pointer-events: ${(props) => (props.invalid ? 'none' : '')};
  display: flex;
  border: ${(props) => (props.fromMobile ? '' : '0.1rem solid #cdcdcdcd')};
  border-radius: 4px;
  height: 56px;
  .SingleDatePickerInput {
    border: 0rem solid;
    width: ${(props) =>
      props.fromMobile ? '53px' : props.width ? props.width : '236px'};
  }
  .DateInput_input {
    font-size: 16px;
    font-weight: 400;
    vertical-align: middle;
    display: ${(props) => (props.fromMobile ? 'none' : 'block')};
  }
  .DateInput__small {
    width: 100%;
    padding-top: 9px;
  }
  .SingleDatePickerInput_calendarIcon {
    padding-top: 17px;
    position: absolute;
    right: 5px;
  }
  .SingleDatePickerInput__withBorder {
    /* background-color: #00ff44; */
    background-color: #f8f7f4;
  }
  .DateInput {
    background-color: ${(props) => (props.fromMobile ? '#f8f7f4' : '')};
  }
  /* DateInput_1 DateInput__small DateInput__small_2 */
  .SingleDatePicker_picker {
    z-index: 10 !important;
    left: ${(props) => (props.fromMobile ? '0 !important' : '')};
    width: ${(props) => (props.fromMobile ? '0%' : '')};
  }
  .DayPicker {
    right: ${(props) => (props.fromMobile ? 'calc((100vw + 190px)/2)' : '')};
  }
`;

const phraseOverrides = {
  calendarLabel: 'Calendar',
  roleDescription: 'datepickerRangeStartDate',
  closeDatePicker: 'datepickerRangeFocusStartDate',
};

const RSingleDatePicker: FC<Props> = ({
  date = moment(),
  setDate,
  fromMobile,
  calendarBlue,
  setFromDatePicker,
  width,
}) => {
  const [focused, setFocused] = useState(false);

  const handleDate = (date: moment.Moment | null) => {
    setDate(date || moment());
    if (setFromDatePicker) {
      setFromDatePicker(true);
    }
  };

  const onFocusChange = ({ focused }: { focused: boolean }) => {
    setFocused(focused);
  };
  return (
    <StyledWrapper fromMobile={fromMobile} width={width}>
      <SingleDatePicker
        id="single-date-picker"
        date={date}
        //COMMENT: ここで表示したくない日付の範囲を指定
        isOutsideRange={() => false}
        // isOutsideRange={(day) => day.isAfter(date)}
        numberOfMonths={1}
        onDateChange={handleDate}
        focused={focused}
        onFocusChange={onFocusChange}
        customInputIcon={calendarBlue ? <CalendarBlue /> : <Calendar />}
        inputIconPosition="after"
        small
        regular={true}
        phrases={phraseOverrides}
      />
    </StyledWrapper>
  );
};

export default RSingleDatePicker;
