import { FC } from 'react';
import * as S from './UpdatePopUp.styles';
import { ReactComponent as VectorIcon } from '@assets/icons/mobile/vector.svg';
import Close from '@assets/icons/mobile/close';

type Props = {
  popUp: boolean;
  formattedDate?: string;
  productName?: string;
  handleClose: () => void;
  fromPc: boolean;
  messageKind?: string;
  taskKind?: string;
  isDelete?: boolean;
  shippingGroupName?: string;
  shippingName?: string;
  isPortraitAndTablet?: boolean;
};

const UpdatePopUp: FC<Props> = ({
  popUp,
  formattedDate,
  productName,
  handleClose,
  fromPc,
  messageKind,
  taskKind,
  isDelete,
  shippingGroupName,
  shippingName,
  isPortraitAndTablet,
}: Props) => {
  return (
    <>
      <S.Wrapper
        open={popUp}
        fromPc={fromPc}
        isDelete={isDelete}
        isPortraitAndTablet={isPortraitAndTablet}
      >
        <S.ModalHead>
          <S.IconWrapper>
            <VectorIcon />
          </S.IconWrapper>
          {taskKind === 'wrapping' && messageKind === 'delete' && (
            <S.MessageContainer>削除しました</S.MessageContainer>
          )}
          {taskKind === 'stock' && messageKind === 'delete' && (
            <S.MessageContainer>削除しました</S.MessageContainer>
          )}
          {taskKind === 'productionResult' && messageKind === 'delete' && (
            <S.MessageContainer>削除しました</S.MessageContainer>
          )}
          {taskKind === 'stock' && messageKind === 'create' && (
            <S.MessageContainer>
              {formattedDate}
              {productName}の在庫を登録しました
            </S.MessageContainer>
          )}
          {taskKind === 'stock' && messageKind === 'update' && (
            <S.MessageContainer>
              {formattedDate}
              {productName}の在庫を編集しました
            </S.MessageContainer>
          )}
          {taskKind === 'wrapping' &&
            messageKind !== 'delete' &&
            messageKind !== 'updateOrder' && (
              <S.MessageContainer>
                {productName}の包装を
                {messageKind === 'update' ? '編集' : '登録'}
                しました
              </S.MessageContainer>
            )}
          {taskKind === 'wrapping' && messageKind === 'updateOrder' && (
            <S.MessageContainer>並替をしました</S.MessageContainer>
          )}
          {taskKind === 'stacker' && (
            <S.MessageContainer>
              {productName}のスタッカーを編集しました
            </S.MessageContainer>
          )}
          {taskKind === 'productionResult' && messageKind !== 'delete' && (
            <S.MessageContainer>
              {productName}の生産実績を
              {messageKind === 'update'
                ? '編集'
                : messageKind === 'duplicate'
                ? '複製'
                : '登録'}
              しました
            </S.MessageContainer>
          )}
          {taskKind === 'stockAllocation' && messageKind !== 'delete' && (
            <S.MessageContainer>
              {productName}の払出入力を
              {messageKind === 'update' ? '編集' : '登録'}
              しました
            </S.MessageContainer>
          )}
          {taskKind === 'stockAllocation' && messageKind === 'delete' && (
            <S.MessageContainer>削除しました</S.MessageContainer>
          )}

          {taskKind === 'production_schedule' && (
            <S.MessageContainer>
              {formattedDate}
              {productName}の 生産計画値を修正しました
            </S.MessageContainer>
          )}
          {taskKind === 'sales_information' && messageKind === 'create' && (
            <S.MessageContainer>営業情報を登録しました</S.MessageContainer>
          )}
          {taskKind === 'addShippingGroup' && (
            <S.MessageContainer>
              {shippingGroupName}を作成しました
            </S.MessageContainer>
          )}
          {taskKind === 'addShippingProduct' && messageKind === 'create' ? (
            <S.MessageContainer>
              {productName}を出荷先{shippingName}へ作成しました
            </S.MessageContainer>
          ) : taskKind === 'addShippingProduct' && messageKind === 'update' ? (
            <S.MessageContainer>
              {productName}の出荷先{shippingName}を更新しました
            </S.MessageContainer>
          ) : (
            taskKind === 'addShippingProduct' &&
            messageKind === 'stop' && (
              <S.MessageContainer>
                {productName}の出荷先{shippingName}の出荷止めを更新しました
              </S.MessageContainer>
            )
          )}
          {taskKind === 'shipping' && messageKind === 'update' && (
            <S.MessageContainer>
              {shippingName}向け出荷数を更新しました
            </S.MessageContainer>
          )}
          {taskKind === 'purchaseOrder' && messageKind === 'update' && (
            <S.MessageContainer>注文書を更新しました</S.MessageContainer>
          )}
          {taskKind === 'materialDelivery' && messageKind === 'create' && (
            <S.MessageContainer>納品状況を作成しました</S.MessageContainer>
          )}
          {taskKind === 'materialDelivery' && messageKind === 'update' && (
            <S.MessageContainer>納品状況を更新しました</S.MessageContainer>
          )}
          {taskKind === 'materialDelivery' && messageKind === 'delete' && (
            <S.MessageContainer>納品状況を削除しました</S.MessageContainer>
          )}
          {taskKind === 'orderStatus' && messageKind === 'update' && (
            <S.MessageContainer>注文書を確定しました</S.MessageContainer>
          )}
          {taskKind === 'orderStatus' && messageKind === 'delete' && (
            <S.MessageContainer>注文書を削除しました</S.MessageContainer>
          )}
          {taskKind === 'orderStatus' && messageKind === 'cancel' && (
            <S.MessageContainer>
              発注済みの注文書を取消しました
            </S.MessageContainer>
          )}
          <S.ModalHeadCloseIcon onClick={() => handleClose()}>
            <Close isWhite={true} />
          </S.ModalHeadCloseIcon>
        </S.ModalHead>
      </S.Wrapper>
    </>
  );
};

export default UpdatePopUp;
