import { axiosInstance } from '@lib/mobile/common/api/axiosConfig';
import { useQuery } from 'react-query';
import humps from 'humps';
import moment from 'moment';
import { Todo } from '@lib/common/type';

export const useMaterialStocks = () => {
  const { data, refetch } = useQuery('materialStocks', async () => {
    const data = await axiosInstance().get(
      `/api/v1/material_stocks?date=${moment().format('YYYYMMDD')}`
    );

    return data;
  });

  const materialStocks =
    data && (humps.camelizeKeys(data.data.material_stocks) as Todo);

  return { materialStocks, refetch };
};

export default useMaterialStocks;
