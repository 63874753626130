import styled from 'styled-components';

export const Wrapper = styled.div<{
  open: boolean;
}>`
  height: 100%;
  width: 390px;
  position: fixed;
  padding: 1.5rem 0.9rem;
  top: 0;
  z-index: 9999;
  right: 0;
  background-color: ${(props) => props.theme.palette.beige};
  box-shadow: 0px 24px 38px rgba(0, 0, 0, 0.07),
    0px 9px 46px rgba(0, 0, 0, 0.06), 0px 11px 15px rgba(0, 0, 0, 0.1);
  transform: ${(props) =>
    props.open ? 'translateX(0px)' : 'translateX(390px)'};
  transition: transform 390ms ease-in-out;
`;

export const ScrollableContainer = styled.div`
  overflow-y: auto;
  height: calc(100vh - 4.6rem);
  padding-bottom: 7.6rem;
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none;
  ::-webkit-scrollbar {
    display: none;
  }
`;

export const HeadContainer = styled.div`
  position: relative;
`;

export const HeadDate = styled.div`
  font-weight: 700;
  font-size: 14px;
  line-height: 20px;
`;

export const HeadMaterialName = styled.div`
  font-weight: 700;
  font-size: 24px;

  margin-top: 0.5rem;
  padding-bottom: 20px;
  display: flex;
  align-items: center;
`;

export const HeadMaterialType = styled.div<{
  materialType: string | undefined;
}>`
  font-size: 0.75rem;
  padding: 0.5rem;
  margin-right: 0.5rem;
  border-radius: 2px;
  border: 1px solid#2BA9D1;
  background: ${(props) =>
    props.materialType === '原'
      ? props.theme.palette.primary.main
      : props.theme.palette.white};
  color: ${(props) =>
    props.materialType === '原'
      ? props.theme.palette.white
      : props.theme.palette.primary.main};
`;

export const ButtonContainer = styled.div<{
  invalid: boolean;
}>`
  position: fixed;
  bottom: 0;
  left: 0;
  padding: 1rem 0;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  background-color: #f3ede6;
  pointer-events: ${(props) => (props.invalid ? 'none' : '')};

  & > button {
    width: 136px;
  }

  & > button:first-child {
    margin-right: 1rem;
  }
`;

export const DuplicateButtonContainer = styled.div<{
  invalid: boolean;
}>`
  position: fixed;
  bottom: 0;
  left: 0;
  padding: 1rem;
  width: 100%;
  display: flex;
  gap: 16px;
  align-items: center;
  justify-content: space-evenly;
  background-color: #f3ede6;
  pointer-events: ${(props) => (props.invalid ? 'none' : '')};

  & > button {
    width: 320px;
  }
`;
