import styled from 'styled-components';

export const Wrapper = styled.div<{
  bgColor: string;
}>`
  width: 100vw;
  //ライン別背景
  background-color: ${(props) => props.bgColor};
`;

export const Header = styled.div<{
  bgColor: string;
}>`
  display: flex;
  justify-content: space-between;
  background-color: ${(props) => props.bgColor};
  width: 100vw;
  border-top: 1px solid #cdcdcd;
`;

export const Content = styled.div`
  width: 100vw;
`;

export const Title = styled.div`
  width: 100vw;
  font-weight: 700;
  font-size: 2rem;
  line-height: 2rem;
  padding: 20px 40px;
  border-bottom: 1px solid ${(props) => props.theme.palette.sandGray};
`;

export const TabContentArea = styled.div`
  font-size: 30px;
`;

export const TabContent = styled.div<{
  open: boolean;
}>`
  color: black;
  display: ${(props) => (props.open ? 'block' : 'none')};
`;
