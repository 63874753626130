/* eslint-disable @typescript-eslint/no-explicit-any */
import { useEffect, useState } from 'react';
import { factoryLineColors } from '@lib/pc/common/type';
import WrappingEditModal from '../WrappingEditModal/WrappingEditModal';
import * as S from './WrappingContents.styles';
import Overlay from '@components/elements/backdrops/Overlay/Overlay';
import WrappingMainTable from '../WrappingMainTable/WrappingMainTable';
import WrappingEditTimeModal from '../WrappingEditTimeModal/WrappingEditTimeModal';
import useUpdateWrappingMutation from '@lib/pc/wrapping/useUpdateWrappingMutation';
import useCreateWrappingMutation from '@lib/pc/wrapping/useCreateWrappingMutation';
import useUpdateWrappingEndTimeMutation from '@lib/pc/wrapping/useUpdateWrappingEndTimeMutation';
import useDeleteWrappingMutation from '@lib/pc/wrapping/useDeleteWrappingMuration';
import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import { BestBeforeDateByPic } from '@lib/pc/wrapping/types';
import { loadModel } from '@lib/pc/wrapping/functions';
import { Case, Todo, scannedProduct } from '@lib/common/type';
import scanSound from '@assets/sounds/sound.mp3';
import BarcodeReader from '@components/modules/pc/BarcodeScanner/BarcodeReader';
import ErrorMsgPopUp from '@components/modules/common/ErrorMsgPopUp/ErrorMsgPopUp';

type Props = {
  wrappings: any;
  selectedFactoryId: number;
  setSelectedFactoryId: (selectedFactoryId: number) => void;
  selectedFactoryData: any;
  setSelectedFactoryData: (selectedFactoryData: any) => void;
  selectedLineId: number;
  setSelectedLineId: (selectedLineId: any) => void;
  selectedLineIndex: number;
  setSelectedLine: (selectedLineIndex: number) => void;
  date: Date;
  products: any;
  cases: Case[] | undefined;
  popUp: boolean;
  setPopUp: (popUp: boolean) => void;
  editProductName: string;
  setEditProductName: (editProductName: string) => void;
  refetch: () => void;
  messageKind: string;
  setMessageKind: (messageKind: string) => void;
  casesPerCart: string;
  setCasesPerCart: (casesPerCart: string) => void;
  piecesPerBox: string;
  setPiecesPerBox: (piecesPerBox: string) => void;
};

export type InputParams = {
  time: string;
};

const defaultEditData = {
  bestBeforeDate: null,
  caseId: null,
  check: false,
  comment: '',
  completedBara: null,
  completedCase: null,
  completedQuantity: null,
  completedTime: null,
  employeeIds: [],
  id: null,
  lineIndex: null,
  lineName: '',
  order: null,
  packagingEmployeeIds: [],
  piecesPerBox: null,
  plannedBara: null,
  plannedCase: null,
  plannedQuantity: null,
  productId: null,
  productName: '',
  productionResultId: null,
  startedTime: null,
};

const defaultScanProduct = {
  productId: null,
  productName: '',
  productCode: '',
};

const WrappingContents = ({
  wrappings,
  selectedFactoryId,
  setSelectedFactoryId,
  selectedFactoryData,
  setSelectedFactoryData,
  selectedLineId,
  setSelectedLineId,
  selectedLineIndex,
  setSelectedLine,
  date,
  products,
  cases,
  popUp,
  setPopUp,
  setEditProductName,
  refetch,
  messageKind,
  setMessageKind,
  casesPerCart,
  setCasesPerCart,
  piecesPerBox,
  setPiecesPerBox,
}: Props) => {
  const [editData, setEditData] = useState<any>(null);
  const [startingTime, setStaringTime] = useState<any | null>(null);
  const [duringEditSubmit, setDuringEditSubmit] = useState(false);
  const [bestBeforeDatesForModal, setBestBeforeDatesForModal] = useState<
    BestBeforeDateByPic[]
  >([]);
  // 賞味期限日、認識数
  const [recognizedCnt, setRecognizedCnt] = useState(0);
  // 賞味期限認識State
  const [bestBeforeDateState, setBestBeforeDateState] = useState('');
  // 賞味期限認識総チェック数
  const [bestBeforeDateCheckCount, setBestBeforeDateCheckCount] = useState(0);

  const valuesOfFactoryLineColors = Object.values(factoryLineColors);
  // モデル読み込み
  const [modelLoading, setModelLoading] = useState(true);

  const [scanning, setScanning] = useState(true);
  const [results, setResults] = useState<Todo>([]);
  const [inputText] = useState('');
  const [audio] = useState(new Audio(scanSound));
  const [errMsg, setErrMsg] = useState('');
  const [foundScannedProduct, setFoundScannedProduct] = useState(false);
  const [scannedProduct, setScannedProduct] =
    useState<scannedProduct>(defaultScanProduct);

  useEffect(() => {
    setLatestFactoryData();
  }, [wrappings?.packagingResult]);

  useEffect(() => {
    setSelectedFactoryData(selectedFactoryData);
  }, [selectedFactoryData]);

  useEffect(() => {
    // モデルの読み込み
    async function tfloadModel() {
      setModelLoading(true);
      await loadModel();
      setModelLoading(false);
    }
    tfloadModel();
  }, []);

  useEffect(() => {
    if (results.length !== 0) {
      const getData = products.products.filter((pf: Todo) => {
        pf.janCode === results[0].codeResult.code &&
          pf.factoryLineIds.includes(Number(selectedLineId));
        return (
          pf.janCode === results[0].codeResult.code &&
          pf.factoryLineIds.includes(Number(selectedLineId))
        );
      });

      const scannedProductInfo = {
        productId: getData[0] ? getData[0].id : '',
        productCode: getData[0] ? getData[0].productCode : '',
        productName: getData[0] ? getData[0].name : '',
      };
      setScannedProduct(scannedProductInfo);

      if (!!getData && getData.length > 0) {
        setEditData({
          ...defaultEditData,
          time: null,
          lineName:
            selectedFactoryData.lines[selectedLineIndex].factoryLineName,
          lineIndex: selectedLineIndex,
        });
        setPopUp(false);
        setBestBeforeDatesForModal([]);
        setRecognizedCnt(0);
        setBestBeforeDateState('');
        setBestBeforeDateCheckCount(0);
        setFoundScannedProduct(true);
      } else {
        setErrMsg('該当の商品が見つかりませんでした。');
        setFoundScannedProduct(false);
        setEditData(false);
      }
    }
  }, [results]);
  const handleTimeSubmit = (time: any) => {
    console.log('終了時間:', time);
    updateWrappingEndTimeMutation.mutate(time);
    setStaringTime(null);
  };

  const handleDelete = () => {
    deleteWrappingMutation.mutate();
    setEditData(null);
    setBestBeforeDatesForModal([]);
    setRecognizedCnt(0);
    setBestBeforeDateState('');
    setBestBeforeDateCheckCount(0);
  };

  const setLatestFactoryData: any = () => {
    if (selectedFactoryId === undefined) {
      setSelectedFactoryId(wrappings?.packagingResult[0]?.factoryId);
      setSelectedFactoryData(wrappings?.packagingResult[0]);
    } else {
      const selectedData = wrappings?.packagingResult.filter(
        (pr: any) => pr.factoryId == selectedFactoryId
      );
      if (selectedData?.[0]) {
        setSelectedFactoryData(selectedData[0]);
      }
    }
  };

  // 最新データの取得
  const getLatestWrappingData = () => {
    refetch();
    setLatestFactoryData();
  };

  const handleDeleteSuccess = () => {
    setPopUp(true);
    setMessageKind('delete');
    getLatestWrappingData();
  };

  const handleCreateSuccess = () => {
    setPopUp(true);
    setMessageKind('create');
    getLatestWrappingData();
    setEditData(null);
    setDuringEditSubmit(false);
    setBestBeforeDatesForModal([]);
    setRecognizedCnt(0);
    setBestBeforeDateState('');
    setBestBeforeDateCheckCount(0);
  };

  const handleUpdateSuccess = () => {
    setPopUp(true);
    setMessageKind('update');
    getLatestWrappingData();
    setEditData(null);
    setDuringEditSubmit(false);
    setBestBeforeDatesForModal([]);
    setRecognizedCnt(0);
    setBestBeforeDateState('');
    setBestBeforeDateCheckCount(0);
  };
  const handleClose = () => {
    setEditData(null);
    setBestBeforeDatesForModal([]);
    setRecognizedCnt(0);
    setScanning(true);
    setResults([]);
    setScannedProduct(defaultScanProduct);
    setBestBeforeDateState('');
    setBestBeforeDateCheckCount(0);
  };

  const errPopUpHandleClose = () => {
    setResults([]);
    setScanning(true);
    setErrMsg('');
  };

  // ここで、newEditData.order(順)が空だったらcreateする。
  const handleEditSubmit = (newEditData: any) => {
    setDuringEditSubmit(true);
    if (newEditData.order === null) {
      createWrappingMutation.mutate(newEditData);
      setScanning(true);
      setResults([]);
      setScannedProduct(defaultScanProduct);
    } else {
      updateWrappingMutation.mutate(newEditData);
      setScanning(true);
      setResults([]);
      setScannedProduct(defaultScanProduct);
    }
  };
  // Packagingデータ作成処理
  const createWrappingMutation = useCreateWrappingMutation(
    date,
    selectedFactoryData?.factoryId,
    selectedLineId,
    handleCreateSuccess
  );

  // Packagingデータ更新処理
  const updateWrappingMutation = useUpdateWrappingMutation(
    editData?.id,
    date,
    selectedFactoryData?.factoryId,
    selectedLineId,
    handleUpdateSuccess
  );

  // ライン別包装終了時刻更新
  const updateWrappingEndTimeMutation = useUpdateWrappingEndTimeMutation(
    date,
    selectedFactoryData?.factoryId,
    selectedLineId
  );

  // 包装実績削除
  const deleteWrappingMutation = useDeleteWrappingMutation(
    editData?.id,
    handleDeleteSuccess
  );

  return (
    <div>
      <S.TabWrapper>
        {selectedFactoryData
          ? selectedFactoryData.lines.map((d: any, i: number) => (
              <div key={d.factoryLineName + d.factoryLineId}>
                <S.TabArea>
                  <S.Tab
                    key={d.factoryLineName + d.factoryLineId}
                    tabCount={selectedFactoryData.lines.length}
                    active={i == selectedLineIndex}
                    onClick={() => {
                      setSelectedLine(i);
                      setSelectedLineId(d.factoryLineId);
                    }}
                    bgColor={valuesOfFactoryLineColors[i]}
                  >
                    {d.factoryLineName}
                  </S.Tab>
                </S.TabArea>
              </div>
            ))
          : ''}
      </S.TabWrapper>
      <DndProvider backend={HTML5Backend}>
        <WrappingMainTable
          data={selectedFactoryData}
          setEditData={setEditData}
          recognizedCnt={recognizedCnt}
          setRecognizedCnt={setRecognizedCnt}
          selectedLineIndex={selectedLineIndex}
          popUp={popUp}
          setPopUp={setPopUp}
          messageKind={messageKind}
          setMessageKind={setMessageKind}
          getLatestWrappingData={getLatestWrappingData}
          casesPerCart={casesPerCart}
          piecesPerBox={piecesPerBox}
          setBestBeforeDatesForModal={setBestBeforeDatesForModal}
          bestBeforeDateState={bestBeforeDateState}
          setBestBeforeDateState={setBestBeforeDateState}
          bestBeforeDateCheckCount={bestBeforeDateCheckCount}
          setBestBeforeDateCheckCount={setBestBeforeDateCheckCount}
          modelLoading={modelLoading}
          setScanning={setScanning}
        />
      </DndProvider>
      {editData && (
        <WrappingEditModal
          scannedProduct={scannedProduct}
          data={editData}
          date={date}
          products={products}
          cases={cases}
          handleClose={() => handleClose()}
          handleDelete={handleDelete}
          handleSubmit={handleEditSubmit}
          setEditProductName={setEditProductName}
          casesPerCart={casesPerCart}
          setCasesPerCart={setCasesPerCart}
          piecesPerBox={piecesPerBox}
          setPiecesPerBox={setPiecesPerBox}
          selectedLineId={selectedLineId}
          duringEditSubmit={duringEditSubmit}
          bestBeforeDatesForModal={bestBeforeDatesForModal}
        />
      )}
      {scanning && (
        <BarcodeReader
          onDetected={(result: Todo) => {
            setScanning(false);
            audio.play();
            const newResult = {
              codeResult: { code: result.codeResult.code },
              inputText,
            };
            setResults([...results, newResult]);
          }}
        />
      )}
      {!foundScannedProduct && (
        <ErrorMsgPopUp
          fromPc={true}
          errMsg={errMsg}
          handleClose={() => errPopUpHandleClose()}
        />
      )}
      {startingTime && (
        <WrappingEditTimeModal
          lineIndex={selectedLineIndex}
          timeData={startingTime}
          handleSubmit={handleTimeSubmit}
          handleClose={() => setStaringTime(null)}
        />
      )}
      {(editData || startingTime || modelLoading) && <Overlay dark />}
    </div>
  );
};

export default WrappingContents;
