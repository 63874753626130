import { useEffect, useState } from 'react';
import * as S from './SupplierDepartmentsContents.styles';
import ChevronLeft from '@assets/icons/chevron_left';
import { ReactComponent as CircleCloseIcon } from '@assets/icons/circle_close.svg';
import { Button } from '@components/elements/buttons/Button';
import ConfirmPopUp from '@components/modules/common/ConfirmPopUp/ConfirmPopUp';
import ErrorMsgPopUp from '@components/modules/common/ErrorMsgPopUp/ErrorMsgPopUp';
import Overlay from '@components/elements/backdrops/Overlay/Overlay';
import DeleteConfirmModal from '@components/modules/common/DeleteConfirmModal/DeleteConfirmModal';
import SupplierContactModal from '@components/modules/pc/settings/supplier_setting/SupplierEdit/SupplierContactModal/SupplierContactModal';
import {
  SupplierDepartment,
  SupplierEdit,
} from '@lib/pc/settings/supplier_edit/type';
import useUpdateSupplierDepartmentMutation from '@lib/pc/settings/supplier_edit/useUpdateSupplierDepartment';

type Props = {
  newData: SupplierEdit;
  updating: boolean;
  setUpdating: (updating: boolean) => void;
  refetch: () => void;
  onClickGoBack: () => void;
  isChanged: boolean;
  setIsChanged: (isChanged: boolean) => void;
  enableToSave: boolean;
  setEnableToSave: (enableToSave: boolean) => void;
  setUpdatePopUp: (updatePopUp: boolean) => void;
  selectedSupplierDepartmentId: number | null;
  setSelectedSupplierDepartmentId: (
    selectedSupplierDepartmentId: number | null
  ) => void;
  newSupplierDepartments: SupplierDepartment[];
  setNewSupplierDepartments: (
    newSupplierDepartments: SupplierDepartment[]
  ) => void;
};

const SupplierDepartmentsContents = ({
  newData,
  updating,
  setUpdating,
  enableToSave,
  setEnableToSave,
  isChanged,
  setIsChanged,
  onClickGoBack,
  refetch,
  setUpdatePopUp,
  selectedSupplierDepartmentId,
  setSelectedSupplierDepartmentId,
  newSupplierDepartments,
  setNewSupplierDepartments,
}: Props) => {
  // 削除確認メッセージ
  const [delConfirmMsg, setDelConfirmMsg] = useState('');
  const [confirmMsg, setConfirmMsg] = useState('');
  const [errMsg, setErrMsg] = useState('');
  const [deleteNo, setDeleteNo] = useState(0);

  const [supplierDepartmentName, setSupplierDepartmentName] = useState('');

  // 下位のコンポーネントに渡しているのがネストの深いオブジェクトなので、強制再レンダリングする必要がある
  const [, setToggleValue] = useState(false);
  const reRender = () => setToggleValue((prev) => !prev);

  // 追加ボタン
  const onClickAddButton = () => {
    newSupplierDepartments.push({
      id: null,
      name: supplierDepartmentName,
      supplierId: newData.id,
    });
    setIsChanged(false);
    reRender();
    setSupplierDepartmentName('');
    setEnableToSave(true);
  };

  const handleUpdateSuccess = () => {
    refetch && refetch();
    setUpdatePopUp(true);
    setIsChanged(false);
    setUpdating(false);
    setEnableToSave(false);
    reRender();
  };

  const handleSupplierDepartments = (
    supplierDepartments: typeof newSupplierDepartments
  ) => {
    setNewSupplierDepartments(supplierDepartments);
  };

  const updateSupplierDepartmentMutation = useUpdateSupplierDepartmentMutation(
    newData?.id,
    handleSupplierDepartments,
    handleUpdateSuccess,
    setErrMsg
  );

  // ***********************
  // [保存ボタン]押下、更新処理
  // ***********************
  const handleSubmit = () => {
    setUpdating(true);
    updateSupplierDepartmentMutation.mutate(newSupplierDepartments);
  };

  // 削除ボタン
  const onClickDelButton = (no: number, name: string) => {
    setDelConfirmMsg(`${name}を本当に削除しますか？`);
    setDeleteNo(no);
  };

  const deleteFunction = () => {
    newSupplierDepartments.splice(deleteNo, 1);
    setNewSupplierDepartments([...newSupplierDepartments]);
    setDelConfirmMsg('');
    setEnableToSave(true);
  };

  const handleChangeSupplierDepartmentName = (
    e: React.ChangeEvent<HTMLInputElement>
  ) => {
    setSupplierDepartmentName(e.target.value);
    setIsChanged(true);
  };

  const [selectedDepartmentId, setSelectedDepartmentId] = useState<
    number | null
  >(null);

  const handleClickDepartment = (supplierDepartmentId: number | null) => {
    if (enableToSave) {
      setSelectedDepartmentId(supplierDepartmentId);
      setConfirmMsg('追加した仕入先部署を保存していませんがよろしいですか？');
    } else if (supplierDepartmentId) {
      setSelectedSupplierDepartmentId(supplierDepartmentId);
    } else {
      setErrMsg('はじめに追加した仕入先部署を保存して下さい。');
    }
  };

  const handleOk = () => {
    if (selectedDepartmentId) {
      setConfirmMsg('');
      setSelectedSupplierDepartmentId(selectedDepartmentId);
    } else {
      setErrMsg('はじめに追加した仕入先部署を保存して下さい。');
    }
  };

  const handleCancel = () => {
    setConfirmMsg('');
  };

  useEffect(() => {
    newData?.supplierDepartments &&
      setNewSupplierDepartments(newData?.supplierDepartments);
  }, [newData?.supplierDepartments]);

  return (
    <>
      <S.Wrapper>
        <S.GoBackWrapper onClick={onClickGoBack}>
          <ChevronLeft isBlue={true} />
          <span>一覧画面へ戻る</span>
        </S.GoBackWrapper>
        <S.GroupCodeWrapper>
          <span>仕入先コード</span> {newData?.code ? newData.code : ''}
        </S.GroupCodeWrapper>
        <S.NameWrapper>
          <span>仕入先名</span> {newData?.name ? newData.name : ''}
        </S.NameWrapper>

        <S.ShippingDestinationTitle>仕入先部署</S.ShippingDestinationTitle>
        <S.ShippingDestinationSelectRow>
          <S.ShippingDestinationSelectWrapper>
            {/* 仕入先部署 */}
            <S.Input
              type="text"
              inputMode="text"
              name="name"
              id="name"
              autoComplete="off"
              value={supplierDepartmentName ? supplierDepartmentName : ''}
              onChange={handleChangeSupplierDepartmentName}
            />
          </S.ShippingDestinationSelectWrapper>
          <div>
            <S.AddButton>
              {isChanged ? (
                <Button children={'追加'} onClick={() => onClickAddButton()} />
              ) : (
                <Button disabled children={'追加'} />
              )}
            </S.AddButton>
          </div>
        </S.ShippingDestinationSelectRow>

        <S.ShippingDestinationsWrapper>
          {newSupplierDepartments &&
            newSupplierDepartments.map(
              (supplierDepartment: SupplierDepartment, index: number) => {
                return (
                  // 1行
                  <S.ShippingDestination key={index}>
                    {/* 削除ボタン */}
                    <S.DeleteButton>
                      <S.CloseButton
                        onClick={() =>
                          onClickDelButton(index, supplierDepartment.name)
                        }
                      >
                        <CircleCloseIcon />
                      </S.CloseButton>
                    </S.DeleteButton>
                    <S.DepartmentNameWrapper
                      onClick={() =>
                        handleClickDepartment(
                          supplierDepartment.id
                          // supplierDepartment.name
                        )
                      }
                    >
                      {supplierDepartment.name}
                    </S.DepartmentNameWrapper>
                  </S.ShippingDestination>
                );
              }
            )}
        </S.ShippingDestinationsWrapper>
      </S.Wrapper>
      <S.ButtonContainer>
        <Button
          children={updating ? '保存中...' : '保存'}
          disabled={updating || !enableToSave}
          onClick={handleSubmit}
        />
      </S.ButtonContainer>
      {selectedSupplierDepartmentId && (
        <SupplierContactModal
          selectedSupplierDepartmentId={selectedSupplierDepartmentId}
          setSelectedSupplierDepartmentId={setSelectedSupplierDepartmentId}
          setUpdatePopUp={setUpdatePopUp}
          refetch={refetch}
        />
      )}
      {delConfirmMsg && (
        <DeleteConfirmModal
          confirmMsg={delConfirmMsg}
          onClickCancel={() => setDelConfirmMsg('')}
          onClickDelete={() => deleteFunction()}
        />
      )}
      <ErrorMsgPopUp
        errMsg={errMsg}
        handleClose={() => {
          setErrMsg('');
          setUpdating(false);
        }}
        fromPc={true}
        zIndex={9999}
      />
      {errMsg && (
        <Overlay
          dark
          handleClick={() => {
            setErrMsg('');
            setUpdating(false);
          }}
        />
      )}
      <ConfirmPopUp
        fromPc={true}
        confirmMsg={confirmMsg}
        zIndex={1004}
        handleOk={handleOk}
        handleCancel={handleCancel}
      />
    </>
  );
};

export default SupplierDepartmentsContents;
