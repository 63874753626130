import styled from 'styled-components';

export const Wrapper = styled.div`
  width: 100%;
  height: calc(100vh - 193px);
  padding: 16px 80px;
  background-color: ${(props) => props.theme.palette.beige};
  overflow-y: scroll;
  font-feature-settings: 'palt';
  padding-bottom: 200px;
`;

export const GoBackWrapper = styled.div`
  width: 160px;
  display: flex;
  padding-bottom: 32px;
  cursor: pointer;
  color: ${(props) => props.theme.palette.blue};
  > span {
    padding-top: 8px;
    padding-left: 10px;
  }
`;

export const ShippingGroupAreaDl = styled.dl`
  > dt {
    font-weight: 700;
    margin-bottom: 8px;
  }
  > dd {
    margin-bottom: 24px;
  }
`;

export const Input = styled.input<{
  width?: string;
}>`
  width: ${(props) => (props.width ? props.width : '100%')};
  height: 40px;
  color: ${(props) => props.theme.palette.baseBlack};
  font-size: 1rem;
  padding: 0 20px;
  text-align: left;
  outline: none;
  border-radius: 0.5rem;
  border: 1px solid rgba(0, 0, 0, 0.08);
  height: ${(props) => (props.height ? props.height : '')};
  background: ${(props) => props.theme.palette.white};
  ::-webkit-inner-spin-button {
    -webkit-appearance: none;
  }
`;

export const HonorificRoundingWrapper = styled.div`
  display: flex;
  justify-content: space-between;
`;

export const HonorificRoundingItem = styled.div`
  display: block;
  width: 48%;
  > span {
    font-weight: 700;
  }
`;

export const DescriptionLabel = styled.div`
  margin-top: 24px;
  font-weight: 700;
`;

export const DescriptionTextArea = styled.textarea`
  width: 100%;
  min-height: 6rem;
  color: ${(props) => props.theme.palette.baseBlack};
  font-size: 1rem;
  line-height: 1.25rem;
  padding: 0.325rem;
  border: none;
  border-radius: 0.5rem;
  border: 1px solid rgba(0, 0, 0, 0.08);
  background: ${(props) => props.theme.palette.white};
  word-wrap: break-word;
  word-break: break-all;
  white-space: normal;
  margin-top: 8px;
`;

export const CurrentUserEmployees = styled.div`
  margin-top: 40px;
  width: 100%;
  display: flex;
  > span {
    font-weight: 700;
  }
`;

export const CurrentUserEmployeeItem = styled.div`
  display: block;
  width: 90%;
  > span {
    font-weight: 700;
  }
`;

export const AddButton = styled.div`
  padding-top: 24px;
  justify-content: center;
  align-items: center;
  margin-left: auto;
  dd {
    text-align: -webkit-right;
  }
  button {
    width: 64px;
    &.disabled {
      background: #e0e0e0;
      border-color: #e0e0e0;
      color: #6b675c;
      cursor: initial;
    }
  }
`;

export const SelectedEmployees = styled.div`
  margin: 12px 0;
  min-height: 40px;
  border-bottom: solid 1px #cdcdcd;
  display: flex;
`;

export const EmployeeName = styled.div`
  width: 28%;
  margin: 12px;
`;

export const EmployeeEmail = styled.div`
  margin: 12px;
  text-align: end;
`;

export const CloseButton = styled.div`
  width: 40px;
  height: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const ButtonContainer = styled.div`
  position: fixed;
  width: 100vw;
  bottom: 0;
  padding: 22px 0;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #f8f7f4;
  & > button {
    width: 136px;
    &.disabled {
      background: #e0e0e0;
      border-color: #e0e0e0;
      color: #6b675c;
      cursor: initial;
    }
  }
`;
