import styled from 'styled-components';

export const LoadingContainer = styled.div<{
  dark?: boolean;
  animationDuration?: string;
  text?: string;
  zIndex?: number;
}>`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 5000px;

  color: ${(props) => props.theme.palette.white};
  display: inline-flex;
  align-items: center;

  background: ${(props) =>
    props.dark ? 'rgba(51, 51, 51, 0.5)' : 'transparent'};
  z-index: ${(props) => (props.zIndex ? props.zIndex : '9998')};
`;

export const CircularProgressWrapper = styled.div`
  position: absolute;
  top: 10%;
  left: 50%;
`;
