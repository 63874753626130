import { axiosInstance } from '@lib/mobile/common/api/axiosConfig';
import { useQuery } from 'react-query';
import humps from 'humps';
import type { Product } from '@lib/common/type';

const useProducts = () => {
  return useQuery('products', async () => {
    const { data } = await axiosInstance().get('/api/v1/products');
    return humps.camelizeKeys(data.products) as Product[];
  });
};

export default useProducts;
