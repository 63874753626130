import styled from 'styled-components';

export const ImageInput = styled.input`
  display: none;
`;

export const Input = styled.input<{
  align?: string;
  padding?: string;
  height?: string;
}>`
  width: 100%;
  height: 40px;
  color: ${(props) => props.theme.palette.baseBlack};
  font-size: 1rem;
  padding: ${(props) => (props.padding ? props.padding : '0.325rem')};
  text-align: ${(props) => props.align};
  border-radius: 0.5rem;
  outline: none;
  border: 1px solid rgba(0, 0, 0, 0.08);
  height: ${(props) => (props.height ? props.height : '')};
  background: ${(props) => props.theme.palette.white};
  ::-webkit-inner-spin-button {
    -webkit-appearance: none;
  }
`;

export const ButtonContainer = styled.div`
  width: 100vw;
  padding: 22px 24px;
  display: flex;
  align-items: center;
  justify-content: center;
  & > button {
    width: 136px;
  }

  & > button:first-child {
    margin-right: 1rem;
  }
`;

export const AlertArea = styled.div`
  background-color: ${(props) => props.theme.palette.sandGray};
  border-radius: 8px;
  margin-bottom: 16px;
`;

export const AlertWrapper = styled.div`
  display: flex;
  padding: 8px;
`;

export const Alert = styled.div`
  padding-top: 4px;
  padding-left: 4px;
  color: ${(props) => props.theme.palette.error};
`;

export const CheckBoxArea = styled.div`
  display: flex;
`;

export const Checkbox = styled.input`
  opacity: 0;
  /* position: absolute; */
  /* appearance: none; */
  z-index: 2;
  cursor: pointer;
`;

export const HiddenAndDiscontinued = styled.span<{
  defaultChecked?: boolean;
}>`
  display: flex;
  align-items: center;
  font-size: 1rem;
  font-weight: 700;
  line-height: 1rem;
  position: relative;
  left: -15px;
  width: 9rem;
  padding-top: 24px;
  color: ${(props) => props.theme.palette.baseBlack};
  ::before {
    display: inline-block;
    content: '';
    width: 1.125rem;
    height: 1.125rem;
    margin-right: 8px;
    background-image: ${(props) =>
      props.defaultChecked
        ? "url('data:image/svg+xml;charset=utf8,%3Csvg%20width%3D%2218%22%20height%3D%2218%22%20viewBox%3D%220%200%2018%2018%22%20fill%3D%22none%22%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%3E%3Cpath%20d%3D%22M16%200H2C0.89%200%200%200.9%200%202V16C0%2017.1%200.89%2018%202%2018H16C17.11%2018%2018%2017.1%2018%2016V2C18%200.9%2017.11%200%2016%200ZM7%2014L2%209L3.41%207.59L7%2011.17L14.59%203.58L16%205L7%2014Z%22%20fill%3D%22%232BA9D1%22%2F%3E%3C%2Fsvg%3E')"
        : "url('data:image/svg+xml;charset=utf8,%3Csvg%20width%3D%2218%22%20height%3D%2218%22%20viewBox%3D%220%200%2018%2018%22%20fill%3D%22none%22%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%3E%3Cpath%20d%3D%22M16%202V16H2V2H16ZM16%200H2C0.9%200%200%200.9%200%202V16C0%2017.1%200.9%2018%202%2018H16C17.1%2018%2018%2017.1%2018%2016V2C18%200.9%2017.1%200%2016%200Z%22%20fill%3D%22%23C0C0C0%22%2F%3E%3C%2Fsvg%3E')"};
    /* background-repeat: no-repeat; */
    background-position: center;
  }
`;

export const TextArea = styled.textarea`
  width: 100%;
  min-height: 120px;
  padding: 10px;
  resize: none;
  border-radius: 0.5rem;
  border: 1px solid rgba(0, 0, 0, 0.08);
`;
