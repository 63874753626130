import styled from 'styled-components';

export const TagList = styled.ul``;

export const TagItem = styled.li``;

export const TagLabel = styled.label`
  display: block;
  padding: 1.25rem 0;
  width: 100%;
  border-bottom: 2px solid #f2f2f2;
  background-color: ${(props) => props.theme.palette.white};
`;

export const StoreroomName = styled.span`
  display: flex;
  align-items: center;
  color: ${(props) => props.theme.palette.blue};
  font-weight: bold;

  ::before {
    display: inline-block;
    content: '';
    width: 1.125rem;
    height: 1.125rem;
  }
`;
