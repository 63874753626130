export const defaultNewProductData = {
  expiryDays: null,
  id: null,
  janCode: '',
  name: '',
  nameAbbreviation: '',
  nameKana: '',
  productCode: '',
  safetyStockDays: null,
  stockDateLabel: '',
  labelOfAmount: 'piece',
};

export const createdProductData = {
  id: 0,
  expiryDays: null,
  janCode: '',
  name: '',
  nameAbbreviation: '',
  nameKana: '',
  productCode: '',
  safetyStockDays: null,
  stockDateLabel: '',
};

export type defaultTag = {
  id: 0;
  name: '';
};
