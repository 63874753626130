import * as S from './SearchProductForm.styles';
import { ReactComponent as SearchIcon } from '@assets/icons/mobile/search.svg';
import { ReactComponent as CloseIcon } from '@assets/icons/close_24px.svg';

type Props = {
  searchChar: string;
  setSearchChar: (searchChar: string) => void;
};

const SearchProductForm = ({ searchChar, setSearchChar }: Props) => {
  return (
    <S.Wrapper>
      <S.SearchConditionList>
        <S.SearchConditionPanel>
          <SearchIcon />
          <S.SearchConditionInput
            placeholder="商品/コードで検索"
            onChange={(event) => setSearchChar(event.target.value)}
            value={searchChar}
          />
          {searchChar && (
            <S.Icon onClick={() => setSearchChar('')}>
              <CloseIcon />
            </S.Icon>
          )}
        </S.SearchConditionPanel>
      </S.SearchConditionList>
    </S.Wrapper>
  );
};

export default SearchProductForm;
