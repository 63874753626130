import { Todo } from '@lib/common/type';

const productName = [
  '商品A2本入',
  '商品A3本入',
  'ソフト豆腐',
  'なめらか豆腐',
  'たまご豆腐',
  '厚揚げ',
  '絹厚揚げ',
  'もめん豆腐',
  '五目がんも',
  'うす揚げ',
  'いなりあげ',
  '旨み天',
];

const destinationCode = [
  '010',
  '020',
  '030',
  '040',
  '050',
  '060',
  '070',
  '080',
  '090',
];

const destinationName = ['宮崎', '福岡', '熊本', '長崎', '大分', '鹿児島'];

const amount = ['100', '150', '200', '230', '300', '310', '400', '450'];

// const checkState = [true, false];

const janCode = [
  '4912345678904',
  '4561234567890',
  '4901121181443',
  '4901121181444',
  '4901121181445',
  '4901121181446',
  '4901121181447',
  '4901121181449',
  '4901121181450',
  '4901121181451',
  '4901121181452',
  '4901121181448',
];

// 出荷先内訳
const generateShippingBreakdownDetail = () => {
  return [...Array(3)].map((_, i) => {
    return {
      shippingDestinationId: i,
      destinationCode:
        destinationCode[Math.floor(Math.random() * destinationCode.length)],
      destinationName:
        destinationName[Math.floor(Math.random() * destinationName.length)],
      amount: amount[Math.floor(Math.random() * amount.length)],
    };
  });
};

// 商品別ピッキング情報
const generatePickingBreakdownDetail = [
  {
    bestBeforeDate: '2023-02-26',
    cart: 0,
    case: 10,
    piece: 5,
    amount: 505,
  },
  {
    bestBeforeDate: '2023-02-27',
    cart: 1,
    case: 50,
    piece: 10,
    amount: 3510,
  },
  {
    bestBeforeDate: '2023-02-28',
    cart: 1,
    case: 100,
    piece: 15,
    amount: 6015,
  },
];
// const generatePickingBreakdownDetail = () => {
//   return [...Array(3)].map((_) => {
//     return {
//       bestBeforeDate: '2023-01-27',
//       cart: 1,
//       case: 100,
//       piece: 13,
//       amount: 6013,
//     };
//   });
// };

const storeroomIds = [1, 3, 5];
// 配列arrayからランダムにnum個の要素を取り出す
const randomSelect = (storeroomIds: Todo[], num: number) => {
  const newArray = [];

  while (newArray.length < num && storeroomIds.length > 0) {
    // 配列からランダムな要素を選ぶ
    const rand = Math.floor(Math.random() * storeroomIds.length);
    // 選んだ要素を別の配列に登録する
    newArray.push(storeroomIds[rand]);
    // もとの配列からは削除する
    // storeroomIds.splice(rand, 1);
  }

  return newArray;
};

// 個別画面、商品別出荷予定：randomではなく固定データにする
const generatePickingDummy = () => {
  return [...Array(12)].map((_, i) => {
    return {
      id: i,
      name: productName[i],
      // name: productName[Math.floor(Math.random() * productName.length)],
      productCode: '0123456789012',
      janCode: janCode[i],
      checkState: false,
      // checkState: checkState[Math.floor(Math.random() * checkState.length)],
      piecesPerBox: 50,
      casesPerCart: 20,
      cart: 2,
      case: 160,
      piece: 30,
      total: 10030,
      pickingBreakdownDetailId: 1,
      storeroomIds: randomSelect(storeroomIds.slice(), 2),
      shortageAmount: 0,
      // 出荷先内訳
      destinationBreakdownDetail: generateShippingBreakdownDetail(),
      // 商品別ピッキング情報
      pickingBreakdownDetail: generatePickingBreakdownDetail,
      // pickingBreakdownDetail: generatePickingBreakdownDetail(),
    };
  });
};

export const PICKING_DETAIL_DATA = {
  shippingId: 1,
  shippingDate: '2022-10-11',
  groupName: '福岡警固エリア',
  shippingScheduledTime: '2023-01-01:8:00',
  numberOfProducts: 2,
  totalAmount: 50014,
  pickingStatus: 0,
  // 商品別チェック
  detailsByProduct: generatePickingDummy(),
};
