import { FC } from 'react';
import * as S from './MaterialTypeSelect.styles';
import { ReactComponent as NavigationExpandWhite } from '@assets/icons/navigation_expand_white.svg';
import type { Todo } from '@lib/common/type';
import ReactSelect, { components } from 'react-select';
import { NO_OPTION_JAPANESE } from '@lib/pc/common/constants';

type Props = {
  allMaterialType: Todo[];
  value: number | undefined;
  disabled?: boolean;
  newMaterialData: Todo;
  setNewMaterialData: (newMaterialData: Todo) => void;
  setIsChanged: (isChanged: boolean) => void;
};

const MaterialTypeSelect: FC<Props> = ({
  allMaterialType,
  value,
  disabled,
  newMaterialData,
  setNewMaterialData,
  setIsChanged,
}: Props) => {
  const handleChange = (e: number, s: string) => {
    const selectedLabel = allMaterialType.find((obj) => obj.name === s);
    setNewMaterialData({
      ...newMaterialData,
      materialType: selectedLabel?.id,
      materialTypeValue: selectedLabel?.id,
      materialTypeLabel: selectedLabel?.name,
    });
    if (newMaterialData?.name !== undefined || newMaterialData?.name !== '') {
      setIsChanged(true);
    }
  };

  const toReactSelectOptions = (options: { id: number; name: string }[]) =>
    options.map(({ id, name }) => ({
      value: id,
      label: name,
    }));
  const reactSelectOptions =
    allMaterialType && toReactSelectOptions(allMaterialType);
  const currentValue = reactSelectOptions?.find((o) => o.value === value);

  // カスタムSVGアイコン
  const CustomDropdownIndicator = (baseStyles: Todo) => {
    return (
      <components.DropdownIndicator {...baseStyles}>
        <NavigationExpandWhite />
      </components.DropdownIndicator>
    );
  };

  // デフォルトのDropdownIndicatorを使用
  const DefaultDropdownIndicator = (baseStyles: Todo) => {
    return <components.DropdownIndicator {...baseStyles} />;
  };

  const rowMaterial =
    currentValue && currentValue?.label === '原材料' ? true : false;

  const styles = {
    control: (baseStyles: Todo) => ({
      ...baseStyles,
      width: '8rem',
      height: '40px',
      borderRadius: '0.5rem',
      fontWeight: 400,
      textAlign: 'left',
      borderStyle: 'hidden',
      outline: '0.5px solid rgba(0,0,0,0.08)',
      backgroundColor: rowMaterial ? '#2BA9D1' : '#ffffff',
      color: rowMaterial ? '#ffffff' : '#2BA9D1',
      cursor: 'pointer',
      boxShadow: 'none',
      '@media (max-width: 599px)': {
        width: '16rem',
      },
    }),
    menu: (baseStyles: Todo) => ({
      ...baseStyles,
      top: '37px',
      zIndex: 1002,
    }),
    option: (baseStyles: Todo) => ({
      ...baseStyles,
      textAlign: 'left',
      fontWeight: 400,
      fontSize: '15px',
    }),
    menuList: (baseStyles: Todo) => ({
      ...baseStyles,
      maxHeight: '180px',
    }),
    singleValue: (baseStyles: Todo) => ({
      ...baseStyles,
      height: '20px',
      color: rowMaterial ? '#ffffff' : '#2BA9D1',
    }),
  };

  return (
    <S.Wrapper>
      <S.SelectWrapper>
        <ReactSelect
          options={reactSelectOptions}
          value={currentValue ? currentValue : null}
          onChange={(option) => {
            option && handleChange(option.value, option.label);
          }}
          styles={styles}
          isDisabled={disabled}
          isSearchable={false}
          components={{
            IndicatorSeparator: () => null,
            DropdownIndicator: rowMaterial
              ? CustomDropdownIndicator
              : DefaultDropdownIndicator,
          }}
          noOptionsMessage={() => NO_OPTION_JAPANESE}
          placeholder="選択してください"
        />
      </S.SelectWrapper>
    </S.Wrapper>
  );
};

export default MaterialTypeSelect;
