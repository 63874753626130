import styled from 'styled-components';

export const FloatingIconWrapper = styled.div<{
  bottom?: string;
  display?: boolean;
  zIndex?: number;
}>`
  display: ${(props) => (props.display ? 'none' : '')};
  position: fixed;
  bottom: ${(props) => (props.bottom ? props.bottom : '1rem')};
  right: 1rem;
  padding: 1rem;
  background: #fff;
  border-radius: 50%;
  z-index: ${(props) => (props.zIndex ? props.zIndex : '')};
  cursor: pointer;
  filter: drop-shadow(0px 6px 10px rgba(0, 0, 0, 0.14))
    drop-shadow(0px 1px 18px rgba(0, 0, 0, 0.12))
    drop-shadow(0px 3px 5px rgba(0, 0, 0, 0.2));
`;
