import React, { useEffect, useState } from 'react';
import { CircularProgress } from '@material-ui/core';
import { useHistory, useLocation } from 'react-router-dom';
import * as S from './TableWithFilter.styles';
import Filter from '@assets/icons/filter_list';
import { ReactComponent as FilteredIcon } from '@assets/icons/filtered.svg';
import ProductImg from '@assets/images/product-img.jpg';
import { ReactComponent as CautionIcon } from '@assets/icons/caution.svg';
import { ReactComponent as CheckIcon } from '@assets/icons/check_square_24px.svg';
import { ReactComponent as DuplicateIcon } from '@assets/icons/duplicate.svg';
import DeleteConfirmModal from '@components/modules/pc/settings/common/DeleteConfirmModal/DeleteConfirmModal';
import DeleteErrorModal from '@components/modules/pc/settings/common/DeleteErrorModal/DeleteErrorModal';
import Overlay from '@components/elements/backdrops/Overlay/Overlay';
import UpdatePopUp from '@components/modules/pc/settings/common/UpdatePopUp/UpdatePopUp';
import { tagSearch } from '@lib/common/functions';
import useDeleteProduct from '@lib/pc/settings/product_master/useDeleteProduct';
import type { Storeroom, Tag, Todo } from '@lib/common/type';
import {
  DuplicateProduct,
  ProductMasterSettingState,
} from '@lib/pc/settings/product_master_setting/type';

type Props = {
  products: Todo;
  refetch: () => void;
  editData: Todo;
  setEditData: (editData: Todo) => void;
  popUp: boolean;
  setPopUp: (popUp: boolean) => void;
  messageKind: string;
  setMessageKind: (messageKind: string) => void;
  productName: string;
  setProductName: (productName: string) => void;
  searchWord: string;
  setSearchWord: (searchWord: string) => void;
  selectedTags: Todo[];
  setSelectedTags: (selectedTags: Todo[]) => void;
  selectedStorerooms: Storeroom[];
  handleSuccessEditSubmit: () => void;
  tagMenuActive: boolean;
  setTagMenuActive: (tagMenuActive: boolean) => void;
};

const TableWithFilter = ({
  products,
  refetch,
  editData,
  setEditData,
  popUp,
  setPopUp,
  messageKind,
  setMessageKind,
  productName,
  setProductName,
  searchWord,
  setSearchWord,
  selectedTags,
  setSelectedTags,
  selectedStorerooms,
  handleSuccessEditSubmit,
  tagMenuActive,
  setTagMenuActive,
}: Props) => {
  const { state } = useLocation<ProductMasterSettingState>();
  const history = useHistory();
  // タグの検索用にfilter使用
  const selectedTagIds = selectedTags
    ? selectedTags.map((item: Tag) => item['id'])
    : [];

  const [stateScrollPosition, setScrollPosition] = useState<number | null>(
    null
  );

  // 編集、削除メニューの表示
  const [editMenuOpen, setEditMenuOpen] = useState(false);
  const [deleteConfirm, setDeleteConfirm] = useState(false);
  const [deleteError, setDeleteError] = useState(false);

  const handleDeleteProduct = () => {
    deleteProduct.mutate();
    setMessageKind('delete');
    setProductName(editData?.semiProduct?.name);
  };

  const handleProductDeleteSuccess = () => {
    handleSuccessEditSubmit();
    refetch();
    setDeleteConfirm(false);
    setMessageKind('delete');
    setPopUp(!popUp);
  };

  const deleteProduct = useDeleteProduct(
    editData?.semiProduct?.id,
    handleProductDeleteSuccess,
    setDeleteError,
    setDeleteConfirm
  );

  const onClickDuplicate = async (p: DuplicateProduct) => {
    const data = { ...p, id: null, imageUrl: null };
    history.push(`/pc/settings/semi_product_master_setting_create`, {
      ...productMasterPickingState,
      stateScrollPosition: stateScrollPosition,
      stateSearchWord: searchWord,
      stateSelectedTags: selectedTags,
      editData: data,
    });
  };

  // XXXFI: This code is terrible, but at least it works
  const toggleProductRemarksVisibility = async (id: Todo) => {
    const target_id = 'remarks-' + id;
    const element = document.getElementById(target_id);

    if (element) {
      if (element.classList.contains('remarks-popup-hidden')) {
        element.classList.remove('remarks-popup-hidden');
        element.classList.add('remarks-popup');
      } else {
        element.classList.add('remarks-popup-hidden');
        element.classList.remove('remarks-popup');
      }
    }
  };

  // XXXFI: See above
  const hideProductRemarks = async (id: Todo) => {
    const target_id = 'remarks-' + id;
    const element = document.getElementById(target_id);

    if (element) {
      element.classList.add('remarks-popup-hidden');
      element.classList.remove('remarks-popup');
    }
  };

  useEffect(() => {
    if (state === undefined) {
      document?.getElementById('table-contents')?.scrollTo(0, 0);
      setSearchWord('');
      setSelectedTags([]);
    } else {
      const { stateScrollPosition, stateSearchWord, stateSelectedTags } = state;
      setSearchWord(stateSearchWord);
      setSelectedTags(stateSelectedTags);
      document
        ?.getElementById('table-contents')
        ?.scrollTo(0, stateScrollPosition);
    }
  }, [state]);

  const productMasterPickingState = {
    stateScrollPosition: stateScrollPosition,
    stateSearchWord: searchWord,
    stateSelectedTags: selectedTags,
  };

  useEffect(() => {
    refetch();
  }, [selectedStorerooms, products?.length]);

  // スクロール位置保存
  document?.getElementById('table-contents')?.addEventListener('scroll', () => {
    const currentScrollPosition =
      document?.getElementById('table-contents')?.scrollTop;
    if (currentScrollPosition) {
      setScrollPosition(currentScrollPosition);
    }
  });

  const FilterFunctionIcon = () => {
    return (
      <S.ListLeftHeadCell
        onClick={() => setTagMenuActive(!tagMenuActive)}
        isBlue={searchWord !== '' || selectedTags.length > 0}
      >
        <span className="filter-product">
          {searchWord !== '' || selectedTags.length > 0 ? (
            <FilteredIcon />
          ) : (
            <Filter isBlue={searchWord !== '' || selectedTags.length > 0} />
          )}
        </span>
      </S.ListLeftHeadCell>
    );
  };

  return (
    <S.Wrapper id="table-contents">
      <S.Table>
        <thead>
          <S.TableRow className="header">
            <S.TableHead>複製</S.TableHead>
            <S.TableHead>画像</S.TableHead>
            <S.TableHead>半製品コード</S.TableHead>
            <S.TableHead>
              <span
                onClick={() => {
                  setTagMenuActive(!tagMenuActive);
                }}
              >
                完成品名&nbsp;
                <FilterFunctionIcon />
              </span>
            </S.TableHead>
            <S.TableHead>半製品名（略称）</S.TableHead>
            <S.TableHead>賞味期限（日数）</S.TableHead>
            <S.TableHead>安全在庫（日数）</S.TableHead>
            <S.TableHead>終売</S.TableHead>
            <S.TableHead>非表示</S.TableHead>
            <S.TableHead>確認事項</S.TableHead>
            <S.TableHead>備考</S.TableHead>
          </S.TableRow>
        </thead>
        <tbody>
          {products ? (
            products
              .filter(
                (p: Todo) =>
                  p.semiProduct.name.includes(searchWord) ||
                  p.semiProduct.nameAbbreviation?.includes(searchWord) ||
                  p.semiProduct.nameKana?.includes(searchWord)
              )
              .filter((p: Todo) => tagSearch(p, selectedTagIds))
              .map((p: Todo) => {
                return (
                  // WATCHOUT: Must do this to avoid non-unique key error!
                  <React.Fragment
                    key={`product-with-remarks-${p.semiProduct.id}`}
                  >
                    <S.TableRow
                      className="hover-effect"
                      id={`product-${p.semiProduct.id}`}
                      key={`product-${p.semiProduct.id}`}
                      onClick={() => {
                        setEditData(p);
                        history.push(
                          `/pc/settings/semi_product_master_setting_edit/${p.semiProduct.id}`,
                          {
                            ...productMasterPickingState,
                            stateScrollPosition: stateScrollPosition,
                            stateSearchWord: searchWord,
                            stateSelectedTags: selectedTags,
                          }
                        );
                      }}
                    >
                      <S.TableColumn>
                        {/* コピー */}
                        <span
                          onClick={(e) => {
                            onClickDuplicate(p.semiProduct);
                            e.stopPropagation();
                          }}
                        >
                          <DuplicateIcon />
                        </span>
                      </S.TableColumn>
                      {/* 完成品画像 */}
                      <S.TableColumn>
                        <img
                          className="product-image"
                          src={p.semiProduct.imageUrl || ProductImg}
                        />
                      </S.TableColumn>
                      {/* 完成品コード */}
                      <S.TableColumn>
                        {p.semiProduct.semiProductCode}
                      </S.TableColumn>
                      <S.TableColumn>{p.semiProduct.name}</S.TableColumn>
                      <S.TableColumn>
                        {p.semiProduct.nameAbbreviation}
                      </S.TableColumn>
                      {/* 賞味期限日数 */}
                      <S.TableColumn>{p.semiProduct.expiryDays}</S.TableColumn>
                      {/* 安全在庫日数 */}
                      <S.TableColumn>
                        {p.semiProduct.safetyStockDays}
                      </S.TableColumn>
                      {/* 終売 */}
                      <S.TableColumn>
                        {p.semiProduct.isDiscontinued ? <CheckIcon /> : ''}
                      </S.TableColumn>
                      {/* 非表示 */}
                      <S.TableColumn>
                        {p.semiProduct.hidden ? <CheckIcon /> : ''}
                      </S.TableColumn>
                      {/* 確認事項 */}
                      <S.TableColumn>
                        {(p.caseIds.length === 0 ||
                          p.storerooms.length === 0) && <CautionIcon />}
                      </S.TableColumn>
                      <S.TableColumn>
                        <div
                          className="remarks-ellipsis"
                          onClick={(e) => {
                            toggleProductRemarksVisibility(p.semiProduct.id);
                            e.stopPropagation();
                          }}
                        >
                          {p.semiProduct.remarks}
                        </div>
                      </S.TableColumn>
                    </S.TableRow>
                    <S.TableRow
                      className="remarks-popup-hidden"
                      key={`remarks-${p.semiProduct.id}`}
                      id={`remarks-${p.semiProduct.id}`}
                      onClick={(e) => {
                        hideProductRemarks(p.semiProduct.id);
                        e.stopPropagation();
                      }}
                    >
                      <td className="remarks" colSpan={12}>
                        <span style={{ fontWeight: 'bold' }}>備考：</span>
                        {p.semiProduct.remarks}
                      </td>
                    </S.TableRow>
                  </React.Fragment>
                );
              })
          ) : (
            <S.TableRow>
              <td className="remarks" colSpan={12}>
                <S.CircularIconWrapper>
                  <CircularProgress style={{ color: '#64b2f9' }} />
                </S.CircularIconWrapper>
              </td>
            </S.TableRow>
          )}
          {editMenuOpen && (
            <Overlay handleClick={() => setEditMenuOpen(!editMenuOpen)} />
          )}
          {deleteConfirm && (
            <DeleteConfirmModal
              name={editData.semiProduct.name}
              onClickCancel={() => {
                setEditData(null);
                setDeleteConfirm(false);
              }}
              onClickDelete={() => handleDeleteProduct()}
            />
          )}
          {deleteError && (
            <DeleteErrorModal onClickOK={() => setDeleteError(false)} />
          )}
        </tbody>
      </S.Table>
      <div>
        <UpdatePopUp
          popUp={popUp}
          taskKind={'productMaster'}
          name={productName}
          handleClose={() => {
            setPopUp(false);
            setMessageKind('');
            setProductName('');
            history.push({
              pathname: `/pc/settings/semi_product_master_setting`,
              state,
            });
          }}
          messageKind={messageKind}
        />
      </div>
    </S.Wrapper>
  );
};

export default TableWithFilter;
