import * as S from './TagList.styles';
import type { Tag } from '@lib/common/type';

type Props = {
  tags: Tag[];
  selectedTagIds: number[];
  setSelectedTagIds: (selectedTagIds: number[]) => void;
};

const TagList = ({ tags, selectedTagIds, setSelectedTagIds }: Props) => {
  const onChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const changedId = Number(event.target.value);
    const updatedTagIds = event.target.checked
      ? [...selectedTagIds, changedId]
      : selectedTagIds.filter((id) => id !== changedId);
    setSelectedTagIds(updatedTagIds);
  };
  return (
    <S.TagList>
      {tags.map((tag) => (
        <S.TagItem key={tag.id}>
          <S.TagLabel $checked={selectedTagIds.includes(tag.id)}>
            <S.TagCheckBox onChange={onChange} type="checkbox" value={tag.id} />
            <S.TagName checked={selectedTagIds.includes(tag.id)}>
              {tag.name}
            </S.TagName>
          </S.TagLabel>
        </S.TagItem>
      ))}
    </S.TagList>
  );
};
export default TagList;
