import styled from 'styled-components';

export const Wrapper = styled.div`
  background: ${(props) => props.theme.palette.beige};
`;

export const Title = styled.div`
  align-items: center;
  justify-content: center;
  font-weight: bold;
  font-size: 1.125rem;
  padding-top: 1rem;
  padding-right: 0.8rem;
`;

export const TitleText = styled.p`
  display: flex;
  justify-content: center;
  padding-right: 0.7rem;
  padding-left: 1rem;
`;

export const SearchConditionList = styled.div`
  position: sticky;
  top: 0;
  padding: 1rem;
  background: ${(props) => props.theme.palette.beige};
`;

export const SearchConditionPanel = styled.div`
  display: flex;
  align-items: center;
  margin-top: 0.75rem;
  width: 100%;
  height: 2.5rem;
  padding: 0 0.75rem;
  border-radius: 0.5rem;
  color: ${(props) => props.theme.palette.khaki};
  background: ${(props) => props.theme.palette.white};

  &:nth-of-type(1) {
    margin-top: 0;
  }
`;

export const SearchConditionInput = styled.input`
  margin-left: 0.75rem;
  width: 100%;

  outline: none;

  &::placeholder {
    color: ${(props) => props.theme.palette.khaki};
  }
`;
