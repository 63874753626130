import { decode } from 'jsonwebtoken';

const useCurrentUser = () => {
  const token = localStorage.getItem('pc_token');
  const decodedJwt = token && decode(token, { json: true });
  // TODO: サーバー側にユーザ名などをjwtに含めてもらって型をつける
  return decodedJwt;
};

export default useCurrentUser;
