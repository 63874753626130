import styled from 'styled-components';

export const Wrapper = styled.div`
  flex-flow: column;
  background: ${(props) => props.theme.palette.beige};
`;

export const Table = styled.div`
  display: flex;
`;
