import * as S from './PickingProductSearchForm.styles';
import { ReactComponent as SearchIcon } from '@assets/icons/mobile/search.svg';
import { ReactComponent as CloseIcon } from '@assets/icons/close_24px.svg';
// import { ReactComponent as FilterIcon } from '@assets/icons/filter_list.svg';
// import { ReactComponent as FilteredIcon } from '@assets/icons/mobile/filtered.svg';
// import { InputWithIcon } from '@components/elements/inputs/InputWithIcon/InputWithIcon';

type Props = {
  searchProductChar: string;
  setSearchProductChar: (searchProductChar: string) => void;
  completedCheckState: boolean;
  setCompletedCheckState: (completedCheckState: boolean) => void;
  allocateTrueStatus: boolean;
};

const PickingProductSearchForm = ({
  searchProductChar,
  setSearchProductChar,
  completedCheckState,
  setCompletedCheckState,
  allocateTrueStatus,
}: Props) => {
  return (
    <S.SearchWrapper>
      <S.SearchConditionInput
        placeholder="商品／出荷先"
        onChange={(event) => setSearchProductChar(event.target.value)}
        value={searchProductChar}
      />
      {searchProductChar ? (
        <S.Icon onClick={() => setSearchProductChar('')}>
          <CloseIcon />
        </S.Icon>
      ) : (
        <S.Icon>
          <SearchIcon />
        </S.Icon>
      )}
      <S.StatusLabel>
        <S.StatusCheckBox
          onChange={() => {
            setCompletedCheckState(!completedCheckState);
          }}
          type="checkbox"
          value={'true'}
        />
        <S.StatusName checked={completedCheckState}>
          {allocateTrueStatus ? '振分済以外表示' : 'ﾁｪｯｸ済以外表示'}
        </S.StatusName>
      </S.StatusLabel>
    </S.SearchWrapper>
  );
};

export default PickingProductSearchForm;
