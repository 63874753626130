import { useEffect, useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { HTML5Backend } from 'react-dnd-html5-backend';
import { DndProvider } from 'react-dnd';
import * as S from './ShippingDestinationEditWrapper.styles';
import ConfirmPopUp from '@components/modules/common/ConfirmPopUp/ConfirmPopUp';
import ErrorMsgPopUp from '@components/modules/common/ErrorMsgPopUp/ErrorMsgPopUp';
import Overlay from '@components/elements/backdrops/Overlay/Overlay';
import ShippingDestinationContents from '@components/modules/pc/settings/shipping_destination_setting/ShippingDestinationContents/ShippingDestinationContents';
import UpdatePopUp from '@components/modules/pc/settings/common/UpdatePopUp/UpdatePopUp';
import { ShippingDestination } from '@lib/pc/settings/shipping_destination_setting/type';
import SalesStoreContents from '../../SalesStoreContents/SalesStoreContents';
import { ShippingDestinationState } from '@lib/pc/settings/shipping_destination_setting/type';

type Props = {
  shippingDestination: ShippingDestination;
  refetch: () => void;
  timestamp: string;
};

const ShippingDestinationEditWrapper = ({
  shippingDestination,
  refetch,
}: Props) => {
  const { state } = useLocation<ShippingDestinationState>();
  const history = useHistory();

  const [newData, setNewData] = useState({
    id: state.id ? state.id : 0,
    code: state.code ? state.code : '',
    name: state.name ? state.name : '',
    transportLeadTime: state.transportLeadTime ? state.transportLeadTime : 0,
  });

  // 削除確認メッセージ
  const [confirmMsg, setConfirmMsg] = useState('');
  const [errMsg, setErrMsg] = useState('');
  const [enableToSave, setEnableToSave] = useState(false);
  const [updating, setUpdating] = useState(false);
  const [selectedTabIndex, setSelectedTabIndex] = useState(0);
  // 確認メッセージhandleOk判定用:一覧画面へ移動か、別タブ表示か。
  const [goBack, setGoBack] = useState(false);
  const [isChanged, setIsChanged] = useState(false);
  const [selectedTabId, setSelectedTabId] = useState(0);
  const [updatePopUp, setUpdatePopUp] = useState(false);

  const ShippingGroupSettingTabs = ['出荷先', '販売店'];

  // 一覧画面へ戻る
  const onClickGoBack = () => {
    if (isChanged) {
      setGoBack(true);
      setConfirmMsg('変更した内容を保存せずに終了します。よろしいですか？');
    } else {
      history.push({
        pathname: `/pc/settings/shipping_destination_setting`,
        state: {
          ...state,
          stateSelectedTabId: 0,
        },
      });
    }
  };

  const handleClickTab = (i: number) => {
    setGoBack(false);
    setEnableToSave(false);
    if (isChanged) {
      setSelectedTabId(i);
      setConfirmMsg('変更した内容を保存せずに終了します。よろしいですか？');
    } else {
      setSelectedTabIndex(i);
    }
  };

  // 確認メッセージOK
  const handleOk = () => {
    // ここで一覧画面へ戻るか別タブを表示するかの判断が必要
    if (goBack) {
      history.push({
        pathname: `/pc/settings/shipping_destination_setting`,
        state: {
          ...state,
          messageKind: '',
          productName: '',
          stateSelectedTabId: 0,
        },
      });
    } else {
      setSelectedTabIndex(selectedTabId);
    }
    setIsChanged(false);
    setConfirmMsg('');
  };
  // 確認メッセージキャンセル
  const handleCancel = () => {
    setConfirmMsg('');
  };

  useEffect(() => {
    if (state.stateSelectedTabId) {
      setSelectedTabIndex(state.stateSelectedTabId);
    } else {
      setSelectedTabIndex(0);
    }
  }, [state]);

  return (
    <>
      <S.Wrapper>
        <S.TabWrapper>
          {ShippingGroupSettingTabs.map((d: string, i: number) => (
            <div key={d}>
              <S.TabArea>
                <S.Tab
                  key={d}
                  tabCount={ShippingGroupSettingTabs.length}
                  active={i == selectedTabIndex}
                  onClick={() => {
                    handleClickTab(i);
                  }}
                >
                  {d}
                </S.Tab>
              </S.TabArea>
            </div>
          ))}
        </S.TabWrapper>

        <DndProvider backend={HTML5Backend}>
          {/* 出荷先 */}
          {selectedTabIndex === 0 && (
            <ShippingDestinationContents
              newData={newData}
              setNewData={setNewData}
              updating={updating}
              setUpdating={setUpdating}
              shippingDestination={shippingDestination}
              refetch={refetch}
              onClickGoBack={onClickGoBack}
              setIsChanged={setIsChanged}
              enableToSave={enableToSave}
              setEnableToSave={setEnableToSave}
              setUpdatePopUp={setUpdatePopUp}
            />
          )}

          {/* 販売店 */}
          {selectedTabIndex === 1 && (
            <SalesStoreContents
              shippingDestination={shippingDestination}
              setEnableToSave={setEnableToSave}
              setIsChanged={setIsChanged}
              onClickGoBack={onClickGoBack}
              refetch={refetch}
              setUpdatePopUp={setUpdatePopUp}
            />
          )}
        </DndProvider>
      </S.Wrapper>
      <ErrorMsgPopUp
        errMsg={errMsg}
        handleClose={() => {
          setErrMsg('');
          setUpdating(false);
        }}
        fromPc={true}
        zIndex={9999}
      />
      {errMsg && (
        <Overlay
          dark
          handleClick={() => {
            setErrMsg('');
            setUpdating(false);
          }}
        />
      )}
      <ConfirmPopUp
        fromPc={true}
        confirmMsg={confirmMsg}
        zIndex={1004}
        handleOk={handleOk}
        handleCancel={handleCancel}
      />
      <UpdatePopUp
        popUp={updatePopUp}
        taskKind={'messageOnly'}
        messageKind={'update'}
        handleClose={() => setUpdatePopUp(false)}
      />
      {updatePopUp && <Overlay handleClick={() => setUpdatePopUp(false)} />}
    </>
  );
};

export default ShippingDestinationEditWrapper;
