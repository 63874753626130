import { FC } from 'react';
import ReactSelect from 'react-select';
import type { Storeroom, Todo } from '@lib/common/type';
import { NO_OPTION_JAPANESE } from '@lib/pc/common/constants';

type Props = {
  storerooms: Storeroom[] | undefined;
  value: number | undefined;
  setSelectedStoreroomId: (selectedStoreroomId: string) => void;
  rightSlideSidebarActive?: boolean;
};

const StoreroomSelect: FC<Props> = ({
  storerooms,
  value,
  setSelectedStoreroomId,
  rightSlideSidebarActive,
}: Props) => {
  const handleChangeStoreroom = (e: number) => {
    setSelectedStoreroomId(e.toString());
  };

  const toReactSelectOptions = (options?: { id: number; name: string }[]) =>
    options?.map(({ id, name }) => ({
      value: id,
      label: name,
    }));

  const allStoreRooms = { id: 0, name: '全ての場所（総数）' };
  const mergedStorerooms = storerooms && storerooms.concat(allStoreRooms);
  const sortedStorerooms =
    mergedStorerooms && mergedStorerooms.sort((a, b) => a.id - b.id);

  const reactSelectOptions =
    storerooms && toReactSelectOptions(sortedStorerooms);
  const currentValue = reactSelectOptions?.find((o) => o.value === value);

  const styles = {
    control: (baseStyles: Todo) => ({
      ...baseStyles,
      width: '237px',
      height: '42px',
      borderRadius: '0.5rem',
      fontWeight: 400,
      textAlign: 'left',
      borderStyle: 'hidden',
      outline: '0.5px solid rgba(0,0,0,0.08)',
      cursor: 'pointer',
      '@media (max-width: 599px)': {
        width: '16rem',
      },
    }),
    menu: (baseStyles: Todo) => ({
      ...baseStyles,
      top: '37px',
      zIndex: 1002,
    }),
    option: (baseStyles: Todo) => ({
      ...baseStyles,
      textAlign: 'left',
      fontWeight: 400,
      fontSize: '15px',
    }),
    menuList: (baseStyles: Todo) => ({
      ...baseStyles,
      maxHeight: '165px',
    }),
  };

  return (
    <ReactSelect
      options={reactSelectOptions}
      value={currentValue ? currentValue : null}
      onChange={(option) => {
        option && handleChangeStoreroom(option.value);
      }}
      styles={styles}
      isDisabled={rightSlideSidebarActive}
      isSearchable={false}
      components={{
        IndicatorSeparator: () => null,
      }}
      noOptionsMessage={() => NO_OPTION_JAPANESE}
    />
  );
};

export default StoreroomSelect;
