import useCurrentUser from '@lib/mobile/common/hooks/useCurrentUser';
import { Redirect, Route, RouteProps } from 'react-router-dom';

type Props = RouteProps & { component: React.FunctionComponent };
const MobileAuthRoute = ({ component: Component, ...restOfProps }: Props) => {
  const currentUser = useCurrentUser();

  return (
    <Route
      {...restOfProps}
      render={(props) =>
        currentUser ? (
          <Component {...props} />
        ) : (
          <Redirect
            to={{ pathname: '/mobile/login', state: { from: props.location } }}
          />
        )
      }
    />
  );
};

export default MobileAuthRoute;
