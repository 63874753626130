import { useMutation } from 'react-query';
import humps from 'humps';
import { axiosInstance } from '@lib/pc/common/api/axiosConfig';

type InputParams = {
  customerId: number | null;
  employeeId: number | null;
  entryDate: string | null;
  title: string;
  comment: string;
  details: DetailParams[];
};

type DetailParams = {
  productId: number | null;
  deliveryDate: string | null;
  shippingDate: string | null;
  salesStoreId: number | null;
  primaryEstimatedAmount: number | null;
  secondaryEstimatedAmount: number | null;
  fixedAmount: number | null;
};

const useCreateSalesInformationMutation = (handleCreateSuccess: () => void) => {
  return useMutation(
    async (params: InputParams) => {
      const apiParams = {
        ...params,
        detailsAttributes: params.details,
      };
      return await axiosInstance().post(
        '/api/v1/sales_informations',
        humps.decamelizeKeys(apiParams)
      );
    },
    {
      onSuccess: () => {
        handleCreateSuccess();
      },
      onError: () => alert('エラーが発生しました'),
    }
  );
};

export default useCreateSalesInformationMutation;
