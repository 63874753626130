// 「2024/11/01」を11/01形式へ変換
export const formatDate = (dateStr: string): string => {
  const date = new Date(dateStr);
  const month = (date.getMonth() + 1).toString().padStart(2, '0');
  const day = date.getDate().toString().padStart(2, '0');

  return `${month}/${day}`;
};

// 曜日を返す
export const formatDayOfWeek = (dateStr: string): string => {
  const date = new Date(dateStr);
  const dayOfWeek = ['日', '月', '火', '水', '木', '金', '土'][date.getDay()];

  return dayOfWeek;
};
