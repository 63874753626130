import styled from 'styled-components';

export const Wrapper = styled.div`
  margin: 0.5rem 0;
  display: flex;
  flex-direction: column;
  border-radius: 16px;
  background: ${(props) => props.theme.palette.white};
`;

export const Header = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 2.25rem 1.75rem;
  background: ${(props) => props.theme.palette.white};
`;

export const MainContainer = styled.div`
  padding: 2rem;

  border-radius: 0 0 16px 16px;
  background: ${(props) => props.theme.palette.beige};
`;

export const DateWrapper = styled.div`
  display: flex;
  justify-content: center;
  gap: 1rem;
`;

export const Date = styled.div`
  font-size: 32px;
  font-weight: bold;
  height: 48px;
  line-height: 32px;
  text-align: center;
  padding: 5px 0;
  user-select: none;

  > span {
    font-size: 24px;
    margin: 0 5px;
  }
`;

export const ArrowLeft = styled.div`
  align-items: baseline;
  cursor: pointer;
  margin-right: 7px;
`;

export const ArrowRight = styled.div`
  align-items: baseline;
  cursor: pointer;
  margin-left: -7px;
`;
