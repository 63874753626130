import styled from 'styled-components';

export const ModalWrapper = styled.div`
  width: 500px;
  background: ${(props) => props.theme.palette.white};
  position: fixed;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  z-index: 10001;
  box-shadow: 0px 6px 10px rgba(0, 0, 0, 0.07), 0px 1px 18px rgba(0, 0, 0, 0.06),
    0px 3px 5px rgba(0, 0, 0, 0.1);
  border-radius: 8px;
  padding: 1rem;
`;

export const ModalHeadWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 10px;
  margin-left: 30px;
`;

export const CloseIconWrapper = styled.div`
  padding: 10px;
  background: none;
  float: right;
  cursor: pointer;
  margin-right: auto;
`;

export const ModalFaxWrapper = styled.div`
  display: flex;
  justify-content: center;
  margin-top: 24px;
`;

export const ConfirmationDateText = styled.div`
  margin-top: 1rem;
  font-size: 14px;
  display: flex;
  justify-content: center;
`;

export const ModalButtonWrapper = styled.div`
  display: flex;
  justify-content: center;
  margin-top: 24px;
`;

export const ModalFormTextArea = styled.textarea`
  width: 100%;
  min-height: 4rem;
  color: ${(props) => props.theme.palette.baseBlack};
  font-size: 1rem;
  line-height: 1.25rem;
  padding: 0.325rem;
  border: none;
  border-radius: 0.5rem;
  border: 1px solid rgba(0, 0, 0, 0.08);
  background: ${(props) => props.theme.palette.white};
  word-wrap: break-word;
  word-break: break-all;
  white-space: normal;
  margin-top: 8px;
`;
