import React, { FC, useEffect, useState } from 'react';
import moment from 'moment';
import * as S from './TableWithFixedSidebar.styles';
import { CircularProgress } from '@material-ui/core';
import Filter from '@assets/icons/filter_list';
import { ReactComponent as FilteredIcon } from '@assets/icons/filtered.svg';
import MemorizedListCell from '@components/modules/pc/order_summary/MemorizedListCell/MemorizedListCell';
import Overlay from '@components/elements/backdrops/Overlay/Overlay';
import TagMenu from '@components/modules/common/TagMenu/TagMenu';
import TagListModal from '@components/modules/common/TagListModal/TagListModal';
import { generateMonthlyDateArray } from '@lib/pc/order_summary/functions';
import { tagSearch } from '@lib/common/functions';
import { PRODUCT_KIND, Todo } from '@lib/common/type';
import { SelectedProductsOrder } from '@lib/pc/order_summary/type';
import { isSameIndex } from '@lib/pc/production_schedule/functions';

type Props = {
  date: Todo;
  data: Todo[];
  rightSlideBarOpen: boolean;
  selectedProductsOrder: SelectedProductsOrder | null;
  setRightSlideBarOpen: (rightSlideBarOpen: boolean) => void;
  selectIndex: number;
  selectedProductId: number;
  setSelectedProductId: (selectedProductId: number) => void;
  setSelectedCustomerId: (selectedCustomerId: number) => void;
  focusedCellIndex: number | null;
  setFocusedCellIndex: (selectedProductId: number | null) => void;
  focusedRowIndex: number | null;
  setFocusedRowIndex: (selectedCustomerId: number | null) => void;
  selectedDate: string;
  setSelectedDate: (selectedDate: string) => void;
};

const TableWithFixedSidebar: FC<Props> = ({
  date,
  data,
  rightSlideBarOpen,
  selectedProductsOrder,
  setRightSlideBarOpen,
  selectIndex,
  setSelectedProductId,
  setSelectedCustomerId,
  focusedCellIndex,
  setFocusedCellIndex,
  focusedRowIndex,
  setFocusedRowIndex,
  selectedDate,
  setSelectedDate,
}: Props) => {
  const [searchWord, setSearchWord] = useState('');
  const [tagMenuActive, setTagMenuActive] = useState(false);
  const [tagListModalActive, setTagListModalActive] = useState(false);
  const [selectedTags, setSelectedTags] = useState<Todo>([]);
  const [selectedCalendarCellIdx, setSelectedCalendarCellIdx] = useState(
    new Date().getDate() - 1
  );
  const selectedTagIds = selectedTags.map((item: Todo) => item['id']);

  const today = new Date();

  const handleOnclickCell = (
    cellIndex: number,
    rowIndex: number,
    selectedDate: string,
    productId: number
  ) => {
    setFocusedCellIndex(cellIndex);
    setFocusedRowIndex(rowIndex);
    setSelectedDate(selectedDate);
    setSelectedProductId(productId);
    setRightSlideBarOpen(true);
  };

  const ProductNameArr = data
    ? data.map((p: Todo) => ({
        productName: p.name,
        productCode: p.productCode,
        tagIds: p.tagIds,
        orderSummaryDetails: p.orderSummaries,
      }))
    : [];

  const handleSearch = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSearchWord(e.target.value);
  };

  useEffect(() => {
    setTagMenuActive(false);
  }, [selectedTagIds.length === 0]);

  return (
    <>
      <div>
        <S.ListWrapper>
          <S.ListSidebar>
            <div>
              <S.ListLeftHeadCell
                invalid={rightSlideBarOpen}
                onClick={() => setTagMenuActive(!tagMenuActive)}
                isBlue={searchWord !== '' || selectedTagIds.length > 0}
              >
                <span className="filter-product">
                  {searchWord !== '' || selectedTagIds.length > 0 ? (
                    <>
                      <FilteredIcon />
                      <div className="filter_text">商品の絞込み中</div>
                    </>
                  ) : (
                    <>
                      <Filter
                        isBlue={searchWord !== '' || selectedTagIds.length > 0}
                      />
                      <div className="filter_text">商品の絞込</div>
                    </>
                  )}
                </span>
              </S.ListLeftHeadCell>
            </div>
            {/* 商品名、商品コード */}
            {ProductNameArr.filter(
              (p: Todo) =>
                p.productName?.includes(searchWord) ||
                p.productCode?.toString().includes(searchWord)
            )
              .filter((p: Todo) => tagSearch(p, selectedTagIds))
              .map(
                (
                  s: {
                    productName: string;
                    productCode: number;
                  },
                  i: number
                ) => {
                  return (
                    <S.ListLeftHeadCell key={i + s.productCode}>
                      <S.ListLeftHeadName>{s.productName}</S.ListLeftHeadName>
                      <S.ListLeftHeadMeta>
                        <span>{s.productCode}</span>
                      </S.ListLeftHeadMeta>
                    </S.ListLeftHeadCell>
                  );
                }
              )}
          </S.ListSidebar>
          <S.ListBody className={selectedProductsOrder ? 'move-to-left' : ''}>
            {/* ヘッダー日付 */}
            <S.ListRow>
              {data &&
                generateMonthlyDateArray(
                  data[0].numberOfDaysParamsMonth,
                  date,
                  selectIndex
                ).map((p, i) => {
                  const isToday = isSameIndex(
                    i,
                    selectedCalendarCellIdx,
                    data[0].numberOfDaysParamsMonth
                  );
                  return (
                    <S.ListHeadCell
                      key={i}
                      highLighten={isToday}
                      dayOfWeek={moment(p.productionDate).format('ddd')}
                      index={i}
                      numOfIndex={data[0].numberOfDaysParamsMonth}
                      onClick={() => setSelectedCalendarCellIdx(i)}
                    >
                      {i === 0
                        ? moment(p.productionDate).format('M/D(ddd)')
                        : i === data[0].numberOfDaysParamsMonth + 1
                        ? '合計'
                        : i === data[0].numberOfDaysParamsMonth + 2
                        ? '前年同月'
                        : moment(p.productionDate).format('DD(ddd)')}
                    </S.ListHeadCell>
                  );
                })}
            </S.ListRow>
            {/* 受注個数 */}
            {data ? (
              data
                .filter(
                  (p: Todo) =>
                    p.name?.includes(searchWord) ||
                    p.productCode?.toString().includes(searchWord)
                )
                .filter((p: Todo) => tagSearch(p, selectedTagIds))
                .map((p: Todo, rowIndex: number) => {
                  return (
                    <S.ListRow key={rowIndex}>
                      {p?.orderSummaries?.map(
                        (orderSummary: Todo[], cellIdx: number) => {
                          return (
                            <MemorizedListCell
                              key={cellIdx}
                              today={today}
                              p={p}
                              rowIndex={rowIndex}
                              cellIdx={cellIdx}
                              orderSummary={orderSummary}
                              setSelectedProductId={setSelectedProductId}
                              setSelectedCustomerId={setSelectedCustomerId}
                              focusedCellIndex={focusedCellIndex}
                              setFocusedCellIndex={setFocusedCellIndex}
                              focusedRowIndex={focusedRowIndex}
                              setFocusedRowIndex={setFocusedRowIndex}
                              selectedDate={selectedDate}
                              handleOnclickCell={handleOnclickCell}
                              monthlyDateNum={data[0].numberOfDaysParamsMonth}
                              selectedCalendarCellIdx={selectedCalendarCellIdx}
                            />
                          );
                        }
                      )}
                    </S.ListRow>
                  );
                })
            ) : (
              <S.CircularIconWrapper>
                <CircularProgress style={{ color: '#64b2f9' }} />
              </S.CircularIconWrapper>
            )}
          </S.ListBody>
        </S.ListWrapper>
      </div>
      {tagMenuActive && (
        <TagMenu
          searchParam={searchWord}
          selectedTags={selectedTags}
          setSelectedTags={(tags) => setSelectedTags(tags)}
          setOpenTagModal={() => setTagListModalActive(true)}
          handleChange={handleSearch}
          deleteSearchParam={() => setSearchWord('')}
        />
      )}
      {tagListModalActive && (
        <TagListModal
          selectedTags={selectedTags}
          setSelectedTags={(tags) => setSelectedTags(tags)}
          tagListModalActive={tagListModalActive}
          productKind={PRODUCT_KIND.PRODUCT}
          handleClose={() => setTagListModalActive(false)}
        />
      )}
      {tagMenuActive && (
        <Overlay
          zIndex={9997}
          handleClick={() => {
            setTagMenuActive(false);
          }}
        />
      )}
    </>
  );
};

export default TableWithFixedSidebar;
