import type { Todo } from '@lib/common/type';
import { useState } from 'react';
import * as tf from '@tensorflow/tfjs';
import { openDB } from 'idb';

// 包装：台車、ケース、バラの計算
export const calcTotalFunc = (
  e: React.ChangeEvent<HTMLInputElement>,
  newData: Todo,
  piecesPerBox: number
) => {
  let caseTotal = 0;
  let baraTotal = 0;

  if (e.target.name.startsWith('planned')) {
    // 包装予定
    caseTotal = Number(newData.plannedCase) * (piecesPerBox || 1);
    baraTotal = Number(newData.plannedBara);
  } else if (e.target.name.startsWith('completed')) {
    // 包装完了
    if (newData.completedCase) {
      caseTotal = Number(newData.completedCase) * (piecesPerBox || 1);
    }
    if (newData.completedBara) {
      baraTotal = Number(newData.completedBara);
    }
  }
  return caseTotal + baraTotal;
};

// 賞味期限日妥当性チェック
export const checkValidDate = (checkDate: string) => {
  // checkDateは6桁の年月日の想定
  if (checkDate.length === 6) {
    const strDate = '20' + checkDate;
    const date = new Date(strDate);
    const INVALID_DATE = new Date('Invalid Date');

    if (date === INVALID_DATE) {
      // 無効な日付
      return false;
    } else {
      // 有効な日付
      return true;
    }
  } else {
    // 無効な日付の桁数
    return false;
  }
};

export const useCounter = (init: number) => {
  const [dateCount, setDateCount] = useState(init);
  const increment = () => setDateCount((prevValue) => prevValue + 1);
  const decrement = () => setDateCount((prevValue) => prevValue - 1);
  const resetCount = () => setDateCount(0);

  return { dateCount, increment, decrement, resetCount };
};

export const useDateCheckState = (str: string) => {
  const [dateCheckState, setDateCheckState] = useState(str);
  // バックエンドから下記の文字列で返ってくる
  // DATE_NOT_RECOGNIZED: 0,     # 日付認識できていないor日付認識できたが日付が違うものが含まれる
  const ngState = () => setDateCheckState('DATE_NOT_RECOGNIZED');
  // DATE_RECOGNIZED: 2          # 日付認識ALL完了
  const okState = () => setDateCheckState('DATE_RECOGNIZED');
  // PARTIAL_DATE_RECOGNIZED: 1, # 一部日付認識できた
  const cautionState = () => setDateCheckState('PARTIAL_DATE_RECOGNIZED');
  const resetState = () => setDateCheckState('');

  return { dateCheckState, ngState, okState, cautionState, resetState };
};

// モデルの読み込み
export const loadModel = async () => {
  const tensorflowjs = await checkDatabaseExists('tensorflowjs');
  if (tensorflowjs) {
    const modelinfo = await checkDatabaseExists('model-info');
    if (modelinfo) {
      const modelUrl = `${process.env.REACT_APP_MIYOSHI_S3_URL}/model.json`;
      const modelResponse = await fetch(modelUrl);
      // s3にあるmodel.jsonの最終更新日時を取得する
      const lastModified = modelResponse.headers.get('last-modified');
      // indexedDB上にあるmodel.jsonの最終更新日時を取得する
      const db = await openDB('model-info', 1);
      const tx = db.transaction('model', 'readonly');
      const storedLastModified = await tx
        .objectStore('model')
        .get('lastModified');
      // 日付を比較する
      if (storedLastModified.value === lastModified) {
        // 最新モデル：変更なし
      } else {
        // モデルの再取得が必要
        db.close();
        // 全部削除して作る
        await deleteModel();
        await createModel();
      }
      db.close();
    } else {
      // DB:model-info無し
      // 全部削除して作る
      await deleteModel();
      await createModel();
    }
  } else {
    // DB:tensorflowjs無し
    // 全部削除して作る
    await deleteModel();
    await createModel();
  }
};

// モデルのクリア
export const deleteModel = async () => {
  const deleteDBRequest1 = indexedDB.deleteDatabase('tensorflowjs');
  const deleteDBRequest2 = indexedDB.deleteDatabase('model-info');

  return Promise.all([deleteDBRequest1, deleteDBRequest2])
    .then(() => {
      console.log('Databases deleted successfully');
    })
    .catch((error) => {
      console.log('Error deleting databases: ', error);
    });
};

// indexedDB作成
export const createModel = async () => {
  // model.jsonのダウンロード
  const modelUrl = `${process.env.REACT_APP_MIYOSHI_S3_URL}/model.json`;
  const modelResponse = await fetch(modelUrl);
  const modelData = await modelResponse.json();
  // 最終更新日時を取得する
  const lastModified = modelResponse.headers.get('last-modified');
  // 各binファイルをダウンロードして、ブラウザのFileオブジェクトに変換する
  const binUrls = modelData.weightsManifest[0].paths;
  const binFileRequests = binUrls.map((url: string) =>
    fetch(`${process.env.REACT_APP_MIYOSHI_S3_URL}/${url}`)
  );
  const binFileResponses = await Promise.all(binFileRequests);
  const binArrayBuffers = await Promise.all(
    binFileResponses.map((res) => res.arrayBuffer())
  );
  const binFiles = binArrayBuffers.map((binArrayBuffer, i) => {
    const filename = binUrls[i].split('/').pop();
    if (!filename) throw new Error('Invalid bin file URL');
    const uint8Array = new Uint8Array(binArrayBuffer);
    return new File([uint8Array], filename);
  });

  // モデルを読み込み、indexedDBに保存する
  const modelFile = new File([JSON.stringify(modelData)], 'model.json', {
    type: 'application/json',
  });
  const model = await tf.loadGraphModel(
    tf.io.browserFiles([modelFile, ...binFiles])
  );

  // 最終更新日時をデータベースに保存する
  const db = await openDB('model-info', 1, {
    upgrade(db: Todo) {
      db.createObjectStore('model', { keyPath: 'id' });
    },
  });
  await Promise.all([
    db.put('model', { id: 'lastModified', value: lastModified }),
    model.save('indexeddb://my-model'),
  ]);
  db.close();

  return model;
};

// indexedDB作成状況チェック
export const checkDatabaseExists = async (dbName: string) => {
  try {
    const db = await openDB(dbName, 1);
    const objectStoreNames = db.objectStoreNames;
    const storeNames = Array.from(objectStoreNames);
    if (storeNames.length === 0) {
      db.close();
      return false;
    }
    const tx = db.transaction(storeNames, 'readonly');
    const requestArray = storeNames.map((storeName) =>
      tx.objectStore(storeName).count()
    );
    const dataArray = await Promise.all(requestArray);
    const isDataExists = dataArray.some((count) => count > 0);
    if (isDataExists) {
      db.close();
    } else {
      db.close();
      return false;
    }
    db.close();
    return true;
  } catch (error) {
    console.error(error);
    return false;
  }
};
