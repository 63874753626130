import { Link } from 'react-router-dom';
import styles from 'styled-components';

export const Wrapper = styles.div`
  display: flex;
  align-items: center;
  // 仮のスタイル
  // justify-content: space-between;
  padding: 1rem 1.75rem;
`;

export const BackButton = styles.button`
  padding: 0.875rem 1rem;
  background: none;
`;

export const IconWrapper = styles.div`
  width: 1.5rem;
`;

export const TitleText = styles.p`
  // 仮のスタイル
  // margin: 0 auto;
  height: 22px;
  font-weight: 700;
  font-size: 18px;
  line-height: 22px;
`;

export const ProductIcon = styles.span<{ packaged: boolean }>`
  font-size: 14px;
  font-weight: 400;
  padding: 2px 6px;
  width: 18px;
  height: 14px;
  vertical-align: top;
  color: ${(props) => (props.packaged ? '#ffffff' : '#2ba9d1')};
  background: ${(props) => (props.packaged ? '#2ba9d1' : '#ffffff')};
  border-radius: 2px;
  border: 0.1rem solid #2ba9d1;
  margin: 0 8px 0 28px;
`;

export const StockListLink = styles(Link)``;
