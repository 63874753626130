import { useEffect, useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { HTML5Backend } from 'react-dnd-html5-backend';
import { DndProvider } from 'react-dnd';
import * as S from './SupplierCreateWrapper.styles';
import ConfirmPopUp from '@components/modules/common/ConfirmPopUp/ConfirmPopUp';
import ErrorMsgPopUp from '@components/modules/common/ErrorMsgPopUp/ErrorMsgPopUp';
import Overlay from '@components/elements/backdrops/Overlay/Overlay';
import UpdatePopUp from '@components/modules/pc/settings/common/UpdatePopUp/UpdatePopUp';
import SupplierContents from '@components/modules/pc/settings/supplier_setting/SupplierEdit/SupplierContents/SupplierContents';
import { createdSupplierData } from '@components/modules/pc/settings/supplier_setting/SupplierCreate/default';
import { CustomerState } from '@lib/pc/settings/customer_setting/type';
import { CurrentUserEmployee } from '@lib/pc/settings/supplier_edit/type';

type Props = {
  employees: CurrentUserEmployee[];
  refetch: () => void;
};

const SupplierCreateWrapper = ({ employees }: Props) => {
  const { state } = useLocation<CustomerState>();
  const history = useHistory();

  const [newData, setNewData] = useState(createdSupplierData);

  // 削除確認メッセージ
  const [confirmMsg, setConfirmMsg] = useState('');
  const [errMsg, setErrMsg] = useState('');
  const [enableToSave, setEnableToSave] = useState(false);
  const [updating, setUpdating] = useState(false);
  const [selectedTabIndex, setSelectedTabIndex] = useState(0);

  // 確認メッセージhandleOk判定用:一覧画面へ移動か、別タブ表示か。
  const [goBack, setGoBack] = useState(false);
  const [isChanged, setIsChanged] = useState(false);
  const [selectedTabId, setSelectedTabId] = useState(0);
  const [updatePopUp, setUpdatePopUp] = useState(false);

  const [createdData, setCreatedData] = useState(createdSupplierData);

  const SupplierSettingTabs = ['仕入先', '仕入先部署'];

  // 一覧画面へ戻る
  const onClickGoBack = () => {
    if (isChanged) {
      setGoBack(true);
      setConfirmMsg('変更した内容を保存せずに終了します。よろしいですか？');
    } else {
      history.push({
        pathname: `/pc/settings/supplier_setting`,
        state: {
          ...state,
          stateSelectedTabId: 0,
        },
      });
      setEnableToSave(false);
    }
  };

  const handleClickTab = (i: number) => {
    setGoBack(false);
    setEnableToSave(false);
    if (createdData.id === 0) {
      setErrMsg('はじめに仕入先を登録してください。');
    } else if (isChanged) {
      setSelectedTabId(i);
      setConfirmMsg('変更した内容を保存せずに終了します。よろしいですか？');
    } else {
      setSelectedTabIndex(i);
      history.push(`/pc/settings/supplier_setting_edit/${createdData.id}`, {
        ...state,
        id: createdData.id,
        code: createdData.code,
        name: createdData.name,
        nameKana: createdData.nameKana,
        stateSelectedTabId: i,
      });
    }
  };

  // 確認メッセージOK
  const handleOk = () => {
    // ここで一覧画面へ戻るか別タブを表示するかの判断が必要
    if (goBack) {
      history.push({
        pathname: `/pc/settings/supplier_setting`,
        state: {
          ...state,
          messageKind: '',
          productName: '',
          stateSelectedTabId: 0,
        },
      });
      setEnableToSave(false);
    } else {
      setSelectedTabIndex(selectedTabId);
    }
    setIsChanged(false);
    setConfirmMsg('');
  };
  // 確認メッセージキャンセル
  const handleCancel = () => {
    setConfirmMsg('');
  };

  useEffect(() => {
    if (state.stateSelectedTabId) {
      setSelectedTabIndex(state.stateSelectedTabId);
    } else {
      setSelectedTabIndex(0);
    }
  }, [state]);

  useEffect(() => {
    if (employees?.length > 0) {
      setNewData({ ...newData, currentUserEmployees: employees });
    }
  }, [employees?.length]);

  return (
    <>
      <S.Wrapper>
        <S.TabWrapper>
          {SupplierSettingTabs.map((d: string, i: number) => (
            <div key={d}>
              <S.TabArea>
                <S.Tab
                  key={d}
                  tabCount={SupplierSettingTabs.length}
                  active={i == selectedTabIndex}
                  onClick={() => {
                    handleClickTab(i);
                  }}
                >
                  {d}
                </S.Tab>
              </S.TabArea>
            </div>
          ))}
        </S.TabWrapper>

        <DndProvider backend={HTML5Backend}>
          {/* 仕入先 */}
          {selectedTabIndex === 0 && (
            <SupplierContents
              newData={newData}
              setNewData={setNewData}
              enableToSave={enableToSave}
              setEnableToSave={setEnableToSave}
              setIsChanged={setIsChanged}
              onClickGoBack={onClickGoBack}
              updating={updating}
              setUpdating={setUpdating}
              setUpdatePopUp={setUpdatePopUp}
              createdData={createdData}
              setCreatedData={setCreatedData}
            />
          )}
        </DndProvider>
      </S.Wrapper>
      <ErrorMsgPopUp
        errMsg={errMsg}
        handleClose={() => {
          setErrMsg('');
          setUpdating(false);
        }}
        fromPc={true}
        zIndex={9999}
      />
      {errMsg && (
        <Overlay
          dark
          handleClick={() => {
            setErrMsg('');
            setUpdating(false);
          }}
        />
      )}
      <ConfirmPopUp
        fromPc={true}
        confirmMsg={confirmMsg}
        zIndex={1004}
        handleOk={handleOk}
        handleCancel={handleCancel}
      />
      <UpdatePopUp
        popUp={updatePopUp}
        taskKind={'messageOnly'}
        messageKind={'create'}
        handleClose={() => {
          setUpdatePopUp(false);
        }}
      />
      {updatePopUp && <Overlay handleClick={() => setUpdatePopUp(false)} />}
    </>
  );
};

export default SupplierCreateWrapper;
