import * as S from './DeleteConfirmModal.styles';
import { Button } from '@components/elements/buttons/Button';
import { ReactComponent as DeleteIcon } from '@assets/icons/delete.svg';

type Props = {
  confirmMsg: string;
  onClickCancel: () => void;
  onClickDelete: () => void;
};

const DeleteConfirmModal = ({
  confirmMsg,
  onClickCancel,
  onClickDelete,
}: Props) => {
  return (
    <S.DeleteModalWrapper>
      <S.DeleteModalTextContainer>
        <span>{confirmMsg}</span>
      </S.DeleteModalTextContainer>
      <S.DeleteModalButtonContainer>
        <Button
          padding="sm"
          outlined
          onClick={() => {
            onClickCancel();
          }}
        >
          キャンセル
        </Button>
        <Button
          padding="sm"
          icon={<DeleteIcon />}
          outlined
          onClick={() => {
            onClickDelete();
          }}
        >
          削除
        </Button>
      </S.DeleteModalButtonContainer>
    </S.DeleteModalWrapper>
  );
};

export default DeleteConfirmModal;
