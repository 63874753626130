import { Redirect, Route, RouteProps } from 'react-router-dom';
import useCurrentUser from '@lib/pc/common/hooks/useCurrentUser';

type Props = RouteProps & {
  component: React.FunctionComponent;
  menuFunction: string;
};
const PCAuthRoute = ({
  component: Component,
  menuFunction,
  ...restOfProps
}: Props) => {
  const currentUser = useCurrentUser();
  const menuFunctions =
    (currentUser && localStorage.getItem('menu_functions')?.split(',')) || '';
  const lastViewPage =
    (currentUser && localStorage.getItem('last_view_page')) || '';
  const functionPath =
    lastViewPage ||
    (menuFunctions?.length &&
      menuFunctions[0]
        ?.split(/(?=[A-Z])/)
        .join('_')
        .toLowerCase()) ||
    'login';

  return (
    <Route
      {...restOfProps}
      render={(props) =>
        currentUser &&
        menuFunctions?.length &&
        menuFunctions?.includes(menuFunction) ? (
          <Component {...props} />
        ) : currentUser ? (
          <Redirect to={`/pc/${functionPath}`} />
        ) : (
          <Redirect
            to={{ pathname: '/pc/login', state: { from: props.location } }}
          />
        )
      }
    />
  );
};

export default PCAuthRoute;
