import { FC, useEffect } from 'react';
import * as S from './MaterialDetailModal.styles';
import { ReactComponent as CloseIcon } from '@assets/icons/close.svg';
import MaterialAllocationGraphWithTable from '@components/modules/pc/material_allocation_status/MaterialAllocationGraphWithTable/MaterialAllocationGraphWithTable';
import { AllocationType, Storeroom } from '@lib/common/type';
import {
  MaterialStockAllocationsAll,
  SelectedMaterialItem,
} from '@lib/pc/material_allocation_status/type';

type Props = {
  selectedItem: SelectedMaterialItem;
  response: MaterialStockAllocationsAll[];
  productDetailModalActive: boolean;
  selectedDate: moment.Moment;
  selectedAllocationTypeId: number;
  allocationTypeOptions: AllocationType[];
  selectedProductId: number;
  selectedStoreroomId: number;
  storerooms: Storeroom[] | undefined;
  handleClose: () => void;
  setSelectedAllocationTypeId: (id: number) => void;
  setSelectedStoreroomId: (id: number) => void;
};

const MaterialDetailModal: FC<Props> = ({
  selectedItem,
  response,
  productDetailModalActive,
  selectedDate,
  selectedAllocationTypeId,
  allocationTypeOptions,
  selectedProductId,
  selectedStoreroomId,
  storerooms,
  handleClose,
  setSelectedAllocationTypeId,
  setSelectedStoreroomId,
}: Props) => {
  // 資材・原材料名押下時に表示するグラフmodal
  const targetData =
    response &&
    response.find(
      (obj: MaterialStockAllocationsAll) => obj.id === selectedProductId
    );

  // 選択した資材・原材料が保管されている在庫室のみfilter
  const selectedItemStorerooms =
    selectedItem.name !== ''
      ? storerooms &&
        storerooms.filter((s) => selectedItem?.storeroomsIds?.includes(s.id))
      : storerooms;

  useEffect(() => {
    // Esc キーのイベントリスナー
    const handleKeyDown = (event: KeyboardEvent) => {
      if (event.key === 'Escape') {
        handleClose();
      }
    };
    if (productDetailModalActive) {
      window.addEventListener('keydown', handleKeyDown);
    }
    // コンポーネントのクリーンアップ時にイベントリスナーを削除
    return () => {
      window.removeEventListener('keydown', handleKeyDown);
    };
  }, [productDetailModalActive]);

  return (
    <S.Wrapper open={productDetailModalActive}>
      <S.ModalHead>
        <S.ProductMetaContainer>
          {/* COMMENT:画像がくる */}
          <div style={{ width: '60px', height: '60px', background: '#ccc' }} />
          <div>{selectedItem?.name} </div>
        </S.ProductMetaContainer>
        <S.ModalHeadCloseIcon>
          <CloseIcon onClick={handleClose} />
        </S.ModalHeadCloseIcon>
      </S.ModalHead>
      <MaterialAllocationGraphWithTable
        selectedDate={selectedDate}
        data={targetData?.materialStockAllocations || []}
        selectedAllocationTypeId={selectedAllocationTypeId}
        allocationTypeOptions={allocationTypeOptions}
        setSelectedAllocationTypeId={setSelectedAllocationTypeId}
        numberOfDaysParamsMonth={targetData?.numberOfDaysParamsMonth || 30}
        selectedStoreroomId={selectedStoreroomId}
        setSelectedStoreroomId={setSelectedStoreroomId}
        storerooms={selectedItemStorerooms}
      />
    </S.Wrapper>
  );
};

export default MaterialDetailModal;
