import styled from 'styled-components';

export const Footer = styled.div<{
  isPortraitAndTablet?: boolean;
}>`
  position: sticky;
  bottom: 0;
  margin-top: ${(props) => (props.isPortraitAndTablet ? '0' : 'auto')};
  margin-bottom: ${(props) => (props.isPortraitAndTablet ? '15%' : '')};
  padding: 1rem;
  background: ${(props) => props.theme.palette.beige};
  box-shadow: 0px 6px 10px rgba(0, 0, 0, 0.07), 0px 1px 18px rgba(0, 0, 0, 0.06),
    0px 3px 5px rgba(0, 0, 0, 0.1);
`;

export const DuplicateButtonArea = styled.div`
  display: flex;
  gap: 15px;
`;

export const EstimatedSubmitButton = styled.button<{
  disabled?: boolean;
}>`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 10px;
  width: 100%;
  padding: 0.75rem 0;
  height: 3.25rem;
  border-radius: 0.5rem;
  background: ${(props) =>
    props.disabled ? '#e0e0e0' : props.theme.palette.white};
  font-weight: bold;
  color: ${(props) =>
    props.disabled
      ? props.theme.palette.khaki
      : props.theme.palette.primary.main};
  border: ${(props) =>
    props.disabled
      ? `2px solid #e0e0e0`
      : `2px solid ${props.theme.palette.primary.main}`};
`;

export const SubmitButton = styled.button<{
  isCopy?: boolean;
}>`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 10px;
  width: 100%;
  padding: 0.75rem 0;
  height: 3.25rem;
  border-radius: 0.5rem;
  background: ${(props) => (props.isCopy ? '#2ba9d1' : '#ffd130')};
  font-weight: bold;
  color: ${(props) =>
    props.isCopy ? props.theme.palette.beige : props.theme.palette.baseBlack};
`;
