import { FC } from 'react';
import * as S from './ShippingDestinationSelect.styles';
import type { Todo } from '@lib/common/type';
import ReactSelect from 'react-select';
import { NO_OPTION_JAPANESE } from '@lib/pc/common/constants';

type Props = {
  branchData: Todo[] | undefined;
  handleChangeShipping: (e: number) => void;
  value: number | undefined;
  setPopUpShippingName: (popUpShippingName: string) => void;
};

const ShippingDestinationSelect: FC<Props> = ({
  branchData,
  handleChangeShipping,
  value,
  setPopUpShippingName,
}: Props) => {
  const toReactSelectOptions = (options: { id: number; name: string }[]) =>
    options.map(({ id, name }) => ({
      value: id,
      label: name,
    }));

  const reactSelectOptions = branchData && toReactSelectOptions(branchData);
  const currentValue = reactSelectOptions?.find((o) => o.value === value);

  const styles = {
    control: (baseStyles: Todo) => ({
      ...baseStyles,
      minHeight: '4rem',
      width: '22rem',
      borderRadius: '0.5rem',
      fontWeight: 400,
      textAlign: 'left',
      borderStyle: 'hidden',
      outline: '0.5px solid rgba(0,0,0,0.08)',
      cursor: 'pointer',
      '@media (max-width: 599px)': {
        width: '16rem',
      },
    }),
    menu: (baseStyles: Todo) => ({
      ...baseStyles,
      top: '37px',
    }),
    option: (baseStyles: Todo) => ({
      ...baseStyles,
      textAlign: 'left',
      fontWeight: 400,
      fontSize: '15px',
    }),
  };

  return (
    <S.Wrapper>
      {/* 出荷先 */}
      <ReactSelect
        options={reactSelectOptions}
        onChange={(option) => {
          option && handleChangeShipping(option.value);
          option && setPopUpShippingName(option.label);
        }}
        value={currentValue ? currentValue : null}
        styles={styles}
        isSearchable={false}
        components={{
          IndicatorSeparator: () => null,
        }}
        noOptionsMessage={() => NO_OPTION_JAPANESE}
        placeholder="出荷先の選択"
      />
    </S.Wrapper>
  );
};

export default ShippingDestinationSelect;
