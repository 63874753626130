// 上段
export const TABLE_HEADER = [
  { key: '原材料名', align: 'left', width: '160px', colSpan: 4 },
  {
    key: '払出先',
    align: 'left',
    wordBreak: 'keep-all',
    colSpan: 1,
  },
  {
    key: '使用期限日',
    align: 'left',
    wordBreak: 'keep-all',
    colSpan: 3,
  },
];

// 下段
export const TABLE_HEADER_UNDER = [
  { key: '払出数', align: 'right', colSpan: 4 },
  {
    key: '備考',
    align: 'left',
    wordBreak: 'keep-all',
    colSpan: 4,
  },
];
