import { axiosInstance } from '@lib/pc/common/api/axiosConfig';
import { useQuery } from 'react-query';
import humps from 'humps';
import { Todo } from '@lib/common/type';

const useSemiProduct = (semiProductId: string) => {
  const { data, refetch } = useQuery(
    ['semi_product', semiProductId],
    async () => {
      const data = await axiosInstance().get(
        `/api/v1/settings/semi_product/${semiProductId}`
      );
      return data;
    }
  );
  const semiProduct = humps.camelizeKeys(data?.data) as Todo;

  return { semiProduct, refetch };
};

export default useSemiProduct;
