import React, { FC } from 'react';

type Props = {
  isActive?: boolean;
};

const ArrowRight: FC<Props> = ({ isActive }) => {
  return (
    <svg
      width="36"
      height="36"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M10 7L15 12L10 17V7Z"
        fill={isActive ? 'rgba(43, 169, 209, 1)' : 'rgba(192, 192, 192, 1)'}
      />
    </svg>
  );
};

export default ArrowRight;
