// import { useState } from 'react';
import * as S from './SearchConditionListModal.styles';
import { Button } from '@components/elements/buttons/Button';
import { Todo } from '@lib/common/type';

export const STATUS_CONDITIONS = [
  { id: 'draft', name: '未発注' },
  { id: 'sending', name: '送信中' },
  { id: 'failed', name: '送信失敗' },
  { id: 'completed', name: '発注済' },
  { id: 'cancel', name: '発注取消' },
];

export const RECEIVED_CONDITIONS = [
  { id: 'undelivered', name: '未納品' },
  { id: 'partial', name: '一部納品' },
  { id: 'delivered', name: '完了' },
];

type Props = {
  suppliers: Todo[];
  setStatusConditions: (ids: string[]) => void;
  setDestConditions: (ids: number[]) => void;
  setReceivedConditions: (ids: string[]) => void;
  selectedDestConditions: number[];
  setSelectedDestConditions: (selectedDestConditions: number[]) => void;
  selectedStatusConditions: string[];
  setSelectedStatusConditions: (selectedStatusConditions: string[]) => void;
  selectedReceivedConditions: string[];
  setSelectedReceivedConditions: (selectedReceivedConditions: string[]) => void;
  handleClose: () => void;
};

const SearchConditionListModal = ({
  suppliers,
  setStatusConditions,
  setDestConditions,
  setReceivedConditions,
  selectedDestConditions,
  setSelectedDestConditions,
  selectedStatusConditions,
  setSelectedStatusConditions,
  selectedReceivedConditions,
  setSelectedReceivedConditions,
  handleClose,
}: Props) => {
  // 取引先
  const onClickDestCheck = (event: React.ChangeEvent<HTMLInputElement>) => {
    const clickedId = Number(event.target.value);
    event.target.checked
      ? setSelectedDestConditions([...selectedDestConditions, clickedId])
      : setSelectedDestConditions(
          selectedDestConditions.filter((id: number) => id !== clickedId)
        );
  };
  // 発注状況
  const onClickStatusCheck = (event: React.ChangeEvent<HTMLInputElement>) => {
    const clickedId = String(event.target.value);
    event.target.checked
      ? setSelectedStatusConditions([...selectedStatusConditions, clickedId])
      : setSelectedStatusConditions(
          selectedStatusConditions.filter((id: string) => id !== clickedId)
        );
  };
  // 納品状況
  const onClickReceivedCheck = (event: React.ChangeEvent<HTMLInputElement>) => {
    const clickedId = String(event.target.value);
    event.target.checked
      ? setSelectedReceivedConditions([
          ...selectedReceivedConditions,
          clickedId,
        ])
      : setSelectedReceivedConditions(
          selectedReceivedConditions.filter((id: string) => id !== clickedId)
        );
  };

  return (
    <S.Wrapper>
      <S.Container>
        <S.Header>
          <S.Title>取引先</S.Title>
        </S.Header>
        <S.EachSection>
          <S.ListUl>
            {suppliers &&
              suppliers?.map((dest: Todo) => (
                <S.ListLi key={dest.id}>
                  <S.Label>
                    <S.Checkbox
                      type="checkbox"
                      name="list"
                      value={dest.id}
                      onChange={onClickDestCheck}
                    />
                    <S.TagName
                      defaultChecked={selectedDestConditions.includes(dest.id)}
                    >
                      {dest.name}
                    </S.TagName>
                  </S.Label>
                </S.ListLi>
              ))}
          </S.ListUl>
        </S.EachSection>
        <S.Header>
          <S.Title>発注状況</S.Title>
        </S.Header>
        <S.EachSection>
          <S.ListUl>
            {STATUS_CONDITIONS.map((status) => (
              <S.ListLi key={status.id}>
                <S.Label>
                  <S.Checkbox
                    type="checkbox"
                    name="list"
                    value={status.id}
                    onChange={onClickStatusCheck}
                  />
                  <S.TagName
                    defaultChecked={selectedStatusConditions.includes(
                      status.id
                    )}
                  >
                    {status.name}
                  </S.TagName>
                </S.Label>
              </S.ListLi>
            ))}
          </S.ListUl>
        </S.EachSection>
        <S.Header>
          <S.Title>納品状況</S.Title>
        </S.Header>
        <S.EachSection>
          <S.ListUl>
            {RECEIVED_CONDITIONS.map((status) => (
              <S.ListLi key={status.id}>
                <S.Label>
                  <S.Checkbox
                    type="checkbox"
                    name="list"
                    value={status.id}
                    onChange={onClickReceivedCheck}
                  />
                  <S.TagName
                    defaultChecked={selectedReceivedConditions.includes(
                      status.id
                    )}
                  >
                    {status.name}
                  </S.TagName>
                </S.Label>
              </S.ListLi>
            ))}
          </S.ListUl>
        </S.EachSection>
      </S.Container>
      <S.ButtonWrapper>
        <S.ButtonContainer>
          <Button borderWidth={1} outlined={true} onClick={handleClose}>
            キャンセル
          </Button>
          <Button
            onClick={() => {
              setDestConditions(selectedDestConditions);
              setStatusConditions(selectedStatusConditions);
              setReceivedConditions(selectedReceivedConditions);
              handleClose();
            }}
          >
            決定
          </Button>
        </S.ButtonContainer>
      </S.ButtonWrapper>
    </S.Wrapper>
  );
};
export default SearchConditionListModal;
