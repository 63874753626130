import { FC } from 'react';
import * as S from './InputWithLabel.styles';

type Props = {
  label: string;
  placeholder: string;
  type?: 'text' | 'password';
  value?: string;
  onChange?: (event: React.ChangeEvent<HTMLInputElement>) => void;
};

export const InputWithLabel: FC<Props> = ({
  label,
  type,
  value,
  onChange,
}: Props) => {
  return (
    <S.Wrapper>
      <S.Label>{label}</S.Label>
      <S.Input type={type} value={value} onChange={onChange} />
    </S.Wrapper>
  );
};
