import { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import * as S from './MaterialInfo.styles';
import { CircularProgress } from '@material-ui/core';
import ChevronLeft from '@assets/icons/chevron_left';
import { Button } from '@components/elements/buttons/Button';
import ErrorMsgPopUp from '@components/modules/common/ErrorMsgPopUp/ErrorMsgPopUp';
import Overlay from '@components/elements/backdrops/Overlay/Overlay';
import MaterialContents from '@components/modules/pc/settings/material_master_setting/MaterialMasterEdit/MaterialContents/MaterialContents';
import { Material, Todo } from '@lib/common/type';
import useUpdateMaterialMutation from '@lib/pc/settings/material_master_edit/useUpdateMaterialMutation';
import useCreateMaterial from '@lib/pc/settings/material_master_create/useCreateMaterial';
import { createdMaterialData } from '@components/modules/pc/settings/material_master_setting/MaterialMasterCreate/MaterialMasterCreateWrapper/default';
import { MaterialMasterSettingState } from '@lib/pc/settings/material_master_setting/type';

type Props = {
  material: Todo;
  updating: boolean;
  setUpdating: (updating: boolean) => void;
  setPopUp: (popUp: boolean) => void;
  newMaterialData: Todo;
  setNewMaterialData: (newMaterialData: Todo) => void;
  handleUpdateSuccess: () => void;
  onClickGoBack: () => void;
  setIsChanged: (isChanged: boolean) => void;
  allMaterials: Material[] | undefined;
  materialFromInfo: Todo;
  setMaterialFromInfo: (productFromInfo: Todo) => void;
  isChanged: boolean;
  timestamp: string;
  caseAlert?: boolean;
  storeRoomAlert?: boolean;
  materialTypes: Todo[];
  orderTypes: Todo[];
  labelOfAmounts: Todo[];
  orderUnits: Todo[];
  labelOfUnits: Todo[];
};

const MaterialInfo = ({
  material,
  updating,
  setUpdating,
  setPopUp,
  newMaterialData,
  setNewMaterialData,
  handleUpdateSuccess,
  onClickGoBack,
  setIsChanged,
  allMaterials,
  materialFromInfo,
  setMaterialFromInfo,
  isChanged,
  timestamp,
  caseAlert,
  storeRoomAlert,
  materialTypes,
  orderTypes,
  labelOfAmounts,
  orderUnits,
  labelOfUnits,
}: Props) => {
  const { state } = useLocation<MaterialMasterSettingState>();
  const [errMsg, setErrMsg] = useState('');

  const handleChange = (
    e:
      | React.ChangeEvent<HTMLInputElement>
      | React.ChangeEvent<HTMLTextAreaElement>
  ) => {
    setIsChanged(true);
    setNewMaterialData({ ...newMaterialData, [e.target.name]: e.target.value });
  };

  const handleChangeImage = (imageDataUri: string) => {
    setIsChanged(true);
    setNewMaterialData({ ...newMaterialData, imageDataUri });
  };

  const handleChangeCode = (
    e:
      | React.ChangeEvent<HTMLInputElement>
      | React.ChangeEvent<HTMLTextAreaElement>
  ) => {
    if (e.target.value.match(/^[a-zA-Z0-9-]*$/)) {
      setIsChanged(true);
      setNewMaterialData({
        ...newMaterialData,
        [e.target.name]: e.target.value,
      });
    }
  };

  const handleChangeNumber = (
    e:
      | React.ChangeEvent<HTMLInputElement>
      | React.ChangeEvent<HTMLTextAreaElement>,
    decimalOk?: boolean
  ) => {
    const isValid = decimalOk
      ? /^[0-9.]*$/.test(e.target.value)
      : /^[0-9]*$/.test(e.target.value);

    if (isValid) {
      setIsChanged(true);
      setNewMaterialData({
        ...newMaterialData,
        [e.target.name]: e.target.value,
      });
    }
  };

  const handleChangeCheckBox = (e: React.ChangeEvent<HTMLInputElement>) => {
    setIsChanged(true);
    setNewMaterialData({
      ...newMaterialData,
      [e.target.name]: e.target.checked,
    });
  };

  const [createdMaterial, setCreatedMaterial] = useState(createdMaterialData);

  const handleCreatedMaterial = (material: typeof createdMaterialData) => {
    setCreatedMaterial(material);
  };

  const createMaterials = useCreateMaterial(
    handleCreatedMaterial,
    handleUpdateSuccess,
    setErrMsg
  );

  const duplicateNameCheck = () => {
    // 製品名重複チェック
    const nameDuplicateProductExists =
      allMaterials &&
      allMaterials?.some(
        (am) => am.name === newMaterialData.name && am.id !== newMaterialData.id
      );

    if (nameDuplicateProductExists) {
      setErrMsg('同じ名前の資材・原材料が登録済みです。');
    } else if (newMaterialData.name === '') {
      setErrMsg('資材・原材料名を入力してください。');
    } else {
      return true;
    }
  };

  // ***********************
  // [登録ボタン]押下、作成処理
  // ***********************
  const handleSubmitCreate = () => {
    const checkResult = duplicateNameCheck();
    if (checkResult) {
      setUpdating(true);
      // 商品マスタ----------------------
      createMaterials.mutate(newMaterialData);
    }
  };

  // 資材・原材料マスタ更新
  const updateMaterialMutation = useUpdateMaterialMutation(
    newMaterialData?.id,
    handleUpdateSuccess,
    setErrMsg
  );

  // ***********************
  // [保存ボタン]押下、更新処理
  // ***********************
  const handleSubmit = () => {
    const checkResult = duplicateNameCheck();
    if (checkResult) {
      setUpdating(true);
      // 資材・原材料マスタ----------------------
      updateMaterialMutation.mutate(newMaterialData);
    }
  };

  // 登録ボタンか保存ボタンか判定
  const createButton = material?.id === 0 || state?.duplicate ? true : false;

  useEffect(() => {
    if (createdMaterial) {
      createdMaterial && setMaterialFromInfo(createdMaterial);
    }
  }, [createdMaterial]);

  // useEffectをproductの変更のときに走らせると、画像ファイルを変更するたびにAPI結果が再ロードされてしまい入力値が消える
  // 対策として、productがundefinedからAPIで取得できたあとorページを開いた直後だけstateにデータを入れるようにする
  // !!productを見るだけだと、react queryのキャッシュのせいなのか、修正→一覧画面→修正と繊維したときに最新データがロードされない
  useEffect(() => {
    if (material?.id !== null && materialFromInfo.id === 0) {
      setNewMaterialData(material?.material);
    }
  }, [!!material, timestamp]);

  return (
    <>
      {material ? (
        material && (
          <>
            <S.Wrapper>
              <div className="go-back" onClick={onClickGoBack}>
                <ChevronLeft isBlue={true} />
                <span>一覧画面へ戻る</span>
              </div>
              <S.ProductMasterEditContents>
                <MaterialContents
                  updating={updating}
                  setUpdating={setUpdating}
                  newMaterialData={newMaterialData}
                  setNewMaterialData={setNewMaterialData}
                  handleChangeCode={handleChangeCode}
                  handleChange={handleChange}
                  handleChangeNumber={handleChangeNumber}
                  handleChangeImage={handleChangeImage}
                  handleChangeCheckBox={handleChangeCheckBox}
                  setPopUp={setPopUp}
                  caseAlert={caseAlert}
                  storeRoomAlert={storeRoomAlert}
                  materialTypes={materialTypes}
                  orderTypes={orderTypes}
                  labelOfAmounts={labelOfAmounts}
                  orderUnits={orderUnits}
                  labelOfUnits={labelOfUnits}
                  setIsChanged={setIsChanged}
                  materialsAndProducts={material.materialsAndProducts}
                  materialsAndSemiProducts={material.materialsAndSemiProducts}
                />
              </S.ProductMasterEditContents>
            </S.Wrapper>
            <S.ButtonContainer>
              {createButton ? (
                <Button
                  children={updating ? '登録中...' : '登録'}
                  disabled={updating || !isChanged}
                  onClick={handleSubmitCreate}
                />
              ) : (
                <Button
                  children={updating ? '保存中...' : '保存'}
                  disabled={updating || !isChanged}
                  onClick={handleSubmit}
                />
              )}
            </S.ButtonContainer>
          </>
        )
      ) : (
        <S.CircularIconWrapper>
          <CircularProgress style={{ color: '#64b2f9' }} />
        </S.CircularIconWrapper>
      )}
      <ErrorMsgPopUp
        errMsg={errMsg}
        handleClose={() => {
          setErrMsg('');
          setUpdating(false);
        }}
        fromPc={true}
        zIndex={9999}
      />
      {errMsg && (
        <Overlay
          dark
          handleClick={() => {
            setErrMsg('');
            setUpdating(false);
          }}
        />
      )}
    </>
  );
};

export default MaterialInfo;
