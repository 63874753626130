import * as S from './StackerMainContainer.styles';
import FactoryLineTab from '@components/modules/pc/stacker/FactoryLineTab/FactoryLineTab';
import FactoryLineContents from '@components/modules/pc/stacker/FactoryLineContents/FactoryLineContents';
import { factory } from '@lib/pc/stacker/useStackers';

type Props = {
  stackingResults: { stackingResults: factory[] };
  refetch: () => void;
  selectedFactoryData: factory;
  setSelectedFactoryData: (selectedFactoryData: factory) => void;
  selectedFactoryId?: number | string;
  setSelectedFactoryId: (selectedFactoryId: number) => void;
  selectedLineId: number;
  setSelectedLineId: (selectedLineId: number) => void;
  tabColor: number;
  setTabColor: (tabColor: number) => void;
  date: Date;
  setPopUp: (popUp: boolean) => void;
  setEditProductName: (editProductName: string) => void;
};

const StackerMainContainer = ({
  stackingResults,
  refetch,
  selectedFactoryData,
  setSelectedFactoryData,
  selectedFactoryId,
  setSelectedFactoryId,
  selectedLineId,
  setSelectedLineId,
  tabColor,
  setTabColor,
  date,
  setPopUp,
  setEditProductName,
}: Props) => {
  return (
    <S.Wrapper>
      <FactoryLineTab
        selectedFactoryData={selectedFactoryData}
        selectedLineId={selectedLineId}
        setSelectedLineId={setSelectedLineId}
        setTabColor={setTabColor}
        setPopUp={setPopUp}
      />
      <FactoryLineContents
        stackingResults={stackingResults}
        refetch={refetch}
        selectedLineId={selectedLineId}
        selectedFactoryData={selectedFactoryData}
        setSelectedFactoryData={setSelectedFactoryData}
        selectedFactoryId={selectedFactoryId}
        setSelectedFactoryId={setSelectedFactoryId}
        tabColor={tabColor}
        date={date}
        setPopUp={setPopUp}
        setEditProductName={setEditProductName}
      />
    </S.Wrapper>
  );
};

export default StackerMainContainer;
