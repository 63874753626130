import { useMutation } from 'react-query';
import humps from 'humps';
import { axiosInstance } from '@lib/pc/common/api/axiosConfig';
import { StockAllocationEditData } from '@lib/pc/stock_allocation/type';

const useUpdateStockAllocationMutation = (
  id: number,
  handleUpdateSuccess: () => void
) => {
  return useMutation(
    async (params: StockAllocationEditData) => {
      return await axiosInstance().patch(
        `/api/v1/product_stock_allocation_details/${id}`,
        humps.decamelizeKeys({
          productStockAllocation: {
            detailId: params.detailId,
            bestBeforeDate: params.bestBeforeDate,
            allocationType: params.allocationType,
            storeroomId: params.storeroomId,
            caseId: params.caseId,
            case: params.case,
            piece: params.piece,
            amount: params.amount,
            comment: params.comment,
            productId: params.productId,
          },
        })
      );
    },
    {
      onSuccess: () => {
        handleUpdateSuccess();
      },
      onError: () => alert('エラーが発生しました'),
    }
  );
};

export default useUpdateStockAllocationMutation;
