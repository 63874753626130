import React from 'react';
import { WithHeader } from '@components/templates/WithHeader';
import { Heading } from '@components/elements/typographies/Heading';
import * as S from './MaterialMasterCreateRoot.styles';
import MaterialMasterCreateWrapper from '@components/modules/pc/settings/material_master_setting/MaterialMasterCreate/MaterialMasterCreateWrapper/MaterialMasterCreateWrapper';

export const MaterialMasterCreateRoot: React.FC = () => {
  return (
    <WithHeader>
      <S.Wrapper>
        <div>
          <S.HeadContainer>
            <S.FlexBaseline>
              <div>
                <Heading
                  tag="span"
                  text="資材・原材料マスタ管理"
                  fs="32"
                  fw="lg"
                />
              </div>
            </S.FlexBaseline>
          </S.HeadContainer>
          <MaterialMasterCreateWrapper />
        </div>
      </S.Wrapper>
    </WithHeader>
  );
};
