export const acceptingOrders = [
  {
    name: '朝日水産',
    createdAt: '2023/12/30',
    sendBy: 'fax',
  },
  {
    name: 'マクアケ工業',
    createdAt: '2023/11/01',
    sendBy: 'mail',
  },
  {
    name: '日の出包材',
    createdAt: '2023/10/09',
    sendBy: 'fax',
  },
  {
    name: '朝日水産',
    createdAt: '2023/12/30',
    sendBy: 'fax',
  },
  {
    name: 'マクアケ工業',
    createdAt: '2023/11/01',
    sendBy: 'mail',
  },
  {
    name: '日の出包材',
    createdAt: '2023/10/09',
    sendBy: 'fax',
  },
  {
    name: '朝日水産',
    createdAt: '2023/12/30',
    sendBy: 'fax',
  },
  {
    name: 'マクアケ工業',
    createdAt: '2023/11/01',
    sendBy: 'mail',
  },
  {
    name: '日の出包材',
    createdAt: '2023/10/09',
    sendBy: 'fax',
  },
  {
    name: '朝日水産',
    createdAt: '2023/12/30',
    sendBy: 'fax',
  },
  {
    name: 'マクアケ工業',
    createdAt: '2023/11/01',
    sendBy: 'mail',
  },
  {
    name: '日の出包材',
    createdAt: '2023/10/09',
    sendBy: 'fax',
  },
  {
    name: '朝日水産',
    createdAt: '2023/12/30',
    sendBy: 'fax',
  },
  {
    name: 'マクアケ工業',
    createdAt: '2023/11/01',
    sendBy: 'mail',
  },
  {
    name: '日の出包材',
    createdAt: '2023/10/09',
    sendBy: 'fax',
  },
  {
    name: '朝日水産',
    createdAt: '2023/12/30',
    sendBy: 'fax',
  },
  {
    name: 'マクアケ工業',
    createdAt: '2023/11/01',
    sendBy: 'mail',
  },
  {
    name: '日の出包材',
    createdAt: '2023/10/09',
    sendBy: 'fax',
  },
  {
    name: '朝日水産',
    createdAt: '2023/12/30',
    sendBy: 'fax',
  },
  {
    name: 'マクアケ工業',
    createdAt: '2023/11/01',
    sendBy: 'mail',
  },
  {
    name: '日の出包材',
    createdAt: '2023/10/09',
    sendBy: 'fax',
  },
  {
    name: '朝日水産',
    createdAt: '2023/12/30',
    sendBy: 'fax',
  },
  {
    name: 'マクアケ工業',
    createdAt: '2023/11/01',
    sendBy: 'mail',
  },
  {
    name: '日の出包材',
    createdAt: '2023/10/09',
    sendBy: 'fax',
  },
  {
    name: '朝日水産',
    createdAt: '2023/12/30',
    sendBy: 'fax',
  },
  {
    name: 'マクアケ工業',
    createdAt: '2023/11/01',
    sendBy: 'mail',
  },
  {
    name: '日の出包材',
    createdAt: '2023/10/09',
    sendBy: 'fax',
  },
  {
    name: '朝日水産',
    createdAt: '2023/12/30',
    sendBy: 'fax',
  },
  {
    name: 'マクアケ工業',
    createdAt: '2023/11/01',
    sendBy: 'mail',
  },
  {
    name: '日の出包材',
    createdAt: '2023/10/09',
    sendBy: 'fax',
  },
] as const;

export const details = [
  {
    productDeliveryDay: '2023/10/09',
    productName: 'チョコ',
    unitAmount: 1,
  },
  {
    productDeliveryDay: '2023/10/09',
    productName: 'ミルク',
    unitAmount: 30,
  },
  {
    productDeliveryDay: '2023/10/09',
    productName: 'ホワイトクッキー',
    unitAmount: 20,
  },
  {
    productDeliveryDay: '2023/10/09',
    productName: '商品A*2',
    unitAmount: 50,
  },
  {
    productDeliveryDay: '2023/10/09',
    productName: '商品A*3',
    unitAmount: 100,
  },
];
