import { axiosInstance } from '@lib/pc/common/api/axiosConfig';
import { useQuery } from 'react-query';
import humps from 'humps';
import moment from 'moment';
import { Todo } from '@lib/common/type';

export const useAllMaterialStockAllocations = (
  date: Date,
  storeroomId: number | undefined,
  allocationTypeId: number
) => {
  const { data, refetch } = useQuery(
    ['allMaterialStocks', date, storeroomId, allocationTypeId],
    async () => {
      const data = await axiosInstance().get(
        `/api/v1/material_stock_allocations_all?&date=${moment(date).format(
          'YYYY-MM-DD'
        )}&storeroom_id=${storeroomId}&allocation_type=${allocationTypeId}`
      );
      return data;
    }
  );
  const allMaterialStocks =
    data && (humps.camelizeKeys(data.data.all_stock_allocations) as Todo);
  // ) as unknown as AllStockAllocations);

  return { allMaterialStocks, refetch };
};

export default useAllMaterialStockAllocations;
