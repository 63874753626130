import { FC, useEffect, useRef, useState } from 'react';
import { isIPad13 } from 'react-device-detect';
import { ReactComponent as DeleteIcon } from '@assets/icons/delete.svg';
import * as S from './DeliveryStatusInputComponent.styles';
import SubmitButton from '@components/modules/pc/purchase_orders/SubmitButton/SubmitButton';
import { Todo } from '@lib/common/type';

type Props = {
  detail: Todo;
  newData: Todo[];
  initData: Todo;
  setNewData: (newData: Todo) => void;
  setErrMsg: (errMsg: string) => void;
  updateMaterialOrderDelivery: (detail: Todo, index: number) => void;
  isChangedArray: boolean[];
  updating: boolean;
  setUpdating: (updating: boolean) => void;
  setPopUp: (popUp: boolean) => void;
  setDeleteId: (deleteId: number) => void;
  setConfirmMsg: (confirmMsg: string) => void;
  msgKind: string;
  isLineChangedArray: boolean[];
  disableAnotherMaterial: boolean;
  setDisableAnotherMaterial: (disableAnotherMaterial: boolean) => void;
  orderStatus: string;
  setInputting: (inputting: boolean) => void;
  setDeliveryDetailId: (deliveryDetailId: number) => void;
};

const DeliveryStatusInputComponent: FC<Props> = ({
  detail,
  newData,
  initData,
  setNewData,
  setErrMsg,
  updateMaterialOrderDelivery,
  isChangedArray,
  updating,
  setUpdating,
  setPopUp,
  setDeleteId,
  setConfirmMsg,
  isLineChangedArray,
  disableAnotherMaterial,
  setDisableAnotherMaterial,
  orderStatus,
  setInputting,
  setDeliveryDetailId,
}) => {
  const deliveryDateRef = useRef<HTMLInputElement>(null);

  // 下位のコンポーネントに渡しているのがネストの深いオブジェクトなので、強制再レンダリングする必要がある
  const [, setToggleValue] = useState(false);
  const reRender = () => setToggleValue((prev) => !prev);

  // 入力中のmaterialId
  const [processingMateriaId, setProcessingMateriaId] = useState(0);

  const onClickSubmit = (
    deliveryDate: string,
    amount: number,
    index: number
  ) => {
    if (!deliveryDate) {
      setErrMsg('納品日を設定してください。');
    } else if (!amount) {
      setErrMsg('納品数を設定してください。');
    } else {
      setUpdating(true);
      updateMaterialOrderDelivery(detail, index);
    }
  };

  const handleChangeDate = (
    e: React.ChangeEvent<HTMLInputElement>,
    index: number
  ) => {
    setPopUp(false);
    setInputting(true);
    setProcessingMateriaId(detail.materialId);
    isChangedArray[index] = true;
    setNewData((newParams: Todo) => {
      const oldDetail = newParams[index];
      const newDetail = { ...oldDetail, [e.target.name]: e.target.value };
      newParams[index] = newDetail;
      return newParams;
    });
    if (newData[index].amount && e.target.value) {
      isLineChangedArray[index] = true;
      setDisableAnotherMaterial(true);
    } else {
      isLineChangedArray[index] = false;
    }
    reRender();
  };

  const handleChangeAmount = (
    e: React.ChangeEvent<HTMLInputElement>,
    index: number
  ) => {
    if (e.target.value.match(/^[0-9]*$/)) {
      setInputting(true);
      isChangedArray[index] = true;
      setProcessingMateriaId(detail.materialId);
      setNewData((newParams: Todo) => {
        const oldDetail = newParams[index];
        const newDetail = { ...oldDetail, [e.target.name]: e.target.value };
        newParams[index] = newDetail;
        return newParams;
      });
      reRender();
    }
  };

  const handleDisableAnotherMaterial = (index: number) => {
    if (newData[index].deliveryDate && newData[index].amount) {
      isLineChangedArray[index] = true;
      setDisableAnotherMaterial(true);
      setDeliveryDetailId(newData[index].deliveryDetailId);
    } else {
      isLineChangedArray[index] = false;
    }
  };

  const handleRemoveField = (i: number) => {
    // setDeleteIndex(i);
    setPopUp(false);
    if (newData[i].deliveryDetailId) {
      // 登録済みデータの場合
      setDeleteId(newData[i].deliveryDetailId);
      setConfirmMsg('登録済みの納品状況を削除してもよろしいですか？');
    } else if (newData.length === 1) {
      // 未登録データ１件のみの場合
      newData[0].deliveryDate = '';
      newData[0].amount = 0;
      isChangedArray[i] = false;
    } else if (newData.length > 1) {
      if (newData[i].orderDetailId === 0) {
        // 未登録データの場合
        newData.splice(i, 1);
        isChangedArray.splice(i, 1);
      }
    }
    reRender();
  };

  useEffect(() => {
    if (newData?.length === 0) {
      newData.push(initData);
    }
    reRender();
  }, [newData?.length]);

  return (
    <>
      {newData &&
        newData?.map((item: Todo, index: number) => {
          return (
            <S.DeliveryDate className="delivered-date" key={index}>
              <S.InputWrapper>
                <S.DateInput
                  className="deliveryDate"
                  ref={deliveryDateRef}
                  type="date"
                  name="deliveryDate"
                  id={`new-delivered-date-${index}`}
                  value={item.deliveryDate ? item.deliveryDate : ''}
                  onChange={(e) => {
                    handleChangeDate(e, index);
                    handleDisableAnotherMaterial(index);
                  }}
                  // onBlur={() => handleDisableAnotherMaterial(index)}
                  pattern="\d{4}-\d{2}-\d{2}"
                  autoComplete="off"
                  max="9999-12-31"
                  placeholder={item.deliveryDate ? item.deliveryDate : '納品日'}
                  item={item.deliveryDate === null ? true : false}
                  disabled={
                    orderStatus !== 'completed'
                      ? true
                      : isChangedArray[index] &&
                        item.deliveryDate &&
                        item.amount
                      ? false
                      : disableAnotherMaterial &&
                        processingMateriaId !== detail.materialId
                      ? true
                      : false
                  }
                />
              </S.InputWrapper>
              {/* 納品個数 */}
              <S.DInputContainer>
                <input
                  name="amount"
                  id={`amount-${index}`}
                  value={item.amount ? item.amount : ''}
                  onChange={(e) => {
                    handleChangeAmount(e, index);
                    // handleDisableAnotherMaterial(index);
                  }}
                  onBlur={() => handleDisableAnotherMaterial(index)}
                  type="text"
                  pattern="^[1-9][0-9]*$"
                  inputMode="numeric"
                  autoComplete="off"
                  disabled={
                    orderStatus !== 'completed'
                      ? true
                      : isChangedArray[index] &&
                        item.deliveryDate &&
                        item.amount
                      ? false
                      : disableAnotherMaterial &&
                        processingMateriaId !== detail.materialId
                      ? true
                      : false
                  }
                  // disabled={
                  //   isLineChangedArray.includes(true) || disableAnotherMaterial
                  // }
                />
                <span>{detail.unit ? detail.unit : ''}</span>
              </S.DInputContainer>
              <SubmitButton
                onClick={() => {
                  onClickSubmit(item.deliveryDate, item.amount, index);
                }}
                disabled={
                  updating
                    ? true
                    : isChangedArray[index] && item.deliveryDate && item.amount
                    ? false
                    : true
                }
                fontSize={'12px'}
              >
                保存
              </SubmitButton>
              {/* {item.deliveryDate || item.amount ? ( */}
              {(item.deliveryDate || item.amount) && !disableAnotherMaterial ? (
                <S.DeleteButton
                  className="del-button"
                  onClick={() => {
                    if (!isIPad13) {
                      handleRemoveField(index);
                    }
                  }}
                  onTouchStart={() => {
                    if (isIPad13) {
                      handleRemoveField(index);
                    }
                  }}
                >
                  <DeleteIcon />
                </S.DeleteButton>
              ) : (
                <S.DeleteIconArea />
              )}
            </S.DeliveryDate>
          );
        })}
    </>
  );
};

export default DeliveryStatusInputComponent;
