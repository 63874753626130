import { useEffect, useState } from 'react';
import * as S from './ShippingDestinationContents.styles';
import ChevronLeft from '@assets/icons/chevron_left';
import { Button } from '@components/elements/buttons/Button';
import ErrorMsgPopUp from '@components/modules/common/ErrorMsgPopUp/ErrorMsgPopUp';
import Overlay from '@components/elements/backdrops/Overlay/Overlay';
import {
  ShippingDestination,
  ShippingGroup,
  Customer,
  ShippingDestinationUpdateData,
} from '@lib/pc/settings/shipping_destination_setting/type';
import { Todo } from '@lib/common/type';
import { createdShippingDestinationData } from '@components/modules/pc/settings/shipping_destination_setting/ShippingDestinationCreate/default';
import useCreateShippingDestinationMutation from '@lib/pc/settings/shipping_destination_master_create/useCreateShippingDestination';
import useUpdateShippingDestinationMutation from '@lib/pc/settings/shipping_destination_master_edit/useUpdateShippingDestination';

type Props = {
  newData: ShippingDestinationUpdateData;
  setNewData: (newData: ShippingDestinationUpdateData) => void;
  updating: boolean;
  setUpdating: (updating: boolean) => void;
  shippingDestination?: ShippingDestination;
  refetch?: () => void;
  onClickGoBack: () => void;
  setIsChanged: (isChanged: boolean) => void;
  enableToSave: boolean;
  setEnableToSave: (enableToSave: boolean) => void;
  setUpdatePopUp: (updatePopUp: boolean) => void;
  setCreatedData?: (createdData: Todo) => void;
};

const ShippingDestinationContents = ({
  newData,
  setNewData,
  updating,
  setUpdating,
  shippingDestination,
  refetch,
  onClickGoBack,
  setIsChanged,
  enableToSave,
  setEnableToSave,
  setUpdatePopUp,
  setCreatedData,
}: Props) => {
  // 登録ボタンか保存ボタンか判定
  const createMode = newData.id === 0 ? true : false;
  const [errMsg, setErrMsg] = useState('');

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const data = { ...newData, [e.target.name]: e.target.value };
    setNewData(data);
    setIsChanged(true);
    setEnableToSave(true);
  };

  const handleUpdateSuccess = () => {
    refetch && refetch();
    setUpdating(false);
    setUpdatePopUp(true);
    setIsChanged(false);
    setEnableToSave(false);
  };

  const [createdShippingDestination, setCreatedShippingDestination] = useState(
    createdShippingDestinationData
  );

  const handleCreatedShippingDestination = (
    shippingDestination: typeof createdShippingDestinationData
  ) => {
    setCreatedShippingDestination(shippingDestination);
  };

  const updateShippingDestinationMutation =
    useUpdateShippingDestinationMutation(
      newData.id,
      handleUpdateSuccess,
      setErrMsg
    );

  // ***********************
  // [保存ボタン]押下、更新処理
  // ***********************
  const handleSubmit = () => {
    setUpdating(true);
    updateShippingDestinationMutation.mutate(newData);
  };

  const createShippingDestinationMutation =
    useCreateShippingDestinationMutation(
      handleCreatedShippingDestination,
      handleUpdateSuccess,
      setErrMsg
    );

  // ***********************
  // [登録ボタン]押下、作成処理
  // ***********************
  const handleSubmitCreate = () => {
    setUpdating(true);
    createShippingDestinationMutation.mutate(newData);
  };

  useEffect(() => {
    if (createdShippingDestination?.id !== 0) {
      setNewData({
        ...newData,
        id: createdShippingDestination?.id,
        code: createdShippingDestination?.code,
        name: createdShippingDestination?.name,
        transportLeadTime: createdShippingDestination?.transportLeadTime
          ? createdShippingDestination?.transportLeadTime
          : 0,
      });
    }
    setCreatedData && setCreatedData(createdShippingDestination);
  }, [createdShippingDestination]);

  return (
    <>
      <S.Wrapper>
        <S.GoBackWrapper onClick={onClickGoBack}>
          <ChevronLeft isBlue={true} />
          <span>一覧画面へ戻る</span>
        </S.GoBackWrapper>

        <S.ShippingGroupAreaDl>
          <dt>出荷先コード</dt>
          <dd>
            <S.Input
              type="text"
              inputMode="numeric"
              name="code"
              id="code"
              autoComplete="off"
              value={newData?.code ? newData?.code : ''}
              onChange={handleChange}
            />
          </dd>
          <dt>出荷先名</dt>
          <dd>
            <S.Input
              type="text"
              inputMode="numeric"
              name="name"
              id="name"
              autoComplete="off"
              value={newData?.name ? newData?.name : ''}
              onChange={handleChange}
            />
          </dd>
          <dt>リードタイム（配送までにかかる日数）</dt>
          <dd>
            <S.Input
              type="number"
              inputMode="numeric"
              name="transportLeadTime"
              id="transportLeadTime"
              autoComplete="off"
              width="20%"
              value={
                newData?.transportLeadTime ? newData?.transportLeadTime : ''
              }
              onChange={handleChange}
            />
          </dd>
        </S.ShippingGroupAreaDl>

        <S.ShippingGroupAndCustomerWrapper>
          {/* 出荷先グループ */}
          <S.ShippingDestinationsWrapper>
            {!createMode && (
              <S.ShippingGroupTitle>出荷先グループ</S.ShippingGroupTitle>
            )}
            {shippingDestination &&
              shippingDestination?.shippingGroups.map(
                (destination: ShippingGroup, index: number) => {
                  return (
                    <S.ShippingDestination key={index}>
                      <S.CodeNameWrapper>
                        <S.CodeWrapper>
                          {destination.shippingGroupCode}
                        </S.CodeWrapper>
                        {destination.shippingGroupName}
                      </S.CodeNameWrapper>
                    </S.ShippingDestination>
                  );
                }
              )}
          </S.ShippingDestinationsWrapper>

          {/* 得意先 */}
          <S.ShippingDestinationsWrapper>
            {!createMode && <S.ShippingGroupTitle>得意先</S.ShippingGroupTitle>}
            {shippingDestination &&
              shippingDestination?.customers.map(
                (customer: Customer, index: number) => {
                  return (
                    <S.ShippingDestination key={index}>
                      <S.CodeNameWrapper>
                        <S.CodeWrapper>{customer.customerCode}</S.CodeWrapper>
                        {customer.customerName}
                      </S.CodeNameWrapper>
                    </S.ShippingDestination>
                  );
                }
              )}
          </S.ShippingDestinationsWrapper>
        </S.ShippingGroupAndCustomerWrapper>
      </S.Wrapper>

      <S.ButtonContainer>
        {createMode ? (
          <Button
            children={updating ? '登録中...' : '登録'}
            disabled={updating || !enableToSave}
            onClick={handleSubmitCreate}
          />
        ) : (
          <Button
            children={updating ? '保存中...' : '保存'}
            disabled={updating || !enableToSave}
            onClick={handleSubmit}
          />
        )}
      </S.ButtonContainer>
      <ErrorMsgPopUp
        errMsg={errMsg}
        handleClose={() => {
          setErrMsg('');
          setUpdating(false);
        }}
        fromPc={true}
        zIndex={9999}
      />
      {errMsg && (
        <Overlay
          dark
          handleClick={() => {
            setErrMsg('');
            setUpdating(false);
          }}
        />
      )}
    </>
  );
};

export default ShippingDestinationContents;
