import ReactSelect from 'react-select';
import { Customer, Todo } from '@lib/common/type';
import { NO_OPTION_JAPANESE } from '@lib/pc/common/constants';

type SelectProps = {
  options: { id: number; name: string }[] | undefined;
  value: number | null;
  searchable?: boolean;
  width?: string;
  onChange: (value: number) => void;
};

const CustomerSelect = ({
  options,
  value,
  searchable,
  width,
  onChange,
}: SelectProps) => {
  const toReactSelectOptions = (options: { id: number; name: string }[]) =>
    options.map(({ id, name }) => ({ value: id, label: name }));

  const notSelectedCustomers = { id: 0, name: '全ての得意先' };
  const allCustomers = options?.concat(notSelectedCustomers);
  const sortedAllCustomers = allCustomers?.sort(
    (a: Customer, b: Customer) => a.id - b.id
  );
  const reactSelectOptions =
    sortedAllCustomers && toReactSelectOptions(sortedAllCustomers);
  const currentValue =
    reactSelectOptions && reactSelectOptions.find((o) => o.value === value);

  const styles = {
    control: (baseStyles: Todo) => ({
      ...baseStyles,
      width: width ? width : '223px',
      height: '42px',
      borderRadius: '0.5rem',
      borderWidth: 0,
      marginRight: '18px',
      zIndex: 5,
      outline: 'solid 1px rgba(0, 0, 0, 0.08)',
      cursor: 'pointer',
    }),
    menu: (baseStyles: Todo) => ({
      ...baseStyles,
      top: '35px',
      width: width ? width : '223px',
      zIndex: 1002,
    }),
  };

  return (
    <ReactSelect
      options={reactSelectOptions}
      value={currentValue}
      onChange={(option) => option && onChange(option.value)}
      styles={styles}
      components={{
        IndicatorSeparator: () => null,
      }}
      isSearchable={searchable ? searchable : false}
      noOptionsMessage={() => NO_OPTION_JAPANESE}
    />
  );
};

export default CustomerSelect;
