import styled from 'styled-components';

export const Wrapper = styled.div<{
  marginTop?: string;
}>`
  display: flex;
  position: relative;
  text-align: center;
  margin-left: 0;
  margin-top: 0.3rem;
`;
