import Form from '@components/modules/pc/purchase_orders_edit/Form/Form';
import { WithHeader } from '@components/templates/WithHeader';
import { initMaterialOrderData } from '@lib/pc/purchase_orders_new/type';

export const PurchaseOrdersNewRoot = () => {
  const materialOrder = initMaterialOrderData;

  return (
    <WithHeader>
      <Form materialOrder={materialOrder} />
    </WithHeader>
  );
};
