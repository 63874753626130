import React from 'react';
import { WithHeader } from '@components/templates/WithHeader';
import { Heading } from '@components/elements/typographies/Heading';
import * as S from './ShippingDestinationMasterCreate.styles';
import ShippingDestinationCreateWrapper from '@components/modules/pc/settings/shipping_destination_setting/ShippingDestinationCreate/ShippingDestinationCreateWrapper';

export const ShippingDestinationMasterCreateRoot: React.FC = () => {
  return (
    <WithHeader>
      <S.Wrapper>
        <div>
          <S.HeadContainer>
            <S.FlexBaseline>
              <div>
                <Heading tag="span" text="出荷先の作成" fs="32" fw="lg" />
              </div>
            </S.FlexBaseline>
          </S.HeadContainer>
          <ShippingDestinationCreateWrapper />
        </div>
      </S.Wrapper>
    </WithHeader>
  );
};
