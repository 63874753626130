import styled from 'styled-components';

export const Wrapper = styled.div`
  width: 15.25rem;
  height: auto;
  padding: 0px 16px 0px 16px;
  background: ${(props) => props.theme.palette.beige};
  box-shadow: 0px 12px 17px rgba(0, 0, 0, 0.07),
    0px 5px 22px rgba(0, 0, 0, 0.06), 0px 7px 8px rgba(0, 0, 0, 0.1);
  position: fixed;
  z-index: 9999;
  gap: 12px;
  border-radius: 4px;
  top: 25%;
  right: 1%;
`;

export const StatusLabel = styled.label`
  display: block;
  padding-top: 10px;
  height: 42px;
  width: 240px;
  background: ${(props) => props.theme.palette.beige};
`;

export const CheckBox = styled.input`
  width: 8px;
  opacity: 0;
  appearance: none;
  position: absolute;
`;

export const CheckBoxImage = styled.div<{
  checked: boolean;
}>`
  display: flex;
  align-items: center;
  ::before {
    display: inline-block;
    content: '';
    width: 1.125rem;
    height: 1.125rem;
    margin-right: 0.45rem;
    /* TODO: チェックマークだけsvgにして枠は背景はcssで作りたい */
    /* TODO: elementに切り出し */
    background-image: ${(props) =>
      props.checked
        ? "url('data:image/svg+xml;charset=utf8,%3Csvg%20width%3D%2218%22%20height%3D%2218%22%20viewBox%3D%220%200%2018%2018%22%20fill%3D%22none%22%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%3E%3Cpath%20d%3D%22M16%200H2C0.89%200%200%200.9%200%202V16C0%2017.1%200.89%2018%202%2018H16C17.11%2018%2018%2017.1%2018%2016V2C18%200.9%2017.11%200%2016%200ZM7%2014L2%209L3.41%207.59L7%2011.17L14.59%203.58L16%205L7%2014Z%22%20fill%3D%22%232BA9D1%22%2F%3E%3C%2Fsvg%3E')"
        : "url('data:image/svg+xml;charset=utf8,%3Csvg%20width%3D%2218%22%20height%3D%2218%22%20viewBox%3D%220%200%2018%2018%22%20fill%3D%22none%22%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%3E%3Cpath%20d%3D%22M16%202V16H2V2H16ZM16%200H2C0.9%200%200%200.9%200%202V16C0%2017.1%200.9%2018%202%2018H16C17.1%2018%2018%2017.1%2018%2016V2C18%200.9%2017.1%200%2016%200Z%22%20fill%3D%22%23C0C0C0%22%2F%3E%3C%2Fsvg%3E')"};
    background-repeat: no-repeat;
    background-position: center;
  }
`;
