import { FC, useEffect, useState } from 'react';
import moment from 'moment';
import * as S from './TableWithFixedSidebar.styles';
import Filter from '@assets/icons/filter_list';
import { ReactComponent as FilteredIcon } from '@assets/icons/filtered.svg';
import Overlay from '@components/elements/backdrops/Overlay/Overlay';
import TagMenu from '@components/modules/common/TagMenu/TagMenu';
import TagListModal from '@components/modules/common/TagListModal/TagListModal';
import { generateDateArray, addComma } from '@lib/pc/stock_status/functions';
import { tagSearch } from '@lib/common/functions';
import { PRODUCT_KIND, Todo } from '@lib/common/type';
import { CircularProgress } from '@material-ui/core';
import { SelectedProductData } from '@lib/pc/stock_status/type';

type Props = {
  selectedDate: moment.Moment;
  data: Todo;
  selected: boolean;
  handleSelectedProduct: (d: SelectedProductData) => void;
  handleOpenProductDetail: (item: Todo) => void;
  setTagListModalActive?: () => void;
  rightSlideSidebarActive: boolean;
  viewEstimated: boolean;
  caseUnitDisplay: boolean;
  onClickCancel: () => void;
};

const TableWithFixedSidebar: FC<Props> = ({
  selectedDate,
  data,
  selected,
  handleSelectedProduct,
  handleOpenProductDetail,
  rightSlideSidebarActive,
  viewEstimated,
  caseUnitDisplay,
  onClickCancel,
}: Props) => {
  const [searchWord, setSearchWord] = useState('');
  const [tagMenuActive, setTagMenuActive] = useState(false);
  const [tagListModalActive, setTagListModalActive] = useState(false);
  const [selectedTags, setSelectedTags] = useState<Todo>([]);
  const selectedTagIds = selectedTags.map((item: Todo) => item['id']);

  const ProductNameArr = data
    ? data.map((p: Todo) => ({
        expiryDays: p.expiryDays,
        id: p.id,
        name: p.name,
        nameAbbreviation: p.nameAbbreviation,
        nameKana: p.nameKana,
        productCode: p.productCode,
        productKind: p.productKind === 1 ? '完' : '半',
        lastInput: p.stockLastInputAt,
        stocks: p.stocks,
        estimatedStocks: p.estimatedStocks,
        tagIds: p.tagIds,
        // safetyStockDaysがない場合、暫定的に3日とする
        safetyStockDays: !p.safetyStockDays ? '3' : String(p.safetyStockDays),
      }))
    : [];

  const handleSearch = (e: Todo) => {
    setSearchWord(e.target.value);
  };

  const handleClickCell = (p: Todo, idx: number) => {
    handleSelectedProduct({
      productId: p.id,
      productName: p.name,
      productKind: p.productKind,
      type: p.productKind === PRODUCT_KIND.PRODUCT ? '完' : '半',
      ExpiryDays: p.expiryDays,
      date: p.stocks[idx].date,
      data: p.stocks[idx].amountDetail,
      comment: p.stocks[idx].comment,
      piecesPerBox: p.piecesPerBox ? p.piecesPerBox : 1,
      stocks: p.stocks,
      estimatedStocks: p.estimatedStocks,
      stockDateLabel: p.stockDateLabel,
      labelOfAmount: p.labelOfAmount,
    });
  };

  const date = new Date();
  const year = date.getFullYear();
  const month = (date.getMonth() + 1).toString().padStart(2, '0');
  const day = date.getDate().toString().padStart(2, '0');
  const today = `${year}-${month}-${day}`;

  useEffect(() => {
    setTagMenuActive(false);
  }, [selectedTagIds.length === 0]);

  return (
    <>
      <div>
        <S.ListWrapper>
          <S.ListSidebar>
            <S.ListLeftHeadCell
              invalid={rightSlideSidebarActive}
              onClick={() => setTagMenuActive(!tagMenuActive)}
              isBlue={searchWord !== '' || selectedTagIds.length > 0}
            >
              <span className="filter-product">
                {searchWord !== '' || selectedTagIds.length > 0 ? (
                  <>
                    <FilteredIcon />
                    <div className="filter_text">商品の絞込み中</div>
                  </>
                ) : (
                  <>
                    <Filter
                      isBlue={searchWord !== '' || selectedTagIds.length > 0}
                    />
                    <div className="filter_text">商品の絞込</div>
                  </>
                )}
              </span>
            </S.ListLeftHeadCell>

            {ProductNameArr.filter(
              (p: Todo) =>
                p.name.includes(searchWord) ||
                p.nameAbbreviation?.includes(searchWord) ||
                p.nameKana?.includes(searchWord) ||
                p.productCode?.toString().includes(searchWord)
            )
              .filter((p: Todo) => tagSearch(p, selectedTagIds))
              .map(
                (
                  s: {
                    name: string;
                    nameAbbreviation: string;
                    productCode: number;
                    productKind: string;
                    lastInput: string;
                    safetyStockDays: string | null;
                  },
                  i: number
                ) => {
                  return (
                    <S.ListLeftHeadCell
                      key={i}
                      onClick={() => handleOpenProductDetail(s)}
                    >
                      <S.ListLeftHeadName>
                        {s.nameAbbreviation ? s.nameAbbreviation : s.name}
                        <S.ProductKindDiv productKind={s.productKind}>
                          {s.productKind}
                        </S.ProductKindDiv>
                      </S.ListLeftHeadName>
                      <S.ListLeftHeadMeta>
                        <span>{s.productCode}</span>
                        <div>
                          最終入力日
                          <span>
                            {s.lastInput &&
                              moment(s.lastInput).format('YYYY/MM/DD')}
                          </span>
                        </div>
                      </S.ListLeftHeadMeta>
                    </S.ListLeftHeadCell>
                  );
                }
              )}
          </S.ListSidebar>
          <S.ListBody className={selected ? 'move-to-left' : ''}>
            <S.ListDateRow>
              {generateDateArray(6, selectedDate).map((p, i) => {
                return (
                  <S.ListHeadCell
                    key={i}
                    highLighten={i === 5}
                    dayOfWeek={moment(p.productionDate).format('ddd')}
                  >
                    {moment(p.productionDate).format('MM/DD(ddd)')}
                  </S.ListHeadCell>
                );
              })}
            </S.ListDateRow>
            {data ? (
              data
                .filter(
                  (p: Todo) =>
                    p.name.includes(searchWord) ||
                    p.nameAbbreviation?.includes(searchWord) ||
                    p.nameKana?.includes(searchWord) ||
                    p.productCode?.toString().includes(searchWord)
                )
                .filter((p: Todo) => tagSearch(p, selectedTagIds))
                .map((p: Todo, i: number) => {
                  return (
                    <S.ListRow key={i}>
                      {generateDateArray(6).map((d: Todo, idx: number) => {
                        const todayOrNot =
                          today ===
                          p.stocks[idx].date.match(/(\d{4}-\d{2}-\d{2})/)[0];
                        if (caseUnitDisplay) {
                          // estimatedStocks、stocksどちらを表示するかの判定
                          const caseAmount = p.stocks[idx].caseAmount
                            ? p.stocks[idx].caseAmount
                            : p.estimatedStocks[idx].caseAmount;
                          // ケース数での表示
                          return (
                            <S.ListCellContainer
                              todaysEstimated={
                                (p.stocks[idx].caseAmount === null ||
                                  p.stocks[idx].caseAmount === 0) &&
                                (p.estimatedStocks[idx].amount === 0 ||
                                  p.estimatedStocks[idx].amount) &&
                                todayOrNot
                              }
                              estimated={
                                (p.stocks[idx].caseAmount === null ||
                                  p.stocks[idx].caseAmount === 0) &&
                                (p.estimatedStocks[idx].amount === 0 ||
                                  p.estimatedStocks[idx].amount)
                              }
                              invalid={rightSlideSidebarActive}
                              key={idx}
                              highLighten={idx === 5}
                              withComment={p.stocks[idx].comment}
                              onClick={() => {
                                handleClickCell(p, idx);
                              }}
                            >
                              {addComma(
                                caseAmount === 0 ? 0 : caseAmount || ''
                              )}
                            </S.ListCellContainer>
                          );
                        } else if (
                          viewEstimated &&
                          p.stocks[idx].amount === null
                        ) {
                          // 実在庫がある場合は予定在庫へのチェックでも実在庫を表示する
                          return (
                            // 予定在庫
                            <S.ListCellContainer
                              todaysEstimated={
                                (p.estimatedStocks[idx].amount === 0 ||
                                  p.estimatedStocks[idx].amount) &&
                                todayOrNot
                              }
                              estimated
                              invalid={rightSlideSidebarActive}
                              key={idx}
                              highLighten={idx === 5}
                              withComment={p.stocks[idx].comment}
                              onClick={() => {
                                handleClickCell(p, idx);
                              }}
                            >
                              {addComma(
                                p.estimatedStocks[idx].amount === 0
                                  ? 0
                                  : p.estimatedStocks[idx].amount || ''
                              )}
                            </S.ListCellContainer>
                          );
                        } else {
                          return (
                            // 実在庫
                            <S.ListCellContainer
                              invalid={rightSlideSidebarActive}
                              key={idx}
                              highLighten={idx === 5}
                              withComment={p.stocks[idx].comment}
                              onClick={() => {
                                handleClickCell(p, idx);
                              }}
                            >
                              {addComma(p.stocks[idx].amount)}
                            </S.ListCellContainer>
                          );
                        }
                      })}
                    </S.ListRow>
                  );
                })
            ) : (
              <S.CircularIconWrapper>
                <CircularProgress style={{ color: '#64b2f9' }} />
              </S.CircularIconWrapper>
            )}
          </S.ListBody>
        </S.ListWrapper>
      </div>
      {tagMenuActive && (
        <TagMenu
          searchParam={searchWord}
          selectedTags={selectedTags}
          setSelectedTags={(tags) => setSelectedTags(tags)}
          setOpenTagModal={() => setTagListModalActive(true)}
          handleChange={handleSearch}
          deleteSearchParam={() => setSearchWord('')}
        />
      )}
      {tagListModalActive && (
        <TagListModal
          selectedTags={selectedTags}
          setSelectedTags={(tags) => setSelectedTags(tags)}
          tagListModalActive={tagListModalActive}
          handleClose={() => {
            setTagListModalActive(false);
            setTagMenuActive(false);
          }}
          productKind={PRODUCT_KIND.PRODUCT_AND_SEMI_PRODUCT}
        />
      )}
      {(tagMenuActive || rightSlideSidebarActive) && (
        <Overlay
          zIndex={9997}
          handleClick={() => {
            setTagMenuActive(false);
            onClickCancel();
          }}
        />
      )}
    </>
  );
};

export default TableWithFixedSidebar;
