// import { useState } from 'react';
import * as S from './PickingDetailWithChecked.styles';
// import { ReactComponent as ArrowUpOutlined } from '@assets/icons/arrow_up_outlined.svg';
// import { ReactComponent as ExpandMore } from '@assets/icons/expand_more.svg';
import CaseSelect from '@components/modules/common/CaseSelect/CaseSelect';
import { Case, Todo } from '@lib/common/type';
// import { PickingBreakdownDetail } from '@lib/mobile/picking_edit/type';
import { PickingDetails } from '@lib/mobile/picking_by_shipping/type';

type Props = {
  idx: number;
  pickingBreakdownDetail: PickingDetails[];
  cases: Case[] | undefined;
  detailActive: boolean;
};

const PickingDetailWithChecked = ({
  idx,
  pickingBreakdownDetail,
  cases,
  detailActive,
}: Props) => {
  // const [detailActive, setDetailActive] = useState(false);

  return (
    <S.Wrapper>
      <S.DetailWrapper open={detailActive}>
        <S.TitleText>
          ピッキングした内訳
          {/* {detailActive ? <ArrowUpOutlined /> : <ExpandMore />} */}
        </S.TitleText>
        {pickingBreakdownDetail &&
          pickingBreakdownDetail.map((p: Todo, index: number) => (
            <S.Detail key={index}>
              <S.ExpirationDate>
                <div className="expiration-date">
                  <label
                    htmlFor={`check-production-date-${idx}-${index}`}
                    className="expiration-date__label"
                  >
                    賞味期限
                  </label>
                  <S.Input
                    type="date"
                    name="productionDate"
                    id={`check-production-date-${idx}-${index}`}
                    value={p.bestBeforeDate ? p.bestBeforeDate : ''}
                    disabled
                  />
                </div>
                <div className="case-name">
                  {/* ケースの選択エリア */}
                  {cases && (
                    <CaseSelect
                      cases={cases}
                      value={p.caseId}
                      marginLeft={'0'}
                      disabled={true}
                      onChange={undefined}
                      controlFontWeight={'700'}
                      labelFontWeight={'400'}
                      pcWidth={'280px'}
                    />
                  )}
                </div>
              </S.ExpirationDate>
              {/* ピッキング入力エリア */}
              <S.AmountWrapper>
                <S.DInputContainer center={true}>
                  <label htmlFor={`check-new-stock-case-${idx}-${index}`}>
                    ケース
                  </label>
                  <input
                    id={`check-new-stock-case-${idx}-${index}`}
                    value={p.case ? p.case : ''}
                    disabled
                  />
                  <span>×{p.piecesPerBox}個</span>
                </S.DInputContainer>
                <S.DSymbol>+</S.DSymbol>
                <S.DInputContainer>
                  <label htmlFor={`check-new-stock-bara-${idx}-${index}`}>
                    個
                  </label>
                  <input
                    id={`check-new-stock-bara-${idx}-${index}`}
                    value={p.piece ? p.piece : ''}
                    disabled
                  />
                </S.DInputContainer>
                <S.DSymbol>=</S.DSymbol>
                <S.DInputContainer>
                  <label htmlFor={`check-new-stock-total-${idx}-${index}`}>
                    合計
                  </label>
                  <input
                    id={`check-new-stock-total-${idx}-${index}`}
                    value={p.amount ? p.amount : ''}
                    disabled
                  />
                </S.DInputContainer>
              </S.AmountWrapper>
            </S.Detail>
          ))}
      </S.DetailWrapper>
    </S.Wrapper>
  );
};

export default PickingDetailWithChecked;
