import { useEffect } from 'react';
import * as S from './FactoryLineContents.styles';
import StackerDetailTable from '../StackerDetailTable/StackerDetailTable';
import { factoryLineBgColors } from '@lib/pc/common/type';
import { factory } from '@lib/pc/stacker/useStackers';
import type { Todo } from '@lib/common/type';

interface Props {
  stackingResults: { stackingResults: factory[] };
  refetch: () => void;
  selectedLineId: number;
  selectedFactoryData: factory;
  setSelectedFactoryData: (selectedFactoryData: factory) => void;
  selectedFactoryId?: number | string;
  setSelectedFactoryId: (selectedFactoryId: number) => void;
  tabColor: number;
  date: Date;
  setPopUp: (popUp: boolean) => void;
  setEditProductName: (editProductName: string) => void;
}

const valueOfLineBgColors = Object.values(factoryLineBgColors);

const FactoryLineContents = ({
  stackingResults,
  refetch,
  selectedLineId,
  selectedFactoryData,
  setSelectedFactoryData,
  selectedFactoryId,
  setSelectedFactoryId,
  tabColor,
  date,
  setPopUp,
  setEditProductName,
}: Props) => {
  useEffect(() => {
    setLatestFactoryData();
  }, [stackingResults?.stackingResults]);

  useEffect(() => {
    setSelectedFactoryData(selectedFactoryData);
  }, [selectedFactoryData]);

  const setLatestFactoryData = () => {
    if (selectedFactoryId === undefined) {
      setSelectedFactoryId(stackingResults?.stackingResults[0]?.factoryId);
      setSelectedFactoryData(stackingResults?.stackingResults[0]);
    } else {
      const selectedData = stackingResults?.stackingResults.filter(
        (pr: factory) => pr.factoryId == selectedFactoryId
      );
      if (selectedData?.[0]) {
        setSelectedFactoryData(selectedData[0]);
      }
    }
  };

  return (
    <S.Wrapper bgColor={valueOfLineBgColors[tabColor]}>
      <S.Header bgColor={valueOfLineBgColors[tabColor]}>
        {/* <S.Title>包装機製造数記録表</S.Title> */}
      </S.Header>
      <S.Content>
        {/* TODO: 型当てる */}
        {selectedFactoryData?.lines.map((line: Todo) => (
          <S.TabContentArea key={line.factoryLineId}>
            <S.TabContent open={line.factoryLineId === selectedLineId}>
              <StackerDetailTable
                refetch={refetch}
                line={line}
                tabColor={tabColor}
                factoryId={selectedFactoryData.factoryId}
                date={date}
                setPopUp={setPopUp}
                setEditProductName={setEditProductName}
              />
            </S.TabContent>
          </S.TabContentArea>
        ))}
      </S.Content>
    </S.Wrapper>
  );
};

export default FactoryLineContents;
