import { useMutation } from 'react-query';
import humps from 'humps';
import { axiosInstance } from '@lib/pc/common/api/axiosConfig';
import { Todo } from '@lib/common/type';

// 従来のPackagingテーブル更新
const useUpdateWrappingMutation = (
  packagingId: string | number,
  date: Date,
  factoryId: number,
  factoryLineId: number,
  handleUpdateSuccess: () => void
) => {
  return useMutation(
    async (params: Todo) => {
      return await axiosInstance().patch(
        `/api/v1/packagings/${packagingId}?date=${date}&factory_id=${factoryId}&factory_line_id=${factoryLineId}`,
        humps.decamelizeKeys(params)
      );
    },
    {
      onSuccess: () => {
        handleUpdateSuccess();
      },
      onError: () => alert('エラーが発生しました'),
    }
  );
};

export default useUpdateWrappingMutation;
