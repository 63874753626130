import moment from 'moment';
import { SingleDatePicker } from 'react-dates';
import 'react-dates/lib/css/_datepicker.css';
import * as S from './ProductProductionResultCopyModal.styles';
import { Button } from '@components/elements/buttons/Button';
import { useState } from 'react';
import { ReactComponent as CalendarBlue } from '@assets/icons/mobile/calendar_blue.svg';

type Props = {
  copyModalOpen: boolean;
  onClickLast: () => void;
  onClickLastWeek: () => void;
  onClickCalender: () => void;
  sevenDaysBefore: Date;
  date: Date;
  setDuplicateTargetDate: (duplicateTargetDate: Date) => void;
};

const ProductProductionResultCopyModal = ({
  copyModalOpen,
  onClickLast,
  onClickLastWeek,
  onClickCalender,
  date,
  setDuplicateTargetDate,
}: Props) => {
  //日曜が0、土曜日が6。配列を使い曜日に変換する。
  const dateT = ['日', '月', '火', '水', '木', '金', '土'];
  const today = new Date();
  // date:現在一覧画面で選択している日付
  const sevenDaysBeforeNew = moment(date).subtract(7, 'days');
  const [focused, setFocused] = useState(false);

  return (
    <S.Wrapper open={copyModalOpen}>
      <S.TextContainer>
        <span>使用する生産実績情報を選択する。</span>
      </S.TextContainer>
      <S.ButtonContainer>
        <Button
          padding="sm"
          width="290px"
          margin={'15px 0'}
          outlined
          onClick={() => {
            onClickLast();
          }}
        >
          前回
        </Button>
        <S.ButtonWrapper
          onClick={() => {
            onClickLastWeek();
          }}
        >
          {sevenDaysBeforeNew.toDate().getFullYear().toString()}/
          {(sevenDaysBeforeNew.toDate().getMonth() + 1)
            .toString()
            .padStart(2, '0')}
          /{sevenDaysBeforeNew.toDate().getDate().toString().padStart(2, '0')}(
          {dateT[sevenDaysBeforeNew.toDate().getDay()]})<span>(先週)</span>
        </S.ButtonWrapper>
        <S.ButtonWrapper padding="0">
          <S.DatePicker>
            <SingleDatePicker
              date={moment(date)}
              isOutsideRange={(day) => day.isAfter(today)}
              onDateChange={(selectedDate) => {
                if (selectedDate) {
                  setDuplicateTargetDate(selectedDate.toDate());
                }
                onClickCalender();
              }}
              numberOfMonths={1}
              focused={focused}
              onFocusChange={({ focused }) => setFocused(focused)}
              showDefaultInputIcon={false}
              customInputIcon={
                <S.Date>
                  カレンダー
                  <CalendarBlue />
                </S.Date>
              }
              id="date_input"
            />
          </S.DatePicker>
        </S.ButtonWrapper>
      </S.ButtonContainer>
    </S.Wrapper>
  );
};

export default ProductProductionResultCopyModal;
