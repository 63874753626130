import * as S from './ShippingStatusHeaderControl.styles';
import moment from 'moment';
import ArrowRight from '@assets/icons/arrow_right_24px';
import { ReactComponent as ArrowLeft } from '@assets/icons/arrow_left_24px.svg';
import { Button } from '@components/elements/buttons/Button';
import { Heading } from '@components/elements/typographies/Heading';
import { Todo } from '@lib/common/type';
import ShippingDestinationSelect from '@components/modules/pc/shipping_status/ShippingDestinationSelect/ShippingDestinationSelect';

type Props = {
  date: Todo;
  setDate: (date: Todo) => void;
  handleClickToday: () => void;
  setSelectedShippingDestinationId: (shippingDestinationId: number) => void;
  refetch: () => void;
};

const ShippingStatusHeaderControl = ({
  date,
  setDate,
  handleClickToday,
  setSelectedShippingDestinationId,
}: Props) => {
  const getMonth = (num: number) => {
    setDate(moment(date).add(num, 'months').startOf('month'));
  };

  const handleSelectShippingDestination = (e: number) => {
    setSelectedShippingDestinationId(e);
  };

  return (
    <S.Wrapper>
      <S.PageTitle>
        <S.PageTitleText>
          <Heading tag="span" text="出荷状況" fs="32" fw="lg" />
          <Heading tag="span" text="を確認" fs="24" fw="lg" />
        </S.PageTitleText>
      </S.PageTitle>
      <S.SelectWrapper>
        <ShippingDestinationSelect
          handleChangeShipping={handleSelectShippingDestination}
          height="41px"
          menuTop="34px"
          searchable={true}
        />
      </S.SelectWrapper>
      <S.DateWrapper>
        <Button
          width="90px"
          margin={'0 8px 0 0'}
          outlined={true}
          borderWidth={1}
          onClick={handleClickToday}
        >
          今日
        </Button>
        <S.ArrowLeft selected={false} onClick={() => getMonth(-1)}>
          <ArrowLeft />
          <span className="circle"></span>
        </S.ArrowLeft>
        <S.Date>
          {moment(date).format('YYYY')}
          <span>年</span>
          {moment(date).format('MM')}
          <span>月</span>
        </S.Date>
        <S.ArrowRight selected={false} onClick={() => getMonth(1)}>
          <ArrowRight isActive={true} />
        </S.ArrowRight>
      </S.DateWrapper>
    </S.Wrapper>
  );
};

export default ShippingStatusHeaderControl;
