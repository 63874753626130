import { axiosInstance } from '@lib/pc/common/api/axiosConfig';
import { useQuery } from 'react-query';
import humps from 'humps';
import moment from 'moment';
import { Todo } from '@lib/common/type';

export const useOrderSummaries = (
  date: Date,
  customerId?: number | undefined
) => {
  const { data, refetch } = useQuery(
    ['order_summaries', date, customerId],
    async () => {
      const data = await axiosInstance().get(
        `/api/v1/order_summaries?date=${moment(date).format(
          'YYYYMMDD'
        )}&customer_id=${customerId}`
      );

      return data;
    }
  );

  const orderSummaries =
    data && (humps.camelizeKeys(data.data.order_detail_summaries) as Todo);

  return { orderSummaries, refetch };
};

export default useOrderSummaries;
