export const defaultEditData = {
  detailId: null,
  amount: null,
  cart: null,
  case: null,
  caseId: null,
  checkTime: null,
  comment: '',
  defectiveProductAmount: null,
  employeeIds: [],
  piece: null,
  plannedAmount: null,
  plannedCase: null,
  plannedPiece: null,
  productCode: '',
  productId: null,
  productName: '',
  productProductionResultId: null,
  productionDateOfSemiProduct: null,
  shippingOrExpirationDate: null,
  storeroomId: null,
  bestBeforeDateInfo: [],
  bestBeforeDate: '',
};

export type productIdConfirmation = {
  id: number;
  confirmed: boolean;
};
