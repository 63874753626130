import { useMutation } from 'react-query';
import humps from 'humps';
import moment from 'moment';
import { axiosInstance } from '@lib/pc/common/api/axiosConfig';
import type { StackingResult } from '@lib/common/type';

// stackingデータ作成
const useCreateStackerMutation = (
  factoryId: string | number,
  lineId: string | number,
  date: Date,
  handleSuccess: () => void
) => {
  return useMutation(
    async (params: StackingResult) => {
      return await axiosInstance().post(
        `/api/v1/stackings?factory_id=${factoryId}&factory_line_id=${lineId}&date=${moment(
          date
        ).format('YYYY-MM-DD')}`,
        humps.decamelizeKeys(params)
      );
    },
    {
      onSuccess: () => {
        handleSuccess();
      },
      onError: () => alert('エラーが発生しました'),
    }
  );
};

export default useCreateStackerMutation;
