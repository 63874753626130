import styled from 'styled-components';

export const ProductListRow = styled.div`
  display: flex;
  gap: 0.25rem;
  align-items: center;
`;

export const ProductName = styled.div`
  flex-basis: 40%;
  /* flex-basis: 35%; */
  padding-top: 6px;
`;

export const MaterialSelectWrapper = styled.div`
  width: 100%;
`;

// export const MaterialSelect = styled.select`
/* margin-left: 32px; */
/* font-size: 18px; */
/* cursor: pointer; */
// `;

export const Input = styled.input<{
  align?: string;
  padding?: string;
  height?: string;
}>`
  width: 100%;
  height: 40px;
  color: ${(props) => props.theme.palette.baseBlack};
  font-size: 1rem;
  padding: ${(props) => (props.padding ? props.padding : '0.325rem')};
  text-align: ${(props) => props.align};
  border: none;
  border-radius: 0.5rem;
  border: 1px solid rgba(0, 0, 0, 0.08);
  height: ${(props) => (props.height ? props.height : '')};
  background: ${(props) => props.theme.palette.white};
  ::-webkit-inner-spin-button {
    -webkit-appearance: none;
  }
  margin-top: 8px;
`;

export const ProductUnitPrice = styled.div`
  flex-basis: 15%;
`;
export const ProductUnitAmount = styled.div`
  flex-basis: 15%;
`;
export const ProductUnitTaxRate = styled.div<{
  paddingTop?: string;
}>`
  padding-top: ${(props) => (props.paddingTop ? props.paddingTop : '')};
  flex-basis: 16%;
`;
export const ProductDelete = styled.div`
  flex-basis: 10%;
  display: flex;
  justify-content: center;
`;
export const IconButton = styled.button`
  padding: 8px;
  background: none;
`;

export const DeletedText = styled.div`
  color: ${(props) => props.theme.palette.khaki};
  font-size: small;
`;
