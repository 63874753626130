import React from 'react';
import moment from 'moment';
import * as S from './AllStoreroomStockForm.styles';
import {
  AllStoreroomStocks,
  selectedItem,
  stocks,
  AllStoreroomDetail,
} from '@lib/pc/stock_status/type';
import LABEL_OF_AMOUNT_MAP from '@components/modules/common/LabelOfAmountMap/LabelOfAmountMap';

type Props = {
  data: AllStoreroomStocks | undefined;
  product: selectedItem;
};

const AllStoreroomStockForm = ({ data, product }: Props) => {
  return (
    <S.Wrapper>
      <S.HeadContainer id="top"></S.HeadContainer>
      <S.InputContainer>
        {data?.stockDetails?.stocks?.map((d: stocks, i: number) => {
          return (
            <div key={i}>
              <S.StoreroomWrapper>
                <S.ProductionOrShippingDateLabel
                  htmlFor={`production-date-${i}`}
                >
                  在庫室
                </S.ProductionOrShippingDateLabel>
                {d && d.storeroomName}
              </S.StoreroomWrapper>
              <S.StoreroomTotalWrapper>
                <S.ProductionOrShippingDateLabel htmlFor={`total-amount-${i}`}>
                  在庫室合計数【{d.isActual ? '実在庫' : '予定在庫'}】
                </S.ProductionOrShippingDateLabel>
                {d && d.totalAmount}
                {product.labelOfAmount
                  ? LABEL_OF_AMOUNT_MAP[product.labelOfAmount]
                  : '個'}
              </S.StoreroomTotalWrapper>
              {d.amountDetail.map((detail: AllStoreroomDetail, i: number) => {
                return (
                  <React.Fragment key={i}>
                    <S.DateWrapper isFirst={i === 0}>
                      <S.ProductionOrShippingDateLabel
                        htmlFor={`production-date-${i}`}
                      >
                        {/* 完成品の場合：賞味期限、半製品の場合： 製造日時*/}
                        {product.stockDateLabel
                          ? product.stockDateLabel
                          : product.type === '完'
                          ? '賞味期限'
                          : '製造日時'}
                      </S.ProductionOrShippingDateLabel>
                      {detail?.productionOrShippingDate
                        ? moment(detail?.productionOrShippingDate).format(
                            'YYYY/MM/DD'
                          )
                        : '未記入'}
                    </S.DateWrapper>
                    {/* ケース種類 */}
                    {data?.stockDetails.hasCase && (
                      <S.CaseWrapper>
                        <S.CaseLabel htmlFor={`production-date-${i}`}>
                          ケース種類
                        </S.CaseLabel>
                        {detail?.caseName}
                      </S.CaseWrapper>
                    )}
                    <S.DRightBottomWrapper>
                      {/* ケース */}
                      <S.DInputContainer center={true}>
                        <label htmlFor={`stock-case-${i}`}>ケース</label>
                        {/* {detail?.case ? detail?.case : ''} */}
                        <input
                          id={`stock-case-${i}`}
                          value={detail?.case || ''}
                          type="text"
                          disabled
                        />
                        <span>
                          ×{detail?.piecesPerBox ? detail?.piecesPerBox : 1}
                          {product.labelOfAmount
                            ? LABEL_OF_AMOUNT_MAP[product.labelOfAmount]
                            : '個'}
                        </span>
                      </S.DInputContainer>
                      <S.DSymbol>+</S.DSymbol>
                      {/* バラ個数 */}
                      <S.DInputContainer>
                        <label htmlFor={`stock-bara-${i}`}>
                          {product.labelOfAmount
                            ? LABEL_OF_AMOUNT_MAP[product.labelOfAmount]
                            : '個'}
                        </label>
                        <input
                          id={`stock-bara-${i}`}
                          value={
                            detail?.bara === 0
                              ? 0
                              : detail?.bara
                              ? detail?.bara
                              : ''
                          }
                          type="text"
                          disabled
                        />
                      </S.DInputContainer>
                      <S.DSymbol>=</S.DSymbol>
                      <S.DInputContainer>
                        <label htmlFor={`stock-total-${i}`}>合計</label>
                        <input
                          id={`stock-total-${i}`}
                          value={
                            detail?.total === 0
                              ? '0'
                              : !detail?.total
                              ? ''
                              : Number(detail?.total).toLocaleString()
                          }
                          disabled
                        />
                      </S.DInputContainer>
                    </S.DRightBottomWrapper>
                  </React.Fragment>
                );
              })}
              <S.CommentContainer>
                <S.CommentLabel>備考</S.CommentLabel>
                <S.CommentContent>{d.comment}</S.CommentContent>
              </S.CommentContainer>
            </div>
          );
        })}
      </S.InputContainer>
    </S.Wrapper>
  );
};
export default AllStoreroomStockForm;
