import { useEffect, useState } from 'react';
import * as S from './SalesStoreContents.styles';
import ChevronLeft from '@assets/icons/chevron_left';
import { ReactComponent as CircleCloseIcon } from '@assets/icons/circle_close.svg';
import { Button } from '@components/elements/buttons/Button';
import DeleteConfirmModal from '@components/modules/common/DeleteConfirmModal/DeleteConfirmModal';
import ErrorMsgPopUp from '@components/modules/common/ErrorMsgPopUp/ErrorMsgPopUp';
import Overlay from '@components/elements/backdrops/Overlay/Overlay';
import {
  SalesStore,
  ShippingDestination,
} from '@lib/pc/settings/shipping_destination_setting/type';
import SalesStoreSelect from '../SalesStoreSelect/SalesStoreSelect';
import useSalesStores from '@lib/pc/settings/shipping_destination_master_edit/useSalesStores';
import useUpdateSalesStoreForShippingDestination from '@lib/pc/settings/shipping_destination_master_edit/useUpdateSalesStoreForShippingDestination';

type Props = {
  shippingDestination: ShippingDestination;
  refetch: () => void;
  onClickGoBack: () => void;
  setIsChanged: (isChanged: boolean) => void;
  setEnableToSave: (enableToSave: boolean) => void;
  setUpdatePopUp: (updatePopUp: boolean) => void;
};

const SalesStoreContents = ({
  shippingDestination,
  refetch,
  onClickGoBack,
  setIsChanged,
  setEnableToSave,
  setUpdatePopUp,
}: Props) => {
  // 削除確認メッセージ
  const [confirmMsg, setConfirmMsg] = useState('');
  const [errMsg, setErrMsg] = useState('');
  // 削除No
  const [deleteNo, setDeleteNo] = useState(0);
  const [updating, setUpdating] = useState(false);

  // selectの作成
  const [selectedId, setSelectedId] = useState(0);

  const { allSalesStores: allSalesStores, refetch: allSalesStoresRefetch } =
    useSalesStores();

  const [newSelectedSalesStores, setNewSelectedSalesStores] = useState<
    SalesStore[]
  >(shippingDestination?.salesStores);

  const registeredIds =
    newSelectedSalesStores &&
    newSelectedSalesStores?.map((item: SalesStore) => item['id']);

  // null更新するsalesStoreId
  const [delSalesStoreId, setDelSalesStoreId] = useState(0);

  // すでに登録済みの販売店を除く
  const filteredSalesStore = allSalesStores?.filter((value: SalesStore) => {
    if (registeredIds && !registeredIds.includes(value.id)) {
      return { value: value.id, label: value.name, code: value.code };
    }
  });

  const handleUpdateSuccess = () => {
    refetch();
    allSalesStoresRefetch();
    setUpdating(false);
    setUpdatePopUp(true);
    setIsChanged(false);
    setEnableToSave(false);
    setSelectedId(0);
  };

  // salesStore追加
  const updateSalesStore = useUpdateSalesStoreForShippingDestination(
    selectedId,
    handleUpdateSuccess,
    setErrMsg
  );

  // salesStore、null更新
  const deleteUpdateSalesStore = useUpdateSalesStoreForShippingDestination(
    delSalesStoreId,
    handleUpdateSuccess,
    setErrMsg
  );

  // ***********************
  // [保存ボタン]押下、更新処理
  // ***********************
  const handleSubmit = () => {
    setUpdating(true);
    updateSalesStore.mutate(shippingDestination.id);
  };

  // 削除ボタン
  const onClickDelButton = (no: number, name: string, id: number) => {
    setDelSalesStoreId(id);
    setConfirmMsg(`${name}を本当に削除しますか？`);
    setDeleteNo(no);
  };

  const deleteFunction = () => {
    deleteUpdateSalesStore.mutate(null);
    setIsChanged(true);
    newSelectedSalesStores.splice(deleteNo, 1);
    setNewSelectedSalesStores([...newSelectedSalesStores]);
    setConfirmMsg('');
    setEnableToSave(true);
  };

  useEffect(() => {
    setNewSelectedSalesStores(shippingDestination?.salesStores);
  }, [shippingDestination?.salesStores?.length]);

  return (
    <>
      <S.Wrapper>
        <S.GoBackWrapper onClick={onClickGoBack}>
          <ChevronLeft isBlue={true} />
          <span>一覧画面へ戻る</span>
        </S.GoBackWrapper>
        <S.NameCodeWrapper>
          <span>出荷先コード</span> {shippingDestination?.code}
        </S.NameCodeWrapper>
        <S.NameCodeWrapper marginRight={'68px'}>
          <span>出荷先名</span> {shippingDestination?.name}
        </S.NameCodeWrapper>
        <S.ShippingDestinationTitle>販売店</S.ShippingDestinationTitle>
        <S.ShippingDestinationSelectRow>
          <S.ShippingDestinationSelectWrapper>
            <SalesStoreSelect
              salesStores={filteredSalesStore}
              value={selectedId}
              setSelectedId={setSelectedId}
              disabled={allSalesStores === null || updating ? true : false}
            />
          </S.ShippingDestinationSelectWrapper>
          <div>
            <S.AddButton>
              {selectedId ? (
                <Button
                  children={'保存'}
                  onClick={() => {
                    handleSubmit();
                  }}
                />
              ) : (
                <Button disabled children={'保存'} />
              )}
            </S.AddButton>
          </div>
        </S.ShippingDestinationSelectRow>

        <S.ShippingDestinationsWrapper>
          {newSelectedSalesStores &&
            newSelectedSalesStores.map(
              (salesStore: SalesStore, index: number) => {
                return (
                  <S.ShippingDestination key={index}>
                    {/* 削除ボタン */}
                    <S.DeleteButton>
                      <S.CloseButton
                        onClick={() =>
                          onClickDelButton(
                            index,
                            salesStore.name,
                            salesStore.id
                          )
                        }
                      >
                        <CircleCloseIcon />
                      </S.CloseButton>
                    </S.DeleteButton>
                    <S.CodeNameWrapper>
                      <S.CodeWrapper>{salesStore.code}</S.CodeWrapper>
                      {salesStore.name}
                    </S.CodeNameWrapper>
                  </S.ShippingDestination>
                );
              }
            )}
        </S.ShippingDestinationsWrapper>
      </S.Wrapper>
      {confirmMsg && (
        <DeleteConfirmModal
          confirmMsg={confirmMsg}
          onClickCancel={() => setConfirmMsg('')}
          onClickDelete={() => deleteFunction()}
        />
      )}
      <ErrorMsgPopUp
        errMsg={errMsg}
        handleClose={() => {
          setErrMsg('');
          setUpdating(false);
        }}
        fromPc={true}
        zIndex={9999}
      />
      {errMsg && (
        <Overlay
          dark
          handleClick={() => {
            setErrMsg('');
            setUpdating(false);
          }}
        />
      )}
    </>
  );
};

export default SalesStoreContents;
