import { FC, useEffect } from 'react';
import * as S from './FactoryDropdown.styles';
import type { factory } from '@lib/pc/stacker/useStackers';

interface Props {
  stackingResults?: { stackingResults: factory[] };
  factories: factory[];
  selectedFactoryId: number | string | undefined;
  setSelectedFactoryId: (factoryId: string) => void;
  setSelectedFactoryData: (selectedFactoryData?: factory) => void;
  setSelectedLineId: (selectedLineId: number) => void;
}

const FactoryDropdown: FC<Props> = ({
  stackingResults,
  factories,
  selectedFactoryId,
  setSelectedFactoryId,
  setSelectedFactoryData,
  setSelectedLineId,
}: Props) => {
  // 工場を変えたときに選択タブを設定する
  useEffect(() => {
    if (selectedFactoryId === undefined) {
      setSelectedFactoryData(stackingResults?.stackingResults[0]);
    } else {
      const selectedData = stackingResults?.stackingResults?.filter(
        (t) => t.factoryId == selectedFactoryId
      );
      if (selectedData?.[0]) {
        setSelectedFactoryData(selectedData[0]);
        setSelectedLineId(selectedData[0].lines[0].factoryLineId);
      }
    }
  }, [selectedFactoryId]);

  return (
    <S.FactorySelect
      value={selectedFactoryId}
      onChange={(e) => setSelectedFactoryId(e.target.value)}
      name="factories"
      id="factories"
    >
      {factories
        ? factories.map((f) => (
            <option key={f.factoryId + f.factoryName} value={f.factoryId}>
              {f.factoryName}
            </option>
          ))
        : ''}
    </S.FactorySelect>
  );
};

export default FactoryDropdown;
