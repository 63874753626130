export type StockAllocationEditData = {
  detailId: number;
  bestBeforeDate: string;
  allocationType: string | number;
  storeroomId: number | null;
  caseId: number | null;
  case: number | null;
  piece: number | null;
  amount: number | null;
  comment: string;
  productId: number;
  transferStoreroomId: number | null;
  piecesPerBox: number | null;
  labelOfAmount: string;
};

export type StockAllocationScannedSemiProduct = {
  productId: number | null;
  productName: string;
};

export const initEditData = {
  detailId: 0,
  bestBeforeDate: '',
  allocationType: '',
  storeroomId: null,
  caseId: null,
  case: null,
  piece: null,
  amount: null,
  comment: '',
  productKind: 0,
  productId: 0,
  transferStoreroomId: null,
  piecesPerBox: null,
  labelOfAmount: '',
};
