import { useState, useRef, useEffect } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { useParams } from 'react-router';
import Scanner from './Scanner';
import scanSound from '@assets/sounds/sound.mp3';
import { ReactComponent as CameraIcon } from '@assets/icons/mobile/camera.svg';
import * as S from './RealTimeScanner.styles';
import Close from '@assets/icons/mobile/close';
// import type { SearchState } from '@lib/common/type';
import type { Product, Todo } from '@lib/common/type';
import { PickingState } from '@lib/mobile/picking_edit/type';
// import moment from 'moment';
// import useProducts from '@lib/mobile/common/hooks/useProducts';

const PickingByShippingEditScannerPage = () => {
  const { id } = useParams<{ id: string }>();
  const [audio] = useState(new Audio(scanSound));
  const [scanning, setScanning] = useState(false);
  const [results, setResults] = useState<Todo>([]);
  const [notFoundJanCode, setNotFoundJanCode] = useState(false);
  const scannerRef = useRef<Todo>(null);
  const history = useHistory();
  const { state } = useLocation<PickingState>();
  // const { data: products } = useProducts();
  const [searchedProductName, setSearchProductName] = useState('');

  useEffect(() => {
    if (scannerRef.current) {
      setScanning(true);
    }
  }, []);

  useEffect(() => {
    if (results.length > 0) {
      // Productテーブルから商品名検索
      const resultOfProduct = state.stateProducts?.filter(
        (pro: Product) =>
          pro.janCode === results[0]?.codeResult?.code ||
          pro.productCode === results[0]?.codeResult?.code
      );
      if (resultOfProduct && resultOfProduct?.length > 0) {
        setSearchProductName(resultOfProduct[0]?.name);
      } else {
        setNotFoundJanCode(true);
      }
    }
  }, [results]);

  useEffect(() => {
    if (searchedProductName) {
      setNotFoundJanCode(false);
      history.push(`/mobile/picking_by_shipping_edit/scanner_result/${id}`, {
        ...state,
        stateJanCode: results[0]?.codeResult?.code,
        stateScannedProductName: searchedProductName,
      });
    } else {
      setNotFoundJanCode(true);
    }
  }, [searchedProductName]);

  return (
    <div
      ref={scannerRef}
      style={{
        height: '100vh',
        width: '100vw',
        position: 'relative',
        backgroundColor: 'black',
      }}
    >
      <S.CameraMessage>
        <S.JanCode>商品コード</S.JanCode>を
        <S.ReadMessage>読み取ってください</S.ReadMessage>
      </S.CameraMessage>
      <S.Header>
        <div
          onClick={() =>
            history.push(`/mobile/picking_by_shipping_edit/${id}`, state)
          }
        >
          <Close isWhite={true} />
        </div>
        <div>バーコード読み取り</div>
        <div />
      </S.Header>

      <div
        className="detect-area"
        style={{
          position: 'absolute',
          top: 0,
          left: 0,
          right: 0,
          bottom: 0,
        }}
      ></div>
      <canvas
        className="drawingBuffer"
        style={{
          position: 'absolute',
          top: '0px',
          left: '0px',
          height: '100%',
          width: '100%',
        }}
      />
      {scanning ? (
        <Scanner
          scannerRef={scannerRef}
          onDetected={(result: Todo) => {
            setScanning(false);
            audio.play();
            setResults([...results, result]);
          }}
        />
      ) : (
        notFoundJanCode && (
          <S.NotFoundMessage>
            該当の商品が見つかりませんでした。
            <S.ReShootButton
              onClick={() => {
                setResults([]);
                setScanning(true);
                setNotFoundJanCode(false);
              }}
            >
              <CameraIcon />
              再度読み取る
            </S.ReShootButton>
          </S.NotFoundMessage>
        )
      )}
    </div>
  );
};

export default PickingByShippingEditScannerPage;
