import { useMutation } from 'react-query';
import humps from 'humps';
import moment from 'moment';
import { axiosInstance } from '@lib/pc/common/api/axiosConfig';
import { MaterialAllocationEditData } from '@lib/pc/material_allocation/type';

const useCreateMaterialStockAllocationMutation = (
  date: Date,
  materialId: number,
  storeroomId: number,
  handleCreateSuccess: () => void
) => {
  return useMutation(
    async (params: MaterialAllocationEditData) => {
      return await axiosInstance().post(
        `/api/v1/material_stock_allocations?date=${moment(date).format(
          'YYYY-MM-DD'
        )}&storeroom_id=${storeroomId}}&material_id=${materialId}`,
        humps.decamelizeKeys({ materialStockAllocation: params })
      );
    },
    {
      onSuccess: () => {
        handleCreateSuccess();
      },
      onError: () => alert('エラーが発生しました'),
    }
  );
};

export default useCreateMaterialStockAllocationMutation;
