import styled from 'styled-components';

export const Wrapper = styled.div`
  background: ${(props) => props.theme.palette.white};
  position: relative;
  height: calc(100vh - 80px);
`;

export const HeadContainer = styled.div`
  height: 86px;
  padding: 1.15rem 2.25rem;
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

export const ProductKind = styled.span`
  padding: 5px 9px 7px;
  height: 32px;
  color: #2ba9d1;
  border: 1px solid #2ba9d1;
  background: ${(props) => props.theme.palette.white};
  font-size: 19px;
  margin-right: 16px;
  border-radius: 2px;
  vertical-align: super;
`;

export const PageTitle = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

export const PageTitleText = styled.div`
  display: flex;
  align-items: baseline;
  padding-bottom: 4px;
`;

export const DateWrapper = styled.div`
  display: flex;
  align-items: center;
  position: relative;
`;

export const SelectWrapper = styled.div`
  margin-right: 0.8rem;
`;

export const ArrowLeft = styled.div<{
  selected: boolean;
}>`
  cursor: pointer;
  margin-right: 5px;
  pointer-events: ${(props) => (props.selected ? 'none' : '')}; ;
`;

export const ArrowRight = styled.div<{
  selected: boolean;
}>`
  cursor: pointer;
  pointer-events: ${(props) => (props.selected ? 'none' : '')}; ;
`;

export const Date = styled.div`
  font-size: 24px;
  font-weight: 700;
  height: 24px;
  line-height: 24px;
  text-align: center;
  padding: 5px 0;
  user-select: none;
  display: flex;
  align-items: center;
  position: relative;
  margin-top: -3px;

  > span {
    font-size: 22px;
    margin: 0 5px 0 5px;
  }
`;

export const ListLeftHeadCell = styled.div<{
  invalid?: boolean;
  isBlue?: boolean;
}>`
  pointer-events: ${(props) => (props.invalid ? 'none' : '')};
  height: 42px;
  width: 230px;
  border-radius: 0.5rem;
  border: 1px solid ${(props) => props.theme.palette.yellowGray};
  position: -webkit-sticky; /* for Safari */
  -webkit-transform: translateZ(0);
  display: flex;
  justify-content: space-between;
  padding: 4px 2px;
  gap: 0.25rem;
  display: flex;
  flex-direction: column;
  margin-right: 12px;
  position: relative;

  > span {
    font-size: 0.75rem;
    &.filter-product {
      width: 90%;
      padding-left: 25px;
      height: 32px;
      line-height: 32px;
      position: absolute;
      background: ${(props) => props.theme.palette.white};
      color: ${(props) =>
        props.isBlue ? props.theme.palette.blue : '#9ca3af'};
      border-radius: 0.5rem;
      cursor: pointer;

      > svg {
        position: absolute;
        top: 12%;
        left: 5%;
      }

      > div {
        padding-left: 14px;
        font-size: 16px;
      }

      > .filter_text {
        font-size: 13px;
        line-height: 16px;
        padding-left: 18px;
        text-align: left;
        position: absolute;
        top: 0;
        color: ${(props) => props.theme.palette.black};
      }

      > .filter_text_false {
        position: absolute;
        top: -20%;
        font-size: 14px;
        line-height: 22px;
        padding-left: 18px;
        color: ${(props) => props.theme.palette.black};
      }

      > .filter_text_false-for-semi {
        font-size: 1rem;
        padding-left: 18px;
        color: ${(props) => props.theme.palette.black};
      }
    }
  }

  :first-child {
    background-color: #f5f2e6 !important;
    > div > input {
      cursor: pointer;
      height: 32px;
      padding: 0.5rem 40px 0.5rem;
    }
  }

  :nth-child(odd) {
    background: ${(props) => props.theme.palette.paleYellow};
  }
  :nth-child(even) {
    background: ${(props) => props.theme.palette.white};
  }
`;
