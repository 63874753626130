import styled from 'styled-components';

export const Wrapper = styled.div<{
  color: string;
}>`
  width: 1.5rem;
  height: 0.5rem;

  position: relative;
`;

export const DotContainer = styled.div<{
  color: string;
}>`
  height: 100%;
  width: 100%;
  border-top-width: 2px;
  border-top-style: dashed;
  border-color: ${(props) => props.color};
  position: absolute;
  top: 50%;
  left: 0;
`;

export const SolidContainer = styled.div<{
  color: string;
}>`
  height: 100%;
  width: 100%;
  border-top-width: 2px;
  border-top-style: solid;
  border-color: ${(props) => props.color};
  position: absolute;
  top: 50%;
  left: 0;
`;

export const Square = styled.div<{
  color: string;
}>`
  border-color: ${(props) => props.color};
  border-style: solid;
  border-width: 2px;

  width: 0.5rem;
  height: 0.5rem;
  position: absolute;
  top: 20%;
  left: 35%;
  background: #fff;
`;
