import styled from 'styled-components';

export const Wrapper = styled.button<{
  disabled: boolean;
  outlined?: boolean;
  fontSize?: string;
}>`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 10px;
  background: ${(props) =>
    props.outlined
      ? props.theme.palette.white
      : props.disabled
      ? '#e0e0e0'
      : props.theme.palette.primary.main};
  color: ${(props) =>
    props.disabled
      ? '#6b675c'
      : props.outlined
      ? props.theme.palette.primary.main
      : props.theme.palette.primary.contrastText};
  border: ${(props) =>
    props.disabled
      ? `2px solid #e0e0e0`
      : `2px solid ${props.theme.palette.primary.main}`};

  font-weight: bold;
  font-size: ${(props) => (props.fontSize ? props.fontSize : '16px')};
  text-align: center;

  border-radius: 8px;
  padding: 0.75rem 0.25rem;
  /* padding: 0.75rem 0; */
  border-width: 1px;
  width: auto;
  cursor: ${(props) => (props.disabled ? 'initial' : 'pointer')};
`;
