import { axiosInstance } from '@lib/pc/common/api/axiosConfig';
import { useQuery } from 'react-query';
import humps from 'humps';
import type { Case } from '@lib/common/type';

const useCases = () => {
  return useQuery('cases', async () => {
    const { data } = await axiosInstance().get('/api/v1/cases');
    return humps.camelizeKeys(data.cases) as Case[];
  });
};

export default useCases;
