import * as S from './DeleteErrorModal.styles';
import { Button } from '@components/elements/buttons/Button';
// import { ReactComponent as DeleteIcon } from '@assets/icons/delete.svg';

type Props = {
  onClickOK: () => void;
};

const DeleteErrorModal = ({ onClickOK }: Props) => {
  return (
    <S.DeleteModalWrapper>
      <S.DeleteModalTextContainer>
        <span>この商品は別のデータに紐付けられています</span>
      </S.DeleteModalTextContainer>
      <S.DeleteModalButtonContainer>
        <Button
          padding="sm"
          outlined
          onClick={() => {
            onClickOK();
          }}
        >
          OK
        </Button>
      </S.DeleteModalButtonContainer>
    </S.DeleteModalWrapper>
  );
};

export default DeleteErrorModal;
