import styled from 'styled-components';

export const Wrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 1.6rem 2.5rem;

  .selector-wrapper {
    display: flex;
    &__stock_delivery_select {
      margin-right: 25px;
    }
    &__date_select {
      padding-top: 10px;
    }
  }
`;

export const MaterialType = styled.span`
  padding: 5px 10px 7px;
  width: 80px;
  height: 32px;
  background: ${(props) => props.theme.palette.primary.main};
  color: ${(props) => props.theme.palette.white};
  font-size: 19px;
  margin-right: 16px;
  border-radius: 2px;
  vertical-align: super;
  border: 1px solid #2ba9d1;
  border-radius: 2px;
`;

export const PageTitle = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

export const PageTitleText = styled.div`
  display: flex;
  align-items: baseline;
  padding-bottom: 4px;
`;

export const ButtonWrapper = styled.div`
  margin-top: 0.4rem;
  height: 42px;
`;
