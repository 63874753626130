export const TABLE_HEADER_TOP = [
  {
    key: '完成品名（工程名）',
    align: 'left',
    width: '160px',
    borderTop: true,
    colSpan: 1,
  },

  {
    key: '予定数量',
    align: 'right',
    width: '6rem',
    borderLeft: true,
    borderTop: true,
    colSpan: 3,
  },
  {
    key: '',
    align: 'left',
    width: '63px',
    wordBreak: 'keep-all',
    borderRight: true,
    borderTop: true,
    colSpan: 1,
  },
  { key: '時刻', borderTop: true, colSpan: 1 },
  { key: '', borderTop: true, colSpan: 1 },
];

export const TABLE_HEADER_MIDDLE = [
  {
    key: 'ケースの種類',
    wordBreak: 'keep-all',
    colSpan: 1,
  },
  {
    key: '実績数量',
    align: 'right',
    width: '6rem',
    borderLeft: true,
    colSpan: 3,
  },
  {
    key: '不良数',
    align: 'left',
    width: '63px',
    wordBreak: 'keep-all',
    borderRight: true,
    colSpan: 1,
  },
  { key: '担当者', colSpan: 2 },
];

export const TABLE_HEADER_BOTTOM = [
  { key: '在庫室', colSpan: 1 },
  { key: 'まとめ箇所', colSpan: 4 },
  {
    key: '備考',
    align: 'left',
    width: '50px',
    wordBreak: 'keep-all',
    colSpan: 2,
  },
];
