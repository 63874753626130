// import type { PickingBreakdownDetail } from '@lib/mobile/picking_edit/type';

// 台車・ケース・バラの共通の計算式
export const calcTotal = (
  piecesPerBox: number | '',
  caseNum: number | '',
  piece: number | ''
) => {
  // ここでbara,case,cartが空であればtotalへ０を設定してあげる。
  let total: number;
  if (caseNum || piece) {
    if (piecesPerBox) {
      total = Number(piece) + Number(caseNum) * piecesPerBox;
    } else {
      total = Number(piece) + Number(caseNum) * (piecesPerBox || 1);
    }
  } else {
    total = 0;
  }
  return total;
};
