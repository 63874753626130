import moment from 'moment';
import { useDrag, useDrop } from 'react-dnd';
import { useEffect, useRef, useState } from 'react';
import type { Identifier, XYCoord } from 'dnd-core';
import * as S from './WrappingMainTable.styles';
import { ReactComponent as AddIcon } from '@assets/icons/add.svg';
import { ReactComponent as SortIcon } from '@assets/icons/draggable_sort.svg';
import { ReactComponent as PencilIcon } from '@assets/icons/pencil.svg';
import { factoryLineBgColors } from '@lib/pc/common/type';
import useUpdateWrappingOrderMutation from '@lib/pc/wrapping/useUpdateWrappingOrderMutation';
import { CircularProgress } from '@material-ui/core';
import { BestBeforeDateByPic } from '@lib/pc/wrapping/types';

import type { EmployeeData, Todo } from '@lib/common/type';
import BestBeforeDateInfo from '@components/modules/common/pc/BestBeforeDateInfo/BestBeforeDateInfo';

const isIPadOS =
  /iPad|Macintosh/.test(navigator.userAgent) && 'ontouchend' in document;

export const TABLE_HEADER = [
  { key: '並替', align: 'left', width: '35px' },
  { key: '順', align: 'left', width: '30px' },
  { key: '製品名\nケースの種類', align: 'left', width: '260px' },
  {
    key: '時刻',
    align: 'left',
    width: '108px',
    wordBreak: 'keep-all',
    colSpan: 2,
  },
  {
    key: '完成品包装数量',
    align: 'right',
    wordBreak: 'keep-all',
    width: isIPadOS ? '21rem' : '378px',
    // width: isIPadOS ? '21rem' : '23.625rem',
    colSpan: 4,
  },
  {
    key: '包装担当者',
    align: 'left',
    // width: '400px',
    width: isIPadOS ? '10rem' : '14.2rem',
    wordBreak: 'keep-all',
    colSpan: 2,
  },
  {
    key: '賞味期限',
    align: 'left',
    width: '80px',
    wordBreak: 'keep-all',
    colSpan: 1,
  },
  {
    key: '写真',
    align: 'left',
    width: '80px',
    wordBreak: 'keep-all',
    colSpan: 1,
  },
  { key: '編集', align: 'left', width: '48px' },
];

const defaultEditData = {
  bestBeforeDate: null,
  caseId: null,
  check: false,
  comment: '',
  completedBara: null,
  completedCase: null,
  completedQuantity: null,
  completedTime: null,
  employeeIds: [],
  id: null,
  lineIndex: null,
  lineName: '',
  order: null,
  packagingEmployeeIds: [],
  piecesPerBox: null,
  plannedBara: null,
  plannedCase: null,
  plannedQuantity: null,
  productId: null,
  productName: '',
  productionResultId: null,
  startedTime: null,
};

const valueOfLineBgColors = Object.values(factoryLineBgColors);

const Row = ({
  packagingRecord,
  index,
  selectedLineIndex,
  setEditData,
  data,
  setRecognizedCnt,
  setBestBeforeDateState,
  setBestBeforeDateCheckCount,
  moveItem,
  setPopUp,
  setBestBeforeDatesForModal,
  setScanning,
}: {
  // TODO: 型当てる
  packagingRecord: Todo;
  index: number;
  moveItem: (a: number, b: number) => void;
} & Omit<Props, 'setStartingTime'>) => {
  const ref = useRef<HTMLTableSectionElement>(null);
  const [{ handlerId }, drop] = useDrop<
    // TODO: 型当てる
    Todo,
    void,
    { handlerId: Identifier | null }
  >({
    accept: ['item'],
    collect(monitor) {
      return {
        handlerId: monitor.getHandlerId(),
      };
    },
    hover(item, monitor) {
      if (!ref.current) return;

      const dragIndex = item.index;
      const hoverIndex = index;

      if (dragIndex === hoverIndex) return;

      // Determine rectangle on screen
      const hoverBoundingRect = ref.current?.getBoundingClientRect();

      // Get vertical middle
      const hoverMiddleY =
        (hoverBoundingRect.bottom - hoverBoundingRect.top) / 2;

      // Determine mouse position
      const clientOffset = monitor.getClientOffset();

      // Get pixels to the top
      const hoverClientY = (clientOffset as XYCoord).y - hoverBoundingRect.top;

      if (dragIndex < hoverIndex && hoverClientY < hoverMiddleY) return;

      if (dragIndex > hoverIndex && hoverClientY > hoverMiddleY) return;

      // Time to actually perform the action
      moveItem(dragIndex, hoverIndex);

      item.index = hoverIndex;
    },
  });

  const [{ isDragging }, drag] = useDrag({
    type: 'item',
    item: () => {
      return { ...packagingRecord, index };
    },
    collect: (monitor) => ({
      isDragging: monitor.isDragging(),
    }),
  });
  drag(drop(ref));

  return (
    <S.DraggableTBody
      bgColor={valueOfLineBgColors[selectedLineIndex]}
      ref={ref}
      data-handler-id={handlerId}
      isDragging={isDragging}
    >
      <S.TRow>
        {/**** 上段 ****/}
        <S.TCellOrder rowSpan={2} marginRight="2.75rem" className="order">
          <SortIcon />
        </S.TCellOrder>
        {/* 順 */}
        <S.TCell rowSpan={2} width="1.75rem" marginRight={'28px'}>
          {index + 1}
        </S.TCell>
        {/* 製品名 */}
        <S.TCell width="12rem" marginRight={'260px'} colSpan={1}>
          {packagingRecord.productCode}:
          {packagingRecord.productNameAbbreviation
            ? packagingRecord.productNameAbbreviation
            : packagingRecord.productName}
        </S.TCell>
        <S.TCell className="label">
          開<br />始
        </S.TCell>
        <S.TCell className="time-start">
          {/* 包装開始時刻 */}
          <div className="cell-content">
            {packagingRecord.startedTime &&
              moment(packagingRecord.startedTime).format('LT')}
          </div>
        </S.TCell>
        <S.TCell className="label">
          予<br />定
        </S.TCell>
        <S.TCell className="display-quantity display-quantity__count--case">
          {/* 完成品 包装予定数量 ケース */}
          <div className="display-quantity__inner-wrap">
            <div className="cell-content">
              <div className="quantity">
                <span className="num">{packagingRecord.plannedCase}</span>
                <span className="unit">ケース</span>
              </div>
              <div className="explanation">
                x{packagingRecord.piecesPerBox}
                <span className="unit">個</span>
              </div>
            </div>
            <div className="symbol">+</div>
          </div>
        </S.TCell>
        <S.TCell className="display-quantity display-quantity__count--quantity">
          {/* 完成品 包装予定数量 個 */}
          <div className="display-quantity__inner-wrap">
            <div className="cell-content">
              <div className="quantity">
                <span className="num">{packagingRecord.plannedBara}</span>
                <span className="unit">個</span>
              </div>
            </div>
            <div className="symbol">=</div>
          </div>
        </S.TCell>
        <S.TCell className="display-quantity display-quantity__count--total">
          {/* 完成品 包装予定数量 合計 */}
          <div className="display-quantity__inner-wrap">
            <div className="with-label__inner-wrap">
              <div className="cell-content">
                <div className="quantity">
                  <span className="title">合計</span>
                  <span className="num">{packagingRecord.plannedQuantity}</span>
                  <span className="unit">個</span>
                </div>
              </div>
            </div>
          </div>
        </S.TCell>
        {/* 担当者 */}
        <S.TCell className="person" colSpan={2}>
          {/* <S.TCell className="person" width="14.2rem" colSpan={2}> */}
          {packagingRecord.employeeIds === null
            ? ''
            : packagingRecord.employeeIds &&
              packagingRecord.employeeIds.map((e: EmployeeData, i: number) => {
                return (
                  <div key={i + e.code + e.name}>
                    {e.code}
                    {e.name && ':'}
                    {e.name}
                  </div>
                );
              })}
        </S.TCell>
        {/* 賞味期限 */}
        <S.TCell colSpan={1} width="80px">
          {packagingRecord.bestBeforeDate &&
            moment(packagingRecord.bestBeforeDate).format('YY.MM.DD')}
        </S.TCell>
        {/* 賞味期限写真 */}
        <S.TCell width="80px" align="center" colSpan={1}>
          <BestBeforeDateInfo result={packagingRecord} />
        </S.TCell>
        {/* 編集 */}
        <S.PencilIcon
          width="48px"
          rowSpan={2}
          colSpan={1}
          onClick={() => {
            setEditData({
              lineName: data?.lines[selectedLineIndex].factoryLineName,
              lineIndex: selectedLineIndex,
              ...packagingRecord,
            });
            // ここで設定必要
            setRecognizedCnt(packagingRecord.bestBeforeDateRecognizedCount);
            setPopUp(false);
            setBestBeforeDateState(packagingRecord.bestBeforeDateState);
            setBestBeforeDateCheckCount(
              packagingRecord.bestBeforeDateCheckCount
            );
            setBestBeforeDatesForModal(packagingRecord.bestBeforeDateInfo);
            setScanning(false);
          }}
        >
          <PencilIcon />
        </S.PencilIcon>
      </S.TRow>
      {/**** 下段 ****/}
      <S.TRow>
        {/* ケースの種類 */}
        <S.TCell width="16.75rem" marginRight={'260px'} colSpan={1}>
          {packagingRecord.caseName}
        </S.TCell>
        <S.TCell className="label">
          終<br />了
        </S.TCell>
        {/* 包装終了時刻 */}
        <S.TCell className="time-end">
          <div className="cell-content">
            {packagingRecord.completedTime &&
              moment(packagingRecord.completedTime).format('LT')}
          </div>
        </S.TCell>
        <S.TCell className="label">
          完<br />了
        </S.TCell>
        <S.TCell className="display-quantity">
          {/* 完成品 包装完了数量 */}
          <div className="display-quantity__inner-wrap">
            <div className="cell-content">
              <div className="quantity">
                <span className="num">{packagingRecord.completedCase}</span>
                <span className="unit">ケース</span>
              </div>
              <div className="explanation">
                x{packagingRecord.piecesPerBox}
                <span className="unit">個</span>
              </div>
            </div>
            <div className="symbol">+</div>
          </div>
        </S.TCell>
        <S.TCell className="display-quantity display-quantity__count--quantity">
          {/* 完成品 包装完了数量 */}
          <div className="display-quantity__inner-wrap">
            <div className="cell-content">
              <div className="quantity">
                <span className="num">{packagingRecord.completedBara}</span>
                <span className="unit">個</span>
              </div>
            </div>
            <div className="symbol">=</div>
          </div>
        </S.TCell>
        <S.TCell className="display-quantity display-quantity__count--total">
          {/* 完成品 包装完了数量 */}
          <div className="display-quantity__inner-wrap">
            <div className="with-label__inner-wrap">
              <div className="cell-content">
                <div className="quantity">
                  <span className="title">合計</span>
                  <span className="num">
                    {packagingRecord.completedQuantity}
                  </span>
                  <span className="unit">個</span>
                </div>
              </div>
            </div>
          </div>
        </S.TCell>
        <S.TCell className="label" colSpan={1}>
          備<br />考
        </S.TCell>
        {/* 備考 */}
        <S.TCell colSpan={3} className="packaging-comment">
          <div className="cell-content">{packagingRecord.comment}</div>
        </S.TCell>
      </S.TRow>
    </S.DraggableTBody>
  );
};

type Props = {
  selectedLineIndex: number;
  data: Todo;
  setEditData: Todo;
  recognizedCnt: number;
  setRecognizedCnt: (recognizedCnt: number) => void;
  popUp: boolean;
  setPopUp: (popUp: boolean) => void;
  messageKind: string;
  setMessageKind: (messageKind: string) => void;
  getLatestWrappingData: () => void;
  casesPerCart: string;
  piecesPerBox: string;
  setBestBeforeDatesForModal: (
    bestBeforeDatesForModal: BestBeforeDateByPic[]
  ) => void;
  bestBeforeDateState: string;
  setBestBeforeDateState: (bestBeforeDateState: string) => void;
  bestBeforeDateCheckCount: number;
  setBestBeforeDateCheckCount: (bestBeforeDateCheckCount: number) => void;
  modelLoading: boolean;
  setScanning: (scanning: boolean) => void;
};

// 仮の型
type PackagingRecord = { id: number };

const packagingIds = (data: PackagingRecord[]) => data.map((d) => d.id);

const arrayMatch = (a: number[], b: number[]) => {
  const aSorted = a.slice().sort();
  const bSorted = b.slice().sort();
  return aSorted.every((val, index) => val === bSorted[index]);
};

const sortPackagingRecords = (
  records: PackagingRecord[],
  sortedIds: number[]
) =>
  records
    .slice()
    .sort((a, b) => sortedIds.indexOf(a.id) - sortedIds.indexOf(b.id));

const WrappingMainTable = ({
  selectedLineIndex,
  data,
  setEditData,
  recognizedCnt,
  setRecognizedCnt,
  popUp,
  setPopUp,
  messageKind,
  setMessageKind,
  getLatestWrappingData,
  casesPerCart,
  piecesPerBox,
  setBestBeforeDatesForModal,
  bestBeforeDateState,
  setBestBeforeDateState,
  bestBeforeDateCheckCount,
  setBestBeforeDateCheckCount,
  modelLoading,
  setScanning,
}: Props) => {
  if (!data?.lines[selectedLineIndex] || modelLoading)
    return (
      <S.CircularIconWrapperOuter>
        <S.CircularIconWrapper>
          <CircularProgress style={{ color: '#64b2f9' }} />
        </S.CircularIconWrapper>
      </S.CircularIconWrapperOuter>
    );

  const records = data.lines[selectedLineIndex].packagings;
  const [sortedPackagingIds, setSortedPackagingIds] = useState(
    packagingIds(records)
  );

  useEffect(() => {
    // 構成しているpackagingIdが変わらない場合は順序変更とみなして
    // sortedPackagingIdsのリセットを行わない
    if (arrayMatch(packagingIds(records), sortedPackagingIds)) return;

    setSortedPackagingIds(packagingIds(records));
  }, [records]);

  const handleSuccess = () => {
    setPopUp(true);
    setMessageKind('updateOrder');
    getLatestWrappingData();
  };

  const updateWrappingOrderMutation =
    useUpdateWrappingOrderMutation(handleSuccess);
  const moveItem = (dragIndex: number, targetIndex: number) => {
    const item = sortedPackagingIds[dragIndex];
    if (!item) return;

    const packagingIds = sortedPackagingIds.filter(
      (_, idx) => idx !== dragIndex
    );
    packagingIds.splice(targetIndex, 0, item);
    setSortedPackagingIds(packagingIds);
    updateWrappingOrderMutation.mutate({ packagingIds });
  };

  const sortedRecords = sortPackagingRecords(records, sortedPackagingIds);
  return (
    <>
      <S.Wrapper bgColor={valueOfLineBgColors[selectedLineIndex]}>
        <S.Header bgColor={valueOfLineBgColors[selectedLineIndex]}>
          {/* <S.Title>包装機製造数記録表</S.Title> */}
        </S.Header>
        <S.Content>
          <S.Table>
            <tbody>
              {/* テーブルヘッダー */}
              <S.THead bgColor={valueOfLineBgColors[selectedLineIndex]}>
                {TABLE_HEADER.map((t) => (
                  <S.THeadCell
                    key={t.key}
                    width={t.width}
                    wordBreak={t.wordBreak}
                    colSpan={t.colSpan}
                  >
                    {t.key}
                  </S.THeadCell>
                ))}
              </S.THead>
            </tbody>
          </S.Table>
          <S.Table>
            {sortedRecords.map((packagingRecord: Todo, i) => {
              return (
                <Row
                  packagingRecord={packagingRecord}
                  index={i}
                  selectedLineIndex={selectedLineIndex}
                  setEditData={setEditData}
                  data={data}
                  recognizedCnt={recognizedCnt}
                  setRecognizedCnt={setRecognizedCnt}
                  key={i}
                  moveItem={moveItem}
                  popUp={popUp}
                  setPopUp={setPopUp}
                  messageKind={messageKind}
                  setMessageKind={setMessageKind}
                  getLatestWrappingData={getLatestWrappingData}
                  casesPerCart={casesPerCart}
                  piecesPerBox={piecesPerBox}
                  setBestBeforeDatesForModal={setBestBeforeDatesForModal}
                  bestBeforeDateState={bestBeforeDateState}
                  setBestBeforeDateState={setBestBeforeDateState}
                  bestBeforeDateCheckCount={bestBeforeDateCheckCount}
                  setBestBeforeDateCheckCount={setBestBeforeDateCheckCount}
                  modelLoading={modelLoading}
                  setScanning={setScanning}
                />
              );
            })}
            <tr>
              <td colSpan={22}>
                <S.FlexTableDivRow
                  onClick={() => {
                    setEditData({
                      ...defaultEditData,
                      time: null,
                      lineName: data.lines[selectedLineIndex].factoryLineName,
                      lineIndex: selectedLineIndex,
                    });
                    setPopUp(false);
                    setBestBeforeDatesForModal([]);
                    setRecognizedCnt(0);
                    setBestBeforeDateState('');
                    setBestBeforeDateCheckCount(0);
                    setScanning(false);
                  }}
                >
                  <AddIcon />
                  追加
                </S.FlexTableDivRow>
              </td>
            </tr>
          </S.Table>
        </S.Content>
      </S.Wrapper>
    </>
  );
};

export default WrappingMainTable;
