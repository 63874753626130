import styled from 'styled-components';
import schedule_icon from '@assets/icons/schedule.svg';

export const Wrapper = styled.div`
  width: 100%;
  height: 100vh;
  padding: 16px 80px;
  background-color: ${(props) => props.theme.palette.beige};
  overflow-y: scroll;
  font-feature-settings: 'palt';
`;

export const GoBackWrapper = styled.div`
  width: 160px;
  display: flex;
  padding-bottom: 32px;
  cursor: pointer;
  color: ${(props) => props.theme.palette.blue};
  > span {
    padding-top: 8px;
    padding-left: 10px;
  }
`;

export const TabWrapper = styled.div`
  display: flex;
  width: 100vw;
`;

export const TabArea = styled.div`
  display: flex;
  background-color: #222e3e;
  cursor: pointer;
`;

export const Tab = styled.div<{
  active: boolean;
  tabCount: number;
}>`
  background-color: ${(props) =>
    props.active ? props.theme.palette.blue : '#F8F7F4'};
  padding-top: 16px;
  width: calc(100vw / ${(props) => props.tabCount});
  height: 64px;
  line-height: 30px;
  text-align: center;
  color: ${(props) =>
    props.active ? props.theme.palette.white : 'rgba(51, 51, 51, 0.5)'};
  font-weight: ${(props) => (props.active ? '900' : '400')};
  font-family: 'Noto Sans JP';
  font-style: normal;
  font-size: 32px;
  line-height: 32px;
  align-items: center;
  text-align: center;
`;

export const Input = styled.input<{
  width?: string;
}>`
  width: ${(props) => (props.width ? props.width : '100%')};
  height: 40px;
  color: ${(props) => props.theme.palette.baseBlack};
  font-size: 1rem;
  padding: 0 20px;
  text-align: left;
  outline: none;
  border-radius: 0.5rem;
  border: 1px solid rgba(0, 0, 0, 0.08);
  height: ${(props) => (props.height ? props.height : '')};
  background: ${(props) => props.theme.palette.white};
  ::-webkit-inner-spin-button {
    -webkit-appearance: none;
  }
`;

export const ShippingDestinationWrapper = styled.div`
  display: flex;
`;

export const ShippingDestinationSelectWrapper = styled.div`
  width: 100%;
`;

export const TimeInput = styled(Input)`
  cursor: pointer;
  ::-webkit-calendar-picker-indicator {
    background: none;
  }
  background-image: url(${schedule_icon});
  background-position: right 10px center;
  background-repeat: no-repeat;
  background-size: 15px;
  cursor: pointer;
  padding-right: 15px;
`;

export const ShippingGroupAreaDl = styled.dl`
  > dt {
    font-weight: 700;
    margin-bottom: 8px;
  }
  > dd {
    margin-bottom: 24px;
  }
`;

export const CustomerNameWrapper = styled.div`
  width: 92%;
  padding: 4px;
  display: flex;
  align-items: center;
  :first-child {
    margin-top: 15px;
  }
`;

export const CustomerName = styled.div`
  height: 30px;
`;

export const CodeWrapper = styled.div`
  width: 100px;
`;

export const CloseButton = styled.div`
  width: 40px;
  height: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const ButtonContainer = styled.div`
  position: fixed;
  width: 100vw;
  bottom: 0;
  padding: 22px 0;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #f8f7f4;
  & > button {
    width: 136px;
    &.disabled {
      background: #e0e0e0;
      border-color: #e0e0e0;
      color: #6b675c;
      cursor: initial;
    }
  }
`;

export const AddButton = styled.span`
  justify-content: center;
  align-items: center;
  dd {
    text-align: -webkit-right;
  }
  button {
    width: 64px;
    &.disabled {
      background: #e0e0e0;
      border-color: #e0e0e0;
      color: #6b675c;
      cursor: initial;
    }
  }
`;
