import { useEffect, useState } from 'react';
import { useHistory, useLocation, useParams } from 'react-router';
import * as S from './PickingByShippingEditScannerResult.styles';
import Close from '@assets/icons/mobile/close';
import ConfirmPopUp from '@components/modules/common/ConfirmPopUp/ConfirmPopUp';
import ErrorMsgPopUp from '@components/modules/common/ErrorMsgPopUp/ErrorMsgPopUp';
import PickingEditWrapper from '@components/modules/mobile/picking_by_shipping/PickingEditWrapper/PickingEditWrapper';
import useProducts from '@lib/mobile/common/hooks/useProducts';
import useStorerooms from '@lib/mobile/common/hooks/useStorerooms';
import useCases from '@lib/mobile/common/hooks/useCases';
import usePicking from '@lib/mobile/picking_by_shipping_edit/usePicking';
import { PickingState } from '@lib/mobile/picking_edit/type';
import { PRODUCT_KIND, Todo } from '@lib/common/type';
import {
  DetailsByProduct,
  NotCheckedInfo,
} from '@lib/mobile/picking_by_shipping/type';
import useUpdatePickingMutation from '@lib/mobile/picking_by_shipping_edit/useUpdatePickingMutation';
import useUpdatePickingStatusMutation from '@lib/mobile/picking_by_shipping_edit/useUpdatePickingStatusMutation';
import { sleep } from '@lib/mobile/picking/functions';

export const PickingByShippingEditScannerResultRoot = () => {
  // idはshipping_id
  const { id } = useParams<{ id: string }>();
  const { state } = useLocation<PickingState>();
  const history = useHistory();
  const [stateSearchChar, setSearchChar] = useState('');
  const [stateShippingDate, setShippingDate] = useState('');
  const [stateShippings, setShippings] = useState<Todo>([]);
  const [statePickingStatus, setStatePickingStatus] = useState<number[]>([]);
  const [stateScrollPosition, setScrollPosition] = useState(0);
  const [stateScannedProductName, setProductName] = useState('');
  const [stateSelectedStoreroomId, setSelectedStoreroomId] = useState<
    number | ''
  >('');
  // 変更を加えてチェック(更新)していない出荷先と商品
  const [notCheckedInfo, setNotCheckedInfo] = useState<NotCheckedInfo[]>([]);
  const [confirmMsg, setConfirmMsg] = useState('');
  const [updating, setUpdating] = useState(false);

  const [janCode, setJanCode] = useState('');
  // 商品検索エリア
  const [searchProductChar, setSearchProductChar] = useState('');
  const { data: products } = useProducts();
  const { data: cases } = useCases();
  const { picking: picking, refetch } = usePicking(
    id,
    state && state.stateSelectedStoreroomId
  );

  // チェック済以外表示チェックボックス
  const [completedCheckState, setCompletedCheckState] = useState(true);

  // スクロール固定エリアの高さ
  const [greenFixedAreaHeight, setGreenFixedAreaHeight] = useState('');
  const [errMsg, setErrMsg] = useState('');
  // 振分完了ボタン表示
  const [allocateCompleteBtn, setAllocateCompleteBtn] = useState(false);

  // 下位のコンポーネントに渡しているのがネストの深いオブジェクトなので、強制再レンダリングする必要がある
  const [, setToggleValue] = useState(false);
  const reRender = () => setToggleValue((prev) => !prev);

  const { data: storerooms } = useStorerooms(PRODUCT_KIND.PRODUCT);

  const handleUpdateSuccess = async () => {
    await sleep(150);
    refetch();
    setUpdating(false);
  };

  // 更新するpickingDetailId
  const [updatePickingDetailId, setUpdatePickingDetailId] = useState(0);

  const updatePickingMutation = useUpdatePickingMutation(
    updatePickingDetailId && updatePickingDetailId,
    handleUpdateSuccess,
    setErrMsg
  );

  // チェックボタン押下時更新処理
  const handleUpdate = (
    checkState: boolean,
    filteredNewData: DetailsByProduct[],
    allocateStatus: boolean
  ) => {
    const filteredInfo = notCheckedInfo.filter(
      (n: NotCheckedInfo) =>
        n.destinationName !== filteredNewData[0].destinationName &&
        n.productName === filteredNewData[0].name
    );
    setNotCheckedInfo(filteredInfo);
    // 合計>0ではないデータは更新対象から外す
    const onlyAmountPlus = filteredNewData[0].amountDetail.filter(
      (f) => f.amount > 0
    );

    if (allocateStatus) {
      updatePickingMutation?.mutate({
        storeroomId: state.stateSelectedStoreroomId,
        pickingDetail: {
          allocationCheckState: checkState,
          amountDetail: onlyAmountPlus,
        },
      });
    } else {
      updatePickingMutation?.mutate({
        storeroomId: state.stateSelectedStoreroomId,
        pickingDetail: {
          checkState: checkState,
          amountDetail: onlyAmountPlus,
        },
      });
    }
  };

  // 振分完了ボタン更新成功
  const handleUpdatePickingStatusSuccess = async () => {
    await sleep(150);
    refetch();
    reRender();
    setUpdating(false);
    setAllocateCompleteBtn(false);
    // stateClickedPickingStatusを0(未着手)へ戻る
    history.push(`/mobile/picking_by_shipping_edit/${id}`, {
      ...state,
      stateClickedPickingStatus: 0,
    });
  };

  // 振分完了ボタン更新
  const updatePickingStatusMutation = useUpdatePickingStatusMutation(
    id,
    handleUpdatePickingStatusSuccess,
    setErrMsg
  );

  const handleClickAllocateComplete = () => {
    updatePickingStatusMutation.mutate({
      id,
      handleUpdateSuccess,
      setErrMsg,
    });
  };

  useEffect(() => {
    if (state) {
      const {
        stateSearchChar,
        stateShippingDate,
        stateShippings,
        stateScrollPosition,
        stateJanCode,
        statePickingStatus,
        stateScannedProductName,
        stateSelectedStoreroomId,
        stateCompletedCheckState,
      } = state;
      setSearchChar(stateSearchChar);
      setShippingDate(stateShippingDate);
      setShippings(stateShippings);
      setJanCode(stateJanCode);
      setStatePickingStatus(statePickingStatus);
      setScrollPosition(stateScrollPosition);
      setProductName(stateScannedProductName);
      setSelectedStoreroomId(stateSelectedStoreroomId);
      setCompletedCheckState(stateCompletedCheckState);
    } else {
      setSearchChar('');
      setShippings(stateShippings);
      setScrollPosition(0);
      setStatePickingStatus([]);
      setProductName('');
      setSelectedStoreroomId('');
      setCompletedCheckState(true);
    }
  }, [state]);

  const pickingState = {
    stateSearchChar: stateSearchChar,
    stateShippingDate: stateShippingDate,
    stateShippings: stateShippings,
    stateScrollPosition: stateScrollPosition,
    stateJanCode: janCode,
    statePickingStatus: statePickingStatus,
    stateScannedProductName: stateScannedProductName,
    stateSelectedStoreroomId: stateSelectedStoreroomId,
    stateCompletedCheckState: completedCheckState,
    stateClickedPickingStatus: picking && picking.pickingStatus,
  };

  useEffect(() => {
    if (!updatePickingMutation?.isLoading) {
      refetch();
    }
  }, [updatePickingMutation?.isLoading]);

  useEffect(() => {
    if (document) {
      const clientW =
        document?.getElementById('green-fixed-area')?.clientHeight;
      clientW && setGreenFixedAreaHeight(clientW.toString());
    }
  }, [document]);

  const handleCloseScannerResult = () => {
    // +ボタン押下時、または入力内容に変更を加えた時
    if (notCheckedInfo.length > 0) {
      setConfirmMsg(
        '以下の変更したピッキング内容を保存せずに終了します。よろしいですか。'
      );
    } else {
      // バーコードスキャン結果の初期化;
      history.push(`/mobile/picking_by_shipping_edit/${id}`, {
        ...pickingState,
        stateJanCode: '',
        stateScannedProductName: '',
        stateScrollPositionForEdit: 0,
      });
    }
  };

  // 確認メッセージOK
  const handleOk = () => {
    setConfirmMsg('');
    setNotCheckedInfo([]);
    history.push(`/mobile/picking_by_shipping_edit/${id}`, {
      ...state,
      stateScannedProductName: '',
      stateScrollPositionForEdit: 0,
    });
  };
  // 確認メッセージキャンセル
  const handleCancel = () => {
    setConfirmMsg('');
  };

  return (
    <>
      <S.Wrapper>
        <S.Header id="green-fixed-area">
          <S.CameraMessage>
            <div onClick={() => handleCloseScannerResult()}>
              <Close isWhite={true} />
            </div>
            <div>商品コード読み取り（出荷先別）</div>
            <div />
          </S.CameraMessage>
        </S.Header>
        {/* ピッキング予定出荷先別情報 */}
        {products && storerooms && picking && (
          <PickingEditWrapper
            searchProductChar={searchProductChar}
            setSearchProductChar={setSearchProductChar}
            scannedJanCode={janCode}
            scannedProductName={stateScannedProductName}
            products={products}
            storerooms={storerooms}
            picking={picking}
            cases={cases}
            completedCheckState={completedCheckState}
            setCompletedCheckState={setCompletedCheckState}
            fromScannerResult={true}
            greenFixedAreaHeight={greenFixedAreaHeight}
            setUpdatePickingDetailId={setUpdatePickingDetailId}
            handleUpdate={handleUpdate}
            handleClickAllocateComplete={handleClickAllocateComplete}
            confirmMsg={confirmMsg}
            setConfirmMsg={setConfirmMsg}
            notCheckedInfo={notCheckedInfo}
            setNotCheckedInfo={setNotCheckedInfo}
            allocateCompleteBtn={allocateCompleteBtn}
            setAllocateCompleteBtn={setAllocateCompleteBtn}
            refetch={refetch}
            setErrMsg={setErrMsg}
            updating={updating}
            setUpdating={setUpdating}
          />
        )}
      </S.Wrapper>
      <ConfirmPopUp
        fromPc={false}
        confirmMsg={confirmMsg}
        notCheckedInfo={notCheckedInfo}
        zIndex={1004}
        handleOk={handleOk}
        handleCancel={handleCancel}
      />
      <ErrorMsgPopUp
        errMsg={errMsg}
        handleClose={() => setErrMsg('')}
        zIndex={1004}
      />
    </>
  );
};
