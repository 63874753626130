import { useMutation } from 'react-query';
import humps from 'humps';
import { AxiosError } from 'axios';
import { axiosInstance } from '@lib/pc/common/api/axiosConfig';
import { ErrorResponse } from '@lib/common/type';

const useUpdateCustomerAndShippingDestination = (
  id: string | number,
  handleSuccess: () => void,
  setErrMsg: (errMsg: string) => void
) => {
  return useMutation(
    async (params: number[]) => {
      return await axiosInstance().patch(
        `/api/v1/settings/customers_and_shipping_destinations/${id}`,
        humps.decamelizeKeys({ newShippingDestinationIds: params })
      );
    },
    {
      onSuccess: () => {
        handleSuccess();
      },
      onError: (error: AxiosError<ErrorResponse>) => {
        const message = error.response?.data.message || 'エラーが発生しました';
        setErrMsg(message);
      },
    }
  );
};

export default useUpdateCustomerAndShippingDestination;
