import { axiosInstance } from '@lib/pc/common/api/axiosConfig';
import { useQuery } from 'react-query';
import humps from 'humps';
import { Todo } from '@lib/common/type';

const useMaterialBasicInfo = () => {
  const { data, refetch } = useQuery(['material_basic_info'], async () => {
    const data = await axiosInstance().get(
      `/api/v1/settings/material_basic_info`
    );
    return data;
  });
  const materialBasicInfo = humps.camelizeKeys(data?.data) as Todo;

  return { materialBasicInfo, refetch };
};

export default useMaterialBasicInfo;
