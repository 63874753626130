import React from 'react';
import { WithHeader } from '@components/templates/WithHeader';
import { Heading } from '@components/elements/typographies/Heading';
import * as S from './ProductMasterCreateRoot.styles';
import ProductMasterCreateWrapper from '@components/modules/pc/settings/product_master_setting/ProductMasterCreate/ProductMasterCreateWrapper/ProductMasterCreateWrapper';

export const ProductMasterCreateRoot: React.FC = () => {
  return (
    <WithHeader>
      <S.Wrapper>
        <div>
          <S.HeadContainer>
            <S.FlexBaseline>
              <div>
                <S.ProductKind>完成品</S.ProductKind>
                <Heading tag="span" text="製品マスタ管理" fs="32" fw="lg" />
              </div>
            </S.FlexBaseline>
          </S.HeadContainer>
          <ProductMasterCreateWrapper />
        </div>
      </S.Wrapper>
    </WithHeader>
  );
};
