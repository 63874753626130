import { useMutation } from 'react-query';
import moment from 'moment';
import { axiosInstance } from '@lib/pc/common/api/axiosConfig';
import { Todo } from '@lib/common/type';

const useDuplicateSemiProductProductionResultMutation = (
  date: Date,
  selectedType: string,
  duplicateTargetDate: Date,
  factoryLineId: number,
  handleDuplicateSuccess: () => void,
  setErrMsg: (errMsg: string) => void
) => {
  return useMutation(
    async () => {
      return await axiosInstance().post(
        `/api/v1/semi_product_production_results/duplicate?production_date=${moment(
          date
        ).format(
          'YYYY-MM-DD'
        )}&factory_line_id=${factoryLineId}&selected_type=${selectedType}&selected_date=${moment(
          duplicateTargetDate
        ).format('YYYY-MM-DD')}`
      );
    },
    {
      onSuccess: () => {
        handleDuplicateSuccess();
      },
      onError: (error: Todo) => {
        setErrMsg(error.response.data.message || 'エラーが発生しました');
      },
    }
  );
};

export default useDuplicateSemiProductProductionResultMutation;
