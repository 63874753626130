import { ReactComponent as SearchIcon } from '@assets/icons/mobile/search.svg';
import * as S from './StoreroomSearchForm.styles';

const StoreroomSearchForm = ({
  searchStoreroom,
  setSearchStoreroom,
  fromPicking,
}: {
  searchStoreroom: string;
  setSearchStoreroom: (s: string) => void;
  fromPicking?: boolean;
}) => {
  return (
    <S.SearchConditionPanel>
      <SearchIcon />
      <S.SearchConditionInput
        placeholder={fromPicking ? '在庫室を検索' : '場所を検索'}
        value={searchStoreroom}
        onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
          setSearchStoreroom(event.target.value)
        }
      />
    </S.SearchConditionPanel>
  );
};
export default StoreroomSearchForm;
