import * as S from './DeleteConfirmModal.styled';
import { ReactComponent as DeleteIcon } from '@assets/icons/delete.svg';
import { Button } from '@components/elements/buttons/Button';

type Props = {
  delConfirmOpen: boolean;
  setDelConfirmOpen: (delConfirmOpen: boolean) => void;
  handleClickDelete: () => void;
};

const DeleteConfirmModal = ({
  delConfirmOpen,
  setDelConfirmOpen,
  handleClickDelete,
}: Props) => {
  return (
    <S.ModalWrapper delConfirmOpen={delConfirmOpen}>
      <S.ModalTextContainer>
        <span>削除後は元に戻すことができません。</span>
        <span>選択した商品の画像4枚を削除しますか？</span>
      </S.ModalTextContainer>
      <S.ModalButtonContainer>
        <Button
          padding="sm"
          outlined
          onClick={() => {
            setDelConfirmOpen(false);
          }}
        >
          キャンセル
        </Button>
        <Button
          padding="sm"
          icon={<DeleteIcon />}
          outlined
          onClick={() => {
            handleClickDelete();
          }}
        >
          削除
        </Button>
      </S.ModalButtonContainer>
    </S.ModalWrapper>
  );
};

export default DeleteConfirmModal;
