import React from 'react';
import moment from 'moment';
import * as S from './StockAllocationMainTable.styles';
import { ReactComponent as AddIcon } from '@assets/icons/add.svg';
import { ReactComponent as PencilIcon } from '@assets/icons/pencil.svg';
import LABEL_OF_AMOUNT_MAP from '@components/modules/common/LabelOfAmountMap/LabelOfAmountMap';
import { CircularProgress } from '@material-ui/core';
import { TABLE_HEADER, TABLE_HEADER_WITH_CASE_KIND } from './header';
import { seeFullComment } from '@lib/pc/common/functions';
import { Todo, ALLOCATION_TYPE } from '@lib/common/type';
import {
  StockAllocationEditData,
  initEditData,
} from '@lib/pc/stock_allocation/type';

type Props = {
  data: Todo;
  setEditData: (editData: StockAllocationEditData) => void;
  setPopUp: (popUp: boolean) => void;
  setEditModalOpen: (editModalOpen: boolean) => void;
  setScanning: (scanning: boolean) => void;
};

const StockALlocationMainTable = ({
  data,
  setEditData,
  setPopUp,
  setEditModalOpen,
  setScanning,
}: Props) => {
  const onClickPencil = (p: Todo) => {
    setEditData(p);
    setPopUp(false);
    setEditModalOpen(true);
  };

  const handleClickCell = (p: Todo, className?: string) => {
    if (className !== 'packaging-comment') {
      onClickPencil(p);
      setScanning(false);
    }
  };

  if (!data)
    return (
      <S.CircularIconWrapperOuter>
        <S.CircularIconWrapper>
          <CircularProgress style={{ color: '#64b2f9' }} />
        </S.CircularIconWrapper>
      </S.CircularIconWrapperOuter>
    );

  return (
    <>
      <S.Wrapper>
        <S.Content>
          <S.Table>
            <tbody>
              {/* テーブルヘッダー */}
              <S.THead>
                {data?.hasCase === true
                  ? TABLE_HEADER_WITH_CASE_KIND.map((t, index: number) => (
                      <S.THeadCell
                        key={t.key + index}
                        width={t.width}
                        wordBreak={t.wordBreak}
                        colSpan={t.colSpan}
                      >
                        {t.key}
                      </S.THeadCell>
                    ))
                  : TABLE_HEADER.map((t, index: number) => (
                      <S.THeadCell
                        key={t.key + index}
                        width={t.width}
                        wordBreak={t.wordBreak}
                        colSpan={t.colSpan}
                      >
                        {t.key}
                      </S.THeadCell>
                    ))}
              </S.THead>
              {data &&
                data?.stockAllocations.map((p: Todo, index: number) => (
                  <React.Fragment key={index + p.detailId + Math.random()}>
                    <S.TRow className="hover-effect">
                      {/**** 上段 ****/}
                      {/* 編集 */}
                      <S.PencilIcon onClick={() => handleClickCell(p)}>
                        <PencilIcon />
                      </S.PencilIcon>
                      {/* 製品名 */}
                      <S.TCell
                        width="12.75rem"
                        marginRight={'12px'}
                        productKind={p.productKind}
                        className="hover-effect"
                        onClick={() => handleClickCell(p)}
                      >
                        <div className="product-wrapper">
                          <div className="product-name-kind">
                            <div className="product-name">{p.productName}</div>
                          </div>
                          <div className="product-code">
                            {p.productCode && p.productCode}
                          </div>
                        </div>
                      </S.TCell>
                      {/* 払出先 */}
                      <S.TCell
                        width="12.75rem"
                        marginRight={'12px'}
                        className="hover-effect"
                        onClick={() => handleClickCell(p)}
                      >
                        {p.allocationType === ALLOCATION_TYPE.SALES
                          ? '店舗販売'
                          : p.allocationType === ALLOCATION_TYPE.DISCARD
                          ? '廃棄'
                          : p.allocationType === ALLOCATION_TYPE.CONSUMPTION
                          ? '消費'
                          : p.transferStoreroomName && p.transferStoreroomName}
                      </S.TCell>
                      {/* ケース種類 */}
                      {data?.hasCase === true && (
                        <S.TCell colSpan={1} onClick={() => handleClickCell(p)}>
                          {p.caseName && p.caseName}
                        </S.TCell>
                      )}

                      <S.TCell
                        className="display-quantity display-quantity__count--case"
                        onClick={() => handleClickCell(p)}
                      >
                        {/* 完成品 ケース */}
                        <div className="display-quantity__inner-wrap">
                          <div className="cell-content">
                            <div className="quantity">
                              <span className="num">{p.case}</span>
                              <span className="unit">ケース</span>
                            </div>
                            <div className="explanation">
                              x{p?.piecesPerBox ? p?.piecesPerBox : 1}
                              <span className="unit">
                                {p.labelOfAmount
                                  ? LABEL_OF_AMOUNT_MAP[p.labelOfAmount]
                                  : '個'}
                              </span>
                            </div>
                          </div>
                          <div className="symbol">+</div>
                        </div>
                      </S.TCell>
                      <S.TCell
                        className="display-quantity display-quantity__count--quantity"
                        onClick={() => handleClickCell(p)}
                      >
                        {/* 完成品 個 */}
                        <div className="display-quantity__inner-wrap">
                          <div className="cell-content">
                            <div className="quantity">
                              <span className="num">{p.piece}</span>
                              <span className="unit">
                                {p.labelOfAmount
                                  ? LABEL_OF_AMOUNT_MAP[p.labelOfAmount]
                                  : '個'}
                              </span>
                            </div>
                          </div>
                          <div className="symbol">=</div>
                        </div>
                      </S.TCell>
                      <S.TCell
                        className="display-quantity display-quantity__count--total"
                        onClick={() => handleClickCell(p)}
                      >
                        {/* 完成品 合計 */}
                        <div className="display-quantity__inner-wrap">
                          <div className="with-label__inner-wrap">
                            <div className="cell-content">
                              <div className="quantity">
                                <span className="title">合計</span>
                                <span className="num">{p.amount}</span>
                                <span className="unit">
                                  {p.labelOfAmount
                                    ? LABEL_OF_AMOUNT_MAP[p.labelOfAmount]
                                    : '個'}
                                </span>
                              </div>
                            </div>
                          </div>
                        </div>
                      </S.TCell>
                      {/* 賞味期限日 */}
                      <S.TCell colSpan={1} onClick={() => handleClickCell(p)}>
                        {p.bestBeforeDate &&
                          moment(p.bestBeforeDate).format('YY.MM.DD')}
                      </S.TCell>
                      {/* 備考 */}
                      <S.TCell
                        colSpan={1}
                        className="packaging-comment"
                        onClick={() => {
                          seeFullComment(index);
                          handleClickCell(p, 'packaging-comment');
                        }}
                      >
                        <div className="cell-content">{p.comment}</div>
                      </S.TCell>
                    </S.TRow>
                    <S.CommentBalloon
                      onClick={() => {
                        seeFullComment(index);
                      }}
                    >
                      <td
                        key={index + p.detailId + Math.random()}
                        id={`comment-full-${index}`}
                        colSpan={13}
                        className={'cell-content__balloon'}
                      >
                        <span className="comment-full__title">備考：</span>
                        <br />
                        {p.comment}
                      </td>
                    </S.CommentBalloon>
                  </React.Fragment>
                ))}
              <tr>
                <td colSpan={13}>
                  <S.FlexTableDivRow
                    onClick={() => {
                      setEditData({
                        ...initEditData,
                      });
                      setPopUp(false);
                      setEditModalOpen(true);
                      setScanning(false);
                    }}
                  >
                    <AddIcon />
                    追加
                  </S.FlexTableDivRow>
                </td>
              </tr>
            </tbody>
          </S.Table>
        </S.Content>
      </S.Wrapper>
    </>
  );
};

export default StockALlocationMainTable;
