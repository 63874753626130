import styled from 'styled-components';

export const Wrapper = styled.div<{
  open?: string;
}>`
  display: ${(props) => (props.open ? 'block' : 'none')};
  bottom: 24px;
  background: #ffffff;
  box-shadow: 0px 6px 10px rgba(0, 0, 0, 0.07), 0px 1px 18px rgba(0, 0, 0, 0.06),
    0px 3px 5px rgba(0, 0, 0, 0.1);
  border-radius: 8px;
  width: 22rem;
  height: 11rem;
  color: ${(props) => props.theme.palette.black};
  padding: 16px;
  z-index: 9999;
  line-height: 1.5;
  margin: 0;
  position: absolute;
  top: 50%;
  left: 50%;
  margin-right: -50%;
  transform: translate(-50%, -50%);
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`;

export const ButtonContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 2rem;
  margin-top: 1rem;
  button {
    width: 40%;
    height: 2.75rem;
    // &:nth-child(2) {
    //   border-color: ${(props) => props.theme.palette.error};
    //   color: ${(props) => props.theme.palette.error};
    // }
  }
`;

export const StopButton = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  gap: 10px;
  border-radius: 8px;
  padding: 0;
  font-weight: 700;
  background: ${(props) => props.theme.palette.white};
  color: ${(props) => props.theme.palette.error};
  border: ${(props) => `2px solid ${props.theme.palette.error}`};
`;
