import { ReactComponent as ChevronLeftIcon } from '@assets/icons/mobile/chevron_left.svg';
import { useHistory } from 'react-router-dom';
import * as S from './TitleBar.styles';
import { FC } from 'react';

type Props = {
  selectedTagIds: number[];
  setSelectedTagIds: (selectedTagIds: number[]) => void;
  setTagsOpen: (tagModal: boolean) => void;
  fromComponent?: string;
};

const TitleBar: FC<Props> = ({
  selectedTagIds,
  setSelectedTagIds,
  setTagsOpen,
  fromComponent,
}) => {
  const history = useHistory();

  const handleSelect = () => {
    setTagsOpen(false);
  };

  const handleClickBackButton = () => {
    setSelectedTagIds([]);
    setTagsOpen(false);
    if (fromComponent === 'materialsForStocks') {
      history.push(`/mobile/materials/for_stocks`);
    } else {
      history.push(`/mobile/products/for_stocks`);
    }
  };

  return (
    <S.Wrapper>
      <S.IconWrapper>
        <S.BackButton
          onClick={() => {
            handleClickBackButton();
          }}
        >
          <ChevronLeftIcon />
        </S.BackButton>
      </S.IconWrapper>
      <S.TitleText>タグの選択</S.TitleText>
      <S.SubmitButton
        active={selectedTagIds.length > 0}
        onClick={() => handleSelect()}
      >
        決定
      </S.SubmitButton>
    </S.Wrapper>
  );
};
export default TitleBar;
