import { FC, useEffect } from 'react';
import * as S from './SemiProductSelect.styles';
import type { Product, Todo, OptionType } from '@lib/common/type';
import ReactSelect from 'react-select';
import { StockAllocationScannedSemiProduct } from '@lib/pc/stock_allocation/type';
import { useDeviceOrientationAndSize } from '@lib/common/functions';
import { NO_OPTION_JAPANESE } from '@lib/pc/common/constants';

type Props = {
  products: Product[] | undefined;
  handleChangeProduct: (e: number) => void;
  value?: number | undefined;
  disabled?: boolean;
  width?: string | undefined;
  height?: string | undefined;
  setEditProductName: (editProductName: string) => void;
  marginLeft?: string | undefined;
  maxHeight?: string | undefined;
  scannedSemiProduct: StockAllocationScannedSemiProduct;
};

const SemiProductSelect: FC<Props> = ({
  products,
  handleChangeProduct,
  value,
  disabled,
  width,
  height,
  setEditProductName,
  marginLeft,
  maxHeight,
  scannedSemiProduct,
}: Props) => {
  const { deviceType } = useDeviceOrientationAndSize();
  const isDesktop = deviceType === 'desktop';

  const toReactSelectOptions = (
    options: { id: number; name: string; productCode: string }[]
  ) =>
    options?.map(({ id, name, productCode }) => ({
      value: id,
      label: name,
      code: productCode || '',
    }));

  const reactSelectOptions = products && toReactSelectOptions(products);
  const selectedOption =
    scannedSemiProduct?.productId &&
    reactSelectOptions &&
    reactSelectOptions.find(
      (option) => option.value === scannedSemiProduct.productId
    );
  const currentValue = reactSelectOptions?.find((o) => o.value === value);

  const filterOption = (
    option: { label: string; value: number | string; data: OptionType },
    inputValue: string
  ): boolean => {
    const { label, data } = option;
    const { code } = data;
    return (
      label.toLowerCase().includes(inputValue.toLowerCase()) ||
      code.toLowerCase().includes(inputValue.toLowerCase())
    );
  };

  useEffect(() => {
    if (currentValue) {
      setEditProductName(currentValue.label);
    } else if (scannedSemiProduct && scannedSemiProduct.productId !== null) {
      handleChangeProduct(scannedSemiProduct.productId);
    }
  }, [currentValue, selectedOption]);

  const styles = {
    control: (baseStyles: Todo) => ({
      ...baseStyles,
      minHeight: height ? height : '4rem',
      width: width ? width : '15.5rem',
      height: height ? height : '',
      borderRadius: '0.5rem',
      fontWeight: 400,
      textAlign: 'left',
      borderStyle: 'hidden',
      outline: '0.5px solid rgba(0,0,0,0.08)',
      cursor: 'pointer',
      backgroundColor: disabled ? '' : 'white',
      '@media (max-width: 599px)': {
        width: '16rem',
      },
    }),
    menu: (baseStyles: Todo) => ({
      ...baseStyles,
      top: '55px',
    }),
    singleValue: (baseStyles: Todo) => ({
      ...baseStyles,
      overflow: 'initial',
    }),
    option: (baseStyles: Todo) => ({
      ...baseStyles,
      textAlign: 'left',
      fontWeight: 400,
      fontSize: '15px',
    }),
    menuList: (baseStyles: Todo) => ({
      ...baseStyles,
      maxHeight: maxHeight ? maxHeight : '',
    }),
  };

  return (
    <S.Wrapper marginLeft={marginLeft}>
      {/* 製品名 */}
      <ReactSelect
        options={reactSelectOptions}
        onChange={(option) => {
          option && handleChangeProduct(option.value);
        }}
        value={currentValue ? currentValue : null}
        styles={styles}
        isDisabled={disabled}
        isSearchable={isDesktop}
        filterOption={filterOption}
        components={{
          IndicatorSeparator: () => null,
        }}
        noOptionsMessage={() => NO_OPTION_JAPANESE}
        placeholder="選択してください"
      />
    </S.Wrapper>
  );
};

export default SemiProductSelect;
