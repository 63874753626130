import styled from 'styled-components';
import { Link } from 'react-router-dom';

export const Product = styled(Link)<{ index: number }>`
  display: flex;
  justify-content: space-between;
  padding: 1.25rem 1rem;
  border-bottom: 2px solid #f2f2f2;
  background: ${(props) =>
    props.index % 2 == 0
      ? props.theme.palette.white
      : props.theme.palette.paleYellow};

  &:hover {
    text-decoration: none;
    opacity: 1;
  }
`;

export const ProductInfo = styled.div`
  color: ${(props) => props.theme.palette.blue};
`;

export const ProductName = styled.p`
  font-weight: bold;
`;

export const ProductCode = styled.div`
  margin-top: 0.5rem;
  font-size: 0.75rem;
`;

export const CameraInfo = styled.div`
  color: ${(props) => props.theme.palette.khaki};
  font-size: 0.75rem;
`;

export const StockDateIcon = styled.div`
  text-align: -webkit-right;
`;

export const ShootingDate = styled.p`
  margin-top: 1.5rem;
  text-align: right;
  font-weight: bold;
`;

export const UpdateDate = styled.span`
  margin-right: 8px;
  font-weight: 400;
`;
