import { axiosInstance } from '@lib/mobile/common/api/axiosConfig';
import { useQuery } from 'react-query';
import humps from 'humps';
// import { Camera } from '@lib/mobile/products_photograph/type';
import { Todo } from '@lib/common/type';

const useCamerasProducts = (cameraId: string) => {
  return useQuery(['cameras_products', cameraId], async () => {
    const { data } = await axiosInstance().get(`/api/v1/cameras/${cameraId}`);
    return humps.camelizeKeys(data.products) as [Todo];
  });
};

export default useCamerasProducts;
