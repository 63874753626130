import styled from 'styled-components';

export const Wrapper = styled.div<{
  open?: string;
  fromPc?: boolean;
  width?: string;
}>`
  display: ${(props) => (props.open ? 'block' : 'none')};
  bottom: 24px;
  background: ${(props) => props.theme.palette.white};
  position: fixed;
  box-shadow: 0px 6px 10px rgba(0, 0, 0, 0.07), 0px 1px 18px rgba(0, 0, 0, 0.06),
    0px 3px 5px rgba(0, 0, 0, 0.1);
  border-radius: 8px;
  width: ${(props) =>
    props.width ? props.width : props.fromPc ? '22rem' : '85%'};
  left: ${(props) => (props.fromPc ? '50%' : '8%')};
  transform: ${(props) => (props.fromPc ? 'translate(-50%, -50%)' : '')};
  color: ${(props) => props.theme.palette.black};
  padding: 16px 18px;
  z-index: 2;
  white-space: pre-wrap;
  line-height: 1.5;
`;

export const ButtonContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 1rem;
  margin-top: 1rem;
  button {
    width: 30%;
    height: 2.75rem;
    &:nth-child(2) {
      border-color: ${(props) => props.theme.palette.error};
      color: ${(props) => props.theme.palette.error};
    }
  }
`;
