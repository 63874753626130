import { FC } from 'react';
import moment from 'moment';
import {
  VictoryAxis,
  VictoryBar,
  VictoryChart,
  VictoryContainer,
  VictoryGroup,
  VictoryLine,
  VictoryScatter,
} from 'victory';
import { DividerWithDot } from '@components/elements/dividers/DividerWithDot';
// import { DividerWithSquare } from '@components/elements/dividers/DividerWithSquare/DividerWithSquare';
import { ModalPaper } from '@components/modules/common/pc/ModalPaper';
import {
  convertShippingArrToBarData,
  convertDemandForecastArrToBarData,
  convertPredictionLowArrToBarData,
  convertPredictionHighArrToBarData,
  convertNumberOfGroundsToBarData,
  convertDemandForecastOrShippingAmountToBarData,
  addComma,
  generateDateArrayForModal,
  changeWidth,
} from '@lib/pc/production_schedule/functions';
import * as S from '../Graph.styles';
import { ReactComponent as SNSIcon } from '@assets/icons/exclamation_mark.svg';
import { CircularProgress } from '@material-ui/core';
import type { ResponseDemandForecast } from '@lib/pc/production_schedule/type';
import type { Todo } from '@lib/common/type';

const _convertDataToSNSScatter = (snsArr: Todo[], arr2: []) => {
  return snsArr.map((a: Todo, i: number) => {
    const amountArr: number[] = arr2.map(({ amount }) => amount);

    return {
      x: i,
      y: a ? Math.max(...amountArr) : null,
      data: a,
    };
  });
};

type Props = {
  data: Todo;
  date: Todo;
  setDate: (date: Todo) => void;
  setSelectedSNSItem: (value: Todo) => void;
};

/*  COMMENT(220314):生産計画の根拠グラフ */
const PredictionGraph: FC<Props> = ({
  data,
  date,
  setDate,
  setSelectedSNSItem,
}: Props) => {
  const _SNSScatterPoint = ({ x, y, datum }: Todo) => {
    return (
      <g
        onClick={() =>
          setSelectedSNSItem({
            ...datum.data,
            product: data.name,
            index: datum.x,
          })
        }
        style={{ zIndex: 99, cursor: 'pointer' }}
      >
        <foreignObject x={x} y={y} width="40" height="40">
          <div className="graph-tooltip">
            <SNSIcon />
          </div>
        </foreignObject>
      </g>
    );
  };

  const indexNum = Number(date.format('DD') - 1);
  return (
    <ModalPaper
      heading1="生産計画の根拠"
      heading2="を確認"
      date={date}
      setDate={setDate}
    >
      <S.GraphControlHead>
        <S.DescHeader>
          出荷数が既に確定している場合：「出荷確定数」を根拠として生産計画を算出。
          <br />
          まだ出荷数が確定していない場合：「その時点での最大の数値（出荷予測数/特売数/受注数を元に判断）」を根拠として生産計画を算出。
          <br />
          欠品リスクを可能な限り回避しています。
        </S.DescHeader>
      </S.GraphControlHead>
      {data ? (
        <S.GraphContainer>
          <S.LeftHeadContainer>
            <S.LeftLabelContainer>
              <S.LeftGraphLabelContainer>
                <S.GraphLeftLabelContainer>
                  <div>
                    <DividerWithDot disableDot ThinLine borderColor="#0A68F4" />
                  </div>
                  <S.LeftGraphTextContainer>
                    <div style={{ marginBottom: '1rem' }}>需要予測（幅）</div>
                  </S.LeftGraphTextContainer>
                </S.GraphLeftLabelContainer>
                <S.GraphLeftLabelContainer>
                  <div>
                    <DividerWithDot borderColor="#0A68F4" />
                  </div>
                  <S.LeftGraphTextContainer>
                    <div>需要予測数</div>
                    <span>
                      完成品の過去の出荷数量を元
                      <br />
                      に算出した、予測した所要量
                    </span>
                  </S.LeftGraphTextContainer>
                </S.GraphLeftLabelContainer>
                {/* <S.GraphLeftLabelContainer>
                  <div>
                    <DividerWithDot dotColor="#C74141" borderColor="#0A68F4" />
                  </div>
                  <S.LeftGraphTextContainer>
                    <div>出荷予測数+特売数</div>
                    <span>特売情報の入力がある日は、その数が足される。</span>
                  </S.LeftGraphTextContainer>
                </S.GraphLeftLabelContainer> */}
                {/* <S.GraphLeftLabelContainer>
                  <div>
                    <DividerWithSquare borderColor="#AF28C5" />
                  </div>
                  <S.LeftGraphTextContainer>
                    <div>受注数(出荷確定数)</div>
                    <span>
                      受注〆切日時が過ぎた時点
                      <br />
                      で確定する、確定した受注
                      <br />
                      数量。これ以上増えない。
                      <br />
                      特売情報も含む。
                    </span>
                  </S.LeftGraphTextContainer>
                </S.GraphLeftLabelContainer> */}
                {/* TODO: 一旦非表示、sales実装後戻り */}
                {/* <S.GraphLeftLabelContainer>
                  <div>
                    <DividerWithSquare disableBorder borderColor="#AF28C5" />
                  </div>
                  <S.LeftGraphTextContainer>
                    <div>一部受注確定分</div>
                    <span>
                      確定受注が入ったタイミングで都度合算されていく数量。受注〆切日時前なのでまだ増える可能性あり。
                    </span>
                  </S.LeftGraphTextContainer>
                </S.GraphLeftLabelContainer> */}
              </S.LeftGraphLabelContainer>
              <div>
                <VictoryChart
                  domainPadding={{ x: 50 }}
                  width={120}
                  height={320}
                  padding={{ top: 50, bottom: 0, right: 0, left: 0 }}
                  containerComponent={<VictoryContainer responsive={false} />}
                >
                  <VictoryAxis
                    width={130}
                    height={400}
                    dependentAxis
                    offsetX={120}
                    standalone
                    style={{
                      axisLabel: {
                        fontSize: 16,
                        padding: 30,
                        zIndex: 100,
                      },
                    }}
                  />
                  <VictoryGroup
                    groupComponent={<g display="none" />}
                    offset={20}
                  >
                    {/* グラフ左のY軸の値 */}
                    <VictoryBar
                      barWidth={16}
                      data={convertDemandForecastOrShippingAmountToBarData(
                        data.demandForecasts
                      )}
                      style={{ data: { display: 'none' } }}
                    />
                  </VictoryGroup>
                </VictoryChart>
              </div>
            </S.LeftLabelContainer>
            <div>
              <S.LeftTableEmptyCell />
              {/* 出荷予測数 */}
              <S.LeftTableHeadCell bg={'#F8F7F4'}>
                <S.GraphLeftLabelContainer>
                  <div>
                    <DividerWithDot borderColor="#0A68F4" />
                  </div>
                  <S.LeftGraphTextContainer>
                    <div>需要予測数</div>
                  </S.LeftGraphTextContainer>
                </S.GraphLeftLabelContainer>
              </S.LeftTableHeadCell>
              {/* 出荷予測数+特売数 */}
              {/* <S.LeftTableHeadCell bg={'#F8F7F4'}>
                <S.GraphLeftLabelContainer>
                  <div>
                    <DividerWithDot dotColor="#C74141" borderColor="#0A68F4" />
                  </div>
                  <S.LeftGraphTextContainer>
                    <div>出荷予測数+特売数</div>
                  </S.LeftGraphTextContainer>
                </S.GraphLeftLabelContainer>
              </S.LeftTableHeadCell> */}
              {/* <S.LeftTableHeadCell bg="#F8F7F4">
                <S.GraphLeftLabelContainer>
                  <div>
                    <DividerWithSquare borderColor="#AF28C5" />
                  </div>
                  <S.LeftGraphTextContainer>
                    <div>受注数(出荷確定数)</div>
                  </S.LeftGraphTextContainer>
                </S.GraphLeftLabelContainer>
              </S.LeftTableHeadCell> */}
            </div>
          </S.LeftHeadContainer>
          <S.ScrollableContainer>
            {/* COMMENT:交互背景は時間かかりそう
              https://github.com/FormidableLabs/victory/issues/1601 */}
            <VictoryChart
              domainPadding={{ x: 44 }}
              width={changeWidth(data.numberOfDaysParamsMonth)}
              height={320}
              padding={{ top: 50, bottom: 0, right: 0, left: 40 }}
              containerComponent={<VictoryContainer responsive={false} />}
            >
              <VictoryAxis
                //x
                style={{
                  axis: { stroke: '#F8F7F4' },
                  ticks: { stroke: 'white' },
                }}
              />
              <VictoryAxis
                //y
                dependentAxis
                style={{
                  axis: { display: 'none' },
                  grid: { stroke: '#E7E2DD' },
                  tickLabels: {
                    fontSize: 0,
                    padding: 0,
                    stroke: '#E7E2DD',
                  },
                }}
              />
              {/* 緑の棒グラフ：出荷確定数と出荷予測数、出荷予測数+特売数の中で最も大きいものが棒グラフになる仕様 */}
              <VictoryGroup offset={20}>
                <VictoryBar
                  style={{
                    data: {
                      fill: '#B0F1D7',
                    },
                  }}
                  barWidth={30}
                  data={convertNumberOfGroundsToBarData(data.demandForecasts)}
                />
              </VictoryGroup>

              {/* 予測幅 */}
              <VictoryLine
                style={{
                  data: {
                    stroke: '#0A68F4',
                    strokeDasharray: '4, 2',
                    zIndex: 100,
                    strokeWidth: 1,
                  },
                }}
                data={convertPredictionHighArrToBarData(data.demandForecasts)}
              />
              <VictoryLine
                style={{
                  data: {
                    stroke: '#0A68F4',
                    strokeDasharray: '4, 2',
                    zIndex: 100,
                    strokeWidth: 1,
                  },
                }}
                data={convertPredictionLowArrToBarData(data.demandForecasts)}
              />
              {/* 予測幅 */}

              {/* 出荷予測数/ラインチャート */}
              <VictoryGroup>
                <VictoryLine
                  data={convertDemandForecastArrToBarData(data.demandForecasts)}
                  // 特売情報
                  // data={convertTokubaiAndBarData(
                  //   data.prediction[selectedItem.unit].productData,
                  //   data.tokubai[selectedItem.unit].productData
                  // )}
                  style={{
                    data: {
                      stroke: '#0A68F4',
                      strokeDasharray: '8, 2',
                      zIndex: 100,
                    },
                  }}
                />
                <VictoryScatter
                  data={convertDemandForecastArrToBarData(data.demandForecasts)}
                  size={4}
                  style={{
                    data: {
                      fill: '#ffffff',
                      stroke: '#0A68F4',
                      strokeWidth: 3,
                    },
                  }}
                />
                {/* 特売情報 */}
                {/* <VictoryScatter
                  data={convertDemandForecastTokubaiArrToBarData(
                    data.demandForecasts
                  )}
                  size={4}
                  style={{
                    data: {
                      fill: '#ffffff',
                      stroke: '#C74141',
                      strokeWidth: 3,
                    },
                  }}
                /> */}
              </VictoryGroup>
              {/* 出荷予測数/ラインチャート */}

              {/* 受注数確定数/ラインチャート  */}
              <VictoryGroup
                data={convertShippingArrToBarData(data.demandForecasts)}
              >
                <VictoryLine
                  style={{
                    data: {
                      stroke: '#AF28C5',
                      zIndex: 100,
                    },
                  }}
                />
                <VictoryScatter
                  size={4}
                  symbol="square"
                  style={{
                    data: {
                      fill: '#ffffff',
                      stroke: '#AF28C5',
                      strokeWidth: 3,
                    },
                  }}
                />
              </VictoryGroup>
              {/* 受注数確定数/ラインチャート  */}

              {/* SNSアイコン（20220916一旦非表示） */}
              {/* <VictoryScatter
                data={convertDataToSNSScatter(
                  data.snsData[selectedItem.unit].productData,
                  data.prediction[selectedItem.unit].productData
                )}
                dataComponent={<SNSScatterPoint />}
              /> */}
              {/* SNSアイコン */}
            </VictoryChart>
            <S.TableWrapper>
              <S.TableRow>
                {generateDateArrayForModal(
                  data.numberOfDaysParamsMonth,
                  date
                ).map((day: Todo, i: number) => {
                  return (
                    <S.TableHeadCell
                      key={i}
                      isToday={i === indexNum}
                      dayOfWeek={moment(day.productionDate).format('ddd')}
                    >
                      {i === 0 ? (
                        <div>
                          {moment(day.productionDate).format('MM/DD')}
                          <span>
                            {moment(day.productionDate).format('(ddd)')}
                          </span>
                        </div>
                      ) : (
                        <div>
                          {moment(day.productionDate).format('DD')}
                          <span>
                            {moment(day.productionDate).format('(ddd)')}
                          </span>
                        </div>
                      )}
                    </S.TableHeadCell>
                  );
                })}
              </S.TableRow>

              {/* 出荷予測数の表 */}
              <S.TableRow>
                {data.demandForecasts.map(
                  (p: ResponseDemandForecast, i: number) => {
                    return (
                      <S.TableCell
                        key={i}
                        isToday={i === indexNum}
                        isMax={
                          !!p.demandForecastAmount &&
                          p.demandForecastAmount >= p.shippingAmount
                        }
                        marginTop
                      >
                        {p.demandForecastAmount
                          ? addComma(p.demandForecastAmount)
                          : '-'}
                      </S.TableCell>
                    );
                  }
                )}
              </S.TableRow>
              {/* 出荷予測数+特売数の表 */}
              {/* <S.TableRow>
                {data.demandForecasts.map(
                  (p: ResponseDemandForecast, i: number) => {
                    return (
                      <S.TableCell key={i} isToday={i === indexNum}>
                        {p.demandForecastAmount
                          ? addComma(p.demandForecastAmount)
                          : '-'}
                      </S.TableCell>
                    );
                  }
                )}
              </S.TableRow> */}
              {/* 受注数量（確定分）【出荷確定数】の表 */}
              {/* <S.TableRow>
                {data.demandForecasts.map(
                  (p: ResponseDemandForecast, i: number) => {
                    return (
                      <S.TableCell
                        key={i}
                        // isToday={i === indexNum}
                        marginTop
                        isMax={p.shippingAmount > p.demandForecastAmount}
                      >
                        {p.shippingAmount ? addComma(p.shippingAmount) : '-'}
                      </S.TableCell>
                    );
                  }
                )}
              </S.TableRow> */}
            </S.TableWrapper>
          </S.ScrollableContainer>
        </S.GraphContainer>
      ) : (
        <S.GraphContainer>
          <S.CircularIconWrapper top={'33rem'}>
            <CircularProgress style={{ color: '#64b2f9' }} />
          </S.CircularIconWrapper>
        </S.GraphContainer>
      )}
      {data && data.products.length !== 0 && (
        <S.DescFooter>
          ※上記の、<span>半製品</span>の「
          {data.semiProductName}
          」の予測数量は、
          <span>下記の完成品</span>
          の予測数量を合算し、その数値を元に算出されています。
          <br />
          {data.products.map((name: string) => {
            return ['「', name, '」'].join('');
          })}
        </S.DescFooter>
      )}
    </ModalPaper>
  );
};

export default PredictionGraph;
