import styled from 'styled-components';

export const Wrapper = styled.div`
  background-color: #f2f2f2;
`;

export const Header = styled.div`
  position: fixed;
  width: 100vw;
  padding: 0.625rem 1rem;
  background: ${(props) => props.theme.palette.beige};
  border-bottom: 1px solid ${(props) => props.theme.palette.sand};
`;

export const TagList = styled.div`
  padding-top: 7.25rem;
`;
