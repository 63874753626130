import { FC } from 'react';
import * as S from './AddPickingProductModal.style';
import { Button } from '@components/elements/buttons/Button';
import ProductSelect from '@components/modules/common/ProductSelect/ProductSelect';
import PickingByShippingDetailHeader from '../PickingByShippingDetaiHeader/PickingByShippingDetaiHeader';
import { Product, Todo } from '@lib/common/type';
import ShippingDestinationSelect from '../ShippingDestinationSelect/ShippingDestinationSelect';

type Props = {
  products: Product[] | undefined;
  selectedProductId: number;
  setSelectedProductId: (selectedProductId: number) => void;
  selectedShippingId: number;
  setSelectedShippingId: (selectedShippingId: number) => void;
  changeProductsAmount: number | string;
  setChangeProductsAmount: (changeProductsAmount: number | string) => void;
  selectedProduct: string;
  selectedPlace: string;
  shippingStop: boolean;
  branchData: Todo[];
  handleUpdateProductAmount: () => void;
  handleCancel: () => void;
  setOpenAddPickingProductModal: () => void;
  setPopUpProductName: (popUpProductName: string) => void;
  setPopUpShippingName: (popUpShippingName: string) => void;
};

const AddPickingProductModal: FC<Props> = ({
  products,
  selectedProductId,
  setSelectedProductId,
  selectedShippingId,
  setSelectedShippingId,
  changeProductsAmount,
  setChangeProductsAmount,
  selectedProduct,
  branchData,
  handleUpdateProductAmount,
  handleCancel,
  setOpenAddPickingProductModal,
  setPopUpProductName,
  setPopUpShippingName,
}: Props) => {
  const handleChangeProduct = (e: number) => {
    setSelectedProductId(e);
  };

  const handleSelectShipping = (e: number) => {
    setSelectedShippingId(e);
  };

  const handleChangeAmount = (event: React.ChangeEvent<HTMLInputElement>) => {
    const value = event.target.value;
    setChangeProductsAmount(Number(value));
  };

  return (
    <S.Wrapper className="add-picking-product-modal" open={selectedProduct}>
      <PickingByShippingDetailHeader
        setOpenAddPickingProductModal={setOpenAddPickingProductModal}
      />
      <S.InnerWrapper>
        <S.Product>
          <S.ProductSpan>製品名</S.ProductSpan>
          <ProductSelect
            width="22rem"
            products={products}
            handleChangeProduct={handleChangeProduct}
            value={selectedProductId}
            setPopUpProductName={setPopUpProductName}
            maxHeight="500px"
            searchable={true}
          />
        </S.Product>
        <S.ShippingDestination>
          <S.ShippingDestinationSpan>出荷先</S.ShippingDestinationSpan>
          <ShippingDestinationSelect
            branchData={branchData}
            handleChangeShipping={handleSelectShipping}
            value={selectedShippingId}
            setPopUpShippingName={setPopUpShippingName}
          />
        </S.ShippingDestination>
        <S.Amount>
          <S.AmountSpan>個数</S.AmountSpan>
          <div>
            <S.ChangeAmountInput
              onChange={handleChangeAmount}
              value={changeProductsAmount || ''}
              type="text"
              inputMode="numeric"
              id="productAmountInput"
            />
          </div>
        </S.Amount>
      </S.InnerWrapper>
      <S.ButtonContainer>
        <Button borderWidth={1} outlined={true} onClick={handleCancel}>
          キャンセル
        </Button>
        <Button borderWidth={1} onClick={handleUpdateProductAmount}>
          保存
        </Button>
      </S.ButtonContainer>
    </S.Wrapper>
  );
};

export default AddPickingProductModal;
