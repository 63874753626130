import React from 'react';
import { useParams } from 'react-router-dom';
import { WithHeader } from '@components/templates/WithHeader';
import { Heading } from '@components/elements/typographies/Heading';
import * as S from './ShippingDestinationMasterEdit.styles';
import ShippingDestinationEditWrapper from '@components/modules/pc/settings/shipping_destination_setting/ShippingDestinationEdit/ShippingDestinationEditWrapper/ShippingDestinationEditWrapper';
import useShippingDestination from '@lib/pc/settings/shipping_destination_setting/useShippingDestination';

export const ShippingDestinationMasterEditRoot: React.FC = () => {
  const { id, timestamp } = useParams<{ id: string; timestamp: string }>();
  const { shippingDestination, refetch } = useShippingDestination(id);

  return (
    <WithHeader>
      <S.Wrapper>
        <div>
          <S.HeadContainer>
            <S.FlexBaseline>
              <div>
                <Heading tag="span" text="出荷先の編集" fs="32" fw="lg" />
              </div>
            </S.FlexBaseline>
          </S.HeadContainer>
          <ShippingDestinationEditWrapper
            shippingDestination={shippingDestination}
            refetch={refetch}
            timestamp={timestamp}
          />
        </div>
      </S.Wrapper>
    </WithHeader>
  );
};
