import { useEffect, useState } from 'react';
import * as S from './CustomerContents.styles';
import ChevronLeft from '@assets/icons/chevron_left';
import { Button } from '@components/elements/buttons/Button';
import ErrorMsgPopUp from '@components/modules/common/ErrorMsgPopUp/ErrorMsgPopUp';
import Overlay from '@components/elements/backdrops/Overlay/Overlay';
import { createdCustomerData } from '@components/modules/pc/settings/customer_setting/CustomerCreate/default';
import { Todo } from '@lib/common/type';
import { CustomerEdit } from '@lib/pc/settings/customer_edit/type';
import useUpdateCustomerMutation from '@lib/pc/settings/customer_edit/useUpdateCustomer';
import useCreateCustomerMutation from '@lib/pc/settings/customer_create/useCreateCustomer';

type Props = {
  newData: CustomerEdit;
  setNewData: (newData: CustomerEdit) => void;
  updating: boolean;
  setUpdating: (updating: boolean) => void;
  enableToSave: boolean;
  setEnableToSave: (enableToSave: boolean) => void;
  setIsChanged: (isChanged: boolean) => void;
  onClickGoBack: () => void;
  refetch?: () => void;
  setUpdatePopUp: (updatePopUp: boolean) => void;
  createdData?: Todo;
  setCreatedData?: (createdData: Todo) => void;
};

const CustomerContents = ({
  newData,
  setNewData,
  updating,
  setUpdating,
  enableToSave,
  setEnableToSave,
  setIsChanged,
  onClickGoBack,
  refetch,
  setUpdatePopUp,
  setCreatedData,
}: Props) => {
  const [errMsg, setErrMsg] = useState('');

  // 登録ボタンか保存ボタンか判定
  const createButton = newData?.id === 0 ? true : false;

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const data = { ...newData, [e.target.name]: e.target.value };
    setNewData(data);
    setIsChanged(true);
    setEnableToSave(true);
  };

  const handleUpdateSuccess = () => {
    refetch && refetch();
    setUpdatePopUp(true);
    setIsChanged(false);
    setUpdating(false);
  };

  const updateCustomerMutation = useUpdateCustomerMutation(
    newData?.id,
    handleUpdateSuccess,
    setErrMsg
  );

  const [createdCustomer, setCreatedCustomer] = useState(createdCustomerData);

  const handleCreatedCustomer = (customer: typeof createdCustomerData) => {
    setCreatedCustomer(customer);
  };

  // ***********************
  // [保存ボタン]押下、更新処理
  // ***********************
  const handleSubmit = () => {
    setUpdating(true);
    updateCustomerMutation.mutate(newData);
  };

  const createCustomerMutation = useCreateCustomerMutation(
    handleCreatedCustomer,
    handleUpdateSuccess,
    setErrMsg
  );

  // ***********************
  // [登録ボタン]押下、作成処理
  // ***********************
  const handleSubmitCreate = () => {
    setUpdating(true);
    createCustomerMutation.mutate(newData);
  };

  useEffect(() => {
    if (createdCustomer?.id !== 0) {
      setNewData({
        ...newData,
        id: createdCustomer?.id,
        code: createdCustomer?.code,
        name: createdCustomer?.name,
        shortName: createdCustomer?.shortName,
        nameKana: createdCustomer?.nameKana,
      });
    }
    setCreatedData && setCreatedData(createdCustomer);
  }, [createdCustomer]);

  return (
    <>
      <S.Wrapper>
        <S.GoBackWrapper onClick={onClickGoBack}>
          <ChevronLeft isBlue={true} />
          <span>一覧画面へ戻る</span>
        </S.GoBackWrapper>

        <S.ShippingGroupAreaDl>
          <dt>得意先コード</dt>
          <dd>
            <S.Input
              type="text"
              inputMode="numeric"
              name="code"
              id="code"
              autoComplete="off"
              value={newData?.code ? newData.code : ''}
              onChange={handleChange}
            />
          </dd>
          <dt>得意先名</dt>
          <dd>
            <S.Input
              type="text"
              inputMode="numeric"
              name="name"
              id="name"
              autoComplete="off"
              value={newData?.name ? newData.name : ''}
              onChange={handleChange}
            />
          </dd>
          <dt>得意先名(ふりがな)</dt>
          <dd>
            <S.Input
              type="text"
              name="nameKana"
              id="nameKana"
              value={newData?.nameKana ? newData.nameKana : ''}
              onChange={handleChange}
            />
          </dd>
          <dt>得意先名(略称)</dt>
          <dd>
            <S.Input
              type="text"
              name="shortName"
              id="shortName"
              value={newData?.shortName ? newData.shortName : ''}
              onChange={handleChange}
            />
          </dd>
        </S.ShippingGroupAreaDl>
      </S.Wrapper>
      <S.ButtonContainer>
        {createButton ? (
          <Button
            children={updating ? '登録中...' : '登録'}
            disabled={updating || !enableToSave}
            onClick={handleSubmitCreate}
          />
        ) : (
          <Button
            children={updating ? '保存中...' : '保存'}
            disabled={updating || !enableToSave}
            onClick={handleSubmit}
          />
        )}
      </S.ButtonContainer>
      <ErrorMsgPopUp
        errMsg={errMsg}
        handleClose={() => {
          setErrMsg('');
          setUpdating(false);
        }}
        fromPc={true}
        zIndex={9999}
      />
      {errMsg && (
        <Overlay
          dark
          handleClick={() => {
            setErrMsg('');
            setUpdating(false);
          }}
        />
      )}
    </>
  );
};

export default CustomerContents;
