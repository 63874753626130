import { FC } from 'react';
import type { OptionType, Todo } from '@lib/common/type';
import ReactSelect from 'react-select';
import { useDeviceOrientationAndSize } from '@lib/common/functions';
import { NO_OPTION_JAPANESE } from '@lib/pc/common/constants';

type Props = {
  semiProducts: Todo[] | undefined;
  value: number | undefined;
  setSelectedSemiProductId: (selectedProcessId: number) => void;
  setSelectedSemiProductName: (selectedName: string) => void;
  setSelectedSemiProductLabelOfAmount: (
    selectedSemiProductLabelOfAmount: string
  ) => void;
  onChange?: (selectedCaseId: number, index?: number) => void;
  disabled?: boolean;
  marginTop?: string | undefined;
};

const SemiProductSelect: FC<Props> = ({
  semiProducts,
  value,
  setSelectedSemiProductId,
  setSelectedSemiProductName,
  setSelectedSemiProductLabelOfAmount,
  onChange,
  disabled,
  marginTop,
}: Props) => {
  const { deviceType } = useDeviceOrientationAndSize();
  const isDesktop = deviceType === 'desktop';

  const handleChange = (e: number, s: string, labelOfAmount: string) => {
    setSelectedSemiProductId(e);
    setSelectedSemiProductName(s);
    setSelectedSemiProductLabelOfAmount(labelOfAmount);
  };

  const toReactSelectOptions = (
    options: {
      id: number;
      name: string;
      semiProductCode: string;
      labelOfAmount: string;
    }[]
  ) =>
    options.map(({ id, name, semiProductCode, labelOfAmount }) => ({
      value: id,
      label: name.replace(/\s/g, '_'),
      code: semiProductCode,
      labelOfAmount: labelOfAmount,
    }));
  const reactSelectOptions = semiProducts && toReactSelectOptions(semiProducts);
  const currentValue = reactSelectOptions?.find((o) => o.value === value);

  const filterOption = (
    option: { label: string; data: OptionType },
    inputValue: string
  ): boolean => {
    const { label, data } = option;
    const { code } = data;
    return (
      label.toLowerCase().includes(inputValue.toLowerCase()) ||
      code?.toLowerCase().includes(inputValue.toLowerCase())
    );
  };

  const styles = {
    control: (baseStyles: Todo) => ({
      ...baseStyles,
      width: '100%',
      height: '40px',
      borderRadius: '0.5rem',
      fontWeight: 400,
      textAlign: 'left',
      borderStyle: 'hidden',
      outline: '0.5px solid rgba(0,0,0,0.08)',
      backgroundColor: disabled ? '' : 'white',
      cursor: 'pointer',
      boxShadow: 'none',
      '@media (max-width: 599px)': {
        width: '16rem',
      },
    }),
    menu: (baseStyles: Todo) => ({
      ...baseStyles,
      top: '37px',
      marginTop: marginTop ? marginTop : '',
      zIndex: 1002,
    }),
    option: (baseStyles: Todo) => ({
      ...baseStyles,
      textAlign: 'left',
      fontWeight: 400,
      fontSize: '15px',
    }),
    menuList: (baseStyles: Todo) => ({
      ...baseStyles,
      maxHeight: '180px',
    }),
  };

  return (
    <ReactSelect
      menuShouldBlockScroll={true}
      menuShouldScrollIntoView={false}
      options={reactSelectOptions}
      value={currentValue ? currentValue : null}
      onChange={(option) => {
        option &&
          handleChange(option.value, option.label, option.labelOfAmount);
        option && onChange?.(option.value);
      }}
      styles={styles}
      isDisabled={disabled}
      isSearchable={isDesktop}
      filterOption={filterOption}
      components={{
        IndicatorSeparator: () => null,
      }}
      noOptionsMessage={() => NO_OPTION_JAPANESE}
      placeholder="選択してください"
    />
  );
};

export default SemiProductSelect;
