import styled from 'styled-components';

export const Wrapper = styled.div`
  height: 100%;
  width: 390px;
  position: fixed;
  padding: 1rem;
  top: 0;
  z-index: 9999;
  right: 0;
  background-color: ${(props) => props.theme.palette.beige};
  box-shadow: 0px 24px 38px rgba(0, 0, 0, 0.07),
    0px 9px 46px rgba(0, 0, 0, 0.06), 0px 11px 15px rgba(0, 0, 0, 0.1);
  transform: translateX(0px);
  transition: transform 390ms ease-in-out;
`;

export const StatusBar = styled.div<{
  bgColor: string;
  color: string;
  borderColor: string;
}>`
  border: ${(props) => `${props.borderColor} solid 2px`};
  color: ${(props) => props.color};
  background-color: ${(props) => props.bgColor};
  padding: 6px 10px;
  width: 8.25rem;
  font-size: 16px;
  font-weight: 700;
  text-align: center;
`;

export const ScrollableContainer = styled.div`
  overflow-y: auto;
  height: calc(100vh - 4.6rem);
  padding-bottom: 4.6rem;
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none;
  ::-webkit-scrollbar {
    display: none;
  }
`;

export const HeadContainer = styled.div`
  position: relative;
  display: flex;
  justify-content: space-between;
  padding: 0 1rem;
`;

export const HeadDate = styled.div`
  font-weight: 700;
  line-height: 20px;
  padding-top: 7px; ;
`;

export const HeadProductName = styled.div`
  font-weight: 700;
  font-size: 24px;

  margin: 0.5rem 0 1rem 0;
  display: flex;
  align-items: center;
`;

export const HeadProductType = styled.div<{
  productKind: string | undefined;
}>`
  font-size: 0.75rem;
  padding: 0.5rem;
  margin-right: 0.5rem;
  border-radius: 2px;
  border: 1px solid#2BA9D1;
  background: ${(props) => (props.productKind === '完' ? '#2BA9D1' : '#fff')};
  color: ${(props) => (props.productKind === '完' ? '#fff' : '#2BA9D1')};
`;
export const FlexAlignBaseline = styled.div`
  display: flex;
  align-items: baseline;
`;

export const FlexAlignCenter = styled.div`
  display: flex;
  align-items: center;
  user-select: none;
  > svg {
    margin-right: 0.5rem;
  }
`;

export const DateContainer = styled.div`
  padding: 1rem 0;
  margin-left: 2rem;
  position: relative;
`;

export const MMTypography = styled.div`
  font-size: 2rem;
  font-weight: bold;
`;

export const SlashTypography = styled.div`
  font-size: 5rem;
  font-weight: 100;
  position: absolute;
  transform: rotate(15deg);
  top: 0;
  left: 10%;
`;

export const DDTypography = styled.div`
  font-size: 3rem;
  margin-left: 3rem;
`;

export const DdTypography = styled.div`
  font-weight: bold;
  font-size: 1.5rem;
`;

export const PickingInfoWrapper = styled.div`
  padding: 1rem;
`;

export const ProductContainer = styled.div`
  margin: 0.5em 0;
  display: flex;
  align-items: center;
  font-weight: 700;

  > div {
    &.title {
      width: 4rem;
    }
    &.name {
      font-size: 24px;
    }
  }
  > div:nth-child(2) {
    margin-left: 8px;
  }
`;

// 現状は仮の箱を表示しているだけ
export const ProductImage = styled.div`
  width: 60px;
  height: 60px;
  background: #ccc;
`;

export const ChangeContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-evenly;
  font-weight: 700;
  padding: 1rem;
  border-top: 2px solid rgb(211, 211, 211);
`;

export const ChangeLabel = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0.5rem 0;
  width: 100%;
  color: #6b675c;
  font-weight: 700;
`;

export const UpdateIconWrapper = styled.div`
  margin-right: 5px;
  cursor: pointer;
`;

export const ChangeAmountWrapper = styled.div`
  width: 100%;
  margin-top: 1rem;
`;

export const ChangeAmountInput = styled.input`
  background: #ffffff;
  border-radius: 8px;
  width: 100%;
  height: 64px;
  text-align: right;
  padding: 1rem;

  ::-webkit-inner-spin-button {
    -webkit-appearance: none;
  }
`;

export const NoteAmountContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-evenly;
  font-weight: 700;
  padding: 1rem 0;
`;

export const NoteAmountLabel = styled.div`
  padding: 0.5rem 0;
  width: 100%;
  color: #6b675c;
  font-weight: 700;
`;

export const NoteAmountInput = styled.textarea`
  background: #ffffff;
  border-radius: 8px;
  width: 100%;
  height: 13rem;
  text-align: left;
  padding: 1rem 0.75rem 1rem;

  resize: none;
`;

export const ButtonContainer = styled.div<{
  invalid: boolean;
}>`
  position: fixed;
  bottom: 0px;
  left: 0px;
  padding: 1rem 0px;
  width: 100%;
  display: flex;
  -webkit-box-align: center;
  align-items: center;
  justify-content: space-evenly;
  /* display: flex;
  align-items: center;
  justify-content: space-evenly;
  pointer-events: ${(props) => (props.invalid ? 'none' : '')}; */

  & > button {
    width: 136px;
  }

  & > button:first-child {
    margin-right: 1rem;
  }
`;
export const MessageContainer = styled.div`
  position: absolute;
  bottom: 16px;
  /* left: 16px; */
`;

export const CheckBoxContainer = styled.div`
  display: flex;
  margin-top: 2rem;
  user-select: none;
  line-height: 1.4;
  & > label {
    display: block;
    position: relative;
    padding-left: 24px;
    margin: 1em 0;
    cursor: pointer;
    user-select: none;

    & > input {
      display: none;
    }
  }
  & > label > span {
    position: absolute;
    top: 8%;
    left: 0;
    height: 20px;
    width: 20px;
    border: solid 2px #c74141;
    border-radius: 4px;
    box-sizing: border-box;

    &:after {
      content: '';
      position: absolute;
      border: solid #fff;
      border-width: 0 2px 2px 0;
      left: 5px;
      top: 2px;
      width: 6px;
      height: 10px;
      transform: rotate(45deg);
      opacity: 0;
    }
  }
  /* チェックが入ったとき */
  & > label {
    input:checked + span {
      background: #c74141;
      border-color: #c74141;
    }
    input:checked + span:after {
      opacity: 1;
    }
  }
`;

export const CheckBoxAndButtonContainer = styled.div`
  position: absolute;
  left: 0;
  bottom: 0;
  width: 390px;
  height: 145px;
  /* background: #f3ede6; */
  z-index: -1;
`;
