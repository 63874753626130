import { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import type { Todo } from '@lib/common/type';

const BarcodeReader = ({ onDetected }: Todo) => {
  const [inputText, setInputText] = useState('');
  const desiredLength = 4;

  useEffect(() => {
    const keyDownHandler = (event: KeyboardEvent) => {
      if (event.key === 'Enter') {
        if (inputText.length >= desiredLength) {
          onDetected({ codeResult: { code: inputText } });
          setInputText('');
        }
      } else {
        if (event.key !== 'Shift') {
          const keyToAdd = event.shiftKey ? event.key : event.key.toLowerCase();
          setInputText((prev) => prev + keyToAdd);
        }
      }
    };
    window.addEventListener('keydown', keyDownHandler);
    return () => {
      window.removeEventListener('keydown', keyDownHandler);
    };
  }, [inputText, onDetected]);
  return null;
};

BarcodeReader.propTypes = {
  onDetected: PropTypes.func.isRequired,
};

export default BarcodeReader;
