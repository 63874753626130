import styled from 'styled-components';

export const CircularIconWrapperOuter = styled.div`
  width: 100vw;
  position: relative;
`;

export const CircularIconWrapper = styled.div`
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 10001;
`;
