import { FC, useEffect, useState } from 'react';
import moment from 'moment';
import * as S from './TableWithFixedSidebar.styles';
import Filter from '@assets/icons/filter_list';
import { ReactComponent as FilteredIcon } from '@assets/icons/filtered.svg';
import Overlay from '@components/elements/backdrops/Overlay/Overlay';
import TagMenu from '@components/modules/common/TagMenu/TagMenu';
import TagListModal from '@components/modules/common/TagListModal/TagListModal';
import { generateMonthlyDateArray } from '@lib/pc/stock_status/functions';
import { isSameIndex, addComma } from '@lib/pc/production_schedule/functions';
import { tagSearch } from '@lib/common/functions';
import { CircularProgress } from '@material-ui/core';
import type { Todo } from '@lib/common/type';
import { PRODUCT_KIND } from '@lib/common/type';

type Props = {
  selectedDate: moment.Moment;
  data: Todo;
  selected: boolean;
  rightSlideSidebarActive: boolean;
  selectedCell: string;
  selectIndex: number;
  popUp: boolean;
  handleSelectedProduct: (
    d: {
      productId: number;
      productName: string;
      type: '完' | '半';
      date: Date;
      data: Todo[];
      stockDateLabel: string;
      productProductionResults: Todo;
    },
    selectedCell: string
  ) => void;
  setTagListModalActive?: () => void;
  setSelectIndex: (index: number) => void;
  setPopUp: (popUp: boolean) => void;
};

const TableWithFixedSidebar: FC<Props> = ({
  selectedDate,
  data,
  selected,
  rightSlideSidebarActive,
  selectedCell,
  selectIndex,
  handleSelectedProduct,
  setSelectIndex,
  setPopUp,
}: Props) => {
  const [searchWord, setSearchWord] = useState('');
  const [tagMenuActive, setTagMenuActive] = useState(false);
  const [tagListModalActive, setTagListModalActive] = useState(false);
  const [selectedTags, setSelectedTags] = useState<Todo>([]);
  const selectedTagIds = selectedTags.map((item: Todo) => item['id']);

  const ProductNameArr = data
    ? data.map((p: Todo) => ({
        id: p.id,
        name: p.name,
        nameAbbreviation: p.nameAbbreviation,
        nameKana: p.nameKana,
        productCode: p.productCode,
        tagIds: p.tagIds,
        factoryLineIds: p.factoryLineIds,
      }))
    : [];

  const handleSearch = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSearchWord(e.target.value);
  };

  useEffect(() => {
    setTagMenuActive(false);
  }, [selectedTagIds.length === 0]);

  return (
    <>
      <div>
        <S.ListWrapper>
          <S.ListSidebar>
            <S.ListLeftHeadCell
              invalid={rightSlideSidebarActive}
              onClick={() => {
                setTagMenuActive(!tagMenuActive);
                setPopUp(false);
              }}
              isBlue={searchWord !== '' || selectedTagIds.length > 0}
            >
              <span className="filter-product">
                {searchWord !== '' || selectedTagIds.length > 0 ? (
                  <>
                    <FilteredIcon />
                    <div className="filter_text">商品の絞込み中</div>
                  </>
                ) : (
                  <>
                    <Filter
                      isBlue={searchWord !== '' || selectedTagIds.length > 0}
                    />
                    <div className="filter_text">商品の絞込</div>
                  </>
                )}
              </span>
            </S.ListLeftHeadCell>

            {ProductNameArr.filter(
              (p: Todo) =>
                p.name.includes(searchWord) ||
                p.nameKana?.includes(searchWord) ||
                p.nameAbbreviation?.includes(searchWord) ||
                p.productCode?.toString().includes(searchWord)
            )
              .filter((p: Todo) => tagSearch(p, selectedTagIds))
              .map(
                (
                  s: {
                    name: string;
                    nameKana: string;
                    nameAbbreviation: string;
                    productCode: number;
                  },
                  i: number
                ) => {
                  return (
                    <S.ListLeftHeadCell key={i}>
                      <S.ListLeftHeadName>
                        {s.nameAbbreviation ? s.nameAbbreviation : s.name}
                      </S.ListLeftHeadName>
                      <S.ListLeftHeadMeta>
                        <span>{s.productCode}</span>
                      </S.ListLeftHeadMeta>
                    </S.ListLeftHeadCell>
                  );
                }
              )}
          </S.ListSidebar>
          <S.ListBody className={selected ? 'move-to-left' : ''}>
            <div>
              {data && (
                <S.ListDateRow>
                  {generateMonthlyDateArray(
                    data[0].numberOfDaysParamsMonth,
                    selectedDate,
                    selectIndex,
                    2
                  ).map((p, i) => {
                    const isToday = isSameIndex(
                      i,
                      selectIndex,
                      data[0].numberOfDaysParamsMonth
                    );
                    return (
                      <S.ListHeadCell
                        key={i}
                        index={i}
                        numOfIndex={data[0].numberOfDaysParamsMonth}
                        highlighten={isToday}
                        dayOfWeek={moment(p.productionDate).format('ddd')}
                        onClick={() => {
                          setSelectIndex(i);
                          setPopUp(false);
                        }}
                      >
                        {i === 0
                          ? moment(p.productionDate).format('M/D(ddd)')
                          : i === data[0].numberOfDaysParamsMonth + 1
                          ? '合計'
                          : moment(p.productionDate).format('DD(ddd)')}
                      </S.ListHeadCell>
                    );
                  })}
                </S.ListDateRow>
              )}
            </div>
            {data ? (
              data
                .filter(
                  (p: Todo) =>
                    p.name.includes(searchWord) ||
                    p.nameKana?.includes(searchWord) ||
                    p.nameAbbreviation?.includes(searchWord) ||
                    p.productCode?.toString().includes(searchWord)
                )
                .filter((p: Todo) => tagSearch(p, selectedTagIds))
                .map((p: Todo, i: number) => {
                  return (
                    <S.ListRow key={i}>
                      {generateMonthlyDateArray(
                        data[0].numberOfDaysParamsMonth,
                        selectedDate,
                        selectIndex,
                        2
                      ).map((d: Todo, idx: number) => {
                        const isToday = isSameIndex(
                          idx,
                          selectIndex,
                          data[0].numberOfDaysParamsMonth
                        );
                        return (
                          <S.ListCellContainer
                            invalid={rightSlideSidebarActive}
                            key={idx}
                            index={i}
                            numOfIndex={data[0].numberOfDaysParamsMonth}
                            highlighten={isToday}
                            withComment={p.productProductionResults[
                              idx
                            ].productionResultDetails.some(
                              (detail: Todo) => detail.comment
                            )}
                            noData={
                              p.productProductionResults[idx]
                                .productionResultDetails.length === 0
                            }
                            onClick={() => {
                              handleSelectedProduct(
                                {
                                  productId: p.id,
                                  productName: p.name,
                                  type: p.productKind === 1 ? '完' : '半',
                                  date: p.productProductionResults[idx].date,
                                  productProductionResults:
                                    p.productProductionResults[idx],
                                  data: p.productProductionResults[idx]
                                    .productionResultDetails,
                                  stockDateLabel: p.stockDateLabel,
                                },
                                `${p.id}-${idx}`
                              );
                              setPopUp(false);
                            }}
                            className={
                              `${p.id}-${idx}` === selectedCell
                                ? 'select-cell'
                                : ''
                            }
                          >
                            {addComma(
                              p.productProductionResults[idx].totalAmount
                            )}
                          </S.ListCellContainer>
                        );
                      })}
                    </S.ListRow>
                  );
                })
            ) : (
              <S.CircularIconWrapperOuter>
                <S.CircularIconWrapper>
                  <CircularProgress style={{ color: '#64b2f9' }} />
                </S.CircularIconWrapper>
              </S.CircularIconWrapperOuter>
            )}
          </S.ListBody>
        </S.ListWrapper>
      </div>
      {tagMenuActive && (
        <TagMenu
          searchParam={searchWord}
          selectedTags={selectedTags}
          setSelectedTags={(tags) => setSelectedTags(tags)}
          setOpenTagModal={() => setTagListModalActive(true)}
          handleChange={handleSearch}
          deleteSearchParam={() => setSearchWord('')}
        />
      )}
      {tagListModalActive && (
        <TagListModal
          selectedTags={selectedTags}
          setSelectedTags={(tags) => setSelectedTags(tags)}
          tagListModalActive={tagListModalActive}
          handleClose={() => {
            setTagListModalActive(false);
            setTagMenuActive(false);
          }}
          productKind={PRODUCT_KIND.PRODUCT}
        />
      )}
      {tagMenuActive && (
        <Overlay
          zIndex={9997}
          handleClick={() => {
            setTagMenuActive(false);
          }}
        />
      )}
    </>
  );
};

export default TableWithFixedSidebar;
