import styled from 'styled-components';

export const Wrapper = styled.div<{
  direction: 'right' | 'left';
}>`
  position: relative;

  > div {
    ${(props) =>
      props.direction === 'right' ? '  padding-right: 0.75rem;' : null};
    ${(props) =>
      props.direction === 'left' ? '  padding-left: 0.75rem;' : null};
    ${(props) => (props.direction === 'right' ? ' right: 0;' : null)};
    ${(props) => (props.direction === 'left' ? ' left: 0;' : null)};
  }

  > input {
    ${(props) =>
      props.direction === 'right' ? '  padding-right: 2.5rem;' : null};
    ${(props) =>
      props.direction === 'left' ? '  padding-left: 2.5rem;' : null};
  }
`;

export const Icon = styled.div`
  color: ${(props) => props.theme.palette.khaki};

  position: absolute;
  top: 0px;
  bottom: 0px;
  display: flex;
  align-items: center;
  svg {
    width: 1.5rem;
    height: 1.5rem;
  }
`;

export const Input = styled.input<{
  background?: string;
  placeholderColor?: string;
  placeholderFontWeight?: string;
  placeholderFontSize?: string;
}>`
  width: 100%;
  color: ${(props) => props.theme.palette.baseBlack};
  /* font-size: 0.875rem; */
  line-height: 1.25rem;
  padding: 0.625rem;
  background: ${(props) => props.theme.palette.white};
  background: ${(props) =>
    props.background ? props.background : props.theme.palette.white};
  border: none;
  border-radius: 0.5rem;
  & ::placeholder,
  textarea::placeholder {
    color: #9ca3af;
  }
  &::placeholder {
    color: ${(props) => props.placeholderColor};
    font-weight: ${(props) => props.placeholderFontWeight};
    font-size: ${(props) => props.placeholderFontSize};
  }
  &:focus {
    outline: none;
  }
`;
