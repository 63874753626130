import styled from 'styled-components';

export const Wrapper = styled.div<{
  open: boolean;
}>`
  height: 100%;
  width: 390px;
  position: fixed;
  padding: 1.5rem 1rem;
  top: 0;
  z-index: 9999;
  right: 0;
  background-color: ${(props) => props.theme.palette.beige};
  box-shadow: 0px 24px 38px rgba(0, 0, 0, 0.07),
    0px 9px 46px rgba(0, 0, 0, 0.06), 0px 11px 15px rgba(0, 0, 0, 0.1);
  transform: ${(props) =>
    props.open ? 'translateX(0px)' : 'translateX(390px)'};
  transition: transform 390ms ease-in-out;
  transition-property: all;
  transition-duration: 700ms;
`;

export const ScrollableContainer = styled.div`
  overflow-y: auto;
  height: calc(100vh - 4.6rem);
  padding-bottom: 4.6rem;
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none;
  ::-webkit-scrollbar {
    display: none;
  }
`;

export const HeadContainer = styled.div`
  position: relative;
`;

export const HeadDate = styled.div`
  font-weight: 700;
  font-size: 14px;
  line-height: 20px;
`;

export const HeadProductName = styled.div`
  font-weight: 700;
  font-size: 24px;

  margin: 0.5rem 0 1rem 0;
  display: flex;
  align-items: center;
`;

export const ButtonContainer = styled.div<{
  invalid: boolean;
}>`
  position: fixed;
  bottom: 0;
  left: 0;
  padding: 1rem 0;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  background-color: #f3ede6;
  pointer-events: ${(props) => (props.invalid ? 'none' : '')};

  & > button {
    width: 136px;
  }

  & > button:first-child {
    margin-right: 1rem;
  }
`;

export const DataContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

export const Table = styled.table`
  border-collapse: collapse;
  border-spacing: 0 1em;
  font-size: 0.875rem;
`;

export const THead = styled.tr`
  height: 2.6rem;
  position: sticky;
  top: 0;
  border-bottom: 1px solid #c4c4c4;
`;

export const TCellOrder = styled.td<{
  marginRight?: string;
}>`
  text-align: left;
  padding: 0 0 0 0.625rem;
  width: 3px;
  margin-right: ${(props) => (props.marginRight ? props.marginRight : '')};
  vertical-align: middle;
`;

export const THeadCell = styled.td<{
  align?: string;
  width?: string;
  wordBreak?: string;
  colSpan?: number;
  textAlign: string;
  paddingRight?: string;
}>`
  font-family: 'Noto Sans JP';
  font-style: normal;
  font-weight: 500;
  font-size: 15px;
  vertical-align: middle;
  word-break: ${(props) => (props.wordBreak ? props.wordBreak : '')};
  width: ${(props) => (props.width ? props.width : '0rem')};
  min-width: ${(props) => (props.width ? props.width : '0rem')};
  padding: 0 0.625rem 0 0.5rem;
  text-align: ${(props) => props.textAlign};
  word-spacing: 100vw;
  padding-right: ${(props) => (props.paddingRight ? props.paddingRight : '')};
`;

export const TrCell = styled.tr<{
  wideBottom: boolean;
}>`
  border-bottom: ${(props) => (props.wideBottom ? '2px' : '1px')} solid
    ${(props) => props.theme.palette.sand};
  width: 100%;
`;

export const TdCell = styled.td<{
  textAlign: string;
  width: string;
  fontWeight?: number;
  paddingRight?: string;
}>`
  text-align: ${(props) => props.textAlign};
  padding: 0.5rem 0.625rem 0.5rem 0.5rem;
  font-weight: ${(props) => (props.fontWeight ? props.fontWeight : '')};
  font-size: 15px;
  padding-right: ${(props) => (props.paddingRight ? props.paddingRight : '')};

  > input {
    margin-left: auto;
    height: 2.5rem;
    max-width: 5.25rem;
    width: 100%;
    border-radius: 8px;
    text-align: right;
    padding-right: 12px;
    opacity: 1;
    -webkit-text-fill-color: ${(props) => props.theme.palette.baseBlack};
    ::-webkit-inner-spin-button {
      -webkit-appearance: none;
    }

    :focus {
      outline: none;
    }
  }
`;

export const FormWrapper = styled.div<{
  marginTop?: string;
}>`
  font-size: 15px;
  margin-bottom: 1rem;
  padding-top: 0.5rem;
  display: flex;
  justify-content: space-between;

  div:first-child {
    white-space: nowrap;
    font-weight: 700;
    margin-top: ${(props) => (props.marginTop ? props.marginTop : '')};
  }
`;
