import * as S from './MemorizedListCell.styles';
import { Todo } from '@lib/common/type';
import {
  addComma,
  hasRemainder,
  isSameIndex,
} from '@lib/pc/production_schedule/functions';
import { SelectedProduct } from '@lib/pc/production_schedule/type';

type Props = {
  index: number;
  p: Todo;
  paramsType: Todo;
  idx: number;
  selectIndex: number;
  last: boolean;
  monthlyDateNum: number;
  selected?: SelectedProduct | boolean | null;
  yellow?: boolean;
};

const MemorizedListCell = ({
  index,
  p,
  paramsType,
  idx,
  selectIndex,
  last,
  monthlyDateNum,
  selected,
  yellow,
}: Props) => {
  const isToday = isSameIndex(idx, selectIndex, monthlyDateNum);

  const evenNumber = hasRemainder(idx, 2);

  // 生産計画、需要予測、理想在庫：beige, white
  // 生産実績、出荷実績、予定在庫：yellowGray,yellow

  return (
    // 各数値セル
    <S.ListCellContainer
      className="list-cell-container"
      key={`${index}-${idx}-${p.id}`}
      last={last}
      highLighten={isToday}
      invalid={selected ? true : false}
      withCheckEnabled={p.productionSchedules[idx]?.checkEnabled}
      yellow={yellow}
      evenNumber={evenNumber}
    >
      {paramsType?.amount || paramsType?.amount === 0
        ? addComma(paramsType?.amount)
        : ''}
    </S.ListCellContainer>
  );
};
export default MemorizedListCell;
