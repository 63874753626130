import { FC } from 'react';
import * as S from './ErrorMsgPopUpForStock.styles';
import { Button } from '@components/elements/buttons/Button';

type Props = {
  errMsg: string;
  handleClose: () => void;
  fromPc?: boolean;
  width?: string;
};

const ErrorMsgPopUpForStock: FC<Props> = ({
  errMsg,
  handleClose,
  fromPc,
  width,
}: Props) => {
  return (
    <>
      <S.Wrapper open={errMsg} fromPc={fromPc} width={width}>
        {errMsg}
        <S.ButtonContainer>
          <Button padding="md" outlined onClick={() => handleClose()}>
            OK
          </Button>
        </S.ButtonContainer>
      </S.Wrapper>
    </>
  );
};

export default ErrorMsgPopUpForStock;
