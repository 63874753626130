import { useMutation } from 'react-query';
import humps from 'humps';
import { axiosInstance } from '@lib/pc/common/api/axiosConfig';

const useUpdateWrappingOrderMutation = (handleSuccess: () => void) => {
  return useMutation(
    async (params: { packagingIds: number[] }) => {
      return await axiosInstance().patch(
        `/api/v1/packagings_order`,
        humps.decamelizeKeys(params)
      );
    },
    {
      onSuccess: async () => {
        await delay(1000);
        handleSuccess();
      },
      onError: () => alert('エラーが発生しました'),
    }
  );
};

function delay(ms: number) {
  return new Promise((resolve) => {
    return setTimeout(resolve, ms);
  });
}

export default useUpdateWrappingOrderMutation;
