import { useEffect, useState } from 'react';
import { Material, Storeroom, Todo, scannedMaterial } from '@lib/common/type';
import Overlay from '@components/elements/backdrops/Overlay/Overlay';
import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import RowMaterialAllocationMainTable from '@components/modules/pc/row_material_allocation/RowMaterialAllocationMainTable/RowMaterialAllocationMainTable';
import RowMaterialAllocationEditModal from '@components/modules/pc/row_material_allocation/RowMaterialAllocationEditModal/RowMaterialAllocationEditModal';
import {
  MaterialAllocationEditData,
  initEditData,
} from '@lib/pc/material_allocation/type';
import useCreateMaterialStockAllocationMutation from '@lib/pc/material_allocation/useCreateMaterialStockAllocationMutation';
import useUpdateMaterialStockAllocationMutation from '@lib/pc/material_allocation/useUpdateMaterialStockAllocationMutation';
import useDeleteMaterialStockAllocationMutation from '@lib/pc/material_allocation/useDeleteMaterialStockAllocationMutation';
import scanSound from '@assets/sounds/sound.mp3';
import BarcodeReader from '@components/modules/pc/BarcodeScanner/BarcodeReader';
import ErrorMsgPopUp from '@components/modules/common/ErrorMsgPopUp/ErrorMsgPopUp';

type Props = {
  stockAllocations: Todo;
  selectedStoreroomId: number | undefined;
  date: Date;
  selectedStoreroomsMaterials: Material[];
  storerooms: Storeroom[];
  setPopUp: (popUp: boolean) => void;
  setEditMaterialName: (editMaterialName: string) => void;
  refetch: () => void;
  setMessageKind: (messageKind: string) => void;
};

export type InputParams = {
  time: string;
};

const defaultScanMaterial = {
  materialId: null,
  materialName: '',
};

const RowMaterialAllocationContents = ({
  stockAllocations,
  selectedStoreroomId,
  date,
  selectedStoreroomsMaterials,
  storerooms,
  setPopUp,
  setEditMaterialName,
  refetch,
  setMessageKind,
}: Props) => {
  const [editData, setEditData] =
    useState<MaterialAllocationEditData>(initEditData);
  const [editModalOpen, setEditModalOpen] = useState(false);
  const [deleteId, setDeleteId] = useState<number | ''>('');
  const [scanning, setScanning] = useState(true);
  const [results, setResults] = useState<Todo>([]);
  const [inputText] = useState('');
  const [audio] = useState(new Audio(scanSound));
  const [errMsg, setErrMsg] = useState('');
  const [foundScannedMaterial, setFoundScannedMaterial] = useState(false);
  const [scannedMaterial, setScannedMaterial] =
    useState<scannedMaterial>(defaultScanMaterial);

  const handleDelete = () => {
    deleteMaterialStockAllocationMutation.mutate();
    setEditData(initEditData);
  };

  // 最新データの取得
  const getLatestData = () => {
    refetch();
  };

  const handleDeleteSuccess = () => {
    setDeleteId('');
    setEditModalOpen(false);
    setPopUp(true);
    setMessageKind('delete');
    getLatestData();
  };

  const handleCreateSuccess = () => {
    setPopUp(true);
    setMessageKind('create');
    getLatestData();
    setEditData(initEditData);
    setEditModalOpen(false);
  };

  const handleUpdateSuccess = () => {
    setPopUp(true);
    setMessageKind('update');
    getLatestData();
    setEditData(initEditData);
    setEditModalOpen(false);
  };

  const handleClose = () => {
    setEditModalOpen(false);
    setScanning(true);
    setResults([]);
    setScannedMaterial(defaultScanMaterial);
  };

  const handleSubmit = (newEditData: MaterialAllocationEditData) => {
    if (newEditData.detailId === 0) {
      createMaterialStockAllocationMutation.mutate(newEditData);
    } else {
      updateMaterialStockAllocationMutation.mutate(newEditData);
    }
    setScanning(true);
    setResults([]);
    setScannedMaterial(defaultScanMaterial);
  };

  // 払出入力データ作成処理
  const createMaterialStockAllocationMutation =
    useCreateMaterialStockAllocationMutation(
      date,
      editData.materialId,
      selectedStoreroomId ? selectedStoreroomId : storerooms?.[0].id,
      handleCreateSuccess
    );

  // 払出入力データ更新処理
  const updateMaterialStockAllocationMutation =
    useUpdateMaterialStockAllocationMutation(
      editData?.detailId,
      handleUpdateSuccess
    );

  // 払出入力データ削除
  const deleteMaterialStockAllocationMutation =
    useDeleteMaterialStockAllocationMutation(
      editData?.detailId,
      handleDeleteSuccess
    );

  useEffect(() => {
    if (results.length !== 0) {
      const getData = selectedStoreroomsMaterials.filter(
        (selectedStoreroomsMaterials: Todo) => {
          selectedStoreroomsMaterials.materialCode ===
            results[0].codeResult.code &&
            selectedStoreroomsMaterials.storeroomIds.includes(
              Number(selectedStoreroomId)
            );
          return (
            selectedStoreroomsMaterials.materialCode ===
              results[0].codeResult.code &&
            selectedStoreroomsMaterials.storeroomIds.includes(
              Number(selectedStoreroomId)
            )
          );
        }
      );

      const scannedMaterialInfo = {
        materialId: getData[0] ? getData[0].id : null,
        materialName: getData[0] ? getData[0].name : '',
      };
      setScannedMaterial(scannedMaterialInfo);

      if (!!getData && getData.length > 0) {
        setEditData({
          ...initEditData,
        });
        setPopUp(false);
        setEditModalOpen(true);
        setFoundScannedMaterial(true);
      } else {
        setErrMsg('該当の原材料が見つかりませんでした。');
        setFoundScannedMaterial(false);
        setEditModalOpen(false);
      }
    }
  }, [results]);

  return (
    <div>
      <DndProvider backend={HTML5Backend}>
        <RowMaterialAllocationMainTable
          data={stockAllocations}
          setEditData={setEditData}
          setPopUp={setPopUp}
          setEditModalOpen={setEditModalOpen}
          setScanning={setScanning}
        />
      </DndProvider>
      {editModalOpen && (
        <RowMaterialAllocationEditModal
          scannedMaterial={scannedMaterial}
          editModalOpen={editModalOpen}
          data={editData}
          selectedStoreroomsMaterials={selectedStoreroomsMaterials}
          storerooms={storerooms}
          selectedStoreroomId={selectedStoreroomId}
          handleClose={() => handleClose()}
          handleDelete={handleDelete}
          handleSubmit={handleSubmit}
          setEditMaterialName={setEditMaterialName}
          setEditData={setEditData}
          deleteId={deleteId}
          setDeleteId={setDeleteId}
        />
      )}
      {editModalOpen && <Overlay dark />}
      {scanning && (
        <BarcodeReader
          onDetected={(result: Todo) => {
            setScanning(false);
            audio.play();
            const newResult = {
              codeResult: { code: result.codeResult.code },
              inputText,
            };
            setResults([...results, newResult]);
          }}
        />
      )}
      {!foundScannedMaterial && (
        <ErrorMsgPopUp
          fromPc={true}
          errMsg={errMsg}
          handleClose={() => {
            setResults([]);
            setScanning(true);
            setErrMsg('');
          }}
        />
      )}
    </div>
  );
};

export default RowMaterialAllocationContents;
