import { FC, useState, useEffect } from 'react';
import * as S from './ProductDetailModal.styles';
import { ReactComponent as CloseIcon } from '@assets/icons/close.svg';
import Overlay from '@components/elements/backdrops/Overlay/Overlay';
import PredictionGraph from '../graphs/PredictionGraph/PredictionGraph';
import StockGraph from '../graphs/StockGraph/StockGraph';
import ProductionGraph from '../graphs/ProductionGraph/ProductionGraph';
import SNSDialog from '../SNSDialog/SNSDialog';
import { useProductionSchedulesForOneProduct } from '@lib/pc/production_schedule/hooks';
import type { Todo } from '@lib/common/type';

type Props = {
  id: number;
  productName: string;
  selectedDate: Todo;
  selectedId: boolean;
  productSafetyStockDays: string;
  handleClose: () => void;
};

const ProductDetailModal: FC<Props> = ({
  id,
  productName,
  selectedDate,
  selectedId,
  productSafetyStockDays,
  handleClose,
}: Props) => {
  const [selectedSNSItem, setSelectedSNSItem] = useState<Todo>(null);
  const [date, setDate] = useState(selectedDate);

  const { productionSchedulesForOneProduct } =
    useProductionSchedulesForOneProduct(date, id);

  const data = productionSchedulesForOneProduct;

  useEffect(() => {
    // Esc キーのイベントリスナー
    const handleKeyDown = (event: KeyboardEvent) => {
      if (event.key === 'Escape') {
        handleClose();
        setSelectedSNSItem(null);
      }
    };
    if (id) {
      window.addEventListener('keydown', handleKeyDown);
    }
    // コンポーネントのクリーンアップ時にイベントリスナーを削除
    return () => {
      window.removeEventListener('keydown', handleKeyDown);
    };
  }, [id]);

  return (
    <>
      <S.Wrapper open={selectedId} dialogOpen={selectedSNSItem}>
        <S.ModalHead>
          <S.ProductMetaContainer>
            <div
              style={{ width: '60px', height: '60px', background: '#ccc' }}
            />
            <S.ProductKind>完成品</S.ProductKind>
            <div>{productName}</div>
          </S.ProductMetaContainer>
          <S.ModalHeadCloseIcon>
            <CloseIcon
              onClick={() => {
                handleClose();
                setSelectedSNSItem(null);
              }}
            />
          </S.ModalHeadCloseIcon>
        </S.ModalHead>

        {/*  COMMENT(220314):生産計画の根拠と受注確定状況 */}
        <PredictionGraph
          data={data}
          date={date}
          setDate={setDate}
          setSelectedSNSItem={setSelectedSNSItem}
        />
        {/*  COMMENT(220307):在庫数の予定と実績 */}
        <StockGraph
          data={data}
          date={date}
          setDate={setDate}
          productSafetyStockDays={productSafetyStockDays}
        />
        {/*  COMMENT(220307): 生産計画と実績（生産計画数と生産実績） */}
        <ProductionGraph data={data} date={date} setDate={setDate} />
      </S.Wrapper>
      <SNSDialog
        item={selectedSNSItem}
        handleClose={() => setSelectedSNSItem(null)}
      />
      {id && <Overlay dark />}
    </>
  );
};

export default ProductDetailModal;
