import { useState } from 'react';
import * as S from './PickingDetailByShipping.styles';
import { ReactComponent as ArrowUpOutlined } from '@assets/icons/arrow_up_outlined.svg';
import { ReactComponent as ExpandMore } from '@assets/icons/expand_more.svg';
import { Todo } from '@lib/common/type';

type Props = { destinationBreakdownDetail: Todo[] };

const PickingDetailByShipping = ({ destinationBreakdownDetail }: Props) => {
  const [shippingDetailActive, setShippingDetailActive] = useState(false);

  return (
    <S.Wrapper>
      <S.TitleText
        onClick={() => setShippingDetailActive(!shippingDetailActive)}
      >
        出荷先内訳
        {shippingDetailActive ? <ArrowUpOutlined /> : <ExpandMore />}
      </S.TitleText>
      <S.DetailWrapper open={shippingDetailActive}>
        {destinationBreakdownDetail.map((d: Todo, idx: number) => (
          <S.Detail key={idx}>
            <S.Destination key={idx + d.destinationCode}>
              <div>{d.destinationCode}</div>
              <div>{d.destinationName}</div>
            </S.Destination>
            <S.Amount>{d.amount}</S.Amount>
          </S.Detail>
        ))}
      </S.DetailWrapper>
    </S.Wrapper>
  );
};

export default PickingDetailByShipping;
