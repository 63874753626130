import { Todo } from '@lib/common/type';
import * as S from './MemorizedListCell.styles';
import { addComma, isSameIndex } from '@lib/pc/production_schedule/functions';

type Props = {
  today: Date;
  handleOnclickCell: (
    cellIndex: number,
    rowIndex: number,
    selectedDate: string,
    productId: number
  ) => void;
  p: Todo;
  rowIndex: number;
  cellIdx: number;
  orderSummary: Todo;
  setSelectedProductId: (selectedProductId: number) => void;
  setSelectedCustomerId: (selectedCustomerId: number) => void;
  focusedCellIndex: number | null;
  setFocusedCellIndex: (selectedProductId: number | null) => void;
  focusedRowIndex: number | null;
  setFocusedRowIndex: (selectedCustomerId: number | null) => void;
  selectedDate: string;
  monthlyDateNum: number;
  selectedCalendarCellIdx: number;
};

const MemorizedListCell = ({
  handleOnclickCell,
  p,
  rowIndex,
  cellIdx,
  orderSummary,
  focusedCellIndex,
  setFocusedCellIndex,
  focusedRowIndex,
  setFocusedRowIndex,
  monthlyDateNum,
  selectedCalendarCellIdx,
}: Props) => {
  const isToday = isSameIndex(cellIdx, selectedCalendarCellIdx, monthlyDateNum);

  return (
    <S.ListCellContainer
      onClick={() => {
        if (orderSummary.amount > 0) {
          handleOnclickCell(cellIdx, rowIndex, orderSummary.date, p.id);
          setFocusedCellIndex(cellIdx);
          setFocusedRowIndex(rowIndex);
        }
      }}
      highLighten={isToday}
      invalid={orderSummary.amount > 0 ? false : true}
      className={
        `${rowIndex}-${cellIdx}` === `${focusedRowIndex}-${focusedCellIndex}`
          ? 'select-cell'
          : ''
      }
    >
      {orderSummary.amount ? addComma(orderSummary.amount) : ''}
    </S.ListCellContainer>
  );
};

export default MemorizedListCell;
