import { FC } from 'react';
import * as S from './LoadingOverlay.styles';
import CenteredLoader from '@components/modules/common/CenteredLoader/CenteredLoader';

type Props = {
  handleClick?: () => void;
  dark?: boolean;
  zIndex?: number;
};

const LoadingOverlay: FC<Props> = ({ handleClick, dark, zIndex }: Props) => {
  return (
    <S.LoadingContainer onClick={handleClick} zIndex={zIndex} dark={dark}>
      <S.CircularProgressWrapper>
        <CenteredLoader />
      </S.CircularProgressWrapper>
    </S.LoadingContainer>
  );
};

export default LoadingOverlay;
