import React from 'react';
import { useParams } from 'react-router-dom';
import { WithHeader } from '@components/templates/WithHeader';
import { Heading } from '@components/elements/typographies/Heading';
import * as S from './SalesStoreMasterEdit.styles';
import SalesStoreEditWrapper from '@components/modules/pc/settings/sales_store_setting/SalesStoreEdit/SalesStoreEditWrapper/SalesStoreEditWrapper';
import useSalesStore from '@lib/pc/settings/sales_store_edit/useSalesStore';

export const SalesStoreMasterEditRoot: React.FC = () => {
  const { id, timestamp } = useParams<{ id: string; timestamp: string }>();
  const { salesStore, refetch } = useSalesStore(id);

  return (
    <WithHeader>
      <S.Wrapper>
        <div>
          <S.HeadContainer>
            <S.FlexBaseline>
              <div>
                <Heading tag="span" text="販売店の編集" fs="32" fw="lg" />
              </div>
            </S.FlexBaseline>
          </S.HeadContainer>
          <SalesStoreEditWrapper
            salesStore={salesStore}
            refetch={refetch}
            timestamp={timestamp}
          />
        </div>
      </S.Wrapper>
    </WithHeader>
  );
};
