import styled from 'styled-components';

export const Wrapper = styled.div`
  width: 100vw;
  background-color: ${(props) => props.theme.palette.yellowGray};
`;

export const TitleText = styled.div`
  justify-content: space-between;
  padding: 1rem 1.5rem;
  display: flex;
  font-size: 16px;
  font-weight: 700;
  line-height: 23px;
  letter-spacing: 0em;
  text-align: left;
`;

export const DetailWrapper = styled.div<{
  open: boolean;
}>`
  display: ${(props) => (props.open ? 'block' : 'none')};
  align-items: center;
  padding-bottom: 0.5rem;
`;

export const Detail = styled.div`
  width: 100vw;
  padding-bottom: 16px;
  /* padding-left: 16px; */
`;

export const ExpirationDate = styled.div`
  font-weight: 700;
  font-size: 16px;
  margin-bottom: 0.5rem;
  border-top: 1px solid ${(props) => props.theme.palette.white};
  padding-top: 1rem;
  padding-left: 16px;

  > label {
    text-align: center;
  }
`;

export const LabelWrapper = styled.div`
  position: relative;
  text-align: center;
  margin-left: 0.75rem;
`;

export const ProductionOrShippingDateLabel = styled.label`
  display: block;
  position: absolute;
  top: 36%;
  font-size: 15px;
`;

export const DeleteButtonWrapper = styled.div`
  position: relative;
  cursor: pointer;
`;

export const DeleteButton = styled.div`
  font-size: 1rem;
  position: absolute;
  top: -2.4rem;
  right: 9%;
`;

// ピッキング入力エリア
export const AmountWrapper = styled.div`
  display: flex;
  padding: 0 16px;
`;
export const DInputContainer = styled.div<{
  center?: boolean;
}>`
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 6px;
  > label {
    font-weight: 700;
    font-size: 13px;
  }
  > input {
    height: 3.25rem;
    max-width: 6.75rem;
    width: 100%;
    border-radius: 8px;
    text-align: ${(props) => (props.center ? 'center' : 'right')};
    padding-right: 12px;

    :focus {
      outline: none;
    }

    :disabled {
      border: 2px solid #ffffff;
      background-color: transparent;
    }
  }

  > span {
    position: absolute;
    right: 0.3rem;
    top: 80%;
    font-size: 12px;
    margin-left: 4px;
    color: #6b675c;
  }
`;

export const DSymbol = styled.div`
  margin-top: 2.5rem;
  padding: 0 5px;
  font-weight: 700;
  font-size: 13px;
`;

export const Input = styled.input<{
  // labelLength?: boolean;
}>`
  width: 52%;
  height: 3.25rem;
  vertical-align: middle;
  color: ${(props) => props.theme.palette.baseBlack};
  font-size: 1rem;
  line-height: 1.25rem;
  padding: 0.325rem;
  background: ${(props) => props.theme.palette.white};
  border: none;
  border-radius: 0.5rem;
  border: 1px solid rgba(0, 0, 0, 0.08);
  -webkit-appearance: none;
  ::-webkit-date-and-time-value {
    text-align: left;
    padding-left: 8px;
  }
`;

export const AddIconWrapper = styled.div`
  width: 24px;
  margin: 0 auto;
`;
