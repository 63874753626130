import { Material } from '@lib/common/type';

// 文字列検索
export const searchCharFunc = (
  stateSearchChar: string,
  materials: Material[]
) => {
  const newArr = materials.filter((m) => {
    return (
      m.name.includes(stateSearchChar) ||
      (m.materialCode && m.materialCode.toString().includes(stateSearchChar))
    );
  });
  return newArr;
};
