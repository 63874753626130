import { WithHeader } from '@components/templates/WithHeader';
import * as S from './SupplierMasterRoot.styles';
import SupplierHeaderControl from '@components/modules/pc/settings/supplier_setting/SupplierHeaderControl/SupplierHeaderControl';
import TableWithFilter from '@components/modules/pc/settings/supplier_setting/TableWithFilter/TableWithFilter';
import useSuppliersForMaterialSetting from '@lib/pc/settings/material_master_edit/useSuppliersForMaterialSetting';

export const SupplierMasterRoot = () => {
  const { suppliers: suppliers } = useSuppliersForMaterialSetting();

  return (
    <WithHeader>
      {/* 仕入先管理 */}
      <S.Wrapper>
        <SupplierHeaderControl />
      </S.Wrapper>
      {suppliers && <TableWithFilter suppliers={suppliers} />}
    </WithHeader>
  );
};
