import { useEffect, useState } from 'react';
import { useHistory, useLocation, useParams } from 'react-router';
import * as S from './PickingEdit.styles';
import FloatingCameraButton from '@components/modules/common/mobile/FloatingCameraButton/FloatingCameraButton';
import PickingEditWrapper from '@components/modules/mobile/picking_edit/PickingEditWrapper/PickingEditWrapper';
// import { Todo } from '@lib/common/type';
import useProducts from '@lib/mobile/common/hooks/useProducts';
import useStorerooms from '@lib/mobile/common/hooks/useStorerooms';
import {
  IndividualShippings,
  // IndividualShippings,
  PickingState,
} from '@lib/mobile/picking_edit/type';
import { PICKING_DETAIL_DATA } from './dummy';
import { PRODUCT_KIND, Product, Todo } from '@lib/common/type';
import { useWindowScroll } from 'react-use';
// import moment from 'moment';

export const PickingEditRoot = () => {
  // idはshipping_id
  const { id } = useParams<{ id: string }>();
  const { state } = useLocation<PickingState>();
  // const [date] = useState(moment());
  const history = useHistory();
  const [stateSearchChar, setSearchChar] = useState('');
  const [stateShippingDate, setShippingDate] = useState('');
  const [stateShippings, setShippings] = useState<Todo>([]);
  const [statePickingStatus, setStatePickingStatus] = useState<number[]>([]);
  const [shippingsFromIndex, setShippingsFromIndex] = useState<Todo>([]);
  const [stateScrollPosition, setScrollPosition] = useState(0);
  // const [, setScrollPositionForEdit] = useState(0);
  const [selectedStoreroomId, setSelectedStoreroomId] = useState<number | ''>(
    ''
  );

  // const [stateSelectedStoreroomId, setSelectedStoreroomId] =
  //   useState<number>('');
  // const [stateSelectedStoreroomId, setSelectedStoreroomId] = useState<
  //   number | ''
  // >('');

  const [stateProducts, setProducts] = useState<Product[]>();
  const [stateScannedProductName, setProductName] = useState('');
  const [janCode, setJanCode] = useState('');
  // const [allProduct, setAllProduct] = useState<Product[]>([]);
  const { data: products } = useProducts();
  const { data: storerooms } = useStorerooms(PRODUCT_KIND.PRODUCT);

  // スクロール位置
  const { y: currentScrollPosition } = useWindowScroll();

  // TODO: バックエンドから出荷データを取得する
  // GET: /api/v1/shippings/1→id
  // const { stocksForOneProduct: shippings, refetch } = useIndividualShipping(id);
  // const { stocksForOneProduct: stocks, refetch } = useStocksForOneProduct(id);
  const [individualShippings] =
    useState<IndividualShippings>(PICKING_DETAIL_DATA);
  // const dummyShippings = PICKING_DETAIL_DATA;

  console.log(
    '---編集画面 shipshippingsFromIndexpings 1308---',
    shippingsFromIndex
  );
  // console.log('---編集画面 allProduct 1019---', allProduct);
  console.log('---編集画面 products 1019---', products);
  // メモ：ココまで入ってる
  console.log('---PickingEditRoot state 1046---', state);
  // ステータスmodal画面open
  // const [statusOpen, setStatusOpen] = useState(false);

  useEffect(() => {
    console.log('---useEffect PickingEditRoot---');
    if (state) {
      console.log('---PickingEditRoot 設定される？1345 state---', state);
      const {
        stateSearchChar,
        stateShippingDate,
        stateShippings,
        stateScrollPosition,
        stateJanCode,
        statePickingStatus,
        stateProducts,
        stateScannedProductName,
        // stateScrollPositionForEdit,
        stateSelectedStoreroomId,
      } = state;
      console.log(
        '---PickingEditRoot 設定される？ stateShippings 1400---',
        stateShippings
      );
      setSearchChar(stateSearchChar);
      setShippingDate(stateShippingDate);
      setShippings(stateShippings);
      setJanCode(stateJanCode);
      setStatePickingStatus(statePickingStatus);
      setScrollPosition(stateScrollPosition);
      setProducts(stateProducts);
      setProductName(stateScannedProductName);
      // setScrollPositionForEdit(stateScrollPositionForEdit);
      setSelectedStoreroomId(stateSelectedStoreroomId);
    } else {
      setSearchChar('');
      setShippings(stateShippings);
      setShippingsFromIndex('');
      setScrollPosition(0);
      setStatePickingStatus([]);
      // setProducts(products);
      setProductName('');
      // setScrollPositionForEdit(0);
      setSelectedStoreroomId('');
    }
    products && setProducts(products);
  }, [state]);
  // }, [id]);

  console.log('---id 1824---', id);
  console.log('---janCode 1824---', janCode);
  const pickingState = {
    stateSearchChar: stateSearchChar,
    stateShippingDate: stateShippingDate,
    stateShippings: stateShippings,
    stateScrollPosition: stateScrollPosition,
    stateJanCode: janCode,
    statePickingStatus: statePickingStatus,
    stateProducts: stateProducts,
    // stateProducts: stateProducts ? stateProducts : products,
    stateScannedProductName: stateScannedProductName,
    stateScrollPositionForEdit: currentScrollPosition,
    stateSelectedStoreroomId: selectedStoreroomId,
  };
  // ココで消えてる
  console.log('---PickingEditRoot pickingState 1638---', pickingState);

  // // スクロール位置復元
  // useEffect(() => {
  //   window.scrollTo(0, stateScrollPositionForEdit);
  // }, [stateScrollPositionForEdit]);

  return (
    <>
      {/* <S.Wrapper scrollInvalid={statusOpen}> */}
      <S.Wrapper>
        {products && storerooms && (
          <PickingEditWrapper
            // pickingState={pickingState}
            // janCode={janCode}
            products={products}
            storerooms={storerooms}
            individualShippings={individualShippings}
          />
        )}
      </S.Wrapper>
      <FloatingCameraButton
        // display={statusOpen}
        // bottom={'6.7rem'}
        zIndex={1003}
        handleClick={() =>
          history.push(
            `/mobile/picking_detail_edit/scanner/${id}`,
            pickingState
          )
        }
      />
    </>
  );
};
