import styled from 'styled-components';

export const DateWrapper = styled.div`
  display: flex;
`;

export const ArrowLeft = styled.div`
  align-items: baseline;
  cursor: pointer;
  width: 36px;
  height: 36px;
  z-index: 2;
`;

export const ArrowRight = styled.div<{
  isActive?: boolean;
}>`
  align-items: baseline;
  cursor: ${(props) => (props.isActive ? 'pointer' : '')};
  z-index: 2;
`;

export const Date = styled.div`
  font-size: 24px;
  font-weight: 700;
  align-items: baseline;
  padding: 4px 4px 0 5px;
  user-select: none;
  display: flex;
  gap: 5px;
`;

export const HiddenDatePicker = styled.div`
  .SingleDatePickerInput {
    border: none;
    width: 183px;
    height: 36px;
    display: block;
    margin: 0;

    > button {
      z-index: 1;
      height: 46px;
    }
  }
  .DateInput_input {
    display: none;
  }
  .DateInput__small {
    padding-top: 9px;
  }
  .SingleDatePickerInput_calendarIcon {
    position: absolute;
    top: -43%;
    right: -8%;
  }
  .SingleDatePicker_picker {
    z-index: 10 !important;
  }
  .SingleDatePickerInput {
    width: 200px;
  }
  .DayPicker {
    position: absolute;
    top: 5px;
    left: -60px;
  }
  .DateInput_fang {
    display: none;
  }
  .CalendarMonthGrid_1 {
    width: 300px !important;
  }
`;
