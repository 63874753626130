import { useEffect } from 'react';
import { Heading } from '@components/elements/typographies/Heading';
import * as S from './RowMaterialAllocationHeaderControl.styles';
import DateSelector from '@components/elements/inputs/DateSelector/DateSelector';
import { Todo } from '@lib/common/type';
import StockAllocationSelect from '@components/modules/pc/stock_allocation_status/StockAllocationSelect/StockAllocationSelect';
import { Button } from '@components/elements/buttons/Button';

type Props = {
  storerooms: Todo;
  selectedStoreroomId: Todo;
  setSelectedStoreroomId: (selectedStoreroomId: number) => void;
  selectedDate: Date;
  getBeforeDate: (selectedDate: Date) => void;
  getNextDate: (selectedDate: Date) => void;
  setPopUp: (popUp: boolean) => void;
  handleClick: () => void;
  setDate: (date: Date) => void;
};

const RowMaterialAllocationHeaderControl = ({
  storerooms,
  selectedStoreroomId,
  setSelectedStoreroomId,
  selectedDate,
  getBeforeDate,
  getNextDate,
  setPopUp,
  handleClick,
  setDate,
}: Props) => {
  useEffect(() => {
    setPopUp(false);
  }, [selectedStoreroomId]);

  return (
    <S.Wrapper>
      <S.PageTitle>
        <S.MaterialType>原材料</S.MaterialType>
        <S.PageTitleText>
          <Heading text="払出状況" fs="32" fw="lg" />
          <Heading text="を入力" fs="24" fw="lg" />
        </S.PageTitleText>
      </S.PageTitle>
      <div className="selector-wrapper">
        <div className="selector-wrapper__stock_delivery_select">
          <StockAllocationSelect
            data={storerooms}
            value={selectedStoreroomId}
            setSelectedId={setSelectedStoreroomId}
            height={'42px'}
            marginTop={'0.4rem'}
          />
        </div>
        <S.ButtonWrapper>
          <Button
            width="90px"
            margin={'0 12px 0 0'}
            outlined={true}
            borderWidth={1}
            onClick={handleClick}
          >
            今日
          </Button>
        </S.ButtonWrapper>
        <div className="selector-wrapper__date_select">
          <DateSelector
            date={selectedDate}
            getBeforeDate={getBeforeDate}
            getNextDate={getNextDate}
            setDate={setDate}
          />
        </div>
      </div>
    </S.Wrapper>
  );
};

export default RowMaterialAllocationHeaderControl;
