import { useMutation } from 'react-query';
import { axiosInstance } from '@lib/pc/common/api/axiosConfig';
import type { InputParams } from '@components/modules/pc/wrapping_status/WrappingContents/WrappingContents';
import { Todo } from '@lib/common/type';

const useUpdateWrappingEndTimeMutation = (
  date: Date,
  factoryId: number,
  factoryLineId: number
  // packagingCompletedTime: string,
  // handleSuccess: () => void
) => {
  return useMutation(
    async (time: InputParams) => {
      return await axiosInstance().patch(
        `/api/v1/production_results/?factory_id=${factoryId}&factory_line_id=${factoryLineId}&date=${date}&packaging_completed_time=${time}`
      );
    },
    {
      onSuccess: () => {
        alert('終了時刻を更新しました');
        // handleSuccess();
      },
      onError: (error: Todo) => {
        alert(error.response.data.message || 'エラーが発生しました');
      },
      // onError: () => alert('エラーが発生しました'),
    }
  );
};

export default useUpdateWrappingEndTimeMutation;
