import { useParams } from 'react-router-dom';
import { WithHeader } from '@components/templates/mobile/WithHeader';
import TitleBar from '@components/modules/mobile/products_stocks/TitleBar/TitleBar';
import * as S from './ProductsStocks.styles';
import SideBar from '@components/modules/mobile/products_stocks/SideBar/SideBar';
import TableBody from '@components/modules/mobile/products_stocks/TableBody/TableBody';
import useAllStocks from '@lib/mobile/common/hooks/useAllStocks';

export const ProductsStocksRoot = () => {
  const { id, stock_kind } = useParams<{ id: string; stock_kind: string }>();
  const { data: products } = useAllStocks();
  const product = products?.find(
    (product) =>
      product.id === Number(id) && product.productKind === Number(stock_kind)
  );

  //TODO： StockコントローラでProduct.idで取得していないためデータが取得できない。
  // バックエンドへ投げる際には、半製品か完成品かの情報を渡す必要がある。
  if (!product) return null;

  return (
    <WithHeader>
      <S.Wrapper>
        <TitleBar productName={product.name} />
        <S.Table>
          <SideBar />
          {product.stocks && (
            <TableBody product={product} stocks={product.stocks} />
          )}
        </S.Table>
      </S.Wrapper>
    </WithHeader>
  );
};
