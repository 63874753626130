import { FC, useState } from 'react';
import TitleBar from '@components/modules/mobile/tags_for_stocks_modal/TitleBar/TitleBar';
import TagSearchForm from '@components/modules/mobile/tags_for_stocks_modal/TagSearchForm/TagSearchForm';
import TagList from '@components/modules/mobile/tags_for_stocks_modal/TagList/TagList';
import * as S from './TagsForStocksModal.styles';
import useTags from '@lib/mobile/common/hooks/useTags';
import ScrollToTop from '@components/modules/common/ScrollToTop/ScrollToTop';
import { MATERIAL_PRODUCT_KIND, PRODUCT_KIND } from '@lib/common/type';

type Props = {
  setTagsOpen: (tagsOpen: boolean) => void;
  selectedTagIds: number[];
  setSelectedTagIds: (selectedTagIds: number[]) => void;
  fromComponent?: string;
};

const TagsForStocksModal: FC<Props> = ({
  setTagsOpen,
  selectedTagIds,
  setSelectedTagIds,
  fromComponent,
}) => {
  const [keyword, setKeyword] = useState('');
  const { data: allTags } = useTags(
    fromComponent === 'materialsForStocks'
      ? MATERIAL_PRODUCT_KIND.MATERIAL
      : fromComponent === 'productsForStocks'
      ? MATERIAL_PRODUCT_KIND.PRODUCT_AND_SEMI_PRODUCT
      : PRODUCT_KIND.PRODUCT_AND_SEMI_PRODUCT
  );
  const tags = allTags?.filter((tag) => tag.name.includes(keyword)) || [];

  return (
    <>
      <ScrollToTop />
      <S.Wrapper>
        <S.Header>
          <TitleBar
            selectedTagIds={selectedTagIds}
            setTagsOpen={setTagsOpen}
            setSelectedTagIds={setSelectedTagIds}
            fromComponent={fromComponent}
          />
          <TagSearchForm value={keyword} onChangeValue={setKeyword} />
        </S.Header>
        <S.TagList>
          <TagList
            tags={tags}
            selectedTagIds={selectedTagIds}
            setSelectedTagIds={setSelectedTagIds}
          />
        </S.TagList>
      </S.Wrapper>
    </>
  );
};

export default TagsForStocksModal;
