import { FC } from 'react';
import moment from 'moment';
import * as S from './RightSlideSidebar.styles';
import { Button } from '@components/elements/buttons/Button';
import { Todo } from '@lib/common/type';
import ScrollToTop from '@components/modules/common/ScrollToTop/ScrollToTop';
import { OrderDetail, SelectedProductsOrder } from '@lib/pc/order_summary/type';
import useOrderSummaryDetails from '@lib/pc/order_summary/useOrderSummaryDetails';
import { addComma } from '@lib/pc/production_schedule/functions';

type Props = {
  selectedDate: Todo;
  rightSlideBarOpen: boolean;
  handleCloseSidebar: () => void;
  selectedProductsOrder: SelectedProductsOrder | null;
  selectedProductId: number;
  selectedCustomerId: number;
};

const RightSlideSidebar: FC<Props> = ({
  selectedDate,
  rightSlideBarOpen,
  handleCloseSidebar,
  selectedProductsOrder,
  selectedProductId,
  selectedCustomerId,
}: Props) => {
  const formattedDate = moment(selectedDate).format('YYYY/MM/DD (ddd)');

  const { orderSummaryDetails } = useOrderSummaryDetails(
    moment(selectedDate).toDate(),
    selectedCustomerId,
    selectedProductId
  );

  if (!orderSummaryDetails) return null;

  return (
    <>
      <S.Wrapper open={rightSlideBarOpen}>
        <ScrollToTop />
        <S.ScrollableContainer>
          <S.HeadContainer>
            <S.HeadDate>{formattedDate}</S.HeadDate>
            <S.HeadProductName>
              {selectedProductsOrder?.productName}
            </S.HeadProductName>
          </S.HeadContainer>
          {orderSummaryDetails?.orderDetailSummaries?.length !== 0 ? (
            <S.DataContainer>
              <S.Table>
                <tbody>
                  <S.THead>
                    <S.THeadCell
                      width={'10'}
                      wordBreak={'keep-all'}
                      colSpan={1}
                      textAlign={'-webkit-left'}
                    >
                      出荷先
                    </S.THeadCell>
                    <S.THeadCell
                      width={'30'}
                      wordBreak={'keep-all'}
                      colSpan={1}
                      textAlign={'-webkit-right'}
                    >
                      個数
                    </S.THeadCell>
                  </S.THead>
                  {orderSummaryDetails &&
                    orderSummaryDetails.orderDetailSummaries.map(
                      (d: OrderDetail, index: number) => (
                        <S.TrCell
                          key={index}
                          wideBottom={
                            orderSummaryDetails.length === index + 1
                              ? true
                              : false
                          }
                        >
                          <S.TdCell
                            textAlign={'-webkit-left'}
                            width={'200px'}
                            fontWeight={700}
                          >
                            {d.destinationName}
                          </S.TdCell>
                          <S.TdCell textAlign={'-webkit-right'} width={'60px'}>
                            {addComma(d.amount)}
                          </S.TdCell>
                        </S.TrCell>
                      )
                    )}
                  <tr>
                    <S.TdCell
                      textAlign={'-webkit-left'}
                      width={'200px'}
                      fontWeight={550}
                    >
                      合計
                    </S.TdCell>
                    <S.TdCell
                      textAlign={'-webkit-right'}
                      width={'60px'}
                      fontWeight={550}
                    >
                      {addComma(orderSummaryDetails?.totalAmount)}
                    </S.TdCell>
                  </tr>
                </tbody>
              </S.Table>
            </S.DataContainer>
          ) : (
            <S.DataContainer>
              <S.MessageWrapper>
                <div>選択した日付の受注はありません。</div>
              </S.MessageWrapper>
            </S.DataContainer>
          )}
          <S.ButtonContainer invalid={false}>
            <Button borderWidth={1} onClick={handleCloseSidebar}>
              OK
            </Button>
          </S.ButtonContainer>
        </S.ScrollableContainer>
      </S.Wrapper>
    </>
  );
};

export default RightSlideSidebar;
