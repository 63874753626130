import { axiosInstance } from '@lib/pc/common/api/axiosConfig';
import { useQuery } from 'react-query';
import humps from 'humps';
import type { Todo } from '@lib/common/type';
// import { SinglePicking } from '@lib/pc/picking_by_shipping/type';

export const usePicking = (pickingId: number) => {
  const { data, refetch } = useQuery(['picking', pickingId], async () => {
    const data = await axiosInstance().get(
      `/api/v1/pickings/${pickingId}/for_pc`
    );

    return data;
  });

  const picking = data && (humps.camelizeKeys(data.data) as Todo);

  return { picking, refetch };
};

export default usePicking;
