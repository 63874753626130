import { axiosInstance } from '@lib/pc/common/api/axiosConfig';
import { useQuery } from 'react-query';
import humps from 'humps';
import moment from 'moment';
import { Todo } from '@lib/common/type';

export type StackingPackagingResult = {
  date?: Date;
  StackingResults?: factory[];
};

export type factory = {
  factoryId: number;
  factoryName: string;
  lines: Line[];
};

type Line = {
  factoryLineId: number;
  factoryLineName: string;
  packagingResult?: PackagingResult[];
};

type PackagingResult = {
  order: number | null;
  packagingBestBeforeDate?: string;
  packagingCompletedQuantity?: number;
  packagingId?: number;
  packagingPlannedQuantity?: number;
  productId?: number;
  productName?: string;
  productionResultId?: number;
  stackingResults?: StackingResult;
};

type StackingResult = {
  packagingId?: number;
  stackingBestBeforeDate?: Date;
  stackingComment?: string;
  stackingCompletedQuantity?: number;
  stackingEmployeeIds?: number[];
  stackingId?: number;
  stackingStartedTime?: string;
};

const useStackers = (date: Date, refetchInterval?: number) => {
  const { data, refetch } = useQuery(
    ['stackings', date],
    async () => {
      const data = await axiosInstance().get(
        `/api/v1/stackings?date=${moment(date).format('YYYYMMDD')}`
      );
      return data;
    },
    { refetchInterval }
  );

  const stackingResults = data && (humps.camelizeKeys(data.data) as Todo);

  return { stackingResults, refetch };
};

export default useStackers;
