import { useState } from 'react';
import { useHistory } from 'react-router-dom';
import * as S from './Form.styles';
import { Heading } from '@components/elements/typographies/Heading';
import PdfView from '@components/modules/pc/accepting_orders_edit/PdfContent/PdfContent';
import { ReactComponent as CloseIcon } from '@assets/icons/close.svg';
import { Button } from '@components/elements/buttons/Button';
import { PurchaseOrder } from '@lib/pc/purchase_orders_edit/type';

const Form = () => {
  const initDetail = {
    productName: '',
    unitPrice: 0,
    unitAmount: 0,
    unitTaxRate: 0,
  };

  const initData = {
    destination: '',
    inCharge: '',
    title: '',
    deadline: '',
    deliveryLocation: '',
    paymentTerms: '',
    purchaseOrderCode: '',
    issueDate: '',
    orderDetails: [initDetail],
  };

  const history = useHistory();
  const [newData, setNewData] = useState<PurchaseOrder>(initData);
  // const [details, setDetails] = useState<Todo>([]);
  const deleteRow = (index: number) => {
    console.log('---index 1511---', index);
    if (newData.orderDetails.length === 1) return;

    const tmp = [...newData.orderDetails];
    tmp.splice(index, 1);
    setNewData({ ...newData, orderDetails: tmp });
  };

  const handleChange = (
    e:
      | React.ChangeEvent<HTMLInputElement>
      | React.ChangeEvent<HTMLTextAreaElement>
  ) => {
    setNewData({ ...newData, [e.target.name]: e.target.value });
  };

  const handleChangeDetail = (
    e:
      | React.ChangeEvent<HTMLInputElement>
      | React.ChangeEvent<HTMLTextAreaElement>,
    index: number
  ) => {
    console.log('---orderDetails 1420---', newData.orderDetails);
    const newDetails = [...newData.orderDetails];
    const newDetail = {
      ...newDetails[index],
      [e.target.name]: e.target.value,
    };
    newDetails[index] = newDetail;
    console.log('---newDetail 1423---', newDetail);
    console.log('---newDetails 1423---', newDetails);
    setNewData({ ...newData, orderDetails: newDetails });
  };

  const handleClickAdd = () => {
    const details = newData.orderDetails;
    details.push(initDetail);
    setNewData({ ...newData, orderDetails: details });
  };

  return (
    <>
      <S.Wrapper>
        <S.HeadContainer>
          <S.PageTitle>
            <S.PageTitleText>
              <Heading text="受注情報" fs="32" fw="lg" />
              <Heading text="を編集" fs="24" fw="lg" />
            </S.PageTitleText>
          </S.PageTitle>
        </S.HeadContainer>

        <S.MainContent>
          <S.PdfViewContainer>
            <PdfView />
          </S.PdfViewContainer>
          <S.FormContainer>
            <S.FormMultiColumnContainer>
              {/* フォーム左カラム */}
              <S.FormLeftContainer>
                <S.FormLabel>取引先（御中）</S.FormLabel>
                <S.Input
                  type="text"
                  name="destination"
                  id="destination"
                  autoComplete="new-password"
                  align="left"
                  padding="0 10px"
                  onChange={handleChange}
                />
                <S.FormLabel>担当者（様）</S.FormLabel>
                <S.Input
                  type="text"
                  name="inCharge"
                  id="inCharge"
                  autoComplete="new-password"
                  align="left"
                  padding="0 10px"
                  onChange={handleChange}
                />
                <S.FormLabel>取引先詳細</S.FormLabel>
                <S.FormTextArea />
                <S.FormLabel>件名</S.FormLabel>
                <S.Input
                  type="text"
                  name="title"
                  id="title"
                  autoComplete="new-password"
                  align="left"
                  padding="0 10px"
                  onChange={handleChange}
                />
                <S.FormLabel>納入場所</S.FormLabel>
                <S.Input
                  type="text"
                  name="deliveryLocation"
                  id="deliveryLocation"
                  autoComplete="new-password"
                  align="left"
                  padding="0 10px"
                  onChange={handleChange}
                />
                <S.FormLabel>支払条件</S.FormLabel>
                <S.Input
                  type="text"
                  name="paymentTerms"
                  id="paymentTerms"
                  autoComplete="new-password"
                  align="left"
                  padding="0 10px"
                  onChange={handleChange}
                />
              </S.FormLeftContainer>

              {/* フォーム右カラム */}
              <S.FormRightContainer>
                <S.FormLabel>注文書番号</S.FormLabel>
                <S.Input
                  type="text"
                  name="purchaseOrderCode"
                  id="purchaseOrderCode"
                  autoComplete="new-password"
                  align="left"
                  padding="0 10px"
                  onChange={handleChange}
                />
                <S.FormLabel>発行日</S.FormLabel>
                <S.Input
                  type="date"
                  name="issueDate"
                  id="issueDate"
                  autoComplete="new-password"
                  align="left"
                  padding="0 10px"
                  value={newData?.issueDate || ''}
                  onChange={handleChange}
                  pattern="\d{4}-\d{2}-\d{2}"
                  max="9999-12-31"
                />
              </S.FormRightContainer>
            </S.FormMultiColumnContainer>
            <S.FormLabel>明細</S.FormLabel>
            <S.ProductList>
              <S.ProductListHeader>
                <S.ProductDeliveryDay>納期</S.ProductDeliveryDay>
                <S.ProductName>品目</S.ProductName>
                <S.ProductUnitAmount>数量</S.ProductUnitAmount>
                <S.ProductDelete>削除</S.ProductDelete>
              </S.ProductListHeader>
              <S.ProductListBody>
                {[...Array(newData.orderDetails.length)].map((_, index) => (
                  <S.ProductListRow key={index}>
                    <S.ProductDeliveryDay>
                      <S.Input
                        type="date"
                        name="deadline"
                        id="deadline"
                        autoComplete="new-password"
                        align="left"
                        padding="0 10px"
                        value={newData?.deadline || ''}
                        onChange={handleChange}
                        pattern="\d{4}-\d{2}-\d{2}"
                        max="9999-12-31"
                      />
                    </S.ProductDeliveryDay>
                    <S.ProductName>
                      <S.Input
                        type="text"
                        name="productName"
                        id="productName"
                        autoComplete="new-password"
                        align="left"
                        padding="0 10px"
                        value={
                          newData?.orderDetails[index]?.productName
                            ? newData.orderDetails[index].productName
                            : ''
                        }
                        onChange={(event) => {
                          handleChangeDetail(event, index);
                        }}
                      />
                    </S.ProductName>
                    <S.ProductUnitAmount>
                      <S.Input
                        type="text"
                        name="unitAmount"
                        id="unitAmount"
                        autoComplete="new-password"
                        align="left"
                        padding="0 10px"
                        value={
                          newData?.orderDetails[index]?.unitAmount
                            ? newData.orderDetails[index].unitAmount
                            : ''
                        }
                        onChange={(event) => {
                          handleChangeDetail(event, index);
                        }}
                      />
                    </S.ProductUnitAmount>
                    <S.ProductDelete>
                      <S.IconButton onClick={() => deleteRow(index)}>
                        <CloseIcon />
                      </S.IconButton>
                    </S.ProductDelete>
                  </S.ProductListRow>
                ))}
              </S.ProductListBody>
              <Button
                borderWidth={1}
                outlined
                onClick={handleClickAdd}
                // onClick={() => setDetails(details.concat({}))}
                width="136px"
                margin="8px 0 0 0"
              >
                品目を追加
              </Button>
            </S.ProductList>
            <S.FormLabel>備考</S.FormLabel>
            <S.FormTextArea />
          </S.FormContainer>
        </S.MainContent>

        <S.ButtonWrapper>
          <S.ButtonContainer>
            <Button
              borderWidth={1}
              outlined
              onClick={() => history.push(`/pc/accepting_orders`)}
            >
              キャンセル
            </Button>
            <Button
              onClick={() => {
                // productRequiredCheck(newData);
              }}
            >
              保存
            </Button>
          </S.ButtonContainer>
        </S.ButtonWrapper>
      </S.Wrapper>
    </>
  );
};

export default Form;
