import styled from 'styled-components';

export const Wrapper = styled.div<{
  display?: boolean;
  width?: string;
}>`
  display: flex;
  position: relative;
  text-align: center;
  width: ${(props) => (props.width ? props.width : '')};
  .case-label {
    font-size: 15px;
    align-items: center;
    display: flex;
    margin-right: 0.75rem;
  }
`;
