import { useMutation } from 'react-query';
import humps from 'humps';
import { axiosInstance } from '@lib/pc/common/api/axiosConfig';
import { AxiosError } from 'axios';
import { Todo, ErrorResponse } from '@lib/common/type';
import { SalesStoreEdit } from '../sales_store_edit/type';
// import { SalesStoreEdit } from './type';

// backendは未実装のため作成する必要あり。
const useCreateSalesStore = (
  setCreatedData: (createdData: Todo) => void,
  handleSuccess: () => void,
  setErrMsg: (errMsg: string) => void
) => {
  return useMutation(
    async (params: SalesStoreEdit) => {
      return await axiosInstance()
        .post(
          `/api/v1/settings/sales_stores`,
          humps.decamelizeKeys({
            sales_store: {
              code: params.code,
              name: params.name,
              shipping_destination_id: params.shippingDestinationId,
              customer_id: params.customerIds,
            },
          })
          // humps.decamelizeKeys({
          //   code: params.code,
          //   name: params.name,
          //   shipping_destination_id: params.shippingDestinationId,
          //   customer_id: params.customerId,
          // })
        )
        .then((response) => {
          setCreatedData(humps.camelizeKeys(response.data));
        });
    },
    {
      onSuccess: () => {
        handleSuccess?.();
      },
      onError: (error: AxiosError<ErrorResponse>) => {
        const message = error.response?.data.message || 'エラーが発生しました';
        setErrMsg(message);
      },
    }
  );
};

export default useCreateSalesStore;
