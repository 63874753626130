import { axiosInstance } from '@lib/pc/common/api/axiosConfig';
import { useQuery } from 'react-query';
import humps from 'humps';
import { ShippingDestination } from './type';

const useShippingDestination = (id: string) => {
  const { data, refetch } = useQuery(
    [`shippingDestination-${id}`, id],
    async () => {
      const data = await axiosInstance().get(
        `/api/v1/settings/shipping_destination/${id}`
      );
      return data;
    }
  );
  const shippingDestination = humps.camelizeKeys(
    data?.data.shipping_destination
  ) as unknown as ShippingDestination;

  return { shippingDestination, refetch };
};

export default useShippingDestination;
