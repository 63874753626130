import styled from 'styled-components';

export const InputContainer = styled.div`
  display: flex;
  flex-direction: column-reverse;
`;

export const NoteAmountContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: left;
  font-weight: 700;
  padding-top: 0.75rem;
  gap: 1rem;
`;

export const NoteAmountLabel = styled.div`
  width: 3.5rem;
  font-weight: 700;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 5.5rem;
`;

export const NoteAmountInput = styled.textarea`
  /* background: #f8f7f4; */
  border-radius: 8px;
  border: 2px solid ${(props) => props.theme.palette.white};
  width: 100%;
  height: 5.5rem;
  text-align: left;
  padding: 0.5rem;
  -webkit-text-fill-color: ${(props) => props.theme.palette.baseBlack};
  opacity: 1;

  resize: none;
`;

export const DWrapper = styled.div`
  padding: 0 0.875rem 1rem 0.5rem;
`;

export const FormWrapper = styled.div<{
  marginTop?: string;
}>`
  font-size: 15px;
  margin-bottom: 1rem;
  padding-top: 0.5rem;
  display: flex;
  justify-content: space-between;

  div:first-child {
    white-space: nowrap;
    font-weight: 700;
    margin-right: 0.5rem;
    margin-top: ${(props) => (props.marginTop ? props.marginTop : '')};
  }

  > input {
    height: 2.5rem;
    max-width: 5.25rem;
    width: 100%;
    border-radius: 8px;
    text-align: right;
    padding-right: 12px;
    opacity: 1;
    -webkit-text-fill-color: ${(props) => props.theme.palette.baseBlack};
    ::-webkit-inner-spin-button {
      -webkit-appearance: none;
    }

    :focus {
      outline: none;
    }
  }
`;

export const DRightBottomWrapper = styled.div`
  display: flex;
  align-items: center;
  padding: 0.5rem 0;

  div:first-child {
    white-space: nowrap;
    font-weight: 700;
    margin-right: 0.5rem;
  }
`;

export const DInputContainer = styled.div<{
  center?: boolean;
}>`
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 4px;
  > label {
    font-weight: 700;
    font-size: 13px;
  }
  > input {
    height: 2.5rem;
    max-width: 6.75rem;
    width: 100%;
    border-radius: 8px;
    text-align: ${(props) => (props.center ? 'center' : 'right')};
    padding-right: 12px;
    opacity: 1;
    -webkit-text-fill-color: ${(props) => props.theme.palette.baseBlack};
    ::-webkit-inner-spin-button {
      -webkit-appearance: none;
    }

    :focus {
      outline: none;
    }

    :disabled {
      border: 2px solid #ffffff;
      background-color: transparent;
    }
  }

  > span {
    position: absolute;
    right: 0.3rem;
    top: 80%;
    font-size: 12px;
    margin-left: 4px;
    color: #6b675c;
  }
`;

export const DSymbol = styled.div`
  margin-top: 1rem;
  padding: 0 5px;
  font-weight: 700;
  font-size: 13px;
`;

export const Input = styled.input<{
  labelLength?: boolean;
}>`
  width: 52%;
  height: 3.25rem;
  vertical-align: middle;
  color: ${(props) => props.theme.palette.baseBlack};
  font-size: 1rem;
  line-height: 1.25rem;
  padding: 0.325rem;
  background: ${(props) => props.theme.palette.white};
  border: none;
  border-radius: 0.5rem;
  border: 1px solid rgba(0, 0, 0, 0.08);
  margin-left: ${(props) => (props.labelLength ? '1rem' : '')};
  -webkit-appearance: none;
  ::-webkit-date-and-time-value {
    text-align: left;
    padding-left: 8px;
  }
`;

export const ButtonContainer = styled.div<{
  invalid: boolean;
}>`
  padding: 1rem 0px;
  width: 100%;
  display: flex;
  -webkit-box-align: center;
  align-items: center;
  justify-content: space-evenly;
  border-bottom: 2px solid #d3d3d3;

  & > button {
    width: 136px;
  }

  & > button:first-child {
    margin-right: 1rem;
  }
`;
