import * as S from './LogoutMenu.styles';
import { appVersion } from '@lib/common/constants';

type Props = {
  handleOnClickLogout: () => void;
};

const LogoutMenu = ({ handleOnClickLogout }: Props) => {
  return (
    <S.Wrapper>
      <S.FlexCenter>
        {/* 初期リリースではアカウント設定は不要 */}
        {/* <S.Avatar />
        <S.Name>山本 のぼる</S.Name>
        <S.Email>soumu@regnio.co.jp</S.Email>
        <S.Edit>編集</S.Edit> */}
        <S.Logout onClick={handleOnClickLogout}>ログアウト</S.Logout>
        <S.Version>ver. {appVersion}</S.Version>
        {/* <S.Reload>
          <S.ReloadText onClick={() => location.reload()}>
            再読み込み
          </S.ReloadText>
        </S.Reload> */}
      </S.FlexCenter>
    </S.Wrapper>
  );
};

export default LogoutMenu;
