import { Todo } from '@lib/common/type';
import moment from 'moment';

const today = moment();
const formattedDate = moment(today).format('YYYY-MM-DD');

// const shippingGroups = [
//   '福岡警固エリア',
//   'サトウ食品',
//   '北九州エリア',
//   '筑豊エリア',
//   '筑後エリア',
// ];

const shippingTime = [
  '08:00',
  '09:00',
  '10:00',
  '11:00',
  '12:00',
  '13:00',
  '14:00',
  '15:00',
  '16:00',
  '17:00',
  '18:00',
  '19:00',
  '20:00',
  '21:00',
];

// const productName = [
//   '商品A2本入',
//   '商品A3本入',
//   'ソフト豆腐',
//   'なめらか豆腐',
//   'たまご豆腐',
//   '厚揚げ',
//   '絹厚揚げ',
//   'もめん豆腐',
//   '五目がんも',
//   'うす揚げ',
//   'いなりあげ',
//   '旨み天',
// ];

const storeroomIds = [1, 3, 5, 11];
// const storeroomIds = [
//   1, 3, 5, 7, 9, 11, 13, 15, 17, 19, 21, 23, 25, 27, 29, 31, 33, 35, 37, 39,
// ];

// const selected = randomSelect(storeroomName.slice(), 2);

// 配列arrayからランダムにnum個の要素を取り出す
const randomSelect = (storeroomIds: Todo[], num: number) => {
  const newArray = [];

  while (newArray.length < num && storeroomIds.length > 0) {
    // 配列からランダムな要素を選ぶ
    const rand = Math.floor(Math.random() * storeroomIds.length);
    // 選んだ要素を別の配列に登録する
    newArray.push(storeroomIds[rand]);
    // もとの配列からは削除する
    // storeroomIds.splice(rand, 1);
  }

  return newArray;
};

const generatePickingDummy = () => {
  return [...Array(24)].map((_, i) => {
    return {
      id: i,
      groupName: `出荷先グループ${i}`,
      // groupName:
      //   shippingGroups[Math.floor(Math.random() * shippingGroups.length)],
      shippingScheduledTime:
        shippingTime[Math.floor(Math.random() * shippingTime.length)],
      // shippingScheduledTime: '2023-01-01:8:00',
      numberOfProducts: 2,
      totalAmount: 50014,
      employeeIds: [1, 2],
      status: Math.floor(Math.random() * 3),
      // 配送グループ別に、配送グループが持つ商品の在庫室idをまとめてバックエンドからもらうようにする
      storeroomIds: randomSelect(storeroomIds.slice(), 2),
      // storeroomIds: [1, 3],
      // 商品別情報
      // products: generateProductDummy(),
    };
  });
};

export const PICKING_DUMMY_DATA = {
  shippingDate: formattedDate,
  useAllocation: false,
  shippings: generatePickingDummy(),
};

// 以下、参考ーーーーーーーーーーーー

export const generateStoreDummy = () => {
  return [...Array(30)].map((_, i) => {
    return {
      storeCode: 10 + i,
      name: `支店${i}`,
      amount: Math.floor(Math.random() * 1000),
    };
  });
};

export const generatebranchDummy = () => {
  return [...Array(30)].map((_, i) => {
    return {
      name: `商品${i}`,
      productCode: Math.floor(Math.random() * 100000),
      data: generateStoreDummy(),
    };
  });
};

export const pickingDummyData = {
  company: 'サトウ食品',
  shippingDate: new Date(),
  pickingPerson: '田中',
  wCheckPerson: null,
  status: 'waiting_picking',

  productData: generatebranchDummy(),
};
