import type { Tag } from '@lib/common/type';
import type { TagNameInputParam } from '@lib/pc/settings/product_master/type';
import {
  CaseForSetting,
  CaseNameInputParam,
} from '../product_master_setting/type';

// 新規タグ追加＆既存タグ編集時、重複チェック
export const tagDuplicateCheck = (
  newTag: TagNameInputParam,
  data: Tag[],
  beforeEditTag?: string
) => {
  if (beforeEditTag && newTag.name === beforeEditTag) {
    return true;
  }
  const tagsName = data?.map((item) => item['name']);
  if (tagsName?.includes(newTag.name)) {
    return false;
  } else {
    return true;
  }
};

// 新規タグ追加＆既存タグ編集時、重複チェック
export const caseDuplicateCheck = (
  newCase: CaseNameInputParam,
  data: CaseForSetting[],
  beforeEditTag?: string
) => {
  if (beforeEditTag && newCase.name === beforeEditTag) {
    return true;
  }
  const casesName = data?.map((item) => item['name']);
  if (casesName?.includes(newCase.name)) {
    return false;
  } else {
    return true;
  }
};
