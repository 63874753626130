import styled from 'styled-components';

export const Wrapper = styled.button<{
  disabled: boolean;
  estimated?: boolean;
}>`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 10px;
  background: ${(props) =>
    props.disabled
      ? '#e0e0e0'
      : props.estimated
      ? props.theme.palette.white
      : props.theme.palette.primary.main};
  color: ${(props) =>
    props.disabled
      ? props.theme.palette.khaki
      : props.estimated
      ? props.theme.palette.primary.main
      : props.theme.palette.primary.contrastText};
  border: ${(props) =>
    props.disabled
      ? `2px solid #e0e0e0`
      : `2px solid ${props.theme.palette.primary.main}`};
  font-weight: bold;
  font-size: 16px;
  text-align: center;

  border-radius: 8px;
  padding: 0.75rem 0;
  border-width: 1px;
  width: 50%;
  cursor: ${(props) => (props.disabled ? 'initial' : 'pointer')};
`;
