import styled from 'styled-components';

export const Wrapper = styled.div<{
  bgColor?: string;
}>`
  width: 100vw;
  height: calc(100vh - 193px);
  padding: 16px 80px 150px;
  overflow: scroll;
  background-color: ${(props) => props.theme.palette.beige};
  .go-back {
    width: 160px;
    display: flex;
    padding-bottom: 32px;
    cursor: pointer;
    color: ${(props) => props.theme.palette.blue};
    > span {
      padding-top: 8px;
      padding-left: 10px;
    }
  }
`;

/**
 * 各商品の情報
 */
export const ProductMasterEditContents = styled.div`
  padding-bottom: 18px;
  .product-info {
    display: flex;
    /* 商品画像 **********/
    .product-img {
      grid-area: product-img;
      width: 60px;
      height: 60px;
      background-color: #ececec;
      display: flex;
      justify-content: center;
      align-items: center;
      .img {
        img {
          width: 60px;
          height: 60px;
          object-fit: contain;
        }
      }
    }
    .name {
      display: flex;
      align-items: center;
      padding-left: 11px;
      font-size: 24px;
    }
  }

  .case-list__header {
    padding-top: 48px;
    color: ${(props) => props.theme.palette.primary.main};
    &--edit-btn {
      width: 320px;
      display: flex;
      margin-left: 8px;
      cursor: pointer;
      .icon {
        margin-right: 8px;
      }
    }
  }
`;

export const Input = styled.input`
  width: 90%;
  height: 44px;
  color: ${(props) => props.theme.palette.baseBlack};
  font-size: 1rem;
  padding: 0.75rem;
  text-align: end;
  outline: none;
  border-radius: 0.5rem;
  border: 1px solid rgba(0, 0, 0, 0.08);
  background: ${(props) => props.theme.palette.white};
  ::-webkit-inner-spin-button {
    -webkit-appearance: none;
  }
`;

export const EditButtonWrapper = styled.div`
  width: 40px;
`;

export const EditButton = styled.div`
  text-align: -webkit-center;
  cursor: pointer;
`;

export const ButtonContainer = styled.div`
  position: fixed;
  width: 100vw;
  bottom: 0;
  padding: 22px 0;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #f8f7f4;
  & > button {
    width: 136px;
    &.disabled {
      background: #e0e0e0;
      border-color: #e0e0e0;
      color: #6b675c;
      cursor: initial;
    }
  }
`;

export const ProductMasterEditTable = styled.table`
  width: 100%;
`;

export const TitleTh = styled.th`
  font-weight: 700;
  text-align: left;
  padding-left: 8px;
`;

export const AddButton = styled.div`
  padding-top: 24px;
  justify-content: center;
  align-items: center;
  margin-left: auto;
  dd {
    text-align: -webkit-right;
  }
  button {
    width: 64px;
    &.disabled {
      background: #e0e0e0;
      border-color: #e0e0e0;
      color: #6b675c;
      cursor: initial;
    }
  }
`;

export const SelectedCaseWrapperTr = styled.tr`
  margin: 12px 0;
  min-height: 40px;
  border-bottom: solid 1px #cdcdcd;
`;

export const DeleteButtonTd = styled.td`
  margin: 12px 0;
`;

export const CloseButton = styled.div`
  width: 40px;
  height: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const CaseTypeTd = styled.td`
  width: 38%;
  margin: 12px 0;
`;

export const PiecesPerBoxAndDisplayOrderTd = styled.td<{
  editMode: boolean;
}>`
  text-align: ${(props) => (props.editMode ? 'center' : 'end')};
  padding: ${(props) => (props.editMode ? '0px' : '25px')};
  margin: 12px 0;
  > span {
    top: 6px;
    left: -30px;
    position: relative;
    font-size: 15px;
    margin-left: 4px;
    color: #6b675c;
  }
`;

export const PiecesPerBoxAndDisplayOrderDiv = styled.div<{
  editMode: boolean;
}>`
  text-align: end;
  padding: ${(props) => (props.editMode ? '25px' : '0px')};
`;

export const PiecesPerBoxAndDisplayOrderInput = styled.input<{
  padding?: string;
}>`
  width: 90%;
  height: 44px;
  color: ${(props) => props.theme.palette.baseBlack};
  font-size: 1rem;
  padding: ${(props) => (props.padding ? props.padding : '0.75rem')};
  text-align: end;
  border: none;
  border-radius: 0.5rem;
  border: 1px solid rgba(0, 0, 0, 0.08);
  margin: 12px 0;
  background: ${(props) => props.theme.palette.white};
  ::-webkit-inner-spin-button {
    -webkit-appearance: none;
  }
`;

export const EditButtonTd = styled.td`
  text-align: -webkit-center;
  margin: 12px 0;
`;
