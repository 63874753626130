import { axiosInstance } from '@lib/pc/common/api/axiosConfig';
import { useQuery } from 'react-query';
import humps from 'humps';
import { Todo } from '@lib/common/type';

const useMaterials = () => {
  const { data, refetch } = useQuery(['materials_for_settings'], async () => {
    const data = await axiosInstance().get(`/api/v1/settings/materials`);
    return data;
  });
  const materials = humps.camelizeKeys(data?.data?.materials) as Todo;

  return { materials, refetch };
};

export default useMaterials;
