import React, { useEffect, useState } from 'react';
import { Heading } from '@components/elements/typographies/Heading';
// import { format } from 'date-fns';
import moment from 'moment';
import Filter from '@assets/icons/filter_list';
import * as S from './PickingByShippingDetail.styles';
import { ReactComponent as CheckedIcon } from '@assets/icons/mobile/check.svg';
import { ReactComponent as CheckIcon } from '@assets/icons/mobile/check_circle_outline_24px.svg';
import { ReactComponent as FilteredIcon } from '@assets/icons/filtered.svg';
import TagMenu from '@components/modules/common/TagMenu/TagMenu';
import TagListModal from '@components/modules/common/TagListModal/TagListModal';
import Overlay from '@components/elements/backdrops/Overlay/Overlay';
import AddPickingProductModal from '@components/modules/pc/picking_by_shipping/picking_by_shipping_detail/AddPickingProductModal/AddPickingProductModal';
import CheckedRightSlideSidebar from '@components/modules/pc/picking_by_shipping/picking_by_shipping_detail/CheckedRightSlideSidebar/CheckedRightSlideSidebar';
import ErrorMsgPopUp from '@components/modules/common/ErrorMsgPopUp/ErrorMsgPopUp';
import ShippingStopModal from '@components/modules/pc/picking_by_shipping/picking_by_shipping_detail/ShippingStopModal/ShippingStopModal';
import RightSlideSidebar from '@components/modules/pc/picking_by_shipping/picking_by_shipping_detail/RightSlideSidebar/RightSlideSidebar';
import { PRODUCT_KIND, Product, Todo } from '@lib/common/type';
import { tagSearch } from '@lib/common/functions';
import {
  Destination,
  DetailsByDestination,
  PickingDetails,
  selectedItem,
} from '@lib/pc/picking_by_shipping/type';
import { PICKING_STATUS } from '@lib/mobile/picking_by_shipping/type';
import useProducts from '@lib/pc/common/hooks/useProducts';
import useStopShippingMutation from '@lib/pc/picking_by_shipping/useStopShippingMutation';
import useUpdateTotalMutation from '@lib/pc/picking_by_shipping/useUpdateTotalMutation';
import useCreateTotalMutation from '@lib/pc/picking_by_shipping/useCreateTotalMutation';
import UpdatePopUp from '@components/modules/common/UpdatePopUp/UpdatePopUp';

type Props = {
  id: string;
  data: Todo;
  refetch: () => void;
  openAddPickingProductModal: boolean;
  setOpenAddPickingProductModal: (openAddPickingProductModal: boolean) => void;
};

const PickingByShippingDetail = ({
  id,
  data,
  refetch,
  openAddPickingProductModal,
  setOpenAddPickingProductModal,
}: Props) => {
  const initializeSelectedItem = {
    id: 0,
    productId: '',
    productName: '',
    total: 0,
    totalAmount: 0,
    shippingId: 0,
    shippingName: '',
    checkState: false,
    stopShipping: false,
  };
  const [searchWord, setSearchWord] = useState('');
  const [tagMenuActive, setTagMenuActive] = useState(false);
  const [tagListModalActive, setTagListModalActive] = useState(false);
  const [selectedTags, setSelectedTags] = useState<Todo>([]);
  const selectedTagIds = selectedTags.map((item: Todo) => item['id']);
  const [selectedProduct, setSelectedProduct] = useState('');
  const [selectedPlace, setSelectedPlace] = useState('');
  const formattedDate = moment(data?.shippingDate).format('YYYY/MM/DD');
  const formattedDayOfWeek = moment(data?.shippingDate).format('(ddd)');
  // 更新するpickingDetailId
  const [updatePickingDetailId, setUpdatePickingDetailId] = useState(0);
  // 更新するstopShipping
  const [stopShippingState, setStopShippingState] = useState(false);
  // 更新、作成popup表示用
  const [popUpProductName, setPopUpProductName] = useState('');
  const [popUpShippingName, setPopUpShippingName] = useState('');
  const [updatePopUp, setUpdatePopUp] = useState(false);
  const [messageKind, setMessageKind] = useState('');
  const [errMsg, setErrMsg] = useState('');
  const [rightSlideSidebarActive, setRightSlideSidebarActive] = useState(false);
  const [checkedRightSlideSidebarActive, setCheckedRightSlideSidebarActive] =
    useState(false);
  const [popUp, setPopUp] = useState(false);
  const [initialize] = useState<Todo>(initializeSelectedItem);
  const [openStopModal, setOpenStopModal] = useState(false);
  const [selectedProductId, setSelectedProductId] = useState(0);
  const [selectedShippingId, setSelectedShippingId] = useState(0);
  const [changeAmount, setChangeAmount] = useState<number | string>('');
  // ピッキング商品追加modal画面用ピッキング数
  const [changeProductsAmount, setChangeProductsAmount] = useState<
    number | string
  >('');
  const [selectedEditItem, setSelectedEditItem] =
    useState<selectedItem>(initialize);
  const { data: products } = useProducts();
  const windowWidth = window.innerWidth / 2;

  // 出荷先情報設定
  const branchData = data?.shippingDestinations.map(
    (d: Todo, index: number) => ({
      id: index + 1,
      name: d.name,
      storeCode: d.code,
      checkState: d.destinationCheckState,
      allocationCheckState: d.destinationAllocationCheckState,
    })
  );

  // modal画面用の出荷先
  const shippingDestinationsSelect = data?.shippingDestinations?.filter(
    (d: Destination) => d.name !== '予定合計' && d.name !== '実合計'
  );

  const handleSearch = (e: Todo) => {
    setSearchWord(e.target.value);
  };

  type ShippingDestination = {
    code: string | null;
    destinationCheckState: boolean;
    id: number | null;
    name: string;
  };

  const findSelectedShipping = (shippingName: string) => {
    const selectedShipping = data?.shippingDestinations.find(
      (s: ShippingDestination) => s.name === shippingName
    );
    return selectedShipping;
  };

  const onClickCell = (p: PickingDetails, d: DetailsByDestination) => {
    if (openAddPickingProductModal || rightSlideSidebarActive) return;
    const selectedShipping = findSelectedShipping(d.name);
    setPopUpProductName(p.productName);
    setPopUpShippingName(d.name);
    setSelectedEditItem({
      id: d.id,
      productId: p.productId,
      productName: p.productName,
      shippingId: selectedShipping.id,
      shippingName: d.name,
      total: d.total,
      totalAmount: d.totalAmount,
      checkState: d.checkState,
      stopShipping: d.stopShipping === null ? false : d.stopShipping,
    });
    setUpdatePickingDetailId(d.id);
    if (d.checkState) {
      setCheckedRightSlideSidebarActive(!checkedRightSlideSidebarActive);
    } else {
      setRightSlideSidebarActive(!rightSlideSidebarActive);
      setUpdatePopUp(false);
    }
  };

  // modal画面で出荷止めOK(idがないものは出荷止されない)
  const handleStopShipping = () => {
    stopShippingMutation.mutate({
      stopShipping: !selectedEditItem.stopShipping,
    });
  };

  // 更新成功
  const handleSuccess = () => {
    setUpdatePickingDetailId(0);
    setStopShippingState(false);
    setSelectedProduct('');
    setSelectedPlace('');
    setErrMsg('');
    refetch();
    setOpenStopModal(false);
    setRightSlideSidebarActive(false);
    setOpenAddPickingProductModal(false);
    setChangeAmount(0);
    setChangeProductsAmount(0);
    setSelectedProductId(0);
    setSelectedShippingId(0);
    setUpdatePopUp(!updatePopUp);
  };

  const handleUpdateSuccess = () => {
    setMessageKind('update');
    handleSuccess();
  };

  const handleCreateSuccess = () => {
    setMessageKind('create');
    handleSuccess();
  };

  const handleStopShippingSuccess = () => {
    setMessageKind('stop');
    handleSuccess();
  };

  // 出荷停止の更新
  const stopShippingMutation = useStopShippingMutation(
    updatePickingDetailId && updatePickingDetailId,
    handleStopShippingSuccess,
    setErrMsg
  );

  // 既存データありのピッキング数更新
  const updateTotalMutation = useUpdateTotalMutation(
    updatePickingDetailId && updatePickingDetailId,
    handleUpdateSuccess,
    setErrMsg
  );

  // 既存データなしのピッキング数作成
  const createTotalMutation = useCreateTotalMutation(
    handleCreateSuccess,
    setErrMsg
  );

  const existProductIds = data?.pickingDetails?.map(
    (item: Todo) => item['productId']
  );
  // すでにデータにある商品はselectから除外する
  const filteredProduct = products?.filter(
    (p: Product) => !existProductIds?.includes(p.id)
  );

  // 個数を変更する場合
  const handleUpdateProductAmount = () => {
    if (updatePickingDetailId) {
      // 更新処理
      updateAmount();
    } else {
      // 作成処理
      createAmount();
    }
  };

  // 更新処理
  const updateAmount = () => {
    if (changeAmount === 0) {
      setErrMsg('ピッキング数を入力してください。');
    } else {
      updateTotalMutation.mutate({ total: Number(changeAmount) });
    }
  };

  // セルからの作成処理
  const createAmount = () => {
    if (changeAmount === 0) {
      setErrMsg('ピッキング数を入力してください。');
    } else {
      createTotalMutation.mutate({
        pickingId: Number(id),
        productId: selectedEditItem.productId,
        shippingDestinationId: selectedEditItem.shippingId,
        total: Number(changeAmount),
      });
    }
  };

  // ピッキング商品追加処理
  const createNewProductShippingAmount = () => {
    if (selectedProductId === 0) {
      setErrMsg('製品を選択してください。');
    } else if (selectedShippingId === 0) {
      setErrMsg('出荷先を選択してください。');
    } else if (changeProductsAmount === 0) {
      setErrMsg('ピッキング数を入力してください。');
    } else {
      createTotalMutation.mutate({
        pickingId: Number(id),
        productId: selectedProductId,
        shippingDestinationId: selectedShippingId,
        total: Number(changeProductsAmount),
      });
    }
  };

  const allocateTrueStatus =
    data &&
    (data.pickingStatus === PICKING_STATUS.ALLOCATION_NOT_STARTED ||
      data.pickingStatus === PICKING_STATUS.ALLOCATION_IN_PROGRESS)
      ? true
      : false;

  useEffect(() => {
    setTagMenuActive(false);
  }, [selectedTagIds.length === 0]);

  return (
    <React.Fragment>
      <S.ListWrapper>
        <S.ListSidebar>
          <S.ListLeftHeadSearchCell>
            <S.FlexColumn>
              <Heading tag="span" text="出荷日" fs="16" fw="lg" />
              <Heading
                tag="span"
                text={formattedDate + formattedDayOfWeek}
                fs="24"
                fw="lg"
              />
              <Heading
                tag="span"
                text={
                  data?.shippingScheduledTime &&
                  moment(data?.shippingScheduledTime).format('LT')
                }
                fs="18"
                fw="lg"
              />
            </S.FlexColumn>

            <S.ListLeftHeadCell
              searchCell={true}
              className="filtered-products-wrapper"
              onClick={() => setTagMenuActive(!tagMenuActive)}
            >
              {selectedTags.length > 0 || searchWord ? (
                <div className="filtered-products">
                  <FilteredIcon />
                  <div className="filtered-products-text">商品の絞り込み中</div>
                </div>
              ) : (
                <div className="not-filtered-products">
                  <Filter />
                  <div className="filtered-products-text">商品の絞り込み</div>
                </div>
              )}
            </S.ListLeftHeadCell>
          </S.ListLeftHeadSearchCell>
          {/* 商品名 */}
          {data?.pickingDetails
            .filter(
              (p: PickingDetails) =>
                p?.productName.includes(searchWord) ||
                p?.productNameAbbreviation?.includes(searchWord) ||
                p?.productNameKana?.includes(searchWord) ||
                p?.productCode?.toString().includes(searchWord)
            )
            .filter((p: Todo) => tagSearch(p, selectedTagIds))
            .map((s: PickingDetails, i: number) => {
              return (
                <S.ListLeftHeadCell
                  className="product-data"
                  key={s.productName + i + Math.random()}
                >
                  <S.ProductInfo key={s.productId + i + Math.random()}>
                    <div key={s.productName + i + Math.random()}>
                      {s.productName}
                    </div>
                    <span key={s.productCode + i + Math.random()}>
                      {s.productCode}
                    </span>
                  </S.ProductInfo>
                </S.ListLeftHeadCell>
              );
            })}
          <S.AddProductButton
            width={windowWidth}
            rightSlideSidebarActive={rightSlideSidebarActive}
            onClick={() => {
              if (!rightSlideSidebarActive) {
                setOpenAddPickingProductModal(!openAddPickingProductModal);
              }
            }}
          >
            + 追加
          </S.AddProductButton>
        </S.ListSidebar>
        <S.ListBody>
          {/* 合計・出荷先 */}
          <S.ListRow>
            {branchData?.map((p: Todo, i: number) => {
              return (
                <S.ListHeadCell
                  className="shop-header"
                  key={i + p.storeCode + Math.random()}
                  checked={p.checkState}
                >
                  {i > 1 && (
                    <S.CheckArea
                      checked={
                        p.checkState ||
                        (allocateTrueStatus && p.allocationCheckState === true)
                      }
                      key={p.id + i + Math.random()}
                    >
                      <S.CheckIconWrapper key={p.id + i + Math.random()}>
                        {p.checkState === true ||
                        (allocateTrueStatus &&
                          p.allocationCheckState === true) ? (
                          <CheckedIcon />
                        ) : (
                          <CheckIcon />
                        )}
                      </S.CheckIconWrapper>
                      <S.StatusName
                        checked={
                          p.checkState ||
                          (allocateTrueStatus &&
                            p.allocationCheckState === true)
                        }
                        key={p.id + i + Math.random()}
                      >
                        {allocateTrueStatus && p.allocationCheckState === true
                          ? '　振分済'
                          : p.checkState === true
                          ? 'チェック済'
                          : '未チェック'}
                      </S.StatusName>
                    </S.CheckArea>
                  )}

                  <span key={p.id + i + Math.random()}>{p.storeCode}</span>
                  <div
                    className="destination-name"
                    key={p.id + i + Math.random()}
                  >
                    {p.name}
                  </div>
                </S.ListHeadCell>
              );
            })}
          </S.ListRow>
          {data?.pickingDetails
            .filter(
              (p: PickingDetails) =>
                p.productName.includes(searchWord) ||
                p.productCode?.toString().includes(searchWord)
            )
            .filter((p: Todo) => tagSearch(p, selectedTagIds))
            // 商品別合計個数表示箇所
            .map((p: PickingDetails, idx: number) => {
              return (
                <S.ListRow
                  className="picking-amount"
                  key={p?.productCode + idx + Math.random()}
                >
                  {p.detailsByDestination.map(
                    (d: DetailsByDestination, idx: number) => {
                      return (
                        <React.Fragment key={idx + d.name}>
                          {d.stopShipping ? (
                            <S.ListCell
                              key={idx}
                              className="stop-shipping"
                              disable={
                                openAddPickingProductModal ||
                                rightSlideSidebarActive
                              }
                              checkState={d.checkState}
                              onClick={() => onClickCell(p, d)}
                            >
                              出荷止め
                            </S.ListCell>
                          ) : d.name === '予定合計' ? (
                            <S.ListCell
                              key={idx}
                              onClick={() => onClickCell(p, d)}
                            >
                              {d?.amount}
                            </S.ListCell>
                          ) : d.name === '実合計' ? (
                            <S.ListCell
                              key={idx}
                              onClick={() => onClickCell(p, d)}
                            >
                              {d?.totalAmount}
                            </S.ListCell>
                          ) : (
                            <S.ListCell
                              key={idx}
                              className="picking-amount"
                              disable={
                                openAddPickingProductModal ||
                                rightSlideSidebarActive
                              }
                              checkState={d.checkState}
                              notEqual={
                                d.checkState === true &&
                                d.totalAmount !== d.total
                                  ? true
                                  : false
                              }
                              shortage={
                                d.checkState === true && d.totalAmount < d.total
                              }
                              onClick={() => onClickCell(p, d)}
                            >
                              {d?.checkState ? d?.totalAmount : d?.total}
                            </S.ListCell>
                          )}
                        </React.Fragment>
                      );
                    }
                  )}
                </S.ListRow>
              );
            })}
        </S.ListBody>
      </S.ListWrapper>
      {tagMenuActive && (
        <TagMenu
          searchParam={searchWord}
          selectedTags={selectedTags}
          setSelectedTags={(tags) => setSelectedTags(tags)}
          setOpenTagModal={() => setTagListModalActive(true)}
          handleChange={handleSearch}
          deleteSearchParam={() => setSearchWord('')}
        />
      )}
      {tagListModalActive && (
        <TagListModal
          selectedTags={selectedTags}
          setSelectedTags={(tags) => setSelectedTags(tags)}
          tagListModalActive={tagListModalActive}
          productKind={PRODUCT_KIND.PRODUCT}
          handleClose={() => {
            setTagListModalActive(false);
            setTagMenuActive(false);
          }}
        />
      )}
      {rightSlideSidebarActive && (
        <RightSlideSidebar
          popUp={popUp}
          setPopUp={setPopUp}
          handleCloseSidebar={() => setRightSlideSidebarActive(false)}
          refetch={refetch}
          selectedEditItem={selectedEditItem}
          data={data}
          setSelectedProduct={setSelectedProduct}
          setSelectedPlace={setSelectedPlace}
          handleStopShipping={handleStopShipping}
          openStopModal={openStopModal}
          setOpenStopModal={setOpenStopModal}
          changeAmount={changeAmount}
          setChangeAmount={setChangeAmount}
          handleUpdateProductAmount={handleUpdateProductAmount}
        />
      )}
      {checkedRightSlideSidebarActive && (
        <CheckedRightSlideSidebar
          onClickOK={() => setCheckedRightSlideSidebarActive(false)}
          selectedEditItem={selectedEditItem}
          data={data}
        />
      )}
      {selectedProduct && selectedPlace && (
        <ShippingStopModal
          selectedProduct={selectedProduct}
          selectedPlace={selectedPlace}
          shippingStop={stopShippingState}
          handleCancel={() => {
            setSelectedProduct('');
            setSelectedPlace('');
          }}
          handleOk={() => handleStopShipping()}
        />
      )}
      {openAddPickingProductModal && (
        <AddPickingProductModal
          products={filteredProduct}
          selectedProductId={selectedProductId}
          setSelectedProductId={setSelectedProductId}
          selectedShippingId={selectedShippingId}
          setSelectedShippingId={setSelectedShippingId}
          selectedProduct={selectedProduct}
          changeProductsAmount={changeProductsAmount}
          setChangeProductsAmount={setChangeProductsAmount}
          selectedPlace={selectedPlace}
          shippingStop={stopShippingState}
          branchData={shippingDestinationsSelect}
          handleCancel={() => {
            setOpenAddPickingProductModal(false);
            setSelectedProductId(0);
            setSelectedShippingId(0);
            setChangeProductsAmount('');
          }}
          handleUpdateProductAmount={() => createNewProductShippingAmount()}
          setOpenAddPickingProductModal={() => setOpenAddPickingProductModal}
          setPopUpProductName={setPopUpProductName}
          setPopUpShippingName={setPopUpShippingName}
        />
      )}
      {selectedProduct && selectedPlace && (
        <Overlay
          dark
          handleClick={() => {
            // setSelectedProduct('');
            // setSelectedPlace('');
          }}
        />
      )}
      {tagMenuActive && (
        <Overlay
          zIndex={9997}
          handleClick={() => {
            setTagMenuActive(false);
          }}
        />
      )}
      <ErrorMsgPopUp
        errMsg={errMsg}
        fromPc={true}
        handleClose={() => setErrMsg('')}
        zIndex={1002}
      />
      <UpdatePopUp
        popUp={updatePopUp}
        fromPc={true}
        taskKind={'addShippingProduct'}
        messageKind={messageKind}
        productName={popUpProductName}
        shippingName={popUpShippingName}
        handleClose={() => {
          setUpdatePopUp(false);
        }}
      />
    </React.Fragment>
  );
};

export default PickingByShippingDetail;
