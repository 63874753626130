import * as S from './ShippingStopMenu.styles';

type Props = {
  selectedShippingStopStatus: boolean;
  setSelectedShippingStopStatus: (selectedShippingStopStatus: boolean) => void;
};

const ShippingStopMenu = ({
  selectedShippingStopStatus,
  setSelectedShippingStopStatus,
}: Props) => {
  return (
    <S.Wrapper>
      <S.StatusLabel>
        <S.CheckBox
          onChange={() => {
            setSelectedShippingStopStatus(!selectedShippingStopStatus);
          }}
          type="checkbox"
          value="shipping_stop_menu"
        />

        <S.CheckBoxImage checked={selectedShippingStopStatus}>
          「出荷止め」ありのみ表示
        </S.CheckBoxImage>
      </S.StatusLabel>
    </S.Wrapper>
  );
};

export default ShippingStopMenu;
