import { FC } from 'react';
import * as S from './PickingByShippingChangeSelect.styles';
import type { Todo } from '@lib/common/type';
import ReactSelect from 'react-select';
import { NO_OPTION_JAPANESE } from '@lib/pc/common/constants';

type Changes = {
  id: number;
  name: string;
};

type Props = {
  changes: Changes[] | undefined;
  value: number | undefined;
  setSelectedId?: (selectedId: number) => void;
  setIsChanged: (isChanged: boolean) => void;
  display?: boolean;
  width?: string;
  marginTop?: string;
  marginLeft?: string;
  selectMarginLeft?: string;
  onChange?: (selectedCaseId: number, index?: number) => void;
  disabled?: boolean;
};

const PickingByShippingChangeSelect: FC<Props> = ({
  changes,
  value,
  setSelectedId,
  setIsChanged,
  display,
  width,
  marginTop,
  marginLeft,
  selectMarginLeft,
  onChange,
  disabled,
}: Props) => {
  const handleChange = (e: number) => {
    if (setSelectedId) {
      setSelectedId(e);
      setIsChanged(true);
    }
  };

  const toReactSelectOptions = (options: { id: number; name: string }[]) =>
    options.map(({ id, name }) => ({
      value: id,
      label: name,
    }));

  const reactSelectOptions = changes && toReactSelectOptions(changes);
  const currentValue = reactSelectOptions?.find((o) => o.value === value);

  const styles = {
    control: (baseStyles: Todo) => ({
      ...baseStyles,
      width: '326px',
      height: '64px',
      borderRadius: '0.5rem',
      fontWeight: 400,
      textAlign: 'left',
      borderStyle: 'hidden',
      outline: '0.5px solid rgba(0,0,0,0.08)',
      backgroundColor: disabled ? '' : 'white',
      cursor: 'pointer',
      marginLeft: selectMarginLeft ? selectMarginLeft : '',
      '@media (max-width: 599px)': {
        width: '16rem',
      },
    }),
    menu: (baseStyles: Todo) => ({
      ...baseStyles,
      top: '37px',
      zIndex: 1002,
    }),
    option: (baseStyles: Todo) => ({
      ...baseStyles,
      textAlign: 'left',
      fontWeight: 400,
      fontSize: '15px',
    }),
  };

  return (
    <S.CaseWrapper
      width={width}
      display={display}
      marginTop={marginTop}
      marginLeft={marginLeft}
    >
      <ReactSelect
        options={reactSelectOptions}
        value={currentValue ? currentValue : null}
        onChange={(option) => {
          option && handleChange(option.value);
          option && onChange?.(option.value);
        }}
        styles={styles}
        isDisabled={disabled}
        isSearchable={false}
        components={{
          IndicatorSeparator: () => null,
        }}
        noOptionsMessage={() => NO_OPTION_JAPANESE}
        placeholder="変更内容の選択"
      />
    </S.CaseWrapper>
  );
};

export default PickingByShippingChangeSelect;
