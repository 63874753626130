import { useEffect, useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import moment from 'moment';
import * as S from './PurchaseOrderList.styles';
import ConfirmPopUp from '@components/modules/common/ConfirmPopUp/ConfirmPopUp';
import {
  MaterialOrderInfo,
  MaterialOrders,
  PurchaseOrderState,
} from '@lib/pc/purchase_orders/type';
import useMaterialOrder from '@lib/pc/purchase_orders_edit/useMaterialOrder';
import { Todo } from '@lib/common/type';

const ORDER_STATUS_LABEL_MAP: Record<string, JSX.Element> = {
  draft: <S.KindLabel>未発注</S.KindLabel>,
  sending: <S.KindLabel>送信中</S.KindLabel>,
  failed: <S.KindLabelFailed>送信失敗</S.KindLabelFailed>,
  completed: <S.KindLabelFill>発注済</S.KindLabelFill>,
  cancel: <S.KindLabelFailed>発注取消</S.KindLabelFailed>,
} as const;
const RECEIVED_STATUS_LABEL_MAP: Record<string, JSX.Element> = {
  undelivered: <S.KindLabel>未納</S.KindLabel>,
  partial: <S.KindLabel partial>一部納品</S.KindLabel>,
  delivered: <S.KindLabelFill>納品済</S.KindLabelFill>,
} as const;

type Props = {
  materialOrders: MaterialOrders;
  isChangedArray: boolean[];
  setIsChangedArray: (isChangedArray: boolean[]) => void;
  selectedMaterialOrderId: number | null;
  setSelectedMaterialOrderId: (selectedMaterialOrderId: number | null) => void;
  setSupplierId: (supplierId: number) => void;
  setPurchaseOrderInfo: (purchaseOrderInfo: Todo) => void;
  popUp: boolean;
  setPopUp: (popUp: boolean) => void;
  startDate: moment.Moment | null;
  endDate: moment.Moment | null;
  selectedDateTypeId: string;
  destConditions: number[];
  statusConditions: string[];
  receivedConditions: string[];
  setFilteredOrders: (filteredOrders: MaterialOrderInfo[]) => void;
  setDisableAnotherMaterial: (disableAnotherMaterial: boolean) => void;
  purchaseOrderState: PurchaseOrderState;
  setPurchaseOrderState: (purchaseOrderState: PurchaseOrderState) => void;
  stateScrollPosition: number;
  setScrollPosition: (stateScrollPosition: number) => void;
};

const PurchaseOrderList = ({
  materialOrders,
  isChangedArray,
  setIsChangedArray,
  selectedMaterialOrderId,
  setSelectedMaterialOrderId,
  setSupplierId,
  setPurchaseOrderInfo,
  popUp,
  setPopUp,
  startDate,
  endDate,
  selectedDateTypeId,
  destConditions,
  statusConditions,
  receivedConditions,
  setFilteredOrders,
  setDisableAnotherMaterial,
  purchaseOrderState,
  setPurchaseOrderState,
  stateScrollPosition,
  setScrollPosition,
}: Props) => {
  const { state } = useLocation<PurchaseOrderState>();
  const history = useHistory();
  const [nextSelectedIndex, setNextSelectedIndex] = useState(0);
  const [confirmMsg, setConfirmMsg] = useState('');

  document
    ?.getElementById('purchase-order-list')
    ?.addEventListener('scroll', () => {
      const currentScrollPosition = document?.getElementById(
        'purchase-order-list'
      )?.scrollTop;
      if (currentScrollPosition) {
        setScrollPosition(currentScrollPosition);
      }
    });

  const { materialOrder: materialOrderForDelivery } = useMaterialOrder(
    selectedMaterialOrderId
  );

  // 下位のコンポーネントに渡しているのがネストの深いオブジェクトなので、強制再レンダリングする必要がある
  const [, setToggleValue] = useState(false);
  const reRender = () => setToggleValue((prev) => !prev);

  const handleClickPurchaseOrderItem = (order: MaterialOrderInfo) => {
    const notSaved = isChangedArray.includes(true);
    if (notSaved) {
      setConfirmMsg('保存されていない納品状況がありますがよろしいですか？');
    } else {
      setSelectedMaterialOrderId(order.id);
      setSupplierId(order.supplierId);
      setPurchaseOrderInfo(order);
      setPurchaseOrderState({
        ...state,
        selectedMaterialOrderId: order.id,
        stateScrollPosition: stateScrollPosition,
        afterCreateOrUpdate: false,
      });
      // 注文書選択時、更新popUpメセージを消す
      history.push({
        pathname: `/pc/purchase_orders`,
        state: {
          ...state,
          selectedMaterialOrderId: order.id,
          stateScrollPosition: stateScrollPosition,
          afterCreateOrUpdate: false,
          afterCreateOrder: false,
        },
      });

      reRender();
    }
  };

  const handleOk = () => {
    setSelectedMaterialOrderId(
      materialOrders.materialOrders[nextSelectedIndex].id
    );
    setPurchaseOrderInfo(materialOrders.materialOrders[nextSelectedIndex]);
    setConfirmMsg('');
    setIsChangedArray([]);
  };

  // 注文書選択時、作成、更新、削除メッセージ設定時、にmaterialOrderをpurchaseOrderInfoへ設定する
  useEffect(() => {
    materialOrderForDelivery && setPurchaseOrderInfo(materialOrderForDelivery);
    reRender();
  }, [selectedMaterialOrderId, popUp]);

  // 日付による検索
  const dateFilteredMaterialOrders = materialOrders.materialOrders.filter(
    (order) => {
      const judgeDate =
        selectedDateTypeId === '1'
          ? moment(order.issuedDate)
          : moment(order.confirmationDate);

      const startMoment = moment(startDate).startOf('day');
      const endMoment = moment(endDate).startOf('day');
      return (
        judgeDate.isSameOrAfter(startMoment) &&
        judgeDate.isSameOrBefore(endMoment)
      );
    }
  );

  // 検索日付入力ありの場合、日付filter実施
  const filteredOrders = startDate
    ? dateFilteredMaterialOrders
    : materialOrders.materialOrders;

  // 日付、発注先、発注状況、納品状況での絞り込み
  const filteredSearchConditionOrders = filteredOrders.filter((order) => {
    const destConditionMatch =
      destConditions.length === 0 || destConditions.includes(order.supplierId);
    const statusConditionMatch =
      statusConditions.length === 0 ||
      statusConditions.includes(order.orderStatus);
    const receivedConditionMatch =
      receivedConditions.length === 0 ||
      receivedConditions.includes(order.receivedStatus);

    return destConditionMatch && statusConditionMatch && receivedConditionMatch;
  });

  useEffect(() => {
    if (state?.afterCreateOrder) {
      setSelectedMaterialOrderId(filteredSearchConditionOrders[0]?.id);
    }
  }, [state?.afterCreateOrder]);

  useEffect(() => {
    setFilteredOrders(filteredSearchConditionOrders);
    // 削除後、最初の注文書データ選択
    // setSelectedMaterialOrderId(filteredSearchConditionOrders[0]?.id);
  }, [filteredSearchConditionOrders.length]);

  useEffect(() => {
    if (state === undefined) {
      document?.getElementById('purchase-order-list')?.scrollTo(0, 0);
    } else {
      document
        ?.getElementById('purchase-order-list')
        ?.scrollTo(0, state?.stateScrollPosition);
    }
  }, [state]);

  return (
    <>
      <S.PurchaseOrderList id="purchase-order-list">
        {filteredSearchConditionOrders.length === 0 && (
          <S.OrderItemNotAvailable>
            該当データはありません。
          </S.OrderItemNotAvailable>
        )}
        {filteredSearchConditionOrders.map((order, index) => (
          <S.PurchaseOrderItem
            key={index}
            index={index}
            selectedIndex={
              purchaseOrderState?.selectedMaterialOrderId
                ? order.id === purchaseOrderState.selectedMaterialOrderId
                : order.id === filteredSearchConditionOrders[0]?.id
            }
            onClick={() => {
              setPopUp(false);
              // 別の注文書をクリックしたときのindexが必要
              setNextSelectedIndex(index);
              handleClickPurchaseOrderItem(order);
              setDisableAnotherMaterial(false);
            }}
          >
            {/* <S.PurchaseOrderItemRow>
          <div>
            <input type="checkbox" />
          </div>
          <S.PurchaseOrderItemDate>
            {order.issuedDate}
          </S.PurchaseOrderItemDate>
        </S.PurchaseOrderItemRow> */}
            <S.PurchaseOrderItemRow>
              {ORDER_STATUS_LABEL_MAP[order.orderStatus]}
              <S.PurchaseOrderItemDate>
                {order.issuedDate}
              </S.PurchaseOrderItemDate>
            </S.PurchaseOrderItemRow>
            <S.PurchaseOrderItemRow>
              {RECEIVED_STATUS_LABEL_MAP[order.receivedStatus]}
              <S.PurchaseOrderItemName>
                {order.supplierName}
              </S.PurchaseOrderItemName>
            </S.PurchaseOrderItemRow>
          </S.PurchaseOrderItem>
        ))}
      </S.PurchaseOrderList>
      <ConfirmPopUp
        fromPc={true}
        confirmMsg={confirmMsg}
        handleOk={handleOk}
        handleCancel={() => setConfirmMsg('')}
      />
    </>
  );
};

export default PurchaseOrderList;
