import styled from 'styled-components';

export const GraphContainer = styled.div`
  min-height: 500px;
  display: flex;
`;

export const ModalHeader = styled.div`
  margin-left: 5rem;
`;

export const FlexContainer = styled.div<{
  justifyContent?: string;
}>`
  display: flex;
  justify-content: ${(props) => props.justifyContent};
  align-items: center;
  margin-right: 0.5rem;
`;

export const ColorBox = styled.div<{
  bgColor: string;
}>`
  height: 1rem;
  width: 1.5rem;
  background: ${(props) => props.bgColor};
  margin-right: 0.5rem;
`;

export const DotDividerWrapper = styled.div`
  width: 1.5rem;
  height: 0.5rem;
  position: relative;
  margin-right: 0.5rem;
`;

export const DotDivider = styled.div`
  height: 100%;
  width: 100%;
  border-top-width: 2px;
  border-top-style: dashed;
  border-color: #106cae;
  position: absolute;
  top: 50%;
  left: 0;
`;

export const GraphLeftLabelContainer = styled.div`
  display: flex;
  align-items: unset;
  gap: 0.25rem;
  min-width: 160px;

  &:not(:first-child) {
    margin-top: 1rem;
  }

  > span {
    margin-left: 4px;
    color: ${(props) => props.theme.palette.baseBlack};
    font-weight: bold;
  }
`;

export const LeftGraphTextContainer = styled.div`
  width: 130px;
  display: flex;
  flex-direction: column;
  color: ${(props) => props.theme.palette.baseBlack};
  font-weight: bold;
  font-size: 14px;
  gap: 0.5rem;

  > span {
    font-size: 10px;
    font-weight: 400;
  }
`;

export const GraphLeftLabelYAxisContainer = styled.div`
  width: 50px;
`;

export const LeftGraphLabelContainer = styled.div`
  position: absolute;
  left: -3.3%;
  top: 34%;
  min-height: 280px;
  width: 152px;
  background: ${(props) => props.theme.palette.beige};
  z-index: 2;
`;

export const LeftLabelContainer = styled.div`
  display: flex;
  position: relative;
  width: 100%;
  justify-content: right;
`;

export const ScrollableContainer = styled.div`
  // max-width: 720px;
  overflow-x: scroll;

  display: flex;
  flex-direction: column;
  /* align-items: end; */
`;

export const TableWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: min-content;
`;

export const TableAccordionHeadCell = styled.div<{
  bgColor: string;
  color?: string;
}>`
  padding: 0.5rem;
  text-align: center;
  word-break: keep-all;
  width: 5.5rem;
  height: 3.5rem;
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: 500;
  font-size: 14px;
  color: ${(props) =>
    props.color ? props.color : props.theme.palette.baseBlack};
  border-bottom: 1px solid ${(props) => props.theme.palette.beige};
  background-color: ${(props) => props.bgColor};
`;

export const TableHeadCell = styled.div<{
  isToday?: boolean;
  dayOfWeek: string;
}>`
  width: 9rem;
  height: 3.5rem;
  font-size: 1rem;
  font-weight: ${(props) => (props.isToday ? 'bold' : '')};
  padding: 8px 10px;
  text-align: center;
  vertical-align: middle;
  display: flex;
  align-items: center;
  justify-content: center;
  border-bottom: ${(props) =>
    !props.isToday ? ` 2px solid ${props.theme.palette.beige}` : ''};
  &:nth-child(even) {
    background: #fff;
  }
  background: ${(props) =>
    props.isToday ? `${props.theme.palette.blue}!important` : ''};
  color: ${(props) => (props.isToday ? `${props.theme.palette.white}` : '')};
  color: ${(props) =>
    props.dayOfWeek === '土' && !props.isToday
      ? '#1046cf'
      : props.dayOfWeek === '日' && !props.isToday
      ? '#c74141'
      : ''};

  > div {
    display: flex;
    align-items: baseline;
    justify-content: center;
  }

  span {
    font-size: 12px;
  }
`;
export const TableCell = styled.div<{
  lastCell?: boolean;
  isToday?: boolean;
}>`
  width: 9rem;
  height: 3.5rem;
  font-size: 1rem;
  padding: 8px 10px;
  padding-top: 0.5rem;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
  border-bottom: ${(props) =>
    props.isToday
      ? props.lastCell
        ? `2px solid  ${props.theme.palette.blue}`
        : ''
      : `2px solid ${props.theme.palette.beige}`};
  border-inline: ${(props) =>
    props.isToday ? `2px solid ${props.theme.palette.blue}` : ''};
  border-top: 0;
  &:nth-child(even) {
    background: #fff;
  }

  > div {
    display: flex;
    align-items: center;
    justify-content: center;
  }

  > span {
    font-size: 12px;
  }
`;

// export const LeftTableEmptyCell = styled.div`
//   width: 200px;
//   height: 2.75rem;
// `;

export const TableRow = styled.div`
  display: inline-flex;
`;

export const AccordionTableHeadRow = styled(TableRow)`
  display: inline-flex;
`;

export const AccordionWrapper = styled.div`
  display: block;
  width: 100vw;
`;

export const Column = styled.div<{ isToday: boolean; even: boolean }>`
  // width: calc((75vw - 1rem) / 6);
  width: 9rem;
  flex-shrink: 0;
  box-sizing: border-box;
  ${(prop) => prop.isToday && 'border: 2px solid #2BA9D1;'}
  ${(prop) => prop.even && 'background: #fff;'}
  border-top: 0;
`;

export const DateCell = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 56px;
  font-weight: 500;
  border-bottom: 2px solid #cdcdcd;
`;

export const TodayDateCell = styled(DateCell)`
  background: #2ba9d1;
  color: #fff;
  font-weight: 700;
  border-bottom: 2px solid #2ba9d1;
`;

export const ValueCell = styled.div`
  height: 58px;
  font-weight: 500;
  display: flex;
  justify-content: center;
  align-items: center;
  border-bottom: 2px solid #f8f7f4;

  &:last-of-type {
    height: 56px;
  }
`;

export const EvenCell = styled(ValueCell)`
  border-bottom: 2px solid #f8f7f4;

  &:last-of-type {
    border-bottom: 0;
  }
`;

export const ValueCellWrapper = styled.div`
  box-sizing: border-box;
`;
