import React from 'react';
import { useParams } from 'react-router-dom';
import { WithHeader } from '@components/templates/WithHeader';
import { Heading } from '@components/elements/typographies/Heading';
import * as S from './CustomerMasterEdit.styles';
import CustomerEditWrapper from '@components/modules/pc/settings/customer_setting/CustomerEdit/CustomerEditWrapper/CustomerEditWrapper';
import useCustomer from '@lib/pc/settings/customer_edit/useCustomer';

export const CustomerMasterEditRoot: React.FC = () => {
  const { id, timestamp } = useParams<{ id: string; timestamp: string }>();
  const { customer, refetch } = useCustomer(id);

  return (
    <WithHeader>
      <S.Wrapper>
        <div>
          <S.HeadContainer>
            <S.FlexBaseline>
              <div>
                <Heading tag="span" text="得意先の編集" fs="32" fw="lg" />
              </div>
            </S.FlexBaseline>
          </S.HeadContainer>
          <CustomerEditWrapper
            customer={customer}
            refetch={refetch}
            timestamp={timestamp}
          />
        </div>
      </S.Wrapper>
    </WithHeader>
  );
};
