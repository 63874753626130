import styled from 'styled-components';

export const Wrapper = styled.div`
  width: 100%;
  background-color: ${(props) => props.theme.palette.beige};
`;

export const TabWrapper = styled.div`
  display: flex;
  width: 100vw;
  border-bottom: 1px solid #cdcdcd;
`;

export const TabArea = styled.div`
  display: flex;
  background-color: #222e3e;
  cursor: pointer;
`;

export const Tab = styled.div<{
  active: boolean;
  tabCount: number;
}>`
  background-color: ${(props) =>
    props.active ? props.theme.palette.blue : '#F8F7F4'};
  padding-top: 16px;
  width: calc(100vw / ${(props) => props.tabCount});
  height: 64px;
  line-height: 30px;
  text-align: center;
  color: ${(props) => (props.active ? '#FFFFFF' : 'rgba(51, 51, 51, 0.5)')};
  font-weight: ${(props) => (props.active ? '900' : '400')};
  font-family: 'Noto Sans JP';
  font-style: normal;
  font-size: 32px;
  line-height: 32px;
  align-items: center;
  text-align: center;
`;

export const Input = styled.input<{
  align?: string;
  padding?: string;
  height?: string;
}>`
  width: 100%;
  height: 40px;
  color: ${(props) => props.theme.palette.baseBlack};
  font-size: 1rem;
  padding: ${(props) => (props.padding ? props.padding : '0.325rem')};
  text-align: ${(props) => props.align};
  outline: none;
  border-radius: 0.5rem;
  border: 1px solid rgba(0, 0, 0, 0.08);
  height: ${(props) => (props.height ? props.height : '')};
  background: ${(props) => props.theme.palette.white};
  ::-webkit-inner-spin-button {
    -webkit-appearance: none;
  }
`;

export const ShippingGroupAreaDl = styled.dl`
  > dt {
    font-weight: 700;
    margin-bottom: 8px;
  }
  > dd {
    margin-bottom: 24px;
  }
`;

export const ButtonContainer = styled.div`
  position: fixed;
  width: 100vw;
  bottom: 0;
  padding: 22px 0;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #f8f7f4;
  & > button {
    width: 136px;
    &.disabled {
      background: #e0e0e0;
      border-color: #e0e0e0;
      color: #6b675c;
      cursor: initial;
    }
  }
`;
