import styled from 'styled-components';

export const Wrapper = styled.div`
  background: ${(props) => props.theme.palette.white};
  position: relative;
  height: calc(100vh - 5rem);
`;

export const HeadContainer = styled.div`
  height: 88px;
  padding: 1.15rem 1.15rem 1.15rem 2.25rem;
  display: flex;
  align-items: center;
`;

export const ProductKind = styled.span`
  padding: 5px 10px 7px;
  width: 80px;
  height: 32px;
  background: ${(props) => props.theme.palette.white};
  color: ${(props) => props.theme.palette.blue};
  border: 1px solid ${(props) => props.theme.palette.blue};
  font-size: 19px;
  margin-right: 16px;
  margin-top: 6px;
  border-radius: 2px;
  vertical-align: super;
`;

// 商品の絞り込み
export const ListLeftHeadCell = styled.div<{
  invalid?: boolean;
  isBlue?: boolean;
}>`
  pointer-events: ${(props) => (props.invalid ? 'none' : '')};
  cursor: pointer;
  height: 52px;
  min-width: 250px;
  border-bottom: 1px solid ${(props) => props.theme.palette.yellowGray};
  position: -webkit-sticky; /* for Safari */
  /* -webkit-transform: translateZ(0); */
  display: flex;
  justify-content: space-between;
  padding: 1px 0.75rem;
  gap: 0.25rem;
  border: 1px solid rgba(51, 51, 51, 0.5);
  border-radius: 8px;
  position: absolute;
  right: 305px;
  height: 44px;
  width: 240px;
  color: ${(props) => (props.isBlue ? props.theme.palette.blue : '#9ca3af')};

  display: flex;
  flex-direction: column;

  > span {
    display: flex;
    font-size: 16px;
    padding-top: 8px;
    vertical-align: middle;
    .filter_text {
      padding-top: 5px;
      padding-left: 6px;
    }
  }

  :nth-child(2) {
    > div > input {
      cursor: pointer;
    }
  }
`;

export const ButtonWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  bottom: 0;
  right: 0;
  left: 0;
  height: 83px;
`;

export const ButtonContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  position: absolute;
  right: 175px;
  & > a {
    width: 160px;
    height: 45px;
    padding: 10px 10px 10px 0;
    border-radius: 5px;
    color: ${(props) => props.theme.palette.white};
    background: ${(props) => props.theme.palette.primary.main};
    font-family: 'Noto Sans JP';
    font-style: normal;
    font-weight: 700;
    font-size: 16px;
    line-height: 23px;
    text-align: center;
  }

  & > button:first-child {
    margin-right: 1rem;
  }
`;

export const ProductRow = styled.div`
  display: flex;
  min-height: 45px;
  align-items: center;
  border-bottom: solid 1px #e5e1dd;
  &.header {
    /* justify-content: space-between; */
    background-color: #f8f7f4;
    color: red;
  }
  div {
    padding: 0 12px;
    min-height: 44px;
    display: flex;
    align-items: center;
    &.sort {
      width: 40px;
    }
    /**
     * 完成品 / 半製品
     */
    &.semi-product-finished-product {
      width: 64px;
      justify-content: center;
      align-items: center;
      .semi-product,
      .finished-product {
        padding: 5px 6px 6px;
        font-weight: 600;
        border-radius: 4px;
      }
      /**
       * 完成品
       */
      .finished-product {
        background-color: #2ba9d1;
        color: white;
      }
      /**
       * 半製品
       */
      .semi-product {
        color: #2ba9d1;
        border: solid 1px #2ba9d1;
      }
    }
    &.img {
      width: 52px;
      padding: 0 4px;
      justify-content: center;
    }
    &.code {
      width: 66px;
      &.header {
        font-size: 14px;
      }
    }
    &.name {
      width: 200px;
    }
    &.name-furigana {
      width: 200px;
    }
    &.name-abbreviation {
      width: 140px;
    }
    &.jan-code {
      width: 110px;
    }
    &.appreciation-period-days {
      width: 80px;
      &.header {
        font-size: 14px;
      }
      &.num {
        justify-content: flex-end;
      }
    }
    &.factory {
      width: 110px;
    }
    &.line {
      width: 110px;
    }
    &.location {
      width: 110px;
    }
    &.tags {
      padding-bottom: 4px;
      width: calc(100vw - 1294px);
      ul {
        display: flex;
        justify-content: flex-start;
        flex-wrap: wrap-reverse;
        li {
          display: inline-block;
          padding: 8px 11px;
          margin: 4px 4px 0 0;
          background-color: #7bb5df;
          border-radius: 15px;
          color: white;
          font-weight: 600;
          font-size: 14px;
        }
      }
    }
  }
`;
