import * as S from './ModalPaper.styles';
import { Heading } from '@components/elements/typographies/Heading';
import type { AllocationType } from '@lib/common/type';
import moment from 'moment';
import { ReactComponent as ArrowRight } from '@assets/icons/arrow_right_40px.svg';
import { ReactComponent as ArrowLeft } from '@assets/icons/arrow_left_40px.svg';
import StockAllocationTransferSelect from '@components/modules/pc/stock_allocation_status/StockAllocationTransferSelect/StockAllocationTransferSelect';
import StockAllocationTypeSelect from '@components/modules/pc/stock_allocation_all/StockAllocationTypeSelect/StockAllocationTypeSelect';
import { PRODUCT_KIND, Storeroom } from '@lib/common/type';

type Props = {
  heading1: string;
  heading2?: string;
  children: React.ReactNode;
  date?: Date;
  setDate?: (d: moment.Moment) => void;
  selectedAllocationTypeId?: number;
  allocationTypeOptions?: AllocationType[];
  selectedStoreroomId?: number;
  storerooms?: Storeroom[] | undefined;
  setSelectedAllocationTypeId?: (id: number) => void;
  handleChangeTransfer?: (id: number) => void; // handleChangeTransfer === setSelectedStoreroomId
};

export const ModalPaper: React.FC<Props> = ({
  heading1,
  heading2,
  children,
  date,
  setDate,
  selectedAllocationTypeId,
  allocationTypeOptions,
  selectedStoreroomId,
  storerooms,
  setSelectedAllocationTypeId,
  handleChangeTransfer,
}) => {
  const getMonth = (num: number) => {
    setDate && setDate(moment(date).add(num, 'months').startOf('month'));
  };
  return (
    <S.Wrapper>
      <S.Header>
        <div>
          <Heading tag="span" text={heading1} fs="32" fw="lg" />
          {heading2 && <Heading tag="span" text={heading2} fs="24" fw="lg" />}
        </div>
        {date && setDate && (
          <S.DateWrapper>
            <S.ArrowLeft onClick={() => getMonth(-1)}>
              <ArrowLeft />
              <span className="circle"></span>
            </S.ArrowLeft>
            <S.Date>
              {moment(date).format('YYYY')}
              <span>年</span>
              {moment(date).format('MM')}
              <span>月</span>
            </S.Date>
            <S.ArrowRight onClick={() => getMonth(1)}>
              <ArrowRight />
            </S.ArrowRight>
          </S.DateWrapper>
        )}
        {allocationTypeOptions &&
          setSelectedAllocationTypeId &&
          handleChangeTransfer && (
            <S.DateWrapper>
              <StockAllocationTransferSelect
                data={storerooms}
                value={selectedStoreroomId}
                height="42px"
                width="280px"
                menuMarginTop="7px"
                handleChangeTransfer={handleChangeTransfer}
                productKind={PRODUCT_KIND.PRODUCT_AND_SEMI_PRODUCT}
              />
              <StockAllocationTypeSelect
                value={selectedAllocationTypeId}
                height="42px"
                width="280px"
                menuMarginTop="7px"
                setSelectedAllocationTypeId={setSelectedAllocationTypeId}
                allocationTypeOptions={allocationTypeOptions}
              />
            </S.DateWrapper>
          )}
      </S.Header>
      <S.MainContainer>{children}</S.MainContainer>
    </S.Wrapper>
  );
};
