import styled from 'styled-components';

export const HeadContainer = styled.div`
  height: 86px;
  padding: 1.15rem 2.25rem;
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-bottom: 1px solid #f5f2e6;
  background-color: ${(props) => props.theme.palette.white};
`;

export const PageTitle = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

export const PageTitleText = styled.div`
  display: flex;
  align-items: baseline;
  padding-bottom: 4px;
`;

export const DateFilter = styled.div<{
  invalid?: boolean;
  isBlue?: boolean;
}>`
  pointer-events: ${(props) => (props.invalid ? 'none' : '')};
  min-height: 42px;
  width: 230px;
  border-radius: 0.5rem;
  border: 1px solid ${(props) => props.theme.palette.yellowGray};
  position: -webkit-sticky; /* for Safari */
  -webkit-transform: translateZ(0);
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 6px 2px;
  gap: 0.25rem;
  display: flex;
  position: relative;
  margin-left: 16px;

  > span {
    font-size: 0.75rem;
    &.filter-product {
      width: 90%;
      height: 24px;
      line-height: 24px;
      position: absolute;
      background: ${(props) => props.theme.palette.white};
      color: ${(props) =>
        props.isBlue ? props.theme.palette.blue : '#9ca3af'};
      border-radius: 0.5rem;
      cursor: pointer;

      > .filter_text {
        padding-left: 0;
        font-size: 13px;
        line-height: 16px;
        padding-left: 36px;
        text-align: left;
        position: absolute;
        top: -8%;
        color: ${(props) => props.theme.palette.black};
      }

      > .filter_text_false {
        gap: 2px;
        position: absolute;
        top: -8%;
        font-size: 13px;
        line-height: 16px;
        padding-left: 36px;
        color: ${(props) => props.theme.palette.black};
      }
    }
  }

  :first-child {
    > div > input {
      cursor: pointer;
      height: 32px;
      padding: 0.5rem 40px 0.5rem;
    }
  }
`;

export const SearchFilterIconContainer = styled.div`
  position: absolute;
  top: 12%;
  left: 5%;
`;

export const DateWrapper = styled.div`
  cursor: pointer;
  display: flex;
  align-items: center;
  position: relative;
`;

export const ButtonWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  bottom: 0;
  right: 0;
  left: 0;
  height: 83px;
`;

export const ButtonContainer = styled.div<{
  padding?: string;
  width?: string;
  buttonType?: string;
}>`
  display: flex;
  align-items: center;
  justify-content: center;
  width: ${(props) => (props.width ? props.width : '100px')};
  height: 45px;
  padding: ${(props) => (props.padding ? props.padding : '')};
  border-radius: 5px;
  color: ${(props) =>
    props.buttonType === 'blue'
      ? props.theme.palette.white
      : props.buttonType === 'delete'
      ? props.theme.palette.error
      : props.theme.palette.primary.main};
  background: ${(props) =>
    props.buttonType === 'delete'
      ? props.theme.palette.white
      : props.buttonType === 'blue'
      ? props.theme.palette.primary.main
      : props.theme.palette.white};
  border: ${(props) =>
    `${
      props.buttonType === 'blue'
        ? props.theme.palette.primary.main
        : props.buttonType === 'delete'
        ? props.theme.palette.error
        : props.theme.palette.primary.main
    } 1px solid`};
  font-weight: bold;
  font-size: 16px;
  cursor: pointer;
`;

export const HeaderRightContainer = styled.div`
  display: flex;
`;

export const DetailConditionText = styled.div`
  font-size: 13px;
  padding-left: 36px;
  margin-top: 3px;
  color: ${(props) => props.theme.palette.black};
`;

export const SearchConditionNameList = styled.div`
  font-size: 13px;
  display: flex;
  flex-wrap: wrap;
  gap: 2px;
  width: 100%;
`;

export const FilterIconWrapper = styled.div`
  width: 24px;
  height: 24px;
  cursor: pointer;
`;

export const SearchConditionLabel = styled.div`
  max-width: 188px;
  font-size: 0.75rem;
  padding: 2px 6px;
  border-radius: 2px;
  border: 1px solid ${(props) => props.theme.palette.primary.main};
  color: ${(props) => props.theme.palette.primary.main};
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  height: 18px;
`;
