import { useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import * as S from './SupplierInfo.styles';
import FaxModal from '@components/modules/pc/purchase_orders/FaxModal/FaxModal';
import MailModal from '@components/modules/pc/purchase_orders/MailModal/MailModal';
import Overlay from '@components/elements/backdrops/Overlay/Overlay';
import TelModal from '@components/modules/pc/purchase_orders/TelModal/TelModal';
import ConfirmPopUp from '@components/modules/common/ConfirmPopUp/ConfirmPopUp';
import ErrorMsgPopUp from '@components/modules/common/ErrorMsgPopUp/ErrorMsgPopUp';
import { Email, PurchaseOrderState } from '@lib/pc/purchase_orders/type';
import { Material, Todo } from '@lib/common/type';
import useDeleteMaterialOrdersMutation from '@lib/pc/purchase_orders/useDeleteMaterialOrdersMutation';
import useCreateMaterialOrderForTelMutation from '@lib/pc/purchase_orders/useCreateMaterialOrderForTelMutation';
import useCreateMaterialOrderForFaxMutation from '@lib/pc/purchase_orders/useCreateMaterialOrderForFaxMutation';
import useCreateMaterialOrderForEmailMutation from '@lib/pc/purchase_orders/useCreateMaterialOrderForEmailMutation';
import useCancelMaterialOrderMutation from '@lib/pc/purchase_orders/useCancelMaterialOrderMutation';

const SEND_BY_LABEL_MAP: Record<string, JSX.Element> = {
  email: <S.KindLabel>メール</S.KindLabel>,
  fax: <S.KindLabel>FAX</S.KindLabel>,
  tel: <S.KindLabel>TEL</S.KindLabel>,
} as const;

const ORDER_STATUS_LABEL_MAP: Record<string, JSX.Element> = {
  draft: <S.KindLabel>未発注</S.KindLabel>,
  sending: <S.KindLabel>送信中</S.KindLabel>,
  failed: <S.KindLabelFailed>送信失敗</S.KindLabelFailed>,
  completed: <S.KindLabelFill>発注済</S.KindLabelFill>,
  cancel: <S.KindLabelFailed>発注取消</S.KindLabelFailed>,
} as const;

const RECEIVED_STATUS_LABEL_MAP: Record<string, JSX.Element> = {
  undelivered: <S.KindLabel>未納</S.KindLabel>,
  delivered: <S.KindLabelFill>納品済み</S.KindLabelFill>,
} as const;

const initEmailData = {
  subject: '',
  emailBody: '',
  emailAddressesForEdit: '',
  ccEmailAddressesForEdit: '',
  emailAddresses: [],
  ccEmailAddresses: [],
};

type Props = {
  materialOrder: Todo;
  selectedMaterialOrderId: number | null;
  setSelectedMaterialOrderId: (selectedMaterialOrderId: number | null) => void;
  materials: Material[] | undefined;
  handleMaterialOrderDeleteSuccess: () => void;
  refetch: () => void;
  materialOrdersRefetch: () => void;
  setPopUp: (popUp: boolean) => void;
  setMsgKind: (msgKind: string) => void;
  setTaskKind: (taskKind: string) => void;
  setPurchaseOrderState: (purchaseOrderState: PurchaseOrderState) => void;
  stateScrollPosition: number;
};

const SupplierInfo = ({
  materialOrder,
  selectedMaterialOrderId,
  setSelectedMaterialOrderId,
  materials,
  handleMaterialOrderDeleteSuccess,
  refetch,
  materialOrdersRefetch,
  setPopUp,
  setMsgKind,
  setTaskKind,
  setPurchaseOrderState,
  stateScrollPosition,
}: Props) => {
  const { state } = useLocation<PurchaseOrderState>();
  const history = useHistory();
  const [openSendMailModal, setOpenSendMailModal] = useState(false);
  const [openTelModal, setOpenTelModal] = useState(false);
  const [openFaxModal, setOpenFaxModal] = useState(false);
  const [sending, setSending] = useState(false);
  const [confirmMsg, setConfirmMsg] = useState('');
  const [errMsg, setErrMsg] = useState('');
  const [newEmail, setNewEmail] = useState<Email>(initEmailData);

  const convertEmailArrayToString = (emails: string[]): string => {
    return emails.join(', ');
  };

  const deleteMaterialOrdersMutation = useDeleteMaterialOrdersMutation(
    selectedMaterialOrderId,
    handleMaterialOrderDeleteSuccess
  );

  // 未発注の発注データの削除
  const handleDeletePurchaseOrder = () => {
    deleteMaterialOrdersMutation.mutate();
    setConfirmMsg('');
    setPurchaseOrderState({ ...state, selectedMaterialOrderId: 0 });
  };

  const handleUpdateSuccess = () => {
    refetch();
    materialOrdersRefetch();
    setOpenTelModal(false);
    setOpenSendMailModal(false);
    setOpenFaxModal(false);
    setPopUp(true);
    setMsgKind('update');
    setTaskKind('orderStatus');
    setNewEmail(initEmailData);
    setSending(false);
    setConfirmMsg('');
  };

  const handleCancelSuccess = () => {
    refetch();
    materialOrdersRefetch();
    setPopUp(true);
    setMsgKind('cancel');
    setTaskKind('orderStatus');
    setSending(false);
    setConfirmMsg('');
  };

  const createMaterialOrderForTel = useCreateMaterialOrderForTelMutation(
    selectedMaterialOrderId,
    handleUpdateSuccess,
    setErrMsg
  );

  const createMaterialOrderForFaxMutation =
    useCreateMaterialOrderForFaxMutation(
      selectedMaterialOrderId,
      handleUpdateSuccess,
      setErrMsg
    );

  const createMaterialOrderForEmailMutation =
    useCreateMaterialOrderForEmailMutation(
      selectedMaterialOrderId,
      handleUpdateSuccess,
      setErrMsg
    );

  const cancelMaterialOrderMutation = useCancelMaterialOrderMutation(
    selectedMaterialOrderId,
    handleCancelSuccess,
    setErrMsg
  );

  const emailSubmitCheck = () => {
    const dividedAddress = newEmail.emailAddressesForEdit.split(/[,\s]+/);
    if (newEmail.emailAddresses.length === 0 || dividedAddress.length === 0) {
      setErrMsg('送信するメールアドレスを入力してください。');
    } else if (!newEmail.subject) {
      setErrMsg('送信するメールの件名を入力してください。');
    } else if (!newEmail.emailBody) {
      setErrMsg('送信するメールの本文を入力してください。');
    } else {
      setSending(true);
      createMaterialOrderForEmailMutation.mutate(newEmail);
    }
  };

  // 削除確認メッセージ、OKボタン押下
  const handleClickDeleteConfirm = () => {
    if (materialOrder?.orderStatus === 'draft') {
      handleDeletePurchaseOrder();
    } else {
      // 発注済み注文書のキャンセル
      cancelMaterialOrderMutation.mutate();
    }
    setConfirmMsg('');
  };

  const handleCloseEmailModal = () => {
    setNewEmail(initEmailData);
    setOpenSendMailModal(false);
  };

  // stateの更新
  const stateUpdate = () => {
    history.replace({
      state: {
        ...state,
        afterCreateOrUpdate: false,
      },
    });
  };

  const handleClickEmail = () => {
    setNewEmail({
      ...newEmail,
      subject: materialOrder?.supplierContacts?.emailSubjectDefault
        ? materialOrder?.supplierContacts?.emailSubjectDefault
        : '',
      emailBody: materialOrder?.supplierContacts?.emailBodyDefault
        ? materialOrder?.supplierContacts?.emailBodyDefault
        : '',
      emailAddressesForEdit: materialOrder?.supplierContacts?.email
        ? convertEmailArrayToString(materialOrder?.supplierContacts?.email)
        : '',
      ccEmailAddressesForEdit: materialOrder?.supplierContacts?.ccEmailAddresses
        ? convertEmailArrayToString(
            materialOrder?.supplierContacts?.ccEmailAddresses
          )
        : '',
    });
    setOpenSendMailModal(true);
    stateUpdate();
  };

  // const handleClickFax = () => {
  //   setOpenFaxModal(true);
  //   stateUpdate();
  // };

  const handleClickTel = () => {
    setOpenTelModal(true);
    stateUpdate();
  };

  return (
    <>
      {materialOrder && (
        <>
          <S.OperationPanel>
            <S.OperationPanelUpper>
              {/* 取引先名称 */}
              <S.OperationPanelDestName>
                {materialOrder?.supplierName}
              </S.OperationPanelDestName>
              <S.OrderAndFaxStatus>
                {/* 発注方法 */}
                {SEND_BY_LABEL_MAP[materialOrder?.orderType]}
                {/* 発注状況 */}
                {ORDER_STATUS_LABEL_MAP[materialOrder?.orderStatus]}
                {/* 納品状況 */}
                {RECEIVED_STATUS_LABEL_MAP[materialOrder?.receivedStatus]}
              </S.OrderAndFaxStatus>
            </S.OperationPanelUpper>
            {/* TODO: 電話番号の設定 */}
            {/* <S.TelWrapper>TEL：xxx-xxxx-xxxx</S.TelWrapper> */}
            <S.ButtonArea>
              <S.ButtonContainer
                buttonType="blue"
                onClick={() => {
                  history.push({
                    pathname: `/pc/purchase_orders/${materialOrder?.orderId}/edit`,
                    state: {
                      ...state,
                      selectedMaterialOrderId: materialOrder?.orderId,
                      materials: materials,
                      afterCreateOrUpdate: false,
                      stateScrollPosition: stateScrollPosition,
                    },
                  });
                  setSelectedMaterialOrderId(selectedMaterialOrderId);
                }}
              >
                編集
              </S.ButtonContainer>
              {(materialOrder?.orderStatus === 'draft' ||
                materialOrder?.orderStatus === 'failed') && (
                <>
                  <S.ButtonContainer onClick={() => handleClickEmail()}>
                    メール
                  </S.ButtonContainer>
                  {/* <S.ButtonContainer onClick={() => handleClickFax()}>
                    FAX
                  </S.ButtonContainer> */}
                  <S.ButtonContainer onClick={() => handleClickTel()}>
                    TEL
                  </S.ButtonContainer>
                </>
              )}
              {/* TODO: sendingを削除する */}
              {materialOrder?.orderStatus === 'draft' ||
              materialOrder?.orderStatus === 'sending' ? (
                <S.ButtonContainer
                  buttonType="delete"
                  onClick={() =>
                    setConfirmMsg('選択した注文書を削除してもよろしいですか？')
                  }
                >
                  削除
                </S.ButtonContainer>
              ) : materialOrder?.orderStatus === 'completed' &&
                materialOrder?.receivedStatus === 'undelivered' ? (
                <S.ButtonContainer
                  buttonType="delete"
                  onClick={() => {
                    setPopUp(false);
                    setConfirmMsg(
                      '発注した注文書を取消状態にしてもよろしいですか？'
                    );
                  }}
                >
                  発注取消
                </S.ButtonContainer>
              ) : (
                ''
              )}
              {openSendMailModal && (
                <MailModal
                  sending={sending}
                  newEmail={newEmail}
                  confirmationDate={materialOrder?.confirmationDate}
                  onClickSubmit={() => {
                    emailSubmitCheck();
                  }}
                  setNewEmail={setNewEmail}
                  setErrMsg={setErrMsg}
                  handleCloseEmailModal={handleCloseEmailModal}
                />
              )}
              {openFaxModal && (
                <FaxModal
                  sending={sending}
                  faxNumber={materialOrder?.supplierContacts?.faxNumber}
                  confirmationDate={materialOrder?.confirmationDate}
                  setOpenFaxModal={setOpenFaxModal}
                  onClickSubmit={() => {
                    setSending(true);
                    createMaterialOrderForFaxMutation.mutate();
                  }}
                />
              )}
              {openTelModal && (
                <TelModal
                  sending={sending}
                  phoneNumber={materialOrder?.supplierContacts?.phoneNumber}
                  confirmationDate={materialOrder?.confirmationDate}
                  setOpenTelModal={setOpenTelModal}
                  onClickSubmit={() => {
                    setSending(true);
                    createMaterialOrderForTel.mutate();
                  }}
                />
              )}
              {(openSendMailModal || openFaxModal || confirmMsg) && (
                <Overlay dark zIndex={1} />
              )}
            </S.ButtonArea>
          </S.OperationPanel>
          <ConfirmPopUp
            fromPc={true}
            confirmMsg={confirmMsg}
            handleOk={() => handleClickDeleteConfirm()}
            handleCancel={() => setConfirmMsg('')}
          />
          <ErrorMsgPopUp
            errMsg={errMsg}
            handleClose={() => {
              setErrMsg('');
              setSending(false);
            }}
            fromPc={true}
            zIndex={10002}
          />
        </>
      )}
    </>
  );
};

export default SupplierInfo;
