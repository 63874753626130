import { Heading } from '@components/elements/typographies/Heading';
// import { Link, useLocation } from 'react-router-dom';
import * as S from './SalesStoreHeaderControl.styles';
// import { ShippingGroupState } from '@lib/pc/settings/shipping_group_setting/type';

const SalesStoreHeaderControl = () => {
  // const { state } = useLocation<ShippingGroupState>();

  return (
    <S.Wrapper>
      <S.HeadContainer className="head-container">
        <div>
          <Heading tag="span" text="販売店管理" fs="32" fw="lg" />
        </div>

        <S.ControlArea className="control-area">
          {/* 新規登録ボタン */}
          {/* <S.ButtonWrapper>
            <S.ButtonContainer>
              <Link
                to={{
                  pathname: `/pc/settings/sales_store_setting_create`,
                  state: {
                    ...state,
                    stateSearchWord: '',
                    stateSelectedTags: [],
                    editData: null,
                  },
                }}
              >
                ＋ 新規登録
              </Link>
            </S.ButtonContainer>
          </S.ButtonWrapper> */}
        </S.ControlArea>
      </S.HeadContainer>
    </S.Wrapper>
  );
};

export default SalesStoreHeaderControl;
