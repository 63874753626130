import styled from 'styled-components';

export const CaseWrapper = styled.div<{
  display?: boolean;
  marginTop?: string;
  marginLeft?: string;
  width?: string;
  labelMarginRight?: string;
  caseLabelWidth?: string;
  labelFontWeight?: string;
}>`
  display: flex;
  position: relative;
  text-align: center;
  margin-left: ${(props) => (props.marginLeft ? props.marginLeft : '0.75rem')};
  margin-top: ${(props) => (props.marginTop ? props.marginTop : '0.75rem')};
  width: ${(props) => (props.width ? props.width : '')};
  .case-label {
    font-size: 15px;
    font-weight: ${(props) =>
      props.labelFontWeight ? props.labelFontWeight : ''};
    align-items: center;
    display: flex;
    margin-right: ${(props) =>
      props.labelMarginRight ? props.labelMarginRight : '0.75rem'};
    width: ${(props) => (props.caseLabelWidth ? props.caseLabelWidth : '')};
  }
`;
