import styles from 'styled-components';

export const Wrapper = styles.div`
  width: 25vw;
  filter: drop-shadow(0px 6px 10px rgba(0, 0, 0, 0.07)) drop-shadow(0px 1px 18px rgba(0, 0, 0, 0.06)) drop-shadow(0px 3px 5px rgba(0, 0, 0, 0.1));
`;

export const BlankBox = styles.div`
  height: 54px;
  background: ${(prop) => prop.theme.palette.beige};
`;

export const Box = styles.div<{
  background: string;
  color?: string;
}>`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 56px;
  margin-top: 0.125rem;
  background: ${(prop) => prop.background};
  font-weight: bold;
  color: ${(prop) => prop.color || 'currentcolor'};
`;

export const IconWrapper = styles.div`
  transform: rotate(180deg);
`;

export const HeadContent = styles.div<{ color: string }>`
  display: flex;
  justify-content: center;
  align-items: center;
  color: ${(props) => props.color};
  font-size: 0.875rem;
  font-weight: 700;

  path {
    fill: ${(props) => props.color};
  }
`;

export const TextWrapper = styles.div`
  display: flex;
  justify-content: space-evenly;
  flex-direction: column;
  margin-left: 0.25rem;
  width: 52px;
  height: 42px;
  text-align: center;
`;

export const Text = styles.p``;
