import React, { useState, useEffect } from 'react';
import { Box, CircularProgress } from '@material-ui/core';

type WindowDimension = {
  width: number;
  height: number;
};

const CenteredLoader: React.FC = () => {
  const [windowSize, setWindowSize] = useState<WindowDimension>({
    width: window.innerWidth,
    height: window.innerHeight,
  });

  useEffect(() => {
    const handleResize = () => {
      setWindowSize({
        width: window.innerWidth,
        height: window.innerHeight,
      });
    };

    // イベントリスナーを追加
    window.addEventListener('resize', handleResize);

    // クリーンアップ関数
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  return (
    <Box
      sx={{
        position: 'fixed',
        top: 0,
        left: 0,
        width: windowSize.width,
        height: windowSize.height,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
      }}
    >
      <CircularProgress style={{ color: '#ffffff' }} />
    </Box>
  );
};

export default CenteredLoader;
