import { axiosInstance } from '@lib/pc/common/api/axiosConfig';
import { useQuery } from 'react-query';
import humps from 'humps';
import type { Factory } from '@lib/common/type';

const useFactories = () => {
  return useQuery('factories', async () => {
    const { data } = await axiosInstance().get('/api/v1/settings/factories');
    return humps.camelizeKeys(data.factories) as Factory[];
  });
};

export default useFactories;
