import styled from 'styled-components';

export const Wrapper = styled.div`
  background-color: ${(props) => props.theme.palette.beige};

  width: 100vw;
  height: 100vh;
  padding: 1.5rem 1.5rem;
  top: 0;
  left: 0;
  position: absolute;
  z-index: 10000;
  overflow-x: hidden;
`;

export const Container = styled.div`
  border-radius: 16px 16px 0 0;
  background-color: ${(props) => props.theme.palette.beige};
  overflow-y: scroll;
  padding-bottom: 100px;
`;

export const Header = styled.div`
  display: flex;
  justify-content: space-between;
  background-color: ${(props) => props.theme.palette.beige};
  border-radius: 16px 16px 0 0;
  padding: 24px 0 20px 0;
`;
export const Title = styled.div`
  font-weight: 700;
  font-size: 2rem;
  line-height: 2rem;
  padding: 4px 0 4px 40px;
  background-color: ${(props) => props.theme.palette.beige};
`;

export const InputWrapper = styled.div`
  position: relative;
  display: flex;
  margin-right: 37px;
  width: 343px;
`;

export const EachSection = styled.div`
  padding-left: 3%;
  padding-right: 3%;
`;

export const ListUl = styled.ul`
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
  background-color: ${(props) => props.theme.palette.white};
  max-height: 300px;
  overflow-y: scroll;
  border-radius: 16px;
`;

export const ListLi = styled.li`
  display: flex;
  align-items: center;
  height: 56px;
  width: 344px;
  padding-left: 40px;
  background-color: ${(props) => props.theme.palette.white};
`;

export const Input = styled.input`
  width: 100%;
  height: 40px;
  color: ${(props) => props.theme.palette.khaki};
  font-size: 1rem;
  line-height: 1rem;
  padding: 0.625rem;
  padding-left: 3rem;
  background-color: ${(props) => props.theme.palette.white};
  outline: solid 1px #c0c0c0;
  border-radius: 8px;
`;

export const Icon = styled.div`
  color: ${(props) => props.theme.palette.khaki};
  padding-left: 0.75rem;
  position: absolute;
  top: 0px;
  bottom: 0px;
  display: flex;
  align-items: center;
  svg {
    width: 1.5rem;
    height: 1.5rem;
  }
`;

export const Label = styled.label`
  cursor: pointer;
`;

export const Checkbox = styled.input`
  opacity: 0;
  appearance: none;
  position: absolute;
`;

export const ButtonWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
  bottom: 0;
  right: 0;
  left: 0;
  background-color: #f3ede6;
  width: 100%;
  height: 83px;
`;

export const ButtonContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  & > button {
    width: 136px;
  }

  & > button:first-child {
    margin-right: 1rem;
  }
`;

export const TagName = styled.span`
  display: flex;
  align-items: center;
  font-weight: 700;
  font-size: 1rem;
  line-height: 1rem;
  color: ${(props) => props.theme.palette.primary.main};

  ::before {
    display: inline-block;
    content: '';
    width: 1.125rem;
    height: 1.125rem;
    margin-right: 1.1875em;
    background-image: ${(props) =>
      props.defaultChecked
        ? "url('data:image/svg+xml;charset=utf8,%3Csvg%20width%3D%2218%22%20height%3D%2218%22%20viewBox%3D%220%200%2018%2018%22%20fill%3D%22none%22%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%3E%3Cpath%20d%3D%22M16%200H2C0.89%200%200%200.9%200%202V16C0%2017.1%200.89%2018%202%2018H16C17.11%2018%2018%2017.1%2018%2016V2C18%200.9%2017.11%200%2016%200ZM7%2014L2%209L3.41%207.59L7%2011.17L14.59%203.58L16%205L7%2014Z%22%20fill%3D%22%232BA9D1%22%2F%3E%3C%2Fsvg%3E')"
        : "url('data:image/svg+xml;charset=utf8,%3Csvg%20width%3D%2218%22%20height%3D%2218%22%20viewBox%3D%220%200%2018%2018%22%20fill%3D%22none%22%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%3E%3Cpath%20d%3D%22M16%202V16H2V2H16ZM16%200H2C0.9%200%200%200.9%200%202V16C0%2017.1%200.9%2018%202%2018H16C17.1%2018%2018%2017.1%2018%2016V2C18%200.9%2017.1%200%2016%200Z%22%20fill%3D%22%23C0C0C0%22%2F%3E%3C%2Fsvg%3E')"};
    background-repeat: no-repeat;
    background-position: center;
  }
`;
