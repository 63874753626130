import { useMutation } from 'react-query';
import humps from 'humps';
import { AxiosError } from 'axios';
import { axiosInstance } from '@lib/pc/common/api/axiosConfig';
import { ErrorResponse, Todo } from '@lib/common/type';

const useUpdateSupplierDepartmentMutation = (
  supplierId: number | string,
  handleSupplierDepartments: (newSupplierDepartments: Todo) => void,
  handleSuccess: () => void,
  setErrMsg: (errMsg: string) => void
) => {
  return useMutation(
    async (params: Todo) => {
      return await axiosInstance()
        .patch(
          `/api/v1/settings/supplier_department/${supplierId}`,
          humps.decamelizeKeys({
            supplier_department_array: params,
          })
        )
        .then((response) => {
          handleSupplierDepartments(humps.camelizeKeys(response.data));
        });
    },
    {
      onSuccess: () => {
        handleSuccess?.();
      },
      onError: (error: AxiosError<ErrorResponse>) => {
        const message = error.response?.data.message || 'エラーが発生しました';
        setErrMsg(message);
      },
    }
  );
};

export default useUpdateSupplierDepartmentMutation;
