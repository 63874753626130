import { useMutation } from 'react-query';
import { axiosInstance } from '@lib/pc/common/api/axiosConfig';
import { Todo } from '@lib/common/type';

const useCreateMaterialOrderForFaxMutation = (
  materialOrderId: number | null,
  handleUpdateSuccess: () => void,
  setErrMsg: (errMsg: string) => void
) => {
  return useMutation(
    async () => {
      return await axiosInstance().post(
        `/api/v1/upload_purchase_order_pdf_to_storage/${materialOrderId}`
      );
    },
    {
      onSuccess: () => {
        handleUpdateSuccess?.();
      },
      onError: (error: Todo) => {
        setErrMsg(error.response.data.message || 'エラーが発生しました');
      },
    }
  );
};

export default useCreateMaterialOrderForFaxMutation;
