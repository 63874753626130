import { axiosInstance } from '@lib/pc/common/api/axiosConfig';
import { useQuery } from 'react-query';
import humps from 'humps';
import type { TagForSetting } from '@lib/pc/settings/product_master_setting/type';

const useTagsForSetting = () => {
  const { data, refetch } = useQuery('tags', async () => {
    const data = await axiosInstance().get(
      `/api/v1/settings/tags/index_for_setting`
    );

    return data;
  });
  const tags = (data && humps.camelizeKeys(data.data.tags)) as TagForSetting[];

  return { tags, refetch };
};

export default useTagsForSetting;
