import styled from 'styled-components';

export const Wrapper = styled.div<{
  open?: string;
  fromPc?: boolean;
  middleOfPcScreen?: boolean;
  width?: string;
  zIndex?: number;
  fontSize?: string;
  isPortraitAndTablet?: boolean;
}>`
  display: ${(props) => (props.open ? 'block' : 'none')};
  bottom: ${(props) => (props.isPortraitAndTablet ? '50%' : '24px')};
  background: #ffffff;
  position: fixed;
  box-shadow: 0px 6px 10px rgba(0, 0, 0, 0.07), 0px 1px 18px rgba(0, 0, 0, 0.06),
    0px 3px 5px rgba(0, 0, 0, 0.1);
  border-radius: 8px;
  width: ${(props) =>
    props.width ? props.width : props.fromPc ? '22rem' : '85%'};
  left: ${(props) => (props.fromPc ? '50%' : '8%')};
  top: ${(props) => (props.middleOfPcScreen ? '50%' : '')};
  height: ${(props) => (props.middleOfPcScreen ? 'fit-content' : '')};
  transform: ${(props) => (props.fromPc ? 'translate(-50%, -50%)' : '')};
  color: ${(props) => props.theme.palette.black};
  padding: 16px 18px;
  z-index: ${(props) => (props.zIndex ? props.zIndex : 2)};
  line-height: 1.5;
  font-size: ${(props) => (props.fontSize ? props.fontSize : '')};
  span {
    font-weight: 700;
  }
`;

export const ButtonContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 2rem;
  margin-top: 1rem;
  button {
    width: 40%;
    height: 2.75rem;
    // &:nth-child(2) {
    //   border-color: ${(props) => props.theme.palette.error};
    //   color: ${(props) => props.theme.palette.error};
    // }
  }
`;
