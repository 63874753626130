import styled from 'styled-components';

export const TableWrapper = styled.div`
  max-height: calc(100vh - 169px);
  overflow: auto;
`;
export const Stable = styled.table`
  width: 100%;
  border-collapse: collapse;
`;

export const Sthead = styled.thead`
  background: ${(props) => props.theme.palette.beige};
  height: 44px;
  border-bottom: 1px solid ${(props) => props.theme.palette.sand};
  position: sticky;
  top: 0;
  left: 0;
`;

export const TbodyHeader = styled.tr`
  background: #f0f4ff;
  height: 45px;
  border-bottom: 1px solid ${(props) => props.theme.palette.sand};
  font-size: 0.875rem;
  font-weight: 500;
`;

export const Tr = styled.tr`
  background: #fefefe;
  height: 45px;
  border-bottom: 1px solid ${(props) => props.theme.palette.sand};
  font-size: 0.875rem;
  :last-child {
    border-bottom: 1px solid ${(props) => props.theme.palette.blue};
  }
`;

export const Td = styled.td<{
  alignRight?: boolean;
}>`
  text-align: ${(props) => (props.alignRight ? 'right' : 'left')};
  vertical-align: middle;
  padding: 0.5rem;
`;

export const Th = styled.th`
  vertical-align: middle;
  text-align: left;
  font-size: 0.75rem;
  font-weight: 500;
  padding: 0.5rem;
`;

export const EmptyArrowIconDiv = styled.div`
  width: 1.5rem;
  height: 1.5rem;
`;

export const TbodyTh = styled.th<{
  alignRight?: boolean;
  fontSize?: string;
}>`
  vertical-align: middle;
  text-align: ${(props) => (props.alignRight ? 'right' : 'left')};
  padding: 0.5rem;
  > span {
    font-size: 0.75rem;
  }
`;

export const Input = styled.input``;
export const IconThWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  padding-right: 1.25rem;
  span {
    font-size: 0.75rem;
  }
`;

export const IconWrapper = styled.div`
  display: flex;
  align-items: center;
`;

export const FlexCenter = styled.div`
  display: flex;
  align-items: center;
`;

export const Status = styled.div`
  font-size: 0.75rem;
  font-weight: 700;
  height: 24px;
  width: 62px;
  background-color: #ffd130;
  display: flex;
  justify-content: center;
  justify-content: center;
  align-items: center;
`;

export const StatusConfirm = styled(Status)`
  background-color: ${(props) => props.theme.palette.khaki};
  color: ${(props) => props.theme.palette.white};
`;

export const Checkbox = styled.input`
  width: 1.125rem;
  height: 1.125rem;
  opacity: 0;
  appearance: none;
  position: absolute;
`;

export const TagName = styled.span`
  display: inline-block;
  content: '';
  width: 1.125rem;
  height: 1.125rem;
  background-image: ${(props) =>
    props.defaultChecked
      ? "url('data:image/svg+xml;charset=utf8,%3Csvg%20width%3D%2218%22%20height%3D%2218%22%20viewBox%3D%220%200%2018%2018%22%20fill%3D%22none%22%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%3E%3Cpath%20d%3D%22M16%200H2C0.89%200%200%200.9%200%202V16C0%2017.1%200.89%2018%202%2018H16C17.11%2018%2018%2017.1%2018%2016V2C18%200.9%2017.11%200%2016%200ZM7%2014L2%209L3.41%207.59L7%2011.17L14.59%203.58L16%205L7%2014Z%22%20fill%3D%22%232BA9D1%22%2F%3E%3C%2Fsvg%3E')"
      : "url('data:image/svg+xml;charset=utf8,%3Csvg%20width%3D%2218%22%20height%3D%2218%22%20viewBox%3D%220%200%2018%2018%22%20fill%3D%22none%22%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%3E%3Cpath%20d%3D%22M16%202V16H2V2H16ZM16%200H2C0.9%200%200%200.9%200%202V16C0%2017.1%200.9%2018%202%2018H16C17.1%2018%2018%2017.1%2018%2016V2C18%200.9%2017.1%200%2016%200Z%22%20fill%3D%22%23C0C0C0%22%2F%3E%3C%2Fsvg%3E')"};
  background-repeat: no-repeat;
  background-position: center;
`;
