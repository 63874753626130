import * as S from './DeleteConfirmModal.styled';
import { ReactComponent as DeleteIcon } from '@assets/icons/delete.svg';
import { Button } from '@components/elements/buttons/Button';

type Props = {
  confirmModal: boolean;
  setConfirmModal: (confirmModal: boolean) => void;
  delIndex: number;
  handleDeleteButton: (delIndex: number) => void;
};

const DeleteModal = ({
  confirmModal,
  setConfirmModal,
  delIndex,
  handleDeleteButton,
}: Props) => {
  return (
    <S.DeleteModalWrapper open={confirmModal}>
      <S.DeleteModalTextContainer>
        <span>削除後は元に戻すことが出来ません。</span>
        <span>本当に削除しますか？</span>
      </S.DeleteModalTextContainer>
      <S.DeleteModalButtonContainer>
        <Button
          padding="sm"
          outlined
          onClick={() => {
            setConfirmModal(false);
          }}
        >
          キャンセル
        </Button>
        <Button
          padding="sm"
          icon={<DeleteIcon />}
          outlined
          onClick={() => {
            handleDeleteButton(delIndex);
            setConfirmModal(false);
          }}
        >
          削除
        </Button>
      </S.DeleteModalButtonContainer>
    </S.DeleteModalWrapper>
  );
};

export default DeleteModal;
