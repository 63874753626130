import styled from 'styled-components';

export const Wrapper = styled.div`
  padding: 0.75rem;
`;

export const FixedArea = styled.div<{
  scrollPosition: number;
}>`
  top: 0;
  position: fixed;
  z-index: 1002;
  background-color: ${(props) => props.theme.palette.white};
  /* padding-bottom: 0.5rem; */
  box-shadow: ${(props) =>
    props.scrollPosition === 0
      ? '0px 6px 10px rgba(0, 0, 0, 0.07), 0px 1px 18px rgba(0, 0, 0, 0.06), 0px 3px 5px rgba(0, 0, 0, 0.1)'
      : props.theme.palette.white};
`;

export const IconWrapper = styled.div`
  width: 1.5rem;
  padding-left: 8px;
  padding-left: 12px;
`;

export const BackButton = styled.button`
  padding-left: 0.5rem;
  padding-right: 0.5rem;
  background: none;
`;

export const Title = styled.div`
  display: flex;
  /* align-items: center;
  justify-content: center; */
  font-weight: 700;
  font-size: 1.125rem;
  &.status {
    padding-top: 1rem;
    padding-left: 7px;
  }
  &.storeroom {
    padding: 8px 0;
    background-color: ${(props) => props.theme.palette.beige};
  }
`;

export const TitleContainer = styled.div`
  padding-left: 20px;
  &.shipping-date {
    padding-top: 15px;
    padding-bottom: 15px;
    padding-left: 2px;
  }
`;

export const PlanText = styled.div`
  font-size: 24px;
  &.group-name {
    font-size: 18px;
  }
  > span {
    font-size: 18px;
  }
`;

export const StatusWrapper = styled.div<{
  status?: string;
}>`
  width: 45%;
  font-size: 16px;
  font-weight: 500;
  border-radius: 0.5rem;
  padding: 0.25rem;
  text-align: center;
  border: ${(props) =>
    props.status === 'pickingNotStarted'
      ? '#FFD130 2px solid'
      : props.status === 'picking'
      ? '#F19B4C 2px solid'
      : '#6B675C 2px solid'};
  background: ${(props) =>
    props.status === 'pickingNotStarted'
      ? '#FFD130'
      : props.status === 'picking'
      ? '#FFFDE9'
      : '#E0E0E0'};
  font-weight: bold;
  color: ${(props) =>
    props.status === 'pickingNotStarted'
      ? props.theme.palette.baseBlack
      : props.status === 'picking'
      ? '#F19B4C'
      : '#6B675C'};
`;

export const ProductInfoWrapper = styled.div`
  width: 55%;
`;

export const ProductInfoText = styled.div`
  float: right;
  font-weight: 500;
  > span {
    font-size: 14px;
  }
  /* margin-top: 10px; */
`;

export const StoreroomText = styled.div`
  padding-top: 1px;
  padding-left: 25px;
  font-weight: 400;
  font-size: 16px;
`;

export const ScrollArea = styled.div<{
  fixedAreaHeight: string;
}>`
  /* margin-top: 43%; */
  /* margin-top: 40%; */
  margin-top: ${(props) => props.fixedAreaHeight + 'px'};
`;
