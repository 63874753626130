import { FC } from 'react';
import type { Todo } from '@lib/common/type';
import ReactSelect from 'react-select';
import { NO_OPTION_JAPANESE } from '@lib/pc/common/constants';

type Props = {
  data: Todo[] | undefined;
  value: string | number | undefined;
  height: string;
  disabled?: boolean;
  setSelectedRoundingMethod: (selectedRoundingMethod: string) => void;
  setEnableToSave?: (enableToSave: true) => void;
};

const CommonReactSelect: FC<Props> = ({
  data,
  value,
  height,
  disabled,
  setSelectedRoundingMethod,
  setEnableToSave,
}: Props) => {
  const toReactSelectOptions = (options: { id: string; name: string }[]) =>
    options.map(({ id, name }) => ({
      value: id,
      label: name,
    }));

  const reactSelectOptions = data && toReactSelectOptions(data);
  const currentValue = reactSelectOptions?.find((o) => o.value === value);

  const styles = {
    control: (baseStyles: Todo) => ({
      ...baseStyles,
      minWidth: '100%',
      width: 'auto',
      height: height ? height : '56px',
      marginTop: '8px',
      borderRadius: '0.5rem',
      textAlign: 'left',
      borderStyle: 'hidden',
      outline: '0.5px solid rgba(0,0,0,0.08)',
      backgroundColor: disabled ? '' : 'white',
      cursor: 'pointer',
      boxShadow: 'none',
      '@media (max-width: 599px)': {
        width: '16rem',
      },
    }),
    menu: (baseStyles: Todo) => ({
      ...baseStyles,
      top: '27px',
      zIndex: 1002,
      marginTop: '15px',
    }),
    option: (baseStyles: Todo) => ({
      ...baseStyles,
      textAlign: 'left',
      fontWeight: 400,
      fontSize: '15px',
    }),
    menuList: (baseStyles: Todo) => ({
      ...baseStyles,
      maxHeight: '195px',
    }),
    ValueContainer: (baseStyles: Todo) => ({
      ...baseStyles,
      padding: '2px 0',
    }),
  };

  return (
    <ReactSelect
      options={reactSelectOptions}
      value={currentValue ? currentValue : null}
      onChange={(option) => {
        option && setSelectedRoundingMethod(option.value);
        setEnableToSave && setEnableToSave(true);
      }}
      styles={styles}
      isDisabled={disabled}
      isSearchable={false}
      components={{
        IndicatorSeparator: () => null,
      }}
      noOptionsMessage={() => NO_OPTION_JAPANESE}
      placeholder=""
    />
  );
};

export default CommonReactSelect;
