import { axiosInstance } from '@lib/pc/common/api/axiosConfig';
import { useQuery } from 'react-query';
import humps from 'humps';
import type { FactoryAll } from '@lib/common/type';

const useFactoryLines = () => {
  return useQuery(['factories'], async () => {
    const { data } = await axiosInstance().get(`/api/v1/factories`);
    return humps.camelizeKeys(data.factories) as FactoryAll[];
  });
};

export default useFactoryLines;
