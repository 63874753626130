import { useEffect, useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { HTML5Backend } from 'react-dnd-html5-backend';
import { DndProvider } from 'react-dnd';
import * as S from './SalesStoreEditWrapper.styles';
import ConfirmPopUp from '@components/modules/common/ConfirmPopUp/ConfirmPopUp';
import ErrorMsgPopUp from '@components/modules/common/ErrorMsgPopUp/ErrorMsgPopUp';
import Overlay from '@components/elements/backdrops/Overlay/Overlay';
import UpdatePopUp from '@components/modules/pc/settings/common/UpdatePopUp/UpdatePopUp';
import SalesStoreContents from '@components/modules/pc/settings/sales_store_setting/SalesStoreEdit/SalesStoreContents/SalesStoreContents';
import { CustomerState } from '@lib/pc/settings/customer_setting/type';
import { SalesStoreEdit } from '@lib/pc/settings/sales_store_edit/type';

type Props = {
  salesStore: SalesStoreEdit;
  refetch: () => void;
  timestamp: string;
};

const SalesStoreEditWrapper = ({ salesStore, refetch }: Props) => {
  const { state } = useLocation<CustomerState>();
  const history = useHistory();

  const [newData, setNewData] = useState(salesStore);

  // 削除確認メッセージ
  const [confirmMsg, setConfirmMsg] = useState('');
  const [errMsg, setErrMsg] = useState('');
  const [enableToSave, setEnableToSave] = useState(false);
  const [updating, setUpdating] = useState(false);

  // 確認メッセージhandleOk判定用:一覧画面へ移動か、別タブ表示か。
  const [goBack, setGoBack] = useState(false);
  const [isChanged, setIsChanged] = useState(false);
  const [updatePopUp, setUpdatePopUp] = useState(false);

  const shippingDestinationInfo = {
    value: salesStore?.shippingDestinationId,
    label: salesStore?.shippingDestinationName,
    code: salesStore?.shippingDestinationCode,
  };

  // 一覧画面へ戻る
  const onClickGoBack = () => {
    if (isChanged) {
      setGoBack(true);
      setConfirmMsg('変更した内容を保存せずに終了します。よろしいですか？');
    } else {
      history.push({
        pathname: `/pc/settings/sales_store_setting`,
      });
    }
  };

  // 確認メッセージOK
  const handleOk = () => {
    // ここで一覧画面へ戻るか別タブを表示するかの判断が必要
    if (goBack) {
      history.push({
        pathname: `/pc/settings/sales_store_setting`,
        state: {
          ...state,
          messageKind: '',
          productName: '',
        },
      });
    }
    setIsChanged(false);
    setConfirmMsg('');
  };
  // 確認メッセージキャンセル
  const handleCancel = () => {
    setConfirmMsg('');
  };

  useEffect(() => {
    setNewData(salesStore);
  }, [salesStore]);

  return (
    <>
      <S.Wrapper>
        <DndProvider backend={HTML5Backend}>
          {/* 販売店 */}
          <SalesStoreContents
            newData={newData}
            setNewData={setNewData}
            enableToSave={enableToSave}
            setEnableToSave={setEnableToSave}
            setIsChanged={setIsChanged}
            onClickGoBack={onClickGoBack}
            updating={updating}
            setUpdating={setUpdating}
            refetch={refetch}
            setUpdatePopUp={setUpdatePopUp}
            shippingDestinationInfo={shippingDestinationInfo}
          />
        </DndProvider>
      </S.Wrapper>
      <ErrorMsgPopUp
        errMsg={errMsg}
        handleClose={() => {
          setErrMsg('');
          setUpdating(false);
        }}
        fromPc={true}
        zIndex={9999}
      />
      {errMsg && (
        <Overlay
          dark
          handleClick={() => {
            setErrMsg('');
            setUpdating(false);
          }}
        />
      )}
      <ConfirmPopUp
        fromPc={true}
        confirmMsg={confirmMsg}
        zIndex={1004}
        handleOk={handleOk}
        handleCancel={handleCancel}
      />
      <UpdatePopUp
        popUp={updatePopUp}
        taskKind={'messageOnly'}
        messageKind={'update'}
        handleClose={() => setUpdatePopUp(false)}
      />
      {updatePopUp && <Overlay handleClick={() => setUpdatePopUp(false)} />}
    </>
  );
};

export default SalesStoreEditWrapper;
