import styled from 'styled-components';

export const Wrapper = styled.div<{
  bgColor: string;
}>`
  width: 100vw;
  height: calc(100vh - 193px);
  /* ライン別背景 */
  background-color: ${(props) => props.bgColor};
`;

export const TabWrapper = styled.div`
  display: flex;
  width: 100vw;
  font-feature-settings: 'palt';
  border-bottom: 1px solid #cdcdcd;
`;

export const TabArea = styled.div`
  display: flex;
  background-color: #222e3e;
  cursor: pointer;
`;

export const Tab = styled.div<{
  active: boolean;
  tabCount: number;
}>`
  background-color: ${(props) =>
    props.active ? props.theme.palette.blue : '#F8F7F4'};
  padding-top: 16px;
  width: calc(100vw / ${(props) => props.tabCount});
  height: 64px;
  line-height: 30px;
  text-align: center;
  color: ${(props) => (props.active ? '#FFFFFF' : 'rgba(51, 51, 51, 0.5)')};
  font-weight: ${(props) => (props.active ? '900' : '400')};
  font-family: 'Noto Sans JP';
  font-style: normal;
  font-size: 32px;
  line-height: 32px;
  align-items: center;
  text-align: center;
`;

export const DraggableTRow = styled.tr<{
  bgColor: string;
}>`
  border: 1px solid #e6e1dd;
  height: 4.5rem;
  background-color: ${(props) => props.bgColor};
`;
