import styled from 'styled-components';

export const Wrapper = styled.div`
  width: 100%;
  height: 100vh;
  padding: 16px 80px;
  background-color: ${(props) => props.theme.palette.beige};
  overflow-y: scroll;
  font-feature-settings: 'palt';
`;

export const GoBackWrapper = styled.div`
  width: 160px;
  display: flex;
  padding-bottom: 32px;
  cursor: pointer;
  color: ${(props) => props.theme.palette.blue};
  > span {
    padding-top: 8px;
    padding-left: 10px;
  }
`;

export const GroupCodeWrapper = styled.div`
  display: flex;
  margin-bottom: 36px;
  > span {
    font-weight: 700;
    margin-right: 55px;
  }
`;

export const NameWrapper = styled.div`
  display: flex;
  margin-bottom: 36px;
  > span {
    font-weight: 700;
    margin-right: 72px;
  }
`;

export const TabWrapper = styled.div`
  display: flex;
  width: 100vw;
`;

export const TabArea = styled.div`
  display: flex;
  background-color: #222e3e;
  cursor: pointer;
`;

export const Tab = styled.div<{
  active: boolean;
  tabCount: number;
}>`
  background-color: ${(props) =>
    props.active ? props.theme.palette.blue : '#F8F7F4'};
  padding-top: 16px;
  width: calc(100vw / ${(props) => props.tabCount});
  height: 64px;
  line-height: 30px;
  text-align: center;
  color: ${(props) => (props.active ? '#FFFFFF' : 'rgba(51, 51, 51, 0.5)')};
  font-weight: ${(props) => (props.active ? '900' : '400')};
  font-family: 'Noto Sans JP';
  font-style: normal;
  font-size: 32px;
  line-height: 32px;
  align-items: center;
  text-align: center;
`;

export const ShippingGroupAreaDl = styled.dl`
  > dt {
    font-weight: 700;
    margin-bottom: 8px;
  }
  > dd {
    margin-bottom: 24px;
  }
`;

export const ShippingDestinationTitle = styled.div`
  font-weight: 700;
  padding-bottom: 8px;
  text-align: left;
  width: 80%;
`;

export const ShippingDestinationSelectRow = styled.div`
  display: flex;
  gap: 24px;
`;

export const ShippingDestinationSelectWrapper = styled.div`
  width: 90%;
`;

export const AddButton = styled.span`
  padding-top: 24px;
  justify-content: center;
  align-items: center;
  dd {
    text-align: -webkit-right;
  }
  button {
    width: 64px;
    &.disabled {
      background: #e0e0e0;
      border-color: #e0e0e0;
      color: #6b675c;
      cursor: initial;
    }
  }
`;

export const ShippingDestinationsWrapper = styled.div`
  margin-top: 24px;
  overflow: scroll;
  height: calc(100vh - 193px);
  padding-bottom: 500px;
`;

export const ShippingDestination = styled.div`
  width: 92%;
  padding: 4px;
  display: flex;
  border-bottom: 1px solid #cdcdcd;
  align-items: center;
`;

export const DeleteButton = styled.div`
  /* margin: 12px 0; */
`;

export const CodeNameWrapper = styled.div`
  display: flex;
`;

export const CodeWrapper = styled.div`
  width: 100px;
`;

export const CloseButton = styled.div`
  width: 40px;
  height: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const ButtonContainer = styled.div`
  position: fixed;
  width: 100vw;
  bottom: 0;
  padding: 22px 0;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #f8f7f4;
  & > button {
    width: 136px;
    &.disabled {
      background: #e0e0e0;
      border-color: #e0e0e0;
      color: #6b675c;
      cursor: initial;
    }
  }
`;
