import * as S from './SalesManagementTable.styles';
import { ReactComponent as FilterListIcon } from '@assets/icons/mobile/filter_list.svg';
import { ReactComponent as MoreVertIcon } from '@assets/icons/vector.svg';
import { SalesInformation } from '@lib/common/type';

type Props = {
  salesInformationList: SalesInformation[];
};

const SalesManagementTable = ({ salesInformationList }: Props) => {
  return (
    <S.TableWrapper>
      <S.Stable>
        <S.Sthead>
          <tr>
            <S.Th></S.Th>
            <S.Th>
              <S.IconWrapper>
                記入日
                <MoreVertIcon />
              </S.IconWrapper>
            </S.Th>
            <S.Th>
              <S.IconWrapper>
                営業担当
                <FilterListIcon />
              </S.IconWrapper>
            </S.Th>
            <S.Th>件名</S.Th>
            <S.Th>
              <S.IconWrapper>
                得意先名
                <FilterListIcon />
              </S.IconWrapper>
            </S.Th>
            <S.Th></S.Th>
            <S.Th></S.Th>
            <S.Th></S.Th>
            <S.Th></S.Th>
            <S.Th></S.Th>
            <S.Th></S.Th>
          </tr>
        </S.Sthead>

        {salesInformationList.map((information, index) => (
          <tbody key={index}>
            <S.TbodyHeader>
              <S.TbodyTh alignRight>
                {/* チェクボックスは動きをつける時に追加する */}
                <S.Checkbox type="checkbox" />
                <S.TagName></S.TagName>
              </S.TbodyTh>
              <S.TbodyTh>{information.entryDate}</S.TbodyTh>
              <S.TbodyTh>
                <S.FlexCenter>{information.employee.name}</S.FlexCenter>
              </S.TbodyTh>
              <S.TbodyTh>{information.title}</S.TbodyTh>
              <S.TbodyTh>{information.customer.name}</S.TbodyTh>
              <S.TbodyTh>
                <span>商品名</span>
              </S.TbodyTh>
              <S.TbodyTh alignRight>
                <span>1次見込数量</span>
              </S.TbodyTh>
              <S.TbodyTh alignRight>
                <span>2次見込数量</span>
              </S.TbodyTh>
              <S.TbodyTh alignRight>
                <span>確定数量</span>
              </S.TbodyTh>
              <S.TbodyTh>
                <span>出荷予定日</span>
              </S.TbodyTh>
              <S.TbodyTh>
                <span>備考</span>
              </S.TbodyTh>
            </S.TbodyHeader>
            {information.details.map((detail, index) => (
              <S.Tr key={index}>
                <S.Td></S.Td>
                <S.Td>{information.entryDate}</S.Td>
                <S.Td>
                  <S.FlexCenter>{information.employee.name}</S.FlexCenter>
                </S.Td>
                <S.Td>{information.title}</S.Td>
                <S.Td>{information.customer.name}</S.Td>

                <S.Td>{detail.product.name}</S.Td>
                <S.Td alignRight>
                  {detail.primaryEstimatedAmount
                    ?.toString()
                    ?.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                </S.Td>
                <S.Td alignRight>
                  {detail.secondaryEstimatedAmount
                    ?.toString()
                    ?.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                </S.Td>
                <S.Td alignRight>
                  {detail.fixedAmount
                    ?.toString()
                    ?.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                </S.Td>
                <S.Td>{detail.deliveryDate}</S.Td>
                <S.Td>{information.comment}</S.Td>
              </S.Tr>
            ))}
          </tbody>
        ))}
      </S.Stable>
    </S.TableWrapper>
  );
};

export default SalesManagementTable;
