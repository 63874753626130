import { FC, useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import useWindowScroll from 'react-use/lib/useWindowScroll';
import * as S from './CameraList.styles';
import {
  Camera,
  ProductsPhotographState,
} from '@lib/mobile/products_photograph/type';

type Props = {
  cameras: Camera[] | undefined;
  searchChar: string;
  scrollPosition: number;
};

const CameraList: FC<Props> = ({ cameras, searchChar }) => {
  const { state } = useLocation<ProductsPhotographState>();
  const [stateScrollPosition, setScrollPosition] = useState(0);
  const { y: currentScrollPosition } = useWindowScroll();

  useEffect(() => {
    if (state === undefined) {
      window.scrollTo(0, 0);
      document?.getElementById('table-contents')?.scrollTo(0, 0);
    } else {
      window.scrollTo(0, state.stateScrollPosition);
    }
  }, [state]);

  // スクロール位置保存
  useEffect(() => {
    setScrollPosition(currentScrollPosition);
  }, [currentScrollPosition]);

  return (
    <>
      {cameras &&
        cameras?.map((c, i) => (
          <S.Camera
            to={{
              pathname: `/mobile/products_photograph_list/${c.cameraId}`,
              state: {
                ...state,
                stateSearchChar: searchChar,
                stateScrollPosition: stateScrollPosition,
                stateCameraName: c.cameraName,
                stateCameraId: c.cameraId,
              },
            }}
            index={i}
            key={i}
          >
            <S.CameraInfo>
              <S.CameraName>{c.cameraName}</S.CameraName>
            </S.CameraInfo>
          </S.Camera>
        ))}
    </>
  );
};

export default CameraList;
