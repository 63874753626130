import { useState } from 'react';
import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import * as S from './CustomerCreateWrapper.styles';
import { useHistory, useLocation } from 'react-router-dom';
import ConfirmPopUp from '@components/modules/common/ConfirmPopUp/ConfirmPopUp';
import ErrorMsgPopUp from '@components/modules/common/ErrorMsgPopUp/ErrorMsgPopUp';
import Overlay from '@components/elements/backdrops/Overlay/Overlay';
import UpdatePopUp from '@components/modules/pc/settings/common/UpdatePopUp/UpdatePopUp';
import CustomerContents from '@components/modules/pc/settings/customer_setting/CustomerEdit/CustomerContents/CustomerContents';
import { createdCustomerData } from '@components/modules/pc/settings/customer_setting/CustomerCreate/default';
import { CustomerState } from '@lib/pc/settings/customer_setting/type';

const CustomerCreateWrapper = () => {
  const { state } = useLocation<CustomerState>();
  const history = useHistory();

  const initShippingDestinations = {
    id: 0,
    code: '',
    name: '',
  };

  const initNewData = {
    id: 0,
    name: '',
    code: '',
    shortName: '',
    nameKana: '',
    shippingDestinations: [initShippingDestinations],
  };

  const [newData, setNewData] = useState(initNewData);

  // 削除確認メッセージ
  const [confirmMsg, setConfirmMsg] = useState('');
  const [errMsg, setErrMsg] = useState('');
  const [enableToSave, setEnableToSave] = useState(false);
  const [updating, setUpdating] = useState(false);
  const [selectedTabIndex, setSelectedTabIndex] = useState(0);
  // 確認メッセージhandleOk判定用:一覧画面へ移動か、別タブ表示か。
  const [goBack, setGoBack] = useState(false);
  const [isChanged, setIsChanged] = useState(false);
  const [selectedTabId, setSelectedTabId] = useState(0);
  const [updatePopUp, setUpdatePopUp] = useState(false);

  const [createdData, setCreatedData] = useState(createdCustomerData);

  const CustomerSettingTabs = ['得意先', '出荷先'];

  // 一覧画面へ戻る
  const onClickGoBack = () => {
    if (isChanged) {
      setGoBack(true);
      setConfirmMsg('変更した内容を保存せずに終了します。よろしいですか？');
    } else {
      history.push({
        pathname: `/pc/settings/customer_setting`,
        state: state,
      });
    }
  };

  const handleClickTab = (i: number) => {
    setGoBack(false);
    setEnableToSave(false);
    if (createdData.id === 0) {
      setErrMsg('はじめに得意先を登録してください。');
    } else if (isChanged) {
      setSelectedTabId(i);
      setConfirmMsg('変更した内容を保存せずに終了します。よろしいですか？');
    } else {
      setSelectedTabIndex(i);
      history.push(`/pc/settings/customer_setting_edit/${createdData.id}`, {
        ...state,
        id: createdData.id,
        code: createdData.code,
        name: createdData.name,
        shortName: createdData.shortName,
        nameKana: createdData.nameKana,
        stateSelectedTabId: i,
      });
    }
  };

  // 確認メッセージOK
  const handleOk = () => {
    // ここで一覧画面へ戻るか別タブを表示するかの判断が必要
    if (goBack) {
      history.push({
        pathname: `/pc/settings/customer_setting`,
        state: {
          ...state,
          messageKind: '',
          productName: '',
          stateSelectedTabId: 0,
        },
      });
    } else {
      setSelectedTabIndex(selectedTabId);
    }
    setIsChanged(false);
    setConfirmMsg('');
  };
  // 確認メッセージキャンセル
  const handleCancel = () => {
    setConfirmMsg('');
  };

  return (
    <>
      <S.Wrapper>
        <S.TabWrapper>
          {CustomerSettingTabs.map((d: string, i: number) => (
            <div key={d}>
              <S.TabArea>
                <S.Tab
                  key={d}
                  tabCount={CustomerSettingTabs.length}
                  active={i == selectedTabIndex}
                  onClick={() => {
                    handleClickTab(i);
                  }}
                >
                  {d}
                </S.Tab>
              </S.TabArea>
            </div>
          ))}
        </S.TabWrapper>

        <DndProvider backend={HTML5Backend}>
          {/* 得意先 */}
          {selectedTabIndex === 0 && (
            <CustomerContents
              newData={newData}
              setNewData={setNewData}
              enableToSave={enableToSave}
              setEnableToSave={setEnableToSave}
              setIsChanged={setIsChanged}
              onClickGoBack={onClickGoBack}
              updating={updating}
              setUpdating={setUpdating}
              setUpdatePopUp={setUpdatePopUp}
              setCreatedData={setCreatedData}
            />
          )}
        </DndProvider>
      </S.Wrapper>
      <ErrorMsgPopUp
        errMsg={errMsg}
        handleClose={() => {
          setErrMsg('');
          setUpdating(false);
        }}
        fromPc={true}
        zIndex={9999}
      />
      {errMsg && (
        <Overlay
          dark
          handleClick={() => {
            setErrMsg('');
            setUpdating(false);
          }}
        />
      )}
      <ConfirmPopUp
        fromPc={true}
        confirmMsg={confirmMsg}
        zIndex={1004}
        handleOk={handleOk}
        handleCancel={handleCancel}
      />
      <UpdatePopUp
        popUp={updatePopUp}
        taskKind={'messageOnly'}
        messageKind={'create'}
        handleClose={() => setUpdatePopUp(false)}
      />
      {updatePopUp && <Overlay handleClick={() => setUpdatePopUp(false)} />}
    </>
  );
};

export default CustomerCreateWrapper;
