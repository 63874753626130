import { useMutation } from 'react-query';
import humps from 'humps';
import { AxiosError } from 'axios';
import { axiosInstance } from '@lib/pc/common/api/axiosConfig';
import { ErrorResponse } from '@lib/common/type';
import { SupplierContactEdit } from '@lib/pc/settings/supplier_edit/type';

const useCreateOrUpdateSupplierContactMutation = (
  supplierDepartmentId: number | string,
  handleSuccess: () => void,
  setErrMsg: (errMsg: string) => void
) => {
  return useMutation(
    async (params: SupplierContactEdit) => {
      return await axiosInstance().patch(
        `/api/v1/settings/supplier_contact/${supplierDepartmentId}`,
        humps.decamelizeKeys({
          supplier_contact: {
            supplier_department_name: params.supplierDepartmentName,
            name: params.name,
            zip_code: params.zipCode,
            address: params.address,
            phone_number: params.phoneNumber,
            fax_number: params.faxNumber,
            email_subject_default: params.emailSubjectDefault,
            email_body_default: params.emailBodyDefault,
            email: params.email ? params.email : [],
            cc_email_addresses: params.ccEmailAddresses
              ? params.ccEmailAddresses
              : [],
          },
        })
      );
    },
    {
      onSuccess: () => {
        handleSuccess?.();
      },
      onError: (error: AxiosError<ErrorResponse>) => {
        const message = error.response?.data.message || 'エラーが発生しました';
        setErrMsg(message);
      },
    }
  );
};

export default useCreateOrUpdateSupplierContactMutation;
