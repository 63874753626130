export type SupplierDepartment = {
  id: number | null;
  supplierId: number;
  name: string;
};

export type EmployeesAndSuppliersData = {
  id: number;
  name: string;
  code: string;
  email: string;
};

export type CurrentUserEmployee = {
  id: number;
  name: string;
  email: string;
  code: string;
};

export type SupplierEdit = {
  id: number;
  name: string;
  code: string;
  description: string;
  honorific: string;
  nameKana: string;
  orderType: string;
  roundingMethod: string;
  supplierDepartments: SupplierDepartment[] | null;
  employeesAndSuppliersData: EmployeesAndSuppliersData[] | null;
  currentUserEmployees: CurrentUserEmployee[] | null;
  employeeIds: number[] | null;
};

export type SupplierDepartmentCreate = {
  supplierId: number;
  name: string;
};

export type EmployeesForUpdate = {
  code: string;
  email: string;
  name: string;
  id: number;
};

export type SupplierContactEdit = {
  supplierDepartmentName: string;
  name: string;
  zipCode: string;
  address: string;
  email: string[];
  ccEmailAddresses: string[];
  phoneNumber: string;
  faxNumber: string;
  emailSubjectDefault: string;
  emailBodyDefault: string;
};

export const RoundingMethod = [
  {
    id: 'floor',
    name: '切り捨て',
  },
  {
    id: 'ceil',
    name: '切り上げ',
  },
  {
    id: 'round',
    name: '四捨五入',
  },
];
