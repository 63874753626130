import { axiosInstance } from '@lib/pc/common/api/axiosConfig';
import { useQuery } from 'react-query';
import humps from 'humps';
import { StoreroomWithDisplayOrder } from '@lib/pc/settings/product_master_edit/type';

const useStoreroomsWitDisplayOrder = (productKind: number | string) => {
  return useQuery('storerooms', async () => {
    const { data } = await axiosInstance().get(
      `/api/v1/storerooms?product_kind=${productKind}`
    );
    return humps.camelizeKeys(data.storerooms) as StoreroomWithDisplayOrder[];
  });
};

export default useStoreroomsWitDisplayOrder;
