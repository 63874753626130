import styled from 'styled-components';

export const Wrapper = styled.div<{
  open?: boolean;
}>`
  width: 100vw;
  height: 100%;

  background: ${(props) => props.theme.palette.sandGray};
  padding: 1.5rem 1.5rem;

  top: ${(props) => (props.open ? '0' : '100%')};
  position: fixed;
  transition: all 0.5s ease-out;
  z-index: 10000;
  overflow-x: hidden;
`;

export const ModalHead = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 0 0.5rem 1.3rem;
`;

export const ModalHeadCloseIcon = styled.div`
  cursor: pointer;
  padding: 8px;

  > svg {
    width: 24px;
    height: 24px;
  }
`;

export const ProductMetaContainer = styled.div`
  display: flex;
  align-items: center;

  font-weight: 700;

  > div:nth-child(2) {
    margin-left: 1rem;
    font-size: 1.5rem;
  }
`;

export const GraphWrapper = styled.div`
  margin: 0.5rem 0;
  display: flex;
  flex-direction: column;
  border-radius: 16px;
  background: ${(props) => props.theme.palette.white};
`;

export const GraphHeader = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 2.25rem 1.75rem;
  background: ${(props) => props.theme.palette.white};
`;

export const MainContainer = styled.div`
  padding: 2rem;

  border-radius: 0 0 16px 16px;
  background: ${(props) => props.theme.palette.beige};
`;

export const GraphControlHead = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  background: ${(props) => props.theme.palette.beige};
  margin-bottom: 2.5rem;
  > div {
    width: 50%;
  }
`;

export const DescHeader = styled.div`
  font-size: 14px;
  color: ${(props) => props.theme.palette.khaki};
`;

export const GraphContainer = styled.div`
  min-height: 500px;

  display: flex;
`;

export const LeftHeadContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: end;
`;

export const LeftLabelContainer = styled.div`
  display: flex;
  position: relative;
  width: 100%;
  justify-content: right;
`;

export const LeftGraphLabelContainer = styled.div`
  position: absolute;
  left: 0;
`;

export const ColorBox = styled.div<{
  bgColor: string;
}>`
  height: 1rem;
  width: 1.5rem;
  background: ${(props) => props.bgColor};
`;

export const GraphLeftLabelContainer = styled.div`
  display: flex;
  align-items: unset;
  gap: 0.25rem;
  min-width: 160px;

  &:not(:first-child) {
    margin-top: 1rem;
  }

  > span {
    margin-left: 4px;
    color: ${(props) => props.theme.palette.baseBlack};
    font-weight: bold;
  }
`;

export const LeftGraphTextContainer = styled.div`
  width: 130px;
  display: flex;
  flex-direction: column;
  color: ${(props) => props.theme.palette.baseBlack};
  font-weight: bold;
  font-size: 14px;
  gap: 0.5rem;

  > span {
    font-size: 10px;
    font-weight: 400;
  }
`;

export const GraphLeftLabelYAxisContainer = styled.div`
  width: 50px;
`;

export const ScrollableContainer = styled.div`
  // max-width: 720px;
  overflow-x: scroll;

  display: flex;
  flex-direction: column;
`;

export const TableWrapper = styled.div``;

export const TableHeadCell = styled.div<{
  isToday?: boolean;
}>`
  width: 80px;
  height: 44px;
  font-size: 1rem;
  padding: 8px 10px;
  text-align: center;
  vertical-align: middle;
  border-bottom: ${(props) =>
    !props.isToday ? ` 2px solid ${props.theme.palette.beige}` : ''};
  &:nth-child(odd) {
    background: #fff;
  }
  background: ${(props) =>
    props.isToday ? `${props.theme.palette.blue}` : ''};
  color: ${(props) => (props.isToday ? `${props.theme.palette.white}` : '')};

  > div {
    display: flex;
    align-items: baseline;
    justify-content: center;
  }

  span {
    font-size: 12px;
  }
`;
export const TableCell = styled.div<{
  marginTop?: boolean;
  isToday?: boolean;
}>`
  width: 80px;
  height: 44px;
  font-size: 1rem;
  padding: 8px 10px;
  padding-top: ${(props) => (props.marginTop ? '10px' : '8px')};
  text-align: center;
  vertical-align: middle;
  border-inline: ${(props) =>
    props.isToday ? `2px solid ${props.theme.palette.blue}` : ''};
  border-bottom: ${(props) =>
    props.isToday
      ? props.marginTop
        ? `2px solid ${props.theme.palette.blue}`
        : ''
      : `2px solid ${props.theme.palette.beige}`};

  &:nth-child(odd) {
    background: #fff;
  }
  > div {
    display: flex;
    align-items: center;
    justify-content: center;
  }

  > span {
    font-size: 12px;
  }
`;

export const LeftTableEmptyCell = styled.div`
  width: 200px;
  height: 44px;
`;

export const TableRow = styled.div`
  display: inline-flex;
`;

export const LeftTableHeadCell = styled.div<{
  bg: string;
  color?: string;
  marginTop?: boolean;
}>`
  width: 200px;
  height: 42px;
  font-size: 0.75rem;
  color: ${(props) =>
    props.color ? props.color : props.theme.palette.baseBlack};
  font-weight: bold;
  text-align: left;
  margin-left: auto;
  display: flex;
  flex-direction: column;
  justify-content: center;

  > span {
    font-weight: ${(props) => (props.color === '#0A68F4' ? 'bold' : '400')};
    font-size: 12px;
    margin-left: 8px;
    margin-bottom: 2px;
  }
  margin-block: ${(props) => (props.marginTop ? '' : `1px`)};

  background: ${(props) =>
    props.bg ? props.bg : props.theme.palette.baseBlack};
`;
