import { useEffect, useState } from 'react';
import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import * as S from './ProductProductionResultContents.styles';
import scanSound from '@assets/sounds/sound.mp3';
import Overlay from '@components/elements/backdrops/Overlay/Overlay';
import ProductProductionResultMainTable from '@components/modules/pc/product_production_result/ProductProductionResultMainTable/ProductProductionResultMainTable';
import ProductProductionResultEditModal from '@components/modules/pc/product_production_result/ProductProductionResultEditModal/ProductProductionResultEditModal';
import BarcodeReader from '@components/modules/pc/BarcodeScanner/BarcodeReader';
import ErrorMsgPopUp from '@components/modules/common/ErrorMsgPopUp/ErrorMsgPopUp';
import { factoryLineColors } from '@lib/pc/common/type';
import {
  Case,
  ProductsProcess,
  Todo,
  ScannedProductInfo,
} from '@lib/common/type';
import useCreateProductProductionResultMutation from '@lib/pc/product_production_result/useCreateProductProductionResultMutation';
import useUpdateProductProductionResultMutation from '@lib/pc/product_production_result/useUpdateProductProductionResultMutation';
import useDeleteProductProductionResultMutation from '@lib/pc/product_production_result/useDeleteProductProductionResultMutation';
import { defaultEditData } from '@lib/pc/product_production_result/type';

type Props = {
  productionResults: Todo;
  selectedFactoryId: number;
  setSelectedFactoryId: (selectedFactoryId: number) => void;
  selectedFactoryData: Todo;
  setSelectedFactoryData: (selectedFactoryData: Todo) => void;
  selectedLineId: number;
  setSelectedLineId: (selectedLineId: Todo) => void;
  selectedLineIndex: number;
  setSelectedLine: (selectedLineIndex: number) => void;
  date: Date;
  products: Todo;
  productsProcesses: ProductsProcess[] | undefined;
  cases: Case[] | undefined;
  setPopUp: (popUp: boolean) => void;
  setEditProductName: (editProductName: string) => void;
  refetch: () => void;
  setMessageKind: (messageKind: string) => void;
  casesPerCart: string;
  setCasesPerCart: (casesPerCart: string) => void;
  registeredProductIds: number[];
  setRegisteredProductIds: (registeredProductIds: number[]) => void;
};

export type InputParams = {
  time: string;
};

const defaultScanProduct = {
  processId: null,
  productId: null,
  productName: '',
};

const ProductProductionResultContents = ({
  productionResults,
  selectedFactoryId,
  setSelectedFactoryId,
  selectedFactoryData,
  setSelectedFactoryData,
  selectedLineId,
  setSelectedLineId,
  selectedLineIndex,
  setSelectedLine,
  date,
  products,
  productsProcesses,
  cases,
  setPopUp,
  setEditProductName,
  refetch,
  setMessageKind,
  casesPerCart,
  setCasesPerCart,
  registeredProductIds,
  setRegisteredProductIds,
}: Props) => {
  const [editData, setEditData] = useState<Todo>(null);
  const [useDuplicateFunc, setUseDuplicateFunc] = useState(false);
  const [scanning, setScanning] = useState(true);
  const [results, setResults] = useState<Todo>([]);
  const [inputText] = useState('');
  const [audio] = useState(new Audio(scanSound));
  const [errMsg, setErrMsg] = useState('');
  const [showEditModal, setShowEditModal] = useState(false);
  const [useScanning, setUseScanning] = useState(false);
  const [scannedProduct, setScannedProduct] =
    useState<ScannedProductInfo>(defaultScanProduct);
  const valuesOfFactoryLineColors = Object.values(factoryLineColors);

  useEffect(() => {
    setLatestFactoryData();
  }, [productionResults?.productionResults]);

  useEffect(() => {
    setSelectedFactoryData(selectedFactoryData);
  }, [selectedFactoryData]);

  useEffect(() => {
    const timer = setInterval(() => {
      const camera = localStorage.getItem('ProductProductionResultCamera');
      if (camera) {
        refetch();
      }
    }, 1000);
    return () => {
      clearInterval(timer);
    };
  }, []);

  useEffect(() => {
    if (results.length !== 0) {
      const getData = products.products.filter((pro: Todo) => {
        const isMatchingProduct =
          (pro.janCode === results[0].codeResult.code ||
            pro.productCode === results[0].codeResult.code) &&
          (pro.factoryLineId === selectedLineId ||
            pro.factoryLineIds.includes(selectedLineId));
        return isMatchingProduct;
      });
      const extractedData = getData.map((products: Todo) => {
        const matchingProductProcess = productsProcesses?.find(
          (productProcess: ProductsProcess) =>
            productProcess.productId === products.id
        );
        return {
          id: matchingProductProcess?.id,
          productName: matchingProductProcess?.name,
          productId: matchingProductProcess?.productId,
        };
      });
      const firstMatchingProduct = extractedData[0] || {};
      const scannedProductInfo = {
        processId: firstMatchingProduct.id || '',
        productId: firstMatchingProduct.productId || '',
        productName: firstMatchingProduct.productName || '',
      };
      setScannedProduct(scannedProductInfo);

      if (!!getData && getData.length > 0) {
        setEditData({
          ...defaultEditData,
          time: null,
          lineName: selectedFactoryData.lines[selectedLineIndex].name,
          lineIndex: selectedLineIndex,
        });
        setPopUp(false);
        setUseDuplicateFunc(false);
        setShowEditModal(true);
        setUseScanning(true);
      } else {
        setErrMsg('該当の商品が見つかりませんでした。');
        setShowEditModal(false);
        setEditData(false);
      }
    }
  }, [results]);

  const handleDelete = () => {
    deleteProductProductionResultMutation.mutate();
    setEditData(null);
  };

  const setLatestFactoryData: Todo = () => {
    if (selectedFactoryId === undefined) {
      setSelectedFactoryId(productionResults?.productionResults[0]?.factoryId);
      setSelectedFactoryData(productionResults?.productionResults[0]);
    } else {
      const selectedData = productionResults?.productionResults.filter(
        (pr: Todo) => pr.factoryId == selectedFactoryId
      );
      if (selectedData?.[0]) {
        setSelectedFactoryData(selectedData[0]);
      }
    }
  };

  // 最新データの取得
  const getLatestWrappingData = () => {
    refetch();
    setLatestFactoryData();
  };

  const handleDeleteSuccess = () => {
    setPopUp(true);
    setMessageKind('delete');
    getLatestWrappingData();
    setUseDuplicateFunc(false);
    setScanning(true);
    setResults([]);
    setScannedProduct(defaultScanProduct);
  };

  const handleCreateSuccess = () => {
    setPopUp(true);
    setMessageKind('create');
    getLatestWrappingData();
    setEditData(null);
    setUseDuplicateFunc(false);
  };

  const handleDuplicateSuccess = () => {
    setPopUp(true);
    setMessageKind('duplicate');
    getLatestWrappingData();
    setEditData(null);
    setUseDuplicateFunc(false);
  };

  const handleUpdateSuccess = () => {
    setPopUp(true);
    setMessageKind('update');
    getLatestWrappingData();
    setEditData(null);
    setUseDuplicateFunc(false);
  };

  const handleClose = () => {
    setScanning(true);
    setScannedProduct(defaultScanProduct);
    setEditData(null);
    setUseDuplicateFunc(false);
    setResults([]);
  };

  // ここで、newEditData.order(順)が空だったらcreateする。
  const handleEditSubmit = (newEditData: Todo) => {
    if (useDuplicateFunc) {
      duplicateProductProductionResultMutation.mutate(newEditData);
      setScanning(true);
      setResults([]);
      setScannedProduct(defaultScanProduct);
    } else if (newEditData.detailId === null) {
      createProductProductionResultMutation.mutate(newEditData);
      setScanning(true);
      setResults([]);
      setScannedProduct(defaultScanProduct);
    } else {
      updateProductProductionResultMutation.mutate(newEditData);
      setScanning(true);
      setResults([]);
      setScannedProduct(defaultScanProduct);
    }
  };

  // 完成品生産実績データ複製処理
  const duplicateProductProductionResultMutation =
    useCreateProductProductionResultMutation(
      date,
      selectedLineId,
      handleDuplicateSuccess
    );

  // 完成品生産実績データ作成処理
  const createProductProductionResultMutation =
    useCreateProductProductionResultMutation(
      date,
      selectedLineId,
      handleCreateSuccess
    );

  // 完成品生産実績データ更新処理
  const updateProductProductionResultMutation =
    useUpdateProductProductionResultMutation(
      editData?.detailId,
      date,
      selectedLineId,
      handleUpdateSuccess
    );

  // 完成品生産実績削除
  const deleteProductProductionResultMutation =
    useDeleteProductProductionResultMutation(
      editData?.detailId,
      handleDeleteSuccess
    );

  const onclickLineTab = (i: number, id: number) => {
    setPopUp(false);
    setSelectedLine(i);
    setSelectedLineId(id);
    const selectedLine = [selectedFactoryId, id];
    localStorage.setItem(
      'product_production_selected_line',
      selectedLine?.join(',')
    );
  };

  return (
    <div>
      <S.TabWrapper>
        {selectedFactoryData &&
          selectedFactoryData.lines.map((d: Todo, i: number) => (
            <S.TabArea key={d.name + d.id}>
              <S.Tab
                key={d.name + d.id + selectedLineIndex}
                tabCount={selectedFactoryData.lines.length}
                active={i == selectedLineIndex}
                onClick={() => {
                  onclickLineTab(i, d.id);
                }}
                bgColor={valuesOfFactoryLineColors[i % 10]}
              >
                {d.name}
              </S.Tab>
            </S.TabArea>
          ))}
      </S.TabWrapper>
      <DndProvider backend={HTML5Backend}>
        <ProductProductionResultMainTable
          data={selectedFactoryData}
          setEditData={setEditData}
          selectedLineIndex={selectedLineIndex}
          setPopUp={setPopUp}
          setUseDuplicateFunc={setUseDuplicateFunc}
          setScanning={setScanning}
          setRegisteredProductIds={setRegisteredProductIds}
        />
      </DndProvider>
      {editData && (
        <>
          <ProductProductionResultEditModal
            scannedProduct={scannedProduct}
            data={editData}
            date={date}
            products={products}
            productsProcesses={productsProcesses}
            cases={cases}
            handleClose={() => handleClose()}
            handleDelete={handleDelete}
            handleSubmit={handleEditSubmit}
            setEditProductName={setEditProductName}
            casesPerCart={casesPerCart}
            setCasesPerCart={setCasesPerCart}
            selectedLineId={selectedLineId}
            useDuplicateFunc={useDuplicateFunc}
            setUseDuplicateFunc={setUseDuplicateFunc}
            useScanning={useScanning}
            registeredProductIds={registeredProductIds}
          />
          <Overlay dark />
        </>
      )}
      {scanning && (
        <BarcodeReader
          onDetected={(result: Todo) => {
            setScanning(false);
            audio.play();
            const newResult = {
              codeResult: { code: result.codeResult.code },
              inputText,
            };
            setResults([...results, newResult]);
          }}
        />
      )}
      {!showEditModal && (
        <ErrorMsgPopUp
          fromPc={true}
          errMsg={errMsg}
          handleClose={() => {
            setResults([]);
            setScanning(true);
            setErrMsg('');
          }}
        />
      )}
    </div>
  );
};

export default ProductProductionResultContents;
