import styled from 'styled-components';

export const Wrapper = styled.div`
  width: 100%;
`;

export const ProductKind = styled.span`
  display: block;
  padding: 5px 10px 7px;
  width: 80px;
  height: 32px;
  background: #2ba9d1;
  color: #fff;
  font-size: 19px;
  border-radius: 2px;
`;

export const SemiProductKind = styled.span`
  display: block;
  padding: 5px 10px 7px;
  width: 80px;
  height: 32px;
  background: #fff;
  color: #2ba9d1;
  font-size: 19px;
  margin-right: 16px;
  border: 1px solid #2ba9d1;
  border-radius: 2px;
`;

export const ContentsWrapper = styled.div`
  margin-top: 24px;
  max-height: 400px;
  overflow-y: scroll;
`;

export const OneLine = styled.div`
  border-bottom: solid 1px #cdcdcd;
  margin-bottom: 16px;
`;

export const Code = styled.div`
  color: rgba(51, 51, 51, 0.5);
  font-size: 14px;
  margin: 3px;
  height: 14px;
`;

export const NameAndUsageAmount = styled.div`
  font-size: 16px;
  display: flex;
  justify-content: space-between;
  margin: 3px 3px 10px 3px;
`;
