import { useState } from 'react';
import { Heading } from '@components/elements/typographies/Heading';
import { WithHeader } from '@components/templates/WithHeader';
import SearchMenu from '@components/modules/common/SearchMenu/SearchMenu';
import Filter from '@assets/icons/filter_list';
import { ReactComponent as FilteredIcon } from '@assets/icons/filtered.svg';
import * as S from './AcceptingOrders.styles';
import { acceptingOrders, details } from './dummy';
import Overlay from '@components/elements/backdrops/Overlay/Overlay';
import PdfView from '@components/modules/pc/accepting_orders/PdfContent/PdfContent';
import SearchConditionListModal, {
  STATUS_CONDITIONS,
  DEST_CONDITIONS,
} from '@components/modules/pc/accepting_orders/SearchConditionListModal/SearchConditionListModal';
import ReactSelect from 'react-select';
import { useHistory } from 'react-router-dom';
import { addComma } from '@lib/pc/stock_status/functions';

const DATE_TYPES = ['絞込み条件の選択', '作成日', '発注日'] as const;
const SEND_BY_LABEL_MAP = {
  fax: <S.KindLabel>FAX</S.KindLabel>,
  mail: <S.KindLabelFill>メール</S.KindLabelFill>,
} as const;

export const AcceptingOrdersRoot = () => {
  const history = useHistory();
  const [searchMenuActive, setSearchMenuActive] = useState(false);
  const [openSendMailModal, setOpenSendMailModal] = useState(false);
  const [selectedDateTypeId, setSelectedDateTypeId] = useState('0');
  const [dateRange, setDateRange] = useState<{
    startDate: moment.Moment | null;
    endDate: moment.Moment | null;
  }>({
    startDate: null,
    endDate: null,
  });
  const targetName = DATE_TYPES[Number(selectedDateTypeId)];
  const [statusConditions, setStatusConditions] = useState<number[]>([]);
  const [destConditions, setDestConditions] = useState<number[]>([]);
  const [searchConditionOpen, setSearchConditionOpen] = useState(false);
  const isSearchConditionBlank =
    statusConditions.length === 0 && destConditions.length === 0;
  const statusConditionNames = statusConditions.map(
    (id) => STATUS_CONDITIONS.find((status) => status.id == id)?.name || ''
  );
  const destConditionNames = destConditions.map(
    (id) => DEST_CONDITIONS.find((dest) => dest.id == id)?.name || ''
  );

  return (
    <WithHeader>
      <S.Wrapper>
        <div>
          <S.HeadContainer>
            <S.PageTitle>
              <S.PageTitleText>
                <Heading text="受注情報" fs="32" fw="lg" />
                <Heading text="を確認" fs="24" fw="lg" />
              </S.PageTitleText>
            </S.PageTitle>

            <S.HeaderRightContainer>
              <S.ButtonWrapper>
                <S.ButtonContainer
                  onClick={() => history.push('/pc/purchase_orders/new')}
                  padding="10px 10px 10px 0"
                  width="160px"
                >
                  ＋ 新規登録
                </S.ButtonContainer>
              </S.ButtonWrapper>
              <S.DateWrapper
                onClick={() => setSearchMenuActive(!searchMenuActive)}
              >
                <S.DateFilter>
                  <span className="filter-product">
                    {dateRange.startDate &&
                    dateRange.endDate &&
                    selectedDateTypeId !== '0' &&
                    dateRange.startDate === dateRange.endDate ? (
                      <>
                        <FilteredIcon />
                        <div className="filter_text">
                          {targetName}
                          <br />
                          {`${dateRange.startDate.format(
                            'YYYY/MM/DD'
                          )}のみ表示`}
                        </div>
                      </>
                    ) : dateRange.startDate &&
                      dateRange.endDate &&
                      selectedDateTypeId !== '0' ? (
                      <>
                        <FilteredIcon />
                        <div className="filter_text">
                          {targetName}
                          <br />
                          {`${dateRange.startDate.format(
                            'YYYY/MM/DD'
                          )} → ${dateRange.endDate.format('YYYY/MM/DD')}`}
                        </div>
                      </>
                    ) : (
                      <>
                        <S.SearchFilterIconContainer>
                          <Filter isBlue={false} />
                        </S.SearchFilterIconContainer>
                        <div className="filter_text_false">
                          出荷予定日または
                          <br />
                          賞味期限日の絞込み
                        </div>
                      </>
                    )}
                  </span>
                </S.DateFilter>
              </S.DateWrapper>
              <S.DateWrapper>
                <S.DateFilter
                  invalid={false}
                  onClick={() => setSearchConditionOpen(true)}
                >
                  {isSearchConditionBlank ? (
                    <span className="filter-product">
                      <>
                        <S.SearchFilterIconContainer>
                          <Filter />
                        </S.SearchFilterIconContainer>
                        <S.DetailConditionText>詳細条件</S.DetailConditionText>
                      </>
                    </span>
                  ) : (
                    <>
                      <S.FilterIconWrapper>
                        <Filter />
                      </S.FilterIconWrapper>
                      <S.SearchConditionNameList>
                        {statusConditionNames
                          .concat(destConditionNames)
                          .map((name) => (
                            <S.SearchConditionLabel>
                              {name}
                            </S.SearchConditionLabel>
                          ))}
                      </S.SearchConditionNameList>
                    </>
                  )}
                </S.DateFilter>
              </S.DateWrapper>
              {searchConditionOpen && (
                <SearchConditionListModal
                  statusConditions={statusConditions}
                  setStatusConditions={setStatusConditions}
                  destConditions={destConditions}
                  setDestConditions={setDestConditions}
                  handleClose={() => setSearchConditionOpen(false)}
                />
              )}
            </S.HeaderRightContainer>

            {searchMenuActive && (
              <SearchMenu
                headerTitleText={'作成日または発注日の絞り込み'}
                selectedDateTypeId={selectedDateTypeId}
                setSearchMenuActive={setSearchMenuActive}
                setSelectedDateTypeId={setSelectedDateTypeId}
                setDateRange={setDateRange}
                dateRange={dateRange}
                dateTypes={DATE_TYPES.map((name, id) => ({ id, name }))}
              />
            )}
          </S.HeadContainer>
          <S.MainContent>
            <S.PurchaseOrderList>
              {acceptingOrders.map((order, index) => (
                <S.PurchaseOrderItem key={index} index={index}>
                  <S.PurchaseOrderItemRow>
                    <div>
                      <input type="checkbox" />
                    </div>
                    <S.PurchaseOrderItemDate>
                      {order.createdAt}
                    </S.PurchaseOrderItemDate>
                  </S.PurchaseOrderItemRow>
                  <S.PurchaseOrderItemRow>
                    {SEND_BY_LABEL_MAP[order.sendBy]}
                    <S.PurchaseOrderItemName>
                      {order.name}
                    </S.PurchaseOrderItemName>
                  </S.PurchaseOrderItemRow>
                </S.PurchaseOrderItem>
              ))}
            </S.PurchaseOrderList>
            <S.CenterColumn>
              <S.OperationPanel>
                <S.OperationPanelUpper>
                  <S.OperationPanelDestName>
                    test株式会社
                  </S.OperationPanelDestName>
                  <S.OperationPanelStatusChange>
                    <ReactSelect
                      options={[
                        { value: 1, label: '未送信' },
                        { value: 2, label: '送信済み' },
                        { value: 3, label: '納品済み' },
                      ]}
                      isSearchable={false}
                      components={{
                        IndicatorSeparator: () => null,
                      }}
                    />
                  </S.OperationPanelStatusChange>
                </S.OperationPanelUpper>
                <S.ButtonArea>
                  <S.ButtonContainer
                    buttonType="edit"
                    onClick={() =>
                      history.push(`/pc/accepting_orders/9999/edit`)
                    }
                  >
                    編集
                  </S.ButtonContainer>
                  <S.ButtonContainer onClick={() => setOpenSendMailModal(true)}>
                    送信
                  </S.ButtonContainer>
                  {openSendMailModal && (
                    <>
                      <S.ModalWrapper>
                        <S.ModalHeadWrapper>
                          <Heading text="メール送信" fs="24" fw="lg" />
                        </S.ModalHeadWrapper>
                        <S.ModalFormLabel>件名</S.ModalFormLabel>
                        <S.Input
                          type="text"
                          name="name"
                          id="name"
                          autoComplete="new-password"
                          align="left"
                          padding="0 10px"
                        />
                        <S.ModalFormLabel>TO</S.ModalFormLabel>
                        <S.Input
                          type="text"
                          name="name"
                          id="name"
                          autoComplete="new-password"
                          align="left"
                          padding="0 10px"
                        />
                        <S.ModalFormLabel>CC</S.ModalFormLabel>
                        <S.Input
                          type="text"
                          name="name"
                          id="name"
                          autoComplete="new-password"
                          align="left"
                          padding="0 10px"
                        />
                        <S.ModalFormLabel>本文</S.ModalFormLabel>
                        <S.ModalFormTextArea />
                        <S.ModalButtonWrapper>
                          <S.ButtonContainer
                            onClick={() => setOpenSendMailModal(false)}
                          >
                            送信
                          </S.ButtonContainer>
                        </S.ModalButtonWrapper>
                      </S.ModalWrapper>
                      <Overlay dark />
                    </>
                  )}
                  <S.ButtonContainer buttonType="delete">
                    削除
                  </S.ButtonContainer>
                </S.ButtonArea>
              </S.OperationPanel>
              <PdfView />
            </S.CenterColumn>
            <S.DetailList>
              <S.ProductListHeader>
                <S.ProductDeliveryDay>納品日</S.ProductDeliveryDay>
                <S.ProductName>品目</S.ProductName>
                <S.ProductQuantity>数量</S.ProductQuantity>
              </S.ProductListHeader>
              <S.ProductListBody>
                {details.map((detail, index) => (
                  <S.ProductListRow index={index} key={index}>
                    <S.ProductDeliveryDay>
                      {detail.productDeliveryDay}
                    </S.ProductDeliveryDay>
                    <S.ProductName>{detail.productName}</S.ProductName>
                    <S.ProductQuantity>
                      {addComma(detail.unitAmount)}
                    </S.ProductQuantity>
                  </S.ProductListRow>
                ))}
              </S.ProductListBody>
            </S.DetailList>
          </S.MainContent>
        </div>
      </S.Wrapper>
    </WithHeader>
  );
};
