import { useMutation } from 'react-query';
import humps from 'humps';
import { axiosInstance } from '@lib/pc/common/api/axiosConfig';
import { Todo } from '@lib/common/type';

const useUpdateMaterialOrderMutation = (
  materialId: string | number,
  handleUpdateSuccess: () => void,
  setErrMsg: (errMsg: string) => void
) => {
  return useMutation(
    async (params: Todo) => {
      const details = params.orderDetails.map((item: Todo) => ({
        id: item.id,
        materialId: item.materialId,
        unit_price: item.unitPrice === '' ? null : item.unitPrice,
        amount: item.amount === '' ? 0 : item.amount,
        tax_rate: item.taxRate,
        _destroy: item.destroy,
      }));
      return await axiosInstance().patch(
        `/api/v1/material_orders/${materialId}`,
        humps.decamelizeKeys({
          materialOrder: {
            supplier_id: params.supplierId,
            honorific: params.honorific,
            supplier_department_id: params.supplierDepartmentId,
            purchase_order_number: params.purchaseOrderNumber,
            confirmation_date: params.confirmationDate,
            subject: params.subject,
            requested_delivery_date: params.requestedDeliveryDate,
            payment_term: params.paymentTerm,
            delivery_destination: params.deliveryDestination,
            supplier_description: params.supplierDescription,
            comment: params.comment,
            details_attributes: details,
          },
        })
      );
    },
    {
      onSuccess: () => {
        handleUpdateSuccess?.();
      },
      onError: (error: Todo) => {
        setErrMsg(error.response.data.message || 'エラーが発生しました');
      },
    }
  );
};

export default useUpdateMaterialOrderMutation;
