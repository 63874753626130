import { axiosInstance } from '@lib/pc/common/api/axiosConfig';
import { useQuery } from 'react-query';
import humps from 'humps';
import type { Tag } from '@lib/common/type';

const useTags = (productKind: number | string) => {
  return useQuery(['tags', productKind], async () => {
    const { data } = await axiosInstance().get(
      `/api/v1/tags?product_kind=${productKind}`
    );
    return humps.camelizeKeys(data.tags) as Tag[];
  });
};

export default useTags;
