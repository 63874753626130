import { axiosInstance } from '@lib/pc/common/api/axiosConfig';
import { useQuery } from 'react-query';
import humps from 'humps';
import type { Product } from '@lib/common/type';
import {
  SemiProduct,
  Material,
} from '@lib/pc/settings/product_master_setting/type';
const useProcessRelations = (processId: number) => {
  const { data } = useQuery(['process_relations', processId], async () => {
    const data = await axiosInstance().get(
      `/api/v1/settings/products_processes/${processId}`
    );
    return data;
  });
  const products = humps.camelizeKeys(
    data?.data.products
  ) as unknown as Product[];

  const semiProducts = humps.camelizeKeys(
    data?.data.semi_products
  ) as unknown as SemiProduct[];

  const rawMaterials = humps.camelizeKeys(
    data?.data.raw_materials
  ) as unknown as Material[];

  const supplyMaterials = humps.camelizeKeys(
    data?.data.supply_materials
  ) as unknown as Material[];

  return { products, semiProducts, rawMaterials, supplyMaterials };
};

export default useProcessRelations;
