import styled from 'styled-components';

export const Title = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: bold;
  font-size: 1.125rem;
  padding: 1rem;
`;

export const BackButton = styled.button`
  padding-left: 0.5rem;
  padding-right: 0.5rem;
  background: none;
`;

export const TitleText = styled.p`
  display: flex;
  justify-content: center;
  padding-right: 0.7rem;
  /* padding-left: 1rem; */
  width: 90%;
`;
