import { useEffect, useState } from 'react';
import * as S from './ProductProductionResultHeaderControl.styles';
import { ReactComponent as CopyIcon } from '@assets/icons/copy.svg';
import { Heading } from '@components/elements/typographies/Heading';
import { Button } from '@components/elements/buttons/Button';
import DateSelector from '@components/elements/inputs/DateSelector/DateSelector';
import type { factory } from '@lib/pc/stacker/useStackers';
import { Todo } from '@lib/common/type';

type Props = {
  productionResults: Todo;
  factories: factory[];
  selectedFactoryId: Todo;
  setSelectedFactoryId: (id: string) => void;
  setSelectedFactoryData: (selectedFactoryData: Todo) => void;
  setSelectedLineId: (selectedLineId: number) => void;
  setSelectedLine: (selectedLineIndex: number) => void;
  selectedDate: Date;
  getBeforeDate: (selectedDate: Date) => void;
  getNextDate: (selectedDate: Date) => void;
  setPopUp: (popUp: boolean) => void;
  handleClickToday: () => void;
  handleClickCopy: () => void;
  setDate: (date: Date) => void;
};

const ProductProductionResultHeaderControl = ({
  productionResults,
  factories,
  selectedFactoryId,
  setSelectedFactoryId,
  setSelectedFactoryData,
  setSelectedLineId,
  setSelectedLine,
  selectedDate,
  getBeforeDate,
  getNextDate,
  setPopUp,
  handleClickToday,
  handleClickCopy,
  setDate,
}: Props) => {
  // localStorageから取得したlineのids
  const [lineIds, setLineIds] = useState<number[]>([]);

  useEffect(() => {
    setPopUp(false);
    // 選択工場変更時
    if (selectedFactoryId === undefined) {
      setSelectedFactoryData(productionResults?.productionResults[0]);
      setSelectedLineId(productionResults?.productionResults[0]?.lines[0]?.id);
    } else {
      const selectedData = productionResults?.productionResults.filter(
        (pr: { factoryId: number }) => pr.factoryId == selectedFactoryId
      );
      if (selectedData?.[0]) {
        setSelectedFactoryData(selectedData[0]);
        setSelectedLineId(selectedData[0]?.lines[0]?.id);
        const selectedFactory = [
          selectedFactoryId,
          selectedData[0]?.lines[0]?.id,
        ];
        localStorage.setItem(
          'product_production_selected_line',
          selectedFactory?.join(',')
        );
      }
    }
  }, [selectedFactoryId]);

  // 選択済みラインの復元
  useEffect(() => {
    const selectedFactoryAndLine = localStorage
      .getItem('product_production_selected_line')
      ?.split(',');
    if (selectedFactoryAndLine) {
      setSelectedFactoryId(selectedFactoryAndLine && selectedFactoryAndLine[0]);
      // lineのid復元
      setSelectedLineId(
        selectedFactoryAndLine && Number(selectedFactoryAndLine[1])
      );
    }
    const factories = productionResults?.factories;
    const factoryIds = factories?.map((factory: Todo) => factory['id']);
    if (selectedFactoryId) {
      // 工場が複数の場合、何番目の工場か設定
      const selectedId = factoryIds?.indexOf(Number(selectedFactoryId));
      const lines = productionResults?.productionResults[selectedId]?.lines;
      setLineIds(lines?.map((item: Todo) => item['id']));
    } else {
      // 工場が１つの場合
      const lines = productionResults?.productionResults[0]?.lines;
      setLineIds(lines?.map((item: Todo) => item['id']));
    }
    // lineのindex復元
    setSelectedLine(
      selectedFactoryAndLine
        ? lineIds?.indexOf(Number(selectedFactoryAndLine[1]))
        : 0
    );
  }, [productionResults]);

  return (
    <S.Wrapper>
      <S.PageTitle>
        <S.ProductKind>完成品</S.ProductKind>
        <S.PageTitleText>
          <Heading text="生産実績" fs="32" fw="lg" />
          <Heading text="を入力" fs="24" fw="lg" />
        </S.PageTitleText>
        {factories && factories.length > 1 && (
          <S.Select
            value={selectedFactoryId}
            onChange={(e) => setSelectedFactoryId(e.target.value)}
            name="factories"
            id="factories"
          >
            {factories &&
              factories.map((f) => (
                <option key={f.factoryId + f.factoryName} value={f.factoryId}>
                  {f.factoryName}
                </option>
              ))}
          </S.Select>
        )}
      </S.PageTitle>
      <S.DateWrapper>
        <Button
          iconAfter={<CopyIcon />}
          width="120px"
          margin={'0 16px 0 0'}
          padding={'sm'}
          outlined={true}
          borderWidth={1}
          onClick={handleClickCopy}
        >
          過去情報
        </Button>
        <Button
          width="60px"
          margin={'0 12px 0 0'}
          outlined={true}
          borderWidth={1}
          onClick={handleClickToday}
        >
          今日
        </Button>
        <DateSelector
          date={selectedDate}
          getBeforeDate={getBeforeDate}
          getNextDate={getNextDate}
          setDate={setDate}
          capableSelectFutureDate
        />
      </S.DateWrapper>
    </S.Wrapper>
  );
};

export default ProductProductionResultHeaderControl;
