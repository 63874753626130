import moment from 'moment';
import { useEffect, useRef } from 'react';
import { Link } from 'react-router-dom';
import * as S from './TableBody.styles';
import type { Stocks } from '@lib/common/type';
import type { Product } from '@lib/common/type';

type Props = {
  product: Product;
  stocks: Stocks[];
};

const today = moment();
const dateList = [...Array(7)].map((_, i) => today.clone().add(i - 6, 'd'));
const TableBody = ({ product, stocks }: Props) => {
  const ref = useRef<HTMLDivElement>(null);
  useEffect(() => {
    ref.current && (ref.current.scrollLeft = 99999999);
  }, []);

  return (
    <S.Wrapper ref={ref}>
      {dateList.map((date, i) => {
        const isToday = i === dateList.length - 1;
        const even = i % 2 === 0;
        const stock = stocks.find((stock) => date.isSame(stock.date, 'day'));
        if (!product || !stock) return null;
        const withoutComment = stock.comment == null || stock.comment == '';
        const packagedAmounts = stock.amountDetail
          ? [
              ...stock.amountDetail
                .map((amount: { total: number | string }) => amount.total)
                .reverse(),
              stock.amount,
            ]
          : [...Array(8).fill('-')];
        return (
          <Link
            key={i}
            to={`/mobile/products/${product.id}/stocks/${
              product.productKind
            }/${date.format('YYYY-MM-DD')}/edit`}
          >
            <S.Column key={i} isToday={isToday} even={even}>
              {isToday ? (
                <S.TodayDateCell>{date.format('M/DD(ddd)')}</S.TodayDateCell>
              ) : (
                <S.DateCell>{date.format('M/DD(ddd)')}</S.DateCell>
              )}
              <S.ValueCellWrapper withComment={withoutComment ? false : true}>
                {packagedAmounts.map((amount, i) =>
                  even ? (
                    <S.EvenCell key={i}>
                      {amount === 0 ? '' : amount}
                    </S.EvenCell>
                  ) : (
                    <S.ValueCell key={i}>
                      {amount === 0 ? '' : amount}
                    </S.ValueCell>
                  )
                )}
              </S.ValueCellWrapper>
            </S.Column>
          </Link>
        );
      })}
    </S.Wrapper>
  );
};
export default TableBody;
