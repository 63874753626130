import { useMutation } from 'react-query';
import humps from 'humps';
import { AxiosError } from 'axios';
import { axiosInstance } from '@lib/pc/common/api/axiosConfig';
import { ErrorResponse } from '@lib/common/type';
import { CustomerEdit } from '@lib/pc/settings/customer_edit/type';

const useUpdateCustomerMutation = (
  customerId: number | string,
  handleSuccess: () => void,
  setErrMsg: (errMsg: string) => void
) => {
  return useMutation(
    async (params: CustomerEdit) => {
      return await axiosInstance().patch(
        `/api/v1/settings/customer/${customerId}`,
        humps.decamelizeKeys({
          customers: {
            code: params.code,
            name: params.name,
            short_name: params.shortName,
            name_kana: params.nameKana,
          },
        })
      );
    },
    {
      onSuccess: () => {
        handleSuccess?.();
      },
      onError: (error: AxiosError<ErrorResponse>) => {
        const message = error.response?.data.message || 'エラーが発生しました';
        setErrMsg(message);
      },
    }
  );
};

export default useUpdateCustomerMutation;
