import styled from 'styled-components';

export const Wrapper = styled.div<{
  display?: boolean;
  width?: string;
}>`
  position: relative;
  width: ${(props) => (props.width ? props.width : '')};
  .case-label {
    font-size: 15px;
    align-items: center;
    display: flex;
    margin-right: 0.75rem;
  }
`;

export const Label = styled.label`
  font-weight: 700;
`;

export const SelectWrapper = styled.div`
  /* padding-top: 8px; */
`;
