import { useEffect, useState } from 'react';
import { Heading } from '@components/elements/typographies/Heading';
import { format } from 'date-fns';
import { ReactComponent as FilterIcon } from '@assets/icons/filter_list.svg';
import { ReactComponent as CheckedIcon } from '@assets/icons/mobile/check.svg';
import { ReactComponent as CheckIcon } from '@assets/icons/mobile/check_circle_outline_24px.svg';
import * as S from './PickingDetailTable.styles';
import { InputWithIcon } from '@components/elements/inputs/InputWithIcon/InputWithIcon';
import TagMenu from '@components/modules/common/TagMenu/TagMenu';
import TagListModal from '@components/modules/common/TagListModal/TagListModal';
import { Todo, PRODUCT_KIND } from '@lib/common/type';
import { tagSearch } from '@lib/common/functions';

type Props = {
  data: Todo;
};

const PickingDetailTable = ({ data }: Props) => {
  const [searchWord, setSearchWord] = useState('');
  const [tagMenuActive, setTagMenuActive] = useState(false);
  const [tagListModalActive, setTagListModalActive] = useState(false);
  const [selectedTags, setSelectedTags] = useState<Todo>([]);
  const selectedTagIds = selectedTags.map((item: Todo) => item['id']);

  const branchData = data.productData[0].data.map((d: Todo, index: number) => ({
    id: index + 1,
    name: d.name,
    storeCode: d.storeCode,
  }));

  const productSum = data.productData.map(function (y: Todo) {
    return y.data
      .map((item: Todo) => item.amount)
      .reduce(function (a: Todo, b: Todo) {
        return a + b;
      });
  });

  const handleSearch = (e: Todo) => {
    setSearchWord(e.target.value);
  };

  useEffect(() => {
    setTagMenuActive(false);
  }, [selectedTagIds.length === 0]);

  return (
    <div>
      <S.ListWrapper>
        <S.ListSidebar>
          <S.ListLeftHeadSearchCell>
            <S.FlexColumn>
              <Heading tag="span" text="出荷日" fs="16" fw="lg" />
              <Heading
                tag="span"
                text={format(data.shippingDate, 'yyyy/MM/dd')}
                fs="24"
                fw="lg"
              />
            </S.FlexColumn>

            <S.ListLeftHeadCell
              searchCell={true}
              // invalid={rightSlideSidebarActive}
              onClick={() => setTagMenuActive(!tagMenuActive)}
            >
              <InputWithIcon
                placeholder="商品の絞り込み"
                icon={<FilterIcon />}
                onChange={() => undefined}
              />
            </S.ListLeftHeadCell>
          </S.ListLeftHeadSearchCell>
          {data.productData
            .filter(
              (p: Todo) =>
                p.name.includes(searchWord) ||
                p.productCode?.toString().includes(searchWord)
            )
            .filter((p: Todo) => tagSearch(p, selectedTagIds))
            .map((s: Todo, i: number) => {
              return (
                <S.ListLeftHeadCell
                  className="product-data"
                  key={s.id + i + Math.random()}
                  checked={s.checkState}
                >
                  <S.CheckArea checked={s.checkState}>
                    <S.CheckIconWrapper>
                      {s.checkState === true ? <CheckedIcon /> : <CheckIcon />}
                    </S.CheckIconWrapper>
                    <S.StatusName checked={s.checkState}>
                      {s.checkState === true ? (
                        <span>チェック済</span>
                      ) : (
                        <span>未チェック</span>
                      )}
                    </S.StatusName>
                  </S.CheckArea>
                  <div
                    key={s.id + Math.random()}
                    style={{
                      width: '100vw',
                      padding: '0.4rem 0',
                      gap: '0.3rem',
                      display: 'grid',
                    }}
                  >
                    <div>{s.name}</div>
                    <span>{s.productCode}</span>
                  </div>
                </S.ListLeftHeadCell>
              );
            })}
        </S.ListSidebar>
        <S.ListBody>
          {/* 合計・出荷先 */}
          <S.ListRow>
            <S.ListHeadCell>合計</S.ListHeadCell>
            {branchData.map((p: Todo, i: number) => {
              return (
                <S.ListHeadCell key={i + p.storeCode + Math.random()}>
                  <span>{p.storeCode}</span>
                  <div> {p.name}</div>
                </S.ListHeadCell>
              );
            })}
          </S.ListRow>
          {data.productData
            .filter(
              (p: Todo) =>
                p.name.includes(searchWord) ||
                p.productCode?.toString().includes(searchWord)
            )
            .filter((p: Todo) => tagSearch(p, selectedTagIds))
            .map((p: Todo, i: Todo) => {
              return (
                <S.ListRow key={p.productCode + i + Math.random()}>
                  {productSum[i] && <S.ListCell>{productSum[i]}</S.ListCell>}
                  {p.data.map((pickingData: Todo, idx: Todo) => {
                    return (
                      <S.ListCell key={pickingData.id + idx + Math.random()}>
                        {pickingData.amount}
                      </S.ListCell>
                    );
                  })}
                </S.ListRow>
              );
            })}
        </S.ListBody>
      </S.ListWrapper>
      {tagMenuActive && (
        <TagMenu
          searchParam={searchWord}
          selectedTags={selectedTags}
          setSelectedTags={(tags) => setSelectedTags(tags)}
          setOpenTagModal={() => setTagListModalActive(true)}
          handleChange={handleSearch}
          deleteSearchParam={() => setSearchWord('')}
        />
      )}
      {tagListModalActive && (
        <TagListModal
          selectedTags={selectedTags}
          setSelectedTags={(tags) => setSelectedTags(tags)}
          tagListModalActive={tagListModalActive}
          productKind={PRODUCT_KIND.PRODUCT}
          handleClose={() => setTagListModalActive(false)}
        />
      )}
    </div>
  );
};

export default PickingDetailTable;
