// ステータスのスタイル
export const STATUS_VALUE = [
  {
    key: '未着手',
    value: 0,
    // value: 'waiting_picking',
    backgroundColor: '#ffffff',
    btnBgColor: '#FFD130',
    fontColor: '#191712',
    borderColor: '#FFD130',
    fontSize: '16px',
    fontWeight: 700,
  },
  {
    key: 'ピッキング中',
    value: 1,
    // value: 'picking',
    backgroundColor: '#FEFDE8',
    btnBgColor: '#FFFCE5',
    fontColor: '#E89A25',
    borderColor: '',
    fontSize: '14px',
    fontWeight: 700,
  },
  {
    key: '出荷済み',
    value: 2,
    // value: 'waiting_shipping',
    backgroundColor: '#E9E9E9',
    btnBgColor: '#E0E0E0',
    fontColor: '#6B675C',
    borderColor: '',
    fontSize: '16px',
    fontWeight: 400,
  },
  {
    key: '振分待ち',
    value: 3,
    backgroundColor: '#ffffff',
    btnBgColor: '#2BA9D1',
    fontColor: '#ffffff',
    borderColor: '#2BA9D1',
    fontSize: '16px',
    fontWeight: 700,
  },
  {
    key: '振分中',
    value: 4,
    backgroundColor: '#ffffff',
    btnBgColor: '#ffffff',
    fontColor: '#2BA9D1',
    borderColor: '',
    fontSize: '16px',
    fontWeight: 700,
  },
  // {
  //   key: '出荷完了',
  //   value: 3,
  //   backgroundColor: '#C0C0C0',
  //   btnBgColor: '#6B675C',
  //   fontColor: '#ffffff',
  //   borderColor: '#6B675C',
  //   fontSize: '16px',
  //   fontWeight: 400,
  // },
];
