import React, { FC, memo, useEffect, useState } from 'react';
import moment from 'moment';
import * as S from './TableWithFixedSidebar.styles';
import Filter from '@assets/icons/filter_list';
import { ReactComponent as FilteredIcon } from '@assets/icons/filtered.svg';
import Overlay from '@components/elements/backdrops/Overlay/Overlay';
import TagMenu from '@components/modules/common/TagMenu/TagMenu';
import TagListModal from '@components/modules/common/TagListModal/TagListModal';
import DisplaySelectModal from '@components/modules/common/DisplaySelectModal/DisplaySelectModal';
import {
  isSameIndex,
  addComma,
  generateMonthlyDateArray,
  isIncludes,
} from '@lib/pc/production_schedule/functions';
import { CircularProgress } from '@material-ui/core';
import { tagSearch, replaceContent } from '@lib/common/functions';
import type {
  Todo,
  BasisForSchedules,
  MonthlyData,
  FunctionType,
} from '@lib/common/type';
import type { SelectedProduct } from '@lib/pc/production_schedule/type';
import { PRODUCT_KIND } from '@lib/common/type';

type Props = {
  selectedDate: Todo;
  data?: BasisForSchedules[];
  selected: SelectedProduct | boolean | null;
  selectedCell: string;
  selectIndex: number;
  setSelectIndex: (index: number) => void;
  handleSelectedProduct: (
    d: Todo,
    productName: string,
    indexId: string
  ) => void;
  handleOpenProductDetail: (
    id: number,
    name: string,
    safetyStockDays: string
  ) => void;
};

const TableWithFixedSidebar: FC<Props> = ({
  selectedDate,
  data,
  selected,
  selectedCell,
  selectIndex,
  setSelectIndex,
  handleSelectedProduct,
  handleOpenProductDetail,
}: Props) => {
  const [searchWord, setSearchWord] = useState('');
  const [tagMenuActive, setTagMenuActive] = useState(false);
  const [tagListModalActive, setTagListModalActive] = useState(false);
  const [displaySelectModalActive, setDisplaySelectModalActive] =
    useState(false);
  const [selectedTags, setSelectedTags] = useState<Todo>([]);
  const selectedTagIds = selectedTags.map((item: Todo) => item['id']);
  const functionTypeObj = [
    { id: 0, type: '生産計画' },
    { id: 1, type: '生産実績' },
    { id: 2, type: '需要予測' },
    { id: 3, type: '理想在庫' },
    { id: 4, type: '予定在庫' },
    { id: 5, type: '実在庫' },
  ];
  const [selectedTypes, setSelectedTypes] =
    useState<FunctionType[]>(functionTypeObj);
  const selectedTypeIds = selectedTypes.map((item: FunctionType) => item['id']);

  const ProductNameArr = data
    ? data.map((p: Todo) => ({
        id: p.id,
        productName: p.name,
        productNameAbbreviation: p.nameAbbreviation,
        productNameKana: p.nameKana,
        productCode: null,
        tagIds: p.tagIds,
        productSafetyStockDays: p.safetyStockDays,
      }))
    : [];

  const handleSearch = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSearchWord(e.target.value);
  };

  useEffect(() => {
    setTagMenuActive(false);
  }, [selectedTagIds.length === 0]);

  const MemorizedListCell = memo(
    ({
      index,
      p,
      paramsType,
      idx,
      productionSchedule,
      last,
      monthlyDateNum,
    }: Todo) => {
      const isToday = isSameIndex(idx, selectIndex, monthlyDateNum);
      return (
        <S.ListCellContainer
          last={last}
          productionSchedule={productionSchedule}
          onClick={() =>
            productionSchedule &&
            handleSelectedProduct(
              {
                productId: p.id,
                productName: p.name,
                productNameAbbreviation: p.productNameAbbreviation,
                productNameKana: p.productNameKana,
                tagIds: p.tagIds,
                selectedSchedule: p.productionSchedules[idx],
                actualAmount: p.productionSchedules[idx].actualAmount,
                estimatedAmount: p.productionSchedules[idx].estimatedAmount,
                productionDate: p.productionSchedules[idx].date,
              },
              p.name,
              `${p.productionSchedules[idx].productionSchedulesId}-${idx}-${index}`
            )
          }
          highLighten={isToday}
          invalid={selected ? true : false}
          withCheckEnabled={
            productionSchedule && p.productionSchedules[idx].checkEnabled
          }
          className={
            `${p.productionSchedules[idx].productionSchedulesId}-${idx}-${index}` ===
              selectedCell && productionSchedule
              ? 'select-cell'
              : ''
          }
        >
          {productionSchedule
            ? paramsType.actualAmount === 0
              ? 0
              : paramsType.actualAmount
              ? addComma(paramsType.actualAmount)
              : paramsType.estimatedAmount === 0
              ? 0
              : paramsType.estimatedAmount
              ? addComma(paramsType.estimatedAmount)
              : !productionSchedule
            : paramsType.amount === 0
            ? 0
            : paramsType.amount
            ? addComma(paramsType.amount)
            : ''}
        </S.ListCellContainer>
      );
    }
  );

  return (
    <>
      <div>
        <S.ListWrapper>
          <S.ListSidebar>
            <S.SearchArea>
              <S.ListLeftHeadCell
                onClick={() => setTagMenuActive(!tagMenuActive)}
                isBlue={searchWord !== '' || selectedTagIds.length > 0}
              >
                <span className="filter-product">
                  {searchWord !== '' || selectedTagIds.length > 0 ? (
                    <>
                      <FilteredIcon />
                      <div className="filter_text">商品絞込み中</div>
                    </>
                  ) : (
                    <>
                      <Filter />
                      <div className="filter_text">商品の絞込</div>
                    </>
                  )}
                </span>
              </S.ListLeftHeadCell>
              <S.DisplayButton
                onClick={() =>
                  setDisplaySelectModalActive(!displaySelectModalActive)
                }
                isBlue={selectedTypeIds.length !== 6}
              >
                <span className="filter-product">
                  {selectedTypeIds.length !== 6 ? (
                    <>
                      <FilteredIcon />
                      <div className="filter_text">選択中</div>
                    </>
                  ) : (
                    <>
                      <Filter />
                      <div className="filter_text">表示選択</div>
                    </>
                  )}
                </span>
              </S.DisplayButton>
            </S.SearchArea>

            {ProductNameArr.filter(
              (p: Todo) =>
                p.productName.includes(searchWord) ||
                p.productNameAbbreviation?.includes(searchWord) ||
                p.productNameKana?.includes(searchWord) ||
                p.productCode?.toString().includes(searchWord)
            )
              .filter((p: Todo) => tagSearch(p, selectedTagIds))
              .map(
                (
                  s: {
                    id: number;
                    productName: string;
                    productNameAbbreviation: string;
                    productNameKana: string;
                    productCode: null;
                    productSafetyStockDays: string;
                  },
                  i: number
                ) => {
                  return (
                    // 商品名、商品コード
                    <S.ListLeftHeadProductCell
                      className="list-left-product-cell"
                      key={i}
                      productIndex={i}
                    >
                      <S.ListTbody>
                        <S.ListLeftHeadNameAndCode
                          key={i}
                          productIndex={i}
                          // typeIndex={i}
                          onClick={() =>
                            handleOpenProductDetail(
                              s.id,
                              s.productNameAbbreviation
                                ? s.productNameAbbreviation
                                : s.productName,
                              !s.productSafetyStockDays
                                ? '3'
                                : String(s.productSafetyStockDays)
                            )
                          }
                        >
                          {/* 商品名 */}
                          <S.ListProductNameAndCode>
                            <S.ListProductName>
                              {replaceContent(
                                s.productNameAbbreviation
                                  ? s.productNameAbbreviation
                                  : s.productName,
                                12.5
                              )}
                            </S.ListProductName>
                            {/* 商品コード */}
                            {s.productCode}
                          </S.ListProductNameAndCode>
                        </S.ListLeftHeadNameAndCode>
                        <div className="type-name">
                          {functionTypeObj
                            .filter((value) =>
                              selectedTypeIds.includes(value.id)
                            )
                            .map((value, j) => {
                              // 表示タイプ
                              return (
                                <S.ListLeftHeadTypeName
                                  key={j}
                                  typeIndex={j}
                                  productIndex={i}
                                  notDisplay={
                                    !selectedTypeIds.includes(value.id)
                                      ? 'true'
                                      : ''
                                  }
                                >
                                  {value.type}
                                </S.ListLeftHeadTypeName>
                              );
                            })}
                        </div>
                      </S.ListTbody>
                    </S.ListLeftHeadProductCell>
                  );
                }
              )}
          </S.ListSidebar>
          <S.ListBody className={selected ? 'move-to-left' : ''}>
            <S.ListRow>
              {data &&
                generateMonthlyDateArray(
                  data[0].numberOfDaysParamsMonth,
                  selectedDate,
                  selectIndex
                ).map((p, i) => {
                  return (
                    // 月日、合計、前年同月
                    <S.ListHeadCell
                      key={i}
                      index={i}
                      numOfIndex={data[0].numberOfDaysParamsMonth}
                      highLighten={p.isToday}
                      dayOfWeek={moment(p.productionDate).format('ddd')}
                      onClick={() => setSelectIndex(i)}
                    >
                      {i === 0
                        ? moment(p.productionDate).format('M/D(ddd)')
                        : i === data[0].numberOfDaysParamsMonth + 1
                        ? '合計'
                        : i === data[0].numberOfDaysParamsMonth + 2
                        ? '前年同月'
                        : moment(p.productionDate).format('DD(ddd)')}
                    </S.ListHeadCell>
                  );
                })}
            </S.ListRow>
            {data ? (
              data
                .filter(
                  (p: Todo) =>
                    p.name.includes(searchWord) ||
                    p.nameAbbreviation?.includes(searchWord) ||
                    p.nameKana?.includes(searchWord) ||
                    p.productCode?.toString().includes(searchWord)
                )
                .filter((p: Todo) => tagSearch(p, selectedTagIds))
                .map((p: Todo, i: number) => {
                  return (
                    <React.Fragment key={i}>
                      <S.ListRow>
                        {p.productionSchedules.map(
                          (schedule: MonthlyData[], idx: number) => {
                            return (
                              <MemorizedListCell
                                index={i}
                                key={idx}
                                p={p}
                                paramsType={schedule}
                                idx={idx}
                                productionSchedule={true}
                                monthlyDateNum={p.numberOfDaysParamsMonth}
                              />
                            );
                          }
                        )}
                      </S.ListRow>
                      <S.ListRow
                        notDisplay={!selectedTypeIds.includes(1) ? 'true' : ''}
                      >
                        {selectedTypeIds.includes(1) &&
                          p.productProductionResults.map(
                            (
                              productProductionResult: MonthlyData[],
                              idx: number
                            ) => {
                              return (
                                <MemorizedListCell
                                  key={idx}
                                  p={p}
                                  paramsType={productProductionResult}
                                  idx={idx}
                                  last={selectedTypeIds.length === 2}
                                  monthlyDateNum={p.numberOfDaysParamsMonth}
                                />
                              );
                            }
                          )}
                      </S.ListRow>
                      <S.ListRow
                        notDisplay={!selectedTypeIds.includes(2) ? 'true' : ''}
                      >
                        {selectedTypeIds.includes(2) &&
                          p.demandForecasts.map(
                            (demandForecast: MonthlyData[], idx: number) => {
                              return (
                                <MemorizedListCell
                                  key={idx}
                                  p={p}
                                  paramsType={demandForecast}
                                  idx={idx}
                                  last={
                                    selectedTypeIds.length === 2 ||
                                    (selectedTypeIds.includes(1) &&
                                      selectedTypeIds.length == 3)
                                  }
                                  monthlyDateNum={p.numberOfDaysParamsMonth}
                                />
                              );
                            }
                          )}
                      </S.ListRow>
                      <S.ListRow
                        notDisplay={!selectedTypeIds.includes(3) ? 'true' : ''}
                      >
                        {selectedTypeIds.includes(3) &&
                          p.safetyStocks.map(
                            (safetyStock: MonthlyData[], idx: number) => {
                              return (
                                <MemorizedListCell
                                  key={idx}
                                  p={p}
                                  paramsType={safetyStock}
                                  idx={idx}
                                  last={
                                    selectedTypeIds.length === 2 ||
                                    !isIncludes([4, 5], selectedTypeIds)
                                  }
                                  monthlyDateNum={p.numberOfDaysParamsMonth}
                                />
                              );
                            }
                          )}
                      </S.ListRow>
                      <S.ListRow
                        notDisplay={!selectedTypeIds.includes(4) ? 'true' : ''}
                      >
                        {selectedTypeIds.includes(4) &&
                          p.estimatedProductStocks.map(
                            (
                              estimatedProductStock: MonthlyData[],
                              idx: number
                            ) => {
                              return (
                                <MemorizedListCell
                                  key={idx}
                                  p={p}
                                  paramsType={estimatedProductStock}
                                  idx={idx}
                                  last={
                                    selectedTypeIds.length === 2 ||
                                    !selectedTypeIds.includes(5)
                                  }
                                  monthlyDateNum={p.numberOfDaysParamsMonth}
                                />
                              );
                            }
                          )}
                      </S.ListRow>
                      <S.ListRow
                        notDisplay={!selectedTypeIds.includes(5) ? 'true' : ''}
                      >
                        {selectedTypeIds.includes(5) &&
                          p.actualProductStocks.map(
                            (
                              actualProductStock: MonthlyData[],
                              idx: number
                            ) => {
                              return (
                                <MemorizedListCell
                                  key={idx}
                                  p={p}
                                  paramsType={actualProductStock}
                                  idx={idx}
                                  last={true}
                                  monthlyDateNum={p.numberOfDaysParamsMonth}
                                />
                              );
                            }
                          )}
                      </S.ListRow>
                    </React.Fragment>
                  );
                })
            ) : (
              <S.CircularIconWrapper>
                <CircularProgress style={{ color: '#64b2f9' }} />
              </S.CircularIconWrapper>
            )}
          </S.ListBody>
        </S.ListWrapper>
      </div>
      {tagMenuActive && (
        <TagMenu
          searchParam={searchWord}
          selectedTags={selectedTags}
          setSelectedTags={(tags) => setSelectedTags(tags)}
          setOpenTagModal={() => setTagListModalActive(true)}
          handleChange={handleSearch}
          deleteSearchParam={() => setSearchWord('')}
        />
      )}
      {tagListModalActive && (
        <TagListModal
          selectedTags={selectedTags}
          setSelectedTags={(tags) => setSelectedTags(tags)}
          tagListModalActive={tagListModalActive}
          handleClose={() => {
            setTagListModalActive(false);
            setTagMenuActive(false);
          }}
          productKind={PRODUCT_KIND.SEMI_PRODUCT}
        />
      )}
      {displaySelectModalActive && (
        <DisplaySelectModal
          notSelectableObj={'生産計画'}
          selectedTypes={selectedTypes}
          functionTypeObj={functionTypeObj}
          setSelectedTypes={(type) => setSelectedTypes(type)}
          displaySelectModalActive={displaySelectModalActive}
          handleClose={() => setDisplaySelectModalActive(false)}
        />
      )}
      {tagMenuActive && (
        <Overlay
          zIndex={9997}
          handleClick={() => {
            setTagMenuActive(false);
          }}
        />
      )}
    </>
  );
};

export default TableWithFixedSidebar;
