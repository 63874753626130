import { useHistory } from 'react-router-dom';
import { ReactComponent as ChevronLeft } from '@assets/icons/chevron_left.svg';
import { Heading } from '@components/elements/typographies/Heading';
import { WithHeader } from '@components/templates/WithHeader';
import PickingDetailTable from '@components/modules/pc/picking/picking_detail/PickingDetailTable';
import { pickingStatus } from '@lib/pc/picking/constants';
import * as S from './PickingDetail.styles';
import { pickingDummyData } from './Dummy';

export const PickingDetailRoot = () => {
  //IDでAPIフェッチするため
  // const { id } = useParams<{ id: string | undefined }>();
  const history = useHistory();

  return (
    <WithHeader>
      <S.Wrapper>
        <div>
          <S.HeadContainer>
            <S.FlexCenter>
              <S.BackButton onClick={() => history.goBack()}>
                <ChevronLeft />
              </S.BackButton>

              <S.FlexBaseline>
                <div>
                  <Heading
                    tag="span"
                    text={pickingDummyData.shippingGroup}
                    fs="32"
                    fw="lg"
                  />
                  <Heading
                    tag="span"
                    text="ピッキングリストを確認"
                    fs="24"
                    fw="lg"
                  />
                </div>
              </S.FlexBaseline>
            </S.FlexCenter>
            <S.FlexCenter>
              {/* <div>
                <div>担当: {pickingDummyData.pickingPerson}</div>
                <div>Wﾁｪｯｸ担当: {pickingDummyData.wCheckPerson}</div>
              </div> */}
              <div>
                <S.StatusBar
                  color={pickingStatus[pickingDummyData.status].statusBarColor}
                  bgColor={
                    pickingStatus[pickingDummyData.status].statusBarBgColor
                  }
                  borderColor={
                    pickingStatus[pickingDummyData.status].statusBorderColor
                  }
                >
                  {pickingStatus[pickingDummyData.status].barLabel}
                </S.StatusBar>
              </div>
            </S.FlexCenter>
          </S.HeadContainer>
        </div>
        <div>
          <PickingDetailTable data={pickingDummyData} />
        </div>
      </S.Wrapper>
    </WithHeader>
  );
};
