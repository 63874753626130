import { useState } from 'react';
import * as S from './TagListModal.styles';
import { ReactComponent as SearchIcon } from '@assets/icons/search.svg';
import { Button } from '@components/elements/buttons/Button';
import type { Tag } from '@lib/common/type';
import useTags from '@lib/pc/common/hooks/useTags';

type Props = {
  selectedTags: Tag[];
  setSelectedTags: (tags: Tag[]) => void;
  tagListModalActive: boolean;
  productKind: number | string;
  handleClose: () => void;
};

const TagListModal: React.FC<Props> = ({
  selectedTags,
  setSelectedTags,
  tagListModalActive,
  productKind,
  handleClose,
}: Props) => {
  const { data: allTags } = useTags(productKind);
  const [searchParam, setSearchParam] = useState<string>('');
  const [selectedTagState, setSelectedTagState] = useState<Tag[]>(selectedTags);

  const tagChangeSearch = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSearchParam(e.target.value);
  };

  if (!allTags) return null;

  return (
    <S.Wrapper open={tagListModalActive}>
      <S.Container>
        <S.Header>
          <S.Title>タグの選択</S.Title>
          <S.InputWrapper>
            <S.Icon>
              <SearchIcon />
            </S.Icon>
            <S.Input
              type="text"
              id="Tag_search"
              placeholder="タグを検索"
              onChange={tagChangeSearch}
            />
          </S.InputWrapper>
        </S.Header>
        <S.ListUl>
          {allTags &&
            allTags
              .filter((tag) => tag.name.includes(searchParam))
              .map((tag) => (
                <S.ListLi key={tag.id}>
                  <S.Label>
                    <S.Checkbox
                      type="checkbox"
                      name="list"
                      value="tag_list"
                      onChange={() =>
                        selectedTagState.some((t) => t.id === tag.id)
                          ? setSelectedTagState(
                              selectedTagState.filter((t) => t.id !== tag.id)
                            )
                          : setSelectedTagState([...selectedTagState, tag])
                      }
                      defaultChecked={selectedTagState.some(
                        (t) => t.id === tag.id
                      )}
                    />
                    <S.TagName
                      defaultChecked={selectedTagState.some(
                        (t) => t.id === tag.id
                      )}
                    >
                      {tag.name}
                    </S.TagName>
                  </S.Label>
                </S.ListLi>
              ))}
        </S.ListUl>
      </S.Container>
      <S.ButtonWrapper>
        <S.ButtonContainer>
          <Button borderWidth={1} outlined={true} onClick={handleClose}>
            キャンセル
          </Button>
          <Button
            onClick={() => {
              setSelectedTags(selectedTagState);
              handleClose();
            }}
          >
            決定
          </Button>
        </S.ButtonContainer>
      </S.ButtonWrapper>
    </S.Wrapper>
  );
};
export default TagListModal;
