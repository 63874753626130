import { FC } from 'react';
import moment from 'moment';
import * as S from './RightSlideSidebar.styles';
import { Button } from '@components/elements/buttons/Button';
import ScrollToTop from '@components/modules/common/ScrollToTop/ScrollToTop';
import MaterialAllocationDetailForm from '@components/modules/pc/material_allocation_status/MaterialAllocationDetailForm/MaterialAllocationDetailForm';
import type { Todo } from '@lib/common/type';
import { SelectedMaterialItem } from '@lib/pc/material_allocation_status/type';

type Props = {
  selectedMaterial: SelectedMaterialItem | null;
  rightSlideSidebarActive: boolean;
  setRightSlideSidebarActive: (rightSlideSidebarActive: boolean) => void;
};

const RightSlideSidebar: FC<Props> = ({
  selectedMaterial,
  rightSlideSidebarActive,
  setRightSlideSidebarActive,
}: Props) => {
  const day =
    selectedMaterial && selectedMaterial.date !== ''
      ? moment(selectedMaterial.date)
      : moment();

  const formattedDate = moment(day).format('YYYY/MM/DD (ddd)');

  const handleOk = () => {
    setRightSlideSidebarActive(false);
  };

  if (!selectedMaterial) return null;

  return (
    <S.Wrapper open={rightSlideSidebarActive}>
      <ScrollToTop />
      <S.ScrollableContainer>
        <S.HeadContainer>
          <S.HeadDate>{formattedDate}</S.HeadDate>
          <S.HeadProductName>
            <S.HeadProductType productKind={selectedMaterial?.type}>
              {selectedMaterial?.type}
            </S.HeadProductType>
            {selectedMaterial?.name}
          </S.HeadProductName>
        </S.HeadContainer>
        {selectedMaterial?.materialStockAllocationDetails ? (
          <S.InputContainer>
            {(selectedMaterial?.materialStockAllocationDetails).map(
              (detail: Todo, i: number) => {
                return (
                  <MaterialAllocationDetailForm
                    key={i}
                    index={i}
                    fromStoreroomName={detail?.fromStoreroomName}
                    toStoreroomName={detail?.toStoreroomName}
                    allocationType={detail?.allocationType}
                    caseValue={detail?.case}
                    unit={detail?.unit}
                    piece={detail?.piece}
                    total={detail?.amount}
                    bestBeforeDate={
                      detail?.bestBeforeDate &&
                      moment(detail?.bestBeforeDate).format('YYYY/MM/DD (ddd)')
                    }
                    piecesPerUnit={
                      detail?.piecesPerUnit ? detail?.piecesPerUnit : 1
                    }
                    piecesPerBox={
                      detail?.piecesPerBox ? detail?.piecesPerBox : 1
                    }
                    comment={detail?.comment || ''}
                    labelOfAmount={selectedMaterial?.labelOfAmount}
                    labelOfUnit={selectedMaterial?.labelOfUnit}
                  />
                );
              }
            )}
          </S.InputContainer>
        ) : (
          <S.InputContainer>
            <S.MessageWrapper>
              <div>選択した日付の払出実績はありません。</div>
            </S.MessageWrapper>
          </S.InputContainer>
        )}
        <S.ButtonContainer invalid={false}>
          <Button borderWidth={1} onClick={handleOk}>
            OK
          </Button>
        </S.ButtonContainer>
      </S.ScrollableContainer>
    </S.Wrapper>
  );
};

export default RightSlideSidebar;
