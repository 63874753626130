import styled from 'styled-components';

export const Wrapper = styled.div`
  height: 100%;
  width: 390px;
  position: fixed;
  padding: 1rem;
  top: 0;
  z-index: 9999;
  right: 0;
  background-color: ${(props) => props.theme.palette.beige};
  box-shadow: 0px 24px 38px rgba(0, 0, 0, 0.07),
    0px 9px 46px rgba(0, 0, 0, 0.06), 0px 11px 15px rgba(0, 0, 0, 0.1);
  transform: translateX(0px);
  transition: transform 390ms ease-in-out;
`;

export const StatusBar = styled.div<{
  bgColor: string;
  color: string;
  borderColor: string;
}>`
  border: ${(props) => `${props.borderColor} solid 2px`};
  color: ${(props) => props.color};
  background-color: ${(props) => props.bgColor};
  padding: 6px 10px;
  width: 8.25rem;
  font-size: 16px;
  font-weight: 700;
  text-align: center;
`;

export const ScrollableContainer = styled.div`
  overflow-y: auto;
  height: calc(100vh - 4.6rem);
  padding-bottom: 4.6rem;
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none;
  ::-webkit-scrollbar {
    display: none;
  }
`;

export const HeadContainer = styled.div`
  position: relative;
  display: flex;
  justify-content: space-between;
  padding: 0 1rem;
`;

export const HeadDate = styled.div`
  font-weight: 700;
  line-height: 20px;
  padding-top: 7px; ;
`;

export const PickingInfoWrapper = styled.div`
  padding: 1rem;
`;

export const ProductContainer = styled.div`
  margin: 0.5em 0;
  display: flex;
  align-items: center;
  font-weight: 700;

  > div {
    &.title {
      width: 4rem;
    }
    &.name {
      font-size: 24px;
    }
  }
  > div:nth-child(2) {
    margin-left: 8px;
  }
`;

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-evenly;
  padding: 1rem;
  border-top: 2px solid rgb(211, 211, 211);

  > div.total-wrapper {
    width: 100%;
    display: flex;
    padding-bottom: 30px;
    justify-content: space-between;
    > div.amount {
      font-size: 18px;
    }
  }
`;

export const Label = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0.5rem 0;
  width: 100%;
  font-weight: 700;
  padding-bottom: 30px;
`;

export const ButtonContainer = styled.div`
  position: fixed;
  bottom: 0px;
  left: 0px;
  padding: 1rem 0px;
  width: 100%;
  display: flex;
  -webkit-box-align: center;
  align-items: center;
  justify-content: space-evenly;

  & > button {
    width: 136px;
  }

  & > button:first-child {
    margin-right: 1rem;
  }
`;
