import { format } from 'date-fns';
import * as S from './ProductList.style';
import moment from 'moment';
import { FC } from 'react';
import { Product } from '@lib/common/type';

type Props = {
  products: Product[] | undefined;
  searchChar: string;
  selectedTagIds: number[];
  selectedStoreroom: string;
  selectedStoreroomId: number | '';
  scrollPosition: number;
};

const ProductList: FC<Props> = ({
  products,
  searchChar,
  selectedTagIds,
  selectedStoreroom,
  selectedStoreroomId,
  scrollPosition,
}) => {
  const searchState = {
    stateSearchChar: searchChar,
    stateSelectedTagIds: selectedTagIds,
    stateSelectedStoreroom: selectedStoreroom,
    stateSelectedStoreroomId: selectedStoreroomId,
    stateScrollPosition: scrollPosition,
  };

  return (
    <>
      {products?.map((product, i) => (
        <S.Product
          to={{
            pathname: `/mobile/products/${product.id}/stocks/${
              product.productKind
            }/${format(new Date(), 'yyyy-MM-dd')}/${selectedStoreroomId}/edit`,
            state: searchState,
          }}
          index={i}
          key={i}
        >
          <S.ProductInfo>
            <S.ProductName>{product.name}</S.ProductName>
            <S.ProductCode>{product.productCode}</S.ProductCode>
          </S.ProductInfo>
          <S.StockInfo>
            <S.StockDateIcon>
              {product.productKind === 1 ? (
                <S.ProductIcon>完</S.ProductIcon>
              ) : (
                <S.SemiProductIcon>半</S.SemiProductIcon>
              )}
            </S.StockDateIcon>
            <S.StockDate>
              <S.UpdateDate>
                {product.stockLastInputAt && '更新日'}
              </S.UpdateDate>
              {product.stockLastInputAt
                ? moment(product.stockLastInputAt).format('YYYY/MM/DD')
                : ''}
            </S.StockDate>
          </S.StockInfo>
        </S.Product>
      ))}
    </>
  );
};
export default ProductList;
