import { useLocation } from 'react-router-dom';
import moment from 'moment';
import * as S from './PickingListTitle.styles';
import { Todo } from '@lib/common/type';
import { PickingState } from '@lib/mobile/picking_edit/type';
import { STATUS_VALUE } from '@lib/mobile/picking_by_shipping/type';

type Props = {
  selectedStoreroomId: number | '' | undefined;
  p: Todo;
  i: number;
  handleClick: (p: Todo, storeroomSelectedPickingStatus: number) => void;
  fromPickingByShippingDestination?: boolean;
  setFromDatePicker?: (fromDatePicker: boolean) => void;
  stateScrollPosition: number;
  selectedStoreroomInfo: Todo;
  setSelectedStoreroomInfo: (selectedStoreroomInfo: Todo) => void;
  selectedPickingStatus: number[] | null;
};

const PickingListTitle = ({
  selectedStoreroomId,
  p,
  i,
  handleClick,
  selectedPickingStatus,
}: Props) => {
  const { state } = useLocation<PickingState>();
  const storeroomId = state?.stateSelectedStoreroomId
    ? state?.stateSelectedStoreroomId
    : selectedStoreroomId
    ? selectedStoreroomId
    : 0;

  const dataByStorerooms = p?.dataByStorerooms;
  const storeroomInfo =
    (storeroomId === 0 || storeroomId) &&
    dataByStorerooms.find((item: Todo) => item[storeroomId]);

  // 在庫室が選択状態のピッキングステータス
  const storeroomSelectedPickingStatus =
    storeroomInfo &&
    (selectedStoreroomId === 0 || selectedStoreroomId) &&
    storeroomInfo[selectedStoreroomId]?.pickingStatus;

  // 欠品情報
  const deliveryResults =
    storeroomInfo &&
    (selectedStoreroomId === 0 || selectedStoreroomId) &&
    storeroomInfo[selectedStoreroomId]?.deliveryResults;

  return (
    <>
      {(selectedPickingStatus &&
        selectedPickingStatus.includes(storeroomSelectedPickingStatus)) ||
      !selectedPickingStatus ? (
        <S.PickingListWrapper
          onClick={() => handleClick(p, storeroomSelectedPickingStatus)}
          index={i}
          key={i}
          backgroundColor={
            STATUS_VALUE[storeroomSelectedPickingStatus]?.backgroundColor
          }
        >
          <S.GroupNameTd>
            <S.GroupName>{p.groupName}</S.GroupName>
            {/* <S.GroupTime>{p.shippingScheduledTime}時出荷分</S.GroupTime> */}
            <S.TimeAndShortageInfo>
              <S.ShortageInfo>{deliveryResults && '欠品あり'}</S.ShortageInfo>
              <S.TimeInfo>
                {p.shippingScheduledTime &&
                  moment(p.shippingScheduledTime).format('HH:mm')}
              </S.TimeInfo>
            </S.TimeAndShortageInfo>
          </S.GroupNameTd>
          <td>
            <S.NumOfProducts>
              {storeroomInfo &&
              (selectedStoreroomId === 0 || selectedStoreroomId) &&
              storeroomInfo[selectedStoreroomId]?.numberOfProducts
                ? storeroomInfo[selectedStoreroomId]?.numberOfProducts
                : 0}
              <span>品目</span>
            </S.NumOfProducts>
            <S.NumOfProducts>
              <span>計</span>
              {storeroomInfo &&
              (selectedStoreroomId === 0 || selectedStoreroomId) &&
              storeroomInfo[selectedStoreroomId]?.totalAmount
                ? storeroomInfo[selectedStoreroomId]?.totalAmount
                : 0}
              <span>個</span>
            </S.NumOfProducts>
          </td>
          <td>
            <S.StatusIcon
              btnBgColor={
                STATUS_VALUE[storeroomSelectedPickingStatus]?.btnBgColor
              }
              borderColor={
                STATUS_VALUE[storeroomSelectedPickingStatus]?.borderColor
              }
              fontSize={STATUS_VALUE[storeroomSelectedPickingStatus]?.fontSize}
              fontColor={
                STATUS_VALUE[storeroomSelectedPickingStatus]?.fontColor
              }
              fontWeight={
                STATUS_VALUE[storeroomSelectedPickingStatus]?.fontWeight
              }
            >
              {STATUS_VALUE[storeroomSelectedPickingStatus]?.key}
            </S.StatusIcon>
          </td>
        </S.PickingListWrapper>
      ) : (
        <></>
      )}
    </>
  );
};

export default PickingListTitle;
