import { Viewer, Worker } from '@react-pdf-viewer/core';
import {
  defaultLayoutPlugin,
  ToolbarProps,
} from '@react-pdf-viewer/default-layout';
import '@react-pdf-viewer/core/lib/styles/index.css';
import '@react-pdf-viewer/default-layout/lib/styles/index.css';

const PdfContent = () => {
  const renderToolbar = (
    Toolbar: (props: ToolbarProps) => React.ReactElement
  ) => (
    <Toolbar>
      {(toolbarSlot) => {
        const { ZoomOut, Zoom, ZoomIn, EnterFullScreen } = toolbarSlot;
        return (
          <div
            style={{
              alignItems: 'center',
              display: 'flex',
              width: '100%',
            }}
          >
            <div style={{ padding: '0px 2px' }}>
              <EnterFullScreen />
            </div>
            <div style={{ padding: '0px 2px', marginLeft: 'auto' }}>
              <ZoomOut />
            </div>
            <div style={{ padding: '0px 2px' }}>
              <Zoom />
            </div>
            <div style={{ padding: '0px 2px' }}>
              <ZoomIn />
            </div>
          </div>
        );
      }}
    </Toolbar>
  );

  const defaultLayoutPluginInstance = defaultLayoutPlugin({
    renderToolbar,
    sidebarTabs: () => [],
  });

  return (
    <Worker workerUrl="https://unpkg.com/pdfjs-dist@2.3.200/build/pdf.worker.min.js">
      <Viewer
        fileUrl="https://regnio-factory.s3.amazonaws.com/store/bb_pdf.pdf"
        plugins={[defaultLayoutPluginInstance]}
      />
    </Worker>
  );
};

export default PdfContent;
