import React, { useState } from 'react';
import * as S from './ProcessesMaterialList.styles';
import { ReactComponent as CircleCloseIcon } from '@assets/icons/circle_close.svg';
import { Button } from '@components/elements/buttons/Button';
import MaterialSelect from '@components/modules/pc/material_allocation/MaterialSelect/MaterialSelect';
import { MATERIAL_TYPE_LABEL, Todo } from '@lib/common/type';
import { Material } from '@lib/pc/settings/product_master_setting/type';

type Props = {
  label: string;
  materials: Todo;
  materialEditItem: Material;
  materialsArray: Material[];
  selectedMaterialId: number;
  setSelectedMaterialId: (selectedMaterialId: number) => void;
  setSelectedMaterialName: (selectedMaterialName: string) => void;
  setIsChanged: (isChanged: boolean) => void;
  setEnableToSave: (enableToSave: boolean) => void;
  setErrMsg: (errMsg: string) => void;
  setConfirmMsg: (confirmMsg: string) => void;
  setDeleteItem: (deleteItem: string) => void;
  setDeleteNo: (deleteNo: number) => void;
  setMaterialEditItem: (materialEditItem: Material) => void;
  selectedMaterialLabelOfAmount: string;
  setSelectedMaterialLabelOfAmount: (
    selectedMaterialLabelOfAmount: string
  ) => void;
  disabled: boolean;
};

const ProcessesMaterialList = ({
  label,
  materials,
  materialEditItem,
  materialsArray,
  selectedMaterialId,
  setSelectedMaterialId,
  setSelectedMaterialName,
  setIsChanged,
  setEnableToSave,
  setErrMsg,
  setConfirmMsg,
  setDeleteItem,
  setDeleteNo,
  setMaterialEditItem,
  selectedMaterialLabelOfAmount,
  setSelectedMaterialLabelOfAmount,
  disabled,
}: Props) => {
  // 資材・原材料入力の数
  const [materialUsageAmount, setMaterialUsageAmount] = useState<Todo>(null);

  const materialsArrayIds = materialsArray.map((item) => item['id']);
  const filteredMaterials = materials?.filter(
    (m: { id: number }) => !materialsArrayIds.includes(m.id)
  );

  const handleChangeMaterial = (id: number) => {
    setSelectedMaterialId(id);
  };

  // 資材・原材料追加ボタン
  const onClickMaterialAddButton = (materialEditItem: Material) => {
    if (materialUsageAmount > 0) {
      setIsChanged(true);
      setEnableToSave(true);
      materialsArray.push({
        id: materialEditItem.id,
        name: materialEditItem.name,
        imageUrl: materialEditItem.imageUrl,
        usageAmount: materialUsageAmount,
        labelOfAmount: selectedMaterialLabelOfAmount,
      });
      setMaterialUsageAmount(null);
      setMaterialEditItem({
        id: 0,
        name: '',
        usageAmount: 0,
        imageUrl: '',
        labelOfAmount: '',
      });
      setSelectedMaterialId(0);
      setSelectedMaterialName('');
      setSelectedMaterialLabelOfAmount('');
    } else {
      setErrMsg('0より大きい数を入力してください。');
    }
  };

  // 資材・原材料削除ボタン
  const onClickMaterialDelButton = (no: number, name: string) => {
    setConfirmMsg(`${name}を本当に削除しますか？`);
    setDeleteNo(no);
    if (label === MATERIAL_TYPE_LABEL.MATERIAL) {
      setDeleteItem('material');
    } else if (label === MATERIAL_TYPE_LABEL.ROW_MATERIAL) {
      setDeleteItem('rawMaterial');
    }
  };

  // 資材・原材料の数の設定
  const handleChangeMaterialUsageAmount = (
    e: React.ChangeEvent<HTMLInputElement>
  ) => {
    setIsChanged(true);
    setMaterialUsageAmount(e.target.value);
  };

  return (
    <S.Wrapper>
      {/* 資材・原材料個数追加箇所 */}
      <div className="material-piece-breakdown-list-header">
        <dl className="material-piece-breakdown-list-header__inputs">
          <div className="close-btn" />
          {/* <div className="product-img" /> */}
          <div className="product-name">
            <dt>{label}</dt>
            <dd>
              {materials && (
                <MaterialSelect
                  menuShouldBlockScroll
                  menuShouldScrollIntoView
                  materials={filteredMaterials}
                  handleChangeMaterial={handleChangeMaterial}
                  setEditMaterialName={setSelectedMaterialName}
                  setSelectedMaterialLabelOfAmount={
                    setSelectedMaterialLabelOfAmount
                  }
                  value={selectedMaterialId}
                  height="40px"
                  maxHeight="200px"
                  marginTop="4px"
                  notDisplayCode
                />
              )}
            </dd>
          </div>
          <div className="product-number-wrapper">
            <div className="product-number">
              <dt>数量</dt>
              <dd>
                <S.Input
                  type="number"
                  inputMode="numeric"
                  name="productPieces"
                  id="productPieces"
                  autoComplete="off"
                  align="left"
                  padding="0 5px"
                  value={materialUsageAmount ? materialUsageAmount : ''}
                  onChange={handleChangeMaterialUsageAmount}
                  disabled={disabled}
                />
              </dd>
            </div>
          </div>
          <div className="label-of-amount">
            {selectedMaterialLabelOfAmount ? selectedMaterialLabelOfAmount : ''}
          </div>
          <div className="add-btn">
            {materialEditItem.name && materialUsageAmount ? (
              <Button
                children={'追加'}
                onClick={() => onClickMaterialAddButton(materialEditItem)}
              />
            ) : (
              <Button disabled children={'追加'} />
            )}
          </div>
        </dl>
      </div>
      {/* 資材・原材料個数内訳リスト */}
      <div className="piece-breakdown-list-contents">
        <ul className="piece-breakdown-list-contents__pieces">
          {materialsArray &&
            materialsArray.map((m: Material, index: number) => {
              return (
                <li
                  className="piece-breakdown-list-contents__piece"
                  key={m?.id + index + Math.random()}
                >
                  <div
                    className="close-btn"
                    onClick={() => onClickMaterialDelButton(index, m?.name)}
                    key={m?.name + index}
                  >
                    <CircleCloseIcon />
                  </div>
                  {/* {m?.imageUrl ? (
                    <img src={m.imageUrl} />
                  ) : (
                    <div className="product-img" key={Math.random()}>
                      img
                    </div>
                  )} */}
                  <div className="product-name" key={m?.name + Math.random()}>
                    {m?.name}
                  </div>
                  <div
                    className="product-pieces-num"
                    key={m?.name + m.usageAmount + Math.random()}
                  >
                    {m?.usageAmount}
                  </div>
                  <div className="label-of-amount">
                    {m?.labelOfAmount ? m?.labelOfAmount : '個'}
                  </div>
                  {/* <div className="add-btn"></div> */}
                </li>
              );
            })}
        </ul>
      </div>
    </S.Wrapper>
  );
};

export default ProcessesMaterialList;
