import { useEffect, useState } from 'react';
import { DndProvider } from 'react-dnd';
import { useHistory, useLocation, useParams } from 'react-router-dom';
import { HTML5Backend } from 'react-dnd-html5-backend';
import * as S from './ProductMasterEditWrapper.styles';
import ProductInfo from '@components/modules/pc/settings/product_master_setting/ProductMasterEdit/ProductInfo/ProductInfo';
import CaseInfo from '@components/modules/pc/settings/product_master_setting/ProductMasterEdit/CaseInfo/CaseInfo';
import ProcessInfo from '@components/modules/pc/settings/product_master_setting/ProductMasterEdit/ProcessInfo/ProcessInfo';
import TagInfo from '@components/modules/pc/settings/product_master_setting/ProductMasterEdit/TagInfo/TagInfo';
import StoreroomInfo from '@components/modules/pc/settings/product_master_setting/ProductMasterEdit/StoreroomInfo/StoreroomInfo';
import { Todo } from '@lib/common/type';
import ConfirmPopUp from '@components/modules/common/ConfirmPopUp/ConfirmPopUp';
import UpdatePopUp from '@components/modules/pc/settings/common/UpdatePopUp/UpdatePopUp';
import Overlay from '@components/elements/backdrops/Overlay/Overlay';
import { ProductMasterSettingState } from '@lib/pc/settings/product_master_setting/type';
import useProducts from '@lib/pc/common/hooks/useProducts';
import useFactories from '@lib/pc/settings/product_master_edit/useFactories';

type Props = {
  product: Todo;
  refetch: () => void;
  timestamp: string;
};

export type InputParams = {
  time: string;
};

const ProductMasterEditWrapper = ({ product, refetch }: Props) => {
  const { timestamp } = useParams<{ timestamp: string }>();
  const { state } = useLocation<ProductMasterSettingState>();
  const history = useHistory();
  const [popUp, setPopUp] = useState(false);
  const [updatePopUp, setUpdatePopUp] = useState(false);
  const [updating, setUpdating] = useState(false);
  const [newProductData, setNewProductData] = useState<Todo>(product?.product);

  const [selectedTabIndex, setSelectedTabIndex] = useState(0);
  const [isChanged, setIsChanged] = useState(false);
  const [confirmMsg, setConfirmMsg] = useState('');
  const [selectedTabId, setSelectedTabId] = useState(0);
  // 保存ボタン有効化
  const [enableToSave, setEnableToSave] = useState(false);
  // ケース編集モード
  const [caseEditMode, setCaseEditMode] = useState(false);

  // 確認メッセージhandleOk判定用:一覧画面へ移動か、別タブ表示か。
  const [goBack, setGoBack] = useState(false);
  const { data: allProducts } = useProducts();
  const { data: allFactories } = useFactories();

  const ProductSettingTabs = ['製品情報', '工程', '在庫室', 'タグ', 'ケース'];

  const defaultNewProductData = '';
  // ProductInfoで作成した新しいProduct
  const [productFromInfo, setProductFromInfo] = useState(defaultNewProductData);

  const caseAlert = product?.productAndCasesInfo.length === 0 ? true : false;
  const storeRoomAlert = product?.storerooms.length === 0 ? true : false;

  // 工程タブ、工程select選択可能
  const [selectableProcess, setSelectableProcess] = useState(false);

  const handleProductUpdateSuccess = () => {
    refetch();
    setUpdating(false);
    setPopUp(!popUp);
    setIsChanged(false);
    setUpdatePopUp(!updatePopUp);
    setEnableToSave(false);
    setCaseEditMode(false);
    if (selectedTabIndex === 1) {
      setSelectableProcess(true);
    }
  };

  // 一覧画面へ戻る
  const onClickGoBack = () => {
    if (isChanged) {
      setGoBack(true);
      setConfirmMsg('変更した内容を保存せずに終了します。よろしいですか？');
    } else {
      history.push({
        pathname: `/pc/settings/product_master_setting`,
        state: state,
      });
    }
  };

  const handleClickTab = (i: number) => {
    setGoBack(false);
    setCaseEditMode(false);
    // setEnableToSave(false);
    if (isChanged) {
      setSelectedTabId(i);
      setConfirmMsg('変更した内容を保存せずに終了します。よろしいですか？');
    } else {
      setSelectedTabIndex(i);
    }
  };

  // 確認メッセージOK
  const handleOk = () => {
    // ここで一覧画面へ戻るか別タブを表示するかの判断が必要
    if (goBack) {
      history.push({
        pathname: `/pc/settings/product_master_setting`,
        state: {
          ...state,
          messageKind: '',
          productName: '',
          stateSelectedTabId: 0,
        },
      });
    } else {
      setSelectedTabIndex(selectedTabId);
      setEnableToSave(false);
      refetch();
    }
    setIsChanged(false);
    setConfirmMsg('');
  };
  // 確認メッセージキャンセル
  const handleCancel = () => {
    setConfirmMsg('');
  };

  useEffect(() => {
    if (state.stateSelectedTabId) {
      setSelectedTabIndex(state.stateSelectedTabId);
    } else {
      setSelectedTabIndex(0);
    }
  }, [state]);

  return (
    <div>
      <S.TabWrapper>
        {ProductSettingTabs.map((d: string, i: number) => (
          <div key={d}>
            <S.TabArea>
              <S.Tab
                key={d}
                tabCount={ProductSettingTabs.length}
                active={i == selectedTabIndex}
                onClick={() => {
                  handleClickTab(i);
                }}
              >
                {d}
              </S.Tab>
            </S.TabArea>
          </div>
        ))}
      </S.TabWrapper>

      <DndProvider backend={HTML5Backend}>
        {/* 製品情報 */}
        {selectedTabIndex === 0 && (
          <ProductInfo
            product={product}
            updating={updating}
            setUpdating={setUpdating}
            setPopUp={setPopUp}
            newProductData={newProductData}
            setNewProductData={setNewProductData}
            handleProductUpdateSuccess={handleProductUpdateSuccess}
            onClickGoBack={onClickGoBack}
            isChanged={isChanged}
            setIsChanged={setIsChanged}
            allProducts={allProducts}
            productFromInfo={productFromInfo}
            setProductFromInfo={setProductFromInfo}
            timestamp={timestamp}
            caseAlert={caseAlert}
            storeRoomAlert={storeRoomAlert}
          />
        )}
        {/* 工程 */}
        {selectedTabIndex === 1 && (
          <ProcessInfo
            product={product}
            updating={updating}
            setUpdating={setUpdating}
            onClickGoBack={onClickGoBack}
            setIsChanged={setIsChanged}
            handleProductUpdateSuccess={handleProductUpdateSuccess}
            allFactories={allFactories}
            enableToSave={enableToSave}
            setEnableToSave={setEnableToSave}
            selectableProcess={selectableProcess}
            setSelectableProcess={setSelectableProcess}
          />
        )}
        {/* 在庫室 */}
        {selectedTabIndex === 2 && (
          <StoreroomInfo
            product={product}
            updating={updating}
            setUpdating={setUpdating}
            onClickGoBack={onClickGoBack}
            handleProductUpdateSuccess={handleProductUpdateSuccess}
            isChanged={isChanged}
            setIsChanged={setIsChanged}
            setEnableToSave={setEnableToSave}
            caseEditMode={caseEditMode}
            setCaseEditMode={setCaseEditMode}
          />
        )}
        {/* タグ */}
        {selectedTabIndex === 3 && (
          <TagInfo
            product={product}
            updating={updating}
            setUpdating={setUpdating}
            onClickGoBack={onClickGoBack}
            handleProductUpdateSuccess={handleProductUpdateSuccess}
            isChanged={isChanged}
            setIsChanged={setIsChanged}
          />
        )}
        {/* ケース */}
        {selectedTabIndex === 4 && (
          <CaseInfo
            product={product}
            updating={updating}
            setUpdating={setUpdating}
            onClickGoBack={onClickGoBack}
            handleProductUpdateSuccess={handleProductUpdateSuccess}
            setIsChanged={setIsChanged}
            productFromInfo={productFromInfo}
            enableToSave={enableToSave}
            setEnableToSave={setEnableToSave}
            caseEditMode={caseEditMode}
            setCaseEditMode={setCaseEditMode}
          />
        )}
      </DndProvider>
      <ConfirmPopUp
        fromPc={true}
        confirmMsg={confirmMsg}
        zIndex={1004}
        handleOk={handleOk}
        handleCancel={handleCancel}
      />
      <UpdatePopUp
        popUp={updatePopUp}
        taskKind={'messageOnly'}
        messageKind={'update'}
        handleClose={() => setUpdatePopUp(false)}
      />
      {updatePopUp && <Overlay handleClick={() => setUpdatePopUp(false)} />}
    </div>
  );
};

export default ProductMasterEditWrapper;
