import styled, { css } from 'styled-components';
import { FixedSizeList as List, FixedSizeGrid as Grid } from 'react-window';

export const StyledList = styled(List)`
  scrollbar-width: none;
  -ms-overflow-style: none;

  &::-webkit-scrollbar {
    display: none;
  }
`;

export const ListWrapper = styled.div`
  max-width: 100vw;
  max-height: 100vh;
  height: calc(100vh - 165px);

  overflow: scroll;
  display: flex;
  position: relative;
  background: ${(props) => props.theme.palette.beige};
`;

const highLightenHeadCell = css`
  height: 49px;
  color: ${(props) => props.theme.palette.white}!important;
  background: ${(props) => props.theme.palette.blue}!important;
  border-color: ${(props) => props.theme.palette.blue}!important;
`;

// 月日、合計、前年同月
export const ListHeadCell = styled.div<{
  highLighten: boolean;
  dayOfWeek?: string;
  index: number;
  numOfIndex: number;
}>`
  height: 49px;
  width: 91px;
  box-sizing: border-box;
  padding: 0.5rem 0;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  position: sticky;

  /* 前年同月 */
  :last-child {
    position: sticky;
    top: 0;
    right: 0;
    border-left: 2px double ${(props) => props.theme.palette.sandGray};
    font-weight: 700;
  }

  /* 合計 */
  :nth-last-child(2) {
    position: sticky;
    top: 0;
    right: 91px;
    border-left: 2px double ${(props) => props.theme.palette.sandGray};
    font-weight: 700;
  }

  :nth-child(odd) {
    background: ${(props) => props.theme.palette.beige};
  }

  :nth-child(even) {
    background: ${(props) => props.theme.palette.white};
  }

  ${({ highLighten }) => (highLighten ? highLightenHeadCell : null)}

  color: ${(props) =>
    props.dayOfWeek === '土' && props.index <= props.numOfIndex
      ? '#1046cf'
      : props.dayOfWeek === '日' && props.index <= props.numOfIndex
      ? '#c74141'
      : ''};
`;

export const ListBody = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  z-index: 2;
  position: relative;
  height: max-content;
  -webkit-transition: transform 320ms ease-in-out;
  -moz-transition: transform 320ms ease-in-out;
  -ms-transition: transform 320ms ease-in-out;
  -o-transition: transform 320ms ease-in-out;
  transition: transform 320ms ease-in-out;
  position: -webkit-sticky; /* for Safari */
  position: sticky;
  top: 0;
  :first-child {
    position: -webkit-sticky; /* for Safari */
    position: sticky;
    top: -1px;
    z-index: 4;
    box-shadow: 1px 3px 5px 0px rgba(0, 0, 0, 0.2);
  }

  &.move-to-left {
    -webkit-transform: translateX(-320px);
    -moz-transform: translateX(-320px);
    -ms-transform: translateX(-320px);
    -o-transform: translateX(-320px);
    transform: translateX(-320px);
  }

  & > div:nth-child(2) {
    margin-top: 3px;
  }
`;

// 月日、合計、前年同月ラッパー
export const HeaderListRow = styled.div`
  display: flex;
  background: #fff;
  position: -webkit-sticky !important; /* for Safari */
  position: sticky !important;
  left: 0;
  top: 0;
  z-index: 4;
  box-shadow: 1px 3px 5px 0px rgba(0, 0, 0, 0.2);

  & > div:nth-child(odd) {
    background: ${(props) => props.theme.palette.beige};
  }

  & > div:nth-child(even) {
    background: ${(props) => props.theme.palette.white};
  }
`;

// 各表示タイプ1列
export const ListRow = styled.div<{
  notDisplay?: boolean;
  yellow?: boolean;
}>`
  display: ${(props) => (props.notDisplay ? 'none' : 'flex')};

  background: ${(props) =>
    props.yellow
      ? `${props.theme.palette.paleYellow} !important`
      : `${props.theme.palette.white} !important`};
`;

export const VirtualizedList = styled.div<{ productsExist: boolean }>`
  position: absolute; //これをコメントアウトすると、ヘッダ以下すべて消える。
  left: 0;
  top: 50px;
  /* width: 100%; */ //この100%をいかすと、表示タイプが表示されなくなる。
  display: flex;

  &.grid-class {
    background: ${(props) => `${props.theme.palette.beige} !important`};
  }

  & > div:nth-child(odd) {
    background: ${(props) =>
      props.productsExist
        ? `${props.theme.palette.beige} !important`
        : `${props.theme.palette.beige} !important`};
  }
`;

export const StyledGrid = styled(Grid)`
  scrollbar-width: none;
  -ms-overflow-style: none;

  &::-webkit-scrollbar {
    display: none;
  }
`;

export const FixedSizeListWrapper = styled.div`
  position: sticky;
  left: 0;
  overflow: hidden;
  /* overflow: visible; */
  z-index: 1;
  display: flex;
`;

export const NoProductsToShowNameArray = styled.div`
  min-width: 300px;
`;

export const NoProductsToShow = styled.div`
  width: 100vw;
  padding: 10rem;
  margin-left: 5%;
  font-weight: 700;
  & > div {
    font-weight: 300;
    font-size: 14px;
    padding-top: 1rem;
  }
`;

export const FixedSizeListTotalWrapper = styled.div`
  position: sticky;
  right: 0;
  overflow: hidden;
  /* overflow: visible; */
  z-index: 1;
  display: flex;
`;

export const ListSidebar = styled.div`
  min-width: 300px;
  height: max-content;
  border-right: 1px solid ${(props) => props.theme.palette.yellowGray};
  background: #fff;
  background: ${(props) => props.theme.palette.beige};

  position: absolute;
  left: 0;
  z-index: 5;

  & > div:first-child {
    background: ${(props) => props.theme.palette.paleYellow};

    position: -webkit-sticky; /* for Safari */
    position: sticky;
    top: -1px;
    padding: 1px;
    background: ${(props) => props.theme.palette.yellowGray};
    z-index: 1000;

    position: sticky;
    left: 0;
    z-index: 1;
  }
`;

export const ListSidebarFilter = styled.div`
  min-width: 300px;
  height: max-content;
  border-right: 1px solid ${(props) => props.theme.palette.yellowGray};
  background: #fff;
  background: ${(props) => props.theme.palette.beige};

  position: sticky;
  left: 0;
  top: 0;
  z-index: 5;

  & > div:first-child {
    background: ${(props) => props.theme.palette.paleYellow};

    position: -webkit-sticky; /* for Safari */
    position: sticky;
    top: -1px;
    padding: 1px;
    background: ${(props) => props.theme.palette.yellowGray};
    z-index: 1000;
  }
`;

export const Matrix = styled.div`
  /* position: absolute; */
  left: 305px;
  top: 50px;
  width: 100%;
`;

export const SearchArea = styled.div`
  display: flex;
  justify-content: space-between;
  box-shadow: 1px 3px 5px 0px rgba(0, 0, 0, 0.2);
`;

// 商品の絞込、表示選択
export const ListLeftHeadCell = styled.div<{
  isBlue?: boolean;
}>`
  height: 47px;
  min-width: 298px;
  box-shadow: 4px 3px 5px 0px rgba(0, 0, 0, 0.05);
  position: -webkit-sticky; /* for Safari */
  display: flex;
  justify-content: space-between;
  padding: 6px 0.5rem;
  flex-direction: column;

  > span {
    font-size: 0.75rem;
    &.filter-product {
      width: 61%;
      padding-left: 30px;
      height: 32px;
      line-height: 32px;
      position: absolute;
      background: ${(props) => props.theme.palette.white};
      color: ${(props) =>
        props.isBlue ? props.theme.palette.blue : '#9ca3af'};
      border-radius: 0.5rem;
      cursor: pointer;

      > svg {
        position: absolute;
        top: 12%;
        left: 5%;
      }

      > div {
        padding-left: 3px;
        font-size: 0.7rem;
      }
    }
  }

  :first-child {
    background-color: #f5f2e6 !important;
    > div > input {
      cursor: pointer;
      height: 32px;
    }
  }
`;

export const DisplayButton = styled.div<{
  isBlue: boolean;
}>`
  > span {
    &.filter-product {
      width: 29%;
      padding-left: 30px;
      height: 32px;
      line-height: 32px;
      top: 7px;
      right: 8px;
      position: absolute;
      background: ${(props) => props.theme.palette.white};
      color: ${(props) =>
        props.isBlue ? props.theme.palette.blue : '#9ca3af'};
      border-radius: 0.5rem;
      cursor: pointer;

      > svg {
        position: absolute;
        top: 12%;
        left: 5%;
      }

      > div {
        font-size: 0.7rem;
      }
    }
  }

  > svg {
    position: absolute;
    top: 12%;
    left: 5%;
  }
`;

export const CircularIconWrapper = styled.div<{
  infiniteScroll?: boolean;
}>`
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 10001;
  background-color: ${(props) => props.theme.palette.beige} !important;
  /* background-color: ${(props) =>
    !props.infiniteScroll ? props.theme.palette.beige : ''} !important; */
`;
