import { Heading } from '@components/elements/typographies/Heading';
import { WithHeader } from '@components/templates/WithHeader';
import { FC, useCallback, useState, useEffect } from 'react';
import * as S from './BasisForProductionSchedule.styles';
import RightSlideSidebar from '@components/modules/pc/production_schedule/RightSlideSidebar/RightSlideSidebar';
import TableWithFixedSidebar from '@components/modules/pc/production_schedule/TableWithFixedSidebar/TableWithFixedSidebar';
import ProductDetailModal from '@components/modules/pc/production_schedule/ProductDetailModal/ProductDetailModal';
// import UpdatingModal from '@components/modules/pc/production_schedule/UpdatingModal/UpdatingModal';
import moment from 'moment';
import { useUpdateScheduleAndStockMutation } from '@lib/pc/production_schedule/hooks';
import UpdatePopUp from '@components/modules/common/UpdatePopUp/UpdatePopUp';
import type { SelectedProduct } from '@lib/pc/production_schedule/type';
import type { Todo } from '@lib/common/type';
import ArrowRight from '@assets/icons/arrow_right_24px';
import { ReactComponent as ArrowLeft } from '@assets/icons/arrow_left_24px.svg';
import { ReactComponent as BrainIcon } from '@assets/icons/brain.svg';
import { Button } from '@components/elements/buttons/Button';
import { CircularProgress } from '@material-ui/core';
import Overlay from '@components/elements/backdrops/Overlay/Overlay';
import FactoryLineSelect from '@components/modules/pc/production_schedule/FactoryLineSelect/FactoryLineSelect';
import FactorySelect from '@components/modules/pc/production_schedule/FactorySelect/FactorySelect';
import { getTodayForReference } from '@lib/common/functions';
import SwitchButton from '@components/modules/common/SwitchButton/SwitchButton';
import { PRODUCT_KIND } from '@lib/common/type';
import useFactoryLines from '@lib/pc/common/hooks/useFactoryLines';
import useFactories from '@lib/pc/common/hooks/useFactories';
import { useDeviceOrientationAndSize } from '@lib/common/functions';
import { useUpdateProductionScheduleMutation } from '@lib/pc/production_schedule/hooks';

export const BasisForProductionScheduleRoot: FC = () => {
  const today = moment();
  const [date, setDate] = useState<Todo>(moment());
  const [productionDate, setProductionDate] = useState<Date>();
  const [selectedProduct, setSelectedProduct] =
    useState<SelectedProduct | null>(null);
  const [productDetailId, setProductDetailId] = useState<number | null>(null);
  const [selectedId, setSelectedId] = useState(false);
  const [productName, setProductName] = useState('');
  const [productSafetyStockDays, setProductSafetyStockDays] = useState('');
  const [popUp, setPopUp] = useState(false);
  const [reCalculation, setReCalculation] = useState(false);
  const [directMode, setDirectMode] = useState(false);
  const [errMsg, setErrMsg] = useState('');
  const [updateCount, setUpdateCount] = useState(0);
  const [selectIndex, setSelectIndex] = useState<number>(
    new Date().getDate() - 1
  );
  const [selectedFactoryId, setSelectedFactoryId] = useState(0);
  const [selectedFactoryLineId, setSelectedFactoryLineId] = useState(0);
  const [factoryLineIds, setFactoryLineIds] = useState<number[]>([]);
  const [selectedFactoryLineName, setSelectedFactoryLineName] =
    useState('全てのライン');

  const formattedDate = moment(productionDate).format('YYYY/MM/DD (ddd)');
  const { deviceType } = useDeviceOrientationAndSize();
  const isTablet = deviceType === 'tablet';
  // セル直接入力で更新中
  const [directUpdating, setDirectUpdating] = useState(false);
  const [updating, setUpdating] = useState(false);
  const { data: factories } = useFactories();

  const { data: factoryLines } = useFactoryLines(PRODUCT_KIND.PRODUCT);

  const handleClosePopUp = () => {
    setPopUp(false);
  };

  const handleSelectedProduct = useCallback(
    (product: SelectedProduct, name: string) => {
      setSelectedProduct(product);
      setProductName(name);
      setPopUp(false);
    },
    []
  );

  const handleCloseSidebar = useCallback((date: Date) => {
    setProductionDate(date);
    setSelectedProduct(null);
  }, []);

  const handleOpenProductDetail = useCallback(
    (id: number, name: string, safetyStockDays: string) => {
      setProductDetailId(id);
      setSelectedId(true);
      setProductName(name);
      setProductSafetyStockDays(safetyStockDays);
      setPopUp(false);
    },
    []
  );

  const handleCloseProductDetail = useCallback(() => {
    setProductDetailId(null);
    setSelectedId(false);
    setProductName('');
  }, []);

  const handleSuccess = () => {
    setReCalculation(!reCalculation);
  };

  const updateScheduleAndStockMutation = useUpdateScheduleAndStockMutation(
    handleSuccess,
    setErrMsg
  );

  const getMonth = (num: number) => {
    setDate(moment(date).add(num, 'months').startOf('month'));
  };

  const handleClickToday = () => {
    getTodayForReference(
      date.format('YYYY-MM'),
      today.format('YYYY-MM'),
      setDate,
      setSelectIndex
    );
  };

  const handleSubmitReCalculation = () => {
    reCalculation && updateScheduleAndStockMutation.mutate();
    setReCalculation(!reCalculation);
    setUpdateCount(0);
  };

  const handleChangeSwitch = () => {
    setDirectMode(!directMode);
    setSelectedProduct(null);
  };

  const [inputParams, setInputParams] = useState<Todo>({
    actualAmount: '',
    comment: '',
    checkEnabled: false,
  });

  const day = new Date(
    selectedProduct ? selectedProduct.selectedSchedule.date : null
  );

  // RightSlideSidebarでの変更有無
  const [isChanged, setIsChanged] = useState(false);

  const handleUpdateSuccess = () => {
    setPopUp(!popUp);
    handleCloseSidebar(day);
    setIsChanged(false);
    setUpdating(false);
  };

  // 更新のmutation
  const updateProductionScheduleMutation = useUpdateProductionScheduleMutation(
    selectedProduct?.productId ? selectedProduct?.productId : 0,
    selectedProduct && selectedProduct.selectedSchedule.date,
    handleUpdateSuccess,
    setErrMsg
  );

  // RightSlideSidebarでの変更ボタン押下処理
  const handleChangeButtonOnRightSlideSidebar = () => {
    if (!isChanged) {
      setErrMsg('変更するデータはありません。');
    } else {
      setUpdating(true);
      selectedProduct && updateProductionScheduleMutation.mutate(inputParams);
      moment(day) >= today && setUpdateCount(updateCount + 1);
    }
  };

  useEffect(() => {
    if (factories && factories.length > 0) {
      setSelectedFactoryId(factories[0].id);
    }
  }, [factories]);

  useEffect(() => {
    setSelectedFactoryLineId(0);
    setSelectedFactoryLineName('全てのライン');
  }, [selectedFactoryId]);

  useEffect(() => {
    if (selectedFactoryId) {
      const filteredFactoryLine = factoryLines?.filter(
        (factoryLine) => factoryLine.factoryId === selectedFactoryId
      );
      const filteredFactoryLineIds = filteredFactoryLine?.map(
        (item) => item['id']
      );
      filteredFactoryLineIds && setFactoryLineIds(filteredFactoryLineIds);
    }
  }, [factoryLines?.length]);

  useEffect(() => {
    localStorage.setItem('last_view_page', 'production_schedule');
  }, []);

  return (
    <WithHeader>
      <S.Wrapper>
        <S.HeadContainer>
          <S.PageTitle>
            <S.ProductKind>完成品</S.ProductKind>
            <S.PageTitleText>
              <Heading tag="span" text="生産計画" fs="32" fw="lg" />
              <Heading tag="span" text="を確認" fs="24" fw="lg" />
            </S.PageTitleText>
          </S.PageTitle>
          <S.DateWrapper>
            <SwitchButton
              handleChangeSwitch={handleChangeSwitch}
              label="直接入力"
            />
            <S.SelectWrapper>
              <FactorySelect
                data={factories}
                value={selectedFactoryId}
                setSelectedId={setSelectedFactoryId}
                height="42px"
                width={isTablet ? '148px' : '180px'}
                searchable={true}
                factoryLineIds={factoryLineIds}
                setFactoryLineIds={setFactoryLineIds}
              />
            </S.SelectWrapper>
            <S.SelectWrapper>
              <FactoryLineSelect
                data={factoryLines}
                value={selectedFactoryLineId}
                setSelectedId={setSelectedFactoryLineId}
                height="42px"
                width={isTablet ? '152px' : '180px'}
                searchable={true}
                disabled={!selectedFactoryId}
                selectedFactoryLineName={selectedFactoryLineName}
                setSelectedFactoryLineName={setSelectedFactoryLineName}
                factoryLineIds={factoryLineIds}
              />
            </S.SelectWrapper>
            <Button
              width={isTablet ? '70px' : '90px'}
              margin={'0 3px 0 0'}
              outlined={true}
              borderWidth={1}
              onClick={handleClickToday}
            >
              今日
            </Button>
            <S.ArrowLeft selected={false} onClick={() => getMonth(-1)}>
              <ArrowLeft />
              <span className="circle"></span>
            </S.ArrowLeft>
            <S.Date>
              {moment(date).format('YYYY')}
              <span>年</span>
              {moment(date).format('MM')}
              <span>月</span>
            </S.Date>
            <S.ArrowRight selected={false} onClick={() => getMonth(1)}>
              <ArrowRight isActive={true} />
            </S.ArrowRight>
            <S.ButtonContainer>
              <S.calcButton
                onClick={() =>
                  updateCount >= 1 && setReCalculation(!reCalculation)
                }
                updateCount={updateCount >= 1}
              >
                <BrainIcon />
                <span className="tooltip">再計算</span>
              </S.calcButton>
            </S.ButtonContainer>
          </S.DateWrapper>
        </S.HeadContainer>
        <TableWithFixedSidebar
          selectedDate={date}
          selected={selectedProduct}
          handleSelectedProduct={handleSelectedProduct}
          handleOpenProductDetail={handleOpenProductDetail}
          selectIndex={selectIndex}
          setSelectIndex={setSelectIndex}
          directMode={directMode}
          updateCount={updateCount}
          setUpdateCount={setUpdateCount}
          directUpdating={directUpdating}
          setDirectUpdating={setDirectUpdating}
          selectedFactoryId={selectedFactoryId}
          selectedFactoryLineId={selectedFactoryLineId}
        />
        <RightSlideSidebar
          handleCloseSidebar={handleCloseSidebar}
          selectedProduct={selectedProduct}
          popUp={popUp}
          setPopUp={setPopUp}
          directMode={directMode}
          handleChangeButtonOnRightSlideSidebar={
            handleChangeButtonOnRightSlideSidebar
          }
          isChanged={isChanged}
          setIsChanged={setIsChanged}
          inputParams={inputParams}
          setInputParams={setInputParams}
          updating={updating}
          errMsg={errMsg}
          setErrMsg={setErrMsg}
        />
        {productDetailId && (
          <ProductDetailModal
            selectedDate={moment(date)}
            id={productDetailId}
            productName={productName}
            productSafetyStockDays={productSafetyStockDays}
            selectedId={selectedId}
            handleClose={handleCloseProductDetail}
          />
        )}
        <UpdatePopUp
          popUp={popUp}
          formattedDate={formattedDate}
          productName={productName}
          handleClose={handleClosePopUp}
          fromPc={true}
          taskKind={'production_schedule'}
        />
        {reCalculation && (
          <S.ModalWrapper>
            <S.ModalTextContainer>
              <span>
                {`${selectedFactoryLineName}の生産計画の再計算を行いますか？`}
              </span>
              <span>※少々時間がかかります。</span>
            </S.ModalTextContainer>
            <S.ModalButtonContainer>
              <Button
                padding="lg"
                outlined
                width="160px"
                onClick={() => setReCalculation(!reCalculation)}
              >
                キャンセル
              </Button>
              <Button
                padding="md"
                outlined
                width="160px"
                onClick={() => handleSubmitReCalculation()}
              >
                再計算
              </Button>
            </S.ModalButtonContainer>
          </S.ModalWrapper>
        )}
        {!reCalculation && updateScheduleAndStockMutation.isLoading && (
          <S.CircularIconWrapper>
            <CircularProgress style={{ color: '#64b2f9' }} />
          </S.CircularIconWrapper>
        )}
        {(reCalculation || updateScheduleAndStockMutation.isLoading) && (
          <Overlay dark={true} />
        )}
        {/* 下記は一旦外す（考慮するべきことがあるため） */}
        {/* {selectedProduct && (
          <Overlay handleClick={() => setSelectedProduct(null)} />
        )} */}
        {/* {directUpdating && <Overlay dark={true} />}
        {directUpdating && <UpdatingModal />} */}
      </S.Wrapper>
    </WithHeader>
  );
};
