import { axiosInstance } from '@lib/pc/common/api/axiosConfig';
import { useQuery } from 'react-query';
import humps from 'humps';
import type { ProductsProcess } from '@lib/common/type';

const useProductsProcesses = () => {
  return useQuery('products_processes', async () => {
    const { data } = await axiosInstance().get('/api/v1/products_processes');
    return humps.camelizeKeys(data.products_processes) as ProductsProcess[];
  });
};

export default useProductsProcesses;
