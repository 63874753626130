import { useState } from 'react';
import * as S from './StoreRoomModal.styles';
import { Button } from '@components/elements/buttons/Button';
import { MATERIAL_PRODUCT_KIND } from '@lib/common/type';
import { StoreroomWithDisplayOrder } from '@lib/pc/settings/product_master_edit/type';
import useStoreroomsWitDisplayOrder from '@lib/pc/common/hooks/useStoreroomsWitDisplayOrder';

type Props = {
  newProductAndStoreroomsArray: StoreroomWithDisplayOrder[];
  setNewProductAndStoreroomsArray: (
    newProductAndStoreroomsArray: StoreroomWithDisplayOrder[]
  ) => void;
  storeRoomModalActive: boolean;
  handleClose: () => void;
  setIsChanged: (isChanged: boolean) => void;
};

const StoreRoomModal: React.FC<Props> = ({
  newProductAndStoreroomsArray,
  setNewProductAndStoreroomsArray,
  storeRoomModalActive,
  handleClose,
  setIsChanged,
}: Props) => {
  const { data: allStorerooms } = useStoreroomsWitDisplayOrder(
    MATERIAL_PRODUCT_KIND.ALL
  );

  const [selectedStoreroomState, setSelectedStoreroomState] = useState<
    StoreroomWithDisplayOrder[]
  >(newProductAndStoreroomsArray);

  return (
    <S.Wrapper open={storeRoomModalActive}>
      <S.Container>
        <S.Header>
          <S.Title>在庫室の選択</S.Title>
        </S.Header>
        <S.ListUl>
          {allStorerooms &&
            allStorerooms.map((as: StoreroomWithDisplayOrder) => (
              <S.ListLi key={as.id + as.name}>
                <S.Label>
                  <S.Checkbox
                    type="checkbox"
                    name="list"
                    onChange={() => {
                      selectedStoreroomState.some((t) => t.id === as.id)
                        ? setSelectedStoreroomState(
                            selectedStoreroomState.filter((t) => t.id !== as.id)
                          )
                        : setSelectedStoreroomState([
                            ...selectedStoreroomState,
                            as,
                          ]);
                      setIsChanged(true);
                    }}
                    defaultChecked={selectedStoreroomState.some(
                      (t) => t.id === as.id
                    )}
                    value={as.id}
                  />
                  <S.TagName
                    defaultChecked={selectedStoreroomState.some(
                      (t) => t.id === as.id
                    )}
                  >
                    {as.name}
                  </S.TagName>
                </S.Label>
              </S.ListLi>
            ))}
        </S.ListUl>
      </S.Container>
      <S.ButtonWrapper>
        <S.ButtonContainer>
          <Button borderWidth={1} outlined={true} onClick={handleClose}>
            キャンセル
          </Button>
          <Button
            onClick={() => {
              setNewProductAndStoreroomsArray(selectedStoreroomState);
              handleClose();
            }}
          >
            決定
          </Button>
        </S.ButtonContainer>
      </S.ButtonWrapper>
    </S.Wrapper>
  );
};
export default StoreRoomModal;
