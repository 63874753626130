import { SalesInformation } from '@lib/common/type';
import { axiosInstance } from '@lib/pc/common/api/axiosConfig';
import humps from 'humps';
import { useQuery } from 'react-query';

export const useSalesInformationList = () => {
  const { data } = useQuery(['salesInformationList'], async () => {
    const data = await axiosInstance().get('/api/v1/sales_informations');
    return data;
  });
  const salesInformationList =
    data &&
    (humps.camelizeKeys(data.data.sales_informations) as SalesInformation[]);

  return { salesInformationList };
};
