import React, { useState, useEffect } from 'react';
import { WithHeader } from '@components/templates/WithHeader';
import { Heading } from '@components/elements/typographies/Heading';
import * as S from './Stacker.styles';
import FactoryDropdown from '@components/modules/pc/stacker/FactoryDropdown/FactoryDropdown';
import StackerMainContainer from '@components/modules/pc/stacker/StackerMainContainer/StackerMainContainer';
import DateSelector from '@components/elements/inputs/DateSelector/DateSelector';
import UpdatePopUp from '@components/modules/common/UpdatePopUp/UpdatePopUp';
import useStackers from '@lib/pc/stacker/useStackers';
import { PcScrollStyle } from '@lib/styled-components/globalStyle';
import { Button } from '@components/elements/buttons/Button';
import { getTodayForInput } from '@lib/common/functions';
import moment from 'moment';

// 親スタッカー
export const StackerRoot: React.FC = () => {
  const today = new Date();
  const [date, setDate] = useState<Date>(new Date());

  const { stackingResults, refetch } = useStackers(date, 10000);
  const [selectedFactoryId, setSelectedFactoryId] = useState<
    number | string | undefined
  >(
    stackingResults?.StackingResults &&
      stackingResults?.StackingResults[0].factoryId
  );
  // 全工場のデータが入っている
  const [factoriesData] = useState(
    stackingResults?.StackingResults && stackingResults.StackingResults
  );
  // 選択された工場のみのデータが入っている
  const [selectedFactoryData, setSelectedFactoryData] = useState(
    stackingResults?.StackingResults && stackingResults?.StackingResults[0]
  );
  // ライン選択
  const [selectedLineId, setSelectedLineId] = useState<number>(
    selectedFactoryData?.lines[0].factoryLineId
  );
  const [popUp, setPopUp] = useState(false);
  const [tabColor, setTabColor] = useState(0);
  const [editProductName, setEditProductName] = useState('');

  // 1日戻る
  const getBeforeDate = (date: Date) => {
    setDate(moment(date).subtract(1, 'days').toDate());
    setPopUp(false);
  };

  // 1日進む
  const getNextDate = (date: Date) => {
    // 現在日付けが過去日のときのみ、日付を進められる。
    if (
      moment(date).format('YYYY/MM/DD') !== moment(today).format('YYYY/MM/DD')
    ) {
      setDate(moment(date).add(1, 'days').toDate());
      setPopUp(false);
    }
  };

  const handleClick = () => {
    getTodayForInput(date, today, setDate, refetch);
  };

  useEffect(() => {
    localStorage.setItem('last_view_page', 'stacker');
  }, []);

  return (
    <WithHeader>
      <PcScrollStyle />
      <S.Wrapper>
        <div>
          <S.HeadContainer>
            <S.FlexBaseline>
              <div>
                <Heading tag="span" text="スタッカー実績" fs="32" fw="lg" />
                <Heading tag="span" text="を入力" fs="24" fw="lg" />
              </div>
              <FactoryDropdown
                stackingResults={stackingResults}
                factories={
                  factoriesData
                    ? factoriesData
                    : stackingResults?.stackingResults
                }
                selectedFactoryId={selectedFactoryId}
                setSelectedFactoryId={setSelectedFactoryId}
                setSelectedFactoryData={setSelectedFactoryData}
                setSelectedLineId={setSelectedLineId}
              />
            </S.FlexBaseline>
            <S.DateWrapper>
              <Button
                width="90px"
                margin={'0 12px 0 0'}
                outlined={true}
                borderWidth={1}
                onClick={handleClick}
              >
                今日
              </Button>
              <DateSelector
                date={date}
                getBeforeDate={getBeforeDate}
                getNextDate={getNextDate}
                setDate={setDate}
              />
            </S.DateWrapper>
          </S.HeadContainer>
          <StackerMainContainer
            refetch={refetch}
            stackingResults={stackingResults}
            selectedFactoryData={
              selectedFactoryData
                ? selectedFactoryData
                : stackingResults?.stackingResults[0]
            }
            setSelectedFactoryData={setSelectedFactoryData}
            selectedFactoryId={selectedFactoryId}
            setSelectedFactoryId={setSelectedFactoryId}
            selectedLineId={
              selectedLineId
                ? selectedLineId
                : stackingResults?.stackingResults[0].lines[0].factoryLineId
            }
            setSelectedLineId={setSelectedLineId}
            tabColor={tabColor}
            setTabColor={setTabColor}
            date={date}
            setPopUp={setPopUp}
            setEditProductName={setEditProductName}
          />
        </div>
      </S.Wrapper>
      <UpdatePopUp
        popUp={popUp}
        handleClose={() => setPopUp(false)}
        fromPc={true}
        taskKind={'stacker'}
        productName={editProductName}
      />
    </WithHeader>
  );
};
