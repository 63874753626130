import { WithHeader } from '@components/templates/WithHeader';
import * as S from './ShippingDestinationMasterRoot.styles';
import ShippingDestinationHeaderControl from '@components/modules/pc/settings/shipping_destination_setting/ShippingDestinationHeaderControl/ShippingDestinationHeaderControl';
import TableWithFilter from '@components/modules/pc/settings/shipping_destination_setting/TableWithFilter/TableWithFilter';

export const ShippingDestinationMasterRoot = () => {
  return (
    <WithHeader>
      {/* 出荷先管理 */}
      <S.Wrapper>
        <ShippingDestinationHeaderControl />
      </S.Wrapper>
      <TableWithFilter />
    </WithHeader>
  );
};
