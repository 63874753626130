import * as S from './ShortageMenu.styles';

type Props = {
  selectedShortStatus: boolean;
  setSelectedShortStatus: (selectedShortStatus: boolean) => void;
};

const ShortageMenu = ({
  selectedShortStatus,
  setSelectedShortStatus,
}: Props) => {
  return (
    <S.Wrapper>
      <S.StatusLabel>
        <S.CheckBox
          onChange={() => {
            setSelectedShortStatus(!selectedShortStatus);
          }}
          type="checkbox"
          value="shipping_stop_menu"
        />

        <S.CheckBoxImage checked={selectedShortStatus}>
          「欠品」ありのみ表示
        </S.CheckBoxImage>
      </S.StatusLabel>
    </S.Wrapper>
  );
};

export default ShortageMenu;
