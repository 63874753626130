import { FC } from 'react';
import { BigNumber } from 'bignumber.js';
import * as S from '@components/modules/pc/purchase_orders/PdfContent/PdfContent.styles';
import moment from 'moment';

import { Todo } from '@lib/common/type';
import { addComma } from '@lib/common/functions';

const HONORIFIC_LABEL_MAP: Record<string, JSX.Element> = {
  attention: <S.Honorific>御中</S.Honorific>,
  dear: <S.Honorific>様</S.Honorific>,
  no_value: <S.Honorific></S.Honorific>,
} as const;

// TODO: Todoの修正
type Props = {
  newData: Todo;
  manufacture: Todo;
  newTotalPrice: number;
  newTotalTax: number;
  newTotalPriceWithTax: number;
};

const PdfContent: FC<Props> = ({
  newData,
  manufacture,
  newTotalPrice,
  newTotalTax,
  newTotalPriceWithTax,
}) => {
  return (
    <S.PdfContainer>
      <S.Title>注文書</S.Title>
      <S.Summary>
        <S.LeftContent>
          <S.LeftContentUpper>
            <S.Destination>
              {newData?.supplierName}
              {HONORIFIC_LABEL_MAP[newData?.supplierHonorific]}
            </S.Destination>
            <S.InCharge>{newData?.supplierDescription}</S.InCharge>
          </S.LeftContentUpper>
          <S.LeftContentLower>
            <S.Row>
              <S.RowTitle>件名</S.RowTitle>
              <S.RowBody>{newData?.subject}</S.RowBody>
            </S.Row>
            <S.Row>
              <S.RowTitle>納期</S.RowTitle>
              <S.RowBody>{newData?.deliveryDate || ''}</S.RowBody>
            </S.Row>
            <S.Row>
              <S.RowTitle>納入場所</S.RowTitle>
              <S.RowBody>{newData?.deliveryDestination}</S.RowBody>
            </S.Row>
            <S.Row>
              <S.RowTitle>支払い条件</S.RowTitle>
              <S.RowBody>{newData?.paymentTerm}</S.RowBody>
            </S.Row>
          </S.LeftContentLower>
        </S.LeftContent>
        <S.RightContent>
          <S.CompanyInfoAndSeal>
            <S.CompanyInfo>
              <S.CompanyName>
                {manufacture?.manufactureName}
                {/* 株式会社Regnio */}
              </S.CompanyName>
              <S.ZipCode>
                〒{manufacture?.manufactureZipCode}
                {/* 〒810-0023 */}
              </S.ZipCode>
              <S.AddressFirst>
                {manufacture?.manufactureAddress1}
                {/* 福岡県福岡市中央区警固2-2-23 */}
              </S.AddressFirst>
              <S.AddressSecond>
                {manufacture?.manufactureAddress2}
                {/* ウイングコート警固601 */}
              </S.AddressSecond>
              <S.Tel>
                TEL:
                {manufacture?.manufacturePhoneNumber}
                {/* 090-4704-1033 */}
              </S.Tel>
            </S.CompanyInfo>
            <S.CompanySeal />
          </S.CompanyInfoAndSeal>
          <S.PurchaseOrderDate>
            発行日：
            {newData?.confirmationDate &&
              `${moment(newData.confirmationDate).format('YYYY年M月D日')}`}
          </S.PurchaseOrderDate>
          <S.PurchaseOrderCode>
            注文書番号：{newData?.purchaseOrderNumber}
          </S.PurchaseOrderCode>
        </S.RightContent>
      </S.Summary>
      <S.TotalAmount>
        <S.TotalAmountTitle>合計金額</S.TotalAmountTitle>
        {newTotalPrice ? (
          <S.PriceTotalValue>
            {addComma(newTotalPriceWithTax)}円
          </S.PriceTotalValue>
        ) : newData?.total ? (
          <S.PriceTotalValue>{addComma(newData?.total)}円</S.PriceTotalValue>
        ) : (
          ''
        )}
      </S.TotalAmount>
      <S.ProductList>
        <S.ProductListHeader>
          <S.ProductName>品目</S.ProductName>
          <S.ProductQuantity>数量</S.ProductQuantity>
          <S.ProductUnit>単位</S.ProductUnit>
          <S.ProductUnitPrice>単価</S.ProductUnitPrice>
          <S.ProductTotalPrice>金額</S.ProductTotalPrice>
        </S.ProductListHeader>
        <S.ProductListBody>
          {newData?.orderDetails &&
            newData.orderDetails.map((detail: Todo, index: number) => (
              <S.DetailControl
                key={index}
                display={detail?.destroy ? detail?.destroy : undefined}
              >
                <S.ProductListRow index={index} key={index}>
                  <S.ProductName>{detail?.materialName}</S.ProductName>
                  {/* 数量 */}
                  <S.ProductQuantity>
                    {detail?.amount ? detail?.amount : ''}
                  </S.ProductQuantity>
                  {/* 単位 */}
                  <S.ProductUnit>
                    {detail?.unit ? detail?.unit : ''}
                  </S.ProductUnit>
                  {/* 単価 */}
                  <S.ProductUnitPrice>
                    {detail.unitPrice ? detail.unitPrice : ''}
                  </S.ProductUnitPrice>
                  {/* 金額 */}
                  <S.ProductTotalPrice>
                    {detail?.unitPrice &&
                      detail?.amount &&
                      addComma(
                        Number(
                          BigNumber(detail?.unitPrice * detail?.amount).dp(
                            0,
                            BigNumber.ROUND_DOWN
                          )
                        )
                      )}
                  </S.ProductTotalPrice>
                </S.ProductListRow>
              </S.DetailControl>
            ))}
        </S.ProductListBody>
        <S.PriceList>
          <S.PriceListRow>
            <S.PriceListRowTitle>小計</S.PriceListRowTitle>

            {newTotalPrice ? (
              <S.PriceListRowValue>
                {addComma(newTotalPrice)}
              </S.PriceListRowValue>
            ) : newData?.subtotal ? (
              <S.PriceListRowValue>
                {addComma(newData?.subtotal)}
              </S.PriceListRowValue>
            ) : (
              ''
            )}
          </S.PriceListRow>
          <S.PriceListRow>
            <S.PriceListRowTitle>消費税合計</S.PriceListRowTitle>
            {newTotalTax ? (
              <S.PriceListRowValue>{addComma(newTotalTax)}</S.PriceListRowValue>
            ) : newData?.tax ? (
              <S.PriceListRowValue>
                {addComma(newData?.tax)}
              </S.PriceListRowValue>
            ) : (
              ''
            )}
          </S.PriceListRow>
          <S.PriceListRow>
            <S.PriceListRowTitle>合計</S.PriceListRowTitle>
            {newTotalPriceWithTax ? (
              <S.PriceListRowValue>
                {addComma(newTotalPriceWithTax)}
              </S.PriceListRowValue>
            ) : newData?.total ? (
              <S.PriceListRowValue>
                {addComma(newData?.total)}
              </S.PriceListRowValue>
            ) : (
              ''
            )}
          </S.PriceListRow>
        </S.PriceList>
      </S.ProductList>
      <S.CommentLabel>備考</S.CommentLabel>
      <S.CommentBody>{newData?.comment}</S.CommentBody>
    </S.PdfContainer>
  );
};

export default PdfContent;
