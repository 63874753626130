import { FC } from 'react';
import * as S from './FactoryLineSelect.styles';
import type { Todo } from '@lib/common/type';
import ReactSelect from 'react-select';
import { NO_OPTION_JAPANESE } from '@lib/pc/common/constants';

type Props = {
  data: Todo[] | undefined;
  value: string | number | undefined;
  width?: string;
  height?: string;
  marginTop?: string;
  disabled?: boolean;
  searchable?: boolean;
  selectedFactoryLineName?: string;
  isProductionResult?: boolean;
  factoryLineIds?: number[];
  setSelectedId?: (selectedId: number) => void;
  onChange?: (selectedCaseId: number, index?: number) => void;
  setSelectedFactoryLineName?: (selectedFactoryLineName: string) => void;
};

const FactoryLineSelect: FC<Props> = ({
  data,
  value,
  width,
  height,
  marginTop,
  disabled,
  searchable,
  isProductionResult,
  factoryLineIds,
  setSelectedId,
  // onChange,
  setSelectedFactoryLineName,
}: Props) => {
  const handleChangeId = (value: number, label: string) => {
    if (setSelectedId) {
      setSelectedId(value);
    }
    if (setSelectedFactoryLineName) {
      setSelectedFactoryLineName(label);
    }
  };

  const initialValue = {
    value: 0,
    label: isProductionResult ? '全てのライン（総数）' : '全てのライン',
  };

  const toReactSelectOptions = (
    options: { id: number; name: string; factoryName: string }[],
    factoryLineIds?: number[]
  ) =>
    options
      .filter((option) => !factoryLineIds || factoryLineIds.includes(option.id))
      .map(({ id, name }) => ({
        value: id,
        label: name,
      }));

  const reactSelectOptions =
    data && (!factoryLineIds || factoryLineIds.length === 0)
      ? [initialValue, ...toReactSelectOptions(data)]
      : data && [initialValue, ...toReactSelectOptions(data, factoryLineIds)];
  const currentValue =
    reactSelectOptions?.find((o: Todo) => o.value === value) || initialValue;

  const styles = {
    control: (baseStyles: Todo) => ({
      ...baseStyles,
      width: width ? width : '220px',
      height: height ? height : '56px',
      marginTop: marginTop ? marginTop : '',
      borderRadius: '0.5rem',
      fontWeight: 400,
      textAlign: 'left',
      borderStyle: 'hidden',
      outline: '0.5px solid rgba(0,0,0,0.08)',
      backgroundColor: disabled ? '' : 'white',
      cursor: 'pointer',
      '@media (max-width: 599px)': {
        width: '16rem',
      },
    }),
    menu: (baseStyles: Todo) => ({
      ...baseStyles,
      top: '37px',
      zIndex: 1002,
    }),
    option: (baseStyles: Todo) => ({
      ...baseStyles,
      textAlign: 'left',
      fontWeight: 400,
      fontSize: '15px',
    }),
    menuList: (baseStyles: Todo) => ({
      ...baseStyles,
      maxHeight: '180px',
    }),
  };

  return (
    <S.Wrapper>
      <ReactSelect
        options={reactSelectOptions}
        value={currentValue ? currentValue : null}
        onChange={(option: Todo) => {
          option && handleChangeId(option.value, option.label);
          // option && onChange?.(option.value);
        }}
        styles={styles}
        isDisabled={disabled}
        isSearchable={searchable ? searchable : false}
        components={{
          IndicatorSeparator: () => null,
        }}
        noOptionsMessage={() => NO_OPTION_JAPANESE}
        placeholder="生産ラインを選択"
      />
    </S.Wrapper>
  );
};

export default FactoryLineSelect;
