import styled from 'styled-components';

export const FactorySelect = styled.select`
  margin-left: 32px;
  font-size: 18px;
  cursor: pointer;
`;

export const FactoryOption = styled.option`
  background-color: white;
`;
