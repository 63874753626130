import { DetailsByProduct } from '@lib/mobile/picking_by_shipping/type';

const filterByStoreroomId = (
  data: DetailsByProduct[],
  storeroomId: number | undefined
) => {
  return data.filter((p: DetailsByProduct) => {
    if (storeroomId) {
      return p?.storeroomIds?.includes(storeroomId);
    }
    return true;
  });
};

export const filterPikingData = (
  data: DetailsByProduct[],
  searchProductChar: string | undefined,
  completedCheckState: boolean,
  allocateStatus: boolean,
  storeroomId: number
) => {
  return filterByStoreroomId(
    data
      .filter((p: DetailsByProduct) => (p?.stopShipping === false ? p : ''))
      .filter((p: DetailsByProduct) =>
        searchProductChar
          ? p?.name?.includes(searchProductChar) ||
            p?.code?.includes(searchProductChar) ||
            p?.destinationCode?.includes(searchProductChar) ||
            p?.destinationName?.includes(searchProductChar)
          : p
      )
      .filter((p: DetailsByProduct) =>
        completedCheckState
          ? allocateStatus
            ? p.allocationCheckState === false
            : p.checkState === false
          : allocateStatus
          ? p.allocationCheckState === true || p.allocationCheckState === false
          : p.checkState === true || p.checkState === false
      ),
    storeroomId
  );
};
