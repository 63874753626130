import styled from 'styled-components';

interface IProps {
  closeMode?: boolean;
  sendMode?: boolean;
  previewMode?: boolean;
  imgIndex?: number;
  sendConfirm?: boolean;
  sending?: boolean;
  sent?: boolean;
}

export const Filter = styled.div`
  background: black;
  opacity: 90%;
  height: 100vh;
  width: 100vw;
  position: absolute;
  z-index: 1;
  display: ${(props: IProps) =>
    props.closeMode || props.sendMode ? 'block' : 'none'};
`;

export const OuterBody = styled.div`
  background: black;
  height: 100vh;
`;

export const HeaderContainer = styled.div`
  width: 100%;
  height: 50px;
  background: #222222;
`;

export const CloseButton = styled.button`
  height: 50px;
  font-size: large;
  border: none;
  background: none;
  color: #2288ff;
  position: absolute;
  left: 0;
`;

export const ProductName = styled.button`
  width: 45%;
  height: 50px;
  font-size: large;
  border: none;
  background: none;
  color: white;
  position: absolute;
  left: 0;
  right: 0;
  margin: auto;
`;

export const SendButton = styled.button`
  height: 50px;
  font-size: large;
  border: none;
  background: none;
  color: #2288ff;
  position: absolute;
  right: 0;
`;

export const ThumbnailContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const Sentence1 = styled.div`
  width: 100%;
  font-size: large;
  color: white;
  text-align: center;
  position: absolute;
  margin-top: 8px;
  visibility: ${(props: IProps) =>
    props.previewMode && props.imgIndex == 0 ? 'visible' : 'hidden'};
`;

export const Sentence2 = styled.div`
  width: 100%;
  font-size: large;
  color: white;
  text-align: center;
  position: absolute;
  margin-top: 8px;
  visibility: ${(props: IProps) =>
    props.previewMode && props.imgIndex == 1 ? 'visible' : 'hidden'};
`;

export const Sentence3 = styled.div`
  width: 100%;
  font-size: large;
  color: white;
  text-align: center;
  position: absolute;
  margin-top: 8px;
  visibility: ${(props: IProps) =>
    props.previewMode && props.imgIndex == 2 ? 'visible' : 'hidden'};
`;

export const Sentence4 = styled.div`
  width: 100%;
  font-size: large;
  color: white;
  text-align: center;
  position: absolute;
  margin-top: 8px;
  visibility: ${(props: IProps) =>
    props.previewMode && props.imgIndex == 3 ? 'visible' : 'hidden'};
`;

export const BlurWrapper = styled.div<{
  previewMode: boolean;
}>`
  width: 100%;
  height: 400px;
  margin-top: 400px;
  position: absolute;
  filter: blur(10px);
  visibility: ${(props) => (props.previewMode ? 'hidden' : 'visible')};
`;

export const CameraWrapper = styled.div<{
  previewMode: boolean;
}>`
  width: 340px;
  height: 340px;
  margin-top: 400px;
  position: absolute;
  border: 5px solid white;
  visibility: ${(props) => (props.previewMode ? 'hidden' : 'visible')};
`;

export const ImageWrapper = styled.img<{
  previewMode: boolean;
}>`
  width: 340px;
  height: 340px;
  margin-top: 400px;
  position: absolute;
  visibility: ${(props) => (props.previewMode ? 'visible' : 'hidden')};
`;

export const CircleButtonContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 400px;
  height: 140px;
`;

export const Circle = styled.div`
  border-radius: 50%;
  width: 90px;
  height: 90px;
  background: white;
  position: absolute;
  visibility: ${(props: IProps) => (props.previewMode ? 'hidden' : 'visible')};
`;

export const CircleButton = styled.button`
  border-radius: 50%;
  border: 1px solid black;
  width: 80px;
  height: 80px;
  background: white;
  position: absolute;
  visibility: ${(props: IProps) => (props.previewMode ? 'hidden' : 'visible')};
`;

export const RecaptureButton = styled.button`
  width: 150px;
  height: 50px;
  font-size: large;
  border: none;
  background: none;
  color: #2288ff;
  margin-top: -130px;
  visibility: ${(props: IProps) => (props.previewMode ? 'visible' : 'hidden')};
`;

export const YesButton = styled.button`
  width: 150px;
  height: 50px;
  font-size: large;
  border: none;
  background: none;
  color: #2288ff;
  margin-top: -130px;
  visibility: ${(props: IProps) => (props.previewMode ? 'visible' : 'hidden')};
`;

export const PreviewThumbnail1 = styled.img`
  width: 64px;
  height: 64px;
  margin: 15px;
  border: 2px solid
    ${(props: IProps) => (props.imgIndex == 0 ? '#66CCFF' : 'black')};
`;

export const PreviewThumbnail2 = styled.img`
  width: 64px;
  height: 64px;
  margin: 15px;
  border: 2px solid
    ${(props: IProps) => (props.imgIndex == 1 ? '#66CCFF' : 'black')};
`;

export const PreviewThumbnail3 = styled.img`
  width: 64px;
  height: 64px;
  margin: 15px;
  border: 2px solid
    ${(props: IProps) => (props.imgIndex == 2 ? '#66CCFF' : 'black')};
`;

export const PreviewThumbnail4 = styled.img`
  width: 64px;
  height: 64px;
  margin: 15px;
  border: 2px solid
    ${(props: IProps) => (props.imgIndex == 3 ? '#66CCFF' : 'black')};
`;

export const CanvasContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  overflow-x: clip;
`;

export const CloseModal = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 320px;
  height: 150px;
  background: white;
  position: absolute;
  margin: auto;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 2;
  visibility: ${(props: IProps) => (props.closeMode ? 'visible' : 'hidden')};
`;

export const CloseConfirm = styled.div`
  text-align: center;
  font-size: large;
  color: black;
  position: absolute;
  margin-bottom: 60px;
`;

export const CloseNo = styled.button`
  text-align: center;
  font-size: large;
  color: black;
  border: none;
  background: none;
  margin-top: 60px;
  margin-right: 30px;
`;

export const CloseYes = styled.button`
  text-align: center;
  font-size: large;
  color: black;
  border: none;
  background: none;
  margin-top: 60px;
  margin-left: 30px;
`;

export const SendModal = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 320px;
  height: 150px;
  background: white;
  position: absolute;
  margin: auto;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 2;
  visibility: ${(props: IProps) => (props.sendMode ? 'visible' : 'hidden')};
`;

export const SendConfirm = styled.div`
  text-align: center;
  font-size: large;
  color: black;
  position: absolute;
  margin-bottom: 60px;
  visibility: ${(props: IProps) => (props.sendConfirm ? 'visible' : 'hidden')};
`;

export const SendNo = styled.button`
  text-align: center;
  font-size: large;
  color: black;
  border: none;
  background: none;
  margin-top: 60px;
  margin-right: 30px;
  visibility: ${(props: IProps) => (props.sendConfirm ? 'visible' : 'hidden')};
`;

export const SendYes = styled.button`
  text-align: center;
  font-size: large;
  color: black;
  border: none;
  background: none;
  margin-top: 60px;
  margin-left: 30px;
  visibility: ${(props: IProps) => (props.sendConfirm ? 'visible' : 'hidden')};
`;

export const Sending = styled.div`
  position: absolute;
  border: 10px solid lightblue;
  border-top: 10px blue solid;
  border-radius: 50%;
  height: 60px;
  width: 60px;
  animation: spin 2s linear infinite;
  /*
  border: 10px solid #f3f3f3;
  border-top: 10px solid #3498db;
  border-radius: 50%;
  width: 60px;
  height: 60px;
  animation: spin 2s linear infinite; */

  @keyframes spin {
    0% {
      transform: rotate(0deg);
    }

    100% {
      transform: rotate(360deg);
    }
  }

  visibility: ${(props: IProps) => (props.sending ? 'visible' : 'hidden')};
`;

export const SentSuccess = styled.div`
  position: absolute;
  text-align: center;
  font-size: large;
  visibility: ${(props: IProps) => (props.sent ? 'visible' : 'hidden')};
`;
