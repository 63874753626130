import { FC } from 'react';
import * as S from './DividerWithSquare.styles';
type Props = {
  borderColor: string;
  dotColor?: string;
  disableBorder?: boolean;
  disableSquare?: boolean;
};

export const DividerWithSquare: FC<Props> = ({
  borderColor = '#000',
  dotColor,
  disableBorder = false,
  disableSquare = false,
}: Props) => {
  return (
    <S.Wrapper color={borderColor}>
      {!disableBorder && <S.SolidContainer color={borderColor} />}
      {!disableSquare && <S.Square color={dotColor ? dotColor : borderColor} />}
    </S.Wrapper>
  );
};
