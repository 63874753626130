import { useMutation } from 'react-query';
import humps from 'humps';
import moment from 'moment';
import { axiosInstance } from '@lib/pc/common/api/axiosConfig';
import type { InputParams } from '@lib/stock_status/type';

// Packagingテーブル作成
const useCreateWrappingMutation = (
  date: Date,
  factoryId: number,
  factoryLineId: number,
  handleCreateSuccess: () => void
) => {
  return useMutation(
    async (params: InputParams) => {
      return await axiosInstance().post(
        `/api/v1/packagings?date=${moment(date).format(
          'YYYY-MM-DD'
        )}&factory_id=${factoryId}&factory_line_id=${factoryLineId}`,
        humps.decamelizeKeys(params)
      );
    },
    {
      onSuccess: () => {
        handleCreateSuccess();
      },
      onError: () => alert('エラーが発生しました'),
    }
  );
};

export default useCreateWrappingMutation;
