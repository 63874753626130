import styled from 'styled-components';

export const SearchWrapper = styled.div`
  display: flex;
  width: 100vw;
  background-color: ${(props) => props.theme.palette.beige};
  padding: 4px;
  height: 40px;
  border-bottom: 1px solid ${(props) => props.theme.palette.sand};
`;

export const SearchConditionInput = styled.input`
  /* margin-left: 0.75rem; */
  width: 55%;
  padding: 4px 0 4px 14px;
  border-radius: 7.8px 0px 0px 7.8px;
  background-color: '#F8F7F4';

  outline: none;

  &::placeholder {
    color: ${(props) => props.theme.palette.khaki};
  }
  &::after {
    top: 0px;
    bottom: 0px;
    display: flex;
    align-items: center;
    svg {
      width: 1.5rem;
      height: 1.5rem;
    }
    /* position: absolute; */
    background-image: '@assets/icons/mobile/search.svg';
  }
`;

export const Icon = styled.div`
  color: ${(props) => props.theme.palette.khaki};
  padding-left: 0.375rem;
  padding-right: 0.375rem;
  background-color: ${(props) => props.theme.palette.white};
  border-radius: 0 7.8px 7.8px 0;
  /* position: absolute; */
  top: 0px;
  bottom: 0px;
  display: flex;
  align-items: center;
  svg {
    width: 1.5rem;
    height: 1.5rem;
  }
`;

export const StatusLabel = styled.label`
  display: block;
  padding-top: 1px;
  background: ${(props) => props.theme.palette.beige};
`;

export const StatusCheckBox = styled.input`
  width: 128px;
  opacity: 0;
  appearance: none;
  position: absolute;
`;

export const StatusName = styled.div<{
  checked: boolean;
}>`
  display: flex;
  align-items: center;
  color: ${(props) => props.theme.palette.khaki};
  font-weight: bold;
  font-size: 13px;
  padding: 4px;
  margin-left: 8px;

  ::before {
    display: inline-block;
    content: '';
    width: 1.125rem;
    height: 1.125rem;
    margin-right: 0.45rem;
    margin-top: 2px;
    /* TODO: チェックマークだけsvgにして枠は背景はcssで作りたい */
    /* TODO: elementに切り出し */
    background-image: ${(props) =>
      props.checked
        ? "url('data:image/svg+xml;charset=utf8,%3Csvg%20width%3D%2218%22%20height%3D%2218%22%20viewBox%3D%220%200%2018%2018%22%20fill%3D%22none%22%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%3E%3Cpath%20d%3D%22M16%200H2C0.89%200%200%200.9%200%202V16C0%2017.1%200.89%2018%202%2018H16C17.11%2018%2018%2017.1%2018%2016V2C18%200.9%2017.11%200%2016%200ZM7%2014L2%209L3.41%207.59L7%2011.17L14.59%203.58L16%205L7%2014Z%22%20fill%3D%22%232BA9D1%22%2F%3E%3C%2Fsvg%3E')"
        : "url('data:image/svg+xml;charset=utf8,%3Csvg%20width%3D%2218%22%20height%3D%2218%22%20viewBox%3D%220%200%2018%2018%22%20fill%3D%22none%22%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%3E%3Cpath%20d%3D%22M16%202V16H2V2H16ZM16%200H2C0.9%200%200%200.9%200%202V16C0%2017.1%200.9%2018%202%2018H16C17.1%2018%2018%2017.1%2018%2016V2C18%200.9%2017.1%200%2016%200Z%22%20fill%3D%22%23C0C0C0%22%2F%3E%3C%2Fsvg%3E')"};
    background-repeat: no-repeat;
    background-position: center;
  }
`;
