import styled from 'styled-components';

export const Wrapper = styled.div`
  position: relative;
`;

export const WrapperButton = styled.button<{
  top?: string;
  right?: string;
  width?: string;
  height?: string;
}>`
  position: absolute;
  top: ${(props) => (props.top ? props.top : '-4rem')};
  right: ${(props) => (props.right ? props.right : '1rem')};
  width: ${(props) => (props.width ? props.width : '94px')};
  height: ${(props) => (props.height ? props.height : '4rem')};
  opacity: 0; // 確認するときは0.2程度にして確認
`;
