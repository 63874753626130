import { useState } from 'react';
import { Redirect, useLocation } from 'react-router-dom';
import { Button } from '@components/elements/buttons/Button';
import { InputWithLabel } from '@components/elements/inputs/InputWithLabel';
import { Heading } from '@components/elements/typographies/Heading';
import * as S from './MobileLoginPage.styles';
import useLogin from '@lib/mobile/common/hooks/useLogin';

const MobileLoginPage = () => {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [redirectToReferrer, setRedirectToReferrer] = useState(false);
  const location = useLocation();
  const state = location.state as { from: string } | null;
  const [handleLogin] = useLogin(email, password, () =>
    setRedirectToReferrer(true)
  );

  const mobileFunctions = localStorage.getItem('mobile_functions');

  if (redirectToReferrer) {
    return (
      <Redirect
        to={
          state?.from
            ? state?.from
            : mobileFunctions?.includes('stockStatus')
            ? '/mobile/products/for_stocks'
            : mobileFunctions?.includes('picking')
            ? '/mobile/picking_by_shipping'
            : '/mobile/products/for_stocks' // TODO: カメラ（生産実績）が増えると条件増える
        }
      />
    );
    // return <Redirect to={state?.from || '/mobile/products/for_stocks'} />;
  }

  return (
    <S.Wrapper>
      <S.Container>
        <Heading
          text="Regnio Factory Mobile"
          align="center"
          fw="lg"
          fs={'32'}
        />
        <S.InputContainer>
          <InputWithLabel
            label="メールアドレス"
            placeholder="メールアドレス"
            type="text"
            value={email}
            onChange={(event) => setEmail(event.target.value)}
          />
          <InputWithLabel
            label="パスワード"
            placeholder="パスワード"
            type="password"
            value={password}
            onChange={(event) => setPassword(event.target.value)}
          />
        </S.InputContainer>

        <div>
          {/* <Button
            onClick={() => {
              // localStorage.setItem('isAuthenticatedPC', 'true');
              history.push('products/for_stocks');
            }}
          >
            ログイン
          </Button> */}
          <Button onClick={handleLogin} padding="lg">
            ログイン
          </Button>
        </div>
      </S.Container>
    </S.Wrapper>
  );
};

export default MobileLoginPage;
