import { axiosInstance } from '@lib/pc/common/api/axiosConfig';
import { useQuery } from 'react-query';
import humps from 'humps';
import type { CaseForSetting } from '@lib/pc/settings/product_master_setting/type';

const useCasesForSetting = () => {
  const { data, refetch } = useQuery('cases_for_setting', async () => {
    const data = await axiosInstance().get(
      '/api/v1/settings/cases/index_for_setting'
    );

    return data;
  });
  const cases = (data &&
    humps.camelizeKeys(data?.data?.cases)) as CaseForSetting[];

  return { cases, refetch };
};

export default useCasesForSetting;
