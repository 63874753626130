import React, { useEffect, useState, useRef } from 'react';
import moment from 'moment';
import * as S from './WrappingEditModal.styles';
import { CircularProgress } from '@material-ui/core';
import { ReactComponent as DeleteIcon } from '@assets/icons/delete.svg';
import { ReactComponent as CameraIcon } from '@assets/icons/camera.svg';
import { Button } from '@components/elements/buttons/Button';
import CaseSelect from '@components/modules/common/CaseSelect/CaseSelect';
import EmployeeModal from '@components/modules/common/EmployeeModal/EmployeeModal';
import Overlay from '@components/elements/backdrops/Overlay/Overlay';
import PhotoViewerModal from '@components/modules/pc/wrapping_status/PhotoViewerModal/PhotoViewerModal';
import PhotoGraphyModal from '@components/modules/pc/wrapping_status/PhotoGraphyModal/PhotoGraphyModal';
import PhotoSwipeModal from '@components/modules/pc/wrapping_status/PhotoSwipeModal/PhotoSwipeModal';
import ProductSelect from '@components/modules/pc/wrapping_status/ProductSelect/ProductSelect';
import { factoryLineColors } from '@lib/pc/common/type';
import type { Case, EmployeeData } from '@lib/common/type';
import type { Product, Todo, scannedProduct } from '@lib/common/type';
import { calcTotalFunc } from '@lib/pc/wrapping/functions';
import { BestBeforeDateByPic } from '@lib/pc/wrapping/types';
import { TABLE_HEADER, TABLE_HEADER_UNDER } from './header';
import { removeSpaces } from '@lib/common/functions';

type Props = {
  data: Todo;
  date: Date;
  products: Todo;
  cases: Case[] | undefined;
  handleClose: () => void;
  handleDelete: (id: number) => void;
  handleSubmit: (data: Todo) => void;
  setEditProductName: (editProductName: string) => void;
  casesPerCart: string;
  setCasesPerCart: (casesPerCart: string) => void;
  piecesPerBox: string;
  setPiecesPerBox: (piecesPerBox: string) => void;
  selectedLineId: number;
  duringEditSubmit: boolean;
  bestBeforeDatesForModal: BestBeforeDateByPic[];
  scannedProduct: scannedProduct;
};

const WrappingEditModal = ({
  data,
  date,
  products,
  cases,
  handleClose,
  handleDelete,
  handleSubmit,
  setEditProductName,
  // casesPerCart,
  // setCasesPerCart,
  piecesPerBox,
  setPiecesPerBox,
  selectedLineId,
  duringEditSubmit,
  bestBeforeDatesForModal,
  scannedProduct,
}: Props) => {
  const [newData, setNewData] = useState(data);
  const [bestBeforeDateByPic, setBestBeforeDateByPic] = useState<
    BestBeforeDateByPic[]
  >([]);
  const [deleteId, setDeleteId] = useState<number | ''>('');
  const [errMsg, setErrMsg] = useState('');
  const valuesOfFactoryLineColors = Object.values(factoryLineColors);
  const startedTimeRef = useRef<HTMLInputElement>(null);
  const completedTimeRef = useRef<HTMLInputElement>(null);
  const bestBeforeDateRef = useRef<HTMLInputElement>(null);
  // 写真確認用のmodal画面open
  const [photoViewerModalActive, setPhotoViewerModalActive] = useState(false);
  const [editData, setEditData] = useState<Todo>(null);
  const [addPictures, setAddPictures] = useState(false);
  const [imageInfo, setImageInfo] = useState({ src: '', timestamp: 0 });
  const initializeImg = new Image();
  const [img, setImg] = useState<HTMLImageElement>(initializeImg);
  // for文が動いている間は+カメラボタンを押せないようにする
  const [duringFor, setDuringFor] = useState(false);
  // swiper画面表示
  const [swipeModalActive, setSwipeModalActive] = useState(false);
  // 写真撮影用のmodal画面open;
  const [photoGraphyModalActive, setPhotoGraphyModalActive] = useState(false);
  const [bestBeforeDate, setBestBeforeDate] = useState('');
  const [bestBeforeDateCheckCount, setBestBeforeDateCheckCount] = useState(0);
  // ケース種類
  const [selectedCaseId, setSelectedCaseId] = useState(0);
  // 選択商品のケース種類
  const [filteredCases, setFilteredCases] = useState<Case[]>([]);

  // PhotoViewerを開く
  const handleOpenPhotoViewer = () => {
    setPhotoViewerModalActive(!photoViewerModalActive);
    setEditData(editData);
    setAddPictures(true);
    // photoGraphyのmodal画面をclose
    setPhotoGraphyModalActive(false);
  };

  const handleChange = (
    e:
      | React.ChangeEvent<HTMLInputElement>
      | React.ChangeEvent<HTMLTextAreaElement>
  ) => {
    setNewData({ ...newData, [e.target.name]: e.target.value });

    // iPadOSでクリアボタンを効かせるためのコード
    setTimeout(() => {
      if (startedTimeRef.current) startedTimeRef.current.defaultValue = '';
      if (completedTimeRef.current) completedTimeRef.current.defaultValue = '';
      if (bestBeforeDateRef.current)
        bestBeforeDateRef.current.defaultValue = '';
    }, 100);
  };

  const calcTotal = (
    e: React.ChangeEvent<HTMLInputElement>,
    // casesPerCart: string,
    piecesPerBox: string
  ) => {
    if (e.target.value.match(/^[0-9]*$/)) {
      setNewData((prevData: Todo) => {
        const oldData = prevData;
        const newData = { ...oldData, [e.target.name]: e.target.value };
        const calcValue = calcTotalFunc(
          e,
          newData,
          // casesPerCart || newData.casesPerCart,
          piecesPerBox || newData.piecesPerBox
        );
        if (e.target.name.startsWith('planned')) {
          prevData = { ...newData, plannedQuantity: calcValue };
        } else {
          prevData = { ...newData, completedQuantity: calcValue };
        }
        return prevData;
      });
    }
  };

  const onClickDelete = () => {
    deleteId && handleDelete(deleteId);
  };

  // 編集画面で使用する従業員データ
  const [employeesAtEdit, setEmployeesAtEdit] = useState<EmployeeData[]>(
    newData?.employeeIds ? newData.employeeIds : []
  );
  // 従業員設定用のmodal画面open;
  const [stackerEmployeeModalActive, setStackerEmployeeModalActive] =
    useState<boolean>(false);

  const handleClickEmployee = () => {
    setStackerEmployeeModalActive(!stackerEmployeeModalActive);
  };

  const cloneDate = (dateObject: Date) => {
    return new Date(dateObject.valueOf());
  };

  // 商品の選択
  useEffect(() => {
    if (scannedProduct && scannedProduct.productId !== null) {
      handleChangeProduct(scannedProduct.productId);
    }
  }, [scannedProduct]);

  const handleChangeProduct = (e: number) => {
    if (e) {
      const getProduct = products.products.filter(
        (p: { id: number }) => p.id === Number(e)
      );
      // 賞味期限日の計算
      const calcDate = cloneDate(date);
      if (getProduct[0].expiryDays) {
        calcDate.setDate(calcDate.getDate() + Number(getProduct[0].expiryDays));
      }

      setNewData({
        ...newData,
        productId: e,
        productName: getProduct[0].name,
        bestBeforeDate: moment(calcDate).format('YYYY-MM-DD'),
      });
      setPiecesPerBox('1');
    } else {
      setNewData({
        ...newData,
        productId: '',
        productName: '',
        bestBeforeDate: '',
      });
      setPiecesPerBox('');
    }
  };

  // ケース種類の選択
  const setSelectedCase = (e: number) => {
    const filtered = filteredCases && filteredCases.find((c) => c.caseId === e);
    setNewData({
      ...newData,
      caseId: e,
      piecesPerBox: filtered?.piecesPerBox,
    });
  };

  const productRequiredCheck = (newData: Todo) => {
    if (!newData.productId) {
      setErrMsg('製品名を選択してください。');
    } else if (!newData.caseId) {
      setErrMsg('ケースの種類を選択してください。');
    } else if (
      (newData.plannedQuantity &&
        newData.plannedQuantity.toString().length > 10) ||
      (newData.completedQuantity &&
        newData.completedQuantity.toString().length > 10)
    ) {
      setErrMsg('予定数量、完了数量の合計は、10桁以内で入力してください。');
    } else {
      const searchedProduct = products.products.filter(
        (p: Product) => p.id === Number(newData.productId)
      );
      setEditProductName(searchedProduct[0].name);
      handleSubmit(newData);
    }
  };

  // 賞味期限写真撮影ボタン
  const Camera = (newData: Todo) => {
    if (!newData.productId) {
      setErrMsg('製品名を選択してください。');
    } else {
      const searchedProduct = products.products.find(
        (p: Product) => p.id === Number(newData.productId)
      );
      // photographyのmodal画面をopen
      setPhotoGraphyModalActive(!photoGraphyModalActive);
      // 入力された賞味期限の取得
      if (bestBeforeDateRef?.current?.defaultValue) {
        setBestBeforeDate(bestBeforeDateRef?.current?.defaultValue);
      } else {
        setBestBeforeDate(newData?.bestBeforeDate);
      }
      // 日付チェックが必要な総数の取得
      setBestBeforeDateCheckCount(searchedProduct.bestBeforeDateCheckCount);
      if (bestBeforeDateByPic.length === 0) {
        // 登録済み画像のみ取得
        setBestBeforeDateByPic(bestBeforeDatesForModal);
      } else {
        // 未登録画像を含む画像の取得
        setBestBeforeDateByPic(bestBeforeDateByPic);
      }
    }
  };

  // iPadOS、初期表示時クリアボタンを効かせるためのコード
  useEffect(() => {
    setTimeout(() => {
      if (startedTimeRef.current) startedTimeRef.current.defaultValue = '';
      if (completedTimeRef.current) completedTimeRef.current.defaultValue = '';
      if (bestBeforeDateRef.current)
        bestBeforeDateRef.current.defaultValue = '';
    }, 100);
  }, []);

  useEffect(() => {
    setNewData({
      ...newData,
      employeeIds: employeesAtEdit.map((e) => e.id),
    });
  }, [employeesAtEdit]);

  useEffect(() => {
    const selectedCase = filteredCases?.find(
      (c) => c.caseId === selectedCaseId
    );
    setPiecesPerBox(selectedCase?.piecesPerBox.toString() || '1');
  }, [selectedCaseId]);

  useEffect(() => {
    const filteredCases = cases?.filter(
      (c) => c.productId === newData.productId && c.productKind === 1
    );
    filteredCases && setFilteredCases(filteredCases);
    filteredCases && setSelectedCaseId(filteredCases[0]?.caseId);
    filteredCases &&
      setNewData({
        ...newData,
        caseId: filteredCases[0]?.caseId,
        piecesPerBox: filteredCases[0]?.piecesPerBox,
      });
  }, [newData.productId]);

  return (
    <>
      <S.Wrapper open={data}>
        <S.Line bgColor={valuesOfFactoryLineColors[data.lineIndex]}>
          {newData?.lineName}
        </S.Line>
        <S.Table>
          <tbody>
            {/***** 上段header *****/}
            <S.THead>
              {TABLE_HEADER.map((t, index) => (
                <S.THeadCell
                  key={index}
                  width={t.width}
                  wordBreak={t.wordBreak}
                  colSpan={t.colSpan}
                  padding={t.padding}
                  right={t.right}
                >
                  {t.key}
                </S.THeadCell>
              ))}
              {/* 削除 */}
              <S.TCell rowSpan={5} width="2.6rem" className="delete-btn">
                <S.DeleteButton onClick={() => setDeleteId(newData.id)}>
                  <DeleteIcon />
                </S.DeleteButton>
              </S.TCell>
            </S.THead>
            {/***** 上段 *****/}
            <S.TRow>
              {/* 順 */}
              <S.TCellOrder rowSpan={4}>{newData?.order}</S.TCellOrder>
              {/* 製品名 */}
              <S.TCell colSpan={1} width="64px" align="center" padding="0">
                {products.products && (
                  <ProductSelect
                    products={products.products.filter((pf: Todo) =>
                      pf.factoryLineIds.includes(selectedLineId)
                    )}
                    handleChangeProduct={handleChangeProduct}
                    setEditProductName={setEditProductName}
                    value={newData.productId}
                    disabled={
                      newData.completedQuantity > 0 ||
                      newData.plannedQuantity > 0
                    }
                    scannedProduct={scannedProduct}
                  />
                )}
              </S.TCell>
              {/* 開始時刻 */}
              <S.TCell padding="0 0 0 1.25rem">
                <S.TimeInput
                  ref={startedTimeRef}
                  type="time"
                  name="startedTime"
                  id="startedTime"
                  value={
                    (newData?.startedTime && newData?.startedTime.length > 5
                      ? moment(newData?.startedTime).format('LT')
                      : newData?.startedTime) || ''
                  }
                  onChange={handleChange}
                />
              </S.TCell>
              <S.TCell className="label" width="22px">
                予<br />定<br />数<br />量
              </S.TCell>
              {/* 完成品 包装予定数量 */}
              {/* ケース */}
              <S.TCell width="6rem">
                <div className="display-quantity__inner-wrap">
                  <div className="cell-content">
                    <S.Input
                      type="text"
                      inputMode="numeric"
                      name="plannedCase"
                      id="plannedCase"
                      value={newData?.plannedCase ?? ''}
                      onChange={(e) => {
                        calcTotal(e, newData?.piecesPerBox);
                      }}
                      autoComplete="off"
                      align="right"
                      padding="0 20px 0 0"
                      disabled={selectedCaseId || newData.caseId ? false : true}
                    />
                    <span className="unit">
                      x
                      {newData?.piecesPerBox
                        ? newData?.piecesPerBox
                        : piecesPerBox}
                      個
                    </span>
                  </div>
                  <div className="symbol">+</div>
                </div>
              </S.TCell>
              {/* 個 */}
              <S.TCell width="6rem">
                <div className="display-quantity__inner-wrap">
                  <S.Input
                    type="text"
                    inputMode="numeric"
                    name="plannedBara"
                    id="plannedBara"
                    value={newData?.plannedBara ?? ''}
                    onChange={(e) => {
                      calcTotal(e, newData?.piecesPerBox);
                    }}
                    autoComplete="off"
                    align="right"
                    padding="0 20px 0 0"
                    disabled={selectedCaseId || newData.caseId ? false : true}
                  />
                  <div className="symbol">=</div>
                </div>
              </S.TCell>
              {/* 合計 */}
              <S.TCell className="display-quantity--total" width="6rem">
                <div className="display-quantity__inner-wrap">
                  <div className="inner">
                    {newData?.plannedQuantity}
                    <span className="unit">個</span>
                  </div>
                </div>
              </S.TCell>
              {/* 賞味期限 */}
              <S.TCell
                colSpan={2}
                width={'50px'}
                fontSize={'16px'}
                padding={'0.25rem 0.25rem 0.25rem 0.25rem'}
              >
                <S.Input
                  ref={bestBeforeDateRef}
                  type="date"
                  name="bestBeforeDate"
                  id="bestBeforeDate"
                  value={newData?.bestBeforeDate || ''}
                  onChange={handleChange}
                  pattern="\d{4}-\d{2}-\d{2}"
                  height={'4rem'}
                  max="9999-12-31"
                />
              </S.TCell>
              {/* 賞味期限写真撮影 */}
              <S.CameraCell onClick={() => Camera(newData)}>
                <CameraIcon />
              </S.CameraCell>
            </S.TRow>
            {/***** 下段header *****/}
            <S.THead>
              {TABLE_HEADER_UNDER.map((t) => (
                <S.THeadCell
                  key={t.key}
                  width={t.width}
                  wordBreak={t.wordBreak}
                  colSpan={t.colSpan}
                  padding={t.padding}
                >
                  {t.key}
                </S.THeadCell>
              ))}
            </S.THead>

            {/***** 下段 *****/}
            <S.TRow>
              {/* ケースの種類 */}
              <S.TCell colSpan={1} width="64px" align="center" padding="0">
                {filteredCases && filteredCases?.length > 0 ? (
                  <CaseSelect
                    cases={filteredCases}
                    value={newData.caseId ? newData.caseId : selectedCaseId}
                    setSelectedCaseId={setSelectedCaseId}
                    onChange={setSelectedCase}
                    noLabel={true}
                    height={'64px'}
                    width={'248px'}
                    marginLeft={'0.25rem'}
                    marginTop={'0'}
                    disabled={
                      newData.productId === null ||
                      newData.completedQuantity > 0 ||
                      newData.plannedQuantity > 0
                    }
                  />
                ) : (
                  <S.FakeCaseSelect className="fake-select" />
                )}
              </S.TCell>
              {/* 終了時刻 */}
              <S.TCell padding="0 0 0 1.25rem">
                <S.TimeInput
                  ref={completedTimeRef}
                  type="time"
                  name="completedTime"
                  id="completedTime"
                  value={
                    (newData?.completedTime && newData?.completedTime.length > 5
                      ? moment(newData?.completedTime).format('LT')
                      : newData?.completedTime) || ''
                  }
                  onChange={handleChange}
                />
              </S.TCell>
              <S.TCell className="label" width="22px">
                完<br />了<br />数<br />量
              </S.TCell>
              {/* 完成品 包装完了数量 */}
              {/* ケース */}
              <S.TCell width="6rem">
                <div className="display-quantity__inner-wrap">
                  <div className="cell-content">
                    <S.Input
                      type="text"
                      inputMode="numeric"
                      name="completedCase"
                      id="completedCase"
                      value={newData?.completedCase ?? ''}
                      onChange={(e) => {
                        calcTotal(e, newData?.piecesPerBox);
                      }}
                      autoComplete="off"
                      align="right"
                      padding="0 20px 0 0"
                      disabled={selectedCaseId || newData.caseId ? false : true}
                    />
                    <span className="unit">
                      x
                      {newData?.piecesPerBox
                        ? newData?.piecesPerBox
                        : piecesPerBox}
                      個
                    </span>
                  </div>
                  <div className="symbol">+</div>
                </div>
              </S.TCell>
              {/* 個 */}
              <S.TCell width="6rem">
                <div className="display-quantity__inner-wrap">
                  <S.Input
                    type="text"
                    inputMode="numeric"
                    name="completedBara"
                    id="completedBara"
                    value={newData?.completedBara ?? ''}
                    onChange={(e) => {
                      calcTotal(e, newData?.piecesPerBox);
                    }}
                    autoComplete="off"
                    align="right"
                    padding="0 20px 0 0"
                    disabled={selectedCaseId || newData.caseId ? false : true}
                  />
                  <div className="symbol">=</div>
                </div>
              </S.TCell>
              {/* 合計 */}
              <S.TCell className="display-quantity--total" width="6rem">
                <div className="display-quantity__inner-wrap">
                  <div className="inner">
                    {newData?.completedQuantity}
                    <span className="unit">個</span>
                  </div>
                </div>
              </S.TCell>
              {/* 包装担当者 */}
              <S.TCell
                colSpan={3}
                width={'9.375rem'}
                background={'#F8F7F4'}
                fontSize={'14px'}
                padding={'0.25rem 1rem 0 0.25rem'}
                onClick={() => {
                  handleClickEmployee();
                }}
              >
                <S.EmployeeWrapper>
                  {employeesAtEdit &&
                    employeesAtEdit.map((e: EmployeeData, i: number) => {
                      return (
                        <div key={i}>
                          {e.code}
                          {e.name && ':'}
                          {removeSpaces(e.name)}
                        </div>
                      );
                    })}
                </S.EmployeeWrapper>
              </S.TCell>
            </S.TRow>
            {/***** 備考 *****/}
            <S.CommentWrapper>
              <S.TCell className="label" padding="0" labelPadding="0">
                備<br />考
              </S.TCell>
              {/* 備考 */}
              <S.TCell colSpan={7}>
                <S.TextareaPackagingComment
                  name="comment"
                  id="comment"
                  value={newData?.comment ?? ''}
                  onChange={handleChange}
                  autoComplete="off"
                  className="packaging-comment"
                />
              </S.TCell>
            </S.CommentWrapper>
          </tbody>
        </S.Table>
        <S.ButtonWrapper>
          <S.ButtonContainer>
            <Button
              borderWidth={1}
              outlined
              onClick={() => {
                handleClose();
                setBestBeforeDateByPic([]);
              }}
            >
              キャンセル
            </Button>
            <Button
              onClick={() => {
                productRequiredCheck(newData);
                setBestBeforeDateByPic([]);
              }}
            >
              保存
            </Button>
          </S.ButtonContainer>
        </S.ButtonWrapper>
      </S.Wrapper>
      {deleteId && (
        <S.DeleteModalWrapper>
          <S.DeleteModalTextContainer>
            <span>削除後は元に戻すことが出来ません。</span>
            <span>スタッカーにデータが登録されている場合も</span>
            <span>同時に削除されます。</span>
            <span>本当に削除しますか？</span>
          </S.DeleteModalTextContainer>
          <S.DeleteModalButtonContainer>
            <Button padding="md" outlined onClick={() => setDeleteId('')}>
              キャンセル
            </Button>
            <Button
              padding="md"
              icon={<DeleteIcon />}
              outlined
              onClick={onClickDelete}
            >
              削除
            </Button>
          </S.DeleteModalButtonContainer>
        </S.DeleteModalWrapper>
      )}
      {errMsg && (
        <S.DeleteModalWrapper width="30%">
          <S.DeleteModalTextContainer>
            <span>{errMsg}</span>
          </S.DeleteModalTextContainer>
          <S.DeleteModalButtonContainer>
            <Button padding="md" outlined onClick={() => setErrMsg('')}>
              OK
            </Button>
          </S.DeleteModalButtonContainer>
        </S.DeleteModalWrapper>
      )}
      {(deleteId || errMsg) && <Overlay zIndex={10000} dark />}
      {duringEditSubmit && (
        <>
          <Overlay zIndex={10005} dark />
          <S.CircularIconWrapper>
            <CircularProgress style={{ color: '#64b2f9' }} />
          </S.CircularIconWrapper>
        </>
      )}
      {stackerEmployeeModalActive && (
        <Overlay handleClick={() => setStackerEmployeeModalActive(false)} />
      )}
      <EmployeeModal
        width="5.05rem"
        employeeRole="packagings"
        employeesAtEdit={employeesAtEdit}
        setEmployeesAtEdit={setEmployeesAtEdit}
        EmployeeModalActive={stackerEmployeeModalActive}
        setEmployeeModalActive={setStackerEmployeeModalActive}
      />
      {photoGraphyModalActive && (
        <PhotoGraphyModal
          img={img}
          setImg={setImg}
          setImageInfo={setImageInfo}
          handleOpenPhotoViewer={handleOpenPhotoViewer}
          photoGraphyModalActive={photoGraphyModalActive}
          setPhotoGraphyModalActive={setPhotoGraphyModalActive}
        />
      )}
      <PhotoViewerModal
        newData={newData}
        setNewData={setNewData}
        setPhotoGraphyModalActive={setPhotoGraphyModalActive}
        duringFor={duringFor}
        setDuringFor={setDuringFor}
        bestBeforeDateByPic={bestBeforeDateByPic}
        setBestBeforeDateByPic={setBestBeforeDateByPic}
        photoViewerModalActive={photoViewerModalActive}
        setPhotoViewerModalActive={setPhotoViewerModalActive}
        pictureDataSrc={imageInfo.src}
        pictureTimestamp={imageInfo.timestamp}
        pictureData={img}
        addPictures={addPictures}
        bestBeforeDate={bestBeforeDate}
        setSwipeModalActive={setSwipeModalActive}
      />
      <PhotoSwipeModal
        newData={newData}
        setNewData={setNewData}
        swipeModalActive={swipeModalActive}
        setSwipeModalActive={setSwipeModalActive}
        setPhotoGraphyModalActive={setPhotoGraphyModalActive}
        bestBeforeDateByPic={bestBeforeDateByPic}
        setPhotoViewerModalActive={setPhotoViewerModalActive}
        bestBeforeDate={bestBeforeDate}
        bestBeforeDateCheckCount={bestBeforeDateCheckCount}
      />
      {(swipeModalActive || photoViewerModalActive) && (
        <Overlay zIndex={10000} dark />
      )}
    </>
  );
};

export default WrappingEditModal;
