import { axiosInstance } from '@lib/pc/common/api/axiosConfig';
import { useQuery } from 'react-query';
import humps from 'humps';
import type { EmployeeData } from '@lib/common/type';

const useEmployees = (role: string) => {
  return useQuery('employees', async () => {
    const { data } = await axiosInstance().get(
      `/api/v1/employees?role=${role}`
    );
    return humps.camelizeKeys(data.employees) as EmployeeData[];
  });
};

export default useEmployees;
