import { FC, useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import moment from 'moment';
import * as S from './ProductListOfPhotograph.styles';
import { ProductsPhotographState } from '@lib/mobile/products_photograph/type';

import { Todo } from '@lib/common/type';
import { useWindowScroll } from 'react-use';

type Props = {
  products: Todo[];
};

const ProductListOfPhotograph: FC<Props> = (products) => {
  const { state } = useLocation<ProductsPhotographState>();
  const [stateScrollPosition, setScrollPosition] = useState(0);
  const { y: currentScrollPosition } = useWindowScroll();

  useEffect(() => {
    if (state === undefined) {
      window.scrollTo(0, 0);
      document?.getElementById('table-contents')?.scrollTo(0, 0);
    } else {
      window.scrollTo(0, state.stateProductsScrollPosition);
    }
  }, [state]);

  // スクロール位置保存
  useEffect(() => {
    setScrollPosition(currentScrollPosition);
  }, [currentScrollPosition]);

  return (
    <>
      {products &&
        products?.products?.map((product: Todo, i: number) => (
          <S.Product
            to={{
              pathname: `/mobile/capture_of_product/${product.id}`,
              state: {
                ...state,
                stateProductId: product.id,
                stateProductName: product.name,
                stateImages: product.images,
                stateProductsScrollPosition: stateScrollPosition,
              },
            }}
            index={i}
            key={i}
          >
            <S.ProductInfo>
              <S.ProductName>{product.name}</S.ProductName>
              <S.ProductCode>{product.productCode}</S.ProductCode>
            </S.ProductInfo>
            <S.CameraInfo>
              <S.ShootingDate>
                <S.UpdateDate>{product.updatedAt && '更新日'}</S.UpdateDate>
                {product.updatedAt
                  ? moment(product.updatedAt).format('YYYY/MM/DD')
                  : ''}
              </S.ShootingDate>
            </S.CameraInfo>
          </S.Product>
        ))}
    </>
  );
};

export default ProductListOfPhotograph;
