import { useMutation } from 'react-query';
import humps from 'humps';
import { axiosInstance } from '@lib/pc/common/api/axiosConfig';
import { Todo } from '@lib/common/type';
// import type { InputParams } from '@lib/stock_status/type';

const useCreateMaterialDeliveriesMutation = (
  handleCreateSuccess: () => void,
  setErrMsg: (errMsg: string) => void
) => {
  return useMutation(
    async (params: Todo) => {
      return await axiosInstance().post(
        `/api/v1/material_deliveries`,
        humps.decamelizeKeys({
          material_delivery: {
            supplier_id: params.supplierId,
            delivery_date: params.deliveryDate,
            details_attributes: [
              {
                material_id: params.detailsAttributes[0].materialId,
                material_order_detail_id:
                  params.detailsAttributes[0].materialOrderDetailId,
                amount: params.detailsAttributes[0].amount,
              },
            ],
          },
        })
      );
    },
    {
      onSuccess: () => {
        handleCreateSuccess();
      },
      onError: (error: Todo) => {
        setErrMsg(error.response.data.message || 'エラーが発生しました');
      },
    }
  );
};

export default useCreateMaterialDeliveriesMutation;
