import { BigNumber } from 'bignumber.js';

type OrderItem = {
  unitPrice: number;
  amount: number;
  // quantity: number;
  taxRate: string;
};

export const roundingMethodForTotalTax = (
  input: number,
  roundingMethod: string
) => {
  switch (roundingMethod) {
    case 'floor':
      return BigNumber(input).dp(0, BigNumber.ROUND_DOWN);
    case 'ceil':
      return BigNumber(input).dp(0, BigNumber.ROUND_UP);
    case 'round':
      return BigNumber(input).dp(0, BigNumber.ROUND_HALF_UP);
    default:
      return BigNumber(input).dp(0, BigNumber.ROUND_DOWN);
  }
};

export const calculateTotals = (
  orderItems: OrderItem[],
  roundingMethod: string
) => {
  let totalPrice = 0;
  let totalTax = 0;
  let totalPriceWithTax = 0;

  // 各アイテムごとに金額と税額を計算し、合計を更新します
  for (const item of orderItems) {
    const numTaxRate = item.taxRate === 'ten_percent' ? 0.1 : 0.08;
    const price = BigNumber(item.unitPrice * item.amount).dp(
      0,
      BigNumber.ROUND_DOWN
    );
    const tax = +BigNumber(Number(price) * numTaxRate).dp(
      0,
      BigNumber.ROUND_DOWN
    );

    totalPrice += Number(price);
    totalTax += tax;
    totalPriceWithTax += totalPrice + tax;
  }

  // totalTaxに対して小数点処理をする
  const roundedTotalTax = Number(
    roundingMethodForTotalTax(totalTax, roundingMethod)
  );

  return { totalPrice, roundedTotalTax, totalPriceWithTax };
};
