import * as S from './ProductionScheduleMemorizedListCell.styles';
import { Todo } from '@lib/common/type';
import {
  addComma,
  hasRemainder,
  isSameIndex,
} from '@lib/pc/production_schedule/functions';
import { SelectedProduct } from '@lib/pc/production_schedule/type';

type Props = {
  selected: SelectedProduct | boolean | null;
  rowIndex: number;
  p: Todo;
  paramsType: Todo;
  cellIndex: number;
  selectIndex: number;
  monthlyDateNum: Todo;
  handleSelectedProduct: (
    d: Todo,
    productName: string,
    indexId: string
  ) => void;
  currentRowIndex: number;
  setCurrentRowIndex: (currentRowIndex: number) => void;
  currentCellIndex: number;
  setCurrentCellIndex: (currentCellIndex: number) => void;
};

const ProductionScheduleMemorizedListCell = ({
  selected,
  rowIndex,
  p,
  paramsType,
  cellIndex,
  selectIndex,
  monthlyDateNum,
  handleSelectedProduct,
  currentRowIndex,
  setCurrentRowIndex,
  currentCellIndex,
  setCurrentCellIndex,
}: Props) => {
  const isToday = isSameIndex(cellIndex, selectIndex, monthlyDateNum);

  const evenNumber = hasRemainder(cellIndex, 2);

  return (
    // 各数値セル
    <S.ListCellContainer
      key={`${rowIndex}-${cellIndex}-${p.id}`}
      productionSchedule={true}
      onClick={() => {
        handleSelectedProduct(
          {
            productId: p.id,
            productName: p.name,
            productNameAbbreviation: p.productNameAbbreviation,
            productNameKana: p.productNameKana,
            productCode: p.productCode,
            tagIds: p.tagIds,
            selectedSchedule: p.productionSchedules[cellIndex],
            actualAmount: p.productionSchedules[cellIndex].actualAmount,
            estimatedAmount: p.productionSchedules[cellIndex].estimatedAmount,
            productionDate: p.productionSchedules[cellIndex].date,
          },
          p.name,
          `${p.productionSchedules[cellIndex].productionSchedulesId}-${cellIndex}-${rowIndex}`
        );
        setCurrentRowIndex(rowIndex);
        setCurrentCellIndex(cellIndex);
      }}
      highLighten={isToday}
      invalid={selected ? true : false}
      withCheckEnabled={p.productionSchedules[cellIndex].checkEnabled}
      className={
        `${cellIndex}-${rowIndex}` === `${currentCellIndex}-${currentRowIndex}`
          ? 'select-cell'
          : ''
      }
      evenNumber={evenNumber}
    >
      {paramsType.actualAmount === 0
        ? 0
        : paramsType.actualAmount
        ? addComma(paramsType.actualAmount)
        : paramsType.estimatedAmount === 0
        ? 0
        : paramsType.estimatedAmount
        ? addComma(paramsType.estimatedAmount)
        : ''}
    </S.ListCellContainer>
  );
};

export default ProductionScheduleMemorizedListCell;
