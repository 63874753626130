import styled from 'styled-components';

export const Wrapper = styled.div<{
  open: boolean;
  fixedAreaHeight: string;
}>`
  display: ${(props) => (props.open ? 'flex' : 'none')};
  background-color: ${(props) => props.theme.palette.yellowGray};
  box-sizing: border-box;
  width: 100vw;
  margin: 0 auto;
  flex-direction: column;
  align-items: flex-start;
  padding: 0px;
`;

export const ProductFormSection = styled.section`
  :last-child {
    /* margin-bottom: 100px; */
  }
`;

export const StickyContainer = styled.div<{
  // scrollPosition: number;
  fromScannerResult?: boolean;
}>`
  height: auto;
  width: 100vw;
  box-sizing: border-box;
  /* margin-top: ${(props) => (props.fromScannerResult ? '12%' : '')}; */
  .last {
    margin-bottom: 100px;
  }
`;

export const StickyProductTitle = styled.div<{
  checked: boolean;
  // scrollPosition: number;
  fromScannerResult?: boolean;
  fixedAreaHeight?: string;
  greenFixedAreaHeight?: string;
  deliveryResults: boolean;
}>`
  display: flex;
  padding: 0.5rem;
  min-height: 60px;
  color: ${(props) =>
    props.deliveryResults
      ? props.theme.palette.error
      : props.checked
      ? props.theme.palette.khaki
      : ''};
  background-color: ${(props) =>
    props.checked ? '#dadada' : props.theme.palette.yellowGray};
  /* props.checked ? '#dadada' : props.theme.palette.white}; */
  border: 1px solid ${(props) => props.theme.palette.sand};
  border-bottom: none;
  position: -webkit-sticky; /* for Safari */
  position: sticky;
  top: ${(props) =>
    props.fromScannerResult === true
      ? props.greenFixedAreaHeight + 'px'
      : props.fixedAreaHeight + 'px'};
  width: 100vw;
  box-sizing: border-box;
  z-index: 1001;
`;

export const ClickExpandArea = styled.div<{
  shortOrOver?: boolean;
}>`
  display: flex;
  width: 100vw;
  color: ${(props) => (props.shortOrOver ? props.theme.palette.error : '')};
`;

export const ProductImg = styled.img`
  width: 45px;
  height: 45px;
  object-fit: contain;
`;

export const ProductImageContainer = styled.div`
  padding-top: 3px;
`;

export const Name = styled.div`
  width: 80%;
  padding: 3px 7px;
  font-size: 16px;
  font-weight: 400;
  > p {
    padding-bottom: 8px;
    font-weight: 700;
    font-size: 18px;
  }
`;

export const TotalAmount = styled.div`
  width: 20%;
  font-size: 18px;
  font-weight: 700;
  > p {
    font-weight: 400;
    padding-bottom: 8px;
    font-size: 13px;
  }
  > span {
    font-weight: 400;
    font-size: 13px;
  }
`;

export const CheckArea = styled.div<{
  checked: boolean;
}>`
  float: right;
  border: 1px solid
    ${(props) =>
      props.checked ? props.theme.palette.black : props.theme.palette.blue};
  width: 60px;
  height: 44px;
  border-radius: 8px;
  > span {
    font-weight: 700;
    font-size: 10px;
    text-align: center;
  }
`;

export const MessageText = styled.div`
  justify-content: space-between;
  padding: 0 0.5rem;
  display: flex;
  font-size: 16px;
  font-weight: 700;
  color: ${(props) => props.theme.palette.error};
  line-height: 23px;
  letter-spacing: 0em;
  text-align: left;
  /* background-color: ${(props) => props.theme.palette.white}; */
  background-color: ${(props) => props.theme.palette.yellowGray};
`;

export const MessageTextWhite = styled.div`
  background-color: ${(props) => props.theme.palette.white};
  border-radius: 8px 8px 0 0;
  padding-top: 8px;
  width: 100vw;
`;

export const CheckIconWrapper = styled.div`
  align-items: center;
  padding: 3px 19px;
`;

export const StatusName = styled.div<{
  checked: boolean;
}>`
  display: flex;
  align-items: center;
  color: ${(props) =>
    props.checked ? props.theme.palette.khaki : props.theme.palette.blue};
  font-weight: bold;
  font-size: 13px;
  > span {
    width: 100vw;
    font-size: 10px;
    text-align: center;
    letter-spacing: ${(props) => (props.checked ? '-0.2em' : '')};
  }
`;

export const Amount = styled.div<{
  checked: boolean;
}>`
  width: 100vw;
  padding: 9px 17px 12px 17px;
  background-color: ${(props) =>
    props.checked ? '#e0e0e0' : props.theme.palette.beige};
  opacity: ${(props) => (props.checked ? '0.7' : '')};
  box-sizing: border-box;
`;

export const DefaultCaseWrapper = styled.div<{
  checked: boolean;
}>`
  display: flex;
  padding: 9px;
  border-bottom: 1px solid #e6e1dd;
  background-color: ${(props) =>
    props.checked ? '#e0e0e0' : props.theme.palette.beige};
  .case-text {
    font-weight: 700;
    align-items: center;
    padding-left: 11px;
    padding-top: 3px;
  }
`;

export const DRightBottomWrapper = styled.div`
  display: flex;
  align-items: center;
  padding-bottom: 0.5rem;
  padding-top: 10px;
`;

export const DInputContainer = styled.div<{
  center?: boolean;
  checked?: boolean;
}>`
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 6px;
  /* width: 33%; */
  > label {
    font-weight: 700;
    font-size: 13px;
    /* height: 20px; */
  }

  > input {
    /* height: 2.9rem; */
    height: 44px;
    max-width: 6.75rem;
    width: 100%;
    border-radius: 8px;
    text-align: ${(props) => (props.center ? 'center' : 'right')};
    padding-right: 12px;
    border: 2px solid
      ${(props) =>
        props.checked ? ' rgba(0, 0, 0, 0.08)' : props.theme.palette.white};
  }

  > span {
    position: absolute;
    right: 0.3rem;
    top: 80%;
    font-size: 12px;
    margin-left: 4px;
    color: #6b675c;
  }
`;

export const DSymbol = styled.div`
  margin-top: 1rem;
  padding: 0 5px;
  font-weight: 700;
  font-size: 13px;
`;
