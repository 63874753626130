import type { Todo } from '@lib/common/type';

// 包装：台車、ケース、バラの計算
export const calcTotalFunc = (
  e: React.ChangeEvent<HTMLInputElement>,
  newData: Todo,
  casesPerCart: number | string,
  piecesPerBox: number | string
) => {
  let cartTotal = 0;
  let caseTotal = 0;
  let baraTotal = 0;

  cartTotal =
    Number(newData.cart) *
    (Number(casesPerCart) || 1) *
    (Number(piecesPerBox) || 1);
  caseTotal = Number(newData.case) * (Number(piecesPerBox) || 1);
  baraTotal = Number(newData.piece);

  return cartTotal + caseTotal + baraTotal;
};

// ケース、バラの予定数量計算
export const calcPlannedTotalFunc = (
  e: React.ChangeEvent<HTMLInputElement>,
  newData: Todo,
  piecesPerBox: number | string
) => {
  let caseTotal = 0;
  let baraTotal = 0;

  caseTotal = Number(newData.plannedCase) * (Number(piecesPerBox) || 1);
  baraTotal = Number(newData.plannedPiece);
  return caseTotal + baraTotal;
};

// ケース、個の計算
export const calcTotalFuncForIndex = (newData: Todo, piecesPerBox: number) => {
  let caseTotal = 0;
  let pieceTotal = 0;
  if (newData.amount) {
    caseTotal = Number(newData.case) * (piecesPerBox || 1);
  } else if (newData && newData.case === undefined) {
    caseTotal = Number(newData.case) * (piecesPerBox || 1);
  } else {
    caseTotal = 0;
  }
  if (newData.piece) {
    pieceTotal = Number(newData.piece);
  } else if (newData && newData.piece === undefined) {
    pieceTotal = newData.piece;
  } else {
    pieceTotal = 0;
  }

  return caseTotal + pieceTotal;
};

// numpadを使用するかの判定
export const shouldDisplayCustomNumPad = (
  productsProcessId: number,
  isIPad13: boolean,
  useScanning: boolean
) => {
  return productsProcessId && isIPad13 && useScanning; // 開発環境で確認する際は' && isIPad13'を外す
};
