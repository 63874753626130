import { axiosInstance } from '@lib/pc/common/api/axiosConfig';
import { useQuery } from 'react-query';
import humps from 'humps';
import { ShippingDestination } from './type';

const useShippingDestination = (shippingGroupId: string) => {
  const { data, refetch } = useQuery(
    ['shippingGroupsDestinations', shippingGroupId],
    async () => {
      const data = await axiosInstance().get(
        `/api/v1/settings/shipping_groups_destinations/${shippingGroupId}`
      );
      return data;
    }
  );
  const shippingDestinations = humps.camelizeKeys(
    data?.data.shipping_destinations
  ) as ShippingDestination[];

  return { shippingDestinations, refetch };
};

export default useShippingDestination;
