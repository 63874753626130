const initDetail = {
  materialId: null,
  productName: '',
  unitPrice: 0,
  amount: 0,
  taxRate: 'ten_percent',
};

export const initMaterialOrderData = {
  supplierId: '',
  supplierName: '',
  supplierDepartmentId: '',
  purchaseOrderNumber: '',
  confirmationDate: '',
  subject: '',
  requestedDeliveryDate: '',
  paymentTerm: '',
  deliveryDestination: '',
  supplierDescription: '',
  comment: '',
  orderDetails: [initDetail],
  orderStatus: '',
};
