// import { Todo } from '@lib/common/type';

import { Product } from '@lib/common/type';

// 出荷先別ピッキング,edit画面で表示されるデータ
export type DetailsByShippingDestination = {
  detailsByDestination: DestinationBreakdownDetail[];
  // detailsByShippingDestination: DestinationBreakdownDetail[];
  groupName: string;
  numberOfProducts: number;
  shippingDate: string | Date;
  shippingScheduledTime?: string;
  shippingId?: number;
  bestBeforeDate?: string | Date;
  pickingStatus: number;
  totalAmount: number;
  allCheckState: boolean[];
};

// １出荷先の配送内容
export type DestinationBreakdownDetail = {
  destinationCode: string;
  // id: number;
  destinationName: string;
  numberOfProducts: number;
  storeroomIds: number[]; //１出荷先の商品がある在庫室すべての情報
  products: DetailsByProduct[];
};

// ピッキング入力内容
export type PickingDetails = {
  bestBeforeDate: string;
  case: number;
  piece: number;
  amount: number;
  caseId: number;
  piecesPerBox: number;
};

// 商品ごとピッキング内容詳細
export type DetailsByProduct = {
  amountDetail: PickingDetails[];
  case: number;
  caseIds: number[];
  checkState: boolean;
  allocationCheckState: boolean;
  code: string;
  deliveryResults: string;
  detailStatus: number;
  detailOpen: boolean;
  destinationCode: string;
  destinationName: string;
  janCode: string;
  name: string;
  pickingDetailId: number;
  caseName: string;
  piece: number;
  piecesPerBox: number;
  productId: number;
  productImage: string;
  total: number;
  totalAmount: number;
  stopShipping: boolean;
  storeroomIds: number[];
  shortageAmount: number;
  // casesPerCart: number;
  // productCode: string;
  // // destinationBreakdownDetail: Todo[];
  // checkState: boolean;
  // // pickingBreakdownDetail: PickingBreakdownDetail[];
  // // pickingBreakdownDetailId: number;
};

type IndexShippingDetail = {
  id: number;
  groupName: string;
  shippingScheduledTime: string;
  numberOfProducts: number;
  totalAmount: number;
  pickingStatus: number;
  storeroomIds: number[];
};

// type IndexShippings = {
//   shippingData: string;
//   shippings: IndexShippingDetail[];
// };

// ピッキング一覧画面→個別画面間で保持するstate
// janCodeは個別画面→スキャン画面間で必要
export type PickingState = {
  stateJanCode: string;
  stateScrollPosition: number;
  stateSearchChar: string;
  stateShippingDate: string;
  stateShippings: IndexShippingDetail[];
  // statePickings: IndexShippingDetail[];
  // statePickings: IndexShippings;
  statePickingStatus: number[];
  stateProducts: Product[] | undefined;
  stateScannedProductName: string;
  stateScrollPositionForEdit: number;
  stateSelectedStoreroomId: number | '';
};

// ステータスのスタイル
export const STATUS_VALUE = [
  {
    key: '未着手',
    value: 0,
    backgroundColor: '#ffffff',
    btnBgColor: '#FFD130',
    fontColor: '#191712',
    borderColor: '#FFD130',
    fontSize: '16px',
    fontWeight: 700,
  },
  {
    key: 'ピッキング中',
    value: 1,
    backgroundColor: '#FEFDE8',
    btnBgColor: '#FFFCE5',
    fontColor: '#E89A25',
    borderColor: '#E89A25',
    fontSize: '14px',
    fontWeight: 700,
  },
  {
    key: '出荷済み',
    value: 2,
    backgroundColor: '#E9E9E9',
    btnBgColor: '#E0E0E0',
    fontColor: '#6B675C',
    borderColor: '#6B675C',
    fontSize: '16px',
    fontWeight: 700,
  },
  {
    key: '振分待ち',
    value: 3,
    backgroundColor: '#ffffff',
    btnBgColor: '#2BA9D1',
    fontColor: '#ffffff',
    borderColor: '#2BA9D1',
    fontSize: '16px',
    fontWeight: 700,
  },
  {
    key: '振分中',
    value: 4,
    backgroundColor: '#ffffff',
    btnBgColor: '#ffffff',
    fontColor: '#2BA9D1',
    borderColor: '#2BA9D1',
    fontSize: '16px',
    fontWeight: 700,
  },

  // {
  //   key: '出荷完了',
  //   value: 3,
  //   backgroundColor: '#C0C0C0',
  //   btnBgColor: '#6B675C',
  //   fontColor: '#ffffff',
  //   borderColor: '#6B675C',
  //   fontSize: '16px',
  //   fontWeight: 400,
  // },
];

// 未チェック（未更新）の出荷先と商品の組み合わせ→alertメッセージ用
export type NotCheckedInfo = {
  productName: string;
  destinationName: string;
};

// pickingステータス
export const PICKING_STATUS = {
  // ピッキング未着手
  NOT_STARTED: 0,
  // ピッキング中
  IN_PROGRESS: 1,
  // 出荷完了
  DONE: 2,
  // 振分待ち
  ALLOCATION_NOT_STARTED: 3,
  // 振分中
  ALLOCATION_IN_PROGRESS: 4,
};
