import {
  BrowserRouter as Router,
  Route,
  Switch,
  Redirect,
} from 'react-router-dom';
import moment from 'moment';
import { MainPath } from '@config/path';
import { defaultTheme } from '@lib/styled-components/theme';
import { ThemeProvider } from 'styled-components';
import { GlobalStyle, MobileStyle } from '@lib/styled-components/globalStyle';
import PCRoutes from '@components/routings/pc/PCRoutes';
import MobileRoutes from '@components/routings/mobile/MobileRoutes';
import NotFound from '@components/pages/NotFound/NotFound';
import { QueryClient, QueryClientProvider } from 'react-query';

moment.locale('ja', {
  weekdays: [
    '日曜日',
    '月曜日',
    '火曜日',
    '水曜日',
    '木曜日',
    '金曜日',
    '土曜日',
  ],
  weekdaysShort: ['日', '月', '火', '水', '木', '金', '土'],
});

const queryClient = new QueryClient();

function App() {
  return (
    <QueryClientProvider client={queryClient}>
      <ThemeProvider theme={defaultTheme}>
        <GlobalStyle />
        <Router>
          <Switch>
            <Route path="/" exact>
              <Redirect to={MainPath.pc} />
            </Route>

            <Route path={MainPath.pc} component={PCRoutes} />
            <Route
              path={MainPath.mobile}
              component={() => (
                <>
                  <MobileStyle />
                  <MobileRoutes />
                </>
              )}
            />
            <Route path="*" component={NotFound} />
          </Switch>
        </Router>
      </ThemeProvider>
    </QueryClientProvider>
  );
}

export default App;
