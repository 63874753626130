import { useMutation } from 'react-query';
import humps from 'humps';
import { axiosInstance } from '@lib/pc/common/api/axiosConfig';
import { Todo } from '@lib/common/type';

const useCreateSemiProductsProcessMutation = (handleSuccess: () => void) => {
  return useMutation(
    async (params: Todo) => {
      return await axiosInstance().post(
        `/api/v1/settings/semi_products_processes`,
        humps.decamelizeKeys({
          productsProcess: {
            name: params.name,
            semiProductId: params.semiProductId,
            semiProducts: params.semiProducts,
            factoryLineIds: params.factoryLineIds,
          },
        })
      );
    },
    {
      onSuccess: () => {
        handleSuccess();
      },
      onError: () => alert('エラーが発生しました'),
    }
  );
};

export default useCreateSemiProductsProcessMutation;
