import { ReactComponent as SearchIcon } from '@assets/icons/mobile/search.svg';
import * as S from './TagSearchForm.styles';

const TagSearchForm = ({
  value,
  onChangeValue,
}: {
  value: string;
  onChangeValue: (s: string) => void;
}) => {
  return (
    <S.SearchConditionPanel>
      <SearchIcon />
      <S.SearchConditionInput
        placeholder="タグを検索"
        value={value}
        onChange={(event) => onChangeValue(event.target.value)}
      />
    </S.SearchConditionPanel>
  );
};
export default TagSearchForm;
