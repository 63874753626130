import { axiosInstance } from '@lib/pc/common/api/axiosConfig';
import { useMutation, useQuery } from 'react-query';
import humps from 'humps';
import moment from 'moment';
import type { BasisForSchedules, Todo } from '@lib/common/type';
import { useSWRConfig } from 'swr';

type InputParams = {
  actualAmount: number;
  comment: string;
  checkEnabled: boolean;
};

// 再計算
export const useUpdateScheduleAndStockMutation = (
  handleSuccess: () => void,
  setErrMsg: (errMsg: string) => void
) => {
  return useMutation(
    async () => {
      return await axiosInstance().patch(
        `/api/v1/recalculation_production_schedules`
      );
    },
    {
      onSuccess: () => {
        handleSuccess();
      },
      onError: (error: Todo) => {
        setErrMsg(error.response.data.message || 'エラーが発生しました');
      },
    }
  );
};

type productsInputParams = {
  actualAmount: Todo;
  productId: number;
  date: Date;
};

// セルから直接生産計画値の更新、RightSlideSidebarからの更新
export const useUpdateProductionScheduleMutation = (
  productId: number,
  date: Date | undefined,
  handleSuccess: () => void,
  setErrMsg: (errMsg: string) => void
) => {
  const { mutate } = useSWRConfig();
  let finalProductId = 0;
  let finalDate: Date | undefined = undefined;

  return useMutation(
    async (params: productsInputParams) => {
      finalProductId =
        params.productId && params.productId !== 0
          ? params.productId
          : productId;
      finalDate = params.date ? params.date : date;

      return await axiosInstance().patch(
        `/api/v1/production_schedules/?date=${moment(finalDate).format(
          'YYYYMMDD'
        )}&product_id=${finalProductId}`,
        humps.decamelizeKeys(params)
      );
    },
    {
      onSuccess: (response, variables) => {
        handleSuccess();
        mutate(
          'schedule',
          [
            {
              product_id: finalProductId,
              date: finalDate,
              actual_amount: variables.actualAmount,
            },
          ],
          false
        );
        mutate('stock', response.data, false);
      },
      onError: (error: Todo) => {
        setErrMsg(error.response.data.message || 'エラーが発生しました');
      },
    }
  );
};

export const useProductionSchedulesForOneProduct = (
  date: Date,
  productId: number | string
) => {
  const { data } = useQuery(
    ['production_schedules', date, productId],
    async () => {
      const data = await axiosInstance().get(
        `/api/v1/production_schedules/${productId}?date=${moment(date).format(
          'YYYYMMDD'
        )}`
      );

      return data;
    }
  );

  const productionSchedulesForOneProduct =
    data && humps.camelizeKeys(data.data);

  return { productionSchedulesForOneProduct };
};

/**
 *
 * 【半製品】生産計画に関するAPI
 *
 */

export const useSemiProductionSchedules = (
  date: moment.Moment,
  factoryLineId: number
) => {
  const { data, refetch } = useQuery(
    ['semi_production_schedules', date, factoryLineId],
    async () => {
      const data = await axiosInstance().get(
        `/api/v1/semi_production_schedules?date=${date.format(
          'YYYYMMDD'
        )}&factory_line_id=${factoryLineId}`
      );

      return data;
    }
  );

  const semiProductionSchedules =
    data &&
    (humps.camelizeKeys(
      data.data.basis_for_production_schedules
    ) as BasisForSchedules[]);

  return { semiProductionSchedules, refetch };
};

export const useSemiProductionSchedulesForOneProduct = (
  date: Date,
  semiProductId: number | string
) => {
  const { data } = useQuery(
    ['semi_production_schedules', date, semiProductId],
    async () => {
      const data = await axiosInstance().get(
        `/api/v1/semi_production_schedules/${semiProductId}?date=${moment(
          date
        ).format('YYYYMMDD')}`
      );

      return data;
    }
  );

  const semiProductionSchedulesForOneProduct =
    data && humps.camelizeKeys(data.data);

  return { semiProductionSchedulesForOneProduct };
};

export const useUpdateSemiProductionScheduleMutation = (
  semiProductionScheduleId: number | null,
  handleSuccess: () => void,
  setErrMsg: (errMsg: string) => void
) => {
  return useMutation(
    async (params: InputParams) => {
      return await axiosInstance().patch(
        `/api/v1/semi_production_schedules/${semiProductionScheduleId}`,
        humps.decamelizeKeys(params)
      );
    },
    {
      onSuccess: () => {
        handleSuccess();
      },
      onError: (error: Todo) => {
        setErrMsg(error.response.data.message || 'エラーが発生しました');
      },
    }
  );
};
