import React from 'react';
import * as S from './StackerDetailTable.styles';
import StackerDetail from '@components/modules/pc/stacker/StackerDetail/StackerDetail';
import useCreateStackerMutation from '@lib/pc/stacker/useCreateStackerMutation';
import useUpdateStackerMutation from '@lib/pc/stacker/useUpdateStackerMutation';
import type { Line, StackingResult } from '@lib/common/type';

export type AlignType = 'left' | 'center' | 'right';

type Props = {
  refetch: () => void;
  line: Line;
  tabColor: number;
  factoryId: number;
  date: Date;
  setPopUp: (popUp: boolean) => void;
  setEditProductName: (editProductName: string) => void;
};
type UpdateParam = StackingResult & { stackingResult: { id: number } };

const isUpdateParam = (
  stackingResult: StackingResult
): stackingResult is UpdateParam => !!stackingResult.stackingResult?.id;

const StackerDetailTable = ({
  refetch,
  line,
  tabColor,
  factoryId,
  date,
  setPopUp,
  setEditProductName,
}: Props) => {
  const handleEditSubmit = (newEditData: StackingResult) => {
    setEditProductName(newEditData.productName);
    if (isUpdateParam(newEditData)) {
      updateStackerMutation.mutate(newEditData);
    } else {
      createStackerMutation.mutate(newEditData);
    }
  };

  const handleSuccess = () => {
    setPopUp(true);
    refetch();
  };

  // データ作成
  const createStackerMutation = useCreateStackerMutation(
    factoryId,
    line.factoryLineId,
    date,
    handleSuccess
  );

  // データ更新
  const updateStackerMutation = useUpdateStackerMutation(
    factoryId,
    line.factoryLineId,
    date,
    handleSuccess
  );

  return (
    <>
      <S.Table>
        {line.packagingResult?.map((t, i) => {
          return (
            <React.Fragment key={i}>
              <StackerDetail
                line={line}
                editData={t}
                handleEditSubmit={handleEditSubmit}
                tabColor={tabColor}
                date={date}
                setPopUp={setPopUp}
              />
            </React.Fragment>
          );
        })}
      </S.Table>
    </>
  );
};

export default StackerDetailTable;
