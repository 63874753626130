import { FC } from 'react';
import ReactSelect from 'react-select';
import * as S from './OrderTypeSelect.styles';
import type { Todo } from '@lib/common/type';
import { NO_OPTION_JAPANESE } from '@lib/pc/common/constants';

type Props = {
  allLabelOfAmount: Todo[];
  value: string | undefined;
  setSelectedOrderTypeName: (selectedOrderTypeName: string) => void;
  disabled?: boolean;
  newMaterialData: Todo;
  setNewMaterialData: (newMaterialData: Todo) => void;
  setIsChanged: (isChanged: boolean) => void;
};

const OrderTypeSelect: FC<Props> = ({
  allLabelOfAmount,
  value,
  setSelectedOrderTypeName,
  disabled,
  newMaterialData,
  setNewMaterialData,
  setIsChanged,
}: Props) => {
  const handleChange = (e: number, s: string) => {
    const selectedLabel = allLabelOfAmount.find((obj) => obj.name === s);
    setSelectedOrderTypeName(s);
    setNewMaterialData({
      ...newMaterialData,
      orderType: selectedLabel?.name === '都度発注' ? 'on_demand' : 'regular',
    });
    setIsChanged(true);
  };

  const toReactSelectOptions = (options: { id: number; name: string }[]) =>
    options.map(({ id, name }) => ({
      value: id,
      label: name,
    }));
  const reactSelectOptions =
    allLabelOfAmount && toReactSelectOptions(allLabelOfAmount);
  const currentValue = reactSelectOptions?.find((o) => o.label === value);

  const styles = {
    control: (baseStyles: Todo) => ({
      ...baseStyles,
      minWidth: '100%',
      width: '213px',
      height: '40px',
      borderRadius: '0.5rem',
      fontWeight: 400,
      textAlign: 'left',
      borderStyle: 'hidden',
      outline: '0.5px solid rgba(0,0,0,0.08)',
      backgroundColor: disabled ? '' : 'white',
      color: '#000405',
      cursor: 'pointer',
      boxShadow: 'none',
      '@media (max-width: 599px)': {
        width: '16rem',
      },
    }),
    menu: (baseStyles: Todo) => ({
      ...baseStyles,
      top: '37px',
      zIndex: 1002,
    }),
    option: (baseStyles: Todo) => ({
      ...baseStyles,
      textAlign: 'left',
      fontWeight: 400,
      fontSize: '15px',
    }),
    menuList: (baseStyles: Todo) => ({
      ...baseStyles,
      maxHeight: '100px',
    }),
    singleValue: (baseStyles: Todo) => ({
      ...baseStyles,
      height: '20px',
    }),
  };

  return (
    <S.Wrapper>
      <ReactSelect
        options={reactSelectOptions}
        value={currentValue ? currentValue : null}
        onChange={(option) => {
          option && handleChange(option.value, option.label);
        }}
        styles={styles}
        isDisabled={disabled}
        isSearchable={false}
        components={{
          IndicatorSeparator: () => null,
        }}
        noOptionsMessage={() => NO_OPTION_JAPANESE}
        placeholder="選択してください"
      />
    </S.Wrapper>
  );
};

export default OrderTypeSelect;
