import axios from 'axios';

const useLogin = (
  email: string,
  password: string,
  afterLoginFn: () => void
) => {
  const handleLogin = () => {
    axios
      .post(`${process.env.REACT_APP_BASE_URL}/api/v1/auth`, {
        email,
        password,
      })
      .then((r) => {
        localStorage.setItem('pc_token', r.data.token);
        localStorage.setItem('use_cart', r.data.use_cart);
        localStorage.setItem('menu_functions', r.data.functions);
        localStorage.setItem('email', email);
        afterLoginFn();
      })
      .catch(() => {
        alert('ログインに失敗しました。IDかパスワードが間違っています。');
      });
  };

  return [handleLogin];
};

export default useLogin;
