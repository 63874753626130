import styled from 'styled-components';

export const Wrapper = styled.div`
  background: ${(props) => props.theme.palette.white};
  position: relative;
  height: calc(100vh - 80px);
`;

export const HeadContainer = styled.div`
  height: 86px;
  padding: 1.15rem 2.25rem;
  display: flex;
  align-items: center;
  justify-content: space-between;
  > div {
    > span {
      font-feature-settings: 'palt';
    }
  }
`;

export const FlexBaseline = styled.div`
  display: flex;
  align-items: baseline;
`;

export const Label = styled.label`
  margin-bottom: 2px;
  cursor: pointer;
  user-select: none;
`;

export const SelectContainer = styled.div`
  display: flex;
  justify-content: space-between;
  gap: 1rem;
`;

export const DisplayOptionWrapper = styled.div`
  display: flex;
`;

export const StoreroomSelectWrapper = styled.div`
  align-self: center;
  padding: 1rem;
`;

export const DateWrapper = styled.div`
  display: flex;
  align-items: center;
  position: relative;
`;

export const CheckboxContainer = styled.div`
  display: flex;
  align-items: center;
  width: 170px;
`;
