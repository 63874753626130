import { axiosInstance } from '@lib/pc/common/api/axiosConfig';
import { useQuery } from 'react-query';
import humps from 'humps';
import { SalesStore } from './type';

const useAllSalesStores = () => {
  const { data, refetch } = useQuery('settings_sales_stores', async () => {
    const data = await axiosInstance().get(
      `/api/v1/settings/sales_stores/all_sales_stores`
    );
    return data;
  });
  const allSalesStores = humps.camelizeKeys(
    data?.data.sales_stores
  ) as SalesStore[];
  return { allSalesStores, refetch };
};

export default useAllSalesStores;
