import { useMutation } from 'react-query';
import humps from 'humps';
import { AxiosError } from 'axios';
import { axiosInstance } from '@lib/pc/common/api/axiosConfig';
import { ErrorResponse } from '@lib/common/type';
import { MaterialsAndSupplier } from '@lib/pc/settings/material_master_edit/type';

const useUpdateMaterialAndSuppliersMutation = (
  materialId: number,
  handleSuccess: () => void,
  setErrMsg: (errMsg: string) => void
) => {
  return useMutation(
    async (params: MaterialsAndSupplier[]) => {
      return await axiosInstance().patch(
        `/api/v1/settings/materials_and_suppliers/${materialId}`,
        humps.decamelizeKeys({ MaterialAndSuppliersArray: params })
      );
    },
    {
      onSuccess: () => {
        handleSuccess();
      },
      onError: (error: AxiosError<ErrorResponse>) => {
        const message = error.response?.data.message || 'エラーが発生しました';
        setErrMsg(message);
      },
    }
  );
};

export default useUpdateMaterialAndSuppliersMutation;
