import { FC } from 'react';
import * as S from './SearchMenu.styles';
import { ReactComponent as FilterListIcon } from '@assets/icons/mobile/filter_list.svg';
import { ReactComponent as CloseIcon } from '@assets/icons/close.svg';
import { ReactComponent as DeleteIcon } from '@assets/icons/close_24px.svg';
import { ReactComponent as SearchIcon } from '@assets/icons/search.svg';

type Props = {
  title: string;
  searchParam: string;
  handleClose: () => void;
  handleChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
  left?: string;
  top?: string;
  deleteSearchParam: (searchParam: string) => void;
  handleClickDeleteIcon?: () => void;
};

const SearchMenu: FC<Props> = ({
  title,
  searchParam,
  handleClose,
  handleChange,
  left,
  top,
  deleteSearchParam,
  handleClickDeleteIcon,
}: Props) => {
  return (
    <S.Wrapper left={left} top={top}>
      <S.Header>
        <S.HeaderTitle>
          <FilterListIcon />
          <S.HeaderTitleText>{title}の絞り込み</S.HeaderTitleText>
        </S.HeaderTitle>
        <S.CloseIconWrapper>
          <CloseIcon
            onClick={() => {
              handleClose();
            }}
          />
        </S.CloseIconWrapper>
      </S.Header>
      <S.Main>
        <S.InputWrapper>
          <S.Icon>
            <SearchIcon />
          </S.Icon>
          <S.Input
            type="text"
            placeholder={`${title}` + 'で検索'}
            value={searchParam}
            onChange={handleChange}
          />
          {/* 検索エリアバツボタン */}
          {searchParam && (
            <S.DeleteIcon
              onClick={() => {
                deleteSearchParam('');
                handleClickDeleteIcon && handleClickDeleteIcon();
              }}
            >
              <DeleteIcon />
            </S.DeleteIcon>
          )}
        </S.InputWrapper>
      </S.Main>
    </S.Wrapper>
  );
};
export default SearchMenu;
