import { axiosInstance } from '@lib/pc/common/api/axiosConfig';
import { useQuery } from 'react-query';
import humps from 'humps';
import { ShippingGroup } from '@lib/common/type';

export const useShippingGroups = () => {
  const { data, refetch } = useQuery(['shipping_groups'], async () => {
    const data = await axiosInstance().get(`/api/v1/shipping_groups`);

    return data;
  });

  const shippingGroups =
    data && (humps.camelizeKeys(data.data.shipping_groups) as ShippingGroup[]);

  return { shippingGroups, refetch };
};

export default useShippingGroups;
