import { axiosInstance } from '@lib/pc/common/api/axiosConfig';
import { useQuery } from 'react-query';
import humps from 'humps';
import moment from 'moment';
import { Todo } from '@lib/common/type';

export const useWrappings = (date: Date) => {
  const { data, refetch } = useQuery(['wrappings', date], async () => {
    const data = await axiosInstance().get(
      `/api/v1/packagings?date=${moment(date).format('YYYYMMDD')}`
    );

    return data;
  });

  const wrappings = data && (humps.camelizeKeys(data.data) as Todo);

  return { wrappings, refetch };
};

export default useWrappings;
