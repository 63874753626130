import styled from 'styled-components';

export const FlexTableDivRow = styled.div`
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  color: ${(props) => props.theme.palette.primary.main};
  font-size: 1rem;
  font-weight: bold;
  height: 1rem;
  margin-top: 16px;
  gap: 0.5rem;
  svg path {
    fill: ${(props) => props.theme.palette.primary.main};
  }
`;

export const DeliveredDate = styled.div`
  align-items: center;
  justify-content: center;
  display: flex;
  font-weight: 700;
  font-size: 16px;
  margin-bottom: 0.5rem;
  justify-content: space-between;
  padding-top: 1rem;
  > label {
    text-align: center;
  }
`;

export const DeliveredDateLabel = styled.label`
  width: 45px;
  display: block;
  font-size: 15px;
  display: flex;
  align-items: center;
  margin-right: 5px;
`;

export const DateWrapper = styled.div`
  display: flex;
  position: relative;
  text-align: center;
  /* margin-left: 0.75rem; */
`;

export const InputWrapper = styled.span`
  /* display: flex; */
  position: relative;
  text-align: center;
  /* margin-left: 0.75rem; */
`;

export const DateInput = styled.input`
  width: 130px;
  height: 44px;
  vertical-align: middle;
  color: ${(props) => props.theme.palette.baseBlack};
  font-size: 1rem;
  line-height: 1.25rem;
  padding: 0.325rem;
  background: ${(props) => props.theme.palette.white};
  border: 1px solid rgba(0, 0, 0, 0.08);
  border-radius: 0.5rem;
  -webkit-appearance: none;
  margin-right: 5px;
  cursor: pointer;
  @media (max-width: 599px) {
    width: 15rem;
  }
  ::before {
    content: attr(placeholder);
    color: ${(props) => props.theme.palette.khaki};
    font-size: 12px;
  }

  ::-webkit-datetime-edit-fields-wrapper {
    display: none;
  }
`;

export const DInputContainer = styled.span<{
  center?: boolean;
}>`
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  border-radius: 0.5rem;
  /* gap: 6px; */
  /* width: 80px; */
  > label {
    font-weight: 700;
    font-size: 13px;
  }

  > input {
    height: 44px;
    width: 80px;
    /* max-width: 4rem; */
    /* max-width: 7.25rem; */
    /* width: 100%; */
    border-radius: 8px;
    text-align: ${(props) => (props.center ? 'center' : 'right')};
    padding-right: 12px;
    border: 1px solid rgba(0, 0, 0, 0.08);
    border-radius: 0.5rem;
    /* @media (max-width: 599px) {
      max-width: 4rem;
    } */
    //  placeholderの値を表示（firefox以外）
    :focus {
      outline: none;
    }
    :disabled {
      border: 2px solid #ffffff;
      background-color: transparent;
    }
  }
  > span {
    position: absolute;
    right: 0.3rem;
    top: 65%;
    font-size: 12px;
    margin-left: 4px;
    /* color: #6b675c; */
    color: ${(props) => props.theme.palette.khaki};
  }
`;

export const DeleteButton = styled.div`
  /* background: ${(props) => props.theme.palette.white}; */
  display: block;
  vertical-align: middle;
  align-items: center;
  /* padding: 0.7rem 0 0.7rem 0.2rem; */
  padding: 0.7rem 0;
  /* padding: 0.7rem; */
  position: relative;
  /* border-top: 2px solid #d3d3d3; */
  grid-auto-rows: 100%;
  grid-area: del-button;
  cursor: pointer;
`;

export const DeleteIconWrapper = styled.div`
  /* margin-top: 45px; */
`;
