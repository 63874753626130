import { useMutation } from 'react-query';
import humps from 'humps';
import { AxiosError } from 'axios';
import { axiosInstance } from '@lib/pc/common/api/axiosConfig';
import { Todo, ErrorResponse } from '@lib/common/type';

const useCreateProductsProcessMutation = (
  setSelectedProcessId: (selectedProcessId: number) => void,
  handleSuccess: () => void,
  setErrMsg: (errMsg: string) => void
) => {
  return useMutation(
    async (params: Todo) => {
      const data = await axiosInstance().post(
        `/api/v1/settings/products_processes`,
        humps.decamelizeKeys({
          productsProcess: {
            name: params.name,
            productId: params.productId,
            semiProducts: params.semiProducts,
            products: params.products,
            materials: params.materials,
            factoryLineIds: params.factoryLineIds,
          },
        })
      );
      const createdProcessId = data.data.product_process_id;
      setSelectedProcessId(createdProcessId);
    },
    {
      onSuccess: () => {
        handleSuccess();
      },
      onError: (error: AxiosError<ErrorResponse>) => {
        const message = error.response?.data.message || 'エラーが発生しました';
        setErrMsg(message);
      },
    }
  );
};

export default useCreateProductsProcessMutation;
