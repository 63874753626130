export const currentUserEmployee = {
  id: 0,
  name: '',
  email: '',
  code: '',
};

export const createdSupplierData = {
  id: 0,
  name: '',
  code: '',
  description: '',
  honorific: '',
  nameKana: '',
  orderType: '',
  roundingMethod: '',
  supplierDepartments: null,
  employeesAndSuppliersData: null,
  currentUserEmployees: [currentUserEmployee],
  employeeIds: null,
};
