import { useEffect, useState } from 'react';
import { useWindowScroll } from 'react-use';
import { useLocation } from 'react-router-dom';
import { WithHeader } from '@components/templates/mobile/WithHeader';
import moment from 'moment';
import * as S from './Picking.styles';
import { ReactComponent as CardboardBox } from '@assets/icons/mobile/cardboard_box.svg';
import RSingleDatePicker from '@components/elements/inputs/RSingleDatepicker/RSingleDatepicker';
import Overlay from '@components/elements/backdrops/Overlay/Overlay';
import PickingStatusMenu from '@components/modules/mobile/picking/PickingStatusMenu/PickingStatusMenu';
import PickingSearchForm from '@components/modules/mobile/picking/PickingSearchForm/PickingSearchForm';
import PickingList from '@components/modules/mobile/picking/PickingList/PickingList';
import PickingSelectStoreroom from '@components/modules/mobile/picking/PickingSelectStoreroom/PickingSelectStoreroom';
import StoreroomsForStocksModal from '@components/modules/mobile/storerooms_for_stocks_modal/StoreroomsForStocksModal/StoreroomsForStocksModal';
import useProducts from '@lib/mobile/common/hooks/useProducts';
import useStorerooms from '@lib/mobile/common/hooks/useStorerooms';
import { PickingState } from '@lib/mobile/picking_edit/type';
import { PRODUCT_KIND, Product, Storeroom, Todo } from '@lib/common/type';
import { PICKING_DUMMY_DATA } from './dummy';

export const PickingRoot = () => {
  const { state } = useLocation<PickingState>();
  const [date, setDate] = useState(moment());
  const [calendarActive, setCalenderActive] = useState(false);
  const formattedDate = moment(date).format('MM/DD');
  const formattedDayOfWeek = moment(date).format('(ddd)');
  // 検索文字state
  const [stateSearchChar, setSearchChar] = useState('');
  const [shippingDate, setShippingDate] = useState('');
  const [pickingStatus, setStatePickingStatus] = useState<number[]>([]);
  const [stateShippings, setShippings] = useState<Todo>([]);
  const [, setScrollPosition] = useState(0);
  // const [stateScrollPositionForEdit, setScrollPositionForEdit] = useState(0);
  const [stateProducts, setProducts] = useState<Product[]>();
  const [scannedProductName, setProductName] = useState('');
  const [stateFromEditComponent, setFromEditComponent] = useState(false);
  const [janCode, setJanCode] = useState('');
  // ステータス絞り込み
  const [statusActive, setStatusActive] = useState(false);
  // 絞り込みステータス
  const [selectedStatus, setSelectedStatus] = useState<number[]>([]);
  // スクロール位置state
  const { y: currentScrollPosition } = useWindowScroll();
  const { data: products } = useProducts();
  // 在庫室modal画面open用
  const [storeroomOpen, setStoreroomOpen] = useState(false);
  const [selectedStoreroom, setSelectedStoreroom] = useState('');
  // 在庫室検索文字列
  const [searchStoreroom, setSearchStoreroom] = useState('');
  const [filteredStoreroom, setFilteredStoreroom] = useState<Storeroom[]>([]);
  const [selectedStoreroomId, setSelectedStoreroomId] = useState<number | ''>(
    ''
  );
  const [storeroomName, setStoreroomName] = useState('');
  // チェック済以外表示チェックボックス
  const [completedCheckState, setCompletedCheckState] = useState(true);
  // 一覧画面からクリックしたときのピッキングステータス
  const [stateClickedPickingStatus, setStateClickedPickingStatus] = useState(0);

  // const [stateSelectedStoreroomId, setSelectedStoreroomId] = useState<
  //   number | ''
  // >('');

  const { data: storerooms } = useStorerooms(PRODUCT_KIND.PRODUCT);

  const shippings = PICKING_DUMMY_DATA;
  // const dummyData = PICKING_DUMMY_DATA;

  console.log(
    '---PickingRoot stateSelectedStoreroomId 1004---',
    selectedStoreroomId
  );
  console.log('---PickingRoot state 1414---', state);

  useEffect(() => {
    // console.log('---useEffect PickingRoot---');
    if (state === undefined) {
      setSearchChar('');
      setShippingDate('');
      setShippings(''); //TODO:バックエンドからの取得データへ置き換える
      setJanCode('');
      setStatePickingStatus([]);
      setScrollPosition(0);
      setProductName('');
      // setSelectedStoreroomId('');
      setCompletedCheckState(true);
      setStateClickedPickingStatus(0);
    } else {
      const {
        stateSearchChar,
        stateShippingDate,
        stateShippings,
        stateScrollPosition,
        stateJanCode,
        statePickingStatus,
        stateProducts,
        stateScannedProductName,
        stateSelectedStoreroomId,
        stateFromEditComponent,
        stateCompletedCheckState,
        stateClickedPickingStatus,
      } = state;
      window.scrollTo(0, stateScrollPosition);
      setScrollPosition(stateScrollPosition);
      setSearchChar(stateSearchChar);
      setShippingDate(stateShippingDate);
      setShippings(stateShippings);
      setJanCode(stateJanCode);
      setStatePickingStatus(statePickingStatus);
      setProducts(stateProducts);
      setProductName(stateScannedProductName);
      setSelectedStoreroomId(stateSelectedStoreroomId);
      setFromEditComponent(stateFromEditComponent);
      setCompletedCheckState(stateCompletedCheckState);
      setStateClickedPickingStatus(stateClickedPickingStatus);
    }
    products && setProducts(products);
  }, [shippings]);
  // }, [dummyData, state]);

  useEffect(() => {
    setProducts(products);
  }, [products]);

  const pickingState = {
    stateSearchChar: stateSearchChar,
    stateShippingDate: shippingDate,
    stateShippings: stateShippings,
    stateScrollPosition: currentScrollPosition,
    stateJanCode: janCode,
    statePickingStatus: pickingStatus,
    stateProducts: stateProducts,
    stateScannedProductName: scannedProductName,
    stateSelectedStoreroomId: selectedStoreroomId,
    stateFromEditComponent: stateFromEditComponent,
    stateCompletedCheckState: completedCheckState,
    stateClickedPickingStatus: stateClickedPickingStatus,
  };

  // // スクロール位置復元
  // useEffect(() => {
  //   window.scrollTo(0, stateScrollPosition);
  // }, [stateScrollPosition]);

  // console.log(
  //   '---page Piking stateScrollPosition 0942---',
  //   stateScrollPosition
  // );
  // console.log('---page Piking searchChar 1003---', stateSearchChar);
  // console.log('---page Piking statePickingStatus 1003---', statePickingStatus);

  // 場所検索機能
  useEffect(() => {
    if (!storerooms) return;
    if (searchStoreroom) {
      setFilteredStoreroom(
        storerooms.filter((sr) => sr.name.match(searchStoreroom))
      );
    } else {
      setFilteredStoreroom(storerooms);
    }
  }, [searchStoreroom, storerooms]);

  useEffect(() => {
    if (state?.stateSelectedStoreroomId) {
      const selectedStoreroom = storerooms?.filter(
        (s: Storeroom) => s.id === state.stateSelectedStoreroomId
      );
      selectedStoreroom && setStoreroomName(selectedStoreroom[0]?.name);
    } else if (state?.stateSelectedStoreroomId === '') {
      setSelectedStoreroom('');
      setStoreroomName('');
    }
  }, [state?.stateSelectedStoreroomId, storerooms]);

  if (storeroomOpen) {
    return (
      <StoreroomsForStocksModal
        searchStoreroom={searchStoreroom}
        setSearchStoreroom={setSearchStoreroom}
        storerooms={filteredStoreroom}
        setStoreroomOpen={setStoreroomOpen}
        setSelectedStoreroomId={setSelectedStoreroomId}
        setSelectedStoreroomName={setSelectedStoreroom}
        fromPicking={'picking'}
        pickingState={pickingState}
      />
    );
  }

  return (
    <WithHeader>
      <S.Wrapper>
        <S.Title>
          <S.TitleText>
            <S.TitleContainer>
              <S.IconContainer>
                <CardboardBox />
              </S.IconContainer>
              ピッキング（商品別）
            </S.TitleContainer>
          </S.TitleText>
        </S.Title>
        <S.Container>
          <S.StickyContainer>
            <S.StickyItem>
              <S.Title className="plannedTitle">
                <S.TitleContainer>
                  <S.PlanDate>{formattedDate}</S.PlanDate>
                  <S.PlanDayOfWeek>{formattedDayOfWeek}</S.PlanDayOfWeek>
                  <S.PlanText>の出荷予定</S.PlanText>
                </S.TitleContainer>
                <RSingleDatePicker
                  date={date}
                  setDate={setDate}
                  invalid={calendarActive}
                  fromMobile={true}
                  calendarBlue={true}
                />
              </S.Title>
              {/* 在庫室検索エリア */}
              {storerooms && (
                <PickingSelectStoreroom
                  setStoreroomOpen={setStoreroomOpen}
                  selectedStoreroom={selectedStoreroom}
                  storeroomName={storeroomName}
                  // storerooms={storerooms}
                  // storeroomId={state?.stateSelectedStoreroomId}
                />
              )}
              {/* 出荷先グループ、ステータス検索エリア */}
              <PickingSearchForm
                searchChar={stateSearchChar}
                setSearchChar={setSearchChar}
                statePickingStatus={pickingStatus}
                statusActive={statusActive}
                setStatusActive={setStatusActive}
              />
            </S.StickyItem>
            <PickingList pickingState={pickingState} />
          </S.StickyContainer>
        </S.Container>
      </S.Wrapper>
      {calendarActive && (
        <Overlay dark handleClick={() => setCalenderActive(false)} />
      )}
      {statusActive && (
        <PickingStatusMenu
          selectedStatus={selectedStatus}
          setSelectedStatus={setSelectedStatus}
          statePickingStatus={pickingStatus}
          setStatePickingStatus={setStatePickingStatus}
          useAllocation={shippings.useAllocation}
        />
      )}
      {statusActive && <Overlay handleClick={() => setStatusActive(false)} />}
    </WithHeader>
  );
};
