import { useEffect, useState } from 'react';
import * as S from './PhotoViewerModal.styles';
import * as tf from '@tensorflow/tfjs';
import type { Todo } from '@lib/common/type';
import type { BestBeforeDateByPic } from '@lib/pc/wrapping/types';
import Overlay from '@components/elements/backdrops/Overlay/Overlay';
import { CircularProgress } from '@material-ui/core';
import { checkValidDate } from '@lib/pc/wrapping/functions';
import moment from 'moment';

type Props = {
  newData: Todo;
  setNewData: (newData: Todo) => void;
  setPhotoGraphyModalActive: (photoGraphyModalActive: boolean) => void;
  duringFor: boolean;
  setDuringFor: (duringFor: boolean) => void;
  bestBeforeDateByPic: BestBeforeDateByPic[];
  setBestBeforeDateByPic: (bestBeforeDateByPic: BestBeforeDateByPic[]) => void;
  photoViewerModalActive: boolean;
  setPhotoViewerModalActive: (photoViewerModalActive: boolean) => void;
  pictureDataSrc: string;
  pictureTimestamp: number;
  pictureData: HTMLImageElement;
  addPictures?: boolean;
  bestBeforeDate: string;
  setSwipeModalActive: (swipeModalActive: boolean) => void;
};

const PhotoViewerModal = ({
  setPhotoGraphyModalActive,
  duringFor,
  setDuringFor,
  bestBeforeDateByPic,
  setBestBeforeDateByPic,
  photoViewerModalActive,
  setPhotoViewerModalActive,
  pictureDataSrc,
  pictureTimestamp,
  pictureData,
  addPictures,
  bestBeforeDate,
  setSwipeModalActive,
}: Props) => {
  const [expiryDates, setExpiryDates] = useState([]);
  const [coordinates, setCoordinates] = useState([]);
  const [headerText, setHeaderText] = useState('');

  useEffect(() => {
    if (addPictures) {
      // 初期化
      setHeaderText('');
      // 日付認識処理
      dateRecognition();
    }
  }, [pictureTimestamp]);

  function bbox_iou(box1: Todo, box2: Todo) {
    const xs1 = Math.max(box1[0], box2[0]);
    const ys1 = Math.max(box1[1], box2[1]);
    const xs2 = Math.min(box1[0] + box1[2], box2[0] + box2[2]);
    const ys2 = Math.min(box1[1] + box1[3], box2[1] + box2[3]);
    const intersections = Math.max(ys2 - ys1, 0) * Math.max(xs2 - xs1, 0);
    const unions = box1[2] * box1[3] + box2[2] * box2[3] - intersections;
    const ious = intersections / unions;
    return ious;
  }

  // 日付認識処理
  async function dateRecognition() {
    setDuringFor(true);
    await tf.ready().then(() => {
      const img = document.getElementById('picture') as HTMLImageElement;
      const board = document.getElementById('board') as HTMLCanvasElement;
      const ctx = board?.getContext('2d');
      // 撮影した写真をimageへ読み込み
      img.src = pictureDataSrc;
      board.width = pictureData.width;
      board.height = pictureData.height;
      // canvasに画像を描画
      img.addEventListener('load', function () {
        ctx?.drawImage(img, 0, 0, pictureData.width, pictureData.height);
      });
      // モデルの読み込み
      tf.loadGraphModel('indexeddb://my-model').then((model) => {
        let inputTensor = tf.browser.fromPixels(board, 3);
        inputTensor = inputTensor.cast('float32').div(tf.scalar(255));
        inputTensor = inputTensor.expandDims();
        inputTensor = inputTensor.transpose([0, 3, 1, 2]);
        model.executeAsync(inputTensor).then((output: Todo) => {
          tf.tidy(() => {
            const model_out = output.arraySync();
            let tmp_list: Todo = [];
            const pre_iou = [];
            const wkCoordinates: Todo = [];
            const prov_Coordinates: Todo = [];
            for (let i = 0; i < model_out[0].length; i++) {
              const box = model_out[0][i].slice(0, 4);
              const probability = model_out[0][i].slice(4, 5);
              const classification = model_out[0][i].slice(5, 16);
              tmp_list = [];
              const max = classification.reduce((a: Todo, b: Todo) => {
                return Math.max(a, b);
              });
              const index = classification.indexOf(max);
              // 日付が6桁以上/以下で取得できてしまう時はココの比較値を調整
              if (classification[index] * probability > 0.6) {
                const dx = box[2] / 2;
                const dy = box[3] / 2;
                tmp_list.push(box[0] - dx);
                tmp_list.push(box[1] - dy);
                tmp_list.push(box[2]);
                tmp_list.push(box[3]);
                tmp_list.push(index);
                tmp_list.push(classification[index] * probability);
                pre_iou.push(tmp_list);
              }
            }
            pre_iou.sort((a, b) => {
              return a[5] - b[5];
            });
            tmp_list = [];
            const boxes: Todo = [];
            for (let i = 0; i < pre_iou.length; i++) {
              let flag = 0;
              for (let j = i + 1; j < pre_iou.length; j++) {
                const iou = bbox_iou(pre_iou[i], pre_iou[j]);
                // 日付が6桁以上/以下で取得できてしまう時はココの比較値を調整
                if (iou > 0.6) {
                  flag = 1;
                  break;
                }
              }
              if (flag === 0) {
                if (pre_iou[i][4] === 10) {
                  boxes.push(pre_iou[i]);
                  wkCoordinates.push(
                    pre_iou[i][0] +
                      ',' +
                      pre_iou[i][1] +
                      ',' +
                      pre_iou[i][2] +
                      ',' +
                      pre_iou[i][3]
                  );
                } else {
                  tmp_list.push(pre_iou[i]);
                }
              }
            }
            for (let i = 0; i < boxes.length; i++) {
              const numbers = tmp_list.filter((value: Todo) => {
                return (
                  value[0] > boxes[i][0] &&
                  value[0] < boxes[i][0] + boxes[i][2] &&
                  value[1] > boxes[i][1] &&
                  value[1] < boxes[i][1] + boxes[i][3]
                );
              });
              numbers.sort((a: Todo, b: Todo) => {
                return a[0] - b[0];
              });
              boxes[i] = numbers;
            }
            const expiry_dates: Todo = [];
            const tmp_Dates: Todo = [];
            boxes.map((box: Todo) => {
              tmp_list = [];
              box.map((b: Todo) => {
                if (b[4] === 9) {
                  tmp_list.push(0);
                } else {
                  tmp_list.push(b[4] + 1);
                }
              });
              tmp_Dates.push(tmp_list.join(''));
            });

            // 撮影しなくても機能開発できるように環境変数で画像読み取り結果を上書きできるようにしておく
            if (process.env.REACT_APP_RECOGNITION_DEBUG_DATE) {
              wkCoordinates.splice(0);
              tmp_Dates.splice(0);
              wkCoordinates.push('10,10,150,150');
              tmp_Dates.push(process.env.REACT_APP_RECOGNITION_DEBUG_DATE);
            }

            // 包装実績で入力された日付と認識した日付を比較し、バウンディングボックスの色を選別する
            if (tmp_Dates.length === 0) {
              // 賞味期限が写っていない場合
              // 日付の取得
              expiry_dates.push('');
              // 座標の取得
              prov_Coordinates.push('');
              setHeaderText('NG');
              // 認識した日付・座標の設定
              setCoordinates(prov_Coordinates);
              setExpiryDates(expiry_dates);
            } else {
              // 賞味期限が写っている場合
              for (let i = 0; i < tmp_Dates.length; i++) {
                // 包装実績で入力された賞味期限
                let strEditDate = bestBeforeDate
                  ? bestBeforeDate.replace(/-/g, '')
                  : '';
                // 比較対象がYYMMDDの場合、桁を揃える
                if (tmp_Dates[i].length === 6) {
                  strEditDate = strEditDate.substring(2);
                }
                // 賞味期限日妥当性チェック
                const checkResult = checkValidDate(tmp_Dates[i]);
                if (checkResult) {
                  // TRUE:日付として認識できる文字列が取得できている
                  // 日付の取得
                  expiry_dates.push('20' + tmp_Dates[i]);
                  const xywh = String(wkCoordinates[i]).split(',');
                  // バウンディングボックスstyle
                  let strokeStylestr: string;
                  if (!ctx) throw new Error('Context Error');
                  if (strEditDate === tmp_Dates[i]) {
                    // 日付OK
                    strokeStylestr = 'rgb(6, 148, 90, 0.8)';
                  } else {
                    // 日付NG
                    strokeStylestr = 'rgb(255, 0, 0, 0.8)';
                    setHeaderText('NG');
                  }
                  // バウンディングボックス表示
                  ctx.strokeStyle = strokeStylestr;
                  ctx.strokeRect(
                    Number(xywh[0]),
                    Number(xywh[1]),
                    Number(xywh[2]),
                    Number(xywh[3])
                  );
                  // 座標の取得
                  prov_Coordinates.push(wkCoordinates[i]);
                } else {
                  // 日付の取得
                  expiry_dates.push('');
                  // 座標の取得
                  prov_Coordinates.push('');
                  setHeaderText('NG');
                }
                // 認識した日付・座標の設定
                setCoordinates(prov_Coordinates);
                setExpiryDates(expiry_dates);
              }
            }
            setDuringFor(false);
          });
          // TensorFlow.jsオブジェクトを解放する
          tf.dispose([inputTensor, output, model]);
        });
        // 撮影日時を表示する
        if (!ctx) throw new Error('Context Error');
        // 塗りつぶし
        ctx.fillStyle = 'rgb(255, 255, 255)';
        ctx.fillRect(325, 455, 190, 30);
        // テキスト
        ctx.fillStyle = 'rgb(107, 103, 92)';
        ctx.font = '12px nomal, "Noto Sans JP"';
        ctx.textAlign = 'right';
        ctx.fillText(
          moment(new Date()).format('YYYY/MM/DD HH:mm:ss') + '  撮影',
          472,
          472
        );
        if (duringFor === false && headerText === '') {
          setHeaderText('OK');
        }
      });
    });
  }

  // キャンセルボタン実行イベント
  function CancelEvent() {
    const img = document.getElementById('picture') as HTMLImageElement;
    const board = document.getElementById('board') as HTMLCanvasElement;
    const ctx = board?.getContext('2d');
    // canvasのクリア
    img.src = '';
    board.width = 0;
    board.height = 0;
    ctx?.clearRect(0, 0, pictureData.width, pictureData.height);
    // 初期化
    setHeaderText('');
    // PhotoViewer画面をClose
    setPhotoViewerModalActive(false);
    setPhotoGraphyModalActive(true);
  }

  // 撮影直後、OKボタン実行イベント
  function UploadEvent() {
    const img = document.getElementById('picture') as HTMLImageElement;
    const board = document.getElementById('board') as HTMLCanvasElement;
    const ctx = board?.getContext('2d');
    const imageSrc = board.toDataURL('image/jpeg');

    const dateByPic: BestBeforeDateByPic = {
      id: null,
      image: imageSrc,
      expiryDates: expiryDates,
      coordinates: coordinates,
    };

    setBestBeforeDateByPic([...bestBeforeDateByPic, dateByPic]);

    // canvasのクリア
    URL.revokeObjectURL(imageSrc);
    img.src = '';
    board.width = 0;
    board.height = 0;
    ctx?.clearRect(0, 0, pictureData.width, pictureData.height);
    // 初期化
    setHeaderText('');
    // PhotoViewer画面をClose
    setPhotoViewerModalActive(false);
    // swiper画面を表示させる
    setSwipeModalActive(true);
  }

  return (
    <>
      <S.Wrapper open={photoViewerModalActive}>
        <S.HeadContainer>
          <S.HeadingWrapper status={headerText}>
            <S.Title>{headerText}</S.Title>
            <S.ButtonContainer></S.ButtonContainer>
          </S.HeadingWrapper>
        </S.HeadContainer>
        <S.CanvasBoard status={headerText}>
          {duringFor && (
            <>
              <Overlay zIndex={10002} dark />
              <S.CircularIconWrapperOuter>
                <S.CircularIconWrapper>
                  <CircularProgress style={{ color: '#64b2f9' }} />
                </S.CircularIconWrapper>
              </S.CircularIconWrapperOuter>
            </>
          )}
          <img id="picture"></img>
          <canvas id="board"></canvas>
        </S.CanvasBoard>
        <S.FooterContainer>
          <S.FooterWrapper>
            <S.FooterButtonContainer>
              <S.CancelButton onClick={() => CancelEvent()}>
                キャンセル
              </S.CancelButton>
              <S.OkButton onClick={() => UploadEvent()}>OK</S.OkButton>
            </S.FooterButtonContainer>
          </S.FooterWrapper>
        </S.FooterContainer>
      </S.Wrapper>
    </>
  );
};

export default PhotoViewerModal;
