import { useMutation } from 'react-query';
import { axiosInstance } from '@lib/mobile/common/api/axiosConfig';

// StockAmountDetailテーブルのレコード削除;
const useDeleteStockMutation = (
  actualSemiProductOrProductStockId: number | undefined,
  stockKind: string,
  amountDetailId: number | null,
  handleDeleteSuccess: () => void
) => {
  return useMutation(
    async () => {
      return await axiosInstance().delete(
        `/api/v1/stocks/${actualSemiProductOrProductStockId}?stock_kind=${stockKind}&amount_detail_id=${amountDetailId}`
      );
    },
    {
      onSuccess: () => {
        handleDeleteSuccess();
      },
      onError: () => alert('エラーが発生しました'),
    }
  );
};

export default useDeleteStockMutation;
