import styled from 'styled-components';

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  min-height: 100vh;
`;

export const Body = styled.div``;

export const TitleBarWrapper = styled.div``;

export const StockFormWrapper = styled.div``;

export const Footer = styled.div`
  position: sticky;
  bottom: 0;
  margin-top: auto;
  padding: 1rem;
  background: ${(props) => props.theme.palette.beige};
  box-shadow: 0px 6px 10px rgba(0, 0, 0, 0.07), 0px 1px 18px rgba(0, 0, 0, 0.06),
    0px 3px 5px rgba(0, 0, 0, 0.1);
`;

export const SubmitButton = styled.button`
  display: block;
  width: 100%;
  padding: 0;
  height: 3.25rem;
  border-radius: 0.5rem;
  background: #ffd130;
  font-weight: bold;
  color: ${(props) => props.theme.palette.baseBlack};
`;
