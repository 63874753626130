import { FC } from 'react';
import * as S from './DividerWithDot.styles';
type Props = {
  borderColor: string;
  dotColor?: string;
  disableDot?: boolean;
  ThinLine?: boolean;
};

export const DividerWithDot: FC<Props> = ({
  borderColor = '#000',
  dotColor,
  disableDot = false,
  ThinLine = false,
}: Props) => {
  return (
    <S.Wrapper color={borderColor}>
      {ThinLine ? (
        <S.DotContainer color={borderColor} borderTopWidth={'1px'} />
      ) : (
        <S.DotContainer color={borderColor} />
      )}
      {!disableDot && <S.Dot color={dotColor ? dotColor : borderColor} />}
    </S.Wrapper>
  );
};
