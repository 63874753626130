import { FC, useEffect, useRef } from 'react';
import { isIPad13 } from 'react-device-detect';
import * as S from './SidebarInput.styles';
import CaseSelect from '@components/modules/common/CaseSelect/CaseSelect';
import LABEL_OF_AMOUNT_MAP from '@components/modules/common/LabelOfAmountMap/LabelOfAmountMap';
import { ReactComponent as DeleteIcon } from '@assets/icons/delete.svg';
import type { Todo, Case } from '@lib/common/type';
import CustomNumPad from '@lib/pc/common/num_pad/customNumPad';
import {
  shouldDisplayCustomNumPad,
  normalizeZeros,
} from '@lib/stock_status/functions';
import { isIPhone } from '@lib/common/functions';

type Props = {
  index: number;
  type: 'packaged' | 'unpackaged';
  stockDateLabel?: string;
  labelOfAmount: string;
  newProductionOrShippingDate: Todo;
  onChangeBaraAmount: (newBaraAmount: string) => void;
  onChangeCaseAmount: (newCaseAmount: string) => void;
  onChangeNewDate: (newProductionOrShippingDate: Todo) => void;
  onChangeNewCase: (newCaseId: number) => void;
  inputAmountParams: Todo;
  setInputAmountParams: (inputAmountParams: Todo) => void;
  handleRemoveField: (index: number, inputAmountParams: Todo) => void;
  cases: Case[] | undefined;
  caseSelectWidth?: string;
  caseLabelWidth?: string;
  useScanning?: boolean;
};

const InputComponent: FC<Props> = ({
  index,
  type,
  stockDateLabel,
  labelOfAmount,
  newProductionOrShippingDate,
  onChangeBaraAmount,
  onChangeCaseAmount,
  onChangeNewDate,
  onChangeNewCase,
  inputAmountParams,
  setInputAmountParams,
  handleRemoveField,
  cases,
  caseSelectWidth,
  caseLabelWidth,
  useScanning,
}: Props) => {
  const bestBeforeDateRef = useRef<HTMLInputElement>(null);

  // ここでは半製品、完成品判定済みのcasesのみ存在する
  const setPiecesPerCase = (value: Todo) => {
    const filtered = cases && cases.find((c) => c.caseId === value);
    if (filtered) {
      setInputAmountParams((newParams: Todo) => {
        const oldDetail = newParams[index];
        const newDetail = {
          ...oldDetail,
          piecesPerBox: filtered?.piecesPerBox,
        };
        newParams[index] = newDetail;
        return newParams;
      });
    }
  };

  // ケース選択時
  const setSelectedCase = (event: number) => {
    onChangeNewCase?.(event);
    setPiecesPerCase(event);
  };

  const clearDate = () => {
    // iPadOSでクリアボタンを効かせるためのコード
    setTimeout(() => {
      if (bestBeforeDateRef.current)
        bestBeforeDateRef.current.defaultValue = '';
    }, 100);
  };

  // numPadクリックでlocalStorageにpiecesPerBox設定
  const handleClick = (displayPiecesPerBox: number) => {
    localStorage.setItem(
      'selectedCasePiecesPerCase',
      displayPiecesPerBox.toString()
    );
  };

  // iPadOS、初期表示時クリアボタンを効かせるためのコード
  useEffect(() => {
    setTimeout(() => {
      if (bestBeforeDateRef.current)
        bestBeforeDateRef.current.defaultValue = '';
    }, 100);
  }, []);

  return (
    <>
      <S.DContainer>
        <S.DExpirationDateAndCase>
          <S.DExpirationDate>
            <S.DateWrapper>
              <S.ProductionOrShippingDateLabel
                htmlFor={`new-production-date-${index}`}
              >
                {/* 完成品の場合：賞味期限、半製品の場合：製造日時*/}
                {stockDateLabel
                  ? stockDateLabel
                  : type === 'packaged'
                  ? '賞味期限'
                  : '製造日時'}
              </S.ProductionOrShippingDateLabel>
              <S.Input
                ref={bestBeforeDateRef}
                type="date"
                name="newDate"
                id={`new-production-date-${index}`}
                value={
                  newProductionOrShippingDate ? newProductionOrShippingDate : ''
                }
                onChange={(event) => {
                  onChangeNewDate?.(event.target.value);
                  clearDate();
                }}
                pattern="\d{4}-\d{2}-\d{2}"
                autoComplete="off"
                max="9999-12-31"
                // labelLength={stockDateLabel ? stockDateLabel.length > 4 : false}
              />
              <S.DeleteButton
                onClick={() => {
                  if (!isIPad13) {
                    handleRemoveField(index, inputAmountParams);
                  }
                }}
                onTouchStart={() => {
                  if (isIPad13) {
                    handleRemoveField(index, inputAmountParams);
                  }
                }}
              >
                <DeleteIcon />
              </S.DeleteButton>
            </S.DateWrapper>
            {cases && cases?.length > 1 && (
              <CaseSelect
                cases={cases}
                value={
                  inputAmountParams[index]?.caseId
                    ? inputAmountParams[index]?.caseId
                    : cases[0].caseId
                }
                labelMarginRight="1rem"
                disabled={
                  Number(inputAmountParams[index]?.case) > 0 ||
                  Number(inputAmountParams[index]?.bara) > 0
                }
                onChange={(value) => setSelectedCase(value)}
                pcWidth={caseSelectWidth}
                caseLabelWidth={caseLabelWidth}
              />
            )}
          </S.DExpirationDate>

          <div>
            <S.DRightBottomWrapper>
              {/* ケース */}
              <S.DInputContainer center={true}>
                <label htmlFor={`new-stock-case-${index}`}>ケース</label>
                <input
                  id={`new-stock-case-${index}`}
                  name={`new-stock-case-${index}`}
                  value={
                    inputAmountParams[index]?.case
                      ? inputAmountParams[index]?.case
                      : ''
                  }
                  onChange={(event) => onChangeCaseAmount?.(event.target.value)}
                  type="text"
                  pattern="^[1-9][0-9]*$"
                  inputMode="numeric"
                  autoComplete="off"
                  disabled={
                    inputAmountParams[index]?.piecesPerBox === 1 ||
                    inputAmountParams[index]?.piecesPerBox === ''
                  }
                />
                <span>
                  ×{inputAmountParams[index]?.piecesPerBox}
                  {labelOfAmount ? LABEL_OF_AMOUNT_MAP[labelOfAmount] : '個'}
                </span>
                {inputAmountParams[index]?.piecesPerBox !== 1 &&
                  shouldDisplayCustomNumPad(
                    isIPad13 || isIPhone(),
                    useScanning || false
                  ) && (
                    <div
                      onClick={() =>
                        handleClick(inputAmountParams[index]?.piecesPerBox)
                      }
                    >
                      <CustomNumPad
                        top="-3.1rem"
                        right="-3.4rem"
                        width="110px"
                        height="2.8rem"
                        name={`new-stock-case-${index}`}
                        value={
                          inputAmountParams[index]?.case
                            ? inputAmountParams[index]?.case
                            : ''
                        }
                        onChange={(event) =>
                          onChangeCaseAmount?.(event.target.value)
                        }
                        flexEnd={isIPhone()}
                      />
                    </div>
                  )}
              </S.DInputContainer>
              <S.DSymbol>+</S.DSymbol>
              {/* バラ個数 */}
              <S.DInputContainer>
                <label htmlFor={`new-stock-bara-${index}`}>
                  {labelOfAmount ? LABEL_OF_AMOUNT_MAP[labelOfAmount] : '個'}
                </label>
                <input
                  id={`new-stock-bara-${index}`}
                  name={`new-stock-bara-${index}`}
                  value={
                    inputAmountParams[index]?.bara
                      ? normalizeZeros(inputAmountParams[index]?.bara)
                      : ''
                  }
                  onChange={(event) => onChangeBaraAmount?.(event.target.value)}
                  onFocus={() =>
                    handleClick(inputAmountParams[index]?.piecesPerBox)
                  }
                  type="text"
                  pattern="^[1-9][0-9]*$"
                  inputMode="numeric"
                  autoComplete="off"
                />
                {shouldDisplayCustomNumPad(
                  isIPad13 || isIPhone(),
                  useScanning || false
                ) && (
                  <div
                    onClick={() =>
                      handleClick(inputAmountParams[index]?.piecesPerBox)
                    }
                  >
                    <CustomNumPad
                      top="-3.1rem"
                      right="-3.4rem"
                      width="110px"
                      height="2.8rem"
                      name={`new-stock-bara-${index}`}
                      value={
                        inputAmountParams[index]?.bara
                          ? normalizeZeros(inputAmountParams[index]?.bara)
                          : ''
                      }
                      onChange={(event) =>
                        onChangeBaraAmount?.(event.target.value)
                      }
                      flexEnd={isIPhone()}
                    />
                  </div>
                )}
              </S.DInputContainer>
              <S.DSymbol>=</S.DSymbol>
              <S.DInputContainer>
                <label htmlFor="new-stock-total">合計</label>
                <input
                  id="new-stock-total"
                  value={
                    (!inputAmountParams[index]?.case &&
                      !inputAmountParams[index]?.bara) ||
                    !normalizeZeros(inputAmountParams[index]?.total)
                      ? ''
                      : Number(inputAmountParams[index]?.total).toLocaleString()
                  }
                  disabled
                />
              </S.DInputContainer>
            </S.DRightBottomWrapper>
          </div>
        </S.DExpirationDateAndCase>
      </S.DContainer>
    </>
  );
};

export default InputComponent;
