import { FC, useEffect } from 'react';
import type {
  Material,
  OptionType,
  Todo,
  scannedMaterial,
} from '@lib/common/type';
import ReactSelect from 'react-select';
import {
  removeSpaces,
  useDeviceOrientationAndSize,
} from '@lib/common/functions';
import { NO_OPTION_JAPANESE } from '@lib/pc/common/constants';

type Props = {
  materials: Material[] | undefined;
  handleChangeMaterial: (e: number) => void;
  value?: number | undefined;
  disabled?: boolean;
  width?: string | undefined;
  maxWidth?: string | undefined;
  height?: string | undefined;
  setEditMaterialName?: (editMaterialName: string) => void;
  setSelectedMaterialLabelOfAmount?: (
    selectedMaterialLabelOfAmount: string
  ) => void;
  maxHeight?: string | undefined;
  marginTop?: string | undefined;
  notDisplayCode?: boolean;
  menuShouldBlockScroll?: boolean;
  menuShouldScrollIntoView?: boolean;
  scannedMaterial?: scannedMaterial;
};

const MaterialSelect: FC<Props> = ({
  materials,
  handleChangeMaterial,
  value,
  disabled,
  width,
  maxWidth,
  marginTop,
  height,
  setEditMaterialName,
  setSelectedMaterialLabelOfAmount,
  maxHeight,
  notDisplayCode,
  menuShouldBlockScroll,
  menuShouldScrollIntoView,
  scannedMaterial,
}: Props) => {
  const { deviceType } = useDeviceOrientationAndSize();
  const isDesktop = deviceType === 'desktop';

  const toReactSelectOptions = (
    options: {
      id: number;
      materialCode: string;
      name: string;
      labelOfAmount: string;
    }[]
  ) =>
    options?.map(({ id, materialCode, name, labelOfAmount }) => ({
      value: id,
      label: notDisplayCode
        ? removeSpaces(name)
        : removeSpaces(materialCode + ':' + name),
      code: materialCode,
      labelOfAmount: labelOfAmount,
    }));

  const reactSelectOptions = materials && toReactSelectOptions(materials);
  const selectedOption =
    scannedMaterial?.materialId &&
    reactSelectOptions &&
    reactSelectOptions.find(
      (option) => option.value === scannedMaterial.materialId
    );
  const currentValue = reactSelectOptions?.find((o) => o.value === value);

  const filterOption = (
    option: { label: string; data: OptionType },
    inputValue: string
  ): boolean => {
    const { label, data } = option;
    const { code } = data;
    return (
      label.toLowerCase().includes(inputValue.toLowerCase()) ||
      code?.toLowerCase().includes(inputValue.toLowerCase())
    );
  };

  useEffect(() => {
    if (currentValue) {
      setEditMaterialName && setEditMaterialName(currentValue.label);
      setSelectedMaterialLabelOfAmount &&
        setSelectedMaterialLabelOfAmount(currentValue.labelOfAmount);
    } else if (scannedMaterial && scannedMaterial.materialId !== null) {
      handleChangeMaterial(scannedMaterial.materialId);
    }
  }, [currentValue, selectedOption]);

  const styles = {
    control: (baseStyles: Todo) => ({
      ...baseStyles,
      minHeight: height ? height : '4rem',
      minWidth: '100%',
      width: width ? width : 'auto',
      maxWidth: maxWidth ? maxWidth : '',
      height: height ? height : '',
      borderRadius: '0.5rem',
      fontWeight: 400,
      textAlign: 'left',
      borderStyle: 'hidden',
      outline: '0.5px solid rgba(0,0,0,0.08)',
      cursor: 'pointer',
      boxShadow: 'none',
      backgroundColor: disabled ? '' : 'white',
      marginTop: marginTop ? marginTop : '8px',
      '@media (max-width: 599px)': {
        width: '16rem',
      },
    }),
    menu: (baseStyles: Todo) => ({
      ...baseStyles,
      // top: '37px',
      marginTop: '0px',
      zIndex: 1002,
    }),
    singleValue: (baseStyles: Todo) => ({
      ...baseStyles,
      overflow: 'initial',
    }),
    option: (baseStyles: Todo) => ({
      ...baseStyles,
      textAlign: 'left',
      fontWeight: 400,
      fontSize: '15px',
    }),
    menuList: (baseStyles: Todo) => ({
      ...baseStyles,
      maxHeight: maxHeight ? maxHeight : '',
      zIndex: 1002,
    }),
  };

  return (
    <ReactSelect
      menuShouldBlockScroll={menuShouldBlockScroll ? true : false}
      menuShouldScrollIntoView={menuShouldScrollIntoView ? false : true}
      options={reactSelectOptions}
      onChange={(option) => {
        option && handleChangeMaterial(option.value);
      }}
      value={currentValue ? currentValue : null}
      styles={styles}
      isDisabled={disabled}
      isSearchable={isDesktop}
      filterOption={filterOption}
      components={{
        IndicatorSeparator: () => null,
      }}
      noOptionsMessage={() => NO_OPTION_JAPANESE}
      placeholder="選択してください"
    />
  );
};

export default MaterialSelect;
