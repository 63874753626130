import { useMutation } from 'react-query';
import humps from 'humps';
import { AxiosError } from 'axios';
import { axiosInstance } from '@lib/pc/common/api/axiosConfig';
import { ErrorResponse } from '@lib/common/type';
import type { ProductAndCasesForUpdate } from '@lib/pc/settings/product_master_setting/type';

const useUpdateProductAndCasesMutation = (
  productId: number,
  handleSuccess: () => void,
  setErrMsg: (errMsg: string) => void
) => {
  return useMutation(
    async (params: ProductAndCasesForUpdate[]) => {
      return await axiosInstance().patch(
        `/api/v1/settings/products_and_cases/${productId}`,
        humps.decamelizeKeys({ ProductAndCasesArray: params })
      );
    },
    {
      onSuccess: () => {
        handleSuccess();
      },
      onError: (error: AxiosError<ErrorResponse>) => {
        const message = error.response?.data.message || 'エラーが発生しました';
        setErrMsg(message);
      },
    }
  );
};

export default useUpdateProductAndCasesMutation;
