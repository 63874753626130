import { FC } from 'react';
import ReactSelect from 'react-select';
import * as S from './SupplierSelect.styles';
import type { Supplier, Todo } from '@lib/common/type';
import { useDeviceOrientationAndSize } from '@lib/common/functions';
import { NO_OPTION_JAPANESE } from '@lib/pc/common/constants';

type Props = {
  suppliers: Supplier[];
  value: number | undefined;
  setSelectedSupplierId: (selectedSupplierId: number) => void;
  onChange?: (selectedCaseId: number, index?: number) => void;
  disabled?: boolean;
};

const SupplierSelect: FC<Props> = ({
  suppliers,
  value,
  setSelectedSupplierId,
  onChange,
  disabled,
}: Props) => {
  const { deviceType } = useDeviceOrientationAndSize();
  const isDesktop = deviceType === 'desktop';

  const handleChangeSupplier = (e: number) => {
    setSelectedSupplierId(e);
  };

  const toReactSelectOptions = (options: { id: number; name: string }[]) =>
    options.map(({ id, name }) => ({
      value: id,
      label: name,
    }));

  const reactSelectOptions = suppliers && toReactSelectOptions(suppliers);
  const currentValue = reactSelectOptions?.find((o) => o.value === value);

  const styles = {
    control: (baseStyles: Todo) => ({
      ...baseStyles,
      minWidth: '100%',
      width: 'auto',
      maxWidth: '880px',
      height: '44px',
      borderRadius: '0.5rem',
      fontWeight: 400,
      textAlign: 'left',
      borderStyle: 'hidden',
      outline: '0.5px solid rgba(0,0,0,0.08)',
      backgroundColor: disabled ? '' : 'white',
      cursor: 'pointer',
      boxShadow: 'none',
      '@media (max-width: 599px)': {
        width: '16rem',
      },
    }),
    menu: (baseStyles: Todo) => ({
      ...baseStyles,
      top: '37px',
      zIndex: 1002,
    }),
    container: (baseStyles: Todo) => ({
      ...baseStyles,
      width: '100%',
    }),
    option: (baseStyles: Todo) => ({
      ...baseStyles,
      textAlign: 'left',
      fontWeight: 400,
      fontSize: '15px',
    }),
    menuList: (baseStyles: Todo) => ({
      ...baseStyles,
      maxHeight: '200px',
    }),
  };

  return (
    <S.Wrapper>
      <ReactSelect
        options={reactSelectOptions}
        value={currentValue ? currentValue : null}
        onChange={(option) => {
          option && handleChangeSupplier(option.value);
          option && onChange?.(option.value);
        }}
        styles={styles}
        isDisabled={disabled}
        isSearchable={isDesktop}
        components={{
          IndicatorSeparator: () => null,
        }}
        noOptionsMessage={() => NO_OPTION_JAPANESE}
        placeholder="仕入先の選択"
      />
    </S.Wrapper>
  );
};

export default SupplierSelect;
