import { useEffect, useState } from 'react';
import * as S from './SupplierContents.styles';
import ChevronLeft from '@assets/icons/chevron_left';
import { ReactComponent as CircleCloseIcon } from '@assets/icons/circle_close.svg';
import { Button } from '@components/elements/buttons/Button';
import CommonReactSelect from '@components/modules/common/CommonReactSelect/CommonReactSelect';
import DeleteConfirmModal from '@components/modules/common/DeleteConfirmModal/DeleteConfirmModal';
import ErrorMsgPopUp from '@components/modules/common/ErrorMsgPopUp/ErrorMsgPopUp';
import Overlay from '@components/elements/backdrops/Overlay/Overlay';
import HonorificSelect from '@components/modules/pc/purchase_orders_edit/HonorificSelect/HonorificSelect';
import { Todo } from '@lib/common/type';
import {
  CurrentUserEmployee,
  EmployeesAndSuppliersData,
  EmployeesForUpdate,
  RoundingMethod,
  SupplierEdit,
} from '@lib/pc/settings/supplier_edit/type';
import { Honorific } from '@lib/pc/purchase_orders_edit/type';
import useUpdateSupplierMutation from '@lib/pc/settings/supplier_edit/useUpdateSupplier';
import useCreateSupplierMutation from '@lib/pc/settings/supplier_create/useCreateSupplier';

type Props = {
  newData: SupplierEdit;
  setNewData: (newData: Todo) => void;
  updating: boolean;
  setUpdating: (updating: boolean) => void;
  enableToSave: boolean;
  setEnableToSave: (enableToSave: boolean) => void;
  setIsChanged: (isChanged: boolean) => void;
  onClickGoBack: () => void;
  refetch?: () => void;
  setUpdatePopUp: (updatePopUp: boolean) => void;
  createdData?: Todo;
  setCreatedData?: (createdData: Todo) => void;
};

const SupplierContents = ({
  newData,
  setNewData,
  updating,
  setUpdating,
  enableToSave,
  setEnableToSave,
  setIsChanged,
  onClickGoBack,
  refetch,
  setUpdatePopUp,
  setCreatedData,
}: Props) => {
  const [errMsg, setErrMsg] = useState('');

  // 登録ボタンか保存ボタンか判定
  const createButton = newData?.id === 0 ? true : false;

  const handleUpdateSuccess = () => {
    refetch && refetch();
    setUpdatePopUp(true);
    setIsChanged(false);
    setUpdating(false);
    setEnableToSave(false);
  };

  const updateSupplierMutation = useUpdateSupplierMutation(
    newData?.id,
    handleUpdateSuccess,
    setErrMsg
  );

  const createdSupplierData = {
    id: 0,
    code: '',
    name: '',
    description: '',
    honorific: '',
    nameKana: '',
    orderType: 0,
    roundingMethod: '',
  };

  const [selectedHonorific, setSelectedHonorific] = useState(
    newData?.honorific ? newData?.honorific : 'attention'
  );
  const [selectedRoundingMethod, setSelectedRoundingMethod] = useState(
    newData?.roundingMethod ? newData?.roundingMethod : 'floor'
  );
  const [selectedCurrentUserEmployee, setSelectedCurrentUserEmployee] =
    useState('');
  const [deleteNo, setDeleteNo] = useState(0);
  const [confirmMsg, setConfirmMsg] = useState('');

  // 下位のコンポーネントに渡しているのがネストの深いオブジェクトなので、強制再レンダリングする必要がある
  const [, setToggleValue] = useState(false);
  const reRender = () => setToggleValue((prev) => !prev);

  const newEmployeesForUpdate = newData?.employeesAndSuppliersData
    ? newData?.employeesAndSuppliersData.map(
        (value: EmployeesAndSuppliersData) => {
          return {
            id: value.id,
            name: value.name,
            email: value.email,
            code: value.code,
          };
        }
      )
    : [];

  // 表示に使用
  const [newEmployeesArray, setNewEmployeesArray] = useState<
    EmployeesForUpdate[]
  >(newEmployeesForUpdate);

  // employeeIdだけ取り出し
  const selectedEmployeeIds = newEmployeesArray?.map(
    (item: EmployeesForUpdate) => item['id']
  );

  // 更新に使用
  const [selectedEmployeeIdsForUpdate, setSelectedEmployeeIdsForUpdate] =
    useState<number[]>(selectedEmployeeIds);

  const handleChange = (
    e:
      | React.ChangeEvent<HTMLInputElement>
      | React.ChangeEvent<HTMLTextAreaElement>
  ) => {
    const data = {
      ...newData,
      [e.target.name]: e.target.value,
      employeeIds: selectedEmployeeIdsForUpdate,
    };
    setNewData(data);
    setIsChanged(true);
    setEnableToSave(true);
  };

  const filteredCurrentUserEmployees =
    newData?.currentUserEmployees &&
    newData?.currentUserEmployees.filter(
      (cu: Todo) => !selectedEmployeeIds?.includes(cu.id)
    );

  const onClickAddButton = () => {
    // 追加ボタン
    const selectedEmployee =
      newData?.currentUserEmployees &&
      newData?.currentUserEmployees.find(
        (e: CurrentUserEmployee) => e.id === Number(selectedCurrentUserEmployee)
      );

    if (selectedEmployee) {
      newEmployeesArray.push({
        id: selectedEmployee.id,
        name: selectedEmployee.name,
        email: selectedEmployee.email,
        code: selectedEmployee.code,
      });
      selectedEmployeeIdsForUpdate.push(selectedEmployee.id);
    }

    setNewData({
      ...newData,
      employeeIds: selectedEmployeeIdsForUpdate
        ? selectedEmployeeIdsForUpdate
        : [],
    });

    setIsChanged(true);
    setEnableToSave(true);
    reRender();
    setSelectedCurrentUserEmployee('');
  };

  // 削除ボタン
  const onClickDelButton = (no: number, name: string) => {
    setConfirmMsg(`${name}を本当に削除しますか？`);
    setDeleteNo(no);
  };

  // 自社の担当者削除
  const deleteFunction = () => {
    setIsChanged(true);
    newEmployeesArray.splice(deleteNo, 1);
    selectedEmployeeIdsForUpdate.splice(deleteNo, 1);
    setNewEmployeesArray([...newEmployeesArray]);
    setNewData({
      ...newData,
      employeeIds: selectedEmployeeIdsForUpdate
        ? selectedEmployeeIdsForUpdate
        : [],
    });
    setConfirmMsg('');
    setEnableToSave(true);
  };

  const [createdSupplier, setCreatedSupplier] = useState(createdSupplierData);

  const handleCreatedSupplier = (supplier: typeof createdSupplierData) => {
    setCreatedSupplier(supplier);
  };
  // ***********************
  // [保存ボタン]押下、更新処理
  // ***********************
  const handleSubmit = () => {
    setUpdating(true);
    updateSupplierMutation.mutate(newData);
  };

  const createSupplierMutation = useCreateSupplierMutation(
    handleCreatedSupplier,
    handleUpdateSuccess,
    setErrMsg
  );

  // ***********************
  // [登録ボタン]押下、作成処理
  // ***********************
  const handleSubmitCreate = () => {
    setUpdating(true);
    createSupplierMutation.mutate(newData);
  };

  useEffect(() => {
    if (createdSupplier?.id !== 0) {
      setNewData({
        ...newData,
        id: createdSupplier?.id,
        code: createdSupplier?.code,
        name: createdSupplier?.name,
        description: createdSupplier?.description,
        honorific: createdSupplier?.honorific,
        nameKana: createdSupplier?.nameKana,
        orderType: createdSupplier?.orderType,
        roundingMethod: createdSupplier?.roundingMethod,
      });
    }
    setCreatedData && setCreatedData(createdSupplier);
  }, [createdSupplier]);

  // 消費税の端数処理方法
  useEffect(() => {
    setNewData({
      ...newData,
      roundingMethod: selectedRoundingMethod
        ? selectedRoundingMethod
        : newData?.roundingMethod,
      employeeIds: selectedEmployeeIdsForUpdate,
    });
  }, [selectedRoundingMethod]);

  // 敬称
  useEffect(() => {
    setNewData({
      ...newData,
      honorific: selectedHonorific ? selectedHonorific : newData?.honorific,
      employeeIds: selectedEmployeeIdsForUpdate,
    });
  }, [selectedHonorific]);

  useEffect(() => {
    if (newData?.honorific) {
      setSelectedHonorific(newData?.honorific);
    } else {
      setSelectedHonorific('attention');
    }
  }, [newData?.honorific]);

  useEffect(() => {
    if (newData?.roundingMethod) {
      setSelectedRoundingMethod(newData?.roundingMethod);
    } else {
      setSelectedRoundingMethod('floor');
    }
  }, [newData?.roundingMethod]);

  useEffect(() => {
    const selectedEmployeeIds = newData?.employeesAndSuppliersData?.map(
      (item: EmployeesForUpdate) => item['id']
    );
    selectedEmployeeIds && setSelectedEmployeeIdsForUpdate(selectedEmployeeIds);

    newData?.employeesAndSuppliersData &&
      setNewEmployeesArray(newData?.employeesAndSuppliersData);
  }, [newData?.employeesAndSuppliersData?.length]);

  return (
    <>
      <S.Wrapper>
        <S.GoBackWrapper onClick={onClickGoBack}>
          <ChevronLeft isBlue={true} />
          <span>一覧画面へ戻る</span>
        </S.GoBackWrapper>
        <S.ShippingGroupAreaDl>
          <dt>仕入先コード</dt>
          <dd>
            <S.Input
              type="text"
              inputMode="numeric"
              name="code"
              id="code"
              autoComplete="off"
              value={newData?.code ? newData.code : ''}
              onChange={handleChange}
            />
          </dd>
          <dt>仕入先名</dt>
          <dd>
            <S.Input
              type="text"
              name="name"
              id="name"
              autoComplete="off"
              value={newData?.name ? newData.name : ''}
              onChange={handleChange}
            />
          </dd>
          <dt>仕入先名(ふりがな)</dt>
          <dd>
            <S.Input
              type="text"
              name="nameKana"
              id="nameKana"
              value={newData?.nameKana ? newData.nameKana : ''}
              onChange={handleChange}
            />
          </dd>
          <S.HonorificRoundingWrapper>
            <S.HonorificRoundingItem>
              <span>敬称</span>
              <HonorificSelect
                data={Honorific}
                height="40px"
                fontSize="16px"
                value={selectedHonorific}
                setSelectedHonorific={setSelectedHonorific}
                setEnableToSave={setEnableToSave}
              />
            </S.HonorificRoundingItem>
            <S.HonorificRoundingItem>
              <span>消費税の端数処理方法</span>
              <CommonReactSelect
                data={RoundingMethod}
                height="40px"
                value={selectedRoundingMethod}
                setSelectedRoundingMethod={setSelectedRoundingMethod}
                setEnableToSave={setEnableToSave}
              />
            </S.HonorificRoundingItem>
          </S.HonorificRoundingWrapper>

          <S.DescriptionLabel>説明</S.DescriptionLabel>
          <S.DescriptionTextArea
            name="description"
            id="description"
            value={newData?.description || ''}
            onChange={handleChange}
          />
        </S.ShippingGroupAreaDl>
        <S.CurrentUserEmployees>
          {filteredCurrentUserEmployees && (
            <S.CurrentUserEmployeeItem>
              <span>自社の担当者</span>
              <CommonReactSelect
                data={filteredCurrentUserEmployees}
                height="40px"
                value={selectedCurrentUserEmployee}
                setSelectedRoundingMethod={setSelectedCurrentUserEmployee}
              />
            </S.CurrentUserEmployeeItem>
          )}
          <S.AddButton>
            <dd>
              {selectedCurrentUserEmployee ? (
                <Button children={'追加'} onClick={() => onClickAddButton()} />
              ) : (
                <Button disabled children={'追加'} />
              )}
            </dd>
          </S.AddButton>
        </S.CurrentUserEmployees>
        <div>
          {newEmployeesArray &&
            newEmployeesArray.map((em: EmployeesForUpdate, index: number) => {
              return (
                <S.SelectedEmployees key={index}>
                  {/* 削除ボタン */}
                  <S.CloseButton
                    onClick={() => onClickDelButton(index, em.name)}
                  >
                    <CircleCloseIcon />
                  </S.CloseButton>
                  {/* 従業員名 */}
                  <S.EmployeeName>
                    <span className="product-icon"></span>
                    {em.name}
                  </S.EmployeeName>
                  {/* email */}
                  <S.EmployeeEmail>{em.email}</S.EmployeeEmail>
                </S.SelectedEmployees>
              );
            })}
        </div>
      </S.Wrapper>
      <S.ButtonContainer>
        {createButton ? (
          <Button
            children={updating ? '登録中...' : '登録'}
            disabled={updating || !enableToSave}
            onClick={handleSubmitCreate}
          />
        ) : (
          <Button
            children={updating ? '保存中...' : '保存'}
            disabled={updating || !enableToSave}
            onClick={handleSubmit}
          />
        )}
      </S.ButtonContainer>
      {confirmMsg && (
        <DeleteConfirmModal
          confirmMsg={confirmMsg}
          onClickCancel={() => setConfirmMsg('')}
          onClickDelete={() => deleteFunction()}
        />
      )}
      <ErrorMsgPopUp
        errMsg={errMsg}
        handleClose={() => {
          setErrMsg('');
          setUpdating(false);
        }}
        fromPc={true}
        zIndex={9999}
      />
      {errMsg && (
        <Overlay
          dark
          handleClick={() => {
            setErrMsg('');
            setUpdating(false);
          }}
        />
      )}
    </>
  );
};

export default SupplierContents;
