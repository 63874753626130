import { FC, useState } from 'react';
import moment from 'moment';
import * as S from './AllStoreroomsRightSlideSidebar.styles';
import { Button } from '@components/elements/buttons/Button';
import ErrorMsgPopUpForStock from '@components/modules/pc/stock_status/ErrorMsgPopUpForStock/ErrorMsgPopUpForStock';
import ScrollToTop from '@components/modules/common/ScrollToTop/ScrollToTop';
import AllStoreroomStockForm from '@components/modules/pc/materials_inventory/AllStoreroomStockForm/AllStoreroomStockForm';
import { selectedEstimatedMaterial } from '@lib/pc/materials_inventory/type';
import useAllStoreroomsEstimatedMaterialStocks from '@lib/pc/materials_inventory/useAllStoreroomsEstimatedMaterialStocks';
import { MATERIAL_TYPE } from '@lib/common/type';

type Props = {
  rightSlideSidebarActive: boolean;
  setRightSlideSidebarActive: (rightSlideSidebarActive: boolean) => void;
  selectedMaterial: selectedEstimatedMaterial;
  selectedStoreroomId: string;
};

const AllStoreroomsRightSlideSidebar: FC<Props> = ({
  rightSlideSidebarActive,
  setRightSlideSidebarActive,
  selectedMaterial,
  selectedStoreroomId,
}: Props) => {
  const day =
    selectedMaterial && selectedMaterial.date !== ''
      ? moment(selectedMaterial.date)
      : moment();

  const formattedDate = moment(day).format('YYYY/MM/DD (ddd)');

  const [errMsg, setErrMsg] = useState('');

  const { materialStocks } = useAllStoreroomsEstimatedMaterialStocks(
    moment(selectedMaterial.date).toDate(),
    selectedMaterial.materialId,
    selectedStoreroomId
  );

  if (!selectedMaterial) return null;

  return (
    <>
      <S.Wrapper open={rightSlideSidebarActive}>
        <ScrollToTop />
        <S.ScrollableContainer>
          <S.HeadContainer>
            <S.HeadDate>{formattedDate}</S.HeadDate>
            <S.HeadProductName>
              <S.HeadProductType materialType={materialStocks?.materialType}>
                {materialStocks?.materialType === MATERIAL_TYPE.ROW_MATERIAL
                  ? '原'
                  : '資'}
              </S.HeadProductType>
              {materialStocks?.name}
            </S.HeadProductName>
          </S.HeadContainer>

          <AllStoreroomStockForm
            data={materialStocks}
            selectedMaterial={selectedMaterial}
          />
          <S.ButtonContainer>
            <Button
              borderWidth={1}
              onClick={() => {
                setRightSlideSidebarActive(false);
              }}
            >
              OK
            </Button>
          </S.ButtonContainer>
        </S.ScrollableContainer>
        <ErrorMsgPopUpForStock
          errMsg={errMsg}
          handleClose={() => setErrMsg('')}
          fromPc={true}
        />
      </S.Wrapper>
      {/* {rightSlideSidebarActive && (
        <Overlay handleClick={() => setRightSlideSidebarActive(false)} />
      )} */}
    </>
  );
};

export default AllStoreroomsRightSlideSidebar;
