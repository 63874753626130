import { MaterialArray } from '@lib/pc/settings/material_master_setting/type';

export const tagSearchForMaterial = (
  m: MaterialArray,
  selectedTagIds: number[]
) => {
  // or検索
  const orIncludeTags = new Set(
    [...m.tagIds, ...selectedTagIds].filter(
      (item) => m.tagIds.includes(item) && selectedTagIds.includes(item)
    )
  );
  if (selectedTagIds.length === 0) {
    return m;
  } else if (orIncludeTags.size > 0) {
    return m;
  }
};
