import React from 'react';
import { WithHeader } from '@components/templates/WithHeader';
import { Heading } from '@components/elements/typographies/Heading';
import * as S from './CustomerMasterCreate.styles';
import CustomerCreateWrapper from '@components/modules/pc/settings/customer_setting/CustomerCreate/CustomerCreateWrapper';

export const CustomerMasterCreateRoot: React.FC = () => {
  return (
    <WithHeader>
      <S.Wrapper>
        <div>
          <S.HeadContainer>
            <S.FlexBaseline>
              <div>
                <Heading tag="span" text="得意先の作成" fs="32" fw="lg" />
              </div>
            </S.FlexBaseline>
          </S.HeadContainer>
          <CustomerCreateWrapper />
        </div>
      </S.Wrapper>
    </WithHeader>
  );
};
