import styled from 'styled-components';
import { Link } from 'react-router-dom';

export const Camera = styled(Link)<{ index: number }>`
  display: flex;
  justify-content: space-between;
  padding: 1.25rem 1rem;
  border-bottom: 2px solid #f2f2f2;
  background: ${(props) =>
    props.index % 2 == 0
      ? props.theme.palette.white
      : props.theme.palette.paleYellow};

  &:hover {
    text-decoration: none;
    opacity: 1;
  }
`;

export const CameraInfo = styled.div`
  color: ${(props) => props.theme.palette.blue};
`;

export const CameraName = styled.p`
  font-weight: bold;
`;
