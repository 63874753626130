import { axiosInstance } from '@lib/pc/common/api/axiosConfig';
import { useMutation } from 'react-query';

export const useDeleteMaterialDeliveriesMutation = (
  detailId: number | null,
  handleDeleteSuccess: () => void
) => {
  return useMutation(
    async () => {
      return await axiosInstance().delete(
        `/api/v1/material_deliveries/${detailId}`
      );
    },
    {
      onSuccess: () => {
        handleDeleteSuccess();
      },
      onError: () => alert('エラーが発生しました'),
    }
  );
};

export default useDeleteMaterialDeliveriesMutation;
