import styled, { keyframes } from 'styled-components';

const fadeIn = keyframes`
  from {
     opacity: 0
   }
   to {
     opacity: 1
   }
`;

const fadeOut = keyframes`
  0% {
     opacity: 1
   }
   100% {
     opacity: 0
   }
`;

export const Wrapper = styled.div<{
  open: boolean;
}>`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  background: rgba(51, 51, 51, 0.5);

  display: ${(props) => (props.open ? 'block' : 'none')};
  animation: ${(props) => (props.open ? fadeIn : fadeOut)} 0.08s linear;
  height: 100vh;
  width: 100vw;

  z-index: 10000;
`;

export const DialogContainer = styled.div`
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  background-color: ${(props) => props.theme.palette.beige};
  box-shadow: 0px 24px 38px rgba(0, 0, 0, 0.07),
    0px 9px 46px rgba(0, 0, 0, 0.06), 0px 11px 15px rgba(0, 0, 0, 0.1);
  min-width: 80%;
`;

export const DialogHeader = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 1.5rem 1.8rem;
`;

export const DialogCloseButton = styled.div`
  cursor: pointer;
`;

export const DialogTitle = styled.div`
  display: flex;
  align-items: baseline;
  gap: 0.5rem;
  min-width: 80vw;

  > svg {
    width: 1.8rem;
    height: 1.8rem;
  }
`;

export const MainContainer = styled.div`
  padding: 1.5rem 2.5rem;
  background-color: ${(props) => props.theme.palette.sandGray};
`;

export const HeadingContainer = styled.div`
  display: flex;
  align-items: baseline;
  margin-bottom: 1.5rem;
`;

export const DateContainer = styled.div`
  display: flex;
  align-items: baseline;
  font-size: 1.5rem;
  font-weight: 700;

  > span {
    margin: 0 1.5rem 0 5px;
    font-size: 1rem;
  }
`;

export const ListItem = styled.div<{
  twoColumns?: boolean;
}>`
  background-color: ${(props) => props.theme.palette.white};
  display: flex;
  border-radius: 8px;
  margin: 0.25rem 0;

  > div {
    display: flex;
    flex-direction: column;
    gap: 10px;
    padding: 2rem 2.5rem;
    width: ${(props) => (props.twoColumns ? '60%' : '20%')};
  }

  > div:not(:last-child) {
    border-right: 1px solid ${(props) => props.theme.palette.sandGray};
  }

  > div:first-child {
    flex-direction: row;
    width: 40%;
    align-items: center;

    > span {
      margin-left: 0.75rem;
      font-weight: 700;
      font-size: 1.1rem;
    }
  }
`;

export const ListCell = styled.div<{
  red?: boolean;
  alignRight?: boolean;
}>`
  align-items: ${(props) => (props.alignRight ? 'baseline' : 'center')};

  > span {
    font-size: 1.25rem;
    font-weight: 700;
    display: contents;
    margin: 0 2px;
    color: ${(props) =>
      props.red ? props.theme.palette.error : props.theme.palette.baseBlack};
  }
`;

export const Unit = styled.span`
  font-size: 0.8rem !important;
  font-weight: 700;
`;
