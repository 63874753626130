import { axiosInstance } from '@lib/pc/common/api/axiosConfig';
import { useQuery } from 'react-query';
import humps from 'humps';
import moment from 'moment';
import type { Todo } from '@lib/common/type';

const useSemiProductProductionResultsForPc = (
  date: Date,
  factoryLineId: string | number,
  dateType: string,
  startDate: Date | null,
  endDate: Date | null
) => {
  const { data, refetch } = useQuery(
    ['production_results', date, factoryLineId],
    async () => {
      const formattedStartDate = endDate
        ? moment(startDate).format('YYYYMMDD')
        : null;
      const formattedEndDate = endDate
        ? moment(endDate).format('YYYYMMDD')
        : null;
      const data = await axiosInstance().get(
        `/api/v1/semi_product_production_results_for_pc?date=${moment(
          date
        ).format(
          'YYYYMMDD'
        )}&factory_line_id=${factoryLineId}&date_type=${dateType}` +
          (formattedStartDate && formattedEndDate
            ? `&start_date=${formattedStartDate}&end_date=${formattedEndDate}`
            : '')
      );

      return data;
    }
  );

  const semiProductProductionResultsForPc =
    data &&
    (humps.camelizeKeys(data.data.semi_product_production_results) as Todo);

  return { semiProductProductionResultsForPc, refetch };
};

export default useSemiProductProductionResultsForPc;
