import { useEffect } from 'react';
import { Case, Todo } from '@lib/common/type';
import * as S from './PickingIndexByShipping.styles';
import PickingDetailByShippingWrapper from '@components/modules/mobile/picking_by_shipping_edit/PickingDetailByShippingWrapper/PickingDetailByShippingWrapper';
import {
  DetailsByProduct,
  DetailsByShippingDestination,
  NotCheckedInfo,
} from '@lib/mobile/picking_by_shipping/type';

type Props = {
  refetch: () => void;
  scannedJanCode?: string;
  detailsByShippingDestination: Todo;
  searchProductChar: string;
  // searchShippingChar: string;
  completedCheckState: boolean;
  // setPickingStatus: (pickingStatus: string) => void;
  fixedAreaHeight: string;
  fromScannerResult: boolean;
  greenFixedAreaHeight: string;
  cases: Case[] | undefined;
  setUpdatePickingDetailId: (updatePickingDetailId: number) => void;
  handleUpdate: (
    checkState: boolean,
    amountDetail: DetailsByProduct[],
    allocateStatus: boolean
  ) => void;
  notCheckedInfo: NotCheckedInfo[];
  setNotCheckedInfo: (notCheckedProducts: NotCheckedInfo[]) => void;
  setErrMsg: (errMsg: string) => void;
  storeroomName: string;
  storeroomId: number;
  updating: boolean;
  setUpdating: (updating: boolean) => void;
  picking: DetailsByShippingDestination;
  // allocateStatus: boolean;
};

const PickingIndexByShipping = ({
  refetch,
  scannedJanCode,
  detailsByShippingDestination,
  searchProductChar,
  // searchShippingChar,
  completedCheckState,
  // setPickingStatus,
  fixedAreaHeight,
  fromScannerResult,
  greenFixedAreaHeight,
  cases,
  setUpdatePickingDetailId,
  handleUpdate,
  notCheckedInfo,
  setNotCheckedInfo,
  setErrMsg,
  storeroomName,
  storeroomId,
  updating,
  setUpdating,
  picking,
}: // allocateStatus,
Props) => {
  // 出荷先別checkState更新のためのrefetch
  useEffect(() => {
    refetch();
  }, []);

  // 出荷先別データ
  return (
    <S.Wrapper className="picking-index" fixedAreaHeight={fixedAreaHeight}>
      {detailsByShippingDestination &&
        detailsByShippingDestination.map((d: Todo, idx: number) => (
          <PickingDetailByShippingWrapper
            key={idx}
            data={d}
            idx={idx}
            searchProductChar={searchProductChar}
            completedCheckState={completedCheckState}
            fixedAreaHeight={fixedAreaHeight}
            fromScannerResult={fromScannerResult}
            greenFixedAreaHeight={greenFixedAreaHeight}
            cases={cases}
            setUpdatePickingDetailId={setUpdatePickingDetailId}
            handleUpdate={handleUpdate}
            scannedJanCode={scannedJanCode}
            notCheckedInfo={notCheckedInfo}
            setNotCheckedInfo={setNotCheckedInfo}
            refetch={refetch}
            setErrMsg={setErrMsg}
            storeroomName={storeroomName}
            storeroomId={storeroomId}
            updating={updating}
            setUpdating={setUpdating}
            picking={picking}
          />
        ))}
    </S.Wrapper>
  );
};

export default PickingIndexByShipping;
