import styled from 'styled-components';

export const Wrapper = styled.div`
  width: 100%;
  height: 100vh;
  padding: 16px 80px;
  background-color: ${(props) => props.theme.palette.beige};
`;

export const GoBackWrapper = styled.div`
  width: 160px;
  display: flex;
  padding-bottom: 32px;
  cursor: pointer;
  color: ${(props) => props.theme.palette.blue};
  > span {
    padding-top: 8px;
    padding-left: 10px;
  }
`;

export const NameCodeWrapper = styled.div<{ marginRight?: string }>`
  display: flex;
  margin-bottom: 36px;
  > span {
    font-weight: 700;
    margin-right: ${(props) =>
      props.marginRight ? props.marginRight : '36px'};
  }
`;

export const ShippingDestinationTitle = styled.div`
  font-weight: 700;
  padding-bottom: 8px;
  text-align: left;
  width: 80%;
`;

export const ShippingDestinationSelectRow = styled.div`
  display: flex;
  gap: 24px;
`;

export const ShippingDestinationSelectWrapper = styled.div`
  width: 90%;
`;

export const AddButton = styled.span`
  padding-top: 24px;
  justify-content: center;
  align-items: center;
  dd {
    text-align: -webkit-right;
  }
  button {
    width: 64px;
    &.disabled {
      background: #e0e0e0;
      border-color: #e0e0e0;
      color: #6b675c;
      cursor: initial;
    }
  }
`;

export const ShippingDestinationsWrapper = styled.div`
  margin-top: 24px;
  overflow: scroll;
  height: calc(100vh - 193px);
  padding-bottom: 500px;
`;

export const ShippingDestination = styled.div`
  width: 90.5%;
  padding: 4px;
  display: flex;
  border-bottom: 1px solid #cdcdcd;
  align-items: center;
`;

export const DeleteButton = styled.div`
  /* margin: 12px 0; */
`;

export const CodeNameWrapper = styled.div`
  display: flex;
`;

export const CodeWrapper = styled.div`
  width: 100px;
`;

export const CloseButton = styled.div`
  width: 40px;
  height: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
`;
