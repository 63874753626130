import styled from 'styled-components';

export const Wrapper = styled.div``;

export const Header = styled.div`
  position: fixed;
  font-weight: 700;
  font-size: 18px;
  line-height: 26px;
  color: ${(props) => props.theme.palette.white};
  width: 100vw;

  display: flex;
  justify-content: space-evenly;
  align-items: center;
  /* margin: 1rem 0; */
  z-index: 1003;
  top: 0;
`;

export const CameraMessage = styled.div`
  font-weight: 700;
  display: flex;
  height: 15%;
  /* position: absolute;
  top: 0; */
  background: #06945a;
  color: ${(props) => props.theme.palette.white};
  width: 100%;
  text-align: center;
  /* padding-top: 10px; */
  padding-bottom: 10px;
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  padding: 12px 0;
  > div {
    font-feature-settings: 'palt';
  }
`;

export const NotFoundMessage = styled.div`
  padding: 17% 0 0 5%;
  font-weight: 700;
  font-size: 18px;
  line-height: 26px;
`;
