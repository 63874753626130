import styled from 'styled-components';

export const Overlay = styled.div<{
  dark?: boolean;
  zIndex?: number;
}>`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  //色変わると思う
  background: ${(props) =>
    props.dark ? 'rgba(51, 51, 51, 0.5)' : 'transparent'};
  height: 5000px;
  z-index: ${(props) => (props.zIndex ? props.zIndex : '9998')};
`;
