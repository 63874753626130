export type OrderDetails = {
  productName: string;
  unitPrice: number;
  unitAmount: number;
  unitTaxRate: number;
};

export type PurchaseOrder = {
  destination: string;
  inCharge: string;
  title: string;
  deadline: string;
  deliveryLocation: string;
  paymentTerms: string;
  purchaseOrderCode: string;
  issueDate: string;
  orderDetails: OrderDetails[];
};

export const Honorific = [
  {
    value: 'attention',
    name: '御中',
  },
  {
    value: 'dear',
    name: '様',
  },
  {
    value: 'no_value',
    name: 'なし',
  },
];
