import styled from 'styled-components';

export const Wrapper = styled.div`
  background: ${(props) => props.theme.palette.white};
  position: relative;
  height: calc(100vh - 80px);
`;

export const HeadContainer = styled.div`
  height: 86px;
  padding: 1.15rem 2.25rem;
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

// export const FlexBaseline = styled.div`
//   display: flex;
//   align-items: baseline;
//   position: fixed;
// `;
