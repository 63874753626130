import { useMutation } from 'react-query';
import humps from 'humps';
import { axiosInstance } from '@lib/pc/common/api/axiosConfig';
import { AxiosError } from 'axios';
import { Todo, ErrorResponse } from '@lib/common/type';
import { CreateUpdateParams } from '@lib/pc/settings/shipping_group_master_create/type';

const useCreateShippingGroupMutation = (
  setCreatedProduct: (createdProduct: Todo) => void,
  handleSuccess: () => void,
  setErrMsg: (errMsg: string) => void
) => {
  return useMutation(
    async (params: CreateUpdateParams) => {
      return await axiosInstance()
        .post(
          `/api/v1/settings/shipping_groups`,
          humps.decamelizeKeys({
            shipping_groups: {
              code: params.code,
              name: params.name,
              shipping_time: params.shippingTime,
            },
          })
        )
        .then((response) => {
          setCreatedProduct(humps.camelizeKeys(response.data));
        });
    },
    {
      onSuccess: () => {
        handleSuccess?.();
      },
      onError: (error: AxiosError<ErrorResponse>) => {
        const message = error.response?.data.message || 'エラーが発生しました';
        setErrMsg(message);
      },
    }
  );
};

export default useCreateShippingGroupMutation;
