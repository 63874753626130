import { FC } from 'react';
import * as S from './UpdatePopUp.styles';
import { ReactComponent as VectorIcon } from '@assets/icons/mobile/vector.svg';
import Close from '@assets/icons/mobile/close';

type Props = {
  popUp: boolean;
  name?: string;
  handleClose: () => void;
  messageKind?: string;
  taskKind?: string;
  isDelete?: boolean;
};

const UpdatePopUp: FC<Props> = ({
  popUp,
  name,
  handleClose,
  messageKind,
  taskKind,
  isDelete,
}: Props) => {
  return (
    <S.Wrapper open={popUp} isDelete={isDelete}>
      <S.ModalHead>
        <S.IconWrapper>
          <VectorIcon />
        </S.IconWrapper>
        {taskKind === 'productMaster' && messageKind === 'update' && (
          <S.MessageContainer>
            {name}の商品マスタを更新しました
          </S.MessageContainer>
        )}
        {taskKind === 'productMaster' && messageKind === 'create' && (
          <S.MessageContainer>{name}を作成しました</S.MessageContainer>
        )}
        {taskKind === 'productMaster' && messageKind === 'delete' && (
          <S.MessageContainer>{name}を削除しました</S.MessageContainer>
        )}
        {taskKind === 'messageOnly' && messageKind === 'create' && (
          <S.MessageContainer>追加しました</S.MessageContainer>
        )}
        {taskKind === 'messageOnly' && messageKind === 'update' && (
          <S.MessageContainer>更新しました</S.MessageContainer>
        )}
        {taskKind === 'messageOnly' && messageKind === 'delete' && (
          <S.MessageContainer>削除しました</S.MessageContainer>
        )}
        <S.ModalHeadCloseIcon onClick={() => handleClose()}>
          <Close isWhite={true} />
        </S.ModalHeadCloseIcon>
      </S.ModalHead>
    </S.Wrapper>
  );
};

export default UpdatePopUp;
