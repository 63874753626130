import React, { FC, useEffect, useState } from 'react';
import * as S from './SalesManagementFormModal.styles';
import { WithHeader } from '@components/templates/WithHeader';
import { Heading } from '@components/elements/typographies/Heading';
import { Button } from '@components/elements/buttons/Button';
import { ReactComponent as Add } from '@assets/icons/add.svg';
import { ReactComponent as ErrorAttention } from '@assets/icons/error_attention.svg';
import { useHistory } from 'react-router-dom';
import { ReactComponent as CloseIcon } from '@assets/icons/close_24px.svg';
import useCreateSalesInformationMutation from '@lib/pc/sales_information/useCreateSalesInformationMutation';
import ReactSelect from 'react-select';
import useProducts from '@lib/pc/common/hooks/useProducts';
import useEmployees from '@lib/pc/common/hooks/useEmployees';
import useCustomers from '@lib/pc/common/hooks/useCustomers';
import useSalesStores from '@lib/pc/common/hooks/useSalesStores';

const INITIAL_DETAIL = {
  productId: null,
  deliveryDate: null,
  shippingDate: null,
  salesStoreId: null,
  primaryEstimatedAmount: null,
  secondaryEstimatedAmount: null,
  fixedAmount: null,
  errors: null,
};

const toReactSelectOptions = (options: { id: number; name: string }[]) =>
  options.map(({ id, name }) => ({ value: id, label: name }));

type SelectProps = {
  width: string;
  options: { id: number; name: string }[];
  value: number | null;
  onChange: (value: number) => void;
};
const Select = ({ width, options, value, onChange }: SelectProps) => {
  const reactSelectOptions = toReactSelectOptions(options);
  const currentValue = reactSelectOptions.find((o) => o.value === value);

  return (
    <ReactSelect
      options={reactSelectOptions}
      value={currentValue}
      onChange={(option) => option && onChange(option.value)}
      styles={{
        control: (baseStyles) => ({
          ...baseStyles,
          width: width,
          height: '40px',
          borderRadius: '0.5rem',
          borderWidth: 0,
          outline: 'solid 1px rgba(0, 0, 0, 0.08)',
        }),
      }}
      components={{
        DropdownIndicator: () => <S.SelectIndicator />,
        IndicatorSeparator: () => null,
      }}
      isSearchable={false}
    />
  );
};

const SalesManagementFormModal: FC = () => {
  const [form, setForm] = useState({
    customerId: null as null | number,
    employeeId: null as null | number,
    entryDate: null,
    title: '',
    comment: '',
    details: [INITIAL_DETAIL],
  });
  const [submitted, setSubmitted] = useState(false);
  const entryDateError = !form.entryDate;
  const titleError = !form.title;
  const detailErrors = form.details.map((d) => {
    return {
      deliveryDate: !d.deliveryDate,
      shippingDate: !d.shippingDate,
      primaryEstimatedAmount:
        !!d.primaryEstimatedAmount && isNaN(d.primaryEstimatedAmount),
      secondaryEstimatedAmount:
        !!d.secondaryEstimatedAmount && isNaN(d.secondaryEstimatedAmount),
      fixedAmount: !!d.fixedAmount && isNaN(d.fixedAmount),
    };
  });
  const hasDetailsError = detailErrors.some((error) =>
    Object.values(error).includes(true)
  );
  const hasError = entryDateError || titleError || hasDetailsError;
  const handleChangeFormIdValue = (key: string) => {
    return (value: number) => {
      setForm({
        ...form,
        [key]: value,
      });
    };
  };
  const handleChangeFormStringValue = (key: string) => {
    return (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
      setForm({
        ...form,
        [key]: e.target.value,
      });
    };
  };
  const handleChangeDetailIdValue = (key: string, index: number) => {
    return (value: number) => {
      const newDetail = { ...form.details[index], [key]: value };
      const details = form.details.map((d, i) => (i === index ? newDetail : d));
      setForm({ ...form, details });
    };
  };
  const handleChangeDetailStringValue = (key: string, index: number) => {
    return (e: React.ChangeEvent<HTMLInputElement>) => {
      const newDetail = { ...form.details[index], [key]: e.target.value };
      const details = form.details.map((d, i) => (i === index ? newDetail : d));
      setForm({ ...form, details });
    };
  };
  const handleClickDeleteDetail = (index: number) => {
    return () => {
      const details = form.details.filter((_, i) => i !== index);
      setForm({ ...form, details });
    };
  };

  const history = useHistory();
  const createSalesInformationMutation = useCreateSalesInformationMutation(() =>
    history.push('/pc/sales_information?created=1')
  );
  const handleSubmit = () => {
    setSubmitted(true);
    !hasError && createSalesInformationMutation.mutate(form);
  };

  // マスターデータの取得と各selectの初期値設定
  const { data: products } = useProducts();
  useEffect(() => {
    products && handleChangeDetailIdValue('productId', 0)(products[0]?.id);
  }, [products]);
  const { data: salesStores } = useSalesStores();
  useEffect(() => {
    salesStores &&
      handleChangeDetailIdValue('salesStoreId', 0)(salesStores[0]?.id);
  }, [salesStores]);
  const { data: employees } = useEmployees('sales_managements');
  useEffect(() => {
    employees && handleChangeFormIdValue('employeeId')(employees[0]?.id);
  }, [employees]);
  const { data: customers } = useCustomers();
  useEffect(() => {
    customers && handleChangeFormIdValue('customerId')(customers[0]?.id);
  }, [customers]);

  if (!products || !employees || !customers || !salesStores) return null;

  return (
    <WithHeader>
      <S.Wrapper>
        <S.HeadContainer>
          <S.FlexBaseline>
            <Heading tag="span" text="営業情報を入力" fs="32" fw="lg" />
          </S.FlexBaseline>
        </S.HeadContainer>
        <S.MainContainer>
          <S.MandatoryItems>
            {hasError && submitted && (
              <S.RequiredError>
                <S.ErrorStatement>
                  <ErrorAttention />
                  必須項目を入力してください
                </S.ErrorStatement>
              </S.RequiredError>
            )}
            <S.InformationInput>
              <S.Label>
                <S.LabelText>得意先</S.LabelText>
                <Select
                  width="26rem"
                  options={customers}
                  value={form.customerId}
                  onChange={handleChangeFormIdValue('customerId')}
                />
              </S.Label>
              <S.Label>
                <S.LabelText>担当者</S.LabelText>
                <Select
                  width="18rem"
                  options={employees}
                  value={form.employeeId}
                  onChange={handleChangeFormIdValue('employeeId')}
                />
              </S.Label>
              <S.Label>
                <S.LabelText>記入日</S.LabelText>
                <S.InputDate
                  type="date"
                  id="datepicker"
                  onChange={handleChangeFormStringValue('entryDate')}
                  error={entryDateError && submitted}
                ></S.InputDate>
              </S.Label>
            </S.InformationInput>
            <div>
              <S.Label>
                <S.LabelText>件名</S.LabelText>
                <S.InputTitle
                  type="text"
                  placeholder="例：○○スーパー 12月分 特売"
                  onChange={handleChangeFormStringValue('title')}
                  error={titleError && submitted}
                ></S.InputTitle>
              </S.Label>
            </div>
          </S.MandatoryItems>
          <S.ProductAddition>
            <S.AdditionWrapper>
              <S.ProductItems width="15.875rem">商品</S.ProductItems>
              <S.ProductItems width="9.125rem">納品日</S.ProductItems>
              <S.ProductItems width="9.125rem">出荷予定日</S.ProductItems>
              <S.ProductItems width="9.125rem">販売店舗</S.ProductItems>
              <S.ProductItems width="8.625rem">1次見込数量</S.ProductItems>
              <S.ProductItems width="8.625rem">2次見込数量</S.ProductItems>
              <S.ProductItems width="8.625rem">確定数量</S.ProductItems>
            </S.AdditionWrapper>
            {form.details.map((detail, index) => {
              const error = detailErrors[index];
              return (
                <S.AdditionRowWrapper key={index}>
                  <S.CloseButtonWrapper>
                    <S.CloseButton onClick={handleClickDeleteDetail(index)}>
                      <CloseIcon />
                    </S.CloseButton>
                  </S.CloseButtonWrapper>
                  <S.AdditionRowFormWrapper>
                    <Select
                      width="15.875rem"
                      options={products}
                      value={detail.productId}
                      onChange={handleChangeDetailIdValue('productId', index)}
                    />
                    <S.InputContainer width="9.125rem">
                      <S.InputDate
                        type="date"
                        id="datepicker"
                        onChange={handleChangeDetailStringValue(
                          'deliveryDate',
                          index
                        )}
                        error={error.deliveryDate && submitted}
                      ></S.InputDate>
                    </S.InputContainer>
                    <S.InputContainer width="9.125rem">
                      <S.InputDate
                        type="date"
                        id="datepicker"
                        onChange={handleChangeDetailStringValue(
                          'shippingDate',
                          index
                        )}
                        error={error.shippingDate && submitted}
                      ></S.InputDate>
                    </S.InputContainer>
                    <Select
                      width="9.125rem"
                      options={salesStores}
                      value={detail.salesStoreId}
                      onChange={handleChangeDetailIdValue(
                        'salesStoreId',
                        index
                      )}
                    />
                    <S.InputWithUnitContainer width="8.625rem">
                      <S.InputTitle
                        onChange={handleChangeDetailStringValue(
                          'primaryEstimatedAmount',
                          index
                        )}
                        error={error.primaryEstimatedAmount && submitted}
                      />
                      <S.Unit>個</S.Unit>
                    </S.InputWithUnitContainer>
                    <S.InputWithUnitContainer width="8.625rem">
                      <S.InputTitle
                        onChange={handleChangeDetailStringValue(
                          'secondaryEstimatedAmount',
                          index
                        )}
                        error={error.secondaryEstimatedAmount && submitted}
                      />
                      <S.Unit>個</S.Unit>
                    </S.InputWithUnitContainer>
                    <S.InputWithUnitContainer width="8.625rem">
                      <S.InputTitle
                        onChange={handleChangeDetailStringValue(
                          'fixedAmount',
                          index
                        )}
                        error={error.fixedAmount && submitted}
                      />
                      <S.Unit>個</S.Unit>
                    </S.InputWithUnitContainer>
                  </S.AdditionRowFormWrapper>
                </S.AdditionRowWrapper>
              );
            })}
            <S.ProductAdditionButton>
              <Button
                onClick={() =>
                  setForm({
                    ...form,
                    details: [...form.details, INITIAL_DETAIL],
                  })
                }
                padding="lg"
                icon={<Add />}
                outlined={true}
              >
                商品追加
              </Button>
            </S.ProductAdditionButton>
          </S.ProductAddition>
          <S.RemarksWrapper>
            <S.LabelText>備考</S.LabelText>
            <S.Remarks
              onChange={handleChangeFormStringValue('comment')}
              value={form.comment}
            ></S.Remarks>
          </S.RemarksWrapper>
        </S.MainContainer>
        <S.ButtonWrapper>
          <S.ButtonContainer>
            <Button
              borderWidth={1}
              outlined={true}
              onClick={() => history.push('/pc/sales_information')}
            >
              キャンセル
            </Button>
            <Button onClick={handleSubmit}>保存</Button>
          </S.ButtonContainer>
        </S.ButtonWrapper>
      </S.Wrapper>
    </WithHeader>
  );
};

export default SalesManagementFormModal;
