import { FC } from 'react';
import moment from 'moment';
import {
  VictoryAxis,
  VictoryBar,
  VictoryChart,
  VictoryContainer,
  VictoryGroup,
} from 'victory';
import { ModalPaper } from '@components/modules/common/pc/ModalPaper';
import {
  extractTotalAmountForBarDisplay,
  convertStockAllocationBarData,
  generateDateArrayForModal,
  isSameDayWithOutTime,
  numberWithDecimalPlaces,
  changeWidth,
  findMinAmountForStockAllocationGraph,
  findMaxAmountForStockAllocationGraph,
} from '@lib/pc/stock_status/functions';
import * as S from './StockAllocationGraphWithTable.styles';
import {
  AllocationType,
  Storeroom,
  productStockAllocation,
} from '@lib/common/type';
import { removeLastItem } from '@lib/common/functions';

type Props = {
  selectedDate: moment.Moment;
  data: productStockAllocation[];
  selectedAllocationTypeId: number;
  allocationTypeOptions: AllocationType[];
  numberOfDaysParamsMonth: number;
  selectedStoreroomId: number;
  storerooms: Storeroom[] | undefined;
  setSelectedAllocationTypeId: (id: number) => void;
  setSelectedStoreroomId: (id: number) => void;
};

const StockStatusGraphWithTable: FC<Props> = ({
  selectedDate,
  data,
  selectedAllocationTypeId,
  allocationTypeOptions,
  numberOfDaysParamsMonth,
  selectedStoreroomId,
  storerooms,
  setSelectedAllocationTypeId,
  setSelectedStoreroomId,
}: Props) => {
  const today = new Date();
  const newAllocations = data && removeLastItem(data);

  const barColor =
    selectedAllocationTypeId === -1
      ? '#ABD5F3'
      : selectedAllocationTypeId === 0
      ? '#B0F1D7'
      : selectedAllocationTypeId === 1
      ? '#F6C1B1'
      : selectedAllocationTypeId === 2
      ? '#FCE6C6'
      : selectedAllocationTypeId === 3
      ? '#F3DC9F'
      : '#FFFFFF';

  const minYCoordinate = findMinAmountForStockAllocationGraph(data);
  const tmpMaxY = findMaxAmountForStockAllocationGraph(data);
  const maxYCoordinate = minYCoordinate == 0 && tmpMaxY == 0 ? 1 : tmpMaxY;

  return (
    <ModalPaper
      heading1="払出状況の分析"
      selectedAllocationTypeId={selectedAllocationTypeId}
      allocationTypeOptions={allocationTypeOptions}
      setSelectedAllocationTypeId={setSelectedAllocationTypeId}
      selectedStoreroomId={selectedStoreroomId}
      handleChangeTransfer={setSelectedStoreroomId}
      storerooms={storerooms}
    >
      <S.DescHeader>
        <S.ColorBox bgColor={barColor} />
        {selectedAllocationTypeId === -1 ? (
          <span>当月在庫払出数</span>
        ) : selectedAllocationTypeId === 0 ? (
          <span>店舗販売数</span>
        ) : selectedAllocationTypeId === 1 ? (
          <span>廃棄数</span>
        ) : selectedAllocationTypeId === 2 ? (
          <span>在庫移動数</span>
        ) : selectedAllocationTypeId === 3 ? (
          <span>消費</span>
        ) : (
          <span></span>
        )}
      </S.DescHeader>
      <S.GraphContainer>
        <S.LeftHeadContainer>
          <S.LeftLabelContainer>
            <S.GraphLeftLabelYAxisContainer>
              <VictoryChart
                height={320}
                minDomain={{ y: minYCoordinate }}
                maxDomain={{ y: maxYCoordinate }}
                padding={{ top: 30, bottom: 5 }}
                containerComponent={<VictoryContainer responsive={false} />}
              >
                <VictoryAxis
                  height={320}
                  minDomain={{ y: minYCoordinate }}
                  maxDomain={{ y: maxYCoordinate }}
                  dependentAxis
                  crossAxis={false}
                  padding={{ top: 5, bottom: 15 }}
                  offsetX={60}
                  standalone
                />
                <VictoryBar
                  barWidth={16}
                  data={extractTotalAmountForBarDisplay(newAllocations)}
                  style={{ data: { display: 'none' } }}
                />
              </VictoryChart>
            </S.GraphLeftLabelYAxisContainer>
          </S.LeftLabelContainer>
          <S.LeftTableEmptyCell />
          <S.LeftTableHeadCell bg={'#E7E2DD'}>
            <span>在庫払出数</span>
          </S.LeftTableHeadCell>
        </S.LeftHeadContainer>
        <S.ScrollableContainer>
          <VictoryChart
            height={320}
            width={changeWidth(numberOfDaysParamsMonth)}
            domainPadding={{ x: 35 }}
            padding={{ top: 30, bottom: 0, right: 0, left: 0 }}
            containerComponent={<VictoryContainer responsive={false} />}
          >
            <VictoryAxis
              dependentAxis
              //x
              style={{
                axis: { display: 'none' },
                tickLabels: {
                  display: 'none',
                },
              }}
            />
            <VictoryAxis
              dependentAxis // Y
              style={{
                axis: { display: 'none' },
                grid: { stroke: '#E7E2DD' },
                tickLabels: {
                  display: 'none',
                },
              }}
            />
            <VictoryGroup>
              <VictoryBar
                barWidth={16}
                style={{ data: { fill: barColor } }}
                data={convertStockAllocationBarData(newAllocations)}
              />
            </VictoryGroup>
          </VictoryChart>
          <S.TableWrapper>
            <S.TableRow>
              {generateDateArrayForModal(
                numberOfDaysParamsMonth,
                selectedDate
              ).map(
                (
                  day: { productionDate: Date; isToday: boolean },
                  i: number
                ) => {
                  return (
                    <S.TableHeadCell
                      key={`date-${i}`}
                      isToday={isSameDayWithOutTime(day.productionDate, today)}
                      dayOfWeek={moment(day.productionDate).format('ddd')}
                    >
                      <div>
                        {i === 0
                          ? moment(day.productionDate).format('MM/DD')
                          : moment(day.productionDate).format('DD')}
                        <span>
                          {moment(day.productionDate).format('(ddd)')}
                        </span>
                      </div>
                    </S.TableHeadCell>
                  );
                }
              )}
            </S.TableRow>
            <S.TableRow>
              {newAllocations &&
                newAllocations.map((p: productStockAllocation, i: number) => {
                  return (
                    <S.TableCell
                      key={`inventory-quantity-${i}`}
                      isToday={isSameDayWithOutTime(p.date, today)}
                      lastCell={true}
                    >
                      {p.totalAmount
                        ? numberWithDecimalPlaces(p.totalAmount, 2)
                        : '-'}
                    </S.TableCell>
                  );
                })}
            </S.TableRow>
          </S.TableWrapper>
        </S.ScrollableContainer>
      </S.GraphContainer>
    </ModalPaper>
  );
};

export default StockStatusGraphWithTable;
