import { useWindowScroll } from 'react-use';
import * as S from './PickingStatusMenu.styles';
import { ReactComponent as CloseIcon } from '@assets/icons/mobile/circle_close_blue.svg';
import { STATUS_VALUE } from '@lib/mobile/picking_by_shipping/type';

type Props = {
  useAllocation: boolean;
  selectedStatus: number[];
  setSelectedStatus: (selectedStatus: number[]) => void;
  statePickingStatus: number[];
  setStatePickingStatus: (statePickingStatus: number[]) => void;
};

const PickingStatusMenu = ({
  useAllocation,
  selectedStatus,
  setSelectedStatus,
  statePickingStatus,
  setStatePickingStatus,
}: Props) => {
  const { y: currentScrollPosition } = useWindowScroll();
  const onChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const changedId = Number(event.target.value);
    const updatedStatusIds = event.target.checked
      ? [...selectedStatus, changedId]
      : selectedStatus.filter((id) => id !== changedId);
    setSelectedStatus(updatedStatusIds);
    setStatePickingStatus(updatedStatusIds);
  };

  const getFilteredStatus = (useAllocation: boolean) => {
    if (useAllocation) {
      return STATUS_VALUE;
    } else {
      return STATUS_VALUE.filter((status) => status.value < 3);
    }
  };
  const filteredStatus = getFilteredStatus(useAllocation);

  return (
    <S.Wrapper scrollPosition={currentScrollPosition}>
      <S.StatusControl>
        {statePickingStatus.length ? (
          <S.StatusClearWrapper>
            解除する
            <S.IconWrapper
              onClick={() => {
                setSelectedStatus([]);
                setStatePickingStatus([]);
              }}
            >
              <CloseIcon />
            </S.IconWrapper>
          </S.StatusClearWrapper>
        ) : (
          '絞り込む'
        )}
      </S.StatusControl>
      {filteredStatus.map((s, index) => (
        <S.StatusLabel
          $checked={statePickingStatus.includes(s.value)}
          key={s.key}
        >
          <S.StatusCheckBox
            onChange={onChange}
            type="checkbox"
            value={s.value}
          />

          <S.StatusName
            checked={statePickingStatus.includes(s.value)}
            font={s.value === 1}
          >
            <S.StatusIcon
              padding={s.value === 1}
              btnBgColor={STATUS_VALUE[index].btnBgColor}
              borderColor={STATUS_VALUE[index].borderColor}
              fontColor={STATUS_VALUE[index].fontColor}
            >
              {s.key}
            </S.StatusIcon>
          </S.StatusName>
        </S.StatusLabel>
      ))}
    </S.Wrapper>
  );
};

export default PickingStatusMenu;
