import type { Todo } from '@lib/common/type';

// 文字列検索
export const searchCharForProductsPhotograph = (
  stateSearchChar: string,
  stocks: Todo[]
) => {
  const newArr = stocks.filter((a) => {
    return (
      a.name.includes(stateSearchChar) ||
      (a.productCode && a.productCode.toString().includes(stateSearchChar))
    );
  });
  return newArr;
};
