import { useMutation } from 'react-query';
import humps from 'humps';
import { AxiosError } from 'axios';
import { axiosInstance } from '@lib/pc/common/api/axiosConfig';
import { ErrorResponse } from '@lib/common/type';
import { SupplierEdit } from '@lib/pc/settings/supplier_edit/type';

const useUpdateSupplierMutation = (
  supplierId: number | string,
  handleSuccess: () => void,
  setErrMsg: (errMsg: string) => void
) => {
  return useMutation(
    async (params: SupplierEdit) => {
      return await axiosInstance().patch(
        `/api/v1/settings/supplier/${supplierId}`,
        humps.decamelizeKeys({
          supplier: {
            code: params.code,
            name: params.name,
            name_kana: params.nameKana,
            honorific: params.honorific,
            rounding_method: params.roundingMethod,
            description: params.description,
            employee_ids: params.employeeIds ? params.employeeIds : [],
          },
        })
      );
    },
    {
      onSuccess: () => {
        handleSuccess?.();
      },
      onError: (error: AxiosError<ErrorResponse>) => {
        const message = error.response?.data.message || 'エラーが発生しました';
        setErrMsg(message);
      },
    }
  );
};

export default useUpdateSupplierMutation;
