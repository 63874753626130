import styled from 'styled-components';

export const Wrapper = styled.div`
  max-width: 100vw;
  height: 100vh;
  height: calc(100vh - 143px);
  overflow: scroll;
  position: relative;
  background: ${(props) => props.theme.palette.beige};
`;

export const ListWrapper = styled.div`
  max-width: 100vw;
  max-height: 100vh;
  height: calc(100vh - 165px);

  overflow: scroll;
  display: flex;
  position: relative;
  background: ${(props) => props.theme.palette.beige};
`;

export const ListHeader = styled.div`
  display: flex;
  align-items: center;

  box-shadow: 1px 3px 5px 0px rgba(0, 0, 0, 0.2);
`;

export const ListHeadCell = styled.div<{
  checked?: boolean;
}>`
  height: 200px;
  width: 80px;
  box-sizing: border-box;
  padding: 0.5rem 0;
  writing-mode: vertical-rl;
  text-orientation: upright;
  display: flex;
  flex-direction: row;
  justify-content: revert;
  align-items: center;
  font-weight: 700;
  font-size: 0.9rem;
  color: ${(props) => props.theme.palette.khaki};

  > span {
    writing-mode: horizontal-tb;
    margin-bottom: 0.25rem;
    font-weight: 500;
    font-size: 0.75rem;
    padding-top: 6px;
  }

  > div {
    &.destination-name {
      height: 125px;
    }
  }

  :first-child,
  :nth-child(2) {
    position: -webkit-sticky; /* for Safari */
    /* position: sticky; */
    left: 250px;
    z-index: 4;
    border-right: 1px solid #c0c0c0;
    padding-top: 1.5rem;
  }

  :nth-child(even) {
    background: ${(props) =>
      props.checked ? '#e0e0e0' : props.theme.palette.beige};
    display: flex;
    flex-direction: row;
  }

  :nth-child(odd) {
    background: ${(props) =>
      props.checked ? '#e0e0e0' : props.theme.palette.white};
    display: flex;
    flex-direction: row;
  }
`;

export const ProductInfo = styled.div`
  /* cursor: pointer; */
  width: 100vw;
  padding: 0.4rem 0;
  gap: 0.3rem;
  display: grid;
`;

export const CheckArea = styled.div<{
  checked: boolean;
}>`
  position: relative;
  float: right;
  border: 1px solid
    ${(props) =>
      props.checked ? props.theme.palette.black : props.theme.palette.blue};
  width: 73%;
  /* width: 60px; */
  height: 39px;
  border-radius: 8px;
  > span {
    writing-mode: horizontal-tb;
    top: 24px;
    left: 4px;
    font-weight: 700;
    font-size: 10px;
    text-align: center;
  }
`;

export const CheckIconWrapper = styled.div`
  display: flex;
  align-items: center;
  padding: 2px 15px 2px 15px;
`;

export const StatusName = styled.span<{
  checked: boolean;
}>`
  position: absolute;
  display: flex;
  align-items: center;
  color: ${(props) =>
    props.checked ? props.theme.palette.khaki : props.theme.palette.blue};
  font-weight: bold;
  font-size: 13px;
  > span {
    width: 100vw;
    font-size: 10px;
    text-align: center;
    letter-spacing: -0.2em;
  }
`;

// 合計・amount
export const ListCell = styled.div<{
  disable?: boolean;
  checkState?: boolean;
  notEqual?: boolean;
  shortage?: boolean;
}>`
  height: 52px;
  width: 80px;
  box-sizing: border-box;
  padding: 0.5rem 0;
  border-spacing: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  background: ${(props) =>
    props.notEqual
      ? '#FDDEDE !important'
      : props.checkState
      ? '#e0e0e0 !important'
      : ''};

  :first-child,
  :nth-child(2) {
    position: -webkit-sticky; /* for Safari */
    /* position: sticky; */
    left: 250px;
    z-index: 2;
    border-right: 1px solid #c0c0c0;
    background: #fff !important;
  }

  &.picking-amount {
    cursor: ${(props) => (props.disable ? '' : 'pointer')};
    color: ${(props) => (props.shortage ? props.theme.palette.error : '')};
  }
  &.stop-shipping {
    color: ${(props) => props.theme.palette.error};
    cursor: ${(props) => (props.disable ? '' : 'pointer')};
  }
`;

export const ListBody = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  z-index: 2;
  position: relative;
  height: max-content;

  & > div:nth-child(odd) {
    background: ${(props) => props.theme.palette.paleYellow};
  }

  & > div:nth-child(even) {
    background: ${(props) => props.theme.palette.white};
  }

  & > div:not(:first-child):nth-child(odd) > div:nth-child(odd) {
    background: ${(props) => props.theme.palette.yellowGray};
    /* background: ${(props) => props.theme.palette.paleYellow}; */
  }
  & > div:not(:first-child):nth-child(even) > div:nth-child(even) {
    background: ${(props) => props.theme.palette.beige};
  }
  & > div:not(:first-child):nth-child(odd) > div:nth-child(even) {
    background: ${(props) => props.theme.palette.paleYellow};
    /* background: ${(props) => props.theme.palette.yellowGray}; */
  }
`;

export const ListRow = styled.div`
  display: flex;
  background: #fff;
  box-sizing: border-box;

  :first-child {
    position: -webkit-sticky; /* for Safari */
    position: sticky;
    top: 0px;
    z-index: 4;
    height: max-content;
    box-shadow: 1px 3px 5px 0px rgba(0, 0, 0, 0.2);
  }
`;

export const ListSidebar = styled.div`
  max-width: 250px;
  border-right: 1px solid ${(props) => props.theme.palette.yellowGray};
  background: #fff;
  background: ${(props) => props.theme.palette.beige};
  position: -webkit-sticky; /* for Safari */
  position: sticky;
  left: -1px;
  z-index: 5;
  height: max-content;
`;

export const ListLeftHeadSearchCell = styled.div`
  height: 200px;
  min-width: 200px;
  border-bottom: 1px solid ${(props) => props.theme.palette.yellowGray};
  box-shadow: 4px -2px 5px 1px rgb(0 0 0 / 20%);
  position: sticky;
  position: -webkit-sticky; /* for Safari */
  top: 0;
  z-index: 4;
  -webkit-transform: translateZ(0);
  color: ${(props) => props.theme.palette.baseBlack};
  background-color: ${(props) => props.theme.palette.yellowGray};
  display: flex;
  justify-content: center;
  padding: 0 0.5rem;
  gap: 1rem;
  flex-direction: column;
`;

export const FlexColumn = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 0.5rem;
  gap: 0.25rem;
`;

export const ListLeftHeadCell = styled.div<{
  searchCell?: boolean;
}>`
  height: 52px;
  border-bottom: 1px solid ${(props) => props.theme.palette.yellowGray};
  box-shadow: ${(props) =>
    props.searchCell ? '' : '4px 3px 5px 0px rgba(0, 0, 0, 0.2)'};
  border-radius: ${(props) => (props.searchCell ? '8px' : '')};
  border: ${(props) => (props.searchCell ? '1px solid #C0C0C0' : '')};
  position: -webkit-sticky; /* for Safari */
  color: ${(props) => props.theme.palette.baseBlack};
  display: flex;
  flex-direction: row;
  justify-content: center;
  padding: 6px 0.75rem;
  gap: 0.25rem;

  > div > span {
    font-size: 0.75rem;
    color: rgba(51, 51, 51, 0.5);
  }

  :nth-child(odd) {
    background: ${(props) => props.theme.palette.paleYellow};
  }
  :nth-child(even) {
    background: ${(props) => props.theme.palette.white};
  }
  :first-child {
    position: -webkit-sticky; /* for Safari */
    position: sticky;
    top: -1px;
    padding: 2px;
    background: ${(props) => props.theme.palette.yellowGray};
    z-index: 1;
    height: max-content;
  }
  &.filtered-products-wrapper {
    cursor: pointer;
  }
  > div.filtered-products {
    cursor: pointer;
    align-items: center;
    justify-content: center;
    display: flex;
    color: ${(props) => props.theme.palette.blue};
    > div.filtered-products-text {
      cursor: pointer;
      padding-top: 3px;
    }
  }
  > div.not-filtered-products {
    cursor: pointer;
    align-items: center;
    justify-content: center;
    display: flex;
    color: ${(props) => props.theme.palette.khaki};
  }
`;

export const AddProductButton = styled.div<{
  width: number;
  rightSlideSidebarActive: boolean;
}>`
  cursor: ${(props) => (props.rightSlideSidebarActive ? '' : 'pointer')};
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: 700;
  padding: 1rem;
  color: ${(props) => props.theme.palette.blue};
  background: ${(props) => props.theme.palette.beige} !important;
`;
