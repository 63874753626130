import { useState } from 'react';
import moment from 'moment';
import { Heading } from '@components/elements/typographies/Heading';
import * as S from './PickingRoot.styles';
import { WithHeader } from '@components/templates/WithHeader';
import TableWithFilter from '@components/modules/pc/picking/TableWithFilter/TableWithFilter';
import RSingleDatePicker from '@components/elements/inputs/RSingleDatepicker/RSingleDatepicker';
import { useWindowScroll } from 'react-use';

export const PickingRoot = () => {
  const [date, setDate] = useState(moment());
  const [datePickerInactive, setDatePickerInactive] = useState(false);
  const { y: currentScrollPosition } = useWindowScroll();
  console.log('---currentScrollPosition---', currentScrollPosition);

  return (
    <WithHeader>
      <S.Wrapper>
        <div>
          <S.HeadContainer>
            <div>
              <Heading tag="span" text="ピッキングの進捗" fs="32" fw="lg" />
              <Heading tag="span" text="を確認" fs="24" fw="lg" />
            </div>
            <div>
              <RSingleDatePicker
                date={date}
                setDate={setDate}
                invalid={datePickerInactive}
              />
            </div>
          </S.HeadContainer>
        </div>
        <div>
          <TableWithFilter setDatePickerInactive={setDatePickerInactive} />
        </div>
      </S.Wrapper>
    </WithHeader>
  );
};
