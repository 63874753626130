import { FC } from 'react';
import * as S from './ConfirmPopUp.styles';
import { Button } from '@components/elements/buttons/Button';
import { NotCheckedInfo } from '@lib/mobile/picking_by_shipping/type';

type Props = {
  fromPc: boolean;
  middleOfPcScreen?: boolean;
  confirmMsg: string;
  width?: string;
  zIndex?: number;
  fontSize?: string;
  notCheckedInfo?: NotCheckedInfo[];
  isPortraitAndTablet?: boolean;
  handleOk: () => void;
  handleCancel: () => void;
};

const ConfirmPopUp: FC<Props> = ({
  fromPc,
  middleOfPcScreen,
  confirmMsg,
  width,
  zIndex,
  fontSize,
  notCheckedInfo,
  isPortraitAndTablet,
  handleOk,
  handleCancel,
}: Props) => {
  return (
    <>
      <S.Wrapper
        open={confirmMsg}
        fromPc={fromPc}
        middleOfPcScreen={middleOfPcScreen}
        width={width}
        zIndex={zIndex}
        fontSize={fontSize}
        isPortraitAndTablet={isPortraitAndTablet}
      >
        {confirmMsg}
        {notCheckedInfo && <br />}
        {notCheckedInfo &&
          notCheckedInfo.map((p: NotCheckedInfo, i: number) =>
            notCheckedInfo.length === i + 1 ? (
              <span key={i + p.destinationName + p.productName}>
                {p.productName}({p.destinationName})
              </span>
            ) : (
              <span key={i + p.destinationName + p.productName}>
                {p.productName}({p.destinationName})、
                <br />
              </span>
            )
          )}
        <S.ButtonContainer>
          <Button outlined={true} padding="md" onClick={() => handleCancel()}>
            キャンセル
          </Button>
          <Button padding="md" onClick={() => handleOk()}>
            OK
          </Button>
        </S.ButtonContainer>
      </S.Wrapper>
    </>
  );
};

export default ConfirmPopUp;
