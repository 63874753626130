import * as S from './MailModal.styles';
import { ReactComponent as CloseIcon } from '@assets/icons/close.svg';
import { Heading } from '@components/elements/typographies/Heading';
import { Button } from '@components/elements/buttons/Button';
import CircularProgressComponent from '@components/modules/pc/purchase_orders/CircularProgressComponent/CircularProgressComponent';
import { hasDuplicates } from '@lib/pc/common/functions';
import { areAllValidEmails } from '@lib/pc/purchase_orders/functions';
import { Email } from '@lib/pc/purchase_orders/type';

type Props = {
  sending: boolean;
  newEmail: Email;
  confirmationDate: string;
  onClickSubmit: () => void;
  setNewEmail: (newEmail: Email) => void;
  setErrMsg: (errMsg: string) => void;
  handleCloseEmailModal: () => void;
};

const MailModal = ({
  sending,
  newEmail,
  confirmationDate,
  onClickSubmit,
  setNewEmail,
  setErrMsg,
  handleCloseEmailModal,
}: Props) => {
  const handleChange = (
    e:
      | React.ChangeEvent<HTMLInputElement>
      | React.ChangeEvent<HTMLTextAreaElement>
  ) => {
    if (e.target.value === '') {
      setNewEmail({ ...newEmail, [e.target.name]: '' });
    } else {
      setNewEmail({ ...newEmail, [e.target.name]: e.target.value });
    }
  };

  // カンマ区切りを配列へ変更
  const handleSetMailAddress = () => {
    const dividedAddress = newEmail.emailAddressesForEdit.split(/[,\s]+/);
    setNewEmail({
      ...newEmail,
      emailAddresses: dividedAddress
        ? dividedAddress
        : newEmail.emailAddresses
        ? newEmail.emailAddresses
        : [],
    });
  };

  const handleSetCcMailAddress = () => {
    const dividedAddress = newEmail.ccEmailAddressesForEdit.split(/[,\s]+/);
    setNewEmail({
      ...newEmail,
      ccEmailAddresses: dividedAddress
        ? dividedAddress
        : newEmail.ccEmailAddresses
        ? newEmail.ccEmailAddresses
        : [],
    });
  };

  // メールアドレス末尾のカンマチェック
  const hasTrailingComma = (str: string): boolean => {
    const regex = /,\s*$/;
    return regex.test(str);
  };

  // 送信ボタン押下時
  const mailAddressCheck = () => {
    const mailAddresses = [
      ...newEmail.emailAddresses,
      ...newEmail.ccEmailAddresses,
    ];
    if (!areAllValidEmails(mailAddresses)) {
      setErrMsg('メールアドレスとして適切ではないものが含まれています。');
    } else if (hasTrailingComma(newEmail.emailAddressesForEdit)) {
      setErrMsg('送信するメールアドレスの末尾のカンマを削除してください。');
    } else if (hasTrailingComma(newEmail.ccEmailAddressesForEdit)) {
      setErrMsg('送信するメールアドレスの末尾のカンマを削除してください。');
    } else if (hasDuplicates(mailAddresses)) {
      setErrMsg('入力されたメールアドレスに重複があります。');
    } else {
      // メール送信
      onClickSubmit();
    }
  };

  return (
    <S.ModalWrapper>
      <S.CloseIconWrapper>
        <CloseIcon onClick={() => handleCloseEmailModal()} />
      </S.CloseIconWrapper>
      <S.ModalHeadWrapper>
        <Heading text="メール送信" fs="24" fw="lg" />
      </S.ModalHeadWrapper>
      <S.ModalFormLabel>件名</S.ModalFormLabel>
      <S.Input
        type="text"
        name="subject"
        id="subject"
        autoComplete="new-password"
        align="left"
        padding="0 10px"
        value={newEmail?.subject || ''}
        onChange={(e) => handleChange(e)}
      />
      <S.ModalFormLabel>TO</S.ModalFormLabel>
      <S.Input
        type="text"
        name="emailAddressesForEdit"
        id="emailAddressesForEdit"
        autoComplete="new-password"
        align="left"
        padding="0 10px"
        value={newEmail.emailAddressesForEdit}
        onChange={(e) => handleChange(e)}
        onBlur={handleSetMailAddress}
      />
      <S.ModalFormLabel>CC</S.ModalFormLabel>
      <S.Input
        type="text"
        name="ccEmailAddressesForEdit"
        id="ccEmailAddressesForEdit"
        autoComplete="new-password"
        align="left"
        padding="0 10px"
        value={newEmail.ccEmailAddressesForEdit}
        onChange={(e) => handleChange(e)}
        onBlur={handleSetCcMailAddress}
      />
      <S.ModalFormLabel>本文</S.ModalFormLabel>
      <S.ModalFormTextArea
        name="emailBody"
        value={newEmail?.emailBody}
        onChange={(e) => handleChange(e)}
      />
      {!confirmationDate && (
        <S.ConfirmationDateText>
          ※注文書の発行日を本日付で作成します。
        </S.ConfirmationDateText>
      )}
      <S.ModalButtonWrapper>
        <Button
          disabled={sending}
          width="100px"
          onClick={() => mailAddressCheck()}
        >
          送信
        </Button>
      </S.ModalButtonWrapper>
      <CircularProgressComponent sending={sending} />
    </S.ModalWrapper>
  );
};

export default MailModal;
