import { useHistory, useLocation } from 'react-router-dom';
import { ReactComponent as ChevronLeftIcon } from '@assets/icons/mobile/chevron_left.svg';
import * as S from './TitleBar.styles';
import { MATERIAL_TYPE } from '@lib/common/type';

const TitleBar = ({
  materialType,
  materialName,
  isChanged,
  setConfirmMsg,
  setConfirmKind,
  setPopUp,
}: {
  materialType: number;
  materialName: string;
  isChanged: boolean;
  setConfirmMsg: (confirmMsg: string) => void;
  setConfirmKind: (confirmKind: string) => void;
  setPopUp: (popUp: boolean) => void;
}) => {
  const history = useHistory();
  const { state } = useLocation();

  const onClickGoBack = () => {
    if (isChanged) {
      setConfirmMsg('保存せずに編集を終了します。よろしいですか。');
      setConfirmKind('ProductList');
      setPopUp(false);
    } else {
      history.push(`/mobile/materials/for_stocks`, state);
    }
  };

  return (
    <S.Wrapper>
      <S.IconWrapper
        onClick={() => {
          onClickGoBack();
        }}
      >
        <S.BackButton>
          <ChevronLeftIcon />
        </S.BackButton>
      </S.IconWrapper>
      <S.TitleText>
        <S.MaterialIcon
          packaged={materialType === MATERIAL_TYPE.MATERIAL ? false : true}
        >
          {materialType === MATERIAL_TYPE.MATERIAL ? '資' : '原'}
        </S.MaterialIcon>
        {materialName}
      </S.TitleText>
      {/* 一旦非表示 */}
      {/* <S.StockListLink
        to={`/mobile/products/${productId}/stocks/${productKind}`}
      >
        <TableChartBlueIcon />
      </S.StockListLink> */}
    </S.Wrapper>
  );
};
export default TitleBar;
