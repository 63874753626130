import { axiosInstance } from '@lib/pc/common/api/axiosConfig';
import { useQuery } from 'react-query';
import humps from 'humps';
import type { Todo } from '@lib/common/type';
// import { MaterialOrders } from '@lib/pc/purchase_orders/type';

const useManufacture = () => {
  return useQuery('manufactures', async () => {
    const { data } = await axiosInstance().get('/api/v1/manufactures');
    return humps.camelizeKeys(data) as unknown as Todo;
  });
};

export default useManufacture;
