import styled from 'styled-components';

export const ListSidebar = styled.div`
  min-width: 300px;
  height: max-content;
  border-right: 1px solid ${(props) => props.theme.palette.yellowGray};
  background: #fff;
  background: ${(props) => props.theme.palette.beige};

  position: absolute;
  left: 0;
  z-index: 5;

  & > div:first-child {
    background: ${(props) => props.theme.palette.paleYellow};

    position: -webkit-sticky; /* for Safari */
    position: sticky;
    top: -1px;
    padding: 1px;
    background: ${(props) => props.theme.palette.yellowGray};
    z-index: 1000;

    position: sticky;
    left: 0;
    z-index: 1;
  }
`;

// 商品名、商品コードラッパー
export const ListLeftHeadProductCell = styled.div<{
  invalid?: boolean;
  productIndex: number;
}>`
  box-shadow: 4px 3px 5px 0px rgba(0, 0, 0, 0.05);
  padding-left: 0.75rem;
  pointer-events: ${(props) => (props.invalid ? 'none' : '')};
  background: ${(props) =>
    props.productIndex % 2 === 0
      ? props.theme.palette.white
      : props.theme.palette.paleYellow};
`;

export const ListTbody = styled.div`
  display: flex;
`;

// 商品名、商品コード
export const ListLeftHeadNameAndCode = styled.div<{
  productIndex: number;
}>`
  width: 205px;
  vertical-align: middle;
  border-right: 1px solid #efefef;
  font-size: 0.9rem;
  display: flex;
  align-items: center;
  cursor: pointer;
  /* border-bottom: 2px solid ${(props) => props.theme.palette.sandGray}; */
  background: ${(props) =>
    props.productIndex % 2 === 0
      ? props.theme.palette.white
      : props.theme.palette.paleYellow};
`;

export const ListProductNameAndCode = styled.div`
  display: block;
  width: 100%;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  margin-left: 0.75rem;
`;

export const ListProductName = styled.div`
  color: ${(props) => props.theme.palette.blue};
`;

// 表示タイプ
export const ListLeftHeadTypeName = styled.div<{
  notDisplay: boolean;
  productIndex: number;
  typeIndex: number;
}>`
  width: 100px;
  height: 30px;
  font-size: 15px;
  color: #6b675c;
  text-align: left;
  padding-left: 1.1rem;
  vertical-align: middle;
  display: ${(props) => (props.notDisplay ? 'none' : 'flex')};
  border-bottom: 1px solid #efefef;
  align-items: center;
  background: ${(props) =>
    props.productIndex % 2 === 0
      ? props.typeIndex % 2 === 0
        ? props.theme.palette.white
        : props.theme.palette.paleYellow
      : props.typeIndex % 2 === 0
      ? props.theme.palette.paleYellow
      : props.theme.palette.white};
`;
