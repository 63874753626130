import * as S from './FactoryLineTab.styles';
import { factoryLineColors } from '@lib/pc/common/type';
import { CircularProgress } from '@material-ui/core';
import { factory } from '@lib/pc/stacker/useStackers';

interface Props {
  selectedFactoryData: factory;
  selectedLineId: number;
  setSelectedLineId: (selectedLineId: number) => void;
  setTabColor: (tabColor: number) => void;
  setPopUp: (popUp: boolean) => void;
}

const FactoryLineTab = ({
  selectedFactoryData,
  selectedLineId,
  setSelectedLineId,
  setTabColor,
  setPopUp,
}: Props) => {
  const valuesOfFactoryLineColors = Object.values(factoryLineColors);

  return (
    <S.Wrapper>
      {selectedFactoryData ? (
        <>
          {selectedFactoryData.lines.map((d, i) => (
            <S.TabArea
              key={d.factoryLineName + d.factoryLineId}
              onClick={() => {
                setSelectedLineId(d.factoryLineId);
                setTabColor(i);
                setPopUp(false);
              }}
            >
              <S.Tab
                key={d.factoryLineName + d.factoryLineId}
                tabCount={selectedFactoryData.lines.length}
                active={d.factoryLineId == selectedLineId}
                onClick={() => setSelectedLineId(d.factoryLineId)}
                bgColor={valuesOfFactoryLineColors[i]}
              >
                {d.factoryLineName}
              </S.Tab>
            </S.TabArea>
          ))}
        </>
      ) : (
        <S.CircularIconWrapperOuter>
          <S.CircularIconWrapper>
            <CircularProgress style={{ color: '#64b2f9' }} />
          </S.CircularIconWrapper>
        </S.CircularIconWrapperOuter>
      )}
    </S.Wrapper>
  );
};

export default FactoryLineTab;
