import { useState } from 'react';
import 'react-dates/initialize';
import { SingleDatePicker } from 'react-dates';
import 'react-dates/lib/css/_datepicker.css';
import * as S from './DateSelector.styles';
import { ReactComponent as ArrowLeft } from '@assets/icons/arrow_left_24px.svg';
import { ReactComponent as CalendarSmall } from '@assets/icons/calendar_small.svg';
import ArrowRight from '@assets/icons/arrow_right_24px';
import moment from 'moment';

interface Props {
  date: Date;
  getBeforeDate: (date: Date) => void;
  getNextDate: (date: Date) => void;
  setDate: (date: Date) => void;
  capableSelectFutureDate?: boolean;
}

//日曜が0、土曜日が6。配列を使い曜日に変換する。
const dateT = ['日', '月', '火', '水', '木', '金', '土'];
const today = new Date();

const DateSelector = ({
  date,
  getBeforeDate,
  getNextDate,
  setDate,
  capableSelectFutureDate,
}: Props) => {
  const [focused, setFocused] = useState(false);

  return (
    <S.DateWrapper>
      <S.ArrowLeft onClick={() => getBeforeDate(date)}>
        <ArrowLeft />
      </S.ArrowLeft>
      <S.HiddenDatePicker>
        <SingleDatePicker
          date={moment(date)}
          isOutsideRange={(day) =>
            !capableSelectFutureDate && day.isAfter(today)
          }
          onDateChange={(selectedDate) => {
            if (selectedDate) {
              setDate(selectedDate.toDate());
            }
          }}
          numberOfMonths={1}
          focused={focused}
          onFocusChange={({ focused }) => setFocused(focused)}
          showDefaultInputIcon={false}
          customInputIcon={
            <S.Date>
              {date.getFullYear()}/
              {(date.getMonth() + 1).toString().padStart(2, '0')}/
              {date.getDate().toString().padStart(2, '0')}(
              {dateT[date.getDay()]})
              <CalendarSmall />
            </S.Date>
          }
          id="date_input"
        />
      </S.HiddenDatePicker>
      <S.ArrowRight
        isActive={
          capableSelectFutureDate
            ? capableSelectFutureDate
            : moment(date).format('YYYY/MM/DD') !==
              moment(today).format('YYYY/MM/DD')
        }
        onClick={() => getNextDate(date)}
      >
        <ArrowRight
          isActive={
            capableSelectFutureDate
              ? capableSelectFutureDate
              : moment(date).format('YYYY/MM/DD') !==
                moment(today).format('YYYY/MM/DD')
          }
        />
      </S.ArrowRight>
    </S.DateWrapper>
  );
};

export default DateSelector;
