import { WithHeader } from '@components/templates/WithHeader';
import * as S from './CustomerMasterRoot.styles';
import CustomerHeaderControl from '@components/modules/pc/settings/customer_setting/CustomerHeaderControl/CustomerHeaderControl';
import TableWithFilter from '@components/modules/pc/settings/customer_setting/TableWithFilter/TableWithFilter';
import useCustomers from '@lib/pc/settings/customer_setting/useCustomers';

export const CustomerMasterRoot = () => {
  const { customers: customers } = useCustomers();

  return (
    <WithHeader>
      {/* 得意先管理 */}
      <S.Wrapper>
        <CustomerHeaderControl />
      </S.Wrapper>
      {customers && <TableWithFilter customers={customers} />}
    </WithHeader>
  );
};
