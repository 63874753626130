import { isIPad13 } from 'react-device-detect';
import * as S from './SubmitButton.styles';

type Props = {
  children: string;
  disabled: boolean;
  outlined?: boolean;
  fontSize?: string;
  onClick: () => void;
};

const SubmitButton = ({
  children,
  disabled,
  outlined,
  fontSize,
  onClick,
}: Props) => {
  return (
    <S.Wrapper
      id="submit-button"
      disabled={disabled}
      outlined={outlined}
      fontSize={fontSize}
      onClick={() => {
        if (!isIPad13) {
          onClick();
        }
      }}
      onTouchStart={() => {
        if (isIPad13) {
          onClick();
        }
      }}
    >
      {children}
    </S.Wrapper>
  );
};

export default SubmitButton;
