import { useHistory, useLocation } from 'react-router';
import * as S from './CompleteModal.styled';
import { Button } from '@components/elements/buttons/Button';
import { Todo } from '@lib/common/type';

type Props = {
  confirmMsg: string;
  setConfirmMsg: (confirmMsg: string) => void;
};

const CompleteModal = ({ confirmMsg, setConfirmMsg }: Props) => {
  const { state } = useLocation<Todo>();
  const history = useHistory();

  return (
    <S.ModalWrapper open={confirmMsg ? true : false}>
      <S.ModalTextContainer>
        <span>{confirmMsg}</span>
      </S.ModalTextContainer>
      <S.ModalButtonContainer>
        <Button
          padding="sm"
          onClick={() => {
            setConfirmMsg('');
            history.push(
              `/mobile/products_photograph_list/${state.stateCameraId}`,
              {
                ...state,
                stateConfirmMsg: '',
              }
            );
          }}
        >
          OK
        </Button>
      </S.ModalButtonContainer>
    </S.ModalWrapper>
  );
};

export default CompleteModal;
