import styled from 'styled-components';

export const FormMultiColumnContainer = styled.div`
  display: flex;
  gap: 0.5rem;
`;
export const FormLeftContainer = styled.div`
  flex-basis: 70%;
  > input {
    &#supplierName {
      border: none;
    }
  }
`;
export const FormRightContainer = styled.div`
  flex-basis: 30%;
`;

export const SupplierLabel = styled.div`
  display: flex;
`;

export const FormLabel = styled.div`
  margin-top: 20px;
  font-weight: bold;
  width: 100%;
`;

export const HonorificLabel = styled.div`
  margin-top: 20px;
  font-weight: bold;
  width: 25%;
`;

export const Input = styled.input<{
  align?: string;
  padding?: string;
  height?: string;
  width?: string;
}>`
  width: ${(props) => (props.width ? props.width : '100%')};
  height: 40px;
  color: ${(props) => props.theme.palette.baseBlack};
  font-size: 1rem;
  padding: ${(props) => (props.padding ? props.padding : '0.325rem')};
  text-align: ${(props) => props.align};
  border: none;
  border-radius: 0.5rem;
  border: 1px solid rgba(0, 0, 0, 0.08);
  height: ${(props) => (props.height ? props.height : '')};
  background: ${(props) => props.theme.palette.white};
  margin-top: 8px;
  ::-webkit-inner-spin-button {
    -webkit-appearance: none;
  }
  &#supplierName {
    background: ${(props) => props.theme.palette.beige};
  }
`;

export const SupplierWrap = styled.div`
  display: flex;
`;

export const SupplierSelectWrap = styled.div`
  margin-top: 8px;
  width: 80%;
`;

export const SuppliersHonorific = styled.div`
  margin-left: 0.5rem;
  width: 20%;
`;

export const FormTextArea = styled.textarea`
  width: 100%;
  min-height: 6rem;
  color: ${(props) => props.theme.palette.baseBlack};
  font-size: 1rem;
  line-height: 1.25rem;
  padding: 0.325rem;
  border: none;
  border-radius: 0.5rem;
  border: 1px solid rgba(0, 0, 0, 0.08);
  background: ${(props) => props.theme.palette.white};
  word-wrap: break-word;
  word-break: break-all;
  white-space: normal;
  margin-top: 8px;
`;
