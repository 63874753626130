import styled from 'styled-components';
import schedule_icon from '../../../../../assets/icons/schedule.svg';
import ArrowDropDown from '@assets/icons/arrow_drop_down.svg';

export const Wrapper = styled.div<{
  open?: boolean;
}>`
  width: 100vw;
  top: ${(props) => (props.open ? '0' : '100%')};
  position: fixed;
  z-index: 10000;
  overflow-x: hidden;
  background-color: #f8f7f4;
`;

export const Line = styled.div<{
  bgColor: string;
}>`
  width: 100vw;
  height: 64px;
  background-color: ${(props) => props.bgColor};
  padding-top: 16px;
  font-family: 'Noto Sans JP';
  font-style: normal;
  font-size: 32px;
  line-height: 32px;
  color: #ffffff;
  font-weight: 700;
  align-items: center;
  text-align: center;
`;

export const Table = styled.table`
  width: 100%;
  border-collapse: collapse;
  border-spacing: 0;
  font-size: 0.875rem;
  table-layout: auto;
`;

export const THead = styled.tr`
  background-color: #f8f7f4;
  height: 28px;
  td {
    vertical-align: bottom;
  }
`;

export const TRow = styled.tr`
  // cursor: pointer;
  background-color: #f8f7f4;
  height: 73px;
`;

export const TCellOrder = styled.td<{
  marginRight?: string;
}>`
  text-align: left;
  padding: 1rem 0rem 1rem 0.625rem;
  margin-right: ${(props) => (props.marginRight ? props.marginRight : '')};
  vertical-align: middle;
  border: 0;
`;

export const TCell = styled.td<{
  align?: string;
  width?: string;
  marginRight?: string;
  background?: string;
  wordBreak?: string;
  fontSize?: string;
  padding?: string;
  labelPadding?: string;
}>`
  position: relative;
  font-size: ${(props) => (props.fontSize ? props.fontSize : '14px')};
  vertical-align: middle;
  word-break: ${(props) => (props.wordBreak ? props.wordBreak : '')};
  width: ${(props) => (props.width ? props.width : '7.25rem')};
  text-align: ${(props) => (props.align ? props.align : 'left')};
  margin-right: ${(props) => (props.marginRight ? props.marginRight : '')};
  background: ${(props) => (props.background ? props.background : '')};
  word-spacing: 100vw;
  padding: ${(props) => (props.padding ? props.padding : '0 0.25rem')};
  border: 0;

  &.label {
    font-weight: bold;
    vertical-align: middle;
    padding: ${(props) =>
      props.labelPadding ? props.labelPadding : '10px 0 10px 0.7rem'};
  }

  &.delete-btn {
    cursor: pointer;
    text-align: center;
    vertical-align: middle;
    padding-left: 8px;
    background: white;
  }

  &.display-quantity {
    &--total {
      .inner {
        width: 100%;
        min-height: 4rem;
        padding: 0 20px 0 0;
        border: solid 4px white;
        border-radius: 0.5rem;
        text-align: right;
        position: relative;
        .unit {
          position: absolute;
          bottom: 8px;
          right: 4px;
        }
      }
    }
  }
  /* 数量入力 */
  .display-quantity__inner-wrap {
    display: flex;
    flex-direction: row;
    align-items: center;
    /* inputとx数量のwrap内 */
    .cell-content {
      position: relative;
      .unit {
        position: absolute;
        bottom: 8px;
        right: 4px;
      }
    }
    .symbol {
      margin-left: 6px;
    }
    .inner {
      align-items: center;
      font-size: 1rem;
      display: flex;
      text-align: center;
      padding-left: 20px;
    }
  }
  > svg {
    display: inline;
    width: 18px;
    height: 18px;
  }

  > span {
    position: absolute;
    right: 1rem;
    top: 60%;
    font-size: 10px;
    margin-left: 4px;
  }
`;

// 編集モーダルtableヘッダセル
export const THeadCell = styled(TCell)<{
  align?: string;
  width?: string;
  wordBreak?: string;
  colSpan?: number;
  padding?: string;
  right?: string;
}>`
  font-weight: 500;
  word-break: ${(props) => (props.wordBreak ? props.wordBreak : '')};
  padding: ${(props) => (props.padding ? props.padding : '0 0.25rem')};
  right: ${(props) => (props.right ? props.right : '')};
`;

export const CameraCell = styled(TCell)`
  cursor: pointer;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  right: 17px;
  width: 77px;
  min-height: 4rem;
  line-height: 1.25rem;
  padding: 0.25rem;
  margin-left: 4px;
  margin-top: 6px;
  border-radius: 0.5rem;
  border: 1px solid rgba(0, 0, 0, 0.08);
  background: ${(props) => props.theme.palette.white};
  svg {
    width: 22px;
    height: 22px;
  }
`;

export const Amount = styled.span`
  background-color: #ffffff;
`;

// 製品名、シール性チェック
export const TCellButtonWrapper = styled.select<{
  disabled?: boolean;
}>`
  vertical-align: middle;
  background: ${(props) => (props.disabled ? '#f8f7f4' : '#ffffff')};
  display: flex;
  align-items: center;
  padding: 0 0 0 1rem;
  width: 100%;
  border-radius: 8px;
  border: ${(props) => (props.disabled ? '' : '1px solid rgba(0, 0, 0, 0.08)')};
  -webkit-appearance: none; /* ベンダープレフィックス(Google Chrome、Safari用) */
  -moz-appearance: none; /* ベンダープレフィックス(Firefox用) */
  appearance: none; /* 標準のスタイルを無効にする */
  ${(props) =>
    props.disabled ? 'background-image: url(${ArrowDropDown})' : ''}
  background-image: url(${ArrowDropDown});
  background-position: right 5px center;
  background-repeat: no-repeat;
  cursor: ${(props) => (props.disabled ? '' : 'pointer')};
  &.product-name {
    min-height: 4rem;
    /* height: 60px; */
  }
  &.seal-check {
    height: 64px;
  }
  .disabled {
    pointer-events: ${(props) => (props.disabled ? 'none' : '')};
  }
`;

export const DeleteButton = styled.div`
  font-size: 1rem;
`;

export const CameraButton = styled.div`
  font-size: 1rem;
`;

export const ButtonWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: ${(props) => props.theme.palette.beige};
  width: 100%;
  height: 83px;
  border-top: solid 1px #e6e1dd;
`;

export const ButtonContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  & > button {
    width: 136px;
  }
  & > button:first-child {
    margin-right: 1rem;
  }
`;

export const Input = styled.input<{
  align?: string;
  padding?: string;
  height?: string;
  disabled?: boolean;
}>`
  width: 100%;
  min-height: 4rem;
  color: ${(props) => props.theme.palette.baseBlack};
  font-size: 1rem;
  line-height: 1.25rem;
  padding: ${(props) => (props.padding ? props.padding : '0.325rem')};
  text-align: ${(props) => props.align};
  border: none;
  border-radius: 0.5rem;
  border: 1px solid rgba(0, 0, 0, 0.08);
  height: ${(props) => (props.height ? props.height : '')};
  background: ${(props) => (props.disabled ? '' : props.theme.palette.white)};
  ::-webkit-inner-spin-button {
    -webkit-appearance: none;
  }
  &#bestBeforeDate {
    width: 94%;
  }
`;

export const CommentWrapper = styled.tr`
  display: flex;
  padding: 1rem;
  .label {
    width: 22px;
    font-weight: bold;
    vertical-align: middle;
    padding: 10px 0 10px 0.7re;
    display: flex;
    align-items: center;
  }
`;

export const TextareaPackagingComment = styled.textarea`
  position: relative;
  width: calc(100vw - 107px);
  min-height: 4rem;
  color: ${(props) => props.theme.palette.baseBlack};
  font-size: 1rem;
  line-height: 1.25rem;
  padding: 0.325rem;
  border: none;
  border-radius: 0.5rem;
  border: 1px solid rgba(0, 0, 0, 0.08);
  background: ${(props) => props.theme.palette.white};
  word-wrap: break-word;
  word-break: break-all;
  white-space: normal;
  @media (min-width: 1388px) {
    left: -249px;
  }
`;

export const TimeInput = styled(Input)`
  cursor: pointer;
  ::-webkit-calendar-picker-indicator {
    background: none;
  }
  background-image: url(${schedule_icon});
  background-position: right 2px center;
  background-repeat: no-repeat;
  background-size: 15px;
  cursor: pointer;
  padding-right: 15px;
`;

export const DeleteModalWrapper = styled.div<{
  width?: string;
}>`
  background: ${(props) => props.theme.palette.white};
  position: fixed;
  left: 50%;
  top: 50%;
  width: ${(props) => (props.width ? props.width : '')};
  transform: translate(-50%, -50%);
  z-index: 10001;
  box-shadow: 0px 6px 10px rgba(0, 0, 0, 0.07), 0px 1px 18px rgba(0, 0, 0, 0.06),
    0px 3px 5px rgba(0, 0, 0, 0.1);
  border-radius: 8px;
  padding: 1rem;
`;

export const DeleteModalTextContainer = styled.div`
  display: flex;
  flex-direction: column;
  font-weight: 500;
  font-size: 1.13rem;
  line-height: 27px;
`;

export const DeleteModalButtonContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 1rem;
  margin-top: 1rem;
  button {
    width: 30%;
    height: 2.75rem;
    &:nth-child(2) {
      border-color: ${(props) => props.theme.palette.error};
      color: ${(props) => props.theme.palette.error};
    }
  }
`;

export const EmployeeWrapper = styled.div`
  vertical-align: middle;
  background: #ffffff;
  height: 4rem;
  border-radius: 8px;
  padding-top: 10px;
  padding-left: 12px;
  cursor: pointer;
  background-image: url(${ArrowDropDown});
  background-position: right 5px center;
  background-repeat: no-repeat;
  border: 1px solid rgba(0, 0, 0, 0.08);
`;

export const CircularIconWrapper = styled.div`
  z-index: 10006;
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  top: 40%;
  left: 48%;
`;

export const FakeCaseSelect = styled.div`
  width: 15.5rem;
  min-height: 4rem;
  margin-left: 4px;
  border-radius: 0.5rem;
  background-color: hsl(0, 0%, 100%);
  outline: 0.5px solid rgba(0, 0, 0, 0.08);
  /* background-color: ${(props) => props.theme.palette.white}; */
`;
