import { axiosInstance } from '@lib/mobile/common/api/axiosConfig';
import { useQuery } from 'react-query';
import humps from 'humps';
import moment from 'moment';
// import type { Todo } from '@lib/common/type';
import { MaterialStocks } from '@lib/mobile/materials_stocks_edit/type';

export const useMaterialsStocksForOneProduct = (
  materialId: number | string,
  storeroomId: number | string
) => {
  const { data, refetch } = useQuery(
    ['materialStocksForOneProduct', materialId, storeroomId],
    async () => {
      const data = await axiosInstance().get(
        `/api/v1/material_stocks/${materialId}?date=${moment().format(
          'YYYYMMDD'
        )}&storeroom_id=${storeroomId}`
      );

      return data;
    }
  );
  // TODO: typeの設定
  const stocksForOneProduct = humps.camelizeKeys(
    data?.data.stocks
  ) as unknown as MaterialStocks;
  // ) as MaterialStocks[];

  return { stocksForOneProduct, refetch };
};

export default useMaterialsStocksForOneProduct;
