import { FC } from 'react';
import * as S from './StockAllocationTransferSelect.styles';
import { AllocationType, Todo } from '@lib/common/type';
import ReactSelect from 'react-select';
import { NO_OPTION_JAPANESE } from '@lib/pc/common/constants';

type Props = {
  value: number | undefined;
  handleChangeTransfer: (id: number) => void;
  allocationTypeOptions: AllocationType[];
  handleClose: () => void;
};

const StockAllocationTransferSelect: FC<Props> = ({
  value,
  handleChangeTransfer,
  allocationTypeOptions,
  handleClose,
}: Props) => {
  const toReactSelectOptions = (options: { id: number; name: string }[]) =>
    options.map(({ id, name }) => ({
      value: id,
      label: name,
    }));

  const reactSelectOptions = toReactSelectOptions(allocationTypeOptions);
  const currentValue = reactSelectOptions?.find((o) => o.value === value);

  const styles = {
    control: (baseStyles: Todo) => ({
      ...baseStyles,
      width: '237px',
      height: '56px',
      borderRadius: '0.5rem',
      fontWeight: 400,
      textAlign: 'left',
      borderStyle: 'hidden',
      outline: '0.5px solid rgba(0,0,0,0.08)',
      cursor: 'pointer',
      '@media (max-width: 599px)': {
        width: '16rem',
      },
    }),
    menu: (baseStyles: Todo) => ({
      ...baseStyles,
      zIndex: 1002,
      marginTop: '3px',
    }),
    option: (baseStyles: Todo) => ({
      ...baseStyles,
      textAlign: 'left',
      fontWeight: 400,
      fontSize: '15px',
    }),
    menuList: (baseStyles: Todo) => ({
      ...baseStyles,
      maxHeight: '100px',
    }),
    menuPortal: (baseStyles: Todo) => ({
      ...baseStyles,
      zIndex: 999999,
    }),
  };

  return (
    <S.Wrapper>
      <ReactSelect
        menuPortalTarget={document.querySelector('body')}
        options={reactSelectOptions}
        value={currentValue}
        onChange={(option) => {
          option && handleChangeTransfer(option.value);
          handleClose();
        }}
        styles={styles}
        isSearchable={false}
        components={{
          IndicatorSeparator: () => null,
        }}
        noOptionsMessage={() => NO_OPTION_JAPANESE}
        placeholder="払出先の選択"
      />
    </S.Wrapper>
  );
};

export default StockAllocationTransferSelect;
