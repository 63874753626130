import { useState, FC } from 'react';
import { DateRangePicker, FocusedInputShape } from 'react-dates';
import moment from 'moment';
import 'moment/locale/ja';
import 'react-dates/initialize';
import 'react-dates/lib/css/_datepicker.css';
import styled from 'styled-components';

type Props = {
  isSelect: boolean;
  dateRange: {
    startDate: moment.Moment | null;
    endDate: moment.Moment | null;
  };
  handleDateChange: (date: {
    startDate: moment.Moment | null;
    endDate: moment.Moment | null;
  }) => void;
};

const StyledWrapper = styled.div`
  display: flex;
  .DateInput {
    width: 140px;
    height: 47px;
  }
  .DateRangePickerInput__withBorder {
    border-radius: 8px;
    border: 1px solid #dcdcdc;
    overflow: hidden;
  }
  .DateInput_input {
    font-size: 15px;
    font-weight: 400;
    text-align: center;
    cursor: pointer;
  }
  .DateRangePicker_picker {
    z-index: 10 !important;
  }
  .DateRangePickerInput_arrow_svg {
    width: 18px;
    height: 18px;
    margin-top: 4px;
  }
`;

const RDateRangePicker: FC<Props> = ({
  isSelect,
  dateRange,
  handleDateChange,
}: Props) => {
  const [focusedInput, setFocusedInput] = useState<FocusedInputShape | null>(
    null
  );

  const phraseOverrides = {
    calendarLabel: 'Calendar',
    roleDescription: 'datepickerRangeStartDate',
    closeDatePicker: 'datepickerRangeFocusStartDate',
  };

  return (
    <StyledWrapper>
      <DateRangePicker
        isOutsideRange={() => false}
        startDate={dateRange.startDate}
        endDate={dateRange.endDate}
        onDatesChange={handleDateChange}
        focusedInput={focusedInput}
        onFocusChange={(focusedInput) => setFocusedInput(focusedInput)}
        startDatePlaceholderText="開始日"
        endDatePlaceholderText="終了日"
        startDateId="start_date_input_id"
        endDateId="end_date_input_id"
        phrases={phraseOverrides}
        disabled={isSelect}
        readOnly
      />
    </StyledWrapper>
  );
};

export default RDateRangePicker;
