import { useEffect, useState } from 'react';
import * as S from './CaseInfo.styles';
import { ReactComponent as CircleCloseIcon } from '@assets/icons/circle_close.svg';
import { ReactComponent as PencilIcon } from '@assets/icons/pencil.svg';
import ChevronLeft from '@assets/icons/chevron_left';
import ProductImg from '@assets/images/product-img.jpg';
import { Button } from '@components/elements/buttons/Button';
import CaseSelect from '@components/modules/pc/settings/product_master_setting/ProductMasterEdit/CaseSelect/CaseSelect';
import CaseSettingsModal from '@components/modules/pc/settings/common/CaseSettingsModal/CaseSettingsModal';
import DeleteConfirmModal from '@components/modules/common/DeleteConfirmModal/DeleteConfirmModal';
import ErrorMsgPopUp from '@components/modules/common/ErrorMsgPopUp/ErrorMsgPopUp';
import Overlay from '@components/elements/backdrops/Overlay/Overlay';
import { Todo } from '@lib/common/type';
import {
  Case,
  CaseForSetting,
  CaseNameInputParam,
  ProductAndCasesForUpdate,
  ProductAndCasesInfo,
} from '@lib/pc/settings/product_master_setting/type';
import useUpdateSemiProductAndCasesMutation from '@lib/pc/settings/semi_product_master_setting/useUpdateSemiProductsAndCases';
import useCasesForSetting from '@lib/pc/settings/product_master_setting/useCasesForSetting';

type Props = {
  semiProduct: Todo;
  updating: boolean;
  setUpdating: (updating: boolean) => void;
  onClickGoBack: () => void;
  handleProductUpdateSuccess: () => void;
  setIsChanged: (isChanged: boolean) => void;
  productFromInfo: Todo;
  enableToSave: boolean;
  setEnableToSave: (enableToSave: boolean) => void;
  caseEditMode: boolean;
  setCaseEditMode: (caseEditMode: boolean) => void;
};

const CaseInfo = ({
  semiProduct,
  updating,
  setUpdating,
  onClickGoBack,
  setIsChanged,
  handleProductUpdateSuccess,
  enableToSave,
  setEnableToSave,
  caseEditMode,
  setCaseEditMode,
}: Props) => {
  const [caseSettingsOpen, setCaseSettingsOpen] = useState(false);
  // ケース種類
  const [selectedCaseId, setSelectedCaseId] = useState(0);
  const [newPiecesPerBox, setNewPiecesPerBox] = useState<string | null>(
    '' || null
  );
  const [newDisplayOrder, setNewDisplayOrder] = useState<string | null>(
    '' || null
  );

  // ケース編集後のメッセージpopUp
  const [casePopUp, setCasePopUp] = useState(false);
  // ケース追加
  const [newCase, setNewCase] = useState<CaseNameInputParam>({ name: '' });
  // ケース名編集
  const [editCase, setEditCase] = useState<CaseNameInputParam>({ name: '' });
  // ケース名編集開始
  const [isCaseChanged, setIsCaseChanged] = useState(false);
  // ケース編集テキストエリアのopen
  const [editInputOpen, setEditInputOpen] = useState(false);
  // ケース削除確認modal画面の表示
  const [caseDelConfirmOpen, setCaseDelConfirmOpen] = useState(false);
  const [deleteError, setDeleteError] = useState(false);
  // 削除するケースの情報
  const [deleteCase, setDeleteCase] = useState<CaseForSetting>({
    id: 0,
    name: '',
    relation: false,
  });
  // 更新、編集、削除後のpopUpメッセージ種別
  const [msgKind, setMsgKind] = useState('');
  // 下位のコンポーネントに渡しているのがネストの深いオブジェクトなので、強制再レンダリングする必要がある
  const [, setToggleValue] = useState(false);
  const reRender = () => setToggleValue((prev) => !prev);
  // 削除確認メッセージ
  const [confirmMsg, setConfirmMsg] = useState('');
  const [errMsg, setErrMsg] = useState('');

  // 削除No
  const [deleteNo, setDeleteNo] = useState(0);
  const [editOpen, setEditOpen] = useState(false);

  const { cases: allCasesForSetting, refetch } = useCasesForSetting();

  const newDataForUpdate = semiProduct?.semiProductAndCasesInfo
    ? semiProduct?.semiProductAndCasesInfo.map((value: ProductAndCasesInfo) => {
        return {
          productId: semiProduct.semiProduct.id,
          caseId: value.caseId,
          caseName: value.caseName,
          piecesPerBox: value.piecesPerBox,
          displayOrder: value.displayOrder,
          editMode: false,
        };
      })
    : [];

  // 更新に使用
  const [newProductAndCasesArray, setNewProductAndCasesArray] =
    useState<ProductAndCasesForUpdate[]>(newDataForUpdate);

  // caseIdだけ取り出し
  const caseIds = newProductAndCasesArray.map(
    (item: ProductAndCasesForUpdate) => item['caseId']
  );

  // すでに登録済みのケースを除く
  const filteredCases = allCasesForSetting?.filter((value: Case) => {
    if (!caseIds.includes(value.id)) {
      return { caseId: value.id, caseName: value.name };
    }
  });

  const newProductAndCaseIds = newProductAndCasesArray.map(
    (item) => item['caseId']
  );

  // 商品とケーステーブル
  const updateSemiProductAndCasesMutation =
    useUpdateSemiProductAndCasesMutation(
      semiProduct?.id || semiProduct?.semiProduct.id,
      handleProductUpdateSuccess,
      setErrMsg
    );

  // ケースそのものの追加成功時（製品とケースの紐づけ成功ではない）
  const handleSuccessCaseSettings = () => {
    refetch();
    setCasePopUp(true);
    setNewCase({ name: '' });
    setEditCase({ name: '' });
    setIsCaseChanged(false);
    setEditInputOpen(false);
    setCaseDelConfirmOpen(false);
  };

  // ***********************
  // [保存ボタン]押下、更新処理
  // ***********************
  const handleSubmit = () => {
    setUpdating(true);

    updateSemiProductAndCasesMutation.mutate(newProductAndCasesArray);
  };

  const handleChangeNumber = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.value.match(/^[0-9]*$/)) {
      setNewPiecesPerBox(e.target.value);
    }
    setIsChanged(true);
  };

  const handleChangePriority = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.value.match(/^[0-9]*$/)) {
      setNewDisplayOrder(e.target.value);
    }
    setIsChanged(true);
  };

  // 追加ボタン
  const onClickAddButton = () => {
    const selectedCase = filteredCases?.find(
      (c: Todo) => c.id === selectedCaseId
    );

    if (selectedCase) {
      newProductAndCasesArray.push({
        productId: semiProduct.id || semiProduct.semiProduct.id,
        caseId: selectedCase.id,
        caseName: selectedCase.name,
        piecesPerBox: Number(newPiecesPerBox),
        displayOrder: newDisplayOrder ? Number(newDisplayOrder) : '',
        editMode: false,
      });
    }
    setIsChanged(true);
    reRender();
    setSelectedCaseId(0);
    setNewPiecesPerBox(null);
    setNewDisplayOrder(null);
    setEnableToSave(true);
  };

  // 削除ボタン
  const onClickDelButton = (no: number, name: string) => {
    setConfirmMsg(`${name}を本当に削除しますか？`);
    setDeleteNo(no);
  };

  const deleteFunction = () => {
    setIsChanged(true);
    newProductAndCasesArray.splice(deleteNo, 1);
    setNewProductAndCasesArray([...newProductAndCasesArray]);
    setConfirmMsg('');
    setEnableToSave(true);
  };

  const onClickEdit = (index: number) => {
    setEditOpen(!editOpen);
    setCaseEditMode(true);
    newProductAndCasesArray[index].editMode = true;
  };

  const [isEdited, setIsEdited] = useState(false);
  const handleChangePieceUpdate = (
    e: React.ChangeEvent<HTMLInputElement>,
    index: number
  ) => {
    setEnableToSave(true);
    setIsEdited(!isEdited);
    setIsChanged(true);
    if (e.target.value.match(/^[0-9]*$/)) {
      newProductAndCasesArray[index].piecesPerBox = Number(e.target.value);
    }
  };

  const handleChangePriorityUpdate = (
    e: React.ChangeEvent<HTMLInputElement>,
    index: number
  ) => {
    setEnableToSave(true);
    setIsEdited(!isEdited);
    setIsChanged(true);
    if (e.target.value.match(/^[0-9]*$/)) {
      newProductAndCasesArray[index].displayOrder = e.target.value
        ? Number(e.target.value)
        : '';
    }
  };

  useEffect(() => {
    setNewPiecesPerBox(null);
  }, [selectedCaseId]);

  return (
    <>
      {semiProduct && allCasesForSetting && (
        <>
          <S.Wrapper>
            <div className="go-back" onClick={onClickGoBack}>
              <ChevronLeft isBlue={true} />
              <span>一覧画面へ戻る</span>
            </div>

            <S.ProductMasterEditContents>
              <div className="product-info">
                <div className="product-img">
                  <div className="img">
                    <img
                      src={
                        semiProduct?.imageUrl ||
                        semiProduct?.semiProduct?.imageUrl ||
                        ProductImg
                      }
                    />
                  </div>
                </div>
                <div className="name">
                  {semiProduct?.name || semiProduct?.semiProduct?.name}
                </div>
              </div>
              {/* ケース */}
              {/* ケース追加・編集 */}
              <div className="case-list__header">
                <div
                  className="case-list__header--edit-btn"
                  onClick={() => {
                    refetch();
                    setCaseSettingsOpen(true);
                  }}
                >
                  <PencilIcon className="icon" />
                  新規ケースの追加・既存ケースの管理
                </div>
              </div>
              {/* 個数内訳リストのheader */}
            </S.ProductMasterEditContents>
            {/* ここからtable */}
            <S.ProductMasterEditTable>
              <thead>
                <tr>
                  <th></th>
                  <S.CaseTypeTitleTh>ケースの種類</S.CaseTypeTitleTh>
                  <S.PiecesPerBoxTitleTh>
                    1ケースに入る数量
                  </S.PiecesPerBoxTitleTh>
                  <S.PiecesPerBoxTitleTh>
                    ケース表示順(任意)
                  </S.PiecesPerBoxTitleTh>
                  <th></th>
                </tr>
                <tr>
                  <th></th>
                  <th>
                    <CaseSelect
                      cases={filteredCases}
                      value={selectedCaseId}
                      noLabel={true}
                      marginLeft={'0px'}
                      marginTop={'0px'}
                      width={'30rem'}
                      setSelectedCaseId={setSelectedCaseId}
                    />
                    {/* <input type="text" placeholder="ケースの種類" /> */}
                  </th>
                  <th>
                    {/* 1ケースに入る数量 */}
                    <S.Input
                      type="text"
                      inputMode="numeric"
                      name="newPiecesPerBox"
                      id="newPiecesPerBox"
                      autoComplete="off"
                      value={newPiecesPerBox ? newPiecesPerBox : ''}
                      onChange={handleChangeNumber}
                    />
                  </th>
                  <th>
                    {/* ケース表示順 */}
                    <S.Input
                      type="text"
                      inputMode="numeric"
                      name="newPriorityNumber"
                      id="newPriorityNumber"
                      autoComplete="off"
                      value={newDisplayOrder ? newDisplayOrder : ''}
                      onChange={handleChangePriority}
                    />
                  </th>
                  <th>
                    <S.AddButton>
                      <dd>
                        {selectedCaseId && newPiecesPerBox ? (
                          <Button
                            children={'追加'}
                            onClick={() => onClickAddButton()}
                          />
                        ) : (
                          <Button disabled children={'追加'} />
                        )}
                      </dd>
                    </S.AddButton>
                  </th>
                </tr>
              </thead>
              <tbody>
                {newProductAndCasesArray &&
                  newProductAndCasesArray.map(
                    (pc: ProductAndCasesForUpdate, index: number) => {
                      return (
                        <S.SelectedCaseWrapperTr key={index}>
                          {/* 削除ボタン */}
                          <S.DeleteButtonTd>
                            <S.CloseButton
                              onClick={() =>
                                onClickDelButton(index, pc.caseName)
                              }
                            >
                              <CircleCloseIcon />
                            </S.CloseButton>
                          </S.DeleteButtonTd>
                          {/* ケースの種類 */}
                          <S.CaseTypeTd>
                            <span className="product-icon"></span>
                            {pc.caseName}
                          </S.CaseTypeTd>
                          {/* 1ケースに入る数量 */}
                          <S.PiecesPerBoxAndDisplayOrderTd
                            editMode={pc.editMode}
                          >
                            {pc.editMode && caseEditMode ? (
                              <S.PiecesPerBoxAndDisplayOrderInput
                                type="text"
                                inputMode="numeric"
                                className="newPiecesPerBoxEdit"
                                id="newPiecesPerBoxEdit"
                                autoComplete="off"
                                value={pc.piecesPerBox}
                                onChange={(e) =>
                                  handleChangePieceUpdate(e, index)
                                }
                              />
                            ) : (
                              <S.PiecesPerBoxAndDisplayOrderDiv
                                editMode={pc.editMode}
                              >
                                {pc.piecesPerBox}個
                              </S.PiecesPerBoxAndDisplayOrderDiv>
                            )}
                          </S.PiecesPerBoxAndDisplayOrderTd>
                          {/* ケース表示順 */}
                          <S.PiecesPerBoxAndDisplayOrderTd
                            editMode={pc.editMode}
                          >
                            {pc.editMode && caseEditMode ? (
                              <S.PiecesPerBoxAndDisplayOrderInput
                                type="text"
                                inputMode="numeric"
                                className="newPiecesPerBoxEdit"
                                id="newPiecesPerBoxEdit"
                                autoComplete="off"
                                value={pc.displayOrder}
                                onChange={(e) =>
                                  handleChangePriorityUpdate(e, index)
                                }
                              />
                            ) : (
                              <S.PiecesPerBoxAndDisplayOrderDiv
                                editMode={pc.editMode}
                              >
                                {pc.displayOrder}
                              </S.PiecesPerBoxAndDisplayOrderDiv>
                            )}
                          </S.PiecesPerBoxAndDisplayOrderTd>
                          <S.EditButtonTd>
                            <S.EditButton
                              className="edit-button"
                              onClick={() => {
                                // 編集モード
                                onClickEdit(index);
                              }}
                            >
                              <PencilIcon className="icon" />
                            </S.EditButton>
                          </S.EditButtonTd>
                        </S.SelectedCaseWrapperTr>
                      );
                    }
                  )}
              </tbody>
            </S.ProductMasterEditTable>
          </S.Wrapper>
        </>
      )}
      {caseSettingsOpen && (
        <CaseSettingsModal
          data={allCasesForSetting}
          handleClose={() => setCaseSettingsOpen(false)}
          handleSuccessCaseSettings={handleSuccessCaseSettings}
          casePopUp={casePopUp}
          setCasePopUp={setCasePopUp}
          newCase={newCase}
          setNewCase={setNewCase}
          editCase={editCase}
          setEditCase={setEditCase}
          isCaseChanged={isCaseChanged}
          setIsCaseChanged={setIsCaseChanged}
          editInputOpen={editInputOpen}
          setEditInputOpen={setEditInputOpen}
          caseDelConfirmOpen={caseDelConfirmOpen}
          setCaseDelConfirmOpen={setCaseDelConfirmOpen}
          deleteCase={deleteCase}
          setDeleteCase={setDeleteCase}
          msgKind={msgKind}
          setMsgKind={setMsgKind}
          deleteError={deleteError}
          setDeleteError={setDeleteError}
          newProductAndCaseIds={newProductAndCaseIds}
        />
      )}
      <S.ButtonContainer>
        {enableToSave ? (
          <Button
            children={updating ? '保存中...' : '保存'}
            onClick={handleSubmit}
          />
        ) : (
          <Button disabled children={'保存'} />
        )}
      </S.ButtonContainer>
      {confirmMsg && (
        <DeleteConfirmModal
          confirmMsg={confirmMsg}
          onClickCancel={() => setConfirmMsg('')}
          onClickDelete={() => deleteFunction()}
        />
      )}
      <ErrorMsgPopUp
        errMsg={errMsg}
        handleClose={() => {
          setErrMsg('');
          setUpdating(false);
        }}
        fromPc={true}
        zIndex={9999}
      />
      {errMsg && (
        <Overlay
          dark
          handleClick={() => {
            setErrMsg('');
            setUpdating(false);
          }}
        />
      )}
    </>
  );
};

export default CaseInfo;
