import { createGlobalStyle } from 'styled-components';
import reset from 'styled-reset';
import { defaultTheme } from './theme';
import '../../assets/fonts/NotoSansJP-Regular.ttf';
import '../../assets/fonts/NotoSansJP-Bold.ttf';
import '../../assets/fonts/NotoSansJP-Light.ttf';

export const GlobalStyle = createGlobalStyle`
  ${reset}
  @font-face {
    font-family: "Noto Sans JP";
    src: local("Noto Sans JP"),
      url("./assets/fonts/NotoSansJP-Regular.ttf") format("truetype");
    font-weight: normal;
  }
  @font-face {
    font-family: "Noto Sans JP";
    src: local("Noto Sans JP"),
      url("./assets/fonts/NotoSansJP-Bold.ttf") format("truetype");
    font-weight: bold;
  }
  @font-face {
    font-family: "Noto Sans JP";
    src: local("Noto Sans JP"),
      url("./assets/fonts/NotoSansJP-Bold.ttf") format("truetype");
    font-weight: 700;
  }
  @font-face {
    font-family: "Noto Sans JP";
    src: local("Noto Sans JP"),
      url("./assets/fonts/NotoSansJP-Light.ttf") format("truetype");
    font-weight: light;
  }
  * {
    font-family: "Noto Sans JP";
    font-size: 16px;
  }
  body {
    overflow: hidden;
    font-family: "Noto Sans JP";
    margin: 0;
    color: ${defaultTheme.palette.baseBlack};
    font-size: 1em;
    word-wrap: break-word;
    -webkit-text-size-adjust: 100%;
    -webkit-font-smoothing: antialiased;
  }
  a {
    cursor: pointer;

    &:link,
    &:visited {
      text-decoration: none;
    }

    &:hover {
      opacity: .9;
      text-decoration: none;
    }
  }
  h1,h2,h3,h4,h5, h6 {
    font-weight: 700;
  }

  button {
      display: inline-block;
      border: none;
      padding: 1rem 2rem;
      margin: 0;
      text-decoration: none;
      background: #0069ed;
      color: #ffffff;
      font-family: "Noto Sans JP";
      font-size: 1rem;
      cursor: pointer;
      text-align: center;
      transition: background 250ms ease-in-out,
                  transform 150ms ease;
      -webkit-appearance: none;
      -moz-appearance: none;
  }

  //COMMENT: React-scriptsで現在認識されているエラー
  //"react-error-overlay"を入れてもいい、様子見
  //https://stackoverflow.com/questions/70499543/create-react-app-creates-this-iframe-that-prevents-me-from-clicking-or-editing
  iframe {
    pointer-events: none;
  }


`;

export const MobileStyle = createGlobalStyle`
  body {
    overflow: visible;
    background: ${(props) => props.theme.palette.beige};
  }
`;

export const PcScrollStyle = createGlobalStyle`
  body {
    overflow: visible;
  }
`;
