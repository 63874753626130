import styled from 'styled-components';

export const Wrapper = styled.div<{
  open?: boolean;
}>`
  width: 100vw;
  top: ${(props) => (props.open ? '0' : '100%')};
  position: fixed;
  z-index: 10000;
  overflow-x: hidden;
  background-color: #f8f7f4;
`;

export const Header = styled.div`
  width: 100vw;
  display: flex;
  padding: 16px;
  font-family: 'Noto Sans JP';
  font-style: normal;
  font-size: 24px;
  line-height: 32px;
  color: ${(props) => props.theme.palette.black};
  font-weight: 700;
  justify-content: space-between;
  border-bottom: 1px solid #e9e9e9;
  .date {
    font-size: 24px;
  }
`;

export const Table = styled.table`
  width: 100%;
  border-collapse: collapse;
  border-spacing: 0;
  font-size: 0.875rem;
`;

export const THead = styled.tr`
  background-color: #f8f7f4;
  height: 28px;
  td {
    vertical-align: bottom;
    padding-left: 16px;
    font-weight: bold;
    line-height: 1.2;
    padding-top: 8px;
  }
`;

export const TRow = styled.tr`
  background-color: #f8f7f4;
  height: 80px;
`;

export const TCell = styled.td<{
  align?: string;
  width?: string;
  marginRight?: string;
  background?: string;
  wordBreak?: string;
  fontSize?: string;
  padding?: string;
  editMode?: boolean;
}>`
  position: relative;
  font-size: ${(props) => (props.fontSize ? props.fontSize : '14px')};
  vertical-align: middle;
  word-break: ${(props) => (props.wordBreak ? props.wordBreak : '')};
  width: ${(props) => (props.width ? props.width : '7.25rem')};
  text-align: ${(props) => (props.align ? props.align : 'left')};
  margin-right: ${(props) => (props.marginRight ? props.marginRight : '')};
  background: ${(props) => (props.background ? props.background : '')};
  word-spacing: 100vw;
  padding: ${(props) => (props.padding ? props.padding : '0 8px')};
  border: 0;

  &.delete-btn {
    ${(props) => (props.editMode ? 'cursor: pointer' : '')};
    text-align: center;
    vertical-align: middle;
    padding-left: 8px;
    background: ${(props) => (props.background ? props.background : '')};
  }

  &.display-quantity {
    /* padding: 0 0.25rem; */
    margin-left: 0.25rem;
    &--total {
      padding-right: 8px;
      .inner {
        width: 100%;
        min-height: 4rem;
        padding: 0 20px 0 0;
        border: solid 4px white;
        border-radius: 0.5rem;
        text-align: right;
        position: relative;
        .unit {
          position: absolute;
          bottom: 8px;
          right: 4px;
        }
      }
    }
  }
  /* 数量入力 */
  .display-quantity__inner-wrap {
    display: flex;
    flex-direction: row;
    align-items: center;
    /* inputとx数量のwrap内 */
    .cell-content {
      position: relative;
      border-radius: 0.5rem;
      height: 64px;
      background: ${(props) => props.theme.palette.white};
      overflow: hidden;
      .unit {
        position: absolute;
        bottom: 8px;
        right: 4px;
      }
    }
    .input-label {
      top: 4px;
      left: 8px;
      position: absolute;
      color: #6b675c;
      &.input-label__sum {
        margin-top: 8px;
        left: 12px;
      }
    }
    .symbol {
      margin-left: 8px;
    }
    .inner {
      align-items: center;
      font-size: 1rem;
      display: flex;
      text-align: center;
      padding-left: 20px;
      &.inner__sum {
        text-align: right;
        padding-left: 8px;
        justify-content: end;
        padding-right: 22px;
      }
    }
  }
  > svg {
    display: inline;
    width: 18px;
    height: 18px;
  }

  > span {
    position: absolute;
    right: 1rem;
    top: 60%;
    font-size: 10px;
    margin-left: 4px;
  }
`;

// 編集モーダルtableヘッダセル
export const THeadCell = styled(TCell)<{
  align?: string;
  width?: string;
  wordBreak?: string;
  colSpan?: number;
}>`
  font-weight: 500;
  word-break: ${(props) => (props.wordBreak ? props.wordBreak : '')};
`;

export const DeleteButton = styled.div`
  font-size: 1rem;
  margin: auto auto;
  svg {
    margin: auto auto;
  }
`;

export const ButtonWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: ${(props) => props.theme.palette.beige};
  width: 100%;
  height: 83px;
  border-top: solid 1px #e6e1dd;
`;

export const ButtonContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  & > button {
    width: 136px;
  }
  & > button:first-child {
    margin-right: 1rem;
  }
`;

export const Input = styled.input<{
  align?: string;
  padding?: string;
  height?: string;
  disabled?: boolean;
}>`
  width: 100%;
  min-height: 4rem;
  color: ${(props) => props.theme.palette.baseBlack};
  font-size: 1rem;
  line-height: 1.25rem;
  padding: ${(props) => (props.padding ? props.padding : '0.325rem')};
  text-align: ${(props) => props.align};
  border: none;
  border-radius: 0.5rem;
  border: 1px solid rgba(0, 0, 0, 0.08);
  height: 64px;
  background: ${(props) => (props.disabled ? '' : props.theme.palette.white)};
  ::-webkit-inner-spin-button {
    -webkit-appearance: none;
  }
  &.bestBeforeDate {
    min-width: 90%;
  }
`;

export const TextareaPackagingComment = styled.textarea<{
  align?: string;
  padding?: string;
}>`
  width: 100%;
  min-height: 4rem;
  color: ${(props) => props.theme.palette.baseBlack};
  font-size: 1rem;
  line-height: 1.25rem;
  padding: ${(props) => (props.padding ? props.padding : '0.325rem')};
  text-align: ${(props) => props.align};
  border: none;
  border-radius: 0.5rem;
  border: 1px solid rgba(0, 0, 0, 0.08);
  background: ${(props) => props.theme.palette.white};
  word-wrap: break-word;
  word-break: break-all;
  white-space: normal;
`;

export const DeleteModalWrapper = styled.div<{
  width?: string;
}>`
  background: ${(props) => props.theme.palette.white};
  position: fixed;
  left: 50%;
  top: 50%;
  width: ${(props) => (props.width ? props.width : '')};
  transform: translate(-50%, -50%);
  z-index: 10001;
  box-shadow: 0px 6px 10px rgba(0, 0, 0, 0.07), 0px 1px 18px rgba(0, 0, 0, 0.06),
    0px 3px 5px rgba(0, 0, 0, 0.1);
  border-radius: 8px;
  padding: 1rem;
`;

export const DeleteModalTextContainer = styled.div`
  display: flex;
  flex-direction: column;
  font-weight: 500;
  font-size: 1.13rem;
  line-height: 27px;
`;

export const DeleteModalButtonContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 1rem;
  margin-top: 1rem;
  button {
    width: 30%;
    height: 2.75rem;
    &:nth-child(2) {
      border-color: ${(props) => props.theme.palette.error};
      color: ${(props) => props.theme.palette.error};
    }
  }
`;
