export const TABLE_HEADER = [
  { key: '編集複製', width: '48px', colSpan: 1 },
  // { key: '複製', width: '48px', colSpan: 1 },
  { key: '製品名', align: 'left', width: '20.75rem', colSpan: 1 },
  {
    key: '半製品の数量',
    width: '19rem',
    colSpan: 4,
  },
  // {
  //   key: '不良品',
  //   width: '90px',
  //   wordBreak: 'keep-all',
  //   colSpan: 1,
  // },
  {
    key: '保管期限起算',
    width: '100px',
    wordBreak: 'keep-all',
    colSpan: 1,
  },
  {
    key: '保管期限',
    width: '100px',
    wordBreak: 'keep-all',
    colSpan: 1,
  },

  {
    key: '時刻',
    width: '6rem',
    wordBreak: 'keep-all',
    colSpan: 1,
  },
  // {
  //   key: '担当者',
  //   width: '9rem',
  //   wordBreak: 'keep-all',
  //   colSpan: 1,
  // },
  {
    key: '備考',
    width: '100%',
    wordBreak: 'keep-all',
    colSpan: 1,
  },
];
