import { Redirect, Route, Switch, useRouteMatch } from 'react-router-dom';
import MobileLoginPage from '@components/pages/mobile/LoginPage/MobileLoginPage';
import { CaptureForTraining } from '@components/pages/mobile/CaptureForTraining';
import { ProductsForStocksRoot } from '@components/pages/mobile/ProductsForStocks';
import { ProductsStocksEditRoot } from '@components/pages/mobile/ProductsStocksEdit';
import { MaterialsForStocksRoot } from '@components/pages/mobile/MaterialsForStocks';
import { MaterialsStocksEditRoot } from '@components/pages/mobile/MaterialsStocksEdit';
import MobileAuthRoute from './MobileAuthRoute';
import { ProductsStocksRoot } from '@components/pages/mobile/ProductsStocksEdit/ProductsStocks';
import { PickingRoot } from '@components/pages/mobile/Picking';
import { PickingEditRoot } from '@components/pages/mobile/PickingEdit';
import { PickingByShippingRoot } from '@components/pages/mobile/PickingByShipping/PickingByShipping';
import RealtimeScannerPage from '@components/pages/mobile/RealtimeScanner';
import PickingEditScannerPage from '@components/pages/mobile/PickingEditScanner';
import { PickingEditScannerResultRoot } from '@components/pages/mobile/PickingEditScannerResult/PickingEditScannerResult';
import { PickingByShippingEditRoot } from '@components/pages/mobile/PickingByShippingEdit/PickingByShippingEdit';
import PickingByShippingEditScannerPage from '@components/pages/mobile/PickingByShippingEditScanner';
import { PickingByShippingEditScannerResultRoot } from '@components/pages/mobile/PickingByShippingEditScannerResult/PickingByShippingEditScannerResult';
import { ProductsPhotographRoot } from '@components/pages/mobile/ProductsPhotograph/ProductsPhotograph';
import { ProductsPhotographListRoot } from '@components/pages/mobile/ProductsPhotographList/ProductsPhotographList';
import { CaptureOfProductRoot } from '@components/pages/mobile/CaptureOfProduct';

const MobileRoutes = () => {
  const match = useRouteMatch();

  return (
    <Switch>
      <Route path={`${match.url}`} exact>
        <Redirect to={`${match.url}/login`} />
      </Route>

      <Route path={`${match.url}/login`} component={MobileLoginPage} />
      <Route
        path={`${match.url}/products/:id/capture_for_training/:token`}
        component={CaptureForTraining}
      />
      <MobileAuthRoute
        path={`${match.url}/live/:storeroom_id`}
        component={RealtimeScannerPage}
        exact
      />

      <MobileAuthRoute
        path={`${match.url}/products/for_stocks`}
        component={ProductsForStocksRoot}
        exact
      />
      <MobileAuthRoute
        path={`${match.url}/products/:id/stocks/:stock_kind`}
        component={ProductsStocksRoot}
        exact
      />
      <MobileAuthRoute
        path={`${match.url}/products/:id/stocks/:stock_kind/:date/:storeroom_id/edit`}
        component={ProductsStocksEditRoot}
        exact
      />
      {/* 資材・原材料在庫 */}
      <MobileAuthRoute
        path={`${match.url}/materials/for_stocks`}
        component={MaterialsForStocksRoot}
        exact
      />
      <MobileAuthRoute
        path={`${match.url}/materials/:id/stocks/:date/:storeroom_id/edit`}
        component={MaterialsStocksEditRoot}
        exact
      />

      {/* 商品別ピッキング */}
      <MobileAuthRoute
        path={`${match.url}/picking`}
        component={PickingRoot}
        exact
      />
      <MobileAuthRoute
        path={`${match.url}/picking_detail_edit/:id`}
        component={PickingEditRoot}
        exact
      />
      <MobileAuthRoute
        path={`${match.url}/picking_detail_edit/scanner/:id`}
        component={PickingEditScannerPage}
        exact
      />
      <MobileAuthRoute
        path={`${match.url}/picking_detail_edit/scanner_result/:id`}
        component={PickingEditScannerResultRoot}
        exact
      />
      {/* 出荷先別ピッキング */}
      <MobileAuthRoute
        path={`${match.url}/picking_by_shipping`}
        component={PickingByShippingRoot}
        exact
      />
      <MobileAuthRoute
        path={`${match.url}/picking_by_shipping_edit/:id`}
        component={PickingByShippingEditRoot}
        exact
      />
      <MobileAuthRoute
        path={`${match.url}/picking_by_shipping_edit/scanner/:id`}
        component={PickingByShippingEditScannerPage}
        exact
      />
      <MobileAuthRoute
        path={`${match.url}/picking_by_shipping_edit/scanner_result/:id`}
        component={PickingByShippingEditScannerResultRoot}
        exact
      />
      <MobileAuthRoute
        path={`${match.url}/products_photograph`}
        component={ProductsPhotographRoot}
        exact
      />
      <MobileAuthRoute
        path={`${match.url}/products_photograph_list/:camera_id`}
        component={ProductsPhotographListRoot}
        exact
      />
      <MobileAuthRoute
        path={`${match.url}/capture_of_product/:id`}
        component={CaptureOfProductRoot}
      />
    </Switch>
  );
};
export default MobileRoutes;
