import { axiosInstance } from '@lib/pc/common/api/axiosConfig';
import { useQuery } from 'react-query';
import humps from 'humps';
import moment from 'moment';
import { Todo } from '@lib/common/type';

export const useMaterialStocks = (date: Date, storeroomId: string) => {
  const { data, refetch } = useQuery(
    ['materialStocks', date, storeroomId],
    async () => {
      const data = await axiosInstance().get(
        `/api/v1/material_stocks?date=${moment(date).format(
          'YYYYMMDD'
        )}&storeroom_id=${storeroomId}`
      );

      return data;
    }
  );

  const materialStocks =
    data && (humps.camelizeKeys(data.data.material_stocks) as Todo);

  return { materialStocks, refetch };
};

export default useMaterialStocks;
