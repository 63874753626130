import { DefaultTheme } from 'styled-components';
export const defaultTheme: DefaultTheme = {
  borderRadius: '4px',
  palette: {
    black: '#000000',
    baseBlack: '#191712',
    white: '#ffffff',
    beige: '#F8F7F4',
    sand: '#E6E1DD',
    sandGray: '#E7E2DD',
    khaki: '#6B675C',
    blue: '#2BA9D1',
    paleYellow: '#FFFEF5',
    yellowGray: '#F5F2E6',
    error: '#C74141',
    dialog: 'rgba(0, 0, 0, 0.5)',
    outline: 'rgba(0, 0, 0, 0.08)',
    overlay: 'rgba(187, 187, 187, 0.1)',
    primary: {
      main: '#2BA9D1',
      contrastText: '#ffffff',
    },
    secondary: {
      main: '#709fb0',
      contrastText: '#ffffff',
    },
    brightGreen: '#25c49f',
    tealGreen: '#06945a',
  },
};
