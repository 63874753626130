import { Todo } from '@lib/common/type';
import { ChangeEventHandler, useRef, useState } from 'react';

/** 画像の取得 **/
const fileImage = new Image();
export const useFileHooks = () => {
  const imageContainerRef = useRef<HTMLDivElement>(null);
  const [objectURL, setObjectURL] = useState('');
  const resetSelection = () => {
    fileImage.src = '';
    const imageContainer = imageContainerRef.current;
    if (imageContainer && fileImage.parentNode === imageContainer) {
      imageContainer.removeChild(fileImage);
    }
    if (objectURL) {
      window.URL.revokeObjectURL(objectURL);
      setObjectURL('');
    }
  };
  const handleFiles: ChangeEventHandler<HTMLInputElement> = (event) => {
    const files = event.currentTarget.files;
    resetSelection();
    if (!files || files?.length === 0) return;
    const file = files[0];
    if (!file.type.includes('image/')) {
      event.currentTarget.value = '';
      return;
    }
    const imageContainer = imageContainerRef.current;
    if (!imageContainer) return;
    const objectURL = window.URL.createObjectURL(file);
    fileImage.src = objectURL;
    imageContainer.appendChild(fileImage);
    setObjectURL(objectURL);
  };
  return { handleFiles, imageContainerRef };
};

/**  備考欄の全文表示 **/
export const seeFullComment = (index: number) => {
  const targetId = `comment-full-${index}`;
  const targetFullComment = document.getElementById(targetId);
  if (targetFullComment) {
    const targetFullCommentClassName = targetFullComment.className;
    if (
      targetFullComment &&
      targetFullCommentClassName === 'active cell-content__balloon'
    ) {
      targetFullComment.className = 'cell-content__balloon';
    } else if (
      targetFullComment &&
      targetFullCommentClassName === 'cell-content__balloon'
    ) {
      targetFullComment.className = 'active cell-content__balloon';
    }
  }
};

/**  ケース、バラの計算 **/
export const calcTotalFunc = (
  e: React.ChangeEvent<HTMLInputElement>,
  newData: Todo,
  piecesPerBox: number
) => {
  let caseTotal = 0;
  let baraTotal = 0;

  caseTotal = Number(newData.case) * (piecesPerBox || 1);
  baraTotal = Number(newData.piece);

  return caseTotal + baraTotal;
};

/**  配列中の重複チェック **/
export const hasDuplicates = (array: Todo[]) => {
  return new Set(array).size !== array.length;
};
