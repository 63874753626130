import styled from 'styled-components';

export const PickingListWrapper = styled.tr<{
  index: number;
  backgroundColor: string;
}>`
  display: flex;
  justify-content: space-between;
  padding: 0.625rem 1rem;
  border-bottom: 1px solid #e6e1dd;
  background: ${(props) => props.backgroundColor};

  &:hover {
    text-decoration: none;
    opacity: 1;
  }
`;

export const GroupNameTd = styled.td`
  display: grid;
  gap: 2px;
  width: 49%;
`;

export const GroupName = styled.p`
  /* width: 33%; */
  font-weight: 500;
`;

export const TimeAndShortageInfo = styled.div`
  display: flex;
`;

export const TimeInfo = styled.div`
  font-size: 14px;
`;

export const ShortageInfo = styled.div`
  color: ${(props) => props.theme.palette.error};
  width: 70%;
  font-weight: 600;
  font-size: 14px;
`;

// export const GroupTime = styled.div`
//   margin-top: 0.5rem;
//   font-size: 0.75rem;
// `;

export const NumOfProducts = styled.p`
  text-align: end;
  font-weight: 500;
  > span {
    font-size: 12px;
  }
`;

export const StatusIcon = styled.div<{
  btnBgColor: string;
  borderColor: string;
  fontColor: string;
  fontSize: string;
  fontWeight: number;
}>`
  width: 95px;
  height: 28px;
  color: ${(props) => props.fontColor};
  background: ${(props) => props.btnBgColor};
  border: ${(props) => `${props.borderColor} 2px solid`};
  border-radius: 2px;
  padding: 4px 0px;
  text-align: center;
  font-weight: ${(props) => props.fontWeight};
  font-size: ${(props) => props.fontSize};
`;
