import { useState } from 'react';
import { useHistory, useLocation, useParams } from 'react-router';
import { ReactComponent as ChevronLeft } from '@assets/icons/chevron_left.svg';
import { Heading } from '@components/elements/typographies/Heading';
import { WithHeader } from '@components/templates/WithHeader';
import PickingByShippingDetail from '@components/modules/pc/picking_by_shipping/picking_by_shipping_detail/PickingByShippingDetail/PickingByShippingDetail';
import { pickingStatus } from '@lib/pc/picking/constants';
import * as S from './PickingByShippingDetail.styles';
import usePicking from '@lib/pc/picking_by_shipping/usePicking';
import { PickingByShippingState } from '@lib/pc/picking_by_shipping/type';

export const PickingByShippingDetailRoot = () => {
  const { state } = useLocation<PickingByShippingState>();
  const { id } = useParams<{ id: string }>();
  const { picking: picking, refetch } = usePicking(Number(id));
  const history = useHistory();
  const [openAddPickingProductModal, setOpenAddPickingProductModal] =
    useState(false);

  return (
    <WithHeader>
      <S.Wrapper>
        <div>
          <S.HeadContainer>
            <S.FlexCenter>
              <S.BackButton
                onClick={() => {
                  history.push(`/pc/picking_by_shipping`, state);
                }}
              >
                <ChevronLeft />
              </S.BackButton>

              <S.FlexBaseline>
                <div>
                  <Heading
                    tag="span"
                    text={picking?.shippingGroupName}
                    fs="32"
                    fw="lg"
                  />
                  <Heading
                    tag="span"
                    text="ピッキングリスト（出荷先別）を確認"
                    fs="24"
                    fw="lg"
                  />
                </div>
              </S.FlexBaseline>
            </S.FlexCenter>
            {/* <PickingByShippingDetailHeader
              setOpenAddPickingProductModal={setOpenAddPickingProductModal}
            /> */}
            <S.FlexCenter>
              {/* <div>
                <div>担当: {pickingDummyData.pickingPerson}</div>
                <div>Wﾁｪｯｸ担当: {pickingDummyData.wCheckPerson}</div>
              </div> */}
              <div>
                <S.StatusBar
                  color={
                    picking &&
                    pickingStatus[picking?.pickingStatus].statusBarColor
                  }
                  bgColor={
                    picking &&
                    pickingStatus[picking?.pickingStatus].statusBarBgColor
                  }
                  borderColor={
                    picking &&
                    pickingStatus[picking?.pickingStatus].statusBorderColor
                  }
                >
                  {picking && pickingStatus[picking?.pickingStatus].label}
                </S.StatusBar>
              </div>
            </S.FlexCenter>
          </S.HeadContainer>
        </div>
        <div>
          <PickingByShippingDetail
            id={id}
            data={picking}
            refetch={refetch}
            openAddPickingProductModal={openAddPickingProductModal}
            setOpenAddPickingProductModal={setOpenAddPickingProductModal}
          />
        </div>
      </S.Wrapper>
    </WithHeader>
  );
};
