import { axiosInstance } from '@lib/pc/common/api/axiosConfig';
import { useQuery } from 'react-query';
import humps from 'humps';
import moment from 'moment';
import { Todo } from '@lib/common/type';

export const useStockAllocationsAll = (
  date: Date,
  storeroomId: number | undefined,
  selectedAllocationTypeId: number
) => {
  const { data, refetch } = useQuery(
    ['stock_allocations', date, storeroomId],
    async () => {
      const data = await axiosInstance().get(
        `/api/v1/product_stock_allocations_all?date=${moment(date).format(
          'YYYYMMDD'
        )}&storeroom_id=${storeroomId}&allocation_type=${selectedAllocationTypeId}`
      );

      return data;
    }
  );

  const stockAllocationsAll =
    data && (humps.camelizeKeys(data.data.all_stock_allocations) as Todo);

  return { stockAllocationsAll, refetch };
};

export default useStockAllocationsAll;
