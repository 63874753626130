import { useEffect } from 'react';
import * as S from './PhotoGraphyModal.styles';
import { ReactComponent as ChevronLeftIcon } from '@assets/icons/chevron_left.svg';
import { ReactComponent as CameraIcon } from '@assets/icons/camera.svg';

type Props = {
  img: HTMLImageElement;
  setImg: (img: HTMLImageElement) => void;
  setImageInfo: (info: { src: string; timestamp: number }) => void;
  handleOpenPhotoViewer: () => void;
  photoGraphyModalActive: boolean;
  setPhotoGraphyModalActive: (photoGraphyModalActive: boolean) => void;
};

const PhotoGraphyModal = ({
  setImg,
  setImageInfo,
  handleOpenPhotoViewer,
  photoGraphyModalActive,
  setPhotoGraphyModalActive,
}: Props) => {
  // Tensorflow.jsへ渡すためのサイズ
  const canvasSize = { width: 480, height: 480 }; // { width: 640, height: 640 };
  // 縦横比を維持できるサイズ
  const snapshotSize = { width: 640, height: 480 };

  useEffect(() => {
    let stream: MediaStream | null = null;
    const player = document.getElementById('player') as HTMLVideoElement;
    const handleSuccess = function (s: MediaStream) {
      stream = s;
      if (player !== undefined) {
        player.srcObject = stream;
        player.play();
      }
    };
    navigator.mediaDevices
      .getUserMedia({
        video: {
          // 背面カメラの使用
          facingMode: {
            exact: 'environment',
          },
        },
        audio: false,
      })
      .then(handleSuccess)
      .catch((error) => {
        console.error('--getUserMedia error--', JSON.stringify(error));
      });
    // アンマウント時にストリームを停止する
    return () => {
      if (stream) {
        const tracks = stream.getTracks();
        tracks.forEach((track) => {
          track.stop();
        });
        player.srcObject = null;
      }
    };
  }, []);

  // 戻るボタン実行イベント
  function ReturnEvent() {
    const player = document.getElementById('player') as HTMLVideoElement;
    const stream = player.srcObject as MediaStream;
    const tracks = stream ? (stream.getTracks() as [MediaStreamTrack]) : [];
    // キャプチャ状態のトラックを停止
    tracks.forEach((track) => {
      track.stop();
    });
    // videoタグのstreamをクリア
    player.srcObject = null;

    // photoGraphyのmodal画面をclose
    setPhotoGraphyModalActive(false);
  }

  // カメラボタン実行イベント
  function Snapshot() {
    const board = document.getElementById('board') as HTMLCanvasElement;
    const ctx = board?.getContext('2d');
    const player = document.getElementById('player') as HTMLVideoElement;
    const stream = player.srcObject as MediaStream;
    const tracks = stream ? (stream.getTracks() as [MediaStreamTrack]) : [];
    // videoの一時停止
    player.pause();
    board.width = canvasSize.width;
    board.height = canvasSize.height;
    // canvasに画像を描画（Tensorflow.jsへ渡すために480x480へリサイズする）
    ctx?.drawImage(
      player,
      0,
      0,
      snapshotSize.width,
      snapshotSize.height,
      0,
      0,
      board.width,
      board.height
    );

    setImageInfo({ src: board.toDataURL('image/jpeg'), timestamp: Date.now() });
    const imageSrc = board.toDataURL('image/jpeg');
    const img = document.getElementById('picture') as HTMLImageElement;
    setImg(img);

    img.width = canvasSize.width;
    img.height = canvasSize.height;
    img.src = imageSrc;

    // カメラの停止（キャプチャ状態のトラックを停止）
    tracks.forEach((track) => {
      track.stop();
    });
    // canvasのクリア
    board.width = 0;
    board.height = 0;
    ctx?.clearRect(0, 0, canvasSize.width, canvasSize.height);
    // videoタグのstreamをクリア
    player.srcObject = null;

    // photoviewerのmodal画面をopen
    handleOpenPhotoViewer();
  }

  return (
    <>
      <S.Wrapper open={photoGraphyModalActive}>
        <video id="player"></video>
        <S.Canvas>
          <canvas id="board"></canvas>
        </S.Canvas>
        <S.HeadContainer>
          <S.ReturnButton onClick={() => ReturnEvent()}>
            <ChevronLeftIcon />
            戻る
          </S.ReturnButton>
          <S.HeadingWrapper>
            <S.MainTitle>賞味期限</S.MainTitle>
            <S.SubTitle>を撮影してください</S.SubTitle>
          </S.HeadingWrapper>
        </S.HeadContainer>
        <S.CameraButton onClick={() => Snapshot()}>
          <CameraIcon />
        </S.CameraButton>
      </S.Wrapper>
    </>
  );
};

export default PhotoGraphyModal;
