import { FC } from 'react';
import { Switch, FormControlLabel, ThemeProvider } from '@material-ui/core';
import * as S from './SwitchButton.styles';
import theme from './theme';

type Props = {
  label: string;
  handleChangeSwitch: () => void;
};

const SwitchButton: FC<Props> = ({ label, handleChangeSwitch }: Props) => {
  return (
    <S.Wrapper>
      <ThemeProvider theme={theme}>
        <FormControlLabel
          control={
            <Switch
              color="primary"
              onChange={handleChangeSwitch}
              // defaultChecked
              focusVisibleClassName=".MuiSwitch-switchBase"
            />
          }
          label={label}
          labelPlacement={'start'}
        />
      </ThemeProvider>
    </S.Wrapper>
  );
};
export default SwitchButton;
