import { useState, useMemo, useEffect, useCallback } from 'react';
import moment from 'moment';
import * as S from './MaterialAllocationStatus.styles';
import Filter from '@assets/icons/filter_list';
import { ReactComponent as FilteredIcon } from '@assets/icons/filtered.svg';
import { WithHeader } from '@components/templates/WithHeader';
import { Heading } from '@components/elements/typographies/Heading';
import MaterialDetailModal from '@components/modules/pc/material_allocation_status/MaterialDetailModal/MaterialDetailModal';
import RightSlideSidebar from '@components/modules/pc/material_allocation_status/RightSlideSidebar/RightSlideSidebar';
import StoreRoomAllocationTypeModal from '@components/modules/pc/material_allocation_status/StoreRoomAllocationTypeModal/StoreRoomAllocationTypeModal';
import TableWithFixedSidebar from '@components/modules/pc/material_allocation_status/TableWithFixedSidebar/TableWithFixedSidebar';
import ArrowRight from '@assets/icons/arrow_right_24px';
import { ReactComponent as ArrowLeft } from '@assets/icons/arrow_left_24px.svg';
import useStorerooms from '@lib/pc/common/hooks/useStorerooms';
import { AllocationType, MATERIAL_PRODUCT_KIND, Todo } from '@lib/common/type';
import {
  MaterialStockAllocations,
  MaterialStockAllocationsAll,
  SelectedMaterialItem,
} from '@lib/pc/material_allocation_status/type';
import Overlay from '@components/elements/backdrops/Overlay/Overlay';
import { Button } from '@components/elements/buttons/Button';
import { getTodayForReference } from '@lib/common/functions';
import useAllMaterialStockAllocations from '@lib/pc/material_allocation_status/useAllMaterialStockAllocations';

export const MaterialAllocationStatusRoot = () => {
  const initializeSelectedItem = {
    id: 0,
    date: '',
    name: '',
    type: '原' as '原' | '資',
    materialStockAllocations: [] as MaterialStockAllocations[],
    storeroomIds: [],
  };

  const today = moment();
  const [date, setDate] = useState(moment());
  const [initialize] = useState<Todo>(initializeSelectedItem);
  const [selectedItem, setSelectedItem] =
    useState<SelectedMaterialItem>(initialize);
  const [rightSlideSidebarActive, setRightSlideSidebarActive] = useState(false);
  const [productDetailModalActive, setProductDetailModalActive] =
    useState(false);
  const [searchMenuActive, setSearchMenuActive] = useState(false);
  const [allocationMenuActive, setAllocationMenuActive] = useState(false);
  const [selectedCell, setSelectedCell] = useState('');
  const [selectedStoreroomId, setSelectedStoreroomId] = useState(0);
  const [selectedAllocationTypeId, setSelectedAllocationTypeId] = useState(-1);
  const [selectedProductId, setSelectedProductId] = useState(0);
  const [selectIndex, setSelectIndex] = useState<number>(
    new Date().getDate() - 1
  );

  const allocationTypeOptions: AllocationType[] = [
    { id: -1, name: '全ての払出タイプ' },
    { id: 0, name: '廃棄' },
    { id: 1, name: '在庫移動' },
  ];
  const { allMaterialStocks, refetch } = useAllMaterialStockAllocations(
    moment(date).toDate(),
    selectedStoreroomId,
    selectedAllocationTypeId
  );
  const { data: storerooms } = useStorerooms(MATERIAL_PRODUCT_KIND.MATERIAL);

  const filtered = useMemo(() => {
    if (!allMaterialStocks) return;
    if (selectedStoreroomId === 0) {
      return allMaterialStocks;
    } else {
      return allMaterialStocks.filter((p: MaterialStockAllocationsAll) =>
        p.storeroomsIds?.includes(selectedStoreroomId)
      );
    }
  }, [selectedStoreroomId, allMaterialStocks]);

  // グラフ表示時
  const handleOpenProductDetail = useCallback((item: Todo) => {
    setProductDetailModalActive(!productDetailModalActive);
    setSelectedProductId(item.id);
    setSelectedItem(item);
  }, []);

  // rightSlideSidebar表示時
  const handleSelectMaterial = (item: Todo, indexId: string) => {
    setSelectedItem(item);
    setRightSlideSidebarActive(!rightSlideSidebarActive);
    setSelectedCell(indexId);
  };

  const getMonth = (num: number) => {
    setDate(moment(date).add(num, 'months').startOf('month'));
  };

  const handleClick = () => {
    getTodayForReference(
      date.format('YYYY-MM'),
      today.format('YYYY-MM'),
      setDate,
      setSelectIndex,
      refetch
    );
  };

  const handleCloseProductDetail = useCallback(() => {
    setProductDetailModalActive(false);
  }, []);

  useEffect(() => {
    localStorage.setItem('last_view_page', 'stock_allocation_all');
  }, []);

  useEffect(() => {
    refetch();
  }, [selectedStoreroomId, selectedAllocationTypeId]);

  return (
    <WithHeader>
      <S.Wrapper>
        <S.HeadContainer>
          <S.PageTitle>
            <S.PageTitleText>
              <Heading text="資材・原材料払出状況" fs="32" fw="lg" />
              <Heading text="を確認" fs="24" fw="lg" />
            </S.PageTitleText>
          </S.PageTitle>

          <S.DateWrapper>
            <S.ListLeftHeadCell
              invalid={rightSlideSidebarActive}
              onClick={() => setAllocationMenuActive(!allocationMenuActive)}
              isBlue={
                selectedStoreroomId !== 0 || selectedAllocationTypeId !== -1
              }
            >
              <span className="filter-product">
                {selectedStoreroomId !== 0 ||
                selectedAllocationTypeId !== -1 ? (
                  <>
                    <FilteredIcon />
                    <div className="filter_text">払出元、払出先の絞込中</div>
                  </>
                ) : (
                  <>
                    <Filter
                      isBlue={
                        selectedStoreroomId !== 0 ||
                        selectedAllocationTypeId !== -1
                      }
                    />
                    <div className="filter_text">払出元、払出先の絞込</div>
                  </>
                )}
              </span>
            </S.ListLeftHeadCell>
            <Button
              width="90px"
              margin={'0 8px 0 0'}
              outlined={true}
              borderWidth={1}
              onClick={handleClick}
            >
              今日
            </Button>
            <S.ArrowLeft selected={false} onClick={() => getMonth(-1)}>
              <ArrowLeft />
              <span className="circle"></span>
            </S.ArrowLeft>
            <S.Date>
              {moment(date).format('YYYY')}
              <span>年</span>
              {moment(date).format('MM')}
              <span>月</span>
            </S.Date>
            <S.ArrowRight selected={false} onClick={() => getMonth(1)}>
              <ArrowRight isActive={true} />
            </S.ArrowRight>
          </S.DateWrapper>
        </S.HeadContainer>

        <TableWithFixedSidebar
          selectedDate={date}
          data={filtered}
          selected={false}
          handleSelectMaterial={handleSelectMaterial}
          selectedCell={selectedCell}
          rightSlideSidebarActive={rightSlideSidebarActive}
          handleOpenProductDetail={handleOpenProductDetail}
          selectIndex={selectIndex}
          setSelectIndex={setSelectIndex}
        />
        <RightSlideSidebar
          selectedMaterial={selectedItem}
          rightSlideSidebarActive={rightSlideSidebarActive}
          setRightSlideSidebarActive={setRightSlideSidebarActive}
        />
      </S.Wrapper>
      {/* グラフ表示 */}
      <MaterialDetailModal
        selectedItem={selectedItem}
        productDetailModalActive={productDetailModalActive}
        selectedDate={date}
        handleClose={handleCloseProductDetail}
        selectedAllocationTypeId={selectedAllocationTypeId}
        allocationTypeOptions={allocationTypeOptions}
        setSelectedAllocationTypeId={setSelectedAllocationTypeId}
        response={filtered}
        selectedProductId={selectedProductId}
        selectedStoreroomId={selectedStoreroomId}
        setSelectedStoreroomId={setSelectedStoreroomId}
        storerooms={storerooms}
      />
      {searchMenuActive ||
        (allocationMenuActive && (
          <Overlay
            handleClick={() => {
              setSearchMenuActive(false);
              setAllocationMenuActive(false);
            }}
          />
        ))}
      {allocationMenuActive && (
        <StoreRoomAllocationTypeModal
          allocationMenuActive={allocationMenuActive}
          storerooms={storerooms}
          stockAllocationsAll={allMaterialStocks}
          selectedAllocationTypeId={selectedAllocationTypeId}
          setSelectedAllocationTypeId={setSelectedAllocationTypeId}
          selectedStoreroomId={selectedStoreroomId}
          setSelectedStoreroomId={setSelectedStoreroomId}
          allocationTypeOptions={allocationTypeOptions}
          handleClose={() => setAllocationMenuActive(false)}
        />
      )}
    </WithHeader>
  );
};
