import styled from 'styled-components';

export const Wrapper = styled.div<{
  marginLeft?: string;
}>`
  display: flex;
  position: relative;
  text-align: center;
  margin-left: ${(props) => (props.marginLeft ? props.marginLeft : '0.25rem')};
  margin-top: 0.3rem;
`;
