import { useState } from 'react';
import * as S from './SearchConditionListModal.styles';
import { Button } from '@components/elements/buttons/Button';

export const STATUS_CONDITIONS = [
  { id: 1, name: '未送信' },
  { id: 2, name: '送信済み' },
];

export const DEST_CONDITIONS = [
  { id: 1, name: '豊鋼材工業株式会社' },
  { id: 2, name: '株式会社神保哲也アトリエ' },
  { id: 3, name: 'みずほ銀行 福岡法人第二部 渉外4課' },
];

type Props = {
  statusConditions: number[];
  destConditions: number[];
  setStatusConditions: (ids: number[]) => void;
  setDestConditions: (ids: number[]) => void;
  handleClose: () => void;
};

const SearchConditionListModal = ({
  destConditions,
  statusConditions,
  handleClose,
  setDestConditions,
  setStatusConditions,
}: Props) => {
  const [selectedDestConditions, setSelectedDestConditions] =
    useState<number[]>(destConditions);
  const [selectedStatusConditions, setSelectedStatusConditions] =
    useState<number[]>(statusConditions);
  const onClickDestCheck = (event: React.ChangeEvent<HTMLInputElement>) => {
    const clickedId = Number(event.target.value);
    event.target.checked
      ? setSelectedDestConditions([...selectedDestConditions, clickedId])
      : setSelectedDestConditions(
          selectedDestConditions.filter((id: number) => id !== clickedId)
        );
  };
  const onClickStatusCheck = (event: React.ChangeEvent<HTMLInputElement>) => {
    const clickedId = Number(event.target.value);
    event.target.checked
      ? setSelectedStatusConditions([...selectedStatusConditions, clickedId])
      : setSelectedStatusConditions(
          selectedStatusConditions.filter((id: number) => id !== clickedId)
        );
  };
  console.log(selectedStatusConditions, selectedDestConditions);

  return (
    <S.Wrapper>
      <S.Container>
        <S.Header>
          <S.Title>取引先</S.Title>
        </S.Header>
        <S.ListUl>
          {DEST_CONDITIONS.map((dest) => (
            <S.ListLi key={dest.id}>
              <S.Label>
                <S.Checkbox
                  type="checkbox"
                  name="list"
                  value={dest.id}
                  onChange={onClickDestCheck}
                />
                <S.TagName
                  defaultChecked={selectedDestConditions.includes(dest.id)}
                >
                  {dest.name}
                </S.TagName>
              </S.Label>
            </S.ListLi>
          ))}
        </S.ListUl>
        <S.Header>
          <S.Title>ステータス</S.Title>
        </S.Header>
        <S.ListUl>
          {STATUS_CONDITIONS.map((status) => (
            <S.ListLi key={status.id}>
              <S.Label>
                <S.Checkbox
                  type="checkbox"
                  name="list"
                  value={status.id}
                  onChange={onClickStatusCheck}
                />
                <S.TagName
                  defaultChecked={selectedStatusConditions.includes(status.id)}
                >
                  {status.name}
                </S.TagName>
              </S.Label>
            </S.ListLi>
          ))}
        </S.ListUl>
      </S.Container>
      <S.ButtonWrapper>
        <S.ButtonContainer>
          <Button borderWidth={1} outlined={true} onClick={handleClose}>
            キャンセル
          </Button>
          <Button
            onClick={() => {
              setDestConditions(selectedDestConditions);
              setStatusConditions(selectedStatusConditions);
              handleClose();
            }}
          >
            決定
          </Button>
        </S.ButtonContainer>
      </S.ButtonWrapper>
    </S.Wrapper>
  );
};
export default SearchConditionListModal;
