import styled from 'styled-components';

export const Wrapper = styled.div`
  width: 100vw;
  background-color: ${(props) => props.theme.palette.white};
  /* background-color: ${(props) => props.theme.palette.yellowGray}; */
  .gap {
    height: 12px;
    background-color: ${(props) => props.theme.palette.sandGray};
  }
`;

export const TitleText = styled.div`
  justify-content: space-between;
  padding: 0 0.5rem;
  display: flex;
  font-size: 16px;
  font-weight: 600;
  line-height: 23px;
  letter-spacing: 0em;
  text-align: left;
  /* background-color: ${(props) => props.theme.palette.white}; */
`;

export const TextWhite = styled.div<{
  displayMessage: boolean;
}>`
  padding-top: 0.5rem;
  padding-left: 16px;
  background-color: ${(props) => props.theme.palette.white};
  width: 100vw;
  border-radius: ${(props) => (props.displayMessage ? '' : '8px 8px 0 0')};
`;

export const DetailWrapper = styled.div<{
  open: boolean;
}>`
  display: ${(props) => (props.open ? 'block' : 'none')};
  align-items: center;
  padding-bottom: 0.5rem;
  background-color: ${(props) => props.theme.palette.yellowGray};
`;

export const Detail = styled.div`
  width: 100vw;
  padding-bottom: 16px;
  padding: 0 0.5rem;
`;

export const DetailWhite = styled.div<{
  lastDetail: boolean;
}>`
  background-color: ${(props) => props.theme.palette.white};
  border-radius: ${(props) => (props.lastDetail ? '0 0 8px 8px' : '')};
  padding-bottom: 8px;
`;

export const ExpirationDate = styled.div`
  font-weight: 700;
  font-size: 16px;
  margin-bottom: 0.5rem;
  /* border-top: 1px solid ${(props) => props.theme.palette.white}; */
  padding-top: 0.5rem;
  /* padding-left: 16px; */

  > label {
    text-align: center;
  }
`;

export const LabelWrapper = styled.div`
  position: relative;
  text-align: center;
  margin-left: 18px;
`;

export const CaseWrapper = styled.div`
  margin-left: 16px;
`;

export const ProductionOrShippingDateLabel = styled.label`
  display: block;
  position: absolute;
  top: 36%;
  font-size: 15px;
  font-weight: 400;
`;

export const DeleteButtonWrapper = styled.div`
  position: relative;
  cursor: pointer;
`;

export const DeleteButton = styled.div`
  font-size: 1rem;
  position: absolute;
  top: -2.4rem;
  right: 6%;
  padding-top: 8px;
`;

// ピッキング入力エリア
export const AmountWrapper = styled.div`
  display: flex;
  padding: 0 16px;
`;
export const DInputContainer = styled.div<{
  center?: boolean;
}>`
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 6px;
  > label {
    font-weight: 400;
    font-size: 13px;
  }
  > input {
    font-weight: 700;
    height: 44px;
    /* height: 3.25rem; */
    max-width: 6.75rem;
    width: 100%;
    border-radius: 8px;
    text-align: ${(props) => (props.center ? 'center' : 'right')};
    border: 1px solid rgba(0, 0, 0, 0.08);
    padding-right: 12px;

    :focus {
      outline: none;
    }

    :disabled {
      background-color: transparent;
    }
  }

  > span {
    position: absolute;
    right: 0.3rem;
    top: 75%;
    font-size: 12px;
    margin-left: 4px;
    color: #6b675c;
  }
`;

export const DSymbol = styled.div`
  margin-top: 2.5rem;
  padding: 0 5px;
  font-weight: 700;
  font-size: 13px;
`;

export const Input = styled.input<{
  value: string;
}>`
  width: 54%;
  height: 44px;
  /* height: 3.25rem; */
  vertical-align: middle;
  color: ${(props) => props.theme.palette.baseBlack};
  font-size: 1rem;
  line-height: 1.25rem;
  padding: 0.325rem;
  background: ${(props) => props.theme.palette.white};
  border: none;
  border-radius: 0.5rem;
  border: 1px solid rgba(0, 0, 0, 0.08);
  -webkit-appearance: none;
  font-weight: ${(props) => (props.value ? '700' : '')};
  ::-webkit-date-and-time-value {
    text-align: left;
    padding-left: 8px;
  }
`;

export const AddIconWrapper = styled.div`
  width: 24px;
  margin: 0 auto;
  margin-top: 0.5rem;
`;
