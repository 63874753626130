import { axiosInstance } from '@lib/mobile/common/api/axiosConfig';
import { useQuery } from 'react-query';
import humps from 'humps';
import moment from 'moment';
import type { DetailItem } from '@lib/common/type';

export const useDuplicateEstimatedStock = (
  productId: number | string,
  storeroomId: number | string,
  date: Date,
  stockKind: number | string
) => {
  const { data } = useQuery(
    [
      `duplicate_estimated_stocks${productId}`,
      productId,
      storeroomId,
      date,
      stockKind,
    ],
    async () => {
      const data = await axiosInstance().get(
        `/api/v1/estimated_stocks/${productId}/${storeroomId}?date=${moment(
          date
        ).format('YYYY-MM-DD')}&stock_kind=${stockKind}`
      );

      return data;
    }
  );
  const duplicateEstimatedStock = humps.camelizeKeys(
    data?.data.stocks.amount_detail
  ) as DetailItem[];

  return { duplicateEstimatedStock };
};

export default useDuplicateEstimatedStock;
