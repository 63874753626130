import { AlignType } from './TableWithFilter';

export const TABLE_HEADER = [
  {
    key: '出荷日',
    filter: false,
    width: '4.44rem',
    align: 'left' as AlignType,
  },
  {
    key: '出荷先グループ名',
    filter: false,
    width: '15.1rem',
    align: 'left' as AlignType,
  },
  {
    key: '出荷予定時刻',
    filter: false,
    width: '5.25rem',
    align: 'right' as AlignType,
  },
  {
    key: 'ピッキング完了時刻',
    filter: false,
    width: '5.25rem',
    align: 'right' as AlignType,
  },
  {
    key: '品目数',
    filter: false,
    width: '4.25rem',
    align: 'right' as AlignType,
  },
  {
    key: '合計個数',
    filter: false,
    width: '4.25rem',
    align: 'right' as AlignType,
  },
  {
    key: '出荷止め',
    filter: false,
    width: '4.25rem',
    align: 'right' as AlignType,
  },
  {
    key: '欠品',
    filter: false,
    width: '4.25rem',
    align: 'right' as AlignType,
  },
  {
    key: 'ステータス',
    filter: true,
    width: '7.25rem',
    align: 'right' as AlignType,
  },
];

import moment from 'moment';

const today = moment();
const formattedDate = moment(today).format('YYYY-MM-DD');

const shippingGroups = [
  '福岡警固エリア',
  'サトウ食品',
  '北九州エリア',
  '筑豊エリア',
  '筑後エリア',
];

const shippingTime = [
  '08:00',
  '09:00',
  '10:00',
  '11:00',
  '12:00',
  '13:00',
  '14:00',
  '15:00',
  '16:00',
  '17:00',
  '18:00',
  '19:00',
  '20:00',
  '21:00',
];

const pickingStatus = ['waiting_picking', 'picking', 'waiting_shipping'];

export const generateStoreDummy = () => {
  return [...Array(30)].map((_, i) => {
    return {
      id: i,
      date: formattedDate,
      shippingGroup:
        shippingGroups[Math.floor(Math.random() * shippingGroups.length)],
      shippingTime:
        shippingTime[Math.floor(Math.random() * shippingTime.length)],
      items: Math.floor(Math.random() * 20),
      sum: Math.floor(Math.random() * 1000),
      stopShipping: Math.random() < 0.3,
      status: pickingStatus[Math.floor(Math.random() * pickingStatus.length)],
    };
  });
};

export const TABLE_DATA_DUMMY = generateStoreDummy();
