import { useHistory } from 'react-router-dom';
import { ReactComponent as ChevronLeftIcon } from '@assets/icons/mobile/chevron_left.svg';
import * as S from './TitleBar.styles';

const TitleBar = ({ productName }: { productName: string }) => {
  const history = useHistory();
  return (
    <S.Wrapper>
      <S.BackButton onClick={() => history.goBack()}>
        <ChevronLeftIcon />
      </S.BackButton>
      <S.TitleText>{`${productName}の在庫状況`}</S.TitleText>
    </S.Wrapper>
  );
};
export default TitleBar;
