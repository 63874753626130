import styled from 'styled-components';

export const TagList = styled.ul``;

export const TagItem = styled.li``;

export const TagLabel = styled.label<{ $checked: boolean }>`
  display: block;
  padding: 1.25rem 1.5rem;
  width: 100%;
  border-bottom: 2px solid #f2f2f2;
  background: ${(props) =>
    props.$checked ? '#F3F6F7' : props.theme.palette.white};
`;

export const TagCheckBox = styled.input`
  opacity: 0;
  appearance: none;
  position: absolute;
`;

export const TagName = styled.span<{ checked: boolean }>`
  display: flex;
  align-items: center;
  color: ${(props) => props.theme.palette.blue};
  font-weight: bold;

  ::before {
    display: inline-block;
    content: '';
    width: 1.125rem;
    height: 1.125rem;
    margin-right: 1.25rem;
    /* TODO: チェックマークだけsvgにして枠は背景はcssで作りたい */
    /* TODO: elementに切り出し */
    background-image: ${(props) =>
      props.checked
        ? "url('data:image/svg+xml;charset=utf8,%3Csvg%20width%3D%2218%22%20height%3D%2218%22%20viewBox%3D%220%200%2018%2018%22%20fill%3D%22none%22%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%3E%3Cpath%20d%3D%22M16%200H2C0.89%200%200%200.9%200%202V16C0%2017.1%200.89%2018%202%2018H16C17.11%2018%2018%2017.1%2018%2016V2C18%200.9%2017.11%200%2016%200ZM7%2014L2%209L3.41%207.59L7%2011.17L14.59%203.58L16%205L7%2014Z%22%20fill%3D%22%232BA9D1%22%2F%3E%3C%2Fsvg%3E')"
        : "url('data:image/svg+xml;charset=utf8,%3Csvg%20width%3D%2218%22%20height%3D%2218%22%20viewBox%3D%220%200%2018%2018%22%20fill%3D%22none%22%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%3E%3Cpath%20d%3D%22M16%202V16H2V2H16ZM16%200H2C0.9%200%200%200.9%200%202V16C0%2017.1%200.9%2018%202%2018H16C17.1%2018%2018%2017.1%2018%2016V2C18%200.9%2017.1%200%2016%200Z%22%20fill%3D%22%23C0C0C0%22%2F%3E%3C%2Fsvg%3E')"};
    background-repeat: no-repeat;
    background-position: center;
  }
`;
