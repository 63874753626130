type statusType = {
  label: string;
  barLabel: string;
  statusBarBgColor: string;
  statusBorderColor: string;
  statusBarColor: string;
  trBgColor: string;
};

// export const pickingStatus: { [key: string]: statusType } = {
export const pickingStatus: { [key: number]: statusType } = {
  // waiting_picking: {
  0: {
    label: '未着手',
    barLabel: '未着手',
    statusBarBgColor: '#FFD130',
    statusBorderColor: '#FFD130',
    statusBarColor: '#191712',
    trBgColor: '#fff',
  },
  // picking: {
  1: {
    label: 'ピッキング中',
    barLabel: 'ピッキング中',
    statusBarBgColor: '#FFFCE5',
    statusBorderColor: '#F19B4C',
    statusBarColor: '#F19B4C',
    trBgColor: '#FFFDE9',
  },
  // waiting_double_check: {
  //   label: 'Wチェック待ち',
  //   barLabel: 'Wﾁｪｯｸ待ち',
  //   statusBarBgColor: '#2BA9D1',
  //   statusBorderColor: '#2BA9D1',
  //   statusBarColor: '#fff',
  //   trBgColor: '#fff',
  // },
  // double_checking: {
  //   label: 'Wチェック中',
  //   barLabel: 'Wﾁｪｯｸ中',
  //   statusBarBgColor: '#EEFBFF',
  //   statusBorderColor: '#2BA9D1',
  //   statusBarColor: '#2BA9D1',
  //   trBgColor: '#EEFBFF',
  // },
  // waiting_shipping: {
  2: {
    label: '出荷済み',
    barLabel: '出荷済み',
    statusBarBgColor: '#E0E0E0',
    statusBorderColor: '#6B675C',
    statusBarColor: '#6B675C',
    trBgColor: '#E0E0E0',
  },
  3: {
    label: '振分待ち',
    barLabel: '振分待ち',
    statusBarBgColor: '#2BA9D1',
    statusBorderColor: '#2BA9D1',
    statusBarColor: '#ffffff',
    trBgColor: '#2BA9D1',
  },
  4: {
    label: '振分中',
    barLabel: '振分中',
    statusBarBgColor: '#ffffff',
    statusBorderColor: '',
    statusBarColor: '#2BA9D1',
    trBgColor: '#ffffff',
  },
  // shipping_completed: {
  //   label: '出荷完了',
  //   barLabel: '出荷完了',
  //   statusBarBgColor: '#6B675C',
  //   statusBorderColor: '#6B675C',
  //   statusBarColor: '#FFFFFF',
  //   trBgColor: '#C0C0C0',
  // },
} as const;
type pickingStatus = typeof pickingStatus[keyof typeof pickingStatus];
