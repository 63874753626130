import styled from 'styled-components';

export const Wrapper = styled.div`
  background-color: #f2f2f2;
`;

export const TitleWrapper = styled.div`
  display: flex;
  vertical-align: center;
`;

export const BackButton = styled.button`
  padding: 0.875rem 1rem;
  background: none;
`;

export const Header = styled.div`
  padding: 0.625rem 1rem;
  background: ${(props) => props.theme.palette.beige};
  border-bottom: 1px solid ${(props) => props.theme.palette.sand};
`;

export const IconWrapper = styled.div`
  width: 4rem;
  .svg {
    vertical-align: center;
  }
`;

export const TitleText = styled.p`
  align-items: center;
  font-weight: bold;
  font-size: 1.125rem;
  padding-right: 50px;
  margin: 0 auto;
  padding-top: 10px;
`;
