import { useState } from 'react';
import * as S from './DeleteConfirmModal.styles';
import { Button } from '@components/elements/buttons/Button';
import Delete from '@assets/icons/delete';

type Props = {
  name: string;
  onClickCancel: () => void;
  onClickDelete: () => void;
};

const DeleteConfirmModal = ({ name, onClickCancel, onClickDelete }: Props) => {
  const [isDeleted, setIsDeleted] = useState(false);

  return (
    <S.DeleteModalWrapper>
      <S.DeleteModalTextContainer>
        <span>{name}</span>
        <span>を本当に削除しますか？</span>
      </S.DeleteModalTextContainer>
      <S.DeleteModalButtonContainer isDeleted={isDeleted}>
        <Button
          padding="sm"
          outlined
          onClick={() => {
            onClickCancel();
          }}
        >
          キャンセル
        </Button>
        <Button
          padding="sm"
          icon={<Delete isActive={isDeleted} />}
          outlined
          disabled={isDeleted}
          onClick={() => {
            onClickDelete();
            setIsDeleted(!isDeleted);
          }}
        >
          削除
        </Button>
      </S.DeleteModalButtonContainer>
    </S.DeleteModalWrapper>
  );
};

export default DeleteConfirmModal;
