import styled from 'styled-components';

export const Wrapper = styled.div`
  background: ${(props) => props.theme.palette.white};
  position: relative;
  height: calc(100vh - 80px);
`;

export const HeadContainer = styled.div`
  height: 86px;
  padding: 1.15rem 2.25rem;
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-bottom: 1px solid #f5f2e6;
`;

export const PageTitle = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

export const PageTitleText = styled.div`
  display: flex;
  align-items: baseline;
  padding-bottom: 4px;
`;

export const DateFilter = styled.div<{
  invalid?: boolean;
  isBlue?: boolean;
}>`
  pointer-events: ${(props) => (props.invalid ? 'none' : '')};
  min-height: 42px;
  width: 230px;
  border-radius: 0.5rem;
  border: 1px solid ${(props) => props.theme.palette.yellowGray};
  position: -webkit-sticky; /* for Safari */
  -webkit-transform: translateZ(0);
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 6px 2px;
  gap: 0.25rem;
  display: flex;
  position: relative;
  margin-left: 12px;

  > span {
    font-size: 0.75rem;
    &.filter-product {
      width: 90%;
      padding-left: 36px;
      height: 24px;
      line-height: 24px;
      position: absolute;
      background: ${(props) => props.theme.palette.white};
      color: ${(props) =>
        props.isBlue ? props.theme.palette.blue : '#9ca3af'};
      border-radius: 0.5rem;
      cursor: pointer;

      > .filter_text {
        font-size: 13px;
        line-height: 16px;
        padding-left: 18px;
        text-align: left;
        position: absolute;
        top: -8%;
        color: ${(props) => props.theme.palette.black};
      }

      > .filter_text_false {
        position: absolute;
        top: -8%;
        font-size: 13px;
        line-height: 16px;
        padding-left: 8px;
        color: ${(props) => props.theme.palette.black};
      }
    }
  }

  :first-child {
    > div > input {
      cursor: pointer;
      height: 32px;
      padding: 0.5rem 40px 0.5rem;
    }
  }
`;

export const SearchFilterIconContainer = styled.div`
  position: absolute;
  top: 12%;
  left: 5%;
`;

export const DateWrapper = styled.div`
  display: flex;
  align-items: center;
  position: relative;
`;

export const ButtonWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  bottom: 0;
  right: 0;
  left: 0;
  height: 83px;
  margin-right: 20px;
`;

export const ButtonContainer = styled.div<{
  padding?: string;
  width?: string;
  buttonType?: string;
}>`
  display: flex;
  align-items: center;
  justify-content: center;
  width: ${(props) => (props.width ? props.width : '100px')};
  height: 45px;
  padding: ${(props) => (props.padding ? props.padding : '')};
  border-radius: 5px;
  color: ${(props) =>
    props.buttonType === 'edit'
      ? props.theme.palette.primary.main
      : props.buttonType === 'delete'
      ? props.theme.palette.error
      : props.theme.palette.white};
  background: ${(props) =>
    props.buttonType === 'edit' || props.buttonType === 'delete'
      ? props.theme.palette.white
      : props.theme.palette.primary.main};
  border: ${(props) =>
    `${
      props.buttonType === 'edit'
        ? props.theme.palette.primary.main
        : props.buttonType === 'delete'
        ? props.theme.palette.error
        : props.theme.palette.white
    } 1px solid`};
  font-weight: bold;
  font-size: 16px;
  cursor: pointer;
`;

export const HeaderRightContainer = styled.div`
  display: flex;
`;

export const DetailConditionText = styled.div`
  font-size: 13px;
  color: ${(props) => props.theme.palette.black};
`;

export const MainContent = styled.div`
  display: flex;
  overflow: scroll;
  height: calc(100vh - 166px);
`;

export const PurchaseOrderList = styled.div`
  /* max-width: 250px; */
  padding: 8px;
`;

export const CenterColumn = styled.div`
  width: 100%;
  align-self: stretch;
  padding-top: 8px;
`;

export const OperationPanel = styled.div`
  border: 1px solid #f5f2e6;
`;

export const ButtonArea = styled.div`
  width: 100%;
  display: flex;
  /* justify-content: center; */
  gap: 1rem;
  padding: 1rem;
`;

export const PdfView = styled.div`
  background: #e7e2dd;
  width: 100%;
  padding: 0 4rem 4rem;
`;

export const PurchaseOrderItem = styled.div<{ index: number }>`
  height: 52px;
  width: 250px;
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
  padding: 6px 0.75rem;
  border-right: 1px solid #f5f2e6;
  border-left: 1px solid #f5f2e6;
  border-bottom: 1px solid #f5f2e6;
  background: ${(props) => (props.index % 2 === 0 ? '#fff' : '#fffef5')};
  :first-child {
    border-top: 1px solid #f5f2e6;
  }
`;

export const PurchaseOrderItemRow = styled.div`
  display: flex;
  justify-content: space-between;
`;

export const PurchaseOrderItemDate = styled.div`
  font-size: 0.75rem;
`;

export const KindLabel = styled.div`
  font-size: 0.75rem;
  padding: 2px 6px;
  border-radius: 2px;
  border: 1px solid #2ba9d1;
  color: #2ba9d1;
  white-space: nowrap;
  height: 18px;
`;

export const AcceptingOrderKindLabel = styled.div`
  font-size: 1.1rem;
  padding: 2px 6px;
  white-space: nowrap;
  height: 25px;
`;

export const AcceptingOrderItemContainer = styled.div``;

export const AcceptingOrderItemName = styled.div`
  font-size: 25px;
  font-weight: bold;
`;

export const KindLabelFill = styled(KindLabel)`
  background: #2ba9d1;
  color: #fff;
`;

export const PurchaseOrderItemName = styled.div`
  font-size: 14px;
  color: #2ba9d1;
`;

export const DetailList = styled.div`
  width: 800px;
  display: flex;
  flex-direction: column;
  gap: 1.5rem;
  padding: 8px 0.75rem;
  border: 1px solid #f5f2e6;
  margin: 8px;
`;

export const ProductListHeader = styled.div`
  display: flex;
  height: 2rem;
  border-bottom: 1px solid #000;
  align-items: center;
`;
export const ProductDeliveryDay = styled.div`
  flex-basis: 35%;
`;
export const ProductName = styled.div`
  flex-basis: 50%;
`;
export const ProductQuantity = styled.div`
  flex-basis: 15%;
`;

export const ProductListBody = styled.div`
  border-bottom: 1px solid #000;
`;
export const ProductListRow = styled.div<{ index: number }>`
  display: flex;
  height: auto;
  padding: 0.5rem;
  word-break: break-all;
  align-items: center;
  background-color: ${({ index }) => (index % 2 ? '#f7f7f7' : '#fff')};
`;

export const DetailItem = styled.div`
  display: flex;
  align-items: center;
  gap: 0.5rem;
  justify-content: space-between;
`;

export const DetailItemText = styled.div`
  line-height: 1.2;
`;

export const DetailTable = styled.table`
  width: 500px;
  border-collapse: collapse;
  margin-bottom: 20px;
`;
export const TableHeader = styled.th`
  border: 1px solid #dddddd;
  text-align: left;
  padding: 8px;
  background-color: #f2f2f2;
`;

export const TableCell = styled.td`
  border: 1px solid #dddddd;
  text-align: left;
  padding: 8px;
`;

export const ModalWrapper = styled.div`
  width: 500px;
  background: ${(props) => props.theme.palette.white};
  position: fixed;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  z-index: 10001;
  box-shadow: 0px 6px 10px rgba(0, 0, 0, 0.07), 0px 1px 18px rgba(0, 0, 0, 0.06),
    0px 3px 5px rgba(0, 0, 0, 0.1);
  border-radius: 8px;
  padding: 2rem 4rem;
`;

export const Input = styled.input<{
  align?: string;
  padding?: string;
  height?: string;
}>`
  width: 100%;
  height: 40px;
  color: ${(props) => props.theme.palette.baseBlack};
  font-size: 1rem;
  padding: ${(props) => (props.padding ? props.padding : '0.325rem')};
  text-align: ${(props) => props.align};
  border: none;
  border-radius: 0.5rem;
  border: 1px solid rgba(0, 0, 0, 0.08);
  height: ${(props) => (props.height ? props.height : '')};
  background: ${(props) => props.theme.palette.white};
  ::-webkit-inner-spin-button {
    -webkit-appearance: none;
  }
  margin-top: 8px;
`;

export const ModalFormTextArea = styled.textarea`
  width: 100%;
  min-height: 4rem;
  color: ${(props) => props.theme.palette.baseBlack};
  font-size: 1rem;
  line-height: 1.25rem;
  padding: 0.325rem;
  border: none;
  border-radius: 0.5rem;
  border: 1px solid rgba(0, 0, 0, 0.08);
  background: ${(props) => props.theme.palette.white};
  word-wrap: break-word;
  word-break: break-all;
  white-space: normal;
  margin-top: 8px;
`;

export const ModalFormLabel = styled.p`
  margin-top: 8px;
`;

export const ModalHeadWrapper = styled.div`
  display: flex;
  justify-content: center;
`;

export const ModalButtonWrapper = styled.div`
  display: flex;
  justify-content: center;
  margin-top: 24px;
`;

export const SearchConditionNameList = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 2px;
  width: 100%;
`;

export const FilterIconWrapper = styled.div`
  width: 24px;
  height: 24px;
  cursor: pointer;
`;

export const SearchConditionLabel = styled.div`
  max-width: 188px;
  font-size: 0.75rem;
  padding: 2px 6px;
  border-radius: 2px;
  border: 1px solid #2ba9d1;
  color: #2ba9d1;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  height: 18px;
`;

export const OperationPanelUpper = styled.div`
  display: flex;
  justify-content: space-between;
  padding: 1rem;
`;
export const OperationPanelDestName = styled.div`
  font-size: 1.5rem;
  font-weight: bold;
`;
export const OperationPanelStatusChange = styled.div`
  width: 140px;
`;
