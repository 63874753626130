import { FC } from 'react';
import moment from 'moment';
import {
  VictoryAxis,
  VictoryBar,
  VictoryChart,
  VictoryContainer,
  VictoryGroup,
  VictoryLine,
  VictoryScatter,
} from 'victory';
import { DividerWithDot } from '@components/elements/dividers/DividerWithDot';
import { ModalPaper } from '@components/modules/common/pc/ModalPaper';
import {
  convertSafetyStocksArrToBarData,
  convertStockEstimatedArrToBarData,
  convertActualArrToBarData,
  convertActualOrSafetyStocksArrToBarData,
  addComma,
  changeWidth,
  generateDateArrayForModal,
  findMinAmount,
  findMaxAmount,
} from '@lib/pc/production_schedule/functions';
import * as S from '../Graph.styles';
import { CircularProgress } from '@material-ui/core';
import type { ResponseStock } from '@lib/pc/production_schedule/type';
import type { Todo } from '@lib/common/type';

type Props = {
  data: Todo;
  date: Todo;
  setDate: (date: Todo) => void;
  productSafetyStockDays: string;
};

/*  COMMENT(220314):生産計画の根拠グラフ */
const StockGraph: FC<Props> = ({
  data,
  date,
  setDate,
  productSafetyStockDays,
}: Props) => {
  const indexNum = Number(date.format('DD') - 1);

  const minYCoordinate = findMinAmount(data?.stocks);
  const maxYCoordinate = findMaxAmount(data?.stocks);

  return (
    <ModalPaper heading1="在庫数の予定と実績" date={date} setDate={setDate}>
      <S.GraphControlHead>
        <S.DescHeader>
          生産計画の通りに生産が行われた場合に、
          <br />
          どのくらいの在庫数になるかを
          <br />
          ｢予定在庫数｣として算出しています。
        </S.DescHeader>
      </S.GraphControlHead>
      {data ? (
        <S.GraphContainer>
          <S.LeftHeadContainer>
            <S.LeftLabelContainer>
              <S.LeftGraphLabelContainer>
                <S.GraphLeftLabelContainer>
                  <div>
                    <DividerWithDot borderColor="#0A68F4" />
                  </div>
                  <S.LeftGraphTextContainer>
                    <div>理想在庫数</div>
                    <span>
                      当日を含めて{productSafetyStockDays}日分の
                      <br />
                      出荷予測数を満たす数量
                    </span>
                  </S.LeftGraphTextContainer>
                </S.GraphLeftLabelContainer>
                <S.GraphLeftLabelContainer>
                  <div>
                    <S.ColorBox bgColor={'#F6C1B1'} />
                  </div>
                  <S.LeftGraphTextContainer>
                    <div>予定在庫数 </div>
                    <span>生産計画通りに生産したら、この在庫数になる予定</span>
                  </S.LeftGraphTextContainer>
                </S.GraphLeftLabelContainer>
                <S.GraphLeftLabelContainer>
                  <div>
                    <S.ColorBox bgColor={'#ABD5F3'} />
                  </div>
                  <S.LeftGraphTextContainer>
                    <div>実在在庫数</div>
                    <span>倉庫にある完成品の実数</span>
                  </S.LeftGraphTextContainer>
                </S.GraphLeftLabelContainer>
              </S.LeftGraphLabelContainer>
              <div>
                <VictoryChart
                  domainPadding={{ x: 44 }}
                  width={120}
                  height={320}
                  minDomain={{ y: minYCoordinate }}
                  maxDomain={{ y: maxYCoordinate }}
                  padding={{ top: 50, bottom: 0, right: 0, left: 0 }}
                  containerComponent={<VictoryContainer responsive={false} />}
                >
                  <VictoryAxis
                    width={130}
                    height={400}
                    dependentAxis
                    crossAxis={false}
                    offsetX={120}
                    standalone
                    style={{
                      axisLabel: {
                        fontSize: 20,
                        padding: 30,
                        zIndex: 100,
                      },
                    }}
                  />
                  <VictoryGroup
                    groupComponent={<g display="none" />}
                    offset={20}
                  >
                    <VictoryLine
                      style={{ data: { display: 'none' } }}
                      data={convertSafetyStocksArrToBarData(data.stocks)}
                    />
                    {/* グラフ左のY軸の値 */}
                    <VictoryBar
                      barWidth={16}
                      data={convertActualOrSafetyStocksArrToBarData(
                        data.stocks
                      )}
                      style={{ data: { display: 'none' } }}
                    />
                  </VictoryGroup>
                </VictoryChart>
              </div>
            </S.LeftLabelContainer>

            <div>
              <S.LeftTableEmptyCell />
              <S.LeftTableHeadCell bg="#F1EFEA" color="#0A68F4">
                <span> 理想在庫数</span>
              </S.LeftTableHeadCell>
              <S.LeftTableHeadCell bg={'#F6C1B1'}>
                <span> 予定在庫数</span>
              </S.LeftTableHeadCell>
              <S.LeftTableHeadCell marginTop bg={'#ABD5F3'}>
                <span> 実在在庫数</span>
              </S.LeftTableHeadCell>
            </div>
          </S.LeftHeadContainer>
          <S.ScrollableContainer>
            <VictoryChart
              domainPadding={{ x: 44 }}
              width={changeWidth(data.numberOfDaysParamsMonth)}
              height={320}
              padding={{ top: 50, bottom: 0, right: 0, left: 35 }}
              containerComponent={<VictoryContainer responsive={false} />}
            >
              <VictoryAxis
                //x
                style={{
                  axis: { stroke: '#E7E2DD' },
                  ticks: { stroke: 'white' },
                  tickLabels: {
                    display: 'none',
                  },
                }}
              />
              <VictoryAxis
                //y
                dependentAxis
                style={{
                  axis: { display: 'none' },
                  grid: { stroke: '#E7E2DD' },
                  tickLabels: {
                    fontSize: 0,
                    padding: 0,
                    stroke: '#E7E2DD',
                  },
                }}
              />
              <VictoryGroup offset={20}>
                <VictoryBar
                  style={{
                    data: {
                      fill: '#F6C1B1',
                      stroke: '#B68A7C',
                      strokeWidth: 1,
                    },
                  }}
                  barWidth={16}
                  data={convertStockEstimatedArrToBarData(data.stocks)}
                />
                <VictoryBar
                  style={{
                    data: {
                      fill: '#ABD5F3',
                      stroke: '#728DA1',
                      strokeWidth: 1,
                    },
                  }}
                  barWidth={16}
                  data={convertActualArrToBarData(data.stocks)}
                />
              </VictoryGroup>
              <VictoryGroup data={convertSafetyStocksArrToBarData(data.stocks)}>
                <VictoryLine
                  style={{
                    data: {
                      stroke: '#0A68F4',
                      strokeDasharray: '8, 4',
                      zIndex: 100,
                    },
                  }}
                />
                <VictoryScatter
                  size={3}
                  style={{
                    data: {
                      fill: '#ffffff',
                      stroke: '#0A68F4',
                      strokeWidth: 3,
                    },
                  }}
                />
              </VictoryGroup>
            </VictoryChart>
            <S.TableWrapper>
              <S.TableRow>
                {generateDateArrayForModal(
                  data.numberOfDaysParamsMonth,
                  date
                ).map((day: Todo, i: number) => {
                  return (
                    <S.TableHeadCell
                      key={i}
                      isToday={i === indexNum}
                      dayOfWeek={moment(day.productionDate).format('ddd')}
                    >
                      {i === 0 ? (
                        <div>
                          {moment(day.productionDate).format('MM/DD')}
                          <span>
                            {moment(day.productionDate).format('(ddd)')}
                          </span>
                        </div>
                      ) : (
                        <div>
                          {moment(day.productionDate).format('DD')}
                          <span>
                            {moment(day.productionDate).format('(ddd)')}
                          </span>
                        </div>
                      )}
                    </S.TableHeadCell>
                  );
                })}
              </S.TableRow>
              <S.TableRow>
                {data.stocks.map((p: ResponseStock, i: number) => {
                  return (
                    <S.TableCell
                      color={'#0A68F4'}
                      key={i}
                      isToday={i === indexNum}
                    >
                      {p.safetyStocks ? addComma(p.safetyStocks) : '-'}
                    </S.TableCell>
                  );
                })}
              </S.TableRow>
              <S.TableRow>
                {data.stocks.map((p: ResponseStock, i: number) => {
                  return (
                    <S.TableCell
                      color={'#CE7D64'}
                      key={i}
                      isToday={i === indexNum}
                    >
                      {addComma(p.estimatedAmount) || '-'}
                    </S.TableCell>
                  );
                })}
              </S.TableRow>
              <S.TableRow>
                {data.stocks.map((p: ResponseStock, i: number) => {
                  return (
                    <S.TableCell
                      color={'#3387C5'}
                      key={i}
                      isToday={i === indexNum}
                      marginTop
                    >
                      {addComma(p.actualAmount) || '-'}
                    </S.TableCell>
                  );
                })}
              </S.TableRow>
            </S.TableWrapper>
          </S.ScrollableContainer>
        </S.GraphContainer>
      ) : (
        <S.GraphContainer>
          <S.CircularIconWrapper top={'83rem'}>
            <CircularProgress style={{ color: '#64b2f9' }} />
          </S.CircularIconWrapper>
        </S.GraphContainer>
      )}
    </ModalPaper>
  );
};

export default StockGraph;
