import styles from 'styled-components';

export const Wrapper = styles.div`
  padding-right: 1rem;
  display: flex;
  width: 75%;
  overflow-x: scroll;
  white-space: nowrap;
`;

export const Column = styles.div<{ isToday: boolean; even: boolean }>`
  width: calc((75vw - 1rem) / 3);
  flex-shrink: 0;
  box-sizing: border-box;
  ${(prop) => prop.isToday && 'border: 2px solid #2BA9D1;'}
  ${(prop) => prop.even && 'background: #fff;'}
  border-top: 0;
`;

export const DateCell = styles.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 56px;
  font-weight: 500;
  border-bottom: 2px solid #CDCDCD;
`;

export const TodayDateCell = styles(DateCell)`
  background: #2BA9D1;
  color: #fff;
  font-weight: 700;
  border-bottom: 2px solid #2BA9D1;
`;

export const ValueCell = styles.div`
  height: 58px;
  font-weight: 500;
  display: flex;
  justify-content: center;
  align-items: center;

  &:last-of-type {
    height: 56px;
  }
`;

export const EvenCell = styles(ValueCell)`
  border-bottom: 2px solid #F8F7F4;
  border-left: 2px solid #F8F7F4;
  border-right: 2px solid #F8F7F4;

  &:last-of-type {
    border-bottom: 0
  }
`;

export const ValueCellWrapper = styles.div<{ withComment: boolean }>`
  box-sizing: border-box;
  border-bottom: 2px solid #CDCDCD;
  background-color: ${(props) => (props.withComment ? '#FDDEDE' : '')};
`;
