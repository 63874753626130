import styled from 'styled-components';

export const Wrapper = styled.div`
  display: flex;
  position: relative;
  text-align: center;
  margin-left: 0.75rem;
  margin-top: 0.75rem;
  min-width: 580px;
  max-width: 880px;
`;
