import { ReactComponent as AddIcon } from '@assets/icons/mobile/plus_blue.svg';
import { isIPad13 } from 'react-device-detect';
import * as S from './FloatingAddButton.styles';
import { useDeviceOrientationAndSize } from '@lib/common/functions';

type Props = {
  handleClick: () => void;
  bottom?: string;
  right?: string;
};

const FloatingAddButton = ({ handleClick, bottom, right }: Props) => {
  const { isPortrait, deviceType } = useDeviceOrientationAndSize();

  return (
    <S.FloatingIconWrapper
      bottom={isPortrait && deviceType === 'tablet' ? '15%' : bottom}
      right={isPortrait && deviceType === 'tablet' ? '2.5rem' : right}
      onClick={() => {
        if (!isIPad13) {
          handleClick();
        }
      }}
      onTouchStart={() => {
        if (isIPad13) {
          handleClick();
        }
      }}
    >
      <AddIcon />
    </S.FloatingIconWrapper>
  );
};

export default FloatingAddButton;
