import styled from 'styled-components';

export const Wrapper = styled.div`
  /* padding: 0 0.875rem 1.75rem 1rem; */
`;

export const HeadDate = styled.div`
  font-weight: 700;
  font-size: 24px;
  line-height: 24px;
  margin-bottom: 10px;
`;

export const HeadProductName = styled.div`
  font-weight: 700;
  font-size: 24px;
  line-height: 35px;
`;

export const ProductIcon = styled.span<{ packaged: boolean }>`
  font-size: 14px;
  font-weight: 400;
  padding: 3px 6px;
  width: 24px;
  height: 16px;
  color: ${(props) => (props.packaged ? '#ffffff' : '#2ba9d1')};
  background: ${(props) => (props.packaged ? '#2ba9d1' : '#ffffff')};
  border-radius: 2px;
  border: 0.1rem solid #2ba9d1;
  margin-right: 8px;
`;

export const Heading = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

export const FormTitle = styled.div`
  font-weight: 700;
  font-size: 18px;
  margin: 0.5rem 0;
`;

export const FormAnchor = styled.a`
  color: ${(props) => props.theme.palette.blue};
  font-weight: 500;
  font-size: 16px;

  :hover,
  :active {
    text-decoration: none;
  }
`;

export const InputContainer = styled.div`
  display: flex;
  flex-direction: column-reverse;
`;

export const UnpackagedFormWrapper = styled.div`
  margin-top: 40px;
  padding-top: 40px;
  border-top: solid 1px #c0c0c0;
`;

export const NoteAmountContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: left;
  font-weight: 700;
  padding-top: 1.5rem;
  gap: 1rem;
  border-top: 2px solid #d3d3d3;
  margin-top: 1rem;
`;

export const NoteAmountLabel = styled.div`
  width: 3.5rem;
  font-weight: 700;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 5.5rem;
`;

export const NoteAmountInput = styled.textarea`
  background: #ffffff;
  border-radius: 8px;
  width: 77%;
  height: 5.5rem;
  text-align: left;
  padding: 1rem 0.75rem 1rem;

  resize: none;
`;

export const StockAdditionButton = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;

  & > button {
    border: 0;
    background-color: ${(props) => props.theme.palette.beige};
    color: ${(props) => props.theme.palette.primary.main};
    gap: 0.5625rem;
    font-size: 0.875rem;
    min-width: 7.5rem;
  }
  & path {
    fill: ${(props) => props.theme.palette.primary.main};
  }
`;

export const DeleteModalWrapper = styled.div`
  background: ${(props) => props.theme.palette.white};
  position: fixed;
  left: 50%;
  top: 50%;
  width: 90%;
  transform: translate(-50%, -50%);
  z-index: 10001;
  box-shadow: 0px 6px 10px rgba(0, 0, 0, 0.07), 0px 1px 18px rgba(0, 0, 0, 0.06),
    0px 3px 5px rgba(0, 0, 0, 0.1);
  border-radius: 8px;
  padding: 1rem;
`;

export const DeleteModalTextContainer = styled.div`
  display: flex;
  flex-direction: column;
  font-weight: 500;
  font-size: 1rem;
  line-height: 27px;
`;

export const DeleteModalButtonContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 1rem;
  margin-top: 1rem;
  button {
    width: 100%;
    height: 2.75rem;
    &:nth-child(2) {
      border-color: ${(props) => props.theme.palette.error};
      color: ${(props) => props.theme.palette.error};
    }
  }
`;
