import { useEffect } from 'react';
import type { Todo } from '@lib/common/type';
import * as S from './SemiProductContents.styles';
import { ReactComponent as AddImage } from '@assets/icons/add_image.svg';
import { ReactComponent as CautionIcon } from '@assets/icons/caution.svg';
import { useFileHooks } from '@lib/pc/common/functions';
import LabelOfAmountSelect from '@components/modules/pc/settings/common/LabelOfAmountSelect/LabelOfAmountSelect';
import { ObjectWitKey } from '@lib/pc/settings/semi_product_master/type';
import useAllLabelsOfAmount from '@lib/pc/settings/semi_product_master/useAllLabelsOfAmount';
// import QRCode from 'react-qr-code';

type Props = {
  newProductData: Todo;
  setNewProductData: (newProductData: Todo) => void;
  handleChangeCode: (
    e:
      | React.ChangeEvent<HTMLInputElement>
      | React.ChangeEvent<HTMLTextAreaElement>
  ) => void;
  handleChange: (
    e:
      | React.ChangeEvent<HTMLInputElement>
      | React.ChangeEvent<HTMLTextAreaElement>
  ) => void;
  handleChangeNumber: (
    e:
      | React.ChangeEvent<HTMLInputElement>
      | React.ChangeEvent<HTMLTextAreaElement>
  ) => void;
  handleChangeImage: (imageBase64: string) => void;
  selectedChangeLabelOfAmountName: string;
  setSelectedChangeLabelOfAmountName: (
    selectedChangeLabelOfAmountName: string
  ) => void;
  handleChangeCheckBox: (e: React.ChangeEvent<HTMLInputElement>) => void;
  setIsChanged: (isChanged: boolean) => void;
  caseAlert?: boolean;
  storeRoomAlert?: boolean;
};

const SemiProductContents = ({
  newProductData,
  setNewProductData,
  handleChangeCode,
  handleChange,
  handleChangeNumber,
  handleChangeImage,
  selectedChangeLabelOfAmountName,
  setSelectedChangeLabelOfAmountName,
  handleChangeCheckBox,
  setIsChanged,
  caseAlert,
  storeRoomAlert,
}: Props) => {
  const { handleFiles, imageContainerRef } = useFileHooks();
  const imageSelected = !!imageContainerRef?.current?.innerHTML;
  const { allLabelsOfAmount } = useAllLabelsOfAmount();
  const onFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    handleFiles(event);
    const file = event?.currentTarget?.files?.[0];
    if (!file) return;

    const reader = new FileReader();
    reader.addEventListener(
      'load',
      () => {
        typeof reader.result === 'string' && handleChangeImage?.(reader.result);
      },
      false
    );

    if (file) {
      reader.readAsDataURL(file);
    }
  };

  const labelsOfAmountObjects =
    allLabelsOfAmount &&
    Object.values(allLabelsOfAmount)?.map((value, key) => ({
      id: key,
      name: value,
    }));

  const labelsOfAmountKeys =
    allLabelsOfAmount && Object.keys(allLabelsOfAmount);

  // 新規作成時の「半製品の単位」初期値設定
  useEffect(() => {
    const objectsWithKey: ObjectWitKey[] = [];
    labelsOfAmountObjects?.forEach((obj: Todo, idx: number) => {
      const appendedKey = Object.assign({}, obj, {
        key: labelsOfAmountKeys[idx],
      });
      objectsWithKey.push(appendedKey);
    });
    const labelValue = objectsWithKey.find(
      (o) => o.key === newProductData?.labelOfAmount
    );
    labelValue && setSelectedChangeLabelOfAmountName(labelValue?.name);
  }, [labelsOfAmountObjects?.length, newProductData?.labelOfAmount]);

  return (
    <>
      {/* 商品画像 */}
      <div className="product-img">
        <label>
          {imageSelected ? (
            ''
          ) : newProductData?.imageUrl ? (
            <img src={newProductData.imageUrl} />
          ) : (
            <div className="add-img">
              <AddImage />
            </div>
          )}
          <div ref={imageContainerRef} />
          <S.ImageInput
            type="file"
            accept="image/*"
            onChange={onFileChange}
            name="image"
          />
        </label>
        {/* <QRCode
          className="qr"
          value={`${process.env.REACT_APP_SELF_URL}/mobile/products/${newProductData?.id}/capture_for_training/1234`}
          size={110}
        /> */}
      </div>
      <dl className="product-infos">
        <S.AlertArea>
          {caseAlert && (
            <S.AlertWrapper>
              <CautionIcon />
              <S.Alert>ケースが未登録です。</S.Alert>
            </S.AlertWrapper>
          )}
          {storeRoomAlert && (
            <S.AlertWrapper>
              <CautionIcon />
              <S.Alert> 在庫室が未登録です。</S.Alert>
              <S.Alert>
                {caseAlert || storeRoomAlert || '在庫室が未登録です。'}
              </S.Alert>
            </S.AlertWrapper>
          )}
        </S.AlertArea>
        <div className="product-info">
          <div className="product-info">
            <dt>半製品コード</dt>
            <dd>
              <S.Input
                width="30%"
                type="text"
                pattern="^[a-zA-Z0-9]+$"
                name="semiProductCode"
                id="semiProductCode"
                autoComplete="off"
                align="left"
                padding="0 20px"
                value={
                  newProductData?.semiProductCode
                    ? newProductData?.semiProductCode
                    : ''
                }
                onChange={handleChangeCode}
              />
            </dd>
          </div>
          <dt>半製品名</dt>
          <dd>
            <S.Input
              type="text"
              name="name"
              id="name"
              value={newProductData?.name ? newProductData?.name : ''}
              onChange={handleChange}
              autoComplete="new-password"
              align="left"
              padding="0 20px"
            />
          </dd>
        </div>

        <div className="product-info">
          <dt>半製品名（ふりがな）</dt>
          <dd>
            <S.Input
              type="text"
              name="nameKana"
              id="nameKana"
              value={newProductData?.nameKana ? newProductData?.nameKana : ''}
              onChange={handleChange}
              autoComplete="new-password"
              align="left"
              padding="0 20px"
            />
          </dd>
        </div>

        <div className="product-info">
          <dt>半製品名（略称）</dt>
          <dd>
            <S.Input
              type="text"
              name="nameAbbreviation"
              id="nameAbbreviation"
              value={
                newProductData?.nameAbbreviation
                  ? newProductData?.nameAbbreviation
                  : ''
              }
              onChange={handleChange}
              autoComplete="new-password"
              align="left"
              padding="0 20px"
            />
          </dd>
        </div>

        <div className="product-infos__codes">
          <div className="product-info number-of-days-before-expiration-date">
            <dt>賞味期限日数</dt>
            <dd className="number-of-days-before-expiration-date__input">
              <S.Input
                type="text"
                inputMode="numeric"
                name="expiryDays"
                id="expiryDays"
                autoComplete="off"
                align="left"
                padding="0 20px"
                value={
                  newProductData?.expiryDays ? newProductData?.expiryDays : ''
                }
                onChange={handleChangeNumber}
              />
            </dd>
          </div>
          <div className="product-info number-of-days-before-expiration-date">
            <dt>安全在庫日数</dt>
            <dd className="number-of-days-before-expiration-date__input">
              <S.Input
                type="text"
                inputMode="numeric"
                name="safetyStockDays"
                id="safetyStockDays"
                autoComplete="off"
                align="left"
                padding="0 20px"
                value={
                  newProductData?.safetyStockDays
                    ? newProductData?.safetyStockDays
                    : ''
                }
                onChange={handleChangeNumber}
              />
            </dd>
          </div>
          <div className="product-info number-of-days-before-expiration-date">
            <LabelOfAmountSelect
              allLabelsOfAmount={labelsOfAmountObjects}
              value={selectedChangeLabelOfAmountName}
              setSelectedChangeLabelOfAmountName={
                setSelectedChangeLabelOfAmountName
              }
              newProductData={newProductData}
              setNewProductData={setNewProductData}
              setIsChanged={setIsChanged}
            />
          </div>
        </div>

        <div className="product-info number-of-days-before-expiration-date">
          <dt>ラベル名を「製造日時」以外に設定したい場合に入力</dt>
          <dd className="number-of-days-before-expiration-date__input">
            <S.Input
              type="text"
              name="stockDateLabel"
              id="stockDateLabel"
              autoComplete="off"
              align="left"
              padding="0 20px"
              value={
                newProductData?.stockDateLabel
                  ? newProductData?.stockDateLabel
                  : ''
              }
              onChange={handleChange}
            />
          </dd>
        </div>

        <S.CheckBoxArea>
          <S.Checkbox
            type="checkbox"
            name="isDiscontinued"
            onChange={handleChangeCheckBox}
            checked={
              newProductData?.isDiscontinued
                ? newProductData?.isDiscontinued
                : false
            }
          />
          <S.HiddenAndDiscontinued
            defaultChecked={newProductData?.isDiscontinued}
          >
            終売
          </S.HiddenAndDiscontinued>
          <S.Checkbox
            type="checkbox"
            name="hidden"
            onChange={handleChangeCheckBox}
            checked={newProductData?.hidden ? newProductData?.hidden : false}
          />
          <S.HiddenAndDiscontinued defaultChecked={newProductData?.hidden}>
            非表示
          </S.HiddenAndDiscontinued>
        </S.CheckBoxArea>

        <div className="product-info remarks">
          <dt>備考</dt>
          <dd>
            <S.TextArea
              name="remarks"
              id="remarks"
              value={newProductData?.remarks ? newProductData?.remarks : ''}
              onChange={handleChange}
              autoComplete="new-password"
            />
          </dd>
        </div>
      </dl>
    </>
  );
};

export default SemiProductContents;
