import { useMutation } from 'react-query';
import humps from 'humps';
import moment from 'moment';
import { axiosInstance } from '@lib/pc/common/api/axiosConfig';
import type { InputParams } from '@lib/pc/semi_product_production_result/type';

const useCreateSemiProductProductionResultMutation = (
  date: Date,
  factoryLineId: number,
  semiProductId: number,
  handleCreateSuccess: () => void
) => {
  return useMutation(
    async (params: InputParams) => {
      return await axiosInstance().post(
        `/api/v1/semi_product_production_results?date=${moment(date).format(
          'YYYY-MM-DD'
        )}&factory_line_id=${factoryLineId}&semi_product_id=${semiProductId}`,
        humps.decamelizeKeys({
          semiProductProductionResultDetails: {
            amount: params.amount,
            cart: params.cart,
            case: params.case,
            caseId: params.caseId,
            checkTime: params.checkTime,
            comment: params.comment,
            defectiveSemiProductAmount: params.defectiveSemiProductAmount,
            employeeIds: params.employeeIds,
            expirationBaseDate: params.expirationBaseDate,
            expirationDate: params.expirationDate,
            piece: params.piece,
            semiProductsProcessId: params.semiProductsProcessId,
            storeroomId: params.storeroomId,
            plannedCase: params.plannedCase,
            plannedPiece: params.plannedPiece,
            plannedAmount: params.plannedAmount,
          },
        })
      );
    },
    {
      onSuccess: () => {
        handleCreateSuccess();
      },
      onError: () => alert('エラーが発生しました'),
    }
  );
};

export default useCreateSemiProductProductionResultMutation;
