import { axiosInstance } from '@lib/pc/common/api/axiosConfig';
import { useQuery } from 'react-query';
import humps from 'humps';
import moment from 'moment';
import type { Todo } from '@lib/common/type';

const useShipping = (
  date: moment.Moment,
  selectedShippingDestinationId: number,
  productId: number
) => {
  const { data, refetch } = useQuery(['shipping', date], async () => {
    const data = await axiosInstance().get(
      `/api/v1/shipping_details?date=${date.format(
        'YYYYMMDD'
      )}&shipping_destination_id=${selectedShippingDestinationId}&product_id=${productId}`
    );

    return data;
  });

  const shippingData = data && (humps.camelizeKeys(data.data) as Todo);

  return { shippingData, refetch };
};

export default useShipping;
