import { axiosInstance } from '@lib/pc/common/api/axiosConfig';
import { useMutation } from 'react-query';

// StockAmountDetailテーブルのレコード削除;
export const useDeleteMaterialStocksMutation = (
  // actualSemiProductOrProductStockId: number | undefined,
  // stockKind: string,
  amountDetailId: number | null,
  handleDeleteSuccess: () => void
) => {
  return useMutation(
    async () => {
      return await axiosInstance().delete(
        `/api/v1/material_stocks/${amountDetailId}`
      );
    },
    {
      onSuccess: () => {
        handleDeleteSuccess();
      },
      onError: () => alert('エラーが発生しました'),
    }
  );
};

export default useDeleteMaterialStocksMutation;
