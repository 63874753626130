import styled from 'styled-components';

export const Wrapper = styled.div`
  background: ${(props) => props.theme.palette.white};
  position: relative;
  height: calc(100vh - 80px);
`;

export const HeadContainer = styled.div`
  height: 86px;
  padding: 1.15rem 2.25rem;
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-bottom: 1px solid #f5f2e6;
`;

export const PageTitle = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

export const PageTitleText = styled.div`
  display: flex;
  align-items: baseline;
  padding-bottom: 4px;
`;

export const MainContent = styled.div`
  display: flex;
  overflow: scroll;
  height: calc(100vh - 236px);
  /* height: calc(100vh - 166px); */
`;
export const PdfViewContainer = styled.div`
  flex-basis: 50%;
  border: 1px solid #f5f2e6;
`;
export const FormContainer = styled.div`
  padding: 2rem;
  flex-basis: 50%;
`;
export const FormMultiColumnContainer = styled.div`
  display: flex;
  gap: 1rem;
`;
export const FormLeftContainer = styled.div`
  flex-basis: 65%;
`;
export const FormRightContainer = styled.div`
  flex-basis: 35%;
`;

export const FormLabel = styled.p`
  margin-top: 8px;
  font-weight: bold;
`;

export const Input = styled.input<{
  align?: string;
  padding?: string;
  height?: string;
}>`
  width: 100%;
  height: 40px;
  color: ${(props) => props.theme.palette.baseBlack};
  font-size: 1rem;
  padding: ${(props) => (props.padding ? props.padding : '0.325rem')};
  text-align: ${(props) => props.align};
  border: none;
  border-radius: 0.5rem;
  border: 1px solid rgba(0, 0, 0, 0.08);
  height: ${(props) => (props.height ? props.height : '')};
  background: ${(props) => props.theme.palette.white};
  ::-webkit-inner-spin-button {
    -webkit-appearance: none;
  }
  margin-top: 8px;
`;

export const FormTextArea = styled.textarea`
  width: 100%;
  min-height: 6rem;
  color: ${(props) => props.theme.palette.baseBlack};
  font-size: 1rem;
  line-height: 1.25rem;
  padding: 0.325rem;
  border: none;
  border-radius: 0.5rem;
  border: 1px solid rgba(0, 0, 0, 0.08);
  background: ${(props) => props.theme.palette.white};
  word-wrap: break-word;
  word-break: break-all;
  white-space: normal;
  margin-top: 8px;
`;

export const ProductList = styled.div`
  margin-top: 8px;
`;
export const ProductListHeader = styled.div`
  display: flex;
  gap: 0.25rem;
  padding-bottom: 4px;
  border-bottom: 1px solid #000;
`;
export const ProductListBody = styled.div``;
export const ProductListRow = styled.div`
  display: flex;
  gap: 0.25rem;
  align-items: center;
`;
export const ProductName = styled.div`
  flex-basis: 40%;
`;
export const ProductDeliveryDay = styled.div`
  flex-basis: 40%;
`;
export const ProductUnitAmount = styled.div`
  flex-basis: 15%;
`;
export const ProductDelete = styled.div`
  flex-basis: 5%;
  display: flex;
  justify-content: center;
`;
export const IconButton = styled.button`
  padding: 8px;
  background: none;
`;

export const ButtonWrapper = styled.div`
  position: fixed;
  bottom: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: ${(props) => props.theme.palette.beige};
  width: 100%;
  height: 70px;
  border-top: solid 1px #e6e1dd;
`;

export const ButtonContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  & > button {
    width: 136px;
  }
  & > button:first-child {
    margin-right: 4rem;
  }
`;
