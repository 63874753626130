import { useMutation } from 'react-query';
import humps from 'humps';
import { axiosInstance } from '@lib/mobile/common/api/axiosConfig';
// import type { InputParams } from '@lib/stock_status/type';
import { Todo } from '@lib/common/type';

const useUpdateProductsCaptureMutation = (
  productId: string | number,
  cameraId: string | number,
  handleSuccessUpdate: () => void,
  setErrMsg: (errMsg: string) => void
) => {
  return useMutation(
    async (params: Todo) => {
      return await axiosInstance().patch(
        `/api/v1/cameras/${productId}?camera_id=${cameraId}`,
        humps.decamelizeKeys(params),
        { timeout: 5000 }
      );
    },
    {
      onSuccess: () => {
        handleSuccessUpdate();
      },
      // TODO: errorの型をどうすればいいか調べる
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      onError: (error: Todo) => {
        setErrMsg(error.response.data.message || 'エラーが発生しました');
      },
    }
  );
};

export default useUpdateProductsCaptureMutation;
