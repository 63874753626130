import { useEffect, useState } from 'react';
import { useHistory, useLocation, useParams } from 'react-router';
import * as S from './PickingByShippingEdit.styles';
import ErrorMsgPopUp from '@components/modules/common/ErrorMsgPopUp/ErrorMsgPopUp';
import FloatingCameraButton from '@components/modules/common/mobile/FloatingCameraButton/FloatingCameraButton';
import PickingEditWrapper from '@components/modules/mobile/picking_by_shipping/PickingEditWrapper/PickingEditWrapper';
import useProducts from '@lib/mobile/common/hooks/useProducts';
import useStorerooms from '@lib/mobile/common/hooks/useStorerooms';
import useCases from '@lib/mobile/common/hooks/useCases';
import usePicking from '@lib/mobile/picking_by_shipping_edit/usePicking';
import { PickingState } from '@lib/mobile/picking_edit/type';
import { PRODUCT_KIND, Product, Todo } from '@lib/common/type';
import { useWindowScroll } from 'react-use';
import {
  DetailsByProduct,
  // DetailsByShippingDestination,
  NotCheckedInfo,
} from '@lib/mobile/picking_by_shipping/type';
import useUpdatePickingMutation from '@lib/mobile/picking_by_shipping_edit/useUpdatePickingMutation';
import useUpdatePickingStatusMutation from '@lib/mobile/picking_by_shipping_edit/useUpdatePickingStatusMutation';
import { sleep } from '@lib/mobile/picking/functions';
import BarcodeReader from '@components/modules/pc/BarcodeScanner/BarcodeReader';
import scanSound from '@assets/sounds/sound.mp3';

export const PickingByShippingEditRoot = () => {
  // idはpickingId
  const { id } = useParams<{ id: string }>();
  const { state } = useLocation<PickingState>();
  const history = useHistory();
  const [stateSearchChar, setSearchChar] = useState('');
  const [stateShippingDate, setShippingDate] = useState('');
  const [stateShippings, setShippings] = useState<Todo>([]);
  const [statePickingStatus, setStatePickingStatus] = useState<number[]>([]);
  const [stateScrollPosition, setScrollPosition] = useState(0);
  const [selectedStoreroomId, setSelectedStoreroomId] = useState<number | ''>(
    ''
  );
  const [stateProducts, setProducts] = useState<Product[]>();
  const [stateScannedProductName, setProductName] = useState('');
  const [janCode, setJanCode] = useState('');
  // チェック済以外表示チェックボックス
  const [completedCheckState, setCompletedCheckState] = useState(true);
  const [confirmMsg, setConfirmMsg] = useState('');
  // 変更を加えてチェック(更新)していない出荷先と商品
  const [notCheckedInfo, setNotCheckedInfo] = useState<NotCheckedInfo[]>([]);
  const [errMsg, setErrMsg] = useState('');
  const [updating, setUpdating] = useState(false);
  // 振分完了ボタン表示
  const [allocateCompleteBtn, setAllocateCompleteBtn] = useState(false);

  const { data: products } = useProducts();
  const { data: storerooms } = useStorerooms(PRODUCT_KIND.PRODUCT);
  const { data: cases } = useCases();
  const { picking: picking, refetch } = usePicking(
    id,
    state && state.stateSelectedStoreroomId,
    5000
  );

  // スクロール位置
  const { y: currentScrollPosition } = useWindowScroll();

  // const [individualShippings] = useState<DetailsByShippingDestination>(picking);
  // 下位のコンポーネントに渡しているのがネストの深いオブジェクトなので、強制再レンダリングする必要がある
  const [, setToggleValue] = useState(false);
  const reRender = () => setToggleValue((prev) => !prev);
  const [scanning, setScanning] = useState(true);
  const [results, setResults] = useState<Todo>([]);
  const [inputText] = useState('');
  // 商品検索エリア
  const [searchProductChar, setSearchProductChar] = useState('');
  const [audio] = useState(new Audio(scanSound));
  const [searchedProductName, setSearchProductName] = useState('');
  // 更新するpickingDetailId
  const [updatePickingDetailId, setUpdatePickingDetailId] = useState(0);

  const handleUpdateSuccess = async () => {
    await sleep(150);
    await refetch();
    reRender();
    setUpdating(false);
  };

  const updatePickingMutation = useUpdatePickingMutation(
    updatePickingDetailId,
    handleUpdateSuccess,
    setErrMsg
  );
  // チェックボタン押下時更新処理
  const handleUpdate = (
    checkState: boolean,
    filteredNewData: DetailsByProduct[],
    allocateStatus: boolean
  ) => {
    // 更新時、notCheckedInfoから更新対象を外す
    const filteredInfo = notCheckedInfo.filter(
      (n: NotCheckedInfo) =>
        n.destinationName !== filteredNewData[0].destinationName &&
        n.productName !== filteredNewData[0].name
    );
    setNotCheckedInfo(filteredInfo);

    // 合計>0ではないデータは更新対象から外す
    const onlyAmountPlus = filteredNewData[0].amountDetail.filter(
      (f) => f.amount > 0
    );
    if (allocateStatus) {
      updatePickingMutation?.mutate({
        storeroomId: state.stateSelectedStoreroomId,
        pickingDetail: {
          allocationCheckState: checkState,
          amountDetail: onlyAmountPlus,
        },
      });
    } else {
      updatePickingMutation?.mutate({
        storeroomId: state.stateSelectedStoreroomId,
        pickingDetail: {
          checkState: checkState,
          amountDetail: onlyAmountPlus,
        },
      });
    }
  };

  // 振分完了ボタン更新成功
  const handleUpdatePickingStatusSuccess = async () => {
    await sleep(150);
    refetch();
    reRender();
    setUpdating(false);
    setAllocateCompleteBtn(false);
    // stateClickedPickingStatusを0(未着手)へ
    history.push(`/mobile/picking_by_shipping_edit/${id}`, {
      ...state,
      stateClickedPickingStatus: 0,
    });
  };

  // 振分完了ボタン更新
  const updatePickingStatusMutation = useUpdatePickingStatusMutation(
    id,
    handleUpdatePickingStatusSuccess,
    setErrMsg
  );

  const handleClickAllocateComplete = () => {
    updatePickingStatusMutation.mutate({
      id,
      handleUpdatePickingStatusSuccess,
      setErrMsg,
    });
  };

  useEffect(() => {
    if (state) {
      const {
        stateSearchChar,
        stateShippingDate,
        stateShippings,
        stateScrollPosition,
        stateJanCode,
        statePickingStatus,
        stateProducts,
        stateScannedProductName,
        stateSelectedStoreroomId,
        stateCompletedCheckState,
      } = state;
      setSearchChar(stateSearchChar);
      setShippingDate(stateShippingDate || picking?.shippingDate.toString());
      setShippings(stateShippings);
      setJanCode(stateJanCode);
      setStatePickingStatus(statePickingStatus);
      setScrollPosition(stateScrollPosition);
      setProducts(stateProducts);
      setProductName(stateScannedProductName);
      // setScrollPositionForEdit(stateScrollPositionForEdit);
      setSelectedStoreroomId(stateSelectedStoreroomId);
      setCompletedCheckState(stateCompletedCheckState);
    } else {
      setSearchChar('');
      setShippings(stateShippings);
      setScrollPosition(0);
      setStatePickingStatus([]);
      setProductName('');
      setSelectedStoreroomId('');
      setCompletedCheckState(true);
    }
    products && setProducts(products);
  }, [state]);

  const pickingState = {
    stateSearchChar: stateSearchChar,
    stateShippingDate: stateShippingDate,
    stateShippings: stateShippings,
    stateScrollPosition: stateScrollPosition,
    stateJanCode: janCode,
    statePickingStatus: statePickingStatus,
    stateProducts: stateProducts,
    stateScannedProductName: stateScannedProductName,
    stateScrollPositionForEdit: currentScrollPosition,
    stateSelectedStoreroomId: selectedStoreroomId,
    stateCompletedCheckState: completedCheckState,
    stateClickedPickingStatus: picking && picking.pickingStatus,
  };

  useEffect(() => {
    if (!updatePickingMutation?.isLoading) {
      refetch();
    }
  }, [updatePickingMutation?.isLoading]);

  useEffect(() => {
    if (results.length > 0) {
      const janCode = results[0]?.codeResult?.code;
      // Productテーブルから商品名検索
      const resultOfProduct = products?.filter(
        (pro: Product) => pro.janCode === janCode || pro.productCode === janCode
      );
      if (resultOfProduct && resultOfProduct?.length > 0) {
        setSearchProductName(resultOfProduct[0].name);
      } else if (!searchProductChar) {
        setErrMsg('該当の商品が見つかりませんでした。');
      }
    }
  }, [results]);

  useEffect(() => {
    if (searchedProductName) {
      setErrMsg('');
      history.push(`/mobile/picking_by_shipping_edit/scanner_result/${id}`, {
        ...state,
        stateJanCode: results[0]?.codeResult?.code,
        stateScannedProductName: searchedProductName,
        stateClickedPickingStatus: picking && picking.pickingStatus,
      });
    }
  }, [searchedProductName]);

  return (
    <>
      <S.Wrapper>
        {products && storerooms && picking && (
          <>
            <PickingEditWrapper
              searchProductChar={searchProductChar}
              setSearchProductChar={setSearchProductChar}
              scannedProductName={stateScannedProductName}
              products={products}
              storerooms={storerooms}
              picking={picking}
              cases={cases}
              completedCheckState={completedCheckState}
              setCompletedCheckState={setCompletedCheckState}
              fromScannerResult={false}
              greenFixedAreaHeight={'0'}
              setUpdatePickingDetailId={setUpdatePickingDetailId}
              handleUpdate={handleUpdate}
              handleClickAllocateComplete={handleClickAllocateComplete}
              confirmMsg={confirmMsg}
              setConfirmMsg={setConfirmMsg}
              notCheckedInfo={notCheckedInfo}
              setNotCheckedInfo={setNotCheckedInfo}
              allocateCompleteBtn={allocateCompleteBtn}
              setAllocateCompleteBtn={setAllocateCompleteBtn}
              refetch={refetch}
              setErrMsg={setErrMsg}
              updating={updating}
              setUpdating={setUpdating}
            />
            {scanning && (
              <BarcodeReader
                onDetected={(result: Todo) => {
                  setScanning(false);
                  audio.play();
                  const newResult = {
                    codeResult: { code: result.codeResult.code },
                    inputText,
                  };
                  setResults([...results, newResult]);
                }}
              />
            )}
          </>
        )}
      </S.Wrapper>
      <FloatingCameraButton
        zIndex={1003}
        handleClick={() =>
          history.push(
            `/mobile/picking_by_shipping_edit/scanner/${id}`,
            pickingState
          )
        }
      />
      <ErrorMsgPopUp
        errMsg={errMsg}
        handleClose={() => {
          setErrMsg('');
          setResults([]);
          setScanning(true);
        }}
        zIndex={1004}
      />
    </>
  );
};
