import styled from 'styled-components';
import company_seal from '@assets/images/company_seal.png';

export const PdfContainer = styled.div`
  width: 100%;
  background-color: white;
  padding: 32px 64px 105px 32px;
  position: relative;
`;

export const Title = styled.div`
  display: flex;
  justify-content: center;
  font-size: 32px;
`;

export const Summary = styled.div`
  display: flex;
  padding-top: 32px;
  margin-top: 2rem;
`;

export const LeftContent = styled.div`
  flex-basis: 55%;
  display: flex;
  flex-direction: column;
`;

export const LeftContentUpper = styled.div`
  font-size: 1.375rem;
`;
export const LeftContentLower = styled.div`
  margin-top: 12rem;
`;

export const RightContent = styled.div`
  flex-basis: 45%;
  padding-left: 2rem;
  font-size: 1rem;
  /* font-size: 1.125rem; */
`;

export const Destination = styled.div`
  display: flex;
`;

export const Honorific = styled.div``;

export const InCharge = styled.p`
  margin-top: 0.75rem;
  font-size: 16px;
  white-space: pre-line;
  line-height: 1.25rem;
`;

export const Row = styled.div`
  display: flex;
  border-bottom: 1px solid #000;
  font-size: 1rem;
  /* font-size: 1.25rem; */
  padding: 0.5rem;
`;
export const RowTitle = styled.p`
  width: 8rem;
  font-size: medium;
`;
export const RowBody = styled.p`
  font-size: medium;
`;

export const CompanyInfoAndSeal = styled.div`
  display: flex;
`;

export const CompanyInfo = styled.div`
  display: block;
`;
export const CompanySeal = styled.div`
  position: absolute;
  right: 6%;
  width: 6rem;
  height: 6rem;
  background-image: url(${company_seal});
  background-size: cover;
`;
export const CompanyName = styled.p`
  /* margin-top: 1.25rem; */
`;
export const ZipCode = styled.p`
  margin-top: 1.25rem;
`;
export const AddressFirst = styled.p`
  margin-top: 0.5rem;
`;
export const AddressSecond = styled.p`
  margin-top: 0.5rem;
`;
export const Tel = styled.p`
  margin-top: 0.5rem;
`;
export const PurchaseOrderDate = styled.p`
  margin-top: 1.25rem;
`;
export const PurchaseOrderCode = styled.p`
  margin-top: 0.5rem;
`;

export const TotalAmount = styled.div`
  margin-top: 2rem;
  border: 1px solid #000;
  font-size: 1.75rem;
  padding: 1rem;
  display: flex;
`;

export const TotalAmountTitle = styled.span`
  font-weight: bold;
  margin-right: 1rem;
`;

export const Calculating = styled.span`
  font-size: 14px;
  vertical-align: middle;
  color: ${(props) => props.theme.palette.khaki};
`;

export const ProductList = styled.div`
  margin-top: 2rem;
  font-size: 1rem;
  /* font-size: 1.125rem; */
`;
export const ProductListHeader = styled.div`
  display: flex;
  height: 2rem;
  border-bottom: 1px solid #000;
  align-items: center;
`;
export const ProductListBody = styled.div`
  border-bottom: 1px solid #000;
`;
export const ProductListRow = styled.div<{ index: number }>`
  display: flex;
  height: 2rem;
  align-items: center;
  background-color: ${({ index }) => (index % 2 ? '#f7f7f7' : '#fff')};
`;

export const DetailControl = styled.div<{
  display: boolean;
}>`
  display: ${(props) => (props.display ? 'none' : '')};
`;

export const ProductName = styled.div`
  flex-basis: 50%;
`;
export const ProductQuantity = styled.div`
  flex-basis: 10%;
`;
export const ProductUnit = styled.div`
  flex-basis: 10%;
`;
export const ProductUnitPrice = styled.div`
  flex-basis: 15%;
`;
export const ProductTotalPrice = styled.div`
  flex-basis: 15%;
  text-align: right;
`;
export const PriceList = styled.div``;
export const PriceListRow = styled.div`
  display: flex;
  justify-content: flex-end;
  height: 2rem;
  align-items: center;
`;
export const PriceListRowTitle = styled.div`
  flex-basis: 20%;
`;

export const PriceTotalValue = styled.div`
  flex-basis: 60%;
  text-align: right;
  display: flex;
  justify-content: flex-end;
`;

export const PriceListRowValue = styled.div`
  flex-basis: 20%;
  text-align: right;
  display: flex;
  justify-content: flex-end;
`;
export const CommentLabel = styled.p``;
export const CommentBody = styled.p`
  margin-top: 1rem;
  border: 1px solid #000;
  padding: 1rem;
`;
