import Form from '@components/modules/pc/accepting_orders_edit/Form/Form';
import { WithHeader } from '@components/templates/WithHeader';

export const AcceptingOrdersEditRoot = () => {
  return (
    <WithHeader>
      <Form />
    </WithHeader>
  );
};
