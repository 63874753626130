import { FC, ReactNode } from 'react';
import * as S from './InputWithIcon.styles';

type Props = {
  placeholder: string;
  icon: ReactNode;
  disabled?: boolean;
  onChange?: (e: React.ChangeEvent<HTMLInputElement>) => void;
  direction?: 'right' | 'left';
  background?: string | '';
  placeholderColor?: string;
  placeholderFontWeight?: string;
  placeholderFontSize?: string;
  onClick?: () => void;
};

export const InputWithIcon: FC<Props> = ({
  placeholder,
  icon,
  onChange,
  disabled,
  direction = 'left',
  background,
  placeholderColor,
  placeholderFontWeight,
  placeholderFontSize,
  onClick,
}: Props) => {
  return (
    <S.Wrapper direction={direction}>
      <S.Icon onClick={onClick}>{icon}</S.Icon>
      <S.Input
        type="text"
        id="email-adress-icon"
        disabled={disabled}
        placeholder={placeholder}
        onChange={onChange}
        onClick={onClick}
        background={background}
        placeholderColor={placeholderColor}
        placeholderFontWeight={placeholderFontWeight}
        placeholderFontSize={placeholderFontSize}
      />
    </S.Wrapper>
  );
};
