import styled from 'styled-components';

export const Wrapper = styled.div<{
  open?: boolean;
}>`
  width: 100vw;
  top: 0;
  position: fixed;
  z-index: 10000;
  overflow-x: hidden;
`;

export const Line = styled.div<{
  bgColor: string;
}>`
  width: 100vw;
  height: 64px;
  background-color: ${(props) => props.bgColor};
  padding-top: 16px;
  font-family: 'Noto Sans JP';
  font-style: normal;
  font-size: 32px;
  line-height: 32px;
  color: #ffffff;
  font-weight: 700;
  align-items: center;
  text-align: center;
`;

export const Table = styled.table`
  width: 100%;
  border-collapse: collapse;
  border-spacing: 0 1em;
  font-size: 0.875rem;
`;

export const THead = styled.tr`
  background-color: #f8f7f4;
  border-top: 1px solid ${(props) => props.theme.palette.sandGray};
  border-bottom: 1px solid ${(props) => props.theme.palette.sandGray};
  height: 42px;
  word-spacing: 100vw;
`;

export const TRow = styled.tr`
  cursor: pointer;
  border: 1px solid #e6e1dd;
  background-color: #f8f7f4;
  height: 73px;
`;

export const TCell = styled.td<{
  align?: string;
  width?: string;
  marginRight?: string;
  background?: string;
  wordBreak?: string;
  fontSize?: string;
}>`
  position: relative;
  font-size: ${(props) => (props.fontSize ? props.fontSize : '14px')};
  vertical-align: middle;
  word-break: ${(props) => (props.wordBreak ? props.wordBreak : '')};
  width: ${(props) => (props.width ? props.width : '7.25rem')};
  text-align: ${(props) => (props.align ? props.align : 'left')};
  margin-right: ${(props) => (props.marginRight ? props.marginRight : '')};
  background: ${(props) => (props.background ? props.background : '')};
  word-spacing: 100vw;
  padding: 0 0.25rem;

  > svg {
    display: inline;
    width: 18px;
    height: 18px;
  }

  > span {
    position: absolute;
    right: 1rem;
    top: 60%;
    font-size: 10px;
    margin-left: 4px;
  }
`;

export const THeadCell = styled(TCell)<{
  align?: string;
  width?: string;
  wordBreak?: string;
}>`
  font-weight: 500;
  word-break: ${(props) => (props.wordBreak ? props.wordBreak : '')};
`;
export const ButtonWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #f8f7f4;
  width: 100%;
  height: 83px;
`;

export const ButtonContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  & > button {
    width: 136px;
  }
  & > button:first-child {
    margin-right: 1rem;
  }
`;

export const Input = styled.input`
  width: 100%;
  height: 4rem;
  color: ${(props) => props.theme.palette.baseBlack};
  font-size: 1rem;
  line-height: 1.25rem;
  padding: 0.325rem;
  background: ${(props) => props.theme.palette.white};
  border: none;
  border-radius: 0.5rem;
`;

export const TimeInput = styled(Input)`
  ::-webkit-calendar-picker-indicator {
    -webkit-appearance: none;
  }
`;
