import styled from 'styled-components';

const fontWeight: { [key: string]: string } = {
  sm: '100',
  md: '400',
  lg: 'bold',
};

export const Root = styled('div')<{
  fw: string | undefined;
  fs: string | undefined;
  align: string | undefined;
}>`
  color: ${(props) =>
    props.color ? props.color : props.theme.palette.baseBlack};
  text-align: ${(props) => (props.align ? props.align : 'left')};
  font-size: ${(props) => (props.fs ? props.fs + 'px' : '1rem')};
  font-weight: ${(props) => (props.fw ? fontWeight[props.fw] : '400')};
`;
