import { axiosInstance } from '@lib/pc/common/api/axiosConfig';
import { useQuery } from 'react-query';
import humps from 'humps';
import moment from 'moment';
// import { Todo } from '@lib/common/type';
import { AllStoreroomEstimatedMaterialStocks } from '@lib/pc/materials_inventory/type';

export const useAllStoreroomsEstimatedMaterialStocks = (
  date: Date,
  materialId: string | number,
  storeroomId: string | number
) => {
  const { data, refetch } = useQuery(
    ['allStoreroomsEstimatedMaterialStocks', date, materialId, storeroomId],
    async () => {
      const data = await axiosInstance().get(
        `/api/v1/material_stocks/all_storerooms/${materialId}?&date=${moment(
          date
        ).format('YYYY-MM-DD')}?&storeroom_id=${storeroomId}`
      );
      return data;
    }
  );
  const materialStocks =
    data &&
    (humps.camelizeKeys(
      data.data.stock_details
    ) as unknown as AllStoreroomEstimatedMaterialStocks);

  return { materialStocks, refetch };
};

export default useAllStoreroomsEstimatedMaterialStocks;
