import * as S from './CircularProgressComponent.styles';
import { CircularProgress } from '@material-ui/core';

type Props = {
  sending: boolean;
};

const CircularProgressComponent = ({ sending }: Props) => {
  return (
    <>
      {sending && (
        <S.CircularIconWrapperOuter>
          <S.CircularIconWrapper>
            <CircularProgress style={{ color: '#64b2f9' }} />
          </S.CircularIconWrapper>
        </S.CircularIconWrapperOuter>
      )}
    </>
  );
};

export default CircularProgressComponent;
