import styled from 'styled-components';

export const Wrapper = styled.div`
  background: ${(props) => props.theme.palette.beige};
  // height: 258px;
  height: 100%;
  width: 240px;
  top: 3.75rem;

  box-shadow: 0px 6px 10px rgba(0, 0, 0, 0.07), 0px 1px 18px rgba(0, 0, 0, 0.06),
    0px 3px 5px rgba(0, 0, 0, 0.1);
`;

export const FlexCenter = styled.div`
  display: flex;
  flex-flow: column;
  align-items: center;
`;

export const Avatar = styled.div`
  width: 4rem /* 32px */;
  height: 4rem /* 32px */;
  border-radius: 9999px;
  margin-top: 0.75rem /* 12px */;
  margin-bottom: 0.75rem /* 12px */;
  background: ${(props) => props.theme.palette.sandGray};
`;

export const Name = styled.div`
  font-weight: 400;
  line-height: 24px;
  margin-bottom: 4px;
`;

export const Email = styled.div`
  font-weight: 400;
  line-height: 24px;
  margin-bottom: 1rem /*16px*/;
`;

export const Edit = styled.button`
  padding: 0;
  margin-bottom: 1rem /*16px*/;

  background: ${(props) => props.theme.palette.beige};
  color: ${(props) => props.theme.palette.khaki};

  border: 1px solid rgba(0, 0, 0, 0.08);
  height: 30px;
  width: 60px;
  border-radius: 16px;
`;

export const Logout = styled.button`
  border-top: 1px solid #e5e1dd;
  background: ${(props) => props.theme.palette.beige};
  color: ${(props) => props.theme.palette.black};
  width: 100%;
`;

export const Version = styled.div`
  border-top: 1px solid #e5e1dd;
  background: ${(props) => props.theme.palette.beige};
  color: #6b675c;
  width: 100%;
  padding: 16px 32px;
  text-align: center;
  font-size: 13px;
`;
