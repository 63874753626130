import styled from 'styled-components';

export const Wrapper = styled.div`
  width: 100%;
  margin-bottom: 100px;
  > span {
    font-weight: 700;
  }
`;

export const EmailTitle = styled.div`
  font-weight: 700;
`;

export const Input = styled.input`
  width: 100%;
  height: 40px;
  color: ${(props) => props.theme.palette.baseBlack};
  font-size: 1rem;
  padding: 0 20px;
  margin-top: 8px;
  text-align: left;
  border: none;
  outline: none;
  border-radius: 0.5rem;
  border: 1px solid rgba(0, 0, 0, 0.08);
  background: ${(props) => props.theme.palette.white};
  ::-webkit-inner-spin-button {
    -webkit-appearance: none;
  }
`;

export const ContentsWrapper = styled.div`
  max-height: 400px;
  overflow-y: scroll;
`;

export const AddNewAddressButton = styled.div`
  display: flex;
  gap: 8px;
  margin-top: 30px;
  justify-content: center;
  cursor: pointer;
  color: ${(props) => props.theme.palette.blue};
`;
