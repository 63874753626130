import * as S from './SideBar.styles';
import { stockStatusDayColors } from '@lib/pc/stock_status/type';
import { defaultTheme } from '@lib/styled-components/theme';

const dateNames = ['D6', 'D5', 'D4', 'D3', 'D2', 'D1', 'D0'] as const;
const dateTextColor = (dateName: string) =>
  ['D6', 'D5', 'D0'].includes(dateName)
    ? defaultTheme.palette.white
    : defaultTheme.palette.baseBlack;

const DateHeaders = () => (
  <>
    {dateNames.map((d) => (
      <S.Box
        key={d}
        background={stockStatusDayColors[d]}
        color={dateTextColor(d)}
      >
        {d.toUpperCase()}
      </S.Box>
    ))}
  </>
);

const SideBar = () => {
  return (
    <S.Wrapper>
      <S.BlankBox />
      <DateHeaders />
      <S.Box background="#DEF1FF">
        <S.HeadContent color="#106CAE">
          <S.TextWrapper>
            <S.Text>在庫数合計</S.Text>
          </S.TextWrapper>
        </S.HeadContent>
      </S.Box>
    </S.Wrapper>
  );
};
export default SideBar;
