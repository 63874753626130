import styled, { css } from 'styled-components';

const highLightenCell = css`
  position: relative;
  border-right: 2px solid ${(props) => props.theme.palette.blue};
  border-left: 2px solid ${(props) => props.theme.palette.blue};
`;

// 生産計画入力セル
export const Wrapper = styled.input<{
  highLighten?: boolean;
  withCheckEnabled?: boolean;
}>`
  width: 91px;
  padding: 0.5rem 0;
  text-align: center;
  height: 30px;
  border-bottom: 1px solid #efefef;
  border-right: 1px solid #efefef;
  ${({ highLighten }) => (highLighten ? highLightenCell : null)};
  color: ${(props) => (props.withCheckEnabled ? '#C74141' : '')};
  font-weight: ${(props) => (props.withCheckEnabled ? 'bold' : '')};
  background-color: ${(props) =>
    props.withCheckEnabled ? '#FDDEDE!important' : ''};

  :focus {
    border: 2px solid #2ba9d1;
    outline: 0;
  }

  ::-webkit-inner-spin-button {
    -webkit-appearance: none;
  }

  &.select-cell {
    border: 2px solid ${(props) => props.theme.palette.primary.main};
  }
`;
