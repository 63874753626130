import * as S from './CloseConfirmModal.styled';
import { Button } from '@components/elements/buttons/Button';

type Props = {
  closeMode: boolean;
  setCloseMode: (confirmModal: boolean) => void;
  goBack: () => void;
};

const CloseConfirmModal = ({ closeMode, setCloseMode, goBack }: Props) => {
  return (
    <S.ModalWrapper open={closeMode}>
      <S.ModalTextContainer>
        <span>写真撮影作業を終了しますか？</span>
      </S.ModalTextContainer>
      <S.ModalButtonContainer>
        <Button
          padding="sm"
          outlined
          onClick={() => {
            setCloseMode(false);
          }}
        >
          キャンセル
        </Button>
        <Button
          padding="sm"
          onClick={() => {
            goBack();
          }}
        >
          OK
        </Button>
      </S.ModalButtonContainer>
    </S.ModalWrapper>
  );
};

export default CloseConfirmModal;
