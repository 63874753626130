import styled from 'styled-components';

export const Wrapper = styled.div<{ open: boolean }>`
  display: ${(props) => (props.open ? 'block' : 'none')};
  background: ${(props) => props.theme.palette.white};
  position: fixed;
  left: 50%;
  top: 50%;
  width: 30%;
  transform: translate(-50%, -50%);
  z-index: 10002;
  box-shadow: 0px 6px 10px rgba(0, 0, 0, 0.07), 0px 1px 18px rgba(0, 0, 0, 0.06),
    0px 3px 5px rgba(0, 0, 0, 0.1);
  border-radius: 8px;
  padding: 1rem;
`;

export const TextContainer = styled.div`
  display: block;
  flex-direction: column;
  font-weight: 500;
  font-size: 1rem;
  line-height: 27px;
`;

export const ButtonContainer = styled.div`
  /* display: flex; */
  justify-content: center;
  align-items: center;
  gap: 1rem;
  margin-top: 1rem;
  cursor: pointer;
  button {
    width: 100%;
    height: 2.75rem;
  }
`;

export const ButtonWrapper = styled.div<{
  padding?: string;
}>`
  width: 100%;
  height: 2.75rem;
  border: 2px solid ${(props) => props.theme.palette.primary.main};
  font-weight: bold;
  font-size: 16px;
  text-align: center;
  padding: 0.75rem 0;
  border-radius: 8px;
  color: ${(props) => props.theme.palette.primary.main};
  gap: 10px;
  align-items: center;
  justify-content: center;
  margin: 15px 0;
  > span {
    font-size: 12px;
  }
`;

export const Date = styled.div`
  font-size: 16px;
  font-weight: 700;
  user-select: none;
  align-items: center;
  display: flex;
  flex-direction: row;
  justify-content: center;
  gap: 11px;
`;

export const DatePicker = styled.div`
  .SingleDatePickerInput {
    border: none;
    width: 100%;
    height: 36px;
    display: block;
    margin: 0;
    top: -12px;
    position: relative;
    text-align: center;
  }
  .DateInput_input {
    display: none;
  }
  .DateInput__small {
    /* padding-top: 9px; */
  }
  .SingleDatePickerInput_calendarIcon {
    /* position: absolute;
    top: -43%;
    right: -8%; */
    margin: 0;
  }
  .SingleDatePicker_picker {
    z-index: 10 !important;
    left: 10px !important;
    background-color: transparent;
  }
  .DayPicker {
    left: -40px;
  }
  .DateInput_fang {
    display: none;
  }
  .CalendarMonthGrid_1 {
    width: 300px !important;
  }
`;
