import { ReactComponent as CameraIcon } from '@assets/icons/mobile/camera.svg';
import * as S from './FloatingCameraButton.styles';
import { useDeviceOrientationAndSize } from '@lib/common/functions';

type Props = {
  bottom?: string;
  handleClick: () => void;
  display?: boolean;
  zIndex?: number;
};

const FloatingCameraButton = ({
  bottom,
  handleClick,
  display,
  zIndex,
}: Props) => {
  const { isPortrait, deviceType } = useDeviceOrientationAndSize();

  return (
    <S.FloatingIconWrapper
      bottom={isPortrait && deviceType === 'tablet' ? '14%' : bottom}
      onClick={handleClick}
      display={display}
      zIndex={zIndex}
    >
      <CameraIcon />
    </S.FloatingIconWrapper>
  );
};

export default FloatingCameraButton;
