import styled from 'styled-components';

export const Header = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 95%;
  margin: 0 auto;
`;

export const HeaderTitle = styled.p`
  display: flex;
  align-items: center;
`;

export const HeaderTitleText = styled.span`
  margin-left: 0.375rem;
`;

export const IconButton = styled.button`
  padding: 0;
  background: none;
`;

export const ButtonIconWrapper = styled.div<{ searchOpen: boolean }>`
  transform: rotate(${(props) => (props.searchOpen ? 0 : 180)}deg);
`;

export const SearchConditionPanelList = styled.div``;

export const SearchConditionPanel = styled.div`
  display: flex;
  align-items: center;
  margin-top: 0.75rem;
  width: 100%;
  height: 2.5rem;
  padding: 0 0.75rem;
  border-radius: 0.5rem;
  color: ${(props) => props.theme.palette.khaki};
  background: ${(props) => props.theme.palette.white};

  &:nth-of-type(1) {
    margin-top: 0;
  }
`;
export const SearchConditions = styled.div``;

export const SearchConditionText = styled.p`
  margin-left: 0.75rem;
`;
export const SearchConditionInput = styled.input`
  margin-left: 0.75rem;
  width: 100%;

  outline: none;

  &::placeholder {
    color: ${(props) => props.theme.palette.khaki};
  }
`;

export const ClearButton = styled.button`
  margin-top: 0.5rem;
  margin-bottom: 0.25rem;
  padding: 0;
  background: none;
  float: right;
`;
