import { axiosInstance } from '@lib/mobile/common/api/axiosConfig';
import { useQuery } from 'react-query';
import humps from 'humps';
import moment from 'moment';
import type { Product } from '@lib/common/type';

// 在庫データをすべて取得
const useAllStocks = () => {
  return useQuery('stocks', async () => {
    const { data } = await axiosInstance().get(
      `/api/v1/stocks?date=${moment().format('YYYYMMDD')}`
    );
    // Stock[]からany[]へ変更している。
    return humps.camelizeKeys(data.products) as Product[];
  });
};

export default useAllStocks;
