import { useQuery } from 'react-query';
import humps from 'humps';
import { axiosInstance } from '@lib/pc/common/api/axiosConfig';
import { Todo } from '@lib/common/type';

const useSupplier = (supplierId: string) => {
  const { data, refetch } = useQuery(
    ['supplier_edit', supplierId],
    async () => {
      const data = await axiosInstance().get(
        `/api/v1/settings/supplier/${supplierId}`
      );
      return data;
    }
  );
  const supplier = humps.camelizeKeys(data?.data) as Todo;

  return { supplier, refetch };
};

export default useSupplier;
