import { FC } from 'react';
import type { Todo } from '@lib/common/type';
import ReactSelect from 'react-select';
import { NO_OPTION_JAPANESE } from '@lib/pc/common/constants';

type Props = {
  data: Todo[] | undefined;
  value: string | undefined;
  height: string;
  marginTop?: string;
  broaden?: boolean;
  handleChangeTax: (selectedTaxRate: string) => void;
};

const ConsumptionTaxSelect: FC<Props> = ({
  data,
  value,
  height,
  marginTop,
  broaden,
  handleChangeTax,
}: Props) => {
  const toReactSelectOptions = (options: { value: string; name: string }[]) =>
    options.map(({ value, name }) => ({
      value: value,
      label: name,
    }));

  const reactSelectOptions = data && toReactSelectOptions(data);
  const currentValue = reactSelectOptions?.find((o) => o.value === value);

  const styles = {
    control: (baseStyles: Todo) => ({
      ...baseStyles,
      minWidth: '100%',
      width: 'auto',
      height: height ? height : '56px',
      marginTop: marginTop ? marginTop : '',
      borderRadius: '0.5rem',
      fontSize: '14px',
      fontWeight: 400,
      textAlign: 'left',
      borderStyle: 'hidden',
      outline: '0.5px solid rgba(0,0,0,0.08)',
      // backgroundColor: disabled ? '' : 'white',
      cursor: 'pointer',
      '@media (max-width: 599px)': {
        width: '16rem',
      },
    }),
    menu: (baseStyles: Todo) => ({
      ...baseStyles,
      top: '27px',
      zIndex: 1002,
      marginTop: '15px',
    }),
    option: (baseStyles: Todo) => ({
      ...baseStyles,
      textAlign: 'left',
      fontWeight: 400,
      fontSize: '15px',
      height: broaden ? '50px' : '',
      lineHeight: broaden ? '30px' : '',
    }),
    menuList: (baseStyles: Todo) => ({
      ...baseStyles,
      maxHeight: '195px',
    }),
    ValueContainer: (baseStyles: Todo) => ({
      ...baseStyles,
      padding: '2px 0',
    }),
  };

  return (
    <ReactSelect
      options={reactSelectOptions}
      value={currentValue ? currentValue : null}
      onChange={(option) => {
        // option && handleChangeTax(option.value, index);
        option && handleChangeTax(option.value);
        // option && handleChangeTaxRate(option.value);
      }}
      styles={styles}
      // isDisabled={disabled}
      isSearchable={false}
      components={{
        IndicatorSeparator: () => null,
      }}
      noOptionsMessage={() => NO_OPTION_JAPANESE}
      placeholder="税率"
    />
  );
};

export default ConsumptionTaxSelect;
