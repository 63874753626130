import React, { FC, ReactNode } from 'react';
import * as S from './Button.styles';

export type ButtonProps = {
  children: string;
  icon?: ReactNode;
  iconAfter?: ReactNode;
  outlined?: boolean;
  borderWidth?: number;
  padding?: 'lg' | 'md' | 'sm';
  disabled?: boolean;
  width?: string;
  margin?: string;
  onClick?: () => void;
};

export const Button: FC<ButtonProps> = ({
  children,
  icon,
  iconAfter,
  outlined,
  borderWidth,
  padding,
  disabled,
  width,
  margin,
  onClick,
}: ButtonProps) => {
  return (
    <S.Wrapper
      borderWidth={borderWidth}
      padding={padding}
      outlined={outlined}
      onClick={disabled ? undefined : onClick}
      className={disabled ? 'disabled' : ''}
      width={width}
      margin={margin}
      disabled={disabled}
    >
      {icon}
      {children}
      {iconAfter}
    </S.Wrapper>
  );
};
