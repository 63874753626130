import { useMutation } from 'react-query';
import { axiosInstance } from '@lib/pc/common/api/axiosConfig';
import { Todo } from '@lib/common/type';

const useDeleteCaseMutation = (
  id: number,
  handleDeleteSuccess: () => void,
  setDeleteError: (deleteError: boolean) => void
) => {
  return useMutation(
    async () => {
      return await axiosInstance().delete(`/api/v1/settings/cases/${id}`);
    },
    {
      onSuccess: () => {
        handleDeleteSuccess();
      },
      onError: (error: Todo) => {
        if (
          error.response.data.message ===
          'このケースは商品に紐付けられています。'
        ) {
          setDeleteError(true);
        } else {
          alert('エラーが発生しました');
        }
      },
    }
  );
};

export default useDeleteCaseMutation;
