import { useEffect, useState, useLayoutEffect } from 'react';
import { useLocation } from 'react-router';
import { Case, Todo } from '@lib/common/type';
import * as S from './PickingDetailByShippingWrapper.styles';
import { ReactComponent as CheckedIcon } from '@assets/icons/mobile/check.svg';
import { ReactComponent as CheckIcon } from '@assets/icons/mobile/check_circle_outline_24px.svg';
import { ReactComponent as ArrowUpOutlined } from '@assets/icons/arrow_up_outlined.svg';
import { ReactComponent as ExpandMore } from '@assets/icons/expand_more.svg';
import PickingDetailByShipping from '@components/modules/mobile/picking_by_shipping_edit/PickingDetailByShipping/PickingDetailByShipping';
import {
  DetailsByProduct,
  DetailsByShippingDestination,
  NotCheckedInfo,
  PICKING_STATUS,
} from '@lib/mobile/picking_by_shipping/type';
import { PickingState } from '@lib/mobile/picking_edit/type';

type Props = {
  data: Todo;
  idx: number;
  searchProductChar: string;
  completedCheckState: boolean;
  fixedAreaHeight: string;
  fromScannerResult: boolean;
  greenFixedAreaHeight: string;
  cases: Case[] | undefined;
  setUpdatePickingDetailId: (updatePickingDetailId: number) => void;
  handleUpdate: (
    checkState: boolean,
    amountDetail: DetailsByProduct[],
    allocateStatus: boolean
  ) => void;
  scannedJanCode: string | undefined;
  notCheckedInfo: NotCheckedInfo[];
  setNotCheckedInfo: (notCheckedProducts: NotCheckedInfo[]) => void;
  refetch: () => void;
  setErrMsg: (errMsg: string) => void;
  storeroomName: string;
  storeroomId: number;
  updating: boolean;
  setUpdating: (updating: boolean) => void;
  picking: DetailsByShippingDestination;
  // allocateStatus: boolean;
};

const PickingDetailByShippingWrapper = ({
  data,
  idx,
  searchProductChar,
  completedCheckState,
  // setPickingStatus,
  fixedAreaHeight,
  fromScannerResult,
  greenFixedAreaHeight,
  cases,
  setUpdatePickingDetailId,
  handleUpdate,
  scannedJanCode,
  notCheckedInfo,
  setNotCheckedInfo,
  refetch,
  setErrMsg,
  storeroomName,
  storeroomId,
  updating,
  setUpdating,
  picking,
}: // allocateStatus,
Props) => {
  const { state } = useLocation<PickingState>();
  // state.stateClickedPickingStatusに正しいstatusが設定されないのでpicking.pickingStatusを使用する
  const pickingStatusForCheckStatus = state.stateClickedPickingStatus
    ? state.stateClickedPickingStatus
    : picking.pickingStatus;
  const allocateTrueStatus =
    pickingStatusForCheckStatus === PICKING_STATUS.ALLOCATION_NOT_STARTED ||
    pickingStatusForCheckStatus === PICKING_STATUS.ALLOCATION_IN_PROGRESS
      ? true
      : false;
  // 出荷先別商品オープン
  const [productActive, setProductActive] = useState(
    fromScannerResult ? true : false
  );
  const [fixedAreaShippingTitleHeight, setFixedAreaShippingTitleHeight] =
    useState('');
  // 出荷先別商品に欠品の商品が含まれるか
  const [includesShort, setIncludesShort] = useState(false);
  // 出荷止めの商品のみの出荷先か
  const [allStopShippingProduct, setAllStopShippingProduct] = useState(false);
  // 下位のコンポーネントに渡しているのがネストの深いオブジェクトなので、強制再レンダリングする必要がある
  const [, setToggleValue] = useState(false);
  const reRender = () => setToggleValue((prev) => !prev);

  // 出荷先別checkState
  const [allCheckState, setAllCheckState] = useState<boolean[]>([]);
  // 上記allCheckStateのtrueの数を変数へ代入
  const countTrue = allCheckState.reduce(
    (count, value) => (value === true ? count + 1 : count),
    0
  );
  // 出荷先別商品のcheckStateに未チェックが存在するか(存在する場合→true)
  const [checkStateByShippingWithFalse, setCheckStateByShippingWithFalse] =
    useState(allCheckState.includes(false));
  // 商品にスキャンしたjanCodeが含まれているか（商品のチェック状態に関わらず）
  const includesScannedCode = data.productBreakdownDetail.some(
    (detail: Todo) =>
      detail?.janCode === scannedJanCode?.toString() ||
      detail?.code === scannedJanCode?.toString()
  );
  // 商品に「チェック済み以外」のスキャンしたjanCodeが含まれているか
  const includesScannedCodeWithoutCheck = data.productBreakdownDetail.some(
    (detail: Todo) =>
      (detail?.janCode === scannedJanCode?.toString() ||
        detail?.code === scannedJanCode?.toString()) &&
      detail?.checkState === false
  );

  // 出荷先名を表示するかの判定（表示する商品がない場合は非表示にする）
  const [displayShippingName, setDisplayShippingName] = useState(false);

  const productNames = data.productBreakdownDetail?.map(
    (obj: Todo) => obj.name
  );

  useLayoutEffect(() => {
    if (!fromScannerResult && !completedCheckState) {
      // スキャナー結果ではなく「チェック済以外」表示ではない場合
      setDisplayShippingName(true);
    } else if (
      !fromScannerResult &&
      completedCheckState &&
      checkStateByShippingWithFalse
    ) {
      // スキャナー結果ではなく「チェック済以外」表示、商品に未チェックが含まれている場合
      setDisplayShippingName(true);
    } else if (
      !fromScannerResult &&
      completedCheckState &&
      !checkStateByShippingWithFalse
    ) {
      // スキャナー結果ではなく「チェック済以外」表示、商品に未チェックが含まれていない場合
      setDisplayShippingName(false);
    } else if (
      fromScannerResult &&
      completedCheckState &&
      includesScannedCodeWithoutCheck
    ) {
      // スキャナー結果、かつ「チェック済以外」表示の商品でスキャンしたjanCodeが含まれている場合
      setDisplayShippingName(true);
    } else if (
      fromScannerResult &&
      !completedCheckState &&
      includesScannedCode
    ) {
      // スキャナー結果、かつ「チェック済以外」表示ではなく、スキャンしたjanCodeが含まれている場合
      setDisplayShippingName(true);
    } else {
      setDisplayShippingName(false);
    }
  }, [completedCheckState, scannedJanCode, checkStateByShippingWithFalse]);

  // 出荷先テキストエリアの高さを取得→sticky商品名に対応する。
  useEffect(() => {
    if (document) {
      const shippingTitleTextHeight = document?.getElementById(
        'picking-shipping-text'
      )?.clientHeight;
      shippingTitleTextHeight &&
        setFixedAreaShippingTitleHeight(shippingTitleTextHeight.toString());
    }
    reRender();
  }, [document]);

  useLayoutEffect(() => {
    const includesShort = data.productBreakdownDetail.some(
      (p: Todo) => p.deliveryResults === 'short' && p.checkState
    );
    setIncludesShort(includesShort);
    const allStopShippingProduct = data.productBreakdownDetail.some(
      (p: Todo) => p.stopShipping === false
    );
    setAllStopShippingProduct(allStopShippingProduct);
    setAllCheckState(data.allCheckState);
    setCheckStateByShippingWithFalse(allCheckState.includes(false));
  }, [data]);

  return (
    <S.WholeWrapper
      fromScannerResult={fromScannerResult}
      fixedAreaHeight={fixedAreaHeight}
      greenFixedAreaHeight={greenFixedAreaHeight}
    >
      {displayShippingName && allStopShippingProduct && (
        <S.Wrapper id="picking-shipping-text">
          {data.destinationCode.includes(searchProductChar) ||
          data.destinationName.includes(searchProductChar) ||
          productNames.filter((str: string) => str.includes(searchProductChar))
            .length > 0 ? (
            <div key={idx}>
              <S.DestinationInfo
                className="destination-info"
                key={idx + data.shippingDestinationId}
                checkState={!checkStateByShippingWithFalse}
                short={includesShort}
                onClick={() => {
                  setProductActive(!productActive);
                }}
              >
                {/* 出荷先別チェックステータス */}
                <S.CheckArea checked={!checkStateByShippingWithFalse}>
                  <S.CheckIconWrapper>
                    {!checkStateByShippingWithFalse === true ? (
                      <CheckedIcon />
                    ) : (
                      <CheckIcon />
                    )}
                  </S.CheckIconWrapper>
                  <S.StatusName checked={!checkStateByShippingWithFalse}>
                    {!checkStateByShippingWithFalse === true ? (
                      <span>
                        {allocateTrueStatus ? '振分済' : 'チェック済み'}
                      </span>
                    ) : (
                      <span>
                        {allocateTrueStatus ? '未振分' : '未チェック'}
                      </span>
                    )}
                  </S.StatusName>
                </S.CheckArea>
                {/* 出荷先情報 */}
                <div className="destination-text-wrapper">
                  <div className="info">
                    <div>{data.destinationCode}</div>
                    <div>
                      {data.numberOfProducts}
                      <span>品目</span>
                      <span>合計</span>
                      {data.totalAmount}
                      <span>個</span>
                    </div>
                  </div>
                  {data.destinationName}
                </div>
                {productActive ? <ArrowUpOutlined /> : <ExpandMore />}
              </S.DestinationInfo>
              {/*  出荷先別の商品別ピッキング内容詳細（商品情報複数） */}
              <PickingDetailByShipping
                refetch={refetch}
                idx={idx}
                open={productActive}
                countTrue={countTrue}
                dataTotalAmount={data.totalAmount}
                destinationName={data.destinationName}
                detailsByProduct={data.productBreakdownDetail}
                searchProductChar={searchProductChar}
                fromScannerResult={fromScannerResult}
                completedCheckState={completedCheckState}
                fixedAreaHeight={fixedAreaHeight}
                greenFixedAreaHeight={greenFixedAreaHeight}
                fixedAreaShippingTitleHeight={fixedAreaShippingTitleHeight}
                cases={cases}
                setUpdatePickingDetailId={setUpdatePickingDetailId}
                handleUpdate={handleUpdate}
                notCheckedInfo={notCheckedInfo}
                setNotCheckedInfo={setNotCheckedInfo}
                setErrMsg={setErrMsg}
                storeroomName={storeroomName}
                storeroomId={storeroomId}
                updating={updating}
                setUpdating={setUpdating}
                allocateTrueStatus={allocateTrueStatus}
              />
            </div>
          ) : (
            ''
          )}
        </S.Wrapper>
      )}
    </S.WholeWrapper>
  );
};
export default PickingDetailByShippingWrapper;
