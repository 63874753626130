const destinationCode = [
  '010',
  '020',
  '030',
  '040',
  '050',
  '060',
  '070',
  '080',
  '090',
];

// const destinationName = ['宮崎', '福岡', '熊本', '長崎', '大分', '鹿児島'];
const destinationName = [
  'セブンイレブン宮崎',
  '関西スーパー',
  '九州コープ',
  '宮崎スーパー',
  'ライフ大分',
  'イオン鹿児島',
  'マックスバリュ',
  'マルキュウ',
  '成城石井',
  '紀伊国屋',
  'サミット',
  '西友',
];

// const amount = ['100', '150', '200', '230', '300', '310', '400', '450'];

export const generateStoreDummy = () => {
  return [...Array(15)].map(() => {
    return {
      storeCode:
        destinationCode[Math.floor(Math.random() * destinationCode.length)],
      name: destinationName[Math.floor(Math.random() * destinationName.length)],
      amount: Math.floor(Math.random() * 1000),
    };
  });
};

const productName = [
  '商品A2本入',
  '商品A3本入',
  'ソフト豆腐',
  'なめらか豆腐',
  'たまご豆腐',
  '厚揚げ',
  '絹厚揚げ',
  'もめん豆腐',
  '五目がんも',
  'うす揚げ',
  'いなりあげ',
  '旨み天',
];

const checkState = [true, false];

// const tagIds = [0, 1, 2, 3];

// 商品
export const generatebranchDummy = () => {
  return [...Array(30)].map(() => {
    return {
      name: productName[Math.floor(Math.random() * productName.length)],
      productCode: Math.floor(Math.random() * 100000),
      tagIds: [1, 3],
      data: generateStoreDummy(),
      checkState: checkState[Math.floor(Math.random() * checkState.length)],
    };
  });
};

// const janCode = [
//   '4912345678904',
//   '4561234567890',
//   '4901121181443',
//   '4901121181444',
//   '4901121181445',
//   '4901121181446',
//   '4901121181447',
//   '4901121181449',
//   '4901121181450',
//   '4901121181451',
//   '4901121181452',
//   '4901121181448',
// ];

// const pickingStatus = ['waiting_picking', 'picking', 'waiting_shipping'];
const pickingStatus = [0, 1, 2];

const shippingGroups = [
  '福岡警固エリア',
  'サトウ食品',
  '北九州エリア',
  '筑豊エリア',
  '筑後エリア',
];

export const pickingDummyData = {
  shippingGroup:
    shippingGroups[Math.floor(Math.random() * shippingGroups.length)],
  shippingDate: new Date(),
  status: pickingStatus[Math.floor(Math.random() * pickingStatus.length)],

  productData: generatebranchDummy(),
};
