import { useState } from 'react';
import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import * as S from './SalesStoreCreateWrapper.styles';
import { useHistory, useLocation } from 'react-router-dom';
import ConfirmPopUp from '@components/modules/common/ConfirmPopUp/ConfirmPopUp';
import ErrorMsgPopUp from '@components/modules/common/ErrorMsgPopUp/ErrorMsgPopUp';
import Overlay from '@components/elements/backdrops/Overlay/Overlay';
import UpdatePopUp from '@components/modules/pc/settings/common/UpdatePopUp/UpdatePopUp';
import SalesStoreContents from '@components/modules/pc/settings/sales_store_setting/SalesStoreEdit/SalesStoreContents/SalesStoreContents';
// import { createdCustomerData } from '@components/modules/pc/settings/customer_setting/CustomerCreate/default';
import { CustomerState } from '@lib/pc/settings/customer_setting/type';
import useCustomers from '@lib/pc/common/hooks/useCustomers';

const SalesStoreCreateWrapper = () => {
  const { state } = useLocation<CustomerState>();
  const history = useHistory();

  interface NewData {
    id: number;
    code: string;
    name: string;
    customerIds: number[];
    customersName: string[];
    shippingDestinationId: number;
    shippingDestinationName: string;
    shippingDestinationCode: string;
  }

  const initNewData: NewData = {
    id: 0,
    code: '',
    name: '',
    customerIds: [],
    customersName: [],
    shippingDestinationId: 0,
    shippingDestinationName: '',
    shippingDestinationCode: '',
  };

  const [newData, setNewData] = useState(initNewData);

  // 削除確認メッセージ
  const [confirmMsg, setConfirmMsg] = useState('');
  const [errMsg, setErrMsg] = useState('');
  const [enableToSave, setEnableToSave] = useState(false);
  const [updating, setUpdating] = useState(false);
  // 確認メッセージhandleOk判定用:一覧画面へ移動か、別タブ表示か。
  const [goBack, setGoBack] = useState(false);
  const [isChanged, setIsChanged] = useState(false);
  const [updatePopUp, setUpdatePopUp] = useState(false);

  // 得意先の取得
  const { data: customers } = useCustomers();

  // const [createdData, setCreatedData] = useState(createdCustomerData);

  // 一覧画面へ戻る
  const onClickGoBack = () => {
    if (isChanged) {
      setGoBack(true);
      setConfirmMsg('変更した内容を保存せずに終了します。よろしいですか？');
    } else {
      history.push({
        pathname: `/pc/settings/sales_store_setting`,
        state: state,
      });
    }
  };

  // 確認メッセージOK
  const handleOk = () => {
    // ここで一覧画面へ戻るか別タブを表示するかの判断が必要
    if (goBack) {
      history.push({
        pathname: `/pc/settings/sales_store_setting`,
        state: {
          ...state,
          messageKind: '',
          productName: '',
          stateSelectedTabId: 0,
        },
      });
    }
    setIsChanged(false);
    setConfirmMsg('');
  };
  // 確認メッセージキャンセル
  const handleCancel = () => {
    setConfirmMsg('');
  };

  return (
    <>
      <S.Wrapper>
        <DndProvider backend={HTML5Backend}>
          {/* 販売店 */}
          <SalesStoreContents
            newData={newData}
            setNewData={setNewData}
            enableToSave={enableToSave}
            setEnableToSave={setEnableToSave}
            setIsChanged={setIsChanged}
            onClickGoBack={onClickGoBack}
            updating={updating}
            setUpdating={setUpdating}
            setUpdatePopUp={setUpdatePopUp}
            // setCreatedData={setCreatedData}
            customers={customers}
          />
        </DndProvider>
      </S.Wrapper>
      <ErrorMsgPopUp
        errMsg={errMsg}
        handleClose={() => {
          setErrMsg('');
          setUpdating(false);
        }}
        fromPc={true}
        zIndex={9999}
      />
      {errMsg && (
        <Overlay
          dark
          handleClick={() => {
            setErrMsg('');
            setUpdating(false);
          }}
        />
      )}
      <ConfirmPopUp
        fromPc={true}
        confirmMsg={confirmMsg}
        zIndex={1004}
        handleOk={handleOk}
        handleCancel={handleCancel}
      />
      <UpdatePopUp
        popUp={updatePopUp}
        taskKind={'messageOnly'}
        messageKind={'create'}
        handleClose={() => setUpdatePopUp(false)}
      />
      {updatePopUp && <Overlay handleClick={() => setUpdatePopUp(false)} />}
    </>
  );
};

export default SalesStoreCreateWrapper;
