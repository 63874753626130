import { axiosInstance } from '@lib/pc/common/api/axiosConfig';
import { useQuery } from 'react-query';
import humps from 'humps';
import { Todo } from '@lib/common/type';

const useProduct = (productId: string) => {
  const { data, refetch } = useQuery(['products', productId], async () => {
    const data = await axiosInstance().get(
      `/api/v1/settings/product/${productId}`
    );
    return data;
  });
  const product = humps.camelizeKeys(data?.data) as Todo;

  return { product, refetch };
};

export default useProduct;
