import { FC, useEffect } from 'react';
import * as S from './ProductDetailModal.styles';
import { ReactComponent as CloseIcon } from '@assets/icons/close.svg';
import StockStatusGraphWithTable from '../StockStatusGraphWithTable/StockStatusGraphWithTable';

//TODO type
type Props = {
  productDetailModalActive: boolean;
  selectedDate: moment.Moment;
  /* eslint-disable-next-line @typescript-eslint/no-explicit-any */
  data: any;
  handleClose: () => void;
};

const ProductDetailModal: FC<Props> = ({
  productDetailModalActive,
  selectedDate,
  data,
  handleClose,
}: Props) => {
  useEffect(() => {
    // Esc キーのイベントリスナー
    const handleKeyDown = (event: KeyboardEvent) => {
      if (event.key === 'Escape') {
        handleClose();
      }
    };
    if (productDetailModalActive) {
      window.addEventListener('keydown', handleKeyDown);
    }
    // コンポーネントのクリーンアップ時にイベントリスナーを削除
    return () => {
      window.removeEventListener('keydown', handleKeyDown);
    };
  }, [productDetailModalActive]);

  return (
    <S.Wrapper open={productDetailModalActive}>
      <S.ModalHead>
        <S.ProductMetaContainer>
          {/* COMMENT:画像がくる/無ければコメントアウト */}
          <div style={{ width: '60px', height: '60px', background: '#ccc' }} />
          {/* COMMENT:画像がくる */}
          <div>{data?.name} </div>
        </S.ProductMetaContainer>
        <S.ModalHeadCloseIcon>
          <CloseIcon onClick={handleClose} />
        </S.ModalHeadCloseIcon>
      </S.ModalHead>
      <StockStatusGraphWithTable selectedDate={selectedDate} data={data} />
    </S.Wrapper>
  );
};

export default ProductDetailModal;
