import styled from 'styled-components';

export const DContainer = styled.div`
  display: grid;
  grid-template-areas:
    'expiration-date del-button'
    'materials-amount del-button';
  justify-content: left;
  width: 100%;
  border-collapse: collapse;
  border-spacing: 0;
  font-size: 0.875rem;
  border-top: 2px solid #d3d3d3;
`;

export const ExpirationDate = styled.div`
  display: flex;
  font-weight: 700;
  font-size: 16px;
  margin-bottom: 0.5rem;
  /* border-top: 2px solid #d3d3d3; */
  padding-top: 1rem;
  > label {
    text-align: center;
  }
`;

export const DateWrapper = styled.div`
  display: flex;
  position: relative;
  text-align: center;
  margin-left: 0.75rem;
`;

export const InputWrapper = styled.span`
  display: flex;
  position: relative;
  text-align: center;
  margin-left: 0.75rem;
`;

export const ExpirationDateLabel = styled.label`
  width: 77px;
  display: block;
  font-size: 15px;
  display: flex;
  align-items: center;
`;

export const MaterialsAmountWrapper = styled.div`
  display: flex;
  align-items: center;
  padding-bottom: 1rem;
  margin-right: 10px;
`;

export const DInputContainer = styled.span<{
  piece?: boolean;
}>`
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 6px;
  > label {
    font-weight: 700;
    font-size: 13px;
  }
  > input {
    height: 44px;
    /* max-width: 4rem; */
    max-width: 7.25rem;
    width: 100%;
    border-radius: 8px;
    text-align: right;
    padding-right: ${(props) => (props.piece ? '4px' : '12px')};
    font-size: 16px; //入力時、画面のズームアウトを防ぐ
    /* @media (max-width: 599px) {
      max-width: 4rem;
    } */
    :focus {
      outline: none;
    }
    :disabled {
      border: 2px solid #ffffff;
      background-color: transparent;
    }
  }
  > span {
    position: absolute;
    right: 0.3rem;
    top: 80%;
    font-size: 12px;
    margin-left: 4px;
    color: #6b675c;
  }
`;

export const DSymbol = styled.span`
  margin-top: 1rem;
  padding: 0 5px;
  font-weight: 700;
  font-size: 13px;
`;

export const Input = styled.input`
  width: 216px;
  /* width: 186px; */
  height: 44px;
  vertical-align: middle;
  color: ${(props) => props.theme.palette.baseBlack};
  font-size: 1rem;
  line-height: 1.25rem;
  padding: 0.325rem;
  background: ${(props) => props.theme.palette.white};
  border: none;
  border-radius: 0.5rem;
  border: 1px solid rgba(0, 0, 0, 0.08);
  -webkit-appearance: none;
  @media (max-width: 599px) {
    width: 15rem;
  }
  ::-webkit-date-and-time-value {
    text-align: left;
    padding-left: 8px;
  }
  input[type='date']::-webkit-clear-button {
    -webkit-appearance: none;
    display: none;
  }
`;

export const DeleteButton = styled.div`
  background: ${(props) => props.theme.palette.white};
  display: block;
  vertical-align: middle;
  align-items: center;
  padding: 0.7rem 0.2rem;
  /* padding: 0.7rem; */
  position: relative;
  /* border-top: 2px solid #d3d3d3; */
  grid-auto-rows: 100%;
  grid-area: del-button;
  cursor: pointer;
`;

export const DeleteIconWrapper = styled.div`
  margin-top: 45px;
`;
