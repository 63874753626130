import styled from 'styled-components';

export const Wrapper = styled.div<{
  open?: boolean;
  fromPc: boolean;
  isDelete?: boolean;
  isPortraitAndTablet?: boolean;
}>`
  display: ${(props) => (props.open ? 'block' : 'none')};
  bottom: ${(props) => (props.isPortraitAndTablet ? '50%' : '24px')};
  background: ${(props) => (props.isDelete ? '#c74141' : '#05731d')};
  position: fixed;
  box-shadow: 0px 6px 10px rgba(0, 0, 0, 0.07), 0px 1px 18px rgba(0, 0, 0, 0.06),
    0px 3px 5px rgba(0, 0, 0, 0.1);
  border-radius: 8px;
  width: ${(props) => (props.fromPc ? '22rem' : '85%')};
  left: ${(props) => (props.fromPc ? '50%' : '8%')};
  transform: ${(props) => (props.fromPc ? 'translate(-50%, -50%)' : '')};
  color: #ffffff;
  padding: 16px 18px;
  z-index: 2;
`;

export const ModalHead = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

export const IconWrapper = styled.div`
  margin-right: 12px;
`;

export const ModalHeadCloseIcon = styled.div`
  cursor: pointer;
`;

export const MessageContainer = styled.div`
  display: flex;
  align-items: center;
  line-height: 23px;
  margin-right: 15px;
  font-weight: 500;
  font-size: 16px;

  font-weight: 700;

  > div:nth-child(2) {
    margin-left: 1rem;
  }
`;
