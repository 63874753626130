import { useMutation } from 'react-query';
import humps from 'humps';
import { axiosInstance } from '@lib/pc/common/api/axiosConfig';
import { AxiosError } from 'axios';
import type { InputParams } from '@lib/pc/settings/product_master_create/type';
import { Todo, ErrorResponse } from '@lib/common/type';

const useCreateProduct = (
  setCreatedProduct: (createdProduct: Todo) => void,
  handleSuccess: () => void,
  setErrMsg: (errMsg: string) => void
) => {
  return useMutation(
    async (params: InputParams) => {
      return await axiosInstance()
        .post(`/api/v1/settings/products`, humps.decamelizeKeys(params))
        .then((response) => {
          setCreatedProduct(humps.camelizeKeys(response.data));
        });
    },
    {
      onSuccess: () => {
        handleSuccess?.();
      },
      onError: (error: AxiosError<ErrorResponse>) => {
        const message = error.response?.data.message || 'エラーが発生しました';
        setErrMsg(message);
      },
    }
  );
};

export default useCreateProduct;
