import React from 'react';
import moment from 'moment';
import * as S from './AllStoreroomStockForm.styles';
import {
  AllStoreroomEstimatedMaterialStocks,
  AmountDetail,
  selectedEstimatedMaterial,
  stocks,
} from '@lib/pc/materials_inventory/type';
import {
  numberWithDecimalPlaces,
  stringWithDecimalPlaces,
} from '@lib/pc/stock_status/functions';

type Props = {
  data: AllStoreroomEstimatedMaterialStocks | undefined;
  selectedMaterial: selectedEstimatedMaterial;
};

const AllStoreroomStockForm = ({ data }: Props) => {
  return (
    <S.Wrapper>
      <S.HeadContainer id="top"></S.HeadContainer>
      <S.InputContainer>
        {data?.stocks?.map((d: stocks, i: number) => {
          return (
            <div key={i}>
              <S.StoreroomWrapper>
                <S.ProductionOrShippingDateLabel
                  htmlFor={`production-date-${i}`}
                >
                  在庫室
                </S.ProductionOrShippingDateLabel>
                {d && d.storeroomName}
              </S.StoreroomWrapper>
              <S.StoreroomTotalWrapper>
                <S.ProductionOrShippingDateLabel htmlFor={`total-amount-${i}`}>
                  在庫室合計数【予定在庫】
                </S.ProductionOrShippingDateLabel>
                {d && d.totalAmount}
                {data.labelOfAmount ? data.labelOfAmount : '個'}
              </S.StoreroomTotalWrapper>
              {d.amountDetail.map((detail: AmountDetail, i: number) => {
                return (
                  <React.Fragment key={i}>
                    <S.DateWrapper isFirst={i === 0}>
                      <S.ProductionOrShippingDateLabel
                        htmlFor={`production-date-${i}`}
                      >
                        使用期限
                      </S.ProductionOrShippingDateLabel>
                      {detail?.expirationDate
                        ? moment(detail?.expirationDate).format('YYYY/MM/DD')
                        : '未記入'}
                    </S.DateWrapper>
                    <S.DRightBottomWrapper>
                      {/* ケース */}
                      <S.DInputContainer center={true}>
                        <label htmlFor={`stock-case-${i}`}>ケース</label>
                        <input
                          id={`stock-case-${i}`}
                          value={detail?.case || ''}
                          type="text"
                          disabled
                        />
                        <span>
                          ×{detail?.piecesPerBox ? detail?.piecesPerBox : 1}
                          {data.labelOfUnit !== 'なし'
                            ? data.labelOfUnit
                            : data.labelOfAmount
                            ? data.labelOfAmount
                            : '個'}
                        </span>
                      </S.DInputContainer>
                      <S.DSymbol>+</S.DSymbol>
                      {/* 単位個数：本、袋等 */}
                      {data.labelOfUnit !== 'なし' && (
                        <>
                          <S.DInputContainer center={true}>
                            <label htmlFor={`stock-unit-${i}`}>
                              {data.labelOfUnit ? data.labelOfUnit : ''}
                            </label>
                            <input
                              id={`stock-unit-${i}`}
                              value={detail?.unit || ''}
                              type="text"
                              disabled
                            />
                            <span>
                              ×
                              {detail?.piecesPerUnit
                                ? detail?.piecesPerUnit
                                : 1}
                              {data.labelOfAmount ? data.labelOfAmount : ''}
                            </span>
                          </S.DInputContainer>
                          <S.DSymbol>+</S.DSymbol>
                        </>
                      )}
                      {/* バラ個数 */}
                      <S.DInputContainer>
                        <label htmlFor={`stock-piece-${i}`}>
                          {data.labelOfAmount ? data.labelOfAmount : '個'}
                        </label>
                        <input
                          id={`stock-piece-${i}`}
                          value={
                            detail?.piece === '0'
                              ? 0
                              : stringWithDecimalPlaces(detail?.piece, 2)
                          }
                          type="text"
                          disabled
                        />
                      </S.DInputContainer>
                      <S.DSymbol>=</S.DSymbol>
                      <S.DInputContainer>
                        <label htmlFor={`stock-total-${i}`}>合計</label>
                        <input
                          id={`stock-total-${i}`}
                          value={
                            detail?.total === 0
                              ? '0'
                              : numberWithDecimalPlaces(detail?.total, 2)
                          }
                          disabled
                        />
                      </S.DInputContainer>
                    </S.DRightBottomWrapper>
                  </React.Fragment>
                );
              })}
            </div>
          );
        })}
      </S.InputContainer>
    </S.Wrapper>
  );
};
export default AllStoreroomStockForm;
