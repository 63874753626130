export const factoryLineColors: { [key: string]: string } = {
  line0: '#FFD130',
  line1: '#90C1E4',
  line2: '#F6C1B1',
  line3: '#B2A8E5',
  line4: '#CFDC7E',
  line5: '#F19B4C',
  line6: '#86BEA7',
  line7: '#F19D9D',
  line8: '#8DA4C7',
  line9: '#E2A38E',
} as const;
type factoryLineColors =
  typeof factoryLineColors[keyof typeof factoryLineColors];

export const factoryLineBgColors: { [key: string]: string } = {
  line0: '#FFFCF1',
  line1: '#F5FBFF',
  line2: '#FFF9F7',
  line3: '#FAF9FF',
  line4: '#FDFFEE',
  line5: '#FFF6EE',
  line6: '#F1F5F4',
  line7: '#FFF4F4',
  line8: '#F3F6F9',
  line9: '#FFF5F1',
} as const;
type factoryLineBgColors =
  typeof factoryLineColors[keyof typeof factoryLineColors];
