import { axiosInstance } from '@lib/pc/common/api/axiosConfig';
import { useQuery } from 'react-query';
import humps from 'humps';
import type { Customer } from '@lib/common/type';

const useCustomers = () => {
  return useQuery('customers', async () => {
    const { data } = await axiosInstance().get(`/api/v1/customers`);
    return humps.camelizeKeys(data.customers) as Customer[];
  });
};

export default useCustomers;
