import React from 'react';
import { WithHeader } from '@components/templates/WithHeader';
import { Heading } from '@components/elements/typographies/Heading';
import * as S from './SemiProductMasterCreate.styles';
import SemiProductMasterCreate from '@components/modules/pc/settings/semi_product_master_setting/SemiProductMasterCreate/SemiProductMasterCreate';

export const SemiProductMasterCreateRoot: React.FC = () => {
  return (
    <WithHeader>
      <S.Wrapper>
        <div>
          <S.HeadContainer>
            <S.FlexBaseline>
              <div>
                <S.ProductKind>半製品</S.ProductKind>
                <Heading tag="span" text="製品マスタ管理" fs="32" fw="lg" />
              </div>
            </S.FlexBaseline>
          </S.HeadContainer>
          <SemiProductMasterCreate />
        </div>
      </S.Wrapper>
    </WithHeader>
  );
};
