import { useEffect, useState } from 'react';
import moment from 'moment';
import * as S from './ShippingGroupContents.styles';
import ChevronLeft from '@assets/icons/chevron_left';
import { Button } from '@components/elements/buttons/Button';
import ErrorMsgPopUp from '@components/modules/common/ErrorMsgPopUp/ErrorMsgPopUp';
import Overlay from '@components/elements/backdrops/Overlay/Overlay';
import { createdShippingGroupData } from '@components/modules/pc/settings/shipping_group_setting/ShippingGroupCreate/default';
import { Todo } from '@lib/common/type';
import useCreateShippingGroupMutation from '@lib/pc/settings/shipping_group_master_create/useCreateShippingGroup';
import useUpdateShippingGroupMutation from '@lib/pc/settings/shipping_group_master_edit/useUpdateShippingGroup';
import { ShippingGroupUpdateData } from '@lib/pc/settings/shipping_group_setting/type';

type Props = {
  newData: ShippingGroupUpdateData;
  setNewData: (newData: ShippingGroupUpdateData) => void;
  updating: boolean;
  setUpdating: (updating: boolean) => void;
  enableToSave: boolean;
  setEnableToSave: (enableToSave: boolean) => void;
  setIsChanged: (isChanged: boolean) => void;
  onClickGoBack: () => void;
  refetch?: () => void;
  setUpdatePopUp: (updatePopUp: boolean) => void;
  createdData?: Todo;
  setCreatedData?: (createdData: Todo) => void;
};

const ShippingGroupContents = ({
  newData,
  setNewData,
  updating,
  setUpdating,
  enableToSave,
  setEnableToSave,
  setIsChanged,
  onClickGoBack,
  refetch,
  setUpdatePopUp,
  setCreatedData,
}: Props) => {
  const [errMsg, setErrMsg] = useState('');

  // 登録ボタンか保存ボタンか判定
  const createButton = newData.id === 0 ? true : false;

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const data = { ...newData, [e.target.name]: e.target.value };
    setNewData(data);
    setIsChanged(true);
    setEnableToSave(true);
  };

  const handleUpdateSuccess = () => {
    refetch && refetch();
    setUpdatePopUp(true);
    setIsChanged(false);
    setUpdating(false);
  };

  const updateShippingGroupMutation = useUpdateShippingGroupMutation(
    newData?.id,
    handleUpdateSuccess,
    setErrMsg
  );

  const [createdShippingGroup, setCreatedShippingGroup] = useState(
    createdShippingGroupData
  );

  const handleCreatedShippingGroup = (
    shippingGroup: typeof createdShippingGroupData
  ) => {
    setCreatedShippingGroup(shippingGroup);
  };

  // ***********************
  // [保存ボタン]押下、更新処理
  // ***********************
  const handleSubmit = () => {
    setUpdating(true);
    updateShippingGroupMutation.mutate(newData);
  };

  const createShippingGroupMutation = useCreateShippingGroupMutation(
    handleCreatedShippingGroup,
    handleUpdateSuccess,
    setErrMsg
  );

  // ***********************
  // [登録ボタン]押下、作成処理
  // ***********************
  const handleSubmitCreate = () => {
    setUpdating(true);
    createShippingGroupMutation.mutate(newData);
  };

  useEffect(() => {
    if (createdShippingGroup?.id !== 0) {
      setNewData({
        ...newData,
        id: createdShippingGroup?.id,
        code: createdShippingGroup?.code,
        name: createdShippingGroup?.name,
        shippingTime: createdShippingGroup?.shippingTime
          ? createdShippingGroup?.shippingTime
          : '',
      });
    }
    setCreatedData && setCreatedData(createdShippingGroup);
  }, [createdShippingGroup]);

  return (
    <>
      <S.Wrapper>
        <S.GoBackWrapper onClick={onClickGoBack}>
          <ChevronLeft isBlue={true} />
          <span>一覧画面へ戻る</span>
        </S.GoBackWrapper>

        <S.ShippingGroupAreaDl>
          <dt>出荷先グループコード</dt>
          <dd>
            <S.Input
              type="text"
              inputMode="numeric"
              name="code"
              id="code"
              autoComplete="off"
              value={newData?.code}
              onChange={handleChange}
            />
          </dd>
          <dt>出荷先グループ名</dt>
          <dd>
            <S.Input
              type="text"
              inputMode="numeric"
              name="name"
              id="name"
              autoComplete="off"
              value={newData?.name}
              onChange={handleChange}
            />
          </dd>
          <dt>出荷予定時刻</dt>
          <dd>
            <S.TimeInput
              type="time"
              name="shippingTime"
              id="shippingTime"
              width="20%"
              value={
                (newData?.shippingTime && newData?.shippingTime.length > 5
                  ? moment(newData?.shippingTime).format('LT')
                  : newData?.shippingTime) || ''
              }
              onChange={handleChange}
            />
          </dd>
        </S.ShippingGroupAreaDl>
      </S.Wrapper>
      <S.ButtonContainer>
        {createButton ? (
          <Button
            children={updating ? '登録中...' : '登録'}
            disabled={updating || !enableToSave}
            onClick={handleSubmitCreate}
          />
        ) : (
          <Button
            children={updating ? '保存中...' : '保存'}
            disabled={updating || !enableToSave}
            onClick={handleSubmit}
          />
        )}
      </S.ButtonContainer>
      <ErrorMsgPopUp
        errMsg={errMsg}
        handleClose={() => {
          setErrMsg('');
          setUpdating(false);
        }}
        fromPc={true}
        zIndex={9999}
      />
      {errMsg && (
        <Overlay
          dark
          handleClick={() => {
            setErrMsg('');
            setUpdating(false);
          }}
        />
      )}
    </>
  );
};

export default ShippingGroupContents;
