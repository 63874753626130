import { isIPad13 } from 'react-device-detect';
import * as S from './SubmitButton.styles';

type Props = {
  children: string;
  disabled: boolean;
  onClick: () => void;
};

const SubmitButton = ({ children, disabled, onClick }: Props) => {
  return (
    <S.Wrapper
      id="stock-status-submit-button"
      disabled={disabled}
      onClick={() => {
        if (!isIPad13) {
          onClick();
        }
      }}
      onTouchStart={() => {
        if (isIPad13) {
          onClick();
        }
      }}
    >
      {children}
    </S.Wrapper>
  );
};

export default SubmitButton;
