// import { useState } from 'react';
// import moment from 'moment';
import * as S from './StockForm.styles';
import type { InputParams } from '@lib/stock_status/type';
import SidebarInput from '@components/modules/mobile/products_stocks_edit/SidebarInput/SidebarInput';
import type { Case, Product, Todo } from '@lib/common/type';
import MobileDateSelector from '@components/elements/inputs/DateSelector/MobileDateSelector';
import InputComponent from '@components/modules/mobile/products_stocks_edit/SidebarInput/InputComponent';
import {
  isInitializeInputAmount,
  normalizeZeros,
} from '@lib/stock_status/functions';

type Props = {
  filteredProductCases: Case[] | undefined;
  product: Product;
  inputParams: InputParams;
  selectedDate: Date;
  count: number;
  inputAmountParams: Todo;
  setInputAmountParams: (inputAmountParams: Todo) => void;
  deleteId: number | null;
  getBeforeDate: (selectedDate: Date) => void;
  getNextDate: (selectedDate: Date) => void;
  setInputParams: (InputParams: InputParams) => void;
  calcTotal: (index: number, value: string, valueKind: 'bara' | 'case') => void;
  changeDate: (index: number, value: Todo) => void;
  changeCase: (index: number, value: number) => void;
  newCalcTotal: (
    index: number,
    value: string,
    valueKind: 'bara' | 'case'
  ) => void;
  changeNewDate: (index: number, value: Todo) => void;
  changeNewCase: (index: number, value: number) => void;
  handleDelete: () => void;
  setDeleteId: (id: number | null) => void;
  setIsChanged: (isChanged: boolean) => void;
  selectedCasePiecesPerCase: number;
  setSelectedCasePiecesPerCase: (selectedCasePiecesPerCase: number) => void;
};

const StockForm = ({
  filteredProductCases,
  product,
  inputParams,
  setInputParams,
  calcTotal,
  changeDate,
  changeCase,
  selectedDate,
  count,
  getBeforeDate,
  getNextDate,
  inputAmountParams,
  newCalcTotal,
  changeNewDate,
  changeNewCase,
  setInputAmountParams,
  handleDelete,
  deleteId,
  setDeleteId,
  setIsChanged,
  selectedCasePiecesPerCase,
  setSelectedCasePiecesPerCase,
}: Props) => {
  // const [piecesPerBox] = useState<number[]>([]);
  const getUseScanning = () => {
    const value = localStorage.getItem('useScanning');
    return value === 'true';
  };

  const handleOnChange = (
    i: number,
    value: string,
    valueKind: 'bara' | 'case'
  ) => {
    // valueがからもじであればそのまま、なんか入ってたら数値化する。
    // stringの場合は何もしない。
    if (value === '' || value === '-' || value.match(/^-?[0-9]*$/)) {
      calcTotal(i, normalizeZeros(value), valueKind);
    }
    setIsChanged(true);
  };

  const handleComment = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
    setInputParams({ ...inputParams, comment: e.target.value });
    setIsChanged(true);
  };

  const handleChangeDate = (i: number, value: Date) => {
    changeDate(i, value);
    setIsChanged(true);
  };

  const handleChangeCase = (i: number, value: number) => {
    changeCase(i, value);
    setIsChanged(true);
  };

  // inputコンポーネントのバラとケースとカートの値の更新
  const handleNewOnChange = (
    i: number,
    value: string,
    valueKind: 'bara' | 'case'
  ) => {
    if (value === '' || value === '-' || value.match(/^-?[0-9]*$/)) {
      newCalcTotal(i, normalizeZeros(value), valueKind);
    }
  };

  // inputコンポーネントの値の更新
  const handleChangeNewDate = (i: number, value: Date) => {
    changeNewDate(i, value);
  };

  // inputコンポーネントのケースの更新
  const handleChangeNewCase = (i: number, value: number) => {
    changeNewCase(i, value);
  };

  // 増やしたinputコンポーネントの削除
  const handleRemoveInputAmountParams = (
    i: number,
    inputAmountParams: Todo
  ) => {
    const amountDetail = inputAmountParams.filter(
      (item: Todo) => item !== inputAmountParams[i]
    );
    setInputAmountParams(amountDetail);
  };

  const handleRemoveInputParams = (i: number, inputParams: Todo) => {
    const amountDetail = inputParams.amountDetail.filter(
      (item: Todo) => item !== inputParams.amountDetail[i]
    );
    setInputParams({ ...inputParams, amountDetail: amountDetail });
  };

  return (
    <S.Wrapper>
      <S.HeadContainer id="top">
        <MobileDateSelector
          date={selectedDate}
          count={count}
          getBeforeDate={getBeforeDate}
          getNextDate={getNextDate}
        />
      </S.HeadContainer>
      <S.InputContainer>
        {inputParams.amountDetail &&
          inputParams.amountDetail.length > 0 &&
          [...Array(inputParams.amountDetail.length)]
            .map((_, i) => {
              const detail = inputParams.amountDetail[i];
              if (
                isInitializeInputAmount(detail) &&
                inputAmountParams.length > 0
              )
                return;

              return (
                <SidebarInput
                  key={i}
                  index={i}
                  type={product.productKind === 1 ? 'packaged' : 'unpackaged'}
                  detail={detail}
                  detailLength={inputParams.amountDetail.length}
                  onChangeBara={(value) => handleOnChange(i, value, 'bara')}
                  onChangeCaseValue={(value) =>
                    handleOnChange(i, value, 'case')
                  }
                  onChangeDate={(value) => handleChangeDate(i, value)}
                  onChangeCase={(value) => handleChangeCase(i, value)}
                  changeCase={changeCase}
                  dateType={`${i}`}
                  stockDateLabel={product.stockDateLabel}
                  labelOfAmount={product.labelOfAmount}
                  handleDelete={handleDelete}
                  deleteId={deleteId}
                  setDeleteId={setDeleteId}
                  inputParams={inputParams}
                  handleRemoveField={handleRemoveInputParams}
                  cases={filteredProductCases}
                  selectedCasePiecesPerCase={selectedCasePiecesPerCase}
                  setSelectedCasePiecesPerCase={setSelectedCasePiecesPerCase}
                  useScanning={getUseScanning()}
                />
              );
            })
            .reverse()}
      </S.InputContainer>
      {inputAmountParams.map((item: Todo, i: number) => {
        return (
          <InputComponent
            key={`item-${i}`}
            index={i}
            type={product.productKind === 1 ? 'packaged' : 'unpackaged'}
            stockDateLabel={product.stockDateLabel}
            labelOfAmount={product.labelOfAmount}
            newProductionOrShippingDate={item.productionOrShippingDate || ''}
            onChangeBaraAmount={(value) => handleNewOnChange(i, value, 'bara')}
            onChangeCaseAmount={(value) => handleNewOnChange(i, value, 'case')}
            onChangeNewDate={(value) => handleChangeNewDate(i, value)}
            onChangeNewCase={(value) => handleChangeNewCase(i, value)}
            inputAmountParams={inputAmountParams}
            setInputAmountParams={setInputAmountParams}
            handleRemoveField={handleRemoveInputAmountParams}
            cases={filteredProductCases}
            useScanning={getUseScanning()}
          />
        );
      })}
      <S.NoteCommentContainer>
        <S.NoteCommentLabel>備考</S.NoteCommentLabel>
        <S.NoteCommentInput
          placeholder=""
          value={inputParams.comment}
          onChange={handleComment}
        />
      </S.NoteCommentContainer>
    </S.Wrapper>
  );
};
export default StockForm;
