import { isIPad13 } from 'react-device-detect';
import * as S from './DuplicateStockButton.styles';
import Duplicate from '@assets/icons/duplicate';
import { ReactComponent as DuplicateIcon } from '@assets/icons/duplicate.svg';

type Props = {
  children: string;
  disabled: boolean;
  estimated?: boolean;
  onClick: () => void;
};

const DuplicateStockButton = ({
  children,
  disabled,
  estimated,
  onClick,
}: Props) => {
  return (
    <S.Wrapper
      id="stock-status-submit-button"
      estimated={estimated}
      disabled={disabled}
      onClick={() => {
        if (!isIPad13) {
          onClick();
        }
      }}
      onTouchStart={() => {
        if (isIPad13) {
          onClick();
        }
      }}
    >
      {children}
      {disabled ? (
        <Duplicate color={'#6B675C'} />
      ) : estimated ? (
        <DuplicateIcon />
      ) : (
        <Duplicate color={'#FFFFFF'} />
      )}
    </S.Wrapper>
  );
};

export default DuplicateStockButton;
