import { FC } from 'react';
import type { Todo } from '@lib/common/type';
import ReactSelect from 'react-select';
import { NO_OPTION_JAPANESE } from '@lib/pc/common/constants';

type Props = {
  data: Todo[] | undefined;
  value: number | undefined;
  height?: string;
  marginTop?: string;
  selectedDepartmentId: number;
  setSelectedDepartmentId: (selectedDepartmentId: number) => void;
  onChange?: (selectedCaseId: number, index?: number) => void;
  disabled?: boolean;
  setIsChanged: (isChanged: boolean) => void;
  setSelectedSupplierName?: (selectedSupplierName: string) => void;
  departmentInput: string;
  setDepartmentInput: (departmentInput: string) => void;
};

const SupplierDepartmentSelect: FC<Props> = ({
  data,
  value,
  height,
  marginTop,
  disabled,
  setSelectedDepartmentId,
  setIsChanged,
}: Props) => {
  const toReactSelectOptions = (
    options: {
      departmentId: number;
      departmentName: string;
      id: number;
      name: string;
    }[]
  ) =>
    options.map(({ departmentId, departmentName, id, name }) => ({
      label: departmentName ? departmentName : name,
      value: departmentId ? departmentId : id,
    }));

  const reactSelectOptions = data && toReactSelectOptions(data);
  const currentValue = reactSelectOptions?.find((o) => o.value === value);

  const handleChange = (value: Todo): void => {
    setSelectedDepartmentId(value);
  };

  const styles = {
    control: (baseStyles: Todo) => ({
      ...baseStyles,
      minWidth: '100%',
      width: 'auto',
      height: height ? height : '56px',
      marginTop: marginTop ? marginTop : '',
      borderRadius: '0.5rem',
      fontWeight: 400,
      textAlign: 'left',
      borderStyle: 'hidden',
      outline: '0.5px solid rgba(0,0,0,0.08)',
      backgroundColor: disabled ? '' : 'white',
      cursor: 'pointer',
      '@media (max-width: 599px)': {
        width: '16rem',
      },
    }),

    menu: (baseStyles: Todo) => ({
      ...baseStyles,
      marginTop: '0px',
    }),
  };

  return (
    <ReactSelect
      onChange={(option) => {
        option && handleChange(option.value);
        setIsChanged(true);
      }}
      value={currentValue ? currentValue : null}
      options={reactSelectOptions}
      styles={styles}
      components={{
        IndicatorSeparator: () => null,
      }}
      noOptionsMessage={() => NO_OPTION_JAPANESE}
      placeholder="取引先部署の選択"
    />
  );
};

export default SupplierDepartmentSelect;
