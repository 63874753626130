import { FC } from 'react';
import * as S from './FactorySelect.styles';
import type { Todo, FactoryAll } from '@lib/common/type';
import ReactSelect from 'react-select';
import { NO_OPTION_JAPANESE } from '@lib/pc/common/constants';

type Props = {
  data: FactoryAll[] | undefined;
  value: string | number | undefined;
  width?: string;
  height?: string;
  marginTop?: string;
  disabled?: boolean;
  searchable?: boolean;
  factoryLineIds?: number[];
  setSelectedId?: (selectedId: number) => void;
  setFactoryLineIds?: (factoryLineIds: number[]) => void;
};

const FactorySelect: FC<Props> = ({
  data,
  value,
  width,
  height,
  marginTop,
  disabled,
  searchable,
  setSelectedId,
  setFactoryLineIds,
}: Props) => {
  const handleChangeId = (value: number, lines: number[]) => {
    if (setSelectedId) {
      setSelectedId(value);
    }
    if (setFactoryLineIds) {
      setFactoryLineIds(lines);
    }
  };

  const _initialValue = {
    value: 0,
    label: '全ての工場',
    lines: [],
  };

  const toReactSelectOptions = (
    options: { id: number; name: string; lines: number[] }[]
  ) =>
    options.map(({ id, name, lines }) => ({
      value: id,
      label: name,
      lines: lines,
    }));

  const reactSelectOptions = data && [...toReactSelectOptions(data)];
  const currentValue = reactSelectOptions?.find((o: Todo) => o.value === value);

  const styles = {
    control: (baseStyles: Todo) => ({
      ...baseStyles,
      width: width ? width : '220px',
      height: height ? height : '56px',
      marginTop: marginTop ? marginTop : '',
      borderRadius: '0.5rem',
      fontWeight: 400,
      textAlign: 'left',
      borderStyle: 'hidden',
      outline: '0.5px solid rgba(0,0,0,0.08)',
      backgroundColor: disabled ? '' : 'white',
      cursor: 'pointer',
      '@media (max-width: 599px)': {
        width: '16rem',
      },
    }),
    menu: (baseStyles: Todo) => ({
      ...baseStyles,
      top: '37px',
      zIndex: 1002,
    }),
    option: (baseStyles: Todo) => ({
      ...baseStyles,
      textAlign: 'left',
      fontWeight: 400,
      fontSize: '15px',
    }),
    menuList: (baseStyles: Todo) => ({
      ...baseStyles,
      maxHeight: '180px',
    }),
  };

  return (
    <S.Wrapper>
      <ReactSelect
        options={reactSelectOptions}
        value={currentValue ? currentValue : null}
        onChange={(option: Todo) => {
          option && handleChangeId(option.value, option.lines);
        }}
        styles={styles}
        isDisabled={disabled}
        isSearchable={searchable ? searchable : false}
        components={{
          IndicatorSeparator: () => null,
        }}
        noOptionsMessage={() => NO_OPTION_JAPANESE}
        placeholder="工場を選択"
      />
    </S.Wrapper>
  );
};

export default FactorySelect;
