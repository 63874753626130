import { useMutation } from 'react-query';
import humps from 'humps';
import moment from 'moment';
import { axiosInstance } from '@lib/mobile/common/api/axiosConfig';
import type { InputParams } from '@lib/stock_status/type';

// 従来のStockテーブル更新
const useUpdateStockMutation = (
  productId: string | number,
  stockKind: string,
  date: Date,
  storeroomId: string | number,
  handleSuccess: () => void,
  setErrMsg: (errMsg: string) => void
) => {
  return useMutation(
    async (params: InputParams) => {
      return await axiosInstance().patch(
        `/api/v1/stocks/${productId}?stock_kind=${stockKind}&date=${moment(
          date
        ).format('YYYY-MM-DD')}&storeroom_id=${storeroomId}`,
        humps.decamelizeKeys(params),
        { timeout: 5000 }
      );
    },
    {
      onSuccess: () => {
        handleSuccess();
      },
      // TODO: errorの型をどうすればいいか調べる
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      onError: (error: any) => {
        setErrMsg(error.response.data.message || 'エラーが発生しました');
      },
    }
  );
};

export default useUpdateStockMutation;
