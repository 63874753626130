import * as S from './MobileDateSelector.styles';
import ArrowLeft from '@assets/icons/arrow_left_24px';
import ArrowRight from '@assets/icons/arrow_right_24px';

interface Props {
  date: Date;
  count: number;
  getBeforeDate: (date: Date) => void;
  getNextDate: (date: Date) => void;
}

//日曜が0、土曜日が6。配列を使い曜日に変換する。
const dateT = ['日', '月', '火', '水', '木', '金', '土'];

const MobileDateSelector = ({
  date,
  count,
  getBeforeDate,
  getNextDate,
}: Props) => {
  return (
    <S.DateWrapper>
      <S.ArrowLeft onClick={() => getBeforeDate(date)} isActive={count <= -5}>
        <ArrowLeft isActive={count > -5} />
      </S.ArrowLeft>
      <S.Date>
        {date.getFullYear()}/{(date.getMonth() + 1).toString().padStart(2, '0')}
        /{date.getDate().toString().padStart(2, '0')}({dateT[date.getDay()]})
      </S.Date>
      <S.ArrowRight isActive={count < 0} onClick={() => getNextDate(date)}>
        <ArrowRight isActive={count < 0} />
      </S.ArrowRight>
    </S.DateWrapper>
  );
};

export default MobileDateSelector;
