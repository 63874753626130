import styled from 'styled-components';

export const DateWrapper = styled.div`
  display: flex;
  justify-content: center;
`;

export const ArrowLeft = styled.div<{
  isActive?: boolean;
}>`
  align-items: baseline;
  cursor: ${(props) => (props.isActive ? 'pointer' : '')};
  pointer-events: ${(props) => (props.isActive ? 'none' : '')};
  width: 36px;
  height: 36px;
`;

export const ArrowRight = styled.div<{
  isActive?: boolean;
}>`
  align-items: baseline;
  cursor: ${(props) => (props.isActive ? 'pointer' : '')};
`;

export const Date = styled.div`
  font-size: 24px;
  font-weight: 700;
  align-items: baseline;
  padding: 4px 4px 0 5px;
`;
