import { axiosInstance } from '@lib/pc/common/api/axiosConfig';
import { useQuery } from 'react-query';
import humps from 'humps';
import { Todo } from '@lib/common/type';

const useProducts = () => {
  const { data, refetch } = useQuery(['products'], async () => {
    const data = await axiosInstance().get(`/api/v1/settings/products`);
    return data;
  });
  const products = humps.camelizeKeys(data?.data?.products) as Todo;

  return { products, refetch };
};

export default useProducts;
