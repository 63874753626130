import { axiosInstance } from '@lib/pc/common/api/axiosConfig';
import { useQuery } from 'react-query';
import humps from 'humps';
import { Supplier } from '@lib/common/type';

const useSuppliersForMaterialSetting = () => {
  const { data, refetch } = useQuery('suppliers_for_setting', async () => {
    const data = await axiosInstance().get('/api/v1/settings/suppliers');

    return data;
  });
  const suppliers = (data &&
    humps.camelizeKeys(data?.data?.suppliers)) as Supplier[];

  return { suppliers, refetch };
};

export default useSuppliersForMaterialSetting;
