import { useMutation } from 'react-query';
import humps from 'humps';
import { axiosInstance } from '@lib/pc/common/api/axiosConfig';
import { AxiosError } from 'axios';
import { Todo, ErrorResponse } from '@lib/common/type';
import { SupplierEdit } from '../supplier_edit/type';

const useCreateSupplierMutation = (
  setCreatedProduct: (createdProduct: Todo) => void,
  handleSuccess: () => void,
  setErrMsg: (errMsg: string) => void
) => {
  return useMutation(
    async (params: SupplierEdit) => {
      return await axiosInstance()
        .post(
          `/api/v1/settings/supplier`,
          humps.decamelizeKeys({
            supplier: {
              code: params.code,
              name: params.name,
              name_kana: params.nameKana,
              honorific: params.honorific ? params.honorific : 'attention',
              rounding_method: params.roundingMethod
                ? params.roundingMethod
                : 'floor',
              description: params.description,
              order_type: params.orderType ? params.orderType : 0,
              employee_ids: params.employeeIds ? params.employeeIds : [],
            },
          })
        )
        .then((response) => {
          setCreatedProduct(humps.camelizeKeys(response.data));
        });
    },
    {
      onSuccess: () => {
        handleSuccess?.();
      },
      onError: (error: AxiosError<ErrorResponse>) => {
        const message = error.response?.data.message || 'エラーが発生しました';
        setErrMsg(message);
      },
    }
  );
};

export default useCreateSupplierMutation;
