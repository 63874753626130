import { WithHeader } from '@components/templates/mobile/WithHeader';
import * as S from './ProductsPhotographList.styles';
import { ReactComponent as Camera } from '@assets/icons/mobile/camera_black.svg';
import { ReactComponent as ChevronLeftIcon } from '@assets/icons/mobile/chevron_left.svg';
import { useHistory, useLocation, useParams } from 'react-router';
import { Todo } from '@lib/common/type';
import ProductListOfPhotograph from '@components/modules/mobile/products_photograph_list/ProductListOfPhotograph/ProductListOfPhotograph';
import useCamerasProducts from '@lib/mobile/products_photograph_list/useCamerasProducts';
import CompleteModal from '@components/modules/mobile/capture_of_product/CompleteModal/CompleteModal';
import { useState } from 'react';

export const ProductsPhotographListRoot = () => {
  const { camera_id } = useParams<{ camera_id: string }>();
  const { state } = useLocation<Todo>();
  const history = useHistory();
  const [confirmMsg, setConfirmMsg] = useState(state.stateConfirmMsg);

  const { data: camerasProducts } = useCamerasProducts(camera_id);

  // 画像がない商品を優先的に上に表示する(画像の枚数で昇順に並べ替える)
  camerasProducts?.sort((a, b) => a.images.length - b.images.length);

  // カメラリストへ戻る
  const onClickGoBack = () => {
    history.push(`/mobile/products_photograph`, {
      ...state,
      stateSearchChar: state.stateSearchChar,
      stateScrollPosition: state.stateScrollPosition,
      stateProductsScrollPosition: 0,
    });
    // }
  };

  return (
    <WithHeader>
      <S.Title>
        <S.BackButton
          onClick={() => {
            onClickGoBack();
          }}
        >
          <ChevronLeftIcon />
        </S.BackButton>
        <S.TitleText className="title-text">
          <Camera />
          商品撮影（{state.stateCameraName}）
        </S.TitleText>
      </S.Title>
      {camerasProducts && (
        <ProductListOfPhotograph products={camerasProducts} />
      )}
      <CompleteModal confirmMsg={confirmMsg} setConfirmMsg={setConfirmMsg} />
    </WithHeader>
  );
};
