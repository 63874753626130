import { useMutation } from 'react-query';
import humps from 'humps';
import { axiosInstance } from '@lib/pc/common/api/axiosConfig';
import { MaterialAllocationEditData } from '@lib/pc/material_allocation/type';

const useUpdateMaterialStockAllocationMutation = (
  id: number,
  handleUpdateSuccess: () => void
) => {
  return useMutation(
    async (params: MaterialAllocationEditData) => {
      return await axiosInstance().patch(
        `/api/v1/material_stock_allocation_details/${id}`,
        humps.decamelizeKeys({ materialStockAllocation: params })
      );
    },
    {
      onSuccess: () => {
        handleUpdateSuccess();
      },
      onError: () => alert('エラーが発生しました'),
    }
  );
};

export default useUpdateMaterialStockAllocationMutation;
