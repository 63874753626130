import { FC } from 'react';
import * as S from './ErrorMsgPopUp.styles';
import { Button } from '@components/elements/buttons/Button';

type Props = {
  errMsg: string;
  handleClose: () => void;
  fromPc?: boolean;
  zIndex?: number;
  isPortraitAndTablet?: boolean;
};

const ErrorMsgPopUp: FC<Props> = ({
  errMsg,
  handleClose,
  fromPc,
  zIndex,
  isPortraitAndTablet,
}: Props) => {
  return (
    <>
      <S.Wrapper
        open={errMsg}
        fromPc={fromPc}
        zIndex={zIndex}
        isPortraitAndTablet={isPortraitAndTablet}
      >
        {errMsg}
        <S.ButtonContainer>
          <Button padding="md" outlined onClick={() => handleClose()}>
            OK
          </Button>
        </S.ButtonContainer>
      </S.Wrapper>
    </>
  );
};

export default ErrorMsgPopUp;
