import { FC, useState, useEffect } from 'react';
import moment from 'moment';
import { Button } from '@components/elements/buttons/Button';
import * as S from './RightSlideSidebar.styles';
import ScrollToTop from '@components/modules/common/ScrollToTop/ScrollToTop';
import StockAllocationDetailForm from '@components/modules/pc/stock_allocation_all/stockAllocationDetailForm/stockAllocationDetailForm';
import type { Todo, selectedItem } from '@lib/common/type';

type Props = {
  stockAllocationsAllInProducts: Todo[];
  rightSlideSidebarActive: boolean;
  setRightSlideSidebarActive: (rightSlideSidebarActive: boolean) => void;
  selectedProduct: selectedItem | null;
};

const RightSlideSidebar: FC<Props> = ({
  stockAllocationsAllInProducts,
  rightSlideSidebarActive,
  setRightSlideSidebarActive,
  selectedProduct,
}: Props) => {
  const day =
    selectedProduct && selectedProduct.date !== ''
      ? moment(selectedProduct.date)
      : moment();

  const formattedDate = moment(day).format('YYYY/MM/DD (ddd)');

  const selectedProductKind = selectedProduct?.type === '完' ? 1 : 2;
  const product = stockAllocationsAllInProducts?.find(
    (product) =>
      product?.id === Number(selectedProduct?.id) &&
      product?.productKind === selectedProductKind
  );
  const stockAllocationDetails = product?.stockAllocationsAlls;
  const stockAllocations = stockAllocationDetails?.find((detail: Todo) =>
    moment(day).isSame(detail.date, 'day')
  );

  const [apiParams, setApiParams] = useState<Todo>({
    stockAllocationDetails: stockAllocations?.stockAllocationDetails || '',
  });

  const handleOk = () => {
    setRightSlideSidebarActive(false);
  };

  useEffect(() => {
    if (selectedProduct) {
      setApiParams({
        stockAllocationDetails: selectedProduct?.data
          ? selectedProduct?.data
          : [],
      });
    }
  }, [selectedProduct]);

  if (!selectedProduct) return null;

  return (
    <>
      <S.Wrapper open={rightSlideSidebarActive}>
        <ScrollToTop />
        <S.ScrollableContainer>
          <S.HeadContainer>
            <S.HeadDate>{formattedDate}</S.HeadDate>
            <S.HeadProductName>
              <S.HeadProductType productKind={selectedProduct?.type}>
                {selectedProduct?.type}
              </S.HeadProductType>
              {selectedProduct?.name}
            </S.HeadProductName>
          </S.HeadContainer>
          {apiParams.stockAllocationDetails.length !== 0 ? (
            <S.InputContainer>
              {[...Array(apiParams.stockAllocationDetails.length)].map(
                (_, i) => {
                  const detail = apiParams.stockAllocationDetails[i];
                  if (!product) return;
                  return (
                    <StockAllocationDetailForm
                      key={i}
                      index={i}
                      fromStoreroomName={detail?.fromStoreroomName}
                      toStoreroomName={detail?.toStoreroomName}
                      allocationType={detail?.allocationType}
                      caseValue={detail?.case || ''}
                      piece={detail?.piece || ''}
                      bestBeforeDate={
                        detail?.bestBeforeDate &&
                        moment(detail?.bestBeforeDate).format(
                          'YYYY/MM/DD (ddd)'
                        )
                      }
                      total={String(detail?.amount)}
                      piecesPerBox={
                        detail?.piecesPerBox ? `${detail?.piecesPerBox}` : '1'
                      }
                      comment={detail?.comment || ''}
                      labelOfAmount={selectedProduct.labelOfAmount}
                    />
                  );
                }
              )}
            </S.InputContainer>
          ) : (
            <S.InputContainer>
              <S.MessageWrapper>
                <div>選択した日付の払出実績はありません。</div>
              </S.MessageWrapper>
            </S.InputContainer>
          )}
          <S.ButtonContainer invalid={false}>
            <Button borderWidth={1} onClick={handleOk}>
              OK
            </Button>
          </S.ButtonContainer>
        </S.ScrollableContainer>
      </S.Wrapper>
    </>
  );
};

export default RightSlideSidebar;
