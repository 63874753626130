import { axiosInstance } from '@lib/pc/common/api/axiosConfig';
import { useQuery } from 'react-query';
import humps from 'humps';
import moment from 'moment';
import { Todo } from '@lib/common/type';

export const usePickings = (date: Date) => {
  const { data, refetch } = useQuery(['pickings', date], async () => {
    const data = await axiosInstance().get(
      `/api/v1/pickings?date=${moment(date).format('YYYYMMDD')}`
    );

    return data;
  });

  const pickings = data && (humps.camelizeKeys(data.data) as Todo);

  return { pickings, refetch };
};

export default usePickings;
