import React from 'react';
import { useParams } from 'react-router-dom';
import * as S from './MaterialMasterEditRoot.styles';
import { WithHeader } from '@components/templates/WithHeader';
import { Heading } from '@components/elements/typographies/Heading';
import MaterialMasterEditWrapper from '@components/modules/pc/settings/material_master_setting/MaterialMasterEdit/MaterialMasterEditWrapper/MaterialMasterEditWrapper';
import useMaterial from '@lib/pc/settings/material_master_edit/useMaterial';

export const MaterialMasterEditRoot: React.FC = () => {
  const { id, timestamp } = useParams<{ id: string; timestamp: string }>();
  const { material, refetch } = useMaterial(id);

  return (
    <WithHeader>
      <S.Wrapper>
        <div>
          <S.HeadContainer>
            <S.FlexBaseline>
              <div>
                <Heading
                  tag="span"
                  text="資材・原材料マスタ管理"
                  fs="32"
                  fw="lg"
                />
              </div>
            </S.FlexBaseline>
          </S.HeadContainer>
          <MaterialMasterEditWrapper
            material={material}
            refetch={refetch}
            timestamp={timestamp}
          />
        </div>
      </S.Wrapper>
    </WithHeader>
  );
};
