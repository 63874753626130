import { useState } from 'react';
import * as S from './PickingDetailByDateWithChecked.styles';
// import { ReactComponent as AddIcon } from '@assets/icons/mobile/add.svg';
import { ReactComponent as ArrowUpOutlined } from '@assets/icons/arrow_up_outlined.svg';
// import { ReactComponent as DeleteIcon } from '@assets/icons/delete.svg';
import { ReactComponent as ExpandMore } from '@assets/icons/expand_more.svg';
// import { PickingInputParams } from '@lib/mobile/picking_edit/type';
import { Todo } from '@lib/common/type';
import { PickingBreakdownDetail } from '@lib/mobile/picking_edit/type';

type Props = {
  casesPerCart: number;
  piecesPerBox: number;
  pickingBreakdownDetail: PickingBreakdownDetail[];
};

const PickingDetailByDateWithChecked = ({
  casesPerCart,
  piecesPerBox,
  pickingBreakdownDetail,
}: Props) => {
  // const [newDetailData, setNewDetailData] = useState(pickingBreakdownDetail);
  const [detailActive, setDetailActive] = useState(false);

  return (
    <S.Wrapper>
      <S.TitleText onClick={() => setDetailActive(!detailActive)}>
        ピッキングした内訳
        {detailActive ? <ArrowUpOutlined /> : <ExpandMore />}
      </S.TitleText>
      <S.DetailWrapper open={detailActive}>
        {pickingBreakdownDetail.map((p: Todo, index: number) => (
          <S.Detail key={index}>
            <S.ExpirationDate>
              <S.LabelWrapper>
                <S.ProductionOrShippingDateLabel
                  htmlFor={`production-date-${index}`}
                >
                  賞味期限
                </S.ProductionOrShippingDateLabel>
                <S.Input
                  // disable={true}
                  type="date"
                  name="productionDate"
                  id={`production-date-${index}`}
                  value={p.bestBeforeDate ? p.bestBeforeDate : ''}
                  disabled
                />
              </S.LabelWrapper>
            </S.ExpirationDate>
            {/* ピッキング入力エリア */}
            <S.AmountWrapper>
              {casesPerCart && (
                <>
                  <S.DInputContainer center={true}>
                    <label htmlFor={`new-stock-cart-${index}`}>台車</label>
                    <input
                      id={`new-stock-cart-${index}`}
                      value={p.cart ? p.cart : ''}
                      disabled
                    />
                    <span>×{casesPerCart}ｹｰｽ</span>
                  </S.DInputContainer>
                  <S.DSymbol>+</S.DSymbol>
                </>
              )}
              <S.DInputContainer center={true}>
                <label htmlFor={`new-stock-case-${index}`}>ケース</label>
                <input
                  id={`new-stock-case-${index}`}
                  value={p.case ? p.case : ''}
                  disabled
                />
                <span>×{piecesPerBox}個</span>
              </S.DInputContainer>
              <S.DSymbol>+</S.DSymbol>
              <S.DInputContainer>
                <label htmlFor={`new-stock-bara-${index}`}>個</label>
                <input
                  id={`new-stock-bara-${index}`}
                  value={p.piece ? p.piece : ''}
                  disabled
                />
              </S.DInputContainer>
              <S.DSymbol>=</S.DSymbol>
              <S.DInputContainer>
                <label htmlFor="new-stock-total">合計</label>
                <input
                  id="new-stock-total"
                  value={p.amount ? p.amount : ''}
                  disabled
                />
              </S.DInputContainer>
            </S.AmountWrapper>
          </S.Detail>
        ))}
      </S.DetailWrapper>
    </S.Wrapper>
  );
};

export default PickingDetailByDateWithChecked;
