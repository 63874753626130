import React from 'react';
import { useParams } from 'react-router-dom';
import { WithHeader } from '@components/templates/WithHeader';
import { Heading } from '@components/elements/typographies/Heading';
import * as S from './ProductMasterEditRoot.styles';
import ProductMasterEditWrapper from '@components/modules/pc/settings/product_master_setting/ProductMasterEdit/ProductMasterEditWrapper/ProductMasterEditWrapper';
import useProduct from '@lib/pc/settings/product_master_edit/useProduct';

export const ProductMasterEditRoot: React.FC = () => {
  const { id, timestamp } = useParams<{ id: string; timestamp: string }>();
  const { product, refetch } = useProduct(id);

  return (
    <WithHeader>
      <S.Wrapper>
        <div>
          <S.HeadContainer>
            <S.FlexBaseline>
              <div>
                <S.ProductKind>完成品</S.ProductKind>
                <Heading tag="span" text="製品マスタ管理" fs="32" fw="lg" />
              </div>
            </S.FlexBaseline>
          </S.HeadContainer>
          <ProductMasterEditWrapper
            product={product}
            refetch={refetch}
            timestamp={timestamp}
          />
        </div>
      </S.Wrapper>
    </WithHeader>
  );
};
