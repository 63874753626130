import styled, { css } from 'styled-components';

export const ListWrapper = styled.div`
  max-width: 100vw;
  height: calc(100vh - 165px);

  overflow: scroll;
  display: flex;
  position: relative;
  background: ${(props) => props.theme.palette.beige};
`;

/**
 *
 * リスト
 *
 */
const highLightenHeadCell = css`
  color: ${(props) => props.theme.palette.white}!important;
  background: ${(props) => props.theme.palette.blue}!important;
  border-color: ${(props) => props.theme.palette.blue}!important;
`;

const highLightenListLeftCell = css`
  position: relative;
  border-right: 2px solid ${(props) => props.theme.palette.blue};
  border-left: 2px solid ${(props) => props.theme.palette.blue};
`;

const cornerArrow = css`
  ::before,
  ::after {
    content: '';
    position: absolute;
    top: 0;
    right: 0;
    border-color: transparent;
    border-style: solid;
  }
  ::before {
    border-width: 0.4rem;
  }
  ::after {
    border-width: 0.4rem;
    border-right-color: ${(props) => props.theme.palette.blue};
    border-top-color: ${(props) => props.theme.palette.blue};
  }
`;

export const ListHeadCell = styled.div<{
  highLighten: boolean;
  dayOfWeek: string;
}>`
  height: 52px;
  width: 85px;
  box-sizing: border-box;
  padding: 0.5rem 2.5rem;
  display: flex;
  justify-content: center;
  align-items: center;
  color: ${(props) =>
    props.dayOfWeek === '土' && !props.highLighten
      ? '#1046cf'
      : props.dayOfWeek === '日' && !props.highLighten
      ? '#c74141'
      : ''};
  box-shadow: 1px 3px 5px 0px rgba(0, 0, 0, 0.2);

  :nth-child(odd) {
    background: ${(props) => props.theme.palette.beige};
  }

  :nth-child(even) {
    background: ${(props) => props.theme.palette.white};
  }

  ${({ highLighten }) => (highLighten ? highLightenHeadCell : null)}
`;

// 実在庫セル
export const ListCellContainer = styled.div<{
  highLighten: boolean;
  withComment?: boolean;
  invalid?: boolean;
  selectedStoreroomId?: boolean;
}>`
  pointer-events: ${(props) => (props.invalid ? 'none' : '')};
  position: relative;
  cursor: ${(props) => (props.selectedStoreroomId ? 'pointer' : '')};
  height: 37px;
  width: 85px;
  box-sizing: border-box;
  padding: 0.5rem 0;
  display: flex;
  justify-content: center;
  align-items: center;
  ${({ withComment }) => (withComment ? cornerArrow : null)};
  ${({ highLighten }) => (highLighten ? highLightenListLeftCell : null)};
  border-bottom: 1px solid ${(props) => props.theme.palette.yellowGray};
`;

// 実在庫以外のセル
export const CellContainer = styled.div<{
  highLighten: boolean;
  amount?: boolean;
}>`
  position: relative;
  cursor: ${(props) => (props.amount ? 'pointer' : '')};
  height: 37px;
  width: 85px;
  box-sizing: border-box;
  padding: 0.5rem 0;
  display: flex;
  justify-content: center;
  align-items: center;
  ${({ highLighten }) => (highLighten ? highLightenListLeftCell : null)};
  border-bottom: 1px solid ${(props) => props.theme.palette.yellowGray};
`;

export const ListBody = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  z-index: 2;
  position: relative;
  height: max-content;
  -webkit-transition: transform 390ms ease-in-out;
  -moz-transition: transform 390ms ease-in-out;
  -ms-transition: transform 390ms ease-in-out;
  -o-transition: transform 390ms ease-in-out;
  transition: transform 390ms ease-in-out;
  &.move-to-left {
    -webkit-transform: translateX(-390px);
    -moz-transform: translateX(-390px);
    -ms-transform: translateX(-390px);
    -o-transform: translateX(-390px);
    transform: translateX(-390px);
  }

  & > div:first-child {
    position: -webkit-sticky; /* for Safari */
    position: sticky;
    top: 0px;
    z-index: 4;
    //COMMENT: 色確認、figmaと違う？ figma:#F5F2E6
    background-color: #f8f7f4;
    //comment: スクロールでシャドウいるか書いてないので
    /* box-shadow: 1px 3px 5px 0px rgba(0, 0, 0, 0.2); */
  }

  & > div:nth-child(odd) {
    background: ${(props) => props.theme.palette.paleYellow};
  }

  & > div:nth-child(even) {
    background: ${(props) => props.theme.palette.white};
  }
  & > div:not(:first-child):nth-child(even) > div:nth-child(odd) {
    background: ${(props) => props.theme.palette.beige};
  }
  & > div:not(:first-child):nth-child(odd) > div:nth-child(odd) {
    background: ${(props) => props.theme.palette.yellowGray};
  }
`;

export const ListRow = styled.div<{
  notDisplay?: string;
}>`
  display: ${(props) => (props.notDisplay === 'true' ? 'none' : 'flex')};
  /* display: flex; */
  background: #fff;
  /* height: 37px; */

  :first-child {
    position: -webkit-sticky; /* for Safari */
    position: sticky;
    top: 10px;
    z-index: 4;
    box-shadow: 1px 3px 5px 0px rgba(0, 0, 0, 0.2);
  }
`;

export const CircularIconWrapper = styled.div`
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 10001;
  background-color: ${(props) => props.theme.palette.beige} !important;
`;

export const ListDateRow = styled.div`
  display: flex;
  background: #fff;
`;

export const ListSidebar = styled.div`
  min-width: 300px;
  height: max-content;
  border-right: 1px solid ${(props) => props.theme.palette.yellowGray};
  background: #fff;
  background: ${(props) => props.theme.palette.beige};

  position: sticky;
  left: -1px;
  z-index: 5;

  & > div:first-child {
    background: ${(props) => props.theme.palette.paleYellow};

    position: -webkit-sticky; /* for Safari */
    position: sticky;
    top: -1px;
    /* padding: 2px; */
    background: ${(props) => props.theme.palette.yellowGray};
    z-index: 1000;
    /* display: grid; */
    /* place-items: center; */
  }
`;

// 資材原材料名
export const ListLeftHeadCell = styled.div<{
  invalid?: boolean;
  isBlue?: boolean;
}>`
  pointer-events: ${(props) => (props.invalid ? 'none' : '')};
  cursor: pointer;
  min-width: 300px;
  box-shadow: 4px 3px 5px 0px rgba(0, 0, 0, 0.05);
  position: -webkit-sticky; /* for Safari */
  -webkit-transform: translateZ(0);
  display: flex;
  gap: 0.25rem;

  cursor: pointer;

  :first-child {
    height: 52px;
    padding: 6px 0.75rem;
  }

  > span {
    font-size: 0.75rem;
    &.filter-product {
      width: 62%;
      padding-left: 30px;
      height: 40px;
      line-height: 32px;
      position: absolute;
      background: ${(props) => props.theme.palette.white};
      color: ${(props) =>
        props.isBlue ? props.theme.palette.blue : '#9ca3af'};
      border-radius: 0.5rem;
      cursor: pointer;

      > svg {
        position: absolute;
        top: 20%;
        left: 5%;
      }

      > div {
        padding-left: 3px;
        padding-top: 5px;
        font-size: 0.7rem;
      }
    }
  }

  //COMMENT: OKであれば後で書き方変えたほうがいい
  :first-child {
    background-color: #f5f2e6 !important;
    > div > input {
      cursor: pointer;
      height: 32px;
      padding: 0.5rem 40px 0.5rem;
    }
  }

  :nth-child(odd) {
    background: ${(props) => props.theme.palette.paleYellow};
  }
  :nth-child(even) {
    background: ${(props) => props.theme.palette.white};
  }
`;

export const MaterialInfoWrapper = styled.div`
  /* height: 100%; */
  width: 265px;
  display: inline-grid;
  padding: 6px 0.75rem;
  vertical-align: middle;
`;

// 表示選択
export const DisplayButton = styled.div<{
  isBlue: boolean;
}>`
  > span {
    &.filter-product {
      width: 28%;
      padding-left: 30px;
      height: 40px;
      line-height: 32px;
      top: 7px;
      right: 8px;
      position: absolute;
      background: ${(props) => props.theme.palette.white};
      color: ${(props) =>
        props.isBlue ? props.theme.palette.blue : '#9ca3af'};
      border-radius: 0.5rem;
      cursor: pointer;

      > svg {
        position: absolute;
        top: 20%;
        left: 5%;
      }

      > div {
        padding-top: 5px;
        font-size: 0.7rem;
      }
    }
  }

  > svg {
    position: absolute;
    top: 12%;
    left: 5%;
  }
`;

export const ListLeftHeadName = styled.div`
  color: ${(props) => props.theme.palette.blue};
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-size: 14px;
`;

export const MaterialName = styled.div`
  max-width: 145px;
  line-height: 1.5;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  cursor: pointer;
`;

// コード、最終入力日
export const ListLeftHeadMeta = styled.div`
  color: rgba(51, 51, 51, 0.5);
  align-items: center;
  font-size: 0.75rem;
  > span {
    font-weight: 700;
  }
`;

export const ListLeftMaterialCode = styled.div`
  font-size: 12px;
  height: 10px;
  padding-bottom: 15px;
`;

// 表示タイプ
export const ListLeftHeadTypeName = styled.div<{
  notDisplay: string;
  productIndex: number;
  typeIndex: number;
}>`
  width: 100px;
  height: 37px;
  /* height: 30px; */
  font-size: 15px;
  color: #6b675c;
  text-align: left;
  padding-left: 1.1rem;
  vertical-align: middle;
  border-left: 1px solid ${(props) => props.theme.palette.yellowGray};
  display: ${(props) => (props.notDisplay === 'true' ? 'none' : 'flex')};
  border-bottom: 1px solid #efefef;
  align-items: center;
  background: ${(props) =>
    props.productIndex % 2 === 0
      ? props.typeIndex % 2 === 0
        ? props.theme.palette.white
        : props.theme.palette.paleYellow
      : props.typeIndex % 2 === 0
      ? props.theme.palette.paleYellow
      : props.theme.palette.white};
  :last-child {
    /* border-bottom: 0px; */
  }
`;

export const MaterialTypeDiv = styled.div<{
  materialKind: string;
}>`
  font-size: 0.75rem;
  padding: 2px 6px;
  border-radius: 2px;
  border: 1px solid #2ba9d1;
  background: ${(props) => (props.materialKind === '原' ? '#2BA9D1' : '#fff')};
  color: ${(props) => (props.materialKind === '原' ? '#fff' : '#2BA9D1')};
`;
