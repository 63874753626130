import { MainPath, PCPath } from '@config/path';
import { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { ReactComponent as MenuIcon } from '@assets/icons/menu_alt.svg';
import * as S from './Header.styles';
import { sidebarLinkData } from '@lib/pc/constants';
import Overlay from '@components/elements/backdrops/Overlay/Overlay';
import LogoutMenu from '@components/modules/common/pc/LogoutMenu/LogoutMenu';
import { ReactComponent as ArrowUpOutlined } from '@assets/icons/arrow_up_outlined.svg';
import { ReactComponent as ExpandMore } from '@assets/icons/expand_more.svg';
import LogoutDialog from '@components/modules/common/LogoutDialog/LogoutDialog';
import ArrowRight from '@assets/icons/arrow_right.svg';
import useMenuFunctions from '@lib/pc/common/hooks/useMenuFunctions';
import useMenuLinkFunctions from '@lib/pc/common/hooks/useMenuLinkFunctions';

export const Header = () => {
  const [active, setActive] = useState(false);
  const [logoutMenuActive, setLogoutMenuActive] = useState(false);
  const [logoutDialogActive, setLogoutDialogActive] = useState(false);
  const history = useHistory();
  const email = localStorage.getItem('email');
  const { data: menuFunctions } = useMenuFunctions();
  const { data: linkFunctions } = useMenuLinkFunctions();

  const logout = () => {
    localStorage.removeItem('pc_token');
    localStorage.removeItem('menu_functions');
    localStorage.removeItem('email');
    localStorage.removeItem('use_cart');
    localStorage.removeItem('product_production_selected_line');
    localStorage.removeItem('semi_product_production_selected_line');
    localStorage.removeItem('last_view_page');
    history.push('/pc/login');
  };

  const handleOnClickLogout = () => {
    setLogoutMenuActive(false);
    setLogoutDialogActive(true);
  };

  const handleCloseDialog = () => {
    setLogoutDialogActive(false);
  };

  const jumpTo = (path: PCPath | '') => {
    //TODO(risakanagawa) もっといい感じにできる
    history.push(MainPath.pc + path);
    setActive(false);
  };

  useEffect(() => {
    localStorage.setItem('menu_functions', menuFunctions?.join(',') || '');
  }, [menuFunctions]);

  return (
    <S.Wrapper>
      <S.Navigation>
        <S.FlexCenter>
          <S.Menu
            onClick={() => {
              setActive(!active);
            }}
          >
            <MenuIcon />
          </S.Menu>
          <S.Title href="#">Regnio Factory</S.Title>
        </S.FlexCenter>
        <S.FlexCenter
          onClick={() => {
            setLogoutMenuActive(!logoutMenuActive);
          }}
        >
          <div>{email}</div>
          {logoutMenuActive ? <ArrowUpOutlined /> : <ExpandMore />}
        </S.FlexCenter>
      </S.Navigation>

      <S.Sidebar active={active}>
        <S.SidebarNavigation>
          <div>
            <ul>
              {sidebarLinkData.map((d) => {
                return (
                  menuFunctions?.includes(d.function) && (
                    <S.SidebarList key={d.title}>
                      <S.SidebarListHeader>
                        {d.icon ? <d.icon /> : ''}
                        <S.SidebarListTitle>{d.title}</S.SidebarListTitle>
                      </S.SidebarListHeader>
                      {d.links.map((link, i) => {
                        return (
                          linkFunctions?.includes(link.function) && (
                            <S.SidebarListLink
                              key={i}
                              onClick={() => {
                                jumpTo(link.url);
                                setActive(false);
                              }}
                            >
                              <img className="arrow-right" src={ArrowRight} />
                              {link.label}
                            </S.SidebarListLink>
                          )
                        );
                      })}
                    </S.SidebarList>
                  )
                );
              })}
            </ul>
          </div>
        </S.SidebarNavigation>
      </S.Sidebar>
      {active && <Overlay handleClick={() => setActive(false)} />}
      {logoutDialogActive && (
        <Overlay dark handleClick={() => setLogoutDialogActive(false)} />
      )}
      {logoutMenuActive && (
        <Overlay handleClick={() => setLogoutMenuActive(false)} />
      )}
      <S.LogoutMenu logoutMenuActive={logoutMenuActive}>
        <LogoutMenu handleOnClickLogout={handleOnClickLogout} />
      </S.LogoutMenu>
      <S.logoutDialog logoutDialogActive={logoutDialogActive}>
        <LogoutDialog logout={logout} handleCloseDialog={handleCloseDialog} />
      </S.logoutDialog>
    </S.Wrapper>
  );
};
