import { Todo } from '@lib/common/type';
import * as S from './FormTop.styles';
import { FC, useEffect, useState } from 'react';
import SupplierDepartmentSelect from '@components/modules/pc/purchase_orders_new/SupplierDepartmentSelect/SupplierDepartmentSelect';
import SupplierSelect from '@components/modules/pc/purchase_orders_new/SupplierSelect/SupplierSelect';
import useSuppliers from '@lib/pc/purchase_orders_new/useSuppliers';
import HonorificSelect from '@components/modules/pc/purchase_orders_edit/HonorificSelect/HonorificSelect';
import { Honorific } from '@lib/pc/purchase_orders_edit/type';

type Props = {
  newData: Todo;
  selectedSupplierId: number;
  setSelectedSupplierId: (selectedSupplierId: number) => void;
  selectedHonorific: string;
  setSelectedHonorific: (selectedHonorific: string) => void;
  selectedDepartmentId: number;
  setSelectedDepartmentId: (selectedDepartmentId: number) => void;
  selectedDepartment: Todo[];
  setSelectedDepartment: (selectedDepartment: Todo[]) => void;
  setNewData: (newData: Todo) => void;
  handleChange: (
    e:
      | React.ChangeEvent<HTMLInputElement>
      | React.ChangeEvent<HTMLTextAreaElement>
  ) => void;
  setIsChanged: (isChanged: boolean) => void;
  isDetailChanged: boolean;
};

const FormTop: FC<Props> = ({
  newData,
  selectedSupplierId,
  setSelectedSupplierId,
  selectedHonorific,
  setSelectedHonorific,
  selectedDepartmentId,
  setSelectedDepartmentId,
  selectedDepartment,
  setSelectedDepartment,
  setNewData,
  handleChange,
  setIsChanged,
  isDetailChanged,
}) => {
  const [selectedSupplierName, setSelectedSupplierName] = useState('');
  const [selectedRoundingMethod, setSelectedRoundingMethod] = useState('');
  const [departmentInput, setDepartmentInput] = useState('');

  const { data: suppliers } = useSuppliers();

  useEffect(() => {
    setNewData({
      ...newData,
      supplierHonorific: selectedHonorific
        ? selectedHonorific
        : newData?.supplierHonorific,
    });
    if (selectedHonorific) {
      setIsChanged(true);
    }
  }, [selectedHonorific]);

  useEffect(() => {
    setNewData({
      ...newData,
      supplierId: selectedSupplierId,
      supplierName: selectedSupplierName
        ? selectedSupplierName
        : newData?.supplierName,
      roundingMethod: selectedRoundingMethod
        ? selectedRoundingMethod
        : newData?.roundingMethod,
    });
  }, [selectedSupplierId]);

  useEffect(() => {
    const selectedInfo = selectedDepartment?.find(
      (sd: { id?: number; departmentId?: number }) =>
        sd.departmentId === selectedDepartmentId
    );
    setNewData((prev: Todo) => ({
      ...prev,
      supplierDepartmentName: selectedInfo?.departmentName,
      supplierDepartmentId: selectedDepartmentId || selectedInfo?.departmentId,
      supplierDescription: selectedInfo?.contactsInfo[0],
    }));
  }, [selectedDepartmentId, newData?.orderId, selectedDepartment]);

  // 編集時にも取引先部署の情報(contactsInfo)設定
  useEffect(() => {
    if (selectedSupplierId) {
      const searchedSupplier = suppliers?.find(
        (item: Todo) => item.id === selectedSupplierId
      );
      setSelectedDepartment(searchedSupplier?.supplierDescription);
    }
  }, [suppliers?.length, selectedSupplierId]);

  return (
    <S.FormMultiColumnContainer>
      {/* フォーム左カラム */}
      <S.FormLeftContainer>
        <S.SupplierLabel>
          <S.FormLabel>取引先（御中）</S.FormLabel>
          <S.HonorificLabel>敬称</S.HonorificLabel>
        </S.SupplierLabel>
        <S.SupplierWrap>
          {newData?.orderStatus === '' ? (
            <S.SupplierSelectWrap>
              <SupplierSelect
                data={suppliers}
                height="40px"
                value={selectedSupplierId}
                setSelectedId={setSelectedSupplierId}
                setSelectedSupplierName={setSelectedSupplierName}
                setSelectedDepartment={setSelectedDepartment}
                setSelectedRoundingMethod={setSelectedRoundingMethod}
                setIsChanged={setIsChanged}
                disabled={isDetailChanged}
              />
            </S.SupplierSelectWrap>
          ) : (
            <S.Input
              type="text"
              name="supplierName"
              id="supplierName"
              autoComplete="new-password"
              align="left"
              padding="0 10px"
              width="79%"
              value={newData?.supplierName || ''}
              disabled
            />
          )}
          <S.SuppliersHonorific>
            <HonorificSelect
              data={Honorific}
              height="40px"
              value={selectedHonorific}
              setSelectedHonorific={setSelectedHonorific}
              disabled={newData?.orderStatus === 'completed'}
            />
          </S.SuppliersHonorific>
        </S.SupplierWrap>
        {(newData?.orderStatus === 'draft' || newData?.orderStatus === '') && (
          <S.FormLabel>取引先部署</S.FormLabel>
        )}

        {newData?.orderStatus === 'draft' || newData?.orderStatus === '' ? (
          <SupplierDepartmentSelect
            data={selectedDepartment}
            height="40px"
            marginTop="8px"
            value={selectedDepartmentId}
            selectedDepartmentId={selectedDepartmentId}
            setSelectedDepartmentId={setSelectedDepartmentId}
            disabled={selectedSupplierId === 0 || isDetailChanged}
            setIsChanged={setIsChanged}
            departmentInput={departmentInput}
            setDepartmentInput={setDepartmentInput}
          />
        ) : newData?.supplierDepartmentName ? (
          <S.Input
            type="text"
            name="supplierName"
            id="supplierName"
            autoComplete="new-password"
            align="left"
            padding="0 10px"
            value={newData?.supplierDepartmentName || ''}
            disabled
          />
        ) : (
          ''
        )}
        <S.FormLabel>取引先詳細</S.FormLabel>
        <S.FormTextArea
          name="supplierDescription"
          id="supplierDescription"
          autoComplete="new-password"
          value={newData?.supplierDescription || ''}
          onChange={handleChange}
        />
        <S.FormLabel>件名</S.FormLabel>
        <S.Input
          type="text"
          name="subject"
          id="subject"
          autoComplete="new-password"
          align="left"
          padding="0 10px"
          value={newData?.subject || ''}
          onChange={handleChange}
        />
        <S.FormLabel>納期</S.FormLabel>
        <S.Input
          type="date"
          name="deliveryDate"
          id="deliveryDate"
          autoComplete="new-password"
          align="left"
          padding="0 10px"
          value={newData?.deliveryDate || ''}
          onChange={handleChange}
          pattern="\d{4}-\d{2}-\d{2}"
          max="9999-12-31"
        />
        <S.FormLabel>納入場所</S.FormLabel>
        <S.Input
          type="text"
          name="deliveryDestination"
          id="deliveryDestination"
          autoComplete="new-password"
          align="left"
          padding="0 10px"
          value={newData?.deliveryDestination || ''}
          onChange={handleChange}
        />
        <S.FormLabel>支払条件</S.FormLabel>
        <S.Input
          type="text"
          name="paymentTerm"
          id="paymentTerm"
          autoComplete="new-password"
          align="left"
          padding="0 10px"
          value={newData?.paymentTerm || ''}
          onChange={handleChange}
        />
      </S.FormLeftContainer>

      {/* フォーム右カラム */}
      <S.FormRightContainer>
        <S.FormLabel>注文書番号</S.FormLabel>
        <S.Input
          type="text"
          name="purchaseOrderNumber"
          id="purchaseOrderNumber"
          autoComplete="new-password"
          align="left"
          padding="0 10px"
          value={newData?.purchaseOrderNumber || ''}
          onChange={handleChange}
        />
        {/* <S.FormLabel>注文データ作成日</S.FormLabel>
        <S.Input
          type="date"
          name="confirmationDate"
          id="confirmationDate"
          autoComplete="new-password"
          align="left"
          padding="0 10px"
          value={newData?.issuedDate || ''}
          onChange={handleChange}
          pattern="\d{4}-\d{2}-\d{2}"
          max="9999-12-31"
          disabled
        /> */}
        <S.FormLabel>発行日（注文書送信日）</S.FormLabel>
        <S.Input
          type="date"
          name="confirmationDate"
          id="confirmationDate"
          autoComplete="new-password"
          align="left"
          padding="0 10px"
          value={newData?.confirmationDate || ''}
          onChange={handleChange}
          pattern="\d{4}-\d{2}-\d{2}"
          max="9999-12-31"
        />
      </S.FormRightContainer>
    </S.FormMultiColumnContainer>
  );
};

export default FormTop;
