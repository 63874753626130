import styled from 'styled-components';

export const Wrapper = styled.div`
  background: ${(props) => props.theme.palette.white};
  position: relative;
  height: calc(100vh - 80px);
`;

export const HeadContainer = styled.div`
  height: 86px;
  padding: 1.4rem 2rem 1.4rem 2.5rem;
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

export const ProductKind = styled.span`
  padding: 5px 10px 7px;
  height: 32px;
  background: #2ba9d1;
  color: ${(props) => props.theme.palette.white};
  font-size: 19px;
  margin-right: 16px;
  border-radius: 2px;
  vertical-align: super;
`;

export const PageTitle = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

export const PageTitleText = styled.div`
  display: flex;
  align-items: baseline;
  padding-bottom: 4px;
`;

export const DateWrapper = styled.div`
  display: flex;
  align-items: center;
  position: relative;
`;

export const SelectWrapper = styled.div`
  margin-right: 1rem;
`;

export const Date = styled.div`
  font-size: 24px;
  font-weight: 700;
  height: 24px;
  line-height: 24px;
  text-align: center;
  padding: 5px 0;
  user-select: none;
  display: flex;
  align-items: center;
  position: relative;
  margin-top: -3px;

  > span {
    font-size: 22px;
    margin: 0 5px 0 5px;
  }
`;

export const ButtonContainer = styled.div`
  position: relative;
  margin-left: 6px;
`;

export const calcButton = styled.div<{
  updateCount: boolean;
}>`
  height: 40px;
  width: 70px;
  background: ${(props) =>
    props.updateCount ? props.theme.palette.brightGreen : '#cdcdcd'};
  color: #9ca3af;
  border-radius: 0.3rem;
  position: relative;
  cursor: ${(props) => (props.updateCount ? 'pointer' : '')};

  > svg {
    position: absolute;
    top: 10px;
    left: 24px;
  }

  > .tooltip {
    width: 50px;
    position: absolute;
    left: 49%;
    top: 108%;
    transform: translateX(-50%);
    padding: 8px;
    border-radius: 0.3rem;
    background-color: #666;
    font-size: 0.7rem;
    color: ${(props) => props.theme.palette.white};
    text-align: center;
    visibility: hidden;
    opacity: 0;
    z-index: 10;
    transition: 0.8s all;
  }

  &:hover .tooltip {
    visibility: visible;
    opacity: 1;
    z-index: 10;
  }

  .tooltip:hover {
    visibility: hidden;
    opacity: 0;
    z-index: 10;
  }
`;

export const ArrowLeft = styled.div<{
  selected: boolean;
}>`
  cursor: pointer;
  margin-right: 0.2rem;
  pointer-events: ${(props) => (props.selected ? 'none' : '')}; ;
`;

export const ArrowRight = styled.div<{
  selected: boolean;
}>`
  cursor: pointer;
  pointer-events: ${(props) => (props.selected ? 'none' : '')}; ;
`;

export const ModalWrapper = styled.div<{
  width?: string;
}>`
  background: ${(props) => props.theme.palette.white};
  position: fixed;
  left: 50%;
  top: 50%;
  width: ${(props) => (props.width ? props.width : '')};
  height: 156px;
  transform: translate(-50%, -50%);
  z-index: 10001;
  box-shadow: 0px 6px 10px rgba(0, 0, 0, 0.07), 0px 1px 18px rgba(0, 0, 0, 0.06),
    0px 3px 5px rgba(0, 0, 0, 0.1);
  border-radius: 8px;
  padding: 1rem;
`;

export const ModalTextContainer = styled.div`
  display: flex;
  flex-direction: column;
  font-weight: 500;
  font-size: 1.13rem;
  line-height: 27px;

  > span:first-child {
    font-weight: bold;
    margin-bottom: 0.7rem;
  }

  > span:last-child {
    font-size: 16px;
  }
`;

export const ModalButtonContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 1rem;
  margin-top: 1rem;
  button {
    height: 2.75rem;
    &:nth-child(2) {
      border-color: ${(props) => props.theme.palette.brightGreen};
      background: ${(props) => props.theme.palette.brightGreen};
      color: ${(props) => props.theme.palette.white};
    }
  }
`;

export const CircularIconWrapper = styled.div`
  position: absolute;
  top: 47%;
  left: 51%;
  z-index: 10001;
`;
