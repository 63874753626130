import styled from 'styled-components';

export const Wrapper = styled.span<{
  open?: boolean;
}>`
  display: ${(props) => (props.open ? 'block' : 'none')};
  z-index: 9999;
  width: 240px;
  position: absolute;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 15px;

  width: 240px;
  // height: 66px;
  /* height: 88px; */
  left: 0px;
  top: calc(50% + 22px);
  /* top: 44px; */

  /* surface */

  background: #fefefe;
  /* 08 dp */

  box-shadow: 0px 8px 10px rgba(0, 0, 0, 0.14), 0px 3px 14px rgba(0, 0, 0, 0.12),
    0px 5px 5px rgba(0, 0, 0, 0.2);
  border-radius: 4px;
`;

export const Edit = styled.span`
  flex: none;
  order: 1;
  flex-grow: 0;

  font-family: 'Noto Sans JP';
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 23px;
  letter-spacing: 0.15px;
  padding-bottom: 10px;

  /* text/base */

  color: #333333;

  /* Inside auto layout */

  flex: none;
  order: 1;
  flex-grow: 0;
  cursor: pointer;
`;

export const Delete = styled.span`
  font-family: 'Noto Sans JP';
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 23px;
  /* padding: 10px; */
  /* identical to box height */

  letter-spacing: 0.15px;

  /* Error */

  color: #b3361b;

  /* Inside auto layout */

  flex: none;
  order: 1;
  flex-grow: 0;
  cursor: pointer;
`;
