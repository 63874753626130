import type { Todo } from '@lib/common/type';

export type StockData = {
  id: number;
  packagedProduct: number;
  packagedProductDetail: StockProductStatus[];
  productionDate: Date;
  unpackagedProduct: number;
  unpackagedProductDetail: StockProductStatus[];
};

export type StockProductStatus = {
  bara: number;
  case: number;
  dateType: string;
  total: number;
};

export type StockStatusData = {
  ExpiryDays: number;
  product_id: number;
  product_name: string;
  stockData: StockData[];
};

export type DetailItem = {
  amountDetailId?: number | '';
  bara?: string;
  case?: string;
  cart?: string;
  total?: number | string;
  caseId?: number;
  piecesPerBox?: number;
  productionOrShippingDate?: Date | string;
};

export type Stock = {
  date: string;
  safetyStocks: number;
  packagedActualAmount: number;
  packagedActualAmountDetail: DetailItem[];
  packagedComment: string;
  unpackagedActualAmount?: number;
  unpackagedActualAmountDetail?: DetailItem[];
  unpackagedComment?: string;
};

export type InputParams = {
  amountDetail: DetailItem[];
  comment: string;
};

export type selectedItem = {
  productId: number;
  productName: string;
  productKind: number;
  ExpiryDays: number;
  type: '完' | '半';
  date: Date | '';
  data: Todo;
  comment: string;
  piecesPerBox: number;
  casesPerCart: number;
  stockDateLabel: string;
  labelOfAmount: string;
  safetyStockDays: string | null;
};

export const stockStatusDayColors: { [key: string]: string } = {
  D0: '#5FA8DC',
  D1: '#B2E7F2',
  D2: '#77CEAA',
  D3: '#CFDC7E',
  D4: '#FCE6C6',
  D5: '#F19B4C',
  D6: '#DD5471',
  0: '#DD5471',
  1: '#5FA8DC',
  2: '#B2E7F2',
  3: '#77CEAA',
  4: '#CFDC7E',
  5: '#FCE6C6',
  6: '#F19B4C',
  7: '#DD5471',
  8: '#5FA8DC',
  9: '#B2E7F2',
  10: '#77CEAA',
  11: '#CFDC7E',
  12: '#FCE6C6',
  13: '#F19B4C',
  14: '#DD5471',
} as const;
type stockStatusDayColors =
  typeof stockStatusDayColors[keyof typeof stockStatusDayColors];

//todo: ここじゃなくてもいいかも
export const productStockStatus: { [key: string]: string } = {
  packaged: '完成品',
  unpackaged: '半製品',
} as const;
type productStockStatus =
  typeof productStockStatus[keyof typeof productStockStatus];

export type AllStoreroomDetail = {
  bara?: number;
  case?: number;
  cart?: number;
  total?: number;
  caseId?: number;
  piecesPerBox?: number;
  productionOrShippingDate?: Date | string;
  caseName?: string;
};

export type stocks = {
  amountDetail: AllStoreroomDetail[];
  storeroomName: string;
  comment: string;
  isActual: boolean;
  totalAmount: number;
};

export type AllStoreroomStocks = {
  stockDetails: {
    date: Date;
    name: string;
    productKind: number;
    stockDateLabel: string | null;
    hasCase: boolean;
    stocks: stocks[];
    estimatedStocks: stocks[];
  };
};
export type SelectedProductData = {
  productId: number;
  productName: string;
  productKind: string;
  ExpiryDays: number;
  type: '完' | '半';
  date: Date;
  data: Todo[];
  comment: string;
  piecesPerBox: number;
  // casesPerCart: number;
  stockDateLabel: string;
  labelOfAmount: string;
  stocks: Todo;
  estimatedStocks: Todo;
};
