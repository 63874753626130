import { FC } from 'react';
import { BigNumber } from 'bignumber.js';
import * as S from './PdfContent.styles';
import moment from 'moment';
import { Todo } from '@lib/common/type';
import { addComma } from '@lib/common/functions';

const HONORIFIC_LABEL_MAP: Record<string, JSX.Element> = {
  attention: <S.Honorific>御中</S.Honorific>,
  dear: <S.Honorific>様</S.Honorific>,
  no_value: <S.Honorific></S.Honorific>,
} as const;

type Props = {
  purchaseOrderInfo: Todo;
  manufactureInfo: Todo;
};

const PdfContent: FC<Props> = ({ purchaseOrderInfo, manufactureInfo }) => {
  if (!purchaseOrderInfo) return null;

  return (
    <S.PdfContainer>
      <S.Title>注文書</S.Title>
      <S.Summary>
        <S.LeftContent>
          <S.LeftContentUpper>
            <S.Destination>
              {purchaseOrderInfo?.supplierName}
              {HONORIFIC_LABEL_MAP[purchaseOrderInfo?.supplierHonorific]}
            </S.Destination>
            <S.InCharge>{purchaseOrderInfo?.supplierDescription}</S.InCharge>
          </S.LeftContentUpper>
          <S.LeftContentLower>
            <S.Row>
              <S.RowTitle>件名</S.RowTitle>
              <S.RowBody>{purchaseOrderInfo?.subject}</S.RowBody>
            </S.Row>
            <S.Row>
              <S.RowTitle>納期</S.RowTitle>
              <S.RowBody>{purchaseOrderInfo?.deliveryDate}</S.RowBody>
            </S.Row>
            <S.Row>
              <S.RowTitle>納入場所</S.RowTitle>
              <S.RowBody>{purchaseOrderInfo?.deliveryDestination}</S.RowBody>
            </S.Row>
            <S.Row>
              <S.RowTitle>支払い条件</S.RowTitle>
              <S.RowBody>{purchaseOrderInfo?.paymentTerm}</S.RowBody>
            </S.Row>
          </S.LeftContentLower>
        </S.LeftContent>
        <S.RightContent>
          <S.CompanyInfoAndSeal>
            <S.CompanyInfo>
              <S.CompanyName>
                {manufactureInfo?.manufactureName}
                {/* 株式会社Regnio */}
              </S.CompanyName>
              <S.ZipCode>
                〒{manufactureInfo?.manufactureZipCode}
                {/* 〒810-0023 */}
              </S.ZipCode>
              <S.AddressFirst>
                {manufactureInfo?.manufactureAddress1}
                {/* 福岡県福岡市中央区警固2-2-23 */}
              </S.AddressFirst>
              <S.AddressSecond>
                {manufactureInfo?.manufactureAddress2}
                {/* ウイングコート警固601 */}
              </S.AddressSecond>
              <S.Tel>
                TEL:
                {manufactureInfo?.manufacturePhoneNumber}
                {/* 090-4704-1033 */}
              </S.Tel>
            </S.CompanyInfo>
            <S.CompanySeal />
          </S.CompanyInfoAndSeal>
          <S.PurchaseOrderDate>
            発行日：
            {purchaseOrderInfo?.confirmationDate &&
              `${moment(purchaseOrderInfo?.confirmationDate).format(
                'YYYY年M月D日'
              )}`}
          </S.PurchaseOrderDate>
          <S.PurchaseOrderCode>
            注文書番号：{purchaseOrderInfo?.purchaseOrderNumber}
          </S.PurchaseOrderCode>
        </S.RightContent>
      </S.Summary>
      <S.TotalAmount>
        <S.TotalAmountTitle>合計金額</S.TotalAmountTitle>
        <S.PriceTotalValue>
          {addComma(purchaseOrderInfo?.total)}円
        </S.PriceTotalValue>
      </S.TotalAmount>
      <S.ProductList>
        <S.ProductListHeader>
          <S.ProductName>品目</S.ProductName>
          <S.ProductQuantity>数量</S.ProductQuantity>
          <S.ProductUnit>単位</S.ProductUnit>
          <S.ProductUnitPrice>単価</S.ProductUnitPrice>
          <S.ProductTotalPrice>金額</S.ProductTotalPrice>
        </S.ProductListHeader>
        <S.ProductListBody>
          {purchaseOrderInfo &&
            purchaseOrderInfo?.orderDetails?.map(
              (detail: Todo, index: number) => (
                <S.ProductListRow index={index} key={index}>
                  <S.ProductName>{detail.materialName}</S.ProductName>
                  <S.ProductQuantity>{detail.amount}</S.ProductQuantity>
                  <S.ProductUnit>{detail.unit}</S.ProductUnit>
                  <S.ProductUnitPrice>{detail.unitPrice}</S.ProductUnitPrice>
                  <S.ProductTotalPrice>
                    {addComma(
                      Number(
                        BigNumber(detail?.unitPrice * detail?.amount).dp(
                          0,
                          BigNumber.ROUND_DOWN
                        )
                      )
                    )}
                  </S.ProductTotalPrice>
                </S.ProductListRow>
              )
            )}
        </S.ProductListBody>
        <S.PriceList>
          <S.PriceListRow>
            <S.PriceListRowTitle>小計</S.PriceListRowTitle>
            <S.PriceListRowValue>
              {addComma(purchaseOrderInfo?.subtotal)}
            </S.PriceListRowValue>
          </S.PriceListRow>
          <S.PriceListRow>
            <S.PriceListRowTitle>消費税合計</S.PriceListRowTitle>
            <S.PriceListRowValue>
              {addComma(purchaseOrderInfo?.tax)}
            </S.PriceListRowValue>
          </S.PriceListRow>
          <S.PriceListRow>
            <S.PriceListRowTitle>合計</S.PriceListRowTitle>
            <S.PriceListRowValue>
              {addComma(purchaseOrderInfo?.total)}
            </S.PriceListRowValue>
          </S.PriceListRow>
        </S.PriceList>
      </S.ProductList>
      {purchaseOrderInfo?.comment && (
        <>
          <S.CommentLabel>備考</S.CommentLabel>
          <S.CommentBody>{purchaseOrderInfo?.comment}</S.CommentBody>
        </>
      )}
    </S.PdfContainer>
  );
};

export default PdfContent;
