import { useMutation } from 'react-query';
import humps from 'humps';
import { axiosInstance } from '@lib/pc/common/api/axiosConfig';
import { AxiosError } from 'axios';
import { ErrorResponse } from '@lib/common/type';

type InputParams = {
  name: string;
};

const useUpdateSemiProductMutation = (
  semiProductId: number,
  handleSuccess: () => void,
  setErrMsg: (errMsg: string) => void
) => {
  return useMutation(
    async (params: InputParams) => {
      return await axiosInstance().patch(
        `/api/v1/settings/semi_products/${semiProductId}`,
        humps.decamelizeKeys(params)
      );
    },
    {
      onSuccess: () => {
        handleSuccess?.();
      },
      onError: (error: AxiosError<ErrorResponse>) => {
        const message = error.response?.data.message || 'エラーが発生しました';
        setErrMsg(message);
      },
    }
  );
};

export default useUpdateSemiProductMutation;
