import { axiosInstance as mobileAxiosInstance } from '@lib/mobile/common/api/axiosConfig';
import { axiosInstance as pcAxiosInstance } from '@lib/pc/common/api/axiosConfig';
import { useQuery } from 'react-query';
import humps from 'humps';
import moment from 'moment';
import { Todo } from '@lib/common/type';

// 前回情報の取得
export const useDuplicateLatestStock = (
  materialId: number | string,
  storeroomId: number | string,
  date: Date,
  fromPc?: boolean
) => {
  const { data } = useQuery(
    ['duplicate_material_stocks', materialId, storeroomId, date],
    async () => {
      const axiosInstance = fromPc ? pcAxiosInstance : mobileAxiosInstance;
      const data = await axiosInstance().get(
        `/api/v1/material_stocks/${materialId}/storeroom/${storeroomId}/duplicate_latest?date=${moment(
          date
        ).format('YYYY-MM-DD')}`
      );

      return data;
    }
  );
  const duplicateLatestStock = humps.camelizeKeys(
    data?.data?.actual_materials
  ) as Todo[];

  return { duplicateLatestStock };
};

export default useDuplicateLatestStock;
