import { useMutation } from 'react-query';
import { axiosInstance } from '@lib/pc/common/api/axiosConfig';
import { Todo } from '@lib/common/type';

const useCancelMaterialOrderMutation = (
  detailId: number | null,
  handleUpdateSuccess: () => void,
  setErrMsg: (errMsg: string) => void
) => {
  return useMutation(
    async () => {
      return await axiosInstance().patch(
        `/api/v1/cancel_purchase_order/${detailId}`
      );
    },
    {
      onSuccess: () => {
        handleUpdateSuccess?.();
      },
      onError: (error: Todo) => {
        setErrMsg(error.response.data.message || 'エラーが発生しました');
      },
    }
  );
};

export default useCancelMaterialOrderMutation;
