import { useMutation } from 'react-query';
import humps from 'humps';
import { axiosInstance } from '@lib/pc/common/api/axiosConfig';
import type { TagNameInputParam } from '@lib/pc/settings/product_master/type';

// タグ名更新
const useUpdateCaseMutation = (id: number, handleSuccess: () => void) => {
  return useMutation(
    async (params: TagNameInputParam) => {
      return await axiosInstance().patch(
        `/api/v1/settings/cases/${id}`,
        humps.decamelizeKeys(params)
      );
    },
    {
      onSuccess: () => {
        handleSuccess();
      },
      onError: () => alert('エラーが発生しました'),
    }
  );
};

export default useUpdateCaseMutation;
