import { FC } from 'react';
import * as S from './CaseSelect.styles';
import type { Todo } from '@lib/common/type';
import type { Case } from '@lib/pc/settings/product_master_setting/type';
import ReactSelect from 'react-select';
import { NO_OPTION_JAPANESE } from '@lib/pc/common/constants';

type Props = {
  cases: Case[] | undefined;
  value: number | undefined;
  setSelectedCaseId?: (selectedCaseId: number) => void;
  noLabel?: boolean;
  display?: boolean;
  height?: string;
  width?: string;
  marginTop?: string;
  marginLeft?: string;
  selectMarginLeft?: string;
  onChange?: (selectedCaseId: number, index?: number) => void;
  disabled?: boolean;
};

const CaseSelect: FC<Props> = ({
  cases,
  value,
  setSelectedCaseId,
  noLabel,
  display,
  height,
  width,
  marginTop,
  marginLeft,
  selectMarginLeft,
  onChange,
  disabled,
}: Props) => {
  const handleChangeCase = (e: number) => {
    if (setSelectedCaseId) {
      setSelectedCaseId(e);
    }
  };

  const toReactSelectOptions = (options: { id: number; name: string }[]) =>
    options.map(({ id, name }) => ({
      value: id,
      label: name,
    }));

  const reactSelectOptions = cases && toReactSelectOptions(cases);
  const currentValue = reactSelectOptions?.find((o) => o.value === value);

  const styles = {
    control: (baseStyles: Todo) => ({
      ...baseStyles,
      width: width ? width : '',
      height: height ? height : '44px',
      borderRadius: '0.5rem',
      fontWeight: 400,
      textAlign: 'left',
      borderStyle: 'hidden',
      outline: '0.5px solid rgba(0,0,0,0.08)',
      backgroundColor: disabled ? '' : 'white',
      cursor: 'pointer',
      boxShadow: 'none',
      marginLeft: selectMarginLeft ? selectMarginLeft : '',
      '@media (max-width: 599px)': {
        width: '16rem',
      },
    }),
    menu: (baseStyles: Todo) => ({
      ...baseStyles,
      top: '37px',
      zIndex: 1002,
    }),
    option: (baseStyles: Todo) => ({
      ...baseStyles,
      textAlign: 'left',
      fontWeight: 400,
      fontSize: '15px',
    }),
    menuList: (baseStyles: Todo) => ({
      ...baseStyles,
      maxHeight: '200px',
    }),
  };

  return (
    <S.CaseWrapper
      width={width}
      display={display}
      marginTop={marginTop}
      marginLeft={marginLeft}
    >
      {noLabel === true ? '' : <label className="case-label">ケース種類</label>}

      <ReactSelect
        options={reactSelectOptions}
        value={currentValue ? currentValue : null}
        onChange={(option) => {
          option && handleChangeCase(option.value);
          option && onChange?.(option.value);
        }}
        styles={styles}
        isDisabled={disabled}
        isSearchable={false}
        components={{
          IndicatorSeparator: () => null,
        }}
        noOptionsMessage={() => NO_OPTION_JAPANESE}
        placeholder="ケース種類の選択"
      />
    </S.CaseWrapper>
  );
};

export default CaseSelect;
