import styled, { css } from 'styled-components';

export const Wrapper = styled.div`
  max-width: 100vw;
  max-height: 100vh;
  height: calc(100vh - 143px);
  overflow: scroll;
  position: relative;
  background: ${(props) => props.theme.palette.beige};
`;

export const ListWrapper = styled.div`
  max-width: 100vw;
  max-height: 100vh;
  height: calc(100vh - 165px);

  overflow: scroll;
  display: flex;
  position: relative;
  background: ${(props) => props.theme.palette.beige};
`;

/**
 *
 * リスト
 *
 */
export const ListHeader = styled.div`
  display: flex;
  align-items: center;

  box-shadow: 1px 3px 5px 0px rgba(0, 0, 0, 0.2);
`;

const highLightenHeadCell = css`
  color: ${(props) => props.theme.palette.white}!important;
  background: ${(props) => props.theme.palette.blue}!important;
  border-color: ${(props) => props.theme.palette.blue}!important;
`;

const highLightenCell = css`
  position: relative;
  border-right: 2px solid ${(props) => props.theme.palette.blue};
  border-left: 2px solid ${(props) => props.theme.palette.blue};
`;

const cornerArrow = css`
  ::before,
  ::after {
    content: '';
    position: absolute;
    top: 0;
    right: 0;
    border-color: transparent;
    border-style: solid;
  }
  ::before {
    border-width: 0.4rem;
  }
  ::after {
    border-width: 0.4rem;
    border-right-color: #c74141;
    border-top-color: #c74141;
  }
`;

export const ListHeadCell = styled.div<{
  highLighten: boolean;
  dayOfWeek?: string;
  index: number;
  numOfIndex: number;
}>`
  height: 52px;
  width: 80px;
  box-sizing: border-box;
  padding: 0.5rem 0;
  cursor: pointer;

  display: flex;
  justify-content: center;
  align-items: center;
  position: sticky;
  color: ${(props) =>
    props.dayOfWeek === '土' &&
    !props.highLighten &&
    props.index <= props.numOfIndex
      ? '#1046cf'
      : props.dayOfWeek === '日' &&
        !props.highLighten &&
        props.index <= props.numOfIndex
      ? '#c74141'
      : ''};

  /* 前年同月 */
  :last-child {
    position: sticky;
    top: 0;
    right: 0;
    border-left: 2px double ${(props) => props.theme.palette.sandGray};
    font-weight: 700;
    pointer-events: none;
  }

  /* 合計 */
  :nth-last-child(2) {
    position: sticky;
    top: 0;
    right: 80px;
    border-left: 2px double ${(props) => props.theme.palette.sandGray};
    font-weight: 700;
    pointer-events: none;
  }

  :nth-child(odd) {
    background: ${(props) => props.theme.palette.beige};
  }

  :nth-child(even) {
    background: ${(props) => props.theme.palette.white};
  }

  ${({ highLighten }) => (highLighten ? highLightenHeadCell : null)}

  color: ${(props) =>
    props.dayOfWeek === '土' && props.index <= props.numOfIndex
      ? '#1046cf'
      : props.dayOfWeek === '日' && props.index <= props.numOfIndex
      ? '#c74141'
      : ''};
`;

export const ListCell = styled.div<{
  highlighten: boolean;
}>`
  cursor: pointer;

  height: 50px;
  width: 80px;
  box-sizing: border-box;
  padding: 0.5rem 0;
  border-spacing: 0;
  display: flex;
  justify-content: center;
  align-items: center;

  ${({ highlighten }) => (highlighten ? highLightenCell : null)}
`;

export const ListCellContainer = styled.div<{
  highlighten: boolean;
  withCheckEnabled?: boolean;
  invalid?: boolean;
}>`
  position: relative;
  cursor: pointer;
  height: 50px;
  width: 80px;
  box-sizing: border-box;
  padding: 0.5rem 0;
  display: flex;
  justify-content: center;
  align-items: center;
  color: ${(props) => (props.withCheckEnabled ? '#C74141' : '')};
  font-weight: ${(props) => (props.withCheckEnabled ? 'bold' : '')};
  background-color: ${(props) =>
    props.withCheckEnabled ? '#FDDEDE!important' : ''};
  ${({ withCheckEnabled }) => (withCheckEnabled ? cornerArrow : null)};

  ${({ highlighten }) => (highlighten ? highLightenCell : null)};
  pointer-events: ${(props) => (props.invalid ? 'none' : '')};

  &.select-cell {
    border: 2px solid ${(props) => props.theme.palette.primary.main};
  }
`;

export const ListBody = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  z-index: 2;
  position: relative;
  height: max-content;
  -webkit-transition: transform 320ms ease-in-out;
  -moz-transition: transform 320ms ease-in-out;
  -ms-transition: transform 320ms ease-in-out;
  -o-transition: transform 320ms ease-in-out;
  transition: transform 320ms ease-in-out;
  &.move-to-left {
    -webkit-transform: translateX(-320px);
    -moz-transform: translateX(-320px);
    -ms-transform: translateX(-320px);
    -o-transform: translateX(-320px);
    transform: translateX(-320px);
  }

  & > div:nth-child(1) {
    height: 52px;
  }

  & > div:nth-child(odd) {
    background: ${(props) => props.theme.palette.paleYellow};
  }

  & > div:nth-child(even) {
    background: ${(props) => props.theme.palette.white};
  }
  & > div:not(:first-child):nth-child(even) > div:nth-child(odd) {
    background: ${(props) => props.theme.palette.beige};
  }
  & > div:not(:first-child):nth-child(odd) > div:nth-child(odd) {
    background: ${(props) => props.theme.palette.yellowGray};
  }
`;

export const ListRow = styled.div`
  display: flex;
  position: -webkit-sticky; /* for Safari */
  background: #fff;

  :first-child {
    position: -webkit-sticky; /* for Safari */
    position: sticky;
    top: -1px;
    z-index: 4;
    box-shadow: 1px 3px 5px 0px rgba(0, 0, 0, 0.2);
  }
`;

export const ListSidebar = styled.div`
  min-width: 270px;
  height: max-content;
  border-right: 1px solid ${(props) => props.theme.palette.yellowGray};
  background: #fff;
  background: ${(props) => props.theme.palette.beige};

  position: sticky;
  left: -1px;
  z-index: 5;

  & > div:first-child {
    background: ${(props) => props.theme.palette.paleYellow};

    position: -webkit-sticky; /* for Safari */
    position: sticky;
    top: -1px;
    padding: 1px;
    background: ${(props) => props.theme.palette.yellowGray};
    z-index: 1000;
  }
`;

export const ListLeftHeadCell = styled.div<{
  invalid?: boolean;
  isBlue?: boolean;
}>`
  cursor: pointer;
  height: 50px;
  min-width: 270px;
  border-bottom: 1px solid ${(props) => props.theme.palette.yellowGray};
  box-shadow: 4px 3px 5px 0px rgba(0, 0, 0, 0.05);
  position: -webkit-sticky; /* for Safari */
  -webkit-transform: translateZ(0);
  display: flex;
  justify-content: space-between;
  padding: 9px 1rem;
  gap: 0.25rem;

  display: flex;
  flex-direction: column;
  pointer-events: ${(props) => (props.invalid ? 'none' : '')};

  > span {
    font-size: 0.75rem;
    &.filter-product {
      width: 90%;
      padding-left: 30px;
      height: 32px;
      line-height: 32px;
      position: absolute;
      background: ${(props) => props.theme.palette.white};
      color: ${(props) =>
        props.isBlue ? props.theme.palette.blue : '#9ca3af'};
      border-radius: 0.5rem;
      cursor: pointer;

      > svg {
        position: absolute;
        top: 12%;
        left: 5%;
      }

      > div {
        padding-left: 15px;
        font-size: 16px;
      }
    }
  }

  //COMMENT: OKであれば後で書き方変えたほうがいい
  :first-child {
    background-color: #f5f2e6 !important;
    height: 51px;
    > div > input {
      cursor: pointer;
      height: 32px;
      padding: 0.5rem 40px 0.5rem;
    }
  }

  :nth-child(odd) {
    background: ${(props) => props.theme.palette.paleYellow};
  }
  :nth-child(even) {
    background: ${(props) => props.theme.palette.white};
  }
`;

export const ListLeftHeadName = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

export const ListLeftHeadMeta = styled.div`
  color: rgba(51, 51, 51, 0.5);
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-size: 0.75rem;
  > span {
    font-weight: 700;
  }
`;

export const CircularIconWrapper = styled.div`
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 10001;
  background-color: ${(props) => props.theme.palette.beige} !important;
`;

/**
 *
 * テーブル仮置き。仕様によっては戻ってくるかも
 *
 */
// export const Table = styled.table`
//   position: relative;
//   border-collapse: initial;

//   td,
//   th {
//     height: 44px;
//     padding: 0.25em;
//     vertical-align: middle;
//     border: 1px solid ${(props) => props.theme.palette.yellowGray};

//     :nth-child(2) {
//       border-left: none;
//     }
//   }

//   tr:nth-child(odd) td:nth-child(odd) {
//     background: ${(props) => props.theme.palette.yellowGray};
//     background-blend-mode: multiply;
//     :first-child {
//       background: ${(props) => props.theme.palette.paleYellow} !important;
//     }
//   }

//   tr {
//     border-bottom: 1px solid ${(props) => props.theme.palette.yellowGray};
//   }

//   tr:nth-child(odd) td:nth-child(even) {
//     background: ${(props) => props.theme.palette.paleYellow};
//     background-blend-mode: multiply;
//     :first-child {
//       background: #fff !important;
//     }
//   }

//   tr:nth-child(even) td:nth-child(odd) {
//     background: ${(props) => props.theme.palette.beige};
//     background-blend-mode: multiply;
//     :first-child {
//       background: #fff !important;
//     }
//   }

//   tr:nth-child(even) td:nth-child(even) {
//     background: ${(props) => props.theme.palette.white};
//   }

//   thead th:nth-child(odd) {
//     background: ${(props) => props.theme.palette.beige};
//   }

//   thead th {
//     position: -webkit-sticky; /* for Safari */
//     position: sticky;
//     top: 0;
//     z-index: 1000;
//     background: ${(props) => props.theme.palette.white};
//     color: ${(props) => props.theme.palette.baseBlack};
//     &.today {
//       color: ${(props) => props.theme.palette.white};
//       background: ${(props) => props.theme.palette.blue};
//       border-color: ${(props) => props.theme.palette.blue};
//     }

//     :first-child {
//       z-index: 1001 !important;
//     }

//     //シャドウ後でクラス名に変える
//     :not(:first-child) {
//       box-shadow: 1px 3px 5px 0px rgba(0, 0, 0, 0.2);
//     }
//   }

//   tbody td {
//     &.today {
//       position: relative;

//       ::before {
//         border-right: 1px solid ${(props) => props.theme.palette.blue};
//         content: '';
//         height: 105%;
//         top: 0px;
//         right: -1px;
//         position: absolute;
//       }
//       ::after {
//         border-left: 1px solid ${(props) => props.theme.palette.blue};
//         content: '';
//         height: 105%;
//         top: 0px;
//         left: 0;
//         position: absolute;
//       }
//     }
//   }
//   tr:last-child {
//     td.today {
//       border-bottom: 1px solid ${(props) => props.theme.palette.blue};
//     }
//   }
//   thead th:first-child {
//     left: 0;
//     z-index: 1;
//     background: ${(props) => props.theme.palette.white};
//     color: ${(props) => props.theme.palette.baseBlack};
//     position: -webkit-sticky; /* for Safari */
//     position: sticky;
//     left: 0;
//     border-right: 1px solid ${(props) => props.theme.palette.yellowGray};
//     background: #fff;
//     z-index: 1000;
//     box-shadow: 4px 3px 5px 0px rgba(0, 0, 0, 0.2);
//   }
//   tbody tr th {
//     position: -webkit-sticky; /* for Safari */
//     position: sticky;
//     left: 0;
//     border-right: 1px solid ${(props) => props.theme.palette.yellowGray};
//     background: #fff;
//     z-index: 1000;
//     :nth-child(odd) {
//       background: ${(props) => props.theme.palette.paleYellow};
//     }

//     //シャドウ後でクラス名に変える
//     box-shadow: 4px 3px 5px 0px rgba(0, 0, 0, 0.2);
//   }
// `;
