import { Header } from '@components/layout/mobile/Header';

type Props = {
  children: React.ReactNode;
};

export const WithHeader = ({ children }: Props) => {
  return (
    <div>
      <Header />
      {children}
    </div>
  );
};
