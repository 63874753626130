import React, { useEffect, useRef, useState } from 'react';
import * as S from './CaptureOfProduct.styles';
import { Camera, CameraType } from 'react-camera-pro';
import Resizer from 'react-image-file-resizer';
import scanSound from '@assets/sounds/sound.mp3';
import Close from '@assets/icons/mobile/close';
import { ReactComponent as DeleteIcon } from '@assets/icons/delete.svg';
import { useHistory, useLocation } from 'react-router-dom';
import CloseConfirmModal from '@components/modules/mobile/capture_of_product/CloseConfirmModal/CloseConfirmModal';
import DeleteConfirmModal from '@components/modules/mobile/capture_of_product/DeleteConfirmModal/DeleteConfirmModal';
import ErrorMsgPopUp from '@components/modules/common/ErrorMsgPopUp/ErrorMsgPopUp';
import ScrollToTop from '@components/modules/common/ScrollToTop/ScrollToTop';
import SendConfirmModal from '@components/modules/mobile/capture_of_product/SendConfirmModal/SendConfirmModal';
import { Todo } from '@lib/common/type';
import { ProductsPhotographState } from '@lib/mobile/products_photograph/type';
import useUpdateProductsCaptureMutation from '@lib/mobile/capture_of_product/useUpdateProductsCaptureMutation';
import useDeleteProductsCaptureMutation from '@lib/mobile/capture_of_product/useDeleteProductsCaptureMutation';

export const CaptureOfProductRoot = () => {
  const camera = useRef<CameraType>(null);
  const { state } = useLocation<ProductsPhotographState>();
  const history = useHistory();
  // バックエンドからの画像
  const [imgSrcFromDb] = useState<Todo>([
    state.stateImages[0],
    state.stateImages[1],
    state.stateImages[2],
    state.stateImages[3],
  ]);
  // 撮影したもの
  const [imgSrc, setImgSrc] = useState<Todo>([null, null, null, null]);
  const [delConfirmOpen, setDelConfirmOpen] = useState(false);
  // 640*640にresizeされたimage
  const [resizedImgSrc, setResizedImgSrc] = useState<Todo>([
    null,
    null,
    null,
    null,
  ]);
  const [imgIndex, setImgIndex] = useState(0);
  // trueで撮影エリアにpreview(リアルタイム画像)が表示される
  const [previewMode, setPreviewMode] = useState(false);
  // 再撮影モードかどうか
  const [reCapture, setReCapture] = useState(false);
  // 再撮影ボタン押下かしたかどうか
  const [reCaptureButton, setReCaptureButton] = useState(false);

  // 保存ボタン表示
  const [showSaveButton, setShowSaveButton] = useState(false);
  const [closeMode, setCloseMode] = useState(false);
  const [, setSendMode] = useState(false);
  const [sendConfirm, setSendConfirm] = useState(false);
  const [sending, setSending] = useState(false);
  const [errMsg, setErrMsg] = useState('');
  const [sent] = useState(false);
  const [audio] = useState(new Audio(scanSound));

  const resizeFile = (file: Blob) =>
    new Promise((resolve) => {
      Resizer.imageFileResizer(
        file,
        640,
        640,
        'JPEG',
        100,
        0,
        (uri) => {
          resolve(uri);
        },
        'base64'
      );
    });

  const capture = React.useCallback(
    async (index) => {
      const tmpImgSrc = [...imgSrc];
      const tempResizedImgSrc = [...resizedImgSrc];
      let cameraImageSrc;
      if (camera.current) {
        cameraImageSrc = await camera.current.takePhoto();
      }

      // resizeFile------------
      if (cameraImageSrc) {
        const blob = await dataURItoBlob(cameraImageSrc);
        const resized = (await resizeFile(blob)) as string;
        tempResizedImgSrc[index] = resized;
        setResizedImgSrc(tempResizedImgSrc);
      }

      tmpImgSrc[index] = cameraImageSrc;
      // 画面上部4枚の画像へ設定
      setImgSrc(tmpImgSrc);
      audio.load();
      audio.play();

      if (index === 3) {
        // 4枚目の写真撮影時、1枚めが撮影されていなかったらindexを1枚目に設定
        setPreviewMode(imgSrc[0] !== null);
        setImgIndex(0);
      } else {
        setPreviewMode(imgSrc[index + 1] !== null);
        setImgIndex(index + 1);
      }
    },
    [setImgSrc, imgIndex, setImgIndex]
  );

  // どの角度のものを選択するか
  const select = React.useCallback(
    (index) => {
      if (index === 4) {
        setPreviewMode(imgSrc[0] !== null || imgSrcFromDb[0] !== null);
        setImgIndex(0);
      } else {
        setPreviewMode(imgSrc[index] !== null || imgSrcFromDb[index] !== null);
        setImgIndex(index);
      }
    },
    [imgIndex, setImgIndex, previewMode, setPreviewMode]
  );

  // 再撮影
  const clear = React.useCallback(
    (index) => {
      const tmpImgSrc = [...imgSrc];
      tmpImgSrc[index] = null;
      setImgSrc(tmpImgSrc);
      setPreviewMode(false);
      setReCapture(false);
      setReCaptureButton(true);
    },
    [imgSrc, setImgSrc, previewMode, setPreviewMode]
  );

  // 初期表示時
  useEffect(() => {
    setPreviewMode(imgSrc[0] !== null);
    if (imgSrcFromDb[0]?.imageUrl !== null && imgSrcFromDb[0] !== undefined) {
      setReCapture(true);
      setPreviewMode(true);
    } else {
      setPreviewMode(false);
    }
  }, []);

  // 保存ボタン表示、非表示判定
  useEffect(() => {
    if (
      resizedImgSrc[0]?.split(',')[1] !== undefined &&
      resizedImgSrc[1]?.split(',')[1] !== undefined &&
      resizedImgSrc[2]?.split(',')[1] !== undefined &&
      resizedImgSrc[3]?.split(',')[1] !== undefined
    ) {
      setShowSaveButton(true);
    } else if (reCaptureButton) {
      setShowSaveButton(true);
    }
  }, [resizedImgSrc, reCaptureButton]);

  // blobへの変換
  const dataURItoBlob = (dataURI: string) => {
    // convert base64 to raw binary data held in a string
    // doesn't handle URLEncoded DataURIs - see SO answer #6850276 for code that does this
    const byteString = atob(dataURI.split(',')[1]);

    // separate out the mime component
    const mimeString = dataURI.split(',')[0].split(':')[1].split(';')[0];

    // write the bytes of the string to an ArrayBuffer
    const ab = new ArrayBuffer(byteString.length);
    const ia = new Uint8Array(ab);
    for (let i = 0; i < byteString.length; i++) {
      ia[i] = byteString.charCodeAt(i);
    }
    return new Blob([ab], { type: mimeString });
  };

  const handleSuccessUpdate = () => {
    history.push(`/mobile/products_photograph_list/${state.stateCameraId}`, {
      ...state,
      stateConfirmMsg: `「${state.stateProductName}」の写真を保存しました`,
    });
  };

  const updateProductsCaptureMutation = useUpdateProductsCaptureMutation(
    state.stateProductId,
    state.stateCameraId,
    handleSuccessUpdate,
    setErrMsg
  );

  const send = async () => {
    setSendConfirm(false);
    setSending(true);

    updateProductsCaptureMutation.mutate({
      image1:
        resizedImgSrc[0]?.split(',')[1] !== undefined
          ? `data:image/jpeg;base64,${resizedImgSrc[0]?.split(',')[1]}`
          : null,
      image2:
        resizedImgSrc[1]?.split(',')[1] !== undefined
          ? `data:image/jpeg;base64,${resizedImgSrc[1]?.split(',')[1]}`
          : null,
      image3:
        resizedImgSrc[2]?.split(',')[1] !== undefined
          ? `data:image/jpeg;base64,${resizedImgSrc[2]?.split(',')[1]}`
          : null,
      image4:
        resizedImgSrc[3]?.split(',')[1] !== undefined
          ? `data:image/jpeg;base64,${resizedImgSrc[3]?.split(',')[1]}`
          : null,
    });
  };

  const Cam = () => (
    <Camera ref={camera} errorMessages={{}} facingMode="environment" />
  );

  const goBack = () => {
    history.push({
      pathname: `/mobile/products_photograph_list/${state.stateCameraId}`,
      state,
    });
  };

  const handleSuccessDelete = () => {
    history.push(`/mobile/products_photograph_list/${state.stateCameraId}`, {
      ...state,
      stateConfirmMsg: `「${state.stateProductName}」の写真を削除しました`,
    });
  };

  const deleteProductsCaptureMutation = useDeleteProductsCaptureMutation(
    state.stateProductId,
    state.stateCameraId,
    handleSuccessDelete,
    setErrMsg
  );

  // 削除ボタンでレコードを削除する
  const handleDelete = () => {
    deleteProductsCaptureMutation.mutate();
  };

  return (
    <>
      <ScrollToTop />
      <S.OuterBody>
        <S.HeaderContainer>
          <S.CloseButton onClick={() => setCloseMode(true)}>
            <Close isWhite={true} />
          </S.CloseButton>
          <S.ProductName>{state.stateProductName}</S.ProductName>
          {/* 削除ボタン */}
          {imgSrcFromDb[0]?.imageUrl && (
            <S.DelButton
              onClick={() => {
                setDelConfirmOpen(true);
                setSendMode(true);
              }}
            >
              <DeleteIcon />
            </S.DelButton>
          )}
        </S.HeaderContainer>
        <S.ThumbnailContainer>
          <S.PreviewThumbnail1
            id="image1"
            src={
              imgSrc[0] !== null
                ? imgSrc[0]
                : imgSrcFromDb[0]?.imageUrl
                ? imgSrcFromDb[0].imageUrl
                : '/front.png'
            }
            imgIndex={imgIndex}
            onClick={() => select(0)}
          />
          <S.PreviewThumbnail2
            id="image2"
            src={
              imgSrc[1]
                ? imgSrc[1]
                : imgSrcFromDb[1]?.imageUrl
                ? imgSrcFromDb[1].imageUrl
                : '/left.png'
            }
            imgIndex={imgIndex}
            onClick={() => select(1)}
          />
          <S.PreviewThumbnail3
            id="image3"
            src={
              imgSrc[2]
                ? imgSrc[2]
                : imgSrcFromDb[2]?.imageUrl
                ? imgSrcFromDb[2].imageUrl
                : '/right.png'
            }
            imgIndex={imgIndex}
            onClick={() => select(2)}
          />
          <S.PreviewThumbnail4
            id="image4"
            src={
              imgSrc[3]
                ? imgSrc[3]
                : imgSrcFromDb[3]?.imageUrl
                ? imgSrcFromDb[3].imageUrl
                : '/upper.png'
            }
            imgIndex={imgIndex}
            onClick={() => select(3)}
          />
        </S.ThumbnailContainer>
        <S.Sentence1 previewMode={previewMode} imgIndex={imgIndex}>
          正面-高さ45°
        </S.Sentence1>
        <S.Sentence2 previewMode={previewMode} imgIndex={imgIndex}>
          左正面-高さ45°
        </S.Sentence2>
        <S.Sentence3 previewMode={previewMode} imgIndex={imgIndex}>
          右正面-高さ45°
        </S.Sentence3>
        <S.Sentence4 previewMode={previewMode} imgIndex={imgIndex}>
          真上
        </S.Sentence4>
        <S.CanvasContainer>
          {/* リアルタイム画像 previewMode: trueでhidden*/}
          <S.BlurWrapper previewMode={previewMode} className="blurCanvasRef">
            <Cam />
          </S.BlurWrapper>
          <S.CameraWrapper id="mainCanvas" previewMode={previewMode}>
            <Cam />
          </S.CameraWrapper>
          {/* 撮影済み画像 */}
          <S.ImageWrapper
            src={
              imgSrc[imgIndex]
                ? imgSrc[imgIndex]
                : imgSrcFromDb[imgIndex]?.imageUrl
            }
            previewMode={previewMode}
          />
        </S.CanvasContainer>
        <S.CircleButtonContainer>
          {/* 撮影ボタン */}
          {!reCapture && (
            <>
              <S.Circle previewMode={previewMode} />
              <S.CircleButton
                id="circle-button"
                previewMode={previewMode}
                onClick={() => capture(imgIndex)}
              />
            </>
          )}
          {/* 再撮影 */}
          <S.RecaptureButton
            onClick={() => clear(imgIndex)}
            previewMode={previewMode}
          >
            再撮影
          </S.RecaptureButton>
          <S.YesButton
            onClick={() => select(imgIndex + 1)}
            previewMode={previewMode}
          >
            OK
          </S.YesButton>
        </S.CircleButtonContainer>
      </S.OuterBody>
      {showSaveButton && (
        <S.Footer>
          <S.SubmitButton
            onClick={() => {
              setSendMode(true), setSendConfirm(true);
            }}
          >
            保存
          </S.SubmitButton>
        </S.Footer>
      )}
      <CloseConfirmModal
        closeMode={closeMode}
        setCloseMode={setCloseMode}
        goBack={goBack}
      />
      <SendConfirmModal
        sendConfirm={sendConfirm}
        setSendConfirm={setSendConfirm}
        send={send}
        sending={sending}
        sent={sent}
        goBack={goBack}
      />
      {delConfirmOpen && (
        <DeleteConfirmModal
          delConfirmOpen={delConfirmOpen}
          setDelConfirmOpen={setDelConfirmOpen}
          handleClickDelete={handleDelete}
        />
      )}
      <ErrorMsgPopUp
        errMsg={errMsg}
        handleClose={() => setErrMsg('')}
        zIndex={1004}
      />
    </>
  );
};
