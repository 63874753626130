import { useState } from 'react';
import { Redirect, useLocation } from 'react-router-dom';
import { Button } from '@components/elements/buttons/Button';
import { InputWithLabel } from '@components/elements/inputs/InputWithLabel';
import { Heading } from '@components/elements/typographies/Heading';
import * as S from './PCLoginPage.styles';
import useLogin from '@lib/pc/common/hooks/useLogin';
import { PCPath } from '@config/path';

const PCLoginPage = () => {
  const location = useLocation();
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [redirectToReferrer, setRedirectToReferrer] = useState(false);
  const state = location.state as { from: string } | null;
  const [handleLogin] = useLogin(email, password, () =>
    setRedirectToReferrer(true)
  );

  // TODO:PCでログイン後最初に表示させるページの設定箇所（ここは全Userがdashboardになる）
  if (redirectToReferrer) {
    // スタッカー
    // return <Redirect to={state?.from || `/pc${PCPath.stacker}`} />;
    // 在庫管理
    return <Redirect to={state?.from || `/pc${PCPath.stockStatus}`} />;
    // 生産計画
    // return <Redirect to={state?.from || `/pc${PCPath.productionSchedule}`} />;
  }

  return (
    <S.Wrapper>
      <S.Container>
        <Heading text="Regnio Factory" align="center" fw="lg" fs={'32'} />
        <S.InputContainer>
          <InputWithLabel
            label="メールアドレス"
            placeholder="メールアドレス"
            type="text"
            value={email}
            onChange={(event) => setEmail(event.target.value)}
          />
          <InputWithLabel
            label="パスワード"
            placeholder="パスワード"
            type="password"
            value={password}
            onChange={(event) => setPassword(event.target.value)}
          />
        </S.InputContainer>

        <div>
          <Button onClick={handleLogin} padding="lg">
            ログイン
          </Button>
        </div>
      </S.Container>
    </S.Wrapper>
  );
};

export default PCLoginPage;
