import { axiosInstance } from '@lib/pc/common/api/axiosConfig';
import { useQuery } from 'react-query';
import humps from 'humps';
import { Todo } from '@lib/common/type';

const useMaterial = (materialId: string) => {
  const { data, refetch } = useQuery(['material', materialId], async () => {
    const data = await axiosInstance().get(
      `/api/v1/settings/material/${materialId}`
    );
    return data;
  });
  const material = humps.camelizeKeys(data?.data) as Todo;

  return { material, refetch };
};

export default useMaterial;
