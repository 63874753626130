import { FC, useEffect, useRef } from 'react';
import { isIPad13 } from 'react-device-detect';
import * as S from './InputComponent.styles';
import { ReactComponent as DeleteIcon } from '@assets/icons/delete.svg';
import type { Todo } from '@lib/common/type';

type Props = {
  index: number;
  labelOfAmount: string;
  labelOfUnit: string;
  newExpirationDate: Todo;
  onChangePieceAmount: (newPieceAmount: string) => void;
  onChangeUnitAmount: (newUnitAmount: string) => void;
  onChangeCaseAmount: (newCaseAmount: string) => void;
  onChangeNewDate: (newExpirationDate: Todo) => void;
  inputAmountParams: Todo;
  handleRemoveField: (index: number, inputAmountParams: Todo) => void;
  piecesPerBox: number;
  piecesPerUnit: number | null;
};

const InputComponent: FC<Props> = ({
  index,
  labelOfAmount,
  labelOfUnit,
  newExpirationDate,
  onChangePieceAmount,
  onChangeUnitAmount,
  onChangeCaseAmount,
  onChangeNewDate,
  inputAmountParams,
  handleRemoveField,
  piecesPerBox,
  piecesPerUnit,
}: Props) => {
  const bestBeforeDateRef = useRef<HTMLInputElement>(null);

  const clearDate = () => {
    // iPadOSでクリアボタンを効かせるためのコード
    setTimeout(() => {
      if (bestBeforeDateRef.current)
        bestBeforeDateRef.current.defaultValue = '';
    }, 100);
  };

  // iPadOS、初期表示時クリアボタンを効かせるためのコード
  useEffect(() => {
    setTimeout(() => {
      if (bestBeforeDateRef.current)
        bestBeforeDateRef.current.defaultValue = '';
    }, 100);
  }, []);

  return (
    <S.DContainer>
      <S.ExpirationDate className="expiration-date">
        <S.DateWrapper>
          <S.ExpirationDateLabel htmlFor={`new-production-date-${index}`}>
            使用期限
          </S.ExpirationDateLabel>
        </S.DateWrapper>
        <S.InputWrapper>
          <S.Input
            ref={bestBeforeDateRef}
            type="date"
            name="newDate"
            id={`new-production-date-${index}`}
            value={newExpirationDate ? newExpirationDate : ''}
            onChange={(event) => {
              onChangeNewDate?.(event.target.value);
              clearDate();
            }}
            pattern="\d{4}-\d{2}-\d{2}"
            autoComplete="off"
            max="9999-12-31"
          />
        </S.InputWrapper>
      </S.ExpirationDate>
      <S.DeleteButton
        className="del-button"
        onClick={() => {
          if (!isIPad13) {
            handleRemoveField(index, inputAmountParams);
          }
        }}
        onTouchStart={() => {
          if (isIPad13) {
            handleRemoveField(index, inputAmountParams);
          }
        }}
      >
        <S.DeleteIconWrapper>
          <DeleteIcon />
        </S.DeleteIconWrapper>
      </S.DeleteButton>

      <S.MaterialsAmountWrapper className="materials-amount">
        {/* ケース */}
        <S.DInputContainer>
          <label htmlFor={`new-stock-case-${index}`}>ケース</label>
          <input
            id={`new-stock-case-${index}`}
            value={
              inputAmountParams[index]?.case
                ? inputAmountParams[index]?.case
                : ''
            }
            onChange={(event) => onChangeCaseAmount?.(event.target.value)}
            type="text"
            pattern="^[1-9][0-9]*$"
            inputMode="numeric"
            autoComplete="off"
            disabled={piecesPerBox === 1}
          />
          <span>
            ×{piecesPerBox}
            {labelOfUnit !== 'なし'
              ? labelOfUnit
              : labelOfAmount
              ? labelOfAmount
              : '個'}
          </span>
        </S.DInputContainer>
        <S.DSymbol>+</S.DSymbol>
        {/* 単位個数：本、袋等、バラまとめ単位 */}
        {labelOfUnit !== 'なし' && (
          <>
            <S.DInputContainer>
              <label htmlFor={`new-stock-unit-${index}`}>{labelOfUnit}</label>
              <input
                id={`new-stock-unit-${index}`}
                value={
                  inputAmountParams[index]?.unit
                    ? inputAmountParams[index]?.unit
                    : ''
                }
                onChange={(event) => onChangeUnitAmount?.(event.target.value)}
                type="text"
                pattern="^[1-9][0-9]*$"
                inputMode="numeric"
                autoComplete="off"
              />
              <span>
                ×{piecesPerUnit}
                {labelOfAmount ? labelOfAmount : ''}
              </span>
            </S.DInputContainer>
            <S.DSymbol>+</S.DSymbol>
          </>
        )}
        {/* バラ個数 */}
        <S.DInputContainer>
          <label htmlFor={`new-stock-piece-${index}`}>
            {labelOfAmount ? labelOfAmount : '個'}
          </label>
          <input
            id={`new-stock-piece-${index}`}
            value={
              inputAmountParams[index]?.piece
                ? inputAmountParams[index]?.piece
                : ''
            }
            onChange={(event) => onChangePieceAmount?.(event.target.value)}
            type="text"
            pattern="^\d*\.?\d{0,2}$"
            inputMode="decimal"
            autoComplete="off"
          />
        </S.DInputContainer>
        <S.DSymbol>=</S.DSymbol>
        <S.DInputContainer piece={true}>
          <label htmlFor="new-stock-total">合計</label>
          <input
            id="new-stock-total"
            value={
              (!inputAmountParams[index]?.case &&
                !inputAmountParams[index]?.unit &&
                !inputAmountParams[index]?.piece) ||
              !inputAmountParams[index]?.total
                ? ''
                : Number(inputAmountParams[index]?.total).toLocaleString()
            }
            disabled
          />
        </S.DInputContainer>
      </S.MaterialsAmountWrapper>
    </S.DContainer>
  );
};

export default InputComponent;
