import { useState } from 'react';
import * as S from './StoreroomInfo.styles';
import { ReactComponent as PlusBlueIcon } from '@assets/icons/mobile/plus_blue.svg';
import ChevronLeft from '@assets/icons/chevron_left';
import { Button } from '@components/elements/buttons/Button';
import ErrorMsgPopUp from '@components/modules/common/ErrorMsgPopUp/ErrorMsgPopUp';
import Overlay from '@components/elements/backdrops/Overlay/Overlay';
import StoreRoomModal from '@components/modules/pc/settings/common/StoreRoomModal/StoreRoomModal';
import { StoreroomWithDisplayOrder } from '@lib/pc/settings/product_master_edit/type';
import { ShippingGroupUpdateData } from '@lib/pc/settings/shipping_group_setting/type';
import useUpdateShippingGroupAndStorerooms from '@lib/pc/settings/shipping_group_master_edit/useUpdateShippingGroupAndStorerooms';

type Props = {
  newData: ShippingGroupUpdateData;
  shippingGroupsStorerooms: StoreroomWithDisplayOrder[];
  shippingGroupsStoreroomsRefetch: () => void;
  updating: boolean;
  setUpdating: (updating: boolean) => void;
  onClickGoBack: () => void;
  isChanged: boolean;
  setIsChanged: (isChanged: boolean) => void;
  setUpdatePopUp: (updatePopUp: boolean) => void;
};

const StoreroomInfo = ({
  newData,
  shippingGroupsStorerooms,
  shippingGroupsStoreroomsRefetch,
  updating,
  setUpdating,
  onClickGoBack,
  isChanged,
  setIsChanged,
  setUpdatePopUp,
}: Props) => {
  const [storeRoomModalActive, setStoreRoomModalActive] = useState(false);
  const [errMsg, setErrMsg] = useState('');

  // 更新に使用
  const [
    newShippingGroupsAndStoreroomsArray,
    setNewShippingGroupsAndStoreroomsArray,
  ] = useState<StoreroomWithDisplayOrder[]>(
    shippingGroupsStorerooms ? shippingGroupsStorerooms : []
  );

  const handleUpdateSuccess = () => {
    shippingGroupsStoreroomsRefetch();
    setUpdatePopUp(true);
    setIsChanged(false);
    setUpdating(false);
  };

  // 出荷先グループと在庫室テーブル
  const updateShippingGroupAndStorerooms = useUpdateShippingGroupAndStorerooms(
    newData?.id,
    handleUpdateSuccess,
    setErrMsg
  );

  const newShippingGroupsAndStoreroomsIds = newShippingGroupsAndStoreroomsArray
    ? newShippingGroupsAndStoreroomsArray.map((item) => item['id'])
    : [];

  // ***********************
  // [保存ボタン]押下、更新処理
  // ***********************
  const handleSubmit = () => {
    setUpdating(true);

    updateShippingGroupAndStorerooms.mutate({
      ids: newShippingGroupsAndStoreroomsIds,
    });
  };

  return (
    <>
      <S.Wrapper>
        <S.GoBackWrapper onClick={onClickGoBack}>
          <ChevronLeft isBlue={true} />
          <span>一覧画面へ戻る</span>
        </S.GoBackWrapper>
        <S.GroupCodeWrapper>
          <span>出荷先グループコード</span> {newData?.code}
        </S.GroupCodeWrapper>
        <S.NameWrapper>
          <span>出荷先グループ名</span> {newData?.name}
        </S.NameWrapper>

        <S.EditContents>
          {/* 在庫室 */}
          <S.StoreroomArrayTable>
            <thead>
              <tr>
                <S.StoreroomTitleTh>
                  出荷先グループ対象在庫室
                </S.StoreroomTitleTh>
              </tr>
            </thead>
            <tbody>
              {newShippingGroupsAndStoreroomsArray &&
                newShippingGroupsAndStoreroomsArray.map(
                  (sr: StoreroomWithDisplayOrder, index: number) => {
                    return (
                      <S.SelectedSToreroomWrapperTr key={index}>
                        <S.StoreroomNameTd>{sr.name}</S.StoreroomNameTd>
                      </S.SelectedSToreroomWrapperTr>
                    );
                  }
                )}
            </tbody>
          </S.StoreroomArrayTable>
          <div
            className="add-button"
            onClick={() => setStoreRoomModalActive(true)}
          >
            <S.PlusIcon>
              <PlusBlueIcon />
            </S.PlusIcon>
            <span className="label">在庫室を選択</span>
          </div>
        </S.EditContents>
      </S.Wrapper>
      {storeRoomModalActive && (
        <StoreRoomModal
          newProductAndStoreroomsArray={newShippingGroupsAndStoreroomsArray}
          setNewProductAndStoreroomsArray={
            setNewShippingGroupsAndStoreroomsArray
          }
          storeRoomModalActive={storeRoomModalActive}
          handleClose={() => setStoreRoomModalActive(false)}
          setIsChanged={setIsChanged}
        />
      )}
      <S.ButtonContainer>
        {isChanged ? (
          <Button
            children={updating ? '保存中...' : '保存'}
            onClick={handleSubmit}
          />
        ) : (
          <Button disabled children={'保存'} />
        )}
      </S.ButtonContainer>
      <ErrorMsgPopUp
        errMsg={errMsg}
        handleClose={() => {
          setErrMsg('');
          setUpdating(false);
        }}
        fromPc={true}
        zIndex={9999}
      />
      {errMsg && (
        <Overlay
          dark
          handleClick={() => {
            setErrMsg('');
            setUpdating(false);
          }}
        />
      )}
    </>
  );
};

export default StoreroomInfo;
