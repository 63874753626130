import { useState } from 'react';
import * as S from './MaterialsForm.styles';
import { ReactComponent as DeleteIcon } from '@assets/icons/delete.svg';
import { Button } from '@components/elements/buttons/Button';
import InputComponent from '@components/modules/pc/materials_inventory/InputComponent/InputComponent';
import { selectedItem, InputParams } from '@lib/pc/materials_inventory/type';
import type { Todo } from '@lib/common/type';

type Props = {
  material: selectedItem;
  inputParams: InputParams;
  setInputParams: (InputParams: InputParams) => void;
  inputAmountParams: Todo;
  setInputAmountParams: (inputAmountParams: Todo) => void;
  newCalcTotal: (
    index: number,
    value: string | number,
    valueKind: 'piece' | 'unit' | 'case'
  ) => void;
  changeNewDate: (index: number, value: Todo) => void;
  handleDelete: () => void;
  deleteId: number | null;
  setDeleteId: (deleteId: number | null) => void;
  setIsChanged: (isChanged: boolean) => void;
  comment: string;
  setComment: (comment: string) => void;
};

const MaterialsForm = ({
  material,
  inputParams,
  setInputParams,
  inputAmountParams,
  setInputAmountParams,
  newCalcTotal,
  changeNewDate,
  handleDelete,
  deleteId,
  setDeleteId,
  setIsChanged,
  comment,
  setComment,
}: Props) => {
  const handleComment = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
    setInputParams({ ...inputParams, comment: e.target.value });
    setComment(e.target.value);
    setIsChanged(true);
  };

  // ケースと単位の値の更新
  const handleNewOnChange = (
    i: number,
    value: string,
    valueKind: 'unit' | 'case'
  ) => {
    if (value.match(/^[0-9]*$/)) {
      newCalcTotal(i, Number(value), valueKind);
      setIsChanged(true);
    }
  };

  // バラの値の更新（小数点以下１桁まで入力可能）
  const handleNewOnChangePieceAmount = (
    i: number,
    value: string,
    valueKind: 'piece'
  ) => {
    if (value.match(/^\d*\.?\d{0,2}$/)) {
      newCalcTotal(i, value, valueKind);
      setIsChanged(true);
    }
  };

  // 賞味期限日の更新
  const handleChangeNewDate = (i: number, value: Date) => {
    changeNewDate(i, value);
    setIsChanged(true);
  };

  const [deleteIndex, setDeleteIndex] = useState(0);

  const onClickDelete = () => {
    handleDelete();
    const amountDetail = inputAmountParams.filter(
      (item: Todo) => item !== inputAmountParams[deleteIndex]
    );
    setInputAmountParams(amountDetail);
    setDeleteId(null);
  };

  const handleDeleteButton = (i: number, inputAmountParams: Todo) => {
    if (inputAmountParams[i].id) {
      // 登録済みデータの場合
      setDeleteId(inputAmountParams[i].id);
      setDeleteIndex(i);
      setIsChanged(true);
    } else {
      // 未登録のデータの場合、増やしたinputコンポーネントの削除
      const amountDetail = inputAmountParams.filter(
        (item: Todo) => item !== inputAmountParams[i]
      );
      setInputAmountParams(amountDetail);
    }
  };

  return (
    <S.Wrapper>
      <S.InputContainer>
        {inputAmountParams &&
          Object.values(inputAmountParams)
            .map((item: Todo, i: number) => {
              if (!material) return;
              return (
                <InputComponent
                  key={`item-${i}`}
                  index={i}
                  labelOfAmount={material.labelOfAmount}
                  labelOfUnit={material.labelOfUnit}
                  newExpirationDate={item.expirationDate}
                  onChangePieceAmount={(value) =>
                    handleNewOnChangePieceAmount(i, value, 'piece')
                  }
                  onChangeUnitAmount={(value) =>
                    handleNewOnChange(i, value, 'unit')
                  }
                  onChangeCaseAmount={(value) =>
                    handleNewOnChange(i, value, 'case')
                  }
                  onChangeNewDate={(value) => handleChangeNewDate(i, value)}
                  inputAmountParams={inputAmountParams}
                  // setInputAmountParams={setInputAmountParams}
                  handleRemoveField={handleDeleteButton}
                  piecesPerBox={material.piecesPerBox}
                  piecesPerUnit={material.piecesPerUnit}
                />
              );
            })
            .reverse()}
      </S.InputContainer>
      <S.NoteAmountContainer>
        <S.NoteAmountLabel>備考</S.NoteAmountLabel>
        <S.NoteAmountInput
          placeholder=""
          value={inputAmountParams.length > 0 && comment ? comment : ''}
          onChange={handleComment}
        />
      </S.NoteAmountContainer>
      {deleteId && (
        <S.DeleteModalWrapper>
          <S.DeleteModalTextContainer>
            <span>削除後は元に戻すことが出来ません。</span>
            <span>本当に削除しますか？</span>
          </S.DeleteModalTextContainer>
          <S.DeleteModalButtonContainer>
            <Button
              padding="sm"
              outlined
              onClick={() => {
                setDeleteId(null);
              }}
            >
              キャンセル
            </Button>
            <Button
              padding="sm"
              icon={<DeleteIcon />}
              outlined
              onClick={() => {
                onClickDelete();
              }}
            >
              削除
            </Button>
          </S.DeleteModalButtonContainer>
        </S.DeleteModalWrapper>
      )}
    </S.Wrapper>
  );
};
export default MaterialsForm;
