import styled from 'styled-components';
import schedule_icon from '../../../../../assets/icons/schedule.svg';
import ArrowDropDown from '@assets/icons/arrow_drop_down.svg';

export const Wrapper = styled.div<{
  open?: boolean;
}>`
  width: 100%;
  background-color: rgba(51, 51, 51, 0.5);
`;

export const Table = styled.table`
  width: 100%;
  border-collapse: collapse;
  border-spacing: 0 1em;
  font-size: 0.875rem;
`;

export const THead = styled.tr`
  background: ${(props) => props.theme.palette.beige};
  border-top: 1px solid ${(props) => props.theme.palette.sandGray};
  border-bottom: 1px solid ${(props) => props.theme.palette.sandGray};
  height: 42px;
  line-height: 1.2;
  font-feature-settings: 'palt';
`;

export const TRow = styled.tr`
  border: 1px solid #e6e1dd;
  background-color: ${(props) => props.theme.palette.beige};
  height: 44px;
`;

export const TopRow = styled(TRow)`
  border-top: 2px solid #e6e1dd;
`;

export const TCellOrder = styled.td<{
  marginRight?: string;
}>`
  text-align: left;
  padding: 1rem 0rem 1rem 0.625rem;
  margin-right: ${(props) => (props.marginRight ? props.marginRight : '')};
  background: ${(props) => props.theme.palette.overlay};
  vertical-align: middle;
  font-weight: bold;
`;

export const TCell = styled.td<{
  align?: string;
  width?: string;
  marginRight?: string;
  background?: string;
  wordBreak?: string;
  fontSize?: string;
  padding?: string;
  spanRight?: string;
}>`
  position: relative;
  font-family: 'Noto Sans JP';
  font-style: normal;
  font-weight: 400;
  font-size: ${(props) => (props.fontSize ? props.fontSize : '16px')};
  padding: 0 4px;

  vertical-align: middle;
  word-break: ${(props) => (props.wordBreak ? props.wordBreak : '')};

  width: ${(props) => (props.width ? props.width : '')};
  padding: ${(props) => (props.padding ? props.padding : '0 0.25rem')};

  text-align: ${(props) => (props.align ? props.align : 'left')};
  margin-right: ${(props) => (props.marginRight ? props.marginRight : '')};
  background: ${(props) => (props.background ? props.background : '')};

  &.label {
    font-weight: bold;
    vertical-align: middle;
    width: 26px;
    text-align: center;
  }

  // .label-lying--small
  &.label-lying {
    font-size: 14px;
    padding: 0 0 4px 0;
    /* width: 100%; */
    display: flex;
    &--small {
      font-size: 12px;
    }
  }

  &.note {
    padding: 4px;
  }
  &.flex {
    display: flex;
    align-items: center;
    .symbol {
      position: absolute;
      right: -8px;
      z-index: 2;
    }
  }

  &.delete-btn {
    text-align: center;
    vertical-align: middle;
    padding-left: 8px;
    background: white;
  }

  &.display-quantity {
    &--total {
      .inner {
        width: 100%;
        min-height: 4rem;
        padding: 0 20px 0 0;
        border: solid 4px white;
        border-radius: 0.5rem;
        text-align: right;
        display: flex;
        justify-content: center;
        align-items: center;
        .unit {
          position: absolute;
          bottom: 28px;
          right: 13px;
        }
      }
    }

    &__count {
      /* 台車 */
      &--bogie {
        width: 8rem;
      }
      /* ケース */
      &--case {
        width: 7.5rem;
      }
      /* 個 */
      &--quantity {
        width: 6.5rem;
      }
      /* 合計 */
      &--total {
        width: 9rem;
        .with-label__inner-wrap {
          width: 100%;
        }
        .quantity {
          width: 100%;
          padding-right: 1rem;
          display: flex;
          justify-content: flex-end;
          .title {
            margin-right: auto;
            font-size: 14px;
          }
          .unit {
            font-size: 14px;
            margin-left: 2px;
          }
        }
      }
    }

    .display-quantity__inner-wrap {
      display: flex;
      flex-direction: row;
      justify-content: flex-end;
      .cell-content {
        justify-content: center;
        align-items: flex-end;
        .quantity {
          display: flex;
          align-items: flex-end;
          .num {
            font-size: 20px;
          }
          .unit {
            font-size: 14px;
          }
        }
        .explanation {
          margin-top: 4px;
          font-size: 12px;
          font-size: 0.75rem;
          color: #6b675c;
        }
      }
    }
    .cell-content {
      display: flex;
      flex-direction: column;
    }
  }

  > svg {
    display: inline;
    width: 18px;
    height: 18px;
  }

  > span {
    position: absolute;
    right: ${(props) => (props.spanRight ? props.spanRight : '')};
    font-size: 12px;
    z-index: 1;
    width: fit-content;
    bottom: 8px;
    right: 17px;
  }
`;

// 編集モーダルtableヘッダセル
export const THeadCell = styled(TCell)<{
  align?: string;
  width?: string;
  wordBreak?: string;
  colSpan?: number;
  whiteSpace?: boolean;
}>`
  font-size: 14px;
  font-weight: bold;

  word-break: ${(props) => (props.wordBreak ? props.wordBreak : '')};
  padding: ${(props) =>
    props.align === 'right'
      ? '0.5rem 0.5rem 0.5rem 0'
      : '0.5rem 0.5rem 0.5rem 0.625rem'};
  white-space: ${(props) => (props.whiteSpace ? 'pre-wrap' : '')};
`;

export const Amount = styled.span`
  background-color: ${(props) => props.theme.palette.white};
  height: 4rem;
  border-radius: 0 8px 8px 0;
`;

export const TCellBestBeforeDate = styled(TCell)<{
  background?: string;
}>`
  padding: 0.5rem 0 0.5rem 1.625rem;
  background: ${(props) => (props.background ? props.background : '')};
`;

export const StackerEmployeeWrapper = styled.div`
  vertical-align: middle;
  background: ${(props) => props.theme.palette.white};
  border: 1px solid ${(props) => props.theme.palette.outline};
  height: 4rem;
  border-radius: 8px;
  padding-top: 10px;
  padding-left: 12px;
  cursor: pointer;
  background-image: url(${ArrowDropDown});
  background-position: right 2px center;
  background-repeat: no-repeat;
  cursor: pointer;
`;

export const DeleteButton = styled.div`
  font-size: 1rem;
  text-align: -webkit-center;
`;

export const ButtonWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: ${(props) => props.theme.palette.beige};
  width: 100%;
  height: 83px;
  border-top: solid 1px #e6e1dd;
`;

export const ButtonContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  & > button {
    width: 136px;
  }

  & > button:first-child {
    margin-right: 1rem;
  }
`;

export const Input = styled.input<{
  padding?: string;
}>`
  width: 100%;
  height: 4rem;
  color: ${(props) => props.theme.palette.baseBlack};
  font-size: 1rem;
  line-height: 1.25rem;
  padding: ${(props) => (props.padding ? props.padding : '0.325rem')};
  background: ${(props) => props.theme.palette.white};
  border: none;
  border-radius: 0.5rem;
  border: 1px solid ${(props) => props.theme.palette.outline};

  &.date {
    max-width: 120px;
  }
`;

export const TextareaStackingComment = styled.textarea<{
  align?: string;
  padding?: string;
}>`
  width: 100%;
  min-height: 4rem;
  color: ${(props) => props.theme.palette.baseBlack};
  font-size: 1rem;
  line-height: 1.25rem;
  padding: ${(props) => (props.padding ? props.padding : '0.325rem')};
  text-align: ${(props) => props.align};
  border: none;
  border-radius: 0.5rem;
  border: 1px solid rgba(0, 0, 0, 0.08);
  background: ${(props) => props.theme.palette.white};
  word-wrap: break-word;
  word-break: break-all;
  white-space: normal;

  &.date {
    max-width: 120px;
  }
`;

export const TimeInput = styled(Input)`
  cursor: pointer;
  ::-webkit-calendar-picker-indicator {
    background: none;
  }
  background-image: url(${schedule_icon});
  background-position: right 3px center;
  background-repeat: no-repeat;
  background-size: 15px;
  cursor: pointer;
  padding-right: 15px;
`;

export const CompletedAmountInput = styled(Input)`
  border-radius: 0.5rem;
  width: 100%;
  text-align: right;
  padding-right: 20px;
  ::-webkit-inner-spin-button {
    -webkit-appearance: none;
  }
`;

export const AmountUnit = styled.span`
  top: 73%;
  right: 21%;
`;

export const ArrowDropDownSpan = styled.span`
  position: absolute;
  top: 36% !important;
  right: 17%;
`;

export const ErrModalWrapper = styled.div`
  background: ${(props) => props.theme.palette.white};
  position: fixed;
  left: 50%;
  top: 50%;
  width: 30%;
  transform: translate(-50%, -50%);
  z-index: 10001;
  box-shadow: 0px 6px 10px rgba(0, 0, 0, 0.07), 0px 1px 18px rgba(0, 0, 0, 0.06),
    0px 3px 5px rgba(0, 0, 0, 0.1);
  border-radius: 8px;
  padding: 1rem;
`;

export const ErrModalTextContainer = styled.div`
  display: flex;
  flex-direction: column;
  font-weight: 500;
  font-size: 1.13rem;
  line-height: 27px;
  text-align: left;
`;

export const ErrModalButtonContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 1rem;
  margin-top: 1rem;
  button {
    width: 100%;
    height: 2.75rem;
    &:nth-child(2) {
      border-color: ${(props) => props.theme.palette.error};
      color: ${(props) => props.theme.palette.error};
    }
  }
`;

export const ClockIcon = styled.span`
  position: absolute;
  left: 75%;
  vertical-align: middle;
  top: 40% !important;
`;

export const PackagingDateLabel = styled.p`
  font-size: 14px;
`;

export const PackagingDate = styled.p`
  margin-top: 4px;
  margin-left: 4px;
`;

export const FormLabel = styled.p`
  font-size: 14px;
  padding: 0 0 4px 0;
  display: flex;
`;

export const FormLabelSmall = styled.p`
  font-size: 12px;
  padding: 0 0 4px 0;
  display: flex;
`;

export const ArithmeticSymbol = styled.div`
  position: absolute;
  right: -8px;
  z-index: 2;
`;

export const CompletedAmountInputWrapper = styled.div`
  display: flex;
  align-items: center;
  position: relative;
  padding: 0px 0.25rem;
`;

export const InputUnit = styled.span`
  position: absolute;
  font-size: 12px;
  z-index: 1;
  width: fit-content;
  bottom: 8px;
  right: 8px;
`;

export const AmountInputWrapper = styled.div`
  position: relative;
`;

export const ButtonGroup = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  height: 4rem;
  margin-left: 4px;
`;

export const Button = styled.button`
  border-radius: 50%;
  background: ${(props) => props.theme.palette.blue};
  width: 28px;
  height: 28px;
  padding: 0;
  font-size: 8px;
`;

export const CompletedWrapper = styled.div`
  font-size: 12px;
  padding-left: 23px;
  display: flex;
  vertical-align: middle;
`;

export const CancelButtonWrapper = styled.div`
  margin-top: 20px;
`;
