import styled from 'styled-components';

interface IProps {
  closeMode?: boolean;
  sendMode?: boolean;
  previewMode?: boolean;
  imgIndex?: number;
  sendConfirm?: boolean;
  sending?: boolean;
  sent?: boolean;
}

export const OuterBody = styled.div`
  background: black;
  height: 100vh;
`;

export const HeaderContainer = styled.div`
  display: flex;
  width: 100%;
  height: 50px;
  padding: 0 1.2rem;
  background: ${(props) => props.theme.palette.tealGreen};
`;

export const CloseButton = styled.button`
  padding: 1rem 1rem 1rem 0;
  background: none;
  left: 0;
`;

export const ProductName = styled.div`
  width: 70%;
  text-align: center;
  padding: 1rem 0.5rem;
  height: 50px;
  font-size: large;
  font-weight: 700;
  border: none;
  background: none;
  color: ${(props) => props.theme.palette.white};
  position: absolute;
  left: 0;
  right: 0;
  margin: auto;
`;

export const DelButton = styled.button`
  padding: 1rem 1rem 1rem 0;
  background: none;
  color: ${(props) => props.theme.palette.white};
  position: absolute;
  right: 0;
`;

export const ThumbnailContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const Sentence1 = styled.div`
  width: 100%;
  font-size: large;
  color: white;
  text-align: center;
  position: absolute;
  margin-top: 8px;
  visibility: ${(props: IProps) =>
    props.previewMode && props.imgIndex == 0 ? 'visible' : 'hidden'};
`;

export const Sentence2 = styled.div`
  width: 100%;
  font-size: large;
  color: white;
  text-align: center;
  position: absolute;
  margin-top: 8px;
  visibility: ${(props: IProps) =>
    props.previewMode && props.imgIndex == 1 ? 'visible' : 'hidden'};
`;

export const Sentence3 = styled.div`
  width: 100%;
  font-size: large;
  color: white;
  text-align: center;
  position: absolute;
  margin-top: 8px;
  visibility: ${(props: IProps) =>
    props.previewMode && props.imgIndex == 2 ? 'visible' : 'hidden'};
`;

export const Sentence4 = styled.div`
  width: 100%;
  font-size: large;
  color: white;
  text-align: center;
  position: absolute;
  margin-top: 8px;
  visibility: ${(props: IProps) =>
    props.previewMode && props.imgIndex == 3 ? 'visible' : 'hidden'};
`;

export const BlurWrapper = styled.div<{
  previewMode: boolean;
}>`
  width: 100%;
  height: 400px;
  margin-top: 400px;
  position: absolute;
  filter: blur(10px);
  visibility: ${(props) => (props.previewMode ? 'hidden' : 'visible')};
`;

export const CameraWrapper = styled.div<{
  previewMode: boolean;
}>`
  width: 340px;
  height: 340px;
  margin-top: 400px;
  position: absolute;
  border: 5px solid white;
  visibility: ${(props) => (props.previewMode ? 'hidden' : 'visible')};
`;

export const ImageWrapper = styled.img<{
  previewMode: boolean;
}>`
  width: 340px;
  height: 340px;
  margin-top: 400px;
  position: absolute;
  visibility: ${(props) => (props.previewMode ? 'visible' : 'hidden')};
`;

export const CircleButtonContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 400px;
  height: 140px;
`;

// 撮影丸ボタン
export const Circle = styled.div`
  border-radius: 50%;
  width: 90px;
  height: 90px;
  background: white;
  position: absolute;
  visibility: ${(props: IProps) => (props.previewMode ? 'hidden' : 'visible')};
`;

// 撮影丸ボタン
export const CircleButton = styled.button`
  border-radius: 50%;
  border: 1px solid black;
  width: 80px;
  height: 80px;
  background: white;
  position: absolute;
  visibility: ${(props: IProps) => (props.previewMode ? 'hidden' : 'visible')};
`;

// 再撮影ボタン
export const RecaptureButton = styled.button`
  width: 150px;
  height: 50px;
  font-size: large;
  border: none;
  background: none;
  color: #2288ff;
  margin-top: -130px;
  visibility: ${(props: IProps) => (props.previewMode ? 'visible' : 'hidden')};
`;

// OKボタン
export const YesButton = styled.button`
  width: 150px;
  height: 50px;
  font-size: large;
  border: none;
  background: none;
  color: #2288ff;
  margin-top: -130px;
  visibility: ${(props: IProps) => (props.previewMode ? 'visible' : 'hidden')};
`;

export const PreviewThumbnail1 = styled.img`
  width: 64px;
  height: 64px;
  margin: 15px;
  border: 2px solid
    ${(props: IProps) => (props.imgIndex == 0 ? '#66CCFF' : 'black')};
`;

export const PreviewThumbnail2 = styled.img`
  width: 64px;
  height: 64px;
  margin: 15px;
  border: 2px solid
    ${(props: IProps) => (props.imgIndex == 1 ? '#66CCFF' : 'black')};
`;

export const PreviewThumbnail3 = styled.img`
  width: 64px;
  height: 64px;
  margin: 15px;
  border: 2px solid
    ${(props: IProps) => (props.imgIndex == 2 ? '#66CCFF' : 'black')};
`;

export const PreviewThumbnail4 = styled.img`
  width: 64px;
  height: 64px;
  margin: 15px;
  border: 2px solid
    ${(props: IProps) => (props.imgIndex == 3 ? '#66CCFF' : 'black')};
`;

export const CanvasContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  overflow-x: clip;
`;

export const Footer = styled.div`
  position: sticky;
  bottom: 0;
  margin-top: auto;
  padding: 1rem;
  text-align: -webkit-center;
  background: ${(props) => props.theme.palette.beige};
  box-shadow: 0px 6px 10px rgba(0, 0, 0, 0.07), 0px 1px 18px rgba(0, 0, 0, 0.06),
    0px 3px 5px rgba(0, 0, 0, 0.1);
`;

export const SubmitButton = styled.button`
  display: block;
  width: 35%;
  padding: 0;
  height: 3.25rem;
  border-radius: 0.5rem;
  background: ${(props) => props.theme.palette.primary.main};
  font-weight: bold;
  color: ${(props) => props.theme.palette.white};
`;
