import styled from 'styled-components';

export const Wrapper = styled.div`
  width: 100vw;
  height: 100vh;
  display: grid;
  place-items: center;
  background: ${(props) => props.theme.palette.beige};
`;

export const Container = styled.div`
  margin: auto 0;
  text-align: center;
  width: 320px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

export const InputContainer = styled.div`
  margin-top: 3rem;
  margin-bottom: 2.75rem;
  width: 100%;
  text-align: left;
  display: flex;
  flex-direction: column;
  gap: 2rem;
`;
