import styled from 'styled-components';

export const Wrapper = styled.div`
  width: 100vw;
  background-color: #f9f5d2;
`;

export const TitleText = styled.div`
  justify-content: space-between;
  padding: 1rem 1.5rem;
  display: flex;
  font-size: 16px;
  font-weight: 700;
  line-height: 23px;
  letter-spacing: 0em;
  text-align: left;
`;

export const DetailWrapper = styled.div<{
  open: boolean;
}>`
  padding-bottom: 23px;
  display: ${(props) => (props.open ? 'block' : 'none')};
`;

export const Detail = styled.div`
  display: flex;
  padding: 0 16px 1px 16px;
  width: 100vw;
  height: 100%;
  font-size: 14px;
  font-weight: 400;
  /* background-color: #f9f5d2; */
`;

export const Destination = styled.div`
  width: 80%;
  margin-right: 1px;
  padding: 5px 8px;
  background-color: ${(props) => props.theme.palette.white};
  div:first-child {
    padding-bottom: 5px;
  }
`;

export const Amount = styled.div`
  width: 22%;
  padding: 15px 12px;
  text-align: end;
  background-color: ${(props) => props.theme.palette.white};
`;
