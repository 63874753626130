import { FC } from 'react';
import * as S from './ShippingStopModal.styles';
import { Button } from '@components/elements/buttons/Button';

type Props = {
  selectedProduct: string;
  selectedPlace: string;
  shippingStop: boolean;
  handleOk: () => void;
  handleCancel: () => void;
};

const ShippingStopModal: FC<Props> = ({
  selectedProduct,
  selectedPlace,
  shippingStop,
  handleOk,
  handleCancel,
}: Props) => {
  return (
    <S.Wrapper open={selectedProduct}>
      {shippingStop
        ? `出荷先「${selectedPlace}」の「${selectedProduct}」を出荷止めを元に戻しますか？`
        : `出荷先「${selectedPlace}」の「${selectedProduct}」を出荷止めにしますか？`}

      <S.ButtonContainer>
        <Button outlined={true} padding="md" onClick={() => handleCancel()}>
          キャンセル
        </Button>
        <S.StopButton onClick={() => handleOk()}>
          {shippingStop ? '元に戻す' : '出荷止めにする'}
        </S.StopButton>
      </S.ButtonContainer>
    </S.Wrapper>
  );
};

export default ShippingStopModal;
