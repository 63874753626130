import { useHistory, useLocation } from 'react-router-dom';
import * as S from './Header.styles';
import { Heading } from '@components/elements/typographies/Heading';
import SearchMenu from '@components/modules/common/SearchMenu/SearchMenu';
import { ReactComponent as FilteredIcon } from '@assets/icons/filtered.svg';
import Filter from '@assets/icons/filter_list';
import { Material } from '@lib/common/type';
import { PurchaseOrderState } from '@lib/pc/purchase_orders/type';

type Props = {
  dateRange: {
    startDate: moment.Moment | null;
    endDate: moment.Moment | null;
  };
  setDateRange: React.Dispatch<
    React.SetStateAction<{
      startDate: moment.Moment | null;
      endDate: moment.Moment | null;
    }>
  >;
  selectedDateTypeId: string;
  setSelectedDateTypeId: (selectedDateTypeId: string) => void;
  materials: Material[] | undefined;
  setSelectedMaterialOrderId: (selectedMaterialOrderId: number | null) => void;
  setSearchConditionOpen: (searchConditionOpen: boolean) => void;
  statusConditions: string[];
  destConditions: number[];
  receivedConditions: string[];
  searchDateMenuActive: boolean;
  setSearchDateMenuActive: (searchDateMenuActive: boolean) => void;
};

const Header = ({
  dateRange,
  setDateRange,
  selectedDateTypeId,
  setSelectedDateTypeId,
  materials,
  setSelectedMaterialOrderId,
  setSearchConditionOpen,
  statusConditions,
  destConditions,
  receivedConditions,
  searchDateMenuActive,
  setSearchDateMenuActive,
}: Props) => {
  const history = useHistory();
  const { state } = useLocation<PurchaseOrderState>();
  const DATE_TYPES = ['絞込み条件の選択', '作成日', '発注日'] as const;
  // const [searchMenuActive, setSearchMenuActive] = useState(false);
  const targetName = DATE_TYPES[Number(selectedDateTypeId)];
  const isSearchConditionBlank =
    statusConditions.length === 0 &&
    destConditions.length === 0 &&
    receivedConditions.length === 0;

  return (
    <S.HeadContainer>
      <S.PageTitle>
        <S.PageTitleText>
          <Heading text="発注情報" fs="32" fw="lg" />
          <Heading text="を確認" fs="24" fw="lg" />
        </S.PageTitleText>
      </S.PageTitle>

      <S.HeaderRightContainer>
        <S.ButtonWrapper>
          <S.ButtonContainer
            onClick={() => {
              history.push({
                pathname: `/pc/purchase_orders/new`,
                state: {
                  ...state,
                  selectedMaterialOrderId: '',
                  materials: materials,
                  afterCreateOrUpdate: false,
                },
              });
              setSelectedMaterialOrderId(null);
            }}
            padding="10px 10px 10px 0"
            width="160px"
            buttonType="blue"
          >
            ＋ 新規登録
          </S.ButtonContainer>
        </S.ButtonWrapper>
        <S.DateWrapper
          onClick={() => setSearchDateMenuActive(!searchDateMenuActive)}
        >
          <S.DateFilter>
            <span className="filter-product">
              {dateRange.startDate &&
              dateRange.endDate &&
              selectedDateTypeId !== '0' &&
              dateRange.startDate === dateRange.endDate ? (
                <>
                  <FilteredIcon />
                  <div className="filter_text">
                    {targetName}
                    <br />
                    {`${dateRange.startDate.format('YYYY/MM/DD')}のみ表示`}
                  </div>
                </>
              ) : dateRange.startDate &&
                dateRange.endDate &&
                selectedDateTypeId !== '0' ? (
                <>
                  <FilteredIcon />
                  <div className="filter_text">
                    {targetName}
                    <br />
                    {`${dateRange.startDate.format(
                      'YYYY/MM/DD'
                    )} → ${dateRange.endDate.format('YYYY/MM/DD')}`}
                  </div>
                </>
              ) : (
                <>
                  <S.SearchFilterIconContainer>
                    <Filter isBlue={false} />
                  </S.SearchFilterIconContainer>
                  <div className="filter_text_false">
                    作成日または
                    <br />
                    発注日の絞込み
                  </div>
                </>
              )}
            </span>
          </S.DateFilter>
        </S.DateWrapper>
        <S.DateWrapper>
          <S.DateFilter
            invalid={false}
            onClick={() => {
              setSearchConditionOpen(true);
              setSearchDateMenuActive(false);
            }}
          >
            {isSearchConditionBlank ? (
              <span className="filter-product">
                <>
                  <S.SearchFilterIconContainer>
                    <Filter />
                  </S.SearchFilterIconContainer>
                  <S.DetailConditionText>詳細条件</S.DetailConditionText>
                </>
              </span>
            ) : (
              <>
                <S.FilterIconWrapper>
                  <FilteredIcon />
                </S.FilterIconWrapper>
                <S.SearchConditionNameList>
                  絞込中
                  {/* {statusConditionNames
                    .concat(destConditionNames)
                    .map((name) => (
                      <S.SearchConditionLabel>{name}</S.SearchConditionLabel>
                    ))} */}
                </S.SearchConditionNameList>
              </>
            )}
          </S.DateFilter>
        </S.DateWrapper>
      </S.HeaderRightContainer>

      {searchDateMenuActive && (
        <SearchMenu
          headerTitleText={'作成日または発注日の絞り込み'}
          selectedDateTypeId={selectedDateTypeId}
          setSearchMenuActive={setSearchDateMenuActive}
          setSelectedDateTypeId={setSelectedDateTypeId}
          setDateRange={setDateRange}
          dateRange={dateRange}
          dateTypes={DATE_TYPES.map((name, id) => ({ id, name }))}
        />
      )}
    </S.HeadContainer>
  );
};

export default Header;
