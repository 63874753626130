import styled from 'styled-components';

export const Wrapper = styled.div`
  height: 100vh;
  width: 100vw;
  position: relative;
`;

export const Header = styled.div`
  position: fixed;
  font-weight: 700;
  font-size: 18px;
  line-height: 26px;
  color: ${(props) => props.theme.palette.white};
  width: 100%;

  display: flex;
  justify-content: space-evenly;
  align-items: center;
  margin: 1rem 0;
  z-index: 10;
`;

export const LoadingContainer = styled.div`
  display: grid;
  place-items: center;
  height: 100vh;
  width: 100vw;
`;

export const CameraMessage = styled.div`
  font-weight: 700;
  /* position: absolute;
  top: 0; */
  background: #06945a;
  color: ${(props) => props.theme.palette.white};
  width: 100%;
  text-align: center;
  padding-top: 10px;
  padding-bottom: 10px;
`;

export const JanCode = styled.span`
  font-size: 32px;
`;

export const ReadMessage = styled.div`
  padding-top: 14px;
`;

export const NotFoundMessage = styled.div`
  position: fixed;
  bottom: 4%;
  text-align: center;
  color: ${(props) => props.theme.palette.white};
  font-weight: 700;
  font-size: 18px;
  line-height: 26px;
`;

export const ReShootButton = styled.button`
  background-color: ${(props) => props.theme.palette.white};
  width: 343px;
  height: 52px;
  margin-top: 1rem;
  border-radius: 8px;
  color: #06945a;
  font-size: 1rem;
  line-height: 1rem;
  font-weight: 700;
  text-align: center;

  > svg {
    display: inline-block;
    margin-right: 10px;
  }
`;
