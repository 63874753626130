import { FC, useEffect } from 'react';
import * as S from './ProductProcessSelect.styles';
import type {
  ProductsProcess,
  Todo,
  ScannedProductInfo,
  OptionType,
} from '@lib/common/type';
import ReactSelect from 'react-select';
import { useDeviceOrientationAndSize } from '@lib/common/functions';
import { NO_OPTION_JAPANESE } from '@lib/pc/common/constants';

type Props = {
  scannedProduct: ScannedProductInfo;
  productsProcesses: ProductsProcess[] | undefined;
  handleChangeProduct: (e: number) => void;
  setEditProductName: (editProductName: string) => void;
  value?: number | undefined;
  disabled?: boolean;
};

const ProductProcessSelect: FC<Props> = ({
  scannedProduct,
  productsProcesses,
  handleChangeProduct,
  setEditProductName,
  value,
  disabled,
}: Props) => {
  const { deviceType } = useDeviceOrientationAndSize();
  const isDesktop = deviceType === 'desktop';

  const toReactSelectOptions = (
    options: {
      id: number;
      productId: number;
      productCode: string;
      name: string;
      factoryLineIds: number[];
    }[]
  ) =>
    options.map(({ id, name, productCode }) => ({
      value: id,
      label: name.replace(/\s/g, '_'),
      code: productCode,
    }));

  const reactSelectOptions =
    productsProcesses && toReactSelectOptions(productsProcesses);

  const selectedOption =
    scannedProduct?.processId &&
    reactSelectOptions &&
    reactSelectOptions.find(
      (option) => option.value === scannedProduct.processId
    );

  const selectedValue = selectedOption
    ? { value: selectedOption.value, label: selectedOption.label }
    : undefined;
  const currentValue = reactSelectOptions?.find((o) => o.value === value);

  const filterOption = (
    option: { label: string; value: number | string; data: OptionType },
    inputValue: string
  ): boolean => {
    const { label, value, data } = option;
    const { code } = data;
    return (
      label.toLowerCase().includes(inputValue.toLowerCase()) ||
      value.toString().toLowerCase().includes(inputValue.toLowerCase()) ||
      code.toLowerCase().includes(inputValue.toLowerCase())
    );
  };

  useEffect(() => {
    if (currentValue) {
      setEditProductName(currentValue.label);
    } else if (scannedProduct && scannedProduct.processId !== null) {
      handleChangeProduct(scannedProduct.processId);
    }
  }, [currentValue, selectedValue]);

  const styles = {
    control: (baseStyles: Todo) => ({
      ...baseStyles,
      minHeight: '4rem',
      width: '23.5rem',
      borderRadius: '0.5rem',
      fontWeight: 400,
      fontSize: '20px',
      textAlign: 'left',
      border: '1px solid rgba(0,0,0,0.08)',
      outline: '0.5px solid rgba(0,0,0,0.08)',
      backgroundColor: disabled ? '' : 'white',
      marginTop: 0,
      '@media (max-width: 599px)': {
        width: '16rem',
      },
    }),
    menu: (baseStyles: Todo) => ({
      ...baseStyles,
      top: '37px',
      marginTop: '30px',
    }),
    option: (baseStyles: Todo) => ({
      ...baseStyles,
      textAlign: 'left',
      fontWeight: 400,
      fontSize: '20px',
      height: '70px',
    }),
    singleValue: (baseStyles: Todo) => ({
      ...baseStyles,
      height: '60px',
      whiteSpace: 'normal',
      paddingTop: '7px',
    }),
  };

  return (
    <S.Wrapper>
      <ReactSelect
        options={reactSelectOptions}
        onChange={(option) => option && handleChangeProduct(option.value)}
        value={currentValue ? currentValue : null}
        styles={styles}
        isDisabled={disabled}
        isSearchable={isDesktop}
        filterOption={filterOption}
        components={{
          IndicatorSeparator: () => null,
        }}
        noOptionsMessage={() => NO_OPTION_JAPANESE}
        placeholder="選択してください"
      />
    </S.Wrapper>
  );
};

export default ProductProcessSelect;
