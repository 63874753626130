export const TABLE_HEADER_WITH_CASE_KIND = [
  {
    key: '編集',
    width: '47px',
    colSpan: 1,
  },
  { key: '製品名', width: '230px' },
  {
    key: '払出先',
    width: '145px',
    wordBreak: 'keep-all',
    colSpan: 1,
  },
  {
    key: 'ケース種類',
    width: '160px',
    wordBreak: 'keep-all',
    colSpan: 1,
  },
  {
    key: '数量',
    width: '202px',
    wordBreak: 'keep-all',
    colSpan: 3,
  },
  {
    key: '賞味期限',
    width: '100px',
    colSpan: 1,
  },
  {
    key: '備考',
    width: '100px',
    wordBreak: 'keep-all',
    colSpan: 1,
  },
];

export const TABLE_HEADER = [
  { key: '製品名', width: '230px' },
  {
    key: '払出先',
    width: '230px',
    wordBreak: 'keep-all',
    colSpan: 1,
  },
  {
    key: '数量',
    width: '200px',
    wordBreak: 'keep-all',
    colSpan: 3,
  },
  {
    key: '賞味期限',
    width: '100px',
    colSpan: 1,
  },
  {
    key: '備考',
    width: '193px',
    wordBreak: 'keep-all',
    colSpan: 1,
  },

  {
    key: '編集',
    width: '47px',
    colSpan: 1,
  },
];
