import { axiosInstance } from '@lib/mobile/common/api/axiosConfig';
import { useQuery } from 'react-query';
import humps from 'humps';
import { Camera } from '@lib/mobile/products_photograph/type';

const useCameras = () => {
  return useQuery('cameras', async () => {
    const { data } = await axiosInstance().get(`/api/v1/cameras`);
    return humps.camelizeKeys(data.cameras) as Camera[];
  });
};

export default useCameras;
