/* eslint-disable @typescript-eslint/no-explicit-any */
import { FC } from 'react';
import moment from 'moment';
import * as S from './SNSDialog.styles';
import { ReactComponent as AttentionIcon } from '@assets/icons/exclamation_mark.svg';
import { ReactComponent as CloseIcon } from '@assets/icons/close.svg';
import { ReactComponent as TwitterIcon } from '@assets/icons/twitter_logo.svg';
import { ReactComponent as InstaIcon } from '@assets/icons/Instagram_logo.svg';
import { ReactComponent as ThermoIcon } from '@assets/icons/thermostat_logo.svg';
import { ReactComponent as TokubaiIcon } from '@assets/icons/tokubai_logo.svg';

import { Heading } from '@components/elements/typographies/Heading';
import { generateDateArray } from '@lib/pc/production_schedule/functions';
// TODO Define Types
type Props = {
  handleClose: () => void;
  item: any;
};

// TODO: 後でlibsへ移動
/**
 * 増加率を返す関数
 * * prevNum: 前年比や前の値
 * * currentNum: 現在の値
 */
const calcDiffRates = (prevNum: number, currentNum: number) => {
  return Math.round((currentNum / prevNum - 1) * 100);
};

/**
 * 値(num1:number)を(num2:number)と比べて+, -をつけたstringを返す関数
 * * num1:number: 前年比や前の値
 * * num2:number: 比較する値
 */

const getNumberWithSymbol = (num1: number, num2 = 0) => {
  if (num1 === num2 || num1 < num2) return num1;

  return `+${num1}`;
};

const SNSDialog: FC<Props> = ({ handleClose, item }: Props) => {
  if (!item) {
    return null;
  }

  // COMMENT: 多分前後の日にちは変わるかもしれないのでどこか別のところにおいた方がいい
  const DateArr = generateDateArray(7);
  const day = DateArr[item.index].productionDate;

  const diffTempreture =
    parseInt(item.weather.temprature) - parseInt(item.weather.fortnightAvarage);
  const diffTwitter = calcDiffRates(item.fortnightAvarage, item.twitter);
  const diffInsta = calcDiffRates(item.fortnightAvarage, item.instagram);
  const tokubaiInfo =
    item.tokubai.length > 5 ? item.tokubai.slice(0, 5) : item.tokubai;

  return (
    <S.Wrapper open={item}>
      <S.DialogContainer>
        <S.DialogHeader>
          <S.DialogTitle>
            <AttentionIcon />
            <Heading fw="lg" fs="32" text="アラート" />
            <Heading fw="lg" fs="22" text="を確認" />
          </S.DialogTitle>

          <S.DialogCloseButton onClick={handleClose}>
            <CloseIcon />
          </S.DialogCloseButton>
        </S.DialogHeader>
        <S.MainContainer>
          <S.HeadingContainer>
            <S.DateContainer>
              {moment(day).format('MM/DD')}
              <span>{moment(day).format('(ddd)')}</span>
            </S.DateContainer>

            <Heading fs="14" text="アラートは以下の通りです。" />
            <Heading
              fw="lg"
              fs="14"
              text="確認の上、必要であれば出荷予測数を修正してください。"
            />
          </S.HeadingContainer>

          <S.ListItem>
            <div>
              <TwitterIcon />
              <span>切れてる豆腐</span>
            </div>
            <S.ListCell>
              <div>直近2週間の平均</div>
              <span>{item.fortnightAvarage} </span>
            </S.ListCell>
            <S.ListCell red={item.fortnightAvarage < item.twitter}>
              <div>今日</div>
              <span>{item.twitter}</span>
            </S.ListCell>
            <S.ListCell red={0 < diffTwitter}>
              <div>増加率</div>
              <span>{getNumberWithSymbol(diffTwitter)}</span>
              <S.Unit>%</S.Unit>増
            </S.ListCell>
          </S.ListItem>
          <S.ListItem>
            <div>
              <InstaIcon />
              <span>切れてる豆腐</span>
            </div>
            <S.ListCell>
              <div>直近2週間の平均</div>
              <span>{item.fortnightAvarage} </span>
            </S.ListCell>
            <S.ListCell red={item.fortnightAvarage < item.instagram}>
              <div>今日</div>
              <span>{item.instagram}</span>
            </S.ListCell>
            <S.ListCell red={0 < diffInsta}>
              <div>増加率</div>
              <span>{getNumberWithSymbol(diffInsta)}</span>
              <S.Unit>%</S.Unit>増
            </S.ListCell>
          </S.ListItem>
          <S.ListItem twoColumns>
            {/* TODO: アイコン変更する */}
            <div>
              <ThermoIcon /> <span>大雪警報</span>
            </div>
            <S.ListCell alignRight>
              <div>都道府県</div>
              <span>福岡県、佐賀県 </span>
            </S.ListCell>
          </S.ListItem>
          <S.ListItem>
            <div>
              <ThermoIcon />
              <span>気温</span>
            </div>
            <S.ListCell>
              <div>直近2週間の平均</div>
              <span>{item.weather.fortnightAvarage}</span>
              <S.Unit>℃</S.Unit>
            </S.ListCell>
            <S.ListCell>
              <div>今日</div>
              <span>{item.weather.temprature}</span>
              <S.Unit>℃</S.Unit>
            </S.ListCell>
            <S.ListCell red={diffTempreture < 0}>
              <div>増減</div>
              <span>{getNumberWithSymbol(diffTempreture)}</span>
              <S.Unit>℃</S.Unit>
            </S.ListCell>
          </S.ListItem>
          {/* TODO: リンク飛ばすのか確認する */}
          <S.ListItem twoColumns>
            <div>
              <TokubaiIcon />
              <span>特売情報</span>
            </div>
            <S.ListCell>
              <div>
                {tokubaiInfo.map((t: any) => (
                  <span key={t.company}>
                    {t.company} ({t.amount})
                  </span>
                ))}
                {item.tokubai.length > 5 && '...'}
              </div>
            </S.ListCell>
          </S.ListItem>
        </S.MainContainer>
      </S.DialogContainer>
    </S.Wrapper>
  );
};

export default SNSDialog;
