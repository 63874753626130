export const defaultNewMaterialData = {
  id: 0,
  materialType: 0,
  materialTypeValue: 0,
  materialCode: '',
  expiryDays: null,
  name: '',
  nameKana: '',
  nameAbbreviation: '',
  piecesPerUnit: null,
  labelOfAmount: 'piece',
  piecesPerBox: null,
  labelOfUnit: 'no_unit',
  labelOfUnitValue: 'なし',
  defaultOrderAmount: null,
  orderUnit: 'piece',
  orderUnitValue: '個',
  orderType: 0,
  orderTypeLabel: '定期発注',
  alertThreshold: null,
  hidden: false,
};

export const createdMaterialData = {
  id: 0,
  materialType: 0,
  materialCode: '',
  expiryDays: null,
  name: '',
  nameKana: '',
  nameAbbreviation: '',
  piecesPerUnit: null,
  labelOfAmount: 'piece',
  piecesPerBox: null,
  labelOfUnit: 'no_unit',
  defaultOrderAmount: '個',
  orderUnit: 'piece',
  orderType: 0,
  orderTypeLabel: '定期発注',
  alertThreshold: null,
  hidden: false,
};

export const createdProductData = {
  id: 0,
  expiryDays: null,
  janCode: '',
  name: '',
  nameAbbreviation: '',
  nameKana: '',
  productCode: '',
  safetyStockDays: null,
  stockDateLabel: '',
};

export type defaultTag = {
  id: 0;
  name: '';
};
