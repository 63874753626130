import * as S from './FaxModal.styles';
import { ReactComponent as CloseIcon } from '@assets/icons/close.svg';
import { Heading } from '@components/elements/typographies/Heading';
import { Button } from '@components/elements/buttons/Button';
import CircularProgressComponent from '@components/modules/pc/purchase_orders/CircularProgressComponent/CircularProgressComponent';

type Props = {
  sending: boolean;
  faxNumber: string;
  confirmationDate: string;
  setOpenFaxModal: (openFaxModal: boolean) => void;
  onClickSubmit: () => void;
};

const FaxModal = ({
  sending,
  faxNumber,
  confirmationDate,
  setOpenFaxModal,
  onClickSubmit,
}: Props) => {
  return (
    <S.ModalWrapper>
      <S.CloseIconWrapper>
        <CloseIcon onClick={() => setOpenFaxModal(false)} />
      </S.CloseIconWrapper>
      <S.ModalHeadWrapper>
        <Heading text="FAX送信" fs="24" fw="lg" />
      </S.ModalHeadWrapper>
      <S.ModalFaxWrapper>
        {faxNumber ? `番号：${faxNumber}` : ''}
      </S.ModalFaxWrapper>
      {!confirmationDate && (
        <S.ConfirmationDateText>
          ※注文書の発行日を本日付で作成します。
        </S.ConfirmationDateText>
      )}

      <S.ModalButtonWrapper>
        <Button
          disabled={sending}
          width="100px"
          onClick={() => onClickSubmit()}
        >
          送信
        </Button>
      </S.ModalButtonWrapper>
      <CircularProgressComponent sending={sending} />
    </S.ModalWrapper>
  );
};

export default FaxModal;
