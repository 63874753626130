import { FC } from 'react';
import type { Todo } from '@lib/common/type';
import ReactSelect from 'react-select';
import { SalesStore } from '@lib/pc/settings/shipping_destination_setting/type';
import { NO_OPTION_JAPANESE } from '@lib/pc/common/constants';

type Props = {
  salesStores: SalesStore[] | undefined;
  value: number | undefined;
  setSelectedId?: (selectedProcessId: number) => void;
  onChange?: (selectedCaseId: number, index?: number) => void;
  disabled?: boolean;
};

const SalesStoreSelect: FC<Props> = ({
  salesStores,
  value,
  setSelectedId,
  onChange,
  disabled,
}: Props) => {
  const handleChangeCase = (e: number) => {
    if (setSelectedId) {
      setSelectedId(e);
    }
  };

  const toReactSelectOptions = (
    options: { id: number; name: string; code: string }[]
  ) =>
    options.map(({ id, name, code }) => ({
      value: id,
      label: name,
      code: code,
    }));

  const reactSelectOptions = salesStores && toReactSelectOptions(salesStores);
  const currentValue = reactSelectOptions?.find((o) => o.value === value);

  const styles = {
    control: (baseStyles: Todo) => ({
      ...baseStyles,
      width: '100%',
      height: '40px',
      borderRadius: '0.5rem',
      fontWeight: 400,
      textAlign: 'left',
      borderStyle: 'hidden',
      outline: '0.5px solid rgba(0,0,0,0.08)',
      backgroundColor: disabled ? '' : 'white',
      cursor: 'pointer',
      boxShadow: 'none',
      '@media (max-width: 599px)': {
        width: '16rem',
      },
    }),
    menu: (baseStyles: Todo) => ({
      ...baseStyles,
      top: '37px',
      marginTop: '0px',
      zIndex: 1002,
    }),
    option: (baseStyles: Todo) => ({
      ...baseStyles,
      textAlign: 'left',
      fontWeight: 400,
      fontSize: '15px',
    }),
  };

  return (
    <ReactSelect
      options={reactSelectOptions}
      value={currentValue ? currentValue : null}
      onChange={(option) => {
        option && handleChangeCase(option.value);
        option && onChange?.(option.value);
      }}
      styles={styles}
      isDisabled={disabled}
      isSearchable={true}
      components={{
        IndicatorSeparator: () => null,
      }}
      noOptionsMessage={() => NO_OPTION_JAPANESE}
      placeholder="販売店の選択"
    />
  );
};

export default SalesStoreSelect;
