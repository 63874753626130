import { axiosInstance } from '@lib/pc/common/api/axiosConfig';
import { useQuery } from 'react-query';
import humps from 'humps';
import type { Material } from '@lib/common/type';

const useMaterials = (supplierId: number) => {
  const { data, refetch } = useQuery(
    ['common_materials', supplierId],
    async () => {
      const { data } = await axiosInstance().get(
        `/api/v1/materials?supplier_id=${supplierId}`
      );
      return data;
    }
  );
  const materials = data && (humps.camelizeKeys(data.materials) as Material[]);
  return { materials, refetch };
};

export default useMaterials;
