import { FC } from 'react';
import * as S from './LabelOfAmountSelect.styles';
import type { Todo } from '@lib/common/type';
import ReactSelect from 'react-select';
import { NO_OPTION_JAPANESE } from '@lib/pc/common/constants';

type Props = {
  label?: string;
  allLabelsOfAmount: Todo[];
  value: string | undefined;
  setSelectedChangeLabelOfAmountName: (selectedName: string) => void;
  disabled?: boolean;
  newProductData: Todo;
  setNewProductData: (newProductData: Todo) => void;
  setIsChanged: (isChanged: boolean) => void;
};

const LabelOfAmountSelect: FC<Props> = ({
  label,
  allLabelsOfAmount,
  value,
  setSelectedChangeLabelOfAmountName,
  disabled,
  newProductData,
  setNewProductData,
  setIsChanged,
}: Props) => {
  const handleChange = (e: number, s: string) => {
    setSelectedChangeLabelOfAmountName(s);
    const selectedLabel = allLabelsOfAmount.find((obj) => (obj.name = s));
    setNewProductData({
      ...newProductData,
      labelOfAmount: selectedLabel?.name,
    });
    setIsChanged(true);
  };

  const toReactSelectOptions = (options: { id: number; name: string }[]) =>
    options.map(({ id, name }) => ({
      value: id,
      label: name,
    }));
  const reactSelectOptions =
    allLabelsOfAmount && toReactSelectOptions(allLabelsOfAmount);
  const currentValue = reactSelectOptions?.find((o) => o.label === value);

  const styles = {
    control: (baseStyles: Todo) => ({
      ...baseStyles,
      width: '8rem',
      height: '40px',
      borderRadius: '0.5rem',
      fontWeight: 400,
      textAlign: 'left',
      borderStyle: 'hidden',
      outline: '0.5px solid rgba(0,0,0,0.08)',
      backgroundColor: disabled ? '' : 'white',
      cursor: 'pointer',
      boxShadow: 'none',
      '@media (max-width: 599px)': {
        width: '16rem',
      },
    }),
    menu: (baseStyles: Todo) => ({
      ...baseStyles,
      top: '34px',
      zIndex: 1002,
    }),
    option: (baseStyles: Todo) => ({
      ...baseStyles,
      textAlign: 'left',
      fontWeight: 400,
      fontSize: '15px',
    }),
    menuList: (baseStyles: Todo) => ({
      ...baseStyles,
      maxHeight: '100px',
    }),
    singleValue: (baseStyles: Todo) => ({
      ...baseStyles,
      height: '20px',
    }),
  };

  return (
    <S.Wrapper>
      <S.Label>{label ? label : '半製品'}の単位</S.Label>
      <S.SelectWrapper>
        <ReactSelect
          options={reactSelectOptions}
          value={currentValue ? currentValue : null}
          onChange={(option) => {
            option && handleChange(option.value, option.label);
          }}
          styles={styles}
          isDisabled={disabled}
          isSearchable={false}
          components={{
            IndicatorSeparator: () => null,
          }}
          noOptionsMessage={() => NO_OPTION_JAPANESE}
          placeholder="選択してください"
        />
      </S.SelectWrapper>
    </S.Wrapper>
  );
};

export default LabelOfAmountSelect;
