import { useEffect, useMemo, useState } from 'react';
import { useWindowScroll } from 'react-use';
import { WithHeader } from '@components/templates/mobile/WithHeader';
import * as S from './ProductsPhotograph.styles';
import { ReactComponent as CameraIcon } from '@assets/icons/mobile/camera_black.svg';
import { searchCharForProductsPhotograph } from '@lib/mobile/products_photograph/functions';
import CameraList from '@components/modules/mobile/products_photograph/CameraList/CameraList';
import { useLocation } from 'react-router-dom';
import SearchProductForm from '@components/modules/mobile/products_photograph/SearchProductForm/SearchProductForm';
import useCameras from '@lib/mobile/products_photograph/useCameras';
import {
  Camera,
  ProductsPhotographState,
} from '@lib/mobile/products_photograph/type';

export const ProductsPhotographRoot = () => {
  const { state } = useLocation<ProductsPhotographState>();
  // 検索文字state
  const [searchChar, setSearchChar] = useState('');
  // スクロール位置state
  const { y: currentScrollPosition } = useWindowScroll();

  const { data: cameraData } = useCameras();

  const charSearchCameraArray: Camera[] = [];
  const filtered = useMemo(() => {
    if (!cameraData) return;
    if (searchChar) {
      // 文字列検索
      cameraData.forEach((camera) => {
        const charSearchResult = searchCharForProductsPhotograph(
          searchChar,
          camera?.products
        );
        if (charSearchResult.length > 0) {
          charSearchCameraArray.push(camera);
        }
      });
      return charSearchCameraArray;
    } else {
      return cameraData;
    }
  }, [searchChar, cameraData]);

  useEffect(() => {
    if (state === undefined) {
      setSearchChar('');
    } else {
      setSearchChar(state.stateSearchChar);
    }
  }, [cameraData]);

  return (
    <WithHeader>
      <S.Title>
        <S.TitleText>
          <CameraIcon />
          カメラ
        </S.TitleText>
      </S.Title>
      <SearchProductForm
        searchChar={searchChar}
        setSearchChar={setSearchChar}
      />
      <CameraList
        cameras={filtered}
        searchChar={searchChar}
        scrollPosition={currentScrollPosition}
      />
    </WithHeader>
  );
};
