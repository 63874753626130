import styled from 'styled-components';
import schedule_icon from '@assets/icons/schedule.svg';

export const Wrapper = styled.div`
  width: 50rem;
  height: 17rem;
  top: 50%;
  left: 50%;
  position: fixed;
  transform: translate(-50%, -50%);
  border-radius: 8px;
  z-index: 9999;
  padding: 1.15rem 2.25rem;
  background: ${(props) => props.theme.palette.white};
  box-shadow: 0px 6px 10px rgba(0, 0, 0, 0.07), 0px 1px 18px rgba(0, 0, 0, 0.06),
    0px 3px 5px rgba(0, 0, 0, 0.1);
`;

export const FlexBaseline = styled.div`
  display: flex;
  align-items: baseline;
  gap: 0.5rem;
`;

export const LeftButton = styled.div`
  cursor: pointer;
`;

export const InnerWrapper = styled.div`
  display: flex;
  padding: 40px 0 20px 0;
  justify-content: center;
  align-items: center;
  gap: 8px;
  justify-content: space-between;
`;

export const ShippingDateLabel = styled.div`
  padding-top: 8px;
  color: #6b675c;
  font-weight: 700;
  > div {
    padding-bottom: 0.3rem;
  }
`;

export const Product = styled.div`
  display: block;
  padding-top: 8px;
`;

export const ProductSpan = styled.span`
  padding-top: 8px;
  color: #6b675c;
  font-weight: 700;
`;

export const ShippingDestination = styled.div`
  display: block;
`;

export const ShippingDestinationSpan = styled.div`
  padding-top: 8px;
  color: #6b675c;
  font-weight: 700;
`;

export const TimeWrapper = styled.div`
  display: block;
  width: 100px;
`;

export const TimeSpan = styled.div`
  padding-top: 8px;
  color: #6b675c;
  font-weight: 700;
`;

export const Input = styled.input`
  width: 100%;
  height: 4rem;
  color: ${(props) => props.theme.palette.baseBlack};
  font-size: 1rem;
  line-height: 1.25rem;
  padding: 0.5rem;
  background: ${(props) => props.theme.palette.white};
  border: 0.1rem solid #cdcdcdcd;
  border-radius: 0.5rem;
  margin-top: 0.3rem;
`;

export const TimeInput = styled(Input)`
  cursor: pointer;
  ::-webkit-calendar-picker-indicator {
    background: none;
  }
  background-image: url(${schedule_icon});
  background-position: right 12px center;
  background-repeat: no-repeat;
  background-size: 20px;
`;

export const ButtonContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 2rem;
  margin-top: 1rem;
  & > button {
    width: 136px;
  }
`;
