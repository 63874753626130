import styled from 'styled-components';

export const Wrapper = styled.div<{
  open?: boolean;
}>`
  width: 740px;
  height: 761px;
  top: ${(props) => (props.open ? '7%' : '100%')};
  left: 50%;
  transform: translate(-50%, 0%);
  bottom: 30px;
  position: fixed;
  transition: all 0.5s ease-out;
  z-index: 10001;
  overflow-x: hidden;
  background-color: white;
  display: flex;
  flex-direction: column;
`;

export const HeadContainer = styled.div`
  position: fixed; //要素の固定
  width: 740px;
  height: 84px;
  display: left;
  flex-direction: column;
  background-color: ${(props) => props.theme.palette.beige};
`;

export const HeadingWrapper = styled.div`
  float: left;
  height: 84px;
  font-weight: 700;
  background-color: white;
  color: black;
  width: 100%;
  text-align: center;
  padding-top: 10px;
  padding-bottom: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: ${(props) => props.theme.palette.beige};
`;

/* 日付認識数 */
export const MainTitle = styled.div`
  font-size: 32px;
  margin-right: -60px;
`;

export const ButtonContainer = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  height: 84px;
  justify-content: space-evenly;
  & > button {
    width: 136px;
  }
  & > button:first-child {
    margin-right: 1rem;
  }
`;

export const HeaderButton = styled.div`
  cursor: pointer;
  width: 136px;
  height: 51px;
  margin-right: 5px;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 10px;
  background-color: ${(props) => props.theme.palette.white};
  color: ${(props) => props.theme.palette.primary.main};
  border: 2px solid ${(props) => props.theme.palette.primary.main};

  font-weight: bold;
  font-size: 16px;
  text-align: center;
  border-radius: 8px;
  padding: 0.75rem;
  border-width: 2px;
`;

export const DateCheckIcon = styled.div`
  display: flex;
  align-items: center;
  margin-left: 100px;
  margin-right: -60px;
  svg {
    width: 2.2rem;
    height: 2.2rem;
  }
`;

export const DateCheckContainer = styled.div`
  display: flex;
  align-items: flex-end;
  margin-right: -20px;
`;

export const DateCount = styled.div`
  display: flex;
  font-size: 32px;
  font-feature-settings: 'halt' on;
  display: inline-block;
`;

export const DateText = styled.div`
  display: flex;
  font-size: 20px;
  font-feature-settings: 'halt' on;
  display: inline-block;
`;

export const DateTotalCount = styled.div`
  display: flex;
  font-size: 24px;
  font-feature-settings: 'halt' on;
  display: inline-block;
`;

export const Swiper = styled.div`
  padding-top: 84px;
  height: 555px;
  width: 740px;
  flex-direction: column;
  &.swiper-pagination {
    position: relative !important;
    padding-top: 5px;
    color: black;
  }
  #pagination {
    position: relative !important;
    padding-top: 7px;
    font-size: 30px; // フォントサイズ
    font-weight: 700; // 太さ
    letter-spacing: 0.2em; // 文字間隔
    margin-bottom: 50px; // 下の余白
    &.swiper-pagination-bullet {
      span {
        background-color: ${(props) => props.theme.palette.baseBlack};
      }
    }
  }

  img {
    /* position: absolute !important; */
    top: 74px !important;
    top: 150px;
    height: 555px;
    width: 740px;
    display: inline-block;
  }
  canvas {
    height: 100%;
    width: 100%;
    display: inline-block;
  }
`;

export const CircularIconWrapper = styled.div`
  z-index: 10003;
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  top: 40%;
  left: 48%;
`;

export const FooterContainer = styled.div`
  //z-index: 10005;
  padding-top: 84px;
  height: 122px;
  display: inline-block;
  flex-direction: column;
  background-color: ${(props) => props.theme.palette.beige};
`;

export const FooterWrapper = styled.div`
  float: left;
  font-weight: 700;
  background-color: white;
  color: black;
  width: 100%;
  height: 122px;
  text-align: center;
  padding-top: 10px;
  padding-bottom: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: ${(props) => props.theme.palette.beige};
`;

export const FooterTitleContainer = styled.div`
  height: 23px;
  top: 16px;
  right: 18px;
  float: left;
  color: black;
  text-align: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: ${(props) => props.theme.palette.beige};
`;

export const FooterButtonContainer = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  justify-content: space-evenly;
  & > button {
    width: 136px;
  }
  & > button:first-child {
    margin-right: 1rem;
  }
`;

// 削除ボタン
export const DeleteButton = styled.div`
  cursor: pointer;
  width: 136px;
  height: 51px;
  margin-left: 5px;
  color: #fff;
  background: #c44;
  padding: 0.75rem 0;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 10px;
  font-weight: bold;
  font-size: 16px;
  text-align: center;
  border: 2px solid #c44;
  border-radius: 8px;
  border-width: 2px;
  svg path {
    fill: #fff;
  }
`;

//OKボタン
export const OkButton = styled.div`
  cursor: pointer;
  width: 136px;
  height: 51px;
  margin-right: 5px;
  color: #fff;
  background: #3ac;
  padding: 0.75rem 0;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 10px;
  font-weight: bold;
  font-size: 16px;
  text-align: center;
  border: 2px solid #3ac;
  border-radius: 8px;
  border-width: 2px;
`;
