import styled from 'styled-components';

export const PurchaseOrderList = styled.div`
  min-width: 250px;
  width: 20%;
  padding: 0.5rem 0 8px 8px;
  overflow-y: scroll;
`;

export const PurchaseOrderFiltering = styled.div<{
  invalid?: boolean;
  isBlue?: boolean;
}>`
  pointer-events: ${(props) => (props.invalid ? 'none' : '')};
  cursor: pointer;
  height: 45px;
  /* width: 250px; */
  border: 1px solid ${(props) => props.theme.palette.yellowGray};
  /* border-right: 1px solid ${(props) => props.theme.palette.yellowGray};
  border-left: 1px solid ${(props) => props.theme.palette.yellowGray};
  border-bottom: 1px solid ${(props) => props.theme.palette.yellowGray}; */
  /* box-shadow: 4px 3px 5px 0px rgba(0, 0, 0, 0.05); */
  position: -webkit-sticky; /* for Safari */
  -webkit-transform: translateZ(0);
  /* display: flex; */
  justify-content: space-between;
  padding: 6px 0.75rem;
  gap: 0.25rem;

  display: flex;
  flex-direction: column;

  cursor: pointer;

  /* :first-child {
    display: grid;
    place-items: center;
  } */

  > span {
    font-size: 0.75rem;
    &.filter-product {
      width: 90%;
      padding-left: 30px;
      height: 32px;
      line-height: 32px;
      position: absolute;
      background: ${(props) => props.theme.palette.white};
      color: ${(props) =>
        props.isBlue ? props.theme.palette.blue : '#9ca3af'};
      border-radius: 0.5rem;
      cursor: pointer;

      > svg {
        position: absolute;
        top: 12%;
        left: 5%;
      }

      > div {
        padding-left: 15px;
        font-size: 16px;
      }
    }
  }
`;

export const CenterColumn = styled.div`
  width: 100%;
  align-self: stretch;
`;

export const OperationPanel = styled.div`
  padding: 2rem 2rem 0 2rem;
`;

export const ButtonArea = styled.div`
  width: 100%;
  display: flex;
  /* justify-content: center; */
  gap: 1rem;
  padding: 1rem;
  background-color: white;
`;

export const PdfView = styled.div`
  /* background: #f8f7f4; */
  /* background: #e7e2dd; */
  width: 100%;
  padding: 2rem;
  /* padding: 0 4rem 4rem; */
`;

export const OrderItemNotAvailable = styled.div`
  /* width: 250px; */
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
  padding: 6px 0.75rem;
  font-size: 14px;
  :first-child {
    border-top: 1px solid #f5f2e6;
  }
`;

export const PurchaseOrderItem = styled.div<{
  index: number;
  selectedIndex: boolean;
}>`
  /* height: 78px; */
  /* height: 52px; */
  /* width: 250px; */
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
  padding: 6px 0.75rem;
  border-right: 1px solid ${(props) => props.theme.palette.yellowGray};
  border-left: 1px solid ${(props) => props.theme.palette.yellowGray};
  border-bottom: 1px solid ${(props) => props.theme.palette.yellowGray};
  background: ${(props) => (props.index % 2 === 0 ? '#fff' : '#fffef5')};
  border-left: ${(props) =>
    props.selectedIndex
      ? `3px solid  ${props.theme.palette.primary.main}`
      : ''};
  cursor: pointer;
  :first-child {
    border-top: 1px solid #f5f2e6;
  }
`;

export const PurchaseOrderItemRow = styled.div`
  display: flex;
  justify-content: space-between;
`;

export const PurchaseOrderItemDate = styled.div`
  font-size: 14px;
`;

export const KindLabel = styled.div<{
  partial?: boolean;
}>`
  font-size: 0.75rem;
  padding: 2px 6px;
  border-radius: 2px;
  border: 1px solid ${(props) => props.theme.palette.primary.main};
  color: ${(props) => props.theme.palette.primary.main};
  white-space: nowrap;
  height: 18px;
  font-weight: bold;
  background: ${(props) => (props.partial ? 'rgba(43, 169, 209, 0.3)' : '')};
`;

export const KindLabelFailed = styled(KindLabel)`
  border: 1px solid ${(props) => props.theme.palette.error};
  color: ${(props) => props.theme.palette.error};
`;

export const FaxFailedLabel = styled.div`
  font-size: 0.75rem;
  padding: 2px 6px;
  border-radius: 2px;
  border: 1px solid ${(props) => props.theme.palette.error};
  color: ${(props) => props.theme.palette.error};
  white-space: nowrap;
  height: 18px;
  font-weight: bold;
  margin-right: 8px;
`;

export const KindLabelFill = styled(KindLabel)`
  background: ${(props) => props.theme.palette.primary.main};
  color: ${(props) => props.theme.palette.white};
`;

export const PurchaseOrderItemName = styled.div`
  font-size: 14px;
  color: ${(props) => props.theme.palette.primary.main};
`;

export const RightSide = styled.div`
  width: 500px;
  /* display: flex; */
  /* flex-direction: column; */
  gap: 0.5rem;
  padding: 2rem 0.75rem 2rem 0;
`;

export const DetailList = styled.div`
  padding: 1rem 0.5rem;
  height: 132px;
  background-color: ${(props) => props.theme.palette.white};
  overflow-y: scroll;
`;

export const DetailItem = styled.div`
  display: flex;
  align-items: center;
  gap: 0.5rem;
`;

export const DetailItemText = styled.div`
  line-height: 1.2;
`;

export const SearchConditionNameList = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 2px;
  width: 100%;
`;

export const FilterIconWrapper = styled.div`
  width: 24px;
  height: 24px;
  cursor: pointer;
`;

export const SearchConditionLabel = styled.div`
  max-width: 188px;
  font-size: 0.75rem;
  padding: 2px 6px;
  border-radius: 2px;
  border: 1px solid ${(props) => props.theme.palette.primary.main};
  color: ${(props) => props.theme.palette.primary.main};
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  height: 18px;
`;

export const TelWrapper = styled.div`
  text-align: end;
  padding-right: 1rem;
  background-color: ${(props) => props.theme.palette.white};
`;

export const OperationPanelUpper = styled.div`
  display: flex;
  justify-content: space-between;
  padding: 1rem;
  background-color: ${(props) => props.theme.palette.white};
`;
export const OperationPanelDestName = styled.div`
  font-size: 1.5rem;
  font-weight: bold;
`;
export const OrderAndFaxStatus = styled.div`
  display: flex;
  justify-content: end;
  width: 140px;
`;
