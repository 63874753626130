import { FC } from 'react';
import * as S from './StockAllocationTransferSelect.styles';
import { PRODUCT_KIND, Storeroom, Todo } from '@lib/common/type';
import ReactSelect from 'react-select';
import { NO_OPTION_JAPANESE } from '@lib/pc/common/constants';

type Props = {
  data: Storeroom[] | undefined;
  value: number | undefined;
  productKind: number;
  width?: string;
  height?: string;
  marginTop?: string;
  onChange?: (selectedCaseId: number, index?: number) => void;
  handleChangeTransfer: (id: number) => void;
  disabled?: boolean;
  fromEditModal?: boolean;
  menuMarginTop?: string;
};

const StockAllocationTransferSelect: FC<Props> = ({
  data,
  value,
  productKind,
  width,
  height,
  marginTop,
  onChange,
  handleChangeTransfer,
  disabled,
  fromEditModal,
  menuMarginTop,
}: Props) => {
  const toReactSelectOptions = (options: { id: number; name: string }[]) =>
    options.map(({ id, name }) => ({
      value: id,
      label: name,
    }));

  const allProductTransferOptions = [{ id: 0, name: '全ての払出元' }];
  const productTransferOptions = [
    { id: -1, name: '店舗販売' },
    { id: -2, name: '廃棄' },
    { id: -3, name: '消費' },
  ];
  const semiProductTransferOptions = [
    { id: -2, name: '廃棄' },
    { id: -3, name: '消費' },
  ];

  const reactSelectOptions =
    productKind === PRODUCT_KIND.PRODUCT
      ? data && toReactSelectOptions(productTransferOptions.concat(data))
      : productKind === PRODUCT_KIND.SEMI_PRODUCT
      ? data && toReactSelectOptions(semiProductTransferOptions.concat(data))
      : data && toReactSelectOptions(allProductTransferOptions.concat(data));

  // 払出先追加用オプション
  const reactSelectOptionsForEditModal =
    productKind === PRODUCT_KIND.PRODUCT
      ? data && toReactSelectOptions(productTransferOptions.concat(data))
      : data && toReactSelectOptions(semiProductTransferOptions.concat(data));

  const currentValue = fromEditModal
    ? reactSelectOptionsForEditModal?.find((o) => o.value === value)
    : reactSelectOptions?.find((o) => o.value === value);

  const styles = {
    control: (baseStyles: Todo) => ({
      ...baseStyles,
      width: width ? width : '237px',
      height: height ? height : '56px',
      marginTop: marginTop ? marginTop : '',
      borderRadius: '0.5rem',
      fontWeight: 400,
      textAlign: 'left',
      borderStyle: 'hidden',
      outline: '0.5px solid rgba(0,0,0,0.08)',
      backgroundColor: disabled ? '' : 'white',
      cursor: 'pointer',
      boxShadow: 'none',
      '@media (max-width: 599px)': {
        width: '16rem',
      },
    }),
    menu: (baseStyles: Todo) => ({
      ...baseStyles,
      top: '37px',
      zIndex: 1002,
      marginTop: menuMarginTop ? menuMarginTop : '',
    }),
    option: (baseStyles: Todo) => ({
      ...baseStyles,
      textAlign: 'left',
      fontWeight: 400,
      fontSize: '15px',
    }),
    menuList: (baseStyles: Todo) => ({
      ...baseStyles,
      maxHeight: '100px',
    }),
  };

  return (
    <S.Wrapper>
      <ReactSelect
        options={
          fromEditModal ? reactSelectOptionsForEditModal : reactSelectOptions
        }
        value={currentValue ? currentValue : null}
        onChange={(option) => {
          option && handleChangeTransfer(option.value);
          option && onChange?.(option.value);
        }}
        styles={styles}
        isDisabled={disabled}
        isSearchable={false}
        components={{
          IndicatorSeparator: () => null,
        }}
        noOptionsMessage={() => NO_OPTION_JAPANESE}
        placeholder="払出先の選択"
      />
    </S.Wrapper>
  );
};

export default StockAllocationTransferSelect;
