import React from 'react';
import { useParams } from 'react-router-dom';
import { WithHeader } from '@components/templates/WithHeader';
import { Heading } from '@components/elements/typographies/Heading';
import * as S from './ShippingGroupMasterEdit.styles';
import useShippingDestination from '@lib/pc/settings/shipping_group_master_edit/useShippingDestination';
import ShippingGroupEditWrapper from '@components/modules/pc/settings/shipping_group_setting/ShippingGroupEdit/ShippingGroupEditWrapper/ShippingGroupEditWrapper';
import useShippingGroupsStorerooms from '@lib/pc/settings/shipping_group_master_edit/useShippingGroupsStorerooms';

export const ShippingGroupMasterEditRoot: React.FC = () => {
  const { id, timestamp } = useParams<{ id: string; timestamp: string }>();
  const { shippingDestinations, refetch } = useShippingDestination(id);
  const { shippingGroupsStorerooms, refetch: shippingGroupsStoreroomsRefetch } =
    useShippingGroupsStorerooms(id);

  return (
    <WithHeader>
      <S.Wrapper>
        <S.HeadContainer>
          <S.FlexBaseline>
            <Heading tag="span" text="出荷先グループの編集" fs="32" fw="lg" />
          </S.FlexBaseline>
        </S.HeadContainer>
        <ShippingGroupEditWrapper
          shippingDestinations={shippingDestinations}
          refetch={refetch}
          shippingGroupsStorerooms={shippingGroupsStorerooms}
          shippingGroupsStoreroomsRefetch={shippingGroupsStoreroomsRefetch}
          timestamp={timestamp}
        />
      </S.Wrapper>
    </WithHeader>
  );
};
