import { axiosInstance } from '@lib/pc/common/api/axiosConfig';
import { useQuery } from 'react-query';
import humps from 'humps';
import moment from 'moment';
import { Todo } from '@lib/common/type';

export const useStockAllocations = (
  date: Date,
  storeroomId: number | undefined
) => {
  const { data, refetch } = useQuery(
    ['stock_allocations', date, storeroomId],
    async () => {
      if (storeroomId === undefined) {
        return;
      }
      const data = await axiosInstance().get(
        `/api/v1/product_stock_allocations?date=${moment(date).format(
          'YYYYMMDD'
        )}&storeroom_id=${storeroomId}`
      );

      return data;
    },
    {
      enabled: storeroomId !== undefined, // storeroomIdがundefinedでない場合にのみクエリを有効化する
    }
  );

  const stockAllocations = data && (humps.camelizeKeys(data.data) as Todo);

  return { stockAllocations, refetch };
};

export default useStockAllocations;
