import { FC, useEffect, useState } from 'react';
import moment from 'moment';
import * as S from './TableWithFixedSidebar.styles';
import Filter from '@assets/icons/filter_list';
import { ReactComponent as FilteredIcon } from '@assets/icons/filtered.svg';
import Overlay from '@components/elements/backdrops/Overlay/Overlay';
import TagMenu from '@components/modules/common/TagMenu/TagMenu';
import TagListModal from '@components/modules/common/TagListModal/TagListModal';
import { generateMonthlyDateArray } from '@lib/pc/stock_status/functions';
import { isSameIndex, addComma } from '@lib/pc/production_schedule/functions';
import { tagSearch } from '@lib/common/functions';
import { CircularProgress } from '@material-ui/core';
import {
  Todo,
  Tag,
  MATERIAL_PRODUCT_KIND,
  stockAllocationsAll,
  MATERIAL_TYPE,
} from '@lib/common/type';
import { MaterialStockAllocationDetail } from '@lib/pc/material_allocation_status/type';

type Props = {
  selectedDate: moment.Moment;
  data: stockAllocationsAll[] | null;
  selected: boolean;
  selectIndex: number;
  setSelectIndex: (index: number) => void;
  handleSelectMaterial: (
    d: {
      name: string;
      type: '原' | '資';
      date: Date;
      materialStockAllocationDetails: MaterialStockAllocationDetail[];
      labelOfAmount: string;
      labelOfUnit: string;
      storeroomIds: number[];
    },
    selectedCell: string
  ) => void;
  setTagListModalActive?: () => void;
  rightSlideSidebarActive: boolean;
  selectedCell: string;
  handleOpenProductDetail: (item: Todo) => void;
};

const TableWithFixedSidebar: FC<Props> = ({
  selectedDate,
  data,
  selected,
  handleSelectMaterial,
  rightSlideSidebarActive,
  selectedCell,
  selectIndex,
  setSelectIndex,
  handleOpenProductDetail,
}: Props) => {
  const [searchWord, setSearchWord] = useState('');
  const [tagMenuActive, setTagMenuActive] = useState(false);
  const [tagListModalActive, setTagListModalActive] = useState(false);
  const [selectedTags, setSelectedTags] = useState<Tag[]>([]);
  const selectedTagIds = selectedTags.map((item: Tag) => item['id']);

  const MaterialNameArr = data
    ? data.map((p: Todo) => ({
        id: p.id,
        labelOfAmount: p.labelOfAmount,
        labelOfUnit: p.labelOfUnit,
        materialCode: p.materialCode,
        materialType: p.materialType,
        name: p.name,
        nameAbbreviation: p.nameAbbreviation,
        nameKana: p.nameKana,
        numberOfDaysParamsMonth: p.numberOfDaysParamsMonth,
        storeroomsIds: p.storeroomsIds,
        tagIds: p.tagIds,
        allocations: p.materialStockAllocations,
      }))
    : [];

  const handleSearch = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSearchWord(e.target.value);
  };

  useEffect(() => {
    setTagMenuActive(false);
  }, [selectedTagIds.length === 0]);

  return (
    <>
      <div>
        <S.ListWrapper>
          <S.ListSidebar>
            <S.ListLeftHeadCell
              invalid={rightSlideSidebarActive}
              onClick={() => setTagMenuActive(!tagMenuActive)}
              isBlue={searchWord !== '' || selectedTagIds.length > 0}
            >
              <span className="filter-product">
                {searchWord !== '' || selectedTagIds.length > 0 ? (
                  <>
                    <FilteredIcon />
                    <div className="filter_text">資材・原材料の絞込み中</div>
                  </>
                ) : (
                  <>
                    <Filter
                      isBlue={searchWord !== '' || selectedTagIds.length > 0}
                    />
                    <div className="filter_text">資材・原材料の絞込</div>
                  </>
                )}
              </span>
            </S.ListLeftHeadCell>

            {MaterialNameArr.filter(
              (p: Todo) =>
                p.name.includes(searchWord) ||
                p.nameAbbreviation?.includes(searchWord) ||
                p.nameKana?.includes(searchWord) ||
                p.materialCode?.toString().includes(searchWord)
            )
              .filter((p: Todo) => tagSearch(p, selectedTagIds))
              .map(
                (
                  s: {
                    id: number;
                    name: string;
                    nameAbbreviation: string;
                    nameKana: string;
                    materialCode: string | number;
                    materialType: number;
                    numberOfDaysParamsMonth: number;
                  },
                  i: number
                ) => {
                  return (
                    <S.ListLeftHeadCell
                      key={i}
                      onClick={() => handleOpenProductDetail(s)}
                    >
                      <S.ListLeftHeadName>
                        <div>
                          {s.nameAbbreviation ? s.nameAbbreviation : s.name}
                        </div>
                        <S.MaterialTypeDiv productKind={s.materialType}>
                          {s.materialType === MATERIAL_TYPE.ROW_MATERIAL
                            ? '原'
                            : '資'}
                        </S.MaterialTypeDiv>
                      </S.ListLeftHeadName>
                      <S.ListLeftHeadMeta>
                        <span>{s.materialCode}</span>
                      </S.ListLeftHeadMeta>
                    </S.ListLeftHeadCell>
                  );
                }
              )}
          </S.ListSidebar>
          <S.ListBody className={selected ? 'move-to-left' : ''}>
            <div>
              {data && (
                <S.ListDateRow>
                  {generateMonthlyDateArray(
                    data[0].numberOfDaysParamsMonth,
                    selectedDate,
                    selectIndex,
                    2
                  ).map((p, i) => {
                    const isToday = isSameIndex(
                      i,
                      selectIndex,
                      data[0].numberOfDaysParamsMonth
                    );
                    return (
                      <S.ListHeadCell
                        key={i}
                        index={i}
                        numOfIndex={data[0].numberOfDaysParamsMonth}
                        highLighten={isToday}
                        dayOfWeek={moment(p.productionDate).format('ddd')}
                        onClick={() => setSelectIndex(i)}
                      >
                        {i === 0
                          ? moment(p.productionDate).format('M/D(ddd)')
                          : i === data[0].numberOfDaysParamsMonth + 1
                          ? '合計'
                          : moment(p.productionDate).format('DD(ddd)')}
                      </S.ListHeadCell>
                    );
                  })}
                </S.ListDateRow>
              )}
            </div>
            {data ? (
              data
                .filter(
                  (p: Todo) =>
                    p.name.includes(searchWord) ||
                    p.nameAbbreviation?.includes(searchWord) ||
                    p.nameKana?.includes(searchWord) ||
                    p.productCode?.toString().includes(searchWord)
                )
                .filter((p: Todo) => tagSearch(p, selectedTagIds))
                .map((p: Todo, i: number) => {
                  return (
                    <S.ListRow key={i}>
                      {generateMonthlyDateArray(
                        data[0].numberOfDaysParamsMonth,
                        selectedDate,
                        selectIndex,
                        2
                      ).map((d: Todo, idx: number) => {
                        const isToday = isSameIndex(
                          idx,
                          selectIndex,
                          data[0].numberOfDaysParamsMonth
                        );
                        return (
                          <S.ListCellContainer
                            invalid={rightSlideSidebarActive}
                            key={idx}
                            index={i}
                            numOfIndex={data[0].numberOfDaysParamsMonth}
                            highLighten={isToday}
                            noData={
                              p.materialStockAllocations[idx]
                                .materialStockAllocationDetails.length === 0
                            }
                            withComment={p.materialStockAllocations[
                              idx
                            ].materialStockAllocationDetails.some(
                              (detail: MaterialStockAllocationDetail) =>
                                detail.comment
                            )}
                            onClick={() => {
                              handleSelectMaterial(
                                {
                                  date: p.materialStockAllocations[idx].date,
                                  name: p.name,
                                  type:
                                    p.materialType ===
                                    MATERIAL_TYPE.ROW_MATERIAL
                                      ? '原'
                                      : '資',
                                  materialStockAllocationDetails:
                                    p.materialStockAllocations[idx]
                                      .materialStockAllocationDetails,
                                  labelOfAmount: p.labelOfAmount,
                                  labelOfUnit: p.labelOfUnit,
                                  storeroomIds: p.storeroomIds,
                                },
                                `${p.id}-${idx}`
                              );
                            }}
                            className={
                              `${p.id}-${idx}` === selectedCell
                                ? 'select-cell'
                                : ''
                            }
                          >
                            {addComma(
                              p.materialStockAllocations[idx].totalAmount
                            )}
                          </S.ListCellContainer>
                        );
                      })}
                    </S.ListRow>
                  );
                })
            ) : (
              <S.CircularIconWrapperOuter>
                <S.CircularIconWrapper>
                  <CircularProgress style={{ color: '#64b2f9' }} />
                </S.CircularIconWrapper>
              </S.CircularIconWrapperOuter>
            )}
          </S.ListBody>
        </S.ListWrapper>
      </div>
      {tagMenuActive && (
        <TagMenu
          searchParam={searchWord}
          selectedTags={selectedTags}
          setSelectedTags={(tags) => setSelectedTags(tags)}
          setOpenTagModal={() => setTagListModalActive(true)}
          handleChange={handleSearch}
          deleteSearchParam={() => setSearchWord('')}
          title="資材・原材料"
        />
      )}
      {tagListModalActive && (
        <TagListModal
          selectedTags={selectedTags}
          setSelectedTags={(tags) => setSelectedTags(tags)}
          tagListModalActive={tagListModalActive}
          handleClose={() => {
            setTagListModalActive(false);
            setTagMenuActive(false);
          }}
          productKind={MATERIAL_PRODUCT_KIND.MATERIAL}
        />
      )}
      {tagMenuActive && (
        <Overlay
          zIndex={9997}
          handleClick={() => {
            setTagMenuActive(false);
          }}
        />
      )}
    </>
  );
};

export default TableWithFixedSidebar;
