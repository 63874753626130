import styles from 'styled-components';

export const Wrapper = styles.div`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 1.75rem 0;
  position: relative;
`;

export const BackButton = styles.button`
  position: absolute;
  padding: 0.875rem 1rem;
  background: none;
  top: 1.75rem;
  left: 1.5rem;
`;

export const TitleText = styles.p`
  font-weight: bold;
  font-size: 1.125rem;
`;
