import { axiosInstance } from '@lib/pc/common/api/axiosConfig';
import { useQuery } from 'react-query';
import humps from 'humps';
import { StoreroomWithDisplayOrder } from '@lib/pc/settings/product_master_edit/type';

const useShippingGroupsStorerooms = (shippingGroupId: string) => {
  const { data, refetch } = useQuery(
    ['shippingGroupsStorerooms', shippingGroupId],
    async () => {
      const data = await axiosInstance().get(
        `/api/v1/settings/shipping_groups_storerooms/${shippingGroupId}`
      );
      return data;
    }
  );
  const shippingGroupsStorerooms = humps.camelizeKeys(
    data?.data.shipping_groups_and_storerooms
  ) as StoreroomWithDisplayOrder[];

  return { shippingGroupsStorerooms, refetch };
};

export default useShippingGroupsStorerooms;
