import { useQuery } from 'react-query';
import humps from 'humps';
import { axiosInstance } from '@lib/pc/common/api/axiosConfig';
import { Todo } from '@lib/common/type';

const useCustomer = (customerId: string) => {
  const { data, refetch } = useQuery(['customer', customerId], async () => {
    const data = await axiosInstance().get(
      `/api/v1/settings/customer/${customerId}`
    );
    return data;
  });
  const customer = humps.camelizeKeys(data?.data) as Todo;

  return { customer, refetch };
};

export default useCustomer;
