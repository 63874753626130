import { useParams } from 'react-router';
import { ReactComponent as ChevronLeft } from '@assets/icons/chevron_left.svg';
import { Heading } from '@components/elements/typographies/Heading';
import { pickingStatus } from '@lib/pc/picking/constants';
import * as S from './PickingByShippingDetaiHeader.styles';
import usePicking from '@lib/pc/picking_by_shipping/usePicking';

type Props = {
  setOpenAddPickingProductModal: (openAddPickingProductModal: boolean) => void;
};

const PickingByShippingDetailHeader = ({
  setOpenAddPickingProductModal,
}: Props) => {
  const { id } = useParams<{ id: string }>();
  const { picking: picking } = usePicking(Number(id));

  return (
    <S.Wrapper>
      <div>
        <S.HeadContainer>
          <S.FlexCenter>
            <S.BackButton
              onClick={() => {
                setOpenAddPickingProductModal(false);
              }}
            >
              <ChevronLeft />
            </S.BackButton>

            <S.FlexBaseline>
              <div>
                <Heading
                  tag="span"
                  text={picking?.shippingGroupName}
                  fs="32"
                  fw="lg"
                />
                <Heading
                  tag="span"
                  text="ピッキングリスト（出荷先別）への追加"
                  fs="24"
                  fw="lg"
                />
              </div>
            </S.FlexBaseline>
          </S.FlexCenter>
          <S.FlexCenter>
            <div>
              <S.StatusBar
                color={
                  picking &&
                  pickingStatus[picking?.pickingStatus].statusBarColor
                }
                bgColor={
                  picking &&
                  pickingStatus[picking?.pickingStatus].statusBarBgColor
                }
                borderColor={
                  picking &&
                  pickingStatus[picking?.pickingStatus].statusBorderColor
                }
              >
                {picking && pickingStatus[picking?.pickingStatus].label}
              </S.StatusBar>
            </div>
          </S.FlexCenter>
        </S.HeadContainer>
      </div>
    </S.Wrapper>
  );
};

export default PickingByShippingDetailHeader;
