import { useHistory, useLocation } from 'react-router-dom';
import { ReactComponent as ChevronLeftIcon } from '@assets/icons/mobile/chevron_left.svg';
import * as S from './TitleBar.styles';
import type { Todo } from '@lib/common/type';

const TitleBar = ({
  // productId,
  productKind,
  productName,
  inputAmountParams,
  isChanged,
  setConfirmMsg,
  setConfirmKind,
  setPopUp,
}: {
  productId: number;
  productKind: number;
  productName: string;
  inputAmountParams: Todo;
  isChanged: boolean;
  setConfirmMsg: (confirmMsg: string) => void;
  setConfirmKind: (confirmKind: string) => void;
  setPopUp: (popUp: boolean) => void;
}) => {
  const history = useHistory();
  const { state } = useLocation();

  const onClickGoBack = () => {
    if (inputAmountParams.length > 0 || isChanged) {
      setConfirmMsg('保存せずに編集を終了します。よろしいですか。');
      setConfirmKind('ProductList');
      setPopUp(false);
    } else {
      history.push(`/mobile/products/for_stocks`, state);
    }
  };

  return (
    <S.Wrapper>
      <S.IconWrapper
        onClick={() => {
          onClickGoBack();
        }}
      >
        <S.BackButton>
          <ChevronLeftIcon />
        </S.BackButton>
      </S.IconWrapper>
      <S.TitleText>
        <S.ProductIcon packaged={productKind === 1 ? true : false}>
          {productKind === 1 ? '完' : '半'}
        </S.ProductIcon>
        {`${productName}`}
      </S.TitleText>
      {/* 一旦非表示 */}
      {/* <S.StockListLink
        to={`/mobile/products/${productId}/stocks/${productKind}`}
      >
        <TableChartBlueIcon />
      </S.StockListLink> */}
    </S.Wrapper>
  );
};
export default TitleBar;
