import { useState } from 'react';
import * as S from './DisplaySelectModal.styles';
import { Button } from '@components/elements/buttons/Button';
import type { FunctionType } from '@lib/common/type';

type Props = {
  notSelectableObj: string;
  selectedTypes: FunctionType[];
  setSelectedTypes: (type: FunctionType[]) => void;
  displaySelectModalActive: boolean;
  handleClose: () => void;
  functionTypeObj: FunctionType[];
};

const DisplaySelectModal: React.FC<Props> = ({
  notSelectableObj,
  selectedTypes,
  setSelectedTypes,
  displaySelectModalActive,
  handleClose,
  functionTypeObj,
}: Props) => {
  const [selectedTypeState, setSelectedTypeState] =
    useState<FunctionType[]>(selectedTypes);

  const filteredFunctionTypeObj = functionTypeObj.filter(
    (f: FunctionType) => f.type !== notSelectableObj
  );

  return (
    <S.Wrapper open={displaySelectModalActive}>
      <S.Container>
        <S.Header>
          <S.Title>表示する項目の選択</S.Title>
        </S.Header>
        <S.ListUl>
          {filteredFunctionTypeObj.map((value: FunctionType, i: number) => (
            <S.ListLi key={i}>
              <S.Label>
                <S.Checkbox
                  type="checkbox"
                  name="list"
                  value="type_list"
                  onChange={() =>
                    selectedTypeState.some((t) => t.id === value.id)
                      ? setSelectedTypeState(
                          selectedTypeState.filter((t) => t.id !== value.id)
                        )
                      : setSelectedTypeState([...selectedTypeState, value])
                  }
                  defaultChecked={selectedTypeState.some(
                    (t) => t.id === value.id
                  )}
                />
                <S.TypeName
                  index={i === 0}
                  defaultChecked={selectedTypeState.some(
                    (t) => t.id === value.id
                  )}
                >
                  {value.type}
                </S.TypeName>
              </S.Label>
            </S.ListLi>
          ))}
        </S.ListUl>
      </S.Container>
      <S.ButtonWrapper>
        <S.ButtonContainer>
          <Button borderWidth={1} outlined={true} onClick={handleClose}>
            キャンセル
          </Button>
          <Button
            onClick={() => {
              setSelectedTypes(selectedTypeState);
              handleClose();
            }}
          >
            決定
          </Button>
        </S.ButtonContainer>
      </S.ButtonWrapper>
    </S.Wrapper>
  );
};
export default DisplaySelectModal;
