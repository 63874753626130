import styled from 'styled-components';

export const SearchConditionPanel = styled.div`
  display: flex;
  align-items: center;
  margin-top: 1.125rem;
  width: 100%;
  height: 2.5rem;
  padding: 0 0.75rem;
  border-radius: 0.5rem;
  color: ${(props) => props.theme.palette.khaki};
  background: ${(props) => props.theme.palette.white};
`;

export const SearchConditionText = styled.p`
  margin-left: 0.75rem;
`;
export const SearchConditionInput = styled.input`
  margin-left: 0.75rem;
  width: 100%;

  outline: none;

  &::placeholder {
    color: ${(props) => props.theme.palette.khaki};
  }
`;
