import React, { FC } from 'react';

type Props = {
  isBlue?: boolean;
};

const ChevronLeft: FC<Props> = ({ isBlue }) => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M20.5441 9.88L18.6641 8L10.6641 16L18.6641 24L20.5441 22.12L14.4374 16L20.5441 9.88Z"
        fill={isBlue ? 'rgba(43, 169, 209, 1)' : '#191712'}
      />
    </svg>
  );
};

export default ChevronLeft;
