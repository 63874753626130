import styled from 'styled-components';

export const DContainer = styled.div`
  display: flex;
  gap: 0.5rem;
  justify-content: left;
  padding-top: 20px;
`;

export const FormWrapper = styled.div<{
  setDeleteId: boolean;
}>`
  width: 100%;
  pointer-events: ${(props) => (props.setDeleteId ? 'none' : '')};
`;

export const DBoxContainer = styled.div<{
  border: string;
  bgColor: string;
  color: string;
}>`
  width: 3.5rem;
  height: 7.5rem;
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: 700;
  font-size: 20px;
  line-height: 18px;
  background-color: ${(props) => props.bgColor};
  border: ${(props) => `4px solid ${props.border}`};
  color: ${(props) => props.color};
`;

export const DExpirationDateAndCase = styled.div`
  width: 100%;
`;

export const DExpirationDate = styled.div`
  width: 100%;
  font-weight: 700;
  font-size: 16px;
  margin-bottom: 0.5rem;
  border-top: 2px solid #d3d3d3;
  padding-top: 1rem;

  > label {
    text-align: center;
  }
`;

export const DateWrapper = styled.div`
  display: flex;
  position: relative;
  text-align: center;
  margin-left: 0.75rem;
`;

export const ProductionOrShippingDateLabel = styled.label`
  width: 95px;
  display: block;
  font-size: 15px;
  display: flex;
  align-items: center;
`;

export const DRightBottomWrapper = styled.div`
  display: flex;
  align-items: center;
  padding-bottom: 0.5rem;
`;

export const DInputContainer = styled.div<{
  center?: boolean;
}>`
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 6px;
  > label {
    font-weight: 700;
    font-size: 13px;
  }
  > input {
    height: 44px;
    max-width: 6.75rem;
    width: 100%;
    border-radius: 8px;
    text-align: ${(props) => (props.center ? 'center' : 'right')};
    padding-right: 12px;

    :focus {
      outline: none;
    }

    :disabled {
      border: 2px solid #ffffff;
      background-color: transparent;
    }
  }

  > span {
    position: absolute;
    right: 0.3rem;
    top: 80%;
    font-size: 12px;
    margin-left: 4px;
    color: #6b675c;
  }
`;

export const DSymbol = styled.div`
  margin-top: 1rem;
  padding: 0 5px;
  font-weight: 700;
  font-size: 13px;
`;

export const Input = styled.input<{
  labelLength?: boolean;
}>`
  width: 54%;
  height: 44px;
  vertical-align: middle;
  color: ${(props) => props.theme.palette.baseBlack};
  font-size: 1rem;
  line-height: 1.25rem;
  padding: 0.325rem;
  background: ${(props) => props.theme.palette.white};
  border: none;
  border-radius: 0.5rem;
  border: 1px solid rgba(0, 0, 0, 0.08);
  /* margin-left: ${(props) => (props.labelLength ? '0.35rem' : '0.35rem')}; */
  -webkit-appearance: none;
  @media (max-width: 599px) {
    width: 58%;
  }
  ::-webkit-date-and-time-value {
    text-align: left;
    padding-left: 8px;
  }
  input[type='date']::-webkit-clear-button {
    -webkit-appearance: none;
    display: none;
  }
`;

export const TimeInput = styled(Input)`
  cursor: pointer;
  ::-webkit-calendar-picker-indicator {
    -webkit-appearance: none;
  }
`;

export const DeleteButton = styled.button`
  background: ${(props) => props.theme.palette.beige};
  display: flex;
  align-items: center;
  padding: 0.7rem 0.5rem 1.3rem 1.5rem;
`;

export const DeleteModalWrapper = styled.div`
  background: ${(props) => props.theme.palette.white};
  position: fixed;
  left: 50%;
  top: 50%;
  width: 90%;
  transform: translate(-50%, -50%);
  z-index: 10001;
  box-shadow: 0px 6px 10px rgba(0, 0, 0, 0.07), 0px 1px 18px rgba(0, 0, 0, 0.06),
    0px 3px 5px rgba(0, 0, 0, 0.1);
  border-radius: 8px;
  padding: 1rem;
`;

export const DeleteModalTextContainer = styled.div`
  display: flex;
  flex-direction: column;
  font-weight: 500;
  font-size: 1rem;
  line-height: 27px;
`;

export const DeleteModalButtonContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 1rem;
  margin-top: 1rem;
  button {
    width: 100%;
    height: 2.75rem;
    &:nth-child(2) {
      border-color: ${(props) => props.theme.palette.error};
      color: ${(props) => props.theme.palette.error};
    }
  }
`;
