import { useState, useRef, useEffect } from 'react';
import Scanner from './Scanner';
import scanSound from '@assets/sounds/sound.mp3';
import { ReactComponent as CameraIcon } from '@assets/icons/mobile/camera.svg';
import { useHistory, useLocation } from 'react-router-dom';
import * as S from './RealTimeScanner.styles';
import Close from '@assets/icons/mobile/close';
import type { Product, SearchState } from '@lib/common/type';
import { Todo, PRODUCT_KIND } from '@lib/common/type';
import moment from 'moment';

const RealtimeScannerPage = () => {
  const [audio] = useState(new Audio(scanSound));
  const [scanning, setScanning] = useState(false);
  const [results, setResults] = useState<Todo>([]);
  const [notFoundJanCode, setNotFoundJanCode] = useState(false);
  const scannerRef = useRef<Todo>(null);
  const history = useHistory();
  const { state } = useLocation<SearchState>();
  const storeroomId = location.pathname.replace('/mobile/live/', '');

  useEffect(() => {
    if (scannerRef.current) {
      setScanning(true);
    }
  }, []);

  useEffect(() => {
    if (results.length !== 0) {
      const getData = state.stateStocksData.filter(
        (stock: Product) =>
          (stock.janCode === results[0].codeResult.code &&
            stock.storeroomIds.includes(Number(storeroomId))) ||
          (stock.productCode === results[0].codeResult.code &&
            stock.storeroomIds.includes(Number(storeroomId)))
      );
      if (
        !!getData &&
        getData.length > 0 &&
        getData[0].productKind === PRODUCT_KIND.PRODUCT
      ) {
        history.push(
          `/mobile/products/${getData[0].id}/stocks/${
            PRODUCT_KIND.PRODUCT
          }/${moment().format('YYYY-MM-DD')}/${storeroomId}/edit`,
          state
        );
      } else if (
        !!getData &&
        getData.length > 0 &&
        getData[0].productKind === PRODUCT_KIND.SEMI_PRODUCT
      ) {
        history.push(
          `/mobile/products/${getData[0].id}/stocks/${
            PRODUCT_KIND.SEMI_PRODUCT
          }/${moment().format('YYYY-MM-DD')}/${storeroomId}/edit`,
          state
        );
      } else {
        // 該当のjanCodeがなかった場合
        setNotFoundJanCode(!notFoundJanCode);
      }
    }
  }, [results]);

  return (
    <div
      ref={scannerRef}
      style={{
        height: '100vh',
        width: '100vw',
        position: 'relative',
        backgroundColor: 'black',
      }}
    >
      <S.CameraMessage>
        <S.JanCode>商品コード</S.JanCode>を
        <S.ReadMessage>読み取ってください</S.ReadMessage>
      </S.CameraMessage>
      <S.Header>
        <div onClick={() => history.push(`/mobile/products/for_stocks`, state)}>
          <Close isWhite={true} />
        </div>
        <div>バーコード読み取り</div>
        <div />
      </S.Header>

      <div
        className="detect-area"
        style={{
          position: 'absolute',
          top: 0,
          left: 0,
          right: 0,
          bottom: 0,
        }}
      ></div>
      <canvas
        className="drawingBuffer"
        style={{
          position: 'absolute',
          top: '0px',
          left: '0px',
          height: '100%',
          width: '100%',
        }}
      />
      {scanning ? (
        <Scanner
          scannerRef={scannerRef}
          onDetected={(result: Todo) => {
            setScanning(false);
            audio.play();
            setResults([...results, result]);
          }}
        />
      ) : (
        notFoundJanCode && (
          <S.NotFoundMessage>
            該当の商品が見つかりませんでした。
            <S.ReShootButton
              onClick={() => {
                setResults([]);
                setScanning(true);
                setNotFoundJanCode(false);
              }}
            >
              <CameraIcon />
              再度読み取る
            </S.ReShootButton>
          </S.NotFoundMessage>
        )
      )}
    </div>
  );
};

export default RealtimeScannerPage;
