import { useState } from 'react';
import * as S from './CaseSettingsModal.styles';
import { WithHeader } from '@components/templates/WithHeader';
import { Heading } from '@components/elements/typographies/Heading';
import { Button } from '@components/elements/buttons/Button';
import { ReactComponent as CloseIcon } from '@assets/icons/close.svg';
import { ReactComponent as SearchIcon } from '@assets/icons/search.svg';
import { Todo } from '@lib/common/type';
import UpdatePopUp from '@components/modules/pc/settings/common/UpdatePopUp/UpdatePopUp';
import TagDeleteConfirmModal from '@components/modules/pc/settings/common/TagDeleteConfirmModal/TagDeleteConfirmModal';
import Overlay from '@components/elements/backdrops/Overlay/Overlay';
import TagEditMenuModal from '../TagEditMenuModal/TagEditMenuModal';
import useDeleteCaseMutation from '@lib/pc/settings/product_master_setting/useDeleteCaseMutation';
import { caseDuplicateCheck } from '@lib/pc/settings/product_master/functions';
import {
  CaseForSetting,
  CaseNameInputParam,
  TagForSetting,
} from '@lib/pc/settings/product_master_setting/type';
import useCreateCaseMutation from '@lib/pc/settings/product_master_setting/useCreateCase';
import useUpdateCaseMutation from '@lib/pc/settings/product_master_setting/useUpdateCase';
import ErrorMsgPopUp from '@components/modules/common/ErrorMsgPopUp/ErrorMsgPopUp';

type Props = {
  data: CaseForSetting[];
  handleClose: () => void;
  handleSuccessCaseSettings: () => void;
  casePopUp: boolean;
  setCasePopUp: (popUp: boolean) => void;
  newCase: CaseNameInputParam;
  setNewCase: (newCase: CaseNameInputParam) => void;
  editCase: CaseNameInputParam;
  setEditCase: (newCase: CaseNameInputParam) => void;
  isCaseChanged: boolean;
  setIsCaseChanged: (isCaseChanged: boolean) => void;
  editInputOpen: boolean;
  setEditInputOpen: (editInputOpen: boolean) => void;
  caseDelConfirmOpen: boolean;
  setCaseDelConfirmOpen: (caseDelConfirmOpen: boolean) => void;
  deleteCase: CaseForSetting;
  setDeleteCase: (deleteCase: CaseForSetting) => void;
  deleteError: boolean;
  setDeleteError: (deleteError: boolean) => void;
  msgKind: string;
  setMsgKind: (msgKind: string) => void;
  newProductAndCaseIds: number[];
};

const CaseSettingsModal = ({
  data,
  handleClose,
  handleSuccessCaseSettings,
  casePopUp,
  setCasePopUp,
  newCase,
  setNewCase,
  editCase,
  setEditCase,
  isCaseChanged,
  setIsCaseChanged,
  editInputOpen,
  setEditInputOpen,
  caseDelConfirmOpen,
  setCaseDelConfirmOpen,
  deleteCase,
  setDeleteCase,
  deleteError,
  setDeleteError,
  msgKind,
  setMsgKind,
  newProductAndCaseIds,
}: Props) => {
  // 編集、削除メニューの表示
  const [editMenuOpen, setEditMenuOpen] = useState(false);
  const [editData, setEditData] = useState<Todo>(null);
  const [searchWord, setSearchWord] = useState('');
  const [errMsg, setErrMsg] = useState('');

  const handleSearch = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSearchWord(e.target.value);
  };

  const handleMasterEdit = (t: TagForSetting) => {
    setEditData(t);
    setEditInputOpen(!editInputOpen);
    setEditMenuOpen(!editMenuOpen);
  };

  // 新規タグ追加
  const handleChangeAddTag = (e: React.ChangeEvent<HTMLInputElement>) => {
    setNewCase({ ...newCase, [e.target.name]: e.target.value });
  };

  const handleCheckAndSubmitNewCase = (
    newCase: CaseNameInputParam,
    data: CaseForSetting[]
  ) => {
    const result = caseDuplicateCheck(newCase, data);
    if (result) {
      handleSubmitNewCase(newCase);
      setMsgKind('create');
    } else {
      setErrMsg('同じケース名が登録済みです。');
    }
  };

  const handleSubmitNewCase = (newCase: CaseNameInputParam) => {
    createTagMutation.mutate(newCase);
  };

  const createTagMutation = useCreateCaseMutation(handleSuccessCaseSettings);

  // 既存タグ編集
  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setIsCaseChanged(true);
    setEditCase({ ...editCase, [e.target.name]: e.target.value });
  };

  const handleCheckAndSubmitCase = (
    editCase: CaseNameInputParam,
    data: CaseForSetting[],
    beforeEditCase: string
  ) => {
    const result = caseDuplicateCheck(editCase, data, beforeEditCase);
    if (result) {
      handleSubmit(editCase);
      setMsgKind('update');
    } else {
      setErrMsg('同じケース名が登録済みです。');
    }
  };

  const handleSubmit = (editCase: CaseNameInputParam) => {
    updateCaseMutation.mutate(editCase);
  };

  const updateCaseMutation = useUpdateCaseMutation(
    editData?.id,
    handleSuccessCaseSettings
  );

  // タグの削除
  const handleDelete = (t: CaseForSetting) => {
    setDeleteCase(t);
    // 削除しますか？のmodal画面表示
    setCaseDelConfirmOpen(!caseDelConfirmOpen);
    setEditMenuOpen(false);
  };

  const handleDeleteCase = () => {
    setMsgKind('delete');
    deleteCaseMutation.mutate();
  };

  const deleteCaseMutation = useDeleteCaseMutation(
    deleteCase.id,
    handleSuccessCaseSettings,
    setDeleteError
  );

  return (
    <>
      <WithHeader>
        <S.Wrapper>
          <S.HeadContainer>
            <div>
              <Heading tag="span" text="ケースの管理" fs="32" fw="lg" />
            </div>
            <S.ModalHeadCloseIcon>
              <CloseIcon
                onClick={() => {
                  handleClose();
                  setCasePopUp(false);
                }}
              />
            </S.ModalHeadCloseIcon>
          </S.HeadContainer>

          <S.CaseAddAndSearchArea>
            <S.InputWrapper>
              <S.Icon>
                <SearchIcon />
              </S.Icon>
              <S.Input
                className="search-input"
                type="text"
                value={searchWord}
                onChange={handleSearch}
              />
            </S.InputWrapper>

            <S.CaseAddWrapper>
              <div className="add-case-name">
                <dt>追加するケース名</dt>
                <dd>
                  <S.Input
                    type="text"
                    name="name"
                    id="name"
                    align="left"
                    padding="0 20px"
                    value={newCase?.name}
                    onChange={handleChangeAddTag}
                    autoComplete="new-password"
                  />
                  <S.ButtonContainer>
                    {newCase?.name !== '' ? (
                      <Button
                        children={'追加'}
                        onClick={() => {
                          handleCheckAndSubmitNewCase(newCase, data);
                        }}
                      />
                    ) : (
                      <Button disabled children={'追加'} />
                    )}
                  </S.ButtonContainer>
                </dd>
              </div>
            </S.CaseAddWrapper>
          </S.CaseAddAndSearchArea>
          {/* コンテンツ */}
          <S.CaseSettingsContents>
            <S.CaseContentsTitle>ケース名</S.CaseContentsTitle>
            <S.CaseContentsWrapper>
              {data &&
                data
                  .filter((d: TagForSetting) => d.name.includes(searchWord))
                  .map((t: TagForSetting) => (
                    <S.CaseRow key={t.id}>
                      {t.relation === false &&
                      !newProductAndCaseIds.includes(t.id) ? (
                        <div
                          className="edit"
                          onClick={() => {
                            setEditMenuOpen(!editMenuOpen);
                            setEditData(t);
                          }}
                        >
                          …
                        </div>
                      ) : (
                        <div className="not-edit" />
                      )}
                      {editData?.id === t.id && editMenuOpen && (
                        <TagEditMenuModal
                          open={editMenuOpen}
                          handleMasterEdit={() => handleMasterEdit(t)}
                          handleDelete={() => handleDelete(t)}
                        />
                      )}
                      {editMenuOpen && (
                        <Overlay handleClick={() => setEditMenuOpen(false)} />
                      )}

                      {editData?.id === t.id && editInputOpen ? (
                        <>
                          <S.Input
                            type="text"
                            name="name"
                            id="name"
                            autoComplete="off"
                            align="left"
                            padding="0 20px"
                            value={isCaseChanged ? editCase.name : t?.name}
                            onChange={handleChange}
                          />
                          <S.ButtonContainer>
                            {isCaseChanged && editCase.name ? (
                              <Button
                                children={'確定'}
                                onClick={() =>
                                  handleCheckAndSubmitCase(
                                    editCase,
                                    data,
                                    t.name
                                  )
                                }
                              />
                            ) : (
                              <S.DisableButton>確定</S.DisableButton>
                            )}
                          </S.ButtonContainer>
                        </>
                      ) : (
                        <div className="code">{t.name}</div>
                      )}
                    </S.CaseRow>
                  ))}
            </S.CaseContentsWrapper>
          </S.CaseSettingsContents>
        </S.Wrapper>
      </WithHeader>
      <UpdatePopUp
        popUp={casePopUp}
        taskKind={'messageOnly'}
        messageKind={msgKind}
        name={editData?.product?.name}
        handleClose={() => {
          setCasePopUp(false);
          setMsgKind('');
        }}
      />
      {caseDelConfirmOpen && (
        <TagDeleteConfirmModal
          tagName={deleteCase.name}
          onClickCancel={() => {
            setCaseDelConfirmOpen(false);
            setDeleteCase({ id: 0, name: '', relation: false });
          }}
          onClickDelete={handleDeleteCase}
        />
      )}
      {deleteError && (
        <ErrorMsgPopUp
          errMsg={'このケースは商品に紐付けられています。'}
          handleClose={() => setDeleteError(false)}
          fromPc={true}
        />
      )}
      <ErrorMsgPopUp
        errMsg={errMsg}
        fromPc={true}
        handleClose={() => setErrMsg('')}
      />
    </>
  );
};

export default CaseSettingsModal;
