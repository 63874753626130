export const TABLE_HEADER_TOP = [
  { key: '半製品名（工程名）', align: 'left', borderTop: true, colSpan: 1 },
  {
    key: '予定数量',
    align: 'right',
    width: '6rem',
    borderLeft: true,
    borderTop: true,
    colSpan: 3,
  },
  {
    key: '',
    align: 'left',
    width: '63px',
    wordBreak: 'keep-all',
    borderRight: true,
    borderTop: true,
    colSpan: 1,
  },
  { key: '時刻', align: 'right', borderTop: true, colSpan: 1 },
  { key: '', align: 'right', borderTop: true, colSpan: 1 },
];

export const TABLE_HEADER_MIDDLE = [
  {
    key: 'ケースの種類',
    align: 'left',
    wordBreak: 'keep-all',
    colSpan: 1,
  },
  {
    key: '実績数量',
    align: 'right',
    width: '6rem',
    borderLeft: true,
    colSpan: 3,
  },
  {
    key: '不良数',
    align: 'left',
    width: '63px',
    wordBreak: 'keep-all',
    borderRight: true,
    colSpan: 1,
  },
  { key: '担当者', align: 'right', colSpan: 2 },
];

export const TABLE_HEADER_BOTTOM = [
  { key: '在庫室', align: 'left', colSpan: 1 },
  {
    key: '保管期限の起算日',
    align: 'left',
    width: '50px',
    wordBreak: 'keep-all',
    borderLeft: true,
    borderTop: true,
    colSpan: 2,
  },
  {
    key: '保管期限日',
    align: 'left',
    width: '50px',
    wordBreak: 'keep-all',
    borderTop: true,
    borderRight: true,
    colSpan: 2,
  },
  {
    key: '備考',
    align: 'left',
    wordBreak: 'keep-all',
    colSpan: 2,
  },
  // { key: '時刻', align: 'right', colSpan: 1 },
];
