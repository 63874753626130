import { useState } from 'react';
import * as S from './StockForm.styles';
import { ReactComponent as DeleteIcon } from '@assets/icons/delete.svg';
import { Button } from '@components/elements/buttons/Button';
import InputComponent from '@components/modules/mobile/products_stocks_edit/SidebarInput/InputComponent';
import { InputParams, selectedItem } from '@lib/pc/stock_status/type';
import type { Case, Todo } from '@lib/common/type';
import { normalizeZeros } from '@lib/stock_status/functions';

type Props = {
  filteredProductCases: Case[] | undefined;
  product: selectedItem;
  inputParams: InputParams;
  setInputParams: (InputParams: InputParams) => void;
  inputAmountParams: Todo;
  setInputAmountParams: (inputAmountParams: Todo) => void;
  newCalcTotal: (
    index: number,
    value: string,
    valueKind: 'bara' | 'case'
  ) => void;
  changeNewDate: (index: number, value: Todo) => void;
  changeNewCase: (index: number, value: number) => void;
  handleDelete: () => void;
  deleteId: number | null;
  setDeleteId: (deleteId: number | null) => void;
  setIsChanged: (isChanged: boolean) => void;
  caseSelectWidth: string;
  caseLabelWidth: string;
  needToSetInitCase: boolean;
  comment: string;
  setComment: (comment: string) => void;
};

const StockForm = ({
  filteredProductCases,
  product,
  inputParams,
  setInputParams,
  inputAmountParams,
  setInputAmountParams,
  newCalcTotal,
  changeNewDate,
  changeNewCase,
  handleDelete,
  deleteId,
  setDeleteId,
  setIsChanged,
  caseSelectWidth,
  caseLabelWidth,
  comment,
  setComment,
}: Props) => {
  const handleComment = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
    setInputParams({ ...inputParams, comment: e.target.value });
    setComment(e.target.value);
    setIsChanged(true);
  };

  // バラとケースの値の更新
  const handleNewOnChange = (
    i: number,
    value: string,
    valueKind: 'bara' | 'case'
  ) => {
    if (value === '' || value === '-' || value.match(/^-?[0-9]*$/)) {
      newCalcTotal(i, normalizeZeros(value), valueKind);
      setIsChanged(true);
    }
  };

  // 賞味期限日の更新
  const handleChangeNewDate = (i: number, value: Date) => {
    changeNewDate(i, value);
    setIsChanged(true);
  };

  // ケース種類の更新
  const handleChangeNewCase = (i: number, value: number) => {
    changeNewCase(i, value);
    setIsChanged(true);
  };

  const [deleteIndex, setDeleteIndex] = useState(0);

  const onClickDelete = () => {
    handleDelete();
    const amountDetail = inputAmountParams.filter(
      (item: Todo) => item !== inputAmountParams[deleteIndex]
    );
    setInputAmountParams(amountDetail);
    setDeleteId(null);
  };

  const handleDeleteButton = (i: number, inputAmountParams: Todo) => {
    if (inputAmountParams[i].amountDetailId) {
      // 登録済みデータの場合
      setDeleteId(inputAmountParams[i].amountDetailId);
      setDeleteIndex(i);
      setIsChanged(true);
    } else {
      // 未登録のデータの場合、増やしたinputコンポーネントの削除
      const amountDetail = inputAmountParams.filter(
        (item: Todo) => item !== inputAmountParams[i]
      );
      setInputAmountParams(amountDetail);
    }
  };

  return (
    <S.Wrapper>
      <S.InputContainer>
        {inputAmountParams &&
          Object.values(inputAmountParams)
            .map((item: Todo, i: number) => {
              if (!product) return;
              return (
                <InputComponent
                  key={`item-${i}`}
                  index={i}
                  type={product.type === '完' ? 'packaged' : 'unpackaged'}
                  stockDateLabel={product.stockDateLabel}
                  labelOfAmount={product.labelOfAmount}
                  newProductionOrShippingDate={item.productionOrShippingDate}
                  onChangeBaraAmount={(value) =>
                    handleNewOnChange(i, value, 'bara')
                  }
                  onChangeCaseAmount={(value) =>
                    handleNewOnChange(i, value, 'case')
                  }
                  onChangeNewDate={(value) => handleChangeNewDate(i, value)}
                  onChangeNewCase={(value) => handleChangeNewCase(i, value)}
                  inputAmountParams={inputAmountParams}
                  setInputAmountParams={setInputAmountParams}
                  handleRemoveField={handleDeleteButton}
                  cases={filteredProductCases}
                  caseSelectWidth={caseSelectWidth}
                  caseLabelWidth={caseLabelWidth}
                />
              );
            })
            .reverse()}
      </S.InputContainer>
      <S.NoteAmountContainer>
        <S.NoteAmountLabel>備考</S.NoteAmountLabel>
        <S.NoteAmountInput
          placeholder=""
          value={inputAmountParams.length > 0 && comment ? comment : ''}
          onChange={handleComment}
        />
      </S.NoteAmountContainer>
      {deleteId && (
        <S.DeleteModalWrapper>
          <S.DeleteModalTextContainer>
            <span>削除後は元に戻すことが出来ません。</span>
            <span>本当に削除しますか？</span>
          </S.DeleteModalTextContainer>
          <S.DeleteModalButtonContainer>
            <Button
              padding="sm"
              outlined
              onClick={() => {
                setDeleteId(null);
                // setConfirmModal(false);
              }}
            >
              キャンセル
            </Button>
            <Button
              padding="sm"
              icon={<DeleteIcon />}
              outlined
              onClick={() => {
                onClickDelete();
              }}
            >
              削除
            </Button>
          </S.DeleteModalButtonContainer>
        </S.DeleteModalWrapper>
      )}
    </S.Wrapper>
  );
};
export default StockForm;
