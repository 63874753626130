import styled from 'styled-components';

export const Wrapper = styled.div<{
  bgColor?: string;
}>`
  width: 100vw;
  height: calc(100vh - 193px);
  padding: 16px 80px 150px;
  overflow: scroll;
  background-color: ${(props) => props.theme.palette.beige};
  .go-back {
    width: 160px;
    display: flex;
    padding-bottom: 32px;
    cursor: pointer;
    color: ${(props) => props.theme.palette.blue};
    > span {
      padding-top: 8px;
      padding-left: 10px;
    }
  }
`;

/**
 * 各商品の情報
 */
export const ProductMasterEditContents = styled.div`
  .product-info {
    display: flex;
    /* 商品画像 **********/
    .product-img {
      grid-area: product-img;
      width: 60px;
      height: 60px;
      background-color: #ececec;
      display: flex;
      justify-content: center;
      align-items: center;
      .img {
        img {
          width: 60px;
          height: 60px;
          object-fit: contain;
        }
      }
    }
    .name {
      display: flex;
      align-items: center;
      padding-left: 11px;
      font-size: 24px;
    }
  }

  /* 在庫室 **********/
  .storage-location-container {
    .storage-location-list__storage-location {
      display: flex;
      align-items: center;
      padding: 12px 0;
      border-bottom: solid 1px #cdcdcd;
      min-height: 64px;
      /* xボタン */
      .close-btn {
        padding: 8px;
      }
      /* 在庫室名 */
      .storage-location-name {
        width: 100%;
      }
      /* 編集ボタン */
      .edit-btn {
        margin-left: auto;
        padding: 8px;
      }
    }
  }

  /* 追加ボタン */
  .add-button {
    width: 170px;
    margin-top: 8px;
    padding: 8px;
    display: flex;
    align-items: center;
    cursor: pointer;
    .label {
      margin-left: 8px;
      color: ${(props) => props.theme.palette.primary.main};
    }
  }

  /* 在庫室 */
  .storage-location-container {
    grid-area: storage-location-container;
    margin-top: 32px;
    &__title {
      font-weight: bold;
      padding: 24px 0 8px;
    }
  }
`;

/**
 * アイコン
 */
export const PlusIcon = styled.div`
  svg {
    width: 1.25rem;
    height: 1.25rem;
  }
`;

export const CircularIconWrapper = styled.div`
  position: absolute;
  top: 20rem;
  right: 50%;
`;

export const ButtonContainer = styled.div`
  position: fixed;
  width: 100vw;
  bottom: 0;
  padding: 22px 0;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #f8f7f4;
  & > button {
    width: 136px;
    &.disabled {
      background: #e0e0e0;
      border-color: #e0e0e0;
      color: #6b675c;
      cursor: initial;
    }
  }
`;

export const StoreroomArrayTable = styled.table`
  width: 100%;
`;

export const StoreroomTitleTh = styled.th`
  width: 80%;
  font-weight: 700;
  text-align: left;
`;

export const DisplayOrderTitleTh = styled.th`
  width: 80%;
  font-weight: 700;
  text-align: right;
  padding-right: 20px;
`;

export const EditButtonTd = styled.td`
  text-align: -webkit-center;
  margin: 12px 0;
`;

export const EditButton = styled.div`
  text-align: -webkit-right;
  width: 100px;
  cursor: pointer;
`;

export const SelectedSToreroomWrapperTr = styled.tr`
  margin: 12px 0;
  min-height: 40px;
  border-bottom: solid 1px #cdcdcd;
`;

export const StoreroomNameTd = styled.td`
  padding: 26px 0;
`;

export const DisplayOrderTd = styled.td<{
  editMode: boolean;
}>`
  text-align: ${(props) => (props.editMode ? 'center' : 'end')};
  padding: ${(props) => (props.editMode ? '0px' : '25px')};
  margin: 12px 0;
`;

export const DisplayOrderDiv = styled.div<{ editMode: boolean }>`
  text-align: end;
  padding: ${(props) => (props.editMode ? '25px 0 25px 25px' : '0')};
`;

export const DisplayOrderInput = styled.input`
  width: 90%;
  height: 44px;
  color: ${(props) => props.theme.palette.baseBlack};
  font-size: 1rem;
  padding: 0.75rem;
  text-align: end;
  border: none;
  border-radius: 0.5rem;
  border: 1px solid rgba(0, 0, 0, 0.08);
  margin: 12px 0;
  background: ${(props) => props.theme.palette.white};
  ::-webkit-inner-spin-button {
    -webkit-appearance: none;
  }
`;
