import styled from 'styled-components';

export const Wrapper = styled.div<{
  open?: boolean;
}>`
  width: 100vw;
  height: 100%;

  background: ${(props) => props.theme.palette.sandGray};
  padding: 1.5rem 1.5rem;

  top: ${(props) => (props.open ? '0' : '100%')};
  position: fixed;
  transition: all 0.5s ease-out;
  z-index: 10000;
  overflow-x: hidden;
`;

export const ModalHead = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 0 0.5rem 1.3rem;
`;

export const ModalHeadCloseIcon = styled.div`
  cursor: pointer;
  padding: 8px;

  > svg {
    width: 24px;
    height: 24px;
  }
`;

export const ProductMetaContainer = styled.div`
  display: flex;
  align-items: center;

  font-weight: 700;

  > div:nth-child(2) {
    margin-left: 1rem;
    font-size: 1.5rem;
  }
`;
