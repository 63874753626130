import { useMutation } from 'react-query';
import humps from 'humps';
import moment from 'moment';
import { axiosInstance } from '@lib/pc/common/api/axiosConfig';
import type { InputParams } from '@lib/pc/semi_product_production_result/type';

const useUpdateSemiProductProductionResultMutation = (
  id: string | number,
  date: Date,
  factoryLineId: number,
  handleUpdateSuccess: () => void
) => {
  return useMutation(
    async (params: InputParams) => {
      return await axiosInstance().patch(
        `/api/v1/semi_product_production_results/${id}?date=${moment(
          date
        ).format('YYYY-MM-DD')}&factory_line_id=${factoryLineId}`,
        humps.decamelizeKeys({
          semiProductProductionResultDetails: {
            expirationDate: params.expirationDate,
            expirationBaseDate: params.expirationBaseDate,
            cart: params.cart,
            case: params.case,
            piece: params.piece,
            caseId: params.caseId,
            amount: params.amount,
            defectiveSemiProductAmount: params.defectiveSemiProductAmount,
            checkTime: params.checkTime,
            comment: params.comment,
            storeroomId: params.storeroomId,
            employeeIds: params.employeeIds,
            plannedCase: params.plannedCase,
            plannedPiece: params.plannedPiece,
            plannedAmount: params.plannedAmount,
          },
        })
      );
    },
    {
      onSuccess: () => {
        handleUpdateSuccess();
      },
      onError: () => alert('エラーが発生しました'),
    }
  );
};

export default useUpdateSemiProductProductionResultMutation;
