import { useEffect, useState } from 'react';
import { Heading } from '@components/elements/typographies/Heading';
import * as S from './SupplierContactModal.styles';
import { ReactComponent as CloseIcon } from '@assets/icons/close.svg';
import { WithHeader } from '@components/templates/WithHeader';
import { Button } from '@components/elements/buttons/Button';
import ConfirmPopUp from '@components/modules/common/ConfirmPopUp/ConfirmPopUp';
import SupplierEmailList from '@components/modules/pc/settings/supplier_setting/SupplierEdit/SupplierEmailList/SupplierEmailList';
import ErrorMsgPopUp from '@components/modules/common/ErrorMsgPopUp/ErrorMsgPopUp';
import Overlay from '@components/elements/backdrops/Overlay/Overlay';
import useSupplierDepartment from '@lib/pc/settings/supplier_edit/useSupplierDepartment';
import useCreateOrUpdateSupplierContactMutation from '@lib/pc/settings/supplier_edit/useCreateOrUpdateSupplierContact';

type Props = {
  selectedSupplierDepartmentId: number;
  setSelectedSupplierDepartmentId: (
    selectedSupplierDepartmentId: number | null
  ) => void;
  setUpdatePopUp: (updatePopUp: boolean) => void;
  refetch: () => void;
};

const SupplierContactModal = ({
  selectedSupplierDepartmentId,
  setSelectedSupplierDepartmentId,
  setUpdatePopUp,
  refetch,
}: Props) => {
  const { supplierDepartment, refetch: supplierDepartmentRefetch } =
    useSupplierDepartment(selectedSupplierDepartmentId);

  const [newData, setNewData] = useState(supplierDepartment);
  const [isChanged, setIsChanged] = useState(false);
  const [updating, setUpdating] = useState(false);
  const [errMsg, setErrMsg] = useState('');
  const [confirmMsg, setConfirmMsg] = useState('');

  const [newEmails, setNewEmails] = useState<string[]>([]);
  const [newCcEmails, setNewCcEmails] = useState<string[]>([]);

  const handleClose = () => {
    if (isChanged) {
      // 確認メッセージ表示：変更があります
      setConfirmMsg('変更がありますが、保存をせずに終了してよろしいですか？');
    } else {
      setSelectedSupplierDepartmentId(null);
    }
  };

  const handleChange = (
    e:
      | React.ChangeEvent<HTMLInputElement>
      | React.ChangeEvent<HTMLTextAreaElement>
  ) => {
    setIsChanged(true);
    setNewData({ ...newData, [e.target.name]: e.target.value });
  };

  const handleChangeNumber = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.value.match(/^[0-9-]*$/)) {
      setIsChanged(true);
      setNewData({ ...newData, [e.target.name]: e.target.value });
    }
  };

  // 確認メッセージ、OKボタン
  const handleOk = () => {
    setSelectedSupplierDepartmentId(null);
    refetch();
    handleClose();
  };

  const handleCancel = () => {
    setConfirmMsg('');
  };

  const handleSuccess = () => {
    setUpdating(false);
    setIsChanged(false);
    setUpdatePopUp(true);
    supplierDepartmentRefetch();
  };

  const createOrUpdateSupplierContactMutation =
    useCreateOrUpdateSupplierContactMutation(
      selectedSupplierDepartmentId,
      handleSuccess,
      setErrMsg
    );

  // 保存ボタン
  const handleSubmit = () => {
    createOrUpdateSupplierContactMutation.mutate(newData);
  };

  // バツボタン押下
  const handleClickClose = () => {
    if (isChanged) {
      setConfirmMsg('変更した内容を保存せずに終了します。よろしいですか？');
    } else {
      setSelectedSupplierDepartmentId(null);
      refetch();
      handleClose();
    }
  };

  useEffect(() => {
    setNewData(supplierDepartment);
  }, [supplierDepartment?.name]);

  useEffect(() => {
    setNewData(supplierDepartment);
  }, [supplierDepartment?.email?.length]);

  useEffect(() => {
    if (supplierDepartment?.email?.length > 0) {
      setNewEmails(supplierDepartment?.email);
    }
  }, [supplierDepartment?.email?.length]);

  useEffect(() => {
    if (supplierDepartment?.ccEmailAddresses?.length > 0) {
      setNewCcEmails(supplierDepartment?.ccEmailAddresses);
    }
  }, [supplierDepartment?.ccEmailAddresses?.length]);

  useEffect(() => {
    setNewData({ ...newData, email: newEmails });
  }, [newEmails]);

  useEffect(() => {
    setNewData({ ...newData, ccEmailAddresses: newCcEmails });
  }, [newCcEmails]);

  return (
    <>
      <WithHeader>
        <S.Wrapper>
          <S.HeadContainer>
            <div>
              <Heading tag="span" text="仕入先連絡先の編集" fs="32" fw="lg" />
            </div>
            <S.ModalHeadCloseIcon>
              <CloseIcon
                onClick={() => {
                  handleClickClose();
                }}
              />
            </S.ModalHeadCloseIcon>
          </S.HeadContainer>
          <S.ContentsWrapper>
            <S.ContentItem>
              <dt>仕入先部署名</dt>
              <dd>
                <S.Input
                  type="text"
                  name="supplierDepartmentName"
                  id="supplierDepartmentName"
                  value={
                    newData?.supplierDepartmentName
                      ? newData?.supplierDepartmentName
                      : ''
                  }
                  onChange={handleChange}
                  autoComplete="new-password"
                />
              </dd>
            </S.ContentItem>
            <S.ContentItem>
              <dt>担当者名</dt>
              <dd>
                <S.Input
                  type="text"
                  name="name"
                  id="name"
                  value={newData?.name ? newData?.name : ''}
                  onChange={handleChange}
                  autoComplete="new-password"
                />
              </dd>
            </S.ContentItem>
            <S.ContentItem>
              <dt>郵便番号</dt>
              <dd>
                <S.Input
                  type="text"
                  name="zipCode"
                  id="zipCode"
                  value={newData?.zipCode ? newData?.zipCode : ''}
                  onChange={handleChange}
                  autoComplete="new-password"
                />
              </dd>
            </S.ContentItem>
            <S.ContentItem>
              <dt>住所</dt>
              <dd>
                <S.Input
                  type="text"
                  name="address"
                  id="address"
                  value={newData?.address ? newData?.address : ''}
                  onChange={handleChange}
                  autoComplete="new-password"
                />
              </dd>
            </S.ContentItem>
            <S.TelFaxWrapper>
              <S.ContentItem width="47%">
                <dt>電話番号</dt>
                <dd>
                  <S.Input
                    type="number"
                    name="phoneNumber"
                    id="phoneNumber"
                    value={newData?.phoneNumber ? newData?.phoneNumber : ''}
                    onChange={handleChangeNumber}
                    autoComplete="new-password"
                  />
                </dd>
              </S.ContentItem>
              <S.ContentItem width="47%">
                <dt>fax番号</dt>
                <dd>
                  <S.Input
                    type="number"
                    name="faxNumber"
                    id="faxNumber"
                    value={newData?.faxNumber ? newData?.faxNumber : ''}
                    onChange={handleChangeNumber}
                    autoComplete="new-password"
                  />
                </dd>
              </S.ContentItem>
            </S.TelFaxWrapper>

            <S.ContentItem marginTop="80px">
              <dt>メール件名</dt>
              <dd>
                <S.Input
                  type="text"
                  name="emailSubjectDefault"
                  id="emailSubjectDefault"
                  value={
                    newData?.emailSubjectDefault
                      ? newData?.emailSubjectDefault
                      : ''
                  }
                  onChange={handleChange}
                  autoComplete="new-password"
                />
              </dd>
            </S.ContentItem>
            <S.ContentItem>
              <dt>メール本文</dt>
              <dd>
                <S.MailBodyTextArea
                  name="emailBodyDefault"
                  id="emailBodyDefault"
                  value={newData?.emailBodyDefault || ''}
                  onChange={handleChange}
                />
              </dd>
            </S.ContentItem>

            <S.ToAndCcMailAddressesWrapper>
              {/* TO：メールアドレス */}
              <S.ToAndCcMailAddressesItem>
                <SupplierEmailList
                  emailKind={'Email'}
                  emails={newEmails}
                  setEmails={setNewEmails}
                  setIsChanged={setIsChanged}
                />
              </S.ToAndCcMailAddressesItem>
              {/* CC：メールアドレス */}
              <S.ToAndCcMailAddressesItem>
                <SupplierEmailList
                  emailKind={'SemiProduct'}
                  emails={newCcEmails}
                  setEmails={setNewCcEmails}
                  setIsChanged={setIsChanged}
                />
              </S.ToAndCcMailAddressesItem>
            </S.ToAndCcMailAddressesWrapper>
          </S.ContentsWrapper>

          <S.ButtonWrapper>
            <S.ButtonContainer>
              <Button
                children={updating ? '保存中...' : '保存'}
                disabled={updating || !isChanged}
                onClick={handleSubmit}
              />
            </S.ButtonContainer>
          </S.ButtonWrapper>
        </S.Wrapper>
      </WithHeader>
      <ConfirmPopUp
        fromPc={true}
        confirmMsg={confirmMsg}
        zIndex={1004}
        handleOk={handleOk}
        handleCancel={handleCancel}
      />
      <ErrorMsgPopUp
        errMsg={errMsg}
        handleClose={() => {
          setErrMsg('');
          setUpdating(false);
        }}
        fromPc={true}
        zIndex={9999}
      />
      {errMsg && (
        <Overlay
          dark
          handleClick={() => {
            setErrMsg('');
            setUpdating(false);
          }}
        />
      )}
    </>
  );
};

export default SupplierContactModal;
