import moment from 'moment';

export const generateMonthlyDateArray = (
  monthlyDateNum: number,
  date = moment(),
  index: number
) => {
  return [...Array(monthlyDateNum + 3)].map((_, i) => {
    const d = moment(date).toDate();
    d.setDate(1 + i);
    return {
      productionDate: d,
      isToday: i === index && i <= monthlyDateNum,
    };
  });
};
