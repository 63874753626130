import { useEffect, useState } from 'react';
import { useHistory, useLocation } from 'react-router';
import { useWindowScroll } from 'react-use';
import moment from 'moment';
import * as S from './PickingEditWrapper.styles';
// import Overlay from '@components/elements/backdrops/Overlay/Overlay';
import { ReactComponent as ChevronLeftIcon } from '@assets/icons/mobile/chevron_left.svg';
import { ReactComponent as StoreHouse } from '@assets/icons/mobile/storehouse.svg';
import PickingProductSearchForm from '@components/modules/mobile/picking_edit/PickingProductSearchForm/PickingProductSearchForm';
import PickingInfoByProduct from '@components/modules/mobile/picking_edit/PickingInfoByProduct/PickingInfoByProduct';
// import FixedStatus from '@components/modules/mobile/picking_edit/FixedStatus/FixedStatus';
// import StatusModal from '@components/modules/mobile/picking_edit/StatusModal/StatusModal';
import {
  DetailsByProduct,
  IndividualShippings,
  PickingState,
} from '@lib/mobile/picking_edit/type';
import { Product, Storeroom } from '@lib/common/type';

type Props = {
  products: Product[];
  storerooms: Storeroom[];
  individualShippings: IndividualShippings;
};

const PickingEditWrapper = ({ storerooms, individualShippings }: Props) => {
  const history = useHistory();
  const { state } = useLocation<PickingState>();
  const shippingDateFromState = state.stateShippingDate;

  const formattedDate = moment(shippingDateFromState).format('YYYY/MM/DD');
  const formattedDayOfWeek = moment(shippingDateFromState).format('(ddd)');

  // const [searchChar, setSearchChar] = useState('');
  // チェック済以外表示チェックボックス
  const [completedCheckState, setCompletedCheckState] = useState(true);
  // 商品検索エリア
  const [searchProductChar, setSearchProductChar] = useState('');
  // ステータス
  const [pickingStatus, setPickingStatus] = useState('pickingNotStarted');
  // スクロール位置state
  const { y: currentScrollPosition } = useWindowScroll();
  // スクロール固定エリアの高さ
  const [fixedAreaHeight, setFixedAreaHeight] = useState('');
  const [storeroomName, setStoreroomName] = useState('');
  const [filteredIndividualShippings, setFilteredIndividualShippings] =
    useState<DetailsByProduct[]>([]);

  const onClickGoBack = () => {
    history.push(`/mobile/picking`, {
      stateSearchChar: state.stateSearchChar,
      stateShippingDate: state.stateShippingDate,
      stateShippings: state.stateShippings,
      stateScrollPosition: state.stateScrollPosition,
      stateJanCode: state.stateJanCode,
      statePickingStatus: state.statePickingStatus,
      stateScannedProductName: state.stateScannedProductName,
      stateScrollPositionForEdit: 0,
      stateSelectedStoreroomId: state.stateSelectedStoreroomId,
      stateFromEditComponent: true,
    });
    //  }
  };

  const Status = () => {
    return (
      <>
        {pickingStatus === 'pickingNotStarted'
          ? 'ピッキング未着手'
          : pickingStatus === 'picking'
          ? 'ピッキング中'
          : '出荷待ち'}
      </>
    );
  };

  useEffect(() => {
    if (document) {
      const clientW = document?.getElementById('fixed-area')?.clientHeight;
      clientW && setFixedAreaHeight(clientW.toString());
    }
  }, [document]);

  useEffect(() => {
    const selectedStoreroom = storerooms.filter(
      (s: Storeroom) => s.id === Number(state.stateSelectedStoreroomId)
    );
    setStoreroomName(selectedStoreroom[0]?.name);
  }, [state.stateSelectedStoreroomId]);

  // 在庫室が選択されている場合、選択した在庫室idでfilter
  useEffect(() => {
    if (state.stateSelectedStoreroomId) {
      const filtered = individualShippings.detailsByProduct.filter(
        (p: DetailsByProduct) =>
          p.storeroomIds.includes(Number(state.stateSelectedStoreroomId))
      );
      setFilteredIndividualShippings(filtered);
    } else {
      setFilteredIndividualShippings(individualShippings.detailsByProduct);
    }
  }, [individualShippings]);

  if (!individualShippings) return null;

  return (
    <>
      <S.FixedArea id="fixed-area" scrollPosition={currentScrollPosition}>
        <S.Wrapper>
          <S.Title>
            <S.BackButton
              onClick={() => {
                onClickGoBack();
              }}
            >
              <S.IconWrapper></S.IconWrapper>
              <ChevronLeftIcon />
            </S.BackButton>
            <S.TitleContainer className="shipping-date">
              <S.PlanText>
                {formattedDate}
                <span>{formattedDayOfWeek}の</span>
                出荷予定
              </S.PlanText>
            </S.TitleContainer>
          </S.Title>
          <S.Title>
            <S.TitleContainer>
              <S.PlanText className="group-name">
                {individualShippings.groupName}
              </S.PlanText>
            </S.TitleContainer>
          </S.Title>
          <S.Title className="status">
            {/* ステータス */}
            <S.StatusWrapper status={pickingStatus}>
              <Status />
            </S.StatusWrapper>
            <S.ProductInfoWrapper>
              <S.ProductInfoText>
                {individualShippings.numberOfProducts}
                <span>品目 合計</span>
                {individualShippings.totalAmount}
                <span>個</span>
              </S.ProductInfoText>
            </S.ProductInfoWrapper>
          </S.Title>
        </S.Wrapper>
        {/* 在庫室 */}
        <S.Title className="storeroom">
          <S.IconWrapper>
            <StoreHouse />
          </S.IconWrapper>
          <S.StoreroomText>
            {storeroomName ? storeroomName : 'すべての在庫室'}
          </S.StoreroomText>
        </S.Title>
        {/* 検索エリア */}
        <PickingProductSearchForm
          searchProductChar={searchProductChar}
          setSearchProductChar={setSearchProductChar}
          completedCheckState={completedCheckState}
          setCompletedCheckState={setCompletedCheckState}
          allocateTrueStatus={true}
        />
      </S.FixedArea>
      <S.ScrollArea fixedAreaHeight={fixedAreaHeight}>
        {/* ピッキング予定商品別情報 複数の商品情報*/}
        <PickingInfoByProduct
          searchProductChar={searchProductChar}
          completedCheckState={completedCheckState}
          detailsByProduct={filteredIndividualShippings}
          // detailsByProduct={individualShippings.detailsByProduct}
          setPickingStatus={setPickingStatus}
          // janCode={janCode}
          fixedAreaHeight={fixedAreaHeight}
        />
      </S.ScrollArea>
      {/* ステータス設定画面 */}
      {/* <StatusModal
        statusOpen={statusOpen}
        setStatusOpen={setStatusOpen}
        pickingStatus={pickingStatus}
        setPickingStatus={setPickingStatus}
      /> */}
      {/* ステータス固定画面 */}
      {/* <FixedStatus
        setStatusOpen={setStatusOpen}
        pickingStatus={pickingStatus}
      />
      {statusOpen && <Overlay dark handleClick={() => setStatusOpen(false)} />} */}
    </>
  );
};

export default PickingEditWrapper;
