import Overlay from '@components/elements/backdrops/Overlay/Overlay';
import { ReactComponent as OkIcon } from '@assets/icons/ok.svg';
import { ReactComponent as NgIcon } from '@assets/icons/ng.svg';
import { ReactComponent as CautionIcon } from '@assets/icons/caution.svg';

import * as S from './BestBeforeDateInfo.styles';
import { useState } from 'react';
import { BestBeforeDateByPic } from '@lib/pc/wrapping/types';
import PhotoSwipeForIndexModal from '../PhotoSwipeForIndexModal/PhotoSwipeForIndexModal';

type Props = {
  result: {
    bestBeforeDateState: string;
    bestBeforeDateCheckCount: number;
    bestBeforeDateRecognizedCount: number;
    bestBeforeDateInfo: BestBeforeDateByPic[];
  };
};

const BestBeforeDateInfo = ({ result }: Props) => {
  const [open, setOpen] = useState(false);

  return (
    <>
      <S.IconWrapper onClick={() => setOpen(true)}>
        {result.bestBeforeDateState === 'DATE_RECOGNIZED' ? (
          <OkIcon />
        ) : result.bestBeforeDateState === 'PARTIAL_DATE_RECOGNIZED' ? (
          <CautionIcon />
        ) : result.bestBeforeDateState === 'DATE_NOT_RECOGNIZED' ? (
          <NgIcon />
        ) : (
          ''
        )}
      </S.IconWrapper>
      {open && (
        <>
          <PhotoSwipeForIndexModal
            bestBeforeDateState={result.bestBeforeDateState}
            bestBeforeDateCheckCount={result.bestBeforeDateCheckCount}
            recognizedCnt={result.bestBeforeDateRecognizedCount}
            bestBeforeDatesForModal={result.bestBeforeDateInfo}
            closeModal={() => setOpen(false)}
          />
          <Overlay dark />
        </>
      )}
    </>
  );
};

export default BestBeforeDateInfo;
